import React from 'react'
import styles from '../utilitarios/UtilitariosBusca.module.css'
import { useState, useEffect/* , useContext */ } from 'react'
import { SearchResults } from './SearchResults'
import { CidAcentuado } from '../../data/CidAcentuado'
import { Baremos } from '../../data/Baremos'
import { Cbo } from '../../data/Cbo'
import { CnaeAcentuado } from '../../data/CnaeAcentuado'
import { DanoEsteticoAipeCobo2 } from '../../data/DanoEsteticoAipeCobo2'
import { DanoEsteticoClassSocFrancesaMedLegal } from '../../data/DanoEsteticoClassSocFrancesaMedLegal'
import { DanoEsteticoPerezGarcia } from '../../data/DanoEsteticoPerezGarcia'
import { NtepListaC } from '../../data/NtepListaC'
import { TabelaDpvat } from '../../data/TabelaDpvat'
import { ValoracaoDanoMao } from '../../data/ValoracaoDanoMao'
import { SofrimentosPadecidosQualitativos } from '../../data/SofrimentosPadecidosQualitativos'

//import { /* doc, setDoc, updateDoc, getDoc, */ getDocs, collection, /* query, where,limit, orderBy, startAfter */ } from "firebase/firestore"
//import { AuthGoogleContext } from '../contexts/authGoogle'
//import { db } from '../services/firebaseConfig'

/* export const BuscaPericia = () => {

    const [data, setData] = useState([])
        
    const handleInputChange = (e) => {
        e.preventDefault()
  
        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

  const { dadosUsuario } = useContext(AuthGoogleContext)

  const [listaPericias, setListaPericias] = useState([])

  const painelPericias = async () => {
    if (dadosUsuario) {

      //DADOS DE  TODAS AS PERICIAS
      const getDocsPericias = await getDocs(collection(db, 'users', dadosUsuario.uid, 'Pericias'))
      //const dadosTodasPericias = getDocsPericias.docs.map((e)=>e._document.data.value.mapValue.fields)
const x = getDocsPericias.docs.map((e)=>e.data())
      setListaPericias(x)

      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _99_dadosTodasPericias: x
      }))

    }
}
  
    //const [listaPericias, setListaPericias ] = useState([])
    const tudo = JSON.parse(localStorage.getItem('tudo')) || {}
  let getDadosTodasPericias = []
  if (tudo._99_dadosTodasPericias) {
  getDadosTodasPericias = JSON.parse(localStorage.getItem('tudo'))._99_dadosTodasPericias
  } else {
  localStorage.setItem(
  "tudo",
  JSON.stringify({
    ...(JSON.parse(localStorage.getItem("tudo")) || []),
    _99_dadosTodasPericias: []
  
  })
  )
  getDadosTodasPericias = JSON.parse(localStorage.getItem('tudo'))._99_dadosTodasPericias
  }
  //console.log(getDadosTodasPericias)
  
    const dadosFiltrados = getDadosTodasPericias.filter(({
        _01_dadosIniciais,
        _02_polo1,
        _03_polo2,
        _08_agendamento,
        _79_conclusaoLaudo,
        _80_esclarecimentos}) => {
  
        const regex = new RegExp(`${data}.*`, 'gi')
        //console.log(_01_dadosIniciais.numProcesso)

        if (data.length <= 2) return null
        return (_01_dadosIniciais.numProcesso !== undefined /* &&
         _02_polo1.nomePolo1 !== undefined && _03_polo2.razaoSocial !== undefined  ? 
         _01_dadosIniciais.numProcesso
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)  
                : '') || 
                (_02_polo1.nomePolo1 !== undefined ? 
                 
                   _02_polo1.nomePolo1
                       .replace(/[ÀÁÂÃÄÅ]/, "A")
                       .replace(/[àáâãäå]/, "a")
                       .replace(/[ÈÉÊË]/, "E")
                       .replace(/[èéêë]/, "e")
                       .replace(/[ÌÍÎÏ]/, "I")
                       .replace(/[ìíîï]/, "i")
                       .replace(/[ÒÓÔÕÖ]/, "O")
                       .replace(/[òóôõö]/, "o")
                       .replace(/[ÙÚÛÜ]/, "U")
                       .replace(/[ùúûü]/, "u")
                       .replace(/[Ç]/, "C")
                       .replace(/[ç]/, "c")
                       .match(regex)
                       : '') || 
                       (_03_polo2.razaoSocial !== undefined ? 
                        
                              _03_polo2.razaoSocial
                              .replace(/[ÀÁÂÃÄÅ]/, "A")
                              .replace(/[àáâãäå]/, "a")
                              .replace(/[ÈÉÊË]/, "E")
                              .replace(/[èéêë]/, "e")
                              .replace(/[ÌÍÎÏ]/, "I")
                              .replace(/[ìíîï]/, "i")
                              .replace(/[ÒÓÔÕÖ]/, "O")
                              .replace(/[òóôõö]/, "o")
                              .replace(/[ÙÚÛÜ]/, "U")
                              .replace(/[ùúûü]/, "u")
                              .replace(/[Ç]/, "C")
                              .replace(/[ç]/, "c")
                              .match(regex)
                              : '')  /* || 
                              (_08_agendamento.dataAgendamento !== undefined || _08_agendamento.horaAgendamento !== undefined ? 
                                     _08_agendamento.dataAgendamento
                                     .replace(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)
                                     .match(regex) ||
                                     _08_agendamento.horaAgendamento
                                     .replace(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/)
                                     .match(regex)
                                     : '')  */
                                     /* || 
                              (_79_conclusaoLaudo.conclusaoLaudoDataEntrega !== undefined || _79_conclusaoLaudo.conclusaoLaudoHoraEntrega !== undefined ? 
                                     _79_conclusaoLaudo.conclusaoLaudoDataEntrega
                                     .replace(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)
                                     .match(regex) ||
                                     _79_conclusaoLaudo.conclusaoLaudoHoraEntrega
                                     .replace(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/)
                                     .match(regex)
                                     : '')  */
                                     /* || 
                              (_80_esclarecimentos.esclarecimentosDataEntrega !== undefined || _80_esclarecimentos.esclarecimentosHoraEntrega !== undefined ? 
                                     _80_esclarecimentos.esclarecimentosDataEntrega
                                     .replace(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)
                                     .match(regex) ||
                                     _80_esclarecimentos.esclarecimentosHoraEntrega
                                     .replace(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/)
                                     .match(regex)
                                     : '')  
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            _01_dadosIniciais,
            _02_polo1,
            _03_polo2,
        _08_agendamento,
        _79_conclusaoLaudo,
        _80_esclarecimentos
        }, i) => {
            //console.log(_01_dadosIniciais)
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    numProcesso: _01_dadosIniciais.numProcesso,
                    nomePolo1: _02_polo1.nomePolo1,
                    razaoSocial: _03_polo2.razaoSocial,
                    dataAgendamento: _08_agendamento.dataAgendamento,
                    horaAgendamento: _08_agendamento.horaAgendamento,
                    conclusaoLaudoDataEntrega: _79_conclusaoLaudo.conclusaoLaudoDataEntrega,
                    conclusaoLaudoHoraEntrega: _79_conclusaoLaudo.conclusaoLaudoHoraEntrega,
                    esclarecimentosDataEntrega: _80_esclarecimentos.esclarecimentosDataEntrega,
                    esclarecimentosHoraEntrega: _80_esclarecimentos.esclarecimentosHoraEntrega,

                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _999_dadosTodasPericias: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {_01_dadosIniciais,
                _02_polo1,
                _03_polo2,
                _08_agendamento,
        _79_conclusaoLaudo,
        _80_esclarecimentos
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _999_dadosTodasPericias: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _999_dadosTodasPericias: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaPericia} />
                <label htmlFor={styles.checkboxBuscaPericia}>
                    <h2 className={styles.titulo}>Busca Pericia</h2>
                </label>
                <fieldset id={styles.conteudoBuscaPericia}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                    <button onClick={painelPericias}>Listar Perícias</button>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Busca perícias'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Número do Processo</th>
                                                <th>Reclamante</th>
                                                <th>Rclamada</th> 
                                                <th>Data e hora pericia</th>
                                                <th>Data e hora entrega laudo</th>
                                                <th>Data e hora esclarecimentos</th>  
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        _01_dadosIniciais,
                                                        _02_polo1,
                                                        _03_polo2,
                                                        _08_agendamento,
        _79_conclusaoLaudo,
        _80_esclarecimentos
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{_01_dadosIniciais.numProcesso}</th>
                                                            <th>{_02_polo1.nomePolo1}</th>
                                                            <th>{_03_polo2.razaoSocial}</th>
                                                            <th>{_08_agendamento.dataAgendamento} - {_08_agendamento.horaAgendamento}</th>
                                                            <th>{_79_conclusaoLaudo.conclusaoLaudoDataEntrega} - {_79_conclusaoLaudo.conclusaoLaudoHoraEntrega}</th>
                                                            <th>{_80_esclarecimentos.esclarecimentosDataEntrega} - {_80_esclarecimentos.esclarecimentosHoraEntrega}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Número do Processo</th>
                                                <th>Reclamante</th>
                                                <th>Rclamada</th>
                                                <th>Data e hora pericia</th>
                                                <th>Data e hora entrega laudo</th>
                                                <th>Data e hora esclarecimentos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                numProcesso,
                                                nomePolo1,
                                                razaoSocial,
                                                dataAgendamento,
                                                horaAgendamento,
                                                conclusaoLaudoDataEntrega,
                                                conclusaoLaudoHoraEntrega,
                                                esclarecimentosDataEntrega,
                                                esclarecimentosHoraEntrega
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{numProcesso}</th>
                                                            <th>{nomePolo1}</th>
                                                            <th>{razaoSocial}</th>
                                                            <th>{dataAgendamento} - {horaAgendamento}</th>
                                                            <th>{conclusaoLaudoDataEntrega} - {conclusaoLaudoHoraEntrega}</th>
                                                            <th>{esclarecimentosDataEntrega} - {esclarecimentosHoraEntrega}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
  } */

/* export const BuscaPericia = () => {

    const [data, setData] = useState([])

    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const [listaPericias, setListaPericias ] = useState([])

    const dadosFiltrados = listaPericias.filter(e => {

        //console.log(typeof(e[0].dadosIniciais[0].numProcesso), e[0].dadosIniciais[0].numProcesso)
        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null

        if (e[0].dadosIniciais[0].numProcesso !== undefined) {
            return e[0].dadosIniciais[0].numProcesso
                .match(regex) ||
                e[0].dadosIniciais[0].numProcesso
                    .replace(/\D/g, '')
                    .match(regex) ||
                e[1].polo1[0].nome
                    .replace(/[ÀÁÂÃÄÅ]/, "A")
                    .replace(/[àáâãäå]/, "a")
                    .replace(/[ÈÉÊË]/, "E")
                    .replace(/[èéêë]/, "e")
                    .replace(/[ÌÍÎÏ]/, "I")
                    .replace(/[ìíîï]/, "i")
                    .replace(/[ÒÓÔÕÖ]/, "O")
                    .replace(/[òóôõö]/, "o")
                    .replace(/[ÙÚÛÜ]/, "U")
                    .replace(/[ùúûü]/, "u")
                    .replace(/[Ç]/, "C")
                    .replace(/[ç]/, "c")
                    .match(regex) ||
                e[2].polo2[0].razaoSocial
                    .replace(/[ÀÁÂÃÄÅ]/, "A")
                    .replace(/[àáâãäå]/, "a")
                    .replace(/[ÈÉÊË]/, "E")
                    .replace(/[èéêë]/, "e")
                    .replace(/[ÌÍÎÏ]/, "I")
                    .replace(/[ìíîï]/, "i")
                    .replace(/[ÒÓÔÕÖ]/, "O")
                    .replace(/[òóôõö]/, "o")
                    .replace(/[ÙÚÛÜ]/, "U")
                    .replace(/[ùúûü]/, "u")
                    .replace(/[Ç]/, "C")
                    .replace(/[ç]/, "c")
                    .match(regex) ||
                e[3].agendamento[0].dataAgendamento
                    .match(regex) ||
                e[3].agendamento[0].dataAgendamento
                    .replace(/\D/g, '')
                    .match(regex)

        }
        return null
    })

    /* console.log('***data:', data) 

    return (
        <>
            <div className={styles.container}>
                <form>
                    <label htmlFor="search">Busca Pericia</label>
                    <input name='search' id='search' onChange={handleInputChange}  ref={inputRef}  />
                </form>
                <div className={styles.searchResults}>
                    <fieldset>
                        <legend>Pericias encontradas</legend>
                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ação</th>
                                        <th>Número do Processo</th>
                                        <th>Polo Ativo</th>
                                        <th>Polo Passivo</th>
                                        <th>Data e hora Perícia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dadosFiltrados.map((e, i) => {
                                        return (
                                            <tr key={i}>
                                                <th><button><i className="fas fa-check-circle confirma"></i></button></th>
                                                <th>{e[0].dadosIniciais[0].numProcesso}</th>
                                                <th>{e[1].polo1[0].nome}</th>
                                                <th>{e[2].polo2[0].razaoSocial}</th>
                                                <th>{e[3].agendamento[0].dataAgendamento} - {e[3].agendamento[0].hora}</th>
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        }
                    </fieldset>
                    <SearchResults data={data} />
                </div>
            </div>
        </>
    )
} */

export const BuscaRefTecnico = () => {

    const [data, setData] = useState([])

    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    //const getListaRefTecnico = JSON.parse(localStorage.getItem('@listaRefTecnico'))
    const [listaRefTecnico/* , setListaRefTecnico */] = useState(/* getListaRefTecnico || */ [])

    const dadosFiltrados = listaRefTecnico.filter(e => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null

        if (e.referencialTecnicoTitulo !== undefined) {
            return e.referencialTecnicoTitulo
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) /* || e.referencialTecnicoConteudo.match(regex) */
        }
        return null
    })

    /* console.log('***data:', data) */

    return (
        <>
            <div className={styles.container}>
                <form>
                    <label htmlFor="search">Busca RefTecnico</label>
                    <input name='search' id='search' onChange={handleInputChange} /* ref={inputRef}  */ />
                </form>
                <div className={styles.searchResults}>
                    <fieldset>
                        <legend>Referenciais técnicos encontrados</legend>
                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                            <table>
                                <thead>
                                    <tr>
                                        <th>Ação</th>
                                        <th>Título</th>
                                        <th>Conteúdo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dadosFiltrados.map((e, i) => {
                                        /* console.log(e) */
                                        return (<tr key={i}>
                                            <th><button><i className="fas fa-check-circle confirma"></i></button></th>
                                            <th>{e.referencialTecnicoTitulo}</th>
                                            <th>{e.referencialTecnicoConteudo}</th>
                                        </tr>)
                                    })}

                                </tbody>
                            </table>

                        }
                    </fieldset>
                    <SearchResults data={data} />
                </div>
            </div>
        </>
    )
}

export const UtilitariosBuscaCid = () => {

    const [data, setData] = useState([])

    const handleInputChange = (e) => {
        e.preventDefault()
        const {value} = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = CidAcentuado.filter(({codigo, descricao}) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return codigo !== undefined && descricao !== undefined ? codigo.match(regex) ||
            descricao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)
                : ''
    })

    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({ codigo, descricao }, i) => {
            if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({codigo: codigo, descricao: descricao })
                setData([])

            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _99_cidBusca: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            { codigo, descricao }, i
        ) => {
            if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _99_cidBusca: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _99_cidBusca: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    /* console.log(dadoSelecionado) */

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaCid} />
                <label htmlFor={styles.checkboxBuscaCid}>
                    <h2 className={styles.titulo}>Busca CID</h2>
                </label>
                <fieldset id={styles.conteudoBuscaCid}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite o codigo ou descriçao do CID'/* ref={inputRef}  */ />
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>CID´d encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Código</th>
                                                        <th>Descrição</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({ codigo, descricao }, i) => {

                                                        return (<tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{codigo}</th>
                                                            <th>{descricao}</th>
                                                        </tr>)
                                                    })}

                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>CID´s selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Código</th>
                                                <th>Descrição</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({codigo, descricao }, i) => {
                                                return (<tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{codigo}</th>
                                                    <th>{descricao}</th>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaBaremos = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = Baremos.filter(({
        descricao,
        baremoEuropeu,
        susep,
        dpvat,
        baremoArgentino,
        baremoPortugues,
        louisMelennec,
        baremoEspanhol

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return descricao !== undefined &&
         baremoEuropeu !== undefined && susep !== undefined &&
         dpvat !== undefined && baremoArgentino !== undefined &&
         baremoPortugues !== undefined && louisMelennec !== undefined && baremoEspanhol !== undefined ? 
         descricao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            baremoEuropeu
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                susep
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                dpvat
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoArgentino
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoPortugues
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                louisMelennec
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoEspanhol
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)   
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            descricao,
            baremoEuropeu,
            susep,
            dpvat,
            baremoArgentino,
            baremoPortugues,
            louisMelennec,
            baremoEspanhol
    
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({descricao,
                    baremoEuropeu,
                    susep,
                    dpvat,
                    baremoArgentino,
                    baremoPortugues,
                    louisMelennec,
                    baremoEspanhol})
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _110_deficitFuncionalDefinitivo: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {descricao,
                baremoEuropeu,
                susep,
                dpvat,
                baremoArgentino,
                baremoPortugues,
                louisMelennec,
                baremoEspanhol}, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _110_deficitFuncionalDefinitivo: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _110_deficitFuncionalDefinitivo: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaBaremos} />
                <label htmlFor={styles.checkboxBuscaBaremos}>
                    <h2 className={styles.titulo}>Busca Baremos</h2>
                </label>
                <fieldset id={styles.conteudoBuscaBaremos}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite o texto relativo a busca de Baremos'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Descrição</th>
                                                <th>Baremo Europeu</th>
                                                        <th>Susep</th>
                                                        <th>Dpvat</th>
                                                        <th>Baremo Argentino</th>
                                                        <th>Baremo Portugues</th>
                                                        <th>Louis Melennec</th>
                                                        <th>Baremo Espanhol</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({descricao,
                                                        baremoEuropeu,
                                                        susep,
                                                        dpvat,
                                                        baremoArgentino,
                                                        baremoPortugues,
                                                        louisMelennec,
                                                        baremoEspanhol
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{descricao}</th>
                                                            <th>{baremoEuropeu}</th>
                                                            <th>{susep}</th>
                                                            <th>{dpvat}</th>
                                                            <th>{baremoArgentino}</th>
                                                            <th>{baremoPortugues}</th>
                                                            <th>{louisMelennec}</th>
                                                            <th>{baremoEspanhol}</th>
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>CID´s selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Descrição</th>
                                                <th>Baremo Europeu</th>
                                                        <th>Susep</th>
                                                        <th>Dpvat</th>
                                                        <th>Baremo Argentino</th>
                                                        <th>Baremo Portugues</th>
                                                        <th>Louis Melennec</th>
                                                        <th>Baremo Espanhol</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({descricao,
                                                        baremoEuropeu,
                                                        susep,
                                                        dpvat,
                                                        baremoArgentino,
                                                        baremoPortugues,
                                                        louisMelennec,
                                                        baremoEspanhol
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{descricao}</th>
                                                    <th>{baremoEuropeu}</th>
                                                    <th>{susep}</th>
                                                    <th>{dpvat}</th>
                                                    <th>{baremoArgentino}</th>
                                                    <th>{baremoPortugues}</th>
                                                    <th>{louisMelennec}</th>
                                                    <th>{baremoEspanhol}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaCbo = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = Cbo.filter(({
        id_Cbo,
        codigo,
        titulo,

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return id_Cbo !== undefined &&
         codigo !== undefined && titulo !== undefined ? 
         id_Cbo
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            codigo
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                titulo
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) 
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            id_Cbo,
            codigo,
            titulo,
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    id_Cbo,
                    codigo,
                    titulo,
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _115_buscaCboMini: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {id_Cbo,
                codigo,
                titulo,
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _115_buscaCboMini: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _115_buscaCboMini: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaCboMini} />
                <label htmlFor={styles.checkboxBuscaCboMini}>
                    <h2 className={styles.titulo}>Busca Cbo</h2>
                </label>
                <fieldset id={styles.conteudoBuscaCboMini}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Busca CBO'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        {/* <th>id Cbo</th> */}
                                                <th>Código</th>
                                                <th>Título</th>    
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        id_Cbo,
                                                        codigo,
                                                        titulo,
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                           {/*  <th>{id_Cbo}</th> */}
                                                            <th>{codigo}</th>
                                                            <th>{titulo}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                               {/*  <th>id Cbo</th> */}
                                                <th>Código</th>
                                                <th>Título</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                id_Cbo,
                                                codigo,
                                                titulo
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    {/* <th>{id_Cbo}</th> */}
                                                            <th>{codigo}</th>
                                                            <th>{titulo}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaCnaeAcentuado = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = CnaeAcentuado.filter(({
        id_cnae,
        subclasse,
        denominacao,

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return id_cnae !== undefined &&
         subclasse !== undefined && denominacao !== undefined ? 
         id_cnae
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            subclasse
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                denominacao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) 
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            id_cnae,
            subclasse,
            denominacao,
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    id_cnae,
                    subclasse,
                    denominacao,
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _116_buscaCnaeMini: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {id_cnae,
                subclasse,
                denominacao,
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _116_buscaCnaeMini: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _116_buscaCnaeMini: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaCnaeMini} />
                <label htmlFor={styles.checkboxBuscaCnaeMini}>
                    <h2 className={styles.denominacao}>Busca Cnae</h2>
                </label>
                <fieldset id={styles.conteudoBuscaCnaeMini}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Busca CNAE mini'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        {/* <th>Id Cnae</th> */}
                                                <th>Subclasse</th>
                                                <th>Denominação</th>    
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        id_cnae,
                                                        subclasse,
                                                        denominacao,
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{id_cnae}</th>
                                                            <th>{subclasse}</th>
                                                            <th>{denominacao}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                {/* <th>Id Cnae</th> */}
                                                <th>Subclasse</th>
                                                <th>Denominação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                id_cnae,
                                                subclasse,
                                                denominacao
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    {/* <th>{id_cnae}</th> */}
                                                            <th>{subclasse}</th>
                                                            <th>{denominacao}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaSofrimentosPadecidosQualitativos = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = SofrimentosPadecidosQualitativos.filter(({
        sofrimentoPadecido,
        baremoAvaliacaoMedicoLegal2000,
        sociedadeFrancesaMedicinaLegal2007,
        brousseauRousseau1983,
        sociedadeFrancesaMedicinaForense1991,
        lambertFaivre1996,
        thierryNicourt1981,
        baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return sofrimentoPadecido !== undefined &&
         baremoAvaliacaoMedicoLegal2000 !== undefined && sociedadeFrancesaMedicinaLegal2007 !== undefined &&
         brousseauRousseau1983 !== undefined && sociedadeFrancesaMedicinaForense1991 !== undefined &&
         lambertFaivre1996 !== undefined && thierryNicourt1981 !== undefined && baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982 !== undefined ? 
         sofrimentoPadecido
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            baremoAvaliacaoMedicoLegal2000
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                sociedadeFrancesaMedicinaLegal2007
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                brousseauRousseau1983
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                sociedadeFrancesaMedicinaForense1991
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                lambertFaivre1996
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                thierryNicourt1981
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)   
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            sofrimentoPadecido,
            baremoAvaliacaoMedicoLegal2000,
            sociedadeFrancesaMedicinaLegal2007,
            brousseauRousseau1983,
            sociedadeFrancesaMedicinaForense1991,
            lambertFaivre1996,
            thierryNicourt1981,
            baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982
    
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({sofrimentoPadecido,
                    baremoAvaliacaoMedicoLegal2000,
                    sociedadeFrancesaMedicinaLegal2007,
                    brousseauRousseau1983,
                    sociedadeFrancesaMedicinaForense1991,
                    lambertFaivre1996,
                    thierryNicourt1981,
                    baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982})
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _109_sofrimentosPadecidosQualitativo: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {sofrimentoPadecido,
                baremoAvaliacaoMedicoLegal2000,
                sociedadeFrancesaMedicinaLegal2007,
                brousseauRousseau1983,
                sociedadeFrancesaMedicinaForense1991,
                lambertFaivre1996,
                thierryNicourt1981,
                baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982}, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _109_sofrimentosPadecidosQualitativo: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _109_sofrimentosPadecidosQualitativo: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaSofrimentosPadecidosQualitativos} />
                <label htmlFor={styles.checkboxBuscaSofrimentosPadecidosQualitativos}>
                    <h2 className={styles.titulo}>Busca Sofrimentos Padecidos Qualitativos</h2>
                </label>
                <fieldset id={styles.conteudoBuscaSofrimentosPadecidosQualitativos}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite o codigo ou descriçao do sofrimento padecido'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Sofrimento Padecido</th>
                                                        <th>Baremo Avaliação Médico Legal 2000</th>
                                                        <th>Sociedade Francesa Medicina Legal 2007</th>
                                                        <th>Brousseau Rousseau 1983</th>
                                                        <th>Sociedade Francesa Medicina Forense 1991</th>
                                                        <th>Lambert Faivre 1996</th>
                                                        <th>Thierry Nicourt 1981</th>
                                                        <th>Baremo Funcional Indicativo Deficiencia Permanente Direito Comum 1982</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({sofrimentoPadecido,
                                                        baremoAvaliacaoMedicoLegal2000,
                                                        sociedadeFrancesaMedicinaLegal2007,
                                                        brousseauRousseau1983,
                                                        sociedadeFrancesaMedicinaForense1991,
                                                        lambertFaivre1996,
                                                        thierryNicourt1981,
                                                        baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{sofrimentoPadecido}</th>
                                                            <th>{baremoAvaliacaoMedicoLegal2000}</th>
                                                            <th>{sociedadeFrancesaMedicinaLegal2007}</th>
                                                            <th>{brousseauRousseau1983}</th>
                                                            <th>{sociedadeFrancesaMedicinaForense1991}</th>
                                                            <th>{lambertFaivre1996}</th>
                                                            <th>{thierryNicourt1981}</th>
                                                            <th>{baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982}</th>
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>CID´s selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Sofrimento Padecido</th>
                                                <th>Baremo Avaliação Médico Legal 2000</th>
                                                        <th>Sociedade Francesa Medicina Legal 2007</th>
                                                        <th>Brousseau Rousseau 1983</th>
                                                        <th>Sociedade Francesa Medicina Forense 1991</th>
                                                        <th>Lambert Faivre 1996</th>
                                                        <th>Thierry Nicourt 1981</th>
                                                        <th>Baremo Funcional Indicativo Deficiencia Permanente Direito Comum 1982</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({sofrimentoPadecido,
                                                        baremoAvaliacaoMedicoLegal2000,
                                                        sociedadeFrancesaMedicinaLegal2007,
                                                        brousseauRousseau1983,
                                                        sociedadeFrancesaMedicinaForense1991,
                                                        lambertFaivre1996,
                                                        thierryNicourt1981,
                                                        baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{sofrimentoPadecido}</th>
                                                    <th>{baremoAvaliacaoMedicoLegal2000}</th>
                                                    <th>{sociedadeFrancesaMedicinaLegal2007}</th>
                                                    <th>{brousseauRousseau1983}</th>
                                                    <th>{sociedadeFrancesaMedicinaForense1991}</th>
                                                    <th>{lambertFaivre1996}</th>
                                                    <th>{thierryNicourt1981}</th>
                                                    <th>{baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaDanoEsteticoAipeCobo2 = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = DanoEsteticoAipeCobo2.filter(({
        classificacaoAipeCobo,
        pergunta1,
        nivelEvidencia,
        pergunta2,
        nivelTendenciaOlhar,
        pergunta3,
        nivelRecordacao,
        pergunta4,
        nivelEmocao,
        pergunta5,
        nivelRelacaoInterpessoal

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return classificacaoAipeCobo !== undefined &&
         pergunta1 !== undefined && nivelEvidencia !== undefined && 
         pergunta2 !== undefined && nivelTendenciaOlhar !== undefined && 
         pergunta3 !== undefined && nivelRecordacao !== undefined &&
         pergunta4 !== undefined && nivelEmocao &&
         pergunta5 !== undefined && nivelRelacaoInterpessoal !== undefined ? 
         classificacaoAipeCobo
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            pergunta1
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)
                ||
                nivelEvidencia
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)
                || 
            pergunta2
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) 
                ||
                nivelTendenciaOlhar
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)
                
                || 
            pergunta3
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) 
                || 
                nivelRecordacao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
            pergunta4
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) 
                || 
                nivelEmocao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
            pergunta5
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                nivelRelacaoInterpessoal
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)     
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            classificacaoAipeCobo,
        pergunta1,
        nivelEvidencia,
        pergunta2,
        nivelTendenciaOlhar,
        pergunta3,
        nivelRecordacao,
        pergunta4,
        nivelEmocao,
        pergunta5,
        nivelRelacaoInterpessoal
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    classificacaoAipeCobo,
        pergunta1,
        nivelEvidencia,
        pergunta2,
        nivelTendenciaOlhar,
        pergunta3,
        nivelRecordacao,
        pergunta4,
        nivelEmocao,
        pergunta5,
        nivelRelacaoInterpessoal
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _113_danoEsteticoAipeCobo: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {classificacaoAipeCobo,
                pergunta1,
                nivelEvidencia,
                pergunta2,
                nivelTendenciaOlhar,
                pergunta3,
                nivelRecordacao,
                pergunta4,
                nivelEmocao,
                pergunta5,
                nivelRelacaoInterpessoal
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _113_danoEsteticoAipeCobo: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _113_danoEsteticoAipeCobo: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaDanoEsteticoAipeCobo} />
                <label htmlFor={styles.checkboxBuscaDanoEsteticoAipeCobo}>
                    <h2 className={styles.titulo}>Busca Dano Estético Aipe Cobo</h2>
                </label>
                <fieldset id={styles.conteudoBuscaDanoEsteticoAipeCobo}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite o texto relativo a busca do dano estético segundo Aipe Cobo'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Classificação</th>
                                        <th>Nível de Evidencia</th>
                                        <th>Nível de Tendência ao Olhar</th>
                                        <th>Nível de Recordção</th>
                                        <th>Nível de Emoção</th>
                                        <th>Nível de Relacao Interpessoal</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        classificacaoAipeCobo,
                                                        pergunta1,
                                                        nivelEvidencia,
                                                        pergunta2,
                                                        nivelTendenciaOlhar,
                                                        pergunta3,
                                                        nivelRecordacao,
                                                        pergunta4,
                                                        nivelEmocao,
                                                        pergunta5,
                                                        nivelRelacaoInterpessoal
                                                        
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{classificacaoAipeCobo}</th>
                                            <th>{pergunta1}||{nivelEvidencia}</th>
                                            <th>{pergunta2}||{nivelTendenciaOlhar}</th>
                                            <th>{pergunta3}||{nivelRecordacao}</th>
                                            <th>{pergunta4}||{nivelEmocao}</th>
                                            <th>{pergunta5}||{nivelRelacaoInterpessoal}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Classificação</th>
                                        <th>Nível de Evidencia</th>
                                        <th>Nível de Tendência ao Olhar</th>
                                        <th>Nível de Recordção</th>
                                        <th>Nível de Emoção</th>
                                        <th>Nível de Relacao Interpessoal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                classificacaoAipeCobo,
                                                pergunta1,
                                                nivelEvidencia,
                                                pergunta2,
                                                nivelTendenciaOlhar,
                                                pergunta3,
                                                nivelRecordacao,
                                                pergunta4,
                                                nivelEmocao,
                                                pergunta5,
                                                nivelRelacaoInterpessoal
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{classificacaoAipeCobo}</th>
                                            <th>{pergunta1}||{nivelEvidencia}</th>
                                            <th>{pergunta2}||{nivelTendenciaOlhar}</th>
                                            <th>{pergunta3}||{nivelRecordacao}</th>
                                            <th>{pergunta4}||{nivelEmocao}</th>
                                            <th>{pergunta5}||{nivelRelacaoInterpessoal}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaDanoEsteticoClassSocFrancesaMedLegal = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = DanoEsteticoClassSocFrancesaMedLegal.filter(({
        classificacaoSocFrancesaMedLegal,
        caracteristicas,

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return classificacaoSocFrancesaMedLegal !== undefined &&
         caracteristicas !== undefined  ? 
         classificacaoSocFrancesaMedLegal
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            caracteristicas
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)    
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            classificacaoSocFrancesaMedLegal,
            caracteristicas,
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    classificacaoSocFrancesaMedLegal,
                    caracteristicas,
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _111_danoEsteticoClassSocFrancesaMedLegal: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {classificacaoSocFrancesaMedLegal,
                caracteristicas,
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _111_danoEsteticoClassSocFrancesaMedLegal: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _111_danoEsteticoClassSocFrancesaMedLegal: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaDanoEsteticoClassSocFrancesaMedLegal} />
                <label htmlFor={styles.checkboxBuscaDanoEsteticoClassSocFrancesaMedLegal}>
                    <h2 className={styles.titulo}>Busca Dano Estético da Class Soc Francesa Med Legal</h2>
                </label>
                <fieldset id={styles.conteudoBuscaDanoEsteticoClassSocFrancesaMedLegal}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite o texto relativo a busca do dano estético segundo Perez Garcia'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Classificação da Class Soc Francesa Med Legal</th>
                                                <th>Características</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        classificacaoSocFrancesaMedLegal,
                                                        caracteristicas,
                                                        
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{classificacaoSocFrancesaMedLegal}</th>
                                                            <th>{caracteristicas}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados da Class Soc Francesa Med Legal</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Classificação Perez Garcia</th>
                                                <th>Características</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                classificacaoSocFrancesaMedLegal,
                                                caracteristicas,
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{classificacaoSocFrancesaMedLegal}</th>
                                                    <th>{caracteristicas}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaDanoEsteticoPerezGarcia = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = DanoEsteticoPerezGarcia.filter(({
        classificacaoPerezGarcia,
        caracteristicas,

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return classificacaoPerezGarcia !== undefined &&
         caracteristicas !== undefined  ? 
         classificacaoPerezGarcia
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            caracteristicas
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)    
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            classificacaoPerezGarcia,
            caracteristicas,
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    classificacaoPerezGarcia,
                    caracteristicas,
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _112_danoEsteticoPerezGarcia: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {classificacaoPerezGarcia,
                caracteristicas,
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _112_danoEsteticoPerezGarcia: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _112_danoEsteticoPerezGarcia: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaDanoEsteticoPerezGarcia} />
                <label htmlFor={styles.checkboxBuscaDanoEsteticoPerezGarcia}>
                    <h2 className={styles.titulo}>Busca Dano Estético Perez Garcia</h2>
                </label>
                <fieldset id={styles.conteudoBuscaDanoEsteticoPerezGarcia}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite o texto relativo a busca do dano estético segundo Perez Garcia'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Classificação Perez Garcia</th>
                                                <th>Características</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        classificacaoPerezGarcia,
                                                        caracteristicas,
                                                        
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{classificacaoPerezGarcia}</th>
                                                            <th>{caracteristicas}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>CID´s selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Classificação Perez Garcia</th>
                                                <th>Características</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                classificacaoPerezGarcia,
                                                caracteristicas,
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{classificacaoPerezGarcia}</th>
                                                    <th>{caracteristicas}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaNtepListaC = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = NtepListaC.filter(({
        intervaloCid10,
        cnae,

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return intervaloCid10 !== undefined &&
         cnae !== undefined  ? 
         intervaloCid10
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            cnae
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)    
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            intervaloCid10,
            cnae,
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    intervaloCid10,
                    cnae,
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _114_ntepListaC: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {intervaloCid10,
                cnae,
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _114_ntepListaC: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _114_ntepListaC: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaNtepListaC} />
                <label htmlFor={styles.checkboxBuscaNtepListaC}>
                    <h2 className={styles.titulo}>Busca NTEP lista C</h2>
                </label>
                <fieldset id={styles.conteudoBuscaNtepListaC}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Busca NTEP lista C'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Intervalo CID 10</th>
                                                <th>Cnae</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        intervaloCid10,
                                                        cnae,
                                                        
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{intervaloCid10}</th>
                                                            <th>{cnae}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Intervalo CID 10</th>
                                                <th>Cnae</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                intervaloCid10,
                                                cnae,
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{intervaloCid10}</th>
                                                    <th>{cnae}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaTabelaDpvat = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = TabelaDpvat.filter(({
        danoCorporal,
        localizacao,
        percentualDaPerda,

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return danoCorporal !== undefined &&
         localizacao !== undefined && percentualDaPerda !== undefined ? 
         danoCorporal
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            localizacao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                percentualDaPerda
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) 
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            danoCorporal,
            localizacao,
            percentualDaPerda,
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    danoCorporal,
                    localizacao,
                    percentualDaPerda,
                })
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _114_ntepListaC: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {danoCorporal,
                localizacao,
                percentualDaPerda,
                }, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _114_ntepListaC: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _114_ntepListaC: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaTabelaDpvat} />
                <label htmlFor={styles.checkboxBuscaTabelaDpvat}>
                    <h2 className={styles.titulo}>Busca Tabela Dpvat</h2>
                </label>
                <fieldset id={styles.conteudoBuscaTabelaDpvat}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Busca NTEP lista C'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Dano Corporal</th>
                                                <th>Localização</th>
                                                <th>Percentual da Perda</th>    
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        danoCorporal,
                                                        localizacao,
                                                        percentualDaPerda,
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{danoCorporal}</th>
                                                            <th>{localizacao}</th>
                                                            <th>{percentualDaPerda}</th>
                                                           
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Dados selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Dano Corporal</th>
                                                <th>Localização</th>
                                                <th>Percentual da Perda</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                danoCorporal,
                                                localizacao,
                                                percentualDaPerda
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{danoCorporal}</th>
                                                            <th>{localizacao}</th>
                                                            <th>{percentualDaPerda}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}

export const UtilitariosBuscaValoracaoDanoMao = () => {

    const [data, setData] = useState([])
    
    const handleInputChange = (e) => {
        e.preventDefault()

        const { value } = e.target
        if (!value) setData([])
        setData(value)
    }

    const dadosFiltrados = ValoracaoDanoMao.filter(({
        mao,
        descricao,
        baremoEuropeu,
        susep,
        dpvat,
        baremoArgentino,
        baremoPortugues,
        louisMelennec,
        baremoEspanhol

    }) => {

        const regex = new RegExp(`${data}.*`, 'gi')

        if (data.length <= 2) return null
        return mao !== undefined && descricao !== undefined &&
         baremoEuropeu !== undefined && susep !== undefined &&
         dpvat !== undefined && baremoArgentino !== undefined &&
         baremoPortugues !== undefined && louisMelennec !== undefined && baremoEspanhol !== undefined ? 
                mao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
         descricao
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) || 
            baremoEuropeu
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                susep
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                dpvat
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoArgentino
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoPortugues
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                louisMelennec
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex) ||
                baremoEspanhol
                .replace(/[ÀÁÂÃÄÅ]/, "A")
                .replace(/[àáâãäå]/, "a")
                .replace(/[ÈÉÊË]/, "E")
                .replace(/[èéêë]/, "e")
                .replace(/[ÌÍÎÏ]/, "I")
                .replace(/[ìíîï]/, "i")
                .replace(/[ÒÓÔÕÖ]/, "O")
                .replace(/[òóôõö]/, "o")
                .replace(/[ÙÚÛÜ]/, "U")
                .replace(/[ùúûü]/, "u")
                .replace(/[Ç]/, "C")
                .replace(/[ç]/, "c")
                .match(regex)   
                : ''
    })


    const [dadoSelecionado, setDadoSelecionado] = useState([])

    const adicionar = (e) => {
        e.preventDefault()
    
        let dado = []
        dadosFiltrados.map(({
            mao,
            descricao,
            baremoEuropeu,
            susep,
            dpvat,
            baremoArgentino,
            baremoPortugues,
            louisMelennec,
            baremoEspanhol
    
        }, i) => {
            //console.log()
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

                dado.push({
                    mao,
                    descricao,
                    baremoEuropeu,
                    susep,
                    dpvat,
                    baremoArgentino,
                    baremoPortugues,
                    louisMelennec,
                    baremoEspanhol})
                setData([])
            }
            return null
        })
        setDadoSelecionado([...dadoSelecionado, ...dado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _111_valoracaoDanoMao: dadoSelecionado
            }))
    }

    const excluir = (e) => {

        e.preventDefault()

        dadoSelecionado.filter((
            {
                mao,
                descricao,
                baremoEuropeu,
                susep,
                dpvat,
                baremoArgentino,
                baremoPortugues,
                louisMelennec,
                baremoEspanhol}, i
        ) => {
            if (e.target.attributes !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                dadoSelecionado.splice(i, 1)
                setDadoSelecionado([...dadoSelecionado])
            }
            return null
        })

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _111_valoracaoDanoMao: dadoSelecionado
            }))
    }

    useEffect(() => {

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _111_valoracaoDanoMao: dadoSelecionado
            }))
    }, [
        dadoSelecionado
    ])

    return (
        <>
            <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxBuscaValoracaoDanoMao} />
                <label htmlFor={styles.checkboxBuscaValoracaoDanoMao}>
                    <h2 className={styles.titulo}>Busca Valoração do Dano na Mão</h2>
                </label>
                <fieldset id={styles.conteudoBuscaValoracaoDanoMao}>
                    <table className={styles.table}></table>
                    <div className={styles.container}>
                        <form>
                            <input name='search' id='search' onChange={handleInputChange} placeholder='Digite texto relativo a valoração do dano na mão'/>
                        </form>
                        {dadosFiltrados.length === 0 ? <fieldset>
                            <legend>Resultado da busca</legend>
                            <p>Sem resultado</p>
                        </fieldset> :
                            <fieldset>
                                <legend>Resultado da busca</legend>
                                <div className={styles.searchResults}>
                                    <fieldset>
                                        <legend>Valores Encontrados</legend>
                                        {dadosFiltrados.length === 0 ? <p>Sem dados registrados</p> :
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Ação</th>
                                                        <th>Mão</th>
                                                        <th>Descrição</th>
                                                <th>Baremo Europeu</th>
                                                        <th>Susep</th>
                                                        <th>Dpvat</th>
                                                        <th>Baremo Argentino</th>
                                                        <th>Baremo Portugues</th>
                                                        <th>Louis Melennec</th>
                                                        <th>Baremo Espanhol</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dadosFiltrados.length > 0 && dadosFiltrados.map(({
                                                        mao,
                                                        descricao,
                                                        baremoEuropeu,
                                                        susep,
                                                        dpvat,
                                                        baremoArgentino,
                                                        baremoPortugues,
                                                        louisMelennec,
                                                        baremoEspanhol
                                                    }, i) => {

                                                        return (
                                                        <tr key={i}>
                                                            <th><button onClick={adicionar}><i className="fas fa-check-circle confirma" value={i}></i></button></th>
                                                            <th>{mao}</th>
                                                            <th>{descricao}</th>
                                                            <th>{baremoEuropeu}</th>
                                                            <th>{susep}</th>
                                                            <th>{dpvat}</th>
                                                            <th>{baremoArgentino}</th>
                                                            <th>{baremoPortugues}</th>
                                                            <th>{louisMelennec}</th>
                                                            <th>{baremoEspanhol}</th>
                                                        </tr>
                                                        )})}
                                                </tbody>
                                            </table>
                                        }
                                    </fieldset>
                                </div>
                            </fieldset>
                        }
                        {dadoSelecionado.length === 0 ? <fieldset>
                            <legend>Resultado selecionado</legend>
                            <p>Nenhum</p>
                        </fieldset> :
                            <fieldset>
                                <legend>CID´s selecionados</legend>
                                <div className={styles.searchResults}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Ação</th>
                                                <th>Mão</th>
                                                <th>Descrição</th>
                                                <th>Baremo Europeu</th>
                                                        <th>Susep</th>
                                                        <th>Dpvat</th>
                                                        <th>Baremo Argentino</th>
                                                        <th>Baremo Portugues</th>
                                                        <th>Louis Melennec</th>
                                                        <th>Baremo Espanhol</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dadoSelecionado.length > 0 && dadoSelecionado.map(({
                                                mao,
                                                descricao,
                                                        baremoEuropeu,
                                                        susep,
                                                        dpvat,
                                                        baremoArgentino,
                                                        baremoPortugues,
                                                        louisMelennec,
                                                        baremoEspanhol
                                                    }, i) => {
                                                return (
                                                <tr key={i}>
                                                    <th><button onClick={excluir}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                                                    <th>{mao}</th>
                                                    <th>{descricao}</th>
                                                    <th>{baremoEuropeu}</th>
                                                    <th>{susep}</th>
                                                    <th>{dpvat}</th>
                                                    <th>{baremoArgentino}</th>
                                                    <th>{baremoPortugues}</th>
                                                    <th>{louisMelennec}</th>
                                                    <th>{baremoEspanhol}</th>
                                                </tr>
                                                )})}
                                        </tbody>
                                    </table>
                                </div>
                            </fieldset>
                        }
                    </div>
                </fieldset>
            </fieldset>
        </>
    )
}
