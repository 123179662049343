export const TabelaDpvat = [
  {
    "danoCorporal": "Total - Repercussao na integra do Patrimonio Fisico",
    "localizacao": "Perda anatomica e/ou funcional completa de ambos os membros superiores ou inferiores",
    "percentualDaPerda": "100%"
  },
  {
    "danoCorporal": "Total - Repercussao na integra do Patrimonio Fisico",
    "localizacao": "Perda anatomica e/ou funcional completa de ambas as maos ou de ambos os pes",
    "percentualDaPerda": "100%"
  },
  {
    "danoCorporal": "Total - Repercussao na integra do Patrimonio Fisico",
    "localizacao": "Perda anatomica e/ou funcional completa de um membro superior e de um membro inferior",
    "percentualDaPerda": "100%"
  },
  {
    "danoCorporal": "Total - Repercussao na integra do Patrimonio Fisico",
    "localizacao": "Perda completa da visao em ambos os olhos (cegueira bilateral) ou cegueira legal bilateral",
    "percentualDaPerda": "100%"
  },
  {
    "danoCorporal": "Total - Repercussao na integra do Patrimonio Fisico",
    "localizacao": "Lesões neurológicas que cursem com: (a)dano cognitivo-comportamental alienante; (b)impedimento do senso de orientacao espacial e/ou do livre deslocamento corporal; (c) perda completado controle esfincteriano; (d) comprometimento de funcao vital ou autonomica",
    "percentualDaPerda": "100%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda anatomica e/ou funcional completa de um dos membros superiores e/ou de uma das maos",
    "percentualDaPerda": "70%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda anatomica e/ou funcional completa de um dos membros inferiores",
    "percentualDaPerda": "70%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda anatomica e/ou funcional completa de um dos pes",
    "percentualDaPerda": "50%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda completa da mobilidade de um dos ombros, cotovelos, punhos ou dedo polegar",
    "percentualDaPerda": "25%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda completa da mobilidade de um quadril, joelho ou tornozelo",
    "percentualDaPerda": "25%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda anatomica e/ou funcional completa de qualquer um dentre os outros dedos da mao",
    "percentualDaPerda": "10%"
  },
  {
    "danoCorporal": "Parcial - Repercussao em Partes de Membros Superiores e Inferiores",
    "localizacao": "Perda anatomica e/ou funcional completa de qualquer um dos dedos do pe",
    "percentualDaPerda": "10%"
  },
  {
    "danoCorporal": "Parcial - Outras Repercussões em òrgaos e Estruturas Corporais",
    "localizacao": "Perda auditiva total bilateral (surdez completa) ou da fonacao (mudez completa) ou 50% da visao de um olho",
    "percentualDaPerda": "50%"
  },
  {
    "danoCorporal": "Parcial - Outras Repercussões em òrgaos e  Estruturas Corporais",
    "localizacao": "Perda completa da mobilidade de um segmento da coluna vertebral exceto o sacral",
    "percentualDaPerda": "25%"
  },
  {
    "danoCorporal": "Parcial - Outras Repercussões em òrgaos e  Estruturas Corporais",
    "localizacao": "Perda integral (retirada cirúrgica) do baco",
    "percentualDaPerda": "10%"
  }
]