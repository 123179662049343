import { Link } from "react-router-dom";

export const linksAdmin = [<Link to={'/Home/Admin/Admin1'}>Admin1</Link>,
    <Link to={'/Home/Admin/Admin2'}>Admin2</Link>,
    <Link to={'/Home/Admin/Admin3'}>Admin3</Link>,
    <Link to={'/Home/Admin/Admin4'}>Admin4</Link>,
    <Link to={'/Home/Admin/GravarDadosPerito'}>Maça</Link>,
    <Link to={'/Home/Admin/GravarDadosUsuario'}>Maça</Link>,]

export const linksDadosIniciais = [<Link to={"/Home/DadosIniciais/DadosIniciaisProcesso"}>DadosIniciaisProcesso </Link>,
    <Link to={"/Home/DadosIniciais/DadosPolo1"}>DadosPolo1 </Link>,
    <Link to={"/Home/DadosIniciais/BuscarApiCnpj"}>BuscarApiCnpj </Link>,
    <Link to={"/Home/DadosIniciais/DadosPolo2"}>DadosPolo2 </Link>,
    <Link to={"/Home/DadosIniciais/DocHonorarios"}>DocHonorarios </Link>,
    <Link to={"/Home/DadosIniciais/DocReclamada"}>DocReclamada </Link>,
    <Link to={"/Home/DadosIniciais/DocReclamante"}>DocReclamante</Link>,
    <Link to={"/Home/DadosIniciais/DocSecVara"}>DocSecVara</Link>,
    <Link to={"/Home/DadosIniciais/AgendamentoPericia"}>AgendamentoPericia</Link>,]

    export const linksDadosProcessuais = [
        <Link to={"/Home/DadosProcessuais/DadosInicial" }>DadosInicial </Link>,
              <Link to={"/Home/DadosProcessuais/DadosContestacao" }>DadosContestacao </Link>,
              <Link to={"/Home/DadosProcessuais/DadosAtestado" }>DadosAtestado </Link>,
              <Link to={"/Home/DadosProcessuais/DadosCat" }>DadosCat </Link>,
              <Link to={"/Home/DadosProcessuais/DadosInss" }>DadosInss </Link>,
              <Link to={"/Home/DadosProcessuais/DadosInssPericia" }>DadosInssPericia </Link>,
              <Link to={"/Home/DadosProcessuais/DadosDocSst" }>DadosDocSst</Link>,
              <Link to={"/Home/DadosProcessuais/DadosDocOutros" }>DadosDocOutros</Link>,
              <Link to={"/Home/DadosProcessuais/DadosTrabEmpresa" }>DadosTrabEmpresa</Link>,
              <Link to={"/Home/DadosProcessuais/DadosHistoricoLaboral" }>DadosHistoricoLaboral</Link>,
    ]

    export const linksRaciocinioTecnicoPericial = [
        <Link to={"/Home/RaciocinioTecnicoPericial/Ntep" }>Ntep </Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/NexoProfissional" }>NexoProfissional </Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/CriteriosSimonin" }>CriteriosSimonin </Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/CriteriosPenteado" }>CriteriosPenteado </Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/CriteriosConcausa" }>CriteriosConcausa </Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/CriteriosBradfordHill" }>CriteriosBradfordHill </Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/CriteriosCfm" }>CriteriosCfm</Link>,
              <Link to={"/Home/RaciocinioTecnicoPericial/CriteriosFranchini" }>CriteriosFranchini</Link>,
    ]

     export const linksValoracaoDanoCorporal = [
        <Link to={"/Home/ValoracaoDanoCorporal/ValoracaoDesnecessaria" }>ValoracaoDesnecessaria </Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/DeficitFuncionalTemporario" }>DeficitFuncionalTemporario </Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/DeficitFuncionalDefinitivo" }>DeficitFuncionalDefinitivo </Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/IncapacidadeLaboralTemporaria" }>IncapacidadeLaboralTemporaria </Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/QuantumDoloris" }>QuantumDoloris </Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/BaremoArgentinoQualitativo" }>BaremoArgentinoQualitativo </Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/ClassificacaoDanoEstetico" }>ClassificacaoDanoEstetico</Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/ClassificacaoDecreto3048" }>ClassificacaoDecreto3048</Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/ClassificacaoIncapacidadePenteado" }>ClassificacaoIncapacidadePenteado</Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/ClassificacaoWelitonBarbosaSantos" }>ClassificacaoWelitonBarbosaSantos</Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/NecessidadeAjudaTerceiros" }>NecessidadeAjudaTerceiros</Link>,
              <Link to={"/Home/ValoracaoDanoCorporal/RepercussaoAtividadesDesportivas" }>RepercussaoAtividadesDesportivas</Link>,
    ] 

    export const linksQuesitos = [
        <Link to={"/Home/Quesitos/QuesitosReclamada" }>QuesitosReclamada </Link>,
              <Link to={"/Home/Quesitos/QuesitosReclamante" }>QuesitosReclamante </Link>,
              <Link to={"/Home/Quesitos/QuesitosJuizo" }>QuesitosJuizo </Link>,
              <Link to={"/Home/Quesitos/QuesitosEsclarecimento" }>QuesitosEsclarecimento </Link>,
    ]

    export const linksConclusaoLaudo = [
<Link to={"/Home/ConclusaoLaudo" }>ConclusaoLaudo </Link>,
    ]

    export const linksNovoGeradorPdf = [
        <Link to={"/Home/NovoGeradorPdf" }>NovoGeradorPdf </Link>,
    ]

    export const linksDadosPerito = [
        <Link to={"/Home/BancoDadosPerito/BuscarApiEnd" }>BuscarApiEnd </Link>,
              <Link to={"/Home/BancoDadosPerito/DadosPerito" }>DadosPerito </Link>,
              <Link to={"/Home/BancoDadosPerito/DadosPeritoEndCom" }>DadosPeritoEndCom </Link>,
              <Link to={"/Home/BancoDadosPerito/DadosPeritoEndRes" }>DadosPeritoEndRes </Link>,
              <Link to={"/Home/BancoDadosPerito/DadosPeritoEspecialidade" }>DadosPeritoEspecialidade </Link>,
    ]