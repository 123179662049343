import React from "react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthGoogleContext } from "../../contexts/authGoogle";

import styles from "./Login.module.css";

export const Login = () => {
  const { entrarComGoogle, logado } = useContext(AuthGoogleContext);

  if (!logado) {
    return (
      <div className={styles.divLogin}>
        <img
          className={styles.imgLogin}
          src="google.png"
          alt="Entrar com o Google"
          onClick={entrarComGoogle}
        />
        <p className={styles.pLogin}>Da Vinci Tecnologia e Saúde</p>
      </div>
    );
  } else {
    return <Navigate to="/Home" />;
  }
};
