export const DanoEsteticoAipeCobo2 = [
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "classificacaoAipeCobo": "0/7 - Não há dano estético mensurável",
    "nivelEvidencia": "NAO SE VE OU PRATICAMENTE NAO SE VE",
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "classificacaoAipeCobo": "1/7 - Sem Relevancia",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "NAO TENDE A FIXAR O OLHAR",
    
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "pergunta3": "Quando nos lembramos da pessoa, a descreveríamos a partir do dano estético?",
    "classificacaoAipeCobo": "2/7 - Ligeiro",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "TENDE A FIXAR O OLHAR",
    "nivelRecordacao": "NAO SE LEMBRA DO DANO ESTETICO",
    
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "pergunta3": "Quando nos lembramos da pessoa, a descreveríamos a partir do dano estético?",
    "classificacaoAipeCobo": "3/7 - Moderado",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "TENDE A EVITAR O OLHAR",
    "nivelRecordacao": "NAO SE LEMBRA DO DANO ESTETICO",
    
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "pergunta3": "Quando nos lembramos da pessoa, a descreveríamos a partir do dano estético?",
    "pergunta4": "A pessoa lesada nos provoca algum tipo de emoção? (angustia, tristeza)",
    "classificacaoAipeCobo": "4/7 - Médio",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "TENDE A FIXAR O OLHAR; TENDE A EVITAR O OLHAR",
    "nivelRecordacao": "LEMBRA-SE DO DANO; A PESSOA É LEMBRADA APARTIR DO DANO",
    "nivelEmocao": "NAO PROVOCA RESPOSTA EMOCIONAL",
    
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "pergunta3": "Quando nos lembramos da pessoa, a descreveríamos a partir do dano estético?",
    "pergunta4": "A pessoa lesada nos provoca algum tipo de emoção? (angustia, tristeza)",
    "classificacaoAipeCobo": "5/7 - Importante",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "TENDE A FIXAR O OLHAR; TENDE A EVITAR O OLHAR",
    "nivelRecordacao": "LEMBRA-SE DO DANO; A PESSOA É LEMBRADA APARTIR DO DANO",
    "nivelEmocao": "PROVOCA LIGEIRA RESPOSTA EMOCIONAL",
    
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "pergunta3": "Quando nos lembramos da pessoa, a descreveríamos a partir do dano estético?",
    "pergunta4": "A pessoa lesada nos provoca algum tipo de emoção? (angustia, tristeza)",
    "pergunta5": "Relação Interpessoal: O dano estético é capaz de alterar uma relação interpessoal?",
    "classificacaoAipeCobo": "6/7 - Bastante Importante",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "TENDE A FIXAR O OLHAR; TENDE A EVITAR O OLHAR",
    "nivelRecordacao": "LEMBRA-SE DO DANO; A PESSOA É LEMBRADA APARTIR DO DANO",
    "nivelEmocao": "PROVOCA RESPOSTA EMOCIONAL INTENSA",
    "nivelRelacaoInterpessoal": "NAO",
    
  },
  {
    "pergunta1": "Até que ponto é 'vista' ou 'percebida' a alteração da imagem da pessoa?",
    "pergunta2": "Existe tendência para que nossos olhos fixem na alteração da imagem da pessoa?",
    "pergunta3": "Quando nos lembramos da pessoa, a descreveríamos a partir do dano estético?",
    "pergunta4": "A pessoa lesada nos provoca algum tipo de emoção? (angustia, tristeza)",
    "pergunta5": "Relação Interpessoal: O dano estético é capaz de alterar uma relação interpessoal?",
    "classificacaoAipeCobo": "7/7 - Muito Importante",
    "nivelEvidencia": " SE VE; SE VE CLARAMENTE",
    "nivelTendenciaOlhar": "TENDE A FIXAR O OLHAR; TENDE A EVITAR O OLHAR",
    "nivelRecordacao": "LEMBRA-SE DO DANO; A PESSOA É LEMBRADA APARTIR DO DANO",
    "nivelEmocao": "PROVOCA LIGEIRA RESPOSTA EMOCIONAL; PROVOCA RESPOSTA EMOCIONAL INTENSA",
    "nivelRelacaoInterpessoal": "SIM",
    
  }
]
