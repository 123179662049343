import React, {useState} from 'react'
import { UtilitariosBuscaBaremos, UtilitariosBuscaValoracaoDanoMao } from "../../utilitarios/UtilitariosBusca"
import styles from '../deficitFuncionalDefinitivo/DeficitFuncionalDefinitivo.module.css'

export const DeficitFuncionalDefinitivo = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ deficitFuncionalDefinitivo, setDeficitFuncionalDefinitivo] = useState([])
   
    const adicionarDeficitFuncionalDefinitivo = (e) => {

        e.preventDefault()

        const arrDeficitFuncionalDefinitivo = []
    
        arrDeficitFuncionalDefinitivo.push({
            perguntaDeficitFuncionalDefinitivo0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaDeficitFuncionalDefinitivo0: formValues.respostaDeficitFuncionalDefinitivo0 !== undefined ? formValues.respostaDeficitFuncionalDefinitivo0 : '',
           comentarioDeficitFuncionalDefinitivo0: formValues.comentarioDeficitFuncionalDefinitivo0 !== undefined ? formValues.comentarioDeficitFuncionalDefinitivo0 : '',     
        })
        setDeficitFuncionalDefinitivo([...arrDeficitFuncionalDefinitivo])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _68_deficitFuncionalDefinitivo: [{
                perguntaDeficitFuncionalDefinitivo0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaDeficitFuncionalDefinitivo0: formValues.respostaDeficitFuncionalDefinitivo0 !== undefined ? formValues.respostaDeficitFuncionalDefinitivo0 : '',
           comentarioDeficitFuncionalDefinitivo0: formValues.comentarioDeficitFuncionalDefinitivo0 !== undefined ? formValues.comentarioDeficitFuncionalDefinitivo0 : '',
            }]
      }))
    }

    const excluirDeficitFuncionalDefinitivo = (e) => {
        e.preventDefault()  

        setDeficitFuncionalDefinitivo([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _68_deficitFuncionalDefinitivo: []
              }))
    }

    const handleCarregarFormDeficitFuncionalDefinitivo = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._68_deficitFuncionalDefinitivo && tudo._68_deficitFuncionalDefinitivo.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._68_deficitFuncionalDefinitivo !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._68_deficitFuncionalDefinitivo !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._68_deficitFuncionalDefinitivo : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaDeficitFuncionalDefinitivo0: e !== undefined && e.perguntaDeficitFuncionalDefinitivo0 !== undefined ? e.perguntaDeficitFuncionalDefinitivo0 : '',
            respostaDeficitFuncionalDefinitivo0: e !== undefined &&  e.respostaDeficitFuncionalDefinitivo0 !== undefined ? e.respostaDeficitFuncionalDefinitivo0 : '',
            comentarioDeficitFuncionalDefinitivo0: e !== undefined &&  e.comentarioDeficitFuncionalDefinitivo0 !== undefined ? e.comentarioDeficitFuncionalDefinitivo0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparDeficitFuncionalDefinitivo = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaDeficitFuncionalDefinitivo'+e.target.attributes.value.value]: '', ['comentarioDeficitFuncionalDefinitivo'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxDeficitFuncionalDefinitivo} />
            <label htmlFor={styles.checkboxDeficitFuncionalDefinitivo}>
                <h2 className={styles.titulo}>Déficit Funcional Definitivo</h2>
            </label>
            <fieldset id={styles.conteudoDeficitFuncionalDefinitivo}>  
            <fieldset>
                <legend>Utilitários Baremos</legend>
                <>
                <UtilitariosBuscaBaremos/>
                <UtilitariosBuscaValoracaoDanoMao/>
                </>
            </fieldset> 
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="Pelas tabelas nacionais e pelos Baremos Internacionais incluindo as tabelas de graduação da redução e tabelas do Baremo Internacional de Invalideces (Valoración de las discapacidades y del daño corporal.) de Louis Mélennec, Baremos propostos pela comunidade europeia, em especial o Português e Espanhol e Guides to the Evaluation of Permanent Impairment da American Medical Association, podemos fazer um cálculo aproximado de:"> 
                        <th><button onClick={handleLimparDeficitFuncionalDefinitivo}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>Pelas tabelas nacionais e pelos Baremos Internacionais incluindo as tabelas de graduação da redução e tabelas do Baremo Internacional de Invalideces (Valoración de las discapacidades y del daño corporal.) de Louis Mélennec, Baremos propostos pela comunidade europeia, em especial o Português e Espanhol e Guides to the Evaluation of Permanent Impairment da American Medical Association, podemos fazer um cálculo aproximado de:</th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="respostaDeficitFuncionalDefinitivo0"
                                placeholder="Comentários do exame do ombro"
                                onChange={handleInputChange}
                                value={formValues.respostaDeficitFuncionalDefinitivo0 !== undefined ? formValues.respostaDeficitFuncionalDefinitivo0 : ''}
                            />
                        </th>
                        <th>
                        <textarea 
                            name="comentarioDeficitFuncionalDefinitivo0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite sua valoração aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioDeficitFuncionalDefinitivo0 !== undefined ? formValues.comentarioDeficitFuncionalDefinitivo0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarDeficitFuncionalDefinitivo}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormDeficitFuncionalDefinitivo}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados de déficit funcional definitivo</legend>
            {
            deficitFuncionalDefinitivo.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            <th>Pergunta</th>
                            <th>Resposta</th>
                          <th>Comentário</th> 
                        </tr>
                    </thead>
                        {deficitFuncionalDefinitivo.length > 0 && deficitFuncionalDefinitivo.map(({ 
                            perguntaDeficitFuncionalDefinitivo0,
                            respostaDeficitFuncionalDefinitivo0,
                           comentarioDeficitFuncionalDefinitivo0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaDeficitFuncionalDefinitivo0}</th>
                                <th>{respostaDeficitFuncionalDefinitivo0}</th>
                               <th>{comentarioDeficitFuncionalDefinitivo0}</th>
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirDeficitFuncionalDefinitivo}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        ) 
}
