import React, { useRef, useState } from "react";

export const useFvRef = () => {
  const [formValues, setFormValues] = useState({});
  const [data, setData] = useState([]);
  const { ls, convertData } = useGeneral();

  const handleInput = (e = HTMLInputElement) => {
    const { name, value, type } = e.target;

    const data = inputsRef[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...inputsRef, [name]: newValue });
    console.log(
      "***data",
      data,
      "***inputsRef",
      inputsRef,
      "***newValue",
      newValue,
      "***type",
      type,
      "***value",
      value
    );
  };

  //const { ls, setData } = useGeneral();
  /* const [data, setData] = useState([]); */

  const refId = useRef();
  const refResposta = useRef();
  const refDataEntregaAmericano = useRef();
  const refHora = useRef();
  const inputsRef = useRef({});

  /* const handleInput = (e) => {
    const { value, type } = e.target;
    console.log("***type", type, "***value", value);
  };  */

  /* const handleBlur = (e) => {
    const { value, name } = e.target;
    inputsRef.current[name] = value
    console.log("***name", name, "***value", value, '***iputsref', inputsRef, '***current',inputsRef.current);
  } */

  const handleSubmit = () => {
    const { esclarecimentos, generalKey } = ls;
    const { numero, nomeItem } = esclarecimentos;
    console.log(/* '***iputsref', inputsRef,  */ "***current", refId.current);
    if (
      formValues.dataEntregaAmericano !== undefined &&
      formValues.dataEntregaAmericano.length === 10
    ) {
      formValues.dataEntrega = convertData(formValues.dataEntregaAmericano);
    }
    const arr = [];
    arr.push({
      id: refId.current && refId.current.value ? refId.current.value : "",
    });
    /* arr.push({
      id: refId.current && refId.current.value ? refId.current.value : "",
      resposta:
        refResposta.current && refResposta.current.value
          ? refResposta.current.value
          : "",
      dataEntregaAmericano:
        refDataEntregaAmericano.current &&
        refDataEntregaAmericano.current.value &&
        refDataEntregaAmericano.current.value.length === 10
          ? refDataEntregaAmericano.current.value
          : "",
      horaEntrega:
        refHora.current && refHora.current.value ? refHora.current.value : "",
      dataEntrega:
        refDataEntregaAmericano.current &&
        refDataEntregaAmericano.current.value &&
        refDataEntregaAmericano.current.value.length === 10
          ? `${refDataEntregaAmericano.current.value.split("-")[2]}-${
              refDataEntregaAmericano.current.value.split("-")[1]
            }-${refDataEntregaAmericano.current.value.split("-")[0]}`
          : "",
    });
 */
    localStorage.setItem(
      generalKey,
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem(generalKey)) || []),
        [numero.concat(nomeItem)]: [...arr],
      })
    );

    //console.log(refDataEntregaAmericano.current.value.length);
    /*  console.log('***id',refId.current, refId.current.name, refId.current.value)
  console.log('***resposta',refResposta.current, refResposta.current.name, refResposta.current.value)
  console.log('***dataEntregaAmericano',refDataEntregaAmericano.current, refDataEntregaAmericano.current.name, refDataEntregaAmericano.current.value)
  console.log('***horaEntrega',refHora.current, refHora.current.name, refHora.current.value) */
    setData([...arr]);
  };

  const handleClearAllFields = () => {
    refId.current.value = "";
    /* refResposta.current.value = "";
    refDataEntregaAmericano.current.value = "";
    refHora.current.value = ""; */
    // inputsRef.current.value = ''
  };

  const handleClearField = (e = HTMLInputElement) => {
    e.preventDefault();
    //const {name} = e.target
    const valueButton = e.target.value;
    const valueIcon = e.target.attributes.value.value;

    /*  if (valueButton && valueButton === inputsRef.current[name])
      inputsRef.current[name].value = "";
    if (valueIcon && valueIcon === inputsRef.current[name])
      inputsRef.current[name].value = ""; */
    if (valueButton && valueButton === refId.current.name)
      refId.current.value = "";
    if (valueIcon && valueIcon === refId.current.name) refId.current.value = "";
    /* if (valueButton && valueButton === refResposta.current.name)
      refResposta.current.value = "";
    if (valueIcon && valueIcon === refResposta.current.name)
      refResposta.current.value = "";
    if (valueButton && valueButton === refDataEntregaAmericano.current.name)
      refDataEntregaAmericano.current.value = "";
    if (valueIcon && valueIcon === refDataEntregaAmericano.current.name)
      refDataEntregaAmericano.current.value = "";
    if (valueButton && valueButton === refHora.current.name)
      refHora.current.value = "";
    if (valueIcon && valueIcon === refHora.current.name)
      refHora.current.value = ""; */
  };

  const handleEdit = () => {
    const { esclarecimentos, generalKey } = ls;
    const { numero, nomeItem } = esclarecimentos;

    const getLs = JSON.parse(localStorage.getItem(generalKey));
    console.log(getLs);
    if (getLs && getLs !== null) {
      const getLsFull = getLs[numero.concat(nomeItem)];
      try {
        if (getLsFull && getLsFull !== null && getLsFull.length > 0) {
          const a =
            getLsFull !== undefined || getLsFull !== null ? getLsFull : [];
          a.map((e) => {
            return (
              //inputsRef.current.value = e
              (refId.current.value = e.id)
              /* (refResposta.current.value = e.resposta),
              (refDataEntregaAmericano.current.value = e.dataEntregaAmericano),
              (refHora.current.value = e.horaEntrega) */
            );
          });
          a.map((e) => setData([e]));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteData = (e = HTMLInputElement) => {
    const { esclarecimentos, generalKey } = ls;
    const { numero, nomeItem } = esclarecimentos;
    e.preventDefault();

    const valueButton = e.target.value;
    const valueIcon = e.target.attributes.value.value;

    try {
      data &&
        data.filter((_, i) => {
          if (
            (valueButton !== undefined && valueButton === String(i)) ||
            (valueIcon !== undefined && valueIcon === String(i))
          ) {
            console.log(data);
            data.splice(i, 1);
            setData([...data]);

            localStorage.setItem(
              generalKey,
              JSON.stringify({
                ...(JSON.parse(localStorage.getItem(generalKey)) || []),
                [numero.concat(nomeItem)]: [...data],
              })
            );
          }
          //setShowTable(false)
          return null;
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAllData = (e = HTMLInputElement) => {
    const { esclarecimentos, generalKey } = ls;
    const { numero, nomeItem } = esclarecimentos;
    e.preventDefault();

    try {
      setData([]);
      localStorage.setItem(
        generalKey,
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem(generalKey)) || []),
          [numero.concat(nomeItem)]: [],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    data,
    setData,
    formValues,
    handleInput,
    handleClearAllFields,
    handleClearField,
    handleSubmit,
    handleEdit,
    handleDeleteData,
    handleDeleteAllData,
    refId,
    refDataEntregaAmericano,
    refResposta,
    refHora
    //inputsRef,
    //handleBlur
  );
};

export const useFv = () => {
  const { ls, convertData } = useGeneral();
  const { generalKey, esclarecimentos } = ls;
  const { nomeItem, numero } = esclarecimentos;
  //const { esclarecimentosPropsValues } = inputPropsValues;
  const [formValues, setFormValues] = useState({});
  const [data, setData] = useState([]);

  const handleInput = (e = HTMLInputElement) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log(
      "***data",
      data,
      "***formValues",
      formValues,
      "***newValue",
      newValue
    );
  };

  const handleClearAllFields = () => {
    setFormValues({});
  };

  const handleClearField = (e = HTMLInputElement) => {
    e.preventDefault();

    /* const {name, type, value} = e.target */
    const valueButton = e.target.value;
    const valueIcon = e.target.attributes.value.value;

    console.log("***button", valueButton, "***icon", valueIcon);
    valueButton && setFormValues({ ...formValues, [valueButton]: "" });
    valueIcon && setFormValues({ ...formValues, [valueIcon]: "" });
  };

  const handleSubmit = () => {
    if (
      formValues.dataEntregaAmericano !== undefined &&
      formValues.dataEntregaAmericano.length === 10
    ) {
      formValues.dataEntrega = convertData(formValues.dataEntregaAmericano);
    }

    const arr = [];

    arr.push(formValues);
    setData([...arr]);

    localStorage.setItem(
      generalKey,
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem(generalKey)) || []),
        [numero.concat(nomeItem)]: [...arr],
      })
    );
    // console.log("***fv submit", formValues, "***arr", arr);
  };

  const handleEdit = () => {
    const getLs = JSON.parse(localStorage.getItem(generalKey));
    console.log("***getLs", getLs);
    if (getLs && getLs !== null) {
      const getLsFull = getLs[numero.concat(nomeItem)];
      console.log("***getLsFull", getLsFull);
      try {
        if (getLsFull && getLsFull !== null && getLsFull.length > 0) {
          const a =
            getLsFull !== undefined || getLsFull !== null ? getLsFull : [];
          a.map((e) => setFormValues(e));
          a.map((e) => setData([e]));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteData = (e = HTMLInputElement) => {
    e.preventDefault();

    const valueButton = e.target.value;
    const valueIcon = e.target.attributes.value.value;

    try {
      data &&
        data.filter((e, i) => {
          //console.log('***delete data',Object.keys(e))
          if (
            (valueButton !== undefined &&
              valueButton === /* Object.keys(e) */ String(i)) ||
            (valueIcon !== undefined &&
              valueIcon === /* Object.keys(e) */ String(i))
          ) {
            console.log(data);
            data.splice(i, 1);
            setData([...data]);

            localStorage.setItem(
              generalKey,
              JSON.stringify({
                ...(JSON.parse(localStorage.getItem(generalKey)) || []),
                [numero.concat(nomeItem)]: [...data],
              })
            );
          }
          //setShowTable(false)
          return null;
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAllData = (e = HTMLInputElement) => {
    e.preventDefault();
    //console.log(data)
    try {
      setData([
        /* esclarecimentosPropsValues */
      ]);
      localStorage.setItem(
        generalKey,
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem(generalKey)) || []),
          [numero.concat(nomeItem)]: [],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return {
    data,
    setData,
    formValues,
    handleInput,
    handleClearAllFields,
    handleClearField,
    handleSubmit,
    handleEdit,
    handleDeleteData,
    handleDeleteAllData,
  };
};

export const useGeneral = () => {
  //const [showTable, setShowTable] = useState(false);
  const inputProps = {
    esclarecimentos: {
      id: {
        type: "text",
        placeholder: "Digite a id e página...",
        nome: "id",
        autoComplete: "on",
        required: "",
        title:
          "É importante preencher esse valor para facilitar a buscar no processo. ",
      },
      resposta: {
        type: "textarea",
        placeholder: "Digite resposta...",
        nome: "resposta",
        cols: "30",
        rows: "10",
        autoComplete: "off",
        required: "",
        title: "Resposta dos quesitos, de forma sucinta. ",
      },
      dataEntregaAmericano: {
        type: "date",
        nome: "dataEntregaAmericano",
        min: "1900-01-01",
        max: "9999-12-31",
        title:
          "Por favor, insira uma data válida entre 01-01-1900 a 31-12-9999 ",
      },
      hora: {
        type: "time",
        texto: "Hora",
        nome: "hora",
        size: "4",
        maxLength: "4",
        title: "Por favor, insira uma hora no formato 24hs HH:MM. ",
      },
      busca: {
        type: "search",
        texto: "Busca",
        placeholder: "Pesquise aqui...",
        nome: "search",
        autoComplete: "on",
        required: "",
        pattern: "[^'\"]+",
        title:
          "Utilize ao menos 3 caracteres para a API do sistema fazer a busca.",
      },
      email: {
        type: "email",
        texto: "Email",
        placeholder: "digite seu email...",
        nome: "email",
        autoComplete: "on",
        required: "",
        pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
        title: "Necessário um email válido.",
      },
      password: {
        type: "password",
        texto: "Password",
        placeholder: "digite seu password...",
        nome: "password",
        autoComplete: "off",
        required: "",
        pattern: "(?=.*)(?=.*[a-z])(?=.*[a-z]).{8,}",
        title:
          "Deve conter ao menos uma letra maiuscula, uma minuscula e ter pelo menos 8 caracteres.",
      },
      file: {
        type: "file",
        texto: "Arquivo",
        placeholder: "Adicone o arquivo...",
        nome: "file",
        title:
          "Por favor, insira um arquivo .jpg ou png., menor que 100kb; outros formatos nao serão aceitos.",
      },
      image: {
        type: "image",
        texto: "Imagem",
        placeholder: "Adicone a foto...",
        nome: "image",
        alt: "",
        src: "",
        title: "Por favor, insira uma imagem apenas no formato .jpg ",
      },
      number: {
        type: "number",
        texto: "Numero",
        placeholder: "Números...",
        nome: "number",
        title: "Apenas números ",
      },
      range: {
        type: "range",
        texto: "Range",
        placeholder: "Escolha o range...",
        nome: "range",
        min: "0",
        max: "10",
        step: "0.5",
        title: "Escolha o range da escala de dor...",
        list: [
          { value: "0", label: "0" },
          { value: "0.5", label: "0.5" },
          { value: "1.0", label: "1.0" },
          { value: "1.5", label: "1.5" },
          { value: "2.0", label: "2.0" },
          { value: "2.5", label: "2.5" },
          { value: "3.0", label: "3.0" },
          { value: "3.5", label: "3.5" },
          { value: "4.0", label: "4.0" },
          { value: "4.5", label: "4.5" },
          { value: "5.0", label: "5.0" },
          { value: "5.5", label: "5.5" },
          { value: "6.0", label: "6.0" },
          { value: "6.5", label: "6.5" },
          { value: "7.0", label: "7.0" },
          { value: "7.5", label: "7.5" },
          { value: "8.0", label: "8.0" },
          { value: "8.5", label: "8.5" },
          { value: "9.0", label: "9.0" },
          { value: "9.5", label: "9.5" },
          { value: "10.0", label: "10.0" },
        ],
      },
      tel: {
        type: "tel",
        texto: "Telefone",
        placeholder: "Escolha o tel...",
        nome: "tel",
        title: "Formato (xx)xxxxx-xxxx",
      },
      url: {
        type: "url",
        texto: "Endereço da Url",
        placeholder: "Escolha a url...",
        nome: "url",
        title: "title url",
      },
      select: {
        type: "select",
        texto: "Cor favorita:",
        nome: "corFavorita",
        title: "Selecione sua cor favorita",
        options: [
          { value: "", label: "Selecione uma opção" },
          { value: "vermelho", label: "Vermelho" },
          { value: "verde", label: "Verde" },
          { value: "azul", label: "Azul" },
        ],
      },
      radio: {
        type: "radio",
        texto: "Gênero",
        nome: "genero",
        title: "Selecione seu gênero",
        options: [
          { value: "masculino", label: "Masculino" },
          { value: "feminino", label: "Feminino" },
          { value: "outro", label: "Outro" },
        ],
      },
      checkbox: {
        type: "checkbox",
        texto: "Interesses",
        nome: "interesses",
        title: "Defina seus interesses...",
        options: [
          { value: "programacao", label: "Programação" },
          { value: "leitura", label: "Leitura" },
          { value: "esportes", label: "Esportes" },
        ],
      },
    },
  };

  const inputPropsValues = {
    esclarecimentos: Object.values(inputProps.esclarecimentos),
  };

  const headerValues = {
    esclarecimentos: {
      id: "Id",
      resposta: "Resposta",
      dataEntrega: "Data da Entrega",
      hora: "Hora",
    },
  };

  const ls = {
    generalKey: "_pericianova",
    esclarecimentos: {
      numero: "_000",
      nomeItem: "_esclarecimentos",
    },
  };

  const convertData = (americanData = {}) => {
    try {
      if (americanData !== undefined && americanData.length === 10) {
        const [ano, mes, dia] = americanData.split("-");
        //console.log('**dia',dia,'**mes',mes,'**ano',ano)
        return `${dia}-${mes}-${ano}`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    inputProps,
    inputPropsValues,
    headerValues,
    ls,
    convertData /* showTable, setShowTable */,
  };
};

const Icon = ({ className = "", value }) => {
  return <i className={className} value={value}></i>;
};

const CustomInput = ({
  isSubmit,
  isEdit,
  isClearField,
  isClearAllFields,
  isDeleteData,
  isDeleteAllData,
  value = "",
  type,
  placeholder,
  nome,
  options,
  rows = "30",
  cols = "10",
  checked,
  checkedvalues,
  checkedvalue,
  data,
  /* min,
  max,
  step, */
  list,

  ...rest
}) => {
  try {
    /* const [doc, setDoc] = useState([options]);
    const handleChange = (e) => {
      const { name, checked } = e.target;
  
      if (name === "allSelected") {
        const tempDoc = doc.map((e) => {
          return { ...e, isChecked: checked };
        });
        setDoc(tempDoc);
      } else {
        const tempDoc = doc.map((e) => {
          return e.name === name ? { ...e, isChecked: checked } : e;
        });
        setDoc(tempDoc);
      }
      const dadoSelecionado = [];

    doc.map(({ name, isChecked }) => {
      if (isChecked === true) {
        dadoSelecionado.push(name);
      }
      return null;
    });

    if (dadoSelecionado) {
      setDoc(dadoSelecionado);
    }
    console.log('***dadoselecionad',dadoSelecionado)
  } */
    const buttonIsSubmit = isSubmit && (
      <button type="button" value={value} {...rest}>
        {<Icon className="fas fa-plus " value={value} {...rest} /> ||
          "Adicionar"}
      </button>
    );

    const buttonIsEdit = isEdit && (
      <button type="button" value={value} {...rest}>
        <Icon className="" value={value} {...rest} /> Editar
      </button>
    );

    const buttonIsClearField = isClearField && (
      <button type="button" value={value} {...rest}>
        {<Icon className="fas fa-trash" value={value} {...rest} /> || "Limpar"}
      </button>
    );

    const buttonIsClearAllFields = isClearAllFields && (
      <button type="button" value={value} {...rest}>
        {<Icon className="fas fa-trash" value={value} {...rest} /> || "Limpar"}
      </button>
    );

    const buttonIsDeleteData = isDeleteData && (
      <button type="button" value={value} {...rest}>
        {<Icon className="fas fa-trash" value={value} {...rest} /> || "Limpar"}
      </button>
    );

    const buttonIsDeleteAllData = isDeleteAllData && (
      <button type="button" value={value} {...rest}>
        {<Icon className="fas fa-trash" value={value} {...rest} /> || "Limpar"}
      </button>
    );

    const typeSelect = type === "select" && (
      <select id={nome} name={nome} {...rest}>
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    );

    const typeRange = type === "range" && (
      <>
        <input type="range" name={nome} value={value} {...rest} list={list} />
        <datalist id={list}>
          {list.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </datalist>
      </>
    );

    const typeTextArea = type === "textarea" && (
      <textarea
        id={nome}
        name={nome}
        placeholder={placeholder}
        value={value}
        rows={rows}
        cols={cols}
        {...rest}
      />
    );

    const typeRadio = type === "radio" && (
      <label>
        <>
          {options.map((option) => (
            <div key={option.value}>
              <input
                type="radio"
                id={`${nome}-${option.value}`}
                name={nome}
                options={options}
                value={option.value}
                defaultChecked={checkedvalue === option.value}
                {...rest}
              />
              <label htmlFor={`${nome}-${option.value}`}>{option.label}</label>
            </div>
          ))}
        </>
      </label>
    );

    const typeCheckBox = type === "checkbox" && (
      <label>
        <>
          {options.map((option) => (
            <div key={option.value}>
              <input
                type="checkbox"
                id={`${nome}-${option.value}`}
                name={nome}
                options={options}
                value={option.value}
                checkedvalues={option.value}
                {...rest}
              />
              <label htmlFor={`${nome}-${option.value}`}>{option.label}</label>
            </div>
          ))}
        </>
      </label>
    );

    const typeStandard = (
      <input
        className="custom-input"
        type={type}
        placeholder={placeholder}
        name={nome}
        value={value}
        {...rest}
      />
    );

    return (
      buttonIsSubmit ||
      buttonIsEdit ||
      buttonIsClearField ||
      buttonIsClearAllFields ||
      buttonIsDeleteData ||
      buttonIsDeleteAllData ||
      typeSelect ||
      typeRange ||
      typeTextArea ||
      typeRadio ||
      typeCheckBox ||
      typeStandard
    );
  } catch (error) {
    console.log(error);
  }
};

const Forms = ({ fv, clearField, handleInput, ref, values }) => {
  try {
    //console.log('***values',values);
    const formularios = Object.values(values).map((e, i) => {
      return (
        <tr key={i}>
          <td>
            <CustomInput
              isClearField
              onClick={clearField}
              title="Apaga apenas o campo ao lado."
              value={e.nome}
            />
          </td>
          <td>
            <CustomInput
              onChange={handleInput}
              value={fv[e.nome] && fv[e.nome]}
              {...e}
            />
          </td>
        </tr>
      );
    });

    const comFormularios = (
      <fieldset>
        <legend>Formulários</legend>
        <table>
          <thead>
            <tr>
              <th>Limpar Campo</th>
              <th>Informação</th>
            </tr>
          </thead>
          <tbody>{formularios}</tbody>
        </table>
      </fieldset>
    );

    const semFormularios = (
      <fieldset>
        <legend>Formulários</legend>
        <p>Ocorreu algum erro no sistema.</p>
      </fieldset>
    );

    return formularios.length > 0 ? comFormularios : semFormularios;
  } catch (error) {
    console.log(error);
  }
};

const DataResult = ({
  data,
  deleteData,
  headerTableDataResult,
  legendTableWithData,
  titleCustomInput,
  valueCustomInput,
  legendTableWithoutData,
  messageWithoutData,
}) => {
  try {
    //console.log('***dataresult data',data)

    const header = headerTableDataResult || [];
    const dataRow =
      data.length > 0 && (data[0] /* (data?.[0] && data?.[0]) */ || []); // Se data[0] for undefined ou vazio, dataRow será um array vazio
    /* console.log(
       '***header',header, "***datarow",
      dataRow
    ); */

    const RenderTableHeader = () =>
      Object.keys(header).map((key) => <th>{header[key]}</th>);
    const RenderTableRow = () =>
      Object.keys(header).map((key) => <td>{dataRow[key] || "-"}</td>);
    const hasData = Object.values(dataRow).length > 0;
    const RootTableWithData = ({
      childrenTableHeader,
      childrenTableRow,
      childrenButton,
    }) => {
      return (
        <>
          <fieldset>
            <legend>{legendTableWithData}</legend>
            <table>
              <thead>
                <tr>{childrenTableHeader}</tr>
              </thead>
              <tbody>
                <tr>{childrenTableRow}</tr>
              </tbody>
            </table>
            {childrenButton}
          </fieldset>
        </>
      );
    };

    const MyTableWithData = () => {
      return (
        <RootTableWithData
          childrenTableHeader={<RenderTableHeader />}
          childrenTableRow={<RenderTableRow />}
          childrenButton={
            <CustomInput
              isDeleteData
              onClick={deleteData}
              title={titleCustomInput}
              value={valueCustomInput}
            />
          }
        />
      );
    };

    const MyTableWithoutData = ({ legend, message }) => {
      return (
        <>
          <fieldset>
            <legend>{legendTableWithoutData}</legend>
            <p>{messageWithoutData}</p>
          </fieldset>
        </>
      );
    };

    return (hasData && <MyTableWithData />) || <MyTableWithoutData />;
  } catch (error) {
    console.log(error);
  }
};

const Actions = ({ submit, edit, clearAllFields }) => {
  try {
    return (
      <fieldset>
        <legend>Ações</legend>
        <table>
          <thead>
            <tr>
              <th>Adicionar</th>
              <th>Editar</th>
              <th>Limpar campos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <CustomInput
                  isSubmit
                  onClick={submit}
                  title="Adiciona os dados."
                />
              </th>
              <th>
                <CustomInput isEdit onClick={edit} title="Modo edição." />
              </th>
              <th>
                <CustomInput
                  isClearAllFields
                  onClick={clearAllFields}
                  title="Reseta os campos dos formulários."
                />
              </th>
            </tr>
          </tbody>
        </table>
      </fieldset>
    );
  } catch (error) {
    console.log(error);
  }
};

const DeleteAllData = ({ deleteAllData }) => {
  try {
    return (
      <fieldset>
        <legend>Ação</legend>
        <table>
          <thead>
            <tr>
              <th>Excluir todos os documentos?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <CustomInput
                  isDeleteAllData
                  onClick={deleteAllData}
                  title="Deleta todos os dados."
                />
              </th>
            </tr>
          </tbody>
        </table>
      </fieldset>
    );
  } catch (error) {
    console.log(error);
  }
};

const Root = ({ nome, children }) => {
  return (
    <fieldset>
      <legend>{nome}</legend>
      <div>{children}</div>
    </fieldset>
  );
};

export const Admin4 = () => {
  const { inputPropsValues, headerValues } = useGeneral();

  const {
    data,
    ref,
    formValues,
    handleInput,
    handleClearField,
    handleClearAllFields,
    handleSubmit,
    handleEdit,
    handleDeleteData,
    handleDeleteAllData,
  } = useFv();

  /* const {
    data,
  inputsRef,
  handleBlur,
    formValues,
    handleInput,
    handleClearField,
    handleClearAllFields,
    handleSubmit,
    handleEdit,
    handleDeleteData,
    handleDeleteAllData,
    refId

  } = useFvRef();  */

  try {
    console.log("***data", data);

    return (
      <>
        <Root nome={"Experimento"}>
          <Forms
            values={inputPropsValues.esclarecimentos}
            fv={formValues}
            handleInput={handleInput}
            clearField={handleClearField}
            ref={ref}
          />
          <Actions
            submit={handleSubmit}
            edit={handleEdit}
            clearAllFields={handleClearAllFields}
          />
          <DataResult
            data={data}
            deleteData={handleDeleteData}
            headerTableDataResult={headerValues.esclarecimentos}
            legendTableWithData={"tem dados"}
            legendTableWithoutData={"nao tem dados"}
            titleCustomInput={"titulo do botao"}
            valueCustomInput={"0"}
            messageWithoutData={"Não tem nada aqui."}
          />
          <DeleteAllData deleteAllData={handleDeleteAllData} />
        </Root>
      </>
    );
  } catch (error) {
    console.log(error);
  }
};
