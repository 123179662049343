import React, { useContext } from "react";
import { AuthGoogleContext } from "../../contexts/authGoogle";
import { TipoUsuarioContexto } from "../../contexts/tipoUsuarioContexto";
import styles from "./DadosUsuario.module.css";

export const DadosUsuario = () => {
  const { sairComGoogle } = useContext(AuthGoogleContext);
  const { usuario, statusPanel, memberButton } =
    useContext(TipoUsuarioContexto);

  return (
    <div className={styles.div}>
      <img className={styles.img} src={usuario.photoURL} alt="foto do usuário" />
      <div>
        {statusPanel}
        {memberButton}
      </div>
      <button onClick={sairComGoogle}>
        <i className="fas fa-trash perigo"></i>
      </button>
    </div>
  );
};
