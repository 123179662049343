import React, {useEffect, useState} from 'react'

export const DadosPolo2 = () => {
    const [formValues, setFormValues] = useState({})
  
      const handleInputChange = (e) => {
          const { name, value, type } = e.target;
      
          const data = formValues[name] || {};
          const isCheckbox = type === 'checkbox'
          const newValue = isCheckbox  ? data : value;
          
          setFormValues({...formValues,  [name]: newValue  });
          //console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
      }
  
      const [ polo2, setPolo2] = useState([])
     
      const adicionarPolo2 = (e) => {
  
          e.preventDefault()
  
          const tudo = JSON.parse(localStorage.getItem('tudo')) || {}
          let getDadosApiCnpj = {}
          if (tudo._99_dadosApiCnpj) {
            getDadosApiCnpj = JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj /* || {} */
          } else {
            localStorage.setItem(
              "tudo",
              JSON.stringify({
                ...(JSON.parse(localStorage.getItem("tudo")) || []),
                _99_dadosApiCnpj: {}
              
              })
            )
            getDadosApiCnpj = JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj
          }
      
      //console.log(getDadosApiCnpj)
      if(getDadosApiCnpj.cnpj && getDadosApiCnpj.razaoSocial && getDadosApiCnpj.cnaeFiscal && getDadosApiCnpj.cnaeFiscalDescricao&& getDadosApiCnpj.tipoPolo2) {
      //console.log(getDadosApiCnpj)
        return ( setPolo2([{
              cnpj: getDadosApiCnpj.cnpj !== '' && getDadosApiCnpj.cnpj !==undefined ? getDadosApiCnpj.cnpj: formValues.cnpj.trim()
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                            .replace(/(\d{4})(\d)/, "$1-$2")
                            .replace(/(-\d{2})\d+$/, "$1") ,
              razaoSocial: getDadosApiCnpj.razaoSocial !== '' && getDadosApiCnpj.razaoSocial !==undefined ? getDadosApiCnpj.razaoSocial: formValues.razaoSocial ,
              cnaeFiscal: getDadosApiCnpj.cnaeFiscal !== '' && getDadosApiCnpj.cnaeFiscal !==undefined ? getDadosApiCnpj.cnaeFiscal: formValues.cnaeFiscal ,
              cnaeFiscalDescricao: getDadosApiCnpj.cnaeFiscalDescricao !== ''&& getDadosApiCnpj.cnaeFiscalDescricao !==undefined ? getDadosApiCnpj.cnaeFiscalDescricao: formValues.cnaeFiscalDescricao ,
              tipoPolo2: getDadosApiCnpj.tipoPolo2 !== '' && getDadosApiCnpj.tipoPolo2 !==undefined ? getDadosApiCnpj.tipoPolo2: formValues.tipoPolo2 ,
        }]),
        
        localStorage.setItem('tudo', JSON.stringify({
          ...JSON.parse(localStorage.getItem('tudo')) || [],
          _03_polo2: [{
              cnpj: getDadosApiCnpj.cnpj !== '' && getDadosApiCnpj.cnpj !==undefined ? getDadosApiCnpj.cnpj: formValues.cnpj.trim()
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                            .replace(/(\d{4})(\d)/, "$1-$2")
                            .replace(/(-\d{2})\d+$/, "$1") ,
              razaoSocial: getDadosApiCnpj.razaoSocial !== '' && getDadosApiCnpj.razaoSocial !==undefined ? getDadosApiCnpj.razaoSocial: formValues.razaoSocial ,
              cnaeFiscal: getDadosApiCnpj.cnaeFiscal !== '' && getDadosApiCnpj.cnaeFiscal !==undefined ? getDadosApiCnpj.cnaeFiscal: formValues.cnaeFiscal ,
              cnaeFiscalDescricao: getDadosApiCnpj.cnaeFiscalDescricao !== '' && getDadosApiCnpj.cnaeFiscalDescricao !==undefined ? getDadosApiCnpj.cnaeFiscalDescricao: formValues.cnaeFiscalDescricao ,
              tipoPolo2: getDadosApiCnpj.tipoPolo2 !== '' && getDadosApiCnpj.tipoPolo2 !==undefined ? getDadosApiCnpj.tipoPolo2: formValues.tipoPolo2 ,
          }]
        })))
      
      } else {
        return (setPolo2([{
          cnpj: formValues.cnpj !== undefined && formValues.cnpj.length === 14 ? formValues.cnpj.trim()
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                            .replace(/(\d{4})(\d)/, "$1-$2")
                            .replace(/(-\d{2})\d+$/, "$1") : 'Cnpj inválido!',
              razaoSocial: formValues.razaoSocial !== '' && formValues.razaoSocial !==undefined ? formValues.razaoSocial : getDadosApiCnpj.razaoSocial ,
              cnaeFiscal: formValues.cnaeFiscal !== '' && formValues.cnaeFiscal !==undefined ? formValues.cnaeFiscal : getDadosApiCnpj.cnaeFiscal ,
              cnaeFiscalDescricao: formValues.cnaeFiscalDescricao !== '' && formValues.cnaeFiscalDescricao !==undefined ? formValues.cnaeFiscalDescricao : getDadosApiCnpj.cnaeFiscalDescricao ,
              tipoPolo2: formValues.tipoPolo2 !== '' && formValues.tipoPolo2 !==undefined ? formValues.tipoPolo2 : getDadosApiCnpj.tipoPolo2 ,
        }]),
        
        localStorage.setItem('tudo', JSON.stringify({
          ...JSON.parse(localStorage.getItem('tudo')) || [],
          _03_polo2: [{
              cnpj: formValues.cnpj !== undefined && formValues.cnpj.length === 14 ? formValues.cnpj.trim()
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                            .replace(/(\d{4})(\d)/, "$1-$2")
                            .replace(/(-\d{2})\d+$/, "$1") : '',
              razaoSocial: formValues.razaoSocial !== '' && formValues.razaoSocial !==undefined ? formValues.razaoSocial: polo2.razaoSocial ,
              cnaeFiscal: formValues.cnaeFiscal !== '' && formValues.cnaeFiscal !==undefined ? formValues.cnaeFiscal: polo2.cnaeFiscal ,
              cnaeFiscalDescricao: formValues.cnaeFiscalDescricao !== '' && formValues.cnaeFiscalDescricao !==undefined ? formValues.cnaeFiscalDescricao: polo2.cnaeFiscalDescricao ,
              tipoPolo2: formValues.tipoPolo2 !== '' && formValues.tipoPolo2 !==undefined ? formValues.tipoPolo2: polo2.tipoPolo2 ,
          }]
        }))
        )
      }
      }
  
      const excluirPolo2 = (e) => {
        e.preventDefault();
        polo2.filter((el, i) => {
          if (
            e.target.attributes.value.value !== undefined &&
            e.target.attributes.value.value === String(i)
          ) {
            polo2.splice(i, 1);
            setPolo2([...polo2]);
    
            localStorage.setItem(
              "tudo",
              JSON.stringify({
                ...(JSON.parse(localStorage.getItem("tudo")) || []),
                _03_polo2: [...polo2],
              })
            );
          }
          return null;
        });
      
      }
  
      const excluirTudoPolo2 = (e) => {
        e.preventDefault();
       
          setPolo2([])
  
          localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _03_polo2: []
            })
          );
      }
  
      const handleCarregarFormPolo2 = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._03_polo2 && tudo._03_polo2.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._03_polo2 : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              cnpj: e !== undefined && e.cnpj !== undefined && e.cnpj !== ""
            ? e.cnpj
            : "",
            
            razaoSocial: e !== undefined && e.razaoSocial !== undefined && e.razaoSocial !== ''
            ? e.razaoSocial
            : "",
            
            cnaeFiscal: e !== undefined && e.cnaeFiscal !== undefined && e.cnaeFiscal !== ""
            ? e.cnaeFiscal
            : "",
  
            cnaeFiscalDescricao: e !== undefined && e.cnaeFiscalDescricao !== undefined && e.cnaeFiscalDescricao !== ""
            ? e.cnaeFiscalDescricao
            : "",
  
            tipoPolo2: e !== undefined && e.tipoPolo2 !== undefined && e.tipoPolo2 !== ""
            ? e.tipoPolo2
            : "", 
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._03_polo2 && tudo._03_polo2.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._03_polo2 : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setPolo2([{
              cnpj: e !== undefined && e.cnpj !== undefined && e.cnpj !== ""
            ? e.cnpj
            : "",
            
            razaoSocial: e !== undefined && e.razaoSocial !== undefined && e.razaoSocial !== ''
            ? e.razaoSocial
            : "",
            
            cnaeFiscal: e !== undefined && e.cnaeFiscal !== undefined && e.cnaeFiscal !== ""
            ? e.cnaeFiscal
            : "",
  
            cnaeFiscalDescricao: e !== undefined && e.cnaeFiscalDescricao !== undefined && e.cnaeFiscalDescricao !== ""
            ? e.cnaeFiscalDescricao
            : "",
  
            tipoPolo2: e !== undefined && e.tipoPolo2 !== undefined && e.tipoPolo2 !== ""
            ? e.tipoPolo2
            : "", 
        }])
      )  
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }
  
      useEffect(() => {
  
        if (formValues.cnpj !== undefined) {
          //console.log(formValues.cnpj.length)
          formValues.cnpj = formValues.cnpj.replace(/\D/g, "")
    
          if (formValues.cnpj.length === 14) {
            formValues.cnpj = formValues.cnpj
              .replace(/\D/g, "")
          }
            //console.log(formValues.cnpj.length)  
        }
      }, [polo2, formValues])
  
  
      return (
      <>
      <fieldset>
      <legend>Polo Passivo</legend>
        <div >
        <fieldset >
          <table >
              <thead>
                  <tr>
                    <th>Limpar Campo</th>
                    <th>Informação</th>
                  </tr>
                </thead>
              </table>
              <table >
                  <tbody>
                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'cnpj'}></i></button></th>
                          <th>
                          <input
                type="text"
                name="cnpj"
                size="18"
                minLength="18"
                maxLength="18"
                placeholder="Digite o CNPJ "
                onChange={handleInputChange}
                value={
                  formValues.cnpj !== undefined
                    ? formValues.cnpj
                        .trim()
                        .replace(/\D/g, "")
                        .replace(/(\d{2})(\d)/, "$1.$2")
                        .replace(/(\d{3})(\d)/, "$1.$2")
                        .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                        .replace(/(\d{4})(\d)/, "$1-$2")
                        .replace(/(-\d{2})\d+$/, "$1")
                    : ""
                }
              />
                          </th> 
                      </tr>


                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'tipoPolo2'}></i></button></th>
                          <th>
                          <div className="radios">
              <span>Tipo do Polo</span>
              <label>
                <input
                  type="radio"
                  value="Polo ativo"
                  name="tipoPolo2"
                  onChange={handleInputChange}
                  checked={formValues.tipoPolo2 !== undefined ? formValues.tipoPolo2 === "Polo ativo" : ''}
                />
                Polo ativo
              </label>
              <label>
                <input
                  type="radio"
                  value="Polo Passivo"
                  name="tipoPolo2"
                  onChange={handleInputChange}
                  checked={formValues.tipoPolo2 !== undefined ? formValues.tipoPolo2 === "Polo Passivo" : ''}
                />
                Polo passivo
              </label>
            </div>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'razaoSocial'}></i></button></th>
                          <th>
                          <input
                type="text"
                name="razaoSocial"
                size="30"
                minLength="0"
                maxLength="30"
                placeholder="Razão Social"
                onChange={handleInputChange} 
                value={formValues.razaoSocial !== undefined ? formValues.razaoSocial : ''}
              />
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'cnaeFiscal'}></i></button></th>
                          <th>
                          <input
                type="text"
                name="cnaeFiscal"
                size="30"
                minLength="0"
                maxLength="30"
                placeholder="Cnae Fiscal"
                onChange={handleInputChange}
                value={formValues.cnaeFiscal !== undefined ? formValues.cnaeFiscal.replace(/\D/g, "") : ''}
              />
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'cnaeFiscalDescricao'}></i></button></th>
                          <th>
                          <input
                type="text"
                name="cnaeFiscalDescricao"
                size="30"
                minLength="0"
                maxLength="30"
                placeholder="Descricao do Cnae"
                onChange={handleInputChange}
                value={formValues.cnaeFiscalDescricao !== undefined ? formValues.cnaeFiscalDescricao : ''}
              />
                          </th> 
                      </tr>


                      </tbody>
              </table>
              
              <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarPolo2}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormPolo2}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>


          <fieldset>
              <legend>Dados registrados do Polo Passivo</legend>
              {
              polo2.length === 0 ? <p>Sem dados registrados</p> : 
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                          {polo2.length > 0 && polo2.map(({ 
                              
                              cnpj,
                              tipoPolo2,
                razaoSocial,
                cnaeFiscal,
                cnaeFiscalDescricao
                              
                          }, i)=>{
  
                          return(
                          
                            <tbody key={i}>
                            <tr >
                              <th>Registro número</th>
                              <th> {i + 1}</th>
                            </tr>
                               
                              <tr >
                                
                                <th>Cnpj</th>
                                  <th> {cnpj}</th>
                              </tr>

                              <tr >
                                
                                <th>Tipo polo</th>
                                  <th> {tipoPolo2}</th>
                              </tr>

                              <tr >
                                
                                <th>Razão social</th>
                                  <th> {razaoSocial}</th>
                              </tr>

                              <tr >
                                
                                <th>CNAE fiscal</th>
                                  <th> {cnaeFiscal}</th>
                              </tr>

                              <tr >
                                
                                <th>CNAE descrição</th>
                                  <th> {cnaeFiscalDescricao}</th>
                              </tr>

                              <tr>
                            <th>Ação</th>
                            <th >
                              <button onClick={excluirPolo2}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoPolo2}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoPolo2"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
        </fieldset>
        </div>
      </fieldset>
    </>
  );
};
