import React, { useState } from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
/* import { useState } from 'react' */
import styles from '../geradorPdf/NovoGeradorPdf.module.css'

export const NovoGeradorPdf = () => {

    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const dataAtual = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'full'}).format(new Date())
    let tudo = JSON.parse(localStorage.getItem('tudo')) || []  
     
const dadosIniciaisPdf = () => {
    let y = []
    if (tudo._01_dadosIniciais && tudo._01_dadosIniciais.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._01_dadosIniciais !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._01_dadosIniciais !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._01_dadosIniciais : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.numProcesso !== undefined ? e.numProcesso : '',
 e.tipoProcesso !== undefined ? e.tipoProcesso : '',
/*  e.dataEntregaDadosIniciais0 !== undefined ? e.dataEntregaDadosIniciais0 : '',
 e.horaEntregaDadosIniciais0 !== undefined ? e.horaEntregaDadosIniciais0 : '', */

            ]) : y.push(
 ['','',/* '','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Dados Iniciais do Processo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','40%',/* '15%','10%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Número do Processo', style: 'cabecalhoTabela'},
                    {text: 'Tipo', style: 'cabecalhoTabela'},
                    /* {text: 'Data', style: 'cabecalhoTabela'},
                    {text: 'Hora', style: 'cabecalhoTabela'}, */
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]],/* [y[0][2]],[y[0][3]] */] : ['','',/* '','' */],
            ],
             }, 
    },
 ]
 }
        
}

 const dadosPolo1Pdf = () => {

    let y = []
    if (tudo._02_polo1 && tudo._02_polo1.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._02_polo1 : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
                 e.nomePolo1 !== undefined && e.nomePolo1 !== ""
                ? e.nomePolo1
                : "",
                
                 e.cpfPolo1 !== undefined && e.cpfPolo1.length === 14
                ? e.cpfPolo1
                : "",
                
                 e.generoPolo1 !== undefined && e.generoPolo1 !== ""
                ? e.generoPolo1
                : "",
      
                 e.tipoPolo1 !== undefined && e.tipoPolo1 !== ""
                ? e.tipoPolo1
                : "",
      
                 /* e.dataNascimentoAmericanoPolo1 !== undefined &&
                e.dataNascimentoAmericanoPolo1.length === 10
                  ? e.dataNascimentoAmericanoPolo1
                  : "", */
      
                e.dataNascimentoPolo1 !== undefined &&
                e.dataNascimentoPolo1.length === 10 &&
                e.dataNascimentoPolo1 !== "01-01-0001" &&
                e.dataNascimentoAmericanoPolo1 !== ""
                  ? e.dataNascimentoPolo1
                  : "",
      
                e.idadePolo1 !== undefined && e.idadePolo1 !== "" ? e.idadePolo1 : "",
      
                 e.estadoCivilPolo1 !== undefined && e.estadoCivilPolo1 !== ""
                ? e.estadoCivilPolo1
                : "",
      
                  e.estadoCivilComentarioPolo1 !== undefined &&
                e.estadoCivilComentarioPolo1 !== ""
                  ? e.estadoCivilComentarioPolo1
                  : "",
      
                 e.filhosPolo1 !== undefined && e.filhosPolo1 !== ""
                ? e.filhosPolo1
                : "",
      
                 e.filhosComentarioPolo1 &&
                (e.filhosComentarioPolo1 !== "") !== undefined
                  ? e.filhosComentarioPolo1
                  : "",
      
                 e.escolaridadePolo1 !== undefined && e.escolaridadePolo1 !== ""
                ? e.escolaridadePolo1
                : "",
      
                 e.escolaridadeComentarioPolo1 &&
                (e.escolaridadeComentarioPolo1 !== "") !== undefined
                  ? e.escolaridadeComentarioPolo1
                  : "",
      
                 e.dominanciaPolo1 !== undefined && e.dominanciaPolo1 !== ""
                ? e.dominanciaPolo1
                : "",
      
                 e.habitosPolo1 !== undefined && e.habitosPolo1 !== ""
                ? e.habitosPolo1
                : "",
      
                 e.habitosComentarioPolo1 !== undefined &&
                e.habitosComentarioPolo1 !== ""
                  ? e.habitosComentarioPolo1
                  : "",

            ]) : y.push(
 ['','','','','','','','','','','','','','',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Polo Ativo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','40%',/* '15%','10%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Nome', style: 'cabecalhoTabela'},
                    {text: 'CPF', style: 'cabecalhoTabela'},
                    /* {text: 'Data', style: 'cabecalhoTabela'},
                    {text: 'Hora', style: 'cabecalhoTabela'}, */
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]],/* [y[0][2]],[y[0][3]] */] : ['','',/* '','' */],
            ],
             }, 
    },
 ]
 }

}

 const dadosPolo2Pdf = () => {

    const y = []
    
    if (tudo._03_polo2) {
            const a = JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._03_polo2 : []

            //console.log('***',typeof(a), a)
        a.map((e)=>   e !== undefined ? y.push( [
        e.razaoSocial !== undefined ? e.razaoSocial : '',
        e.cnpj !== undefined ? e.cnpj : '',
        e.cnaeFiscal !== undefined ? e.cnaeFiscal : '',
        e.cnaeFiscalDescricao !== undefined ? e.cnaeFiscalDescricao : '',
        ]) : y.push(['','','','',]) )   

    //console.log(y[0][0])

    return [
    {text: '\n'}, 
    {
        text: 'Polo Passivo',
        style: 'header',
        alignment:'center',
    }, 
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','40%'/* , '15%','30%' */ ],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Razão social', style: 'cabecalhoTabela'},
                    {text: 'Cnpj', style: 'cabecalhoTabela'},
                    /*  {text: 'Cnae', style: 'cabecalhoTabela'},
                    {text: 'Descrição', style: 'cabecalhoTabela'}, */ 
                ],
                //DADOS DA TABELA EM SI
                //y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3]] : ['','','',''],

                [
                    y.length > 0 && y[0][0] !== ''  ? [[y[0][0]]] : [''],
                y.length > 0 && y[0][1] !== ''  ? [[y[0][1]]] : [''],
                
                
                /*  y.length > 0 && y[0][2] !== ''  ? [[y[0][2]]] : [''],
                y.length > 0 && y[0][3] !== ''  ? [[y[0][3]]] : [''],  */
                ]
                       
            ]
        }, 
    },]
    }

}

const dadosAgendamentoPdf = () => {

    const y = []
    if (tudo._08_agendamento/*  !== undefined && tudo._08_agendamento !== null */) {
        const a = JSON.parse(localStorage.getItem('tudo'))._08_agendamento !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._08_agendamento !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._08_agendamento : []

     //  console.log('***',a)
    /* JSON.parse(localStorage.getItem('tudo'))._11_atestado */a.map((
/* {
    idAtestado,
    cid,
    inicioAtestado, 
    profissionalAssistente, 
    qtdeDiasAfastadoAtestado
} */e)=> 
e !== undefined ? y.push( [
    e.dataAgendamento !== undefined ? e.dataAgendamento : '',
    e.diaSem !== undefined ? e.diaSem : '',
    e.hora !== undefined ? e.hora : '',
    e.local !== undefined || e.local !== null || e.local !== '' ? e.local.map((
        /* {codigo, descricao} */o)=> o !== undefined ? [o.logradouro, o.bairro,o.numero, o.complemento,o.cidade, o.estado,o.cep,] : ['','','','','','','',]) : '',
    
    ]) : y.push(['','','','',])
)} else {
    y.push(['','','','',])
}
//console.log(y[0][3])

return [
    {text: '\n'},       
        {
            text: 'Venho marcar perícia referente ao processo acima. Ficam desde já autorizados por ambas as partes (Autor e Réu) todos os procedimentos relativos ao artigo 429 do CPC sendo que qualquer disposição em contrário deve ser feita por escrito e antes da realização da perícia.\n',
            style: 'subheader',
            alignment:'justify',
        },
        {
            text: '\n', 
        },
        {
            layout: 'headerLineOnly',
            alignment:'center',
            table: {
                heights: function () {
                    return 15
                },
                headerRows: 1,
                widths: ['*','*','*',/* '*' */],
                alignment:'justify',
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Data da Perícia', style: 'cabecalhoTabela'},
                        {text: 'Dia da Semana', style: 'cabecalhoTabela'},
                        {text: 'Hora', style: 'cabecalhoTabela'},
                       // {text: 'Hora', style: 'cabecalhoTabela'},
                    ],
                    
                //DADOS DA TABELA EM SI
                //...y
                /* // SO APARECE UMA OPCÃO!! */ y.length > 0 ? [y[0][0],y[0][1],y[0][2],/* y[0][3], *//* y[0][4] */] : ['','','',/* '', *//* '' */],
            ]
        }, 
    },
    {text: '\n'},
    {
        text: '\n', 
    },
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['auto','*','*','*','*','*','auto' ],
            alignment:'justify',
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Logradouro', style: 'cabecalhoTabela'},
                    {text: 'Número', style: 'cabecalhoTabela'},
                    {text: 'Complemento', style: 'cabecalhoTabela'},
                    {text: 'Bairro', style: 'cabecalhoTabela'},
                    {text: 'Cidade', style: 'cabecalhoTabela'},
                    {text: 'Estado', style: 'cabecalhoTabela'},
                    {text: 'Cep', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                ...y[0][3]
               // [
                 // SO APARECE UMA OPCÃO!! */ y.length > 0 ? [y[0][3][0],y[0][3][1],y[0][3][2],y[0][3][3],y[0][3][4],y[0][3][5],y[0][3][6]] : ['','','','','','','' ],
               // ],
                
            ]
        }, 
    },
    {text: '\n'},

]

}

const docHonorariosPdf = () => {
        
        return [
            {text: '\n\n'},
            {
            layout: 'headerLineOnly',
            alignment:'center',
            table: {
                heights: function () {
                    return 15
                },
                headerRows: 1,
                widths: ['*',],
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Pedidos referentes a honorários', style: 'cabecalhoTabela'},
                    ],
                    //DADOS DA TABELA EM SI
                    tudo._04_docHonorarios !== undefined && [tudo._04_docHonorarios.map((e)=>e)][0].length > 0 ? [tudo._04_docHonorarios.map((e)=>`\n${e}`)] : ['']
                ]
            }, 
            }
        ]
}

const docReclamadaPdf = () => {

        return [
            {text: '\n\n'},
            {
            layout: 'headerLineOnly',
            alignment:'center',
            table: {
                heights: function () {
                    return 15
                },
                headerRows: 1,
                widths: ['*',],
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Documentos solicitados à reclamada', style: 'cabecalhoTabela'},
                    ],
                    //DADOS DA TABELA EM SI
                    tudo._05_docReclamada !== undefined && [tudo._05_docReclamada.map((e)=>e)][0].length > 0 ? [tudo._05_docReclamada.map((e)=>`\n${e}`)] : ['']
                ]
            }, 
            }]
}

const docReclamantePdf = () => {

        return [
            {text: '\n\n'},
            {
            layout: 'headerLineOnly',
            alignment:'center',
            table: {
                heights: function () {
                    return 15
                },
                headerRows: 1,
                widths: ['*',],
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Documentos solicitados ao reclamante', style: 'cabecalhoTabela'},
                    ],
                    //DADOS DA TABELA EM SI
                    tudo._06_docReclamante !== undefined && [tudo._06_docReclamante.map((e)=>e)][0].length > 0 ? [tudo._06_docReclamante.map((e)=>`\n${e}`)] : ['']
                ]
            }, 
            }]
}

const docSecVaraPdf = () => {

    //console.log([tudo._07_docSecVara.map((e)=>`\n${e}`)])

        return [
            {text: '\n\n'},
            {
            layout: 'headerLineOnly',
            alignment:'center',
            table: {
                heights: function () {
                    return 15
                },
                headerRows: 1,
                widths: ['*',],
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Pedidos direcionados à secretaria da vara', style: 'cabecalhoTabela'},
                    ],
                    //DADOS DA TABELA EM SI
                    tudo._07_docSecVara !== undefined && [tudo._07_docSecVara.map((e)=>e)][0].length > 0 ? [tudo._07_docSecVara.map((e)=>`\n${e}`)] : ['']
                ]
            }, 
            }]
}

const checkDataAtual = () => {
    if(dataAtual) {
        return [
            {text: '\n\n\n\n'},
            {
            text:  `${dataAtual[0].toUpperCase()+dataAtual.substring(1)}\n`,
            style: 'center',
            alignment:'center',
            } 
        ]     
    }
}

const checkDadosPerito = () => {
        
    return {
        text: [
            tudo._81_dadosPerito !== undefined && tudo._81_dadosPerito.nome !== undefined ? `\n${tudo._81_dadosPerito.nome}` : '',
            tudo._81_dadosPerito !== undefined && tudo._81_dadosPerito.estadoCrm !== undefined ? `\n CRM-${tudo._81_dadosPerito.estadoCrm}` : '',
            tudo._81_dadosPerito !== undefined && tudo._81_dadosPerito.numCrm !== undefined ? `${tudo._81_dadosPerito.numCrm}` : '',
        ],
        style: 'center',
        alignment:'center',
    }



}

const checkDadosPeritoEspecialidade = () => {
    
    //console.log([...tudo._82_dadosPeritoEspecialidade.map((e)=>`\n${e.especialidade}`)] )

    // FORMA PARA MOSTRAR ARRAY QUE POSSUA OUTROS OBJETOS...É DIFERENTE DOS ACIMA!!! TALVEZ SEJA PORQUE É TEXTO E NAO TABELA!!
    return { 
            text:  tudo._82_dadosPeritoEspecialidade !== undefined && [tudo._82_dadosPeritoEspecialidade.map(({especialidade})=>`\n${especialidade}`)][0].length > 0 ? [...tudo._82_dadosPeritoEspecialidade.map(({especialidade})=>`\n${especialidade}`)] : [''],
            style: 'center',
            alignment:'center',  
        } 
}

const pdfAgendamentoPericia = () => {
    
    tudo = JSON.parse(localStorage.getItem('tudo')) || []
    
        var dd = {
    
            //ORIENTACAO DA PAGINA
            pageOrientation: 'portrait',
    
            //TAMANHO DA PAGINA
            pageSize: 'A4',
    
            //MANIPULACAO DASMARGENS
            pageMargins: [40, 60, 40, 60], 
    
            //FONTES
            fonts: {

                /* Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajadadosProcessuais/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                }, */
                
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-MediumItalic.ttf'
                }
            },
    
            //DEFINIÇÃO DO CONTEÚDO DO CABEÇALHO E RODAPÉ DA PÁGINA
            footer: function (currentPage, pageCount) {
                return currentPage.toString() + ' de ' + pageCount +'       Powered by Da Vinci Tecnologia e Saude'
            },
            header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return [{
                        text: tudo._01_dadosIniciais !== undefined && tudo._01_dadosIniciais.numProcesso !== undefined ? tudo._01_dadosIniciais.numProcesso : '',
                        alignment: (currentPage % 2) ? 'left' : 'right'
                    },
                    {
                        canvas: [{
                            type: 'rect',
                            x: 170,
                            y: 32,
                            w: pageSize.width - 170,
                            h: 40
                        }]
                    }
                ]
            },
    
            //ESTILOS
    
            //PADRAO
            defaultStyle: {
                font: 'Roboto',
                fontSize: 10,
                bold: false,
                alignment: 'justify',
            },
            
            //ESPECIFICOS
            styles: {
                header: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    alingment:'justify',
                },
                table: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'black'
                },
                cabecalhoTabela: {
                    bold:true,
                    fillOpacity: 0.15,
                    fillColor: 'blue',
                    overlayPattern: ['stripe45d', 'gray'],
                    overlayOpacity: 0.15,
                },
                /*subheader: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'justify'
                },*/
                ol: {
                    fontSize: 10,
                    bold: false,
                    alignment: 'justify'
                },
                quote: {
                    italics: true
                },
                small: {
                    fontSize: 8,
                    alignment: 'center'
                },
                medio: {
                    fontSize: 10,
                    alignment: 'justify',
                },
                center: {
                    alingment: 'center',
                    fontSize: 10,
                }
            },
    
            //CONTEÚDO DO PDF
            content: [
    
            {
                qr: 'https://pericias.app',
                fit: '50'
            },
    
    /*
            // INDICE
            {
                toc: {
                    id: 'mainToc',
                    title: {
                        text: 'Ìndice',
                        style: 'header'
                    }
                }
            },
            {
                    text: 'Texto simples',
                    style: 'header',
                    tocItem: 'mainToc'
            },
    
    
            //LINKS
            {
                text: 'Ir para a página 1',
                linkToPage: 1
            },
            {
                text: 'Go to Header',
                linkToDestination: 'header'
            },
    */
            // TEXTOS E TABELAS
                
                dadosIniciaisPdf(),
                dadosPolo1Pdf(),
                dadosPolo2Pdf(),
                dadosAgendamentoPdf(),

                docHonorariosPdf(),
                docReclamantePdf(),
                docReclamadaPdf(),
                docSecVaraPdf(),
                
                checkDataAtual(),
                checkDadosPerito(),
                checkDadosPeritoEspecialidade(),

                /* {
                    text: 'Powered by Da Vinci Tecnologia e Saude',
                    style: ['quote', 'small']
                }, */
                /* dadosProcessuaisPdf() */     
            ],
        }
    
        // CRIA UMA NOVA ABA COM O  DOCUMENTO
    
        //pdfMake.createPdf(dd, tableLayouts, fonts/*, vfs*/).open()
    
        // PARA MOSTRAR NA TELA
        /* const pdfDocGenerator = pdfMake.createPdf(dd)
        pdfDocGenerator.getDataUrl((dataUrl) => {
            const targetElement = document.querySelector('#iframeContainerRefatorado');
            const iframe = document.createElement('iframe');
            iframe.src = dataUrl;
            targetElement.appendChild(iframe);
        }) */

        /* pdfMake.createPdf(dd).open() */
        //pdfMake.createPdf(dd/* , tableLayouts, fonts, vfs */).print()
        pdfMake.createPdf(dd).getDataUrl((url)=> setUrl(url))
}

const imprimir = () => {

    tudo = JSON.parse(localStorage.getItem('tudo')) || [] 

        const docDefinitions = {
    
            //ORIENTACAO DA PAGINA
            pageOrientation: 'portrait',
    
            //TAMANHO DA PAGINA
            pageSize: 'A4',
    
            //MANIPULACAO DASMARGENS
            pageMargins: [40, 60, 40, 60], 
    
            //FONTES
            fonts: {

                /* Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                }, */

                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-MediumItalic.ttf'
                }
            },
    
            //DEFINIÇÃO DO CONTEÚDO DO CABEÇALHO E RODAPÉ DA PÁGINA
            footer: function (currentPage, pageCount) {
                return currentPage.toString() + ' de ' + pageCount +'       Powered by Da Vinci Tecnologia e Saude'
            },
            header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                return [{
                        text: tudo._01_dadosIniciais !== undefined && tudo._01_dadosIniciais.numProcesso !== undefined ? tudo._01_dadosIniciais.numProcesso : '',
                        alignment: (currentPage % 2) ? 'left' : 'right'
                    },
                    {
                        canvas: [{
                            type: 'rect',
                            x: 170,
                            y: 32,
                            w: pageSize.width - 170,
                            h: 40
                        }]
                    }
                ]
            },
    
            //ESTILOS
    
            //PADRAO
            defaultStyle: {
                font: 'Roboto',
                fontSize: 10,
                bold: false,
                alignment: 'justify',
            },
            
            //ESPECIFICOS
            styles: {
                header: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    alingment:'justify',
                },
                table: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'black'
                },
                cabecalhoTabela: {
                    bold:true,
                    fillOpacity: 0.15,
                    fillColor: 'blue',
                    overlayPattern: ['stripe45d', 'gray'],
                    overlayOpacity: 0.15,
                },
                /*subheader: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'justify'
                },*/
                ol: {
                    fontSize: 10,
                    bold: false,
                    alignment: 'justify'
                },
                quote: {
                    italics: true
                },
                small: {
                    fontSize: 8,
                    alignment: 'center'
                },
                medio: {
                    fontSize: 10,
                    alignment: 'justify',
                },
                center: {
                    alingment: 'center',
                    fontSize: 10,
                }
            },
    
            //CONTEÚDO DO PDF
            content: [
    
            {
                qr: 'https://pericias.app',
                fit: '50'
            },
    
            /*
            // INDICE
            {
                toc: {
                    id: 'mainToc',
                    title: {
                        text: 'Ìndice',
                        style: 'header'
                    }
                }
            },
            {
                    text: 'Texto simples',
                    style: 'header',
                    tocItem: 'mainToc'
            },
    
    
            //LINKS
            {
                text: 'Ir para a página 1',
                linkToPage: 1
            },
            {
                text: 'Go to Header',
                linkToDestination: 'header'
            },
    */
            // TEXTOS E TABELAS
                
            dadosIniciaisPdf(),
            dadosPolo1Pdf(),
            dadosPolo2Pdf(),
            dadosAgendamentoPdf(),

            docHonorariosPdf(),
            docReclamantePdf(),
            docReclamadaPdf(),
            docSecVaraPdf(),
                
            checkDataAtual(),
            checkDadosPerito(),
            checkDadosPeritoEspecialidade(),                        
            ],
        }
    
        // CRIA UMA NOVA ABA COM O  DOCUMENTO
    
        //pdfMake.createPdf(docDefinitions, tableLayouts, fonts/*, vfs*/).open()

        /* const pdfDocGenerator = pdfMake.createPdf(docDefinitions);
                pdfDocGenerator.getDataUrl((dataUrl) => {
                    
                    iframe.src = dataUrl;
                    targetElement.appendChild(iframe);
                }) */

        /* pdfMake.createPdf(docDefinitions).download() */
        pdfMake.createPdf(docDefinitions/* , tableLayouts, fonts, vfs */).print()
        pdfMake.createPdf(docDefinitions).getDataUrl((url)=> setUrl(url))
}




//PDF LAUDO MEDICO PERICIAL REFATORADO

const dadosIniciaisLaudoPdf = () => {
    /* if (
        tudo._01_dadosIniciais.numProcesso === undefined | false | null | '' && 
        tudo._02_polo1.nome === undefined | false | null | '' &&
        tudo._03_polo2.razaoSocial === undefined | false | null | ''  &&
        tudo._81_dadosPerito.estadoCrm === undefined | false | null | '' &&
        tudo._08_agendamento.dataAgendamento === undefined | false | null | '' &&
        tudo._08_agendamento.hora === undefined | false | null | '' &&
        tudo._86_dadosPeritoEndCom.logradouro === undefined | false | null | '' /* && */
        /* dadosProcessuais.objetoPericia 
        === undefined | false | null | '' 
    )
    {
        return alert(
            `Verifique os dados, por favor:
            Número do processo: ${tudo._01_dadosIniciais.numProcesso}
            Nome do Reclamante: ${tudo._02_polo1.nome}
            Razão social: ${tudo._03_polo2.razaoSocial}
            Nome do perito: ${tudo._81_dadosPerito.nome}
            Data da perícia: ${tudo._08_agendamento.dataAgendamento}
            Hora da perícia: ${tudo._08_agendamento.hora}
            Local da perícia: ${tudo._86_dadosPeritoEndCom.logradouro}
            
            `
            /* Objeto da perícia: ${dadosProcessuais.objetoPericia } 
        )
    } else { */
    return [
    //{text: '\n\n'},        
    /* {
        text: `Número do processo: ${tudo._01_dadosIniciais.numProcesso}\n`,
        style: 'header',
    },
    {
        text: `Polo ativo: ${tudo._02_polo1.nome}\n`,
        style: 'header',
    },
    {
        text: `Polo passivo: ${tudo._03_polo2.razaoSocial}\n`,
        style: 'header',
    }, */
    /* {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['*'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Numero do Processo', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                [
                    `${tudo._01_dadosIniciais.numProcesso}`,
                ],
                
            ]
        }, 
    }, */
    dadosIniciaisPdf(),
    {text: '\n'},
    dadosPolo1Pdf(),
    dadosPolo2Pdf(),
    /* {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['*','*'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Polo Ativo', style: 'cabecalhoTabela'},
                    {text: 'Polo Passivo', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                [
                    `${tudo._02_polo1.nome}`,
                    `${tudo._03_polo2.razaoSocial}`,
                ],
                
            ]
        }, 
    }, */
    {text: '\n'},
    {
        text: [tudo._81_dadosPerito !== undefined && tudo._81_dadosPerito.nome !== undefined ? `${tudo._81_dadosPerito.nome}` : '',', perito do juízo e compromissado nos autos da reclamação trabalhista acima, tendo efetuado as diligências necessárias ao cumprimento de seu mandato, venho apresentar laudo pericial e requerer a sua juntada aos autos para fins de direito.\n\n'],
        style: 'subheader'
    },
    {
        text: `INTRODUÇÃO\n`,
        style: 'header',
        alignment:'center',
    },
    /* {
        text: [ `Data da perícia: ${tudo._08_agendamento.dataAgendamento} \n`,`Hora: ${tudo._08_agendamento.hora} \n`,`Local: ${tudo._86_dadosPeritoEndCom[0].logradouro}, ${tudo._86_dadosPeritoEndCom[0].numero}, ${tudo._86_dadosPeritoEndCom[0].complemento}, ${tudo._86_dadosPeritoEndCom[0].bairro}. ${tudo._86_dadosPeritoEndCom[0].cidade} - ${tudo._86_dadosPeritoEndCom[0].estado}. Cep: ${tudo._86_dadosPeritoEndCom[0].cep} \n\n`],
        style: 'subheader',
        alignment:'center',
    }, */
    dadosAgendamentoPdf(),
    /* {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['*','*','*' ],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Data da Perícia', style: 'cabecalhoTabela'},
                    {text: 'Hora', style: 'cabecalhoTabela'},
                    {text: 'Local', style: 'cabecalhoTabela'}, 
                ],
                //DADOS DA TABELA EM SI
                [
                    `${tudo._08_agendamento.dataAgendamento}`,
                    `${tudo._08_agendamento.hora}`,
                    `${tudo._86_dadosPeritoEndCom.logradouro}, ${tudo._86_dadosPeritoEndCom.numero}, ${tudo._86_dadosPeritoEndCom.complemento}, ${tudo._86_dadosPeritoEndCom.bairro}. ${tudo._86_dadosPeritoEndCom.cidade} - ${tudo._86_dadosPeritoEndCom.estado}. Cep: ${tudo._86_dadosPeritoEndCom.cep}`
                ],
                
            ]
        }, 
    }, */
    {text: '\n'},   
    {
        text: `MOTIVO DA PERÍCIA\n`,
        style: 'header',
        alignment:'center',
    },
    {
        text: `Realização de prova técnica, mediante perícia, para avaliação da existência ou não de nexo causal da doença que porventura acomete a parte autora, com seu trabalho realizado na reclamada.\n\n`,
        style: 'subheader'
    },
    {
        text: `OBJETO DA PERÍCIA\n`,
        style: 'header',
        alignment:'center',
    },
    /* {
        text:  [`${ dadosProcessuais.objetoPericia }\n\n`], 
        style: 'subheader'
    }, */
    {
        text: `METODOLOGIA DO LAUDO\n`,
        style: 'header',
        alignment:'center',
    },
    {
        text: `Procedeu-se inicialmente a verificação do termo de audiência para identificação do objeto da perícia. Em complementação verificou-se a inicial do processo para se definir o pedido existente nos autos. Realizou-se o resumo estudando a inicial e a defesa bem como efetuou-se análise detalhada dos documentos existentes nos autos trazidas pelas partes em litigio. Solicitamos também sempre que necessário documentos adicionais. Marcada a perícia médica as partes presentes foram identificadas. Questionados então os dados profissiográficos e as condições de trabalho existentes na reclamada. Iniciado o ato médico foram feitas anamnese e exame físico compatíveis como os pedidos da inicial e defesa. Para estabelecimento do nexo causal e valoração do dano corporal foi realizada pesquisa bibliográfica referente ao caso em questão, tanto em literaturas nacionais como internacionais. Por fim foram respondidos aos quesitos porventura realizados.\n\n`,
        style: 'subheader'
    },
    {
        text: `PARTICIPANTES DA PERÍCIA\n`,
        style: 'header',
        alignment:'center',
    },
    {
        text: `digite aqui os assistentes técnicos.\n\n`,
        style: 'subheader'
    },
]

}

const dadosReclamantePdf = () => {
    
    let y = []
    if (tudo._02_polo1 && tudo._02_polo1.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._02_polo1 : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
                 e.nomePolo1 !== undefined && e.nomePolo1 !== ""
                ? e.nomePolo1
                : "",
                
                 e.cpfPolo1 !== undefined && e.cpfPolo1.length === 14
                ? e.cpfPolo1
                : "",
                
                 e.generoPolo1 !== undefined && e.generoPolo1 !== ""
                ? e.generoPolo1
                : "",
      
                 e.tipoPolo1 !== undefined && e.tipoPolo1 !== ""
                ? e.tipoPolo1
                : "",
      
                 /* e.dataNascimentoAmericanoPolo1 !== undefined &&
                e.dataNascimentoAmericanoPolo1.length === 10
                  ? e.dataNascimentoAmericanoPolo1
                  : "", */
      
                e.dataNascimentoPolo1 !== undefined &&
                e.dataNascimentoPolo1.length === 10 &&
                e.dataNascimentoPolo1 !== "01-01-0001" &&
                e.dataNascimentoAmericanoPolo1 !== ""
                  ? e.dataNascimentoPolo1
                  : "",
      
                e.idadePolo1 !== undefined && e.idadePolo1 !== "" ? e.idadePolo1 : "",
      
                 e.estadoCivilPolo1 !== undefined && e.estadoCivilPolo1 !== ""
                ? e.estadoCivilPolo1
                : "",
      
                  e.estadoCivilComentarioPolo1 !== undefined &&
                e.estadoCivilComentarioPolo1 !== ""
                  ? e.estadoCivilComentarioPolo1
                  : "",
      
                 e.filhosPolo1 !== undefined && e.filhosPolo1 !== ""
                ? e.filhosPolo1
                : "",
      
                 e.filhosComentarioPolo1 &&
                (e.filhosComentarioPolo1 !== "") !== undefined
                  ? e.filhosComentarioPolo1
                  : "",
      
                 e.escolaridadePolo1 !== undefined && e.escolaridadePolo1 !== ""
                ? e.escolaridadePolo1
                : "",
      
                 e.escolaridadeComentarioPolo1 &&
                (e.escolaridadeComentarioPolo1 !== "") !== undefined
                  ? e.escolaridadeComentarioPolo1
                  : "",
      
                 e.dominanciaPolo1 !== undefined && e.dominanciaPolo1 !== ""
                ? e.dominanciaPolo1
                : "",
      
                 e.habitosPolo1 !== undefined && e.habitosPolo1 !== ""
                ? e.habitosPolo1
                : "",
      
                 e.habitosComentarioPolo1 !== undefined &&
                e.habitosComentarioPolo1 !== ""
                  ? e.habitosComentarioPolo1
                  : "",

            ]) : y.push(
 ['','','','','','','','','','','','','','',''/* ,'' */])
    )
 
  //console.log('***y',y[0],'***length',y[0].length)


    return [
    {text: '\n'}, 
    {
        text: 'DADOS DO RECLAMANTE',
        style: 'header',
        alignment:'center',
    }, 
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','40%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Nome', style: 'cabecalhoTabela'},
                    {text: 'CPF', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                [
                    y.length > 0 && y[0][0] !== ''  ? [[y[0][0]]] : [''],
                    y.length > 0 && y[0][1] !== ''  ? [[y[0][1]]] : [''],
                    
                    /* y.length > 0 ? y[0][0] : [''],
                    y.length > 0 ? y[0][1] : [''],
                    y.length > 0 ? y[0][2] : [''],
                    y.length > 0 ? y[0][3] : [''],
                    y.length > 0 ? y[0][4] : [''], */
                   
                ],
                
            ]
        }, 
    },
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['33%','33%','34%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Data de nascimento', style: 'cabecalhoTabela'},
                    {text: 'Idade', style: 'cabecalhoTabela'},
                    {text: 'Dominância', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                [
                    y.length > 0 && y[0][4] !== ''  ? [[y[0][4]]] : [''],
                    y.length > 0 && y[0][5] !== ''  ? [[y[0][5]]] : [''],
                    y.length > 0 && y[0][12] !== ''  ? [[y[0][12]]] : [''],
                   
                ],
                
            ]
        }, 
    },
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%',/* '*' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Genero', style: 'cabecalhoTabela'},
                    {text: 'Estado Civil', style: 'cabecalhoTabela'},
                    {text: 'Comentarios', style: 'cabecalhoTabela'},
                    /* {text: 'Idade', style: 'cabecalhoTabela'}, */
                ],
                //DADOS DA TABELA EM SI
                [
                    y.length > 0 && y[0][2] !== ''  ? [[y[0][2]]] : [''],
                    y.length > 0 && y[0][6] !== ''  ? [[y[0][6]]] : [''],
                    y.length > 0 && y[0][7] !== ''  ? [[y[0][7]]] : [''],
                ],
                
            ]
        }, 
    },
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['30%','70%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Filhos', style: 'cabecalhoTabela'},
                    {text: 'Comentarios', style: 'cabecalhoTabela'},
           
                ],
                //DADOS DA TABELA EM SI
                [
                    y.length > 0 && y[0][8] !== ''  ? [[y[0][8]]] : [''],
                    y.length > 0 && y[0][9] !== ''  ? [[y[0][9]]] : [''],
                    
                ],
                
            ]
        }, 
    },
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['30%','70%'/* '*' */,/* '*' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Escolaridade', style: 'cabecalhoTabela'},
                    {text: 'Comentarios', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                [
                    y.length > 0 && y[0][10] !== ''  ? [[y[0][10]]] : [''],
                    y.length > 0 && y[0][11] !== ''  ? [[y[0][11]]] : [''],
                ],
                
            ]
        }, 
    },
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['30%','70%',/* '*' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Habitos', style: 'cabecalhoTabela'},
                    {text: 'Comentarios', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                [
                    y.length > 0 && y[0][13] !== ''  ? [[y[0][13]]] : [''],
                    y.length > 0 && y[0][14] !== ''  ? [[y[0][14]]] : [''],
                ],
                
            ]
        }, 
    },
]


    }
}

const dadosReclamadaPdf = () => {
    
    const y = []
    
    if (tudo._03_polo2) {
            const a = JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._03_polo2 !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._03_polo2 : []

           // console.log('***',typeof(a), a)
        a.map((e)=>   e !== undefined ? y.push( [
        e.razaoSocial !== undefined ? e.razaoSocial : '',
        e.cnpj !== undefined ? e.cnpj : '',
        e.cnaeFiscal !== undefined ? e.cnaeFiscal : '',
        e.cnaeFiscalDescricao !== undefined ? e.cnaeFiscalDescricao : '',
        ]) : y.push(['','','','',]) ) 

    //console.log(y[0])

    return [
    {text: '\n'}, 
    {
        text: 'DADOS DA RECLAMADA',
        style: 'header',
        alignment:'center',
    }, 
    {text: '\n'},
    {
        layout: 'headerLineOnly',
        alignment:'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['35%','20%','15%','30%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Razão social', style: 'cabecalhoTabela'},
                    {text: 'Cnpj', style: 'cabecalhoTabela'},
                    {text: 'Cnae', style: 'cabecalhoTabela'},
                    {text: 'Descrição', style: 'cabecalhoTabela'},
                ],
                //DADOS DA TABELA EM SI
                //y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3]] : ['','','',''],
                
                [
                y.length > 0 && y[0][0] !== ''  ? [[y[0][0]]] : [''],
                y.length > 0 && y[0][1] !== ''  ? [[y[0][1]]] : [''],
                y.length > 0 && y[0][2] !== ''  ? [[y[0][2]]] : [''],
                y.length > 0 && y[0][3] !== ''  ? [[y[0][3]]] : [''],
                ]
                       
            ]
        }, 
    },]
    }
    
}

const headerDadosProcessuaisPdf = () => {
    if ((tudo._09_inicial && tudo._09_inicial.length > 0 && tudo._09_inicial.resumoInicial !== '')||
    (tudo._10_contestacao && tudo._10_contestacao.length > 0 && tudo._10_contestacao.resumoContestacao !== '')||
    (tudo._11_atestado && tudo._11_atestado.length > 0) ||
    (tudo._12_inss && tudo._12_inss.length > 0) ||
    (tudo._13_inssPericia && tudo._13_inssPericia.length > 0) ||
    (tudo._14_cat && tudo._14_cat.length > 0) ||
    (tudo._15_docSst && tudo._15_docSst.length > 0) ||
    (tudo._16_docOutros && tudo._16_docOutros.length > 0) ||
    (tudo._17_ && tudo._17_.length > 0) ||
    (tudo._18_trab && tudo._18_trab.length > 0) 
) {
    return [
    {text: '\n\n'}, 
    {
        text: `DADOS PROCESSUAIS`,
        style: 'header'
    },
    {text: '\n'},
    ]
    
} 
} 

const dadosInicialPdf = () => {

    const y = []
                if (tudo._09_inicial) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._09_inicial !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._09_inicial !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._09_inicial : []

                a.map((e) => e !== undefined ?          
                y.push( [
                e.idInicial !== undefined ? e.idInicial : '',
                e.resumoInicial !== undefined ? e.resumoInicial : '',
                ]) : y.push(['','',])
            )} else {
                y.push(['','',])
            }
            //console.log(y)
    
            return [
        /*         {text: '\n\n'}, 
    {
        text: `DADOS PROCESSUAIS`,
        style: 'header'
    }, */
    {text: '\n'},
        {text: '\n'}, 
        {
            text: 'DADOS DA INICIAL',
            style: 'header',
            alignment:'center',
        }, 
        {text: '\n'},
        {
            layout: 'headerLineOnly',
            alignment:'center',
            table: {
                heights: function () {
                    return 15
                },
                headerRows: 1,
                widths: ['10%','90%'],
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Id', style: 'cabecalhoTabela'},
                        {text: 'Resumo da Inicial', style: 'cabecalhoTabela'},
                    ],
                    //DADOS DA TABELA EM SI
                            ...y
                           // y.length > 0 ? [y[0][0],y[0][1],] : ['','',],
                        ]
                    }, 
                },]
 
}

const dadosContestacaoPdf = () => {

    const y = []
                if (tudo._10_contestacao) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._10_contestacao !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._10_contestacao !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._10_contestacao : []

                    a.map((e) => e !== undefined ?          
                    y.push( [
                    e.idContestacao !== undefined ? e.idContestacao : '',
                    e.resumoContestacao !== undefined ? e.resumoContestacao : '',
                    ]) : y.push(['','',])
                )} else {
                    y.push(['','',])
                }
                //console.log(y)
        
                return [
         /*            {text: '\n\n'}, 
        {
            text: `DADOS PROCESSUAIS`,
            style: 'header'
        }, */
        {text: '\n'},
            {text: '\n'}, 
            {
                text: 'DADOS DA CONTESTAÇÃO',
                style: 'header',
                alignment:'center',
            }, 
            {text: '\n'},
            {
                layout: 'headerLineOnly',
                alignment:'center',
                table: {
                    heights: function () {
                        return 15
                    },
                    headerRows: 1,
                    widths: ['10%','90%'],
                body: [
                    [
                        //DADOS DO CABECALHO E ESTILO
                        {text: 'Id', style: 'cabecalhoTabela'},
                        {text: 'Resumo da Contestação', style: 'cabecalhoTabela'},
                    ],
                        //DADOS DA TABELA EM SI
                                ...y
                               // y.length > 0 ? [y[0][0],y[0][1],] : ['','',],
                            ]
                        }, 
                    },]
}

const dadosAtestadoPdf = () => {
  

            const y = []
                if (tudo._11_atestado/*  !== undefined && tudo._11_atestado !== null */) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._11_atestado !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._11_atestado !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._11_atestado : []

                 //  console.log('***',a)
                /* JSON.parse(localStorage.getItem('tudo'))._11_atestado */a.map((
            /* {
                idAtestado,
                cid,
                inicioAtestado, 
                profissionalAssistente, 
                qtdeDiasAfastadoAtestado
            } */e)=> 
            e !== undefined ? y.push( [
                e.idAtestado !== undefined ? e.idAtestado : '',
                e.cid !== undefined || e.cid !== null || e.cid !== '' ? e.cid.map((/* {codigo, descricao} */o)=> o !== undefined ? [o.codigo, o.descricao] : ['','']) : '',
                e.inicioAtestado !== undefined ? e.inicioAtestado : '',
                e.profissionalAssistente !== undefined ? e.profissionalAssistente : '',
                e.qtdeDiasAfastadoAtestado !== undefined ? e.qtdeDiasAfastadoAtestado : ''
                ]) : y.push(['','','','',''])
            )} else {
                y.push(['','','','',''])
            }
            //console.log(y)
           
            return [
                {text: '\n'},
                {
                    text: 'Atestado',
                    style: 'subheader',
                    alignment:'center',
                },
                {text: '\n'},
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['auto', '*',  /* '*', */'*','*','auto',],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                //{text: 'Id - página', style: 'cabecalhoTabela'},
                                {text: 'Id Atestado', style: 'cabecalhoTabela'},
                                {text: 'Cid', style: 'cabecalhoTabela'},
                                {text: 'Início', style: 'cabecalhoTabela'},
                                {text: 'Profissional assistente', style: 'cabecalhoTabela'},
                                {text: 'Dias afastados', style: 'cabecalhoTabela'},
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                            // SO APARECE UMA OPCÃO!! y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4]] : ['','','','',''],
                        ]
                    }, 
                },]
}

const dadosInssPdf = () => {
    
    const y = []
                if (tudo._12_inss/*  !== undefined && tudo._12_inss !== null */) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._12_inss !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._12_inss !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._12_inss : []

                   // console.log('***',a)
                /* JSON.parse(localStorage.getItem('tudo'))._12_inss */a.map((
            /* {
                idInss,
                tipoBeneficio,
                situacaoBeneficio,
                inicioInss, 
                qtdeDiasAfastadoInss,
            } */e)=> e !== undefined ?  y.push( [
                e.idInss !== undefined ? e.idInss : '',
                e.tipoBeneficio !== undefined ? e.tipoBeneficio : '',
                e.situacaoBeneficio !== undefined ? e.situacaoBeneficio : '',
                e.inicioInss !== undefined ? e.inicioInss : '',              
                e.qtdeDiasAfastadoInss !== undefined ? e.qtdeDiasAfastadoInss : '',
                ]) : y.push(['','','','',''])
            )} else {
                y.push(['','','','',''])
            }
            //console.log(y)
    
            return [
                {text: '\n'},
                {
                    text: 'Documentos do INSS',
                    style: 'subheader',
                    alignment:'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['auto','*','*','*','auto', /* '*' */],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                /* {text: 'Id - página', style: 'cabecalhoTabela'}, */
                                {text: 'Id Inss', style: 'cabecalhoTabela'},
                                {text: 'Tipo benefício', style: 'cabecalhoTabela'},
                                {text: 'Situação do benefício', style: 'cabecalhoTabela'},
                                {text: 'Início', style: 'cabecalhoTabela'},
                                {text: 'Dias afastados', style: 'cabecalhoTabela'},
                                
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                            //y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4]] : ['','','','',''],
                        ]
                    }, 
                    },]
}

const dadosCatPdf = () => {
    
    const y = []
                if (tudo._14_cat/*  !== undefined && tudo._14_cat !== null */) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._14_cat !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._14_cat !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._14_cat : []

                   // console.log('***',a)
                /* JSON.parse(localStorage.getItem('tudo'))._14_cat */a.map((
            /* {
                idCat,
                tipo,
                emissor,
                dataEmissao,
                dataAcidente,
                cid,
            } */e)=>  
            e !== undefined ?          
            y.push( [
                e.idCat !== undefined ? e.idCat : '',
                e.tipo !== undefined ? e.tipo : '',
                e.emissor !== undefined ? e.emissor : '',             
                e.dataEmissao !== undefined ? e.dataEmissao : '',
                e.dataAcidente !== undefined ? e.dataAcidente : '',
                e.cid !== undefined || e.cid !== null || e.cid !== '' ? [e.cid].map((/* {codigo, descricao} */o)=> o !== undefined ? [o.codigo, o.descricao] : ['','']) : '',
                ]) : y.push(['','','','','',''])
            )} else {
                y.push(['','','','','',''])
            }
            //console.log(y)
    
            return [
                {text: '\n'},
                {
                    text: 'CAT',
                    style: 'subheader',
                    alignment:'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['auto','*','*','*','*','auto'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Id - página', style: 'cabecalhoTabela'},
                                {text: 'Tipo', style: 'cabecalhoTabela'},
                                {text: 'Emissor', style: 'cabecalhoTabela'},
                                {text: 'Data emissão', style: 'cabecalhoTabela'},
                                {text: 'Data do acidente', style: 'cabecalhoTabela'},
                                {text: 'Cid', style: 'cabecalhoTabela'},
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                            //y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4],y[0][5]] : ['','','','','',''],
                        ]
                    }, 
                },]
}

const dadosSstPdf = () => {
    
    const y = []
                if (tudo._15_docSst) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._15_docSst !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._15_docSst !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._15_docSst : []

                a.map((e) => e !== undefined ?          
                y.push( [
                e.idDocSst !== undefined ? e.idDocSst : '',
                e.detalheDocSst !== undefined ? e.detalheDocSst : '',
                ]) : y.push(['','',])
            )} else {
                y.push(['','',])
            }
            //console.log(y)
    
            return [
                {text: '\n'},
                {
                    text: 'Documentos de SST',
                    style: 'subheader',
                    alignment:'center'
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['*','*',],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Id - página', style: 'cabecalhoTabela'},
                                {text: 'Tipo de documento SST / Treinamento', style: 'cabecalhoTabela'},
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                           // y.length > 0 ? [y[0][0],y[0][1],] : ['','',],
                        ]
                    }, 
                },]
      /*           default: return console.log("vazio");
    } */
}

const dadosOutrosPdf = () => {
    
    const y = []
                if (tudo._16_docOutros) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._16_docOutros !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._16_docOutros !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._16_docOutros : []

                   // console.log('***',a)
                a.map((e)=>  e !== undefined ?          
                y.push( [
                    e.idDocOutros !== undefined ? e.idDocOutros : '',
                    e.detalheDocOutros !== undefined ? e.detalheDocOutros : '',
                    ]) : y.push(['','',])
                )} else {
                    y.push(['','',])
                }

            return [
                {text: '\n'},
                {
                    text: 'Outros documentos',
                    style: 'subheader',
                    alignment:'center'
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['*','*',],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Id - página', style: 'cabecalhoTabela'},
                                {text: 'Detalhes do documento', style: 'cabecalhoTabela',/* alignment:'center' */},
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                            //y.length > 0 ? [y[0][0],y[0][1],] : ['','',],
                        ]
                    }, 
                },]
}



const headerDadosTrabalhoPdf = () => {
    
    /* for (const doc of tudo._18_trab) {
        const linha = []
        linha.push(doc.id)
        linha.push(doc.dataAdmissao)
        linha.push(doc.dataDemissao)
        linha.push(doc.nomeEmpresaTrabalho)
        linha.push(doc.funcaoTrabalho)
        linha.push(doc.historicoLaboral)
        
    
       // console.log(linha)
        bodyHistoricoLaboral.push(linha)
       //console.log(bodyHistoricoLaboral) 
    }

    for (const doc of tudo._17_trabEmpresa) {
        const linha = []
        linha.push(doc.id)
        linha.push(doc.dataAdmissaoEmpresa)
        linha.push(doc.dataDemissaoEmpresa)
        linha.push(doc.ativLabEmpresa)
        linha.push(doc.detalheTrabEmpresa)
        
    
       // console.log(linha)
        bodyDadosTrabalhoEmpresa.push(linha)
       //console.log(bodyDadosTrabalhoEmpresa) 
    }
    let x = ''
    if(bodyDadosTrabalhoEmpresa[0] |
        bodyHistoricoLaboral[0] 
         !== undefined) {x = true}
         else {x = false}
         console.log('Header Dados Trabalho', x)
         switch (x) {
             case false:
                 return console.log("vazio");
                 
             case true: */
    return [
        {text: '\n\n'}, 
        {
            text: `DADOS RELATIVOS À TRABALHO`,
            style: 'header'
        },
                    {text: '\n'},
    ]
   /*  default: return console.log("vazio");
} */
}

const dadosTrabalhoEmpresaPdf = () => {
    
   const y = []
                if (tudo._17_trabEmpresa/*  !== undefined && tudo._17_trabEmpresa !== null */) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._17_trabEmpresa !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._17_trabEmpresa !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._17_trabEmpresa : []

                   // console.log('***',a)
            a.map((e)=> e !== undefined ?          
            y.push( [
                e.dataAdmissaoEmpresa !== undefined ? e.dataAdmissaoEmpresa : '',
                e.dataDemissaoEmpresa !== undefined ? e.dataDemissaoEmpresa : '',  
                e.qtdeDiasTrabEmpresa !== undefined ? e.qtdeDiasTrabEmpresa : '',
                e.ativLabEmpresa !== undefined ? e.ativLabEmpresa : '',           
                e.detalheTrabEmpresa !== undefined ? e.detalheTrabEmpresa : '',
                
                ]) : y.push(['','','','',''])
            )} else {
                y.push(['','','','',''])
            }
            //console.log(y)

            return [
                {text: '\n\n'}, 
        {
            text: `DADOS RELATIVOS À TRABALHO`,
            style: 'header'
        },
                    {text: '\n'},
                {
                    text: 'Dados de trabalho na empresa',
                    style: 'subheader',
                    alignment:'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        /* margin: [0, 5, 0, 15], */
                        headerRows: 1,
                        widths: ['auto','*','*','*','*'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Admissão', style: 'cabecalhoTabela'},
                                {text: 'Demissão', style: 'cabecalhoTabela'},
                                {text: 'Dias trabalhados', style: 'cabecalhoTabela'},
                                {text: 'Descrição das atividades laborais', style: 'cabecalhoTabela'},
                                {text: 'Detalhes', style: 'cabecalhoTabela', /* margin: [ 5, 2, 10, 20 ] */},
                                
                                //{text: 'Função/CBO', style: 'cabecalhoTabela'},
                                
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                            //y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4]] : ['','','','',''],
                        ]
                    }, 
                },]
       /*          default: return console.log("vazio");
    } */
}

const dadosHistoricoLaboralPdf = () => {

    const y = []
                if (tudo._18_trab) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._18_trab !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._18_trab !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._18_trab : []

                   // console.log('***',a)
                a.map((e)=> e !== undefined ?          
            y.push( [
                e.dataAdmissao !== undefined ? e.dataAdmissao : '',
                e.dataDemissao !== undefined ? e.dataDemissao : '',
                e.qtdeDiasTrabalho !== undefined ? e.qtdeDiasTrabalho : '',
                e.nomeEmpresaTrabalho !== undefined ? e.nomeEmpresaTrabalho : '',
                e.funcaoTrabalho !== undefined ? e.funcaoTrabalho : '',             
                e.historicoLaboral !== undefined ? e.historicoLaboral : '',
                ]) : y.push(['','','','','',''])
            )} else {
                y.push(['','','','','',''])
            }
            //console.log(y)


            return [
                {text: '\n'},
                {
                    text: 'Histórico laboral',
                    style: 'subheader',
                    alignment:'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['auto','*','*','*','*','auto'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Admissão', style: 'cabecalhoTabela'},
                                {text: 'Demissao', style: 'cabecalhoTabela'},
                                {text: 'Qtde dias', style: 'cabecalhoTabela'},
                                {text: 'Empresa', style: 'cabecalhoTabela'},
                                {text: 'Função', style: 'cabecalhoTabela'},
                                {text: 'Historico Laboral', style: 'cabecalhoTabela'},
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                           // y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4],y[0][5]] : ['','','','','',''],
                        ]
                    }, 
                },]
}


const headerDadosSaudePdf = () => {
    
    return [
        {text: '\n\n'}, 
                {
                    text: `DADOS DE SAÚDE`,
                    style: 'header'
                },
                {text: '\n'},
    ]
}


const dadosAnamneseOrtopedicaPdf = () => {

    const y = []
    
                if (tudo._19_anamneseOrtopedica && tudo._19_anamneseOrtopedica.length > 0) {

                    
                    const a = JSON.parse(localStorage.getItem('tudo'))._19_anamneseOrtopedica !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._19_anamneseOrtopedica !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._19_anamneseOrtopedica : []

                    //console.log('***',a)
                    a.map((e)=> e !== undefined ?          
                    y.push( [
                    e.articulacao !== undefined ? e.articulacao : '',
                    e.lado !== undefined ? e.lado : '',
                    e.inicioSintomaOrtopedico !== undefined ? e.inicioSintomaOrtopedico : '',
                    e.escalaDorInicial !== undefined ? e.escalaDorInicial : '',             
                    e.tratamentoInicial !== undefined ? e.tratamentoInicial : '',
                    e.fimSintomaOrtopedico !== undefined ? e.fimSintomaOrtopedico : '',
                    e.escalaDorAtual !== undefined ? e.escalaDorAtual : '',             
                    e.tratamentoAtual !== undefined ? e.tratamentoAtual : '',
                    ]) : y.push(['','','','','','','',''])
                    ) /*else {
                y.push(['','','','','','','','']) */
            
           // console.log(y)
    
            return [
                {
                    text: 'Anamnese ortopédica',
                    style: 'subheader'

                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['auto','*','*','*','*','*','*','auto'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                //{text: 'CID', style: 'cabecalhoTabela'},
                                {text: 'Articulação', style: 'cabecalhoTabela'},
                                {text: 'Lado', style: 'cabecalhoTabela'},
                                {text: 'Início dos sintomas', style: 'cabecalhoTabela'},
                                {text: 'Nível de dor', style: 'cabecalhoTabela'},
                                {text: 'Tratamento', style: 'cabecalhoTabela'},
                                //{text: 'Qtde dias pós admissão', style: 'cabecalhoTabela'},
                                {text: 'Fim dos sintomas', style: 'cabecalhoTabela'},
                                {text: 'Nível de dor', style: 'cabecalhoTabela'},
                                {text: 'Tratamento', style: 'cabecalhoTabela'},
                                //{text: 'Qtde dias pós demissão', style: 'cabecalhoTabela'},
                            ],
                            //DADOS DA TABELA EM SI
                            ...y
                           // y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4],y[0][5],y[0][6],y[0][7]] : ['','','','','','','',''],
                          
                        ],
                    }, 
           },
       ]
    }
   }      
//} 
            /* {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['auto','*','*','*','*','*','*','*','*','*','auto'],
                                //['*','*','*','*','*','*','*','**','*','*','*',],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'CID', style: 'cabecalhoTabela'},
                                {text: 'Articulação', style: 'cabecalhoTabela'},
                                {text: 'Lado', style: 'cabecalhoTabela'},
                                {text: 'Início dos sintomas', style: 'cabecalhoTabela'},
                                {text: 'Nível de dor', style: 'cabecalhoTabela'},
                                {text: 'Tratamento', style: 'cabecalhoTabela'},
                                {text: 'Qtde dias pós admissão', style: 'cabecalhoTabela'},
                                {text: 'Fim dos sintomas', style: 'cabecalhoTabela'},
                                {text: 'Nível de dor', style: 'cabecalhoTabela'},
                                {text: 'Tratamento', style: 'cabecalhoTabela'},
                                {text: 'Qtde dias pós demissão', style: 'cabecalhoTabela'},
                            ],
                            //DADOS DA TABELA EM SI
                            y.length > 0 ? [y[0][0],y[0][1],y[0][2],y[0][3],y[0][4]] : ['','','','',''],
                        ]
                    }, 
                }, 
            
            ] */
    //}


const dadosAnamnesePsiPdf = () => {

    let y = []
                if (tudo._20_anamnesePsi && tudo._20_anamnesePsi.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._20_anamnesePsi !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._20_anamnesePsi !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._20_anamnesePsi : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
                            e.inicioSintomaPsiPergunta!== undefined ? e.inicioSintomaPsiPergunta : '',
                            e.inicioSintomaPsi !== undefined && e.inicioSintomaPsi.length === 10 ? e.inicioSintomaPsi : '',
                            e.inicioSintomaPsiComentario !== undefined ? e.inicioSintomaPsiComentario : '', 
                            e.sintomaPrincipalPergunta !== undefined ? e.sintomaPrincipalPergunta : '' ,
                            e.sintomaPrincipal !== undefined ? e.sintomaPrincipal : '',
                            e.sintomaPrincipalComentario !== undefined ? e.sintomaPrincipalComentario : '',
                            e.sintomaSecundarioPergunta !== undefined ? e.sintomaSecundarioPergunta : '',
                            e.sintomaSecundario !== undefined ? e.sintomaSecundario : '',
                            e.sintomaSecundarioComentario !== undefined ? e.sintomaSecundarioComentario : '',
                            e.fatoresDesencadeamentoPergunta !== undefined ? e.fatoresDesencadeamentoPergunta : '',
                            e.fatoresDesencadeamento !== undefined ? e.fatoresDesencadeamento : '',
                            e.fatoresDesencadeamentoComentario !== undefined ? e.fatoresDesencadeamentoComentario : '',
                            e.fatoresMelhoraPergunta !== undefined ? e.fatoresMelhoraPergunta : '',
                            e.fatoresMelhora !== undefined ? e.fatoresMelhora : '',
                            e.fatoresMelhoraComentario !== undefined ? e.fatoresMelhoraComentario : '',
                            e.usouMedicamentoPergunta !== undefined ? e.usouMedicamentoPergunta : '',
                            e.usouMedicamento !== undefined ? e.usouMedicamento : '',
                            e.usouMedicamentoComentario !== undefined ? e.usouMedicamentoComentario : '',
                            e.fezPsicoterapiaPergunta !== undefined ? e.fezPsicoterapiaPergunta : '',
                            e.fezPsicoterapia !== undefined ? e.fezPsicoterapia : '',
                            e.fezPsicoterapiaComentario !== undefined ? e.fezPsicoterapiaComentario : '',
                            e.teveInternamentoPergunta !== undefined ? e.teveInternamentoPergunta : '',
                            e.teveInternamento !== undefined ? e.teveInternamento : '',
                            e.teveInternamentoComentario !== undefined ? e.teveInternamentoComentario : '',
                            e.outrasInformacoesPergunta !== undefined ? e.outrasInformacoesPergunta : '',
                            e.outrasInformacoes !== undefined ? e.outrasInformacoes : '',
                            e.outrasInformacoesComentario !== undefined ? e.outrasInformacoesComentario : '',
                            e.estadoAtualPergunta !== undefined ? e.estadoAtualPergunta : '',
                            e.estadoAtual !== undefined ? e.estadoAtual : '',
                            e.estadoAtualComentario !== undefined ? e.estadoAtualComentario : '',
                            e.ultimaConsultaPsiPergunta !== undefined ? e.ultimaConsultaPsiPergunta : '',
                            e.ultimaConsultaPsi !== undefined && e.ultimaConsultaPsi.length === 10 ? e.ultimaConsultaPsi : '',
                            e.ultimaConsultaPsiComentario !== undefined ? e.ultimaConsultaPsiComentario : '',
                        ]) : y.push(['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''])
                )

            //console.log('***y',y[0],'***length',y[0].length)
        
        return [
                {text: '\n'},
                {
                    text: 'Anamnese psiquiátrica',
                    style: 'subheader'
                },
                {
                    layout: 'lightHorizontalLines',alignment:/* 'justify' */'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        //alignment:'center',
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text:  'Perguunta' , style: 'cabecalhoTabela'/* ,alignment:'center' */},
                                {text:  'Resposta' , style: 'cabecalhoTabela'/* ,alignment:'center' */},
                                {text:  'Comentário' , style: 'cabecalhoTabela'/* ,alignment:'center' */},
                            ],
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' || y[0][20]!== '') ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' || y[0][23]!== '') ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' || y[0][26]!== '') ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' || y[0][29]!== '') ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' || y[0][32]!== '') ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''], 
                        ],
                         }, 
                },
            ]
        }      
} 


const dadosQuestionatioPsiPdf = () => {

    let y = []
                if (tudo._98_questionarioPsi && tudo._98_questionarioPsi.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._98_questionarioPsi !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._98_questionarioPsi !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._98_questionarioPsi : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
                            e.perguntaQuestionarioPsi0 !== undefined ? e.perguntaQuestionarioPsi0 : '',
            e.respostaQuestionarioPsi0 !== undefined ? e.respostaQuestionarioPsi0 : '',
            e.comentarioQuestionarioPsi0 !== undefined ? e.comentarioQuestionarioPsi0 : '',
            e.perguntaQuestionarioPsi1 !== undefined ? e.perguntaQuestionarioPsi1 : '',
            e.respostaQuestionarioPsi1 !== undefined ? e.respostaQuestionarioPsi1 : '',
            e.comentarioQuestionarioPsi1 !== undefined ? e.comentarioQuestionarioPsi1 : '',
            e.perguntaQuestionarioPsi2 !== undefined ? e.perguntaQuestionarioPsi2 : '',
            e.respostaQuestionarioPsi2 !== undefined ? e.respostaQuestionarioPsi2 : '',
            e.comentarioQuestionarioPsi2 !== undefined ? e.comentarioQuestionarioPsi2 : '',
            e.perguntaQuestionarioPsi3 !== undefined ? e.perguntaQuestionarioPsi3 : '',
            e.respostaQuestionarioPsi3 !== undefined ? e.respostaQuestionarioPsi3 : '',
            e.comentarioQuestionarioPsi3 !== undefined ? e.comentarioQuestionarioPsi3 : '',
            e.perguntaQuestionarioPsi4 !== undefined ? e.perguntaQuestionarioPsi4 : '',
            e.respostaQuestionarioPsi4 !== undefined ? e.respostaQuestionarioPsi4 : '',
            e.comentarioQuestionarioPsi4 !== undefined ? e.comentarioQuestionarioPsi4 : '',
            e.perguntaQuestionarioPsi5 !== undefined ? e.perguntaQuestionarioPsi5 : '',
            e.respostaQuestionarioPsi5 !== undefined ? e.respostaQuestionarioPsi5 : '',
            e.comentarioQuestionarioPsi5 !== undefined ? e.comentarioQuestionarioPsi5 : '',
            e.perguntaQuestionarioPsi6 !== undefined ? e.perguntaQuestionarioPsi6 : '',
            e.respostaQuestionarioPsi6 !== undefined ? e.respostaQuestionarioPsi6 : '',
            e.comentarioQuestionarioPsi6 !== undefined ? e.comentarioQuestionarioPsi6 : '',
            e.perguntaQuestionarioPsi7 !== undefined ? e.perguntaQuestionarioPsi7 : '',
            e.respostaQuestionarioPsi7 !== undefined ? e.respostaQuestionarioPsi7 : '',
            e.comentarioQuestionarioPsi7 !== undefined ? e.comentarioQuestionarioPsi7 : '',
            e.perguntaQuestionarioPsi8 !== undefined ? e.perguntaQuestionarioPsi8 : '',
            e.respostaQuestionarioPsi8 !== undefined ? e.respostaQuestionarioPsi8 : '',
            e.comentarioQuestionarioPsi8 !== undefined ? e.comentarioQuestionarioPsi8 : '',
            e.perguntaQuestionarioPsi9 !== undefined ? e.perguntaQuestionarioPsi9 : '',
            e.respostaQuestionarioPsi9 !== undefined ? e.respostaQuestionarioPsi9 : '',
            e.comentarioQuestionarioPsi9 !== undefined ? e.comentarioQuestionarioPsi9 : '',
            e.perguntaQuestionarioPsi10 !== undefined ? e.perguntaQuestionarioPsi10 : '',
            e.respostaQuestionarioPsi10 !== undefined ? e.respostaQuestionarioPsi10 : '',
            e.comentarioQuestionarioPsi10 !== undefined ? e.comentarioQuestionarioPsi10 : '',
            e.perguntaQuestionarioPsi11 !== undefined ? e.perguntaQuestionarioPsi11 : '',
            e.respostaQuestionarioPsi11 !== undefined ? e.respostaQuestionarioPsi11 : '',
            e.comentarioQuestionarioPsi11 !== undefined ? e.comentarioQuestionarioPsi11 : '',
            e.perguntaQuestionarioPsi12 !== undefined ? e.perguntaQuestionarioPsi12 : '',
            e.respostaQuestionarioPsi12 !== undefined ? e.respostaQuestionarioPsi12 : '',
            e.comentarioQuestionarioPsi12 !== undefined ? e.comentarioQuestionarioPsi12 : '',
            e.perguntaQuestionarioPsi13 !== undefined ? e.perguntaQuestionarioPsi13 : '',
            e.respostaQuestionarioPsi13 !== undefined ? e.respostaQuestionarioPsi13 : '',
            e.comentarioQuestionarioPsi13 !== undefined ? e.comentarioQuestionarioPsi13 : '',
            e.perguntaQuestionarioPsi14 !== undefined ? e.perguntaQuestionarioPsi14 : '',
            e.respostaQuestionarioPsi14 !== undefined ? e.respostaQuestionarioPsi14 : '',
            e.comentarioQuestionarioPsi14 !== undefined ? e.comentarioQuestionarioPsi14 : '',
            e.perguntaQuestionarioPsi15 !== undefined ? e.perguntaQuestionarioPsi15 : '',
            e.respostaQuestionarioPsi15 !== undefined ? e.respostaQuestionarioPsi15 : '',
            e.comentarioQuestionarioPsi15 !== undefined ? e.comentarioQuestionarioPsi15 : '',
            e.perguntaQuestionarioPsi16 !== undefined ? e.perguntaQuestionarioPsi16 : '',
            e.respostaQuestionarioPsi16 !== undefined ? e.respostaQuestionarioPsi16 : '',
            e.comentarioQuestionarioPsi16 !== undefined ? e.comentarioQuestionarioPsi16 : '',
            e.perguntaQuestionarioPsi17 !== undefined ? e.perguntaQuestionarioPsi17 : '',
            e.respostaQuestionarioPsi17 !== undefined ? e.respostaQuestionarioPsi17 : '',
            e.comentarioQuestionarioPsi17 !== undefined ? e.comentarioQuestionarioPsi17 : '',
            e.perguntaQuestionarioPsi18 !== undefined ? e.perguntaQuestionarioPsi18 : '',
            e.respostaQuestionarioPsi18 !== undefined ? e.respostaQuestionarioPsi18 : '',
            e.comentarioQuestionarioPsi18 !== undefined ? e.comentarioQuestionarioPsi18 : '',
            e.perguntaQuestionarioPsi19 !== undefined ? e.perguntaQuestionarioPsi19 : '',
            e.respostaQuestionarioPsi19 !== undefined ? e.respostaQuestionarioPsi19 : '',
            e.comentarioQuestionarioPsi19 !== undefined ? e.comentarioQuestionarioPsi19 : '',
            e.perguntaQuestionarioPsi20 !== undefined ? e.perguntaQuestionarioPsi20 : '',
            e.respostaQuestionarioPsi20 !== undefined ? e.respostaQuestionarioPsi20 : '',
            e.comentarioQuestionarioPsi20 !== undefined ? e.comentarioQuestionarioPsi20 : '',
            e.perguntaQuestionarioPsi21 !== undefined ? e.perguntaQuestionarioPsi21 : '',
            e.respostaQuestionarioPsi21 !== undefined ? e.respostaQuestionarioPsi21 : '',
            e.comentarioQuestionarioPsi21 !== undefined ? e.comentarioQuestionarioPsi21 : '',
            e.perguntaQuestionarioPsi22 !== undefined ? e.perguntaQuestionarioPsi22 : '',
            e.respostaQuestionarioPsi22 !== undefined ? e.respostaQuestionarioPsi22 : '',
            e.comentarioQuestionarioPsi22 !== undefined ? e.comentarioQuestionarioPsi22 : '',
            e.perguntaQuestionarioPsi23 !== undefined ? e.perguntaQuestionarioPsi23 : '',
            e.respostaQuestionarioPsi23 !== undefined ? e.respostaQuestionarioPsi23 : '',
            e.comentarioQuestionarioPsi23 !== undefined ? e.comentarioQuestionarioPsi23 : '',
            e.perguntaQuestionarioPsi24 !== undefined ? e.perguntaQuestionarioPsi24 : '',
            e.respostaQuestionarioPsi24 !== undefined ? e.respostaQuestionarioPsi24 : '',
            e.comentarioQuestionarioPsi24 !== undefined ? e.comentarioQuestionarioPsi24 : '',
            e.perguntaQuestionarioPsi25 !== undefined ? e.perguntaQuestionarioPsi25 : '',
            e.respostaQuestionarioPsi25 !== undefined ? e.respostaQuestionarioPsi25 : '',
            e.comentarioQuestionarioPsi25 !== undefined ? e.comentarioQuestionarioPsi25 : '',
            e.perguntaQuestionarioPsi26 !== undefined ? e.perguntaQuestionarioPsi26 : '',
            e.respostaQuestionarioPsi26 !== undefined ? e.respostaQuestionarioPsi26 : '',
            e.comentarioQuestionarioPsi26 !== undefined ? e.comentarioQuestionarioPsi26 : '', 
                        ]) : y.push(
            ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''])
                )

            //console.log('***y',y[0],'***length',y[0].length)
        
        return [
                {text: '\n'},
                {
                    text: 'Questionário psiquiátrico',
                    style: 'subheader'
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment:'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        //alignment:'center',
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text:  'Perguunta' , style: 'cabecalhoTabela'/* ,alignment:'center' */},
                                {text:  'Resposta' , style: 'cabecalhoTabela'/* ,alignment:'center' */},
                                {text:  'Comentário' , style: 'cabecalhoTabela'/* ,alignment:'center' */},
                            ],
                            /* [
                                //DADOS DO CABECALHO E ESTILO
                                {text:  'Perguunta' , style: 'cabecalhoTabela'},
                                {text:  'Resposta' , style: 'cabecalhoTabela'},
                                {text:  'Comentário' , style: 'cabecalhoTabela'},
                            ],
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text:  'Perguunta' , style: 'cabecalhoTabela'},
                                {text:  'Resposta' , style: 'cabecalhoTabela'},
                                {text:  'Comentário' , style: 'cabecalhoTabela'},
                            ], */
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' || y[0][20]!== '') ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' || y[0][23]!== '') ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' || y[0][26]!== '') ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' || y[0][29]!== '') ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' || y[0][32]!== '') ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                            y.length > 0 && (y[0][34] !== '' || y[0][35]!== '') ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                            y.length > 0 && (y[0][37] !== '' || y[0][38]!== '') ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                            y.length > 0 && (y[0][40] !== '' || y[0][41]!== '') ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                            y.length > 0 && (y[0][43] !== '' || y[0][44]!== '') ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                            y.length > 0 && (y[0][46] !== '' || y[0][47]!== '') ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                            y.length > 0 && (y[0][49] !== '' || y[0][50]!== '') ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                            y.length > 0 && (y[0][52] !== '' || y[0][53]!== '') ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                            y.length > 0 && (y[0][55] !== '' || y[0][56]!== '') ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                            y.length > 0 && (y[0][58] !== '' || y[0][59]!== '') ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                            y.length > 0 && (y[0][61] !== '' || y[0][62]!== '') ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],
                            y.length > 0 && (y[0][64] !== '' || y[0][65]!== '') ? [[y[0][63]],[y[0][64]],[y[0][65]]] : ['','',''], 
                            y.length > 0 && (y[0][67] !== '' || y[0][68]!== '') ? [[y[0][66]],[y[0][67]],[y[0][68]]] : ['','',''],
                            y.length > 0 && (y[0][70] !== '' || y[0][71]!== '') ? [[y[0][69]],[y[0][70]],[y[0][71]]] : ['','',''],
                            y.length > 0 && (y[0][73] !== '' || y[0][74]!== '') ? [[y[0][72]],[y[0][73]],[y[0][74]]] : ['','',''],
                            y.length > 0 && (y[0][76] !== '' || y[0][77]!== '') ? [[y[0][75]],[y[0][76]],[y[0][77]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }      
} 

const dadosExameMentalPsiPdf = () => {

    let y = []
                if (tudo._21_exameMentalPsi && tudo._21_exameMentalPsi.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._21_exameMentalPsi !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._21_exameMentalPsi !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._21_exameMentalPsi : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
                            e.perguntaExameMentalPsi0 !== undefined ? e.perguntaExameMentalPsi0 : '',
            e.respostaExameMentalPsi0 !== undefined ? e.respostaExameMentalPsi0 : '',
            e.comentarioExameMentalPsi0 !== undefined ? e.comentarioExameMentalPsi0 : '',
            e.perguntaExameMentalPsi1 !== undefined ? e.perguntaExameMentalPsi1 : '',
            e.respostaExameMentalPsi1 !== undefined ? e.respostaExameMentalPsi1 : '',
            e.comentarioExameMentalPsi1 !== undefined ? e.comentarioExameMentalPsi1 : '',
            e.perguntaExameMentalPsi2 !== undefined ? e.perguntaExameMentalPsi2 : '',
            e.respostaExameMentalPsi2 !== undefined ? e.respostaExameMentalPsi2 : '',
            e.comentarioExameMentalPsi2 !== undefined ? e.comentarioExameMentalPsi2 : '',
            e.perguntaExameMentalPsi3 !== undefined ? e.perguntaExameMentalPsi3 : '',
            e.respostaExameMentalPsi3 !== undefined ? e.respostaExameMentalPsi3 : '',
            e.comentarioExameMentalPsi3 !== undefined ? e.comentarioExameMentalPsi3 : '',
            e.perguntaExameMentalPsi4 !== undefined ? e.perguntaExameMentalPsi4 : '',
            e.respostaExameMentalPsi4 !== undefined ? e.respostaExameMentalPsi4 : '',
            e.comentarioExameMentalPsi4 !== undefined ? e.comentarioExameMentalPsi4 : '',
            e.perguntaExameMentalPsi5 !== undefined ? e.perguntaExameMentalPsi5 : '',
            e.respostaExameMentalPsi5 !== undefined ? e.respostaExameMentalPsi5 : '',
            e.comentarioExameMentalPsi5 !== undefined ? e.comentarioExameMentalPsi5 : '',
            e.perguntaExameMentalPsi6 !== undefined ? e.perguntaExameMentalPsi6 : '',
            e.respostaExameMentalPsi6 !== undefined ? e.respostaExameMentalPsi6 : '',
            e.comentarioExameMentalPsi6 !== undefined ? e.comentarioExameMentalPsi6 : '',
            e.perguntaExameMentalPsi7 !== undefined ? e.perguntaExameMentalPsi7 : '',
            e.respostaExameMentalPsi7 !== undefined ? e.respostaExameMentalPsi7 : '',
            e.comentarioExameMentalPsi7 !== undefined ? e.comentarioExameMentalPsi7 : '',
            e.perguntaExameMentalPsi8 !== undefined ? e.perguntaExameMentalPsi8 : '',
            e.respostaExameMentalPsi8 !== undefined ? e.respostaExameMentalPsi8 : '',
            e.comentarioExameMentalPsi8 !== undefined ? e.comentarioExameMentalPsi8 : '',
            e.perguntaExameMentalPsi9 !== undefined ? e.perguntaExameMentalPsi9 : '',
            e.respostaExameMentalPsi9 !== undefined ? e.respostaExameMentalPsi9 : '',
            e.comentarioExameMentalPsi9 !== undefined ? e.comentarioExameMentalPsi9 : '',
            e.perguntaExameMentalPsi10 !== undefined ? e.perguntaExameMentalPsi10 : '',
            e.respostaExameMentalPsi10 !== undefined ? e.respostaExameMentalPsi10 : '',
            e.comentarioExameMentalPsi10 !== undefined ? e.comentarioExameMentalPsi10 : '',
            e.perguntaExameMentalPsi11 !== undefined ? e.perguntaExameMentalPsi11 : '',
            e.respostaExameMentalPsi11 !== undefined ? e.respostaExameMentalPsi11 : '',
            e.comentarioExameMentalPsi11 !== undefined ? e.comentarioExameMentalPsi11 : '',
            e.perguntaExameMentalPsi12 !== undefined ? e.perguntaExameMentalPsi12 : '',
            e.respostaExameMentalPsi12 !== undefined ? e.respostaExameMentalPsi12 : '',
            e.comentarioExameMentalPsi12 !== undefined ? e.comentarioExameMentalPsi12 : '',
            e.perguntaExameMentalPsi13 !== undefined ? e.perguntaExameMentalPsi13 : '',
            e.respostaExameMentalPsi13 !== undefined ? e.respostaExameMentalPsi13 : '',
            e.comentarioExameMentalPsi13 !== undefined ? e.comentarioExameMentalPsi13 : '',
            e.perguntaExameMentalPsi14 !== undefined ? e.perguntaExameMentalPsi14 : '',
            e.respostaExameMentalPsi14 !== undefined ? e.respostaExameMentalPsi14 : '',
            e.comentarioExameMentalPsi14 !== undefined ? e.comentarioExameMentalPsi14 : '',
            e.perguntaExameMentalPsi15 !== undefined ? e.perguntaExameMentalPsi15 : '',
            e.respostaExameMentalPsi15 !== undefined ? e.respostaExameMentalPsi15 : '',
            e.comentarioExameMentalPsi15 !== undefined ? e.comentarioExameMentalPsi15 : '',
            e.perguntaExameMentalPsi16 !== undefined ? e.perguntaExameMentalPsi16 : '',
            e.respostaExameMentalPsi16 !== undefined ? e.respostaExameMentalPsi16 : '',
            e.comentarioExameMentalPsi16 !== undefined ? e.comentarioExameMentalPsi16 : '',
            e.perguntaExameMentalPsi17 !== undefined ? e.perguntaExameMentalPsi17 : '',
            e.respostaExameMentalPsi17 !== undefined ? e.respostaExameMentalPsi17 : '',
            e.comentarioExameMentalPsi17 !== undefined ? e.comentarioExameMentalPsi17 : '',
            e.perguntaExameMentalPsi18 !== undefined ? e.perguntaExameMentalPsi18 : '',
            e.respostaExameMentalPsi18 !== undefined ? e.respostaExameMentalPsi18 : '',
            e.comentarioExameMentalPsi18 !== undefined ? e.comentarioExameMentalPsi18 : '',
            e.perguntaExameMentalPsi19 !== undefined ? e.perguntaExameMentalPsi19 : '',
            e.respostaExameMentalPsi19 !== undefined ? e.respostaExameMentalPsi19 : '',
            e.comentarioExameMentalPsi19 !== undefined ? e.comentarioExameMentalPsi19 : '',
            e.perguntaExameMentalPsi20 !== undefined ? e.perguntaExameMentalPsi20 : '',
            e.respostaExameMentalPsi20 !== undefined ? e.respostaExameMentalPsi20 : '',
            e.comentarioExameMentalPsi20 !== undefined ? e.comentarioExameMentalPsi20 : '',
            e.perguntaExameMentalPsi21 !== undefined ? e.perguntaExameMentalPsi21 : '',
            e.respostaExameMentalPsi21 !== undefined ? e.respostaExameMentalPsi21 : '',
            e.comentarioExameMentalPsi21 !== undefined ? e.comentarioExameMentalPsi21 : '',
            e.perguntaExameMentalPsi22 !== undefined ? e.perguntaExameMentalPsi22 : '',
            e.respostaExameMentalPsi22 !== undefined ? e.respostaExameMentalPsi22 : '',
            e.comentarioExameMentalPsi22 !== undefined ? e.comentarioExameMentalPsi22 : '',
            e.perguntaExameMentalPsi23 !== undefined ? e.perguntaExameMentalPsi23 : '',
            e.respostaExameMentalPsi23 !== undefined ? e.respostaExameMentalPsi23 : '',
            e.comentarioExameMentalPsi23 !== undefined ? e.comentarioExameMentalPsi23 : '',
            e.perguntaExameMentalPsi24 !== undefined ? e.perguntaExameMentalPsi24 : '',
            e.respostaExameMentalPsi24 !== undefined ? e.respostaExameMentalPsi24 : '',
            e.comentarioExameMentalPsi24 !== undefined ? e.comentarioExameMentalPsi24 : '',
            e.perguntaExameMentalPsi25 !== undefined ? e.perguntaExameMentalPsi25 : '',
            e.respostaExameMentalPsi25 !== undefined ? e.respostaExameMentalPsi25 : '',
            e.comentarioExameMentalPsi25 !== undefined ? e.comentarioExameMentalPsi25 : '',
            e.perguntaExameMentalPsi26 !== undefined ? e.perguntaExameMentalPsi26 : '',
            e.respostaExameMentalPsi26 !== undefined ? e.respostaExameMentalPsi26 : '',
            e.comentarioExameMentalPsi26 !== undefined ? e.comentarioExameMentalPsi26 : '',
            e.perguntaExameMentalPsi27 !== undefined ? e.perguntaExameMentalPsi27 : '',
            e.respostaExameMentalPsi27 !== undefined ? e.respostaExameMentalPsi27 : '',
            e.comentarioExameMentalPsi27 !== undefined ? e.comentarioExameMentalPsi27 : '',
            e.perguntaExameMentalPsi28 !== undefined ? e.perguntaExameMentalPsi28 : '',
            e.respostaExameMentalPsi28 !== undefined ? e.respostaExameMentalPsi28 : '',
            e.comentarioExameMentalPsi28 !== undefined ? e.comentarioExameMentalPsi28 : '',
            e.perguntaExameMentalPsi29 !== undefined ? e.perguntaExameMentalPsi29 : '',
            e.respostaExameMentalPsi29 !== undefined ? e.respostaExameMentalPsi29 : '',
            e.comentarioExameMentalPsi29 !== undefined ? e.comentarioExameMentalPsi29 : '',
            e.perguntaExameMentalPsi30 !== undefined ? e.perguntaExameMentalPsi30 : '',
            e.respostaExameMentalPsi30 !== undefined ? e.respostaExameMentalPsi30 : '',
            e.comentarioExameMentalPsi30 !== undefined ? e.comentarioExameMentalPsi30 : '',
            e.perguntaExameMentalPsi31 !== undefined ? e.perguntaExameMentalPsi31 : '',
            e.respostaExameMentalPsi31 !== undefined ? e.respostaExameMentalPsi31 : '',
            e.comentarioExameMentalPsi31 !== undefined ? e.comentarioExameMentalPsi31 : '',
            e.perguntaExameMentalPsi32 !== undefined ? e.perguntaExameMentalPsi32 : '',
            e.respostaExameMentalPsi32 !== undefined ? e.respostaExameMentalPsi32 : '',
            e.comentarioExameMentalPsi32 !== undefined ? e.comentarioExameMentalPsi32 : '',
            e.perguntaExameMentalPsi33 !== undefined ? e.perguntaExameMentalPsi33 : '',
            e.respostaExameMentalPsi33 !== undefined ? e.respostaExameMentalPsi33 : '',
            e.comentarioExameMentalPsi33 !== undefined ? e.comentarioExameMentalPsi33 : '',
            e.perguntaExameMentalPsi34 !== undefined ? e.perguntaExameMentalPsi34 : '',
            e.respostaExameMentalPsi34 !== undefined ? e.respostaExameMentalPsi34 : '',
            e.comentarioExameMentalPsi34 !== undefined ? e.comentarioExameMentalPsi34 : '',
            e.perguntaExameMentalPsi35 !== undefined ? e.perguntaExameMentalPsi35 : '',
            e.respostaExameMentalPsi35 !== undefined ? e.respostaExameMentalPsi35 : '',
            e.comentarioExameMentalPsi35 !== undefined ? e.comentarioExameMentalPsi35 : '',
            e.perguntaExameMentalPsi36 !== undefined ? e.perguntaExameMentalPsi36 : '',
            e.respostaExameMentalPsi36 !== undefined ? e.respostaExameMentalPsi36 : '',
            e.comentarioExameMentalPsi36 !== undefined ? e.comentarioExameMentalPsi36 : '',
            e.perguntaExameMentalPsi37 !== undefined ? e.perguntaExameMentalPsi37 : '',
            e.respostaExameMentalPsi37 !== undefined ? e.respostaExameMentalPsi37 : '',
            e.comentarioExameMentalPsi37 !== undefined ? e.comentarioExameMentalPsi37 : '',
            e.perguntaExameMentalPsi38 !== undefined ? e.perguntaExameMentalPsi38 : '',
            e.respostaExameMentalPsi38 !== undefined ? e.respostaExameMentalPsi38 : '',
            e.comentarioExameMentalPsi38 !== undefined ? e.comentarioExameMentalPsi38 : '',
            e.perguntaExameMentalPsi39 !== undefined ? e.perguntaExameMentalPsi39 : '',
            e.respostaExameMentalPsi39 !== undefined ? e.respostaExameMentalPsi39 : '',
            e.comentarioExameMentalPsi39 !== undefined ? e.comentarioExameMentalPsi39 : '',
            e.perguntaExameMentalPsi40 !== undefined ? e.perguntaExameMentalPsi40 : '',
            e.respostaExameMentalPsi40 !== undefined ? e.respostaExameMentalPsi40 : '',
            e.comentarioExameMentalPsi40 !== undefined ? e.comentarioExameMentalPsi40 : '',
            e.perguntaExameMentalPsi41 !== undefined ? e.perguntaExameMentalPsi41 : '',
            e.respostaExameMentalPsi41 !== undefined ? e.respostaExameMentalPsi41 : '',
            e.comentarioExameMentalPsi41 !== undefined ? e.comentarioExameMentalPsi41 : '',
            e.perguntaExameMentalPsi42 !== undefined ? e.perguntaExameMentalPsi42 : '',
            e.respostaExameMentalPsi42 !== undefined ? e.respostaExameMentalPsi42 : '',
            e.comentarioExameMentalPsi42 !== undefined ? e.comentarioExameMentalPsi42 : '',
            e.perguntaExameMentalPsi43 !== undefined ? e.perguntaExameMentalPsi43 : '',
            e.respostaExameMentalPsi43 !== undefined ? e.respostaExameMentalPsi43 : '',
            e.comentarioExameMentalPsi43 !== undefined ? e.comentarioExameMentalPsi43 : '',
            e.perguntaExameMentalPsi44 !== undefined ? e.perguntaExameMentalPsi44 : '',
            e.respostaExameMentalPsi44 !== undefined ? e.respostaExameMentalPsi44 : '',
            e.comentarioExameMentalPsi44 !== undefined ? e.comentarioExameMentalPsi44 : '',
            e.perguntaExameMentalPsi45 !== undefined ? e.perguntaExameMentalPsi45 : '',
            e.respostaExameMentalPsi45 !== undefined ? e.respostaExameMentalPsi45 : '',
            e.comentarioExameMentalPsi45 !== undefined ? e.comentarioExameMentalPsi45 : '',
            e.perguntaExameMentalPsi46 !== undefined ? e.perguntaExameMentalPsi46 : '',
            e.respostaExameMentalPsi46 !== undefined ? e.respostaExameMentalPsi46 : '',
            e.comentarioExameMentalPsi46 !== undefined ? e.comentarioExameMentalPsi46 : '',
            e.perguntaExameMentalPsi47 !== undefined ? e.perguntaExameMentalPsi47 : '',
            e.respostaExameMentalPsi47 !== undefined ? e.respostaExameMentalPsi47 : '',
            e.comentarioExameMentalPsi47 !== undefined ? e.comentarioExameMentalPsi47 : '',
            e.perguntaExameMentalPsi48 !== undefined ? e.perguntaExameMentalPsi48 : '',
            e.respostaExameMentalPsi48 !== undefined ? e.respostaExameMentalPsi48 : '',
            e.comentarioExameMentalPsi48 !== undefined ? e.comentarioExameMentalPsi48 : '',
            e.perguntaExameMentalPsi49 !== undefined ? e.perguntaExameMentalPsi49 : '',
            e.respostaExameMentalPsi49 !== undefined ? e.respostaExameMentalPsi49 : '',
            e.comentarioExameMentalPsi49 !== undefined ? e.comentarioExameMentalPsi49 : '',
            e.perguntaExameMentalPsi50 !== undefined ? e.perguntaExameMentalPsi50 : '',
            e.respostaExameMentalPsi50 !== undefined ? e.respostaExameMentalPsi50 : '',
            e.comentarioExameMentalPsi50 !== undefined ? e.comentarioExameMentalPsi50 : '',
            e.perguntaExameMentalPsi51 !== undefined ? e.perguntaExameMentalPsi51 : '',
            e.respostaExameMentalPsi51 !== undefined ? e.respostaExameMentalPsi51 : '',
            e.comentarioExameMentalPsi51 !== undefined ? e.comentarioExameMentalPsi51 : '',
            e.perguntaExameMentalPsiConfiabilidade !== undefined ? e.perguntaExameMentalPsiConfiabilidade : '',
            e.respostaExameMentalPsiConfiabilidade !== undefined ? e.respostaExameMentalPsiConfiabilidade : '',
            e.comentarioExameMentalPsiConfiabilidade !== undefined ? e.comentarioExameMentalPsiConfiabilidade : '',
                        ]) : y.push(
            ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
    
            return [
                {text: '\n'},
                {
                    text: 'Dados do exame mental',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' || y[0][20]!== '') ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' || y[0][23]!== '') ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' || y[0][26]!== '') ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' || y[0][29]!== '') ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' || y[0][32]!== '') ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                            y.length > 0 && (y[0][34] !== '' || y[0][35]!== '') ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                            y.length > 0 && (y[0][37] !== '' || y[0][38]!== '') ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                            y.length > 0 && (y[0][40] !== '' || y[0][41]!== '') ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                            y.length > 0 && (y[0][43] !== '' || y[0][44]!== '') ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                            y.length > 0 && (y[0][46] !== '' || y[0][47]!== '') ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                            y.length > 0 && (y[0][49] !== '' || y[0][50]!== '') ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                            y.length > 0 && (y[0][52] !== '' || y[0][53]!== '') ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                            y.length > 0 && (y[0][55] !== '' || y[0][56]!== '') ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                            y.length > 0 && (y[0][58] !== '' || y[0][59]!== '') ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                            y.length > 0 && (y[0][61] !== '' || y[0][62]!== '') ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],
                            y.length > 0 && (y[0][64] !== '' || y[0][65]!== '') ? [[y[0][63]],[y[0][64]],[y[0][65]]] : ['','',''], 
                            y.length > 0 && (y[0][67] !== '' || y[0][68]!== '') ? [[y[0][66]],[y[0][67]],[y[0][68]]] : ['','',''],
                            y.length > 0 && (y[0][70] !== '' || y[0][71]!== '') ? [[y[0][69]],[y[0][70]],[y[0][71]]] : ['','',''],
                            y.length > 0 && (y[0][73] !== '' || y[0][74]!== '') ? [[y[0][72]],[y[0][73]],[y[0][74]]] : ['','',''],
                            y.length > 0 && (y[0][76] !== '' || y[0][77]!== '') ? [[y[0][75]],[y[0][76]],[y[0][77]]] : ['','',''],
                            y.length > 0 && (y[0][79] !== '' || y[0][80]!== '') ? [[y[0][78]],[y[0][79]],[y[0][80]]] : ['','',''],
                            y.length > 0 && (y[0][82] !== '' || y[0][83]!== '') ? [[y[0][81]],[y[0][82]],[y[0][83]]] : ['','',''],
                            y.length > 0 && (y[0][85] !== '' || y[0][86]!== '') ? [[y[0][84]],[y[0][85]],[y[0][86]]] : ['','',''],
                            y.length > 0 && (y[0][88] !== '' || y[0][89]!== '') ? [[y[0][87]],[y[0][88]],[y[0][89]]] : ['','',''],
                            y.length > 0 && (y[0][91] !== '' || y[0][92]!== '') ? [[y[0][90]],[y[0][91]],[y[0][92]]] : ['','',''],
                            y.length > 0 && (y[0][94] !== '' || y[0][95]!== '') ? [[y[0][93]],[y[0][94]],[y[0][95]]] : ['','',''],
                            y.length > 0 && (y[0][97] !== '' || y[0][98]!== '') ? [[y[0][96]],[y[0][97]],[y[0][98]]] : ['','',''],
                            y.length > 0 && (y[0][100] !== '' || y[0][101]!== '') ? [[y[0][99]],[y[0][100]],[y[0][101]]] : ['','',''],
                            y.length > 0 && (y[0][103] !== '' || y[0][104]!== '') ? [[y[0][102]],[y[0][103]],[y[0][104]]] : ['','',''],
                            y.length > 0 && (y[0][106] !== '' || y[0][107]!== '') ? [[y[0][105]],[y[0][106]],[y[0][107]]] : ['','',''],
                            y.length > 0 && (y[0][109] !== '' || y[0][110]!== '') ? [[y[0][108]],[y[0][109]],[y[0][110]]] : ['','',''],
                            y.length > 0 && (y[0][112] !== '' || y[0][113]!== '') ? [[y[0][111]],[y[0][112]],[y[0][113]]] : ['','',''],
                            y.length > 0 && (y[0][115] !== '' || y[0][116]!== '') ? [[y[0][114]],[y[0][115]],[y[0][116]]] : ['','',''],
                            y.length > 0 && (y[0][118] !== '' || y[0][119]!== '') ? [[y[0][117]],[y[0][118]],[y[0][119]]] : ['','',''],
                            y.length > 0 && (y[0][121] !== '' || y[0][122]!== '') ? [[y[0][120]],[y[0][121]],[y[0][122]]] : ['','',''],
                            y.length > 0 && (y[0][124] !== '' || y[0][125]!== '') ? [[y[0][123]],[y[0][124]],[y[0][125]]] : ['','',''],
                            y.length > 0 && (y[0][127] !== '' || y[0][128]!== '') ? [[y[0][126]],[y[0][127]],[y[0][128]]] : ['','',''],
                            y.length > 0 && (y[0][130] !== '' || y[0][131]!== '') ? [[y[0][129]],[y[0][130]],[y[0][131]]] : ['','',''],
                            y.length > 0 && (y[0][133] !== '' || y[0][134]!== '') ? [[y[0][132]],[y[0][133]],[y[0][134]]] : ['','',''],
                            y.length > 0 && (y[0][136] !== '' || y[0][137]!== '') ? [[y[0][135]],[y[0][136]],[y[0][137]]] : ['','',''],
                            y.length > 0 && (y[0][139] !== '' || y[0][140]!== '') ? [[y[0][138]],[y[0][139]],[y[0][140]]] : ['','',''],
                            y.length > 0 && (y[0][142] !== '' || y[0][143]!== '') ? [[y[0][141]],[y[0][142]],[y[0][143]]] : ['','',''], 
                            y.length > 0 && (y[0][145] !== '' || y[0][146]!== '') ? [[y[0][144]],[y[0][145]],[y[0][146]]] : ['','',''],
                            y.length > 0 && (y[0][148] !== '' || y[0][149]!== '') ? [[y[0][147]],[y[0][148]],[y[0][149]]] : ['','',''],
                            y.length > 0 && (y[0][151] !== '' || y[0][152]!== '') ? [[y[0][150]],[y[0][151]],[y[0][152]]] : ['','',''],
                            y.length > 0 && (y[0][154] !== '' || y[0][155]!== '') ? [[y[0][153]],[y[0][154]],[y[0][155]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }      
} 

const dadosAnamneseAudioPdf = () => {
    let y = []
                if (tudo._22_anamneseAudio && tudo._22_anamneseAudio.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._22_anamneseAudio !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._22_anamneseAudio !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._22_anamneseAudio : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
                            e.perguntaAnamneseAudio0 !== undefined ? e.perguntaAnamneseAudio0 : '',
            e.respostaAnamneseAudio0 !== undefined ? e.respostaAnamneseAudio0 : '',
            e.comentarioAnamneseAudio0 !== undefined ? e.comentarioAnamneseAudio0 : '',
            e.perguntaAnamneseAudio1 !== undefined ? e.perguntaAnamneseAudio1 : '',
            e.respostaAnamneseAudio1 !== undefined ? e.respostaAnamneseAudio1 : '',
            e.comentarioAnamneseAudio1 !== undefined ? e.comentarioAnamneseAudio1 : '',
            e.perguntaAnamneseAudio2 !== undefined ? e.perguntaAnamneseAudio2 : '',
            e.respostaAnamneseAudio2 !== undefined ? e.respostaAnamneseAudio2 : '',
            e.comentarioAnamneseAudio2 !== undefined ? e.comentarioAnamneseAudio2 : '',
            e.perguntaAnamneseAudio3 !== undefined ? e.perguntaAnamneseAudio3 : '',
            e.respostaAnamneseAudio3 !== undefined ? e.respostaAnamneseAudio3 : '',
            e.comentarioAnamneseAudio3 !== undefined ? e.comentarioAnamneseAudio3 : '',
            e.perguntaAnamneseAudio4 !== undefined ? e.perguntaAnamneseAudio4 : '',
            e.respostaAnamneseAudio4 !== undefined ? e.respostaAnamneseAudio4 : '',
            e.comentarioAnamneseAudio4 !== undefined ? e.comentarioAnamneseAudio4 : '',
            e.perguntaAnamneseAudio5 !== undefined ? e.perguntaAnamneseAudio5 : '',
            e.respostaAnamneseAudio5 !== undefined ? e.respostaAnamneseAudio5 : '',
            e.comentarioAnamneseAudio5 !== undefined ? e.comentarioAnamneseAudio5 : '',
            e.perguntaAnamneseAudio6 !== undefined ? e.perguntaAnamneseAudio6 : '',
            e.respostaAnamneseAudio6 !== undefined ? e.respostaAnamneseAudio6 : '',
            e.comentarioAnamneseAudio6 !== undefined ? e.comentarioAnamneseAudio6 : '',
            e.perguntaAnamneseAudio7 !== undefined ? e.perguntaAnamneseAudio7 : '',
            e.respostaAnamneseAudio7 !== undefined ? e.respostaAnamneseAudio7 : '',
            e.comentarioAnamneseAudio7 !== undefined ? e.comentarioAnamneseAudio7 : '',
            e.perguntaAnamneseAudio8 !== undefined ? e.perguntaAnamneseAudio8 : '',
            e.respostaAnamneseAudio8 !== undefined ? e.respostaAnamneseAudio8 : '',
            e.comentarioAnamneseAudio8 !== undefined ? e.comentarioAnamneseAudio8 : '',
            e.perguntaAnamneseAudio9 !== undefined ? e.perguntaAnamneseAudio9 : '',
            e.respostaAnamneseAudio9 !== undefined ? e.respostaAnamneseAudio9 : '',
            e.comentarioAnamneseAudio9 !== undefined ? e.comentarioAnamneseAudio9 : '',
            e.perguntaAnamneseAudio10 !== undefined ? e.perguntaAnamneseAudio10 : '',
            e.respostaAnamneseAudio10 !== undefined ? e.respostaAnamneseAudio10 : '',
            e.comentarioAnamneseAudio10 !== undefined ? e.comentarioAnamneseAudio10 : '',
            e.perguntaAnamneseAudio11 !== undefined ? e.perguntaAnamneseAudio11 : '',
            e.respostaAnamneseAudio11 !== undefined ? e.respostaAnamneseAudio11 : '',
            e.comentarioAnamneseAudio11 !== undefined ? e.comentarioAnamneseAudio11 : '',
            e.perguntaAnamneseAudio12 !== undefined ? e.perguntaAnamneseAudio12 : '',
            e.respostaAnamneseAudio12 !== undefined ? e.respostaAnamneseAudio12 : '',
            e.comentarioAnamneseAudio12 !== undefined ? e.comentarioAnamneseAudio12 : '',
            e.perguntaAnamneseAudio13 !== undefined ? e.perguntaAnamneseAudio13 : '',
            e.respostaAnamneseAudio13 !== undefined ? e.respostaAnamneseAudio13 : '',
            e.comentarioAnamneseAudio13 !== undefined ? e.comentarioAnamneseAudio13 : '',
            e.perguntaAnamneseAudio14 !== undefined ? e.perguntaAnamneseAudio14 : '',
            e.respostaAnamneseAudio14 !== undefined ? e.respostaAnamneseAudio14 : '',
            e.comentarioAnamneseAudio14 !== undefined ? e.comentarioAnamneseAudio14 : '',
            e.perguntaAnamneseAudio15 !== undefined ? e.perguntaAnamneseAudio15 : '',
            e.respostaAnamneseAudio15 !== undefined ? e.respostaAnamneseAudio15 : '',
            e.comentarioAnamneseAudio15 !== undefined ? e.comentarioAnamneseAudio15 : '',
            e.perguntaAnamneseAudio16 !== undefined ? e.perguntaAnamneseAudio16 : '',
            e.respostaAnamneseAudio16 !== undefined ? e.respostaAnamneseAudio16 : '',
            e.comentarioAnamneseAudio16 !== undefined ? e.comentarioAnamneseAudio16 : '',
            e.perguntaAnamneseAudio17 !== undefined ? e.perguntaAnamneseAudio17 : '',
            e.respostaAnamneseAudio17 !== undefined ? e.respostaAnamneseAudio17 : '',
            e.comentarioAnamneseAudio17 !== undefined ? e.comentarioAnamneseAudio17 : '',
            e.perguntaAnamneseAudio18 !== undefined ? e.perguntaAnamneseAudio18 : '',
            e.respostaAnamneseAudio18 !== undefined ? e.respostaAnamneseAudio18 : '',
            e.comentarioAnamneseAudio18 !== undefined ? e.comentarioAnamneseAudio18 : '',
            e.perguntaAnamneseAudio19 !== undefined ? e.perguntaAnamneseAudio19 : '',
            e.respostaAnamneseAudio19 !== undefined ? e.respostaAnamneseAudio19 : '',
            e.comentarioAnamneseAudio19 !== undefined ? e.comentarioAnamneseAudio19 : '',
            e.perguntaAnamneseAudio20 !== undefined ? e.perguntaAnamneseAudio20 : '',
            e.respostaAnamneseAudio20 !== undefined ? e.respostaAnamneseAudio20 : '',
            e.comentarioAnamneseAudio20 !== undefined ? e.comentarioAnamneseAudio20 : '',
            e.perguntaAnamneseAudio21 !== undefined ? e.perguntaAnamneseAudio21 : '',
            e.respostaAnamneseAudio21 !== undefined ? e.respostaAnamneseAudio21 : '',
            e.comentarioAnamneseAudio21 !== undefined ? e.comentarioAnamneseAudio21 : '',
            e.perguntaAnamneseAudio22 !== undefined ? e.perguntaAnamneseAudio22 : '',
            e.respostaAnamneseAudio22 !== undefined ? e.respostaAnamneseAudio22 : '',
            e.comentarioAnamneseAudio22 !== undefined ? e.comentarioAnamneseAudio22 : '',
            e.perguntaAnamneseAudio23 !== undefined ? e.perguntaAnamneseAudio23 : '',
            e.respostaAnamneseAudio23 !== undefined ? e.respostaAnamneseAudio23 : '',
            e.comentarioAnamneseAudio23 !== undefined ? e.comentarioAnamneseAudio23 : '',
            e.perguntaAnamneseAudio24 !== undefined ? e.perguntaAnamneseAudio24 : '',
            e.respostaAnamneseAudio24 !== undefined ? e.respostaAnamneseAudio24 : '',
            e.comentarioAnamneseAudio24 !== undefined ? e.comentarioAnamneseAudio24 : '',
            e.perguntaAnamneseAudio25 !== undefined ? e.perguntaAnamneseAudio25 : '',
            e.respostaAnamneseAudio25 !== undefined ? e.respostaAnamneseAudio25 : '',
            e.comentarioAnamneseAudio25 !== undefined ? e.comentarioAnamneseAudio25 : '',
            e.perguntaAnamneseAudio26 !== undefined ? e.perguntaAnamneseAudio26 : '',
            e.respostaAnamneseAudio26 !== undefined ? e.respostaAnamneseAudio26 : '',
            e.comentarioAnamneseAudio26 !== undefined ? e.comentarioAnamneseAudio26 : '',
            e.perguntaAnamneseAudio27 !== undefined ? e.perguntaAnamneseAudio27 : '',
            e.respostaAnamneseAudio27 !== undefined ? e.respostaAnamneseAudio27 : '',
            e.comentarioAnamneseAudio27 !== undefined ? e.comentarioAnamneseAudio27 : '',
            e.perguntaAnamneseAudio28 !== undefined ? e.perguntaAnamneseAudio28 : '',
            e.respostaAnamneseAudio28 !== undefined ? e.respostaAnamneseAudio28 : '',
            e.comentarioAnamneseAudio28 !== undefined ? e.comentarioAnamneseAudio28 : '',
            e.perguntaAnamneseAudio29 !== undefined ? e.perguntaAnamneseAudio29 : '',
            e.respostaAnamneseAudio29 !== undefined ? e.respostaAnamneseAudio29 : '',
            e.comentarioAnamneseAudio29 !== undefined ? e.comentarioAnamneseAudio29 : '',
            e.perguntaAnamneseAudio30 !== undefined ? e.perguntaAnamneseAudio30 : '',
            e.respostaAnamneseAudio30 !== undefined ? e.respostaAnamneseAudio30 : '',
            e.comentarioAnamneseAudio30 !== undefined ? e.comentarioAnamneseAudio30 : '',
            e.perguntaAnamneseAudio31 !== undefined ? e.perguntaAnamneseAudio31 : '',
            e.respostaAnamneseAudio31 !== undefined ? e.respostaAnamneseAudio31 : '',
            e.comentarioAnamneseAudio31 !== undefined ? e.comentarioAnamneseAudio31 : '',
            e.perguntaAnamneseAudio32 !== undefined ? e.perguntaAnamneseAudio32 : '',
            e.respostaAnamneseAudio32 !== undefined ? e.respostaAnamneseAudio32 : '',
            e.comentarioAnamneseAudio32 !== undefined ? e.comentarioAnamneseAudio32 : '',
            e.perguntaAnamneseAudio33 !== undefined ? e.perguntaAnamneseAudio33 : '',
            e.respostaAnamneseAudio33 !== undefined ? e.respostaAnamneseAudio33 : '',
            e.comentarioAnamneseAudio33 !== undefined ? e.comentarioAnamneseAudio33 : '',
            e.perguntaAnamneseAudio34 !== undefined ? e.perguntaAnamneseAudio34 : '',
            e.respostaAnamneseAudio34 !== undefined ? e.respostaAnamneseAudio34 : '',
            e.comentarioAnamneseAudio34 !== undefined ? e.comentarioAnamneseAudio34 : '',
            e.perguntaAnamneseAudio35 !== undefined ? e.perguntaAnamneseAudio35 : '',
            e.respostaAnamneseAudio35 !== undefined ? e.respostaAnamneseAudio35 : '',
            e.comentarioAnamneseAudio35 !== undefined ? e.comentarioAnamneseAudio35 : '',
            e.perguntaAnamneseAudio36 !== undefined ? e.perguntaAnamneseAudio36 : '',
            e.respostaAnamneseAudio36 !== undefined ? e.respostaAnamneseAudio36 : '',
            e.comentarioAnamneseAudio36 !== undefined ? e.comentarioAnamneseAudio36 : '',
            e.perguntaAnamneseAudio37 !== undefined ? e.perguntaAnamneseAudio37 : '',
            e.respostaAnamneseAudio37 !== undefined ? e.respostaAnamneseAudio37 : '',
            e.comentarioAnamneseAudio37 !== undefined ? e.comentarioAnamneseAudio37 : '',
            e.perguntaAnamneseAudio38 !== undefined ? e.perguntaAnamneseAudio38 : '',
            e.respostaAnamneseAudio38 !== undefined ? e.respostaAnamneseAudio38 : '',
            e.comentarioAnamneseAudio38 !== undefined ? e.comentarioAnamneseAudio38 : '',
            e.perguntaAnamneseAudio39 !== undefined ? e.perguntaAnamneseAudio39 : '',
            e.respostaAnamneseAudio39 !== undefined ? e.respostaAnamneseAudio39 : '',
            e.comentarioAnamneseAudio39 !== undefined ? e.comentarioAnamneseAudio39 : '',
            e.perguntaAnamneseAudio40 !== undefined ? e.perguntaAnamneseAudio40 : '',
            e.respostaAnamneseAudio40 !== undefined ? e.respostaAnamneseAudio40 : '',
            e.comentarioAnamneseAudio40 !== undefined ? e.comentarioAnamneseAudio40 : '',
                        ]) : y.push(
            ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
    
            return [
                {text: '\n'},
                {
                    text: 'Dados da anamnese audiológica',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' || y[0][20]!== '') ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' || y[0][23]!== '') ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' || y[0][26]!== '') ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' || y[0][29]!== '') ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' || y[0][32]!== '') ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                            y.length > 0 && (y[0][34] !== '' || y[0][35]!== '') ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                            y.length > 0 && (y[0][37] !== '' || y[0][38]!== '') ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                            y.length > 0 && (y[0][40] !== '' || y[0][41]!== '') ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                            y.length > 0 && (y[0][43] !== '' || y[0][44]!== '') ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                            y.length > 0 && (y[0][46] !== '' || y[0][47]!== '') ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                            y.length > 0 && (y[0][49] !== '' || y[0][50]!== '') ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                            y.length > 0 && (y[0][52] !== '' || y[0][53]!== '') ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                            y.length > 0 && (y[0][55] !== '' || y[0][56]!== '') ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                            y.length > 0 && (y[0][58] !== '' || y[0][59]!== '') ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                            y.length > 0 && (y[0][61] !== '' || y[0][62]!== '') ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],
                            y.length > 0 && (y[0][64] !== '' || y[0][65]!== '') ? [[y[0][63]],[y[0][64]],[y[0][65]]] : ['','',''], 
                            y.length > 0 && (y[0][67] !== '' || y[0][68]!== '') ? [[y[0][66]],[y[0][67]],[y[0][68]]] : ['','',''],
                            y.length > 0 && (y[0][70] !== '' || y[0][71]!== '') ? [[y[0][69]],[y[0][70]],[y[0][71]]] : ['','',''],
                            y.length > 0 && (y[0][73] !== '' || y[0][74]!== '') ? [[y[0][72]],[y[0][73]],[y[0][74]]] : ['','',''],
                            y.length > 0 && (y[0][76] !== '' || y[0][77]!== '') ? [[y[0][75]],[y[0][76]],[y[0][77]]] : ['','',''],
                            y.length > 0 && (y[0][79] !== '' || y[0][80]!== '') ? [[y[0][78]],[y[0][79]],[y[0][80]]] : ['','',''],
                            y.length > 0 && (y[0][82] !== '' || y[0][83]!== '') ? [[y[0][81]],[y[0][82]],[y[0][83]]] : ['','',''],
                            y.length > 0 && (y[0][85] !== '' || y[0][86]!== '') ? [[y[0][84]],[y[0][85]],[y[0][86]]] : ['','',''],
                            y.length > 0 && (y[0][88] !== '' || y[0][89]!== '') ? [[y[0][87]],[y[0][88]],[y[0][89]]] : ['','',''],
                            y.length > 0 && (y[0][91] !== '' || y[0][92]!== '') ? [[y[0][90]],[y[0][91]],[y[0][92]]] : ['','',''],
                            y.length > 0 && (y[0][94] !== '' || y[0][95]!== '') ? [[y[0][93]],[y[0][94]],[y[0][95]]] : ['','',''],
                            y.length > 0 && (y[0][97] !== '' || y[0][98]!== '') ? [[y[0][96]],[y[0][97]],[y[0][98]]] : ['','',''],
                            y.length > 0 && (y[0][100] !== '' || y[0][101]!== '') ? [[y[0][99]],[y[0][100]],[y[0][101]]] : ['','',''],
                            y.length > 0 && (y[0][103] !== '' || y[0][104]!== '') ? [[y[0][102]],[y[0][103]],[y[0][104]]] : ['','',''],
                            y.length > 0 && (y[0][106] !== '' || y[0][107]!== '') ? [[y[0][105]],[y[0][106]],[y[0][107]]] : ['','',''],
                            y.length > 0 && (y[0][109] !== '' || y[0][110]!== '') ? [[y[0][108]],[y[0][109]],[y[0][110]]] : ['','',''],
                            y.length > 0 && (y[0][112] !== '' || y[0][113]!== '') ? [[y[0][111]],[y[0][112]],[y[0][113]]] : ['','',''],
                            y.length > 0 && (y[0][115] !== '' || y[0][116]!== '') ? [[y[0][114]],[y[0][115]],[y[0][116]]] : ['','',''],
                            y.length > 0 && (y[0][118] !== '' || y[0][119]!== '') ? [[y[0][117]],[y[0][118]],[y[0][119]]] : ['','',''],
                            y.length > 0 && (y[0][121] !== '' || y[0][122]!== '') ? [[y[0][120]],[y[0][121]],[y[0][122]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }
}

const dadosAnamneseLivrePdf = () => {
    let y = []
                if (tudo._23_anamneseLivre && tudo._23_anamneseLivre.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._23_anamneseLivre !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._23_anamneseLivre !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._23_anamneseLivre : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
                            e.anamneseLivre !== undefined ? e.anamneseLivre : '',
                        ]) : y.push(
            [''])
                )

            //console.log('***y',y[0],'***length',y[0].length)

            return [
                {text: '\n'},
                {
                    text: 'Anamnese Livre',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'headerLineOnly',
                    alignment: 'justify',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Dados da anamnese', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            ...y
                        ],
                    }, 
           },
       ]
   }
}

const dadosDeficienciaPreviaPdf = () => {
    let y = []
                if (tudo._24_defPrevia && tudo._24_defPrevia.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._24_defPrevia !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._24_defPrevia !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._24_defPrevia : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
            e.perguntaDefPrevia0 !== undefined ? e.perguntaDefPrevia0 : '',
            e.respostaDefPrevia0 !== undefined ? e.respostaDefPrevia0 : '',
            e.comentarioDefPrevia0 !== undefined ? e.comentarioDefPrevia0 : '',
            e.perguntaDefPrevia1 !== undefined ? e.perguntaDefPrevia1 : '',
            e.respostaDefPrevia1 !== undefined ? e.respostaDefPrevia1 : '',
            e.comentarioDefPrevia1 !== undefined ? e.comentarioDefPrevia1 : '',
            e.perguntaDefPrevia2 !== undefined ? e.perguntaDefPrevia2 : '',
            e.respostaDefPrevia2 !== undefined ? e.respostaDefPrevia2 : '',
            e.comentarioDefPrevia2 !== undefined ? e.comentarioDefPrevia2 : '',
            e.perguntaDefPrevia3 !== undefined ? e.perguntaDefPrevia3 : '',
            e.respostaDefPrevia3 !== undefined ? e.respostaDefPrevia3 : '',
            e.comentarioDefPrevia3 !== undefined ? e.comentarioDefPrevia3 : '',
                        ]) : y.push(
            ['','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
    
            return [
                {text: '\n'},
                {
                    text: 'Deficiências Prévias',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }
}

const dadosComorbidadesPdf = () => {
    let y = []
                if (tudo._25_comorbidade && tudo._25_comorbidade.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._25_comorbidade !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._25_comorbidade !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._25_comorbidade : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
            e.perguntaComorbidade0 !== undefined ? e.perguntaComorbidade0 : '',
            e.respostaComorbidade0 !== undefined ? e.respostaComorbidade0 : '',
            e.comentarioComorbidade0 !== undefined ? e.comentarioComorbidade0 : '',
            e.perguntaComorbidade1 !== undefined ? e.perguntaComorbidade1 : '',
            e.respostaComorbidade1 !== undefined ? e.respostaComorbidade1 : '',
            e.comentarioComorbidade1 !== undefined ? e.comentarioComorbidade1 : '',
            e.perguntaComorbidade2 !== undefined ? e.perguntaComorbidade2 : '',
            e.respostaComorbidade2 !== undefined ? e.respostaComorbidade2 : '',
            e.comentarioComorbidade2 !== undefined ? e.comentarioComorbidade2 : '',
            e.perguntaComorbidade3 !== undefined ? e.perguntaComorbidade3 : '',
            e.respostaComorbidade3 !== undefined ? e.respostaComorbidade3 : '',
            e.comentarioComorbidade3 !== undefined ? e.comentarioComorbidade3 : '',
            e.perguntaComorbidade4 !== undefined ? e.perguntaComorbidade4 : '',
            e.respostaComorbidade4 !== undefined ? e.respostaComorbidade4 : '',
            e.comentarioComorbidade4 !== undefined ? e.comentarioComorbidade4 : '',
            e.perguntaComorbidade5 !== undefined ? e.perguntaComorbidade5 : '',
            e.respostaComorbidade5 !== undefined ? e.respostaComorbidade5 : '',
            e.comentarioComorbidade5 !== undefined ? e.comentarioComorbidade5 : '',
            e.perguntaComorbidade6 !== undefined ? e.perguntaComorbidade6 : '',
            e.respostaComorbidade6 !== undefined ? e.respostaComorbidade6 : '',
            e.comentarioComorbidade6 !== undefined ? e.comentarioComorbidade6 : '',
            e.perguntaComorbidade7 !== undefined ? e.perguntaComorbidade7 : '',
            e.respostaComorbidade7 !== undefined ? e.respostaComorbidade7 : '',
            e.comentarioComorbidade7 !== undefined ? e.comentarioComorbidade7 : '',
            e.perguntaComorbidade8 !== undefined ? e.perguntaComorbidade8 : '',
            e.respostaComorbidade8 !== undefined ? e.respostaComorbidade8 : '',
            e.comentarioComorbidade8 !== undefined ? e.comentarioComorbidade8 : '',
            e.perguntaComorbidade9 !== undefined ? e.perguntaComorbidade9 : '',
            e.respostaComorbidade9 !== undefined ? e.respostaComorbidade9 : '',
            e.comentarioComorbidade9 !== undefined ? e.comentarioComorbidade9 : '',
            e.perguntaComorbidade10 !== undefined ? e.perguntaComorbidade10 : '',
            e.respostaComorbidade10 !== undefined ? e.respostaComorbidade10 : '',
            e.comentarioComorbidade10 !== undefined ? e.comentarioComorbidade10 : '',
            e.perguntaComorbidade11 !== undefined ? e.perguntaComorbidade11 : '',
            e.respostaComorbidade11 !== undefined ? e.respostaComorbidade11 : '',
            e.comentarioComorbidade11 !== undefined ? e.comentarioComorbidade11 : '',
            e.perguntaComorbidade12 !== undefined ? e.perguntaComorbidade12 : '',
            e.respostaComorbidade12 !== undefined ? e.respostaComorbidade12 : '',
            e.comentarioComorbidade12 !== undefined ? e.comentarioComorbidade12 : '',
            e.perguntaComorbidade13 !== undefined ? e.perguntaComorbidade13 : '',
            e.respostaComorbidade13 !== undefined ? e.respostaComorbidade13 : '',
            e.comentarioComorbidade13 !== undefined ? e.comentarioComorbidade13 : '',
            e.perguntaComorbidade14 !== undefined ? e.perguntaComorbidade14 : '',
            e.respostaComorbidade14 !== undefined ? e.respostaComorbidade14 : '',
            e.comentarioComorbidade14 !== undefined ? e.comentarioComorbidade14 : '',
                        ]) : y.push(
            ['','','','','','','','','','','','','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
    
            return [
                {text: '\n'},
                {
                    text: 'Comorbidades',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' || y[0][20]!== '') ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' || y[0][23]!== '') ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' || y[0][26]!== '') ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' || y[0][29]!== '') ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' || y[0][32]!== '') ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                            y.length > 0 && (y[0][34] !== '' || y[0][35]!== '') ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                            y.length > 0 && (y[0][37] !== '' || y[0][38]!== '') ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                            y.length > 0 && (y[0][40] !== '' || y[0][41]!== '') ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                            y.length > 0 && (y[0][43] !== '' || y[0][44]!== '') ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }
}

const dadosAntecedentesFamiliaresPdf = () => {
    let y = []
                if (tudo._26_antecedenteFamiliar && tudo._26_antecedenteFamiliar.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._26_antecedenteFamiliar !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._26_antecedenteFamiliar !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._26_antecedenteFamiliar : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
            e.perguntaAntecedenteFamiliar0 !== undefined ? e.perguntaAntecedenteFamiliar0 : '',
            e.respostaAntecedenteFamiliar0 !== undefined ? e.respostaAntecedenteFamiliar0 : '',
            e.comentarioAntecedenteFamiliar0 !== undefined ? e.comentarioAntecedenteFamiliar0 : '',
            e.perguntaAntecedenteFamiliar1 !== undefined ? e.perguntaAntecedenteFamiliar1 : '',
            e.respostaAntecedenteFamiliar1 !== undefined ? e.respostaAntecedenteFamiliar1 : '',
            e.comentarioAntecedenteFamiliar1 !== undefined ? e.comentarioAntecedenteFamiliar1 : '',
            e.perguntaAntecedenteFamiliar2 !== undefined ? e.perguntaAntecedenteFamiliar2 : '',
            e.respostaAntecedenteFamiliar2 !== undefined ? e.respostaAntecedenteFamiliar2 : '',
            e.comentarioAntecedenteFamiliar2 !== undefined ? e.comentarioAntecedenteFamiliar2 : '',
            e.perguntaAntecedenteFamiliar3 !== undefined ? e.perguntaAntecedenteFamiliar3 : '',
            e.respostaAntecedenteFamiliar3 !== undefined ? e.respostaAntecedenteFamiliar3 : '',
            e.comentarioAntecedenteFamiliar3 !== undefined ? e.comentarioAntecedenteFamiliar3 : '',
            e.perguntaAntecedenteFamiliar4 !== undefined ? e.perguntaAntecedenteFamiliar4 : '',
            e.respostaAntecedenteFamiliar4 !== undefined ? e.respostaAntecedenteFamiliar4 : '',
            e.comentarioAntecedenteFamiliar4 !== undefined ? e.comentarioAntecedenteFamiliar4 : '',
            e.perguntaAntecedenteFamiliar5 !== undefined ? e.perguntaAntecedenteFamiliar5 : '',
            e.respostaAntecedenteFamiliar5 !== undefined ? e.respostaAntecedenteFamiliar5 : '',
            e.comentarioAntecedenteFamiliar5 !== undefined ? e.comentarioAntecedenteFamiliar5 : '',
            e.perguntaAntecedenteFamiliar6 !== undefined ? e.perguntaAntecedenteFamiliar6 : '',
            e.respostaAntecedenteFamiliar6 !== undefined ? e.respostaAntecedenteFamiliar6 : '',
            e.comentarioAntecedenteFamiliar6 !== undefined ? e.comentarioAntecedenteFamiliar6 : '',
            e.perguntaAntecedenteFamiliar7 !== undefined ? e.perguntaAntecedenteFamiliar7 : '',
            e.respostaAntecedenteFamiliar7 !== undefined ? e.respostaAntecedenteFamiliar7 : '',
            e.comentarioAntecedenteFamiliar7 !== undefined ? e.comentarioAntecedenteFamiliar7 : '',
            e.perguntaAntecedenteFamiliar8 !== undefined ? e.perguntaAntecedenteFamiliar8 : '',
            e.respostaAntecedenteFamiliar8 !== undefined ? e.respostaAntecedenteFamiliar8 : '',
            e.comentarioAntecedenteFamiliar8 !== undefined ? e.comentarioAntecedenteFamiliar8 : '',
            e.perguntaAntecedenteFamiliar9 !== undefined ? e.perguntaAntecedenteFamiliar9 : '',
            e.respostaAntecedenteFamiliar9 !== undefined ? e.respostaAntecedenteFamiliar9 : '',
            e.comentarioAntecedenteFamiliar9 !== undefined ? e.comentarioAntecedenteFamiliar9 : '',
            e.perguntaAntecedenteFamiliar10 !== undefined ? e.perguntaAntecedenteFamiliar10 : '',
            e.respostaAntecedenteFamiliar10 !== undefined ? e.respostaAntecedenteFamiliar10 : '',
            e.comentarioAntecedenteFamiliar10 !== undefined ? e.comentarioAntecedenteFamiliar10 : '',
            e.perguntaAntecedenteFamiliar11 !== undefined ? e.perguntaAntecedenteFamiliar11 : '',
            e.respostaAntecedenteFamiliar11 !== undefined ? e.respostaAntecedenteFamiliar11 : '',
            e.comentarioAntecedenteFamiliar11 !== undefined ? e.comentarioAntecedenteFamiliar11 : '',
            e.perguntaAntecedenteFamiliar12 !== undefined ? e.perguntaAntecedenteFamiliar12 : '',
            e.respostaAntecedenteFamiliar12 !== undefined ? e.respostaAntecedenteFamiliar12 : '',
            e.comentarioAntecedenteFamiliar12 !== undefined ? e.comentarioAntecedenteFamiliar12 : '',
            e.perguntaAntecedenteFamiliar13 !== undefined ? e.perguntaAntecedenteFamiliar13 : '',
            e.respostaAntecedenteFamiliar13 !== undefined ? e.respostaAntecedenteFamiliar13 : '',
            e.comentarioAntecedenteFamiliar13 !== undefined ? e.comentarioAntecedenteFamiliar13 : '',
            e.perguntaAntecedenteFamiliar14 !== undefined ? e.perguntaAntecedenteFamiliar14 : '',
            e.respostaAntecedenteFamiliar14 !== undefined ? e.respostaAntecedenteFamiliar14 : '',
            e.comentarioAntecedenteFamiliar14 !== undefined ? e.comentarioAntecedenteFamiliar14 : '',
                        ]) : y.push(
            ['','','','','','','','','','','','','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
    
            return [
                {text: '\n'},
                {
                    text: 'Antecedentes Familiares',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' || y[0][20]!== '') ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' || y[0][23]!== '') ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' || y[0][26]!== '') ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' || y[0][29]!== '') ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' || y[0][32]!== '') ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                            y.length > 0 && (y[0][34] !== '' || y[0][35]!== '') ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                            y.length > 0 && (y[0][37] !== '' || y[0][38]!== '') ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                            y.length > 0 && (y[0][40] !== '' || y[0][41]!== '') ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                            y.length > 0 && (y[0][43] !== '' || y[0][44]!== '') ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }
}

const dadosAtividadesExtralaboraisPdf = () => {
    let y = []
    if (tudo._27_atividadeExtralaboral && tudo._27_atividadeExtralaboral.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._27_atividadeExtralaboral !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._27_atividadeExtralaboral !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._27_atividadeExtralaboral : []

        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
e.perguntaAtividadeExtralaboral0 !== undefined ? e.perguntaAtividadeExtralaboral0 : '',
e.respostaAtividadeExtralaboral0 !== undefined ? e.respostaAtividadeExtralaboral0 : '',
e.comentarioAtividadeExtralaboral0 !== undefined ? e.comentarioAtividadeExtralaboral0 : '',
e.perguntaAtividadeExtralaboral1 !== undefined ? e.perguntaAtividadeExtralaboral1 : '',
e.respostaAtividadeExtralaboral1 !== undefined ? e.respostaAtividadeExtralaboral1 : '',
e.comentarioAtividadeExtralaboral1 !== undefined ? e.comentarioAtividadeExtralaboral1 : '',
e.perguntaAtividadeExtralaboral2 !== undefined ? e.perguntaAtividadeExtralaboral2 : '',
e.respostaAtividadeExtralaboral2 !== undefined ? e.respostaAtividadeExtralaboral2 : '',
e.comentarioAtividadeExtralaboral2 !== undefined ? e.comentarioAtividadeExtralaboral2 : '',
e.perguntaAtividadeExtralaboral3 !== undefined ? e.perguntaAtividadeExtralaboral3 : '',
e.respostaAtividadeExtralaboral3 !== undefined ? e.respostaAtividadeExtralaboral3 : '',
e.comentarioAtividadeExtralaboral3 !== undefined ? e.comentarioAtividadeExtralaboral3 : '',
e.perguntaAtividadeExtralaboral4 !== undefined ? e.perguntaAtividadeExtralaboral4 : '',
e.respostaAtividadeExtralaboral4 !== undefined ? e.respostaAtividadeExtralaboral4 : '',
e.comentarioAtividadeExtralaboral4 !== undefined ? e.comentarioAtividadeExtralaboral4 : '',
e.perguntaAtividadeExtralaboral5 !== undefined ? e.perguntaAtividadeExtralaboral5 : '',
e.respostaAtividadeExtralaboral5 !== undefined ? e.respostaAtividadeExtralaboral5 : '',
e.comentarioAtividadeExtralaboral5 !== undefined ? e.comentarioAtividadeExtralaboral5 : '',
            ]) : y.push(
['','','','','',''])
    )

// console.log('***y',y[0],'***length',y[0].length)

return [
    {text: '\n'},
    {
        text: 'Atividades Extralaborais',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' || y[0][2]!== '') ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' || y[0][5]!== '') ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' || y[0][8]!== '') ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' || y[0][11]!== '') ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' || y[0][14]!== '') ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' || y[0][17]!== '') ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
            ],
             }, 
    },
]
}
}

const dadosExameComplementarPdf = () => {
    let y = []
    if (tudo._28_exameComplementar && tudo._28_exameComplementar.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._28_exameComplementar !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._28_exameComplementar !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._28_exameComplementar : []

        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
e.idExameComplementar !== undefined ? e.idExameComplementar : '',
e.data !== undefined ? e.data : '',
e.exame !== undefined ? e.exame : '',
e.laudo !== undefined ? e.laudo : '',
            ]) : y.push(
['','','',''])
    )

// console.log('***y',y[0],'***length',y[0].length)
             return [
                 {text: '\n'},
                 {
                     text: 'Exames Complementares',
                     style: 'subheader',
                     alignment: 'center',
                 },
                 {
                     layout: 'lightHorizontalLines',
                     alignment: 'center',
                     table: {
                         heights: function () {
                             return 15
                         },
                         headerRows: 1,
                         widths: ['20%','20%','20%','40%'],
                         body: [
                             [
                                 //DADOS DO CABECALHO E ESTILO
                                 {text: 'Id', style: 'cabecalhoTabela'},
                                 {text: 'Data do exame', style: 'cabecalhoTabela'},
                                 {text: 'Tipo', style: 'cabecalhoTabela'},
                                 {text: 'Laudo', style: 'cabecalhoTabela'},
                             ],
                             
                             //DADOS DA TABELA EM SI
                             ...y
                            ],
                        }, 
                },
                ]
                }
 }

const dadosPesoAlturaImcPdf = () => {
    let y = []
    if (tudo._29_calcularImc && tudo._29_calcularImc.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._29_calcularImc !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._29_calcularImc !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._29_calcularImc : []

        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
e.peso !== undefined ? e.peso : '',
e.altura !== undefined ? e.altura : '',
e.imc !== undefined ? e.imc : '',
e.resultadoImc !== undefined ? e.resultadoImc : '',
            ]) : y.push(
['','','',''])
    )

 //console.log('***y',y[0],'***length',y[0].length)
            return [
                {text: '\n'},
                {
                    text: 'Peso, Altura e IMC',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','25%','25%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Peso', style: 'cabecalhoTabela'},
                                {text: 'Altura', style: 'cabecalhoTabela'},
                                {text: 'Imc', style: 'cabecalhoTabela'},
                                {text: 'Resultado Imc', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            /* y.length > 0 && (y[0][0] !== '') ? [[y[0][0]]] : [''],
                            y.length > 0 && (y[0][1] !== '') ? [[y[0][1]]] : [''],
                            y.length > 0 && (y[0][2] !== '') ? [[y[0][2]]] : [''],
                            y.length > 0 && (y[0][3] !== '') ? [[y[0][3]]] : [''], */
                            y[0]
            ],
        }, 
},
]
}
}

const dadosCircunferenciaMembroPdf = () => {
    let y = []
    if (tudo._30_circunferenciaMembro && tudo._30_circunferenciaMembro.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._30_circunferenciaMembro !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._30_circunferenciaMembro !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._30_circunferenciaMembro : []

        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
e.local !== undefined ? e.local : '',
e.lado !== undefined ? e.lado : '',
e.circunferencia !== undefined ? e.circunferencia : '',
            ]) : y.push(
['','','',''])
    )

// console.log('***y',y[0],'***length',y[0].length)
            return [
                {text: '\n'},
                {
                    text: 'Circunferência dos membros',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['40%','30%','30%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Local', style: 'cabecalhoTabela'},
                                {text: 'Lado', style: 'cabecalhoTabela'},
                                {text: 'Circunferência (cm)', style: 'cabecalhoTabela'},
                                //{text: 'Fim', style: 'cabecalhoTabela'},
                                //{text: 'Dias afastados', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            ...y
            ],
        }, 
},
]
}
}



const dadosAtitudeExaminadorPdf = () => {
    let y = []
                if (tudo._31_exameAtitudeExaminador && tudo._31_exameAtitudeExaminador.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._31_exameAtitudeExaminador !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._31_exameAtitudeExaminador !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._31_exameAtitudeExaminador : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
                             e.perguntaExameAtitudeExaminador0 !== undefined ? e.perguntaExameAtitudeExaminador0 : '',
                             e.respostaExameAtitudeExaminador0 !== undefined ? e.respostaExameAtitudeExaminador0 : '',
                             e.comentarioExameAtitudeExaminador0 !== undefined ? e.comentarioExameAtitudeExaminador0 : '', 
                             e.perguntaExameAtitudeExaminador1 !== undefined ? e.perguntaExameAtitudeExaminador1 : '',
                             e.respostaExameAtitudeExaminador1 !== undefined ? e.respostaExameAtitudeExaminador1 : '',
                             e.comentarioExameAtitudeExaminador1 !== undefined ? e.comentarioExameAtitudeExaminador1 : '', 
                             e.perguntaExameAtitudeExaminador2 !== undefined ? e.perguntaExameAtitudeExaminador2 : '',
                             e.respostaExameAtitudeExaminador2 !== undefined ? e.respostaExameAtitudeExaminador2 : '',
                             e.comentarioExameAtitudeExaminador2 !== undefined ? e.comentarioExameAtitudeExaminador2 : '', 
                             e.perguntaExameAtitudeExaminador3 !== undefined ? e.perguntaExameAtitudeExaminador3 : '',
                             e.respostaExameAtitudeExaminador3 !== undefined ? e.respostaExameAtitudeExaminador3 : '',
                             e.comentarioExameAtitudeExaminador3 !== undefined ? e.comentarioExameAtitudeExaminador3 : '',
                             e.perguntaExameAtitudeExaminador4 !== undefined ? e.perguntaExameAtitudeExaminador4 : '',
                             e.respostaExameAtitudeExaminador4 !== undefined ? e.respostaExameAtitudeExaminador4 : '',
                             e.comentarioExameAtitudeExaminador4 !== undefined ? e.comentarioExameAtitudeExaminador4 : '',
                             e.perguntaExameAtitudeExaminador5 !== undefined ? e.perguntaExameAtitudeExaminador5 : '',
                             e.respostaExameAtitudeExaminador5 !== undefined ? e.respostaExameAtitudeExaminador5 : '',
                             e.comentarioExameAtitudeExaminador5 !== undefined ? e.comentarioExameAtitudeExaminador5 : '',
                        ]) : y.push(
            ['','','','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
            return [
                {text: '\n'},
                {
                    text: 'Atitude com o examinador',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' /* || y[0][2]!== '' */) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' /* || y[0][5]!== '' */) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' /* || y[0][8]!== '' */) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' /* || y[0][11]!== '' */) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' /* || y[0][14]!== '' */) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' /* || y[0][17]!== '' */) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                        ],
                         }, 
                },
            ]
            }
}

const dadosExameGeralPdf = () => {
    let y = []
                if (tudo._32_exameGeral && tudo._32_exameGeral.length > 0) {
                    const a = JSON.parse(localStorage.getItem('tudo'))._32_exameGeral !== undefined
                    ||JSON.parse(localStorage.getItem('tudo'))._32_exameGeral !== null ? 
                    JSON.parse(localStorage.getItem('tudo'))._32_exameGeral : []

                    //console.log('***',a)
                a.map((e)=> 
                    e !== undefined ? y.push(
                        [
            e.perguntaExameGeral0 !== undefined ? e.perguntaExameGeral0 : '',
            e.respostaExameGeral0 !== undefined ? e.respostaExameGeral0 : '',
            e.comentarioExameGeral0 !== undefined ? e.comentarioExameGeral0 : '',
            e.perguntaExameGeral1 !== undefined ? e.perguntaExameGeral1 : '',
            e.respostaExameGeral1 !== undefined ? e.respostaExameGeral1 : '',
            e.comentarioExameGeral1 !== undefined ? e.comentarioExameGeral1 : '',
            e.perguntaExameGeral2 !== undefined ? e.perguntaExameGeral2 : '',
            e.respostaExameGeral2 !== undefined ? e.respostaExameGeral2 : '',
            e.comentarioExameGeral2 !== undefined ? e.comentarioExameGeral2 : '',
            e.perguntaExameGeral3 !== undefined ? e.perguntaExameGeral3 : '',
            e.respostaExameGeral3 !== undefined ? e.respostaExameGeral3 : '',
            e.comentarioExameGeral3 !== undefined ? e.comentarioExameGeral3 : '',
            e.perguntaExameGeral4 !== undefined ? e.perguntaExameGeral4 : '',
            e.respostaExameGeral4 !== undefined ? e.respostaExameGeral4 : '',
            e.comentarioExameGeral4 !== undefined ? e.comentarioExameGeral4 : '',
            e.perguntaExameGeral5 !== undefined ? e.perguntaExameGeral5 : '',
            e.respostaExameGeral5 !== undefined ? e.respostaExameGeral5 : '',
            e.comentarioExameGeral5 !== undefined ? e.comentarioExameGeral5 : '',
            e.perguntaExameGeral6 !== undefined ? e.perguntaExameGeral6 : '',
            e.respostaExameGeral6 !== undefined ? e.respostaExameGeral6 : '',
            e.comentarioExameGeral6 !== undefined ? e.comentarioExameGeral6 : '',
            e.perguntaExameGeral7 !== undefined ? e.perguntaExameGeral7 : '',
            e.respostaExameGeral7 !== undefined ? e.respostaExameGeral7 : '',
            e.comentarioExameGeral7 !== undefined ? e.comentarioExameGeral7 : '',
            e.perguntaExameGeral8 !== undefined ? e.perguntaExameGeral8 : '',
            e.respostaExameGeral8 !== undefined ? e.respostaExameGeral8 : '',
            e.comentarioExameGeral8 !== undefined ? e.comentarioExameGeral8 : '',
            e.perguntaExameGeral9 !== undefined ? e.perguntaExameGeral9 : '',
            e.respostaExameGeral9 !== undefined ? e.respostaExameGeral9 : '',
            e.comentarioExameGeral9 !== undefined ? e.comentarioExameGeral9 : '',
            e.perguntaExameGeral10 !== undefined ? e.perguntaExameGeral10 : '',
            e.respostaExameGeral10 !== undefined ? e.respostaExameGeral10 : '',
            e.comentarioExameGeral10 !== undefined ? e.comentarioExameGeral10 : '',
            e.perguntaExameGeral11 !== undefined ? e.perguntaExameGeral11 : '',
            e.respostaExameGeral11 !== undefined ? e.respostaExameGeral11 : '',
            e.comentarioExameGeral11 !== undefined ? e.comentarioExameGeral11 : '',
            e.perguntaExameGeral12 !== undefined ? e.perguntaExameGeral12 : '',
            e.respostaExameGeral12 !== undefined ? e.respostaExameGeral12 : '',
            e.comentarioExameGeral12 !== undefined ? e.comentarioExameGeral12 : '',
            e.perguntaExameGeral13 !== undefined ? e.perguntaExameGeral13 : '',
            e.respostaExameGeral13 !== undefined ? e.respostaExameGeral13 : '',
            e.comentarioExameGeral13 !== undefined ? e.comentarioExameGeral13 : '',
            e.perguntaExameGeral14 !== undefined ? e.perguntaExameGeral14 : '',
            e.respostaExameGeral14 !== undefined ? e.respostaExameGeral14 : '',
            e.comentarioExameGeral14 !== undefined ? e.comentarioExameGeral14 : '',
            e.perguntaExameGeral15 !== undefined ? e.perguntaExameGeral15 : '',
            e.respostaExameGeral15 !== undefined ? e.respostaExameGeral15 : '',
            e.comentarioExameGeral15 !== undefined ? e.comentarioExameGeral15 : '',
            e.perguntaExameGeral16 !== undefined ? e.perguntaExameGeral16 : '',
            e.respostaExameGeral16 !== undefined ? e.respostaExameGeral16 : '',
            e.comentarioExameGeral16 !== undefined ? e.comentarioExameGeral16 : '',
            e.perguntaExameGeral17 !== undefined ? e.perguntaExameGeral17 : '',
            e.respostaExameGeral17 !== undefined ? e.respostaExameGeral17 : '',
            e.comentarioExameGeral17 !== undefined ? e.comentarioExameGeral17 : '',
            e.perguntaExameGeral18 !== undefined ? e.perguntaExameGeral18 : '',
            e.respostaExameGeral18 !== undefined ? e.respostaExameGeral18 : '',
            e.comentarioExameGeral18 !== undefined ? e.comentarioExameGeral18 : '',
            e.perguntaExameGeral19 !== undefined ? e.perguntaExameGeral19 : '',
            e.respostaExameGeral19 !== undefined ? e.respostaExameGeral19 : '',
            e.comentarioExameGeral19 !== undefined ? e.comentarioExameGeral19 : '',
            e.perguntaExameGeral20 !== undefined ? e.perguntaExameGeral20 : '',
            e.respostaExameGeral20 !== undefined ? e.respostaExameGeral20 : '',
            e.comentarioExameGeral20 !== undefined ? e.comentarioExameGeral20 : '',
                        ]) : y.push(
            ['','','','','','','','','','','','','','','','','','','','',''])
                )

           // console.log('***y',y[0],'***length',y[0].length)
    
            return [
                {text: '\n'},
                {
                    text: 'Exame Geral',
                    style: 'subheader',
                    alignment: 'center',
                },
                {
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                    table: {
                        heights: function () {
                            return 15
                        },
                        headerRows: 1,
                        widths: ['25%','25%','50%'],
                        body: [
                            [
                                //DADOS DO CABECALHO E ESTILO
                                {text: 'Pergunta', style: 'cabecalhoTabela'},
                                {text: 'Resposta', style: 'cabecalhoTabela'},
                                {text: 'Comentário', style: 'cabecalhoTabela'},
                            ],
                            
                            //DADOS DA TABELA EM SI
                            y.length > 0 && (y[0][1] !== '' /* || y[0][2]!== '' */) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                            y.length > 0 && (y[0][4] !== '' /*|| y[0][5]!== ''*/) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                            y.length > 0 && (y[0][7] !== '' /*|| y[0][8]!== ''*/) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                            y.length > 0 && (y[0][10] !== '' /*|| y[0][11]!== ''*/) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                            y.length > 0 && (y[0][13] !== '' /*|| y[0][14]!== ''*/) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                            y.length > 0 && (y[0][16] !== '' /*|| y[0][17]!== ''*/) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                            y.length > 0 && (y[0][19] !== '' /*|| y[0][20]!== ''*/) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                            y.length > 0 && (y[0][22] !== '' /*|| y[0][23]!== ''*/) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                            y.length > 0 && (y[0][25] !== '' /*|| y[0][26]!== ''*/) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                            y.length > 0 && (y[0][28] !== '' /*|| y[0][29]!== ''*/) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                            y.length > 0 && (y[0][31] !== '' /*|| y[0][32]!== ''*/) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                            y.length > 0 && (y[0][34] !== '' /*|| y[0][35]!== ''*/) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                            y.length > 0 && (y[0][37] !== '' /*|| y[0][38]!== ''*/) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                            y.length > 0 && (y[0][40] !== '' /*|| y[0][41]!== ''*/) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                            y.length > 0 && (y[0][43] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                            y.length > 0 && (y[0][46] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                            y.length > 0 && (y[0][49] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                            y.length > 0 && (y[0][52] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                            y.length > 0 && (y[0][55] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                            y.length > 0 && (y[0][58] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                            y.length > 0 && (y[0][61] !== '' /*|| y[0][44]!== ''*/) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],
                        ],
                         }, 
                },
            ]
        }
}

const dadosExamePsiPdf = () => {
   let y = []
   if (tudo._33_examePsi && tudo._33_examePsi.length > 0) {
       const a = JSON.parse(localStorage.getItem('tudo'))._33_examePsi !== undefined
       ||JSON.parse(localStorage.getItem('tudo'))._33_examePsi !== null ? 
       JSON.parse(localStorage.getItem('tudo'))._33_examePsi : []

       //console.log('***',a)
   a.map((e)=> 
       e !== undefined ? y.push(
           [
e.perguntaExamePsi0 !== undefined ? e.perguntaExamePsi0 : '',
e.respostaExamePsi0 !== undefined ? e.respostaExamePsi0 : '',
e.comentarioExamePsi0 !== undefined ? e.comentarioExamePsi0 : '',
e.perguntaExamePsi1 !== undefined ? e.perguntaExamePsi1 : '',
e.respostaExamePsi1 !== undefined ? e.respostaExamePsi1 : '',
e.comentarioExamePsi1 !== undefined ? e.comentarioExamePsi1 : '',
e.perguntaExamePsi2 !== undefined ? e.perguntaExamePsi2 : '',
e.respostaExamePsi2 !== undefined ? e.respostaExamePsi2 : '',
e.comentarioExamePsi2 !== undefined ? e.comentarioExamePsi2 : '',
e.perguntaExamePsi3 !== undefined ? e.perguntaExamePsi3 : '',
e.respostaExamePsi3 !== undefined ? e.respostaExamePsi3 : '',
e.comentarioExamePsi3 !== undefined ? e.comentarioExamePsi3 : '',
e.perguntaExamePsi4 !== undefined ? e.perguntaExamePsi4 : '',
e.respostaExamePsi4 !== undefined ? e.respostaExamePsi4 : '',
e.comentarioExamePsi4 !== undefined ? e.comentarioExamePsi4 : '',
e.perguntaExamePsi5 !== undefined ? e.perguntaExamePsi5 : '',
e.respostaExamePsi5 !== undefined ? e.respostaExamePsi5 : '',
e.comentarioExamePsi5 !== undefined ? e.comentarioExamePsi5 : '',
e.perguntaExamePsi6 !== undefined ? e.perguntaExamePsi6 : '',
e.respostaExamePsi6 !== undefined ? e.respostaExamePsi6 : '',
e.comentarioExamePsi6 !== undefined ? e.comentarioExamePsi6 : '',
e.perguntaExamePsi7 !== undefined ? e.perguntaExamePsi7 : '',
e.respostaExamePsi7 !== undefined ? e.respostaExamePsi7 : '',
e.comentarioExamePsi7 !== undefined ? e.comentarioExamePsi7 : '',
e.perguntaExamePsi8 !== undefined ? e.perguntaExamePsi8 : '',
e.respostaExamePsi8 !== undefined ? e.respostaExamePsi8 : '',
e.comentarioExamePsi8 !== undefined ? e.comentarioExamePsi8 : '',
e.perguntaExamePsi9 !== undefined ? e.perguntaExamePsi9 : '',
e.respostaExamePsi9 !== undefined ? e.respostaExamePsi9 : '',
e.comentarioExamePsi9 !== undefined ? e.comentarioExamePsi9 : '',
e.perguntaExamePsi10 !== undefined ? e.perguntaExamePsi10 : '',
e.respostaExamePsi10 !== undefined ? e.respostaExamePsi10 : '',
e.comentarioExamePsi10 !== undefined ? e.comentarioExamePsi10 : '',
e.perguntaExamePsi11 !== undefined ? e.perguntaExamePsi11 : '',
e.respostaExamePsi11 !== undefined ? e.respostaExamePsi11 : '',
e.comentarioExamePsi11 !== undefined ? e.comentarioExamePsi11 : '',
/*e.perguntaExamePsi12 !== undefined ? e.perguntaExamePsi12 : '',
e.respostaExamePsi12 !== undefined ? e.respostaExamePsi12 : '',
 e.comentarioExamePsi12 !== undefined ? e.comentarioExamePsi12 : '',
e.perguntaExamePsi13 !== undefined ? e.perguntaExamePsi13 : '',
e.respostaExamePsi13 !== undefined ? e.respostaExamePsi13 : '',
e.comentarioExamePsi13 !== undefined ? e.comentarioExamePsi13 : '',
e.perguntaExamePsi14 !== undefined ? e.perguntaExamePsi14 : '',
e.respostaExamePsi14 !== undefined ? e.respostaExamePsi14 : '',
e.comentarioExamePsi14 !== undefined ? e.comentarioExamePsi14 : '',
e.perguntaExamePsi15 !== undefined ? e.perguntaExamePsi15 : '',
e.respostaExamePsi15 !== undefined ? e.respostaExamePsi15 : '',
e.comentarioExamePsi15 !== undefined ? e.comentarioExamePsi15 : '',
e.perguntaExamePsi16 !== undefined ? e.perguntaExamePsi16 : '',
e.respostaExamePsi16 !== undefined ? e.respostaExamePsi16 : '',
e.comentarioExamePsi16 !== undefined ? e.comentarioExamePsi16 : '',
e.perguntaExamePsi17 !== undefined ? e.perguntaExamePsi17 : '',
e.respostaExamePsi17 !== undefined ? e.respostaExamePsi17 : '',
e.comentarioExamePsi17 !== undefined ? e.comentarioExamePsi17 : '',
e.perguntaExamePsi18 !== undefined ? e.perguntaExamePsi18 : '',
e.respostaExamePsi18 !== undefined ? e.respostaExamePsi18 : '',
e.comentarioExamePsi18 !== undefined ? e.comentarioExamePsi18 : '',
e.perguntaExamePsi19 !== undefined ? e.perguntaExamePsi19 : '',
e.respostaExamePsi19 !== undefined ? e.respostaExamePsi19 : '',
e.comentarioExamePsi19 !== undefined ? e.comentarioExamePsi19 : '',
e.perguntaExamePsi20 !== undefined ? e.perguntaExamePsi20 : '',
e.respostaExamePsi20 !== undefined ? e.respostaExamePsi20 : '',
e.comentarioExamePsi20 !== undefined ? e.comentarioExamePsi20 : '', */
           ]) : y.push(
['','','','','','','','','','','' ,''/*,'','','','','','','','','' */])
   )

// console.log('***y',y[0],'***length',y[0].length)

return [
   {text: '\n'},
   {
       text: 'Exame Psi',
       style: 'subheader',
       alignment: 'center',
   },
   {
       layout: 'lightHorizontalLines',
       alignment: 'center',
       table: {
           heights: function () {
               return 15
           },
           headerRows: 1,
           widths: ['25%','25%','50%'],
           body: [
               [
                   //DADOS DO CABECALHO E ESTILO
                   {text: 'Pergunta', style: 'cabecalhoTabela'},
                   {text: 'Resposta', style: 'cabecalhoTabela'},
                   {text: 'Comentário', style: 'cabecalhoTabela'},
               ],
               
               //DADOS DA TABELA EM SI
               y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
               y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
               y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
               y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
               y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
               y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
               y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
               y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
               y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
               y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
               y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
               y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
             /*  y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
               y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
               y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
               y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
               y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
               y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
               y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
               y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
               y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
           ],
            }, 
   },
]
}
}

const dadosExameOlhoPdf = () => {
    let y = []
    if (tudo._34_exameOlho && tudo._34_exameOlho.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._34_exameOlho !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._34_exameOlho !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._34_exameOlho : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameOlho0 !== undefined ? e.perguntaExameOlho0 : '',
 e.respostaExameOlho0 !== undefined ? e.respostaExameOlho0 : '',
 e.comentarioExameOlho0 !== undefined ? e.comentarioExameOlho0 : '',
 e.perguntaExameOlho1 !== undefined ? e.perguntaExameOlho1 : '',
 e.respostaExameOlho1 !== undefined ? e.respostaExameOlho1 : '',
 e.comentarioExameOlho1 !== undefined ? e.comentarioExameOlho1 : '',
 e.perguntaExameOlho2 !== undefined ? e.perguntaExameOlho2 : '',
 e.respostaExameOlho2 !== undefined ? e.respostaExameOlho2 : '',
 e.comentarioExameOlho2 !== undefined ? e.comentarioExameOlho2 : '',
 e.perguntaExameOlho3 !== undefined ? e.perguntaExameOlho3 : '',
 e.respostaExameOlho3 !== undefined ? e.respostaExameOlho3 : '',
 e.comentarioExameOlho3 !== undefined ? e.comentarioExameOlho3 : '',
 e.perguntaExameOlho4 !== undefined ? e.perguntaExameOlho4 : '',
 e.respostaExameOlho4 !== undefined ? e.respostaExameOlho4 : '',
 e.comentarioExameOlho4 !== undefined ? e.comentarioExameOlho4 : '',
 e.perguntaExameOlho5 !== undefined ? e.perguntaExameOlho5 : '',
 e.respostaExameOlho5 !== undefined ? e.respostaExameOlho5 : '',
 e.comentarioExameOlho5 !== undefined ? e.comentarioExameOlho5 : '',
 e.perguntaExameOlho6 !== undefined ? e.perguntaExameOlho6 : '',
 e.respostaExameOlho6 !== undefined ? e.respostaExameOlho6 : '',
 e.comentarioExameOlho6 !== undefined ? e.comentarioExameOlho6 : '',
 e.perguntaExameOlho7 !== undefined ? e.perguntaExameOlho7 : '',
 e.respostaExameOlho7 !== undefined ? e.respostaExameOlho7 : '',
 e.comentarioExameOlho7 !== undefined ? e.comentarioExameOlho7 : '',
 e.perguntaExameOlho8 !== undefined ? e.perguntaExameOlho8 : '',
 e.respostaExameOlho8 !== undefined ? e.respostaExameOlho8 : '',
 e.comentarioExameOlho8 !== undefined ? e.comentarioExameOlho8 : '',
 e.perguntaExameOlho9 !== undefined ? e.perguntaExameOlho9 : '',
 e.respostaExameOlho9 !== undefined ? e.respostaExameOlho9 : '',
 e.comentarioExameOlho9 !== undefined ? e.comentarioExameOlho9 : '',
 e.perguntaExameOlho10 !== undefined ? e.perguntaExameOlho10 : '',
 e.respostaExameOlho10 !== undefined ? e.respostaExameOlho10 : '',
 e.comentarioExameOlho10 !== undefined ? e.comentarioExameOlho10 : '',
 e.perguntaExameOlho11 !== undefined ? e.perguntaExameOlho11 : '',
 e.respostaExameOlho11 !== undefined ? e.respostaExameOlho11 : '',
 e.comentarioExameOlho11 !== undefined ? e.comentarioExameOlho11 : '',
 /*e.perguntaExameOlho12 !== undefined ? e.perguntaExameOlho12 : '',
 e.respostaExameOlho12 !== undefined ? e.respostaExameOlho12 : '',
  e.comentarioExameOlho12 !== undefined ? e.comentarioExameOlho12 : '',
 e.perguntaExameOlho13 !== undefined ? e.perguntaExameOlho13 : '',
 e.respostaExameOlho13 !== undefined ? e.respostaExameOlho13 : '',
 e.comentarioExameOlho13 !== undefined ? e.comentarioExameOlho13 : '',
 e.perguntaExameOlho14 !== undefined ? e.perguntaExameOlho14 : '',
 e.respostaExameOlho14 !== undefined ? e.respostaExameOlho14 : '',
 e.comentarioExameOlho14 !== undefined ? e.comentarioExameOlho14 : '',
 e.perguntaExameOlho15 !== undefined ? e.perguntaExameOlho15 : '',
 e.respostaExameOlho15 !== undefined ? e.respostaExameOlho15 : '',
 e.comentarioExameOlho15 !== undefined ? e.comentarioExameOlho15 : '',
 e.perguntaExameOlho16 !== undefined ? e.perguntaExameOlho16 : '',
 e.respostaExameOlho16 !== undefined ? e.respostaExameOlho16 : '',
 e.comentarioExameOlho16 !== undefined ? e.comentarioExameOlho16 : '',
 e.perguntaExameOlho17 !== undefined ? e.perguntaExameOlho17 : '',
 e.respostaExameOlho17 !== undefined ? e.respostaExameOlho17 : '',
 e.comentarioExameOlho17 !== undefined ? e.comentarioExameOlho17 : '',
 e.perguntaExameOlho18 !== undefined ? e.perguntaExameOlho18 : '',
 e.respostaExameOlho18 !== undefined ? e.respostaExameOlho18 : '',
 e.comentarioExameOlho18 !== undefined ? e.comentarioExameOlho18 : '',
 e.perguntaExameOlho19 !== undefined ? e.perguntaExameOlho19 : '',
 e.respostaExameOlho19 !== undefined ? e.respostaExameOlho19 : '',
 e.comentarioExameOlho19 !== undefined ? e.comentarioExameOlho19 : '',
 e.perguntaExameOlho20 !== undefined ? e.perguntaExameOlho20 : '',
 e.respostaExameOlho20 !== undefined ? e.respostaExameOlho20 : '',
 e.comentarioExameOlho20 !== undefined ? e.comentarioExameOlho20 : '', */
            ]) : y.push(
 ['','','','','','','','','','','' ,''/*,'','','','','','','','','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Olho',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
              /*  y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
            ],
             }, 
    },
 ]
 }
}

const dadosExameOuvidoPdf = () => {
    let y = []
    if (tudo._35_exameOuvido && tudo._35_exameOuvido.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._35_exameOuvido !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._35_exameOuvido !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._35_exameOuvido : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameOuvido0 !== undefined ? e.perguntaExameOuvido0 : '',
 e.respostaExameOuvido0 !== undefined ? e.respostaExameOuvido0 : '',
 e.comentarioExameOuvido0 !== undefined ? e.comentarioExameOuvido0 : '',
 e.perguntaExameOuvido1 !== undefined ? e.perguntaExameOuvido1 : '',
 e.respostaExameOuvido1 !== undefined ? e.respostaExameOuvido1 : '',
 e.comentarioExameOuvido1 !== undefined ? e.comentarioExameOuvido1 : '',
 e.perguntaExameOuvido2 !== undefined ? e.perguntaExameOuvido2 : '',
 e.respostaExameOuvido2 !== undefined ? e.respostaExameOuvido2 : '',
 e.comentarioExameOuvido2 !== undefined ? e.comentarioExameOuvido2 : '',
 e.perguntaExameOuvido3 !== undefined ? e.perguntaExameOuvido3 : '',
 e.respostaExameOuvido3 !== undefined ? e.respostaExameOuvido3 : '',
 e.comentarioExameOuvido3 !== undefined ? e.comentarioExameOuvido3 : '',
 e.perguntaExameOuvido4 !== undefined ? e.perguntaExameOuvido4 : '',
 e.respostaExameOuvido4 !== undefined ? e.respostaExameOuvido4 : '',
 e.comentarioExameOuvido4 !== undefined ? e.comentarioExameOuvido4 : '',
 e.perguntaExameOuvido5 !== undefined ? e.perguntaExameOuvido5 : '',
 e.respostaExameOuvido5 !== undefined ? e.respostaExameOuvido5 : '',
 e.comentarioExameOuvido5 !== undefined ? e.comentarioExameOuvido5 : '',
 e.perguntaExameOuvido6 !== undefined ? e.perguntaExameOuvido6 : '',
 e.respostaExameOuvido6 !== undefined ? e.respostaExameOuvido6 : '',
 e.comentarioExameOuvido6 !== undefined ? e.comentarioExameOuvido6 : '',
 e.perguntaExameOuvido7 !== undefined ? e.perguntaExameOuvido7 : '',
 e.respostaExameOuvido7 !== undefined ? e.respostaExameOuvido7 : '',
 e.comentarioExameOuvido7 !== undefined ? e.comentarioExameOuvido7 : '',
 e.perguntaExameOuvido8 !== undefined ? e.perguntaExameOuvido8 : '',
 e.respostaExameOuvido8 !== undefined ? e.respostaExameOuvido8 : '',
 e.comentarioExameOuvido8 !== undefined ? e.comentarioExameOuvido8 : '',
 /* e.perguntaExameOuvido9 !== undefined ? e.perguntaExameOuvido9 : '',
 e.respostaExameOuvido9 !== undefined ? e.respostaExameOuvido9 : '',
 e.comentarioExameOuvido9 !== undefined ? e.comentarioExameOuvido9 : '',
 e.perguntaExameOuvido10 !== undefined ? e.perguntaExameOuvido10 : '',
 e.respostaExameOuvido10 !== undefined ? e.respostaExameOuvido10 : '',
 e.comentarioExameOuvido10 !== undefined ? e.comentarioExameOuvido10 : '',
 e.perguntaExameOuvido11 !== undefined ? e.perguntaExameOuvido11 : '',
 e.respostaExameOuvido11 !== undefined ? e.respostaExameOuvido11 : '',
 e.comentarioExameOuvido11 !== undefined ? e.comentarioExameOuvido11 : '', */
 /*e.perguntaExameOuvido12 !== undefined ? e.perguntaExameOuvido12 : '',
 e.respostaExameOuvido12 !== undefined ? e.respostaExameOuvido12 : '',
  e.comentarioExameOuvido12 !== undefined ? e.comentarioExameOuvido12 : '',
 e.perguntaExameOuvido13 !== undefined ? e.perguntaExameOuvido13 : '',
 e.respostaExameOuvido13 !== undefined ? e.respostaExameOuvido13 : '',
 e.comentarioExameOuvido13 !== undefined ? e.comentarioExameOuvido13 : '',
 e.perguntaExameOuvido14 !== undefined ? e.perguntaExameOuvido14 : '',
 e.respostaExameOuvido14 !== undefined ? e.respostaExameOuvido14 : '',
 e.comentarioExameOuvido14 !== undefined ? e.comentarioExameOuvido14 : '',
 e.perguntaExameOuvido15 !== undefined ? e.perguntaExameOuvido15 : '',
 e.respostaExameOuvido15 !== undefined ? e.respostaExameOuvido15 : '',
 e.comentarioExameOuvido15 !== undefined ? e.comentarioExameOuvido15 : '',
 e.perguntaExameOuvido16 !== undefined ? e.perguntaExameOuvido16 : '',
 e.respostaExameOuvido16 !== undefined ? e.respostaExameOuvido16 : '',
 e.comentarioExameOuvido16 !== undefined ? e.comentarioExameOuvido16 : '',
 e.perguntaExameOuvido17 !== undefined ? e.perguntaExameOuvido17 : '',
 e.respostaExameOuvido17 !== undefined ? e.respostaExameOuvido17 : '',
 e.comentarioExameOuvido17 !== undefined ? e.comentarioExameOuvido17 : '',
 e.perguntaExameOuvido18 !== undefined ? e.perguntaExameOuvido18 : '',
 e.respostaExameOuvido18 !== undefined ? e.respostaExameOuvido18 : '',
 e.comentarioExameOuvido18 !== undefined ? e.comentarioExameOuvido18 : '',
 e.perguntaExameOuvido19 !== undefined ? e.perguntaExameOuvido19 : '',
 e.respostaExameOuvido19 !== undefined ? e.respostaExameOuvido19 : '',
 e.comentarioExameOuvido19 !== undefined ? e.comentarioExameOuvido19 : '',
 e.perguntaExameOuvido20 !== undefined ? e.perguntaExameOuvido20 : '',
 e.respostaExameOuvido20 !== undefined ? e.respostaExameOuvido20 : '',
 e.comentarioExameOuvido20 !== undefined ? e.comentarioExameOuvido20 : '', */
            ]) : y.push(
 ['','','','','','','',''/* ,'','','' ,'' *//*,'','','','','','','','','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Ouvido',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
               /*  y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], */
              /*  y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
            ],
             }, 
    },
 ]
 }
}

const dadosExameNarizPdf = () => {
    let y = []
    if (tudo._36_exameNariz && tudo._36_exameNariz.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._36_exameNariz !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._36_exameNariz !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._36_exameNariz : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameNariz0 !== undefined ? e.perguntaExameNariz0 : '',
 e.respostaExameNariz0 !== undefined ? e.respostaExameNariz0 : '',
 e.comentarioExameNariz0 !== undefined ? e.comentarioExameNariz0 : '',
 e.perguntaExameNariz1 !== undefined ? e.perguntaExameNariz1 : '',
 e.respostaExameNariz1 !== undefined ? e.respostaExameNariz1 : '',
 e.comentarioExameNariz1 !== undefined ? e.comentarioExameNariz1 : '',
 e.perguntaExameNariz2 !== undefined ? e.perguntaExameNariz2 : '',
 e.respostaExameNariz2 !== undefined ? e.respostaExameNariz2 : '',
 e.comentarioExameNariz2 !== undefined ? e.comentarioExameNariz2 : '',
 e.perguntaExameNariz3 !== undefined ? e.perguntaExameNariz3 : '',
 e.respostaExameNariz3 !== undefined ? e.respostaExameNariz3 : '',
 e.comentarioExameNariz3 !== undefined ? e.comentarioExameNariz3 : '',
 /* e.perguntaExameNariz4 !== undefined ? e.perguntaExameNariz4 : '',
 e.respostaExameNariz4 !== undefined ? e.respostaExameNariz4 : '',
 e.comentarioExameNariz4 !== undefined ? e.comentarioExameNariz4 : '',
 e.perguntaExameNariz5 !== undefined ? e.perguntaExameNariz5 : '',
 e.respostaExameNariz5 !== undefined ? e.respostaExameNariz5 : '',
 e.comentarioExameNariz5 !== undefined ? e.comentarioExameNariz5 : '',
 e.perguntaExameNariz6 !== undefined ? e.perguntaExameNariz6 : '',
 e.respostaExameNariz6 !== undefined ? e.respostaExameNariz6 : '',
 e.comentarioExameNariz6 !== undefined ? e.comentarioExameNariz6 : '',
 e.perguntaExameNariz7 !== undefined ? e.perguntaExameNariz7 : '',
 e.respostaExameNariz7 !== undefined ? e.respostaExameNariz7 : '',
 e.comentarioExameNariz7 !== undefined ? e.comentarioExameNariz7 : '',
 e.perguntaExameNariz8 !== undefined ? e.perguntaExameNariz8 : '',
 e.respostaExameNariz8 !== undefined ? e.respostaExameNariz8 : '',
 e.comentarioExameNariz8 !== undefined ? e.comentarioExameNariz8 : '', */
 /* e.perguntaExameNariz9 !== undefined ? e.perguntaExameNariz9 : '',
 e.respostaExameNariz9 !== undefined ? e.respostaExameNariz9 : '',
 e.comentarioExameNariz9 !== undefined ? e.comentarioExameNariz9 : '',
 e.perguntaExameNariz10 !== undefined ? e.perguntaExameNariz10 : '',
 e.respostaExameNariz10 !== undefined ? e.respostaExameNariz10 : '',
 e.comentarioExameNariz10 !== undefined ? e.comentarioExameNariz10 : '',
 e.perguntaExameNariz11 !== undefined ? e.perguntaExameNariz11 : '',
 e.respostaExameNariz11 !== undefined ? e.respostaExameNariz11 : '',
 e.comentarioExameNariz11 !== undefined ? e.comentarioExameNariz11 : '', */
 /*e.perguntaExameNariz12 !== undefined ? e.perguntaExameNariz12 : '',
 e.respostaExameNariz12 !== undefined ? e.respostaExameNariz12 : '',
  e.comentarioExameNariz12 !== undefined ? e.comentarioExameNariz12 : '',
 e.perguntaExameNariz13 !== undefined ? e.perguntaExameNariz13 : '',
 e.respostaExameNariz13 !== undefined ? e.respostaExameNariz13 : '',
 e.comentarioExameNariz13 !== undefined ? e.comentarioExameNariz13 : '',
 e.perguntaExameNariz14 !== undefined ? e.perguntaExameNariz14 : '',
 e.respostaExameNariz14 !== undefined ? e.respostaExameNariz14 : '',
 e.comentarioExameNariz14 !== undefined ? e.comentarioExameNariz14 : '',
 e.perguntaExameNariz15 !== undefined ? e.perguntaExameNariz15 : '',
 e.respostaExameNariz15 !== undefined ? e.respostaExameNariz15 : '',
 e.comentarioExameNariz15 !== undefined ? e.comentarioExameNariz15 : '',
 e.perguntaExameNariz16 !== undefined ? e.perguntaExameNariz16 : '',
 e.respostaExameNariz16 !== undefined ? e.respostaExameNariz16 : '',
 e.comentarioExameNariz16 !== undefined ? e.comentarioExameNariz16 : '',
 e.perguntaExameNariz17 !== undefined ? e.perguntaExameNariz17 : '',
 e.respostaExameNariz17 !== undefined ? e.respostaExameNariz17 : '',
 e.comentarioExameNariz17 !== undefined ? e.comentarioExameNariz17 : '',
 e.perguntaExameNariz18 !== undefined ? e.perguntaExameNariz18 : '',
 e.respostaExameNariz18 !== undefined ? e.respostaExameNariz18 : '',
 e.comentarioExameNariz18 !== undefined ? e.comentarioExameNariz18 : '',
 e.perguntaExameNariz19 !== undefined ? e.perguntaExameNariz19 : '',
 e.respostaExameNariz19 !== undefined ? e.respostaExameNariz19 : '',
 e.comentarioExameNariz19 !== undefined ? e.comentarioExameNariz19 : '',
 e.perguntaExameNariz20 !== undefined ? e.perguntaExameNariz20 : '',
 e.respostaExameNariz20 !== undefined ? e.respostaExameNariz20 : '',
 e.comentarioExameNariz20 !== undefined ? e.comentarioExameNariz20 : '', */
            ]) : y.push(
 ['','','',''/* ,'','','','' *//* ,'','','' ,'' *//*,'','','','','','','','','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Nariz',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
               /*  y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''], */
               /*  y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], */
              /*  y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
            ],
             }, 
    },
 ]
 }
}

const dadosExameGargantaPdf = () => {
    let y = []
    if (tudo._37_exameGarganta && tudo._37_exameGarganta.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._37_exameGarganta !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._37_exameGarganta !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._37_exameGarganta : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameGarganta0 !== undefined ? e.perguntaExameGarganta0 : '',
 e.respostaExameGarganta0 !== undefined ? e.respostaExameGarganta0 : '',
 e.comentarioExameGarganta0 !== undefined ? e.comentarioExameGarganta0 : '',
 e.perguntaExameGarganta1 !== undefined ? e.perguntaExameGarganta1 : '',
 e.respostaExameGarganta1 !== undefined ? e.respostaExameGarganta1 : '',
 e.comentarioExameGarganta1 !== undefined ? e.comentarioExameGarganta1 : '',
 e.perguntaExameGarganta2 !== undefined ? e.perguntaExameGarganta2 : '',
 e.respostaExameGarganta2 !== undefined ? e.respostaExameGarganta2 : '',
 e.comentarioExameGarganta2 !== undefined ? e.comentarioExameGarganta2 : '',
 e.perguntaExameGarganta3 !== undefined ? e.perguntaExameGarganta3 : '',
 e.respostaExameGarganta3 !== undefined ? e.respostaExameGarganta3 : '',
 e.comentarioExameGarganta3 !== undefined ? e.comentarioExameGarganta3 : '',
  e.perguntaExameGarganta4 !== undefined ? e.perguntaExameGarganta4 : '',
 e.respostaExameGarganta4 !== undefined ? e.respostaExameGarganta4 : '',
 e.comentarioExameGarganta4 !== undefined ? e.comentarioExameGarganta4 : '',
 e.perguntaExameGarganta5 !== undefined ? e.perguntaExameGarganta5 : '',
 e.respostaExameGarganta5 !== undefined ? e.respostaExameGarganta5 : '',
 e.comentarioExameGarganta5 !== undefined ? e.comentarioExameGarganta5 : '',
 e.perguntaExameGarganta6 !== undefined ? e.perguntaExameGarganta6 : '',
 e.respostaExameGarganta6 !== undefined ? e.respostaExameGarganta6 : '',
 e.comentarioExameGarganta6 !== undefined ? e.comentarioExameGarganta6 : '',
 /*e.perguntaExameGarganta7 !== undefined ? e.perguntaExameGarganta7 : '',
 e.respostaExameGarganta7 !== undefined ? e.respostaExameGarganta7 : '',
 e.comentarioExameGarganta7 !== undefined ? e.comentarioExameGarganta7 : '',
 e.perguntaExameGarganta8 !== undefined ? e.perguntaExameGarganta8 : '',
 e.respostaExameGarganta8 !== undefined ? e.respostaExameGarganta8 : '',
 e.comentarioExameGarganta8 !== undefined ? e.comentarioExameGarganta8 : '', */
 /* e.perguntaExameGarganta9 !== undefined ? e.perguntaExameGarganta9 : '',
 e.respostaExameGarganta9 !== undefined ? e.respostaExameGarganta9 : '',
 e.comentarioExameGarganta9 !== undefined ? e.comentarioExameGarganta9 : '',
 e.perguntaExameGarganta10 !== undefined ? e.perguntaExameGarganta10 : '',
 e.respostaExameGarganta10 !== undefined ? e.respostaExameGarganta10 : '',
 e.comentarioExameGarganta10 !== undefined ? e.comentarioExameGarganta10 : '',
 e.perguntaExameGarganta11 !== undefined ? e.perguntaExameGarganta11 : '',
 e.respostaExameGarganta11 !== undefined ? e.respostaExameGarganta11 : '',
 e.comentarioExameGarganta11 !== undefined ? e.comentarioExameGarganta11 : '', */
 /*e.perguntaExameGarganta12 !== undefined ? e.perguntaExameGarganta12 : '',
 e.respostaExameGarganta12 !== undefined ? e.respostaExameGarganta12 : '',
  e.comentarioExameGarganta12 !== undefined ? e.comentarioExameGarganta12 : '',
 e.perguntaExameGarganta13 !== undefined ? e.perguntaExameGarganta13 : '',
 e.respostaExameGarganta13 !== undefined ? e.respostaExameGarganta13 : '',
 e.comentarioExameGarganta13 !== undefined ? e.comentarioExameGarganta13 : '',
 e.perguntaExameGarganta14 !== undefined ? e.perguntaExameGarganta14 : '',
 e.respostaExameGarganta14 !== undefined ? e.respostaExameGarganta14 : '',
 e.comentarioExameGarganta14 !== undefined ? e.comentarioExameGarganta14 : '',
 e.perguntaExameGarganta15 !== undefined ? e.perguntaExameGarganta15 : '',
 e.respostaExameGarganta15 !== undefined ? e.respostaExameGarganta15 : '',
 e.comentarioExameGarganta15 !== undefined ? e.comentarioExameGarganta15 : '',
 e.perguntaExameGarganta16 !== undefined ? e.perguntaExameGarganta16 : '',
 e.respostaExameGarganta16 !== undefined ? e.respostaExameGarganta16 : '',
 e.comentarioExameGarganta16 !== undefined ? e.comentarioExameGarganta16 : '',
 e.perguntaExameGarganta17 !== undefined ? e.perguntaExameGarganta17 : '',
 e.respostaExameGarganta17 !== undefined ? e.respostaExameGarganta17 : '',
 e.comentarioExameGarganta17 !== undefined ? e.comentarioExameGarganta17 : '',
 e.perguntaExameGarganta18 !== undefined ? e.perguntaExameGarganta18 : '',
 e.respostaExameGarganta18 !== undefined ? e.respostaExameGarganta18 : '',
 e.comentarioExameGarganta18 !== undefined ? e.comentarioExameGarganta18 : '',
 e.perguntaExameGarganta19 !== undefined ? e.perguntaExameGarganta19 : '',
 e.respostaExameGarganta19 !== undefined ? e.respostaExameGarganta19 : '',
 e.comentarioExameGarganta19 !== undefined ? e.comentarioExameGarganta19 : '',
 e.perguntaExameGarganta20 !== undefined ? e.perguntaExameGarganta20 : '',
 e.respostaExameGarganta20 !== undefined ? e.respostaExameGarganta20 : '',
 e.comentarioExameGarganta20 !== undefined ? e.comentarioExameGarganta20 : '', */
            ]) : y.push(
 ['','','','' ,'','',''/*,'' *//* ,'','','' ,'' *//*,'','','','','','','','','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Garganta',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                 y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
               y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
              /*   y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''], */
               /*  y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], */
              /*  y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
            ],
             }, 
    },
 ]
 }
}

const dadosExameColunaCervicalPdf = () => {
    let y = []
    if (tudo._38_exameColunaCervical && tudo._38_exameColunaCervical.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._38_exameColunaCervical !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._38_exameColunaCervical !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._38_exameColunaCervical : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameColunaCervical0 !== undefined ? e.perguntaExameColunaCervical0 : '',
 e.respostaExameColunaCervical0 !== undefined ? e.respostaExameColunaCervical0 : '',
 e.comentarioExameColunaCervical0 !== undefined ? e.comentarioExameColunaCervical0 : '',
 e.perguntaExameColunaCervical1 !== undefined ? e.perguntaExameColunaCervical1 : '',
 e.respostaExameColunaCervical1 !== undefined ? e.respostaExameColunaCervical1 : '',
 e.comentarioExameColunaCervical1 !== undefined ? e.comentarioExameColunaCervical1 : '',
 e.perguntaExameColunaCervical2 !== undefined ? e.perguntaExameColunaCervical2 : '',
 e.respostaExameColunaCervical2 !== undefined ? e.respostaExameColunaCervical2 : '',
 e.comentarioExameColunaCervical2 !== undefined ? e.comentarioExameColunaCervical2 : '',
 e.perguntaExameColunaCervical3 !== undefined ? e.perguntaExameColunaCervical3 : '',
 e.respostaExameColunaCervical3 !== undefined ? e.respostaExameColunaCervical3 : '',
 e.comentarioExameColunaCervical3 !== undefined ? e.comentarioExameColunaCervical3 : '',
 e.perguntaExameColunaCervical4 !== undefined ? e.perguntaExameColunaCervical4 : '',
 e.respostaExameColunaCervical4 !== undefined ? e.respostaExameColunaCervical4 : '',
 e.comentarioExameColunaCervical4 !== undefined ? e.comentarioExameColunaCervical4 : '',
 e.perguntaExameColunaCervical5 !== undefined ? e.perguntaExameColunaCervical5 : '',
 e.respostaExameColunaCervical5 !== undefined ? e.respostaExameColunaCervical5 : '',
 e.comentarioExameColunaCervical5 !== undefined ? e.comentarioExameColunaCervical5 : '',
 e.perguntaExameColunaCervical6 !== undefined ? e.perguntaExameColunaCervical6 : '',
 e.respostaExameColunaCervical6 !== undefined ? e.respostaExameColunaCervical6 : '',
 e.comentarioExameColunaCervical6 !== undefined ? e.comentarioExameColunaCervical6 : '',
 e.perguntaExameColunaCervical7 !== undefined ? e.perguntaExameColunaCervical7 : '',
 e.respostaExameColunaCervical7 !== undefined ? e.respostaExameColunaCervical7 : '',
 e.comentarioExameColunaCervical7 !== undefined ? e.comentarioExameColunaCervical7 : '',
 e.perguntaExameColunaCervical8 !== undefined ? e.perguntaExameColunaCervical8 : '',
 e.respostaExameColunaCervical8 !== undefined ? e.respostaExameColunaCervical8 : '',
 e.comentarioExameColunaCervical8 !== undefined ? e.comentarioExameColunaCervical8 : '',
 e.perguntaExameColunaCervical9 !== undefined ? e.perguntaExameColunaCervical9 : '',
 e.respostaExameColunaCervical9 !== undefined ? e.respostaExameColunaCervical9 : '',
 e.comentarioExameColunaCervical9 !== undefined ? e.comentarioExameColunaCervical9 : '',
 e.perguntaExameColunaCervical10 !== undefined ? e.perguntaExameColunaCervical10 : '',
 e.respostaExameColunaCervical10 !== undefined ? e.respostaExameColunaCervical10 : '',
 e.comentarioExameColunaCervical10 !== undefined ? e.comentarioExameColunaCervical10 : '',
 e.perguntaExameColunaCervical11 !== undefined ? e.perguntaExameColunaCervical11 : '',
 e.respostaExameColunaCervical11 !== undefined ? e.respostaExameColunaCervical11 : '',
 e.comentarioExameColunaCervical11 !== undefined ? e.comentarioExameColunaCervical11 : '',
 /*e.perguntaExameColunaCervical12 !== undefined ? e.perguntaExameColunaCervical12 : '',
 e.respostaExameColunaCervical12 !== undefined ? e.respostaExameColunaCervical12 : '',
  e.comentarioExameColunaCervical12 !== undefined ? e.comentarioExameColunaCervical12 : '',
 e.perguntaExameColunaCervical13 !== undefined ? e.perguntaExameColunaCervical13 : '',
 e.respostaExameColunaCervical13 !== undefined ? e.respostaExameColunaCervical13 : '',
 e.comentarioExameColunaCervical13 !== undefined ? e.comentarioExameColunaCervical13 : '',
 e.perguntaExameColunaCervical14 !== undefined ? e.perguntaExameColunaCervical14 : '',
 e.respostaExameColunaCervical14 !== undefined ? e.respostaExameColunaCervical14 : '',
 e.comentarioExameColunaCervical14 !== undefined ? e.comentarioExameColunaCervical14 : '',
 e.perguntaExameColunaCervical15 !== undefined ? e.perguntaExameColunaCervical15 : '',
 e.respostaExameColunaCervical15 !== undefined ? e.respostaExameColunaCervical15 : '',
 e.comentarioExameColunaCervical15 !== undefined ? e.comentarioExameColunaCervical15 : '',
 e.perguntaExameColunaCervical16 !== undefined ? e.perguntaExameColunaCervical16 : '',
 e.respostaExameColunaCervical16 !== undefined ? e.respostaExameColunaCervical16 : '',
 e.comentarioExameColunaCervical16 !== undefined ? e.comentarioExameColunaCervical16 : '',
 e.perguntaExameColunaCervical17 !== undefined ? e.perguntaExameColunaCervical17 : '',
 e.respostaExameColunaCervical17 !== undefined ? e.respostaExameColunaCervical17 : '',
 e.comentarioExameColunaCervical17 !== undefined ? e.comentarioExameColunaCervical17 : '',
 e.perguntaExameColunaCervical18 !== undefined ? e.perguntaExameColunaCervical18 : '',
 e.respostaExameColunaCervical18 !== undefined ? e.respostaExameColunaCervical18 : '',
 e.comentarioExameColunaCervical18 !== undefined ? e.comentarioExameColunaCervical18 : '',
 e.perguntaExameColunaCervical19 !== undefined ? e.perguntaExameColunaCervical19 : '',
 e.respostaExameColunaCervical19 !== undefined ? e.respostaExameColunaCervical19 : '',
 e.comentarioExameColunaCervical19 !== undefined ? e.comentarioExameColunaCervical19 : '',
 e.perguntaExameColunaCervical20 !== undefined ? e.perguntaExameColunaCervical20 : '',
 e.respostaExameColunaCervical20 !== undefined ? e.respostaExameColunaCervical20 : '',
 e.comentarioExameColunaCervical20 !== undefined ? e.comentarioExameColunaCervical20 : '', */
            ]) : y.push(
 ['','','','','','','','','','','' ,''/*,'','','','','','','','','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Coluna Cervical',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                /* y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], */
              /*  y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
            ],
             }, 
    },
 ]
 }
}

const dadosExameColunaLombarPdf = () => {
    let y = []
    if (tudo._39_exameColunaLombar && tudo._39_exameColunaLombar.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._39_exameColunaLombar !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._39_exameColunaLombar !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._39_exameColunaLombar : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameColunaLombar0 !== undefined ? e.perguntaExameColunaLombar0 : '',
 e.respostaExameColunaLombar0 !== undefined ? e.respostaExameColunaLombar0 : '',
 e.comentarioExameColunaLombar0 !== undefined ? e.comentarioExameColunaLombar0 : '',
 e.perguntaExameColunaLombar1 !== undefined ? e.perguntaExameColunaLombar1 : '',
 e.respostaExameColunaLombar1 !== undefined ? e.respostaExameColunaLombar1 : '',
 e.comentarioExameColunaLombar1 !== undefined ? e.comentarioExameColunaLombar1 : '',
 e.perguntaExameColunaLombar2 !== undefined ? e.perguntaExameColunaLombar2 : '',
 e.respostaExameColunaLombar2 !== undefined ? e.respostaExameColunaLombar2 : '',
 e.comentarioExameColunaLombar2 !== undefined ? e.comentarioExameColunaLombar2 : '',
 e.perguntaExameColunaLombar3 !== undefined ? e.perguntaExameColunaLombar3 : '',
 e.respostaExameColunaLombar3 !== undefined ? e.respostaExameColunaLombar3 : '',
 e.comentarioExameColunaLombar3 !== undefined ? e.comentarioExameColunaLombar3 : '',
 e.perguntaExameColunaLombar4 !== undefined ? e.perguntaExameColunaLombar4 : '',
 e.respostaExameColunaLombar4 !== undefined ? e.respostaExameColunaLombar4 : '',
 e.comentarioExameColunaLombar4 !== undefined ? e.comentarioExameColunaLombar4 : '',
 e.perguntaExameColunaLombar5 !== undefined ? e.perguntaExameColunaLombar5 : '',
 e.respostaExameColunaLombar5 !== undefined ? e.respostaExameColunaLombar5 : '',
 e.comentarioExameColunaLombar5 !== undefined ? e.comentarioExameColunaLombar5 : '',
 e.perguntaExameColunaLombar6 !== undefined ? e.perguntaExameColunaLombar6 : '',
 e.respostaExameColunaLombar6 !== undefined ? e.respostaExameColunaLombar6 : '',
 e.comentarioExameColunaLombar6 !== undefined ? e.comentarioExameColunaLombar6 : '',
 e.perguntaExameColunaLombar7 !== undefined ? e.perguntaExameColunaLombar7 : '',
 e.respostaExameColunaLombar7 !== undefined ? e.respostaExameColunaLombar7 : '',
 e.comentarioExameColunaLombar7 !== undefined ? e.comentarioExameColunaLombar7 : '',
 e.perguntaExameColunaLombar8 !== undefined ? e.perguntaExameColunaLombar8 : '',
 e.respostaExameColunaLombar8 !== undefined ? e.respostaExameColunaLombar8 : '',
 e.comentarioExameColunaLombar8 !== undefined ? e.comentarioExameColunaLombar8 : '',
 e.perguntaExameColunaLombar9 !== undefined ? e.perguntaExameColunaLombar9 : '',
 e.respostaExameColunaLombar9 !== undefined ? e.respostaExameColunaLombar9 : '',
 e.comentarioExameColunaLombar9 !== undefined ? e.comentarioExameColunaLombar9 : '',
 e.perguntaExameColunaLombar10 !== undefined ? e.perguntaExameColunaLombar10 : '',
 e.respostaExameColunaLombar10 !== undefined ? e.respostaExameColunaLombar10 : '',
 e.comentarioExameColunaLombar10 !== undefined ? e.comentarioExameColunaLombar10 : '',
 e.perguntaExameColunaLombar11 !== undefined ? e.perguntaExameColunaLombar11 : '',
 e.respostaExameColunaLombar11 !== undefined ? e.respostaExameColunaLombar11 : '',
 e.comentarioExameColunaLombar11 !== undefined ? e.comentarioExameColunaLombar11 : '',
 e.perguntaExameColunaLombar12 !== undefined ? e.perguntaExameColunaLombar12 : '',
 e.respostaExameColunaLombar12 !== undefined ? e.respostaExameColunaLombar12 : '',
  e.comentarioExameColunaLombar12 !== undefined ? e.comentarioExameColunaLombar12 : '',
 e.perguntaExameColunaLombar13 !== undefined ? e.perguntaExameColunaLombar13 : '',
 e.respostaExameColunaLombar13 !== undefined ? e.respostaExameColunaLombar13 : '',
 e.comentarioExameColunaLombar13 !== undefined ? e.comentarioExameColunaLombar13 : '',
 e.perguntaExameColunaLombar14 !== undefined ? e.perguntaExameColunaLombar14 : '',
 e.respostaExameColunaLombar14 !== undefined ? e.respostaExameColunaLombar14 : '',
 e.comentarioExameColunaLombar14 !== undefined ? e.comentarioExameColunaLombar14 : '',
 /*e.perguntaExameColunaLombar15 !== undefined ? e.perguntaExameColunaLombar15 : '',
 e.respostaExameColunaLombar15 !== undefined ? e.respostaExameColunaLombar15 : '',
 e.comentarioExameColunaLombar15 !== undefined ? e.comentarioExameColunaLombar15 : '',
 e.perguntaExameColunaLombar16 !== undefined ? e.perguntaExameColunaLombar16 : '',
 e.respostaExameColunaLombar16 !== undefined ? e.respostaExameColunaLombar16 : '',
 e.comentarioExameColunaLombar16 !== undefined ? e.comentarioExameColunaLombar16 : '',
 e.perguntaExameColunaLombar17 !== undefined ? e.perguntaExameColunaLombar17 : '',
 e.respostaExameColunaLombar17 !== undefined ? e.respostaExameColunaLombar17 : '',
 e.comentarioExameColunaLombar17 !== undefined ? e.comentarioExameColunaLombar17 : '',
 e.perguntaExameColunaLombar18 !== undefined ? e.perguntaExameColunaLombar18 : '',
 e.respostaExameColunaLombar18 !== undefined ? e.respostaExameColunaLombar18 : '',
 e.comentarioExameColunaLombar18 !== undefined ? e.comentarioExameColunaLombar18 : '',
 e.perguntaExameColunaLombar19 !== undefined ? e.perguntaExameColunaLombar19 : '',
 e.respostaExameColunaLombar19 !== undefined ? e.respostaExameColunaLombar19 : '',
 e.comentarioExameColunaLombar19 !== undefined ? e.comentarioExameColunaLombar19 : '',
 e.perguntaExameColunaLombar20 !== undefined ? e.perguntaExameColunaLombar20 : '',
 e.respostaExameColunaLombar20 !== undefined ? e.respostaExameColunaLombar20 : '',
 e.comentarioExameColunaLombar20 !== undefined ? e.comentarioExameColunaLombar20 : '', */
            ]) : y.push(
 ['','','','','','','','','','','' ,''/*,'','','','','','','','','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Coluna Lombar',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
              /*  y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],*/
            ],
             }, 
    },
 ]
 }
}

const dadosExameOmbroPdf = () => {
    let y = []
    if (tudo._40_exameOmbro && tudo._40_exameOmbro.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._40_exameOmbro !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._40_exameOmbro !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._40_exameOmbro : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameOmbro0 !== undefined ? e.perguntaExameOmbro0 : '',
 e.respostaExameOmbro0 !== undefined ? e.respostaExameOmbro0 : '',
 e.comentarioExameOmbro0 !== undefined ? e.comentarioExameOmbro0 : '',
 e.perguntaExameOmbro1 !== undefined ? e.perguntaExameOmbro1 : '',
 e.respostaExameOmbro1 !== undefined ? e.respostaExameOmbro1 : '',
 e.comentarioExameOmbro1 !== undefined ? e.comentarioExameOmbro1 : '',
 e.perguntaExameOmbro2 !== undefined ? e.perguntaExameOmbro2 : '',
 e.respostaExameOmbro2 !== undefined ? e.respostaExameOmbro2 : '',
 e.comentarioExameOmbro2 !== undefined ? e.comentarioExameOmbro2 : '',
 e.perguntaExameOmbro3 !== undefined ? e.perguntaExameOmbro3 : '',
 e.respostaExameOmbro3 !== undefined ? e.respostaExameOmbro3 : '',
 e.comentarioExameOmbro3 !== undefined ? e.comentarioExameOmbro3 : '',
 e.perguntaExameOmbro4 !== undefined ? e.perguntaExameOmbro4 : '',
 e.respostaExameOmbro4 !== undefined ? e.respostaExameOmbro4 : '',
 e.comentarioExameOmbro4 !== undefined ? e.comentarioExameOmbro4 : '',
 e.perguntaExameOmbro5 !== undefined ? e.perguntaExameOmbro5 : '',
 e.respostaExameOmbro5 !== undefined ? e.respostaExameOmbro5 : '',
 e.comentarioExameOmbro5 !== undefined ? e.comentarioExameOmbro5 : '',
 e.perguntaExameOmbro6 !== undefined ? e.perguntaExameOmbro6 : '',
 e.respostaExameOmbro6 !== undefined ? e.respostaExameOmbro6 : '',
 e.comentarioExameOmbro6 !== undefined ? e.comentarioExameOmbro6 : '',
 e.perguntaExameOmbro7 !== undefined ? e.perguntaExameOmbro7 : '',
 e.respostaExameOmbro7 !== undefined ? e.respostaExameOmbro7 : '',
 e.comentarioExameOmbro7 !== undefined ? e.comentarioExameOmbro7 : '',
 e.perguntaExameOmbro8 !== undefined ? e.perguntaExameOmbro8 : '',
 e.respostaExameOmbro8 !== undefined ? e.respostaExameOmbro8 : '',
 e.comentarioExameOmbro8 !== undefined ? e.comentarioExameOmbro8 : '',
 e.perguntaExameOmbro9 !== undefined ? e.perguntaExameOmbro9 : '',
 e.respostaExameOmbro9 !== undefined ? e.respostaExameOmbro9 : '',
 e.comentarioExameOmbro9 !== undefined ? e.comentarioExameOmbro9 : '',
 e.perguntaExameOmbro10 !== undefined ? e.perguntaExameOmbro10 : '',
 e.respostaExameOmbro10 !== undefined ? e.respostaExameOmbro10 : '',
 e.comentarioExameOmbro10 !== undefined ? e.comentarioExameOmbro10 : '',
 e.perguntaExameOmbro11 !== undefined ? e.perguntaExameOmbro11 : '',
 e.respostaExameOmbro11 !== undefined ? e.respostaExameOmbro11 : '',
 e.comentarioExameOmbro11 !== undefined ? e.comentarioExameOmbro11 : '',
 e.perguntaExameOmbro12 !== undefined ? e.perguntaExameOmbro12 : '',
 e.respostaExameOmbro12 !== undefined ? e.respostaExameOmbro12 : '',
  e.comentarioExameOmbro12 !== undefined ? e.comentarioExameOmbro12 : '',
 e.perguntaExameOmbro13 !== undefined ? e.perguntaExameOmbro13 : '',
 e.respostaExameOmbro13 !== undefined ? e.respostaExameOmbro13 : '',
 e.comentarioExameOmbro13 !== undefined ? e.comentarioExameOmbro13 : '',
 e.perguntaExameOmbro14 !== undefined ? e.perguntaExameOmbro14 : '',
 e.respostaExameOmbro14 !== undefined ? e.respostaExameOmbro14 : '',
 e.comentarioExameOmbro14 !== undefined ? e.comentarioExameOmbro14 : '',
 e.perguntaExameOmbro15 !== undefined ? e.perguntaExameOmbro15 : '',
 e.respostaExameOmbro15 !== undefined ? e.respostaExameOmbro15 : '',
 e.comentarioExameOmbro15 !== undefined ? e.comentarioExameOmbro15 : '',
 e.perguntaExameOmbro16 !== undefined ? e.perguntaExameOmbro16 : '',
 e.respostaExameOmbro16 !== undefined ? e.respostaExameOmbro16 : '',
 e.comentarioExameOmbro16 !== undefined ? e.comentarioExameOmbro16 : '',
 e.perguntaExameOmbro17 !== undefined ? e.perguntaExameOmbro17 : '',
 e.respostaExameOmbro17 !== undefined ? e.respostaExameOmbro17 : '',
 e.comentarioExameOmbro17 !== undefined ? e.comentarioExameOmbro17 : '',
 e.perguntaExameOmbro18 !== undefined ? e.perguntaExameOmbro18 : '',
 e.respostaExameOmbro18 !== undefined ? e.respostaExameOmbro18 : '',
 e.comentarioExameOmbro18 !== undefined ? e.comentarioExameOmbro18 : '',
 e.perguntaExameOmbro19 !== undefined ? e.perguntaExameOmbro19 : '',
 e.respostaExameOmbro19 !== undefined ? e.respostaExameOmbro19 : '',
 e.comentarioExameOmbro19 !== undefined ? e.comentarioExameOmbro19 : '',
 e.perguntaExameOmbro20 !== undefined ? e.perguntaExameOmbro20 : '',
 e.respostaExameOmbro20 !== undefined ? e.respostaExameOmbro20 : '',
 e.comentarioExameOmbro20 !== undefined ? e.comentarioExameOmbro20 : '', 
 e.perguntaExameOmbro21 !== undefined ? e.perguntaExameOmbro21 : '',
 e.respostaExameOmbro21 !== undefined ? e.respostaExameOmbro21 : '',
 e.comentarioExameOmbro21 !== undefined ? e.comentarioExameOmbro21 : '', 
 e.perguntaExameOmbro22 !== undefined ? e.perguntaExameOmbro22 : '',
 e.respostaExameOmbro22 !== undefined ? e.respostaExameOmbro22 : '',
 e.comentarioExameOmbro22 !== undefined ? e.comentarioExameOmbro22 : '', 
 e.perguntaExameOmbro23 !== undefined ? e.perguntaExameOmbro23 : '',
 e.respostaExameOmbro23 !== undefined ? e.respostaExameOmbro23 : '',
 e.comentarioExameOmbro23 !== undefined ? e.comentarioExameOmbro23 : '', 
 e.perguntaExameOmbro24 !== undefined ? e.perguntaExameOmbro24 : '',
 e.respostaExameOmbro24 !== undefined ? e.respostaExameOmbro24 : '',
 e.comentarioExameOmbro24 !== undefined ? e.comentarioExameOmbro24 : '', 
            ]) : y.push(
 ['','','','','','','','','','','' ,'','','','','','','','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame do Ombro',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],
                y.length > 0 && (y[0][64] !== '' ) ? [[y[0][63]],[y[0][64]],[y[0][65]]] : ['','',''],
                y.length > 0 && (y[0][67] !== '' ) ? [[y[0][66]],[y[0][67]],[y[0][68]]] : ['','',''],
                y.length > 0 && (y[0][70] !== '' ) ? [[y[0][69]],[y[0][70]],[y[0][71]]] : ['','',''],
                y.length > 0 && (y[0][73] !== '' ) ? [[y[0][72]],[y[0][73]],[y[0][74]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosExameCotoveloPdf = () => {
    let y = []
    if (tudo._41_exameCotovelo && tudo._41_exameCotovelo.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._41_exameCotovelo !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._41_exameCotovelo !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._41_exameCotovelo : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameCotovelo0 !== undefined ? e.perguntaExameCotovelo0 : '',
 e.respostaExameCotovelo0 !== undefined ? e.respostaExameCotovelo0 : '',
 e.comentarioExameCotovelo0 !== undefined ? e.comentarioExameCotovelo0 : '',
 e.perguntaExameCotovelo1 !== undefined ? e.perguntaExameCotovelo1 : '',
 e.respostaExameCotovelo1 !== undefined ? e.respostaExameCotovelo1 : '',
 e.comentarioExameCotovelo1 !== undefined ? e.comentarioExameCotovelo1 : '',
 e.perguntaExameCotovelo2 !== undefined ? e.perguntaExameCotovelo2 : '',
 e.respostaExameCotovelo2 !== undefined ? e.respostaExameCotovelo2 : '',
 e.comentarioExameCotovelo2 !== undefined ? e.comentarioExameCotovelo2 : '',
 e.perguntaExameCotovelo3 !== undefined ? e.perguntaExameCotovelo3 : '',
 e.respostaExameCotovelo3 !== undefined ? e.respostaExameCotovelo3 : '',
 e.comentarioExameCotovelo3 !== undefined ? e.comentarioExameCotovelo3 : '',
 e.perguntaExameCotovelo4 !== undefined ? e.perguntaExameCotovelo4 : '',
 e.respostaExameCotovelo4 !== undefined ? e.respostaExameCotovelo4 : '',
 e.comentarioExameCotovelo4 !== undefined ? e.comentarioExameCotovelo4 : '',
 e.perguntaExameCotovelo5 !== undefined ? e.perguntaExameCotovelo5 : '',
 e.respostaExameCotovelo5 !== undefined ? e.respostaExameCotovelo5 : '',
 e.comentarioExameCotovelo5 !== undefined ? e.comentarioExameCotovelo5 : '',
 e.perguntaExameCotovelo6 !== undefined ? e.perguntaExameCotovelo6 : '',
 e.respostaExameCotovelo6 !== undefined ? e.respostaExameCotovelo6 : '',
 e.comentarioExameCotovelo6 !== undefined ? e.comentarioExameCotovelo6 : '',
 e.perguntaExameCotovelo7 !== undefined ? e.perguntaExameCotovelo7 : '',
 e.respostaExameCotovelo7 !== undefined ? e.respostaExameCotovelo7 : '',
 e.comentarioExameCotovelo7 !== undefined ? e.comentarioExameCotovelo7 : '',
 e.perguntaExameCotovelo8 !== undefined ? e.perguntaExameCotovelo8 : '',
 e.respostaExameCotovelo8 !== undefined ? e.respostaExameCotovelo8 : '',
 e.comentarioExameCotovelo8 !== undefined ? e.comentarioExameCotovelo8 : '',
 e.perguntaExameCotovelo9 !== undefined ? e.perguntaExameCotovelo9 : '',
 e.respostaExameCotovelo9 !== undefined ? e.respostaExameCotovelo9 : '',
 e.comentarioExameCotovelo9 !== undefined ? e.comentarioExameCotovelo9 : '',
 e.perguntaExameCotovelo10 !== undefined ? e.perguntaExameCotovelo10 : '',
 e.respostaExameCotovelo10 !== undefined ? e.respostaExameCotovelo10 : '',
 e.comentarioExameCotovelo10 !== undefined ? e.comentarioExameCotovelo10 : '',
 e.perguntaExameCotovelo11 !== undefined ? e.perguntaExameCotovelo11 : '',
 e.respostaExameCotovelo11 !== undefined ? e.respostaExameCotovelo11 : '',
 e.comentarioExameCotovelo11 !== undefined ? e.comentarioExameCotovelo11 : '',
 e.perguntaExameCotovelo12 !== undefined ? e.perguntaExameCotovelo12 : '',
 e.respostaExameCotovelo12 !== undefined ? e.respostaExameCotovelo12 : '',
  e.comentarioExameCotovelo12 !== undefined ? e.comentarioExameCotovelo12 : '',
 e.perguntaExameCotovelo13 !== undefined ? e.perguntaExameCotovelo13 : '',
 e.respostaExameCotovelo13 !== undefined ? e.respostaExameCotovelo13 : '',
 e.comentarioExameCotovelo13 !== undefined ? e.comentarioExameCotovelo13 : '',
 e.perguntaExameCotovelo14 !== undefined ? e.perguntaExameCotovelo14 : '',
 e.respostaExameCotovelo14 !== undefined ? e.respostaExameCotovelo14 : '',
 e.comentarioExameCotovelo14 !== undefined ? e.comentarioExameCotovelo14 : '',
  
            ]) : y.push(
 ['','','','','','','','','','','' ,'','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame do Cotovelo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
               
            ],
             }, 
    },
 ]
 }
}

const dadosExamePunhoPdf = () => {
    let y = []
    if (tudo._42_examePunho && tudo._42_examePunho.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._42_examePunho !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._42_examePunho !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._42_examePunho : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExamePunho0 !== undefined ? e.perguntaExamePunho0 : '',
 e.respostaExamePunho0 !== undefined ? e.respostaExamePunho0 : '',
 e.comentarioExamePunho0 !== undefined ? e.comentarioExamePunho0 : '',
 e.perguntaExamePunho1 !== undefined ? e.perguntaExamePunho1 : '',
 e.respostaExamePunho1 !== undefined ? e.respostaExamePunho1 : '',
 e.comentarioExamePunho1 !== undefined ? e.comentarioExamePunho1 : '',
 e.perguntaExamePunho2 !== undefined ? e.perguntaExamePunho2 : '',
 e.respostaExamePunho2 !== undefined ? e.respostaExamePunho2 : '',
 e.comentarioExamePunho2 !== undefined ? e.comentarioExamePunho2 : '',
 e.perguntaExamePunho3 !== undefined ? e.perguntaExamePunho3 : '',
 e.respostaExamePunho3 !== undefined ? e.respostaExamePunho3 : '',
 e.comentarioExamePunho3 !== undefined ? e.comentarioExamePunho3 : '',
 e.perguntaExamePunho4 !== undefined ? e.perguntaExamePunho4 : '',
 e.respostaExamePunho4 !== undefined ? e.respostaExamePunho4 : '',
 e.comentarioExamePunho4 !== undefined ? e.comentarioExamePunho4 : '',
 e.perguntaExamePunho5 !== undefined ? e.perguntaExamePunho5 : '',
 e.respostaExamePunho5 !== undefined ? e.respostaExamePunho5 : '',
 e.comentarioExamePunho5 !== undefined ? e.comentarioExamePunho5 : '',
 e.perguntaExamePunho6 !== undefined ? e.perguntaExamePunho6 : '',
 e.respostaExamePunho6 !== undefined ? e.respostaExamePunho6 : '',
 e.comentarioExamePunho6 !== undefined ? e.comentarioExamePunho6 : '',
 e.perguntaExamePunho7 !== undefined ? e.perguntaExamePunho7 : '',
 e.respostaExamePunho7 !== undefined ? e.respostaExamePunho7 : '',
 e.comentarioExamePunho7 !== undefined ? e.comentarioExamePunho7 : '',
 e.perguntaExamePunho8 !== undefined ? e.perguntaExamePunho8 : '',
 e.respostaExamePunho8 !== undefined ? e.respostaExamePunho8 : '',
 e.comentarioExamePunho8 !== undefined ? e.comentarioExamePunho8 : '',
 e.perguntaExamePunho9 !== undefined ? e.perguntaExamePunho9 : '',
 e.respostaExamePunho9 !== undefined ? e.respostaExamePunho9 : '',
 e.comentarioExamePunho9 !== undefined ? e.comentarioExamePunho9 : '',
 e.perguntaExamePunho10 !== undefined ? e.perguntaExamePunho10 : '',
 e.respostaExamePunho10 !== undefined ? e.respostaExamePunho10 : '',
 e.comentarioExamePunho10 !== undefined ? e.comentarioExamePunho10 : '',
 e.perguntaExamePunho11 !== undefined ? e.perguntaExamePunho11 : '',
 e.respostaExamePunho11 !== undefined ? e.respostaExamePunho11 : '',
 e.comentarioExamePunho11 !== undefined ? e.comentarioExamePunho11 : '',
 e.perguntaExamePunho12 !== undefined ? e.perguntaExamePunho12 : '',
 e.respostaExamePunho12 !== undefined ? e.respostaExamePunho12 : '',
  e.comentarioExamePunho12 !== undefined ? e.comentarioExamePunho12 : '',
 e.perguntaExamePunho13 !== undefined ? e.perguntaExamePunho13 : '',
 e.respostaExamePunho13 !== undefined ? e.respostaExamePunho13 : '',
 e.comentarioExamePunho13 !== undefined ? e.comentarioExamePunho13 : '',
 e.perguntaExamePunho14 !== undefined ? e.perguntaExamePunho14 : '',
 e.respostaExamePunho14 !== undefined ? e.respostaExamePunho14 : '',
 e.comentarioExamePunho14 !== undefined ? e.comentarioExamePunho14 : '',
 e.perguntaExamePunho15 !== undefined ? e.perguntaExamePunho15 : '',
 e.respostaExamePunho15 !== undefined ? e.respostaExamePunho15 : '',
 e.comentarioExamePunho15 !== undefined ? e.comentarioExamePunho15 : '',
 e.perguntaExamePunho16 !== undefined ? e.perguntaExamePunho16 : '',
 e.respostaExamePunho16 !== undefined ? e.respostaExamePunho16 : '',
 e.comentarioExamePunho16 !== undefined ? e.comentarioExamePunho16 : '',
 e.perguntaExamePunho17 !== undefined ? e.perguntaExamePunho17 : '',
 e.respostaExamePunho17 !== undefined ? e.respostaExamePunho17 : '',
 e.comentarioExamePunho17 !== undefined ? e.comentarioExamePunho17 : '',
 e.perguntaExamePunho18 !== undefined ? e.perguntaExamePunho18 : '',
 e.respostaExamePunho18 !== undefined ? e.respostaExamePunho18 : '',
 e.comentarioExamePunho18 !== undefined ? e.comentarioExamePunho18 : '',
 e.perguntaExamePunho19 !== undefined ? e.perguntaExamePunho19 : '',
 e.respostaExamePunho19 !== undefined ? e.respostaExamePunho19 : '',
 e.comentarioExamePunho19 !== undefined ? e.comentarioExamePunho19 : '',
 e.perguntaExamePunho20 !== undefined ? e.perguntaExamePunho20 : '',
 e.respostaExamePunho20 !== undefined ? e.respostaExamePunho20 : '',
 e.comentarioExamePunho20 !== undefined ? e.comentarioExamePunho20 : '', 
 
            ]) : y.push(
 ['','','','','','','','','','','' ,'','','','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame do Punho',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
                y.length > 0 && (y[0][61] !== '' ) ? [[y[0][60]],[y[0][61]],[y[0][62]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosExameMaoPdf = () => {
    let y = []
    if (tudo._43_exameMao && tudo._43_exameMao.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._43_exameMao !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._43_exameMao !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._43_exameMao : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameMao0 !== undefined ? e.perguntaExameMao0 : '',
 e.respostaExameMao0 !== undefined ? e.respostaExameMao0 : '',
 e.comentarioExameMao0 !== undefined ? e.comentarioExameMao0 : '',
 e.perguntaExameMao1 !== undefined ? e.perguntaExameMao1 : '',
 e.respostaExameMao1 !== undefined ? e.respostaExameMao1 : '',
 e.comentarioExameMao1 !== undefined ? e.comentarioExameMao1 : '',
 e.perguntaExameMao2 !== undefined ? e.perguntaExameMao2 : '',
 e.respostaExameMao2 !== undefined ? e.respostaExameMao2 : '',
 e.comentarioExameMao2 !== undefined ? e.comentarioExameMao2 : '',
 e.perguntaExameMao3 !== undefined ? e.perguntaExameMao3 : '',
 e.respostaExameMao3 !== undefined ? e.respostaExameMao3 : '',
 e.comentarioExameMao3 !== undefined ? e.comentarioExameMao3 : '',
 e.perguntaExameMao4 !== undefined ? e.perguntaExameMao4 : '',
 e.respostaExameMao4 !== undefined ? e.respostaExameMao4 : '',
 e.comentarioExameMao4 !== undefined ? e.comentarioExameMao4 : '',
 e.perguntaExameMao5 !== undefined ? e.perguntaExameMao5 : '',
 e.respostaExameMao5 !== undefined ? e.respostaExameMao5 : '',
 e.comentarioExameMao5 !== undefined ? e.comentarioExameMao5 : '',
 
            ]) : y.push(
 ['','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame da Mão',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
               
            ],
             }, 
    },
 ]
 }
}

const dadosExameQuadrilPdf = () => {
    let y = []
    if (tudo._44_exameQuadril && tudo._44_exameQuadril.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._44_exameQuadril !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._44_exameQuadril !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._44_exameQuadril : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameQuadril0 !== undefined ? e.perguntaExameQuadril0 : '',
 e.respostaExameQuadril0 !== undefined ? e.respostaExameQuadril0 : '',
 e.comentarioExameQuadril0 !== undefined ? e.comentarioExameQuadril0 : '',
 e.perguntaExameQuadril1 !== undefined ? e.perguntaExameQuadril1 : '',
 e.respostaExameQuadril1 !== undefined ? e.respostaExameQuadril1 : '',
 e.comentarioExameQuadril1 !== undefined ? e.comentarioExameQuadril1 : '',
 e.perguntaExameQuadril2 !== undefined ? e.perguntaExameQuadril2 : '',
 e.respostaExameQuadril2 !== undefined ? e.respostaExameQuadril2 : '',
 e.comentarioExameQuadril2 !== undefined ? e.comentarioExameQuadril2 : '',
 e.perguntaExameQuadril3 !== undefined ? e.perguntaExameQuadril3 : '',
 e.respostaExameQuadril3 !== undefined ? e.respostaExameQuadril3 : '',
 e.comentarioExameQuadril3 !== undefined ? e.comentarioExameQuadril3 : '',
 e.perguntaExameQuadril4 !== undefined ? e.perguntaExameQuadril4 : '',
 e.respostaExameQuadril4 !== undefined ? e.respostaExameQuadril4 : '',
 e.comentarioExameQuadril4 !== undefined ? e.comentarioExameQuadril4 : '',
 e.perguntaExameQuadril5 !== undefined ? e.perguntaExameQuadril5 : '',
 e.respostaExameQuadril5 !== undefined ? e.respostaExameQuadril5 : '',
 e.comentarioExameQuadril5 !== undefined ? e.comentarioExameQuadril5 : '',
 e.perguntaExameQuadril6 !== undefined ? e.perguntaExameQuadril6 : '',
 e.respostaExameQuadril6 !== undefined ? e.respostaExameQuadril6 : '',
 e.comentarioExameQuadril6 !== undefined ? e.comentarioExameQuadril6 : '',
 e.perguntaExameQuadril7 !== undefined ? e.perguntaExameQuadril7 : '',
 e.respostaExameQuadril7 !== undefined ? e.respostaExameQuadril7 : '',
 e.comentarioExameQuadril7 !== undefined ? e.comentarioExameQuadril7 : '',
 e.perguntaExameQuadril8 !== undefined ? e.perguntaExameQuadril8 : '',
 e.respostaExameQuadril8 !== undefined ? e.respostaExameQuadril8 : '',
 e.comentarioExameQuadril8 !== undefined ? e.comentarioExameQuadril8 : '',
 e.perguntaExameQuadril9 !== undefined ? e.perguntaExameQuadril9 : '',
 e.respostaExameQuadril9 !== undefined ? e.respostaExameQuadril9 : '',
 e.comentarioExameQuadril9 !== undefined ? e.comentarioExameQuadril9 : '',
 e.perguntaExameQuadril10 !== undefined ? e.perguntaExameQuadril10 : '',
 e.respostaExameQuadril10 !== undefined ? e.respostaExameQuadril10 : '',
 e.comentarioExameQuadril10 !== undefined ? e.comentarioExameQuadril10 : '',
 e.perguntaExameQuadril11 !== undefined ? e.perguntaExameQuadril11 : '',
 e.respostaExameQuadril11 !== undefined ? e.respostaExameQuadril11 : '',
 e.comentarioExameQuadril11 !== undefined ? e.comentarioExameQuadril11 : '',
 e.perguntaExameQuadril12 !== undefined ? e.perguntaExameQuadril12 : '',
 e.respostaExameQuadril12 !== undefined ? e.respostaExameQuadril12 : '',
  e.comentarioExameQuadril12 !== undefined ? e.comentarioExameQuadril12 : '',
  
            ]) : y.push(
 ['','','','','','','','','','','' ,'',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame do Quadril',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
               
            ],
             }, 
    },
 ]
 }
}

const dadosExameJoelhoPdf = () => {
    let y = []
    if (tudo._45_exameJoelho && tudo._45_exameJoelho.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._45_exameJoelho !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._45_exameJoelho !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._45_exameJoelho : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameJoelho0 !== undefined ? e.perguntaExameJoelho0 : '',
 e.respostaExameJoelho0 !== undefined ? e.respostaExameJoelho0 : '',
 e.comentarioExameJoelho0 !== undefined ? e.comentarioExameJoelho0 : '',
 e.perguntaExameJoelho1 !== undefined ? e.perguntaExameJoelho1 : '',
 e.respostaExameJoelho1 !== undefined ? e.respostaExameJoelho1 : '',
 e.comentarioExameJoelho1 !== undefined ? e.comentarioExameJoelho1 : '',
 e.perguntaExameJoelho2 !== undefined ? e.perguntaExameJoelho2 : '',
 e.respostaExameJoelho2 !== undefined ? e.respostaExameJoelho2 : '',
 e.comentarioExameJoelho2 !== undefined ? e.comentarioExameJoelho2 : '',
 e.perguntaExameJoelho3 !== undefined ? e.perguntaExameJoelho3 : '',
 e.respostaExameJoelho3 !== undefined ? e.respostaExameJoelho3 : '',
 e.comentarioExameJoelho3 !== undefined ? e.comentarioExameJoelho3 : '',
 e.perguntaExameJoelho4 !== undefined ? e.perguntaExameJoelho4 : '',
 e.respostaExameJoelho4 !== undefined ? e.respostaExameJoelho4 : '',
 e.comentarioExameJoelho4 !== undefined ? e.comentarioExameJoelho4 : '',
 e.perguntaExameJoelho5 !== undefined ? e.perguntaExameJoelho5 : '',
 e.respostaExameJoelho5 !== undefined ? e.respostaExameJoelho5 : '',
 e.comentarioExameJoelho5 !== undefined ? e.comentarioExameJoelho5 : '',
 e.perguntaExameJoelho6 !== undefined ? e.perguntaExameJoelho6 : '',
 e.respostaExameJoelho6 !== undefined ? e.respostaExameJoelho6 : '',
 e.comentarioExameJoelho6 !== undefined ? e.comentarioExameJoelho6 : '',
 e.perguntaExameJoelho7 !== undefined ? e.perguntaExameJoelho7 : '',
 e.respostaExameJoelho7 !== undefined ? e.respostaExameJoelho7 : '',
 e.comentarioExameJoelho7 !== undefined ? e.comentarioExameJoelho7 : '',
 e.perguntaExameJoelho8 !== undefined ? e.perguntaExameJoelho8 : '',
 e.respostaExameJoelho8 !== undefined ? e.respostaExameJoelho8 : '',
 e.comentarioExameJoelho8 !== undefined ? e.comentarioExameJoelho8 : '',
 e.perguntaExameJoelho9 !== undefined ? e.perguntaExameJoelho9 : '',
 e.respostaExameJoelho9 !== undefined ? e.respostaExameJoelho9 : '',
 e.comentarioExameJoelho9 !== undefined ? e.comentarioExameJoelho9 : '',
 e.perguntaExameJoelho10 !== undefined ? e.perguntaExameJoelho10 : '',
 e.respostaExameJoelho10 !== undefined ? e.respostaExameJoelho10 : '',
 e.comentarioExameJoelho10 !== undefined ? e.comentarioExameJoelho10 : '',
 e.perguntaExameJoelho11 !== undefined ? e.perguntaExameJoelho11 : '',
 e.respostaExameJoelho11 !== undefined ? e.respostaExameJoelho11 : '',
 e.comentarioExameJoelho11 !== undefined ? e.comentarioExameJoelho11 : '',
 e.perguntaExameJoelho12 !== undefined ? e.perguntaExameJoelho12 : '',
 e.respostaExameJoelho12 !== undefined ? e.respostaExameJoelho12 : '',
  e.comentarioExameJoelho12 !== undefined ? e.comentarioExameJoelho12 : '',
 e.perguntaExameJoelho13 !== undefined ? e.perguntaExameJoelho13 : '',
 e.respostaExameJoelho13 !== undefined ? e.respostaExameJoelho13 : '',
 e.comentarioExameJoelho13 !== undefined ? e.comentarioExameJoelho13 : '',
 e.perguntaExameJoelho14 !== undefined ? e.perguntaExameJoelho14 : '',
 e.respostaExameJoelho14 !== undefined ? e.respostaExameJoelho14 : '',
 e.comentarioExameJoelho14 !== undefined ? e.comentarioExameJoelho14 : '',
 e.perguntaExameJoelho15 !== undefined ? e.perguntaExameJoelho15 : '',
 e.respostaExameJoelho15 !== undefined ? e.respostaExameJoelho15 : '',
 e.comentarioExameJoelho15 !== undefined ? e.comentarioExameJoelho15 : '',
 e.perguntaExameJoelho16 !== undefined ? e.perguntaExameJoelho16 : '',
 e.respostaExameJoelho16 !== undefined ? e.respostaExameJoelho16 : '',
 e.comentarioExameJoelho16 !== undefined ? e.comentarioExameJoelho16 : '',
 e.perguntaExameJoelho17 !== undefined ? e.perguntaExameJoelho17 : '',
 e.respostaExameJoelho17 !== undefined ? e.respostaExameJoelho17 : '',
 e.comentarioExameJoelho17 !== undefined ? e.comentarioExameJoelho17 : '',
 e.perguntaExameJoelho18 !== undefined ? e.perguntaExameJoelho18 : '',
 e.respostaExameJoelho18 !== undefined ? e.respostaExameJoelho18 : '',
 e.comentarioExameJoelho18 !== undefined ? e.comentarioExameJoelho18 : '',
  
            ]) : y.push(
 ['','','','','','','','','','','' ,'','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame dos Joelhos',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                
            ],
             }, 
    },
 ]
 }
}

const dadosExameTornozeloPdf = () => {
    let y = []
    if (tudo._46_exameTornozelo && tudo._46_exameTornozelo.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._46_exameTornozelo !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._46_exameTornozelo !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._46_exameTornozelo : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameTornozelo0 !== undefined ? e.perguntaExameTornozelo0 : '',
 e.respostaExameTornozelo0 !== undefined ? e.respostaExameTornozelo0 : '',
 e.comentarioExameTornozelo0 !== undefined ? e.comentarioExameTornozelo0 : '',
 e.perguntaExameTornozelo1 !== undefined ? e.perguntaExameTornozelo1 : '',
 e.respostaExameTornozelo1 !== undefined ? e.respostaExameTornozelo1 : '',
 e.comentarioExameTornozelo1 !== undefined ? e.comentarioExameTornozelo1 : '',
 e.perguntaExameTornozelo2 !== undefined ? e.perguntaExameTornozelo2 : '',
 e.respostaExameTornozelo2 !== undefined ? e.respostaExameTornozelo2 : '',
 e.comentarioExameTornozelo2 !== undefined ? e.comentarioExameTornozelo2 : '',
 e.perguntaExameTornozelo3 !== undefined ? e.perguntaExameTornozelo3 : '',
 e.respostaExameTornozelo3 !== undefined ? e.respostaExameTornozelo3 : '',
 e.comentarioExameTornozelo3 !== undefined ? e.comentarioExameTornozelo3 : '',
 e.perguntaExameTornozelo4 !== undefined ? e.perguntaExameTornozelo4 : '',
 e.respostaExameTornozelo4 !== undefined ? e.respostaExameTornozelo4 : '',
 e.comentarioExameTornozelo4 !== undefined ? e.comentarioExameTornozelo4 : '',
 e.perguntaExameTornozelo5 !== undefined ? e.perguntaExameTornozelo5 : '',
 e.respostaExameTornozelo5 !== undefined ? e.respostaExameTornozelo5 : '',
 e.comentarioExameTornozelo5 !== undefined ? e.comentarioExameTornozelo5 : '',
 e.perguntaExameTornozelo6 !== undefined ? e.perguntaExameTornozelo6 : '',
 e.respostaExameTornozelo6 !== undefined ? e.respostaExameTornozelo6 : '',
 e.comentarioExameTornozelo6 !== undefined ? e.comentarioExameTornozelo6 : '',
 e.perguntaExameTornozelo7 !== undefined ? e.perguntaExameTornozelo7 : '',
 e.respostaExameTornozelo7 !== undefined ? e.respostaExameTornozelo7 : '',
 e.comentarioExameTornozelo7 !== undefined ? e.comentarioExameTornozelo7 : '',
 e.perguntaExameTornozelo8 !== undefined ? e.perguntaExameTornozelo8 : '',
 e.respostaExameTornozelo8 !== undefined ? e.respostaExameTornozelo8 : '',
 e.comentarioExameTornozelo8 !== undefined ? e.comentarioExameTornozelo8 : '',
 e.perguntaExameTornozelo9 !== undefined ? e.perguntaExameTornozelo9 : '',
 e.respostaExameTornozelo9 !== undefined ? e.respostaExameTornozelo9 : '',
 e.comentarioExameTornozelo9 !== undefined ? e.comentarioExameTornozelo9 : '',
 e.perguntaExameTornozelo10 !== undefined ? e.perguntaExameTornozelo10 : '',
 e.respostaExameTornozelo10 !== undefined ? e.respostaExameTornozelo10 : '',
 e.comentarioExameTornozelo10 !== undefined ? e.comentarioExameTornozelo10 : '',
 e.perguntaExameTornozelo11 !== undefined ? e.perguntaExameTornozelo11 : '',
 e.respostaExameTornozelo11 !== undefined ? e.respostaExameTornozelo11 : '',
 e.comentarioExameTornozelo11 !== undefined ? e.comentarioExameTornozelo11 : '',
  
            ]) : y.push(
 ['','','','','','','','','','','' ,''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame do Tornozelo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
               
            ],
             }, 
    },
 ]
 }
}

const dadosExamePePdf = () => {
    let y = []
    if (tudo._47_examePe && tudo._47_examePe.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._47_examePe !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._47_examePe !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._47_examePe : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExamePe0 !== undefined ? e.perguntaExamePe0 : '',
 e.respostaExamePe0 !== undefined ? e.respostaExamePe0 : '',
 e.comentarioExamePe0 !== undefined ? e.comentarioExamePe0 : '',
 e.perguntaExamePe1 !== undefined ? e.perguntaExamePe1 : '',
 e.respostaExamePe1 !== undefined ? e.respostaExamePe1 : '',
 e.comentarioExamePe1 !== undefined ? e.comentarioExamePe1 : '',
 e.perguntaExamePe2 !== undefined ? e.perguntaExamePe2 : '',
 e.respostaExamePe2 !== undefined ? e.respostaExamePe2 : '',
 e.comentarioExamePe2 !== undefined ? e.comentarioExamePe2 : '',
 e.perguntaExamePe3 !== undefined ? e.perguntaExamePe3 : '',
 e.respostaExamePe3 !== undefined ? e.respostaExamePe3 : '',
 e.comentarioExamePe3 !== undefined ? e.comentarioExamePe3 : '',
 e.perguntaExamePe4 !== undefined ? e.perguntaExamePe4 : '',
 e.respostaExamePe4 !== undefined ? e.respostaExamePe4 : '',
 e.comentarioExamePe4 !== undefined ? e.comentarioExamePe4 : '',
 e.perguntaExamePe5 !== undefined ? e.perguntaExamePe5 : '',
 e.respostaExamePe5 !== undefined ? e.respostaExamePe5 : '',
 e.comentarioExamePe5 !== undefined ? e.comentarioExamePe5 : '', 
            ]) : y.push(
 ['','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame do Pé',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
               
            ],
             }, 
    },
 ]
 }
}

const dadosExameNeurologicoPdf = () => {
    let y = []
    if (tudo._48_exameNeurologico && tudo._48_exameNeurologico.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._48_exameNeurologico !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._48_exameNeurologico !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._48_exameNeurologico : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaExameNeurologico0 !== undefined ? e.perguntaExameNeurologico0 : '',
 e.respostaExameNeurologico0 !== undefined ? e.respostaExameNeurologico0 : '',
 e.comentarioExameNeurologico0 !== undefined ? e.comentarioExameNeurologico0 : '',
 e.perguntaExameNeurologico1 !== undefined ? e.perguntaExameNeurologico1 : '',
 e.respostaExameNeurologico1 !== undefined ? e.respostaExameNeurologico1 : '',
 e.comentarioExameNeurologico1 !== undefined ? e.comentarioExameNeurologico1 : '',
 e.perguntaExameNeurologico2 !== undefined ? e.perguntaExameNeurologico2 : '',
 e.respostaExameNeurologico2 !== undefined ? e.respostaExameNeurologico2 : '',
 e.comentarioExameNeurologico2 !== undefined ? e.comentarioExameNeurologico2 : '',
 e.perguntaExameNeurologico3 !== undefined ? e.perguntaExameNeurologico3 : '',
 e.respostaExameNeurologico3 !== undefined ? e.respostaExameNeurologico3 : '',
 e.comentarioExameNeurologico3 !== undefined ? e.comentarioExameNeurologico3 : '',
 e.perguntaExameNeurologico4 !== undefined ? e.perguntaExameNeurologico4 : '',
 e.respostaExameNeurologico4 !== undefined ? e.respostaExameNeurologico4 : '',
 e.comentarioExameNeurologico4 !== undefined ? e.comentarioExameNeurologico4 : '',
 e.perguntaExameNeurologico5 !== undefined ? e.perguntaExameNeurologico5 : '',
 e.respostaExameNeurologico5 !== undefined ? e.respostaExameNeurologico5 : '',
 e.comentarioExameNeurologico5 !== undefined ? e.comentarioExameNeurologico5 : '',
 e.perguntaExameNeurologico6 !== undefined ? e.perguntaExameNeurologico6 : '',
 e.respostaExameNeurologico6 !== undefined ? e.respostaExameNeurologico6 : '',
 e.comentarioExameNeurologico6 !== undefined ? e.comentarioExameNeurologico6 : '',
 e.perguntaExameNeurologico7 !== undefined ? e.perguntaExameNeurologico7 : '',
 e.respostaExameNeurologico7 !== undefined ? e.respostaExameNeurologico7 : '',
 e.comentarioExameNeurologico7 !== undefined ? e.comentarioExameNeurologico7 : '',
 e.perguntaExameNeurologico8 !== undefined ? e.perguntaExameNeurologico8 : '',
 e.respostaExameNeurologico8 !== undefined ? e.respostaExameNeurologico8 : '',
 e.comentarioExameNeurologico8 !== undefined ? e.comentarioExameNeurologico8 : '',
 e.perguntaExameNeurologico9 !== undefined ? e.perguntaExameNeurologico9 : '',
 e.respostaExameNeurologico9 !== undefined ? e.respostaExameNeurologico9 : '',
 e.comentarioExameNeurologico9 !== undefined ? e.comentarioExameNeurologico9 : '',
 e.perguntaExameNeurologico10 !== undefined ? e.perguntaExameNeurologico10 : '',
 e.respostaExameNeurologico10 !== undefined ? e.respostaExameNeurologico10 : '',
 e.comentarioExameNeurologico10 !== undefined ? e.comentarioExameNeurologico10 : '',
 e.perguntaExameNeurologico11 !== undefined ? e.perguntaExameNeurologico11 : '',
 e.respostaExameNeurologico11 !== undefined ? e.respostaExameNeurologico11 : '',
 e.comentarioExameNeurologico11 !== undefined ? e.comentarioExameNeurologico11 : '',
 e.perguntaExameNeurologico12 !== undefined ? e.perguntaExameNeurologico12 : '',
 e.respostaExameNeurologico12 !== undefined ? e.respostaExameNeurologico12 : '',
  e.comentarioExameNeurologico12 !== undefined ? e.comentarioExameNeurologico12 : '',
 e.perguntaExameNeurologico13 !== undefined ? e.perguntaExameNeurologico13 : '',
 e.respostaExameNeurologico13 !== undefined ? e.respostaExameNeurologico13 : '',
 e.comentarioExameNeurologico13 !== undefined ? e.comentarioExameNeurologico13 : '',
 e.perguntaExameNeurologico14 !== undefined ? e.perguntaExameNeurologico14 : '',
 e.respostaExameNeurologico14 !== undefined ? e.respostaExameNeurologico14 : '',
 e.comentarioExameNeurologico14 !== undefined ? e.comentarioExameNeurologico14 : '',
 e.perguntaExameNeurologico15 !== undefined ? e.perguntaExameNeurologico15 : '',
 e.respostaExameNeurologico15 !== undefined ? e.respostaExameNeurologico15 : '',
 e.comentarioExameNeurologico15 !== undefined ? e.comentarioExameNeurologico15 : '',
 e.perguntaExameNeurologico16 !== undefined ? e.perguntaExameNeurologico16 : '',
 e.respostaExameNeurologico16 !== undefined ? e.respostaExameNeurologico16 : '',
 e.comentarioExameNeurologico16 !== undefined ? e.comentarioExameNeurologico16 : '',
 e.perguntaExameNeurologico17 !== undefined ? e.perguntaExameNeurologico17 : '',
 e.respostaExameNeurologico17 !== undefined ? e.respostaExameNeurologico17 : '',
 e.comentarioExameNeurologico17 !== undefined ? e.comentarioExameNeurologico17 : '',
 e.perguntaExameNeurologico18 !== undefined ? e.perguntaExameNeurologico18 : '',
 e.respostaExameNeurologico18 !== undefined ? e.respostaExameNeurologico18 : '',
 e.comentarioExameNeurologico18 !== undefined ? e.comentarioExameNeurologico18 : '',
 e.perguntaExameNeurologico19 !== undefined ? e.perguntaExameNeurologico19 : '',
 e.respostaExameNeurologico19 !== undefined ? e.respostaExameNeurologico19 : '',
 e.comentarioExameNeurologico19 !== undefined ? e.comentarioExameNeurologico19 : '',
  
            ]) : y.push(
 ['','','','','','','','','','','' ,'','','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Neurológico',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['25%','25%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                 y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''], 
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
                y.length > 0 && (y[0][52] !== '' ) ? [[y[0][51]],[y[0][52]],[y[0][53]]] : ['','',''],
                y.length > 0 && (y[0][55] !== '' ) ? [[y[0][54]],[y[0][55]],[y[0][56]]] : ['','',''],
                y.length > 0 && (y[0][58] !== '' ) ? [[y[0][57]],[y[0][58]],[y[0][59]]] : ['','',''],
               
            ],
             }, 
    },
 ]
 }
}

const dadosExameLivrePdf = () => {
    let y = []
    if (tudo._49_exameLivre && tudo._49_exameLivre.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._49_exameLivre !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._49_exameLivre !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._49_exameLivre : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaExameLivre0 !== undefined ? e.perguntaExameLivre0 : '',
 //e.respostaExameLivre0 !== undefined ? e.respostaExameLivre0 : '',
 e.comentarioExameLivre0 !== undefined ? e.comentarioExameLivre0 : '',
            ]) : y.push(
 ['',/* '','' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Exame Livre',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['100%',/* '33%','33%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    /* {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'}, */
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]]/* ,[y[0][1]],[y[0][2]] */] : ['',/* '','' */],
            ],
             }, 
    },
 ]
 }
}

const headerAvaliacaoNexoPdf = () => {

    return [
        {text: '\n\n'}, 
        {
            text: `AVALIAÇÃO DO NEXO CAUSAL / CONCAUSAL`,
            style: 'header',
        },
        {text: '\n'},
    ]
}

const dadosNtepPdf = () => {
    let y = []
    if (tudo._50_ntep && tudo._50_ntep.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._50_ntep !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._50_ntep !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._50_ntep : []

        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
e.codigo !== undefined ? e.codigo : '',
e.descricao !== undefined ? e.descricao : '',
e.ntep !== undefined ? e.ntep : '',
e.cnae !== undefined ? e.cnae : '',
            ]) : y.push(
['','','',''])
    )

// console.log('***y',y[0],'***length',y[0].length)
             return [
                 {text: '\n'},
                 {
                     text: 'NTEP',
                     style: 'subheader',
                     alignment: 'center',
                 },
                 {
                     layout: 'lightHorizontalLines',
                     alignment: 'center',
                     table: {
                         heights: function () {
                             return 15
                         },
                         headerRows: 1,
                         widths: ['10%','40%','30%','20%'],
                         body: [
                             [
                                 //DADOS DO CABECALHO E ESTILO
                                 {text: 'Código', style: 'cabecalhoTabela'},
                                 {text: 'Descrição', style: 'cabecalhoTabela'},
                                 {text: 'NTEP', style: 'cabecalhoTabela'},
                                 {text: 'Cnae', style: 'cabecalhoTabela'},
                             ],
                             
                             //DADOS DA TABELA EM SI
                             ...y
                            ],
                        }, 
                },
                ]
    }
}

const dadosNexoProfissionalPdf = () => {
    let y = []
    if (tudo._51_nexoProfissional && tudo._51_nexoProfissional.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._51_nexoProfissional !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._51_nexoProfissional !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._51_nexoProfissional : []

        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
e.codigo !== undefined ? e.codigo : '',
e.descricao !== undefined ? e.descricao : '',
e.nexoProfissional !== undefined ? e.nexoProfissional : '',
e.patologias !== undefined ? e.patologias : '',
e.agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional !== undefined ? e.agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional : '',
            ]) : y.push(
['','','','',''])
    )

// console.log('***y',y[0],'***length',y[0].length)
             return [
                 {text: '\n'},
                 {
                     text: 'Nexo Profissional',
                     style: 'subheader',
                     alignment: 'center',
                 },
                 {
                     layout: 'lightHorizontalLines',
                     alignment: 'center',
                     table: {
                         heights: function () {
                             return 15
                         },
                         headerRows: 1,
                         widths: ['10%','15%','15%','25%','35%'],
                         body: [
                             [
                                 //DADOS DO CABECALHO E ESTILO
                                 {text: 'Código', style: 'cabecalhoTabela'},
                                 {text: 'Descrição', style: 'cabecalhoTabela'},
                                 {text: 'Nexo Profissional', style: 'cabecalhoTabela'},
                                 {text: 'Patologias', style: 'cabecalhoTabela'},
                                 {text: 'Agente etiológico', style: 'cabecalhoTabela'},
                             ],
                             
                             //DADOS DA TABELA EM SI
                             ...y
                            ],
                        }, 
                },
                ]
    }
}

const dadosPenteadoPdf = () => {
    let y = []
    if (tudo._54_criteriosPenteado && tudo._54_criteriosPenteado.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._54_criteriosPenteado !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._54_criteriosPenteado !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._54_criteriosPenteado : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaCriteriosPenteado0 !== undefined ? e.perguntaCriteriosPenteado0 : '',
 e.respostaCriteriosPenteado0 !== undefined ? e.respostaCriteriosPenteado0 : '',
 e.comentarioCriteriosPenteado0 !== undefined ? e.comentarioCriteriosPenteado0 : '',
 e.perguntaCriteriosPenteado1 !== undefined ? e.perguntaCriteriosPenteado1 : '',
 e.respostaCriteriosPenteado1 !== undefined ? e.respostaCriteriosPenteado1 : '',
 e.comentarioCriteriosPenteado1 !== undefined ? e.comentarioCriteriosPenteado1 : '',
 e.perguntaCriteriosPenteado2 !== undefined ? e.perguntaCriteriosPenteado2 : '',
 e.respostaCriteriosPenteado2 !== undefined ? e.respostaCriteriosPenteado2 : '',
 e.comentarioCriteriosPenteado2 !== undefined ? e.comentarioCriteriosPenteado2 : '',
 e.perguntaCriteriosPenteado3 !== undefined ? e.perguntaCriteriosPenteado3 : '',
 e.respostaCriteriosPenteado3 !== undefined ? e.respostaCriteriosPenteado3 : '',
 e.comentarioCriteriosPenteado3 !== undefined ? e.comentarioCriteriosPenteado3 : '',
 e.perguntaCriteriosPenteado4 !== undefined ? e.perguntaCriteriosPenteado4 : '',
 e.respostaCriteriosPenteado4 !== undefined ? e.respostaCriteriosPenteado4 : '',
 e.comentarioCriteriosPenteado4 !== undefined ? e.comentarioCriteriosPenteado4 : '',
 e.perguntaCriteriosPenteado5 !== undefined ? e.perguntaCriteriosPenteado5 : '',
 e.respostaCriteriosPenteado5 !== undefined ? e.respostaCriteriosPenteado5 : '',
 e.comentarioCriteriosPenteado5 !== undefined ? e.comentarioCriteriosPenteado5 : '',
 e.perguntaCriteriosPenteado6 !== undefined ? e.perguntaCriteriosPenteado6 : '',
 e.respostaCriteriosPenteado6 !== undefined ? e.respostaCriteriosPenteado6 : '',
 e.comentarioCriteriosPenteado6 !== undefined ? e.comentarioCriteriosPenteado6 : '',
 e.perguntaCriteriosPenteado7 !== undefined ? e.perguntaCriteriosPenteado7 : '',
 e.respostaCriteriosPenteado7 !== undefined ? e.respostaCriteriosPenteado7 : '',
 e.comentarioCriteriosPenteado7 !== undefined ? e.comentarioCriteriosPenteado7 : '',
 e.perguntaCriteriosPenteado8 !== undefined ? e.perguntaCriteriosPenteado8 : '',
 e.respostaCriteriosPenteado8 !== undefined ? e.respostaCriteriosPenteado8 : '',
 e.comentarioCriteriosPenteado8 !== undefined ? e.comentarioCriteriosPenteado8 : '',
 e.perguntaCriteriosPenteado9 !== undefined ? e.perguntaCriteriosPenteado9 : '',
 e.respostaCriteriosPenteado9 !== undefined ? e.respostaCriteriosPenteado9 : '',
 e.comentarioCriteriosPenteado9 !== undefined ? e.comentarioCriteriosPenteado9 : '',
 e.perguntaCriteriosPenteado10 !== undefined ? e.perguntaCriteriosPenteado10 : '',
 e.respostaCriteriosPenteado10 !== undefined ? e.respostaCriteriosPenteado10 : '',
 e.comentarioCriteriosPenteado10 !== undefined ? e.comentarioCriteriosPenteado10 : '',
 e.perguntaCriteriosPenteado11 !== undefined ? e.perguntaCriteriosPenteado11 : '',
 e.respostaCriteriosPenteado11 !== undefined ? e.respostaCriteriosPenteado11 : '',
 e.comentarioCriteriosPenteado11 !== undefined ? e.comentarioCriteriosPenteado11 : '',
 e.perguntaCriteriosPenteado12 !== undefined ? e.perguntaCriteriosPenteado12 : '',
 e.respostaCriteriosPenteado12 !== undefined ? e.respostaCriteriosPenteado12 : '',
 e.comentarioCriteriosPenteado12 !== undefined ? e.comentarioCriteriosPenteado12 : '',
 e.perguntaCriteriosPenteado13 !== undefined ? e.perguntaCriteriosPenteado13 : '',
 e.respostaCriteriosPenteado13 !== undefined ? e.respostaCriteriosPenteado13 : '',
 e.comentarioCriteriosPenteado13 !== undefined ? e.comentarioCriteriosPenteado13 : '',
 e.perguntaCriteriosPenteado14 !== undefined ? e.perguntaCriteriosPenteado14 : '',
 e.respostaCriteriosPenteado14 !== undefined ? e.respostaCriteriosPenteado14 : '',
 e.comentarioCriteriosPenteado14 !== undefined ? e.comentarioCriteriosPenteado14 : '',
 e.perguntaCriteriosPenteado15 !== undefined ? e.perguntaCriteriosPenteado15 : '',
 e.respostaCriteriosPenteado15 !== undefined ? e.respostaCriteriosPenteado15 : '',
 e.comentarioCriteriosPenteado15 !== undefined ? e.comentarioCriteriosPenteado15 : '',
 e.perguntaCriteriosPenteado16 !== undefined ? e.perguntaCriteriosPenteado16 : '',
 e.respostaCriteriosPenteado16 !== undefined ? e.respostaCriteriosPenteado16 : '',
 e.comentarioCriteriosPenteado16 !== undefined ? e.comentarioCriteriosPenteado16 : '',
            ]) : y.push(
 ['','','','','','','','','','','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Criterios de Penteado',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','15%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
                y.length > 0 && (y[0][34] !== '' ) ? [[y[0][33]],[y[0][34]],[y[0][35]]] : ['','',''],
                y.length > 0 && (y[0][37] !== '' ) ? [[y[0][36]],[y[0][37]],[y[0][38]]] : ['','',''],
                y.length > 0 && (y[0][40] !== '' ) ? [[y[0][39]],[y[0][40]],[y[0][41]]] : ['','',''],
                y.length > 0 && (y[0][43] !== '' ) ? [[y[0][42]],[y[0][43]],[y[0][44]]] : ['','',''],
                y.length > 0 && (y[0][46] !== '' ) ? [[y[0][45]],[y[0][46]],[y[0][47]]] : ['','',''],
                y.length > 0 && (y[0][49] !== '' ) ? [[y[0][48]],[y[0][49]],[y[0][50]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosConcausaPdf = () => {
    let y = []
    if (tudo._62_criteriosConcausa && tudo._62_criteriosConcausa.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._62_criteriosConcausa !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._62_criteriosConcausa !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._62_criteriosConcausa : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaCriteriosConcausa0 !== undefined ? e.perguntaCriteriosConcausa0 : '',
 e.respostaCriteriosConcausa0 !== undefined ? e.respostaCriteriosConcausa0 : '',
 e.comentarioCriteriosConcausa0 !== undefined ? e.comentarioCriteriosConcausa0 : '',
 e.perguntaCriteriosConcausa1 !== undefined ? e.perguntaCriteriosConcausa1 : '',
 e.respostaCriteriosConcausa1 !== undefined ? e.respostaCriteriosConcausa1 : '',
 e.comentarioCriteriosConcausa1 !== undefined ? e.comentarioCriteriosConcausa1 : '',
 e.perguntaCriteriosConcausa2 !== undefined ? e.perguntaCriteriosConcausa2 : '',
 e.respostaCriteriosConcausa2 !== undefined ? e.respostaCriteriosConcausa2 : '',
 e.comentarioCriteriosConcausa2 !== undefined ? e.comentarioCriteriosConcausa2 : '',
 e.perguntaCriteriosConcausa3 !== undefined ? e.perguntaCriteriosConcausa3 : '',
 e.respostaCriteriosConcausa3 !== undefined ? e.respostaCriteriosConcausa3 : '',
 e.comentarioCriteriosConcausa3 !== undefined ? e.comentarioCriteriosConcausa3 : '',
 e.perguntaCriteriosConcausa4 !== undefined ? e.perguntaCriteriosConcausa4 : '',
 e.respostaCriteriosConcausa4 !== undefined ? e.respostaCriteriosConcausa4 : '',
 e.comentarioCriteriosConcausa4 !== undefined ? e.comentarioCriteriosConcausa4 : '',
 e.perguntaCriteriosConcausa5 !== undefined ? e.perguntaCriteriosConcausa5 : '',
 e.respostaCriteriosConcausa5 !== undefined ? e.respostaCriteriosConcausa5 : '',
 e.comentarioCriteriosConcausa5 !== undefined ? e.comentarioCriteriosConcausa5 : '',
 e.perguntaCriteriosConcausa6 !== undefined ? e.perguntaCriteriosConcausa6 : '',
 e.respostaCriteriosConcausa6 !== undefined ? e.respostaCriteriosConcausa6 : '',
 e.comentarioCriteriosConcausa6 !== undefined ? e.comentarioCriteriosConcausa6 : '',
 e.perguntaCriteriosConcausa7 !== undefined ? e.perguntaCriteriosConcausa7 : '',
 e.respostaCriteriosConcausa7 !== undefined ? e.respostaCriteriosConcausa7 : '',
 e.comentarioCriteriosConcausa7 !== undefined ? e.comentarioCriteriosConcausa7 : '',
            ]) : y.push(
 ['','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Criterios de Concausa',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','15%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosSimoninPdf = () => {
    let y = []
    if (tudo._52_criteriosSimonin && tudo._52_criteriosSimonin.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._52_criteriosSimonin !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._52_criteriosSimonin !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._52_criteriosSimonin : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaCriteriosSimonin0 !== undefined ? e.perguntaCriteriosSimonin0 : '',
 e.respostaCriteriosSimonin0 !== undefined ? e.respostaCriteriosSimonin0 : '',
 e.comentarioCriteriosSimonin0 !== undefined ? e.comentarioCriteriosSimonin0 : '',
 e.perguntaCriteriosSimonin1 !== undefined ? e.perguntaCriteriosSimonin1 : '',
 e.respostaCriteriosSimonin1 !== undefined ? e.respostaCriteriosSimonin1 : '',
 e.comentarioCriteriosSimonin1 !== undefined ? e.comentarioCriteriosSimonin1 : '',
 e.perguntaCriteriosSimonin2 !== undefined ? e.perguntaCriteriosSimonin2 : '',
 e.respostaCriteriosSimonin2 !== undefined ? e.respostaCriteriosSimonin2 : '',
 e.comentarioCriteriosSimonin2 !== undefined ? e.comentarioCriteriosSimonin2 : '',
 e.perguntaCriteriosSimonin3 !== undefined ? e.perguntaCriteriosSimonin3 : '',
 e.respostaCriteriosSimonin3 !== undefined ? e.respostaCriteriosSimonin3 : '',
 e.comentarioCriteriosSimonin3 !== undefined ? e.comentarioCriteriosSimonin3 : '',
 e.perguntaCriteriosSimonin4 !== undefined ? e.perguntaCriteriosSimonin4 : '',
 e.respostaCriteriosSimonin4 !== undefined ? e.respostaCriteriosSimonin4 : '',
 e.comentarioCriteriosSimonin4 !== undefined ? e.comentarioCriteriosSimonin4 : '',
 e.perguntaCriteriosSimonin5 !== undefined ? e.perguntaCriteriosSimonin5 : '',
 e.respostaCriteriosSimonin5 !== undefined ? e.respostaCriteriosSimonin5 : '',
 e.comentarioCriteriosSimonin5 !== undefined ? e.comentarioCriteriosSimonin5 : '',
 e.perguntaCriteriosSimonin6 !== undefined ? e.perguntaCriteriosSimonin6 : '',
 e.respostaCriteriosSimonin6 !== undefined ? e.respostaCriteriosSimonin6 : '',
 e.comentarioCriteriosSimonin6 !== undefined ? e.comentarioCriteriosSimonin6 : '',
 e.perguntaCriteriosSimonin7 !== undefined ? e.perguntaCriteriosSimonin7 : '',
 e.respostaCriteriosSimonin7 !== undefined ? e.respostaCriteriosSimonin7 : '',
 e.comentarioCriteriosSimonin7 !== undefined ? e.comentarioCriteriosSimonin7 : '',
            ]) : y.push(
 ['','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Criterios de Simonin',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','15%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosFranchiniPdf = () => {
    let y = []
    if (tudo._56_criteriosFranchini && tudo._56_criteriosFranchini.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._56_criteriosFranchini !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._56_criteriosFranchini !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._56_criteriosFranchini : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaCriteriosFranchini0 !== undefined ? e.perguntaCriteriosFranchini0 : '',
 e.respostaCriteriosFranchini0 !== undefined ? e.respostaCriteriosFranchini0 : '',
 e.comentarioCriteriosFranchini0 !== undefined ? e.comentarioCriteriosFranchini0 : '',
 e.perguntaCriteriosFranchini1 !== undefined ? e.perguntaCriteriosFranchini1 : '',
 e.respostaCriteriosFranchini1 !== undefined ? e.respostaCriteriosFranchini1 : '',
 e.comentarioCriteriosFranchini1 !== undefined ? e.comentarioCriteriosFranchini1 : '',
 e.perguntaCriteriosFranchini2 !== undefined ? e.perguntaCriteriosFranchini2 : '',
 e.respostaCriteriosFranchini2 !== undefined ? e.respostaCriteriosFranchini2 : '',
 e.comentarioCriteriosFranchini2 !== undefined ? e.comentarioCriteriosFranchini2 : '',
 e.perguntaCriteriosFranchini3 !== undefined ? e.perguntaCriteriosFranchini3 : '',
 e.respostaCriteriosFranchini3 !== undefined ? e.respostaCriteriosFranchini3 : '',
 e.comentarioCriteriosFranchini3 !== undefined ? e.comentarioCriteriosFranchini3 : '',
 e.perguntaCriteriosFranchini4 !== undefined ? e.perguntaCriteriosFranchini4 : '',
 e.respostaCriteriosFranchini4 !== undefined ? e.respostaCriteriosFranchini4 : '',
 e.comentarioCriteriosFranchini4 !== undefined ? e.comentarioCriteriosFranchini4 : '',
 e.perguntaCriteriosFranchini5 !== undefined ? e.perguntaCriteriosFranchini5 : '',
 e.respostaCriteriosFranchini5 !== undefined ? e.respostaCriteriosFranchini5 : '',
 e.comentarioCriteriosFranchini5 !== undefined ? e.comentarioCriteriosFranchini5 : '',
            ]) : y.push(
 ['','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Criterios de Franchini',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','15%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosBradfordHillPdf = () => {
    let y = []
    if (tudo._58_criteriosBradfordHill && tudo._58_criteriosBradfordHill.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._58_criteriosBradfordHill !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._58_criteriosBradfordHill !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._58_criteriosBradfordHill : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaCriteriosBradfordHill0 !== undefined ? e.perguntaCriteriosBradfordHill0 : '',
 e.respostaCriteriosBradfordHill0 !== undefined ? e.respostaCriteriosBradfordHill0 : '',
 e.comentarioCriteriosBradfordHill0 !== undefined ? e.comentarioCriteriosBradfordHill0 : '',
 e.perguntaCriteriosBradfordHill1 !== undefined ? e.perguntaCriteriosBradfordHill1 : '',
 e.respostaCriteriosBradfordHill1 !== undefined ? e.respostaCriteriosBradfordHill1 : '',
 e.comentarioCriteriosBradfordHill1 !== undefined ? e.comentarioCriteriosBradfordHill1 : '',
 e.perguntaCriteriosBradfordHill2 !== undefined ? e.perguntaCriteriosBradfordHill2 : '',
 e.respostaCriteriosBradfordHill2 !== undefined ? e.respostaCriteriosBradfordHill2 : '',
 e.comentarioCriteriosBradfordHill2 !== undefined ? e.comentarioCriteriosBradfordHill2 : '',
 e.perguntaCriteriosBradfordHill3 !== undefined ? e.perguntaCriteriosBradfordHill3 : '',
 e.respostaCriteriosBradfordHill3 !== undefined ? e.respostaCriteriosBradfordHill3 : '',
 e.comentarioCriteriosBradfordHill3 !== undefined ? e.comentarioCriteriosBradfordHill3 : '',
 e.perguntaCriteriosBradfordHill4 !== undefined ? e.perguntaCriteriosBradfordHill4 : '',
 e.respostaCriteriosBradfordHill4 !== undefined ? e.respostaCriteriosBradfordHill4 : '',
 e.comentarioCriteriosBradfordHill4 !== undefined ? e.comentarioCriteriosBradfordHill4 : '',
 e.perguntaCriteriosBradfordHill5 !== undefined ? e.perguntaCriteriosBradfordHill5 : '',
 e.respostaCriteriosBradfordHill5 !== undefined ? e.respostaCriteriosBradfordHill5 : '',
 e.comentarioCriteriosBradfordHill5 !== undefined ? e.comentarioCriteriosBradfordHill5 : '',
 e.perguntaCriteriosBradfordHill6 !== undefined ? e.perguntaCriteriosBradfordHill6 : '',
 e.respostaCriteriosBradfordHill6 !== undefined ? e.respostaCriteriosBradfordHill6 : '',
 e.comentarioCriteriosBradfordHill6 !== undefined ? e.comentarioCriteriosBradfordHill6 : '',
 e.perguntaCriteriosBradfordHill7 !== undefined ? e.perguntaCriteriosBradfordHill7 : '',
 e.respostaCriteriosBradfordHill7 !== undefined ? e.respostaCriteriosBradfordHill7 : '',
 e.comentarioCriteriosBradfordHill7 !== undefined ? e.comentarioCriteriosBradfordHill7 : '',
 e.perguntaCriteriosBradfordHill8 !== undefined ? e.perguntaCriteriosBradfordHill8 : '',
 e.respostaCriteriosBradfordHill8 !== undefined ? e.respostaCriteriosBradfordHill8 : '',
 e.comentarioCriteriosBradfordHill8 !== undefined ? e.comentarioCriteriosBradfordHill8 : '',
            ]) : y.push(
 ['','','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Criterios de BradfordHill',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','15%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosCfmPdf = () => {
    let y = []
    if (tudo._60_criteriosCfm && tudo._60_criteriosCfm.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._60_criteriosCfm !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._60_criteriosCfm !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._60_criteriosCfm : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaCriteriosCfm0 !== undefined ? e.perguntaCriteriosCfm0 : '',
 e.respostaCriteriosCfm0 !== undefined ? e.respostaCriteriosCfm0 : '',
 e.comentarioCriteriosCfm0 !== undefined ? e.comentarioCriteriosCfm0 : '',
 e.perguntaCriteriosCfm1 !== undefined ? e.perguntaCriteriosCfm1 : '',
 e.respostaCriteriosCfm1 !== undefined ? e.respostaCriteriosCfm1 : '',
 e.comentarioCriteriosCfm1 !== undefined ? e.comentarioCriteriosCfm1 : '',
 e.perguntaCriteriosCfm2 !== undefined ? e.perguntaCriteriosCfm2 : '',
 e.respostaCriteriosCfm2 !== undefined ? e.respostaCriteriosCfm2 : '',
 e.comentarioCriteriosCfm2 !== undefined ? e.comentarioCriteriosCfm2 : '',
 e.perguntaCriteriosCfm3 !== undefined ? e.perguntaCriteriosCfm3 : '',
 e.respostaCriteriosCfm3 !== undefined ? e.respostaCriteriosCfm3 : '',
 e.comentarioCriteriosCfm3 !== undefined ? e.comentarioCriteriosCfm3 : '',
 e.perguntaCriteriosCfm4 !== undefined ? e.perguntaCriteriosCfm4 : '',
 e.respostaCriteriosCfm4 !== undefined ? e.respostaCriteriosCfm4 : '',
 e.comentarioCriteriosCfm4 !== undefined ? e.comentarioCriteriosCfm4 : '',
 e.perguntaCriteriosCfm5 !== undefined ? e.perguntaCriteriosCfm5 : '',
 e.respostaCriteriosCfm5 !== undefined ? e.respostaCriteriosCfm5 : '',
 e.comentarioCriteriosCfm5 !== undefined ? e.comentarioCriteriosCfm5 : '',
 e.perguntaCriteriosCfm6 !== undefined ? e.perguntaCriteriosCfm6 : '',
 e.respostaCriteriosCfm6 !== undefined ? e.respostaCriteriosCfm6 : '',
 e.comentarioCriteriosCfm6 !== undefined ? e.comentarioCriteriosCfm6 : '',
 e.perguntaCriteriosCfm7 !== undefined ? e.perguntaCriteriosCfm7 : '',
 e.respostaCriteriosCfm7 !== undefined ? e.respostaCriteriosCfm7 : '',
 e.comentarioCriteriosCfm7 !== undefined ? e.comentarioCriteriosCfm7 : '',
 e.perguntaCriteriosCfm8 !== undefined ? e.perguntaCriteriosCfm8 : '',
 e.respostaCriteriosCfm8 !== undefined ? e.respostaCriteriosCfm8 : '',
 e.comentarioCriteriosCfm8 !== undefined ? e.comentarioCriteriosCfm8 : '',
 e.perguntaCriteriosCfm9 !== undefined ? e.perguntaCriteriosCfm9 : '',
 e.respostaCriteriosCfm9 !== undefined ? e.respostaCriteriosCfm9 : '',
 e.comentarioCriteriosCfm9 !== undefined ? e.comentarioCriteriosCfm9 : '',
 e.perguntaCriteriosCfm10 !== undefined ? e.perguntaCriteriosCfm10 : '',
 e.respostaCriteriosCfm10 !== undefined ? e.respostaCriteriosCfm10 : '',
 e.comentarioCriteriosCfm10 !== undefined ? e.comentarioCriteriosCfm10 : '',
            ]) : y.push(
 ['','','','','','','','','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Criterios de Cfm',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['60%','15%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
                y.length > 0 && (y[0][4] !== '' ) ? [[y[0][3]],[y[0][4]],[y[0][5]]] : ['','',''],
                y.length > 0 && (y[0][7] !== '' ) ? [[y[0][6]],[y[0][7]],[y[0][8]]] : ['','',''],
                y.length > 0 && (y[0][10] !== '' ) ? [[y[0][9]],[y[0][10]],[y[0][11]]] : ['','',''],
                y.length > 0 && (y[0][13] !== '' ) ? [[y[0][12]],[y[0][13]],[y[0][14]]] : ['','',''],
                y.length > 0 && (y[0][16] !== '' ) ? [[y[0][15]],[y[0][16]],[y[0][17]]] : ['','',''],
                y.length > 0 && (y[0][19] !== '' ) ? [[y[0][18]],[y[0][19]],[y[0][20]]] : ['','',''],
                y.length > 0 && (y[0][22] !== '' ) ? [[y[0][21]],[y[0][22]],[y[0][23]]] : ['','',''],
                y.length > 0 && (y[0][25] !== '' ) ? [[y[0][24]],[y[0][25]],[y[0][26]]] : ['','',''],
                y.length > 0 && (y[0][28] !== '' ) ? [[y[0][27]],[y[0][28]],[y[0][29]]] : ['','',''],
                y.length > 0 && (y[0][31] !== '' ) ? [[y[0][30]],[y[0][31]],[y[0][32]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const headerValoracaoDanoPdf = () => {

    return [
    {text: '\n\n'}, 
    {
        text: `VALORAÇÃO DO DANO CORPORAL`,
        style: 'header'
    },
                {text: '\n\n'},
    ]
}

const dadosValoracaoDesnecessariaPdf = () => {
    let y = []
    if (tudo._64_valoracaoDesnecessaria && tudo._64_valoracaoDesnecessaria.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._64_valoracaoDesnecessaria !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._64_valoracaoDesnecessaria !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._64_valoracaoDesnecessaria : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaValoracaoDesnecessaria0 !== undefined ? e.perguntaValoracaoDesnecessaria0 : '',
 e.respostaValoracaoDesnecessaria0 !== undefined ? e.respostaValoracaoDesnecessaria0 : '',
 //e.comentarioValoracaoDesnecessaria0 !== undefined ? e.comentarioValoracaoDesnecessaria0 : '',
            ]) : y.push(
 ['',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Valoração Desnecessária',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    //{text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]]] : ['',''],
            ],
             }, 
    },
 ]
 }
}

const dadosDeficitFuncionalTemporarioPdf = () => {
    let y = []
    if (tudo._65_deficitFuncionalTemporario && tudo._65_deficitFuncionalTemporario.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._65_deficitFuncionalTemporario !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._65_deficitFuncionalTemporario !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._65_deficitFuncionalTemporario : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaDeficitFuncionalTemporario0 !== undefined ? e.perguntaDeficitFuncionalTemporario0 : '',
 e.respostaDeficitFuncionalTemporario0 !== undefined ? e.respostaDeficitFuncionalTemporario0 : '',
 //e.comentarioDeficitFuncionalTemporario0 !== undefined ? e.comentarioDeficitFuncionalTemporario0 : '',
            ]) : y.push(
 ['',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Déficit funcional temporário',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    //{text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]]] : ['',''],
            ],
             }, 
    },
 ]
 }
}

const dadosIncapacidadeLaboralTemporariaPdf = () => {
    let y = []
    if (tudo._66_incapacidadeLaboralTemporaria && tudo._66_incapacidadeLaboralTemporaria.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._66_incapacidadeLaboralTemporaria !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._66_incapacidadeLaboralTemporaria !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._66_incapacidadeLaboralTemporaria : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaIncapacidadeLaboralTemporaria0 !== undefined ? e.perguntaIncapacidadeLaboralTemporaria0 : '',
 e.respostaIncapacidadeLaboralTemporaria0 !== undefined ? e.respostaIncapacidadeLaboralTemporaria0 : '',
 //e.comentarioIncapacidadeLaboralTemporaria0 !== undefined ? e.comentarioIncapacidadeLaboralTemporaria0 : '',
            ]) : y.push(
 ['',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Incapacidade laboral temporária',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    //{text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]]] : ['',''],
            ],
             }, 
    },
 ]
 }
}

const dadosQuantumDolorisPdf = () => {
    let y = []
    if (tudo._67_quantumDoloris && tudo._67_quantumDoloris.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._67_quantumDoloris !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._67_quantumDoloris !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._67_quantumDoloris : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaQuantumDoloris0 !== undefined ? e.perguntaQuantumDoloris0 : '',
 e.respostaQuantumDoloris0 !== undefined ? e.respostaQuantumDoloris0 : '',
 e.comentarioQuantumDoloris0 !== undefined ? e.comentarioQuantumDoloris0 : '',
            ]) : y.push(
 ['','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Quantum Doloris',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['34%','33%','33%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosDeficitFuncionalDefinitivoPdf = () => {
    let y = []
    if (tudo._68_deficitFuncionalDefinitivo && tudo._68_deficitFuncionalDefinitivo.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._68_deficitFuncionalDefinitivo !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._68_deficitFuncionalDefinitivo !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._68_deficitFuncionalDefinitivo : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.perguntaDeficitFuncionalDefinitivo0 !== undefined ? e.perguntaDeficitFuncionalDefinitivo0 : '',
 e.respostaDeficitFuncionalDefinitivo0 !== undefined ? e.respostaDeficitFuncionalDefinitivo0 : '',
 e.comentarioDeficitFuncionalDefinitivo0 !== undefined ? e.comentarioDeficitFuncionalDefinitivo0 : '',
            ]) : y.push(
 ['','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Déficit Funcional Definitivo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','25%','25%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]]] : ['','',''],
            ],
             }, 
    },
 ]
 }
}

const dadosClassificacaoIncapacidadePenteadoPdf = () => {
    let y = []
    if (tudo._69_classificacaoIncapacidadePenteado && tudo._69_classificacaoIncapacidadePenteado.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._69_classificacaoIncapacidadePenteado !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._69_classificacaoIncapacidadePenteado !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._69_classificacaoIncapacidadePenteado : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaClassificacaoIncapacidadePenteado0 !== undefined ? e.perguntaClassificacaoIncapacidadePenteado0 : '',
 e.respostaClassificacaoIncapacidadePenteado0 !== undefined ? e.respostaClassificacaoIncapacidadePenteado0 : '',
 e.comentarioClassificacaoIncapacidadePenteado0 !== undefined ? e.comentarioClassificacaoIncapacidadePenteado0 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Classificação de Incapacidade de Penteado',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}

const dadosBaremoArgentinoQualitativoPdf = () => {
    let y = []
    if (tudo._70_baremoArgetinoQualitativo && tudo._70_baremoArgetinoQualitativo.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._70_baremoArgetinoQualitativo !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._70_baremoArgetinoQualitativo !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._70_baremoArgetinoQualitativo : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaBaremoArgentinoQualitativo0 !== undefined ? e.perguntaBaremoArgentinoQualitativo0 : '',
 e.respostaBaremoArgentinoQualitativo0 !== undefined ? e.respostaBaremoArgentinoQualitativo0 : '',
 e.comentarioBaremoArgentinoQualitativo0 !== undefined ? e.comentarioBaremoArgentinoQualitativo0 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Baremo Argentino Qualitativo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}

const dadosClassificacaoWelitonBarbosaSantosPdf = () => {
    let y = []
    if (tudo._71_classificacaoWelitonBarbosaSantos && tudo._71_classificacaoWelitonBarbosaSantos.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._71_classificacaoWelitonBarbosaSantos !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._71_classificacaoWelitonBarbosaSantos !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._71_classificacaoWelitonBarbosaSantos : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaClassificacaoWelitonBarbosaSantos0 !== undefined ? e.perguntaClassificacaoWelitonBarbosaSantos0 : '',
 e.respostaClassificacaoWelitonBarbosaSantos0 !== undefined ? e.respostaClassificacaoWelitonBarbosaSantos0 : '',
 e.comentarioClassificacaoWelitonBarbosaSantos0 !== undefined ? e.comentarioClassificacaoWelitonBarbosaSantos0 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Classificação de Weliton Barbosa Santos',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}

const dadosClassificacaoDecreto3048Pdf = () => {
    let y = []
    if (tudo._72_classificacaoDecreto3048 && tudo._72_classificacaoDecreto3048.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._72_classificacaoDecreto3048 !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._72_classificacaoDecreto3048 !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._72_classificacaoDecreto3048 : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaClassificacaoDecreto30480 !== undefined ? e.perguntaClassificacaoDecreto30480 : '',
 e.respostaClassificacaoDecreto30480 !== undefined ? e.respostaClassificacaoDecreto30480 : '',
 e.comentarioClassificacaoDecreto30480 !== undefined ? e.comentarioClassificacaoDecreto30480 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Classificação segundo Decreto 3048',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}

const dadosNecessidadeAjudaPdf = () => {
    let y = []
    if (tudo._73_necessidadeAjudaTerceiros && tudo._73_necessidadeAjudaTerceiros.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._73_necessidadeAjudaTerceiros !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._73_necessidadeAjudaTerceiros !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._73_necessidadeAjudaTerceiros : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaNecessidadeAjudaTerceiros0 !== undefined ? e.perguntaNecessidadeAjudaTerceiros0 : '',
 e.respostaNecessidadeAjudaTerceiros0 !== undefined ? e.respostaNecessidadeAjudaTerceiros0 : '',
 e.comentarioNecessidadeAjudaTerceiros0 !== undefined ? e.comentarioNecessidadeAjudaTerceiros0 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Necessidade de Ajuda de Terceiros',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}

const dadosDanoEsteticoPdf = () => {
    let y = []
    if (tudo._74_classificacaoDanoEstetico && tudo._74_classificacaoDanoEstetico.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._74_classificacaoDanoEstetico !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._74_classificacaoDanoEstetico !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._74_classificacaoDanoEstetico : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaClassificacaoDanoEstetico0 !== undefined ? e.perguntaClassificacaoDanoEstetico0 : '',
 e.respostaClassificacaoDanoEstetico0 !== undefined ? e.respostaClassificacaoDanoEstetico0 : '',
 e.comentarioClassificacaoDanoEstetico0 !== undefined ? e.comentarioClassificacaoDanoEstetico0 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Classificação de Dano Estético',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}

const dadosRepercussaoAtividadesDesportivasPdf = () => {
    let y = []
    if (tudo._75_repercussaoAtividadesDesportivas && tudo._75_repercussaoAtividadesDesportivas.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._75_repercussaoAtividadesDesportivas !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._75_repercussaoAtividadesDesportivas !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._75_repercussaoAtividadesDesportivas : []
 
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 //e.perguntaRepercussaoAtividadesDesportivas0 !== undefined ? e.perguntaRepercussaoAtividadesDesportivas0 : '',
 e.respostaRepercussaoAtividadesDesportivas0 !== undefined ? e.respostaRepercussaoAtividadesDesportivas0 : '',
 e.comentarioRepercussaoAtividadesDesportivas0 !== undefined ? e.comentarioRepercussaoAtividadesDesportivas0 : '',
            ]) : y.push(
 ['',''/* ,'' */])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Repercussao Sobre Atividades Desportivas',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['50%','50%'/* ,'25%' */],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    //{text: 'Pergunta', style: 'cabecalhoTabela'},
                    {text: 'Resposta', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]]/* ,[y[0][2]] */] : ['',''/* ,'' */],
            ],
             }, 
    },
 ]
 }
}


const headerQuesitosPdf = () => {

    if ((tudo._76_quesitosReclamada && tudo._76_quesitosReclamada.length > 0 && tudo._76_quesitosReclamada.conteudoQuesitosReclamada0 !== '')||
        (tudo._77_quesitosReclamante && tudo._77_quesitosReclamante.length > 0 && tudo._77_quesitosReclamante.conteudoQuesitosReclamante0 !== '')||
        (tudo._78_quesitosJuizo && tudo._78_quesitosJuizo.length > 0 && tudo._78_quesitosJuizo.conteudoQuesitosJuizo0 !== '')) {
    return [
        {text: '\n\n'}, 
        {
            text: `QUESITOS`,
            style: 'header'
        },
        {text: '\n'},
        ]

    }
}

const dadosQuesitosReclamadaPdf = () => {
    let y = []
    if (tudo._77_quesitosReclamada && tudo._76_quesitosReclamada.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._76_quesitosReclamada !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._76_quesitosReclamada !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._76_quesitosReclamada : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.idQuesitosReclamada0 !== undefined ? e.idQuesitosReclamada0 : '',
 e.conteudoQuesitosReclamada0 !== undefined ? e.conteudoQuesitosReclamada0 : '',

            ]) : y.push(
 ['',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Quesitos da Reclamada',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['20%','80%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Id', style: 'cabecalhoTabela'},
                    {text: 'Conteúdo', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]]] : ['',''],
            ],
             }, 
    },
 ]
 }
}

const dadosQuesitosReclamantePdf = () => {
    let y = []
    if (tudo._77_quesitosReclamante && tudo._77_quesitosReclamante.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._77_quesitosReclamante !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._77_quesitosReclamante !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._77_quesitosReclamante : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.idQuesitosReclamante0 !== undefined ? e.idQuesitosReclamante0 : '',
 e.conteudoQuesitosReclamante0 !== undefined ? e.conteudoQuesitosReclamante0 : '',

            ]) : y.push(
 ['',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Quesitos da Reclamante',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['20%','80%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Id', style: 'cabecalhoTabela'},
                    {text: 'Conteúdo', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]]] : ['',''],
            ],
             }, 
    },
 ]
 }
}

const dadosQuesitosJuizoPdf = () => {
    let y = []
    if (tudo._78_quesitosJuizo && tudo._78_quesitosJuizo.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._78_quesitosJuizo !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._78_quesitosJuizo !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._78_quesitosJuizo : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.idQuesitosJuizo0 !== undefined ? e.idQuesitosJuizo0 : '',
 e.conteudoQuesitosJuizo0 !== undefined ? e.conteudoQuesitosJuizo0 : '',

            ]) : y.push(
 ['',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Quesitos do Juízo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['20%','80%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Id', style: 'cabecalhoTabela'},
                    {text: 'Conteúdo', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][1] !== '' ) ? [[y[0][0]],[y[0][1]]] : ['',''],
            ],
             }, 
    },
 ]
 }
}

//let bodyConclusaoLaudo = []

const headerConclusaoPdf = () => {

    if (tudo._79_conclusaoLaudo && tudo._79_conclusaoLaudo.length > 0 && tudo._79_conclusaoLaudo.respostaConclusaoLaudo0 !== '') {
    return [
        {text: '\n\n'}, 
        {
            text: `CONCLUSÃO`,
            style: 'header'
        },
        {text: '\n\n'},
    ]
}
}

const dadosConclusaoPdf = () => {
    let y = []
    if (tudo._79_conclusaoLaudo && tudo._79_conclusaoLaudo.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._79_conclusaoLaudo !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._79_conclusaoLaudo !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._79_conclusaoLaudo : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.respostaConclusaoLaudo0 !== undefined ? e.respostaConclusaoLaudo0 : '',
 e.comentarioConclusaoLaudo0 !== undefined ? e.comentarioConclusaoLaudo0 : '',
 e.dataEntregaConclusaoLaudo0 !== undefined ? e.dataEntregaConclusaoLaudo0 : '',
 e.horaEntregaConclusaoLaudo0 !== undefined ? e.horaEntregaConclusaoLaudo0 : '',

            ]) : y.push(
 ['','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Conclusão do Laudo',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['35%','40%','15%','10%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Conclusão', style: 'cabecalhoTabela'},
                    {text: 'Comentário', style: 'cabecalhoTabela'},
                    {text: 'Data', style: 'cabecalhoTabela'},
                    {text: 'Hora', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]],[y[0][3]]] : ['','','',''],
            ],
             }, 
    },
 ]
 }
}




const pdfLaudoPericial = () => {

    tudo = JSON.parse(localStorage.getItem('tudo')) || [] 

    var dd = {

        //ORIENTACAO DA PAGINA
        pageOrientation: 'portrait',

        //TAMANHO DA PAGINA
        pageSize: 'A4',

        //MANIPULACAO DASMARGENS
        pageMargins: [40, 60, 40, 60], 

        //FONTES
        fonts: {
            /* Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajadadosProcessuais/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                }, */
                
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-MediumItalic.ttf'
                },
                OpenSans : {
                    normal: 'OpenSans-Regular.ttf',
                    bold: 'OpenSans-Medium.ttf',
                    italics: 'OpenSans-Italic.ttf',
                    bolditalics: 'OpenSans-Italic.ttf'
                }
        },

        //DEFINIÇÃO DO CONTEÚDO DO CABEÇALHO E RODAPÉ DA PÁGINA
        footer: function (currentPage, pageCount) {
            return currentPage.toString() + ' de ' + pageCount +'       Powered by Da Vinci Tecnologia e Saude'
        },
        header: function (currentPage, pageCount, pageSize) {
            // you can apply any logic and return any valid pdfmake element
            return [{
                    text: tudo._01_dadosIniciais !== undefined && tudo._01_dadosIniciais.numProcesso !== undefined ? tudo._01_dadosIniciais.numProcesso : '',
                    alignment: (currentPage % 2) ? 'left' : 'right'
                },
                {
                    canvas: [{
                        type: 'rect',
                        x: 170,
                        y: 32,
                        w: pageSize.width - 170,
                        h: 40
                    }]
                }
            ]
        },

        //ESTILOS

        //PADRAO
        defaultStyle: {
            font: 'Roboto',
            fontSize: 10,
            bold: false,
            alignment: 'justify',
        },
        
        //ESPECIFICOS
        
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'center'
            },
            subheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 5],
                alingment:'center',
            },
            table: {
                margin: [0, 5, 0, 15],
                alingment:'center',
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            cabecalhoTabela: {
                bold:true,
                fillOpacity: 0.15,
                fillColor: 'blue',
                overlayPattern: ['stripe45d', 'gray'],
                overlayOpacity: 0.15,
                /* margin: [0, 5, 0, 15], */
                alignment: 'center'
            },
            /* subheader: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
            }, */
            tableLayouts: {
                exampleLayout: {
                  hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                      return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                  },
                  vLineWidth: function (i) {
                    return 0;
                  },
                  hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                  },
                  paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                  },
                  paddingRight: function (i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 8;
                  }
                }
              },
            ol: {
                fontSize: 10,
                bold: false,
                alignment: 'center'
            },
            quote: {
                italics: true,
                alignment: 'center'
            },
            small: {
                fontSize: 8,
                alignment: 'center'
            },
            medio: {
                fontSize: 10,
                alignment: 'center',
            },
            center: {
                alingment: 'center',
                fontSize: 10,
            }
        },

        //CONTEÚDO DO PDF
        content: [

        {
            qr: 'https://pericias.app',
            fit: '50'
        },

/*
        // INDICE
        {
            toc: {
                id: 'mainToc',
                title: {
                    text: 'Ìndice',
                    style: 'header'
                }
            }
        },
        {
                text: 'Texto simples',
                style: 'header',
                tocItem: 'mainToc'
        },


        //LINKS
        {
            text: 'Ir para a página 1',
            linkToPage: 1
        },
        {
            text: 'Go to Header',
            linkToDestination: 'header'
        },
*/
        // TEXTOS E TABELAS
            
        dadosIniciaisLaudoPdf(),
        dadosReclamantePdf(),
        dadosReclamadaPdf(),
        headerDadosProcessuaisPdf(),                   
        dadosInicialPdf(), 
        dadosContestacaoPdf(),
         dadosAtestadoPdf(),
        dadosInssPdf(),
        dadosCatPdf(),
        dadosSstPdf(),
        dadosOutrosPdf(),
        headerDadosTrabalhoPdf(),
        dadosTrabalhoEmpresaPdf(),
        dadosHistoricoLaboralPdf(),

        headerDadosSaudePdf(),
        dadosAnamneseOrtopedicaPdf(),
        dadosAnamnesePsiPdf(),
        dadosQuestionatioPsiPdf(),
        dadosExameMentalPsiPdf(),
        dadosAnamneseAudioPdf(),
        dadosAnamneseLivrePdf(),

        dadosDeficienciaPreviaPdf(),
        dadosComorbidadesPdf(),
        dadosAntecedentesFamiliaresPdf(),
        dadosAtividadesExtralaboraisPdf(),
        
        dadosPesoAlturaImcPdf(),
        dadosCircunferenciaMembroPdf(),
        dadosAtitudeExaminadorPdf(),
        dadosExameGeralPdf(),
        dadosExamePsiPdf(),
        dadosExameOlhoPdf(),
        dadosExameOuvidoPdf(),
        dadosExameNarizPdf(),
        dadosExameGargantaPdf(),
        dadosExameColunaCervicalPdf(),
        dadosExameColunaLombarPdf(),
        dadosExameOmbroPdf(),
        dadosExameCotoveloPdf(),
        dadosExamePunhoPdf(),
        dadosExameMaoPdf(),
        dadosExameQuadrilPdf(),
        dadosExameJoelhoPdf(),
        dadosExameTornozeloPdf(),
        dadosExamePePdf(),
        dadosExameNeurologicoPdf(),
        dadosExameLivrePdf(),

        dadosExameComplementarPdf(),

        //headerReferencialTecnicoPdf(),
        //dadosReferencialTecnicoPdf(),
        
        headerAvaliacaoNexoPdf(),
        dadosNtepPdf(),
        dadosNexoProfissionalPdf(),
        dadosPenteadoPdf(),
        dadosConcausaPdf(),
        dadosSimoninPdf(),
        dadosFranchiniPdf(),
        dadosBradfordHillPdf(),
        dadosCfmPdf(),

        headerValoracaoDanoPdf(),
        dadosValoracaoDesnecessariaPdf(),
        dadosDeficitFuncionalTemporarioPdf(),
        dadosIncapacidadeLaboralTemporariaPdf(),
        dadosQuantumDolorisPdf(),
        dadosDeficitFuncionalDefinitivoPdf(),
        dadosClassificacaoIncapacidadePenteadoPdf(),
        dadosBaremoArgentinoQualitativoPdf(),
        dadosClassificacaoWelitonBarbosaSantosPdf(),
        dadosClassificacaoDecreto3048Pdf(),
        dadosNecessidadeAjudaPdf(),
        dadosDanoEsteticoPdf(),
        dadosRepercussaoAtividadesDesportivasPdf(),
        
        headerQuesitosPdf(),
        dadosQuesitosReclamadaPdf(),
        dadosQuesitosReclamantePdf(),
        dadosQuesitosJuizoPdf(),
        headerConclusaoPdf(),
        dadosConclusaoPdf(),
        headerEsclarecimentosPdf(),
        dadosQuesitosEsclarecimentosPdf(),
        //dadosPeritoLaudoPdf(),
        ],
    }

    // CRIA UMA NOVA ABA COM O  DOCUMENTO

    //pdfMake.createPdf(dd, tableLayouts, fonts/*, vfs*/).open()

    // PARA MOSTRAR NA TELA
    /*const pdfDocGenerator = pdfMake.createPdf(dd);
     pdfDocGenerator.getDataUrl((dataUrl) => {
        const targetElement = document.querySelector('#iframeContainerRefatoradoLaudo');
        const iframe = document.createElement('iframe');
        iframe.src = dataUrl;
        targetElement.appendChild(iframe);
    }) */
    //pdfMake.createPdf(dd/* , tableLayouts, fonts, vfs */).print()
    pdfMake.createPdf(dd).getDataUrl((url)=> setUrl(url))
}

const headerEsclarecimentosPdf = () => {

    if (tudo._80_esclarecimentos && tudo._80_esclarecimentos.length > 0 && tudo._80_esclarecimentos.respostaEsclarecimentos0 !== '') {
    return [
        {text: '\n\n'}, 
        {
            text: `ESCLARECIMENTOS`,
            style: 'header'
        },
        {text: '\n\n'},
    ]
}
} 

const dadosQuesitosEsclarecimentosPdf = () => {
    let y = []
    if (tudo._80_esclarecimentos && tudo._80_esclarecimentos.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._80_esclarecimentos !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._80_esclarecimentos !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._80_esclarecimentos : []
        //console.log('***',a)
    a.map((e)=> 
        e !== undefined ? y.push(
            [
 e.idEsclarecimentos0 !== undefined ? e.idEsclarecimentos0 : '',
 e.respostaEsclarecimentos0 !== undefined ? e.respostaEsclarecimentos0 : '',
 e.dataEntregaEsclarecimentos0 !== undefined ? e.dataEntregaEsclarecimentos0 : '',
 e.horaEntregaEsclarecimentos0 !== undefined ? e.horaEntregaEsclarecimentos0 : '',

            ]) : y.push(
 ['','','',''])
    )
 
 // console.log('***y',y[0],'***length',y[0].length)
 
 return [
    {text: '\n'},
    {
        text: 'Quesitos de Esclarecimentos',
        style: 'subheader',
        alignment: 'center',
    },
    {
        layout: 'lightHorizontalLines',
        alignment: 'center',
        table: {
            heights: function () {
                return 15
            },
            headerRows: 1,
            widths: ['15%','60%','15%','10%'],
            body: [
                [
                    //DADOS DO CABECALHO E ESTILO
                    {text: 'Id', style: 'cabecalhoTabela'},
                    {text: 'Quesitos', style: 'cabecalhoTabela'},
                    {text: 'Data', style: 'cabecalhoTabela'},
                    {text: 'Hora', style: 'cabecalhoTabela'},
                ],
                
                //DADOS DA TABELA EM SI
                y.length > 0 && (y[0][0] !== '' ) ? [[y[0][0]],[y[0][1]],[y[0][2]],[y[0][3]]] : ['','','',''],
            ],
             }, 
    },
 ]
 }
} 

const pdfLaudoPericialEsclarecimentos = () => {

    tudo = JSON.parse(localStorage.getItem('tudo')) || [] 

    var dd = {

        //ORIENTACAO DA PAGINA
        pageOrientation: 'portrait',

        //TAMANHO DA PAGINA
        pageSize: 'A4',

        //MANIPULACAO DASMARGENS
        pageMargins: [40, 60, 40, 60], 

        //FONTES
        fonts: {
            /* Roboto: {
                    normal: 'https://cdnjs.cloudflare.com/ajadadosProcessuais/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
                    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
                    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
                    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
                }, */
                
                Roboto: {
                    normal: 'Roboto-Regular.ttf',
                    bold: 'Roboto-Medium.ttf',
                    italics: 'Roboto-Italic.ttf',
                    bolditalics: 'Roboto-MediumItalic.ttf'
                },
                OpenSans : {
                    normal: 'OpenSans-Regular.ttf',
                    bold: 'OpenSans-Medium.ttf',
                    italics: 'OpenSans-Italic.ttf',
                    bolditalics: 'OpenSans-Italic.ttf'
                }
        },

        //DEFINIÇÃO DO CONTEÚDO DO CABEÇALHO E RODAPÉ DA PÁGINA
        footer: function (currentPage, pageCount) {
            return currentPage.toString() + ' de ' + pageCount +'       Powered by Da Vinci Tecnologia e Saude'
        },
        header: function (currentPage, pageCount, pageSize) {
            // you can apply any logic and return any valid pdfmake element
            return [{
                    text: tudo._01_dadosIniciais !== undefined && tudo._01_dadosIniciais.numProcesso !== undefined ? tudo._01_dadosIniciais.numProcesso : '',
                    alignment: (currentPage % 2) ? 'left' : 'right'
                },
                {
                    canvas: [{
                        type: 'rect',
                        x: 170,
                        y: 32,
                        w: pageSize.width - 170,
                        h: 40
                    }]
                }
            ]
        },

        //ESTILOS

        //PADRAO
        defaultStyle: {
            font: 'Roboto',
            fontSize: 10,
            bold: false,
            alignment: 'justify',
        },
        
        //ESPECIFICOS
        
        styles: {
            header: {
                fontSize: 14,
                bold: true,
                margin: [0, 0, 0, 10],
                alignment: 'center'
            },
            subheader: {
                fontSize: 12,
                bold: true,
                margin: [0, 10, 0, 5],
                alingment:'center',
            },
            table: {
                margin: [0, 5, 0, 15],
                alingment:'center',
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                color: 'black',
                alignment: 'center'
            },
            cabecalhoTabela: {
                bold:true,
                fillOpacity: 0.15,
                fillColor: 'blue',
                overlayPattern: ['stripe45d', 'gray'],
                overlayOpacity: 0.15,
                /* margin: [0, 5, 0, 15], */
                alignment: 'center'
            },
            /* subheader: {
                fontSize: 10,
                bold: true,
                alignment: 'center'
            }, */
            tableLayouts: {
                exampleLayout: {
                  hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                      return 0;
                    }
                    return (i === node.table.headerRows) ? 2 : 1;
                  },
                  vLineWidth: function (i) {
                    return 0;
                  },
                  hLineColor: function (i) {
                    return i === 1 ? 'black' : '#aaa';
                  },
                  paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                  },
                  paddingRight: function (i, node) {
                    return (i === node.table.widths.length - 1) ? 0 : 8;
                  }
                }
              },
            ol: {
                fontSize: 10,
                bold: false,
                alignment: 'center'
            },
            quote: {
                italics: true,
                alignment: 'center'
            },
            small: {
                fontSize: 8,
                alignment: 'center'
            },
            medio: {
                fontSize: 10,
                alignment: 'center',
            },
            center: {
                alingment: 'center',
                fontSize: 10,
            }
        },

        //CONTEÚDO DO PDF
        content: [

        {
            qr: 'https://pericias.app',
            fit: '50'
        },

/*
        // INDICE
        {
            toc: {
                id: 'mainToc',
                title: {
                    text: 'Ìndice',
                    style: 'header'
                }
            }
        },
        {
                text: 'Texto simples',
                style: 'header',
                tocItem: 'mainToc'
        },


        //LINKS
        {
            text: 'Ir para a página 1',
            linkToPage: 1
        },
        {
            text: 'Go to Header',
            linkToDestination: 'header'
        },
*/
        // TEXTOS E TABELAS
            
        //dadosIniciaisLaudoPdf(),
        /* dadosReclamantePdf(),
        dadosReclamadaPdf(),
        headerDadosProcessuaisPdf(),                   
        dadosInicialContestacaoPdf(), 
         dadosAtestadoPdf(),
        dadosInssPdf(),
        dadosCatPdf(),
        dadosSstPdf(),
        dadosOutrosPdf(),
        headerDadosTrabalhoPdf(),
        dadosTrabalhoEmpresaPdf(),
        dadosHistoricoLaboralPdf(),

        headerDadosSaudePdf(),
        dadosAnamneseOrtopedicaPdf(),
        dadosAnamnesePsiPdf(),
        dadosQuestionatioPsiPdf(),
        dadosExameMentalPsiPdf(),
        dadosAnamneseAudioPdf(),
        dadosAnamneseLivrePdf(),

        dadosDeficienciaPreviaPdf(),
        dadosComorbidadesPdf(),
        dadosAntecedentesFamiliaresPdf(),
        dadosAtividadesExtralaboraisPdf(),
        
        dadosPesoAlturaImcPdf(),
        dadosCircunferenciaMembroPdf(),
        dadosAtitudeExaminadorPdf(),
        dadosExameGeralPdf(),
        dadosExamePsiPdf(),
        dadosExameOlhoPdf(),
        dadosExameOuvidoPdf(),
        dadosExameNarizPdf(),
        dadosExameGargantaPdf(),
        dadosExameColunaCervicalPdf(),
        dadosExameColunaLombarPdf(),
        dadosExameOmbroPdf(),
        dadosExameCotoveloPdf(),
        dadosExamePunhoPdf(),
        dadosExameMaoPdf(),
        dadosExameQuadrilPdf(),
        dadosExameJoelhoPdf(),
        dadosExameTornozeloPdf(),
        dadosExamePePdf(),
        dadosExameNeurologicoPdf(),
        dadosExameLivrePdf(),

        dadosExameComplementarPdf(),

        //headerReferencialTecnicoPdf(),
        //dadosReferencialTecnicoPdf(),
        
        headerAvaliacaoNexoPdf(),
        dadosNtepPdf(),
        dadosNexoProfissionalPdf(),
        dadosPenteadoPdf(),
        dadosConcausaPdf(),
        dadosSimoninPdf(),
        dadosFranchiniPdf(),
        dadosBradfordHillPdf(),
        dadosCfmPdf(),

        headerValoracaoDanoPdf(),
        dadosValoracaoDesnecessariaPdf(),
        dadosDeficitFuncionalTemporarioPdf(),
        dadosIncapacidadeLaboralTemporariaPdf(),
        dadosQuantumDolorisPdf(),
        dadosDeficitFuncionalDefinitivoPdf(),
        dadosClassificacaoIncapacidadePenteadoPdf(),
        dadosBaremoArgentinoQualitativoPdf(),
        dadosClassificacaoWelitonBarbosaSantosPdf(),
        dadosClassificacaoDecreto3048Pdf(),
        dadosNecessidadeAjudaPdf(),
        dadosDanoEsteticoPdf(),
        dadosRepercussaoAtividadesDesportivasPdf(),
        
        headerQuesitosPdf(),
        dadosQuesitosReclamadaPdf(),
        dadosQuesitosReclamantePdf(),
        dadosQuesitosJuizoPdf(),
        headerConclusaoPdf(),
        dadosConclusaoPdf(), */
        headerEsclarecimentosPdf(),
        dadosQuesitosEsclarecimentosPdf(),
        //dadosPeritoLaudoPdf(),
        ],
    }

    // CRIA UMA NOVA ABA COM O  DOCUMENTO

    //pdfMake.createPdf(dd, tableLayouts, fonts/*, vfs*/).open()

    // PARA MOSTRAR NA TELA
    /*const pdfDocGenerator = pdfMake.createPdf(dd);
     pdfDocGenerator.getDataUrl((dataUrl) => {
        const targetElement = document.querySelector('#iframeContainerRefatoradoLaudo');
        const iframe = document.createElement('iframe');
        iframe.src = dataUrl;
        targetElement.appendChild(iframe);
    }) */
    //pdfMake.createPdf(dd/* , tableLayouts, fonts, vfs */).print()
    pdfMake.createPdf(dd).getDataUrl((url)=> setUrl(url))
}

const [url,setUrl] = useState('') 



    return (
        
        <fieldset  >
        <legend>Gerador de Pdf</legend>
        <div className={styles.div}>
        <button className={styles.button} onClick={pdfAgendamentoPericia}><i className="fas fa-solid fa-file-pdf">Agendamento</i></button>
        <button className={styles.button} onClick={pdfLaudoPericial}><i className="fas fa-solid fa-file-pdf">Laudo pericial</i></button>
        <button className={styles.button} onClick={pdfLaudoPericialEsclarecimentos}><i className="fas fa-solid fa-file-pdf">Esclarecimentos</i></button>
        <button className={styles.button} onClick={imprimir}><i className="fas fa-solid fa-file-pdf">Imprimir</i></button>
        </div>
        <iframe className={styles.iframe} title='Pdf'src={url && url }/* allowFullScreen={true} */ referrerPolicy='no-referrer' /* seamless={true} *//* sandbox='sandbox' */> Erro no Iframe </iframe>
        </fieldset>
        
    )
 }