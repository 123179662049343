import React from "react";

import { QuesitosReclamada } from "./quesitosReclamada/QuesitosReclamada";
import { QuesitosReclamante } from "./quesitosReclamante/QuesitosReclamante";
import { QuesitosJuizo } from "./quesitosJuizo/QuesitosJuizo";
import { Outlet } from "react-router-dom";

export const Quesitos = () => {
  return (
    <>
          <Outlet>
          <QuesitosReclamada />
          <QuesitosReclamante />
          <QuesitosJuizo />
          </Outlet>
    </>
  );
};
