import React, {useState} from 'react'
import styles from '../criteriosPenteado/CriteriosPenteado.module.css'

export const CriteriosPenteado = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
        //console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ criteriosPenteado, setCriteriosPenteado] = useState([])
   
    const adicionarCriteriosPenteado = (e) => {

        e.preventDefault()

        const arrCriteriosPenteado = []
    
        arrCriteriosPenteado.push({
            perguntaCriteriosPenteado0: "O mecanismo etiológico de aparecimento da doença encontra-se amparado na literatura técnica para nexo de causalidade com a exposição ao risco? (Critério técnico-científico)",
            respostaCriteriosPenteado0: formValues.respostaCriteriosPenteado0 !== undefined ? formValues.respostaCriteriosPenteado0 : '',
            comentarioCriteriosPenteado0: formValues.comentarioCriteriosPenteado0 !== undefined ? formValues.comentarioCriteriosPenteado0 : '',
            perguntaCriteriosPenteado1: "Existem dados na literatura médica sobre a existência especifica de relação entre a doença e a atividade laboral? (Critério técnico-científico)",
            respostaCriteriosPenteado1: formValues.respostaCriteriosPenteado1 !== undefined ? formValues.respostaCriteriosPenteado1 : '',
            comentarioCriteriosPenteado1: formValues.comentarioCriteriosPenteado1 !== undefined ? formValues.comentarioCriteriosPenteado1 : '',
            perguntaCriteriosPenteado2: "O agente causador da doença está identificado claramente nas atividades laborais? (Critério de intensidade e tempo de exposição)",
            respostaCriteriosPenteado2: formValues.respostaCriteriosPenteado2 !== undefined ? formValues.respostaCriteriosPenteado2 : '',
            comentarioCriteriosPenteado2: formValues.comentarioCriteriosPenteado2 !== undefined ? formValues.comentarioCriteriosPenteado2 : '',
            perguntaCriteriosPenteado3: "A intensidade, ou seja, a forma e a magnitude com que ocorre a exposição na atividade laboral é passível de trazer dano à saúde? (Critério de intensidade e tempo de exposição)",
            respostaCriteriosPenteado3: formValues.respostaCriteriosPenteado3 !== undefined ? formValues.respostaCriteriosPenteado3 : '',
            comentarioCriteriosPenteado3: formValues.comentarioCriteriosPenteado3 !== undefined ? formValues.comentarioCriteriosPenteado3 : '',
            perguntaCriteriosPenteado4: "O tempo de exposição diário ao risco informado é capaz de causar a doença? (Critério de intensidade e tempo de exposição)",
            respostaCriteriosPenteado4: formValues.respostaCriteriosPenteado4 !== undefined ? formValues.respostaCriteriosPenteado4 : '',
            comentarioCriteriosPenteado4: formValues.comentarioCriteriosPenteado4 !== undefined ? formValues.comentarioCriteriosPenteado4 : '',
            perguntaCriteriosPenteado5: "O tempo compreendido entre o inicio da exposição ao risco e o inicio dos sintomas é suficiente para que a doença apareça e se desenvolva? (Critério de tempo de latência)",
            respostaCriteriosPenteado5: formValues.respostaCriteriosPenteado5 !== undefined ? formValues.respostaCriteriosPenteado5 : '',
            comentarioCriteriosPenteado5: formValues.comentarioCriteriosPenteado5 !== undefined ? formValues.comentarioCriteriosPenteado5 : '',
            perguntaCriteriosPenteado6: "Foi comprovada a presença de algum tipo de incapacidade laborativa, total ou parcial, temporária ou definitiva, que tenha relação com o período contratual? (Critério de incapacidade)",
            respostaCriteriosPenteado6: formValues.respostaCriteriosPenteado6 !== undefined ? formValues.respostaCriteriosPenteado6 : '',
            comentarioCriteriosPenteado6: formValues.comentarioCriteriosPenteado6 !== undefined ? formValues.comentarioCriteriosPenteado6 : '',
            perguntaCriteriosPenteado7: "Após o afastamento do risco, houve cura, melhora, ou no mínimo, estacionamento nas queixas clínicas? (Critério de afastamento do risco)",
            respostaCriteriosPenteado7: formValues.respostaCriteriosPenteado7 !== undefined ? formValues.respostaCriteriosPenteado7 : '',
            comentarioCriteriosPenteado7: formValues.comentarioCriteriosPenteado7 !== undefined ? formValues.comentarioCriteriosPenteado7 : '', 
            perguntaCriteriosPenteado8: "O aparecimento das queixas clínicas guarda relação temporal direta/imediata com a exposição ao risco? Existe comprovação documental em prontuário médico? (Critério temporal)",
            respostaCriteriosPenteado8: formValues.respostaCriteriosPenteado8 !== undefined ? formValues.respostaCriteriosPenteado8 : '',
            comentarioCriteriosPenteado8: formValues.comentarioCriteriosPenteado8 !== undefined ? formValues.comentarioCriteriosPenteado8 : '',
            perguntaCriteriosPenteado9: "O diagnóstico da patologia com exames complementares guarda relação temporal direta/imediata com a exposição ao risco? (Critério temporal)",
            respostaCriteriosPenteado9: formValues.respostaCriteriosPenteado9 !== undefined ? formValues.respostaCriteriosPenteado9 : '',
            comentarioCriteriosPenteado9: formValues.comentarioCriteriosPenteado9 !== undefined ? formValues.comentarioCriteriosPenteado9 : '',
            perguntaCriteriosPenteado10: "Encontra-se a doença elencada no Decreto no 3.048/99 e demais resoluções legais? (Critério legal)",
            respostaCriteriosPenteado10: formValues.respostaCriteriosPenteado10 !== undefined ? formValues.respostaCriteriosPenteado10 : '',
            comentarioCriteriosPenteado10: formValues.comentarioCriteriosPenteado10 !== undefined ? formValues.comentarioCriteriosPenteado10 : '',
            perguntaCriteriosPenteado11: "As atividades laborais realizadas anteriormente ao aparecimento da doença podem estar influenciando na gênese da doença? (Critério de condições pregressas)",
            respostaCriteriosPenteado11: formValues.respostaCriteriosPenteado11 !== undefined ? formValues.respostaCriteriosPenteado11 : '',
            comentarioCriteriosPenteado11: formValues.comentarioCriteriosPenteado11 !== undefined ? formValues.comentarioCriteriosPenteado11 : '',
            perguntaCriteriosPenteado12: "Conforme registros médicos do estado anterior do paciente, existiam queixas pregressas à exposição ao risco? (Critério de condições pregressas)",
            respostaCriteriosPenteado12: formValues.respostaCriteriosPenteado12 !== undefined ? formValues.respostaCriteriosPenteado12 : '',
            comentarioCriteriosPenteado12: formValues.comentarioCriteriosPenteado12 !== undefined ? formValues.comentarioCriteriosPenteado12 : '',
            perguntaCriteriosPenteado13: "Existe coerência clinica entre sintomas, diagnóstico e tratamentos? (Critério de coerência clinica)",
            respostaCriteriosPenteado13: formValues.respostaCriteriosPenteado13 !== undefined ? formValues.respostaCriteriosPenteado13 : '',
            comentarioCriteriosPenteado13: formValues.comentarioCriteriosPenteado13 !== undefined ? formValues.comentarioCriteriosPenteado13 : '',
            perguntaCriteriosPenteado14: "Foram constatadas a presença de outras causas para a gênese da doença?",
            respostaCriteriosPenteado14: formValues.respostaCriteriosPenteado14 !== undefined ? formValues.respostaCriteriosPenteado14 : '',
            comentarioCriteriosPenteado14: formValues.comentarioCriteriosPenteado14 !== undefined ? formValues.comentarioCriteriosPenteado14 : '',
            perguntaCriteriosPenteado15: "Existindo mais de uma causa para a doença a e analisado a possibilidade de causa adequada, causa concorrente ou concausa?",
            respostaCriteriosPenteado15: formValues.respostaCriteriosPenteado15 !== undefined ? formValues.respostaCriteriosPenteado15 : '',
            comentarioCriteriosPenteado15: formValues.comentarioCriteriosPenteado15 !== undefined ? formValues.comentarioCriteriosPenteado15 : '',
            perguntaCriteriosPenteado16: "conclusaoCriteriosPenteado",
            respostaCriteriosPenteado16: formValues.respostaCriteriosPenteado16 !== undefined ? formValues.respostaCriteriosPenteado16 : '',
            comentarioCriteriosPenteado16: formValues.comentarioCriteriosPenteado16 !== undefined ? formValues.comentarioCriteriosPenteado16 : '',    
        })
        setCriteriosPenteado([...arrCriteriosPenteado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _54_criteriosPenteado: [{
                perguntaCriteriosPenteado0: "O mecanismo etiológico de aparecimento da doença encontra-se amparado na literatura técnica para nexo de causalidade com a exposição ao risco? (Critério técnico-científico)",
            respostaCriteriosPenteado0: formValues.respostaCriteriosPenteado0 !== undefined ? formValues.respostaCriteriosPenteado0 : '',
            comentarioCriteriosPenteado0: formValues.comentarioCriteriosPenteado0 !== undefined ? formValues.comentarioCriteriosPenteado0 : '',
            perguntaCriteriosPenteado1: "Existem dados na literatura médica sobre a existência especifica de relação entre a doença e a atividade laboral? (Critério técnico-científico)",
            respostaCriteriosPenteado1: formValues.respostaCriteriosPenteado1 !== undefined ? formValues.respostaCriteriosPenteado1 : '',
            comentarioCriteriosPenteado1: formValues.comentarioCriteriosPenteado1 !== undefined ? formValues.comentarioCriteriosPenteado1 : '',
            perguntaCriteriosPenteado2: "O agente causador da doença está identificado claramente nas atividades laborais? (Critério de intensidade e tempo de exposição)",
            respostaCriteriosPenteado2: formValues.respostaCriteriosPenteado2 !== undefined ? formValues.respostaCriteriosPenteado2 : '',
            comentarioCriteriosPenteado2: formValues.comentarioCriteriosPenteado2 !== undefined ? formValues.comentarioCriteriosPenteado2 : '',
            perguntaCriteriosPenteado3: "A intensidade, ou seja, a forma e a magnitude com que ocorre a exposição na atividade laboral é passível de trazer dano à saúde? (Critério de intensidade e tempo de exposição)",
            respostaCriteriosPenteado3: formValues.respostaCriteriosPenteado3 !== undefined ? formValues.respostaCriteriosPenteado3 : '',
            comentarioCriteriosPenteado3: formValues.comentarioCriteriosPenteado3 !== undefined ? formValues.comentarioCriteriosPenteado3 : '',
            perguntaCriteriosPenteado4: "O tempo de exposição diário ao risco informado é capaz de causar a doença? (Critério de intensidade e tempo de exposição)",
            respostaCriteriosPenteado4: formValues.respostaCriteriosPenteado4 !== undefined ? formValues.respostaCriteriosPenteado4 : '',
            comentarioCriteriosPenteado4: formValues.comentarioCriteriosPenteado4 !== undefined ? formValues.comentarioCriteriosPenteado4 : '',
            perguntaCriteriosPenteado5: "O tempo compreendido entre o inicio da exposição ao risco e o inicio dos sintomas é suficiente para que a doença apareça e se desenvolva? (Critério de tempo de latência)",
            respostaCriteriosPenteado5: formValues.respostaCriteriosPenteado5 !== undefined ? formValues.respostaCriteriosPenteado5 : '',
            comentarioCriteriosPenteado5: formValues.comentarioCriteriosPenteado5 !== undefined ? formValues.comentarioCriteriosPenteado5 : '',
            perguntaCriteriosPenteado6: "Foi comprovada a presença de algum tipo de incapacidade laborativa, total ou parcial, temporária ou definitiva, que tenha relação com o período contratual? (Critério de incapacidade)",
            respostaCriteriosPenteado6: formValues.respostaCriteriosPenteado6 !== undefined ? formValues.respostaCriteriosPenteado6 : '',
            comentarioCriteriosPenteado6: formValues.comentarioCriteriosPenteado6 !== undefined ? formValues.comentarioCriteriosPenteado6 : '',
            perguntaCriteriosPenteado7: "Após o afastamento do risco, houve cura, melhora, ou no mínimo, estacionamento nas queixas clínicas? (Critério de afastamento do risco)",
            respostaCriteriosPenteado7: formValues.respostaCriteriosPenteado7 !== undefined ? formValues.respostaCriteriosPenteado7 : '',
            comentarioCriteriosPenteado7: formValues.comentarioCriteriosPenteado7 !== undefined ? formValues.comentarioCriteriosPenteado7 : '',
            perguntaCriteriosPenteado8: "O aparecimento das queixas clínicas guarda relação temporal direta/imediata com a exposição ao risco? Existe comprovação documental em prontuário médico? (Critério temporal)",
            respostaCriteriosPenteado8: formValues.respostaCriteriosPenteado8 !== undefined ? formValues.respostaCriteriosPenteado8 : '',
            comentarioCriteriosPenteado8: formValues.comentarioCriteriosPenteado8 !== undefined ? formValues.comentarioCriteriosPenteado8 : '',
            perguntaCriteriosPenteado9: "O diagnóstico da patologia com exames complementares guarda relação temporal direta/imediata com a exposição ao risco? (Critério temporal)",
            respostaCriteriosPenteado9: formValues.respostaCriteriosPenteado9 !== undefined ? formValues.respostaCriteriosPenteado9 : '',
            comentarioCriteriosPenteado9: formValues.comentarioCriteriosPenteado9 !== undefined ? formValues.comentarioCriteriosPenteado9 : '',
            perguntaCriteriosPenteado10: "Encontra-se a doença elencada no Decreto no 3.048/99 e demais resoluções legais? (Critério legal)",
            respostaCriteriosPenteado10: formValues.respostaCriteriosPenteado10 !== undefined ? formValues.respostaCriteriosPenteado10 : '',
            comentarioCriteriosPenteado10: formValues.comentarioCriteriosPenteado10 !== undefined ? formValues.comentarioCriteriosPenteado10 : '',
            perguntaCriteriosPenteado11: "As atividades laborais realizadas anteriormente ao aparecimento da doença podem estar influenciando na gênese da doença? (Critério de condições pregressas)",
            respostaCriteriosPenteado11: formValues.respostaCriteriosPenteado11 !== undefined ? formValues.respostaCriteriosPenteado11 : '',
            comentarioCriteriosPenteado11: formValues.comentarioCriteriosPenteado11 !== undefined ? formValues.comentarioCriteriosPenteado11 : '',
            perguntaCriteriosPenteado12: "Conforme registros médicos do estado anterior do paciente, existiam queixas pregressas à exposição ao risco? (Critério de condições pregressas)",
            respostaCriteriosPenteado12: formValues.respostaCriteriosPenteado12 !== undefined ? formValues.respostaCriteriosPenteado12 : '',
            comentarioCriteriosPenteado12: formValues.comentarioCriteriosPenteado12 !== undefined ? formValues.comentarioCriteriosPenteado12 : '',
            perguntaCriteriosPenteado13: "Existe coerência clinica entre sintomas, diagnóstico e tratamentos? (Critério de coerência clinica)",
            respostaCriteriosPenteado13: formValues.respostaCriteriosPenteado13 !== undefined ? formValues.respostaCriteriosPenteado13 : '',
            comentarioCriteriosPenteado13: formValues.comentarioCriteriosPenteado13 !== undefined ? formValues.comentarioCriteriosPenteado13 : '',
            perguntaCriteriosPenteado14: "Foram constatadas a presença de outras causas para a gênese da doença?",
            respostaCriteriosPenteado14: formValues.respostaCriteriosPenteado14 !== undefined ? formValues.respostaCriteriosPenteado14 : '',
            comentarioCriteriosPenteado14: formValues.comentarioCriteriosPenteado14 !== undefined ? formValues.comentarioCriteriosPenteado14 : '',
            perguntaCriteriosPenteado15: "Existindo mais de uma causa para a doença a e analisado a possibilidade de causa adequada, causa concorrente ou concausa?",
            respostaCriteriosPenteado15: formValues.respostaCriteriosPenteado15 !== undefined ? formValues.respostaCriteriosPenteado15 : '',
            comentarioCriteriosPenteado15: formValues.comentarioCriteriosPenteado15 !== undefined ? formValues.comentarioCriteriosPenteado15 : '',
            perguntaCriteriosPenteado16: "conclusaoCriteriosPenteado",
            respostaCriteriosPenteado16: formValues.respostaCriteriosPenteado16 !== undefined ? formValues.respostaCriteriosPenteado16 : '',
            comentarioCriteriosPenteado16: formValues.comentarioCriteriosPenteado16 !== undefined ? formValues.comentarioCriteriosPenteado16 : '',
            }]
      }))
    }

    const excluirCriteriosPenteado = (e) => {
        e.preventDefault()  

        setCriteriosPenteado([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _54_criteriosPenteado: []
              }))
    }

    const handleCarregarFormCriteriosPenteado = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._54_criteriosPenteado && tudo._54_criteriosPenteado.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._54_criteriosPenteado !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._54_criteriosPenteado !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._54_criteriosPenteado : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaCriteriosPenteado0: e !== undefined && e.perguntaCriteriosPenteado0 !== undefined ? e.perguntaCriteriosPenteado0 : '',
            respostaCriteriosPenteado0: e !== undefined &&  e.respostaCriteriosPenteado0 !== undefined ? e.respostaCriteriosPenteado0 : '',
            comentarioCriteriosPenteado0: e !== undefined &&  e.comentarioCriteriosPenteado0 !== undefined ? e.comentarioCriteriosPenteado0 : '',
            perguntaCriteriosPenteado1: e !== undefined && e.perguntaCriteriosPenteado1 !== undefined ? e.perguntaCriteriosPenteado1 : '',
            respostaCriteriosPenteado1: e !== undefined &&  e.respostaCriteriosPenteado1 !== undefined ? e.respostaCriteriosPenteado1 : '',
            comentarioCriteriosPenteado1: e !== undefined &&  e.comentarioCriteriosPenteado1 !== undefined ? e.comentarioCriteriosPenteado1 : '',
            perguntaCriteriosPenteado2: e !== undefined && e.perguntaCriteriosPenteado2 !== undefined ? e.perguntaCriteriosPenteado2 : '',
            respostaCriteriosPenteado2: e !== undefined &&  e.respostaCriteriosPenteado2 !== undefined ? e.respostaCriteriosPenteado2 : '',
            comentarioCriteriosPenteado2: e !== undefined &&  e.comentarioCriteriosPenteado2 !== undefined ? e.comentarioCriteriosPenteado2 : '',
            perguntaCriteriosPenteado3: e !== undefined && e.perguntaCriteriosPenteado3 !== undefined ? e.perguntaCriteriosPenteado3 : '',
            respostaCriteriosPenteado3: e !== undefined &&  e.respostaCriteriosPenteado3 !== undefined ? e.respostaCriteriosPenteado3 : '',
            comentarioCriteriosPenteado3: e !== undefined &&  e.comentarioCriteriosPenteado3 !== undefined ? e.comentarioCriteriosPenteado3 : '',
            perguntaCriteriosPenteado4: e !== undefined && e.perguntaCriteriosPenteado4 !== undefined ? e.perguntaCriteriosPenteado4 : '',
            respostaCriteriosPenteado4: e !== undefined &&  e.respostaCriteriosPenteado4 !== undefined ? e.respostaCriteriosPenteado4 : '',
            comentarioCriteriosPenteado4: e !== undefined &&  e.comentarioCriteriosPenteado4 !== undefined ? e.comentarioCriteriosPenteado4 : '',
            perguntaCriteriosPenteado5: e !== undefined && e.perguntaCriteriosPenteado5 !== undefined ? e.perguntaCriteriosPenteado5 : '',
            respostaCriteriosPenteado5: e !== undefined &&  e.respostaCriteriosPenteado5 !== undefined ? e.respostaCriteriosPenteado5 : '',
            comentarioCriteriosPenteado5: e !== undefined &&  e.comentarioCriteriosPenteado5 !== undefined ? e.comentarioCriteriosPenteado5 : '',
            perguntaCriteriosPenteado6: e !== undefined && e.perguntaCriteriosPenteado6 !== undefined ? e.perguntaCriteriosPenteado6 : '',
            respostaCriteriosPenteado6: e !== undefined &&  e.respostaCriteriosPenteado6 !== undefined ? e.respostaCriteriosPenteado6 : '',
            comentarioCriteriosPenteado6: e !== undefined &&  e.comentarioCriteriosPenteado6 !== undefined ? e.comentarioCriteriosPenteado6 : '',
            perguntaCriteriosPenteado7: e !== undefined && e.perguntaCriteriosPenteado7 !== undefined ? e.perguntaCriteriosPenteado7 : '',
            respostaCriteriosPenteado7: e !== undefined &&  e.respostaCriteriosPenteado7 !== undefined ? e.respostaCriteriosPenteado7 : '',
            comentarioCriteriosPenteado7: e !== undefined &&  e.comentarioCriteriosPenteado7 !== undefined ? e.comentarioCriteriosPenteado7 : '', 
            perguntaCriteriosPenteado8: e !== undefined && e.perguntaCriteriosPenteado8 !== undefined ? e.perguntaCriteriosPenteado8 : '',
            respostaCriteriosPenteado8: e !== undefined &&  e.respostaCriteriosPenteado8 !== undefined ? e.respostaCriteriosPenteado8 : '',
            comentarioCriteriosPenteado8: e !== undefined &&  e.comentarioCriteriosPenteado8 !== undefined ? e.comentarioCriteriosPenteado8 : '',
            perguntaCriteriosPenteado9: e !== undefined && e.perguntaCriteriosPenteado9 !== undefined ? e.perguntaCriteriosPenteado9 : '',
            respostaCriteriosPenteado9: e !== undefined &&  e.respostaCriteriosPenteado9 !== undefined ? e.respostaCriteriosPenteado9 : '',
            comentarioCriteriosPenteado9: e !== undefined &&  e.comentarioCriteriosPenteado9 !== undefined ? e.comentarioCriteriosPenteado9 : '',
            perguntaCriteriosPenteado10: e !== undefined && e.perguntaCriteriosPenteado10 !== undefined ? e.perguntaCriteriosPenteado10 : '',
            respostaCriteriosPenteado10: e !== undefined &&  e.respostaCriteriosPenteado10 !== undefined ? e.respostaCriteriosPenteado10 : '',
            comentarioCriteriosPenteado10: e !== undefined &&  e.comentarioCriteriosPenteado10 !== undefined ? e.comentarioCriteriosPenteado10 : '',
            perguntaCriteriosPenteado11: e !== undefined && e.perguntaCriteriosPenteado11 !== undefined ? e.perguntaCriteriosPenteado11 : '',
            respostaCriteriosPenteado11: e !== undefined &&  e.respostaCriteriosPenteado11 !== undefined ? e.respostaCriteriosPenteado11 : '',
            comentarioCriteriosPenteado11: e !== undefined &&  e.comentarioCriteriosPenteado11 !== undefined ? e.comentarioCriteriosPenteado11 : '',
            perguntaCriteriosPenteado12: e !== undefined && e.perguntaCriteriosPenteado12 !== undefined ? e.perguntaCriteriosPenteado12 : '',
            respostaCriteriosPenteado12: e !== undefined &&  e.respostaCriteriosPenteado12 !== undefined ? e.respostaCriteriosPenteado12 : '',
            comentarioCriteriosPenteado12: e !== undefined &&  e.comentarioCriteriosPenteado12 !== undefined ? e.comentarioCriteriosPenteado12 : '',
            perguntaCriteriosPenteado13: e !== undefined && e.perguntaCriteriosPenteado13 !== undefined ? e.perguntaCriteriosPenteado13 : '',
            respostaCriteriosPenteado13: e !== undefined &&  e.respostaCriteriosPenteado13 !== undefined ? e.respostaCriteriosPenteado13 : '',
            comentarioCriteriosPenteado13: e !== undefined &&  e.comentarioCriteriosPenteado13 !== undefined ? e.comentarioCriteriosPenteado13 : '',
            perguntaCriteriosPenteado14: e !== undefined && e.perguntaCriteriosPenteado14 !== undefined ? e.perguntaCriteriosPenteado14 : '',
            respostaCriteriosPenteado14: e !== undefined &&  e.respostaCriteriosPenteado14 !== undefined ? e.respostaCriteriosPenteado14 : '',
            comentarioCriteriosPenteado14: e !== undefined &&  e.comentarioCriteriosPenteado14 !== undefined ? e.comentarioCriteriosPenteado14 : '',
            perguntaCriteriosPenteado15: e !== undefined && e.perguntaCriteriosPenteado15 !== undefined ? e.perguntaCriteriosPenteado15 : '',
            respostaCriteriosPenteado15: e !== undefined &&  e.respostaCriteriosPenteado15 !== undefined ? e.respostaCriteriosPenteado15 : '',
            comentarioCriteriosPenteado15: e !== undefined &&  e.comentarioCriteriosPenteado15 !== undefined ? e.comentarioCriteriosPenteado15 : '',
            perguntaCriteriosPenteado16: e !== undefined && e.perguntaCriteriosPenteado16 !== undefined ? e.perguntaCriteriosPenteado16 : '',
            respostaCriteriosPenteado16: e !== undefined &&  e.respostaCriteriosPenteado16 !== undefined ? e.respostaCriteriosPenteado16 : '',
            comentarioCriteriosPenteado16: e !== undefined &&  e.comentarioCriteriosPenteado16 !== undefined ? e.comentarioCriteriosPenteado16 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparCriteriosPenteado = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaCriteriosPenteado'+e.target.attributes.value.value]: '', ['comentarioCriteriosPenteado'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxCriteriosPenteado} />
            <label htmlFor={styles.checkboxCriteriosPenteado}>
                <h2 className={styles.titulo}>Criterios de Penteado</h2>
            </label>
            <fieldset id={styles.conteudoCriteriosPenteado}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="O mecanismo etiológico de aparecimento da doença encontra-se amparado na literatura técnica para nexo de causalidade com a exposição ao risco? (Critério técnico-científico)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>O mecanismo etiológico de aparecimento da doença encontra-se amparado na literatura técnica para nexo de causalidade com a exposição ao risco? (Critério técnico-científico)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado0 !== undefined ? formValues.respostaCriteriosPenteado0 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado0 !== undefined ? formValues.respostaCriteriosPenteado0 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado0 !== undefined ? formValues.respostaCriteriosPenteado0 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado0 !== undefined ? formValues.respostaCriteriosPenteado0 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado0"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado0 !== undefined ? formValues.comentarioCriteriosPenteado0 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Existem dados na literatura médica sobre a existência especifica de relação entre a doença e a atividade laboral? (Critério técnico-científico)">
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'1'}></i></button></th>
                        <th>Existem dados na literatura médica sobre a existência especifica de relação entre a doença e a atividade laboral? (Critério técnico-científico)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado1 !== undefined ? formValues.respostaCriteriosPenteado1 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado1 !== undefined ? formValues.respostaCriteriosPenteado1 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado1 !== undefined ? formValues.respostaCriteriosPenteado1 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado1 !== undefined ? formValues.respostaCriteriosPenteado1 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado1"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado1 !== undefined ? formValues.comentarioCriteriosPenteado1 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="O agente causador da doença está identificado claramente nas atividades laborais? (Critério de intensidade e tempo de exposição)">
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'2'}></i></button></th>
                        <th>O agente causador da doença está identificado claramente nas atividades laborais? (Critério de intensidade e tempo de exposição)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado2 !== undefined ? formValues.respostaCriteriosPenteado2 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado2 !== undefined ? formValues.respostaCriteriosPenteado2 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado2 !== undefined ? formValues.respostaCriteriosPenteado2 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado2 !== undefined ? formValues.respostaCriteriosPenteado2 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado2"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado2 !== undefined ? formValues.comentarioCriteriosPenteado2 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="A intensidade, ou seja, a forma e a magnitude com que ocorre a exposição na atividade laboral é passível de trazer dano à saúde? (Critério de intensidade e tempo de exposição)">
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'3'}></i></button></th>
                        <th>A intensidade, ou seja, a forma e a magnitude com que ocorre a exposição na atividade laboral é passível de trazer dano à saúde? (Critério de intensidade e tempo de exposição)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado3 !== undefined ? formValues.respostaCriteriosPenteado3 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado3 !== undefined ? formValues.respostaCriteriosPenteado3 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado3 !== undefined ? formValues.respostaCriteriosPenteado3 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado3 !== undefined ? formValues.respostaCriteriosPenteado3 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado3"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado3 !== undefined ? formValues.comentarioCriteriosPenteado3 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="O tempo de exposição diário ao risco informado é capaz de causar a doença? (Critério de intensidade e tempo de exposição)">
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'4'}></i></button></th>
                        <th>O tempo de exposição diário ao risco informado é capaz de causar a doença? (Critério de intensidade e tempo de exposição)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado4 !== undefined ? formValues.respostaCriteriosPenteado4 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado4 !== undefined ? formValues.respostaCriteriosPenteado4 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado4 !== undefined ? formValues.respostaCriteriosPenteado4 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado4 !== undefined ? formValues.respostaCriteriosPenteado4 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado4"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado4 !== undefined ? formValues.comentarioCriteriosPenteado4 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="O tempo compreendido entre o inicio da exposição ao risco e o inicio dos sintomas é suficiente para que a doença apareça e se desenvolva? (Critério de tempo de latência)">
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'5'}></i></button></th>
                        <th>O tempo compreendido entre o inicio da exposição ao risco e o inicio dos sintomas é suficiente para que a doença apareça e se desenvolva? (Critério de tempo de latência)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado5 !== undefined ? formValues.respostaCriteriosPenteado5 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado5 !== undefined ? formValues.respostaCriteriosPenteado5 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado5 !== undefined ? formValues.respostaCriteriosPenteado5 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado5 !== undefined ? formValues.respostaCriteriosPenteado5 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado5"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado5 !== undefined ? formValues.comentarioCriteriosPenteado5 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Foi comprovada a presença de algum tipo de incapacidade laborativa, total ou parcial, temporária ou definitiva, que tenha relação com o período contratual? (Critério de incapacidade)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'6'}></i></button></th>
                        <th>Foi comprovada a presença de algum tipo de incapacidade laborativa, total ou parcial, temporária ou definitiva, que tenha relação com o período contratual? (Critério de incapacidade)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado6 !== undefined ? formValues.respostaCriteriosPenteado6 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado6 !== undefined ? formValues.respostaCriteriosPenteado6 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado6 !== undefined ? formValues.respostaCriteriosPenteado6 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado6 !== undefined ? formValues.respostaCriteriosPenteado6 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado6"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado6 !== undefined ? formValues.comentarioCriteriosPenteado6 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Após o afastamento do risco, houve cura, melhora, ou no mínimo, estacionamento nas queixas clínicas? (Critério de afastamento do risco)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'7'}></i></button></th>
                        <th>Após o afastamento do risco, houve cura, melhora, ou no mínimo, estacionamento nas queixas clínicas? (Critério de afastamento do risco)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado7 !== undefined ? formValues.respostaCriteriosPenteado7 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado7 !== undefined ? formValues.respostaCriteriosPenteado7 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado7 !== undefined ? formValues.respostaCriteriosPenteado7 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado7 !== undefined ? formValues.respostaCriteriosPenteado7 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado7"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado7 !== undefined ? formValues.comentarioCriteriosPenteado7 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="O aparecimento das queixas clínicas guarda relação temporal direta/imediata com a exposição ao risco? Existe comprovação documental em prontuário médico? (Critério temporal)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'8'}></i></button></th>
                        <th>O aparecimento das queixas clínicas guarda relação temporal direta/imediata com a exposição ao risco? Existe comprovação documental em prontuário médico? (Critério temporal)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado8 !== undefined ? formValues.respostaCriteriosPenteado8 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado8 !== undefined ? formValues.respostaCriteriosPenteado8 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado8 !== undefined ? formValues.respostaCriteriosPenteado8 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado8 !== undefined ? formValues.respostaCriteriosPenteado8 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado8"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado8 !== undefined ? formValues.comentarioCriteriosPenteado8 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="O diagnóstico da patologia com exames complementares guarda relação temporal direta/imediata com a exposição ao risco? (Critério temporal)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'9'}></i></button></th>
                        <th>O diagnóstico da patologia com exames complementares guarda relação temporal direta/imediata com a exposição ao risco? (Critério temporal)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado9 !== undefined ? formValues.respostaCriteriosPenteado9 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado9 !== undefined ? formValues.respostaCriteriosPenteado9 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado9 !== undefined ? formValues.respostaCriteriosPenteado9 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado9 !== undefined ? formValues.respostaCriteriosPenteado9 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado9"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado9 !== undefined ? formValues.comentarioCriteriosPenteado9 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Encontra-se a doença elencada no Decreto no 3.048/99 e demais resoluções legais? (Critério legal)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'10'}></i></button></th>
                        <th>Encontra-se a doença elencada no Decreto no 3.048/99 e demais resoluções legais? (Critério legal)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado10 !== undefined ? formValues.respostaCriteriosPenteado10 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado10 !== undefined ? formValues.respostaCriteriosPenteado10 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado10 !== undefined ? formValues.respostaCriteriosPenteado10 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado10 !== undefined ? formValues.respostaCriteriosPenteado10 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado10"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado10 !== undefined ? formValues.comentarioCriteriosPenteado10 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="As atividades laborais realizadas anteriormente ao aparecimento da doença podem estar influenciando na gênese da doença? (Critério de condições pregressas)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'11'}></i></button></th>
                        <th>As atividades laborais realizadas anteriormente ao aparecimento da doença podem estar influenciando na gênese da doença? (Critério de condições pregressas)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado11"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado11 !== undefined ? formValues.respostaCriteriosPenteado11 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado11"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado11 !== undefined ? formValues.respostaCriteriosPenteado11 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado11"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado11 !== undefined ? formValues.respostaCriteriosPenteado11 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado11"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado11 !== undefined ? formValues.respostaCriteriosPenteado11 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado11"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado11 !== undefined ? formValues.comentarioCriteriosPenteado11 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Conforme registros médicos do estado anterior do paciente, existiam queixas pregressas à exposição ao risco? (Critério de condições pregressas)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'12'}></i></button></th>
                        <th>Conforme registros médicos do estado anterior do paciente, existiam queixas pregressas à exposição ao risco? (Critério de condições pregressas)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado12"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado12 !== undefined ? formValues.respostaCriteriosPenteado12 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado12"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado12 !== undefined ? formValues.respostaCriteriosPenteado12 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado12"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado12 !== undefined ? formValues.respostaCriteriosPenteado12 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado12"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado12 !== undefined ? formValues.respostaCriteriosPenteado12 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado12"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado12 !== undefined ? formValues.comentarioCriteriosPenteado12 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Existe coerência clinica entre sintomas, diagnóstico e tratamentos? (Critério de coerência clinica)"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'13'}></i></button></th>
                        <th>Existe coerência clinica entre sintomas, diagnóstico e tratamentos? (Critério de coerência clinica)</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado13"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado13 !== undefined ? formValues.respostaCriteriosPenteado13 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado13"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado13 !== undefined ? formValues.respostaCriteriosPenteado13 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado13"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado13 !== undefined ? formValues.respostaCriteriosPenteado13 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado13"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado13 !== undefined ? formValues.respostaCriteriosPenteado13 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado13"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado13 !== undefined ? formValues.comentarioCriteriosPenteado13 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Foram constatadas a presença de outras causas para a gênese da doença?"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'14'}></i></button></th>
                        <th>Foram constatadas a presença de outras causas para a gênese da doença?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado14"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado14 !== undefined ? formValues.respostaCriteriosPenteado14 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado14"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado14 !== undefined ? formValues.respostaCriteriosPenteado14 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado14"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado14 !== undefined ? formValues.respostaCriteriosPenteado14 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado14"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado14 !== undefined ? formValues.respostaCriteriosPenteado14 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado14"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado14 !== undefined ? formValues.comentarioCriteriosPenteado14 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Existindo mais de uma causa para a doença a e analisado a possibilidade de causa adequada, causa concorrente ou concausa?"> 
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'15'}></i></button></th>
                        <th>Existindo mais de uma causa para a doença a e analisado a possibilidade de causa adequada, causa concorrente ou concausa?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosPenteado15"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado15 !== undefined ? formValues.respostaCriteriosPenteado15 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosPenteado15"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado15 !== undefined ? formValues.respostaCriteriosPenteado15 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosPenteado15"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado15 !== undefined ? formValues.respostaCriteriosPenteado15 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosPenteado15"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado15 !== undefined ? formValues.respostaCriteriosPenteado15 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado15"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado15 !== undefined ? formValues.comentarioCriteriosPenteado15 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="conclusaoCriteriosPenteado">
                        <th><button onClick={handleLimparCriteriosPenteado}><i className="fas fa-trash perigo" value={'16'}></i></button></th>
                        <th>conclusaoCriteriosPenteado</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Não é plausível o nexo causal"
                                    name="respostaCriteriosPenteado16"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado16 !== undefined ? formValues.respostaCriteriosPenteado16 === 'Não é plausível o nexo causal' : ''}
                                />
                                    Não é plausível o nexo causal
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="É duvidoso o nexo causal"
                                    name="respostaCriteriosPenteado16"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosPenteado16 !== undefined ? formValues.respostaCriteriosPenteado16 === 'É duvidoso o nexo causal' : ''}
                                />
                                    É duvidoso o nexo causal
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosPenteado16"
                                placeholder="Comentários do criterio de Penteado"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosPenteado16 !== undefined ? formValues.comentarioCriteriosPenteado16 : ''}
                            />
                        </th>
                    </tr>                     
                </tbody>
            </table>
            <div>
            <button onClick={adicionarCriteriosPenteado}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormCriteriosPenteado}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados dos Critérios de Penteado</legend>
            {
            criteriosPenteado.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            {/* <th>Ação</th> */}
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th>Comentário</th>
                        </tr>
                    </thead>
                        {criteriosPenteado.length > 0 && criteriosPenteado.map(({ 
                            perguntaCriteriosPenteado0,
                            respostaCriteriosPenteado0,
                            comentarioCriteriosPenteado0,
                            perguntaCriteriosPenteado1,
                            respostaCriteriosPenteado1,
                            comentarioCriteriosPenteado1,
                            perguntaCriteriosPenteado2,
                            respostaCriteriosPenteado2,
                            comentarioCriteriosPenteado2,
                            perguntaCriteriosPenteado3,
                            respostaCriteriosPenteado3,
                            comentarioCriteriosPenteado3,
                            perguntaCriteriosPenteado4,
                            respostaCriteriosPenteado4,
                            comentarioCriteriosPenteado4,
                            perguntaCriteriosPenteado5,
                            respostaCriteriosPenteado5,
                            comentarioCriteriosPenteado5,
                            perguntaCriteriosPenteado6,
                            respostaCriteriosPenteado6,
                            comentarioCriteriosPenteado6,
                            perguntaCriteriosPenteado7,
                            respostaCriteriosPenteado7,
                            comentarioCriteriosPenteado7,
                            perguntaCriteriosPenteado8,
                            respostaCriteriosPenteado8,
                            comentarioCriteriosPenteado8,
                            perguntaCriteriosPenteado9,
                            respostaCriteriosPenteado9,
                            comentarioCriteriosPenteado9,
                            perguntaCriteriosPenteado10,
                            respostaCriteriosPenteado10,
                            comentarioCriteriosPenteado10,
                            perguntaCriteriosPenteado11,
                            respostaCriteriosPenteado11,
                            comentarioCriteriosPenteado11,
                            perguntaCriteriosPenteado12,
                            respostaCriteriosPenteado12,
                            comentarioCriteriosPenteado12,
                            perguntaCriteriosPenteado13,
                            respostaCriteriosPenteado13,
                            comentarioCriteriosPenteado13,
                            perguntaCriteriosPenteado14,
                            respostaCriteriosPenteado14,
                            comentarioCriteriosPenteado14,
                            perguntaCriteriosPenteado15,
                            respostaCriteriosPenteado15,
                            comentarioCriteriosPenteado15,
                            perguntaCriteriosPenteado16,
                            respostaCriteriosPenteado16,
                            comentarioCriteriosPenteado16, 
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaCriteriosPenteado0}</th>
                                <th>{respostaCriteriosPenteado0}</th>
                                <th>{comentarioCriteriosPenteado0}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado1}</th>
                                <th>{respostaCriteriosPenteado1}</th>
                                <th>{comentarioCriteriosPenteado1}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado2}</th>
                                <th>{respostaCriteriosPenteado2}</th>
                                <th>{comentarioCriteriosPenteado2}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado3}</th>
                                <th>{respostaCriteriosPenteado3}</th>
                                <th>{comentarioCriteriosPenteado3}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado4}</th>
                                <th>{respostaCriteriosPenteado4}</th>
                                <th>{comentarioCriteriosPenteado4}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado5}</th>
                                <th>{respostaCriteriosPenteado5}</th>
                                <th>{comentarioCriteriosPenteado5}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado6}</th>
                                <th>{respostaCriteriosPenteado6}</th>
                                <th>{comentarioCriteriosPenteado6}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado7}</th>
                                <th>{respostaCriteriosPenteado7}</th>
                                <th>{comentarioCriteriosPenteado7}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado8}</th>
                                <th>{respostaCriteriosPenteado8}</th>
                                <th>{comentarioCriteriosPenteado8}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado9}</th>
                                <th>{respostaCriteriosPenteado9}</th>
                                <th>{comentarioCriteriosPenteado9}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado10}</th>
                                <th>{respostaCriteriosPenteado10}</th>
                                <th>{comentarioCriteriosPenteado10}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado11}</th>
                                <th>{respostaCriteriosPenteado11}</th>
                                <th>{comentarioCriteriosPenteado11}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado12}</th>
                                <th>{respostaCriteriosPenteado12}</th>
                                <th>{comentarioCriteriosPenteado12}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado13}</th>
                                <th>{respostaCriteriosPenteado13}</th>
                                <th>{comentarioCriteriosPenteado13}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado14}</th>
                                <th>{respostaCriteriosPenteado14}</th>
                                <th>{comentarioCriteriosPenteado14}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado15}</th>
                                <th>{respostaCriteriosPenteado15}</th>
                                <th>{comentarioCriteriosPenteado15}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosPenteado16}</th>
                                <th>{respostaCriteriosPenteado16}</th>
                                <th>{comentarioCriteriosPenteado16}</th>
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirCriteriosPenteado}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}