import React, { useState, useEffect } from "react";

export const DadosTrabEmpresa = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log(
      "***data",
      data,
      "***formValues",
      formValues,
      "***newValue",
      newValue
    );
  };

  const [trabEmpresa, setTrabEmpresa] = useState([]);

  const adicionarTrabEmpresa = () => {
    const arrTrabEmpresa = [];
    arrTrabEmpresa.push({
      //id: trabEmpresa !== undefined ? trabEmpresa.length + 1 : '',
      dataAdmissaoEmpresa:
        formValues.dataAdmissaoEmpresa !== undefined &&
        formValues.dataAdmissaoEmpresa.length === 10
          ? formValues.dataAdmissaoEmpresa
          : "",
      dataAdmissaoEmpresaAmericano:
        formValues.dataAdmissaoEmpresaAmericano !== undefined &&
        formValues.dataAdmissaoEmpresaAmericano.length === 10
          ? formValues.dataAdmissaoEmpresaAmericano
          : "",
      dataDemissaoEmpresa:
        formValues.dataDemissaoEmpresa !== undefined &&
        formValues.dataDemissaoEmpresa.length === 10
          ? formValues.dataDemissaoEmpresa
          : "",
      dataDemissaoEmpresaAmericano:
        formValues.dataDemissaoEmpresaAmericano !== undefined &&
        formValues.dataDemissaoEmpresaAmericano.length === 10
          ? formValues.dataDemissaoEmpresaAmericano
          : "",
      qtdeDiasTrabalhoEmpresa:
        formValues.qtdeDiasTrabalhoEmpresa !== undefined
          ? formValues.qtdeDiasTrabalhoEmpresa
          : "",
      detalheTrabEmpresa:
        formValues.detalheTrabEmpresa !== undefined
          ? formValues.detalheTrabEmpresa
          : "",
      ativLabEmpresa:
        formValues.ativLabEmpresa !== undefined
          ? formValues.ativLabEmpresa
          : "",
    });

    setTrabEmpresa([...trabEmpresa, ...arrTrabEmpresa]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _17_trabEmpresa: [
          ...trabEmpresa,
          {
            // id: trabEmpresa !== undefined ? trabEmpresa.length + 1 : '',
            dataAdmissaoEmpresa:
              formValues.dataAdmissaoEmpresa !== undefined &&
              formValues.dataAdmissaoEmpresa.length === 10
                ? formValues.dataAdmissaoEmpresa
                : "",
            dataAdmissaoEmpresaAmericano:
              formValues.dataAdmissaoEmpresaAmericano !== undefined &&
              formValues.dataAdmissaoEmpresaAmericano.length === 10
                ? formValues.dataAdmissaoEmpresaAmericano
                : "",
            dataDemissaoEmpresa:
              formValues.dataDemissaoEmpresa !== undefined &&
              formValues.dataDemissaoEmpresa.length === 10
                ? formValues.dataDemissaoEmpresa
                : "",
            dataDemissaoEmpresaAmericano:
              formValues.dataDemissaoEmpresaAmericano !== undefined &&
              formValues.dataDemissaoEmpresaAmericano.length === 10
                ? formValues.dataDemissaoEmpresaAmericano
                : "",
            qtdeDiasTrabalhoEmpresa:
              formValues.qtdeDiasTrabalhoEmpresa !== undefined
                ? formValues.qtdeDiasTrabalhoEmpresa
                : "",
            detalheTrabEmpresa:
              formValues.detalheTrabEmpresa !== undefined
                ? formValues.detalheTrabEmpresa
                : "",
            ativLabEmpresa:
              formValues.ativLabEmpresa !== undefined
                ? formValues.ativLabEmpresa
                : "",
          },
        ],
      })
    );
  };

  const excluirTrabEmpresa = (e) => {
    e.preventDefault();
    trabEmpresa.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        trabEmpresa.splice(i, 1);
        setTrabEmpresa([...trabEmpresa]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _17_trabEmpresa: trabEmpresa,
          })
        );
      }
      return null;
    });
  };

  const excluirTudoTrabEmpresa = (e) => {
    e.preventDefault();

    setTrabEmpresa([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _17_trabEmpresa: [],
      })
    );
  };

  const handleCarregarFormTrabEmpresa = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
      if (tudo._17_trabEmpresa && tudo._17_trabEmpresa.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._17_trabEmpresa !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._17_trabEmpresa !== null
            ? JSON.parse(localStorage.getItem("tudo"))._17_trabEmpresa
            : [];

        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            dataAdmissaoEmpresa:
              e !== undefined &&
              e.dataAdmissaoEmpresa !== undefined &&
              e.dataAdmissaoEmpresa !== ""
                ? e.dataAdmissaoEmpresa
                : "",

            dataAdmissaoEmpresaAmericano:
              e !== undefined &&
              e.dataAdmissaoEmpresaAmericano !== undefined &&
              e.dataAdmissaoEmpresaAmericano !== ""
                ? e.dataAdmissaoEmpresaAmericano
                : "",

            dataDemissaoEmpresa:
              e !== undefined &&
              e.dataDemissaoEmpresa !== undefined &&
              e.dataDemissaoEmpresa !== ""
                ? e.dataDemissaoEmpresa
                : "",

            dataDemissaoEmpresaAmericano:
              e !== undefined &&
              e.dataDemissaoEmpresaAmericano !== undefined &&
              e.dataDemissaoEmpresaAmericano !== ""
                ? e.dataDemissaoEmpresaAmericano
                : "",

            qtdeDiasTrabalhoEmpresa:
              e !== undefined &&
              e.qtdeDiasTrabalhoEmpresa !== undefined &&
              e.qtdeDiasTrabalhoEmpresa !== ""
                ? e.qtdeDiasTrabalhoEmpresa
                : "",

            detalheTrabEmpresa:
              e !== undefined &&
              e.detalheTrabEmpresa !== undefined &&
              e.detalheTrabEmpresa !== ""
                ? e.detalheTrabEmpresa
                : "",

            ativLabEmpresa:
              e !== undefined &&
              e.ativLabEmpresa !== undefined &&
              e.ativLabEmpresa !== ""
                ? e.ativLabEmpresa
                : "",
          })
        );
      }
    };

    const y = () => {
      if (tudo._17_trabEmpresa && tudo._17_trabEmpresa.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._17_trabEmpresa !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._17_trabEmpresa !== null
            ? JSON.parse(localStorage.getItem("tudo"))._17_trabEmpresa
            : [];

        //console.log('***',a)
        const arrTrabEmpresa = [];
        a.map((e) => {
          return arrTrabEmpresa.push(
            {
              dataAdmissaoEmpresa:
                e !== undefined &&
                e.dataAdmissaoEmpresa !== undefined &&
                e.dataAdmissaoEmpresa !== ""
                  ? e.dataAdmissaoEmpresa
                  : "",

              dataAdmissaoEmpresaAmericano:
                e !== undefined &&
                e.dataAdmissaoEmpresaAmericano !== undefined &&
                e.dataAdmissaoEmpresaAmericano !== ""
                  ? e.dataAdmissaoEmpresaAmericano
                  : "",

              dataDemissaoEmpresa:
                e !== undefined &&
                e.dataDemissaoEmpresa !== undefined &&
                e.dataDemissaoEmpresa !== ""
                  ? e.dataDemissaoEmpresa
                  : "",

              dataDemissaoEmpresaAmericano:
                e !== undefined &&
                e.dataDemissaoEmpresaAmericano !== undefined &&
                e.dataDemissaoEmpresaAmericano !== ""
                  ? e.dataDemissaoEmpresaAmericano
                  : "",

              qtdeDiasTrabalhoEmpresa:
                e !== undefined &&
                e.qtdeDiasTrabalhoEmpresa !== undefined &&
                e.qtdeDiasTrabalhoEmpresa !== ""
                  ? e.qtdeDiasTrabalhoEmpresa
                  : "",

              detalheTrabEmpresa:
                e !== undefined &&
                e.detalheTrabEmpresa !== undefined &&
                e.detalheTrabEmpresa !== ""
                  ? e.detalheTrabEmpresa
                  : "",

              ativLabEmpresa:
                e !== undefined &&
                e.ativLabEmpresa !== undefined &&
                e.ativLabEmpresa !== ""
                  ? e.ativLabEmpresa
                  : "",
            },
          );
        });
        setTrabEmpresa(arrTrabEmpresa);
      }
    };

    x();
    y();
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  useEffect(() => {
    if (
      formValues.dataAdmissaoEmpresaAmericano !== undefined &&
      formValues.dataAdmissaoEmpresaAmericano.length === 10
    ) {
      formValues.dataAdmissaoEmpresa =
        `${formValues.dataAdmissaoEmpresaAmericano.split("-")[2]}-${
          formValues.dataAdmissaoEmpresaAmericano.split("-")[1]
        }-${formValues.dataAdmissaoEmpresaAmericano.split("-")[0]}` || "";
    }
    if (
      formValues.dataDemissaoEmpresaAmericano !== undefined &&
      formValues.dataDemissaoEmpresaAmericano.length === 10
    ) {
      formValues.dataDemissaoEmpresa =
        `${formValues.dataDemissaoEmpresaAmericano.split("-")[2]}-${
          formValues.dataDemissaoEmpresaAmericano.split("-")[1]
        }-${formValues.dataDemissaoEmpresaAmericano.split("-")[0]}` || "";
    }
    if (
      formValues.dataAdmissaoEmpresaAmericano !== undefined &&
      formValues.dataAdmissaoEmpresaAmericano.length === 10 &&
      formValues.dataDemissaoEmpresaAmericano !== undefined &&
      formValues.dataDemissaoEmpresaAmericano.length === 10
    ) {
      formValues.qtdeDiasTrabalhoEmpresa = Math.ceil(
        (new Date(formValues.dataDemissaoEmpresaAmericano).getTime() -
          new Date(formValues.dataAdmissaoEmpresaAmericano).getTime()) /
          (1000 * 60 * 60 * 24) +
          1
      );
    }
  }, [formValues]);

  return (
    <>
     <fieldset>
        <legend>Dados do Trabalho na Empresa</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>Limpar Campo</th>
                <th>Informação</th>
              </tr>
            </thead>

            <tbody>
              <tr key="dataAdmissaoEmpresaAmericano">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"dataAdmissaoEmpresaAmericano"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                  <span>Data da admissão</span> 
                  <input
                    type="date"
                    name="dataAdmissaoEmpresaAmericano"
                    onChange={handleInputChange}
                    value={
                      formValues.dataAdmissaoEmpresaAmericano !== undefined
                        ? formValues.dataAdmissaoEmpresaAmericano
                        : ""
                    }
                  />
                  </div>
                  
                </th>
              </tr>

              <tr key="dataDemissaoEmpresaAmericano">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"dataDemissaoEmpresaAmericano"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                    <span>Data da demissão</span>
                    <input
                    type="date"
                    name="dataDemissaoEmpresaAmericano"
                    onChange={handleInputChange}
                    value={
                      formValues.dataDemissaoEmpresaAmericano !== undefined
                        ? formValues.dataDemissaoEmpresaAmericano
                        : ""
                    }
                  />
                  </div>
                  
                </th>
              </tr>

              <tr key="qtdeDiasTrabalhoEmpresa">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"qtdeDiasTrabalhoEmpresa"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                    <span>Tempo de empresa</span>
                    <input
                    disabled
                    type="text"
                    name="qtdeDiasTrabalhoEmpresa"
                    onChange={handleInputChange}
                    value={
                      formValues.qtdeDiasTrabalhoEmpresa !== undefined
                        ? formValues.qtdeDiasTrabalhoEmpresa
                        : ""
                    }
                  />
                  </div>
                  
                </th>
              </tr>

              <tr key="detalheTrabEmpresa">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"detalheTrabEmpresa"}
                    ></i>
                  </button>
                </th>
                <th>
                  <textarea
                    name="detalheTrabEmpresa"
                    rows="6"
                    cols="40"
                    placeholder="Descreva outras observacoes sobre a empresa"
                    onChange={handleInputChange}
                    value={
                      formValues.detalheTrabEmpresa !== undefined
                        ? formValues.detalheTrabEmpresa
                        : ""
                    }
                  />
                </th>
              </tr>

              <tr key="ativLabEmpresa">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"ativLabEmpresa"}
                    ></i>
                  </button>
                </th>
                <th>
                  <textarea
                    name="ativLabEmpresa"
                    rows="6"
                    cols="40"
                    placeholder="Descreva a atividade laboral na empresa"
                    onChange={handleInputChange}
                    value={
                      formValues.ativLabEmpresa !== undefined
                        ? formValues.ativLabEmpresa
                        : ""
                    }
                  />
                </th>
              </tr>
            </tbody>
          </table>
          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarTrabEmpresa}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormTrabEmpresa}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Dados registrados do trabalho na empresa</legend>
            {trabEmpresa.length === 0 ? 
              <p>Sem Dados registrados</p>
             : 
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {trabEmpresa.length > 0 &&
                    trabEmpresa.map(
                      (
                        {
                          dataAdmissaoEmpresa,
                          dataDemissaoEmpresa,
                          qtdeDiasTrabalhoEmpresa,
                          ativLabEmpresa,
                          detalheTrabEmpresa,
                        },
                        i
                      ) => {
                        return (
                          
                            <tbody key={i}>
                              <tr  >
                                <th>Registro número</th>
                                <th> {i + 1}</th>
                              </tr>
                              <tr >
                                <th>Admissão</th>
                                <th>{dataAdmissaoEmpresa}</th>
                              </tr>
                              <tr >
                                <th>Demissão</th>
                                <th>{dataDemissaoEmpresa}</th>
                              </tr>
                              <tr >
                                <th>Dias corridos</th>
                                <th>{qtdeDiasTrabalhoEmpresa}</th>
                              </tr>

                              <tr >
                                <th>Detalhes do trabalho</th>
                                <th>{detalheTrabEmpresa}</th>
                              </tr>
                              <tr >
                                <th>Atividades do trabalho</th>
                                <th>{ativLabEmpresa}</th>
                              </tr>
                              <tr >
                                <th>Ação</th>
                                <th >
                                  <button onClick={excluirTrabEmpresa}>
                                    <i
                                      className="fas fa-solid fa-trash perigo"
                                      value={i}
                                    ></i>
                                  </button>
                                </th>
                              </tr>
                            </tbody>
                          
                        );
                      }
                    )}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoTrabEmpresa}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoTrabEmpresa"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
