import React, { useState, useEffect } from 'react'
import styles from './BuscarApiEnd.module.css'

export const BuscarApiEnd = () => {

  const [dadosApi, setDadosApi] = useState({})

  const [formValues, setFormValues] = useState({})

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === 'checkbox'
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
}

  const chamarApi = async () => {

  if (formValues.cep !== undefined && formValues.cep.length === 8) {
    //console.log(formValues.cep.length)
    const resApi = await fetch(`https://brasilapi.com.br/api/cep/v1/${formValues.cep.replace(/\D/g, '')}`)
    const dados = await resApi.json()
    //console.log('***resApi***',resApi,'***dados***',dados)

    if (formValues.tipoEnd === 'Comercial') {
      if (resApi.status !== 200) {
        //alert(`Desculpe, estamos sem acesso aos dados ou o CEP é inválido!! status:${resApi.status}, ok:${resApi.ok}`)
        setDadosApi({
          statusApi: resApi.status !== undefined ? resApi.status : 'Sem informações de status da API',
          respostaApi : resApi.ok !== undefined ? resApi.ok : 'Sem informações da API',
          /* cep:'' */
        })
          localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _85_dadosApiEndCom: {}
            }))

      } else {

      setDadosApi({
        cep: formValues.cep !== undefined && formValues.cep.length === 8 ? formValues.cep.replace(/(\d)(\d{3})$/, "$1-$2") : 'CEP inválido',
        cidade: dados.city !== undefined ? dados.city : '',
        bairro: dados.neighborhood !== undefined ? dados.neighborhood : '',
        estado: dados.state !== undefined ? dados.state : '',
        logradouro: dados.street !== undefined ? dados.street : '',
        tipoEnd: formValues.tipoEnd !== undefined ? formValues.tipoEnd : '',
        statusApi: resApi.status !== undefined ? resApi.status : 'Sem informações de status da API',
        respostaApi : resApi.ok !== undefined ? resApi.ok : 'Sem informações da API',
      })

      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _85_dadosApiEndCom: {
          cep: formValues.cep !== undefined && formValues.cep.length === 8 ? formValues.cep.replace(/(\d)(\d{3})$/, "$1-$2") : '',
          cidade: dados.city !== undefined ? dados.city : '',
          bairro: dados.neighborhood !== undefined ? dados.neighborhood : '',
          estado: dados.state !== undefined ? dados.state : '',
          logradouro: dados.street !== undefined ? dados.street : '',
          tipoEnd: formValues.tipoEnd !== undefined ? formValues.tipoEnd : '',
        }
        }))

        console.log('***dadosApi***',dadosApi)
      }

    }

    if (formValues.tipoEnd === 'Residencial') {

      if (resApi.status !== 200) {
        //alert(`Desculpe, estamos sem acesso aos dados ou o CEP é inválido!! status:${resApi.status}, ok:${resApi.ok}`)
        setDadosApi({
          statusApi: resApi.status !== undefined ? resApi.status : 'Sem informações de status da API',
          respostaApi : resApi.ok !== undefined ? `A resposta da API foi ${resApi.ok}` : 'Sem informações da API',
          /* cep:'' */
        })
          localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _83_dadosApiEndRes: {}
            }))
      } else {
        setDadosApi({
          cep: formValues.cep !== undefined && formValues.cep.length === 8 ? formValues.cep.replace(/(\d)(\d{3})$/, "$1-$2") : 'CEP inválido',
          cidade: dados.city !== undefined ? dados.city : '',
          bairro: dados.neighborhood !== undefined ? dados.neighborhood : '',
          estado: dados.state !== undefined ? dados.state : '',
          logradouro: dados.street !== undefined ? dados.street : '',
          tipoEnd: formValues.tipoEnd !== undefined ? formValues.tipoEnd : '',
          statusApi: resApi.status !== undefined ? resApi.status : 'Sem informações de status da API',
          respostaApi : resApi.ok !== undefined ? resApi.ok : 'Sem informações da API',
        })
  
        localStorage.setItem('tudo', JSON.stringify({
          ...JSON.parse(localStorage.getItem('tudo')) || [],
          _83_dadosApiEndRes: {
            cep: formValues.cep !== undefined && formValues.cep.length === 8 ? formValues.cep.replace(/(\d)(\d{3})$/, "$1-$2") : '',
            cidade: dados.city !== undefined ? dados.city : '',
            bairro: dados.neighborhood !== undefined ? dados.neighborhood : '',
            estado: dados.state !== undefined ? dados.state : '',
            logradouro: dados.street !== undefined ? dados.street : '',
            tipoEnd: formValues.tipoEnd !== undefined ? formValues.tipoEnd : ''
          }
          }))
      }
        console.log('***dadosApi***',dadosApi)
    }
}  else {
  setDadosApi({cep:'CEP inválido!'})
} 
}

  const excluirDados = (e) => {
    e.preventDefault()
        
  if (e.target.offsetParent
      .nextElementSibling
      .innerText === 'Comercial') {
          setDadosApi({})
          localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _85_dadosApiEndCom: {}
            }))
        }
         
        if (e.target.offsetParent
            .nextElementSibling
            .innerText === 'Residencial') {
          setDadosApi({})
          localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _83_dadosApiEndRes: {}
            }))
        } 
        
        if (e.target.offsetParent
          .nextElementSibling
          .nextElementSibling
          .nextElementSibling
          .nextElementSibling
          .nextElementSibling
          .nextElementSibling
          .nextElementSibling
          .innerText === dadosApi.statusApi) {
          setDadosApi({})
        }
      return null
}

  useEffect(() => {

    if (formValues.cep !== undefined) {
      //console.log(formValues.cep.length)
      formValues.cep = formValues.cep.replace(/\D/g, "")

      if (formValues.cep.length === 8) {
        formValues.cep = formValues.cep
          .replace(/\D/g, "")
      }
        //console.log(formValues.cep.length)  
    } 

    /* if (dadosApi.tipoEnd === 'Comercial') {
      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _85_dadosApiEndCom: dadosApi
        }))
    } else {
      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _85_dadosApiEndCom: {}
        }))
    }

    if (dadosApi.tipoEnd === 'Residencial') {
      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _83_dadosApiEndRes: dadosApi
        }))
    } else {
      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _83_dadosApiEndRes: {}
        }))
    } */

  }, [/* dadosApi, */ formValues])  
  //console.log(dadosApi.logradouro, dadosApi) 
  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxBuscarApiEnd} />
        <label htmlFor={styles.checkboxBuscarApiEnd}>
          <h2 className={styles.titulo}>Buscar Api End</h2>
        </label>
        <fieldset id={styles.conteudoBuscarApiEnd}>
          <table className={styles.table}></table>
          <fieldset>
            <legend>Busca CEP</legend>
            <input
              type="text"
              name="cep"
              size="8"
              minLength="8"
              maxLength="8"
              placeholder="Digite o CEP "
              onChange={handleInputChange}
              value={formValues.cep !== undefined ? formValues.cep.replace(/\D/g, '') : ''}
            />
            <select
              name="tipoEnd"
              className="tipoEnd"
              onChange={handleInputChange}
              value={formValues.tipoEnd !== undefined ? formValues.tipoEnd : ''}
            >
              <option defaultValue=''>Selecione tipo do Endereço</option>
              <option value='Residencial'>Endereço Residencial</option>
              <option value='Comercial'>Endereço Comercial</option>
            </select>
            <button onClick={chamarApi}>Buscar CEP´s</button>
            <fieldset>
              <legend></legend>
              {dadosApi.statusApi !== 200 && !dadosApi.tipoEnd ? <p>Erro {dadosApi.statusApi} ou sem o tipo do endereço, preencha manualmente</p> : <table>
                <thead>
                  <tr>
                    <th>Ação</th>
                    <th>Tipo endereço</th>
                    <th>Logradouro</th>
                    {/* <th>Número</th>
                    <th>Complemento</th> */}
                    <th>Bairro</th>
                    <th>Cidade</th>
                    <th>Estado</th>
                    <th>CEP</th>
                    {/* <th>Coordenadas</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {[dadosApi].length > 0 && [dadosApi].map(({
                    tipoEnd,
                    /* id, */
                    logradouro,
                    /* numero,
                    complemento, */
                    bairro,
                    cidade,
                    estado,
                    cep,
                    statusApi,
                    respostaApi,
                    /* coordenadas */
                  }, i) => {

                    return (
                      <tr key={i}>
                        <th><button onClick={excluirDados}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                        <th>{tipoEnd}</th>
                        <th>{logradouro}</th>
                        {/* <th>{numero}</th>
                        <th>{complemento}</th> */}
                        <th>{bairro}</th>
                        <th>{cidade}</th>
                        <th>{estado}</th>
                        <th>{cep}</th>
                        {/* <th>
                           {[coordenadas].length === 0 ? <p>Não registrado</p> : [coordenadas].map(({ latitude, longitude }, i) => {
                            return (
                              <div key={i}>
                                <p>Latitude: {latitude}</p>
                                <p>Longitude: {longitude}</p>
                              </div>
                            )
                          })
                          } 
                        </th> */}
                        <th>{statusApi} {/* {respostaApi} */}</th>
                      </tr>)
                  })}
                </tbody>
              </table>}
            </fieldset>
            </fieldset>    
          </fieldset>  
      </fieldset>
    </>)
}
