import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const CheckoutAssinatura = () => {
  const [url, setUrl] = useState({});

  useEffect(() => {
    const carregaSessionStorage = () => {
      const sessionCheckout = JSON.parse(
        sessionStorage.getItem("@CheckoutAssinatura")
      );
      sessionCheckout
        ? setUrl(sessionCheckout)
        : console.log("sessionCheckout nao encontrado", sessionCheckout);
    };
    carregaSessionStorage();
  }, []);

  return (
    
      
        url ? (
          <div style={{display:'flex', justifyContent: 'center',
            alignItems: 'center'}}>
            <Link to={url ? url.checkoutUrl : ""}
              target="_blank"
              referrerPolicy='same-origin'
              style={{ padding:'10px', margin:'10px',  textDecoration:'none'}}
            >
              Acesso ao checkout do usuário
            </Link>
            <Link to="/Home" referrerPolicy='same-origin' style={{ padding:'10px', margin:'10px', textDecoration:'none'}}>Voltar para a Home</Link>
          </div>
        ) : (
          <div style={{display:'flex', justifyContent: 'center',
            alignItems: 'center'}}>   
            <Link to="/Home" referrerPolicy='same-origin' style={{ padding:'10px', margin:'10px', textDecoration:'none'}}>Voltar para a Home</Link>
          </div>
        )
        
  );
};
