import React, {useState, useEffect,} from 'react'

export const DadosInssPericia = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ inssPericia, setInssPericia] = useState([])
    
    const adicionarInssPericia = () => {

            const arrInssPericia = []
            arrInssPericia.push({
                //id: inssPericia !== undefined ? inssPericia.length + 1 : '',
                idPericiaInss: formValues.idPericiaInss !== undefined ? formValues.idPericiaInss : '',
                dataPericiaInss: formValues.dataPericiaInss !== undefined && formValues.dataPericiaInss.length === 10 ? formValues.dataPericiaInss : '',
                dataPericiaInssAmericano: formValues.dataPericiaInssAmericano !== undefined && formValues.dataPericiaInssAmericano.length === 10 ? formValues.dataPericiaInssAmericano : '',
                detalhesPericiaInss: formValues.detalhesPericiaInss !== undefined ? formValues.detalhesPericiaInss : '',
                conclusaoPericiaInss: formValues.conclusaoPericiaInss !== undefined ? formValues.conclusaoPericiaInss : '',
                viaJudicialInss: formValues.viaJudicialInss !== undefined ? formValues.viaJudicialInss : '',
            })
            setInssPericia([...inssPericia, ...arrInssPericia])

            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _13_inssPericia: [...inssPericia,{
                    //id: inssPericia !== undefined ? inssPericia.length + 1 : '',
                    idPericiaInss: formValues.idPericiaInss !== undefined ? formValues.idPericiaInss : '',
                    dataPericiaInss: formValues.dataPericiaInss !== undefined && formValues.dataPericiaInss.length === 10 ? formValues.dataPericiaInss : '',
                    dataPericiaInssAmericano: formValues.dataPericiaInssAmericano !== undefined && formValues.dataPericiaInssAmericano.length === 10 ? formValues.dataPericiaInssAmericano : '',
                    detalhesPericiaInss: formValues.detalhesPericiaInss !== undefined ? formValues.detalhesPericiaInss : '',
                    conclusaoPericiaInss: formValues.conclusaoPericiaInss !== undefined ? formValues.conclusaoPericiaInss : '',
                    viaJudicialInss: formValues.viaJudicialInss !== undefined ? formValues.viaJudicialInss : '',
                }]
          }))
        
    }

    const excluirInssPericia = (e) => {

        e.preventDefault()
        inssPericia.filter((
            el, i
        )=>{         
            if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                        
                inssPericia.splice(i, 1)
                setInssPericia([...inssPericia])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _13_inssPericia: inssPericia
              }))
            }    
            return null
        })  
    }

    const excluirTudoInssPericia = (e) => {
        e.preventDefault();
       // console.log(e.target.attributes.value.value)

          setInssPericia([])
  
           localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _13_inssPericia: []
            })
          ); 
      }
  
      const handleCarregarFormInssPericia = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._13_inssPericia && tudo._13_inssPericia.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._13_inssPericia !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._13_inssPericia !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._13_inssPericia : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              idPericiaInss: e !== undefined && e.idPericiaInss !== undefined && e.idPericiaInss !== ""
            ? e.idPericiaInss
            : "",

            dataPericiaInss: e !== undefined && e.dataPericiaInss !== undefined && e.dataPericiaInss !== ""
            ? e.dataPericiaInss
            : "",
            
            dataPericiaInssAmericano: e !== undefined && e.dataPericiaInssAmericano !== undefined && e.dataPericiaInssAmericano !== ''
            ? e.dataPericiaInssAmericano
            : "",
            
            detalhesPericiaInss: e !== undefined && e.detalhesPericiaInss !== undefined && e.detalhesPericiaInss !== ""
            ? e.detalhesPericiaInss
            : "",
  
            conclusaoPericiaInss: e !== undefined && e.conclusaoPericiaInss !== undefined && e.conclusaoPericiaInss !== ""
            ? e.conclusaoPericiaInss
            : "",
  
            viaJudicialInss: e !== undefined && e.viaJudicialInss !== undefined &&
            e.viaJudicialInss !== ''
              ? e.viaJudicialInss
              : "",
  
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._13_inssPericia && tudo._13_inssPericia.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._13_inssPericia !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._13_inssPericia !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._13_inssPericia : []
      
              //console.log('***',a)
              const arrInssPericia = []
          a.map((e)=> {
  
          return  arrInssPericia.push({
                idPericiaInss: e !== undefined && e.idPericiaInss !== undefined && e.idPericiaInss !== ""
                ? e.idPericiaInss
                : "",
    
                dataPericiaInss: e !== undefined && e.dataPericiaInss !== undefined && e.dataPericiaInss !== ""
                ? e.dataPericiaInss
                : "",
                
                dataPericiaInssAmericano: e !== undefined && e.dataPericiaInssAmericano !== undefined && e.dataPericiaInssAmericano !== ''
                ? e.dataPericiaInssAmericano
                : "",
                
                detalhesPericiaInss: e !== undefined && e.detalhesPericiaInss !== undefined && e.detalhesPericiaInss !== ""
                ? e.detalhesPericiaInss
                : "",
      
                conclusaoPericiaInss: e !== undefined && e.conclusaoPericiaInss !== undefined && e.conclusaoPericiaInss !== ""
                ? e.conclusaoPericiaInss
                : "",
      
                viaJudicialInss: e !== undefined && e.viaJudicialInss !== undefined &&
                e.viaJudicialInss !== ''
                  ? e.viaJudicialInss
                  : "",
        }) 
    
    }
      )  

      setInssPericia(arrInssPericia)
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }

    useEffect(() => {
        
        if (formValues.dataPericiaInssAmericano !== undefined &&
            formValues.dataPericiaInssAmericano.length === 10) {
            formValues.dataPericiaInss =`${formValues.dataPericiaInssAmericano.split('-')[2]}-${formValues.dataPericiaInssAmericano.split('-')[1]}-${formValues.dataPericiaInssAmericano.split('-')[0]}` || ''
        }
        }, [
        formValues]
    )

    return (
      <>
        <fieldset>
        <legend>Dados Relativos a Perícias do Inss</legend>
        <div >
        <fieldset >
          <table >
              <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>

              <tbody>
                <tr key="idPericiaInss">
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idPericiaInss"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <input
                      type="text"
                      name="idPericiaInss"
                      maxLength="22"
                      className="capturaIdPericiaInss"
                      placeholder="Id - página"
                      onChange={handleInputChange}
                      value={
                        formValues.idPericiaInss !== undefined
                          ? formValues.idPericiaInss
                          : ""
                      }
                    />
                  </th>
                </tr>

                <tr key="dataPericiaInssAmericano">
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"dataPericiaInssAmericano"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div>
                    <span>Data da perícia</span>
                    <input
                      type="date"
                      name="dataPericiaInssAmericano"
                      onChange={handleInputChange}
                      value={
                        formValues.dataPericiaInssAmericano !== undefined
                          ? formValues.dataPericiaInssAmericano
                          : ""
                      }
                    />
                    </div>
                    
                  </th>
                </tr>

                <tr key="detalhesPericiaInss">
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"detalhesPericiaInss"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <textarea
                      name="detalhesPericiaInss"
                      rows="6"
                      cols="40"
                      placeholder="Insira aqui os detalhes da pericia no INSS..."
                      onChange={handleInputChange}
                      value={
                        formValues.detalhesPericiaInss !== undefined
                          ? formValues.detalhesPericiaInss
                          : ""
                      }
                    />
                  </th>
                </tr>

                <tr key="conclusaoPericiaInss">
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"conclusaoPericiaInss"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div className="radios">
                      <span>Conclusão</span>
                      <label>
                        <input
                          type="radio"
                          value="Existe incapacidade laborativa"
                          name="conclusaoPericiaInss"
                          onChange={handleInputChange}
                          checked={
                            formValues.conclusaoPericiaInss ===
                            "Existe incapacidade laborativa"
                          }
                        />
                        Existe incapacidade laborativa
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Existiu incapacidade laborativa"
                          name="conclusaoPericiaInss"
                          onChange={handleInputChange}
                          checked={
                            formValues.conclusaoPericiaInss ===
                            "Existiu incapacidade laborativa"
                          }
                        />
                        Existiu incapacidade laborativa
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Não existe incapacidade laborativa"
                          name="conclusaoPericiaInss"
                          onChange={handleInputChange}
                          checked={
                            formValues.conclusaoPericiaInss ===
                            "Não existe incapacidade laborativa"
                          }
                        />
                        Não existe incapacidade laborativa
                      </label>
                    </div>
                  </th>
                </tr>

                <tr key="viaJudiciaInss">
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"viaJudiciaInss"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div className="radios">
                      <span>Via Judicial?</span>
                      <label>
                        <input
                          type="radio"
                          value="Sim"
                          name="viaJudicialInss"
                          onChange={handleInputChange}
                          checked={formValues.viaJudicialInss === "Sim"}
                        />
                        Sim
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="Não"
                          name="viaJudicialInss"
                          onChange={handleInputChange}
                          checked={formValues.viaJudicialInss === "Não"}
                        />
                        Não
                      </label>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>

            <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarInssPericia}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormInssPericia}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>

            <fieldset>
              <legend>Dados registrados das Perícias no Inss</legend>
              {inssPericia.length === 0 ? 
                <p>Sem Dados registrados</p>
               : 
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Tópico</th>
                        <th>Informação</th>
                      </tr>
                    </thead>

                    {inssPericia.length > 0 &&
                      inssPericia.map(
                        (
                          {
                            idPericiaInss,
                            dataPericiaInss,
                            detalhesPericiaInss,
                            conclusaoPericiaInss,
                            viaJudicialInss,
                          },
                          i
                        ) => {
                          return (
                            
                              <tbody key={i}>
                                <tr >
                                  <th>Registro número</th>
                                  <th> {i + 1}</th>
                                </tr>
                                <tr>
                                  <th>Id</th>
                                  <th>{idPericiaInss}</th>
                                </tr>

                                <tr>
                                  <th>Data da perícia</th>
                                  <th>{dataPericiaInss}</th>
                                </tr>

                                <tr>
                                  <th>Detalhes da perícia</th>
                                  <th>{detalhesPericiaInss}</th>
                                </tr>

                                <tr>
                                  <th>Conclusão</th>
                                  <th>{conclusaoPericiaInss}</th>
                                </tr>

                                <tr>
                                  <th>Via judicial?</th>
                                  <th>{viaJudicialInss}</th>
                                </tr>
                                <tr>
                                  <th>Ação</th>
                                  <th >
                                    <button onClick={excluirInssPericia}>
                                      <i
                                        className="fas fa-solid fa-trash perigo"
                                        value={i}
                                      ></i>
                                    </button>
                                  </th>
                                </tr>
                              </tbody>
                            
                          );
                        }
                      )}
                  </table>
                  <fieldset>
                    <legend>Ação</legend>
                    <table>
                      <thead>
                        <tr>
                          <th>Excluir todos os documentos?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr >
                          <th>
                            <button onClick={excluirTudoInssPericia}>
                              <i
                                className="fas fa-solid fa-trash perigo"
                                value={"excluirTudoInssPericia"}
                              ></i>
                            </button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </>
              }
            </fieldset>
          </fieldset>
      </div>  </fieldset>
      </>
    );
}
