import React from "react";
/* import styles from "../admin/Admin.module.css"; */

import { Admin1 } from "./admin1/Admin1";
import { Admin2, GravarDadosPerito, GravarDadosUsuario } from "./admin2/Admin2";
import { Admin3 } from "./admin3/Admin3";
import { Admin4 } from "./admin4/Admin4";
import { Outlet } from "react-router-dom";

export const Admin = () => {
  return (
    <>
      {/* <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxAdmin} />
        <label htmlFor={styles.checkboxAdmin}>
          <h2 className={styles.titulo}>Admin</h2>
        </label>
        <fieldset id={styles.conteudoAdmin}>
          <table className={styles.table}></table> */}
          <Outlet>
          <Admin1 />
          <Admin2 />
          <GravarDadosPerito />
          <GravarDadosUsuario />
          <Admin3 />
          <Admin4 />
          </Outlet>
          
       {/*  </fieldset>
      </fieldset> */}
    </>
  );
};
