import React, { useEffect, useState } from "react";

export const QuesitosEsclarecimento = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  };

  const [esclarecimentos, setEsclarecimentos] = useState([]);

  const adicionarEsclarecimentos = (e) => {
    e.preventDefault();

    const arrEsclarecimentos = [];

    arrEsclarecimentos.push({
      idEsclarecimentos0:
        formValues.idEsclarecimentos0 !== undefined
          ? formValues.idEsclarecimentos0
          : "",
      respostaEsclarecimentos0:
        formValues.respostaEsclarecimentos0 !== undefined
          ? formValues.respostaEsclarecimentos0
          : "",
      dataEntregaEsclarecimentos0:
        formValues.dataEntregaEsclarecimentos0 !== undefined &&
        formValues.dataEntregaEsclarecimentos0.length === 10
          ? formValues.dataEntregaEsclarecimentos0
          : "",
      dataEntregaAmericanoEsclarecimentos0:
        formValues.dataEntregaAmericanoEsclarecimentos0 !== undefined &&
        formValues.dataEntregaAmericanoEsclarecimentos0.length === 10
          ? formValues.dataEntregaAmericanoEsclarecimentos0
          : "",
      horaEntregaEsclarecimentos0:
        formValues.horaEntregaEsclarecimentos0 !== undefined
          ? formValues.horaEntregaEsclarecimentos0
          : "",
    });
    setEsclarecimentos([...arrEsclarecimentos]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _80_esclarecimentos: [
          {
            idEsclarecimentos0:
              formValues.idEsclarecimentos0 !== undefined
                ? formValues.idEsclarecimentos0
                : "",
            respostaEsclarecimentos0:
              formValues.respostaEsclarecimentos0 !== undefined
                ? formValues.respostaEsclarecimentos0
                : "",
            dataEntregaEsclarecimentos0:
              formValues.dataEntregaEsclarecimentos0 !== undefined &&
              formValues.dataEntregaEsclarecimentos0.length === 10
                ? formValues.dataEntregaEsclarecimentos0
                : "",
            dataEntregaAmericanoEsclarecimentos0:
              formValues.dataEntregaAmericanoEsclarecimentos0 !== undefined &&
              formValues.dataEntregaAmericanoEsclarecimentos0.length === 10
                ? formValues.dataEntregaAmericanoEsclarecimentos0
                : "",
            horaEntregaEsclarecimentos0:
              formValues.horaEntregaEsclarecimentos0 !== undefined &&
              formValues.horaEntregaEsclarecimentos0.length === 5
                ? formValues.horaEntregaEsclarecimentos0
                : "",
          },
        ],
      })
    );
  };

  const excluirEsclarecimentos = (e) => {
    e.preventDefault();
    esclarecimentos.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        esclarecimentos.splice(i, 1);
        setEsclarecimentos([...esclarecimentos]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _80_esclarecimentos: [...esclarecimentos],
          })
        );
      }
      return null;
    });
  };

  const excluirTudoEsclarecimentos = (e) => {
    e.preventDefault();

    setEsclarecimentos([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _80_esclarecimentos: [],
      })
    );
  };

  const handleCarregarFormEsclarecimentos = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));
    const x = () => {
      if (tudo._80_esclarecimentos && tudo._80_esclarecimentos.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._80_esclarecimentos !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._80_esclarecimentos !== null
            ? JSON.parse(localStorage.getItem("tudo"))._80_esclarecimentos
            : [];

        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            idEsclarecimentos0:
              e !== undefined && e.idEsclarecimentos0 !== undefined
                ? e.idEsclarecimentos0
                : "",
            respostaEsclarecimentos0:
              e !== undefined && e.respostaEsclarecimentos0 !== undefined
                ? e.respostaEsclarecimentos0
                : "",
            dataEntregaAmericanoEsclarecimentos0:
              e.dataEntregaAmericanoEsclarecimentos0 !== undefined &&
              e.dataEntregaAmericanoEsclarecimentos0.length === 10
                ? e.dataEntregaAmericanoEsclarecimentos0
                : "",
            dataEntregaEsclarecimentos0:
              e !== undefined && e.dataEntregaEsclarecimentos0 !== undefined
                ? e.dataEntregaEsclarecimentos0
                : "",
            horaEntregaEsclarecimentos0:
              e !== undefined && e.horaEntregaEsclarecimentos0 !== undefined
                ? e.horaEntregaEsclarecimentos0
                : "",
          })
        );
      }
    };

    const y = () => {
      if (tudo._80_esclarecimentos && tudo._80_esclarecimentos.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._80_esclarecimentos !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._80_esclarecimentos !== null
            ? JSON.parse(localStorage.getItem("tudo"))._80_esclarecimentos
            : [];

        //console.log('***',a)
        a.map((e) =>
          setEsclarecimentos([
            {
              idEsclarecimentos0:
                e !== undefined && e.idEsclarecimentos0 !== undefined
                  ? e.idEsclarecimentos0
                  : "",
              respostaEsclarecimentos0:
                e !== undefined && e.respostaEsclarecimentos0 !== undefined
                  ? e.respostaEsclarecimentos0
                  : "",
              dataEntregaAmericanoEsclarecimentos0:
                e.dataEntregaAmericanoEsclarecimentos0 !== undefined &&
                e.dataEntregaAmericanoEsclarecimentos0.length === 10
                  ? e.dataEntregaAmericanoEsclarecimentos0
                  : "",
              dataEntregaEsclarecimentos0:
                e !== undefined && e.dataEntregaEsclarecimentos0 !== undefined
                  ? e.dataEntregaEsclarecimentos0
                  : "",
              horaEntregaEsclarecimentos0:
                e !== undefined && e.horaEntregaEsclarecimentos0 !== undefined
                  ? e.horaEntregaEsclarecimentos0
                  : "",
            },
          ])
        );
      }
    };

    x();
    y();
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  useEffect(() => {
    if (
      formValues.dataEntregaAmericanoEsclarecimentos0 !== undefined &&
      formValues.dataEntregaAmericanoEsclarecimentos0.length === 10
    ) {
      //console.log(formValues.dataEntregaAmericanoEsclarecimentos0.lenght)
      formValues.dataEntregaEsclarecimentos0 =
        `${formValues.dataEntregaAmericanoEsclarecimentos0.split("-")[2]}-${
          formValues.dataEntregaAmericanoEsclarecimentos0.split("-")[1]
        }-${formValues.dataEntregaAmericanoEsclarecimentos0.split("-")[0]}` ||
        "";

      //console.log(formValues.dataEntregaAmericanoEsclarecimentos0.lenght)
    }
  }, [formValues]);

  return (
    <>
      <fieldset>
        <legend>Esclarecimentos</legend>
        <div>
          <fieldset>
            <table>
              <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idEsclarecimentos0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <input
                      name="idEsclarecimentos0"
                      placeholder="Id - página"
                      onChange={handleInputChange}
                      value={
                        formValues.idEsclarecimentos0 !== undefined
                          ? formValues.idEsclarecimentos0
                          : ""
                      }
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"respostaEsclarecimentos0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <textarea
                      name="respostaEsclarecimentos0"
                      cols="30"
                      rows="10"
                      placeholder="Digite o conteúdo aqui."
                      onChange={handleInputChange}
                      value={
                        formValues.respostaEsclarecimentos0 !== undefined
                          ? formValues.respostaEsclarecimentos0
                          : ""
                      }
                    />
                  </th>
                </tr>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"dataEntregaAmericanoEsclarecimentos0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div>
                      <span>Data da entrega</span>
                      <input
                        type="date"
                        size={8}
                        maxLength={8}
                        name="dataEntregaAmericanoEsclarecimentos0"
                        onChange={handleInputChange}
                        value={
                          formValues.dataEntregaAmericanoEsclarecimentos0 !==
                            undefined &&
                          formValues.dataEntregaAmericanoEsclarecimentos0
                            .length === 10
                            ? formValues.dataEntregaAmericanoEsclarecimentos0
                            : ""
                        }
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"horaEntregaEsclarecimentos0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div>
                      <span>Hora</span>
                      <input
                        type="time"
                        size={4}
                        maxLength={4}
                        name="horaEntregaEsclarecimentos0"
                        onChange={handleInputChange}
                        value={
                          formValues.horaEntregaEsclarecimentos0 !==
                            undefined &&
                          formValues.horaEntregaEsclarecimentos0.length === 5
                            ? formValues.horaEntregaEsclarecimentos0
                            : ""
                        }
                      />
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>

            <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <button onClick={adicionarEsclarecimentos}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormEsclarecimentos}>
                        Carregar formulário
                      </button>
                    </th>
                    <th>
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>

            <fieldset>
              <legend>Dados registrados dos quesitos suplementares</legend>
              {esclarecimentos.length === 0 ? (
                <p>Sem dados registrados</p>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Tópico</th>
                        <th>Informação</th>
                      </tr>
                    </thead>
                    {esclarecimentos.length > 0 &&
                      esclarecimentos.map(
                        (
                          {
                            idEsclarecimentos0,
                            respostaEsclarecimentos0,
                            dataEntregaEsclarecimentos0,
                            horaEntregaEsclarecimentos0,
                          },
                          i
                        ) => {
                          return (
                            <tbody key={i}>
                              <tr>
                                <th>Registro número</th>
                                <th> {i + 1}</th>
                              </tr>
                              <tr>
                                <th>Id</th>
                                <th>{idEsclarecimentos0}</th>
                              </tr>
                              <tr>
                                <th>Resposta</th>
                                <th>{respostaEsclarecimentos0}</th>
                              </tr>
                              <tr>
                                <th>Data da Entrega</th>
                                <th>{dataEntregaEsclarecimentos0}</th>
                              </tr>
                              <tr>
                                <th>Hora</th>
                                <th>{horaEntregaEsclarecimentos0}</th>
                              </tr>

                              <tr>
                                <th>Ação</th>
                                <th>
                                  <button onClick={excluirEsclarecimentos}>
                                    <i
                                      className="fas fa-solid fa-trash perigo"
                                      value={i}
                                    ></i>
                                  </button>
                                </th>
                              </tr>
                            </tbody>
                          );
                        }
                      )}
                  </table>
                  <fieldset>
                    <legend>Ação</legend>
                    <table>
                      <thead>
                        <tr>
                          <th>Excluir todos os documentos?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            <button onClick={excluirTudoEsclarecimentos}>
                              <i
                                className="fas fa-solid fa-trash perigo"
                                value={"excluirTudoEsclarecimentos"}
                              ></i>
                            </button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </>
              )}
            </fieldset>
          </fieldset>
        </div>{" "}
      </fieldset>
    </>
  );
};
