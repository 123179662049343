import React, {useState} from 'react'
import styles from '../classificacaoIncapacidadePenteado/ClassificacaoIncapacidadePenteado.module.css'

export const ClassificacaoIncapacidadePenteado = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ classificacaoIncapacidadePenteado, setClassificacaoIncapacidadePenteado] = useState([])
   
    const adicionarClassificacaoIncapacidadePenteado = (e) => {

        e.preventDefault()

        const arrClassificacaoIncapacidadePenteado = []
    
        arrClassificacaoIncapacidadePenteado.push({
          //  perguntaClassificacaoIncapacidadePenteado0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoIncapacidadePenteado0: formValues.respostaClassificacaoIncapacidadePenteado0 !== undefined ? formValues.respostaClassificacaoIncapacidadePenteado0 : '',
            comentarioClassificacaoIncapacidadePenteado0: formValues.comentarioClassificacaoIncapacidadePenteado0 !== undefined ? formValues.comentarioClassificacaoIncapacidadePenteado0 : '',     
        })
        setClassificacaoIncapacidadePenteado([...arrClassificacaoIncapacidadePenteado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _69_classificacaoIncapacidadePenteado: [{
           //     perguntaClassificacaoIncapacidadePenteado0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoIncapacidadePenteado0: formValues.respostaClassificacaoIncapacidadePenteado0 !== undefined ? formValues.respostaClassificacaoIncapacidadePenteado0 : '',
         comentarioClassificacaoIncapacidadePenteado0: formValues.comentarioClassificacaoIncapacidadePenteado0 !== undefined ? formValues.comentarioClassificacaoIncapacidadePenteado0 : '',
            }]
      }))
    }

    const excluirClassificacaoIncapacidadePenteado = (e) => {
        e.preventDefault()  

        setClassificacaoIncapacidadePenteado([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _69_classificacaoIncapacidadePenteado: []
              }))
    }

    const handleCarregarFormClassificacaoIncapacidadePenteado = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._69_classificacaoIncapacidadePenteado && tudo._69_classificacaoIncapacidadePenteado.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._69_classificacaoIncapacidadePenteado !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._69_classificacaoIncapacidadePenteado !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._69_classificacaoIncapacidadePenteado : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
   // perguntaClassificacaoIncapacidadePenteado0: e !== undefined && e.perguntaClassificacaoIncapacidadePenteado0 !== undefined ? e.perguntaClassificacaoIncapacidadePenteado0 : '',
            respostaClassificacaoIncapacidadePenteado0: e !== undefined &&  e.respostaClassificacaoIncapacidadePenteado0 !== undefined ? e.respostaClassificacaoIncapacidadePenteado0 : '',
            comentarioClassificacaoIncapacidadePenteado0: e !== undefined &&  e.comentarioClassificacaoIncapacidadePenteado0 !== undefined ? e.comentarioClassificacaoIncapacidadePenteado0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparClassificacaoIncapacidadePenteado = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaClassificacaoIncapacidadePenteado'+e.target.attributes.value.value]: '', ['comentarioClassificacaoIncapacidadePenteado'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxClassificacaoIncapacidadePenteado} />
            <label htmlFor={styles.checkboxClassificacaoIncapacidadePenteado}>
                <h2 className={styles.titulo}>Classificação de Incapacidade de Penteado</h2>
            </label>
            <fieldset id={styles.conteudoClassificacaoIncapacidadePenteado}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {/* <th>Pergunta</th> */}
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0"> 
                        <th><button onClick={handleLimparClassificacaoIncapacidadePenteado}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>
                        <select 
                                name="respostaClassificacaoIncapacidadePenteado0"
                                onChange={handleInputChange}
                                value={formValues.respostaClassificacaoIncapacidadePenteado0 !== undefined ? formValues.respostaClassificacaoIncapacidadePenteado0 : ''}
                            >
                                <option value="" defaultValue>Selecione:</option>
                                <option value="Não há incapacidade laboral || 0a: Não identificado deficiência funcional e por consequente não há tão pouco incapacidade laboral. || Significado: Não identificado deficiência funcional e por consequente não há tão pouco incapacidade laboral.">Não há incapacidade laboral || 0a: Não identificado deficiência funcional e por consequente não há tão pouco incapacidade laboral. || Significado: Não identificado deficiência funcional e por consequente não há tão pouco incapacidade laboral.</option>
                                <option value="Não há incapacidade laboral || 0b: Identificado deficiência funcional, que porém não traz repercussão para o exercício do ofício/profissão.">Não há incapacidade laboral || 0b: Identificado deficiência funcional, que porém não traz repercussão para o exercício do ofício/profissão.</option>
                                <option value="Não há incapacidade laboral || 0c: Identificado deficiência funcional, que porém não causará redução da incapacidade laborativa, desde que realizado o trabalho em condições ambientais e ergonômicas adequadas.">Não há incapacidade laboral || 0c: Identificado deficiência funcional, que porém não causará redução da incapacidade laborativa, desde que realizado o trabalho em condições ambientais e ergonômicas adequadas.</option>
                                <option value="Existe restrição parcial da capacidade laboral || 1a: Implica em necessidade de esforços suplementares ou acrescidos para realização da mesma atividade, ou ainda necessita de ajuda técnica para realizá-la. Tal situação enquadra a redução da capacidade laboral em valor equivalente ao déficit funcional identificado.">Existe restrição parcial da capacidade laboral || 1a: Implica em necessidade de esforços suplementares ou acrescidos para realização da mesma atividade, ou ainda necessita de ajuda técnica para realizá-la. Tal situação enquadra a redução da capacidade laboral em valor equivalente ao déficit funcional identificado.</option>
                                <option value="Existe restrição parcial da capacidade laboral || 1b: Pode ser reabilitado para uma atividade do mesmo nível de complexidade ou em funções compatíveis com sua formação profissional. Tal situação enquadra a redução da capacidade laboral em valor equivalente ao déficit funcional identificado.">Existe restrição parcial da capacidade laboral || 1b: Pode ser reabilitado para uma atividade do mesmo nível de complexidade ou em funções compatíveis com sua formação profissional. Tal situação enquadra a redução da capacidade laboral em valor equivalente ao déficit funcional identificado.</option>
                                <option value="Existe restrição total da capacidade laboral || 2a: As sequelas são impeditivas do exercício de atividade profissional mas podendo ser reabilitada para uma atividade de menor nível de complexidade. Tal situação enquadra a redução da capacidade laboral em 100% para o ofício/profissão.">Existe restrição total da capacidade laboral || 2a: As sequelas são impeditivas do exercício de atividade profissional mas podendo ser reabilitada para uma atividade de menor nível de complexidade. Tal situação enquadra a redução da capacidade laboral em 100% para o ofício/profissão.</option>
                                <option value="Existe restrição total da capacidade laboral || 2b: As sequelas são totalmente impeditivas do exercício de qualquer atividade profissional. Tal situação enquadra a redução da capacidade laboral em 100% para o ofício/profissão.">Existe restrição total da capacidade laboral || 2b: As sequelas são totalmente impeditivas do exercício de qualquer atividade profissional. Tal situação enquadra a redução da capacidade laboral em 100% para o ofício/profissão.</option>
                            </select> 
                            </th>
                        <th>
                        <textarea 
                            name="comentarioClassificacaoIncapacidadePenteado0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite seu comentário aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioClassificacaoIncapacidadePenteado0 !== undefined ? formValues.comentarioClassificacaoIncapacidadePenteado0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarClassificacaoIncapacidadePenteado}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormClassificacaoIncapacidadePenteado}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados da Classificação de Incapacidade de Penteado</legend>
            {
            classificacaoIncapacidadePenteado.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                           {/*  <th>Pergunta</th> */}
                            <th>Resposta</th>
                            <th>Comentário</th> 
                        </tr>
                    </thead>
                        {classificacaoIncapacidadePenteado.length > 0 && classificacaoIncapacidadePenteado.map(({ 
                           // perguntaClassificacaoIncapacidadePenteado0,
                            respostaClassificacaoIncapacidadePenteado0,
                            comentarioClassificacaoIncapacidadePenteado0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                               {/*  <th>{perguntaClassificacaoIncapacidadePenteado0}</th> */}
                                <th>{respostaClassificacaoIncapacidadePenteado0}</th>
                                 <th>{comentarioClassificacaoIncapacidadePenteado0}</th> 
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirClassificacaoIncapacidadePenteado}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}