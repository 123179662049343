export const DanoEsteticoClassSocFrancesaMedLegal = [
  {
    "classificacaoSocFrancesaMedLegal": "0.5",
    "caracteristicas": "Cicatriz de boa qualidade, baixa deformação visível ou luz.; - Prótese dentária em incisivo e canino com modificação da coloração.; - Ligeira claudicação intermitente."
  },
  {
    "classificacaoSocFrancesaMedLegal": "1",
    "caracteristicas": "Cicatriz de osteossíntese de boa qualidade, geralmente coberta por roupa.; - Cicatriz discreta em rosto.; - Boa cicatriz de laparotomia.; - Boa cicatriz de traqueotomia.; - Claudicação moderada ou permanente."
  },
  {
    "classificacaoSocFrancesaMedLegal": "1.5",
    "caracteristicas": "Deformação da pirâmide nasal devido a uma fratura dos ossos com deslocamento."
  },
  {
    "classificacaoSocFrancesaMedLegal": "2",
    "caracteristicas": "Cicatriz visível na face, sem ser de caráter desagradável.; - Moderada claudicação que necessitam o uso de bengalas.; - Perda de uma mama com plástica de boa qualidade e cicatriz pouco visível.; - Perda de um olho e com prótese de boa qualidade.; - Cicatriz de traqueostomia, aderida ao desenho profundo e com hiperpgmentação.; - Amputação de vários dedos ou cicatriz desagradável na mão.; - Aspecto de mão em garra devido a doença de Dupuytren."
  },
  {
    "classificacaoSocFrancesaMedLegal": "2.5",
    "caracteristicas": "Clara diminuição da mobilidade de um olho com deformação da aparência (deformação da pupila, anisocoria...); -Perda de um olho com prótese ocular pouco móvel."
  },
  {
    "classificacaoSocFrancesaMedLegal": "3",
    "caracteristicas": "Cicatriz na face com deformação na mímica, facilmente visível de aspecto desagradável.; - Grande dificuldade na marcha com necessidade de uso de duas bengalas ou um deambulador.; - Perda de uma mama, sem prótese ou com prótese de má qualidade, cicatriz de média qualidade.; -Amputação de uma perna, bem tolerada com prótese permitindo uma harmonia da marcha."
  },
  {
    "classificacaoSocFrancesaMedLegal": "3.5",
    "caracteristicas": "Amputação ao nível da coxa com prótese articulada, e a marcha é possível sem uma muleta.; - Paralisia facial.; - Perda de um olho com enucleação sem possibilidade de prótese.; - Traqueostomia persistente.; - Amputação de mão com prótese estética de boa qualidade."
  },
  {
    "classificacaoSocFrancesaMedLegal": "4",
    "caracteristicas": "Paraplégico em uma cadeira de rodas manual.; - Amputação ao nível do quadril, com importante de claudicação, precisando usar muletas."
  },
  {
    "classificacaoSocFrancesaMedLegal": "5",
    "caracteristicas": "Tetraplégico em cadeira de rodas elétrica; - Perda da mandíbula"
  },
  {
    "classificacaoSocFrancesaMedLegal": "6",
    "caracteristicas": "Tetraplégico com ventilação assistida por traqueostomia.; - Desfiguração."
  },
  {
    "classificacaoSocFrancesaMedLegal": "7",
    "caracteristicas": "Desfiguração monstruosa que geram inexequível repulsão habitual."
  }
]
