import React, { useState } from "react";

export const DocHonorarios = () => {
  const [doc, setDoc] = useState([
    {
      name: "Solicito ser intimado pela secretaria da vara quando for registrado os honorários periciais",
    },
    {
      name: "Preenchendo o reclamante os requisitos para o deferimento do benefício da justiça gratuita nos termos do art. 790, § 3º, da CLT, solicito a possibilidade da Secretaria requerer ao E. Tribunal Regional a antecipação dos honorários periciais pelo máximo autorizado atualmente nos termos dos Provimentos.",
    },
    {
      name: "A título de proposta de honorários, conforme determina o artigo 465 do NCPC, venho solicitar o valor de R$ (Valor total do honorário), tendo em vista o grau de zelo deste profissional, bem como, o trabalho desenvolvido e o tempo necessário à sua execução. A título de adiantamento, solicito ao magistrado autorizar o depósito de até 50% do honorário supra citado, conforme determina o § 4º deste mesmo artigo.",
    },
  ]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (name === "allSelected") {
      const tempDoc = doc.map((e) => {
        return { ...e, isChecked: checked };
      });
      setDoc(tempDoc);
    } else {
      const tempDoc = doc.map((e) => {
        return e.name === name ? { ...e, isChecked: checked } : e;
      });
      setDoc(tempDoc);
    }
  };

  const [docHonorarios, setDocHonorarios] = useState([]);

  const adicionarDocHonorarios = (e) => {
    e.preventDefault();

    const dadoSelecionado = [];

    doc.map(({ name, isChecked }) => {
      if (isChecked === true) {
        dadoSelecionado.push(name);
      }
      return null;
    });

    if (dadoSelecionado) {
      setDocHonorarios(dadoSelecionado);

      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _04_docHonorarios: dadoSelecionado,
        })
      );
    }
  };

  const excluirDocHonorarios = (e) => {
    e.preventDefault();
    docHonorarios.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        docHonorarios.splice(i, 1);
        setDocHonorarios([...docHonorarios]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _04_docHonorarios: docHonorarios,
          })
        );
      }
      return null;
    });
  };

  const personalizacaoDoUsuario = /* async  */ () => {
    /* const getRefs = (docs) => {
              return docs.map((doc) =>
                doc ? doc.ref : console.log(`Um erro ocorreu`)
              );
            };
            const getIds = (refs) => {
              return refs.map((ref) =>
                ref ? ref.id : console.log(`Um erro ocorreu`)
              );
            }; */

    const docPersonalizado = JSON.parse(
      localStorage.getItem("tudo")
    )._07_docSecVara; /* getIds(getRefs((await getDocs(collection(db, "users"))).docs)) */
    if (docPersonalizado && docPersonalizado.length > 0) {
      let z = [];
      docPersonalizado.map((e) => {
        z.push({
          name: e.toString(),
        });
        return z;
      });
      setDoc(
        [
          {
            name: "Solicito ser intimado pela secretaria da vara quando for registrado os honorários periciais",
          },
          {
            name: "Preenchendo o reclamante os requisitos para o deferimento do benefício da justiça gratuita nos termos do art. 790, § 3º, da CLT, solicito a possibilidade da Secretaria requerer ao E. Tribunal Regional a antecipação dos honorários periciais pelo máximo autorizado atualmente nos termos dos Provimentos.",
          },
          {
            name: "A título de proposta de honorários, conforme determina o artigo 465 do NCPC, venho solicitar o valor de R$ (Valor total do honorário), tendo em vista o grau de zelo deste profissional, bem como, o trabalho desenvolvido e o tempo necessário à sua execução. A título de adiantamento, solicito ao magistrado autorizar o depósito de até 50% do honorário supra citado, conforme determina o § 4º deste mesmo artigo.",
          },
        ].concat(z)
      );
    }
  };

  const excluirTudoDocHonorarios = (e) => {
    e.preventDefault();

    setDocHonorarios([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _04_docHonorarios: [],
      })
    );
  };

  const handleCarregarFormDocHonorarios = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));
    if (tudo._04_docHonorarios && tudo._04_docHonorarios.length > 0) {
      const a =
        JSON.parse(localStorage.getItem("tudo"))._04_docHonorarios !==
          undefined ||
        JSON.parse(localStorage.getItem("tudo"))._04_docHonorarios !== null
          ? JSON.parse(localStorage.getItem("tudo"))._04_docHonorarios
          : [];

      setDocHonorarios(a);
    }
  };

  return (
    <>
      <fieldset>
      <legend>Documentos sobre Honorários</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>
                  {" "}
                  <span>Ação</span>
                  <span>Informação</span>
                  <button onClick={personalizacaoDoUsuario}>botao teste</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="allSelected"
                    onChange={handleChange}
                  />
                </th>
                <th>Selecionar todos</th>
              </tr>
              {doc.map((e, id) => {
                return (
                  <tr key={id}>
                        <th>
                        <label>
                        <div>
                        <input
                          type="checkbox"
                          name={e.name}
                          checked={e.isChecked || false}
                          onChange={handleChange}
                        /></div>
                        <span>
                        {e.name}
                        </span>       
                        </label>
                        </th>       
                  </tr>
                );
              })}
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarDocHonorarios}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormDocHonorarios}>
                      Carregar formulário
                    </button>
                  </th>
                  {/* <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th> */}
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Documentos relativos a honorários Registrados</legend>
            {docHonorarios.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docHonorarios.length > 0 &&
                      docHonorarios.map((e, i) => {
                        return (
                          <tr key={i}>
                            <th>
                              <button onClick={excluirDocHonorarios}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                            <th>{e}</th>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoDocHonorarios}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoDocHonorarios"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            )}
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
