import React, { useEffect, useState } from "react";

export const ConclusaoLaudo = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  };

  const [conclusaoLaudo, setConclusaoLaudo] = useState([]);

  const adicionarConclusaoLaudo = (e) => {
    e.preventDefault();

    const arrConclusaoLaudo = [];

    arrConclusaoLaudo.push({
      respostaConclusaoLaudo0:
        formValues.respostaConclusaoLaudo0 !== undefined
          ? formValues.respostaConclusaoLaudo0
          : "",
      comentarioConclusaoLaudo0:
        formValues.comentarioConclusaoLaudo0 !== undefined
          ? formValues.comentarioConclusaoLaudo0
          : "",
      dataEntregaConclusaoLaudo0:
        formValues.dataEntregaConclusaoLaudo0 !== undefined &&
        formValues.dataEntregaConclusaoLaudo0.length === 10
          ? formValues.dataEntregaConclusaoLaudo0
          : "",
      dataEntregaAmericanoConclusaoLaudo0:
        formValues.dataEntregaAmericanoConclusaoLaudo0 !== undefined &&
        formValues.dataEntregaAmericanoConclusaoLaudo0.length === 10
          ? formValues.dataEntregaAmericanoConclusaoLaudo0
          : "",
      horaEntregaConclusaoLaudo0:
        formValues.horaEntregaConclusaoLaudo0 !== undefined
          ? formValues.horaEntregaConclusaoLaudo0
          : "",
    });
    setConclusaoLaudo([...arrConclusaoLaudo]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _79_conclusaoLaudo: [
          {
            respostaConclusaoLaudo0:
              formValues.respostaConclusaoLaudo0 !== undefined
                ? formValues.respostaConclusaoLaudo0
                : "",
            comentarioConclusaoLaudo0:
              formValues.comentarioConclusaoLaudo0 !== undefined
                ? formValues.comentarioConclusaoLaudo0
                : "",
            dataEntregaConclusaoLaudo0:
              formValues.dataEntregaConclusaoLaudo0 !== undefined &&
              formValues.dataEntregaConclusaoLaudo0.length === 10
                ? formValues.dataEntregaConclusaoLaudo0
                : "",
            dataEntregaAmericanoConclusaoLaudo0:
              formValues.dataEntregaAmericanoConclusaoLaudo0 !== undefined &&
              formValues.dataEntregaAmericanoConclusaoLaudo0.length === 10
                ? formValues.dataEntregaAmericanoConclusaoLaudo0
                : "",
            horaEntregaConclusaoLaudo0:
              formValues.horaEntregaConclusaoLaudo0 !== undefined &&
              formValues.horaEntregaConclusaoLaudo0.length === 5
                ? formValues.horaEntregaConclusaoLaudo0
                : "",
          },
        ],
      })
    );
  };

  const excluirConclusaoLaudo = (e) => {
    e.preventDefault();
    conclusaoLaudo.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        conclusaoLaudo.splice(i, 1);
        setConclusaoLaudo([...conclusaoLaudo]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _79_conclusaoLaudo: [...conclusaoLaudo],
          })
        );
      }
      return null;
    });
  };

  const excluirTudoConclusaoLaudo = (e) => {
    e.preventDefault();

    setConclusaoLaudo([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _79_conclusaoLaudo: [],
      })
    );
  };

  const handleCarregarFormConclusaoLaudo = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

const x = () => {
    if (tudo._79_conclusaoLaudo && tudo._79_conclusaoLaudo.length > 0) {
      const a =
        JSON.parse(localStorage.getItem("tudo"))._79_conclusaoLaudo !==
          undefined ||
        JSON.parse(localStorage.getItem("tudo"))._79_conclusaoLaudo !== null
          ? JSON.parse(localStorage.getItem("tudo"))._79_conclusaoLaudo
          : [];

      //console.log('***',a)
      a.map((e) =>
        setFormValues({
          respostaConclusaoLaudo0:
            e !== undefined && e.respostaConclusaoLaudo0 !== undefined
              ? e.respostaConclusaoLaudo0
              : "",
          comentarioConclusaoLaudo0:
            e !== undefined && e.comentarioConclusaoLaudo0 !== undefined
              ? e.comentarioConclusaoLaudo0
              : "",
          dataEntregaAmericanoConclusaoLaudo0:
            e.dataEntregaAmericanoConclusaoLaudo0 !== undefined &&
            e.dataEntregaAmericanoConclusaoLaudo0.length === 10
              ? e.dataEntregaAmericanoConclusaoLaudo0
              : "",
          dataEntregaConclusaoLaudo0:
            e !== undefined && e.dataEntregaConclusaoLaudo0 !== undefined
              ? e.dataEntregaConclusaoLaudo0
              : "",
          horaEntregaConclusaoLaudo0:
            e !== undefined && e.horaEntregaConclusaoLaudo0 !== undefined
              ? e.horaEntregaConclusaoLaudo0
              : "",
        })
      );
    }}

    const y = () => {
      if (tudo._79_conclusaoLaudo && tudo._79_conclusaoLaudo.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._79_conclusaoLaudo !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._79_conclusaoLaudo !== null
            ? JSON.parse(localStorage.getItem("tudo"))._79_conclusaoLaudo
            : [];
  
        //console.log('***',a)
        a.map((e) =>
          setConclusaoLaudo([{
            respostaConclusaoLaudo0:
              e !== undefined && e.respostaConclusaoLaudo0 !== undefined
                ? e.respostaConclusaoLaudo0
                : "",
            comentarioConclusaoLaudo0:
              e !== undefined && e.comentarioConclusaoLaudo0 !== undefined
                ? e.comentarioConclusaoLaudo0
                : "",
            dataEntregaAmericanoConclusaoLaudo0:
              e.dataEntregaAmericanoConclusaoLaudo0 !== undefined &&
              e.dataEntregaAmericanoConclusaoLaudo0.length === 10
                ? e.dataEntregaAmericanoConclusaoLaudo0
                : "",
            dataEntregaConclusaoLaudo0:
              e !== undefined && e.dataEntregaConclusaoLaudo0 !== undefined
                ? e.dataEntregaConclusaoLaudo0
                : "",
            horaEntregaConclusaoLaudo0:
              e !== undefined && e.horaEntregaConclusaoLaudo0 !== undefined
                ? e.horaEntregaConclusaoLaudo0
                : "",
          }])
        );
      }}

      x()
      y()

  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  useEffect(() => {
    if (
      formValues.dataEntregaAmericanoConclusaoLaudo0 !== undefined &&
      formValues.dataEntregaAmericanoConclusaoLaudo0.length === 10
    ) {
      //console.log(formValues.dataEntregaAmericanoConclusaoLaudo0.lenght)
      formValues.dataEntregaConclusaoLaudo0 =
        `${formValues.dataEntregaAmericanoConclusaoLaudo0.split("-")[2]}-${
          formValues.dataEntregaAmericanoConclusaoLaudo0.split("-")[1]
        }-${formValues.dataEntregaAmericanoConclusaoLaudo0.split("-")[0]}` ||
        "";

      //console.log(formValues.dataEntregaAmericanoConclusaoLaudo0.lenght)
    }
  }, [formValues]);

  return (
    <>
      <fieldset>
        <legend>Conclusão do Laudo</legend>
        <div>
          <fieldset>
            <table>
              <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"respostaConclusaoLaudo0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <select
                      name="respostaConclusaoLaudo0"
                      onChange={handleInputChange}
                      value={
                        formValues.respostaConclusaoLaudo0 !== undefined
                          ? formValues.respostaConclusaoLaudo0
                          : ""
                      }
                    >
                      <option value="">
                        Defina sua conclusão do laudo pericial
                      </option>
                      <option value="Não é plausível o nexo, de acordo com os critérios de simonin">
                        Não é plausível o nexo, de acordo com os critérios de
                        simonin
                      </option>
                      <option value="É duvidoso o nexo, de acordo com os critérios de simonin">
                        É duvidoso o nexo, de acordo com os critérios de simonin
                      </option>
                      <option value="É plausível o nexo, de acordo com os critérios de simonin">
                        É plausível o nexo, de acordo com os critérios de
                        simonin
                      </option>
                      <option value="Não é plausível o nexo causal, nem concausal, de acordo com os critérios de Penteado">
                        Não é plausível o nexo causal, nem concausal, de acordo
                        com os critérios de Penteado
                      </option>
                      <option value="É plausível concausal, de acordo com os critérios de Penteado">
                        É plausível concausal, de acordo com os critérios de
                        Penteado
                      </option>
                      <option value="Não há nexo causal nem concausal, visto que não há incapacidade laboral documentada">
                        Não há nexo causal nem concausal, visto que não há
                        incapacidade laboral documentada
                      </option>
                      <option value="Não há nexo causal nem concausal, é plausível que a doença tenha etiologia degenerativa">
                        Não há nexo causal nem concausal, é plausível que a
                        doença tenha etiologia degenerativa
                      </option>
                    </select>
                  </th>
                </tr>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"comentarioConclusaoLaudo0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <textarea
                      name="comentarioConclusaoLaudo0"
                      cols="30"
                      rows="10"
                      placeholder="Digite o conteúdo aqui."
                      onChange={handleInputChange}
                      value={
                        formValues.comentarioConclusaoLaudo0 !== undefined
                          ? formValues.comentarioConclusaoLaudo0
                          : ""
                      }
                    />
                  </th>
                </tr>

                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"dataEntregaAmericanoConclusaoLaudo0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div>
                      <span>Data da entrega</span>
                      <input
                        type="date"
                        size={8}
                        maxLength={8}
                        name="dataEntregaAmericanoConclusaoLaudo0"
                        onChange={handleInputChange}
                        value={
                          formValues.dataEntregaAmericanoConclusaoLaudo0 !==
                            undefined &&
                          formValues.dataEntregaAmericanoConclusaoLaudo0
                            .length === 10
                            ? formValues.dataEntregaAmericanoConclusaoLaudo0
                            : ""
                        }
                      />
                    </div>
                  </th>
                </tr>

                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"horaEntregaConclusaoLaudo0"}
                      ></i>
                    </button>
                  </th>
                  <th>
                    <div>
                      <span>Hora</span>
                      <input
                        type="time"
                        size={4}
                        maxLength={4}
                        name="horaEntregaConclusaoLaudo0"
                        onChange={handleInputChange}
                        value={
                          formValues.horaEntregaConclusaoLaudo0 !== undefined &&
                          formValues.horaEntregaConclusaoLaudo0.length === 5
                            ? formValues.horaEntregaConclusaoLaudo0
                            : ""
                        }
                      />
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
            <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <button onClick={adicionarConclusaoLaudo}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormConclusaoLaudo}>
                        Carregar formulário
                      </button>
                    </th>
                    <th>
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <fieldset>
              <legend>Dados registrados dos quesitos do(a) Juízo</legend>
              {conclusaoLaudo.length === 0 ? (
                <p>Sem dados registrados</p>
              ) : (
                <>
                  <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                    {conclusaoLaudo.length > 0 &&
                      conclusaoLaudo.map(
                        (
                          {
                            respostaConclusaoLaudo0,
                            comentarioConclusaoLaudo0,
                            dataEntregaConclusaoLaudo0,
                            horaEntregaConclusaoLaudo0,
                          },
                          i
                        ) => {
                          return (
                            <tbody key={i}>
                            
                            <tr >
                            <th>Registro número</th>
                            <th> {i + 1}</th>
                          </tr>
                          <tr >
                            <th>Conclusão</th>
                            <th>{respostaConclusaoLaudo0}</th>
                          </tr>
                          <tr >
                            <th>Comentário</th>
                            <th>{comentarioConclusaoLaudo0}</th>
                          </tr>
                          <tr >
                            <th>Data da Entrega</th>
                            <th>{dataEntregaConclusaoLaudo0}</th>
                          </tr>
                          <tr >
                            <th>Hora</th>
                            <th>{horaEntregaConclusaoLaudo0}</th>
                          </tr>
                              <tr>
                              <th>Ação</th>
                              <th>
                                <button onClick={excluirConclusaoLaudo}>
                                  <i
                                    className="fas fa-solid fa-trash perigo"
                                    value={i}
                                  ></i>
                                </button>
                              </th>
                            </tr> 


                            </tbody>
                          );
                        }
                      )}
                  </table>
                  <fieldset>
                    <legend>Ação</legend>
                    <table>
                      <thead>
                        <tr>
                          <th>Excluir todos os documentos?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            <button onClick={excluirTudoConclusaoLaudo}>
                              <i
                                className="fas fa-solid fa-trash perigo"
                                value={"excluirTudoConclusaoLaudo"}
                              ></i>
                            </button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </>
              )}
            </fieldset>
          </fieldset>
        </div>{" "}
      </fieldset>
    </>
  );
};
