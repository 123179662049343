import React, { useState, useEffect } from "react";

export const AgendamentoPericia = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log(
      "***data",
      data,
      "***formValues",
      formValues,
      "***newValue",
      newValue
    );
  };

  const [agendamento, setAgendamento] = useState([]);

  const adicionarAgendamento = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo")) || {};
    let getDadosPeritoEndCom = {};
    if (tudo._86_dadosPeritoEndCom) {
      getDadosPeritoEndCom = JSON.parse(
        localStorage.getItem("tudo")
      )._86_dadosPeritoEndCom; /* || {} */
    } else {
      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _86_dadosPeritoEndCom: {},
        })
      );
      getDadosPeritoEndCom = JSON.parse(
        localStorage.getItem("tudo")
      )._86_dadosPeritoEndCom;
    }

    let numDiaSem = "";
    let arrDiaSem = "";
    let diaSem = "";

    if (
      formValues.dataAgendamentoAmericano !== undefined &&
      formValues.dataAgendamentoAmericano.length === 10
    ) {
      formValues.dataAgendamento =
        `${formValues.dataAgendamentoAmericano.split("-")[2]}-${
          formValues.dataAgendamentoAmericano.split("-")[1]
        }-${formValues.dataAgendamentoAmericano.split("-")[0]}` || "";
      numDiaSem = new Date(formValues.dataAgendamentoAmericano).getDay();
      arrDiaSem = [
        "Segunda",
        "Terca",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sabado",
        "Domingo",
      ];
      diaSem = arrDiaSem[numDiaSem];
    }

    const arrAgendamento = [];
    arrAgendamento.push({
      dataAgendamentoAmericano:
        formValues.dataAgendamentoAmericano !== undefined &&
        formValues.dataAgendamentoAmericano.length === 10
          ? formValues.dataAgendamentoAmericano
          : "",
      dataAgendamento:
        formValues.dataAgendamento !== undefined &&
        formValues.dataAgendamento.length === 10
          ? formValues.dataAgendamento
          : "",
      diaSem: diaSem !== undefined && diaSem !== "" ? diaSem : "",
      hora:
        formValues.hora !== undefined && formValues.hora !== ""
          ? formValues.hora
          : "",
      local:
        getDadosPeritoEndCom !== null && getDadosPeritoEndCom !== undefined
          ? [
              {
                logradouro:
                  getDadosPeritoEndCom.logradouro !== undefined
                    ? getDadosPeritoEndCom.logradouro
                    : "",
                bairro:
                  getDadosPeritoEndCom.bairro !== undefined
                    ? getDadosPeritoEndCom.bairro
                    : "",
                numero:
                  getDadosPeritoEndCom.numero !== undefined
                    ? getDadosPeritoEndCom.numero
                    : "",
                complemento:
                  getDadosPeritoEndCom.complemento !== undefined
                    ? getDadosPeritoEndCom.complemento
                    : "",
                cidade:
                  getDadosPeritoEndCom.cidade !== undefined
                    ? getDadosPeritoEndCom.cidade
                    : "",
                estado:
                  getDadosPeritoEndCom.estado !== undefined
                    ? getDadosPeritoEndCom.estado
                    : "",
                cep:
                  getDadosPeritoEndCom.cep !== undefined
                    ? getDadosPeritoEndCom.cep
                    : "",
              },
            ]
          : [
              {
                logradouro: "Erro!",
                bairro: "Erro!",
                numero: "Erro!",
                complemento: "Erro!",
                cidade: "Erro!",
                estado: "Erro!",
                cep: "Erro!",
              },
            ],
    });

    setAgendamento(arrAgendamento);

    setFormValues({ ...formValues, local: arrAgendamento[0].local });

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _08_agendamento: arrAgendamento,
      })
    );
  };

  const excluirAgendamento = (e) => {
    e.preventDefault();
    agendamento.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        agendamento.splice(i, 1);
        setAgendamento([...agendamento]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _08_agendamento: [...agendamento],
          })
        );
      }
      return null;
    });
  };

  const excluirTudoAgendamento = (e) => {
    e.preventDefault();

    setAgendamento([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _08_agendamento: [],
      })
    );
  };

  const handleCarregarFormAgendamento = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
      if (tudo._08_agendamento) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._08_agendamento !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._08_agendamento !== null
            ? JSON.parse(localStorage.getItem("tudo"))._08_agendamento
            : [];

        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            dataAgendamentoAmericano:
              e.dataAgendamentoAmericano !== undefined &&
              e.dataAgendamentoAmericano.length === 10
                ? e.dataAgendamentoAmericano
                : "",
            dataAgendamento:
              e.dataAgendamento !== undefined && e.dataAgendamento.length === 10
                ? e.dataAgendamento
                : "",
            diaSem: e.diaSem !== undefined && e.diaSem !== "" ? e.diaSem : "",
            hora: e.hora !== undefined && e.hora !== "" ? e.hora : "",
            local: e.local !== undefined && e.local.length > 0 ? e.local : [{}],
          })
        );
      }
    };

    const y = () => {
      if (tudo._08_agendamento /* && tudo._08_agendamento.length > 0 */) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._08_agendamento !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._08_agendamento !== null
            ? JSON.parse(localStorage.getItem("tudo"))._08_agendamento
            : [];

        //console.log('***',a)
        const arrAgendamento = [];
        a.map((e) =>
          arrAgendamento.push({
            dataAgendamentoAmericano:
              e.dataAgendamentoAmericano !== undefined &&
              e.dataAgendamentoAmericano.length === 10
                ? e.dataAgendamentoAmericano
                : "",
            dataAgendamento:
              e.dataAgendamento !== undefined && e.dataAgendamento.length === 10
                ? e.dataAgendamento
                : "",
            diaSem: e.diaSem !== undefined && e.diaSem !== "" ? e.diaSem : "",
            hora: e.hora !== undefined && e.hora !== "" ? e.hora : "",
            local:
              e.local !== undefined
                ? [
                    {
                      logradouro:
                        e.local[0].logradouro !== undefined
                          ? e.local[0].logradouro
                          : "",
                      bairro:
                        e.local[0].bairro !== undefined
                          ? e.local[0].bairro
                          : "",
                      numero:
                        e.local[0].numero !== undefined
                          ? e.local[0].numero
                          : "",
                      complemento:
                        e.local[0].complemento !== undefined
                          ? e.local[0].complemento
                          : "",
                      cidade:
                        e.local[0].cidade !== undefined
                          ? e.local[0].cidade
                          : "",
                      estado:
                        e.local[0].estado !== undefined
                          ? e.local[0].estado
                          : "",
                      cep: e.local[0].cep !== undefined ? e.local[0].cep : "",
                    },
                  ]
                : [{}],
          })
        );

        setAgendamento(arrAgendamento);
      }
    };

    x();
    y();
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  useEffect(() => {
    if (
      formValues.dataAgendamentoAmericano !== undefined &&
      formValues.dataAgendamentoAmericano.length === 10
    ) {
      //console.log(formValues.dataAgendamentoAmericano.lenght)
      formValues.dataAgendamento =
        `${formValues.dataAgendamentoAmericano.split("-")[2]}-${
          formValues.dataAgendamentoAmericano.split("-")[1]
        }-${formValues.dataAgendamentoAmericano.split("-")[0]}` || "";

      //console.log(formValues.dataAgendamentoAmericano.lenght)
    }
  }, [formValues]);

  //console.log( '***formValues:', formValues)

  return (
    <>
      <fieldset>
      <legend>Agendamento da Perícia</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>Limpar campo</th>
                <th>Informação</th>
              </tr>
            </thead>
            <tbody>
              <tr key="dataAgendamentoAmericano">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"dataAgendamentoAmericano"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                    <span>
                      Data do agendamento
                    </span>
                    <input
                    type="date"
                    size={8}
                    maxLength={8}
                    name="dataAgendamentoAmericano"
                    onChange={handleInputChange}
                    value={
                      formValues.dataAgendamentoAmericano !== undefined
                        ? formValues.dataAgendamentoAmericano
                        : ""
                    }
                  />
                  </div>
                  
                </th>
              </tr>
              <tr key="hora">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"hora"}></i>
                  </button>
                </th>
                <th>
                  <div>
                    <span>Hora</span>
                    <input
                    type="time"
                    name="hora"
                    size={4}
                    maxLength={4}
                    id="hora"
                    onChange={handleInputChange}
                    value={formValues.hora !== undefined ? formValues.hora : ""}
                  />
                  </div>
                  
                </th>
              </tr>
              <tr key="local">
                <th>
                  Local (End Comercial do perito)
                  {/* <button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={"local"}></i></button> */}
                </th>
                <th>
                <input
                disabled
                    type="text"
                    name="enderecoAgendamentoPericia"
      
                    value={formValues.local !== undefined &&
                      formValues.local.length > 0 ? (
                        
                          formValues.local
                            ? `${
                                formValues.local[0].logradouro !== undefined
                                  ? formValues.local[0].logradouro
                                  : ""
                              }
                   ${
                     formValues.local[0].numero !== undefined
                       ? formValues.local[0].numero
                       : ""
                   } 
                   ${
                     formValues.local[0].complemento !== undefined
                       ? formValues.local[0].complemento
                       : ""
                   } 
                   ${
                     formValues.local[0].bairro !== undefined
                       ? formValues.local[0].bairro
                       : ""
                   }
                   ${
                     formValues.local[0].cidade !== undefined
                       ? formValues.local[0].cidade
                       : ""
                   } ${
                                formValues.local[0].estado !== undefined
                                  ? formValues.local[0].estado
                                  : ""
                              }
                   ${
                     formValues.local[0].cep !== undefined
                       ? formValues.local[0].cep
                       : ""
                   }
                   `
                            : "Verifique se há endereço comercial! form"
                        
                      ) : (
                        
                          agendamento.local
                            ? `${
                                agendamento.local[0].logradouro !== undefined
                                  ? agendamento.local[0].logradouro
                                  : ""
                              }
                   ${
                     agendamento.local[0].numero !== undefined
                       ? agendamento.local[0].numero
                       : ""
                   } 
                   ${
                     agendamento.local[0].complemento !== undefined
                       ? agendamento.local[0].complemento
                       : ""
                   } 
                   ${
                     agendamento.local[0].bairro !== undefined
                       ? agendamento.local[0].bairro
                       : ""
                   }
                   ${
                     agendamento.local[0].cidade !== undefined
                       ? agendamento.local[0].cidade
                       : ""
                   } ${
                                agendamento.local[0].estado !== undefined
                                  ? agendamento.local[0].estado
                                  : ""
                              }
                   ${
                     agendamento.local[0].cep !== undefined
                       ? agendamento.local[0].cep
                       : ""
                   }
                   `
                            : "Verifique se há endereço comercial!"
                        
                      )}
                  />
                  
                </th>
              </tr>
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarAgendamento}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormAgendamento}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Dados do Agendamento Registrado</legend>
            {agendamento.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {agendamento.length > 0 &&
                    agendamento.map(
                      ({ dataAgendamento, diaSem, hora, local }, i) => {
                        return (
                          <tbody key={i}>
                            <tr >
                              <th>Registro número</th>
                              <th> {i + 1}</th>
                            </tr>
                            <tr>
                              <th>Dia da semana</th>
                              <th>{diaSem}</th>
                            </tr>
                            <tr>
                              <th>Data</th>
                              <th>{dataAgendamento}</th>
                            </tr>
                            <tr>
                              <th>Hora</th>
                              <th>{hora}</th>
                            </tr>

                            <tr>
                              <th>Local</th>
                              <th>
                                {local !== null &&
                                local !== undefined &&
                                local.length === 0 ? (
                                  <p>Não registrado</p>
                                ) : (
                                  local.map(
                                    (
                                      {
                                        logradouro,
                                        numero,
                                        complemento,
                                        bairro,
                                        cep,
                                        cidade,
                                        estado,
                                        coordenadas,
                                      },
                                      i
                                    ) => {
                                      return (
                                        <p key={i}>
                                          {logradouro} {numero} {complemento}{" "}
                                          {bairro} {cidade} {estado} {cep}{" "}
                                        </p>
                                      );
                                    }
                                  )
                                )}
                              </th>
                            </tr>

                            <tr>
                              <th>Ação</th>
                              <th >
                                <button onClick={excluirAgendamento}>
                                  <i
                                    className="fas fa-solid fa-trash perigo"
                                    value={i}
                                  ></i>
                                </button>
                              </th>
                            </tr>
                          </tbody>
                        );
                      }
                    )}
                </table>

                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoAgendamento}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoAgendamento"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            )}
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
