import React, {useState, useEffect,} from 'react'
import { UtilitariosBuscaCid } from '../../utilitarios/UtilitariosBusca'

export const DadosAtestado = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ atestado, setAtestado] = useState([])

    const adicionarAtestado = () => {

        /* if (JSON.parse(localStorage.getItem('tudo'))._99_cidBusca === null || JSON.parse(localStorage.getItem('tudo'))._99_cidBusca === undefined) {
            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _99_cidBusca: []
                }))
           } */

        /* const cidBusca = JSON.parse(localStorage.getItem('tudo'))._99_cidBusca !== undefined ||
        JSON.parse(localStorage.getItem('tudo'))._99_cidBusca !== null ? JSON.parse(localStorage.getItem('tudo'))._99_cidBusca : localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _99_cidBusca: []
            })) */
            const tudo = JSON.parse(localStorage.getItem('tudo')) || []
            let cidBusca = []
            if(tudo._99_cidBusca){
         cidBusca = tudo._99_cidBusca /* !== null ? tudo._99_cidBusca : [] */
            }

        const arrAtestado = []
        arrAtestado.push({
            //id: atestado.length !== undefined ? atestado.length + 1 : '',
            cid: cidBusca !== undefined || cidBusca !== null ? cidBusca : [],
            idAtestado: formValues.idAtestado !== undefined ? formValues.idAtestado : '',
            inicioAtestado: formValues.inicioAtestado !== undefined && formValues.inicioAtestado.length === 10 ? formValues.inicioAtestado : '',
            inicioAtestadoAmericano: formValues.inicioAtestadoAmericano !== undefined && formValues.inicioAtestadoAmericano.length === 10 ? formValues.inicioAtestadoAmericano : '',
            fimAtestado: formValues.fimAtestado !== undefined && formValues.fimAtestado.length === 10 ? formValues.fimAtestado : '',
            fimAtestadoAmericano: formValues.fimAtestadoAmericano !== undefined && formValues.fimAtestadoAmericano.length === 10 ? formValues.fimAtestadoAmericano : '',
            qtdeDiasAfastadoAtestado: formValues.qtdeDiasAfastadoAtestado !== undefined ? formValues.qtdeDiasAfastadoAtestado : '',
            profissionalAssistente: formValues.profissionalAssistente !== undefined ? formValues.profissionalAssistente : ''
        })

        setAtestado([...atestado, ...arrAtestado])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _11_atestado: [...atestado,{
                //id: atestado.length !== undefined ? atestado.length + 1 : '',
                cid: cidBusca !== undefined || cidBusca !== null ? cidBusca : [],
                idAtestado: formValues.idAtestado !== undefined ? formValues.idAtestado : '',
                inicioAtestado: formValues.inicioAtestado !== undefined && formValues.inicioAtestado.length === 10 ? formValues.inicioAtestado : '',
                inicioAtestadoAmericano: formValues.inicioAtestadoAmericano !== undefined && formValues.inicioAtestadoAmericano.length === 10 ? formValues.inicioAtestadoAmericano : '',
            fimAtestado: formValues.fimAtestado !== undefined && formValues.fimAtestado.length === 10 ? formValues.fimAtestado : '',
            fimAtestadoAmericano: formValues.fimAtestadoAmericano !== undefined && formValues.fimAtestadoAmericano.length === 10 ? formValues.fimAtestadoAmericano : '',
                qtdeDiasAfastadoAtestado: formValues.qtdeDiasAfastadoAtestado !== undefined ? formValues.qtdeDiasAfastadoAtestado : '',
                profissionalAssistente: formValues.profissionalAssistente !== undefined ? formValues.profissionalAssistente : ''
            }]
      }))
    }

    const excluirAtestado = (e) => {

        e.preventDefault() 
        atestado.filter((
            el, i
            )=>{

                if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                    
                        atestado.splice(i, 1)
                        setAtestado([...atestado])

                        localStorage.setItem('tudo', JSON.stringify({
                            ...JSON.parse(localStorage.getItem('tudo')) || [],
                            _11_atestado: atestado
                      }))                     
                    }    
                    return null
        })
    }

    const excluirTudoAtestado = (e) => {
        e.preventDefault();
       // console.log(e.target.attributes.value.value)

          setAtestado([])
  
           localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _11_atestado: []
            })
          ); 
      }
  
      const handleCarregarFormAtestado = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._11_atestado && tudo._11_atestado.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._11_atestado !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._11_atestado !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._11_atestado : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
                cid: e !== undefined && e.cid !== undefined && e.cid !== ""
            ? e.cid
            : "",

              idAtestado: e !== undefined && e.idAtestado !== undefined && e.idAtestado !== ""
            ? e.idAtestado
            : "",

            inicioAtestado: e !== undefined && e.inicioAtestado !== undefined && e.inicioAtestado !== ""
            ? e.inicioAtestado
            : "",
            
            inicioAtestadoAmericano: e !== undefined && e.inicioAtestadoAmericano !== undefined && e.inicioAtestadoAmericano !== ''
            ? e.inicioAtestadoAmericano
            : "",
            
            fimAtestado: e !== undefined && e.fimAtestado !== undefined && e.fimAtestado !== ""
            ? e.fimAtestado
            : "",

            fimAtestadoAmericano: e !== undefined && e.fimAtestadoAmericano !== undefined && e.fimAtestadoAmericano !== ""
            ? e.fimAtestadoAmericano
            : "",
  
            qtdeDiasAfastadoAtestado: e !== undefined && e.qtdeDiasAfastadoAtestado !== undefined &&
            e.qtdeDiasAfastadoAtestado !== ''
              ? e.qtdeDiasAfastadoAtestado
              : "",

              profissionalAssistente: e !== undefined && e.profissionalAssistente !== undefined &&
            e.profissionalAssistente !== ''
              ? e.profissionalAssistente
              : "",

        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._11_atestado && tudo._11_atestado.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._11_atestado !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._11_atestado !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._11_atestado : []
      
              //console.log('***',a)
              const arrAtestado = []
          a.map((e)=> {
  
          return  arrAtestado.push({
            cid: e !== undefined && e.cid !== undefined && e.cid !== ""
            ? e.cid
            : "",

              idAtestado: e !== undefined && e.idAtestado !== undefined && e.idAtestado !== ""
            ? e.idAtestado
            : "",

            inicioAtestado: e !== undefined && e.inicioAtestado !== undefined && e.inicioAtestado !== ""
            ? e.inicioAtestado
            : "",
            
            inicioAtestadoAmericano: e !== undefined && e.inicioAtestadoAmericano !== undefined && e.inicioAtestadoAmericano !== ''
            ? e.inicioAtestadoAmericano
            : "",
            
            fimAtestado: e !== undefined && e.fimAtestado !== undefined && e.fimAtestado !== ""
            ? e.fimAtestado
            : "",

            fimAtestadoAmericano: e !== undefined && e.fimAtestadoAmericano !== undefined && e.fimAtestadoAmericano !== ""
            ? e.fimAtestadoAmericano
            : "",
  
            qtdeDiasAfastadoAtestado: e !== undefined && e.qtdeDiasAfastadoAtestado !== undefined &&
            e.qtdeDiasAfastadoAtestado !== ''
              ? e.qtdeDiasAfastadoAtestado
              : "",

              profissionalAssistente: e !== undefined && e.profissionalAssistente !== undefined &&
            e.profissionalAssistente !== ''
              ? e.profissionalAssistente
              : "",

        }) 
    
    }
      )  

      setAtestado(arrAtestado)
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }

    useEffect(() => {
        if (
            formValues.inicioAtestadoAmericano !== undefined &&
            formValues.inicioAtestadoAmericano.length === 10
        ) {
            formValues.inicioAtestado =`${formValues.inicioAtestadoAmericano.split('-')[2]}-${formValues.inicioAtestadoAmericano.split('-')[1]}-${formValues.inicioAtestadoAmericano.split('-')[0]}` || ''
        }
        if  (
            formValues.fimAtestadoAmericano !== undefined &&
            formValues.fimAtestadoAmericano.length === 10
        ) {
            formValues.fimAtestado =`${formValues.fimAtestadoAmericano.split('-')[2]}-${formValues.fimAtestadoAmericano.split('-')[1]}-${formValues.fimAtestadoAmericano.split('-')[0]}` || ''
        }
        if (formValues.inicioAtestadoAmericano && formValues.fimAtestadoAmericano) {
            formValues.qtdeDiasAfastadoAtestado = Math.ceil((((new Date(formValues.fimAtestadoAmericano).getTime()) - new Date(formValues.inicioAtestadoAmericano).getTime()) / (1000 * 60 * 60 * 24)) + 1)
        } 
            }, [
            formValues]
    )

    return (
        <>
           <fieldset>
        <legend>Atestados</legend>
        <div >
        <fieldset >
          <table >
                        <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idAtestado"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="idAtestado"
                            maxLength="22"
                            className="capturaIdAtestado"
                            placeholder="Id - página"
                            onChange={handleInputChange}
                            value={formValues.idAtestado !== undefined ? formValues.idAtestado : ''}
                        /></th>
                </tr>

                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"inicioAtestadoAmericano"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span>Data do início</span>
                        <input
                            type="date"
                            name="inicioAtestadoAmericano"
                            onChange={handleInputChange}
                            value={formValues.inicioAtestadoAmericano !== undefined ? formValues.inicioAtestadoAmericano : ''}
                        />
                      </div>
                      </th>
                </tr>

                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"fimAtestadoAmericano"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span>Data do Fim</span>
                        <input
                            type="date"
                            name="fimAtestadoAmericano"
                            onChange={handleInputChange}
                            value={formValues.fimAtestadoAmericano !== undefined ? formValues.fimAtestadoAmericano : ''}
                        />
                      </div>
                      </th>
                </tr>

                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"qtdeDiasAfastadoAtestado"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span>Quantidade de dias afastado</span>
                        <input
                        disabled
                            type="text"
                            name="qtdeDiasAfastadoAtestado"
                            onChange={handleInputChange}
                            value={formValues.qtdeDiasAfastadoAtestado !== undefined ? formValues.qtdeDiasAfastadoAtestado : ''}
                        />
                      </div>
                      </th>
                </tr>

                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"profissionalAssistente"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="profissionalAssistente"
                            placeholder="Insira os Dados do profissional assistente"
                            onChange={handleInputChange}
                            value={formValues.profissionalAssistente !== undefined ? formValues.profissionalAssistente : ''}
                        /></th>
                </tr>

                </tbody>
                </table>
                        <UtilitariosBuscaCid />

                        <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarAtestado}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormAtestado}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>
                    


                    <fieldset>
                        <legend>Dados registrados de Atestado</legend>
                        {atestado.length === 0 ? <p>Sem Dados registrados</p> :
                            <>
                            <table>
                              <thead>
                                <tr>
                                  <th>Tópico</th>
                                  <th>Informação</th>
                                </tr>
                              </thead>
                                    {atestado.length > 0 && atestado.map(({
                                        
                                        cid,
                                        idAtestado,
                                        inicioAtestado,
                                        fimAtestado,
                                        qtdeDiasAfastadoAtestado,
                                        profissionalAssistente }, i) => {
                                        return (
                                        
                                            <tbody key={i}>
                                            <tr >
                                              <th>Registro número</th>
                                              <th> {i + 1}</th>
                                            </tr>
                                            <tr>
                                              <th>Id</th>
                                              <th>{idAtestado}</th>
                                            </tr>
                                            <tr>
                                              <th>Início</th>
                                              <th>{inicioAtestado}</th>
                                            </tr>
                                            <tr>
                                              <th>Fim</th>
                                              <th>{fimAtestado}</th>
                                            </tr>
                                            <tr>
                                              <th>Dias Afestado</th>
                                              <th>{qtdeDiasAfastadoAtestado}</th>
                                            </tr>
                                            <tr>
                                              <th>Profissional assistente</th>
                                              <th>{profissionalAssistente}</th>
                                            </tr>
                                            <tr>
                                              <th>Cid</th>
                                              <th>
                                            {cid === undefined && cid.lenght === 0 ? <p>Não registrado</p> : cid.map(({ codigo, descricao }, i) => {
                                                    return <p key={i}>{codigo} - {descricao}</p>
                                                })
                                                }
                                            </th>
                                            </tr>
                                            <tr>
                                                <th>Ação</th>
                                                <th >
                                                  <button onClick={excluirAtestado}>
                                                    <i
                                                      className="fas fa-solid fa-trash perigo"
                                                      value={i}
                                                    ></i>
                                                  </button>
                                                </th>
                                              </tr>
                                            </tbody>
                                          
                                        );
                                      }
                                    )}
                                </table>
                                <fieldset>
                                  <legend>Ação</legend>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Excluir todos os documentos?</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr >
                                        <th>
                                          <button onClick={excluirTudoAtestado}>
                                            <i
                                              className="fas fa-solid fa-trash perigo"
                                              value={"excluirTudoAtestado"}
                                            ></i>
                                          </button>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                              </>
                            }
                          </fieldset>
                        </fieldset>
                      </div></fieldset>
                    </>
                  );
}