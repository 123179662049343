import React, {useState} from 'react'
import styles from '../baremoArgentinoQualitativo/BaremoArgentinoQualitativo.module.css'

export const BaremoArgentinoQualitativo = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ baremoArgentinoQualitativo, setBaremoArgentinoQualitativo] = useState([])
   
    const adicionarBaremoArgentinoQualitativo = (e) => {

        e.preventDefault()

        const arrBaremoArgentinoQualitativo = []
    
        arrBaremoArgentinoQualitativo.push({
          //  perguntaBaremoArgentinoQualitativo0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaBaremoArgentinoQualitativo0: formValues.respostaBaremoArgentinoQualitativo0 !== undefined ? formValues.respostaBaremoArgentinoQualitativo0 : '',
            comentarioBaremoArgentinoQualitativo0: formValues.comentarioBaremoArgentinoQualitativo0 !== undefined ? formValues.comentarioBaremoArgentinoQualitativo0 : '',     
        })
        setBaremoArgentinoQualitativo([...arrBaremoArgentinoQualitativo])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _70_baremoArgentinoQualitativo: [{
           //     perguntaBaremoArgentinoQualitativo0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaBaremoArgentinoQualitativo0: formValues.respostaBaremoArgentinoQualitativo0 !== undefined ? formValues.respostaBaremoArgentinoQualitativo0 : '',
         comentarioBaremoArgentinoQualitativo0: formValues.comentarioBaremoArgentinoQualitativo0 !== undefined ? formValues.comentarioBaremoArgentinoQualitativo0 : '',
            }]
      }))
    }

    const excluirBaremoArgentinoQualitativo = (e) => {
        e.preventDefault()  

        setBaremoArgentinoQualitativo([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _70_baremoArgentinoQualitativo: []
              }))
    }

    const handleCarregarFormBaremoArgentinoQualitativo = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._70_baremoArgentinoQualitativo && tudo._70_baremoArgentinoQualitativo.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._70_baremoArgentinoQualitativo !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._70_baremoArgentinoQualitativo !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._70_baremoArgentinoQualitativo : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
   // perguntaBaremoArgentinoQualitativo0: e !== undefined && e.perguntaBaremoArgentinoQualitativo0 !== undefined ? e.perguntaBaremoArgentinoQualitativo0 : '',
            respostaBaremoArgentinoQualitativo0: e !== undefined &&  e.respostaBaremoArgentinoQualitativo0 !== undefined ? e.respostaBaremoArgentinoQualitativo0 : '',
            comentarioBaremoArgentinoQualitativo0: e !== undefined &&  e.comentarioBaremoArgentinoQualitativo0 !== undefined ? e.comentarioBaremoArgentinoQualitativo0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparBaremoArgentinoQualitativo = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaBaremoArgentinoQualitativo'+e.target.attributes.value.value]: '', ['comentarioBaremoArgentinoQualitativo'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxBaremoArgentinoQualitativo} />
            <label htmlFor={styles.checkboxBaremoArgentinoQualitativo}>
                <h2 className={styles.titulo}>Baremo Argentino Qualitativo</h2>
            </label>
            <fieldset id={styles.conteudoBaremoArgentinoQualitativo}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {/* <th>Pergunta</th> */}
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0"> 
                        <th><button onClick={handleLimparBaremoArgentinoQualitativo}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>
                        <select 
                                name="respostaBaremoArgentinoQualitativo0"
                                onChange={handleInputChange}
                                value={formValues.respostaBaremoArgentinoQualitativo0 !== undefined ? formValues.respostaBaremoArgentinoQualitativo0 : ''}
                            >
                                <option value="" defaultValue>Selecione:</option>
                                    <option value="Sequela leve || A perda total da função afetada provoca algum tipo de menosvalia à saúde do indivíduo, porém não dificulta de nenhuma maneira a realização do trabalho em questão.">Sequela leve || A perda total da função afetada provoca algum tipo de menosvalia à saúde do indivíduo, porém não dificulta de nenhuma maneira a realização do trabalho em questão.</option>
                                    <option value="Sequela moderada || A perda total da função afetada dificulta de alguma maneira a realização do trabalho mas não provoca uma diminuição do rendimento ou da qualidade do trabalho, ainda que implique um maior esforço ou risco para o trabalhador.">Sequela moderada || A perda total da função afetada dificulta de alguma maneira a realização do trabalho mas não provoca uma diminuição do rendimento ou da qualidade do trabalho, ainda que implique um maior esforço ou risco para o trabalhador.</option>
                                    <option value="Sequela importante || A perda total da função afetada dificulta de forma notável a realização do trabalho implicando em uma diminuição do rendimento e na qualidade do trabalho.">Sequela importante || A perda total da função afetada dificulta de forma notável a realização do trabalho implicando em uma diminuição do rendimento e na qualidade do trabalho.</option>
                            </select> 
                            </th>
                        <th>
                        <textarea 
                            name="comentarioBaremoArgentinoQualitativo0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite seu comentário aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioBaremoArgentinoQualitativo0 !== undefined ? formValues.comentarioBaremoArgentinoQualitativo0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarBaremoArgentinoQualitativo}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormBaremoArgentinoQualitativo}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados do Baremo Argentino Qualitativo</legend>
            {
            baremoArgentinoQualitativo.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                           {/*  <th>Pergunta</th> */}
                            <th>Resposta</th>
                            <th>Comentário</th> 
                        </tr>
                    </thead>
                        {baremoArgentinoQualitativo.length > 0 && baremoArgentinoQualitativo.map(({ 
                           // perguntaBaremoArgentinoQualitativo0,
                            respostaBaremoArgentinoQualitativo0,
                            comentarioBaremoArgentinoQualitativo0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                               {/*  <th>{perguntaBaremoArgentinoQualitativo0}</th> */}
                                <th>{respostaBaremoArgentinoQualitativo0}</th>
                                 <th>{comentarioBaremoArgentinoQualitativo0}</th> 
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirBaremoArgentinoQualitativo}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}
