import React, { useState } from "react";

export const QuesitosJuizo = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  };

  const [quesitosJuizo, setQuesitosJuizo] = useState([]);

  const adicionarQuesitosJuizo = (e) => {
    e.preventDefault();

    const arrQuesitosJuizo = [];

    arrQuesitosJuizo.push({
      idQuesitosJuizo0:
        formValues.idQuesitosJuizo0 !== undefined
          ? formValues.idQuesitosJuizo0
          : "",
      conteudoQuesitosJuizo0:
        formValues.conteudoQuesitosJuizo0 !== undefined
          ? formValues.conteudoQuesitosJuizo0
          : "",
    });
    setQuesitosJuizo([...arrQuesitosJuizo]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _78_quesitosJuizo: [
          {
            idQuesitosJuizo0:
              formValues.idQuesitosJuizo0 !== undefined
                ? formValues.idQuesitosJuizo0
                : "",
            conteudoQuesitosJuizo0:
              formValues.conteudoQuesitosJuizo0 !== undefined
                ? formValues.conteudoQuesitosJuizo0
                : "",
          },
        ],
      })
    );
  };

  const excluirQuesitosJuizo = (e) => {
    e.preventDefault();
    quesitosJuizo.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        quesitosJuizo.splice(i, 1);
        setQuesitosJuizo([...quesitosJuizo]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _78_quesitosJuizo: [...quesitosJuizo],
          })
        );
      }
      return null;
    });
  };


  const excluirTudoQuesitosJuizo = (e) => {
    e.preventDefault();

    setQuesitosJuizo([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _78_quesitosJuizo: [],
      })
    );
  };

  const handleCarregarFormQuesitosJuizo = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
    if (tudo._78_quesitosJuizo && tudo._78_quesitosJuizo.length > 0) {
      const a =
        JSON.parse(localStorage.getItem("tudo"))._78_quesitosJuizo !==
          undefined ||
        JSON.parse(localStorage.getItem("tudo"))._78_quesitosJuizo !== null
          ? JSON.parse(localStorage.getItem("tudo"))._78_quesitosJuizo
          : [];

      //console.log('***',a)
      a.map((e) =>
        setFormValues({
          idQuesitosJuizo0:
            e !== undefined && e.idQuesitosJuizo0 !== undefined
              ? e.idQuesitosJuizo0
              : "",
          conteudoQuesitosJuizo0:
            e !== undefined && e.conteudoQuesitosJuizo0 !== undefined
              ? e.conteudoQuesitosJuizo0
              : "",
        })
      );
    }}

    const y = () => {
      if (tudo._78_quesitosJuizo && tudo._78_quesitosJuizo.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._78_quesitosJuizo !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._78_quesitosJuizo !== null
            ? JSON.parse(localStorage.getItem("tudo"))._78_quesitosJuizo
            : [];
  
        //console.log('***',a)
        a.map((e) =>
          setQuesitosJuizo([{
            idQuesitosJuizo0:
              e !== undefined && e.idQuesitosJuizo0 !== undefined
                ? e.idQuesitosJuizo0
                : "",
            conteudoQuesitosJuizo0:
              e !== undefined && e.conteudoQuesitosJuizo0 !== undefined
                ? e.conteudoQuesitosJuizo0
                : "",
          }])
        );
      }}

      x()
      y()


  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  return (
    <>
      <fieldset>
        <legend>Quesitos do Juízo</legend>
        <div >
        <fieldset >
          <table >
          <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>
            <tbody>
              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"idQuesitosJuizo0"}></i>
                  </button>
                </th>
                <th>
                  <input
                    name="idQuesitosJuizo0"
                    placeholder="Id - página"
                    onChange={handleInputChange}
                    value={
                      formValues.idQuesitosJuizo0 !== undefined
                        ? formValues.idQuesitosJuizo0
                        : ""
                    }
                  />
                </th>
                </tr>
                <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"conteudoQuesitosJuizo0"}></i>
                  </button>
                </th>
                <th>
                  <textarea
                    name="conteudoQuesitosJuizo0"
                    cols="30"
                    rows="10"
                    placeholder="Digite o conteúdo aqui."
                    onChange={handleInputChange}
                    value={
                      formValues.conteudoQuesitosJuizo0 !== undefined
                        ? formValues.conteudoQuesitosJuizo0
                        : ""
                    }
                  />
                </th>
                </tr>
              
                
              
            </tbody>
          </table>
          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarQuesitosJuizo}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormQuesitosJuizo}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Dados registrados dos quesitos do(a) Juízo</legend>
            {quesitosJuizo.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {quesitosJuizo.length > 0 &&
                    quesitosJuizo.map(
                      ({ idQuesitosJuizo0, conteudoQuesitosJuizo0 }, i) => {
                        return (
                          <tbody key={i}>
                            
                            <tr >
                            <th>Registro número</th>
                            <th> {i + 1}</th>
                          </tr>
                          <tr >
                            <th>Id</th>
                            <th>{idQuesitosJuizo0}</th>
                          </tr>
                          <tr >
                            <th>Conteúdo</th>
                            <th>{conteudoQuesitosJuizo0}</th>
                          </tr>
                          <tr>
                                <th>Ação</th>
                                <th>
                                  <button onClick={excluirQuesitosJuizo}>
                                    <i
                                      className="fas fa-solid fa-trash perigo"
                                      value={i}
                                    ></i>
                                  </button>
                                </th>
                              </tr>

                          </tbody>
                        );
                      }
                    )}
                </table>
                <fieldset>
                    <legend>Ação</legend>
                    <table>
                      <thead>
                        <tr>
                          <th>Excluir todos os documentos?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            <button onClick={excluirTudoQuesitosJuizo}>
                              <i
                                className="fas fa-solid fa-trash perigo"
                                value={"excluirTudoQuesitosJuizo"}
                              ></i>
                            </button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </>
              )}
            </fieldset>
          </fieldset>
        </div>{" "}
      </fieldset>
    </>
  );
};
