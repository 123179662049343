import React, {useState} from 'react'
import { UtilitariosBuscaDanoEsteticoAipeCobo2, UtilitariosBuscaDanoEsteticoClassSocFrancesaMedLegal, UtilitariosBuscaDanoEsteticoPerezGarcia } from "../../utilitarios/UtilitariosBusca"
import styles from '../classificacaoDanoEstetico/ClassificacaoDanoEstetico.module.css'

export const ClassificacaoDanoEstetico = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ classificacaoDanoEstetico, setClassificacaoDanoEstetico] = useState([])
   
    const adicionarClassificacaoDanoEstetico = (e) => {

        e.preventDefault()

        const arrClassificacaoDanoEstetico = []
    
        arrClassificacaoDanoEstetico.push({
          //  perguntaClassificacaoDanoEstetico0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoDanoEstetico0: formValues.respostaClassificacaoDanoEstetico0 !== undefined ? formValues.respostaClassificacaoDanoEstetico0 : '',
            comentarioClassificacaoDanoEstetico0: formValues.comentarioClassificacaoDanoEstetico0 !== undefined ? formValues.comentarioClassificacaoDanoEstetico0 : '',     
        })
        setClassificacaoDanoEstetico([...arrClassificacaoDanoEstetico])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _74_classificacaoDanoEstetico: [{
           //     perguntaClassificacaoDanoEstetico0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoDanoEstetico0: formValues.respostaClassificacaoDanoEstetico0 !== undefined ? formValues.respostaClassificacaoDanoEstetico0 : '',
         comentarioClassificacaoDanoEstetico0: formValues.comentarioClassificacaoDanoEstetico0 !== undefined ? formValues.comentarioClassificacaoDanoEstetico0 : '',
            }]
      }))
    }

    const excluirClassificacaoDanoEstetico = (e) => {
        e.preventDefault()  

        setClassificacaoDanoEstetico([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _74_classificacaoDanoEstetico: []
              }))
    }

    const handleCarregarFormClassificacaoDanoEstetico = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._74_classificacaoDanoEstetico && tudo._74_classificacaoDanoEstetico.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._74_classificacaoDanoEstetico !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._74_classificacaoDanoEstetico !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._74_classificacaoDanoEstetico : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
   // perguntaClassificacaoDanoEstetico0: e !== undefined && e.perguntaClassificacaoDanoEstetico0 !== undefined ? e.perguntaClassificacaoDanoEstetico0 : '',
            respostaClassificacaoDanoEstetico0: e !== undefined &&  e.respostaClassificacaoDanoEstetico0 !== undefined ? e.respostaClassificacaoDanoEstetico0 : '',
            comentarioClassificacaoDanoEstetico0: e !== undefined &&  e.comentarioClassificacaoDanoEstetico0 !== undefined ? e.comentarioClassificacaoDanoEstetico0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparClassificacaoDanoEstetico = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaClassificacaoDanoEstetico'+e.target.attributes.value.value]: '', ['comentarioClassificacaoDanoEstetico'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxClassificacaoDanoEstetico} />
            <label htmlFor={styles.checkboxClassificacaoDanoEstetico}>
                <h2 className={styles.titulo}>Classificacao de Dano Estético</h2>
            </label>
            <fieldset id={styles.conteudoClassificacaoDanoEstetico}> 
            <fieldset>
                <legend>Utilitarios Dano Estético</legend>
                <>
                <UtilitariosBuscaDanoEsteticoPerezGarcia/>
                <UtilitariosBuscaDanoEsteticoClassSocFrancesaMedLegal/>
                <UtilitariosBuscaDanoEsteticoAipeCobo2/>
                </>
            </fieldset> 
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {/* <th>Pergunta</th> */}
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0"> 
                        <th><button onClick={handleLimparClassificacaoDanoEstetico}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>
                        <select 
                                name="respostaClassificacaoDanoEstetico0"
                                onChange={handleInputChange}
                                value={formValues.respostaClassificacaoDanoEstetico0 !== undefined ? formValues.respostaClassificacaoDanoEstetico0 : ''}
                            >
                                <option value="" defaultValue>Selecione:</option>
                                <option value="Ausente">Ausente</option>
                                  <option value="Não mensurável">Não mensurável</option>
                                  <option value="1/7 Muito leve">1/7 Muito leve</option>
                                  <option value="2/7 Leve">2/7 Leve</option>
                                  <option value="3/7 Médio">3/7 Médio</option>
                                  <option value="4/7 Moderado">4/7 Moderado</option>
                                  <option value="5/7 Considerável">5/7 Considerável</option>
                                  <option value="6/7 Importante">6/7 Importante</option>
                                  <option value="7/7 Muito importante">7/7 Muito importante</option>
                            </select> 
                            </th>
                        <th>
                        <textarea 
                            name="comentarioClassificacaoDanoEstetico0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite seu comentário aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioClassificacaoDanoEstetico0 !== undefined ? formValues.comentarioClassificacaoDanoEstetico0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarClassificacaoDanoEstetico}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormClassificacaoDanoEstetico}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados da Classificacao de Dano Estético</legend>
            {
            classificacaoDanoEstetico.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                           {/*  <th>Pergunta</th> */}
                            <th>Resposta</th>
                            <th>Comentário</th> 
                        </tr>
                    </thead>
                        {classificacaoDanoEstetico.length > 0 && classificacaoDanoEstetico.map(({ 
                           // perguntaClassificacaoDanoEstetico0,
                            respostaClassificacaoDanoEstetico0,
                            comentarioClassificacaoDanoEstetico0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                               {/*  <th>{perguntaClassificacaoDanoEstetico0}</th> */}
                                <th>{respostaClassificacaoDanoEstetico0}</th>
                                 <th>{comentarioClassificacaoDanoEstetico0}</th> 
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirClassificacaoDanoEstetico}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}