import React, {useState, useEffect,} from 'react'

export const DadosInss = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ inss, setInss] = useState([])

    const adicionarInss = () => {

            const arrInss = []
            arrInss.push({
               // id: inss !== undefined ? inss.length + 1 : '',
                idInss: formValues.idInss !== undefined ? formValues.idInss : '',
                inicioInss: formValues.inicioInss !== undefined && formValues.inicioInss.length === 10 ? formValues.inicioInss : '',
                fimInss: formValues.fimInss !== undefined && formValues.fimInss.length === 10 ? formValues.fimInss : '',
                qtdeDiasAfastadoInss: formValues.qtdeDiasAfastadoInss !== undefined ? formValues.qtdeDiasAfastadoInss : '',
                tipoBeneficio: formValues.tipoBeneficio !== undefined ? formValues.tipoBeneficio : '',
                situacaoBeneficio: formValues.situacaoBeneficio !== undefined ? formValues.situacaoBeneficio : '',
            })
            setInss([...inss, ...arrInss])

            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _12_inss: [...inss,{
                   // id: inss !== undefined ? inss.length + 1 : '',
                    idInss: formValues.idInss !== undefined ? formValues.idInss : '',
                    inicioInss: formValues.inicioInss !== undefined && formValues.inicioInss.length === 10 ? formValues.inicioInss : '',
                    fimInss: formValues.fimInss !== undefined && formValues.fimInss.length === 10 ? formValues.fimInss : '',
                    qtdeDiasAfastadoInss: formValues.qtdeDiasAfastadoInss !== undefined ? formValues.qtdeDiasAfastadoInss : '',
                    tipoBeneficio: formValues.tipoBeneficio !== undefined ? formValues.tipoBeneficio : '',
                    situacaoBeneficio: formValues.situacaoBeneficio !== undefined ? formValues.situacaoBeneficio : '',
                }]
          }))
        
    }

    const excluirInss = (e) => {

        e.preventDefault()
            inss.filter((
                el, i
                )=>{
               
                    if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {           
                            inss.splice(i, 1)
                            setInss([...inss])   
                            
                            localStorage.setItem('tudo', JSON.stringify({
                                ...JSON.parse(localStorage.getItem('tudo')) || [],
                                _12_inss: inss
                          }))
                        }    
                        return null
            })   
    }

    const excluirTudoInss = (e) => {
        e.preventDefault();
       // console.log(e.target.attributes.value.value)

          setInss([])
  
           localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _12_inss: []
            })
          ); 
      }
  
      const handleCarregarFormInss = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._12_inss && tudo._12_inss.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._12_inss !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._12_inss !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._12_inss : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              idInss: e !== undefined && e.idInss !== undefined && e.idInss !== ""
            ? e.idInss
            : "",

            inicioInss: e !== undefined && e.inicioInss !== undefined && e.inicioInss !== ""
            ? e.inicioInss
            : "",
            
            fimInss: e !== undefined && e.fimInss !== undefined && e.fimInss !== ''
            ? e.fimInss
            : "",
            
            qtdeDiasAfastadoInss: e !== undefined && e.qtdeDiasAfastadoInss !== undefined && e.qtdeDiasAfastadoInss !== ""
            ? e.qtdeDiasAfastadoInss
            : "",
  
            tipoBeneficio: e !== undefined && e.tipoBeneficio !== undefined && e.tipoBeneficio !== ""
            ? e.tipoBeneficio
            : "",
  
            situacaoBeneficio: e !== undefined && e.situacaoBeneficio !== undefined &&
            e.situacaoBeneficio !== ''
              ? e.situacaoBeneficio
              : "",
  
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._12_inss && tudo._12_inss.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._12_inss !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._12_inss !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._12_inss : []
      
              //console.log('***',a)
              const arrInss = []
          a.map((e)=> {
  
          return  arrInss.push({
            idInss: e !== undefined && e.idInss !== undefined && e.idInss !== ""
            ? e.idInss
            : "",

            inicioInss: e !== undefined && e.inicioInss !== undefined && e.inicioInss !== ""
            ? e.inicioInss
            : "",
            
            fimInss: e !== undefined && e.fimInss !== undefined && e.fimInss !== ''
            ? e.fimInss
            : "",
            
            qtdeDiasAfastadoInss: e !== undefined && e.qtdeDiasAfastadoInss !== undefined && e.qtdeDiasAfastadoInss !== ""
            ? e.qtdeDiasAfastadoInss
            : "",
  
            tipoBeneficio: e !== undefined && e.tipoBeneficio !== undefined && e.tipoBeneficio !== ""
            ? e.tipoBeneficio
            : "",
  
            situacaoBeneficio: e !== undefined && e.situacaoBeneficio !== undefined &&
            e.situacaoBeneficio !== ''
              ? e.situacaoBeneficio
              : "",
        }) 
    
    }
      )  

      setInss(arrInss)
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }

    useEffect(() => {
        
        if (formValues.inicioInssAmericano !== undefined &&
            formValues.inicioInssAmericano.length === 10) {
            formValues.inicioInss =`${formValues.inicioInssAmericano.split('-')[2]}-${formValues.inicioInssAmericano.split('-')[1]}-${formValues.inicioInssAmericano.split('-')[0]}` || ''
        }
        if (formValues.fimInssAmericano !== undefined &&
            formValues.fimInssAmericano.length === 10) {
            formValues.fimInss =`${formValues.fimInssAmericano.split('-')[2]}-${formValues.fimInssAmericano.split('-')[1]}-${formValues.fimInssAmericano.split('-')[0]}` || ''
        }
        if (formValues.inicioInssAmericano !== undefined && formValues.fimInssAmericano !== undefined) {
            formValues.qtdeDiasAfastadoInss = Math.ceil((((new Date(formValues.fimInssAmericano).getTime()) - new Date(formValues.inicioInssAmericano).getTime()) / (1000 * 60 * 60 * 24)) + 1)
        }
        if (formValues.dataDemissaoEmpresaAmericano !== undefined &&
            formValues.dataDemissaoEmpresaAmericano.length === 10) {
            formValues.dataDemissaoEmpresa =`${formValues.dataDemissaoEmpresaAmericano.split('-')[2]}-${formValues.dataDemissaoEmpresaAmericano.split('-')[1]}-${formValues.dataDemissaoEmpresaAmericano.split('-')[0]}` || ''
        }
        }, [
        formValues]
    )

    return (
        <>
         <fieldset>
        <legend>Dados do Inss</legend>
        <div >
        <fieldset >
          <table >
                    <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idInss"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="idInss"
                            maxLength="22"
                            className="capturaIdInss"
                            placeholder="Id - página"
                            onChange={handleInputChange}
                            value={formValues.idInss !== undefined ? formValues.idInss : ''}
                        /></th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"inicioInssAmericano"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span >Início</span>
                        <input
                            type="date"
                            name="inicioInssAmericano"
                            onChange={handleInputChange}
                            value={formValues.inicioInssAmericano !== undefined ? formValues.inicioInssAmericano : ''}
                        />
                      </div>
                      </th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"fimInssAmericano"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span >Cessação</span>
                        <input
                            type="date"
                            name="fimInssAmericano"
                            onChange={handleInputChange}
                            value={formValues.fimInssAmericano !== undefined ? formValues.fimInssAmericano : ''}
                        />
                      </div>
                      </th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"qtdeDiasAfastadoInss"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
<span>Quantidade de dias afastado</span>
                        <input
                        disabled
                            type="text"
                            name="qtdeDiasAfastadoInss"
                            onChange={handleInputChange}
                            value={formValues.qtdeDiasAfastadoInss !== undefined ? formValues.qtdeDiasAfastadoInss : ''}
                        />
                      </div>
                      </th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"tipoBeneficio"}
                      ></i>
                    </button></th>
                    <th>
                        <select
                            name="tipoBeneficio"
                            className="selectInss"
                            onChange={handleInputChange}
                            value={formValues.tipoBeneficio !== undefined ? formValues.tipoBeneficio : ''}
                        >
                            <option value="">Escolha o tipo do benefício</option>
                            <option value="B31 | Auxílio-doença">B31 | Auxílio-doença</option>
                            <option value="B32 | Aposentadoria por invalidez previdenciária">B32 | Aposentadoria por invalidez previdenciária</option>
                            <option value="B36 | Auxílio-acidente previdenciário">B36 | Auxílio-acidente previdenciário</option>
                            <option value="B41 | Aposentadoria por idade">B41 | Aposentadoria por idade</option>
                            <option value="B42 | Aposentadoria por tempo de contribuição">B42 | Aposentadoria por tempo de contribuição</option>
                            <option value="B46 | Aposentadoria especial">B46 | Aposentadoria especial</option>
                            <option value="B91 | Auxílio-doença por acidente do trabalho">B91 | Auxílio-doença por acidente do trabalho</option>
                            <option value="B92 | Aposentadoria por invalidez por acidente do trabalho">B92 | Aposentadoria por invalidez por acidente do trabalho</option>
                            <option value="B93 | Pensão por morte por acidente do trabalho">B93 | Pensão por morte por acidente do trabalho</option>
                            <option value="B94 | Auxílio-acidente por acidente do trabalho">B94 | Auxílio-acidente por acidente do trabalho</option>
                            <option value="indeferido">Indeferido</option>
                        </select></th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"situacaoBeneficio"}
                      ></i>
                    </button></th>
                    <th><div className="radios">
                            <span>Situação do beneficio</span>
                            <label>
                                <input
                                    type="radio"
                                    value="Deferido"
                                    name="situacaoBeneficio"
                                    onChange={handleInputChange}
                                    checked={formValues.situacaoBeneficio === 'Deferido'}
                                />
                                Deferido
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Indeferido"
                                    name="situacaoBeneficio"
                                    onChange={handleInputChange}
                                    checked={formValues.situacaoBeneficio === 'Indeferido'}
                                />
                                Indeferido
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Sem resultado"
                                    name="situacaoBeneficio"
                                    onChange={handleInputChange}
                                    checked={formValues.situacaoBeneficio === 'Sem resultado'}
                                />
                                Sem resultado
                            </label>
                        </div></th>
                </tr>
                   </tbody>     
                  </table>      
                        
                  <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarInss}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormInss}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>
                    
                    <fieldset>
                        <legend>Dados registrados do Inss</legend>
                        {inss.length === 0 ? <p>Sem Dados registrados</p> :
                            
                                <>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Tópico</th>
                                        <th>Informação</th>
                                      </tr>
                                    </thead>
                                    {inss.length > 0 && inss.map(({
                                        idInss,
                                        inicioInss,
                                        fimInss,
                                        qtdeDiasAfastadoInss,
                                        tipoBeneficio,
                                        situacaoBeneficio,
                                    }, i) => {
                                        return (
                            
                                            <tbody key={i}>
                                              <tr >
                                                <th>Registro número</th>
                                                <th> {i + 1}</th>
                                              </tr>
                                              <tr>
                                                <th>Id</th>
                                                <th>{idInss}</th>
                                              </tr>
              
                                              <tr>
                                                <th>Início</th>
                                                <th>{inicioInss}</th>
                                              </tr>
              
                                              <tr>
                                                <th>Fim</th>
                                                <th>{fimInss}</th>
                                              </tr>
              
                                              <tr>
                                                <th>Dias afastado</th>
                                                <th>{qtdeDiasAfastadoInss}</th>
                                              </tr>
              
                                              <tr>
                                                <th>Espécie</th>
                                                <th>{tipoBeneficio}</th>
                                              </tr>
                                              <tr>
                                                <th>Situação</th>
                                                <th>{situacaoBeneficio}</th>
                                              </tr>
                                              <tr>
                                                <th>Ação</th>
                                                <th >
                                                  <button onClick={excluirInss}>
                                                    <i
                                                      className="fas fa-solid fa-trash perigo"
                                                      value={i}
                                                    ></i>
                                                  </button>
                                                </th>
                                              </tr>
                                            </tbody>
                                          
                                        );
                                      }
                                    )}
                                </table>
                                <fieldset>
                                  <legend>Ação</legend>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Excluir todos os documentos?</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr >
                                        <th>
                                          <button onClick={excluirTudoInss}>
                                            <i
                                              className="fas fa-solid fa-trash perigo"
                                              value={"excluirTudoInss"}
                                            ></i>
                                          </button>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                              </>
                            }
                          </fieldset>
                        </fieldset>
                    </div>  </fieldset>
                    </>
                  );
}
