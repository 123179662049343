import React, {useState} from 'react'
import styles from '../criteriosFranchini/CriteriosFranchini.module.css'

export const CriteriosFranchini = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ criteriosFranchini, setCriteriosFranchini] = useState([])
   
    const adicionarCriteriosFranchini = (e) => {

        e.preventDefault()

        const arrCriteriosFranchini = []
    
        arrCriteriosFranchini.push({
            perguntaCriteriosFranchini0: "Cronológico: Existe respeito de certas relações temporais entre os fatos observados, antecedentes e subsequentes ao evento infortunístico.",
            respostaCriteriosFranchini0: formValues.respostaCriteriosFranchini0 !== undefined ? formValues.respostaCriteriosFranchini0 : '',
            comentarioCriteriosFranchini0: formValues.comentarioCriteriosFranchini0 !== undefined ? formValues.comentarioCriteriosFranchini0 : '',
            perguntaCriteriosFranchini1: "Adequação lesiva: A ação informado pode provocar a lesão existente.",
            respostaCriteriosFranchini1: formValues.respostaCriteriosFranchini1 !== undefined ? formValues.respostaCriteriosFranchini1 : '',
            comentarioCriteriosFranchini1: formValues.comentarioCriteriosFranchini1 !== undefined ? formValues.comentarioCriteriosFranchini1 : '',
            perguntaCriteriosFranchini2: "Continuidade fenomenológica: O aparecimento das manifestações somato-psíquico-funcionais guarda uma relação imediata com a exposição ao agente agressor.",
            respostaCriteriosFranchini2: formValues.respostaCriteriosFranchini2 !== undefined ? formValues.respostaCriteriosFranchini2 : '',
            comentarioCriteriosFranchini2: formValues.comentarioCriteriosFranchini2 !== undefined ? formValues.comentarioCriteriosFranchini2 : '',
            perguntaCriteriosFranchini3: "Topográfico: As relações entre o local alvo, o possível mecanismo de ação do instrumento ou do meio e o quadro clínico exibido na vítima.",
            respostaCriteriosFranchini3: formValues.respostaCriteriosFranchini3 !== undefined ? formValues.respostaCriteriosFranchini3 : '',
            comentarioCriteriosFranchini3: formValues.comentarioCriteriosFranchini3 !== undefined ? formValues.comentarioCriteriosFranchini3 : '',
            perguntaCriteriosFranchini4: "Epidemiológico: Existe prevalência dos achados com as frequências preestabelecidas, pondo em evidência as discrepâncias com aquilo 'quod plerumque accidit' (aquilo que geralmente acontece).",
            respostaCriteriosFranchini4: formValues.respostaCriteriosFranchini4 !== undefined ? formValues.respostaCriteriosFranchini4 : '',
            comentarioCriteriosFranchini4: formValues.comentarioCriteriosFranchini4 !== undefined ? formValues.comentarioCriteriosFranchini4 : '',
            perguntaCriteriosFranchini5: "conclusaoCriteriosFranchini",
            respostaCriteriosFranchini5: formValues.respostaCriteriosFranchini5 !== undefined ? formValues.respostaCriteriosFranchini5 : '',
            comentarioCriteriosFranchini5: formValues.comentarioCriteriosFranchini5 !== undefined ? formValues.comentarioCriteriosFranchini5 : '',
               
        })
        setCriteriosFranchini([...arrCriteriosFranchini])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _56_criteriosFranchini: [{
                perguntaCriteriosFranchini0: "Cronológico: Existe respeito de certas relações temporais entre os fatos observados, antecedentes e subsequentes ao evento infortunístico.",
            respostaCriteriosFranchini0: formValues.respostaCriteriosFranchini0 !== undefined ? formValues.respostaCriteriosFranchini0 : '',
            comentarioCriteriosFranchini0: formValues.comentarioCriteriosFranchini0 !== undefined ? formValues.comentarioCriteriosFranchini0 : '',
            perguntaCriteriosFranchini1: "Adequação lesiva: A ação informado pode provocar a lesão existente.",
            respostaCriteriosFranchini1: formValues.respostaCriteriosFranchini1 !== undefined ? formValues.respostaCriteriosFranchini1 : '',
            comentarioCriteriosFranchini1: formValues.comentarioCriteriosFranchini1 !== undefined ? formValues.comentarioCriteriosFranchini1 : '',
            perguntaCriteriosFranchini2: "Continuidade fenomenológica: O aparecimento das manifestações somato-psíquico-funcionais guarda uma relação imediata com a exposição ao agente agressor.",
            respostaCriteriosFranchini2: formValues.respostaCriteriosFranchini2 !== undefined ? formValues.respostaCriteriosFranchini2 : '',
            comentarioCriteriosFranchini2: formValues.comentarioCriteriosFranchini2 !== undefined ? formValues.comentarioCriteriosFranchini2 : '',
            perguntaCriteriosFranchini3: "Topográfico: As relações entre o local alvo, o possível mecanismo de ação do instrumento ou do meio e o quadro clínico exibido na vítima.",
            respostaCriteriosFranchini3: formValues.respostaCriteriosFranchini3 !== undefined ? formValues.respostaCriteriosFranchini3 : '',
            comentarioCriteriosFranchini3: formValues.comentarioCriteriosFranchini3 !== undefined ? formValues.comentarioCriteriosFranchini3 : '',
            perguntaCriteriosFranchini4: "Epidemiológico: Existe prevalência dos achados com as frequências preestabelecidas, pondo em evidência as discrepâncias com aquilo 'quod plerumque accidit' (aquilo que geralmente acontece).",
            respostaCriteriosFranchini4: formValues.respostaCriteriosFranchini4 !== undefined ? formValues.respostaCriteriosFranchini4 : '',
            comentarioCriteriosFranchini4: formValues.comentarioCriteriosFranchini4 !== undefined ? formValues.comentarioCriteriosFranchini4 : '',
            perguntaCriteriosFranchini5: "conclusaoCriteriosFranchini",
            respostaCriteriosFranchini5: formValues.respostaCriteriosFranchini5 !== undefined ? formValues.respostaCriteriosFranchini5 : '',
            comentarioCriteriosFranchini5: formValues.comentarioCriteriosFranchini5 !== undefined ? formValues.comentarioCriteriosFranchini5 : '',
            
            }]
      }))
    }

    const excluirCriteriosFranchini = (e) => {
        e.preventDefault()  

        setCriteriosFranchini([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _56_criteriosFranchini: []
              }))
    }

    const handleCarregarFormCriteriosFranchini = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._56_criteriosFranchini && tudo._56_criteriosFranchini.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._56_criteriosFranchini !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._56_criteriosFranchini !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._56_criteriosFranchini : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaCriteriosFranchini0: e !== undefined && e.perguntaCriteriosFranchini0 !== undefined ? e.perguntaCriteriosFranchini0 : '',
            respostaCriteriosFranchini0: e !== undefined &&  e.respostaCriteriosFranchini0 !== undefined ? e.respostaCriteriosFranchini0 : '',
            comentarioCriteriosFranchini0: e !== undefined &&  e.comentarioCriteriosFranchini0 !== undefined ? e.comentarioCriteriosFranchini0 : '',
            perguntaCriteriosFranchini1: e !== undefined && e.perguntaCriteriosFranchini1 !== undefined ? e.perguntaCriteriosFranchini1 : '',
            respostaCriteriosFranchini1: e !== undefined &&  e.respostaCriteriosFranchini1 !== undefined ? e.respostaCriteriosFranchini1 : '',
            comentarioCriteriosFranchini1: e !== undefined &&  e.comentarioCriteriosFranchini1 !== undefined ? e.comentarioCriteriosFranchini1 : '',
            perguntaCriteriosFranchini2: e !== undefined && e.perguntaCriteriosFranchini2 !== undefined ? e.perguntaCriteriosFranchini2 : '',
            respostaCriteriosFranchini2: e !== undefined &&  e.respostaCriteriosFranchini2 !== undefined ? e.respostaCriteriosFranchini2 : '',
            comentarioCriteriosFranchini2: e !== undefined &&  e.comentarioCriteriosFranchini2 !== undefined ? e.comentarioCriteriosFranchini2 : '',
            perguntaCriteriosFranchini3: e !== undefined && e.perguntaCriteriosFranchini3 !== undefined ? e.perguntaCriteriosFranchini3 : '',
            respostaCriteriosFranchini3: e !== undefined &&  e.respostaCriteriosFranchini3 !== undefined ? e.respostaCriteriosFranchini3 : '',
            comentarioCriteriosFranchini3: e !== undefined &&  e.comentarioCriteriosFranchini3 !== undefined ? e.comentarioCriteriosFranchini3 : '',
            perguntaCriteriosFranchini4: e !== undefined && e.perguntaCriteriosFranchini4 !== undefined ? e.perguntaCriteriosFranchini4 : '',
            respostaCriteriosFranchini4: e !== undefined &&  e.respostaCriteriosFranchini4 !== undefined ? e.respostaCriteriosFranchini4 : '',
            comentarioCriteriosFranchini4: e !== undefined &&  e.comentarioCriteriosFranchini4 !== undefined ? e.comentarioCriteriosFranchini4 : '',
            perguntaCriteriosFranchini5: e !== undefined && e.perguntaCriteriosFranchini5 !== undefined ? e.perguntaCriteriosFranchini5 : '',
            respostaCriteriosFranchini5: e !== undefined &&  e.respostaCriteriosFranchini5 !== undefined ? e.respostaCriteriosFranchini5 : '',
            comentarioCriteriosFranchini5: e !== undefined &&  e.comentarioCriteriosFranchini5 !== undefined ? e.comentarioCriteriosFranchini5 : '',
            
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparCriteriosFranchini = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaCriteriosFranchini'+e.target.attributes.value.value]: '', ['comentarioCriteriosFranchini'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxCriteriosFranchini} />
            <label htmlFor={styles.checkboxCriteriosFranchini}>
                <h2 className={styles.titulo}>Criterios de Franchini</h2>
            </label>
            <fieldset id={styles.conteudoCriteriosFranchini}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="Cronológico: Existe respeito de certas relações temporais entre os fatos observados, antecedentes e subsequentes ao evento infortunístico."> 
                        <th><button onClick={handleLimparCriteriosFranchini}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>Cronológico: Existe respeito de certas relações temporais entre os fatos observados, antecedentes e subsequentes ao evento infortunístico.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosFranchini0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini0 !== undefined ? formValues.respostaCriteriosFranchini0 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosFranchini0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini0 !== undefined ? formValues.respostaCriteriosFranchini0 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosFranchini0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini0 !== undefined ? formValues.respostaCriteriosFranchini0 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosFranchini0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini0 !== undefined ? formValues.respostaCriteriosFranchini0 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosFranchini0"
                                placeholder="Comentários do criterio de Franchini"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosFranchini0 !== undefined ? formValues.comentarioCriteriosFranchini0 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Adequação lesiva: A ação informado pode provocar a lesão existente.">
                        <th><button onClick={handleLimparCriteriosFranchini}><i className="fas fa-trash perigo" value={'1'}></i></button></th>
                        <th>Adequação lesiva: A ação informado pode provocar a lesão existente.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosFranchini1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini1 !== undefined ? formValues.respostaCriteriosFranchini1 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosFranchini1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini1 !== undefined ? formValues.respostaCriteriosFranchini1 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosFranchini1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini1 !== undefined ? formValues.respostaCriteriosFranchini1 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosFranchini1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini1 !== undefined ? formValues.respostaCriteriosFranchini1 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosFranchini1"
                                placeholder="Comentários do criterio de Franchini"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosFranchini1 !== undefined ? formValues.comentarioCriteriosFranchini1 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Continuidade fenomenológica: O aparecimento das manifestações somato-psíquico-funcionais guarda uma relação imediata com a exposição ao agente agressor.">
                        <th><button onClick={handleLimparCriteriosFranchini}><i className="fas fa-trash perigo" value={'2'}></i></button></th>
                        <th>Continuidade fenomenológica: O aparecimento das manifestações somato-psíquico-funcionais guarda uma relação imediata com a exposição ao agente agressor.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosFranchini2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini2 !== undefined ? formValues.respostaCriteriosFranchini2 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosFranchini2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini2 !== undefined ? formValues.respostaCriteriosFranchini2 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosFranchini2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini2 !== undefined ? formValues.respostaCriteriosFranchini2 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosFranchini2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini2 !== undefined ? formValues.respostaCriteriosFranchini2 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosFranchini2"
                                placeholder="Comentários do criterio de Franchini"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosFranchini2 !== undefined ? formValues.comentarioCriteriosFranchini2 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Topográfico: As relações entre o local alvo, o possível mecanismo de ação do instrumento ou do meio e o quadro clínico exibido na vítima.">
                        <th><button onClick={handleLimparCriteriosFranchini}><i className="fas fa-trash perigo" value={'3'}></i></button></th>
                        <th>Topográfico: As relações entre o local alvo, o possível mecanismo de ação do instrumento ou do meio e o quadro clínico exibido na vítima.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosFranchini3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini3 !== undefined ? formValues.respostaCriteriosFranchini3 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosFranchini3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini3 !== undefined ? formValues.respostaCriteriosFranchini3 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosFranchini3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini3 !== undefined ? formValues.respostaCriteriosFranchini3 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosFranchini3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini3 !== undefined ? formValues.respostaCriteriosFranchini3 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosFranchini3"
                                placeholder="Comentários do criterio de Franchini"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosFranchini3 !== undefined ? formValues.comentarioCriteriosFranchini3 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Epidemiológico: Existe prevalência dos achados com as frequências preestabelecidas, pondo em evidência as discrepâncias com aquilo 'quod plerumque accidit' (aquilo que geralmente acontece).">
                        <th><button onClick={handleLimparCriteriosFranchini}><i className="fas fa-trash perigo" value={'4'}></i></button></th>
                        <th>Epidemiológico: Existe prevalência dos achados com as frequências preestabelecidas, pondo em evidência as discrepâncias com aquilo 'quod plerumque accidit' (aquilo que geralmente acontece).</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosFranchini4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini4 !== undefined ? formValues.respostaCriteriosFranchini4 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosFranchini4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini4 !== undefined ? formValues.respostaCriteriosFranchini4 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosFranchini4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini4 !== undefined ? formValues.respostaCriteriosFranchini4 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosFranchini4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini4 !== undefined ? formValues.respostaCriteriosFranchini4 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosFranchini4"
                                placeholder="Comentários do criterio de Franchini"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosFranchini4 !== undefined ? formValues.comentarioCriteriosFranchini4 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="conclusaoCriteriosFranchini">
                        <th><button onClick={handleLimparCriteriosFranchini}><i className="fas fa-trash perigo" value={'5'}></i></button></th>
                        <th>conclusaoCriteriosFranchini</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosFranchini5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini5 !== undefined ? formValues.respostaCriteriosFranchini5 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosFranchini5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini5 !== undefined ? formValues.respostaCriteriosFranchini5 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosFranchini5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini5 !== undefined ? formValues.respostaCriteriosFranchini5 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosFranchini5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosFranchini5 !== undefined ? formValues.respostaCriteriosFranchini5 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosFranchini5"
                                placeholder="Comentários do criterio de Franchini"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosFranchini5 !== undefined ? formValues.comentarioCriteriosFranchini5 : ''}
                            />
                        </th>
                    </tr>
                                        
                </tbody>
            </table>
            <div>
            <button onClick={adicionarCriteriosFranchini}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormCriteriosFranchini}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados dos Critérios de Franchini</legend>
            {
            criteriosFranchini.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            {/* <th>Ação</th> */}
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th>Comentário</th>
                        </tr>
                    </thead>
                        {criteriosFranchini.length > 0 && criteriosFranchini.map(({ 
                            perguntaCriteriosFranchini0,
                            respostaCriteriosFranchini0,
                            comentarioCriteriosFranchini0,
                            perguntaCriteriosFranchini1,
                            respostaCriteriosFranchini1,
                            comentarioCriteriosFranchini1,
                            perguntaCriteriosFranchini2,
                            respostaCriteriosFranchini2,
                            comentarioCriteriosFranchini2,
                            perguntaCriteriosFranchini3,
                            respostaCriteriosFranchini3,
                            comentarioCriteriosFranchini3,
                            perguntaCriteriosFranchini4,
                            respostaCriteriosFranchini4,
                            comentarioCriteriosFranchini4,
                            perguntaCriteriosFranchini5,
                            respostaCriteriosFranchini5,
                            comentarioCriteriosFranchini5,
                             
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaCriteriosFranchini0}</th>
                                <th>{respostaCriteriosFranchini0}</th>
                                <th>{comentarioCriteriosFranchini0}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosFranchini1}</th>
                                <th>{respostaCriteriosFranchini1}</th>
                                <th>{comentarioCriteriosFranchini1}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosFranchini2}</th>
                                <th>{respostaCriteriosFranchini2}</th>
                                <th>{comentarioCriteriosFranchini2}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosFranchini3}</th>
                                <th>{respostaCriteriosFranchini3}</th>
                                <th>{comentarioCriteriosFranchini3}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosFranchini4}</th>
                                <th>{respostaCriteriosFranchini4}</th>
                                <th>{comentarioCriteriosFranchini4}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosFranchini5}</th>
                                <th>{respostaCriteriosFranchini5}</th>
                                <th>{comentarioCriteriosFranchini5}</th>
                            </tr>
                           
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirCriteriosFranchini}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}
