import React from "react";
import { AppRoutes } from "./routes/Routes";
/* import { MostraEmpresas } from "./components/MostraEmpresas"; */
import { AuthGoogleProvider } from "./contexts/authGoogle";
/* import Card from "./layout/Card";
import DadosPerito from "./components/DadosPerito";
import { MostraReferencialTecnico } from "./components/MostraRefTecnico";
import { MostraPericias } from "./components/MostraPericias";
import { Cadastro } from "./components/Cadastro"; */


export const App = () => {

  return (
    
    <>
    <AuthGoogleProvider>
    
      <AppRoutes />
      
      
    </AuthGoogleProvider>
      
    </>
    
    
  )
}
