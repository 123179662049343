export const SofrimentosPadecidosQualitativos = [
  {
    "sofrimentoPadecido": "Mínimo (0,5 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Não há hospitalização, nem atos cirúrgicos ou reabilitação. Tratamento analgésico de curta duração. Deficiência temporária de alguns dias. Exemplo: contusão benigna sem internação ou ato cirúrgico, deficiência laboral de curta duração, alguns dias de tratamento analgésico",
    "sociedadeFrancesaMedicinaLegal2007": "Curta hospitalização para observação ou pequeno ato cirúrgico em ambulatório.Tratamento analgésico, observação médica de até 10 dias, sem tratamento de reabilitaçãoExemplo: Ferimentos suturados, lesões dentais tratadas."
  },
  {
    "sofrimentoPadecido": "Muito Leve (1 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Curta hospitalização ou pequena cirurgia ambulatorial .;Incapacidade laboral por cerca de uma semana.;Tratamento analgésico ou observação por 10 dias.;Exemplo: ferida suturada durante o tratamento em ambulatório ou durante uma internação de um dia ou dois; lesão dentária que exigem cuidados e a colocação de uma prótese.",
    "sociedadeFrancesaMedicinaLegal2007": "Hospitalização de 01 dia ou pequena cirurgia sob anestesia geral ou ambulatorial.;Tratamento analgésico ou ansiolíticos durante várias semanas, algumas sessões de reabilitação, imobilização curta.;Exemplo: traumatismo cervical, lesão na cabeça leve, esquince nas extremidades.",
    "brousseauRousseau1983": "Ferimento superficial que precisasse de uma cura não removível; simples hematomas. Exame clínico e radiológico sem internação, sem tratamento; ferida na sobrancelha isolada, com pontos e sem internação.",
    "sociedadeFrancesaMedicinaForense1991": "Contusão com uma pequena ferida suturada: internação curta ou sem necessidade de internação; pouco ou nenhum impedimento ao trabalho ou limitado a poucos dias.",
    "lambertFaivre1996": "Contusão com pequena ferida suturada.;Nenhuma ou curta Hospitalização;Incapacidades limitadas por alguns dias."
  },
  {
    "sofrimentoPadecido": "Muito Leve (1,5 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Hospitalização de um ou dois dias com pequena cirurgia.;Tratamento analgésico em uma ou duas semanas.;Deficiência temporária de duas semanas.;Exemplo: traumatismo craniano benigna, com perda de memória, que justifique a 48 h de hospitalização e observação; contusão cervical ou fratura de costela que justificou uma breve hospitalização, tratamento analgésico de duas semanas e incapacidade funcional temporária de algumas semanas.",
    "sociedadeFrancesaMedicinaLegal2007": "Hospitalização de 12 dias.;Imobilização de um membro ou pescoço durante 23 semanas;Exemplo: Traumatismo Cervical documentado radiologicamente, fratura de esterno, fratura de falanges, sutura do tendão."
  },
  {
    "sofrimentoPadecido": "Leve (2 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Imobilização de um membro ou o pescoço por 2 ou 3 semanas.;Internação de menos de uma semana.;Deficiência temporária de 15 dias a um mês.;Algumas sessões de fisioterapia (10 a 15).;Exemplo: dor cervical que justificou uso de colar de imobilização por algumas semanas, uma internação de alguns dias, algumas sessões de reabilitação e um período de incapacidade para o trabalho em torno de um mês; fratura do polegar tratadas com imobilização com gesso; fratura de dedo imobilizada com tala e reabilitação de algumas semanas, uma reabilitação e uma incapacidade para o trabalho da ordem de um mês; fraturas de várias costelas ou esterno, sem complicação. ",
    "sociedadeFrancesaMedicinaLegal2007": "Hospitalização de 12 dias.;Imobilização de um membro por 26 semanas.;Reabilitação com sessões: de 5 a 10 dias.;Exemplo: fratura de costela, fratura de esterno com deslocamento, sutura de tendão e fratura de perônio.",
    "brousseauRousseau1983": "Traumatismo craniano com uma breve perda de consciência. Sutura de couro cabeludo e hospitalização de 1 a 3 dias; trauma cervical sem fratura, que precisa de afastamento e uso de analgésicos, com regressão dos sintomas em duas ou três semanas; fratura de punho sem desvio, imobilização de punho com gesso por 3 a 4 semanas e doze sessões de fisioterapia , fratura de 1 a 3 costelas sem deslocamento; fratura sem deslocamento de púbis tratados somente com repouso.",
    "sociedadeFrancesaMedicinaForense1991": "Lesões que precisavam de uma simples imobilização de 15 a 20 dias (entorse cervical imobilizada com colar, fratura de punho sem desvio, imobilizado com gesso fratura); internação hospitalar de 510 dias; e eventualmente precisar de 10 a 15 sessões de fisioterapia; traumatiamo craniano com hospitalização de concussão simples por uma semana.",
    "lambertFaivre1996": "Lesões que tiveram necessidade de uma imobilização simples de 15 a 20 dias (entorse cervical imobilizada por colar, fratura do polegar);5 a 10 dias de internação.;Possivelmente de 10 a 15 sessões de reeducação.;Trauma craniano com concussão simples, hospitalização de 1 semana",
    "thierryNicourt1981": "Traumatismo craniano com breve perda de consciência. Sutura de um ferimento no couro cabeludo e internação de curto prazo; dor cervical sem fratura tratada com analgésicos, com regressão dos sintomas em 1520 dias.",
    "baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982": "TCE com breve perda de consciência, sutura do couro cabeludo, internação de curto prazo; dor cervical sem fratura tratada com analgésicos, com regressão dos sintomas em 20 dias"
  },
  {
    "sofrimentoPadecido": "Leve (2,5 / 7)",
    "sociedadeFrancesaMedicinaLegal2007": "25 dias de internação hospitalar; Incapacidade de 3 semanas a 1 mês, procedimento cirúrgico, sob anestesia local ou geral.;Sessões de Fisioterapia: 10 a 15;Psicotrópicos associados com antidepressivo, ansiolítico e tratamento hipnótico ou psicoterapia por 6 meses.;Exemplo: Fratura de costela, fratura do polegar, fratura na perna com pouco deslocamento. Esmagamento da espinha dorsal com fratura lombar."
  },
  {
    "sofrimentoPadecido": "Moderada (3 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Intervenção sob anestesia geral ou locoregional (por exemplo: osteossíntese).;Hospitalização de duas a três semanas.;Reeducação (30 sessões).;Exemplo: Fratura de tíbia e fíbula, fêmur e úmero com osteossíntese, com internação em torno de uma semana, imobilização de dois meses, incapacidade para o trabalho de dois a três meses; fratura de mandíbula tratada por osteossíntese ou imobilização prolongada; laparotomia, esplenectomia, nefrectomia ou hemorragia abdominal.",
    "sociedadeFrancesaMedicinaLegal2007": "510 dias de hospitalização;Imobilização de 1 a 2 meses.; Intervenção sob anestesia geral ou locorregional.;Sessões de reabilitação: 30.;Tratamento associado com antidepressivos, ansiolíticos e hipnóticos psicotrópicos ou psicoterapia por mais de 1 ano e até a data da consolidação. No caso em que o tratamento é feito a mais de 1 ano e até a data da consolidação a, eles sofrem alteração e podem ser de 3.5 / 7.;Exemplo: Fratura da perna, de fêmur, fratura do úmero tratada por redução e osteossíntese.",
    "brousseauRousseau1983": "Fratura fechada da perna, imobilização com gesso de 6 a 8 semanas, hospitalização de 23 semanas e 20 a 30 sessões de reabilitação; politraumatismo que precisa de exames dolorosos, mas sem intervenção cirúrgica e hospitalização de 2 semanas; traumatismo abdominal isolado, esplenectomia, consequências simples.",
    "sociedadeFrancesaMedicinaForense1991": "Fratura com desvio tratada por redução sob anestesia geral ou por osteossíntese: internação de 15 dias a 3 semanas, imobilização de 2 a 3 meses, 30 sessões de reabilitação; trauma de tórax com fratura de costelas, sem complicações; fratura por compressão da coluna vertebral dorsal ou lombar, tratado com imobilização simples durante algumas semanas e reeducação.",
    "lambertFaivre1996": "Fratura com desvio, tratado por redução sob anestesia geral ou por osteossíntese.;Internação de 15 dias a 3 semanas.;Em torno de 30 sessões de reabilitação.;Imobilização de 2 a 3 meses.;Trauma de tórax com fratura de costelas, sem complicações.; Fratura da coluna vertebral dorsal ou lombar tratados com imobilização simples por algumas semanas, após a reabilitação.",
    "thierryNicourt1981": "Fratura de pulso sem deslocamento notável, imobilizado por 34 semanas, seguido por 1015 sessões de reabilitação.",
    "baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982": "Fratura de punho sem desvio significativo, imobilizado por gesso por 3 semanas a um mês, seguido por 15 sessões de reabilitação."
  },
  {
    "sofrimentoPadecido": "Médio (4 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Várias intervenções cirúrgicas.;Imobilização com tração (por extensão continua, com fixação externa) ou intervenção em diversos segmentos dos membros, por várias vezes.;Hospitalização de um ou dois meses.;Incapacidade por 6 meses ou mais.;Reeducação durante vários meses.;Exemplos: fratura complexa de membros inferiores, que precisaria de várias intervenções cirúrgicas, imobilização prolongada, hospitalização da ordem de um mês, reabilitação de vários meses e a incapacidade funcional temporária de mais de seis meses; trauma torácico grave com necessidade de cuidados de reanimação, internação de um ou dois meses, uma reeducação prolongada; politraumatismo; traumatismo craniano grave, com intervenção neurocirúrgica e reanimação prolongada por várias semanas, seguido de uma reabilitação durante vários meses.",
    "sociedadeFrancesaMedicinaLegal2007": "Hospitalização de 1 a 2 meses.;Várias intervenções cirúrgicas, sob anestesia geral ou regional.;Imobilização durante vários meses.;Reabilitação por vários meses.;Tratamento associado com antidepressivo, ansiolítico e hipnótico psicotrópicos ou psicoterapia para mais de 1 ano e até a data da consolidação. No caso em que o tratamento é feito a mais de 1 ano e até a data da consolidação, eles sofrem montado podem ser de 4.5 / 7.;Exemplo: fratura complexa dos membros inferiores que já precisava de várias intervenções, colocar em tração, complicação evolutiva tipo causalgia, pseudartrose, traumatismo craniano que já precisou de uma cirurgia. Fratura mandibular com osteossíntese ou bloqueio.Psicotraumatismo grave após o ataque.",
    "brousseauRousseau1983": "Fratura da perna tratada por fixação seguida de imobilização com gesso de 2 a 3 meses, hospitalização por 3 a 4 semanas, 30 sessões de reabilitação; trauma toracoabdominal com fraturas de costelas e ruptura do rim, precisando de uma Nefrectomia, sem complicações; luxação do ombro com fratura dos componentes da glenóide, a redução sob anestesia geral. Hospitalização por 1 semana. Imobilização de 4 a 6 semanas. 30 sessões de reabilitação.",
    "sociedadeFrancesaMedicinaForense1991": "Fratura complexa que precisou várias intervenções cirúrgicas; pseudoartrose de evolução prolongada; trauma de tórax com fratura de costela pneumotórax ou hemotórax e drenagem; hospitalização por 1 mês ou mais; Reabilitação por vários meses; traumatismo craniano com necessidade de intervenção neurocirúrgica.",
    "lambertFaivre1996": "Fratura complexa com necessidade de várias intervenções;Pseudoartrose (evolução prolongada, reoperação).;Trauma de tórax com fratura de costelas quebradas, pneumotórax ou hemotórax (drenagem).;Hospitalização por um mês ou mais.;Reabilitação durante vários meses;Traumatismo craniano com necessidade de intervenção neurocirúrgica.",
    "thierryNicourt1981": "Fratura fechada de dois ossos da perna, tratada pela osteossíntese ou imobilização com gesso. Suporte progressivo após 3 meses e 20 a 30 sessões de reabilitação.",
    "baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982": "Fratura fechada de dois ossos da perna, tratadas por osteossíntese e imobilização com gesso. Suporte progressivo após três meses e trinta sessões de reabilitação."
  },
  {
    "sofrimentoPadecido": "Consideravel (5 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Várias cirurgias por um período prolongado.;Hospitalização de dois a seis meses.;Deficiência temporária de 6 meses a um ano.;Exemplo: paraplegia por fratura da coluna vertebral, com ou sem intervenção cirúrgica, com permanência hospitalar de um ou dois meses no serviço ativo, e dois ou três meses no serviço de reabilitação; traumatismo cranioencefálico que exigem reanimação pelo menos um mês e reabilitação de quatro a seis meses, com uma recuperação gradual em casa; politraumatismo associado a lesões de diversos segmentos dos membros, ou com torax instavel ou de lesão vascular grave, que precisa de intervenção cirúrgica para transplante; evolução de uma fratura de osso longo, complicado com pseudartrose de osteíte necessárias várias cirurgias durante um longo período de tempo.",
    "sociedadeFrancesaMedicinaLegal2007": "Internação de 2 a 6 meses.;Várias operações cirúrgicas, por um período de 6 a 9 meses.;Reabilitação há mais de 6 meses.;Exemplo: Paraplegia por fratura da coluna, trauma craniocerebral, politraumatismo, englobando várias lesões ortopédicas traumáticas dos membros, lesão vasculonervoso que precisa de vários tratamentos cirúrgicos.",
    "brousseauRousseau1983": "Trauma Craniofacial com fratura da mandíbula tratada com osteossíntese e bloqueio mandibular de 46 semanas; politraumatizado que exigem várias intervenções, fratura de cabeça de fêmur tratados por osteossíntese ou prótese cervical, fratura aberta da perna complicada por pseudoartrose que necessite de várias intervenções e várias hospitalizações.",
    "sociedadeFrancesaMedicinaForense1991": "Politraumatismo que está ligado a várias intervenções; fratura de face com bloqueio maxilar por osteosintese; trauma de tórax com fraturas multiplas de costela; fratura complicada de lesões vasculares nervosas que têm necessárias várias intervenções e reabilitação prolongada; fratura da coluna vertebral com complicações neurológicas; trauma cerebral com problemas neurológicos, exigindo reciclagem especializada e cinesioterapia.",
    "lambertFaivre1996": "Politraumatismo com necessidade de várias intervenções.;Fratura de face que precisou de travamento da articulação da mandíbula após osteossíntese.;Trauma torácico com torax instável.;Fratura complicada com lesões vascularesnervosas, que têm necessitado várias intervenções e uma longa reabilitação.;Fratura da coluna vertebral com complicações neurológicas.",
    "thierryNicourt1981": "Trauma craniofacial com fratura do maxilar tratado por osteossíntese e bloqueio bimaxilar durante um mês mais fraturas da diáfise dos dois ossos do antebraço, tratados com placas e imobilização. Mantida durante 30 dias de internação. 30 sessões de reabilitação.",
    "baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982": "Traumatismo craneofacial com fratura de maxilares, tratado mediante osteossíntese e bloqueio bimaxilar, durante um mês, mais fratura diafisaria de umero e rádio tratado por placas e imobilização por gesso; hospitalização de 30 dias e 30 dias de reabilitação."
  },
  {
    "sofrimentoPadecido": "Importante (6 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Várias intervenções cirúrgicas.;Internação da ordem de 1 ano.;Incapacidade de temporária da ordem de 18 meses.;Reabilitação muito prolongada.;Tetraplegia que necessite de estadia no serviço de cirurgia por dois meses e estadia no centro de reabilitação de 6 a 10 meses. TCE gravíssimo que necessite de uma estancia de reanimação de dois ou três meses e reabilitação prolongada da ordem de 1 ano; extensas queimadoras que necessite de serviço especializado durante vários meses, de intervenções cirúrgicas e cuidados especializados.",
    "sociedadeFrancesaMedicinaLegal2007": "Hospitalização de cerca de um ano.;Várias intervenções cirúrgicas.;Reabilitação prolongada.;Exemplo: várias lesões traumáticas ortopédicas ou viscerais graves que tenham necessitado intervenções reiterativas. Tetraplegia com síndrome deficitária, Tetraplegia com síndrome deficitária, traumatismo craniano com hemiplegia e síndrome deficitária, queimaduras extensas.",
    "brousseauRousseau1983": "Trauma de tórax com volet costal e hemopneumotórax, sendo necessária reanimação e drenagem pleural, reeducação respiratória; luxação do quadril, luxação de quadril, necrose secundária da cabeça do femur necessitando, secundariamente, de uma prótese total de quadril; traumatismo craniano com coma prolongado, tempo de internação longo.",
    "sociedadeFrancesaMedicinaForense1991": "Várias lesões traumáticas graves; intervenções cirúrgicas múltiplas ou repetitivos; reeducação muito longa por deficiencias neurológicas ou neuropsicológicos; queimaduras com vários meses de tratamento.",
    "lambertFaivre1996": "Várias lesões traumáticas graves.;Cirurgias múltiplas ou repetitivas;Reabilitação muito prolongada para síndromes de déficit neurológico e neuropsicológica.;Queimaduras que necessitem vários meses de tratamento.",
    "thierryNicourt1981": "Trauma de torax com volet costal e hemopneumotórax, que precisou de ressuscitação, drenagem pleural e reeducação respiratória de 20 sessões. Fratura do úmero direito com paralisia radial, osteossíntese, sutura nervosa, progressiva recuperação da paralisia radial. Luxação do quadril tratada por redução. Necrose secundária da cabeça do fêmur que é necessária uma colocação de prótese total. Fratura exposta de perna tratada por ostessíntese com pino contromedular. Várias internações, totalizando 90 dias. Longo tratamento de reabilitação, em torno de 100 sessões com uma frequência de 23 sessões por semana.",
    "baremoFuncionalIndicativoDeficienciaPermanenteDireitoComum1982": "Trauma craniofacial com fratura da mandíbula, tratada por osteossíntese e bloqueio bimaxilaar durante um mês; fratura diafisária do rádio e ulna com placas e imobilização por gesso, hospitalização por 30 dias e 30 sessões de reabilitação."
  },
  {
    "sofrimentoPadecido": "Muito Importante (6 / 7)",
    "baremoAvaliacaoMedicoLegal2000": "Tudo o que excede os situações anteriores: excepcionalmente, dependendo da intensidade e duração do sofrimento.",
    "sociedadeFrancesaMedicinaLegal2007": "Situações que excedem os critérios anteriores excepcionalmente dependendo da intensidade e duração do sofrimento.;Exemplo: internações iterativas de vários anos por queimaduras ou intervenções cirúrgicas ortopédicas em grandes números complicadas tipo osteíte, de atraso de consolidação, de amputação.",
    "brousseauRousseau1983": "Queimaduras extensas, tratamentos repetidos, pontos, longa internação; paraplegia ou tetraplegia, longa permanencia no hospital; fratura complicado que precise múltiplas intervenções especializadas; Fratura aberta complicada que requer várias intervenções e possibilidade de amputação.",
    "sociedadeFrancesaMedicinaForense1991": "Polilesionadas hospitalizado por vários meses ou queimaduras sofreram várias intervenções.",
    "lambertFaivre1996": "Polilesionado hospitalizado durante vários meses, queimaduras que sofreram várias intervenções."
  }
]
