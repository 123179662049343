import React, {useState} from 'react'
import styles from '../ntep/Ntep.module.css'

import { NtepFap4Modificado } from '../../../data/NtepFap4Modificado'

export const Ntep = () => {

    const [ ntep, setNtep] = useState([])

    const adicionarNtep = (e) => {
        e.preventDefault()

    const getPolo2 = localStorage.getItem('tudo') !== null && localStorage.getItem('tudo')._03_polo2 !== null ? JSON.parse(localStorage.getItem('tudo'))._03_polo2 : []/* localStorage.setItem('tudo', JSON.stringify({...JSON.parse(localStorage.getItem('tudo')) || [], _50_ntep: []})) */
    
    let cnaeReclamada = ''
    if(getPolo2 !== null && getPolo2 !== undefined) {
        cnaeReclamada = getPolo2[0].length > 0 ? getPolo2[0].cnaeFiscal.toString().substring(0,4) : ''      
    } 

    const getAtestados = localStorage.getItem('tudo') !== null && localStorage.getItem('tudo')._11_atestados !== null ? JSON.parse(localStorage.getItem('tudo'))._11_atestados : []/* localStorage.setItem('tudo', JSON.stringify({...JSON.parse(localStorage.getItem('tudo')) || [], _50_ntep: []})) */
    
    let cidAtestados = []
    if(getAtestados !== null && getAtestados !== undefined) {
        getAtestados.map(({cid},i)=>{
            return cidAtestados.push(...cid)
        })
    }

        const arrNtep = []
        cidAtestados.map(({codigo, descricao},i)=>{
            NtepFap4Modificado.filter((e,i)=>{
                
                    if (e.intervaloCid10.includes(codigo.substring(0,3)) && e.cnae.includes(cnaeReclamada) && cnaeReclamada !== ''){
                        return arrNtep.push({
                            id: ntep.length + 1,
                            codigo: codigo,
                            descricao: descricao,
                            cnae: cnaeReclamada !== '' ? cnaeReclamada : 'Sem dados do CNAE',
                            ntep: cnaeReclamada !== '' ? 'Existe NTEP' : '' 
                        })    
                    }           
                return null 
        })
        return null
    })

    let x = []
    arrNtep.map(({codigo})=> x.push(codigo))

    cidAtestados.map(({codigo, descricao})=>{
        if(!x.includes(codigo)){
            return arrNtep.push({
                id: ntep.length + 1,
                codigo: codigo,
                descricao: descricao,
                cnae: cnaeReclamada !== '' ? cnaeReclamada : 'Sem dados do CNAE',
                ntep: cnaeReclamada !== '' ? 'Não existe NTEP' : '' 
            }) 
        }
        return null
    })

        setNtep(arrNtep)

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _50_ntep: arrNtep
      }))
   }

const excluirNtep = (e) => {
    e.preventDefault()      
    ntep.filter((
        el, i
    )=>{
        //console.log(e.target, e.target.attributes)
        if (e.target.attributes.value !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                
            ntep.splice(i, 1)
            setNtep([...ntep])

            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _50_ntep: ntep
          }))
        }    
        return null
    })
}

const handleCarregarFormNtep = () => {
        
    const tudo = JSON.parse(localStorage.getItem('tudo'))
    if (tudo._50_ntep && tudo._50_ntep.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._50_ntep !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._50_ntep !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._50_ntep : []

        //console.log('***',a)
        const arrNtep = []
    a.map((e)=> 
        arrNtep.push({
             codigo: e !== undefined && e.codigo !== undefined ? e.codigo : '',
            descricao: e !== undefined &&  e.descricao !== undefined ? e.descricao : '',
            cnae: e !== undefined &&  e.cnae !== undefined ? e.cnae : '',
            ntep: e !== undefined && e.ntep !== undefined ? e.ntep : '', 
    }) 
    
)  
setNtep(arrNtep)
}

}

const handleReset = () => {
    setNtep([])
}

//console.log(ntep)

  return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxNtep} />
            <label htmlFor={styles.checkboxNtep}>
                <h2 className={styles.titulo}>Ntep</h2>
            </label>
            <fieldset id={styles.conteudoNtep}>  
            <table className={styles.table}></table>
    
    <div>
    <button onClick={adicionarNtep}><i className="fas fa-plus"></i></button>
    <button onClick={handleCarregarFormNtep}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
    <fieldset>
        <legend>Ntep Registrado</legend>
        {ntep.length === 0 ? <p>Sem dados registrados</p> : 
            <table>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>CID</th>
                        <th>Ntep</th>
                        <th>Cnae</th>
                    </tr>
                </thead>
                <tbody>
                    {ntep.length > 0 && ntep.map(({
                        id, 
                        codigo,
                        descricao,
                        ntep,
                        cnae
                    }, i)=>{

                    return(
                        <tr key={i}>
                            <th><button onClick={excluirNtep}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                            <th>{codigo} - {descricao}</th>
                            <th>{ntep}</th>
                            <th>{cnae}</th>
                        </tr>)
                    })}  
                </tbody>
            </table>}
    </fieldset>
    </fieldset> 
    </fieldset>
   </>
  )
}
