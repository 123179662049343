import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthGoogleContext } from "../contexts/authGoogle";
import { TipoUsuarioProvider } from "../contexts/tipoUsuarioContexto";
import { ErrorBoundaryProvider } from "../contexts/errorBoudaryContexto";
import { StripePagamentoProvider } from "../contexts/stripePagamentoContexto";

export const PrivateRoutes = () => {
  const { logado } = useContext(AuthGoogleContext);

  return logado ? (
    <ErrorBoundaryProvider>
      <StripePagamentoProvider>
        <TipoUsuarioProvider>
          <Outlet />{" "}
        </TipoUsuarioProvider>
      </StripePagamentoProvider>
    </ErrorBoundaryProvider>
  ) : (
    <Navigate to="/" />
  );
};
