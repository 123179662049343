import React, { useState, useEffect } from 'react'
import styles from './DadosPeritoEspecialidade.module.css'

export const DadosPeritoEspecialidade = () => {

  const [formValues, setFormValues] = useState({})

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === 'checkbox'
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  }

  //const getDadosPeritoEspecialidade = JSON.parse(localStorage.getItem('@dadosPeritoEspecialidade'))
  const [dadosPeritoEspecialidade, setDadosPeritoEspecialidade] = useState(/* getDadosPeritoEspecialidade || */ [])

  const adicionarDadosPeritoEspecialidade = () => {

    const arrDadosPeritoEspecialidade = []
    arrDadosPeritoEspecialidade.push({
      id: dadosPeritoEspecialidade.length + 1,
      especialidade: formValues.especialidade,
    })
    if (formValues.especialidade) {
      setDadosPeritoEspecialidade([...dadosPeritoEspecialidade, ...arrDadosPeritoEspecialidade])

      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _82_dadosPeritoEspecialidade: [...dadosPeritoEspecialidade,{
            id: dadosPeritoEspecialidade.length + 1,
            especialidade: formValues.especialidade,
        }]
  }))
      //sessionStorage.setItem('@dadosPeritoEspecialidade', JSON.stringify(dadosPeritoEspecialidade))
      //localStorage.setItem('@dadosPeritoEspecialidade', JSON.stringify(dadosPeritoEspecialidade))
    }
  }

  const excluirDadosPeritoEspecialidade = (e) => {
    e.preventDefault()
    dadosPeritoEspecialidade.filter((
      el, i
    ) => {
      if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {

        dadosPeritoEspecialidade.splice(i, 1)
        setDadosPeritoEspecialidade([...dadosPeritoEspecialidade])

        localStorage.setItem('tudo', JSON.stringify({
          ...JSON.parse(localStorage.getItem('tudo')) || [],
          _82_dadosPeritoEspecialidade: dadosPeritoEspecialidade
    }))
   
      }
      return null
    })
  }

  useEffect(() => {

    //sessionStorage.setItem('@dadosPeritoEspecialidade', JSON.stringify(dadosPeritoEspecialidade))
    //localStorage.setItem('@dadosPeritoEspecialidade', JSON.stringify(dadosPeritoEspecialidade))

  }, [/* adicionarDadosPeritoEspecialidade,
    excluirDadosPeritoEspecialidade, */dadosPeritoEspecialidade
  ])

  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxEspecialidade} />
        <label htmlFor={styles.checkboxEspecialidade}>
          <h2 className={styles.titulo}>Especialidades</h2>
        </label>
        <fieldset id={styles.conteudoEspecialidade}>
          <table className={styles.table}></table>
          <input
            type="text"
            name="especialidade"
            size="30"
            /* minLength="6" 
            maxLength="6"  */
            /* className="crm"  */
            placeholder="Digite sua especialidade(s)..."
            onChange={handleInputChange}
            value={formValues.especialidade || ''}
          />
          <button onClick={adicionarDadosPeritoEspecialidade}>Adicionar Especialidades</button>
          <fieldset>
            <legend>Especialidades Registradas</legend>
            {dadosPeritoEspecialidade.length === 0 ? <p>Sem dados registrados</p> :
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Especialidade(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dadosPeritoEspecialidade.length > 0 && dadosPeritoEspecialidade.map(({
                      id,
                      especialidade
                    }, i) => {
                      return (
                        <tr key={i}>
                          <th><button onClick={excluirDadosPeritoEspecialidade}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                          <th>{especialidade}</th>
                        </tr>)
                    }
                    )}
                  </tbody>
                </table>
              </>}
          </fieldset>
        </fieldset>
      </fieldset>


    </>
  )

}