/* import React from 'react' */
import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyBDV-FIdxDVZd5u_KfR9MlyK0jT7HPN5FI",
  authDomain: "davincipericias.firebaseapp.com",
  databaseURL: "https://davincipericias-default-rtdb.firebaseio.com",
  projectId: "davincipericias",
  storageBucket: "davincipericias.appspot.com",
  messagingSenderId: "98009487082",
  appId: "1:98009487082:web:49dbcd5edac0ae80566993",
  measurementId: "G-92GC3YLZWV"
  });

export const analytics = getAnalytics(firebaseApp)

export const db = getFirestore(firebaseApp)

export const auth = getAuth(firebaseApp)

logEvent(analytics, 'notification_received')