import React, {useEffect, useState} from 'react'

export const DadosPolo1 = () => {
    const [formValues, setFormValues] = useState({})
  
      const handleInputChange = (e) => {
          const { name, value, type } = e.target;
      
          const data = formValues[name] || {};
          const isCheckbox = type === 'checkbox'
          const newValue = isCheckbox  ? data : value;
          
          setFormValues({...formValues,  [name]: newValue  });
          //console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
      }
  
      const [ polo1, setPolo1] = useState([])
     
      const adicionarPolo1 = (e) => {
  
          e.preventDefault()
  
          let idadePolo1 = "";
  
      if (formValues.dataNascimentoAmericanoPolo1) {
        idadePolo1 = Math.floor(
          Math.ceil(
            Math.abs(
              new Date(formValues.dataNascimentoAmericanoPolo1).getTime() -
                formValues.hoje.getTime()
            ) /
              (1000 * 3600 * 24)
          ) / 365.25
        );
      }
      //console.log(idade)
  
          const arrPolo1 = []
      
          arrPolo1.push({
              nomePolo1: formValues.nomePolo1 !== undefined && formValues.nomePolo1 !== ""
              ? formValues.nomePolo1
              : "Erro!",
              cpfPolo1: formValues.cpfPolo1 !== undefined && formValues.cpfPolo1.length === 14
              ? formValues.cpfPolo1
              : "Erro!",
              generoPolo1: formValues.generoPolo1 !== undefined && formValues.generoPolo1 !== ""
              ? formValues.generoPolo1
              : "Erro!",
              tipoPolo1: formValues.tipoPolo1 !== undefined && formValues.tipoPolo1 !== ""
              ? formValues.tipoPolo1
              : "Erro!",
              dataNascimentoAmericanoPolo1: formValues.dataNascimentoAmericanoPolo1 !== undefined &&
              formValues.dataNascimentoAmericanoPolo1.length === 10
                ? formValues.dataNascimentoAmericanoPolo1
                : "Erro!",
              dataNascimentoPolo1: formValues.dataNascimentoPolo1 !== undefined &&
              formValues.dataNascimentoPolo1.length === 10 &&
              formValues.dataNascimentoPolo1 !== "01-01-0001" &&
              formValues.dataNascimentoAmericanoPolo1 !== "Erro!"
                ? formValues.dataNascimentoPolo1
                : "Erro!",
              idadePolo1: idadePolo1 !== undefined && idadePolo1 !== "" ? idadePolo1 : "Erro!",
              estadoCivilPolo1: formValues.estadoCivilPolo1 !== undefined && formValues.estadoCivilPolo1 !== ""
              ? formValues.estadoCivilPolo1
              : "Erro!",
              estadoCivilComentarioPolo1: formValues.estadoCivilComentarioPolo1 !== undefined &&
              formValues.estadoCivilComentarioPolo1 !== ""
                ? formValues.estadoCivilComentarioPolo1
                : "Erro!",
              filhosPolo1: formValues.filhosPolo1 !== undefined && formValues.filhosPolo1 !== ""
              ? formValues.filhosPolo1
              : "Erro!",
              filhosComentarioPolo1: formValues.filhosComentarioPolo1 &&
              (formValues.filhosComentarioPolo1 !== "") !== undefined
                ? formValues.filhosComentarioPolo1
                : "Erro!",
              escolaridadePolo1: formValues.escolaridadePolo1 !== undefined && formValues.escolaridadePolo1 !== ""
              ? formValues.escolaridadePolo1
              : "Erro!",
              escolaridadeComentarioPolo1: formValues.escolaridadeComentarioPolo1 &&
              (formValues.escolaridadeComentarioPolo1 !== "") !== undefined
                ? formValues.escolaridadeComentarioPolo1
                : "Erro!",
              dominanciaPolo1: formValues.dominanciaPolo1 !== undefined && formValues.dominanciaPolo1 !== ""
              ? formValues.dominanciaPolo1
              : "Erro!",
              habitosPolo1: formValues.habitosPolo1 !== undefined && formValues.habitosPolo1 !== ""
              ? formValues.habitosPolo1
              : "Erro!",
              habitosComentarioPolo1: formValues.habitosComentarioPolo1 !== undefined &&
              formValues.habitosComentarioPolo1 !== ""
                ? formValues.habitosComentarioPolo1
                : "Erro!",   
          })
          setPolo1(arrPolo1)
  
          localStorage.setItem('tudo', JSON.stringify({
              ...JSON.parse(localStorage.getItem('tudo')) || [],
              _02_polo1: [{
                nomePolo1: formValues.nomePolo1 !== undefined && formValues.nomePolo1 !== ""
                ? formValues.nomePolo1
                : "",
                cpfPolo1: formValues.cpfPolo1 !== undefined && formValues.cpfPolo1.length === 14
                ? formValues.cpfPolo1
                : "",
                generoPolo1: formValues.generoPolo1 !== undefined && formValues.generoPolo1 !== ""
                ? formValues.generoPolo1
                : "",
                tipoPolo1: formValues.tipoPolo1 !== undefined && formValues.tipoPolo1 !== ""
                ? formValues.tipoPolo1
                : "",
                dataNascimentoAmericanoPolo1: formValues.dataNascimentoAmericanoPolo1 !== undefined &&
                formValues.dataNascimentoAmericanoPolo1.length === 10
                  ? formValues.dataNascimentoAmericanoPolo1
                  : "",
                dataNascimentoPolo1: formValues.dataNascimentoPolo1 !== undefined &&
                formValues.dataNascimentoPolo1.length === 10 &&
                formValues.dataNascimentoPolo1 !== "01-01-0001" &&
                formValues.dataNascimentoAmericanoPolo1 !== ""
                  ? formValues.dataNascimentoPolo1
                  : "",
                idadePolo1: idadePolo1 !== undefined && idadePolo1 !== "" ? idadePolo1 : "",
                estadoCivilPolo1: formValues.estadoCivilPolo1 !== undefined && formValues.estadoCivilPolo1 !== ""
                ? formValues.estadoCivilPolo1
                : "",
                estadoCivilComentarioPolo1: formValues.estadoCivilComentarioPolo1 !== undefined &&
                formValues.estadoCivilComentarioPolo1 !== ""
                  ? formValues.estadoCivilComentarioPolo1
                  : "",
                filhosPolo1: formValues.filhosPolo1 !== undefined && formValues.filhosPolo1 !== ""
                ? formValues.filhosPolo1
                : "",
                filhosComentarioPolo1: formValues.filhosComentarioPolo1 &&
                (formValues.filhosComentarioPolo1 !== "") !== undefined
                  ? formValues.filhosComentarioPolo1
                  : "",
                escolaridadePolo1: formValues.escolaridadePolo1 !== undefined && formValues.escolaridadePolo1 !== ""
                ? formValues.escolaridadePolo1
                : "",
                escolaridadeComentarioPolo1: formValues.escolaridadeComentarioPolo1 &&
                (formValues.escolaridadeComentarioPolo1 !== "") !== undefined
                  ? formValues.escolaridadeComentarioPolo1
                  : "",
                dominanciaPolo1: formValues.dominanciaPolo1 !== undefined && formValues.dominanciaPolo1 !== ""
                ? formValues.dominanciaPolo1
                : "",
                habitosPolo1: formValues.habitosPolo1 !== undefined && formValues.habitosPolo1 !== ""
                ? formValues.habitosPolo1
                : "",
                habitosComentarioPolo1: formValues.habitosComentarioPolo1 !== undefined &&
                formValues.habitosComentarioPolo1 !== ""
                  ? formValues.habitosComentarioPolo1
                  : "",   
            }]
        }))
      }
  
      const excluirPolo1 = (e) => {
        e.preventDefault();
        polo1.filter((el, i) => {
          if (
            e.target.attributes.value.value !== undefined &&
            e.target.attributes.value.value === String(i)
          ) {
            polo1.splice(i, 1);
            setPolo1([...polo1]);
    
            localStorage.setItem(
              "tudo",
              JSON.stringify({
                ...(JSON.parse(localStorage.getItem("tudo")) || []),
                _02_polo1: [...polo1],
              })
            );
          }
          return null;
        });
      }
  
      const excluirTudoPolo1 = (e) => {
        e.preventDefault();
       
          setPolo1([])
  
          localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _02_polo1: []
            })
          );
      }
  
      const handleCarregarFormPolo1 = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._02_polo1 && tudo._02_polo1.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._02_polo1 : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              nomePolo1: e !== undefined && e.nomePolo1 !== undefined && e.nomePolo1 !== ""
            ? e.nomePolo1
            : "Erro!",
            
            cpfPolo1: e !== undefined && e.cpfPolo1 !== undefined && e.cpfPolo1.length === 14
            ? e.cpfPolo1
            : "Erro!",
            
            generoPolo1: e !== undefined && e.generoPolo1 !== undefined && e.generoPolo1 !== ""
            ? e.generoPolo1
            : "Erro!",
  
            tipoPolo1: e !== undefined && e.tipoPolo1 !== undefined && e.tipoPolo1 !== ""
            ? e.tipoPolo1
            : "Erro!",
  
            dataNascimentoAmericanoPolo1: e !== undefined && e.dataNascimentoAmericanoPolo1 !== undefined &&
            e.dataNascimentoAmericanoPolo1.length === 10
              ? e.dataNascimentoAmericanoPolo1
              : "Erro!",
  
            dataNascimentoPolo1: e !== undefined && e.dataNascimentoPolo1 !== undefined &&
            e.dataNascimentoPolo1.length === 10 &&
            e.dataNascimentoPolo1 !== "01-01-0001" &&
            e.dataNascimentoAmericanoPolo1 !== "Erro!"
              ? e.dataNascimentoPolo1
              : "Erro!",
  
            idadePolo1: e !== undefined && e.idadePolo1 !== undefined && e.idadePolo1 !== "" ? e.idadePolo1 : "Erro!",
  
            estadoCivilPolo1: e !== undefined && e.estadoCivilPolo1 !== undefined && e.estadoCivilPolo1 !== ""
            ? e.estadoCivilPolo1
            : "Erro!",
  
            estadoCivilComentarioPolo1: e !== undefined && e.estadoCivilComentarioPolo1 !== undefined &&
            e.estadoCivilComentarioPolo1 !== ""
              ? e.estadoCivilComentarioPolo1
              : "Erro!",
  
            filhosPolo1: e !== undefined && e.filhosPolo1 !== undefined && e.filhosPolo1 !== ""
            ? e.filhosPolo1
            : "Erro!",
  
            filhosComentarioPolo1: e !== undefined && e.filhosComentarioPolo1 &&
            (e.filhosComentarioPolo1 !== "") !== undefined
              ? e.filhosComentarioPolo1
              : "Erro!",
  
            escolaridadePolo1: e !== undefined && e.escolaridadePolo1 !== undefined && e.escolaridadePolo1 !== ""
            ? e.escolaridadePolo1
            : "Erro!",
  
            escolaridadeComentarioPolo1: e !== undefined && e.escolaridadeComentarioPolo1 &&
            (e.escolaridadeComentarioPolo1 !== "") !== undefined
              ? e.escolaridadeComentarioPolo1
              : "Erro!",
  
            dominanciaPolo1: e !== undefined && e.dominanciaPolo1 !== undefined && e.dominanciaPolo1 !== ""
            ? e.dominanciaPolo1
            : "Erro!",
  
            habitosPolo1: e !== undefined && e.habitosPolo1 !== undefined && e.habitosPolo1 !== ""
            ? e.habitosPolo1
            : "Erro!",
  
            habitosComentarioPolo1: e !== undefined && e.habitosComentarioPolo1 !== undefined &&
            e.habitosComentarioPolo1 !== ""
              ? e.habitosComentarioPolo1
              : "Erro!",   
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._02_polo1 && tudo._02_polo1.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._02_polo1 !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._02_polo1 : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setPolo1([{
              nomePolo1: e !== undefined && e.nomePolo1 !== undefined && e.nomePolo1 !== ""
            ? e.nomePolo1
            : "Erro!",
            
            cpfPolo1: e !== undefined && e.cpfPolo1 !== undefined && e.cpfPolo1.length === 14
            ? e.cpfPolo1
            : "Erro!",
            
            generoPolo1: e !== undefined && e.generoPolo1 !== undefined && e.generoPolo1 !== ""
            ? e.generoPolo1
            : "Erro!",
  
            tipoPolo1: e !== undefined && e.tipoPolo1 !== undefined && e.tipoPolo1 !== ""
            ? e.tipoPolo1
            : "Erro!",
  
            dataNascimentoAmericanoPolo1: e !== undefined && e.dataNascimentoAmericanoPolo1 !== undefined &&
            e.dataNascimentoAmericanoPolo1.length === 10
              ? e.dataNascimentoAmericanoPolo1
              : "Erro!",
  
            dataNascimentoPolo1: e !== undefined && e.dataNascimentoPolo1 !== undefined &&
            e.dataNascimentoPolo1.length === 10 &&
            e.dataNascimentoPolo1 !== "01-01-0001" &&
            e.dataNascimentoAmericanoPolo1 !== "Erro!"
              ? e.dataNascimentoPolo1
              : "Erro!",
  
            idadePolo1: e !== undefined && e.idadePolo1 !== undefined && e.idadePolo1 !== "" ? e.idadePolo1 : "Erro!",
  
            estadoCivilPolo1: e !== undefined && e.estadoCivilPolo1 !== undefined && e.estadoCivilPolo1 !== ""
            ? e.estadoCivilPolo1
            : "Erro!",
  
            estadoCivilComentarioPolo1: e !== undefined && e.estadoCivilComentarioPolo1 !== undefined &&
            e.estadoCivilComentarioPolo1 !== ""
              ? e.estadoCivilComentarioPolo1
              : "Erro!",
  
            filhosPolo1: e !== undefined && e.filhosPolo1 !== undefined && e.filhosPolo1 !== ""
            ? e.filhosPolo1
            : "Erro!",
  
            filhosComentarioPolo1: e !== undefined && e.filhosComentarioPolo1 &&
            (e.filhosComentarioPolo1 !== "") !== undefined
              ? e.filhosComentarioPolo1
              : "Erro!",
  
            escolaridadePolo1: e !== undefined && e.escolaridadePolo1 !== undefined && e.escolaridadePolo1 !== ""
            ? e.escolaridadePolo1
            : "Erro!",
  
            escolaridadeComentarioPolo1: e !== undefined && e.escolaridadeComentarioPolo1 &&
            (e.escolaridadeComentarioPolo1 !== "") !== undefined
              ? e.escolaridadeComentarioPolo1
              : "Erro!",
  
            dominanciaPolo1: e !== undefined && e.dominanciaPolo1 !== undefined && e.dominanciaPolo1 !== ""
            ? e.dominanciaPolo1
            : "Erro!",
  
            habitosPolo1: e !== undefined && e.habitosPolo1 !== undefined && e.habitosPolo1 !== ""
            ? e.habitosPolo1
            : "Erro!",
  
            habitosComentarioPolo1: e !== undefined && e.habitosComentarioPolo1 !== undefined &&
            e.habitosComentarioPolo1 !== ""
              ? e.habitosComentarioPolo1
              : "Erro!",   
        }])
      )  
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }
  
      useEffect(() => {
        if (
          formValues.dataNascimentoAmericanoPolo1 !== undefined &&
          formValues.dataNascimentoAmericanoPolo1.length === 10
        ) {
          formValues.dataNascimentoPolo1 =
            `${formValues.dataNascimentoAmericanoPolo1.split("-")[2]}-${
              formValues.dataNascimentoAmericanoPolo1.split("-")[1]
            }-${formValues.dataNascimentoAmericanoPolo1.split("-")[0]}` || "";
          formValues.hoje = new Date();
        }
    
        if (formValues.cpfPolo1 !== undefined) {
          //console.log(formValues.cpfPolo1.length)
          formValues.cpfPolo1 = formValues.cpfPolo1
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})/, "$1-$2")
            .replace(/(-\d{2})\d+$/, "$1");
    
          //console.log(formValues.cpf.length)
        }
      }, [formValues, polo1]);
  
  
      return (
      <>
      <fieldset>
      <legend>Polo Ativo</legend>
        <div >
        <fieldset >
              <table >
                <thead>
                  <tr>
                    <th>Limpar Campo</th>
                    <th>Informação</th>
                  </tr>
                </thead>
              </table>
              <table >
                  <tbody>
                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'nomePolo1'}></i></button></th>
                          <th>
                          <input
              type="text"
              name="nomePolo1"
              maxLength="40"
              className="capturaNome"
              placeholder="Nome"
              onChange={handleInputChange}
              value={formValues.nomePolo1 !== undefined ? formValues.nomePolo1 : ""}
            />
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'cpfPolo1'}></i></button></th>
                          <th>
                          <input
              type="text"
              name="cpfPolo1"
              size="14"
              minLength="14"
              maxLength="14"
              placeholder="CPF"
              onChange={handleInputChange}
              value={
                formValues.cpfPolo1 !== undefined
                  ? formValues.cpfPolo1
                      .trim()
                      .replace(/\D/g, "")
                      .replace(/(\d{3})(\d)/, "$1.$2")
                      .replace(/(\d{3})(\d)/, "$1.$2")
                      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
                      .replace(/(-\d{2})\d+$/, "$1")
                  : ""
              }
            /> 
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'tipoPolo1'}></i></button></th>
                          <th>
                          <div className="radios">
              <span>Tipo do Polo</span>
              <label>
                <input
                  type="radio"
                  value="Polo ativo"
                  name="tipoPolo1"
                  onChange={handleInputChange}
                  checked={formValues.tipoPolo1 !== undefined ? formValues.tipoPolo1 === "Polo ativo" : ''}
                />
                Polo ativo
              </label>
              <label>
                <input
                  type="radio"
                  value="Polo Passivo"
                  name="tipoPolo1"
                  onChange={handleInputChange}
                  checked={formValues.tipoPolo1 !== undefined ? formValues.tipoPolo1 === "Polo Passivo" : ''}
                />
                Polo passivo
              </label>
            </div>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'generoPolo1'}></i></button></th>
                          <th>
                          <select
              name="generoPolo1"
              className="selectGenero"
              onChange={handleInputChange}
              value={formValues.generoPolo1 !== undefined ? formValues.generoPolo1 : ""}
            >
              <option value="">Selecione o gênero</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
              <option value="Nenhuma das opções">Nenhuma das opções</option>
            </select> 
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'dataNascimentoAmericanoPolo1'}></i></button></th>
                          <th>

                <div>
                <span>Data de Nascimento</span>
            <input
              type="date"
              name="dataNascimentoAmericanoPolo1"
              onChange={handleInputChange}
              value={
                formValues.dataNascimentoAmericanoPolo1 !== undefined &&
                formValues.dataNascimentoAmericanoPolo1 !== ""
                  ? formValues.dataNascimentoAmericanoPolo1
                  : ""
              }
            />
                </div>
                       
                          </th> 
                      </tr>
                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'estadoCivilPolo1'}></i></button></th>
                          <th>
                          <select
              name="estadoCivilPolo1"
              className="selectEstadoCivil"
              onChange={handleInputChange}
              value={
                formValues.estadoCivilPolo1 !== undefined ? formValues.estadoCivilPolo1 : ""
              }
            >
              <option value="">Estado civil</option>
              <option value="Solteiro">Solteiro</option>
              <option value="União estável">União estável</option>
              <option value="Casado">Casado</option>
              <option value="Divorciado">Divorciado</option>
              <option value="Viúvo">Viúvo</option>
            </select>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'estadoCivilComentarioPolo1'}></i></button></th>
                          <th>
                          <input
              type="text"
              name="estadoCivilComentarioPolo1"
              placeholder="Comentários sobre o estado civil"
              onChange={handleInputChange}
              value={
                formValues.estadoCivilComentarioPolo1 !== undefined
                  ? formValues.estadoCivilComentarioPolo1
                  : ""
              }
            />
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'filhosPolo1'}></i></button></th>
                          <th>
                          <div className="radios">
              <span>Possui filhos?</span>
              <label>
                <input
                  type="radio"
                  value="Sim"
                  name="filhosPolo1"
                  onChange={handleInputChange}
                  checked={formValues.filhosPolo1 !== undefined ? formValues.filhosPolo1 === "Sim" : ''}
                />
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  value="Não"
                  name="filhosPolo1"
                  onChange={handleInputChange}
                  checked={formValues.filhosPolo1 !== undefined ? formValues.filhosPolo1 === "Não" : ''}
                />
                Não
              </label>
            </div>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'filhosComentarioPolo1'}></i></button></th>
                          <th>
                          <input
              type="text"
              name="filhosComentarioPolo1"
              placeholder="Comentários sobre filhos"
              onChange={handleInputChange}
              value={
                formValues.filhosComentarioPolo1 !== undefined
                  ? formValues.filhosComentarioPolo1
                  : ""
              }
            />
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'escolaridadePolo1'}></i></button></th>
                          <th>
                          <select
              name="escolaridadePolo1"
              className="selectEscolaridade"
              onChange={handleInputChange}
              value={
                formValues.escolaridadePolo1 !== undefined
                  ? formValues.escolaridadePolo1
                  : ""
              }
            >
              <option value="">Selecione a escolaridade</option>
              <option value="Analfabeto">Analfabeto</option>
              <option value="Ensino fundamental incompleto">
                Ensino fundamental incompleto
              </option>
              <option value="Ensino fundamental completo">
                Ensino fundamental completo
              </option>
              <option value="Ensino médio incompleto">
                Ensino médio incompleto
              </option>
              <option value="Ensino médio completo">Ensino médio completo</option>
              <option value="Ensino superior incompleto">
                Ensino superior incompleto
              </option>
              <option value="Ensino superior completo">
                Ensino superior completo
              </option>
              <option value="Pós graduação">Pós graduação</option>
              <option value="Mestrado">Mestrado</option>
              <option value="Doutorado">Doutorado</option>
            </select>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'escolaridadeComentarioPolo1'}></i></button></th>
                          <th>
                          <input
              type="text"
              name="escolaridadeComentarioPolo1"
              placeholder="Comentários sobre a escolaridade"
              onChange={handleInputChange}
              value={
                formValues.escolaridadeComentarioPolo1 !== undefined
                  ? formValues.escolaridadeComentarioPolo1
                  : ""
              }
            /> 
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'dominanciaPolo1'}></i></button></th>
                          <th>
                          <select
              name="dominanciaPolo1"
              className="selectDominancia"
              onChange={handleInputChange}
              value={
                formValues.dominanciaPolo1 !== undefined ? formValues.dominanciaPolo1 : ""
              }
            >
              <option value="">Selecione a dominância</option>
              <option value="Destro">Destro</option>
              <option value="Sinistro">Sinistro</option>
              <option value="Ambidestro">Ambidestro</option>
            </select>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'habitosPolo1'}></i></button></th>
                          <th>
                          <select
              name="habitosPolo1"
              className="selectHabitos"
              onChange={handleInputChange}
              value={formValues.habitosPolo1 !== undefined ? formValues.habitosPolo1 : ""}
            >
              <option value="">Selecione os hábitos</option>
              <option value="Nega tabagismo, uso de bebidas alcoólicas e droga ilícitas">
                Nega tabagismo, uso de bebidas alcoólicas e droga ilícitas
              </option>
              <option value="Nega tabagismo">Nega tabagismo</option>
              <option value="Fuma eventualmente">Fuma eventualmente</option>
              <option value="Fuma habitualmente">Fuma habitualmente</option>
              <option value="Ex tabagista">Ex tabagista</option>
              <option value="Nega uso de bebidas alcoólicas">
                Nega uso de bebidas alcoólicas
              </option>
              <option value="Uso de bebidas alcoólicas eventualmente">
                Uso de bebidas alcoólicas eventualmente
              </option>
              <option value="Uso de bebidas alcoólicas habitualmente">
                Uso de bebidas alcoólicas habitualmente
              </option>
              <option value="Ex etilista">Ex etilista</option>
              <option value="Nega uso de drogas ilícitas">
                Nega uso de drogas ilícitas
              </option>
              <option value="Uso de drogas ilícitas eventualmente">
                Uso de drogas ilícitas eventualmente
              </option>
              <option value="Uso de drogas ilícitas habitualmente">
                Uso de drogas ilícitas habitualmente
              </option>
              <option value="Ex drogadicto">Ex drogadicto</option>
            </select>
                          </th> 
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}><i className="fas fa-trash perigo" value={'habitosComentarioPolo1'}></i></button></th>
                          <th>
                          <input
              type="text"
              name="habitosComentarioPolo1"
              placeholder="Comentários sobre a hábitos"
              onChange={handleInputChange}
              value={
                formValues.habitosComentarioPolo1 !== undefined
                  ? formValues.habitosComentarioPolo1
                  : ""
              }
            />
                          </th> 
                      </tr>
  
                  </tbody>
              </table>
              
              <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarPolo1}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormPolo1}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>


          <fieldset>
              <legend>Dados registrados do Polo 1</legend>
              {
              polo1.length === 0 ? <p>Sem dados registrados</p> : 
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                          {polo1.length > 0 && polo1.map(({ 
                              
                              nomePolo1,
                              cpfPolo1,
                              tipoPolo1,
                              generoPolo1,
                              dataNascimentoPolo1,
                              idadePolo1,
                              escolaridadePolo1,
                              escolaridadeComentarioPolo1,
                              estadoCivilPolo1,
                              estadoCivilComentarioPolo1,
                              filhosPolo1,
                              filhosComentarioPolo1,
                              dominanciaPolo1,
                              habitosPolo1,
                              habitosComentarioPolo1
                      
                          }, i)=>{
  
                          return(
                          
                            <tbody key={i}>
                            <tr >
                              <th>Registro número</th>
                              <th> {i + 1}</th>
                            </tr>
                               
                              <tr >
                                
                                <th>Nome</th>
                                  <th> {nomePolo1}</th>
                              </tr>

                              <tr>
                              <th>Cpf</th>
                              <th>{cpfPolo1}</th>
                            </tr>

                            <tr>
                              <th>Tipo polo</th>
                              <th>{tipoPolo1}</th>
                            </tr>

                            <tr>
                              <th>Gênero</th>
                              <th>{generoPolo1}</th>
                            </tr>

                            <tr>
                              <th>Data de nascimento</th>
                              <th>{dataNascimentoPolo1}</th>
                            </tr>

                            <tr>
                              <th>Idade</th>
                              <th>{idadePolo1}</th>
                            </tr>

                            <tr>
                              <th>Escolaridade</th>
                              <th>{escolaridadePolo1}</th>
                            </tr>

                            <tr>
                              <th>Comentário escolaridade</th>
                              <th>{escolaridadeComentarioPolo1}</th>
                            </tr>

                            <tr>
                              <th>Estado civil</th>
                              <th>{estadoCivilPolo1}</th>
                            </tr>

                            <tr>
                              <th>Comentário Estado civil</th>
                              <th>{estadoCivilComentarioPolo1}</th>
                            </tr>

                            <tr>
                              <th>Filhos</th>
                              <th>{filhosPolo1}</th>
                            </tr>

                            <tr>
                              <th>Comentário filhos</th>
                              <th>{filhosComentarioPolo1}</th>
                            </tr>

                            <tr>
                              <th>Dominância</th>
                              <th>{dominanciaPolo1}</th>
                            </tr>

                            <tr>
                              <th>Hábitos</th>
                              <th>{habitosPolo1}</th>
                            </tr>

                            <tr>
                              <th>Comentário hábitos</th>
                              <th>{habitosComentarioPolo1}</th>
                            </tr>

                            <tr>
                            <th>Ação</th>
                            <th >
                              <button onClick={excluirPolo1}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoPolo1}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoPolo1"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
        </fieldset>
      </div>
      </fieldset>
    </>
  );
};
