import React, {useState} from 'react'
import styles from '../valoracaoDesnecessaria/ValoracaoDesnecessaria.module.css'

export const ValoracaoDesnecessaria = () => {
    const [formValues, setFormValues] = useState({})
    
        const handleInputChange = (e) => {
            const { name, value, type } = e.target;
        
            const data = formValues[name] || {};
            const isCheckbox = type === 'checkbox'
            const newValue = isCheckbox  ? data : value;
            
            setFormValues({...formValues,  [name]: newValue  });
           // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
        }
    
        const [ valoracaoDesnecessaria, setValoracaoDesnecessaria] = useState([])
       
        const adicionarValoracaoDesnecessaria = (e) => {
    
            e.preventDefault()
    
            const arrValoracaoDesnecessaria = []
        
            arrValoracaoDesnecessaria.push({
                perguntaValoracaoDesnecessaria0: "O nexo causal é o vinculo que se estabelece entre a execução do serviço (causa) e o acidente do trabalho ou doença ocupacional (efeito). Pode-se afirmar que esse pressuposto é o primeiro a ser investigado, visto que, se o acidente ou doença não estiverem relacionados ao trabalho, é desnecessário, por óbvio, analisar a extensão dos danos e da culpa patronal.”(Oliveira, Sebastião Geraldo, Indenizações por acidente do trabalho ou doença ocupacional, 9ª edição - 2016, pág. 160).",
                respostaValoracaoDesnecessaria0: formValues.respostaValoracaoDesnecessaria0 !== undefined ? formValues.respostaValoracaoDesnecessaria0 : '',
               // comentarioValoracaoDesnecessaria0: formValues.comentarioValoracaoDesnecessaria0 !== undefined ? formValues.comentarioValoracaoDesnecessaria0 : '',     
            })
            setValoracaoDesnecessaria([...arrValoracaoDesnecessaria])
    
            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _64_valoracaoDesnecessaria: [{
                    perguntaValoracaoDesnecessaria0: "O nexo causal é o vinculo que se estabelece entre a execução do serviço (causa) e o acidente do trabalho ou doença ocupacional (efeito). Pode-se afirmar que esse pressuposto é o primeiro a ser investigado, visto que, se o acidente ou doença não estiverem relacionados ao trabalho, é desnecessário, por óbvio, analisar a extensão dos danos e da culpa patronal.”(Oliveira, Sebastião Geraldo, Indenizações por acidente do trabalho ou doença ocupacional, 9ª edição - 2016, pág. 160).",
                respostaValoracaoDesnecessaria0: formValues.respostaValoracaoDesnecessaria0 !== undefined ? formValues.respostaValoracaoDesnecessaria0 : '',
               // comentarioValoracaoDesnecessaria0: formValues.comentarioValoracaoDesnecessaria0 !== undefined ? formValues.comentarioValoracaoDesnecessaria0 : '',
                }]
          }))
        }
    
        const excluirValoracaoDesnecessaria = (e) => {
            e.preventDefault()  
    
            setValoracaoDesnecessaria([])
    
                    localStorage.setItem('tudo', JSON.stringify({
                        ...JSON.parse(localStorage.getItem('tudo')) || [],
                        _64_valoracaoDesnecessaria: []
                  }))
        }
    
        const handleCarregarFormValoracaoDesnecessaria = () => {
            
            const tudo = JSON.parse(localStorage.getItem('tudo'))
            if (tudo._64_valoracaoDesnecessaria && tudo._64_valoracaoDesnecessaria.length > 0) {
                const a = JSON.parse(localStorage.getItem('tudo'))._64_valoracaoDesnecessaria !== undefined
                ||JSON.parse(localStorage.getItem('tudo'))._64_valoracaoDesnecessaria !== null ? 
                JSON.parse(localStorage.getItem('tudo'))._64_valoracaoDesnecessaria : []
        
                //console.log('***',a)
            a.map((e)=> 
                setFormValues({
        perguntaValoracaoDesnecessaria0: e !== undefined && e.perguntaValoracaoDesnecessaria0 !== undefined ? e.perguntaValoracaoDesnecessaria0 : '',
                respostaValoracaoDesnecessaria0: e !== undefined &&  e.respostaValoracaoDesnecessaria0 !== undefined ? e.respostaValoracaoDesnecessaria0 : '',
                //comentarioValoracaoDesnecessaria0: e !== undefined &&  e.comentarioValoracaoDesnecessaria0 !== undefined ? e.comentarioValoracaoDesnecessaria0 : '',
            }) 
        )  
    }
    
    }
    
        const handleReset = () => {
            setFormValues({})
        }
    
        const handleLimparValoracaoDesnecessaria = (e) => {
            e.preventDefault()
            if (e.target.attributes.value) {
                setFormValues({...formValues,  ['respostaValoracaoDesnecessaria'+e.target.attributes.value.value]: '', ['comentarioValoracaoDesnecessaria'+e.target.attributes.value.value]: ''})
            }
        }
    
    
        return (
        <>
        <fieldset>
            <legend></legend>
            <input type="checkbox" id={styles.checkboxValoracaoDesnecessaria} />
                <label htmlFor={styles.checkboxValoracaoDesnecessaria}>
                    <h2 className={styles.titulo}>Valoração Desnecessária</h2>
                </label>
                <fieldset id={styles.conteudoValoracaoDesnecessaria}>  
                <table className={styles.table}>
                    <thead>
                        <tr>
                             <th>Ação</th> 
                            <th>Pergunta</th>
                         <th>Resposta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key="O nexo causal é o vinculo que se estabelece entre a execução do serviço (causa) e o acidente do trabalho ou doença ocupacional (efeito). Pode-se afirmar que esse pressuposto é o primeiro a ser investigado, visto que, se o acidente ou doença não estiverem relacionados ao trabalho, é desnecessário, por óbvio, analisar a extensão dos danos e da culpa patronal.”(Oliveira, Sebastião Geraldo, Indenizações por acidente do trabalho ou doença ocupacional, 9ª edição - 2016, pág. 160)."> 
                            <th><button onClick={handleLimparValoracaoDesnecessaria}><i className="fas fa-trash perigo" value={'0'}></i></button></th> 
                            <th>O nexo causal é o vinculo que se estabelece entre a execução do serviço (causa) e o acidente do trabalho ou doença ocupacional (efeito). Pode-se afirmar que esse pressuposto é o primeiro a ser investigado, visto que, se o acidente ou doença não estiverem relacionados ao trabalho, é desnecessário, por óbvio, analisar a extensão dos danos e da culpa patronal.”(Oliveira, Sebastião Geraldo, Indenizações por acidente do trabalho ou doença ocupacional, 9ª edição - 2016, pág. 160).</th>
                             <th>
                            <textarea 
                                name="respostaValoracaoDesnecessaria0"  
                                cols="30" 
                                rows="10" 
                                placeholder="Digite sua valoração aqui."
                                onChange={handleInputChange}
                                value={formValues.respostaValoracaoDesnecessaria0 !== undefined ? formValues.respostaValoracaoDesnecessaria0 : ''}
                            />
                            </th> 
                        </tr>                    
                    </tbody>
                </table>
                <div>
                <button onClick={adicionarValoracaoDesnecessaria}><i className="fas fa-plus"></i></button>
                <button onClick={handleCarregarFormValoracaoDesnecessaria}>Carregar formulário</button>
                <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
                </div>
            <fieldset>
                <legend>Dados registrados do déficit funcional temporário</legend>
                {
                valoracaoDesnecessaria.length === 0 ? <p>Sem dados registrados</p> : 
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Pergunta</th>
                                <th>Resposta</th> 
                               {/*  <th>Comentário</th> */}
                            </tr>
                        </thead>
                            {valoracaoDesnecessaria.length > 0 && valoracaoDesnecessaria.map(({ 
                                perguntaValoracaoDesnecessaria0,
                                respostaValoracaoDesnecessaria0,
                               // comentarioValoracaoDesnecessaria0,
                            }, i)=>{
    
                            return(
                            
                                <tbody key={i}>
                                    
                                <tr >
                                    <th>{perguntaValoracaoDesnecessaria0}</th>
                                    <th>{respostaValoracaoDesnecessaria0}</th> 
                                   {/*  <th>{comentarioValoracaoDesnecessaria0}</th> */}
                                </tr>
                                </tbody>
                                
                        )})}
                        
                        </table>
                        <div>
                        <button onClick={excluirValoracaoDesnecessaria}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                        </div>
                    </>
                    }
                </fieldset>
                </fieldset>
            </fieldset>
            </>
            ) 
}
