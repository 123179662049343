import React, { useState/* , useMemo */ } from "react";

export const DocReclamada = () => {
  const [doc, setDoc] = useState([
    {
      name: "PPRA da empresa relativo as funções desempenhadas pela parte autora.",
    },
    {
      name: "PCMSO da empresa relativo as funções desempenhadas pela parte autora.",
    },
    { name: "Cópias de todos os ASOS." },
    { name: "Análise ergonômica da atividade realizada pela parte autora." },
    { name: "Ficha de entrega de EPIs." },
    { name: "Ficha de treinamentos." },
    {
      name: "Ordens de serviço com treinamentos realizados para a parte autora devidamente assinados.",
    },
    {
      name: "Ficha de análise de acidente de trabalho pela CIPA se pertinente ao caso.",
    },
    {
      name: "Cópia do prontuário clínico da empresa (caso a letra não se seja legível, anexar tradução).",
    },
    {
      name: "LTCAT da empresa relativo as funções desempenhadas pela parte autora.",
    },
    {
      name: "Ordem de Serviços referente as funções exercidas na reclamada.",
    },
    { name: "Cbo da atividade do reclamante" },
    {
      name: "Todos os exames de audiometria realizados durante o contrato laboral em ordem cronológica.",
    },
  ]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (name === "allSelected") {
      const tempDoc = doc.map((e) => {
        return { ...e, isChecked: checked };
      });
      setDoc(tempDoc);
    } else {
      const tempDoc = doc.map((e) => {
        return e.name === name ? { ...e, isChecked: checked } : e;
      });
      setDoc(tempDoc);
    }
  };

  const [docReclamada, setDocReclamada] = useState([]);

  const adicionarDocReclamada = (e) => {
    e.preventDefault();

    const dadoSelecionado = [];

    doc.map(({ name, isChecked }) => {
      if (isChecked === true) {
        dadoSelecionado.push(name);
      }
      return null;
    });

    if (dadoSelecionado) {
      setDocReclamada(dadoSelecionado);

      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _05_docReclamada: dadoSelecionado,
        })
      );
    }
  };

  const excluirDocReclamada = (e) => {
    e.preventDefault();
    docReclamada.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        docReclamada.splice(i, 1);
        setDocReclamada([...docReclamada]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _05_docReclamada: docReclamada,
          })
        );
      }
      return null;
    });
  };

  const personalizacaoDoUsuario = /* async  */ () => {
    /* const getRefs = (docs) => {
              return docs.map((doc) =>
                doc ? doc.ref : console.log(`Um erro ocorreu`)
              );
            };
            const getIds = (refs) => {
              return refs.map((ref) =>
                ref ? ref.id : console.log(`Um erro ocorreu`)
              );
            }; */

    const docPersonalizado = JSON.parse(
      localStorage.getItem("tudo")
    )._04_docHonorarios; /* getIds(getRefs((await getDocs(collection(db, "users"))).docs)) */
    if (docPersonalizado && docPersonalizado.length > 0) {
      let z = [];
      docPersonalizado.map((e) => {
        z.push({
          name: e.toString(),
        });
        return z;
      });
      setDoc(
        [
          {
            name: "PPRA da empresa relativo as funções desempenhadas pela parte autora.",
          },
          {
            name: "PCMSO da empresa relativo as funções desempenhadas pela parte autora.",
          },
          { name: "Cópias de todos os ASOS." },
          {
            name: "Análise ergonômica da atividade realizada pela parte autora.",
          },
          { name: "Ficha de entrega de EPIs." },
          { name: "Ficha de treinamentos." },
          {
            name: "Ordens de serviço com treinamentos realizados para a parte autora devidamente assinados.",
          },
          {
            name: "Ficha de análise de acidente de trabalho pela CIPA se pertinente ao caso.",
          },
          {
            name: "Cópia do prontuário clínico da empresa (caso a letra não se seja legível, anexar tradução).",
          },
          {
            name: "LTCAT da empresa relativo as funções desempenhadas pela parte autora.",
          },
          {
            name: "Ordem de Serviços referente as funções exercidas na reclamada.",
          },
          { name: "Cbo da atividade do reclamante" },
          {
            name: "Todos os exames de audiometria realizados durante o contrato laboral em ordem cronológica.",
          },
        ].concat(z)
      );
    }
  };

  /* useMemo(() => {
    setDoc([
      {
        name: "PPRA da empresa relativo as funções desempenhadas pela parte autora.",
      },
      {
        name: "PCMSO da empresa relativo as funções desempenhadas pela parte autora.",
      },
      { name: "Cópias de todos os ASOS." },
      { name: "Análise ergonômica da atividade realizada pela parte autora." },
      { name: "Ficha de entrega de EPIs." },
      { name: "Ficha de treinamentos." },
      {
        name: "Ordens de serviço com treinamentos realizados para a parte autora devidamente assinados.",
      },
      {
        name: "Ficha de análise de acidente de trabalho pela CIPA se pertinente ao caso.",
      },
      {
        name: "Cópia do prontuário clínico da empresa (caso a letra não se seja legível, anexar tradução).",
      },
      {
        name: "LTCAT da empresa relativo as funções desempenhadas pela parte autora.",
      },
      {
        name: "Ordem de Serviços referente as funções exercidas na reclamada.",
      },
      { name: "Cbo da atividade do reclamante" },
      {
        name: "Todos os exames de audiometria realizados durante o contrato laboral em ordem cronológica.",
      },
    ]);
  }, []); */

  const excluirTudoDocReclamada = (e) => {
    e.preventDefault();

    setDocReclamada([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _05_docReclamada: [],
      })
    );
  };

  const handleCarregarFormDocReclamada = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));
    if (tudo._05_docReclamada && tudo._05_docReclamada.length > 0) {
      const a =
        JSON.parse(localStorage.getItem("tudo"))._05_docReclamada !==
          undefined ||
        JSON.parse(localStorage.getItem("tudo"))._05_docReclamada !== null
          ? JSON.parse(localStorage.getItem("tudo"))._05_docReclamada
          : [];

      setDocReclamada(a);
    }
  };

  return (
    <>
      <fieldset>
      <legend>Documentos para a Reclamada</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>
                  {" "}
                  <span>Ação</span>
                  <span>Informação</span>
                  <button onClick={personalizacaoDoUsuario}>botao teste</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="allSelected"
                    onChange={handleChange}
                  />
                </th>
                <th>Selecionar todos</th>
              </tr>
              {doc.map((e, id) => {
                return (
                  <tr key={id}>
                    <th>
                    <label>
                        <div>
                        <input
                          type="checkbox"
                          name={e.name}
                          checked={e.isChecked || false}
                          onChange={handleChange}
                        /></div>
                        <span>
                        {e.name}
                        </span>
                        </label>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarDocReclamada}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormDocReclamada}>
                      Carregar formulário
                    </button>
                  </th>
                  {/* <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th> */}
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Documentos relativos à reclamada Registrados</legend>
            {docReclamada.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docReclamada.length > 0 &&
                      docReclamada.map((e, i) => {
                        return (
                          <tr key={i}>
                            <th>
                              <button onClick={excluirDocReclamada}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                            <th>{e}</th>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoDocReclamada}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoDocReclamada"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            )}
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
