import React, { useState, useEffect, createContext, useContext } from "react";
import { auth, db, firebaseApp } from "../services/firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { StripePagamentoContexto } from "./stripePagamentoContexto";

export const TipoUsuarioContexto = createContext();

export const TipoUsuarioProvider = ({ children }) => {

  const {UpGradeToPremium, ManageSubscription} = useContext(StripePagamentoContexto)

  const usuario = auth.currentUser;
  //console.log(usuario.uid)
  const [isPremium, setIsPremium] = useState(false);
  const [isSecretaria, setIsSecretaria] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStandard, setIsStandard] = useState(true);

  const getPremiumStatus = async () => {
    const user = auth.currentUser;
  
    if (!user.uid) throw new Error("Usuário não autenticado!!");
  
    const subscriptionsRef = collection(
      db,
      "customers",
      user.uid,
      "subscriptions"
    );
  
    const q = query(
      subscriptionsRef,
      where("status", "in", ["trialing", "active"])
    );
  
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        q,
        (snap) => {
          //console.log("Snapshot de subscrições", snap.docs.length);
          /* console.log(
            "getPremiumStatus",
            snap.docs.map((doc) => doc.data())
          ); */
          if (snap.docs.length === 0) {
            //console.log(" Sem subscricoes trialing ou active.");
            resolve(false);
          } else {
            //console.log(" Subscricoes trialing ou active encontradas!");
            resolve(true);
          }
          unsubscribe();
        },
        reject
      );
    });
  };

  useEffect(() => {
    const checkPremium = async () => {
      const newPremiumStatus = usuario
        ? await getPremiumStatus(firebaseApp)
        : false;
      setIsPremium(newPremiumStatus);
    };
    checkPremium(firebaseApp, usuario.uid);

    const checkAdmin = async () => {
      usuario && usuario.uid === "kcMVtTJ4qegJeUOix9cr1Zqz04C2"
        ? setIsAdmin(true) && setIsPremium(false)
        : setIsAdmin(false);
    };
    checkAdmin();

    const checkSecretaria = async () => {
      isSecretaria === true
        ? setIsSecretaria(true) && setIsStandard(false)
        : setIsSecretaria(false);
    };
    checkSecretaria();
  }, [usuario, isStandard, isAdmin, isSecretaria]);

  //console.log('admin',isAdmin,'premium',isPremium,'isSecretaria',isSecretaria)

  const AdminPanel = () => {
    return <div>Conta do Administrador</div>;
  };

  const PremiumPanel = () => {
    return <div>Conta Premium</div>;
  };

  const StandardPanel = () => {
    return <div>Conta Básica</div>;
  };

  const SecretariaPanel = () => {
    return <div>Conta da Secretária</div>;
  };

  const upgradeToPremiumButton = (
    <button onClick={UpGradeToPremium}>Assinar sistema</button>
  );

  const managePortalButton = (
    <button onClick={ManageSubscription}>Gerenciamento de assinatura</button>
  );

  const adminButton =
    isAdmin && isPremium ? (
      <button onClick={ManageSubscription}>
        Botão do Admin Gerenciar Assinatura...
      </button>
    ) : (
      <button onClick={UpGradeToPremium}>
        Botão do Admin Assinar o Premium...
      </button>
    );

  const secretarialButton = (
    <button /* onClick={} */>Secretária do perito ...</button>
  );

  const statusPanel = isAdmin ? (
    <AdminPanel />
  ) : isPremium ? (
    <PremiumPanel />
  ) : isSecretaria ? (
    <SecretariaPanel />
  ) : (
    <StandardPanel />
  );
  const memberButton = isAdmin
    ? adminButton
    : isPremium
    ? managePortalButton
    : isSecretaria
    ? secretarialButton
    : upgradeToPremiumButton;

  return (
    <TipoUsuarioContexto.Provider
      value={{
        usuario,
        isAdmin,
        isPremium,
        isSecretaria,
        getPremiumStatus,
        statusPanel,
        memberButton,
        isStandard
      }}
    >
      {children}
    </TipoUsuarioContexto.Provider>
  );
};
