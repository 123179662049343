import React, {useState} from 'react'
import styles from '../criteriosSimonin/CriteriosSimonin.module.css'

export const CriteriosSimonin = () => {
        const [formValues, setFormValues] = useState({})
    
        const handleInputChange = (e) => {
            const { name, value, type } = e.target;
        
            const data = formValues[name] || {};
            const isCheckbox = type === 'checkbox'
            const newValue = isCheckbox  ? data : value;
            
            setFormValues({...formValues,  [name]: newValue  });
           // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
        }
    
        const [ criteriosSimonin, setCriteriosSimonin] = useState([])
       
        const adicionarCriteriosSimonin = (e) => {
    
            e.preventDefault()
    
            const arrCriteriosSimonin = []
        
            arrCriteriosSimonin.push({
                perguntaCriteriosSimonin0: "Natureza adequada do traumatismo: Se a forma como ocorreu o trauma pode levar ao dano informado",
                respostaCriteriosSimonin0: formValues.respostaCriteriosSimonin0 !== undefined ? formValues.respostaCriteriosSimonin0 : '',
                comentarioCriteriosSimonin0: formValues.comentarioCriteriosSimonin0 !== undefined ? formValues.comentarioCriteriosSimonin0 : '',
                perguntaCriteriosSimonin1: "Natureza adequada da lesão a uma etiologia traumática: Se a lesão apresentada decorre de um trauma ou de outra causa",
                respostaCriteriosSimonin1: formValues.respostaCriteriosSimonin1 !== undefined ? formValues.respostaCriteriosSimonin1 : '',
                comentarioCriteriosSimonin1: formValues.comentarioCriteriosSimonin1 !== undefined ? formValues.comentarioCriteriosSimonin1 : '',
                perguntaCriteriosSimonin2: "Adequação entre a sede do traumatismo e a sede da lesão: Se a lesão encontra-se no local do trauma",
                respostaCriteriosSimonin2: formValues.respostaCriteriosSimonin2 !== undefined ? formValues.respostaCriteriosSimonin2 : '',
                comentarioCriteriosSimonin2: formValues.comentarioCriteriosSimonin2 !== undefined ? formValues.comentarioCriteriosSimonin2 : '',
                perguntaCriteriosSimonin3: "Encadeamento Anatomo-clínico: Se as alterações posteriores ao trauma estão evoluindo da maneira esperada pela literatura médica",
                respostaCriteriosSimonin3: formValues.respostaCriteriosSimonin3 !== undefined ? formValues.respostaCriteriosSimonin3 : '',
                comentarioCriteriosSimonin3: formValues.comentarioCriteriosSimonin3 !== undefined ? formValues.comentarioCriteriosSimonin3 : '',
                perguntaCriteriosSimonin4: "Adequação Temporal: O aparecimento das manifestações somato-psíquico-funcionais devem guardar uma relação imediata com a exposição ao agente agressor",
                respostaCriteriosSimonin4: formValues.respostaCriteriosSimonin4 !== undefined ? formValues.respostaCriteriosSimonin4 : '',
                comentarioCriteriosSimonin4: formValues.comentarioCriteriosSimonin4 !== undefined ? formValues.comentarioCriteriosSimonin4 : '',
                perguntaCriteriosSimonin5: "Exclusão de pré-existência do dano: Deve se ter certeza de que não havia dano anterior",
                respostaCriteriosSimonin5: formValues.respostaCriteriosSimonin5 !== undefined ? formValues.respostaCriteriosSimonin5 : '',
                comentarioCriteriosSimonin5: formValues.comentarioCriteriosSimonin5 !== undefined ? formValues.comentarioCriteriosSimonin5 : '',
                perguntaCriteriosSimonin6: "Exclusão de causa estranha ao traumatismo: Identificação de outra causa de etiologia mais adequada à doença/lesão, que não seja o traumal",
                respostaCriteriosSimonin6: formValues.respostaCriteriosSimonin6 !== undefined ? formValues.respostaCriteriosSimonin6 : '',
                comentarioCriteriosSimonin6: formValues.comentarioCriteriosSimonin6 !== undefined ? formValues.comentarioCriteriosSimonin6 : '',
                perguntaCriteriosSimonin7: "conclusaoCriteriosSimonin",
                respostaCriteriosSimonin7: formValues.respostaCriteriosSimonin7 !== undefined ? formValues.respostaCriteriosSimonin7 : '',
                comentarioCriteriosSimonin7: formValues.comentarioCriteriosSimonin7 !== undefined ? formValues.comentarioCriteriosSimonin7 : '',     
            })
            setCriteriosSimonin([...arrCriteriosSimonin])
    
            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _52_criteriosSimonin: [{
                    perguntaCriteriosSimonin0: "Natureza adequada do traumatismo: Se a forma como ocorreu o trauma pode levar ao dano informado",
                respostaCriteriosSimonin0: formValues.respostaCriteriosSimonin0 !== undefined ? formValues.respostaCriteriosSimonin0 : '',
                comentarioCriteriosSimonin0: formValues.comentarioCriteriosSimonin0 !== undefined ? formValues.comentarioCriteriosSimonin0 : '',
                perguntaCriteriosSimonin1: "Natureza adequada da lesão a uma etiologia traumática: Se a lesão apresentada decorre de um trauma ou de outra causa",
                respostaCriteriosSimonin1: formValues.respostaCriteriosSimonin1 !== undefined ? formValues.respostaCriteriosSimonin1 : '',
                comentarioCriteriosSimonin1: formValues.comentarioCriteriosSimonin1 !== undefined ? formValues.comentarioCriteriosSimonin1 : '',
                perguntaCriteriosSimonin2: "Adequação entre a sede do traumatismo e a sede da lesão: Se a lesão encontra-se no local do trauma",
                respostaCriteriosSimonin2: formValues.respostaCriteriosSimonin2 !== undefined ? formValues.respostaCriteriosSimonin2 : '',
                comentarioCriteriosSimonin2: formValues.comentarioCriteriosSimonin2 !== undefined ? formValues.comentarioCriteriosSimonin2 : '',
                perguntaCriteriosSimonin3: "Encadeamento Anatomo-clínico: Se as alterações posteriores ao trauma estão evoluindo da maneira esperada pela literatura médica",
                respostaCriteriosSimonin3: formValues.respostaCriteriosSimonin3 !== undefined ? formValues.respostaCriteriosSimonin3 : '',
                comentarioCriteriosSimonin3: formValues.comentarioCriteriosSimonin3 !== undefined ? formValues.comentarioCriteriosSimonin3 : '',
                perguntaCriteriosSimonin4: "Adequação Temporal: O aparecimento das manifestações somato-psíquico-funcionais devem guardar uma relação imediata com a exposição ao agente agressor",
                respostaCriteriosSimonin4: formValues.respostaCriteriosSimonin4 !== undefined ? formValues.respostaCriteriosSimonin4 : '',
                comentarioCriteriosSimonin4: formValues.comentarioCriteriosSimonin4 !== undefined ? formValues.comentarioCriteriosSimonin4 : '',
                perguntaCriteriosSimonin5: "Exclusão de pré-existência do dano: Deve se ter certeza de que não havia dano anterior",
                respostaCriteriosSimonin5: formValues.respostaCriteriosSimonin5 !== undefined ? formValues.respostaCriteriosSimonin5 : '',
                comentarioCriteriosSimonin5: formValues.comentarioCriteriosSimonin5 !== undefined ? formValues.comentarioCriteriosSimonin5 : '',
                perguntaCriteriosSimonin6: "Exclusão de causa estranha ao traumatismo: Identificação de outra causa de etiologia mais adequada à doença/lesão, que não seja o traumal",
                respostaCriteriosSimonin6: formValues.respostaCriteriosSimonin6 !== undefined ? formValues.respostaCriteriosSimonin6 : '',
                comentarioCriteriosSimonin6: formValues.comentarioCriteriosSimonin6 !== undefined ? formValues.comentarioCriteriosSimonin6 : '',
                perguntaCriteriosSimonin7: "conclusaoCriteriosSimonin",
                respostaCriteriosSimonin7: formValues.respostaCriteriosSimonin7 !== undefined ? formValues.respostaCriteriosSimonin7 : '',
                comentarioCriteriosSimonin7: formValues.comentarioCriteriosSimonin7 !== undefined ? formValues.comentarioCriteriosSimonin7 : '',
                }]
          }))
        }
    
        const excluirCriteriosSimonin = (e) => {
            e.preventDefault()  
    
            setCriteriosSimonin([])
    
                    localStorage.setItem('tudo', JSON.stringify({
                        ...JSON.parse(localStorage.getItem('tudo')) || [],
                        _52_criteriosSimonin: []
                  }))
        }
    
        const handleCarregarFormCriteriosSimonin = () => {
            
            const tudo = JSON.parse(localStorage.getItem('tudo'))
            if (tudo._52_criteriosSimonin && tudo._52_criteriosSimonin.length > 0) {
                const a = JSON.parse(localStorage.getItem('tudo'))._52_criteriosSimonin !== undefined
                ||JSON.parse(localStorage.getItem('tudo'))._52_criteriosSimonin !== null ? 
                JSON.parse(localStorage.getItem('tudo'))._52_criteriosSimonin : []
        
                //console.log('***',a)
            a.map((e)=> 
                setFormValues({
        perguntaCriteriosSimonin0: e !== undefined && e.perguntaCriteriosSimonin0 !== undefined ? e.perguntaCriteriosSimonin0 : '',
                respostaCriteriosSimonin0: e !== undefined &&  e.respostaCriteriosSimonin0 !== undefined ? e.respostaCriteriosSimonin0 : '',
                comentarioCriteriosSimonin0: e !== undefined &&  e.comentarioCriteriosSimonin0 !== undefined ? e.comentarioCriteriosSimonin0 : '',
                perguntaCriteriosSimonin1: e !== undefined && e.perguntaCriteriosSimonin1 !== undefined ? e.perguntaCriteriosSimonin1 : '',
                respostaCriteriosSimonin1: e !== undefined &&  e.respostaCriteriosSimonin1 !== undefined ? e.respostaCriteriosSimonin1 : '',
                comentarioCriteriosSimonin1: e !== undefined &&  e.comentarioCriteriosSimonin1 !== undefined ? e.comentarioCriteriosSimonin1 : '',
                perguntaCriteriosSimonin2: e !== undefined && e.perguntaCriteriosSimonin2 !== undefined ? e.perguntaCriteriosSimonin2 : '',
                respostaCriteriosSimonin2: e !== undefined &&  e.respostaCriteriosSimonin2 !== undefined ? e.respostaCriteriosSimonin2 : '',
                comentarioCriteriosSimonin2: e !== undefined &&  e.comentarioCriteriosSimonin2 !== undefined ? e.comentarioCriteriosSimonin2 : '',
                perguntaCriteriosSimonin3: e !== undefined && e.perguntaCriteriosSimonin3 !== undefined ? e.perguntaCriteriosSimonin3 : '',
                respostaCriteriosSimonin3: e !== undefined &&  e.respostaCriteriosSimonin3 !== undefined ? e.respostaCriteriosSimonin3 : '',
                comentarioCriteriosSimonin3: e !== undefined &&  e.comentarioCriteriosSimonin3 !== undefined ? e.comentarioCriteriosSimonin3 : '',
                perguntaCriteriosSimonin4: e !== undefined && e.perguntaCriteriosSimonin4 !== undefined ? e.perguntaCriteriosSimonin4 : '',
                respostaCriteriosSimonin4: e !== undefined &&  e.respostaCriteriosSimonin4 !== undefined ? e.respostaCriteriosSimonin4 : '',
                comentarioCriteriosSimonin4: e !== undefined &&  e.comentarioCriteriosSimonin4 !== undefined ? e.comentarioCriteriosSimonin4 : '',
                perguntaCriteriosSimonin5: e !== undefined && e.perguntaCriteriosSimonin5 !== undefined ? e.perguntaCriteriosSimonin5 : '',
                respostaCriteriosSimonin5: e !== undefined &&  e.respostaCriteriosSimonin5 !== undefined ? e.respostaCriteriosSimonin5 : '',
                comentarioCriteriosSimonin5: e !== undefined &&  e.comentarioCriteriosSimonin5 !== undefined ? e.comentarioCriteriosSimonin5 : '',
                perguntaCriteriosSimonin6: e !== undefined && e.perguntaCriteriosSimonin6 !== undefined ? e.perguntaCriteriosSimonin6 : '',
                respostaCriteriosSimonin6: e !== undefined &&  e.respostaCriteriosSimonin6 !== undefined ? e.respostaCriteriosSimonin6 : '',
                comentarioCriteriosSimonin6: e !== undefined &&  e.comentarioCriteriosSimonin6 !== undefined ? e.comentarioCriteriosSimonin6 : '',
                perguntaCriteriosSimonin7: e !== undefined && e.perguntaCriteriosSimonin7 !== undefined ? e.perguntaCriteriosSimonin7 : '',
                respostaCriteriosSimonin7: e !== undefined &&  e.respostaCriteriosSimonin7 !== undefined ? e.respostaCriteriosSimonin7 : '',
                comentarioCriteriosSimonin7: e !== undefined &&  e.comentarioCriteriosSimonin7 !== undefined ? e.comentarioCriteriosSimonin7 : '', 
            }) 
        )  
    }
    
    }
    
        const handleReset = () => {
            setFormValues({})
        }
    
        const handleLimparCriteriosSimonin = (e) => {
            e.preventDefault()
            if (e.target.attributes.value) {
                setFormValues({...formValues,  ['respostaCriteriosSimonin'+e.target.attributes.value.value]: '', ['comentarioCriteriosSimonin'+e.target.attributes.value.value]: ''})
            }
        }
    
    
        return (
        <>
        <fieldset>
            <legend></legend>
            <input type="checkbox" id={styles.checkboxCriteriosSimonin} />
                <label htmlFor={styles.checkboxCriteriosSimonin}>
                    <h2 className={styles.titulo}>Criterios de Simonin</h2>
                </label>
                <fieldset id={styles.conteudoCriteriosSimonin}>  
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Ação</th>
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th>Comentário</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key="Natureza adequada do traumatismo: Se a forma como ocorreu o trauma pode levar ao dano informado"> 
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                            <th>Natureza adequada do traumatismo: Se a forma como ocorreu o trauma pode levar ao dano informado</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin0"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin0 !== undefined ? formValues.respostaCriteriosSimonin0 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin0"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin0 !== undefined ? formValues.respostaCriteriosSimonin0 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin0"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin0 !== undefined ? formValues.comentarioCriteriosSimonin0 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="Natureza adequada da lesão a uma etiologia traumática: Se a lesão apresentada decorre de um trauma ou de outra causa">
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'1'}></i></button></th>
                            <th>Natureza adequada da lesão a uma etiologia traumática: Se a lesão apresentada decorre de um trauma ou de outra causa</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin1"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin1 !== undefined ? formValues.respostaCriteriosSimonin1 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin1"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin1 !== undefined ? formValues.respostaCriteriosSimonin1 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin1"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin1 !== undefined ? formValues.comentarioCriteriosSimonin1 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="Adequação entre a sede do traumatismo e a sede da lesão: Se a lesão encontra-se no local do trauma">
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'2'}></i></button></th>
                            <th>Adequação entre a sede do traumatismo e a sede da lesão: Se a lesão encontra-se no local do trauma</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin2"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin2 !== undefined ? formValues.respostaCriteriosSimonin2 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin2"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin2 !== undefined ? formValues.respostaCriteriosSimonin2 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin2"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin2 !== undefined ? formValues.comentarioCriteriosSimonin2 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="Encadeamento Anatomo-clínico: Se as alterações posteriores ao trauma estão evoluindo da maneira esperada pela literatura médica">
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'3'}></i></button></th>
                            <th>Encadeamento Anatomo-clínico: Se as alterações posteriores ao trauma estão evoluindo da maneira esperada pela literatura médica</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin3"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin3 !== undefined ? formValues.respostaCriteriosSimonin3 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin3"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin3 !== undefined ? formValues.respostaCriteriosSimonin3 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin3"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin3 !== undefined ? formValues.comentarioCriteriosSimonin3 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="Adequação Temporal: O aparecimento das manifestações somato-psíquico-funcionais devem guardar uma relação imediata com a exposição ao agente agressor">
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'4'}></i></button></th>
                            <th>Adequação Temporal: O aparecimento das manifestações somato-psíquico-funcionais devem guardar uma relação imediata com a exposição ao agente agressor</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin4"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin4 !== undefined ? formValues.respostaCriteriosSimonin4 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin4"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin4 !== undefined ? formValues.respostaCriteriosSimonin4 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin4"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin4 !== undefined ? formValues.comentarioCriteriosSimonin4 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="Exclusão de pré-existência do dano: Deve se ter certeza de que não havia dano anterior">
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'5'}></i></button></th>
                            <th>Exclusão de pré-existência do dano: Deve se ter certeza de que não havia dano anterior</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin5"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin5 !== undefined ? formValues.respostaCriteriosSimonin5 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin5"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin5 !== undefined ? formValues.respostaCriteriosSimonin5 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin5"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin5 !== undefined ? formValues.comentarioCriteriosSimonin5 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="Exclusão de causa estranha ao traumatismo: Identificação de outra causa de etiologia mais adequada à doença/lesão, que não seja o trauma"> 
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'6'}></i></button></th>
                            <th>Exclusão de causa estranha ao traumatismo: Identificação de outra causa de etiologia mais adequada à doença/lesão, que não seja o trauma</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Atendido"
                                        name="respostaCriteriosSimonin6"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin6 !== undefined ? formValues.respostaCriteriosSimonin6 === 'Atendido' : ''}
                                    />
                                        Atendido
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não atendido"
                                        name="respostaCriteriosSimonin6"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin6 !== undefined ? formValues.respostaCriteriosSimonin6 === 'Não atendido' : ''}
                                    />
                                        Não atendido
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin6"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin6 !== undefined ? formValues.comentarioCriteriosSimonin6 : ''}
                                />
                            </th>
                        </tr>
                        <tr key="conclusaoCriteriosSimonin">
                            <th><button onClick={handleLimparCriteriosSimonin}><i className="fas fa-trash perigo" value={'7'}></i></button></th>
                            <th>conclusaoCriteriosSimonin</th>
                            <th>
                                <div 
                                    className={styles.resposta}
                                >
                                    <label>
                                    <input
                                        type="radio"
                                        value="Não é plausível o nexo causal"
                                        name="respostaCriteriosSimonin7"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin7 !== undefined ? formValues.respostaCriteriosSimonin7 === 'Não é plausível o nexo causal' : ''}
                                    />
                                        Não é plausível o nexo causal
                                    </label>
                                    <label>
                                    <input
                                        type="radio"
                                        value="É duvidoso o nexo causal"
                                        name="respostaCriteriosSimonin7"
                                        onChange={handleInputChange}
                                        checked={formValues.respostaCriteriosSimonin7 !== undefined ? formValues.respostaCriteriosSimonin7 === 'É duvidoso o nexo causal' : ''}
                                    />
                                        É duvidoso o nexo causal
                                    </label>
                                </div>
                            </th>
                            <th>
                                <input 
                                    className={styles.comentario}
                                    type="text" 
                                    name="comentarioCriteriosSimonin7"
                                    placeholder="Comentários do criterio de Simonin"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCriteriosSimonin7 !== undefined ? formValues.comentarioCriteriosSimonin7 : ''}
                                />
                            </th>
                        </tr>                     
                    </tbody>
                </table>
                <div>
                <button onClick={adicionarCriteriosSimonin}><i className="fas fa-plus"></i></button>
                <button onClick={handleCarregarFormCriteriosSimonin}>Carregar formulário</button>
                <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
                </div>
            <fieldset>
                <legend>Dados registrados do exame do ombro</legend>
                {
                criteriosSimonin.length === 0 ? <p>Sem dados registrados</p> : 
                <>
                    <table>
                        <thead>
                            <tr>
                                {/* <th>Ação</th> */}
                                <th>Pergunta</th>
                                <th>Resposta</th>
                                <th>Comentário</th>
                            </tr>
                        </thead>
                            {criteriosSimonin.length > 0 && criteriosSimonin.map(({ 
                                perguntaCriteriosSimonin0,
                                respostaCriteriosSimonin0,
                                comentarioCriteriosSimonin0,
                                perguntaCriteriosSimonin1,
                                respostaCriteriosSimonin1,
                                comentarioCriteriosSimonin1,
                                perguntaCriteriosSimonin2,
                                respostaCriteriosSimonin2,
                                comentarioCriteriosSimonin2,
                                perguntaCriteriosSimonin3,
                                respostaCriteriosSimonin3,
                                comentarioCriteriosSimonin3,
                                perguntaCriteriosSimonin4,
                                respostaCriteriosSimonin4,
                                comentarioCriteriosSimonin4,
                                perguntaCriteriosSimonin5,
                                respostaCriteriosSimonin5,
                                comentarioCriteriosSimonin5,
                                perguntaCriteriosSimonin6,
                                respostaCriteriosSimonin6,
                                comentarioCriteriosSimonin6,
                                perguntaCriteriosSimonin7,
                                respostaCriteriosSimonin7,
                                comentarioCriteriosSimonin7, 
                            }, i)=>{
    
                            return(
                            
                                <tbody key={i}>
                                    
                                <tr >
                                    <th>{perguntaCriteriosSimonin0}</th>
                                    <th>{respostaCriteriosSimonin0}</th>
                                    <th>{comentarioCriteriosSimonin0}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin1}</th>
                                    <th>{respostaCriteriosSimonin1}</th>
                                    <th>{comentarioCriteriosSimonin1}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin2}</th>
                                    <th>{respostaCriteriosSimonin2}</th>
                                    <th>{comentarioCriteriosSimonin2}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin3}</th>
                                    <th>{respostaCriteriosSimonin3}</th>
                                    <th>{comentarioCriteriosSimonin3}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin4}</th>
                                    <th>{respostaCriteriosSimonin4}</th>
                                    <th>{comentarioCriteriosSimonin4}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin5}</th>
                                    <th>{respostaCriteriosSimonin5}</th>
                                    <th>{comentarioCriteriosSimonin5}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin6}</th>
                                    <th>{respostaCriteriosSimonin6}</th>
                                    <th>{comentarioCriteriosSimonin6}</th>
                                </tr>
                                <tr >
                                    
                                    <th>{perguntaCriteriosSimonin7}</th>
                                    <th>{respostaCriteriosSimonin7}</th>
                                    <th>{comentarioCriteriosSimonin7}</th>
                                </tr>
                                </tbody>
                                
                        )})}
                        
                        </table>
                        <div>
                        <button onClick={excluirCriteriosSimonin}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                        </div>
                    </>
                    }
                </fieldset>
                </fieldset>
            </fieldset>
            </>
            )  
 
}
