import { createContext } from "react";

export const ErrorBoundaryContexto = createContext();

export const ErrorBoundaryProvider = ({ children }) => {
  return (
    <ErrorBoundaryContexto.Provider value={{}}>
      {children}
    </ErrorBoundaryContexto.Provider>
  );
};
