import React, {useState} from 'react'
import styles from '../incapacidadeLaboralTemporaria/IncapacidadeLaboralTemporaria.module.css'

export const IncapacidadeLaboralTemporaria = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ incapacidadeLaboralTemporaria, setIncapacidadeLaboralTemporaria] = useState([])
   
    const adicionarIncapacidadeLaboralTemporaria = (e) => {

        e.preventDefault()

        const arrIncapacidadeLaboralTemporaria = []
    
        arrIncapacidadeLaboralTemporaria.push({
            perguntaIncapacidadeLaboralTemporaria0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaIncapacidadeLaboralTemporaria0: formValues.respostaIncapacidadeLaboralTemporaria0 !== undefined ? formValues.respostaIncapacidadeLaboralTemporaria0 : '',
           // comentarioIncapacidadeLaboralTemporaria0: formValues.comentarioIncapacidadeLaboralTemporaria0 !== undefined ? formValues.comentarioIncapacidadeLaboralTemporaria0 : '',     
        })
        setIncapacidadeLaboralTemporaria([...arrIncapacidadeLaboralTemporaria])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _66_incapacidadeLaboralTemporaria: [{
                perguntaIncapacidadeLaboralTemporaria0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaIncapacidadeLaboralTemporaria0: formValues.respostaIncapacidadeLaboralTemporaria0 !== undefined ? formValues.respostaIncapacidadeLaboralTemporaria0 : '',
           // comentarioIncapacidadeLaboralTemporaria0: formValues.comentarioIncapacidadeLaboralTemporaria0 !== undefined ? formValues.comentarioIncapacidadeLaboralTemporaria0 : '',
            }]
      }))
    }

    const excluirIncapacidadeLaboralTemporaria = (e) => {
        e.preventDefault()  

        setIncapacidadeLaboralTemporaria([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _66_incapacidadeLaboralTemporaria: []
              }))
    }

    const handleCarregarFormIncapacidadeLaboralTemporaria = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._66_incapacidadeLaboralTemporaria && tudo._66_incapacidadeLaboralTemporaria.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._66_incapacidadeLaboralTemporaria !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._66_incapacidadeLaboralTemporaria !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._66_incapacidadeLaboralTemporaria : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaIncapacidadeLaboralTemporaria0: e !== undefined && e.perguntaIncapacidadeLaboralTemporaria0 !== undefined ? e.perguntaIncapacidadeLaboralTemporaria0 : '',
            respostaIncapacidadeLaboralTemporaria0: e !== undefined &&  e.respostaIncapacidadeLaboralTemporaria0 !== undefined ? e.respostaIncapacidadeLaboralTemporaria0 : '',
            //comentarioIncapacidadeLaboralTemporaria0: e !== undefined &&  e.comentarioIncapacidadeLaboralTemporaria0 !== undefined ? e.comentarioIncapacidadeLaboralTemporaria0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparIncapacidadeLaboralTemporaria = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaIncapacidadeLaboralTemporaria'+e.target.attributes.value.value]: '', ['comentarioIncapacidadeLaboralTemporaria'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxIncapacidadeLaboralTemporaria} />
            <label htmlFor={styles.checkboxIncapacidadeLaboralTemporaria}>
                <h2 className={styles.titulo}>Incapacidade Laboral Temporária</h2>
            </label>
            <fieldset id={styles.conteudoIncapacidadeLaboralTemporaria}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões."> 
                        <th><button onClick={handleLimparIncapacidadeLaboralTemporaria}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.</th>
                        <th>
                        <textarea 
                            name="respostaIncapacidadeLaboralTemporaria0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite sua valoração aqui."
                            onChange={handleInputChange}
                            value={formValues.respostaIncapacidadeLaboralTemporaria0 !== undefined ? formValues.respostaIncapacidadeLaboralTemporaria0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarIncapacidadeLaboralTemporaria}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormIncapacidadeLaboralTemporaria}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados da incapacidade laboral temporária</legend>
            {
            incapacidadeLaboralTemporaria.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            <th>Pergunta</th>
                            <th>Resposta</th>
                           {/*  <th>Comentário</th> */}
                        </tr>
                    </thead>
                        {incapacidadeLaboralTemporaria.length > 0 && incapacidadeLaboralTemporaria.map(({ 
                            perguntaIncapacidadeLaboralTemporaria0,
                            respostaIncapacidadeLaboralTemporaria0,
                           // comentarioIncapacidadeLaboralTemporaria0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaIncapacidadeLaboralTemporaria0}</th>
                                <th>{respostaIncapacidadeLaboralTemporaria0}</th>
                               {/*  <th>{comentarioIncapacidadeLaboralTemporaria0}</th> */}
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirIncapacidadeLaboralTemporaria}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}
