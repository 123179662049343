export const NtepFap3Modificado = [{
    "idNtepFap3": "1",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XVI",
    "patologias": "Dermatite de Contato por Irritantes Berilio, Bromo, Cromo, Cimento, Fluor, Fosforo, Inseticidas (L24.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (Z57.5) (Quadro I) devida a outros produtos quimicos: Arsenio,"
},
{
    "idNtepFap3": "2",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XXVII",
    "patologias": "Outras formas de Acne: Cloracne (L70.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados halogenados dos hidrocarbonetos aromaticos, (L70.8) Monoclorobenzeno, Monobromobenzeno, Hexaclorobenzeno (X46.; Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "3",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XVI",
    "patologias": "Dermatite de Contato por Irritantes Berilio, Bromo, Cromo, Cimento, Fluor, Fosforo, Inseticidas (L24.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Berilio e seus compostos toxicos (Z57.5) (Quadro IV)"
},
{
    "idNtepFap3": "4",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XX",
    "patologias": "Urticaria Alergica (L50.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos  e outros produtos quimicos (X48.-) (Z57.4) e (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "5",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XVII",
    "patologias": " Dermatite de Contato por Irritantes devida a Alimentos em contato com a pele (L24.6)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alimentos, em exposicao ocupacional (Z57.8) (Quadro XXVII)"
},
{
    "idNtepFap3": "6",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "IX",
    "patologias": "Outros transtornos especificados dos globulos brancos: leucocitose, reacao leucemoide (D72.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno  (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "7",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XV",
    "patologias": "Dermatite de Contato por Irritantes devida a Drogas em contato com a pele (L24.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Drogas, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "8",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XXI",
    "patologias": "Urticaria devida ao Calor e ao Frio (L50.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a calor e frio (W92.-); (W93.-); (Z57.6) (Quadro XXVII)"
},
{
    "idNtepFap3": "9",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XVII",
    "patologias": "Osteonecrose no Mal dos Caixoes (M90.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido  (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "10",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "VII",
    "patologias": "Otalgia e Secrecao Auditiva (H92.-): Otalgia (H92.0) (H92.2)  Otorreia (H92.1) ou Otorragia (H92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "11",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XII",
    "patologias": "Mal dos Caixoes (Doenca de Descompressao) (T70.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido  (W94.-); (Z57.8)(Quadro XXIII)"
},
{
    "idNtepFap3": "12",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "I",
    "patologias": "Otite Media nao-supurativa (H65.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "13",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "II",
    "patologias": "Perfuracao da Membrana do Timpano (S09.2 ou H72.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "13",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "II",
    "patologias": "Perfuracao da Membrana do Timpano (H72.- ou S09.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "14",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "X",
    "patologias": "Otite Barotraumatica (T70.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "15",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XI",
    "patologias": "Sinusite Barotraumatica (T70.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "16",
    "tipoDoenca": "Doencas infecciosas e parasitarias relacionadas com o trabalho  ( grupo i da cid-10 )",
    "grupo": "XIII",
    "patologias": "Sindrome devida ao deslocamento de ar de uma explosao (T70.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-); (Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "17",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "III",
    "patologias": "Dermatite Alergica de Contato devida a Adesivos (L23.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Adesivos, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "18",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "X",
    "patologias": "Dermatite Alergica de Contato devida a outros agentes (Causa Externa especificada) (L23.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes quimicos, nao especificados anteriormente, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "19",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XIX",
    "patologias": "Dermatite de Contato por Irritantes devida a outros agentes: Corantes (L24.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes quimicos, nao especificados anteriormente, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "20",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "X",
    "patologias": "Neoplasia maligna da bexiga (C67.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alcatrao, breu, betume, hulha mineral, parafina e produtos de residuos dessas substancias (X49.-); (Z57.5) (Quadro XX)"
},
{
    "idNtepFap3": "21",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VI",
    "patologias": "Cistite Aguda (N30.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Aminas aromaticas e seus derivados (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "22",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenico e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "23",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenico e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "24",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "25",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X48.-); (X49.-) ; (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "26",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "II",
    "patologias": "Angiossarcoma do figado (C22.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X48.-); (X49.-); (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "27",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VIII",
    "patologias": "Outras neoplasias malignas da pele (C44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "28",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XI",
    "patologias": "Polineuropatia devida a outros agentes toxicos (G62.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "29",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XIII",
    "patologias": "Encefalopatia Toxica Aguda (G92.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "30",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "I",
    "patologias": "Blefarite (H01.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "31",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "32",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "III",
    "patologias": "Queratite e Queratoconjuntivite (H16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "33",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VII",
    "patologias": "Ulceracao ou Necrose do Septo Nasal (J34.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "34",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VIII",
    "patologias": "Perfuracao do Septo Nasal (J34.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "35",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VIII",
    "patologias": "Hipertensao Portal (K76.6)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "36",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "37",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XXXII",
    "patologias": "Ceratose Palmar e Plantar Adquirida (L85.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.4) e (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "38",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "39",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "IV",
    "patologias": "Estomatite Ulcerativa Cronica (K12.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "40",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "V",
    "patologias": "Gastroenterite e Colite toxicas (K52.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Arsenio e seus compostos arsenicais (X49.-); (Z57.5) (Quadro I)"
},
{
    "idNtepFap3": "41",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "V",
    "patologias": "Placas epicardicas ou pericardicas (I34.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Asbesto ou Amianto (W83.-); (Z57.2) (Quadro II)"
},
{
    "idNtepFap3": "42",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "IX",
    "patologias": "Mesotelioma (C45.-):Mesotelioma da pleura (C45.0), Mesotelioma do peritonio (C45.1) e Mesotelioma do pericardio (C45.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Asbesto ou Amianto (X49.-); (Z57.2) (Quadro II)"
},
{
    "idNtepFap3": "43",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "I",
    "patologias": "Neoplasia maligna do estomago (C16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Asbesto ou Amianto (X49.-); (Z57.2)(Quadro II)"
},
{
    "idNtepFap3": "44",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "V",
    "patologias": "Neoplasia maligna da laringe (C32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Asbesto ou Amianto (Z57.2) (Quadro II)"
},
{
    "idNtepFap3": "45",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno  (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "45",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno  (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "45",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno  (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "45",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno  (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "45",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)(C95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno  (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "46",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "I",
    "patologias": "Sindromes Mielodisplasicas (D46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "47",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "IV",
    "patologias": " Aplastica devida a outros agentes externos (D61.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "48",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "V",
    "patologias": "Anemia Aplastica nao especificada, Anemia hipoplastica SOE, Hipoplasia medular (D61.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "49",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VII",
    "patologias": "Purpura e outras manifestacoes hemorragicas (D69.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "50",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VIII",
    "patologias": "Agranulocitose (Neutropenia toxica) (D70.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "51",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XIII",
    "patologias": "Dermatite de Contato por Irritantes devida a Solventes: Cetonas, Ciclohexano, Compostos do Cloro, esteres, Glicol, Hidrocarbonetos (L24.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Benzeno (X46.-); (Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "52",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Berilio e seus compostos toxicos (X49.-); (Z57.5) (Quadro IV)"
},
{
    "idNtepFap3": "53",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Berilio e seus compostos toxicos (X49.-); (Z57.5) (Quadro IV)"
},
{
    "idNtepFap3": "54",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "II",
    "patologias": "Delirium, nao sobreposto a demencia, como descrita (F05.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de Metila  (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "55",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "IV",
    "patologias": "Labirintite (H83.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de metila  (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "56",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "III",
    "patologias": "Outras formas especificadas de tremor (G25.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "57",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VI",
    "patologias": "Neurite optica (H46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "58",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "VII",
    "patologias": "Disturbios visuais subjetivos (H53.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "59",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "IX",
    "patologias": "Outros transtornos especificados do ouvido (H93.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "60",
    "tipoDoenca": "Neoplasias (tumores) relacionados com o trabalho (grupo ii da cid-10)",
    "grupo": "I",
    "patologias": "Faringite Aguda, nao especificada (Angina Aguda, Dor de Garganta) (J02.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-); (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "61",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "II",
    "patologias": "Laringotraqueite Aguda (J04.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-); (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "62",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "V",
    "patologias": "Faringite Cronica (J31.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-); (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "63",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "VI",
    "patologias": "Sinusite Cronica (J32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-); (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "64",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "IX",
    "patologias": "Laringotraqueite Cronica (J37.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-); (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "65",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-); (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "66",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "XXIX",
    "patologias": "Enfisema intersticial (J98.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos  (X49.-); (Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "67",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "XIII",
    "patologias": "Osteomalacia do Adulto induzida por drogas (M83.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-) (Quadro VI)"
},
{
    "idNtepFap3": "68",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "VII",
    "patologias": "Transtornos do nervo olfatorio (G52.0) (Inclui Anosmia) (R43.0) (G52.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-); (Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "68",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "VII",
    "patologias": "Transtornos do nervo olfatorio (G52.0) (Inclui Anosmia) (R43.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-); (Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "69",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "III",
    "patologias": "Nefropatia tubulo-intersticial induzida por metais pesados (N14.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-); (Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "70",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Carbonetos metalicos de tungstenio sinterizados (X49.-); (Z57.2) e (Z57.5) (Quadro VII)"
},
{
    "idNtepFap3": "71",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "V",
    "patologias": "Insuficiencia Renal Cronica (N18.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos (X49.-); (Z57.5)  (Quadro VIII)"
},
{
    "idNtepFap3": "72",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "II",
    "patologias": "Outras anemias devidas a transtornos enzimaticos (D55.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos  (X49.-); (Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "73",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "I",
    "patologias": "Hipotireoidismo devido a substancias exogenas (E03.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-); (Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "74",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "I",
    "patologias": "Hipertensao Arterial (I10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "75",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "VI",
    "patologias": "Outros transtornos funcionais do intestino (Sindrome dolorosa abdominal paroxistica apiretica, com estado suboclusivo (colica do chumbo) (K59.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "76",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "II",
    "patologias": "Gota induzida pelo chumbo (M10.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "77",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "VII",
    "patologias": "Infertilidade Masculina (N46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "78",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "XXXIV",
    "patologias": "Geladura (Frostbite) Superficial (T33.-): Eritema Pernio (L53.-) (T33.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de etila (anestesico local) (W93.-);(Z57.6) (Quadro XIII)"
},
{
    "idNtepFap3": "78",
    "tipoDoenca": "Doencas do sangue e dos orgaos hematopoeticos relacionadas com o trabalho ( grupo iii da cid-10 )",
    "grupo": "XXXIV",
    "patologias": "Geladura (Frostbite) Superficial (T33.-): Eritema Pernio (L53.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de etila (anestesico local) (W93.-);(Z57.6) (Quadro XIII)"
},
{
    "idNtepFap3": "79",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "XXXV",
    "patologias": "Geladura (Frostbite) com Necrose de Tecidos (T34.-) (T33.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de etila (anestesico local) (W93.-);(Z57.6) (Quadro XIII)"
},
{
    "idNtepFap3": "79",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "XXXV",
    "patologias": "Geladura (Frostbite) com Necrose de Tecidos (T34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de etila (anestesico local) (W93.-);(Z57.6) (Quadro XIII)"
},
{
    "idNtepFap3": "80",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "III",
    "patologias": "Outras vertigens perifericas Z57.5) (Quadro XIII) (H81.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de metileno e outros solventes halogenados toxicos  (X46.-);"
},
{
    "idNtepFap3": "81",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "XVI",
    "patologias": "Osteolise (de falanges distais de quirodactilos) (M89.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila  (X49.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "82",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "III",
    "patologias": "Neoplasia maligna do pancreas (C25.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "83",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "IX",
    "patologias": "Sindrome de Raynaud (I73.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de vinila (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "84",
    "tipoDoenca": "Doencas endocrinas, nutricionais e metabolicas relacionadas com o trabalho ( grupo iv da cid-10 )",
    "grupo": "X",
    "patologias": "Acrocianose e Acroparestesia (I73.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de vinila (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "85",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VII",
    "patologias": "Doenca Toxica do Figado (K71.-): Doenca Toxica do Figado, com Necrose Hepatica (K71.1); Doenca Toxica do Figado, com Hepatite Aguda (K71.2); Doenca Toxica do Figado com Hepatite Cronica Persistente (K71.3); Doenca Toxica do Figado com Outros Transtornos Hepaticos (K71.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila, Clorobenzeno,  Tetracloreto de Carbono,"
},
{
    "idNtepFap3": "86",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica)  (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloro gasoso (X47.-);(Z57.5) (Quadro IX)"
},
{
    "idNtepFap3": "87",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "II",
    "patologias": "Outras Porfirias (E80.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Clorobenzeno e seus derivados (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "88",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IV",
    "patologias": "Cor Pulmonale SOE ou Doenca Cardio-Pulmonar Cronica (I27.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Complicacao evolutiva das pneumoconioses graves, principalmente Silicose (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "89",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VI",
    "patologias": "Dermatite Alergica de Contato devida  a Corantes (L23.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Corantes, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "90",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Dermatite de Contato por Irritantes devida a Cosmeticos (L24.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cosmeticos, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "91",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Outras Infeccoes Locais da Pele e do Tecido Subcutaneo: Dermatoses Papulo-Pustulosas e suas complicacoes infecciosas (L08.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "92",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "II",
    "patologias": "Dermatite Alergica de Contato devida a Metais (L23.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "93",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "94",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXXIII",
    "patologias": "ulcera Cronica da Pele, nao classificada em outra parte (L98.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "95",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VI",
    "patologias": "Parada Cardiaca (I46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados halogenados dos hidrocarbonetos alifaticos (X46.- ) (Quadro XIII)"
},
{
    "idNtepFap3": "96",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXXI",
    "patologias": "Outros transtornos especificados da pigmentacao: Porfiria Cutanea Tardia (L81.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados halogenados dos hidrocarbonetos aromaticos: minocloro-benzeno, monobromo-benzeno, hexaclorobenzeno (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "97",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "III",
    "patologias": "Anemia Hemolitica adquirida (D59.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados nitrados e aminados do Benzeno (X46.-);(Z57.5)"
},
{
    "idNtepFap3": "98",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XI",
    "patologias": "Dermatite de Contato por Irritantes devida a Detergentes (L24.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Detergentes, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "99",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "V",
    "patologias": "Dermatite Alergica de Contato devida a Drogas em contato com a pele (L23.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Drogas, em exposicao ocupacional  (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "100",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "V",
    "patologias": "Tetano (A35.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ao Clostridium tetani, em circunstancias de acidentes do trabalho na agricultura, na construcao civil, na industria, ou em acidentes de trajeto (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "101",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXII",
    "patologias": "Urticaria de Contato (L50.6)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes quimicos, fisicos e biologicos que afetam a pele (X49.-); (Z57.4) e (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "102",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Efeitos toxicos de Solventes Organicos (T52.-): alcoois (T51.8) e Cetonas (T52.4); Benzeno Tolueno e Xileno (T52.1 e T52.2); Derivados halogenados dos Hidrocarbonetos Alifaticos e Aromaticos (T53.-): Tetracloreto de Carbono (T53.0); Cloroformio (T53.1); Tricloroetileno (T53.2); Tetracloroetileno (T53.3); Dicloroetano (T53.4); Clorofluor-carbonos (T53.5); Outros derivados halogenados de hidrocarbonetos alifaticos (T53.6); Outros derivados halogenados de hidrocarbonetos aromaticos (T53.7); Derivados halogenados de hidrocarbonetos alifaticos e aromaticos  nao especificados (T53.9); Sulfeto de Carbono (T65.4) (T51.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "102",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Efeitos toxicos de Solventes Organicos (T52.-): alcoois (T51.8) e Cetonas (T52.4); Benzeno Tolueno e Xileno (T52.1 e T52.2); Derivados halogenados dos Hidrocarbonetos Alifaticos e Aromaticos (T53.-): Tetracloreto de Carbono (T53.0); Cloroformio (T53.1); Tricloroetileno (T53.2); Tetracloroetileno (T53.3); Dicloroetano (T53.4); Clorofluor-carbonos (T53.5); Outros derivados halogenados de hidrocarbonetos alifaticos (T53.6); Outros derivados halogenados de hidrocarbonetos aromaticos (T53.7); Derivados halogenados de hidrocarbonetos alifaticos e aromaticos  nao especificados (T53.9); Sulfeto de Carbono (T65.4) (T52.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "102",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Efeitos toxicos de Solventes Organicos (T52.-): alcoois (T51.8) e Cetonas (T52.4); Benzeno Tolueno e Xileno (T52.1 e T52.2); Derivados halogenados dos Hidrocarbonetos Alifaticos e Aromaticos (T53.-): Tetracloreto de Carbono (T53.0); Cloroformio (T53.1); Tricloroetileno (T53.2); Tetracloroetileno (T53.3); Dicloroetano (T53.4); Clorofluor-carbonos (T53.5); Outros derivados halogenados de hidrocarbonetos alifaticos (T53.6); Outros derivados halogenados de hidrocarbonetos aromaticos (T53.7); Derivados halogenados de hidrocarbonetos alifaticos e aromaticos  nao especificados (T53.9); Sulfeto de Carbono (T65.4) (T53.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "102",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Efeitos toxicos de Solventes Organicos (T52.-): alcoois (T51.8) e Cetonas (T52.4); Benzeno Tolueno e Xileno (T52.1 e T52.2); Derivados halogenados dos Hidrocarbonetos Alifaticos e Aromaticos (T53.-): Tetracloreto de Carbono (T53.0); Cloroformio (T53.1); Tricloroetileno (T53.2); Tetracloroetileno (T53.3); Dicloroetano (T53.4); Clorofluor-carbonos (T53.5); Outros derivados halogenados de hidrocarbonetos alifaticos (T53.6); Outros derivados halogenados de hidrocarbonetos aromaticos (T53.7); Derivados halogenados de hidrocarbonetos alifaticos e aromaticos  nao especificados (T53.9); Sulfeto de Carbono (T65.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},

{
    "idNtepFap3": "103",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "II",
    "patologias": "Efeito toxico de Substancias Corrosivas (T54.-): Fenol e homologos do fenol (T54.0); Fluor e seus compostos (T65.8); Selenio e seus compostos (T56.8); Outros compostos organicos corrosivos (T54.1); acidos corrosivos e substancias acidas similares (T54.2); alcalis causticos e substancias alcalinas similares (T54.3); Efeito toxico de substancia corrosiva  nao especificada (T54.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "103",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "II",
    "patologias": "Efeito toxico de Substancias Corrosivas (T54.-): Fenol e homologos do fenol (T54.0); Fluor e seus compostos (T65.8); Selenio e seus compostos (T56.8); Outros compostos organicos corrosivos (T54.1); acidos corrosivos e substancias acidas similares (T54.2); alcalis causticos e substancias alcalinas similares (T54.3); Efeito toxico de substancia corrosiva  nao especificada (T54.9) (T56.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "103",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "II",
    "patologias": "Efeito toxico de Substancias Corrosivas (T54.-): Fenol e homologos do fenol (T54.0); Fluor e seus compostos (T65.8); Selenio e seus compostos (T56.8); Outros compostos organicos corrosivos (T54.1); acidos corrosivos e substancias acidas similares (T54.2); alcalis causticos e substancias alcalinas similares (T54.3); Efeito toxico de substancia corrosiva  nao especificada (T54.9) (T65.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "104",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "III",
    "patologias": "Efeito toxico de Metais (T56.-): Arsenico e seus compostos (T57.0); Cadmio e seus compostos (T56.3); Chumbo e seus compostos (T56.0); Cromo e seus compostos (T56.2); Manganes e seus compostos (T57.2); Mercurio e seus compostos (T56.1); Outros metais (T56.8); Metal  nao especificado (T56.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "104",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "III",
    "patologias": "Efeito toxico de Metais (T56.-): Arsenico e seus compostos (T57.0); Cadmio e seus compostos (T56.3); Chumbo e seus compostos (T56.0); Cromo e seus compostos (T56.2); Manganes e seus compostos (T57.2); Mercurio e seus compostos (T56.1); Outros metais (T56.8); Metal  nao especificado (T56.9) (T57.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "105",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IV",
    "patologias": "Asfixiantes Quimicos (T57.-)(T58.-)(T59.-): Monoxido de Carbono (T58.-); acido cianidrico e cianetos (T57.3); Sulfeto de hidrogenio (T59.6); Aminas aromaticas e seus derivados (T65.3) (T57.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "105",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IV",
    "patologias": "Asfixiantes Quimicos (T57.-)(T58.-)(T59.-): Monoxido de Carbono (T58.-); acido cianidrico e cianetos (T57.3); Sulfeto de hidrogenio (T59.6); Aminas aromaticas e seus derivados (T65.3) (T58.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "105",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IV",
    "patologias": "Asfixiantes Quimicos (T57.-)(T58.-)(T59.-): Monoxido de Carbono (T58.-); acido cianidrico e cianetos (T57.3); Sulfeto de hidrogenio (T59.6); Aminas aromaticas e seus derivados (T65.3) (T59.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "105",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IV",
    "patologias": "Asfixiantes Quimicos (T57.-)(T58.-)(T59.-): Monoxido de Carbono (T58.-); acido cianidrico e cianetos (T57.3); Sulfeto de hidrogenio (T59.6); Aminas aromaticas e seus derivados (T65.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos em outras industrias (Z57.5)"
},
{
    "idNtepFap3": "106",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "V",
    "patologias": "Praguicidas (Pesticidas, Agrotoxicos) (T60.-): Organofosforados e Carbamatos (T60.0); Halogenados (T60.1); Outros praguicidas (T60.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a agentes toxicos na Agricultura (Z57.4)"
},
{
    "idNtepFap3": "107",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XII",
    "patologias": "Candidiase (B37.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a Candida albicans, Candida glabrata, etc., em trabalhos que requerem longas imersoes das maos em agua e irritacao mecanica das maos, tais como trabalhadores de limpeza, lavadeiras, cozinheiras, entre outros. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "108",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XI",
    "patologias": "Dermatofitose (B35.-) e Outras Micoses Superficiais (B36.-) (B35.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a fungos do genero Epidermophyton, Microsporum e Trichophyton, em trabalhos em condicoes de temperatura elevada e umidade (cozinhas, ginasios, piscinas) e outras situacoes especificas de exposicao ocupacional. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "108",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XI",
    "patologias": "Dermatofitose (B35.-) e Outras Micoses Superficiais (B36.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a fungos do genero Epidermophyton, Microsporum e Trichophyton, em trabalhos em condicoes de temperatura elevada e umidade (cozinhas, ginasios, piscinas) e outras situacoes especificas de exposicao ocupacional. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "109",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XV",
    "patologias": "Leishmaniose Cutanea (B55.1) ou Leishmaniose Cutaneo-Mucosa (B55.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a Leishmania braziliensis, principalmente em trabalhos agricolas ou florestais e em zonas endemicas, e outras situacoes especificas de exposicao ocupacional. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "110",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IV",
    "patologias": "Leptospirose (A27.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a Leptospira icterohaemorrhagiae (e outras especies), em trabalhos expondo ao contato direto com aguas sujas, ou efetuado em locais suscetiveis de serem sujos por dejetos de animais portadores de germes; trabalhos efetuados dentro de minas, tuneis, galerias, esgotos em locais subterraneos; trabalhos em cursos d\u2019agua; trabalhos de drenagem; contato com roedores; trabalhos com animais domesticos, e com gado; preparacao de alimentos de origem animal, de peixes, de laticinios, etc.. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "111",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXI",
    "patologias": "Pneumonite por Hipersensibilidade a Poeira Organica (J67.-): Pulmao do Granjeiro (ou Pulmao do Fazendeiro) (J67.0); Bagacose (J67.1); Pulmao dos Criadores de Passaros (J67.2);Suberose (J67.3);Pulmao dos Trabalhadores de Malte (J67.4); Pulmao dos que Trabalham com Cogumelos (J67.5); Doenca Pulmonar Devida a Sistemas de Ar Condicionado e de Umidificacao do Ar (J67.7); Pneumonites de Hipersensibilidade Devidas a Outras Poeiras Organicas  (J67.8); Pneumonite de Hipersensibilidade Devida a Poeira Organica nao especificada (Alveolite Alergica Extrinseca SOE; Pneumonite de Hipersensibilidade SOE (J67.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras contendo microorganismos e parasitas infecciosos vivos e seus produtos toxicos (Z57.2) (Quadro XXV)"
},
{
    "idNtepFap3": "112",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XX",
    "patologias": "Doencas das vias aereas devidas a poeiras organicas (J66.-): Bissinose (J66.0), devidas a outras poeiras organicas especificadas (J66.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de algodao, linho, canhamo, sisal (Z57.2) (Quadro XXVI)"
},
{
    "idNtepFap3": "113",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXVIII",
    "patologias": "Placas pleurais (J92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de Asbesto ou Amianto  (Z57.2) (Quadro II)"
},
{
    "idNtepFap3": "114",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIII",
    "patologias": "Pneumoconiose devida ao Asbesto (Asbestose) e a outras fibras minerais (J61.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de asbesto ou amianto (Z57.2) (Quadro II)"
},
{
    "idNtepFap3": "115",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XV",
    "patologias": "Beriliose (J63.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de berilio e seus compostos toxicos (Z57.2) (Quadro IV)"
},
{
    "idNtepFap3": "116",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XVIII",
    "patologias": "Pneumoconiose devida a outras poeiras inorganicas especificadas (J63.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de carboneto de tungstenio (Z57.2) (Quadro VII)"
},
{
    "idNtepFap3": "117",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Artrite Reumatoide associada a Pneumoconiose dos Trabalhadores do Carvao (J60.-): Sindrome de Caplan (M05.3) (J60.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de carvao mineral  (Z57.2)"
},
{
    "idNtepFap3": "117",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Artrite Reumatoide associada a Pneumoconiose dos Trabalhadores do Carvao (J60.-): Sindrome de Caplan (M05.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de carvao mineral  (Z57.2)"
},
{
    "idNtepFap3": "118",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XII",
    "patologias": "Pneumoconiose dos Trabalhadores do Carvao (J60.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de carvao mineral (Z57.2)"
},
{
    "idNtepFap3": "119",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXX",
    "patologias": "Transtornos respiratorios em outras doencas sistemicas do tecido conjuntivo classificadas em outra parte (M05.3): Sindrome de Caplan (J99.1) (M05.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de Carvao Mineral (Z57.2)"
},
{
    "idNtepFap3": "119",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXX",
    "patologias": "Transtornos respiratorios em outras doencas sistemicas do tecido conjuntivo classificadas em outra parte (M05.3): Sindrome de Caplan (J99.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de Carvao Mineral (Z57.2)"
},
{
    "idNtepFap3": "120",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XVII",
    "patologias": "Estanhose (J63.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de estanho (Z57.2)"
},
{
    "idNtepFap3": "121",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XVI",
    "patologias": "Siderose (J63.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de ferro (Z57.2)"
},
{
    "idNtepFap3": "122",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Pneumoconiose devida a poeira de Silica (Silicose) (J62.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de silica-livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "123",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIX",
    "patologias": "Pneumoconiose associada com Tuberculose (Silico-Tuberculose) (J65.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de silica-livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "124",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VI",
    "patologias": "Efeitos da Pressao do Ar e da Pressao da agua (T70.-): Barotrauma Otitico (T70.0); Barotrauma Sinusal (T70.1); Doenca Descompressiva (Mal dos Caixoes) (T70.3); Outros efeitos da pressao do ar e da agua (T70.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a pressoes atmosfericas anormais (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "125",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XXIII",
    "patologias": "Queimadura Solar (L55.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a radiacoes actinicas (X32.-);(Z57.1) (Quadro XXVII)"
},
{
    "idNtepFap3": "126",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VII",
    "patologias": "Dengue [Dengue Classico] (A90.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao mosquito (Aedes aegypti), transmissor do arbovirus da Dengue, principalmente em atividades em zonas endemicas, em trabalhos de saude publica, e em trabalhos de laboratorios de pesquisa, entre outros. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "127",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VIII",
    "patologias": "Febre Amarela (A95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao mosquito (Aedes aegypti), transmissor do arbovirus da Febre Amarela, principalmente em atividades em zonas endemicas, em trabalhos de saude publica, e em trabalhos de laboratorios de pesquisa, entre outros. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "128",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Mycobacterium tuberculosis (Bacilo de Koch) ou Mycobacterium bovis, em atividades em laboratorios de biologia, e atividades realizadas por pessoal de saude, que propiciam contato direto com produtos contaminados ou com doentes cujos exames bacteriologicos sao positivos (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "128",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Mycobacterium tuberculosis (Bacilo de Koch) ou Mycobacterium bovis, em atividades em laboratorios de biologia, e atividades realizadas por pessoal de saude, que propiciam contato direto com produtos contaminados ou com doentes cujos exames bacteriologicos sao positivos (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "128",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)(A17.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Mycobacterium tuberculosis (Bacilo de Koch) ou Mycobacterium bovis, em atividades em laboratorios de biologia, e atividades realizadas por pessoal de saude, que propiciam contato direto com produtos contaminados ou com doentes cujos exames bacteriologicos sao positivos (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "128",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)(A17.-)(A18.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Mycobacterium tuberculosis (Bacilo de Koch) ou Mycobacterium bovis, em atividades em laboratorios de biologia, e atividades realizadas por pessoal de saude, que propiciam contato direto com produtos contaminados ou com doentes cujos exames bacteriologicos sao positivos (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "128",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)(A17.-)(A18.-)(A19.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Mycobacterium tuberculosis (Bacilo de Koch) ou Mycobacterium bovis, em atividades em laboratorios de biologia, e atividades realizadas por pessoal de saude, que propiciam contato direto com produtos contaminados ou com doentes cujos exames bacteriologicos sao positivos (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "129",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIII",
    "patologias": "Paracoccidioidomicose (Blastomicose Sul Americana, Blastomicose Brasileira, Doenca de Lutz) (B41.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Paracoccidioides brasiliensis, principalmente em trabalhos agricolas ou florestais e em zonas endemicas. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "130",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Malaria (B50.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Plasmodium malariae; Plasmodium vivax; Plasmodium falciparum ou outros protozoarios, principalmente em atividades de mineracao, construcao de barragens ou rodovias, em extracao de petroleo e outras atividades que obrigam a entrada dos trabalhadores em zonas endemicas (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "130",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Malaria (B50.-)(B51.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Plasmodium malariae; Plasmodium vivax; Plasmodium falciparum ou outros protozoarios, principalmente em atividades de mineracao, construcao de barragens ou rodovias, em extracao de petroleo e outras atividades que obrigam a entrada dos trabalhadores em zonas endemicas (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "130",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Malaria (B50.-)(B51.-)(B52.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Plasmodium malariae; Plasmodium vivax; Plasmodium falciparum ou outros protozoarios, principalmente em atividades de mineracao, construcao de barragens ou rodovias, em extracao de petroleo e outras atividades que obrigam a entrada dos trabalhadores em zonas endemicas (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "130",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Malaria (B50.-)(B51.-)(B52.-)(B53.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Plasmodium malariae; Plasmodium vivax; Plasmodium falciparum ou outros protozoarios, principalmente em atividades de mineracao, construcao de barragens ou rodovias, em extracao de petroleo e outras atividades que obrigam a entrada dos trabalhadores em zonas endemicas (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "130",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "XIV",
    "patologias": "Malaria (B50.-)(B51.-)(B52.-)(B53.-)(B54.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Plasmodium malariae; Plasmodium vivax; Plasmodium falciparum ou outros protozoarios, principalmente em atividades de mineracao, construcao de barragens ou rodovias, em extracao de petroleo e outras atividades que obrigam a entrada dos trabalhadores em zonas endemicas (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "131",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "V",
    "patologias": "Efeitos do ruido sobre o ouvido interno/ Perda da Audicao Provocada pelo Ruido e Trauma Acustico (H83.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Ruido (Z57.0; W42.-) (Quadro XXI)"
},
{
    "idNtepFap3": "132",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "VIII",
    "patologias": "Outras percepcoes auditivas anormais: Alteracao Temporaria do Limiar Auditivo Comprometimento da Discriminacao Auditiva e Hiperacusia (H93.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Ruido (Z57.0; X42.-) (Quadro XXI)"
},
{
    "idNtepFap3": "133",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IX",
    "patologias": "Hepatites Virais (B15.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Hepatite A (HAV); Virus da Hepatite B (HBV); Virus da Hepatite C (HCV); Virus da Hepatite D (HDV); Virus da Hepatite E (HEV), em trabalhos envolvendo manipulacao, acondicionamento ou emprego de sangue humano ou de seus derivados; trabalho com aguas usadas e esgotos; trabalhos em contato com materiais provenientes de doentes ou objetos contaminados por eles. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "133",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IX",
    "patologias": "Hepatites Virais (B15.-)(B16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Hepatite A (HAV); Virus da Hepatite B (HBV); Virus da Hepatite C (HCV); Virus da Hepatite D (HDV); Virus da Hepatite E (HEV), em trabalhos envolvendo manipulacao, acondicionamento ou emprego de sangue humano ou de seus derivados; trabalho com aguas usadas e esgotos; trabalhos em contato com materiais provenientes de doentes ou objetos contaminados por eles. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "133",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IX",
    "patologias": "Hepatites Virais (B15.-)(B16.-)(B17.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Hepatite A (HAV); Virus da Hepatite B (HBV); Virus da Hepatite C (HCV); Virus da Hepatite D (HDV); Virus da Hepatite E (HEV), em trabalhos envolvendo manipulacao, acondicionamento ou emprego de sangue humano ou de seus derivados; trabalho com aguas usadas e esgotos; trabalhos em contato com materiais provenientes de doentes ou objetos contaminados por eles. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "133",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IX",
    "patologias": "Hepatites Virais (B15.-)(B16.-)(B17.-)(B18.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Hepatite A (HAV); Virus da Hepatite B (HBV); Virus da Hepatite C (HCV); Virus da Hepatite D (HDV); Virus da Hepatite E (HEV), em trabalhos envolvendo manipulacao, acondicionamento ou emprego de sangue humano ou de seus derivados; trabalho com aguas usadas e esgotos; trabalhos em contato com materiais provenientes de doentes ou objetos contaminados por eles. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "133",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "IX",
    "patologias": "Hepatites Virais (B15.-)(B16.-)(B17.-)(B18.-)(B19.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Hepatite A (HAV); Virus da Hepatite B (HBV); Virus da Hepatite C (HCV); Virus da Hepatite D (HDV); Virus da Hepatite E (HEV), em trabalhos envolvendo manipulacao, acondicionamento ou emprego de sangue humano ou de seus derivados; trabalho com aguas usadas e esgotos; trabalhos em contato com materiais provenientes de doentes ou objetos contaminados por eles. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "134",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "X",
    "patologias": "Doenca pelo Virus da Imunodeficiencia Humana (HIV) (B15.-)(B16.-)(B17.-)(B18.-)(B19.-)(B20.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Imuno-deficiencia Humana (HIV), principalmente em trabalhadores da saude, em decorrencia de acidentes perfuro-cortantes com agulhas ou material cirurgico contaminado, e na manipulacao, acondicionamento ou emprego de sangue ou de seus derivados, e contato com materiais provenientes de pacientes infectados. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "134",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "X",
    "patologias": "Doenca pelo Virus da Imunodeficiencia Humana (HIV) (B20.-)(B21.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Imuno-deficiencia Humana (HIV), principalmente em trabalhadores da saude, em decorrencia de acidentes perfuro-cortantes com agulhas ou material cirurgico contaminado, e na manipulacao, acondicionamento ou emprego de sangue ou de seus derivados, e contato com materiais provenientes de pacientes infectados. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "134",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "X",
    "patologias": "Doenca pelo Virus da Imunodeficiencia Humana (HIV) (B20.-)(B21.-)(B22.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Imuno-deficiencia Humana (HIV), principalmente em trabalhadores da saude, em decorrencia de acidentes perfuro-cortantes com agulhas ou material cirurgico contaminado, e na manipulacao, acondicionamento ou emprego de sangue ou de seus derivados, e contato com materiais provenientes de pacientes infectados. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "134",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "X",
    "patologias": "Doenca pelo Virus da Imunodeficiencia Humana (HIV) (B20.-)(B21.-)(B22.-)(B23.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Imuno-deficiencia Humana (HIV), principalmente em trabalhadores da saude, em decorrencia de acidentes perfuro-cortantes com agulhas ou material cirurgico contaminado, e na manipulacao, acondicionamento ou emprego de sangue ou de seus derivados, e contato com materiais provenientes de pacientes infectados. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "134",
    "tipoDoenca": "Transtornos mentais e do comportamento relacionados com o trabalho ( grupo v da cid-10 ) DOENÇAS AGENT",
    "grupo": "X",
    "patologias": "Doenca pelo Virus da Imunodeficiencia Humana (HIV) (B20.-)(B21.-)(B22.-)(B23.-)(B24.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Virus da Imuno-deficiencia Humana (HIV), principalmente em trabalhadores da saude, em decorrencia de acidentes perfuro-cortantes com agulhas ou material cirurgico contaminado, e na manipulacao, acondicionamento ou emprego de sangue ou de seus derivados, e contato com materiais provenientes de pacientes infectados. (Z57.8) (Quadro XXV)"
},

{
    "idNtepFap3": "135",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "VIII",
    "patologias": "Dermatite Alergica de Contato devida a Alimentos em contato com a pele (fabricacao/ manipulacao) (L23.6)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fabricacao/manipulacao de Alimentos (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "136",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "IV",
    "patologias": "Dermatite Alergica de Contato devida a Cosmeticos (fabricacao/manipulacao) (L23.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fabricacao/manipulacao de Cosmeticos (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "137",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "XIV",
    "patologias": "Fluorose do Esqueleto (M85.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fluor e seus compostos toxicos (X49.-);(Z57.5)  (Quadro XI)"
},
{
    "idNtepFap3": "138",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "XV",
    "patologias": "Osteonecrose (M87.-): Osteonecrose devida a drogas (M87.1); Outras Osteonecroses secundarias (M87.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fosforo e seus compostos (Sesquissulfeto de Fosforo) (X49.-);(Z57.5) (Quadro XII)"
},
{
    "idNtepFap3": "139",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "I",
    "patologias": "Sindrome Nefritica Aguda (N00.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos alifaticos halogenados nefrotoxicos (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "140",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "IV",
    "patologias": "Insuficiencia Renal Aguda (N17.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos alifaticos halogenados nefrotoxicos (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "141",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "VI",
    "patologias": "Hipoacusia Ototoxica (H91.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Homologos do Benzeno otoneurotoxicos (Tolueno e Xileno) (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "142",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "I",
    "patologias": "Demencia em outras doencas especificas classificadas em outros locais (F02.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes  (X49.-); (Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "143",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "II",
    "patologias": "Parkisonismo Secundario devido a outros agentes externos (G21.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "144",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "V",
    "patologias": "Inflamacao Coriorretiniana (H30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "145",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "IX",
    "patologias": "Dermatite Alergica de Contato devida a Plantas (Nao inclui plantas usadas como alimentos) (L23.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manipulacao de Plantas, em exposicao ocupacional (Z57.8) (Quadro XXVII)"
},
{
    "idNtepFap3": "146",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "IV",
    "patologias": "Transtorno extrapiramidal do movimento nao especificado (G25.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos  (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "147",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "I",
    "patologias": "Ataxia Cerebelosa (G11.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "148",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "III",
    "patologias": "Gengivite Cronica (K05.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-);(Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "149",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "II",
    "patologias": "Doenca Glomerular Cronica (N03.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-);(Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "150",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "XI",
    "patologias": "Asma (J45.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mesma lista das substancias sensibilizantes produtoras de Rinite Alergica (X49.-); (Z57.2), (Z57.4) e (Z57.5)"
},
{
    "idNtepFap3": "151",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "II",
    "patologias": "Angina Pectoris (I20.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Monoxido de Carbono (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "152",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "III",
    "patologias": "Infarto Agudo do Miocardio (I21.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Monoxido de Carbono (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "153",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "I",
    "patologias": "Erosao Dentaria (K03.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nevoas  de fluoretos ou seus compostos toxicos (X47.-);(Z57.5) (Quadro XI)"
},
{
    "idNtepFap3": "154",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "II",
    "patologias": "Alteracoes pos-eruptivas da cor dos tecidos duros dos dentes (K03.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nevoas de Cadmio ou seus compostos (X47.-);(Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "155",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "XXVIII",
    "patologias": "Outras formas de Cistos Foliculares da Pele e do Tecido Subcutaneo: Elaioconiose ou Dermatite Folicular (L72.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oleos e gorduras de origem mineral ou sinteticos (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "156",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "XII",
    "patologias": "Dermatite de Contato por Irritantes devida a oleos e Gorduras (L24.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oleos e Gorduras, em exposicao ocupacional (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "157",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "VIII",
    "patologias": " Reacoes ao Stress Grave e Transtornos de Adaptacao (F43.-): Estado de Stress Pos-Traumatico (F43.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outras dificuldades fisicas e mentais relacionadas com o trabalho : reacao apos acidente do trabalho grave ou catastrofico, ou apos assalto no trabalho (Z56.6)"
},
{
    "idNtepFap3": "158",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "XVIII",
    "patologias": "Dermatite de Contato por Irritantes devida a Plantas, exceto alimentos (L24.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Plantas, em exposicao ocupacional (Z57.8) (Quadro XXVII)"
},
{
    "idNtepFap3": "159",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "VIII",
    "patologias": "Transtornos do plexo braquial (Sindrome da Saida do Torax, Sindrome do Desfiladeiro Toracico) (G54.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "160",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "IX",
    "patologias": "Mononeuropatias dos Membros Superiores (G56.-): Sindrome do Tunel do Carpo (G56.0); Outras Lesoes do Nervo Mediano: Sindrome do Pronador Redondo (G56.1); Sindrome do Canal de Guyon (G56.2); Lesao do Nervo Cubital (ulnar): Sindrome do Tunel Cubital(G56.2); Lesao do Nervo Radial (G56.3); Outras Mononeuropatias dos Membros Superiores: Compressao do Nervo Supra-escapular (G56.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "161",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "X",
    "patologias": "Mononeuropatias  do  membro inferior (G57.-): Lesao do Nervo Popliteo Lateral (G57.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "162",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Artroses (M19.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "163",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "IV",
    "patologias": "Outros transtornos articulares nao classificados em outra parte: Dor Articular (M25.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "164",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "V",
    "patologias": "Sindrome Cervicobraquial (M53.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "165",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "VI",
    "patologias": "Dorsalgia (M54.-): Cervicalgia (M54.2); Ciatica (M54.3); Lumbago com Ciatica (M54.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "166",
    "tipoDoenca": "Doencas do sistema nervoso relacionadas com o trabalho ( grupo vi da cid-10 )",
    "grupo": "VII",
    "patologias": "Sinovites e Tenossinovites (M65.-): Dedo em Gatilho (M65.3); Tenossinovite do Estiloide Radial (De Quervain) (M65.4); Outras Sinovites e Tenossinovites (M65.8); Sinovites e Tenossinovites, nao especificadas (M65.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "167",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VIII",
    "patologias": "Transtornos dos tecidos moles relacionados com o uso, o uso excessivo e a pressao, de origem ocupacional (M70.-): Sinovite Crepitante Cronica da mao e do punho (M70.0); Bursite da Mao (M70.1); Bursite do Olecrano (M70.2); Outras Bursites do Cotovelo (M70.3); Outras Bursites Pre-rotulianas (M70.4); Outras Bursites do Joelho (M70.5); Outros transtornos dos tecidos moles relacionados com o uso, o uso excessivo e a pressao (M70.8); Transtorno nao especificado dos tecidos moles, relacionados com o uso, o uso excessivo e a pressao (M70.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "168",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IX",
    "patologias": "Fibromatose da Fascia Palmar: Contratura ou Molestia de Dupuytren (M72.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "169",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "X",
    "patologias": "Lesoes do Ombro (M75.-): Capsulite Adesiva do Ombro (Ombro Congelado, Periartrite do Ombro) (M75.0); Sindrome do Manguito Rotatorio ou Sindrome do Supraespinhoso (M75.1); Tendinite Bicipital (M75.2); Tendinite Calcificante do Ombro (M75.3); Bursite do Ombro (M75.5); Outras Lesoes do Ombro (M75.8); Lesoes do Ombro, nao especificadas (M75.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "170",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XI",
    "patologias": "Outras entesopatias (M77.-): Epicondilite Medial (M77.0); Epicondilite lateral (Cotovelo de Tenista); Mialgia (M79.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "171",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XII",
    "patologias": "Outros transtornos especificados dos tecidos moles (M79.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Posicoes forcadas e gestos repetitivos (Z57.8)"
},
{
    "idNtepFap3": "172",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "X",
    "patologias": "Outros transtornos neuroticos especificados (Inclui Neurose Profissional) (F48.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego (Z56.-): Desemprego (Z56.0); Mudanca de emprego (Z56.1); Ameaca de perda de emprego (Z56.2); Ritmo de trabalho penoso (Z56.3); Desacordo com patrao e colegas de trabalho (Condicoes dificeis de trabalho) (Z56.5); Outras dificuldades fisicas e mentais relacionadas com o trabalho (Z56.6)"
},
{
    "idNtepFap3": "173",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VI",
    "patologias": "Transtornos mentais e comportamentais devidos ao uso do alcool: Alcoolismo Cronico (Relacionado com o Trabalho) (F10.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego: Condicoes dificeis de trabalho (Z56.5)"
},
{
    "idNtepFap3": "174",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XI",
    "patologias": "Transtorno do Ciclo Vigilia-Sono Devido a Fatores Nao-Organicos (F51.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego: Ma adaptacao a organizacao do horario de trabalho (Trabalho em Turnos ou Trabalho Noturno) (Z56.6)"
},
{
    "idNtepFap3": "175",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "V",
    "patologias": "Disturbios do Ciclo Vigilia-Sono (G47.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego: Ma adaptacao a organizacao do horario de trabalho (Trabalho em Turnos ou Trabalho Noturno) (Z56.6)"
},
{
    "idNtepFap3": "176",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XXIV",
    "patologias": "Outras Alteracoes Agudas da Pele  devidas a Radiacao Ultravioleta (L56.-): Dermatite por Fotocontato (Dermatite de Berloque) (L56.2); Urticaria Solar (L56.3);Outras Alteracoes Agudas Especificadas da Pele devidas a Radiacao Ultravioleta (L56.8); Outras Alteracoes Agudas da Pele devidas a Radiacao Ultravioleta, sem outra especificacao (L56.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacao Ultravioleta (W89.-);(Z57.1) (Quadro XXVII)"
},
{
    "idNtepFap3": "177",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VII",
    "patologias": "Neoplasia maligna dos ossos e cartilagens articulares dos membros (Inclui Sarcoma osseo) (C40.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "178",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IV",
    "patologias": "Catarata (H28.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "179",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XXVI",
    "patologias": "Pneumonite por Radiacao (manifestacao aguda) (J70.0) e Fibrose Pulmonar Consequente a Radiacao (manifestacao cronica) (J70.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "180",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XXVI",
    "patologias": "Radiodermatite (L58.-): 1. Radiodermatite Aguda (L58.0); Radiodermatite Cronica (L58.1); Radiodermatite, nao especificada (L58.9); Afeccoes da pele e do tecido conjuntivo relacionadas com a radiacao, nao especificadas (L59.9) (L58.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "180",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XXVI",
    "patologias": "Radiodermatite (L58.-): 1. Radiodermatite Aguda (L58.0); Radiodermatite Cronica (L58.1); Radiodermatite, nao especificada (L58.9); Afeccoes da pele e do tecido conjuntivo relacionadas com a radiacao, nao especificadas (L59.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "181",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1)(Quadro XXIV)"
},
{
    "idNtepFap3": "181",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)(C31.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1)(Quadro XXIV)"
},
{
    "idNtepFap3": "182",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XII",
    "patologias": "Polineuropatia induzida pela radiacao (G62.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (X88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "183",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XXV",
    "patologias": "Alteracoes da Pele devidas a Exposicao Cronica   a Radiacao Nao Ionizante (L57.-): Ceratose Actinica (L57.0); Outras Alteracoes: Dermatite Solar, Pele de Fazendeiro, Pele de Marinheiro (L57.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes nao-ionizantes (W89.-); (X32.-); (Z57.1) (Quadro XXVII)"
},
{
    "idNtepFap3": "184",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XII",
    "patologias": "Sensacao de Estar Acabado (Sindrome de Burn-Out, Sindrome do Esgotamento Profissional) (Z73.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ritmo de trabalho penoso (Z56.3)"
},
{
    "idNtepFap3": "185",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VIII",
    "patologias": "Ateroesclerose (I70.-) e Doenca Ateroesclerotica do Coracao (I25.1) (I70.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "185",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VIII",
    "patologias": "Ateroesclerose (I70.-) e Doenca Ateroesclerotica do Coracao (I25.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "186",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tolueno e outros solventes aromaticos neurotoxicos (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "187",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tolueno e outros solventes aromaticos neurotoxicos (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "188",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tolueno e outros solventes aromaticos neurotoxicos (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "189",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tolueno e outros solventes aromaticos neurotoxicos (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "190",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tolueno e outros solventes aromaticos neurotoxicos (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "191",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XIV",
    "patologias": "Encefalopatia Toxica Cronica (G92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tolueno e Xileno (X46.-);(Z57.5) (Quadro III)"
},
{
    "idNtepFap3": "192",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VI",
    "patologias": "Transtornos do nervo trigemio (G50.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tricloroetileno e outros solventes halogenados neurotoxicos (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "193",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XVIII",
    "patologias": "Doenca de Kienbock do Adulto (Osteo-condrose do Adulto do Semilunar do Carpo) (M93.1) e outras Osteocondro-patias especificadas (M93.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-);(Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "194",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "III",
    "patologias": "Brucelose (A23.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Zoonose causada pela exposicao ocupacional a Brucella melitensis, B. abortus, B. suis, B. canis, etc., em atividades em abatedouros, frigorificos, manipulacao de produtos de carne; ordenha e fabricacao de laticinios e atividades assemelhadas. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "195",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "II",
    "patologias": "Carbunculo (A22.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Zoonose causada pela exposicao ocupacional ao Bacillus anthracis, em atividades suscetiveis de colocar os trabalhadores em contato direto com animais infectados ou com cadaveres desses animais; trabalhos artesanais ou industriais com pelos, pele, couro ou la. (Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "196",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "VI",
    "patologias": "Psitacose, Ornitose, Doenca dos Tratadores de Aves (A70.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Zoonoses causadas pela exposicao ocupacional em trabalhos em criadouros de aves ou passaros, atividades de Veterinaria, em zoologicos, e em laboratorios biologicos, etc.(Z57.8) (Quadro XXV)"
},
{
    "idNtepFap3": "197",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cianeto de hidrogenio (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "198",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Enzimas de origem animal, vegetal ou bacteriano (X44.-);(Z57.3)"
},
{
    "idNtepFap3": "199",
    "tipoDoenca": "Doencas do olho e anexos relacionadas com o trabalho (grupo vii da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Niquel e seus compostos  (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "200",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Parafenilenodiamina e seus derivados (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "201",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "202",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes Ultravioletas  (W89.-); (Z57.1)"
},
{
    "idNtepFap3": "203",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "acido Sulfidrico (Sulfeto de hidrogenio) (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "204",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Acrilatos  (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "205",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Emissoes de fornos de coque (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "206",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Furfural e alcool Furfurilico (X45.-);(Z57.5)"
},
{
    "idNtepFap3": "207",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeira de determinadas madeiras (Z57.3) (Quadro XXVII)"
},
{
    "idNtepFap3": "208",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Selenio e seus compostos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "209",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Carbetos de metais duros (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "210",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cimento  (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "211",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Isocianatos organicos  (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "212",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Niquel e seus compostos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "213",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Quinino e seus derivados (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "214",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Acrilonitrila  (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "215",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Amonia (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "216",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Enzimas de origem animal, vegetal ou bacteriana  (X44.-);(Z57.2)"
},
{
    "idNtepFap3": "217",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Niquel e seus compostos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "218",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sais de ouro (X44.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "219",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Anidrido sulfuroso (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "220",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Furfural e alcool Furfurilico (X45.-);(Z57.5)"
},
{
    "idNtepFap3": "221",
    "tipoDoenca": "Doencas do ouvido relacionadas com o trabalho ( grupo viii da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Industria do aluminio (fundicoes) (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "222",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Pentoxido de vanadio  (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "223",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sais de prata (Sequelas de Dermatite Cronica de Contato) (X44.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "224",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Isocianatos organicos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "225",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Neblinas de oleos minerais (oleo de corte) (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "226",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nevoas e aerosois de acidos minerais (X47.-);(Z57.5)"
},
{
    "idNtepFap3": "227",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Produtos da pirolise de plasticos, cloreto de vinila, teflon  (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "228",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Acrilatos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "229",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fundicoes de metais (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "230",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Selenio e seus compostos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "231",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfitos, bissulfitos e persulfatos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "232",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Medicamentos: macrolidos; ranetidina ; penicilina e seus sais; cefalosporinas  (X44.-);(Z57.3)"
},
{
    "idNtepFap3": "233",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Selenio e seus compostos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "234",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Proteinas animais em aerossois (Z57.3)"
},
{
    "idNtepFap3": "235",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outras substancias de origem vegetal (cereais, farinhas, serragem, etc.) (Z57.2)"
},
{
    "idNtepFap3": "236",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "IX",
    "patologias": "Outros transtornos especificados do ouvido (H93.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido  (W94.-);(Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "237",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "IV",
    "patologias": "Labirintite (H83.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ar Comprimido (W94.-);(Z57.8) (Quadro XXIII)"
},
{
    "idNtepFap3": "238",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "IX",
    "patologias": "Outros transtornos especificados dos globulos brancos: leucocitose, reacao leucemoide (D72.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "239",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "III",
    "patologias": "Queratite e Queratoconjuntivite (H16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "acido sulfidrico (Sulfeto de hidrogenio) (X49.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "240",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "VIII",
    "patologias": "Outras neoplasias malignas da pele (C44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alcatrao, breu, betume, hulha mineral, parafina e produtos de residuos dessas substancias causadores de epiteliomas da pele (X49.-);(Z57.5) (Quadro XX)"
},
{
    "idNtepFap3": "241",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XI",
    "patologias": "Sinusite Barotraumatica (T70.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alteracoes na pressao atmosferica ou na pressao da agua no ambiente (W94.-)"
},
{
    "idNtepFap3": "242",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "X",
    "patologias": "Otite Barotraumatica (T70.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alteracoes na pressao atmosferica ou na pressao da agua no ambiente (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "243",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XII",
    "patologias": "Mal dos Caixoes (Doenca de Descompressao) (T70.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alteracoes na pressao atmosferica ou na pressao da agua no ambiente (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "244",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XIII",
    "patologias": "Sindrome devida ao deslocamento de ar de uma explosao (T70.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alteracoes na pressao atmosferica ou na pressao da agua no ambiente (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "245",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "X",
    "patologias": "Neoplasia maligna da bexiga (C67.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Aminas aromaticas e seus derivados (Beta- naftilamina, 2-cloroanilina, benzidina, o-toluidina, 4-cloro-orto-toluidina (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "246",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Asbesto ou Amianto (X49.-);(Z57.2) (Quadro II)"
},
{
    "idNtepFap3": "247",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Berilio e seus compostos (X49.-);(Z57.5) (Quadro IV)"
},
{
    "idNtepFap3": "248",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Berilio e seus compostos toxicos  (X49.-);(Z57.5) (Quadro IV)"
},
{
    "idNtepFap3": "249",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo  (X49.-);(Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "250",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-);(Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "251",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "IV",
    "patologias": "Estomatite Ulcerativa Cronica (K12.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-);(Z57.5) (Quadro XII)"
},
{
    "idNtepFap3": "252",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "VII",
    "patologias": "Ulceracao ou Necrose do Septo Nasal (J34.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-);(Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "253",
    "tipoDoenca": "Doencas do sistema circulatorio relacionadas com o trabalho ( grupo ix da cid-10 )",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-);(Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "254",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Erosao Dentaria (K03.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao  ocupacional  a outras nevoas acidas (X47.-);(Z57.5)"
},
{
    "idNtepFap3": "255",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Artrite Reumatoide associada a Pneumoconiose dos Trabalhadores do Carvao (J60.-): Sindrome de Caplan (M05.3) (J60.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de silica livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "255",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Artrite Reumatoide associada a Pneumoconiose dos Trabalhadores do Carvao (J60.-): Sindrome de Caplan (M05.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de silica livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "256",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Hipertensao Arterial (I10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional ao Ruido (Z57.0; X42.-) (Quadro XXI)"
},
{
    "idNtepFap3": "257",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Outras Infeccoes Locais da Pele e do Tecido Subcutaneo: Dermatoses Papulo-Pustulosas e suas complicacoes infecciosas (L08.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos alifaticos ou aromaticos (seus derivados toxicos) (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "258",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Hipotireoidismo devido a substancias exogenas (E03.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos halogenados (Clorobenzeno e seus derivados) (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "259",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hipersuscetibilidade do trabalhador exposto a poeiras de silica (Silico-tuberculose) (J65.-)"
},
{
    "idNtepFap3": "259",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hipersuscetibilidade do trabalhador exposto a poeiras de silica (Silico-tuberculose) (J65.-)"
},
{
    "idNtepFap3": "259",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)(A17.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hipersuscetibilidade do trabalhador exposto a poeiras de silica (Silico-tuberculose) (J65.-)"
},
{
    "idNtepFap3": "259",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)(A17.-)(A18.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hipersuscetibilidade do trabalhador exposto a poeiras de silica (Silico-tuberculose) (J65.-)"
},
{
    "idNtepFap3": "259",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Tuberculose (A15.-)(A16.-)(A17.-)(A18.-)(A19.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hipersuscetibilidade do trabalhador exposto a poeiras de silica (Silico-tuberculose) (J65.-)"
},
{
    "idNtepFap3": "260",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Faringite Aguda, nao especificada (Angina Aguda, Dor de Garganta) (J02.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "261",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Otite Media nao-supurativa (H65.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Pressao atmosferica inferior a pressao padrao (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "262",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Sindromes Mielodisplasicas (D46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "263",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Blefarite (H01.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes Ionizantes (W88.-);(Z57.1) (Quadro XXIV) Cimento  (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "264",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Demencia em outras doencas especificas classificadas em outros locais (F02.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Substancias asfixiantes: CO, H2S, etc. (sequela) (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "265",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "I",
    "patologias": "Outras Infeccoes Locais da Pele e do Tecido Subcutaneo: Dermatoses Papulo-Pustulosas e suas complicacoes infecciosas (L08.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Microorganismos e parasitas infecciosos vivos e seus produtos toxicos (Z57.5) (Quadro XXV)"
},
{
    "idNtepFap3": "266",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Angiossarcoma do figado (C22.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "267",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Alteracoes pos-eruptivas da cor dos tecidos duros dos dentes (K03.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a metais: Cobre, Niquel, Prata (X47.-);(Z57.5)"
},
{
    "idNtepFap3": "268",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Laringotraqueite Aguda (J04.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "269",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Dermatite Alergica de Contato devida a Metais (L23.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "270",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Perfuracao da Membrana do Timpano (S09.2 0u H72.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Pressao atmosferica inferior a pressao padrao (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "270",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Perfuracao da Membrana do Timpano (H72.- ou S09.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Pressao atmosferica inferior a pressao padrao (W94.-);(Z57.8)"
},
{
    "idNtepFap3": "271",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Delirium, nao sobreposto a demencia, como descrita (F05.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "272",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Angina Pectoris (I20.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "273",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fluor e seus compostos toxicos (X49.-)  (Quadro XI)"
},
{
    "idNtepFap3": "274",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "275",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Nefropatia tubulo-intersticial induzida por metais pesados (N14.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "276",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (X49.-);(Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "277",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Neoplasia maligna do pancreas (C25.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Epicloridrina (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "278",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Infarto Agudo do Miocardio (I21.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "279",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Outras formas especificadas de tremor (G25.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tetracloroetano (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "280",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outras substancias quimicas sensibilizantes da pele e das vias respiratorias (X49.-);(Z57.2) (Quadro XXVII)"
},
{
    "idNtepFap3": "281",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Neoplasia maligna do pancreas (C25.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos alifafitos e aromaticos na Industria do Petroleo (X46.-);(Z57.5)"
},
{
    "idNtepFap3": "282",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Outras formas especificadas de tremor (G25.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-) (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "283",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "III",
    "patologias": "Nefropatia tubulo-intersticial induzida por metais pesados (N14.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "284",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Transtorno extrapiramidal do movimento nao especificado (G25.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de metileno (Diclorometano) e outros solventes halogenados neurotoxicos (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "285",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloro gasoso (X47.-);(Z57.5) (Quadro IX)"
},
{
    "idNtepFap3": "286",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Niquel e seus compostos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "286",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)(C31.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Niquel e seus compostos (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "287",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Catarata (H28.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes Infravermelhas (W90.-); (Z57.1)"
},
{
    "idNtepFap3": "288",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": " Aplastica devida a outros agentes externos (D61.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-) (Quadro XXIV)"
},
{
    "idNtepFap3": "289",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tricloroetileno, Tetracloroetileno, Tricloroetano e outros solventes organicos halogenados neurotoxicos (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "290",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Outros transtornos articulares nao classificados em outra parte: Dor Articular (M25.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-); (Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "291",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de Metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "292",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (X49.-) (Quadro X)"
},
{
    "idNtepFap3": "293",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IV",
    "patologias": "Estomatite Ulcerativa Cronica (K12.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "294",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tricloroetileno, Tetracloroetileno, Tricloroetano e outros solventes organicos halogenados (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "295",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IX",
    "patologias": "Sindrome de Raynaud (I73.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-); (Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "296",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IX",
    "patologias": "Fibromatose da Fascia Palmar: Contratura ou Molestia de Dupuytren (M72.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-); (Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "297",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de Metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "298",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "V",
    "patologias": "Gastroenterite e Colite toxicas (K52.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-); (Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "299",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "V",
    "patologias": "Anemia Aplastica nao especificada, Anemia hipoplastica SOE, Hipoplasia medular (D61.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "300",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tricloroetileno, Tetracloroetileno, Tricloroetano e outros solventes organicos halogenados neurotoxicos (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "301",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "V",
    "patologias": "Sindrome Cervicobraquial (M53.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-); (Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "302",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de Metila (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "303",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Transtornos mentais e comportamentais devidos ao uso do alcool: Alcoolismo Cronico (Relacionado com o Trabalho) (F10.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Circunstancia relativa as condicoes de trabalho (Y96.-)"
},
{
    "idNtepFap3": "304",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Neurite optica (H46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de metileno (Diclorometano) e outros solventes clorados neurotoxicos (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "305",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Sinusite Cronica (J32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-); (Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "306",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Parada Cardiaca (I46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Monoxido de Carbono (X47.-); (Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "307",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Dorsalgia (M54.-): Cervicalgia (M54.2); Ciatica (M54.3); Lumbago com Ciatica (M54.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ritmo de trabalho penoso (Z56.3)"
},
{
    "idNtepFap3": "308",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Hipoacusia Ototoxica (H91.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solventes organicos otoneurotoxicos (X46.-); (Z57.8) (Quadro XIII)"
},
{
    "idNtepFap3": "309",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Berilio (X49.-); (Z57.5) (Quadro IV)"
},
{
    "idNtepFap3": "310",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VI",
    "patologias": "Dorsalgia (M54.-): Cervicalgia (M54.2); Ciatica (M54.3); Lumbago com Ciatica (M54.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Condicoes dificeis de trabalho (Z56.5)"
},
{
    "idNtepFap3": "311",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X49.-); (Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "312",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Disturbios visuais subjetivos (H53.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de metileno e outros solventes clorados neurotoxicos (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "313",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Purpura e outras manifestacoes hemorragicas (D69.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila  (X46.-) (Quadro XIII)"
},
{
    "idNtepFap3": "314",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Doenca Toxica do Figado (K71.-): Doenca Toxica do Figado, com Necrose Hepatica (K71.1); Doenca Toxica do Figado, com Hepatite Aguda (K71.2); Doenca Toxica do Figado com Hepatite Cronica Persistente (K71.3); Doenca Toxica do Figado com Outros Transtornos Hepaticos (K71.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloroformio, e outros solventes halogenados hepatotoxicos (X46.-) e (X48.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "315",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fosforo ou seus produtos toxicos (Z57.5) (Quadro XII)"
},
{
    "idNtepFap3": "316",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Infertilidade Masculina (N46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-: Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "317",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Sinovites e Tenossinovites (M65.-): Dedo em Gatilho (M65.3); Tenossinovite do Estiloide Radial (De Quervain) (M65.4); Outras Sinovites e Tenossinovites (M65.8); Sinovites e Tenossinovites, nao especificadas (M65.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ritmo de trabalho penoso (Z56.3)"
},
{
    "idNtepFap3": "318",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Transtornos do nervo olfatorio (G52.0) (Inclui Anosmia) (R43.0) (G52.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de hidrogenio (X49.-); (Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "318",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Transtornos do nervo olfatorio (G52.0) (Inclui Anosmia) (R43.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de hidrogenio (X49.-); (Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "319",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tricloroetileno, Tetracloroetileno, Tricloroetano e outros solventes organicos halogenados neurotoxicos (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "320",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de Metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "321",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Infertilidade Masculina (N46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chlordecone (X48.-); (Z57.4)"
},
{
    "idNtepFap3": "322",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Sinovites e Tenossinovites (M65.-): Dedo em Gatilho (M65.3); Tenossinovite do Estiloide Radial (De Quervain) (M65.4); Outras Sinovites e Tenossinovites (M65.8); Sinovites e Tenossinovites, nao especificadas (M65.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Condicoes dificeis de trabalho (Z56.5)"
},
{
    "idNtepFap3": "323",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Ulceracao ou Necrose do Septo Nasal (J34.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (X49.-); (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "324",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados halogenados dos hidrocarbonetos alifaticos (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "325",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Doenca Toxica do Figado (K71.-): Doenca Toxica do Figado, com Necrose Hepatica (K71.1); Doenca Toxica do Figado, com Hepatite Aguda (K71.2); Doenca Toxica do Figado com Hepatite Cronica Persistente (K71.3); Doenca Toxica do Figado com Outros Transtornos Hepaticos (K71.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hexaclorobenzeno (HCB) (X48.-); (Z57.4) e (Z57.5)"
},
{
    "idNtepFap3": "326",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "327",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": " Reacoes ao Stress Grave e Transtornos de Adaptacao (F43.-): Estado de Stress Pos-Traumatico (F43.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Circunstancia relativa as condicoes de trabalho (Y96.-)"
},
{
    "idNtepFap3": "328",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": "Hipertensao Portal (K76.6)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "329",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": "Perfuracao do Septo Nasal (J34.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (X49.-); (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "330",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": "Agranulocitose (Neutropenia toxica) (D70.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes  (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "331",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": "Transtornos dos tecidos moles relacionados com o uso, o uso excessivo e a pressao, de origem ocupacional (M70.-): Sinovite Crepitante Cronica da mao e do punho (M70.0); Bursite da Mao (M70.1); Bursite do Olecrano (M70.2); Outras Bursites do Cotovelo (M70.3); Outras Bursites Pre-rotulianas (M70.4); Outras Bursites do Joelho (M70.5); Outros transtornos dos tecidos moles relacionados com o uso, o uso excessivo e a pressao (M70.8); Transtorno nao especificado dos tecidos moles, relacionados com o uso, o uso excessivo e a pressao (M70.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ritmo de trabalho penoso (Z56.3)"
},
{
    "idNtepFap3": "332",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": "Transtornos dos tecidos moles relacionados com o uso, o uso excessivo e a pressao, de origem ocupacional (M70.-): Sinovite Crepitante Cronica da mao e do punho (M70.0); Bursite da Mao (M70.1); Bursite do Olecrano (M70.2); Outras Bursites do Cotovelo (M70.3); Outras Bursites Pre-rotulianas (M70.4); Outras Bursites do Joelho (M70.5); Outros transtornos dos tecidos moles relacionados com o uso, o uso excessivo e a pressao (M70.8); Transtorno nao especificado dos tecidos moles, relacionados com o uso, o uso excessivo e a pressao (M70.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Condicoes dificeis de trabalho (Z56.5)"
},
{
    "idNtepFap3": "333",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "VIII",
    "patologias": "Agranulocitose (Neutropenia toxica) (D70.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados do Fenol, Pentaclorofenol, Hidroxibenzonitrilo (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "334",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica)  (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeira de silica livre (Z57.2-) (Quadro XVIII)"
},
{
    "idNtepFap3": "335",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "X",
    "patologias": "Lesoes do Ombro (M75.-): Capsulite Adesiva do Ombro (Ombro Congelado, Periartrite do Ombro) (M75.0); Sindrome do Manguito Rotatorio ou Sindrome do Supraespinhoso (M75.1); Tendinite Bicipital (M75.2); Tendinite Calcificante do Ombro (M75.3); Bursite do Ombro (M75.5); Outras Lesoes do Ombro (M75.8); Lesoes do Ombro, nao especificadas (M75.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Ritmo de trabalho penoso (Z56.-)"
},
{
    "idNtepFap3": "336",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "X",
    "patologias": "Acrocianose e Acroparestesia (I73.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-); (Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "337",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "X",
    "patologias": "Neoplasia maligna da bexiga (C67.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Emissoes de fornos de coque (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "338",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica)  (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de algodao, linho, canhamo ou sisal (Z57.2) (Quadro XXVI)"
},
{
    "idNtepFap3": "339",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Polineuropatia devida a outros agentes toxicos (G62.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo e seus compostos toxicos (X49.-); (Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "340",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Transtorno do Ciclo Vigilia-Sono Devido a Fatores Nao-Organicos (F51.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Circunstancia relativa as condicoes de trabalho (Y96.-)"
},
{
    "idNtepFap3": "341",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "341",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "341",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "341",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "341",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)(C95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-); (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "342",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Outras entesopatias (M77.-): Epicondilite Medial (M77.0); Epicondilite lateral (Cotovelo de Tenista); Mialgia (M79.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-);(Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "342",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Outras entesopatias (M77.-): Epicondilite lateral (Cotovelo de Tenista); Mialgia (M79.1); Epicondilite Medial (M77.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-);(Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "343",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XI",
    "patologias": "Polineuropatia devida a outros agentes toxicos (G62.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fosforo (X48.-); (X49.-); (Z57.4) e (Z57.5) (Quadro XII)"
},
{
    "idNtepFap3": "344",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XII",
    "patologias": "Pneumoconiose dos Trabalhadores do Carvao (J60.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de silica-livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "345",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XII",
    "patologias": "Sensacao de Estar Acabado (Sindrome de Burn-Out, Sindrome do Esgotamento Profissional) (Z73.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outras dificuldades fisicas e mentais relacionadas com o trabalho (Z56.6)"
},
{
    "idNtepFap3": "346",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XII",
    "patologias": "Outros transtornos especificados dos tecidos moles (M79.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-);(Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "347",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XIII",
    "patologias": "Encefalopatia Toxica Aguda (G92.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo e seus compostos toxicos  (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "348",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XIII",
    "patologias": "Osteomalacia do Adulto induzida por drogas (M83.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fosforo e seus compostos (Sesquissulfeto de Fosforo) (X49.-);(Z57.5) (Quadro XII)"
},
{
    "idNtepFap3": "349",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XIII",
    "patologias": "Dermatite de Contato por Irritantes devida a Solventes: Cetonas, Ciclohexano, Compostos do Cloro, esteres, Glicol, Hidrocarbonetos (L24.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos aromaticos ou alifaticos ou seus derivados halogenados toxicos (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "350",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XIII",
    "patologias": "Encefalopatia Toxica Aguda (G92.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidrocarbonetos alifaticos ou aromaticos (seus derivados halogenados neurotoxicos) (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "351",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XIV",
    "patologias": "Encefalopatia Toxica Cronica (G92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo e seus compostos toxicos (X49.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "352",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XV",
    "patologias": "Osteonecrose (M87.-): Osteonecrose devida a drogas (M87.1); Outras Osteonecroses secundarias (M87.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas  (W43.-);(Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "353",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XVI",
    "patologias": "Dermatite de Contato por Irritantes Berilio, Bromo, Cromo, Cimento, Fluor, Fosforo, Inseticidas (L24.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "354",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XVIII",
    "patologias": "Pneumoconiose devida a outras poeiras inorganicas especificadas (J63.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de carbetos de metais duros (Cobalto, Titanio, etc.) (Z57.2)"
},
{
    "idNtepFap3": "355",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XVIII",
    "patologias": "Pneumoconiose devida a outras poeiras inorganicas especificadas (J63.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a rocha fosfatica (Z57.2)"
},
{
    "idNtepFap3": "356",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXI",
    "patologias": "Pneumonite por Hipersensibilidade a Poeira Organica (J67.-): Pulmao do Granjeiro (ou Pulmao do Fazendeiro) (J67.0); Bagacose (J67.1); Pulmao dos Criadores de Passaros (J67.2);Suberose (J67.3);Pulmao dos Trabalhadores de Malte (J67.4); Pulmao dos que Trabalham com Cogumelos (J67.5); Doenca Pulmonar Devida a Sistemas de Ar Condicionado e de Umidificacao do Ar (J67.7); Pneumonites de Hipersensibilidade Devidas a Outras Poeiras Organicas  (J67.8); Pneumonite de Hipersensibilidade Devida a Poeira Organica nao especificada (Alveolite Alergica Extrinseca SOE; Pneumonite de Hipersensibilidade SOE (J67.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a outras poeiras organicas (Z57.2)"
},
{
    "idNtepFap3": "357",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-);(Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "358",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-);(Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "359",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Gas Cloro (X47.-);(Z57.5) (Quadro IX)"
},
{
    "idNtepFap3": "360",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Clorobenzeno e Diclorobenzeno (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "361",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alcatrao, Breu, Betume, Hulha Mineral, Parafina, Creosoto, Piche, Coaltar ou residuos dessas substancias (Z57.8) (Quadro XX)"
},
{
    "idNtepFap3": "362",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bromo (X49.-);(Z57.5) (Quadro V)"
},
{
    "idNtepFap3": "363",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXVII",
    "patologias": "Outras formas de Acne: Cloracne (L70.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Derivados do fenol, pentaclorofenol e do hidrobenzonitrilo (X49.-); (Z57.4) e (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "364",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXX",
    "patologias": "Transtornos respiratorios em outras doencas sistemicas do tecido conjuntivo classificadas em outra parte (M05.3): Sindrome de Caplan (J99.1) (M05.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de Silica livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "364",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXX",
    "patologias": "Transtornos respiratorios em outras doencas sistemicas do tecido conjuntivo classificadas em outra parte (M05.3): Sindrome de Caplan (J99.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de Silica livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "365",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Hidroquinona e esteres derivados (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "366",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXXIII",
    "patologias": "ulcera Cronica da Pele, nao classificada em outra parte (L98.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Enzimas de origem animal, vegetal ou bacteriana  (Z57.8) (Quadro XXVII)"
},
{
    "idNtepFap3": "367",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXXIV",
    "patologias": "Geladura (Frostbite) Superficial (T33.-): Eritema Pernio (L53.-) (T33.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Frio (X31.-); (W93.-); (Z57.6) (Quadro XXVII)"
},
{
    "idNtepFap3": "367",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXXIV",
    "patologias": "Geladura (Frostbite) Superficial (T33.-): Eritema Pernio (L53.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Frio (X31.-); (W93.-); (Z57.6) (Quadro XXVII)"
},
{
    "idNtepFap3": "368",
    "tipoDoenca": "Doencas do sistema respiratorio relacionadas com o trabalho (grupo x da cid-10)",
    "grupo": "XXXV",
    "patologias": "Geladura (Frostbite) com Necrose de Tecidos (T34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Frio (X31.-); (W93.-); (Z57.6) (Quadro XXVII)"
},
{
    "idNtepFap3": "369",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Monometil eter de hidroquinona (MBEH) (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "370",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "II",
    "patologias": "Angina Pectoris (I20.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nitroglicerina e outros esteres do acido nitrico (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "371",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "III",
    "patologias": "Infarto Agudo do Miocardio (I21.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nitroglicerina e outros esteres do acido nitrico (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "372",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "VI",
    "patologias": "Parada Cardiaca (I46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros agentes potencialmente causadores de arritmia cardiaca (Z57.5)"
},
{
    "idNtepFap3": "373",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oxido de etileno (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "373",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oxido de etileno (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "373",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oxido de etileno (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "373",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oxido de etileno (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "373",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)(C95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oxido de etileno (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "374",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras de algodao, linho, canhamo ou sisal (Z57.2) (Quadro XXVI)"
},
{
    "idNtepFap3": "375",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras de madeira e outras poeiras organicas da industria do mobiliario (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "375",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)(C31.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras de madeira e outras poeiras organicas da industria do mobiliario (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "376",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XXVII",
    "patologias": "Outras formas de Acne: Cloracne (L70.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Policloretos de Bifenila (PCBs) (X49.-); (Z57.4) e (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "377",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "I",
    "patologias": "Hipertensao Arterial (I10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego (Z56.-)"
},
{
    "idNtepFap3": "378",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "VIII",
    "patologias": "Outras neoplasias malignas da pele (C44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "379",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "VII",
    "patologias": "Purpura e outras manifestacoes hemorragicas (D69.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "380",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "III",
    "patologias": "Queratite e Queratoconjuntivite (H16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "381",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "V",
    "patologias": "Gastroenterite e Colite toxicas (K52.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "382",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XV",
    "patologias": "Osteonecrose (M87.-): Osteonecrose devida a drogas (M87.1); Outras Osteonecroses secundarias (M87.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "383",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "XIV",
    "patologias": "Encefalopatia Toxica Cronica (G92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solventes organicos halogenados neurotoxicos (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "384",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "I",
    "patologias": "Demencia em outras doencas especificas classificadas em outros locais (F02.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "385",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "VI",
    "patologias": "Neurite optica (H46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tetracloreto de carbono (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "386",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "I",
    "patologias": "Hipotireoidismo devido a substancias exogenas (E03.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tiuracil (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "387",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "VIII",
    "patologias": "Hipertensao Portal (K76.6)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Torio (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "388",
    "tipoDoenca": "Doencas do sistema digestivo relacionadas com o trabalho ( grupo xi da cid-10 )",
    "grupo": "IX",
    "patologias": "Sindrome de Raynaud (I73.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Trabalho em baixas temperaturas (frio) (W93.-);(Z57.6)"
},
{
    "idNtepFap3": "389",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "I",
    "patologias": "Outras Infeccoes Locais da Pele e do Tecido Subcutaneo: Dermatoses Papulo-Pustulosas e suas complicacoes infecciosas (L08.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros agentes quimicos ou biologicos que afetem a pele, nao considerados em outras rubricas (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "390",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "I",
    "patologias": "Hipotireoidismo devido a substancias exogenas (E03.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tiocinatos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "391",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "I",
    "patologias": "Hipotireoidismo devido a substancias exogenas (E03.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tiureia (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "392",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "393",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "II",
    "patologias": "Angina Pectoris (I20.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego (Z56.-)"
},
{
    "idNtepFap3": "394",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de etila (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "395",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tricloroetileno, Tetracloroetileno, Tricloroetano e outros solventes organicos halogenados neurotoxicos (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "396",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Acrilatos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "397",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Brometo de Metila (X46.-); (Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "398",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outras formas especificadas de tremor (G25.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes organicos neurotoxicos (X46.-); (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "399",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Infarto Agudo do Miocardio (I21.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego (Z56.-)"
},
{
    "idNtepFap3": "400",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Queratite e Queratoconjuntivite (H16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes Infravermelhas (W90.-); (Z57.1)"
},
{
    "idNtepFap3": "401",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Aldeido formico e seus polimeros (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "402",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "403",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Queratite e Queratoconjuntivite (H16.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes Ultravioletas (W89.-);(Z57.1)"
},
{
    "idNtepFap3": "404",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Aminas aromaticas e seus derivados (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "405",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Gas de fluor e Fluoreto de Hidrogenio (X47.-);(Z57.5) (Quadro XI)"
},
{
    "idNtepFap3": "406",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "407",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras da industria do couro (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "407",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)(C31.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras da industria do couro (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "408",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Amonia  (X47.-);(Z57.5)"
},
{
    "idNtepFap3": "409",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "410",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras organicas (na industria textil e em padarias (X49.-); (Z57.2)"
},
{
    "idNtepFap3": "410",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)(C31.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Poeiras organicas (na industria textil e em padarias (X49.-);(Z57.2)"
},
{
    "idNtepFap3": "411",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Anidrido sulfuroso (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "412",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "413",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "414",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "415",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "416",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos  (X49.-);(Z57.5)  (Quadro VI)"
},
{
    "idNtepFap3": "417",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VI",
    "patologias": "Neurite optica (H46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "418",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos  (X49.-);(Z57.5)  (Quadro X)"
},
{
    "idNtepFap3": "419",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VI",
    "patologias": "Neurite optica (H46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Metanol (X45.-);(Z57.5)"
},
{
    "idNtepFap3": "420",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cloreto de Vinila (X46.-); (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "421",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alcatrao, Breu, Betume, Hulha Mineral, Parafina ou residuos dessas substancias (Z57.8) (Quadro XX)"
},
{
    "idNtepFap3": "422",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Doenca Toxica do Figado (K71.-): Doenca Toxica do Figado, com Necrose Hepatica (K71.1); Doenca Toxica do Figado, com Hepatite Aguda (K71.2); Doenca Toxica do Figado com Hepatite Cronica Persistente (K71.3); Doenca Toxica do Figado com Outros Transtornos Hepaticos (K71.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bifenilas policloradas (PCBs) (X49.-); (Z57.4) e (Z57.5)"
},
{
    "idNtepFap3": "423",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Infertilidade Masculina (N46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Dibromocloropropano (DBCP) (X48.-); (Z57.4) e (Z57.5)"
},
{
    "idNtepFap3": "424",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "425",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-);(Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "426",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Ulceracao ou Necrose do Septo Nasal (J34.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solucoes e aeoressois de acido Cianidrico e seus derivados (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "427",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Borracha (Z57.8) (Quadro XXVII)"
},
{
    "idNtepFap3": "428",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Infertilidade Masculina (N46.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Calor (trabalho em temperaturas elevadas) (Z57.6)"
},
{
    "idNtepFap3": "429",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "430",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Monoxido de Carbono (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "431",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Doenca Toxica do Figado (K71.-): Doenca Toxica do Figado, com Necrose Hepatica (K71.1); Doenca Toxica do Figado, com Hepatite Aguda (K71.2); Doenca Toxica do Figado com Hepatite Cronica Persistente (K71.3); Doenca Toxica do Figado com Outros Transtornos Hepaticos (K71.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tetraclorodibenzodioxina (TCDD) (X49.-)"
},
{
    "idNtepFap3": "432",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos organofosforados e carbamatos (X48; Z57.4) (Quadros XII e XXVII)"
},
{
    "idNtepFap3": "433",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "VIII",
    "patologias": "Outras neoplasias malignas da pele (C44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ultravioletas (W89; Z57.1)"
},
{
    "idNtepFap3": "434",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "X",
    "patologias": "Acrocianose e Acroparestesia (I73.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Trabalho em baixas temperaturas (frio) (W93.-);(Z57.6)"
},
{
    "idNtepFap3": "435",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "X",
    "patologias": "Lesoes do Ombro (M75.-): Capsulite Adesiva do Ombro (Ombro Congelado, Periartrite do Ombro) (M75.0); Sindrome do Manguito Rotatorio ou Sindrome do Supraespinhoso (M75.1); Tendinite Bicipital (M75.2); Tendinite Calcificante do Ombro (M75.3); Bursite do Ombro (M75.5); Outras Lesoes do Ombro (M75.8); Lesoes do Ombro, nao especificadas (M75.9)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Vibracoes localizadas (W43.-);(Z57.7) (Quadro XXII)"
},
{
    "idNtepFap3": "436",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica)  (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Amonia (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "437",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica)  (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Anidrido sulfuroso (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "438",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes antineoplasicos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "438",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes antineoplasicos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "438",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes antineoplasicos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "438",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes antineoplasicos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "438",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)(C95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agentes antineoplasicos (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "439",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Polineuropatia devida a outros agentes toxicos (G62.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono  (X49.-);(Z57.5)(Quadro XIX)"
},
{
    "idNtepFap3": "440",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Campos eletromagneticos (W90.-); (Z57.5)"
},
{
    "idNtepFap3": "440",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Campos eletromagneticos (W90.-); (Z57.5)"
},
{
    "idNtepFap3": "440",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Campos eletromagneticos (W90.-);(Z57.5)"
},
{
    "idNtepFap3": "440",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Campos eletromagneticos (W90.-);(Z57.5)"
},
{
    "idNtepFap3": "440",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)(C95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Campos eletromagneticos (W90.-);(Z57.5)"
},
{
    "idNtepFap3": "441",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Polineuropatia devida a outros agentes toxicos (G62.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "n-Hexano  (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "442",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos clorados (Clordane e Heptaclor) (X48.-);(Z57.4)"
},
{
    "idNtepFap3": "442",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos clorados (Clordane e Heptaclor) (X48.-);(Z57.4)"
},
{
    "idNtepFap3": "442",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos clorados (Clordane e Heptaclor) (X48.-);(Z57.4)"
},
{
    "idNtepFap3": "442",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos clorados (Clordane e Heptaclor) (X48.-);(Z57.4)"
},
{
    "idNtepFap3": "442",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XI",
    "patologias": "Leucemias (C91.-)(C92.-)(C93.-)(C94.-)(C95.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Agrotoxicos clorados (Clordane e Heptaclor) (X48.-);(Z57.4)"
},
{
    "idNtepFap3": "443",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XIII",
    "patologias": "Encefalopatia Toxica Aguda (G92.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus derivados toxicos  (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "444",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XIV",
    "patologias": "Encefalopatia Toxica Cronica (G92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos (X49.-); (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "445",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XIV",
    "patologias": "Encefalopatia Toxica Cronica (G92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Substancias asfixiantes: CO, H2S, etc. (sequela) (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "446",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XVI",
    "patologias": "Dermatite de Contato por Irritantes Berilio, Bromo, Cromo, Cimento, Fluor, Fosforo, Inseticidas (L24.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cromo e seus compostos toxicos (Z57.5) (Quadro X)"
},
{
    "idNtepFap3": "447",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XVI",
    "patologias": "Dermatite de Contato por Irritantes Berilio, Bromo, Cromo, Cimento, Fluor, Fosforo, Inseticidas (L24.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fluor ou seus compostos toxicos (Z57.5) (Quadro XI)"
},
{
    "idNtepFap3": "448",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XVI",
    "patologias": "Dermatite de Contato por Irritantes Berilio, Bromo, Cromo, Cimento, Fluor, Fosforo, Inseticidas (L24.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fosforo (Z57.5) (Quadro XII)"
},
{
    "idNtepFap3": "449",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XVIII",
    "patologias": "Pneumoconiose devida a outras poeiras inorganicas especificadas (J63.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de alumina (Al2O3) (Doenca de Shaver) (Z57.2)"
},
{
    "idNtepFap3": "450",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Gas Cloro (X47.-);(Z57.5) (Quadro IX)"
},
{
    "idNtepFap3": "451",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fluor ou seus compostos toxicos (X47.-);(Z57.5) (Quadro XI)"
},
{
    "idNtepFap3": "452",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Gas Cloro (X47.-);(Z57.5) (Quadro IX)"
},
{
    "idNtepFap3": "453",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fluor e seus compostos (X47.-);(Z57.5) (Quadro XI)"
},
{
    "idNtepFap3": "454",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solventes halogenados irritantes respiratorios (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "455",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "456",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cianeto de hidrogenio (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "457",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Antraceno e Dibenzoantraceno (Z57.5) (Quadro XX)"
},
{
    "idNtepFap3": "458",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Bismuto (X44.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "459",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Citostaticos  (X44.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "460",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cadmio ou seus compostos (X49.-);(Z57.5) (Quadro VI)"
},
{
    "idNtepFap3": "461",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Gas Cloro (X47.-);(Z57.5) (Quadro IX)"
},
{
    "idNtepFap3": "462",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fluor e seus compostos (X47.-);(Z57.5) (Quadro XI)"
},
{
    "idNtepFap3": "463",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "para-Aminofenol (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "464",
    "tipoDoenca": "Doencas da pele e do tecido subcutaneo relacionadas com o trabalho (grupo xii da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "para-Butilfenol (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "465",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Industria do petroleo (X46.-);(Z57.5)"
},
{
    "idNtepFap3": "465",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IV",
    "patologias": "Neoplasia maligna da cavidade nasal e dos seios paranasais (C30.-)(C31.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Industria do petroleo (X46.-);(Z57.5)"
},
{
    "idNtepFap3": "466",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Inseticidas (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "467",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Mercurio e seus compostos toxicos  (X49.-); (Z57.4) e (Z57.5) (Quadro XVI)"
},
{
    "idNtepFap3": "468",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XI",
    "patologias": "Polineuropatia devida a outros agentes toxicos (G62.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Metil-n-Butil Cetona (MBK) (X46.-);(Z57.5)"
},
{
    "idNtepFap3": "469",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica) (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nevoas e aerossois de acidos minerais (X47.-);(Z57.5)"
},
{
    "idNtepFap3": "470",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "para-Cresol (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "471",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solventes halogenados irritantes respiratorios (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "472",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solventes halogenados irritantes respiratorios (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "473",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "474",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "475",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "476",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XIV",
    "patologias": "Encefalopatia Toxica Cronica (G92.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "477",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5)(Quadro XIX)"
},
{
    "idNtepFap3": "478",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Tetracloreto de carbono  (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "479",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXIV",
    "patologias": "Sindrome de Disfuncao Reativa das Vias Aereas (SDVA/RADS) (J68.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Amonia (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "480",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Anidrido ftalico (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "481",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Catecol e Pirocatecol (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "482",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica  (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cimento  (Z57.2)"
},
{
    "idNtepFap3": "483",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Clorometil eteres (X49.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "484",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Compostos nitrogenados: acido nitrico, Dinitrofenol (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "485",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a Cobalto (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "486",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "X",
    "patologias": "Outras Doencas Pulmonares Obstrutivas Cronicas (Inclui: Asma Obstrutiva, Bronquite Cronica, Bronquite Asmatica, Bronquite Obstrutiva Cronica)  (J44.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de carvao mineral (Z57.2)"
},
{
    "idNtepFap3": "487",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "488",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "489",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes halogenados toxicos (X46.-);(Z57.4) e (Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "490",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IV",
    "patologias": "Transtornos de personalidade e de comportamento decorrentes de doenca, lesao e de disfuncao de personalidade (F07.-): Transtorno Organico de Personalidade (F07.0); Outros transtornos de personalidade e de comportamento decorrentes de doenca, lesao ou disfuncao cerebral (F07.8)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes organicos neurotoxicos  (X46.-); (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "491",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "V",
    "patologias": "Transtorno Mental Organico ou Sintomatico nao especificado (F09.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes organicos neurotoxicos  (X46.-); (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "492",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "VII",
    "patologias": "Episodios Depressivos (F32.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes organicos neurotoxicos  (X46.-); (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "493",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "IX",
    "patologias": "Neurastenia (Inclui Sindrome de Fadiga) (F48.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes organicos neurotoxicos  (X46.-); (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "494",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "VII",
    "patologias": "Dermatite Alergica de Contato devida a outros produtos quimicos (L23.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Plasticos (Z57.8) (Quadro XXVII)"
},
{
    "idNtepFap3": "495",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Solventes halogenados irritantes respiratorios (X46.-);(Z57.5) (Quadro XIII)"
},
{
    "idNtepFap3": "496",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Sulfeto de Carbono (X49.-);(Z57.5) (Quadro XIX)"
},
{
    "idNtepFap3": "497",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "acido sulfidrico (Sulfeto de hidrogenio) (X49.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "498",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Azodicarbonamida (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "499",
    "tipoDoenca": "Doencas do sistema osteomuscular e do tecido conjuntivo, relacionadas com o trabalho (grupo xiii da cid-10)",
    "grupo": "XXIII",
    "patologias": "Edema Pulmonar Agudo devido a produtos quimicos, gases, fumacas e vapores (Edema Pulmonar Quimico) (J68.1)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cianeto de hidrogenio (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "500",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "XXX",
    "patologias": "Leucodermia, nao classificada em outra parte (Inclui Vitiligo Ocupacional) (L81.5)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Clorofenol  (X46.-); (Z57.4) e (Z57.5)(Quadro XXVII)"
},
{
    "idNtepFap3": "501",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Fenol e homologos (X46.-);(Z57.5)"
},
{
    "idNtepFap3": "502",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Iodo (X49.-);(Z57.5) (Quadro XIV)"
},
{
    "idNtepFap3": "503",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "504",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Naftois adicionados a corantes (X49.-);(Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "505",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nitroglicerina e outros esteres do acido nitrico (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "506",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "III",
    "patologias": "Outros transtornos mentais decorrentes de lesao e disfuncao cerebrais e de doenca fisica (F06.-): Transtorno Cognitivo Leve (F06.7)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Outros solventes organicos neurotoxicos  (X46.-); (X49.-); (Z57.5)"
},
{
    "idNtepFap3": "507",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Silica-livre (Z57.2) (Quadro XVIII)"
},
{
    "idNtepFap3": "508",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "VI",
    "patologias": "Neoplasia maligna dos bronquios e do pulmao (C34.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Alcatrao  breu  betume produtos de residuos dessas substancias (X49.-);(Z57.5) (Quadro XX)"
},
{
    "idNtepFap3": "509",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "III",
    "patologias": "Outras Rinites Alergicas (J30.3)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Carbetos de metais duros: cobalto e titanio (Z57.2)"
},
{
    "idNtepFap3": "510",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "XXII",
    "patologias": "Bronquite e Pneumonite devida a produtos quimicos, gases, fumacas e vapores (Bronquite Quimica Aguda) (J68.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Cianeto de hidrogenio (X47.-);(Z57.5) (Quadro XVII)"
},
{
    "idNtepFap3": "511",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "XXV",
    "patologias": "Afeccoes respiratorias cronicas devidas a inalacao de gases, fumos, vapores e substancias quimicas: Bronquiolite Obliterante Cronica, Enfisema Cronico Difuso, Fibrose Pulmonar Cronica (J68.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Manganes e seus compostos toxicos (X49.-);(Z57.5) (Quadro XV)"
},
{
    "idNtepFap3": "512",
    "tipoDoenca": "Doencas do sistema genito-urinario relacionadas com o trabalho (grupo xiv da cid-10)",
    "grupo": "IV",
    "patologias": "Rinite Cronica (J31.0)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Nevoas de acidos minerais (X47.-);(Z57.5)"
},
{
    "idNtepFap3": "513",
    "tipoDoenca": "Traumatismos, envenenamentos e algumas outras consequencias de causas externas, relacionados com o trabalho ( Grupo XIX da CID-10 )",
    "grupo": "XXIX",
    "patologias": "Outras formas de hiperpigmentacao pela melanina: Melanodermia (L81.4)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "oleos de corte (Z57.5) (Quadro XXVII)"
},
{
    "idNtepFap3": "514",
    "tipoDoenca": "Traumatismos, envenenamentos e algumas outras consequencias de causas externas, relacionados com o trabalho ( Grupo XIX da CID-10 )",
    "grupo": "VII",
    "patologias": "Arritmias cardiacas (I49.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Problemas relacionados com o emprego e com o desemprego (Z56.-)"
},
{
    "idNtepFap3": "515",
    "tipoDoenca": "Traumatismos, envenenamentos e algumas outras consequencias de causas externas, relacionados com o trabalho ( Grupo XIX da CID-10 )",
    "grupo": "II",
    "patologias": "Conjuntivite (H10.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Radiacoes ionizantes (W88.-);(Z57.1) (Quadro XXIV)"
},
{
    "idNtepFap3": "516",
    "tipoDoenca": "Traumatismos, envenenamentos e algumas outras consequencias de causas externas, relacionados com o trabalho ( Grupo XIX da CID-10 )",
    "grupo": "X",
    "patologias": "Metahemoglobinemia (D74.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Aminas aromaticas e seus derivados (X49.-);(Z57.5)"
},
{
    "idNtepFap3": "517",
    "tipoDoenca": "Traumatismos, envenenamentos e algumas outras consequencias de causas externas, relacionados com o trabalho ( Grupo XIX da CID-10 )",
    "grupo": "VI",
    "patologias": "Anemia Sideroblastica secundaria a toxinas  (Inclui Anemia Hipocromica, Microcitica, com Reticulocitose) (D64.2)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Chumbo ou seus compostos toxicos (X46.-);(Z57.5) (Quadro VIII)"
},
{
    "idNtepFap3": "518",
    "tipoDoenca": "Traumatismos, envenenamentos e algumas outras consequencias de causas externas, relacionados com o trabalho ( Grupo XIX da CID-10 )",
    "grupo": "XXVII",
    "patologias": "Derrame pleural (J90.-)",
    "agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional": "Exposicao ocupacional a poeiras de Asbesto ou Amianto (Z57.2) (Quadro II)"
}
]