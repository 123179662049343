export const NtepFap4Modificado = [{
  "idNtepFap4": "0",
  "intervaloCid10": "A15 A16 A17 A18 A19",
  "cnae": "0810 1091 1411 1412 1533 1540 2330 3011 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4299 4312 4321 4391 4399 4687 4711 4713 4721 4741 4742 4743 4744 4789 4921 4923 4924 4929 5611 7810 7820 7830 8121 8122 8129 8610 9420 9601"
},
{
  "idNtepFap4": "1",
  "intervaloCid10": "E10 E11 E12 E13 E14",
  "cnae": "1091 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4292 4299 4313 4319 4329 4399 4721 4921 4922 4923 4924 4929 4930 5030 5231 5239 8011 8012 8020 8030 8121 8122 8129 8411 9420"
},
{
  "idNtepFap4": "2",
  "intervaloCid10": "F10 F11 F12 F13 F14 F15 F16 F17 F18 F19",
  "cnae": "0710 0990 1011 1012 1013 1220 1532 1622 1732 1733 2211 2330 2342 2451 2511 2512 2531 2539 2542 2543 2593 2814 2822 2840 2861 2866 2869 2920 2930 3101 3102 3329 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4292 4299 4313 4319 4321 4329 4399 4520 4912 4921 5030 5212 5221 5222 5223 5229 5231 5232 5239 5250 5310 6423 7810 7820 7830 8121 8122 8129 8411 8423 8424 9420"
},
{
  "idNtepFap4": "3",
  "intervaloCid10": "F20 F21 F22 F23 F24 F25 F26 F27 F28 F29",
  "cnae": "0710 0990 1011 1012 1013 1031 1071 1321 1411 1412 2330 2342 2511 2543 2592 2861 2866 2869 2942 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4292 4299 4312 4391 4399 4921 4922 4923 4924 4929 5212 5310 6423 7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 8423 9420"
},
{
  "idNtepFap4": "4",
  "intervaloCid10": "F30 F31 F32 F33 F34 F35 F36 F37 F38 F39",
  "cnae": "0710 0892 0990 1011 1012 1013 1031 1220 1311 1313 1314 1321 1330 1340 1351 1359 1411 1412 1413 1422 1531 1532 1540 2091 2123 2511 2710 2751 2861 2930 2945 3299 3600 4636 4711 4753 4756 4759 4762 4911 4912 4921 4922 4923 4924 4929 5111 5120 5221 5222 5223 5229 5310 5620 6110 6120 6130 6141 6142 6143 6190 6311 6422 6423 6431 6550 8121 8122 8129 8411 8413 8423 8424 8610 8711 8720 8730 8800"
},
{
  "idNtepFap4": "5",
  "intervaloCid10": "F40 F41 F42 F43 F44 F45 F46 F47 F48",
  "cnae": "0710 0990 1311 1321 1351 1411 1412 1421 1532 2945 3600 4711 4753 4756 4759 4762 4911 4912 4921 4922 4923 4924 4929 5111  5120 5221 5222 5223 5229 5310 6110 6120 6130 6141 6142 6143 6190 6311 6422 6423 8011 8012 8020 8030 8121 8122 8129 8411 8423 8424 8610"
},
{
  "idNtepFap4": "6",
  "intervaloCid10": "G40 G41 G42 G43 G44 G45 G46 G47",
  "cnae": "0113 0210 0220 0810 1011 1012 1013 1321 1411 1412 1610 1621 1732 1733 1931 2330 2342 2511 2539 2861 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4292 4299 4313 4319 4399 4921 4922 4923 4924 4929 4930 5212 8011 8012 8020 8030 8121 8122 8129"
},
{
  "idNtepFap4": "7",
  "intervaloCid10": "G50 G51 G52 G53 G54 G55 G56 G57 G58 G59",
  "cnae": "0155 1011 1012 1013 1062 1093 1095 1313 1351 1411 1412 1421 1529 1531 1532 1533 1539 1540 2063 2123 2211 2222 2223 2229 2349 2542 2593 2640 2710 2759 2944 2945 3240 3250 4711 5611 5612 5620 6110 6120 6130 6141 6142 6143 6190 6422 6423 8121 8122 8129 8610"
},
{
  "idNtepFap4": "8",
  "intervaloCid10": "H53 H54",
  "cnae": "0210 0220 0810 1071 1220 1610 1622 2330 2342 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4212 4213 4222 4223 4291 4299 4312 4313 4319 4321 4329 4391 4399 4741 4742 4743 4744 4789 4921 4922 4923 4924 4929 4930 8011 8012 8020 8030 8121 8122 8129"
},
{
  "idNtepFap4": "9",
  "intervaloCid10": "I05 I06 I07 I08 I09",
  "cnae": "4921"
},
{
  "idNtepFap4": "10",
  "intervaloCid10": "I10 I11 I12 I13 I14 I15",
  "cnae": "0111 1411 1412 4921 4922 4923 4924 4929 5111 5120"
},
{
  "idNtepFap4": "11",
  "intervaloCid10": "I20 I21 I22 I23 I24 I25",
  "cnae": "1621 4120 4211 4213 4221 4222 4223 4291 4299 4329 4399 4921 4922 4930 6110 6120 6130 6141 6142 6143 6190"
},
{
  "idNtepFap4": "12",
  "intervaloCid10": "I30 I31 I32 I33 I34 I35 I36 I37 I38 I39 I40 I41 I42 I43 I44 I45 I46 I47 I48 I49 I50 I51 I52",
  "cnae": "0113 0210 0220 0810 1011 1012 1013 1061 1071 1411 1412 1610 1931 2029 2330 2342 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4292 4299 4312 4313 4319 4391 4399 4621 4622 4623 4921 4922 4923 4924 4929 4930 8121 8122 8129 8411 9420"
},
{
  "idNtepFap4": "13",
  "intervaloCid10": "I60 I61 I62 I63 I64 I65 I66 I67 I68 I69",
  "cnae": "0810 1071 2330 2342 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4299 4312 4313 4319 4321 4391 4399 4921 4922 4923 4924 4929 4930 8112 8121 8122 8129 8411 8591 9200 9311 9312 9313 9319 9420"
},
{
  "idNtepFap4": "14",
  "intervaloCid10": "I80 I81 I82 I83 I84 I85 I86 I87 I88 I89",
  "cnae": "1011 1012 1013 1020 1031 1033 1091 1092 1220 1311 1321 1351 1411 1412 1413 1422 1510 1531 1532 1540 1621 1622 2123 2342 2542 2710 2813 2832 2833 2920 2930 2944 2945 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4621 4622 4623 4721 4722 4921 4922 5611 5612 5620 8011 8012 8020 8030 8121 8122 8129 8411 8610 9420 9491 9601"
},
{
  "idNtepFap4": "15",
  "intervaloCid10": "J40 J41 J42 J43 J44 J45 J46 J47",
  "cnae": "0810 1031 1220 1311 1321 1351 1411 1412 1610 1622 1629 2330 2342 2539 3101 3102 3329 4120 4211 4213 4292 4299 4313 4319 4399 4921 8121 8122 8129 8411"
},
{
  "idNtepFap4": "16",
  "intervaloCid10": "K35 K36 K37 K38",
  "cnae": "0810 1011 1012 1013 1071 1411 1412 1531 1540 1610 1621 1732 1733 2451 2511 2512 2832 2833 2930 3101 3329 4621 4622 4623 4921 4922 8610"
},
{
  "idNtepFap4": "17",
  "intervaloCid10": "K40 K41 K42 K43 K44 K45 K46",
  "cnae": "0113 0210 0220 0230 0810 1011 1012 1013 1020 1031 1033 1041 1051 1061 1066 1071 1091 1122 1321 1354 1510 1610 1621 1622 1629 1722 1732 1733 1931 2211 2212 2219 2330 2341 2342 2349 2443 2449 2451 2511 2512 2521 2539 2541 2542 2543 2592 2593 2710 2815 2822 2832 2833 2861 2866 2869 2930 2943 2944 2945 3011 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4212 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399 4621 4622 4623 4632 4634 4687 4721 4722 4741 4742 4743 4744 4789 4921 4922 4930 5212 8121 8122 8129 9420"
},
{
  "idNtepFap4": "18",
  "intervaloCid10": "L60 L61 L62 L63 L64 L65 L66 L67 L68 L69 L70 L71 L72 L73 L74 L75",
  "cnae": "8610"
},
{
  "idNtepFap4": "19",
  "intervaloCid10": "L80 L81 L82 L83 L84 L85 L86 L87 L88 L89 L90 L91 L92 L93 L94 L95 L96 L97 L98 L99",
  "cnae": "0113 1011 1012 1013 1071 1411 1412 1610 1621 1931 2451 5611 5620 8121 8122 8129 8610"
},
{
  "idNtepFap4": "20",
  "intervaloCid10": "M00 M01 M02 M03 M04 M05 M06 M07 M08 M09 M10 M11 M12 M13 M14 M15 M16 M17 M18 M19 M20 M21 M22 M23 M24 M25",
  "cnae": "0113 0131 0133 0210 0220 0810 0892 0910 1011 1012 1013 1020 1031 1033 1041 1051 1052 1061 1064 1071 1072 1091 1122 1220 1311 1321 1351 1354 1411 1412 1413 1532 1621 1732 1733 1931 2012 2019 2312 2330 2341 2342 2349 2431 2443 2449 2511 2522 2539 2543 2550 2710 2813 2815 2822 2852 2853 2854 2861 2862 2865 2866 2869 2920 2930 2944 2945 2950 3011 3102 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4212 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399 4621 4622 4623 4636 4661 4711 4721 4921 4922 4923 4924 4929 4930 5012 5021 5212 5310 5611 5620 7719 8121 8122 8129 8411 8424 8430 8591 8610 9200 9311 9312 9313 9319 9420 9491 9601"
},
{
  "idNtepFap4": "21",
  "intervaloCid10": "M30 M31 M32 M33 M34 M35 M36",
  "cnae": "1412 8121 8122 8129 8610 0113 0131 0133 0210 0220 0230 0500 0710 0810 0892 0910 0990 1011 1012 1013 1020 1031 1033 1041 1051 1052 1061 1062 1064 1071 1072 1092 1122 1311 1312 1321 1323 1340 1351 1354 1411 1412 1413 1421 1422 1510 1532 1610 1621 1622 1623 1629 1710 1721 1722 1732 1733 1931 2012 2019 2029 2040 2091 2093 2123 2211 2212 2219 2221 2222 2312 2320 2330 2341 2342 2349 2391 2431 2439 2441 2443 2449 2451 2511 2513"
},
{
  "idNtepFap4": "22",
  "intervaloCid10": "M40 M41 M42 M43 M44 M45 M46 M47 M48 M49 M50 M51 M52 M53 M54",
  "cnae": "2521 2522 2539 2542 2543 2550 2592 2593 2710 2722 2733 2813 2815 2822 2832 2833 2852 2853 2854 2861 2862 2864 2866 2869 2920 2930 2942 2943 2944 2945 2950 3011 3101 3102 3240 3321 3329 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4212 4213 4222 4223 4291 4292 4299 4311 4312 4313 4319 4321 4329 4391 4399 4621 4622 4623 4632 4636 4661 4681 4682 4685 4686 4687 4689 4921 4922 4923 4924 4929 4930 5012 5021 5211 5212 5221 5222 5223 5229 5310 5612 5620 6431 7719 7732 8121 8122 8129 8424 8430 8610 9420"
},
{
  "idNtepFap4": "23",
  "intervaloCid10": "M60 M61 M62 M63 M64 M65 M66 M67 M68 M69 M70 M71 M72 M73 M74 M75 M76 M77 M78 M79",
  "cnae": "0113 0155 0210 0220 1011 1012 1013 1020 1031 1033 1051 1052 1062 1064 1092 1093 1094 1095 1096 1099 1122 1311 1314 1321 1323 1340 1351 1352 1354 1359 1411 1412 1413 1414 1421 1510 1521 1529 1531 1532 1533 1540 1623 1732 1733 1742 1749 2040 2063 2091 2110 2121 2123 2211 2219 2221 2222 2223 2229 2312 2319 2342 2349 2439 2443 2449 2451 2531 2539 2541 2542 2543 2550 2591 2592 2593 2610 2631 2632 2640 2651 2710 2721 2722 2732 2733 2740 2751 2759 2813 2814 2815 2822 2823 2824 2840 2853 2854 2861 2864 2866 2869 2920 2930 2941 2942 2943 2944 2945 2949 3092 3101 3102 3104 3230 3240 3250 3291 3299 3316 3329 3701 3702 3811 3812 3821 3822 3839 3900 4221 4632 4634 4711 4713 4912 5111 5120 5212 5221 5222 5223 5229 5310 5320 5612 5620 6021 6022 6110 6120 6130 6141 6142 6143 6190 6209 6311 6399 6422 6423 6431 6550 7410 7490 7719 7733 8121 8122 8129 8211 8219 8220 8230 8291 8292 8299 8610 9420 9601"
},
{
  "idNtepFap4": "24",
  "intervaloCid10": "S00 S01 S02 S03 S04 S05 S06 S07 S08 S09",
  "cnae": "0210 0220 0230 0810 1011 1012 1013 1033 1041 1061 1071 1122 1321 1510 1532 1610 1621 1622 1732 1733 1931 2212 2330 2342 2391 2511 2512 2539 2542 2543 2593 2832 2833 2866 2869 2930 3011 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399 4520 4530 4541 4542 4621 4622 4623 4635 4671 4672 4673 4674 4679 4687 4731 4732 4741 4742 4743 4744 4789 4921 4922 4930 5212 5320 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 9420"
},
{
  "idNtepFap4": "25",
  "intervaloCid10": "S20 S21 S22 S23 S24 S25 S26 S27 S28 S29",
  "cnae": "0113 0131 0133 0210 0220 0230 0810 1011 1012 1013 1071 1321 1510 1610 1621 1622 1629 1732 1733 1931 2330 2342 2512 2539 2543 2832 2833 2866 2869 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4321 4399 4621 4622 4623 4632 4687 4741 4742 4743 4744 4789 4921 4922 4930 5212 5310 8121 8122 8129 9420"
},
{
  "idNtepFap4": "26",
  "intervaloCid10": "S30 S31 S32 S33 S34 S35 S36 S37 S38 S39",
  "cnae": "0131 0133 0210 0220 1011 1012 1013 1061 1071 1610 1621 2330 2342 2511 2512 3101 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4299 4312 4313 4319 4321 4329 4391 4399 4621 4622 4623 4687 4722 4741 4742 4743 4744 4789 4921 4930 5212 5221 5222 5223 5229 7810 7820 7830 8121 8122 8129 9420"
},
{
  "idNtepFap4": "27",
  "intervaloCid10": "S40 S41 S42 S43 S44 S45 S46 S47 S48 S49",
  "cnae": "0131 0133 0210 0220 0500 0810 1011 1012 1013 1031 1033 1041 1051 1061 1064 1071 1091 1122 1321 1351 1354 1411 1412 1510 1531 1532 1533 1540 1610 1621 1622 1623 1629 1722 1732 1733 1931 2212 2221 2222 2223 2229 2330 2342 2349 2391 2451 2511 2512 2539 2542 2543 2592 2593 2710 2813 2815 2822 2823 2832 2833 2861 2866 2869 2930 2944 2945 2950 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399 4520 4530 4541 4542 4618 4621 4622 4623 4635 4661 4671 4672 4673 4674 4679 4687 4721 4722 4731 4732 4741 4742 4743 4744 4784 4789 4921 4922 4930 5212 5221 5222 5223 5229 5310 5320 7719 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 9420"
},
{
  "idNtepFap4": "28",
  "intervaloCid10": "S50 S51 S52 S53 S54 S55 S56 S57 S58 S59",
  "cnae": "0210 0220 0810 1011 1012 1013 1031 1033 1041 1051 1061 1064 1071 1091 1092 1093 1096 1099 1122 1311 1321 1354 1411 1412 1510 1531 1532 1533 1540 1610 1621 1622 1623 1629 1722 1732 1733 2211 2221 2222 2223 2229 2330 2341 2342 2391 2511 2512 2539 2542 2543 2592 2593 2710 2759 2813 2822 2823 2832 2833 2861 2866 2869 2930 2944 2945 2950 3011 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4322 4329 4391 4399 4520 4621 4622 4623 4635 4661 4685 4686 4687 4689 4711 4721 4722 4741 4742 4743 4744 4784 4789 4921 4923 4924 4929 4930 5212 5221 5222 5223 5229 5310 5320 7719 7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 9420"
},
{
  "idNtepFap4": "29",
  "intervaloCid10": "S60 S61 S62 S63 S64 S66 S66 S67 S68 S69",
  "cnae": "0113 0210 0220 0500 0810 1011 1012 1013 1031 1033 1041 1042 1051 1052 1061 1062 1063 1064 1071 1072 1091 1092 1093 1094 1096 1099 1122 1311 1312 1321 1323 1340 1351 1353 1354 1359 1411 1412 1510 1529 1531 1532 1533 1540 1610 1621 1622 1623 1629 1710 1721 1722 1731 1732 1733 1741 1742 1749 1813 1931 2012 2019 2029 2061 2063 2091 2092 2123 2211 2212 2219 2221 2222 2223 2229 2311 2312 2319 2330 2341 2342 2349 2391 2392 2399 2431 2439 2441 2443 2449 2451 2452 2511 2512 2513 2521 2522 2531 2532 2539 2541 2542 2543 2550 2591 2592 2593 2599 2632 2651 2710 2721 2722 2732 2733 2740 2751 2759 2790 2811 2812 2813 2814 2815 2821 2822 2823 2824 2825 2829 2831 2832 2833 2840 2852 2853 2854 2861 2862 2864 2865 2866 2869 2920 2930 2941 2942 2943 2944 2945 2949 2950 3011 3012 3032 3091 3092 3099 3101 3102 3103 3104 3220 3230 3240 3250 3291 3299 3319 3329 3701 3702 3811 3812 3821 3822 3832 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4322 4329 4391 4399 4520 4621 4622 4623 4632 4634 4661 4671 4672 4673 4674 4679 4681 4682 4685 4686 4687 4689 4711 4721 4722 4741 4742 4743 4744 4789 4930 5211 5212 5320 5819 5829 7719 7732 7810 7820 7830 8121 8122 8129 8423 9420 9529"
},
{
  "idNtepFap4": "30",
  "intervaloCid10": "S70 S71 S72 S73 S74 S77 S76 S77 S78 S79",
  "cnae": "0210 0220 1011 1012 1013 1033 1122 1610 1621 1622 2330 2391 2511 2512 2539 3101 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4299 4312 4321 4391 4399 4520 4530 4541 4542 4618 4687 4731 4732 4741 4742 4743 4744 4784 4789 4921 4930 5212 5221 5222 5223 5229 5232 5250 5320 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 9420"
},
{
  "idNtepFap4": "31",
  "intervaloCid10": "S80 S81 S82 S83 S84 S88 S86 S87 S88 S89",
  "cnae": "0210 0220 0230 0500 0710 0810 0990 1011 1012 1013 1031 1033 1041 1051 1061 1062 1064 1071 1072 1092 1096 1099 1122 1321 1351 1354 1411 1412 1510 1531 1532 1540 1610 1621 1622 1623 1629 1710 1721 1722 1732 1733 1931 2012 2019 2029 2073 2091 2211 2219 2222 2312 2320 2330 2341 2342 2391 2439 2443 2449 2451 2511 2512 2521 2522 2539 2542 2543 2550 2592 2593 2651 2710 2812 2813 2815 2821 2822 2823 2831 2832 2833 2840 2852 2854 2861 2862 2864 2865 2866 2869 2930 2943 2944 2945 2950 3011 3101 3102 3329 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4322 4329 4391 4399 4520 4530 4541 4542 4618 4621 4622 4623 4632 4635 4636 4637 4639 4661 4671 4672 4673 4674 4679 4681 4682 4685 4686 4687 4689 4711 4722 4723 4731 4732 4741 4742 4743 4744 4784 4789 4912 4921 4922 4923 4924 4929 4930 5211 5212 5221 5222 5223 5229 5232 5250 5310 5320 7719 7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 8423 8424 9420"
},
{
  "idNtepFap4": "32",
  "intervaloCid10": "S90 S91 S92 S93 S94 S99 S96 S97 S98 S99",
  "cnae": "0210 0220 0500 0810 1011 1012 1013 1031 1033 1041 1051 1061 1062 1064 1071 1072 1092 1093 1122 1311 1321 1351 1354 1411 1412 1510 1532 1610 1621 1622 1623 1629 1710 1721 1722 1732 1733 1931 2029 2091 2219 2221 2222 2312 2330 2341 2342 2391 2431 2439 2441 2443 2449 2451 2511 2512 2513 2521 2522 2531 2539 2542 2543 2592 2593 2710 2722 2815 2822 2831 2832 2833 2840 2852 2853 2854 2861 2862 2865 2866 2869 2920 2930 2943 2944 2945 2950 3011 3101 3102 3329 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4322 4329 4391 4399 4621 4622 4623 4661 4681 4682 4685 4686 4687 4689 4711 4784 4912 4921 4922 4930 5111 5120 5212 5221 5222 5223 5229 5232 5250 5310 5320 6423 6431 6550 7719 7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 8423 8424 8610 9420"
},
{
  "idNtepFap4": "33",
  "intervaloCid10": "T90 T91 T92 T93 T94 T95 T96 T97 T98",
  "cnae": "0210 0220 0710 0810 0892 0910 1011 1013 1020 1031 1033 1041 1042 1061 1062 1071 1072 1091 1092 1093 1122 1220 1311 1312 1321 1351 1352 1353 1411 1412 1510 1531 1532 1533 1540 1610 1621 1622 1629 1733 1932 2014 2019 2029 2032 2091 2211 2221 2223 2229 2312 2320 2330 2341 2342 2391 2451 2511 2512 2521 2522 2539 2542 2592 2593 2640 2740 2751 2790 2813 2814 2822 2862 2864 2866 2869 2920 2930 2944 2945 2950 3091 3092 3101 3102 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4291 4292 4299 4312 4313 4319 4321 4322 4391 4399 4635 4661 4681 4682 4687 4721 4741 4743 4744 4784 4922 4923 4924 4929 4930 5012 5021 5030 5212 5221 5222 5223 5229 5231 5232 5239 5250 5310 5320 7719 7732 8011 8012 8020 8030 8121 8122 9420"
}
]