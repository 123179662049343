export const CidAcentuado =[
  {
    "codigo": "A000",
    "descricao": "Cólera devida a Vibrio cholerae 01, biótipo cholerae"
  },
  {
    "codigo": "A001",
    "descricao": "Cólera devida a Vibrio cholerae 01, biótipo El Tor"
  },
  { "codigo": "A009", "descricao": "Cólera não especificada" },
  { "codigo": "A010", "descricao": "Febre tifóide" },
  { "codigo": "A011", "descricao": "Febre paratifóide A" },
  { "codigo": "A012", "descricao": "Febre paratifóide B" },
  { "codigo": "A013", "descricao": "Febre paratifóide C" },
  {
    "codigo": "A014",
    "descricao": "Febre paratifóide não especificada"
  },
  { "codigo": "A020", "descricao": "Enterite por salmonela" },
  { "codigo": "A021", "descricao": "Septicemia por salmonela" },
  {
    "codigo": "A022",
    "descricao": "Infecções localizadas por salmonela"
  },
  {
    "codigo": "A028",
    "descricao": "Outras infecções especificadas por salmonela"
  },
  {
    "codigo": "A029",
    "descricao": "Infecção não especificada por salmonela"
  },
  { "codigo": "A030", "descricao": "Shiguelose devida a Shigella dysenteriae" },
  { "codigo": "A031", "descricao": "Shiguelose devida a Shigella flexneri" },
  { "codigo": "A032", "descricao": "Shiguelose devida a Shigella boydii" },
  { "codigo": "A033", "descricao": "Shiguelose devida a Shigella sonnei" },
  { "codigo": "A038", "descricao": "Outras shigueloses" },
  { "codigo": "A039", "descricao": "Shiguelose não especificada" },
  {
    "codigo": "A040",
    "descricao": "Infecção por Escherichia coli enteropatogênica"
  },
  {
    "codigo": "A041",
    "descricao": "Infecção por Escherichia coli enterotoxigênica"
  },
  {
    "codigo": "A042",
    "descricao": "Infecção por Escherichia coli enteroinvasiva"
  },
  {
    "codigo": "A043",
    "descricao": "Infecção por Escherichia coli enterohemorrágica"
  },
  {
    "codigo": "A044",
    "descricao": "Outras infecções intestinais por Escherichia coli"
  },
  { "codigo": "A045", "descricao": "Enterite por Campylobacter" },
  {
    "codigo": "A046",
    "descricao": "Enterite devida a Yersinia enterocolítica"
  },
  {
    "codigo": "A047",
    "descricao": "Enterocolite devida a Clostridium difficile"
  },
  {
    "codigo": "A048",
    "descricao": "Outras infecções bacterianas intestinais especificadas"
  },
  {
    "codigo": "A049",
    "descricao": "Infecção intestinal bacteriana não especificada"
  },
  {
    "codigo": "A050",
    "descricao": "Intoxicação alimentar estafilocócica"
  },
  { "codigo": "A051", "descricao": "Botulismo" },
  {
    "codigo": "A052",
    "descricao": "Intoxicação alimentar devida a Clostridium perfringens [Clostridium welchii]"
  },
  {
    "codigo": "A053",
    "descricao": "Intoxicação alimentar devida a Vibrio parahemolyticus"
  },
  {
    "codigo": "A054",
    "descricao": "Intoxicação alimentar devida a Bacillus cereus"
  },
  {
    "codigo": "A058",
    "descricao": "Outras intoxicações alimentares bacterianas especificadas"
  },
  {
    "codigo": "A059",
    "descricao": "Intoxicação alimentar bacteriana não especificada"
  },
  { "codigo": "A060", "descricao": "Disenteria amebiana aguda" },
  { "codigo": "A061", "descricao": "Amebíase intestinal crônica" },
  {
    "codigo": "A062",
    "descricao": "Colite amebiana não-disentérica"
  },
  { "codigo": "A063", "descricao": "Ameboma intestinal" },
  { "codigo": "A064", "descricao": "Abscesso amebiano do fígado" },
  { "codigo": "A065", "descricao": "Abscesso amebiano do pulmão" },
  { "codigo": "A066", "descricao": "Abscesso amebiano do cérebro" },
  { "codigo": "A067", "descricao": "Amebíase cutânea" },
  {
    "codigo": "A068",
    "descricao": "Infecção amebiana de outras localizações"
  },
  { "codigo": "A069", "descricao": "Amebíase não especificada" },
  { "codigo": "A070", "descricao": "Balantidíase" },
  { "codigo": "A071", "descricao": "Giardíase [lamblíase]" },
  { "codigo": "A072", "descricao": "Criptosporidiose" },
  { "codigo": "A073", "descricao": "Isosporíase" },
  {
    "codigo": "A078",
    "descricao": "Outras doenças intestinais especificadas por protozoários"
  },
  {
    "codigo": "A079",
    "descricao": "Doença intestinal não especificada por protozoários"
  },
  { "codigo": "A080", "descricao": "Enterite por rotavírus" },
  {
    "codigo": "A081",
    "descricao": "Gastroenteropatia aguda pelo agente de Norwalk"
  },
  { "codigo": "A082", "descricao": "Enterite por adenovírus" },
  { "codigo": "A083", "descricao": "Outras enterites virais" },
  {
    "codigo": "A084",
    "descricao": "Infecção intestinal devida a vírus não especificado"
  },
  {
    "codigo": "A085",
    "descricao": "Outras infecções intestinais especificadas"
  },
  {
    "codigo": "A09",
    "descricao": "Diarréia e gastroenterite de origem infecciosa presumível"
  },
  {
    "codigo": "A150",
    "descricao": "Tuberculose pulmonar, com confirmação por exame microscópico da expectoração, com ou sem cultura"
  },
  {
    "codigo": "A151",
    "descricao": "Tuberculose pulmonar, com confirmação somente por cultura"
  },
  {
    "codigo": "A152",
    "descricao": "Tuberculose pulmonar, com confirmação histológica"
  },
  {
    "codigo": "A153",
    "descricao": "Tuberculose pulmonar, com confirmação por meio não especificado"
  },
  {
    "codigo": "A154",
    "descricao": "Tuberculose dos gânglios intratorácicos, com confirmação bacteriológica e histológica"
  },
  {
    "codigo": "A155",
    "descricao": "Tuberculose da laringe, da traquéia e dos brônquios, com confirmação bacteriológica e histológica"
  },
  {
    "codigo": "A156",
    "descricao": "Pleuris tuberculoso, com confirmação bacteriológica e histológica"
  },
  {
    "codigo": "A157",
    "descricao": "Tuberculose primária das vias respiratórias, com confirmação bacteriológica e histológica"
  },
  {
    "codigo": "A158",
    "descricao": "Outras formas de tuberculose das vias respiratórias, com confirmação bacteriológica e histológica"
  },
  {
    "codigo": "A159",
    "descricao": "Tuberculose não especificada das vias respiratórias, com confirmação bacteriológica e histológica"
  },
  {
    "codigo": "A160",
    "descricao": "Tuberculose pulmonar com exames bacteriológico e histológico negativos"
  },
  {
    "codigo": "A161",
    "descricao": "Tuberculose pulmonar, sem realização de exame bacteriológico ou histológico"
  },
  {
    "codigo": "A162",
    "descricao": "Tuberculose pulmonar, sem menção de confirmação bacteriológica ou histológica"
  },
  {
    "codigo": "A163",
    "descricao": "Tuberculose dos gânglios intratorácicos, sem menção de confirmação bacteriológica ou histológica"
  },
  {
    "codigo": "A164",
    "descricao": "Tuberculose da laringe, da traquéia e dos brônquios, sem menção de confirmação bacteriológica ou histológica"
  },
  {
    "codigo": "A165",
    "descricao": "Pleurisia tuberculosa, sem menção de confirmação bacteriológica ou histológica"
  },
  {
    "codigo": "A167",
    "descricao": "Tuberculosa respiratória primária sem menção de confirmação bacteriológica ou histológica"
  },
  {
    "codigo": "A168",
    "descricao": "Outras formas de tuberculose das vias respiratórias, sem menção de confirmação bacteriológica ou histológica"
  },
  {
    "codigo": "A169",
    "descricao": "Tuberculose respiratória, não especificada, sem menção de confirmação bacteriológica ou histológica"
  },
  { "codigo": "A170", "descricao": "Meningite tuberculosa" },
  { "codigo": "A171", "descricao": "Tuberculoma meníngeo" },
  { "codigo": "A178", "descricao": "Outras tuberculoses do sistema nervoso" },
  {
    "codigo": "A179",
    "descricao": "Tuberculose não especificada do sistema nervoso"
  },
  {
    "codigo": "A180",
    "descricao": "Tuberculose óssea e das articulações"
  },
  {
    "codigo": "A181",
    "descricao": "Tuberculose do aparelho geniturinário"
  },
  {
    "codigo": "A182",
    "descricao": "Linfadenopatia tuberculosa periférica"
  },
  {
    "codigo": "A183",
    "descricao": "Tuberculose do intestino, do peritônio e dos gânglios mesentéricos"
  },
  {
    "codigo": "A184",
    "descricao": "Tuberculose de pele e do tecido celular subcutâneo"
  },
  { "codigo": "A185", "descricao": "Tuberculose do olho" },
  { "codigo": "A186", "descricao": "Tuberculose do ouvido" },
  { "codigo": "A187", "descricao": "Tuberculose das supra-renais" },
  {
    "codigo": "A188",
    "descricao": "Tuberculose de outros órgãos especificados"
  },
  {
    "codigo": "A190",
    "descricao": "Tuberculose miliar aguda de localização única e especificada"
  },
  {
    "codigo": "A191",
    "descricao": "Tuberculose miliar aguda de múltiplas localizações"
  },
  {
    "codigo": "A192",
    "descricao": "Tuberculose miliar aguda não especificada"
  },
  { "codigo": "A198", "descricao": "Outras tuberculoses miliares" },
  { "codigo": "A199", "descricao": "Tuberculose miliar não especificada" },
  { "codigo": "A200", "descricao": "Peste bubônica" },
  { "codigo": "A201", "descricao": "Peste celulocutânea" },
  { "codigo": "A202", "descricao": "Peste pneumônica" },
  { "codigo": "A203", "descricao": "Peste meníngea" },
  { "codigo": "A207", "descricao": "Peste septicêmica" },
  { "codigo": "A208", "descricao": "Outras formas de peste" },
  { "codigo": "A209", "descricao": "Peste, forma não especificada" },
  { "codigo": "A210", "descricao": "Tularemia ulceroglandular" },
  { "codigo": "A211", "descricao": "Tularemia oculoglandular" },
  { "codigo": "A212", "descricao": "Tularemia pulmonar" },
  { "codigo": "A213", "descricao": "Tularemia gastrointestinal" },
  { "codigo": "A217", "descricao": "Tularemia generalizada" },
  { "codigo": "A218", "descricao": "Outras formas de tularemia" },
  { "codigo": "A219", "descricao": "Tularemia, forma não especificada" },
  { "codigo": "A220", "descricao": "Carbúnculo cutâneo" },
  { "codigo": "A221", "descricao": "Carbúnculo pulmonar" },
  { "codigo": "A222", "descricao": "Carbúnculo gastrointestinal" },
  { "codigo": "A227", "descricao": "Septicemia carbunculosa" },
  { "codigo": "A228", "descricao": "Outras formas de carbúnculo" },
  {
    "codigo": "A229",
    "descricao": "Carbúnculo, forma não especificada"
  },
  { "codigo": "A230", "descricao": "Brucelose por Brucella melitensis" },
  { "codigo": "A231", "descricao": "Brucelose por Brucella abortus" },
  { "codigo": "A232", "descricao": "Brucelose por Brucella suis" },
  { "codigo": "A233", "descricao": "Brucelose por Brucella canis" },
  { "codigo": "A238", "descricao": "Outras bruceloses" },
  { "codigo": "A239", "descricao": "Brucelose não especificada" },
  { "codigo": "A240", "descricao": "Mormo" },
  { "codigo": "A241", "descricao": "Melioidose aguda e fulminante" },
  { "codigo": "A242", "descricao": "Melioidose subaguda e crônica" },
  { "codigo": "A243", "descricao": "Outras melioidoses" },
  { "codigo": "A244", "descricao": "Melioidose não especificada" },
  { "codigo": "A250", "descricao": "Espirilose" },
  { "codigo": "A251", "descricao": "Estreptobacilose" },
  {
    "codigo": "A259",
    "descricao": "Febre transmitida por mordedura de rato, tipo não especificado"
  },
  { "codigo": "A260", "descricao": "Erisipelóide cutâneo" },
  { "codigo": "A267", "descricao": "Septicemia por Erysipelothrix" },
  { "codigo": "A268", "descricao": "Outras formas de erisipelóide" },
  { "codigo": "A269", "descricao": "Erisipelóide não especificado" },
  { "codigo": "A270", "descricao": "Leptopirose icterohemorrágica" },
  { "codigo": "A278", "descricao": "Outras formas de leptospirose" },
  { "codigo": "A279", "descricao": "Leptospirose não especificada" },
  { "codigo": "A280", "descricao": "Pasteurelose" },
  { "codigo": "A281", "descricao": "Doença por arranhadura do gato" },
  { "codigo": "A282", "descricao": "Yersiniose extra-intestinal" },
  {
    "codigo": "A288",
    "descricao": "Outras doenças bacterianas zoonóticas especificadas não classificadas em outra parte"
  },
  {
    "codigo": "A289",
    "descricao": "Doença bacteriana zoonótica não especificada"
  },
  { "codigo": "A300", "descricao": "Hanseníase [lepra] indeterminada" },
  {
    "codigo": "A301",
    "descricao": "Hanseníase [lepra] tuberculóide"
  },
  {
    "codigo": "A302",
    "descricao": "Hanseníase [lepra] tuberculóide borderline"
  },
  { "codigo": "A303", "descricao": "Hanseníase [lepra] dimorfa" },
  {
    "codigo": "A304",
    "descricao": "Hanseníase [lepra] lepromatosa borderline"
  },
  { "codigo": "A305", "descricao": "Hanseníase [lepra] lepromatosa" },
  { "codigo": "A308", "descricao": "Outras formas de hanseníase [lepra]" },
  {
    "codigo": "A309",
    "descricao": "Hanseníase [lepra] não especificada"
  },
  {
    "codigo": "A310",
    "descricao": "Infecção pulmonar micobacteriana"
  },
  {
    "codigo": "A311",
    "descricao": "Infecção cutânea micobacteriana"
  },
  {
    "codigo": "A318",
    "descricao": "Outras infecções micobacterianas"
  },
  {
    "codigo": "A319",
    "descricao": "Infecção micobacteriana não especificada"
  },
  { "codigo": "A320", "descricao": "Listeriose cutânea" },
  {
    "codigo": "A321",
    "descricao": "Meningite e meningoencefalite por listéria"
  },
  { "codigo": "A327", "descricao": "Septicemia listeriótica" },
  { "codigo": "A328", "descricao": "Outras formas de listeriose" },
  { "codigo": "A329", "descricao": "Listeriose não especificada" },
  {
    "codigo": "A33",
    "descricao": "Tétano do recém-nascido [neonatal]"
  },
  { "codigo": "A34", "descricao": "Tétano obstétrico" },
  { "codigo": "A35", "descricao": "Outros tipos de tétano" },
  { "codigo": "A360", "descricao": "Difteria faríngea" },
  { "codigo": "A361", "descricao": "Difteria nasofaríngea" },
  { "codigo": "A362", "descricao": "Difteria laríngea" },
  { "codigo": "A363", "descricao": "Difteria cutânea" },
  { "codigo": "A368", "descricao": "Outras formas de difteria" },
  { "codigo": "A369", "descricao": "Difteria não especificada" },
  { "codigo": "A370", "descricao": "Coqueluche por Bordetella pertussis" },
  { "codigo": "A371", "descricao": "Coqueluche por Bordetella parapertussis" },
  {
    "codigo": "A378",
    "descricao": "Coqueluche por outras espécies da Bordetella"
  },
  { "codigo": "A379", "descricao": "Coqueluche não especificada" },
  { "codigo": "A38", "descricao": "Escarlatina" },
  { "codigo": "A390", "descricao": "Meningite meningocócica" },
  { "codigo": "A391", "descricao": "Síndrome de Waterhouse-Friderichsen" },
  { "codigo": "A392", "descricao": "Meningococcemia aguda" },
  { "codigo": "A393", "descricao": "Meningococcemia crônica" },
  { "codigo": "A394", "descricao": "Meningococcemia não especificada" },
  { "codigo": "A395", "descricao": "Cardite por meningococos" },
  {
    "codigo": "A398",
    "descricao": "Outras infecções por meningococos"
  },
  {
    "codigo": "A399",
    "descricao": "Infecção meningocócica não especificada"
  },
  { "codigo": "A400", "descricao": "Septicemia por Streptococcus do grupo A" },
  { "codigo": "A401", "descricao": "Septicemia por Streptococcus do grupo B" },
  { "codigo": "A402", "descricao": "Septicemia por Streptococcus do grupo D" },
  { "codigo": "A403", "descricao": "Septicemia por Streptococcus pneumonia" },
  { "codigo": "A408", "descricao": "Outras septicemias estreptocócicas" },
  {
    "codigo": "A409",
    "descricao": "Septicemia estreptocócica não especificada"
  },
  { "codigo": "A410", "descricao": "Septicemia por Staphylococcus aureus" },
  {
    "codigo": "A411",
    "descricao": "Septicemia por outros estafilococos especificados"
  },
  {
    "codigo": "A412",
    "descricao": "Septicemia por estafilococos não especificados"
  },
  { "codigo": "A413", "descricao": "Septicemia por Haemophilus influenzae" },
  { "codigo": "A414", "descricao": "Septicemia por anaeróbios" },
  {
    "codigo": "A415",
    "descricao": "Septicemia por outros microorganismos gram-negativos"
  },
  { "codigo": "A418", "descricao": "Outras septicemias especificadas" },
  { "codigo": "A419", "descricao": "Septicemia não especificada" },
  { "codigo": "A420", "descricao": "Actinomicose pulmonar" },
  { "codigo": "A421", "descricao": "Actinomicose abdominal" },
  { "codigo": "A422", "descricao": "Actinomicose cervicofacial" },
  { "codigo": "A427", "descricao": "Septicemia actinomicótica" },
  { "codigo": "A428", "descricao": "Outras formas de actinomicose" },
  { "codigo": "A429", "descricao": "Actinomicose não especificada" },
  { "codigo": "A430", "descricao": "Nocardiose pulmonar" },
  { "codigo": "A431", "descricao": "Nocardiose cutânea" },
  { "codigo": "A438", "descricao": "Outras formas de nocardiose" },
  { "codigo": "A439", "descricao": "Nocardiose não especificada" },
  { "codigo": "A440", "descricao": "Bartonelose sistêmica" },
  {
    "codigo": "A441",
    "descricao": "Bartonelose cutânea e cutâneo-mucosa"
  },
  { "codigo": "A448", "descricao": "Outras formas de bartonelose" },
  { "codigo": "A449", "descricao": "Bartonelose não especificada" },
  { "codigo": "A46", "descricao": "Erisipela" },
  { "codigo": "A480", "descricao": "Gangrena gasosa" },
  { "codigo": "A481", "descricao": "Doença dos legionários" },
  {
    "codigo": "A482",
    "descricao": "Doença dos legionários não-pneumônica [febre de Pontiac]"
  },
  { "codigo": "A483", "descricao": "Síndrome do choque tóxico" },
  { "codigo": "A484", "descricao": "Febre purpúrica do Brasil" },
  {
    "codigo": "A488",
    "descricao": "Outras doenças bacterianas especificadas"
  },
  {
    "codigo": "A490",
    "descricao": "Infecção estafilocócica de localização não especificada"
  },
  {
    "codigo": "A491",
    "descricao": "Infecção estreptocócica de localização não especificada"
  },
  {
    "codigo": "A492",
    "descricao": "Infecção por Haemophilus influenzae de localização não especificada"
  },
  {
    "codigo": "A493",
    "descricao": "Infecção por Mycoplasma de localização não especificada"
  },
  {
    "codigo": "A498",
    "descricao": "Outras infecções bacterianas de localização não especificada"
  },
  {
    "codigo": "A499",
    "descricao": "Infecção bacteriana não especificada"
  },
  {
    "codigo": "A500",
    "descricao": "Sífilis congênita precoce sintomática"
  },
  {
    "codigo": "A501",
    "descricao": "Sífilis congênita precoce, forma latente"
  },
  {
    "codigo": "A502",
    "descricao": "Sífilis congênita precoce não especificada"
  },
  {
    "codigo": "A503",
    "descricao": "Oculopatia sifilítica congênita tardia"
  },
  {
    "codigo": "A504",
    "descricao": "Neurossífilis congênita tardia [neurossífilis juvenil]"
  },
  {
    "codigo": "A505",
    "descricao": "Outras formas tardias e sintomáticas da sífilis congênita"
  },
  {
    "codigo": "A506",
    "descricao": "Sífilis congênita tardia latente"
  },
  {
    "codigo": "A507",
    "descricao": "Sífilis congênita tardia não especificada"
  },
  {
    "codigo": "A509",
    "descricao": "Sífilis congênita não especificada"
  },
  { "codigo": "A510", "descricao": "Sífilis genital primária" },
  { "codigo": "A511", "descricao": "Sífilis anal primária" },
  {
    "codigo": "A512",
    "descricao": "Sífilis primária de outras localizações"
  },
  {
    "codigo": "A513",
    "descricao": "Sífilis secundária da pele e das mucosas"
  },
  {
    "codigo": "A514",
    "descricao": "Outras formas de sífilis secundária"
  },
  { "codigo": "A515", "descricao": "Sífilis precoce latente" },
  {
    "codigo": "A519",
    "descricao": "Sífilis precoce não especificada"
  },
  { "codigo": "A520", "descricao": "Sífilis cardiovascular" },
  { "codigo": "A521", "descricao": "Neurossífilis sintomática" },
  { "codigo": "A522", "descricao": "Neurossífilis assintomática" },
  { "codigo": "A523", "descricao": "Neurossífilis não especificada" },
  {
    "codigo": "A527",
    "descricao": "Outras formas de sífilis tardia sintomática"
  },
  { "codigo": "A528", "descricao": "Sífilis tardia latente" },
  {
    "codigo": "A529",
    "descricao": "Sífilis tardia não especificada"
  },
  {
    "codigo": "A530",
    "descricao": "Sífilis latente, não especificada se recente ou tardia"
  },
  { "codigo": "A539", "descricao": "Sífilis não especificada" },
  {
    "codigo": "A540",
    "descricao": "Infecção gonocócica do trato geniturinário inferior, sem abscesso periuretral ou das glândulas acessórias"
  },
  {
    "codigo": "A541",
    "descricao": "Infecção gonocócica do trato geniturinário inferior, com abscesso periuretral ou das glândulas acessórias"
  },
  {
    "codigo": "A542",
    "descricao": "Pelviperitonite gonocócica e outras infecções geniturinárias gonocócicas"
  },
  {
    "codigo": "A543",
    "descricao": "Infecção gonocócica do olho"
  },
  {
    "codigo": "A544",
    "descricao": "Infecção gonocócica do sistema músculo-esquelético"
  },
  { "codigo": "A545", "descricao": "Faringite gonocócica" },
  {
    "codigo": "A546",
    "descricao": "Infecção gonocócica do ânus ou do reto"
  },
  {
    "codigo": "A548",
    "descricao": "Outras infecções gonocócicas"
  },
  {
    "codigo": "A549",
    "descricao": "Infecção gonocócica não especificada"
  },
  {
    "codigo": "A55",
    "descricao": "Linfogranuloma (venéreo) por clamídia"
  },
  {
    "codigo": "A560",
    "descricao": "Infecções por clamídias do trato geniturinário inferior"
  },
  {
    "codigo": "A561",
    "descricao": "Infecção por clamídias, pelviperitonial e de outros órgãos geniturinários"
  },
  {
    "codigo": "A562",
    "descricao": "Infecção por clamídias do trato geniturinário, localização não especificada"
  },
  {
    "codigo": "A563",
    "descricao": "Infecção do ânus e do reto por clamídias"
  },
  {
    "codigo": "A564",
    "descricao": "Infecção da faringe por clamídias"
  },
  {
    "codigo": "A568",
    "descricao": "Infecção por clamídias transmitida por via sexual, de outras localizações"
  },
  { "codigo": "A57", "descricao": "Cancro mole" },
  { "codigo": "A58", "descricao": "Granuloma inguinal" },
  { "codigo": "A590", "descricao": "Tricomoníase urogenital" },
  {
    "codigo": "A598",
    "descricao": "Outras localizações de tricomoníase"
  },
  { "codigo": "A599", "descricao": "Tricomoníase não especificada" },
  {
    "codigo": "A600",
    "descricao": "Infecção dos órgãos genitais e do trato geniturinário pelo vírus do herpes"
  },
  {
    "codigo": "A601",
    "descricao": "Infecção da margem cutânea do ânus e do reto pelo vírus do herpes"
  },
  {
    "codigo": "A609",
    "descricao": "Infecção anogenital não especificada pelo vírus do herpes"
  },
  { "codigo": "A630", "descricao": "Verrugas anogenitais (venéreas)" },
  {
    "codigo": "A638",
    "descricao": "Outras doenças especificadas de transmissão predominantemente sexual"
  },
  {
    "codigo": "A64",
    "descricao": "Doenças sexualmente transmitidas, não especificadas"
  },
  { "codigo": "A65", "descricao": "Sífilis não-venérea" },
  { "codigo": "A660", "descricao": "Lesões iniciais da bouba" },
  {
    "codigo": "A661",
    "descricao": "Papilomas múltiplos e bouba plantar úmida (cravo de bouba)"
  },
  {
    "codigo": "A662",
    "descricao": "Outras lesões cutâneas precoces da bouba"
  },
  { "codigo": "A663", "descricao": "Hiperceratose devida a bouba" },
  {
    "codigo": "A664",
    "descricao": "Gomas e úlceras devidas à bouba"
  },
  { "codigo": "A665", "descricao": "Gangosa" },
  {
    "codigo": "A666",
    "descricao": "Lesões osteoarticulares devidas à bouba"
  },
  { "codigo": "A667", "descricao": "Outras manifestações da bouba" },
  { "codigo": "A668", "descricao": "Bouba latente" },
  { "codigo": "A669", "descricao": "Bouba não especificada" },
  { "codigo": "A670", "descricao": "Lesões primárias da pinta" },
  { "codigo": "A671", "descricao": "Lesões intermediárias da pinta" },
  { "codigo": "A672", "descricao": "Lesões tardias da pinta" },
  { "codigo": "A673", "descricao": "Lesões mistas da pinta" },
  { "codigo": "A679", "descricao": "Pinta não especificada" },
  { "codigo": "A680", "descricao": "Febre recorrente transmitida por piolhos" },
  {
    "codigo": "A681",
    "descricao": "Febre recorrente transmitida por carrapatos"
  },
  { "codigo": "A689", "descricao": "Febre recorrente não especificada" },
  { "codigo": "A690", "descricao": "Estomatite ulcerativa necrotizante" },
  { "codigo": "A691", "descricao": "Outras infecções de Vincent" },
  { "codigo": "A692", "descricao": "Doença de Lyme" },
  {
    "codigo": "A698",
    "descricao": "Outras infecções especificadas por espiroquetas"
  },
  {
    "codigo": "A699",
    "descricao": "Infecção por espiroqueta, não especificada"
  },
  {
    "codigo": "A70",
    "descricao": "Infecções causadas por Clamídia psittaci"
  },
  { "codigo": "A710", "descricao": "Fase inicial do tracoma" },
  { "codigo": "A711", "descricao": "Fase ativa do tracoma" },
  { "codigo": "A719", "descricao": "Tracoma não especificado" },
  { "codigo": "A740", "descricao": "Conjuntivite causada por clamídias" },
  {
    "codigo": "A748",
    "descricao": "Outras doenças causadas por clamídias"
  },
  {
    "codigo": "A749",
    "descricao": "Infecção causada por clamídias não especificada"
  },
  {
    "codigo": "A750",
    "descricao": "Tifo epidêmico transmitido por piolhos devido a Rickettsia prowazekii"
  },
  {
    "codigo": "A751",
    "descricao": "Tifo recrudescente [doença de Brill]"
  },
  { "codigo": "A752", "descricao": "Tifo por Rickettsia typhi" },
  { "codigo": "A753", "descricao": "Tifo por Rickettsia tsutsugamuchi" },
  { "codigo": "A759", "descricao": "Tifo não especificado" },
  { "codigo": "A770", "descricao": "Febre maculosa por Rickettsia richettsii" },
  { "codigo": "A771", "descricao": "Febre maculosa por Rickettsia conorii" },
  {
    "codigo": "A772",
    "descricao": "Febre maculosa devida à Rickettsia siberica"
  },
  {
    "codigo": "A773",
    "descricao": "Febre maculosa devida à Rickettsia australis"
  },
  { "codigo": "A778", "descricao": "Outras febres maculosas" },
  { "codigo": "A779", "descricao": "Febre maculosa não especificada" },
  { "codigo": "A78", "descricao": "Febre Q" },
  { "codigo": "A790", "descricao": "Febre das trincheiras" },
  {
    "codigo": "A791",
    "descricao": "Rickettsiose variceliforme devida à Rickettsia akari"
  },
  {
    "codigo": "A798",
    "descricao": "Outros tipos de rickettsioses especificadas"
  },
  { "codigo": "A799", "descricao": "Rickettsiose não especificada" },
  {
    "codigo": "A800",
    "descricao": "Poliomielite paralítica aguda, associada ao vírus vacinal"
  },
  {
    "codigo": "A801",
    "descricao": "Poliomielite paralítica aguda, vírus selvagem importado"
  },
  {
    "codigo": "A802",
    "descricao": "Poliomielite paralítica aguda, vírus selvagem indígena"
  },
  {
    "codigo": "A803",
    "descricao": "Poliomielites paralíticas agudas, outras e não especificadas"
  },
  {
    "codigo": "A804",
    "descricao": "Poliomielite aguda não-paralítica"
  },
  { "codigo": "A809", "descricao": "Poliomielite aguda não especificada" },
  { "codigo": "A810", "descricao": "Doença de Creutzfeldt-Jakob" },
  { "codigo": "A811", "descricao": "Panencefalite esclerosante subaguda" },
  {
    "codigo": "A812",
    "descricao": "Leucoencefalopatia multifocal progressiva"
  },
  {
    "codigo": "A818",
    "descricao": "Outras infecções por vírus atípicos do sistema nervoso central"
  },
  {
    "codigo": "A819",
    "descricao": "Infecção não especificada do sistema nervosos central por vírus atípicos"
  },
  { "codigo": "A820", "descricao": "Raiva silvestre" },
  { "codigo": "A821", "descricao": "Raiva urbana" },
  { "codigo": "A829", "descricao": "Raiva não especificada" },
  { "codigo": "A830", "descricao": "Encefalite japonesa" },
  { "codigo": "A831", "descricao": "Encefalite eqüina ocidental" },
  { "codigo": "A832", "descricao": "Encefalite eqüina oriental" },
  { "codigo": "A833", "descricao": "Encefalite de St. Louis" },
  { "codigo": "A834", "descricao": "Encefalite australiana" },
  { "codigo": "A835", "descricao": "Encefalite da Califórnia" },
  { "codigo": "A836", "descricao": "Doença pelo vírus de Rocio" },
  {
    "codigo": "A838",
    "descricao": "Outras encefalites por vírus transmitidas por mosquitos"
  },
  {
    "codigo": "A839",
    "descricao": "Encefalite não especificada por vírus transmitida por mosquitos"
  },
  {
    "codigo": "A840",
    "descricao": "Encefalite da taiga [encefalite vernoestival russa]"
  },
  {
    "codigo": "A841",
    "descricao": "Encefalite da Europa Central transmitida por carrapatos"
  },
  {
    "codigo": "A848",
    "descricao": "Outras encefalites por vírus transmitidas por carrapatos"
  },
  {
    "codigo": "A849",
    "descricao": "Encefalite não especificada por vírus transmitida por carrapatos"
  },
  { "codigo": "A850", "descricao": "Encefalite por enterovírus" },
  { "codigo": "A851", "descricao": "Encefalite por adenovírus" },
  {
    "codigo": "A852",
    "descricao": "Encefalite por vírus transmitido por artrópodes, não especificada"
  },
  { "codigo": "A858", "descricao": "Outras encefalites virais especificadas" },
  { "codigo": "A86", "descricao": "Encefalite viral, não especificada" },
  { "codigo": "A870", "descricao": "Meningite por enterovírus" },
  { "codigo": "A871", "descricao": "Meningite por adenovírus" },
  { "codigo": "A872", "descricao": "Coriomeningite linfocitária" },
  { "codigo": "A878", "descricao": "Outras meningites virais" },
  { "codigo": "A879", "descricao": "Meningite viral não especificada" },
  {
    "codigo": "A880",
    "descricao": "Febre exantemática por enterovírus [exantema de Boston]"
  },
  { "codigo": "A881", "descricao": "Vertigem epidêmica" },
  {
    "codigo": "A888",
    "descricao": "Outras infecções virais especificadas do sistema nervoso central"
  },
  {
    "codigo": "A89",
    "descricao": "Infecções virais não especificadas do sistema nervoso central"
  },
  { "codigo": "A90", "descricao": "Dengue [dengue clássico]" },
  {
    "codigo": "A91",
    "descricao": "Febre hemorrágica devida ao vírus do dengue"
  },
  { "codigo": "A920", "descricao": "Febre de Chikungunya" },
  { "codigo": "A921", "descricao": "Febre de O'nyong-nyong" },
  { "codigo": "A922", "descricao": "Febre eqüina venezuelana" },
  {
    "codigo": "A923",
    "descricao": "Infecção pelo vírus West Nile"
  },
  { "codigo": "A924", "descricao": "Febre do vale do Rift" },
  {
    "codigo": "A928",
    "descricao": "Outras febres virais especificadas transmitidas por mosquitos"
  },
  {
    "codigo": "A929",
    "descricao": "Febre viral transmitida por mosquitos, não especificada"
  },
  { "codigo": "A930", "descricao": "Febre de Oropouche" },
  { "codigo": "A931", "descricao": "Febre por flebótomos" },
  {
    "codigo": "A932",
    "descricao": "Febre do Colorado transmitida por carrapatos"
  },
  {
    "codigo": "A938",
    "descricao": "Outras febres virais especificadas transmitidas por artrópodes"
  },
  {
    "codigo": "A94",
    "descricao": "Febre viral transmitida por artrópodes, não especificada"
  },
  { "codigo": "A950", "descricao": "Febre amarela silvestre" },
  { "codigo": "A951", "descricao": "Febre amarela urbana" },
  { "codigo": "A959", "descricao": "Febre amarela não especificada" },
  { "codigo": "A960", "descricao": "Febre hemorrágica de Junin" },
  { "codigo": "A961", "descricao": "Febre hemorrágica de Machupo" },
  { "codigo": "A962", "descricao": "Febre de Lassa" },
  {
    "codigo": "A968",
    "descricao": "Outras febres hemorrágicas por arenavírus"
  },
  {
    "codigo": "A969",
    "descricao": "Febre hemorrágica por arenavírus, não especificada"
  },
  {
    "codigo": "A980",
    "descricao": "Febre hemorrágica da Criméia (do Congo)"
  },
  { "codigo": "A981", "descricao": "Febre hemorrágica de Omsk" },
  { "codigo": "A982", "descricao": "Doença da floresta de Kyasanur" },
  { "codigo": "A983", "descricao": "Doença de Marburg" },
  { "codigo": "A984", "descricao": "Doença pelo vírus Ebola" },
  {
    "codigo": "A985",
    "descricao": "Febre hemorrágica com síndrome renal"
  },
  {
    "codigo": "A988",
    "descricao": "Outras febres hemorrágicas especificadas por vírus"
  },
  {
    "codigo": "A99",
    "descricao": "Febres hemorrágicas virais não especificadas"
  },
  { "codigo": "B000", "descricao": "Eczema herpético" },
  {
    "codigo": "B001",
    "descricao": "Dermatite vesicular devido ao vírus do herpes"
  },
  {
    "codigo": "B002",
    "descricao": "Gengivoestomatite e faringoamigdalite devida ao vírus do herpes"
  },
  { "codigo": "B003", "descricao": "Meningite devida ao vírus do herpes" },
  {
    "codigo": "B004",
    "descricao": "Encefalite devida ao vírus do herpes"
  },
  {
    "codigo": "B005",
    "descricao": "Afecções oculares devidas ao vírus do herpes"
  },
  {
    "codigo": "B007",
    "descricao": "Doença disseminada devida ao vírus do herpes"
  },
  {
    "codigo": "B008",
    "descricao": "Outras formas de infecção devida ao vírus do herpes"
  },
  {
    "codigo": "B009",
    "descricao": "Infecção não especificada devida ao vírus do herpes"
  },
  { "codigo": "B010", "descricao": "Meningite por varicela" },
  { "codigo": "B011", "descricao": "Encefalite por varicela" },
  { "codigo": "B012", "descricao": "Pneumopatia varicelosa" },
  {
    "codigo": "B018",
    "descricao": "Varicela com outras complicações"
  },
  { "codigo": "B019", "descricao": "Varicela sem complicação" },
  {
    "codigo": "B020",
    "descricao": "Encefalite pelo vírus do herpes zoster"
  },
  {
    "codigo": "B021",
    "descricao": "Meningite pelo vírus do herpes zoster"
  },
  {
    "codigo": "B022",
    "descricao": "Herpes zoster acompanhado de outras manifestações neurológicas"
  },
  { "codigo": "B023", "descricao": "Herpes zoster oftálmico" },
  { "codigo": "B027", "descricao": "Herpes zoster disseminado" },
  {
    "codigo": "B028",
    "descricao": "Herpes zoster com outras complicações"
  },
  { "codigo": "B029", "descricao": "Herpes zoster sem complicação" },
  { "codigo": "B03", "descricao": "Varíola" },
  { "codigo": "B04", "descricao": "Varíola dos macacos [Monkeypox]" },
  { "codigo": "B050", "descricao": "Sarampo complicado por encefalite" },
  { "codigo": "B051", "descricao": "Sarampo complicado por meningite" },
  { "codigo": "B052", "descricao": "Sarampo complicado por pneumonia" },
  { "codigo": "B053", "descricao": "Sarampo complicado por otite média" },
  {
    "codigo": "B054",
    "descricao": "Sarampo com complicações intestinais"
  },
  {
    "codigo": "B058",
    "descricao": "Sarampo com outras complicações"
  },
  { "codigo": "B059", "descricao": "Sarampo sem complicação" },
  {
    "codigo": "B060",
    "descricao": "Rubéola com complicações neurológicas"
  },
  {
    "codigo": "B068",
    "descricao": "Rubéola com outras complicações"
  },
  { "codigo": "B069", "descricao": "Rubéola sem complicação" },
  { "codigo": "B07", "descricao": "Verrugas de origem viral" },
  {
    "codigo": "B080",
    "descricao": "Outras infecções por ortopoxvírus"
  },
  { "codigo": "B081", "descricao": "Molusco contagioso" },
  { "codigo": "B082", "descricao": "Exantema súbito [sexta doença]" },
  { "codigo": "B083", "descricao": "Eritema infeccioso [quinta doença]" },
  {
    "codigo": "B084",
    "descricao": "Estomatite vesicular devida a enterovírus com exantema"
  },
  {
    "codigo": "B085",
    "descricao": "Faringite vesicular devida a enterovírus"
  },
  {
    "codigo": "B088",
    "descricao": "Outras infecções virais especificadas caracterizadas por lesões de pele e das membranas mucosas"
  },
  {
    "codigo": "B09",
    "descricao": "Infecção viral não especificada caracterizada por lesões da pele e membranas mucosas"
  },
  { "codigo": "B150", "descricao": "Hepatite A com coma hepático" },
  { "codigo": "B159", "descricao": "Hepatite A sem coma hepático" },
  {
    "codigo": "B160",
    "descricao": "Hepatite aguda B com agente Delta (co-infecção), com coma hepático"
  },
  {
    "codigo": "B161",
    "descricao": "Hepatite aguda B com agente Delta, (co-infecção), sem coma hepático"
  },
  {
    "codigo": "B162",
    "descricao": "Hepatite aguda B sem agente Delta, com coma hepático"
  },
  {
    "codigo": "B169",
    "descricao": "Hepatite aguda B sem agente Delta e sem coma hepático"
  },
  {
    "codigo": "B170",
    "descricao": "(Super)infecção Delta aguda de portador de hepatite B"
  },
  { "codigo": "B171", "descricao": "Hepatite aguda C" },
  { "codigo": "B172", "descricao": "Hepatite aguda E" },
  {
    "codigo": "B178",
    "descricao": "Outras hepatites virais agudas especificadas"
  },
  {
    "codigo": "B180",
    "descricao": "Hepatite viral crônica B com agente Delta"
  },
  {
    "codigo": "B181",
    "descricao": "Hepatite crônica viral B sem agente Delta"
  },
  { "codigo": "B182", "descricao": "Hepatite viral crônica C" },
  { "codigo": "B188", "descricao": "Outras hepatites crônicas virais" },
  {
    "codigo": "B189",
    "descricao": "Hepatite viral crônica não especificada"
  },
  {
    "codigo": "B190",
    "descricao": "Hepatite viral, não especificada, com coma"
  },
  {
    "codigo": "B199",
    "descricao": "Hepatite viral, não especificada, sem coma"
  },
  {
    "codigo": "B200",
    "descricao": "Doença pelo HIV resultando em infecções micobacterianas"
  },
  {
    "codigo": "B201",
    "descricao": "Doença pelo HIV resultando em outras infecções bacterianas"
  },
  {
    "codigo": "B202",
    "descricao": "Doença pelo HIV resultando em doença citomegálica"
  },
  {
    "codigo": "B203",
    "descricao": "Doença pelo HIV resultando em outras infecções virais"
  },
  {
    "codigo": "B204",
    "descricao": "Doença pelo HIV resultando em candidíase"
  },
  {
    "codigo": "B205",
    "descricao": "Doença pelo HIV resultando em outras micoses"
  },
  {
    "codigo": "B206",
    "descricao": "Doença pelo HIV resultando em pneumonia por Pneumocystis jirovecii"
  },
  {
    "codigo": "B207",
    "descricao": "Doença pelo HIV resultando em infecções múltiplas"
  },
  {
    "codigo": "B208",
    "descricao": "Doença pelo HIV resultando em outras doenças infecciosas e parasitárias"
  },
  {
    "codigo": "B209",
    "descricao": "Doença pelo HIV resultando em doença infecciosa ou parasitária não especificada"
  },
  {
    "codigo": "B210",
    "descricao": "Doença pelo HIV resultando em sarcoma de Kaposi"
  },
  {
    "codigo": "B211",
    "descricao": "Doença pelo HIV resultando em linfoma de Burkitt"
  },
  {
    "codigo": "B212",
    "descricao": "Doença pelo HIV resultando em outros tipos de linfoma não-Hodgkin"
  },
  {
    "codigo": "B213",
    "descricao": "Doença pelo HIV resultando em outras neoplasias malignas dos tecidos linfático, hematopoético e correlatos"
  },
  {
    "codigo": "B217",
    "descricao": "Doença pelo HIV resultando em múltiplas neoplasias malignas"
  },
  {
    "codigo": "B218",
    "descricao": "Doença pelo HIV resultando em outras neoplasias malignas"
  },
  {
    "codigo": "B219",
    "descricao": "Doença pelo HIV resultando em neoplasia maligna não especificada"
  },
  {
    "codigo": "B220",
    "descricao": "Doença pelo HIV resultando em encefalopatia"
  },
  {
    "codigo": "B221",
    "descricao": "Doença pelo HIV resultando em pneumonite intersticial linfática"
  },
  {
    "codigo": "B222",
    "descricao": "Doença pelo HIV resultando em síndrome de emaciação"
  },
  {
    "codigo": "B227",
    "descricao": "Doença pelo HIV resultando em doenças múltiplas classificadas em outra parte"
  },
  {
    "codigo": "B230",
    "descricao": "Síndrome de infecção aguda pelo HIV"
  },
  {
    "codigo": "B231",
    "descricao": "Doença pelo HIV resultando em linfadenopatias generalizadas (persistentes)"
  },
  {
    "codigo": "B232",
    "descricao": "Doença pelo HIV resultando em anomalias hematológicas e imunológicas não classificadas em outra parte"
  },
  {
    "codigo": "B238",
    "descricao": "Doença pelo HIV resultando em outra afecções especificadas"
  },
  {
    "codigo": "B24",
    "descricao": "Doença pelo vírus da imunodeficiência humana [HIV] não especificada"
  },
  { "codigo": "B250", "descricao": "Pneumonite por citomegalovírus " },
  { "codigo": "B251", "descricao": "Hepatite por citomegalovírus" },
  { "codigo": "B252", "descricao": "Pancreatite por citomegalovírus" },
  {
    "codigo": "B258",
    "descricao": "Outras doenças por citomegalovírus"
  },
  {
    "codigo": "B259",
    "descricao": "Doença não especificada por citomegalovírus"
  },
  {
    "codigo": "B260",
    "descricao": "Orquite por caxumba [parotidite epidêmica]"
  },
  {
    "codigo": "B261",
    "descricao": "Meningite por caxumba [parotidite epidêmica]"
  },
  {
    "codigo": "B262",
    "descricao": "Encefalite por caxumba [parotidite epidêmica]"
  },
  {
    "codigo": "B263",
    "descricao": "Pancreatite por caxumba [parotidite epidêmica]"
  },
  {
    "codigo": "B268",
    "descricao": "Caxumba [parotidite epidêmica] com outras complicações"
  },
  {
    "codigo": "B269",
    "descricao": "Caxumba [parotidite epidêmica] sem complicações"
  },
  { "codigo": "B270", "descricao": "Mononucleose pelo vírus herpes gama" },
  { "codigo": "B271", "descricao": "Mononucleose por citomegalovírus" },
  { "codigo": "B278", "descricao": "Outras mononucleoses infecciosas" },
  {
    "codigo": "B279",
    "descricao": "Mononucleose infecciosa não especificada"
  },
  {
    "codigo": "B300",
    "descricao": "Ceratoconjuntivite devida a adenovírus"
  },
  { "codigo": "B301", "descricao": "Conjuntivite devida a adenovírus" },
  { "codigo": "B302", "descricao": "Faringoconjuntivite viral" },
  {
    "codigo": "B303",
    "descricao": "Conjuntivite hemorrágica aguda endêmica (por enterovírus)"
  },
  { "codigo": "B308", "descricao": "Outras conjuntivites virais" },
  { "codigo": "B309", "descricao": "Conjuntivite viral não especificada" },
  { "codigo": "B330", "descricao": "Mialgia epidêmica" },
  { "codigo": "B331", "descricao": "Doença de Ross River" },
  { "codigo": "B332", "descricao": "Cardite viral" },
  {
    "codigo": "B333",
    "descricao": "Infecção por retrovírus não classificada em outra parte"
  },
  {
    "codigo": "B334",
    "descricao": "Síndrome (cardio-)pulmonar por Hantavírus [SPH] [SCPH]"
  },
  {
    "codigo": "B338",
    "descricao": "Outras doenças especificadas por vírus"
  },
  {
    "codigo": "B340",
    "descricao": "Infecção por adenovírus de localização não especificada"
  },
  {
    "codigo": "B341",
    "descricao": "Infecção por enterovírus de localização não especificada"
  },
  {
    "codigo": "B342",
    "descricao": "Infecção por coronavírus de localização não especificada"
  },
  {
    "codigo": "B343",
    "descricao": "Infecção por parvovírus de localização não especificada"
  },
  {
    "codigo": "B344",
    "descricao": "Infecção por papovavírus de localização não especificada"
  },
  {
    "codigo": "B348",
    "descricao": "Outras infecções por vírus de localização não especificada"
  },
  {
    "codigo": "B349",
    "descricao": "Infecção viral não especificada"
  },
  { "codigo": "B350", "descricao": "Tinha da barba e do couro cabeludo" },
  { "codigo": "B351", "descricao": "Tinha das unhas" },
  { "codigo": "B352", "descricao": "Tinha da mão" },
  { "codigo": "B353", "descricao": "Tinha dos pés" },
  { "codigo": "B354", "descricao": "Tinha do corpo" },
  { "codigo": "B355", "descricao": "Tinha imbricada" },
  { "codigo": "B356", "descricao": "Tinea cruris" },
  { "codigo": "B358", "descricao": "Outras dermatofitoses" },
  { "codigo": "B359", "descricao": "Dermatofitose não especificada" },
  { "codigo": "B360", "descricao": "Pitiríase versicolor" },
  { "codigo": "B361", "descricao": "Tinha negra" },
  { "codigo": "B362", "descricao": "Piedra branca" },
  { "codigo": "B363", "descricao": "Piedra negra" },
  {
    "codigo": "B368",
    "descricao": "Outras micoses superficiais especificadas"
  },
  { "codigo": "B369", "descricao": "Micose superficial não especificada" },
  { "codigo": "B370", "descricao": "Estomatite por Candida" },
  { "codigo": "B371", "descricao": "Candidíase pulmonar" },
  { "codigo": "B372", "descricao": "Candidíase da pele e das unhas" },
  { "codigo": "B373", "descricao": "Candidíase da vulva e da vagina" },
  {
    "codigo": "B374",
    "descricao": "Candidíase de outras localizações urogenitais"
  },
  { "codigo": "B375", "descricao": "Meningite por Candida" },
  { "codigo": "B376", "descricao": "Endocardite por Candida" },
  { "codigo": "B377", "descricao": "Septicemia por Candida" },
  {
    "codigo": "B378",
    "descricao": "Candidíase de outras localizações"
  },
  { "codigo": "B379", "descricao": "Candidíase não especificada" },
  { "codigo": "B380", "descricao": "Coccidioidomicose pulmonar aguda" },
  { "codigo": "B381", "descricao": "Coccidioidomicose pulmonar crônica" },
  {
    "codigo": "B382",
    "descricao": "Coccidioidomicose pulmonar não especificada"
  },
  { "codigo": "B383", "descricao": "Coccidioidomicose cutânea" },
  { "codigo": "B384", "descricao": "Meningite por coccidioidomicose" },
  { "codigo": "B387", "descricao": "Coccidioidomicose disseminada" },
  { "codigo": "B388", "descricao": "Outras formas de coccidioidomicose" },
  { "codigo": "B389", "descricao": "Coccidioidomicose não especificada" },
  {
    "codigo": "B390",
    "descricao": "Histoplasmose pulmonar aguda por Histoplasma capsulatum"
  },
  {
    "codigo": "B391",
    "descricao": "Histoplasmose pulmonar crônica por Histoplasma capsulatum"
  },
  {
    "codigo": "B392",
    "descricao": "Histoplasmose pulmonar não especificada por Histoplasma capsulatum"
  },
  {
    "codigo": "B393",
    "descricao": "Histoplasmose disseminada por Histoplasma capsulatum"
  },
  {
    "codigo": "B394",
    "descricao": "Histoplasmose não especificada por Histoplasma capsulatum"
  },
  { "codigo": "B395", "descricao": "Histoplasmose por Histoplasma duboisii" },
  { "codigo": "B399", "descricao": "Histoplasmose não especificada" },
  { "codigo": "B400", "descricao": "Blastomicose pulmonar aguda" },
  { "codigo": "B401", "descricao": "Blastomicose pulmonar crônica" },
  {
    "codigo": "B402",
    "descricao": "Blastomicose pulmonar não especificada"
  },
  { "codigo": "B403", "descricao": "Blastomicose cutânea" },
  { "codigo": "B407", "descricao": "Blastomicose disseminada" },
  { "codigo": "B408", "descricao": "Outras formas de blastomicose" },
  { "codigo": "B409", "descricao": "Blastomicose não especificada" },
  { "codigo": "B410", "descricao": "Paracoccidioidomicose pulmonar" },
  { "codigo": "B417", "descricao": "Paracoccidioidomicose disseminada" },
  { "codigo": "B418", "descricao": "Outras formas de paracoccidioidomicose" },
  {
    "codigo": "B419",
    "descricao": "Paracoccidioidomicose não especificada"
  },
  { "codigo": "B420", "descricao": "Esporotricose pulmonar" },
  { "codigo": "B421", "descricao": "Esporotricose linfocutânea" },
  { "codigo": "B427", "descricao": "Esporotricose disseminada" },
  { "codigo": "B428", "descricao": "Outras formas de esporotricose" },
  { "codigo": "B429", "descricao": "Esporotricose não especificada" },
  { "codigo": "B430", "descricao": "Cromomicose cutânea" },
  { "codigo": "B431", "descricao": "Abscesso cerebral feomicótico" },
  {
    "codigo": "B432",
    "descricao": "Abscesso e cisto feomicótico subcutâneos"
  },
  { "codigo": "B438", "descricao": "Outras formas de cromomicose" },
  { "codigo": "B439", "descricao": "Cromomicose não especificada" },
  { "codigo": "B440", "descricao": "Aspergilose pulmonar invasiva" },
  { "codigo": "B441", "descricao": "Outras aspergiloses pulmonares" },
  { "codigo": "B442", "descricao": "Aspergilose amigdaliana" },
  { "codigo": "B447", "descricao": "Aspergilose disseminada" },
  { "codigo": "B448", "descricao": "Outras formas de aspergilose" },
  { "codigo": "B449", "descricao": "Aspergilose não especificada" },
  { "codigo": "B450", "descricao": "Criptococose pulmonar" },
  { "codigo": "B451", "descricao": "Criptococose cerebral" },
  { "codigo": "B452", "descricao": "Criptococose cutânea" },
  { "codigo": "B453", "descricao": "Criptococose óssea" },
  { "codigo": "B457", "descricao": "Criptococose disseminada" },
  { "codigo": "B458", "descricao": "Outras formas de criptococose" },
  { "codigo": "B459", "descricao": "Criptococose não especificada" },
  { "codigo": "B460", "descricao": "Mucormicose pulmonar" },
  { "codigo": "B461", "descricao": "Mucormicose rinocerebral" },
  { "codigo": "B462", "descricao": "Mucormicose gastrointestinal" },
  { "codigo": "B463", "descricao": "Mucormicose cutânea" },
  { "codigo": "B464", "descricao": "Mucormicose disseminada" },
  { "codigo": "B465", "descricao": "Mucormicose não especificada" },
  { "codigo": "B468", "descricao": "Outras zigomicoses" },
  { "codigo": "B469", "descricao": "Zigomicose não especificada" },
  { "codigo": "B470", "descricao": "Eumicetoma" },
  { "codigo": "B471", "descricao": "Actinomicetoma" },
  { "codigo": "B479", "descricao": "Micetoma não especificado" },
  { "codigo": "B480", "descricao": "Lobomicose" },
  { "codigo": "B481", "descricao": "Rinosporidiose" },
  { "codigo": "B482", "descricao": "Alesqueriose" },
  { "codigo": "B483", "descricao": "Geotricose" },
  { "codigo": "B484", "descricao": "Penicilose" },
  { "codigo": "B487", "descricao": "Micoses oportunistas" },
  { "codigo": "B488", "descricao": "Outras micoses especificadas" },
  { "codigo": "B49", "descricao": "Micose não especificada" },
  {
    "codigo": "B500",
    "descricao": "Malária por Plasmodium falciparum com complicações cerebrais"
  },
  {
    "codigo": "B508",
    "descricao": "Outras formas graves e complicadas de malária por Plasmodium falciparum"
  },
  {
    "codigo": "B509",
    "descricao": "Malária não especificada por Plasmodium falciparum"
  },
  {
    "codigo": "B510",
    "descricao": "Malária por Plasmodium vivax com rotura do baço"
  },
  {
    "codigo": "B518",
    "descricao": "Malária por Plasmodium vivax com outras complicações"
  },
  {
    "codigo": "B519",
    "descricao": "Malária por Plasmodium vivax sem complicações"
  },
  {
    "codigo": "B520",
    "descricao": "Malária por Plasmodium malariae com nefropatia"
  },
  {
    "codigo": "B528",
    "descricao": "Malária por Plasmodium malariae com outras complicações"
  },
  {
    "codigo": "B529",
    "descricao": "Malária por Plasmodium malariae sem complicações"
  },
  { "codigo": "B530", "descricao": "Malária por Plasmodium ovale" },
  {
    "codigo": "B531",
    "descricao": "Malária por plasmódios de macacos"
  },
  {
    "codigo": "B538",
    "descricao": "Outras formas de malárias com confirmação parasitológica, não classificadas em outra parte"
  },
  { "codigo": "B54", "descricao": "Malária não especificada" },
  { "codigo": "B550", "descricao": "Leishmaniose visceral" },
  { "codigo": "B551", "descricao": "Leishmaniose cutânea" },
  { "codigo": "B552", "descricao": "Leishmaniose cutâneo-mucosa" },
  { "codigo": "B559", "descricao": "Leishmaniose não especificada" },
  {
    "codigo": "B560",
    "descricao": "Tripanossomíase por Trypanosoma gambiense"
  },
  {
    "codigo": "B561",
    "descricao": "Tripanossomíase por Trypanosoma rhodesiense"
  },
  {
    "codigo": "B569",
    "descricao": "Tripanossomíase africana não especificada"
  },
  {
    "codigo": "B570",
    "descricao": "Forma aguda da doença de Chagas, com comprometimento cardíaco"
  },
  {
    "codigo": "B571",
    "descricao": "Forma aguda da doença de Chagas, sem comprometimento cardíaco"
  },
  {
    "codigo": "B572",
    "descricao": "Doença de Chagas (crônica) com comprometimento cardíaco"
  },
  {
    "codigo": "B573",
    "descricao": "Doença de Chagas (crônica) com comprometimento do aparelho digestivo"
  },
  {
    "codigo": "B574",
    "descricao": "Doença de Chagas (crônica) com comprometimento do sistema nervoso"
  },
  {
    "codigo": "B575",
    "descricao": "Doença de Chagas (crônica) com comprometimento de outros órgãos"
  },
  { "codigo": "B580", "descricao": "Oculopatia por Toxoplasma" },
  { "codigo": "B581", "descricao": "Hepatite por Toxoplasma" },
  { "codigo": "B582", "descricao": "Meningoencefalite por Toxoplasma" },
  { "codigo": "B583", "descricao": "Toxoplasmose pulmonar" },
  {
    "codigo": "B588",
    "descricao": "Toxoplasmose com comprometimento de outros órgãos"
  },
  { "codigo": "B589", "descricao": "Toxoplasmose não especificada" },
  { "codigo": "B59", "descricao": "Pneumocistose" },
  { "codigo": "B600", "descricao": "Babesiose" },
  { "codigo": "B601", "descricao": "Acantamebíase" },
  { "codigo": "B602", "descricao": "Naegleríase" },
  {
    "codigo": "B608",
    "descricao": "Outras doenças especificadas devidas a protozoários"
  },
  {
    "codigo": "B64",
    "descricao": "Doença não especificada devida a protozoários"
  },
  {
    "codigo": "B650",
    "descricao": "Esquistossomose devida ao Schistosoma haematobium [esquistossomose urinária]"
  },
  {
    "codigo": "B651",
    "descricao": "Esquistossomose devida ao Schistosoma mansoni [esquistossomose intestinal]"
  },
  {
    "codigo": "B652",
    "descricao": "Esquistossomose devida ao Schistosoma japonicum"
  },
  { "codigo": "B653", "descricao": "Dermatite por cercárias" },
  { "codigo": "B658", "descricao": "Outras esquistossomoses" },
  { "codigo": "B659", "descricao": "Esquistossomose não especificada" },
  { "codigo": "B660", "descricao": "Opistorquíase" },
  { "codigo": "B661", "descricao": "Clonorquíase" },
  { "codigo": "B662", "descricao": "Dicrocelíase" },
  { "codigo": "B663", "descricao": "Fasciolíase" },
  { "codigo": "B664", "descricao": "Paragonimíase" },
  { "codigo": "B665", "descricao": "Fasciolopsíase" },
  {
    "codigo": "B668",
    "descricao": "Outras infestações por trematódeos especificados"
  },
  {
    "codigo": "B669",
    "descricao": "Infecção não especificada por trematódeo"
  },
  {
    "codigo": "B670",
    "descricao": "Infestação hepática por Echinococcus granulosus"
  },
  {
    "codigo": "B671",
    "descricao": "Infestação pulmonar por Echinococcus granulosus"
  },
  {
    "codigo": "B672",
    "descricao": "Infestação óssea por Echinococcus granulosus"
  },
  {
    "codigo": "B673",
    "descricao": "Infestações por Echinmococcus granulosus, outras e de localizações múltiplas"
  },
  {
    "codigo": "B674",
    "descricao": "Infestação não especificada por Echinococcus granulosus"
  },
  {
    "codigo": "B675",
    "descricao": "Infestação hepática por Echinococcus multilocularis"
  },
  {
    "codigo": "B676",
    "descricao": "Infecções por Echinococcus multilocularis, outras e de localizações múltiplas"
  },
  {
    "codigo": "B677",
    "descricao": "Infestação não especificada por Echinococcus multilocularis"
  },
  {
    "codigo": "B678",
    "descricao": "Infestação hepática não especificada, por Echinococcus"
  },
  {
    "codigo": "B679",
    "descricao": "Infestações por Echinococcus, outras e as não especificadas"
  },
  { "codigo": "B680", "descricao": "Infestação por Taenia solium" },
  { "codigo": "B681", "descricao": "Infestação por Taenia saginata" },
  {
    "codigo": "B689",
    "descricao": "Infestação não especificada por Taenia"
  },
  { "codigo": "B690", "descricao": "Cisticercose do sistema nervoso central" },
  { "codigo": "B691", "descricao": "Cisticercose do olho" },
  {
    "codigo": "B698",
    "descricao": "Cisticercose de outras localizações"
  },
  { "codigo": "B699", "descricao": "Cisticercose não especificada" },
  { "codigo": "B700", "descricao": "Difilobotríase" },
  { "codigo": "B701", "descricao": "Esparganose" },
  { "codigo": "B710", "descricao": "Infestação por Hymenolepis" },
  { "codigo": "B711", "descricao": "Infestação por Dipylidium" },
  {
    "codigo": "B718",
    "descricao": "Outras infestações especificadas por cestóides"
  },
  {
    "codigo": "B719",
    "descricao": "Infestação não especificada por cestóides"
  },
  { "codigo": "B72", "descricao": "Dracontíase" },
  { "codigo": "B73", "descricao": "Oncocercose" },
  { "codigo": "B740", "descricao": "Filariose por Wuchereria bancrofti" },
  { "codigo": "B741", "descricao": "Filariose por Brugia malayi" },
  { "codigo": "B742", "descricao": "Filariose por Brugia timori" },
  { "codigo": "B743", "descricao": "Loaíase" },
  { "codigo": "B744", "descricao": "Mansonelose" },
  { "codigo": "B748", "descricao": "Outras filarioses" },
  { "codigo": "B749", "descricao": "Filariose não especificada" },
  { "codigo": "B75", "descricao": "Triquinose" },
  { "codigo": "B760", "descricao": "Ancilostomose" },
  { "codigo": "B761", "descricao": "Necatoríase" },
  { "codigo": "B768", "descricao": "Outras ancilostomíases" },
  {
    "codigo": "B769",
    "descricao": "Ancilostomíase não especificada"
  },
  {
    "codigo": "B770",
    "descricao": "Ascaridíase com complicações intestinais"
  },
  {
    "codigo": "B778",
    "descricao": "Ascaridíase com outras complicações"
  },
  { "codigo": "B779", "descricao": "Ascaridíase não especificada" },
  { "codigo": "B780", "descricao": "Estrongiloidíase intestinal" },
  { "codigo": "B781", "descricao": "Estrongiloidíase cutânea" },
  { "codigo": "B787", "descricao": "Estrongiloidíase disseminada" },
  {
    "codigo": "B789",
    "descricao": "Estrongiloidíase não especificada"
  },
  { "codigo": "B79", "descricao": "Tricuríase" },
  { "codigo": "B80", "descricao": "Oxiuríase" },
  { "codigo": "B810", "descricao": "Anisaquíase" },
  { "codigo": "B811", "descricao": "Capilaríase intestinal" },
  { "codigo": "B812", "descricao": "Tricostrongilose" },
  { "codigo": "B813", "descricao": "Angiostrongilíase intestinal" },
  { "codigo": "B814", "descricao": "Helmintíases intestinais mistas" },
  {
    "codigo": "B818",
    "descricao": "Outras helmintíases intestinais especificadas"
  },
  {
    "codigo": "B820",
    "descricao": "Helmintíase intestinal não especificada"
  },
  {
    "codigo": "B829",
    "descricao": "Parasitose intestinal não especificada"
  },
  { "codigo": "B830", "descricao": "Larva migrans visceral" },
  { "codigo": "B831", "descricao": "Gnatostomíase" },
  {
    "codigo": "B832",
    "descricao": "Angrostrongilíase devida a Parastrongylus cantonensis"
  },
  { "codigo": "B833", "descricao": "Singamose" },
  { "codigo": "B834", "descricao": "Hirudiníase interna" },
  { "codigo": "B838", "descricao": "Outras helmintíases especificadas" },
  { "codigo": "B839", "descricao": "Helmintíase não especificada" },
  {
    "codigo": "B850",
    "descricao": "Pediculose devida a Pediculus humanus capitis"
  },
  {
    "codigo": "B851",
    "descricao": "Pediculose devida a Pediculus humanus corporis"
  },
  { "codigo": "B852", "descricao": "Pediculose não especificada" },
  { "codigo": "B853", "descricao": "Ftiríase" },
  { "codigo": "B854", "descricao": "Pediculose e ftiríase mista" },
  { "codigo": "B86", "descricao": "Escabiose [sarna]" },
  { "codigo": "B870", "descricao": "Miíase cutânea" },
  { "codigo": "B871", "descricao": "Miíase das feridas" },
  { "codigo": "B872", "descricao": "Miíase ocular" },
  { "codigo": "B873", "descricao": "Miíase nasofaríngea" },
  { "codigo": "B874", "descricao": "Miíase auricular" },
  {
    "codigo": "B878",
    "descricao": "Miíase de outras localizações"
  },
  { "codigo": "B879", "descricao": "Miíase não especificada" },
  { "codigo": "B880", "descricao": "Outras acaríases" },
  {
    "codigo": "B881",
    "descricao": "Tungíase [infestação pela pulga da areia]"
  },
  {
    "codigo": "B882",
    "descricao": "Outras infestações por artrópodos"
  },
  { "codigo": "B883", "descricao": "Hirudiníase externa" },
  {
    "codigo": "B888",
    "descricao": "Outras infestações especificadas"
  },
  {
    "codigo": "B889",
    "descricao": "Infestação não especificada"
  },
  {
    "codigo": "B89",
    "descricao": "Doença parasitária não especificada"
  },
  {
    "codigo": "B900",
    "descricao": "Seqüelas de tuberculose do sistema nervoso central"
  },
  {
    "codigo": "B901",
    "descricao": "Seqüelas de tuberculose geniturinária"
  },
  {
    "codigo": "B902",
    "descricao": "Seqüelas de tuberculose óssea e das articulações"
  },
  {
    "codigo": "B908",
    "descricao": "Seqüelas de tuberculose de outros órgãos"
  },
  {
    "codigo": "B909",
    "descricao": "Seqüelas de tuberculose das vias respiratórias e de órgãos não especificados"
  },
  { "codigo": "B91", "descricao": "Seqüelas de poliomielite" },
  { "codigo": "B92", "descricao": "Seqüelas de hanseníase [lepra]" },
  { "codigo": "B940", "descricao": "Seqüelas de tracoma" },
  { "codigo": "B941", "descricao": "Seqüelas de encefalite viral" },
  { "codigo": "B942", "descricao": "Seqüelas de hepatite viral" },
  {
    "codigo": "B948",
    "descricao": "Seqüelas de outras doenças infecciosas e parasitárias especificadas"
  },
  {
    "codigo": "B949",
    "descricao": "Seqüelas de doença infecciosa ou parasitária não especificada"
  },
  {
    "codigo": "B950",
    "descricao": "Estreptococo do grupo A, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B951",
    "descricao": "Estreptococo do grupo B, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B952",
    "descricao": "Estreptococo do grupo D, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B953",
    "descricao": "Streptococcus pneumoniae, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B954",
    "descricao": "Outros estreptococos, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B955",
    "descricao": "Estreptococo não especificado, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B956",
    "descricao": "Staphylococcus aureus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B957",
    "descricao": "Outros estafilococos como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B958",
    "descricao": "Estafilococo não especificado, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B960",
    "descricao": "Mycoplasma pneumoniae [M. pneumoniae], como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B961",
    "descricao": "Klebsiella pneumoniae [M. pneumoniae], como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B962",
    "descricao": "Escherichia coli [E. Coli], como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B963",
    "descricao": "Haemophilus influenzae [H. influenzae], como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B964",
    "descricao": "Proteus (mirabilis) (morganii), como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B965",
    "descricao": "Pseudomonas (aeruginosa) (mallei) (pseudomallei), como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B966",
    "descricao": "Bacillus fragilis [B. fragilis], como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B967",
    "descricao": "Clostridium perfringens [C. perfringens], como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B968",
    "descricao": "Outros agentes bacterianos especificados, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B970",
    "descricao": "Adenovírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B971",
    "descricao": "Enterovírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B972",
    "descricao": "Coronavírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B973",
    "descricao": "Retrovírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B974",
    "descricao": "Vírus sincicial respiratório, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B975",
    "descricao": "Reovírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B976",
    "descricao": "Parvovírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B977",
    "descricao": "Papilomavírus, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B978",
    "descricao": "Outros agentes virais, como causa de doenças classificadas em outros capítulos"
  },
  {
    "codigo": "B99",
    "descricao": "Doenças infecciosas, outras e as não especificadas"
  },
  {
    "codigo": "C000",
    "descricao": "Neoplasia maligna do lábio superior externo"
  },
  {
    "codigo": "C001",
    "descricao": "Neoplasia maligna do lábio inferior externo"
  },
  {
    "codigo": "C002",
    "descricao": "Neoplasia maligna do lábio externo, não especificado"
  },
  {
    "codigo": "C003",
    "descricao": "Neoplasia maligna do lábio superior, face interna"
  },
  {
    "codigo": "C004",
    "descricao": "Neoplasia maligna do lábio inferior, face interna"
  },
  {
    "codigo": "C005",
    "descricao": "Neoplasia maligna do lábio, sem especificação, face interna"
  },
  { "codigo": "C006", "descricao": "Neoplasia maligna da comissura labial" },
  {
    "codigo": "C008",
    "descricao": "Neoplasia maligna do lábio com lesão invasiva"
  },
  {
    "codigo": "C009",
    "descricao": "Neoplasia maligna do lábio, não especificado"
  },
  { "codigo": "C01", "descricao": "Neoplasia maligna da base da língua" },
  {
    "codigo": "C020",
    "descricao": "Neoplasia maligna da face dorsal da língua"
  },
  {
    "codigo": "C021",
    "descricao": "Neoplasia maligna da borda da língua"
  },
  {
    "codigo": "C022",
    "descricao": "Neoplasia maligna da face ventral da língua"
  },
  {
    "codigo": "C023",
    "descricao": "Neoplasia maligna de dois terços anteriores da língua, parte não especificada"
  },
  {
    "codigo": "C024",
    "descricao": "Neoplasia maligna da amígdala lingual"
  },
  {
    "codigo": "C028",
    "descricao": "Neoplasia maligna da língua com lesão invasiva"
  },
  {
    "codigo": "C029",
    "descricao": "Neoplasia maligna da língua, não especificada"
  },
  { "codigo": "C030", "descricao": "Neoplasia maligna da gengiva superior" },
  { "codigo": "C031", "descricao": "Neoplasia maligna da gengiva inferior" },
  {
    "codigo": "C039",
    "descricao": "Neoplasia maligna da gengiva, não especificada"
  },
  {
    "codigo": "C040",
    "descricao": "Neoplasia maligna do assoalho anterior da boca"
  },
  {
    "codigo": "C041",
    "descricao": "Neoplasia maligna do assoalho lateral da boca"
  },
  {
    "codigo": "C048",
    "descricao": "Neoplasia maligna do assoalho da boca com lesão invasiva"
  },
  {
    "codigo": "C049",
    "descricao": "Neoplasia maligna do assoalho da boca, não especificado"
  },
  { "codigo": "C050", "descricao": "Neoplasia maligna do palato duro" },
  { "codigo": "C051", "descricao": "Neoplasia maligna do palato mole" },
  { "codigo": "C052", "descricao": "Neoplasia maligna da úvula" },
  {
    "codigo": "C058",
    "descricao": "Neoplasia maligna do palato com lesão invasiva"
  },
  {
    "codigo": "C059",
    "descricao": "Neoplasia maligna do palato, não especificado"
  },
  { "codigo": "C060", "descricao": "Neoplasia maligna da mucosa oral" },
  {
    "codigo": "C061",
    "descricao": "Neoplasia maligna do vestíbulo da boca"
  },
  {
    "codigo": "C062",
    "descricao": "Neoplasia maligna da área retromolar"
  },
  {
    "codigo": "C068",
    "descricao": "Neoplasia maligna de outras partes e de partes não especificadas da boca com lesão invasiva"
  },
  {
    "codigo": "C069",
    "descricao": "Neoplasia maligna da boca, não especificada"
  },
  {
    "codigo": "C07",
    "descricao": "Neoplasia maligna da glândula parótida"
  },
  {
    "codigo": "C080",
    "descricao": "Neoplasia maligna da glândula submandibular"
  },
  {
    "codigo": "C081",
    "descricao": "Neoplasia maligna da glândula sublingual"
  },
  {
    "codigo": "C088",
    "descricao": "Neoplasia maligna das glândulas salivares maiores com lesão invasiva"
  },
  {
    "codigo": "C089",
    "descricao": "Neoplasia maligna da glândula salivar maior, não especificada"
  },
  { "codigo": "C090", "descricao": "Neoplasia maligna da fossa amigdaliana" },
  {
    "codigo": "C091",
    "descricao": "Neoplasia maligna do pilar amigdaliano (anterior) (posterior)"
  },
  {
    "codigo": "C098",
    "descricao": "Neoplasia maligna da amígdala com lesão invasiva"
  },
  {
    "codigo": "C099",
    "descricao": "Neoplasia maligna da amígdala, não especificada"
  },
  { "codigo": "C100", "descricao": "Neoplasia maligna da valécula" },
  {
    "codigo": "C101",
    "descricao": "Neoplasia maligna da face anterior da epiglote"
  },
  {
    "codigo": "C102",
    "descricao": "Neoplasia maligna da parede lateral da orofaringe"
  },
  {
    "codigo": "C103",
    "descricao": "Neoplasia maligna da parede posterior da orofaringe"
  },
  { "codigo": "C104", "descricao": "Neoplasia maligna da fenda branquial" },
  {
    "codigo": "C108",
    "descricao": "Neoplasia maligna da orofaringe com lesão invasiva"
  },
  {
    "codigo": "C109",
    "descricao": "Neoplasia maligna da orofaringe, não especificada"
  },
  {
    "codigo": "C110",
    "descricao": "Neoplasia maligna da parede superior da nasofaringe"
  },
  {
    "codigo": "C111",
    "descricao": "Neoplasia maligna da parede posterior da nasofaringe"
  },
  {
    "codigo": "C112",
    "descricao": "Neoplasia maligna da parede lateral da nasofaringe"
  },
  {
    "codigo": "C113",
    "descricao": "Neoplasia maligna da parede anterior da nasofaringe"
  },
  {
    "codigo": "C118",
    "descricao": "Neoplasia maligna da nasofaringe com lesão invasiva"
  },
  {
    "codigo": "C119",
    "descricao": "Neoplasia maligna da nasofaringe, não especificada"
  },
  { "codigo": "C12", "descricao": "Neoplasia maligna do seio piriforme" },
  {
    "codigo": "C130",
    "descricao": "Neoplasia maligna da região pós-cricóidea"
  },
  {
    "codigo": "C131",
    "descricao": "Neoplasia maligna da prega ariepiglótica, face hipofaríngea"
  },
  {
    "codigo": "C132",
    "descricao": "Neoplasia maligna da parede posterior da hipofaringe"
  },
  {
    "codigo": "C138",
    "descricao": "Neoplasia maligna da hipofaringe com lesão invasiva"
  },
  {
    "codigo": "C139",
    "descricao": "Neoplasia maligna da hipofaringe, não especificada"
  },
  {
    "codigo": "C140",
    "descricao": "Neoplasia maligna da faringe, não especificada"
  },
  { "codigo": "C142", "descricao": "Neoplasia maligna do anel de Waldeyer" },
  {
    "codigo": "C148",
    "descricao": "Neoplasia maligna do lábio, cavidade oral e faringe com lesão invasiva"
  },
  {
    "codigo": "C150",
    "descricao": "Neoplasia maligna da porção cervical do esôfago (esôfago cervical)"
  },
  {
    "codigo": "C151",
    "descricao": "Neoplasia maligna da porção torácica do esôfago (esôfago torácico)"
  },
  {
    "codigo": "C152",
    "descricao": "Neoplasia maligna da porção abdominal do esôfago (esôfago abdominal)"
  },
  {
    "codigo": "C153",
    "descricao": "Neoplasia maligna do terço superior do esôfago"
  },
  {
    "codigo": "C154",
    "descricao": "Neoplasia maligna do terço médio do esôfago"
  },
  {
    "codigo": "C155",
    "descricao": "Neoplasia maligna do terço inferior do esôfago"
  },
  {
    "codigo": "C158",
    "descricao": "Neoplasia maligna do esôfago com lesão invasiva"
  },
  {
    "codigo": "C159",
    "descricao": "Neoplasia maligna do esôfago, não especificado"
  },
  { "codigo": "C160", "descricao": "Neoplasia maligna da cárdia" },
  {
    "codigo": "C161",
    "descricao": "Neoplasia maligna do fundo do estômago"
  },
  {
    "codigo": "C162",
    "descricao": "Neoplasia maligna do corpo do estômago"
  },
  { "codigo": "C163", "descricao": "Neoplasia maligna do antro pilórico" },
  { "codigo": "C164", "descricao": "Neoplasia maligna do piloro" },
  {
    "codigo": "C165",
    "descricao": "Neoplasia maligna da pequena curvatura do estômago, não especificada"
  },
  {
    "codigo": "C166",
    "descricao": "Neoplasia maligna da grande curvatura do estômago, não especificada"
  },
  {
    "codigo": "C168",
    "descricao": "Neoplasia maligna do estômago com lesão invasiva"
  },
  {
    "codigo": "C169",
    "descricao": "Neoplasia maligna do estômago, não especificado"
  },
  { "codigo": "C170", "descricao": "Neoplasia maligna do duodeno" },
  { "codigo": "C171", "descricao": "Neoplasia maligna do jejuno" },
  { "codigo": "C172", "descricao": "Neoplasia maligna do íleo" },
  {
    "codigo": "C173",
    "descricao": "Neoplasia maligna do divertículo de Meckel"
  },
  {
    "codigo": "C178",
    "descricao": "Neoplasia maligna do intestino delgado com lesão invasiva"
  },
  {
    "codigo": "C179",
    "descricao": "Neoplasia maligna do intestino delgado, não especificado"
  },
  { "codigo": "C180", "descricao": "Neoplasia maligna do ceco" },
  {
    "codigo": "C181",
    "descricao": "Neoplasia maligna do apêndice (vermiforme)"
  },
  {
    "codigo": "C182",
    "descricao": "Neoplasia maligna do cólon ascendente"
  },
  {
    "codigo": "C183",
    "descricao": "Neoplasia maligna da flexura (ângulo) hepática(o)"
  },
  {
    "codigo": "C184",
    "descricao": "Neoplasia maligna do cólon transverso"
  },
  {
    "codigo": "C185",
    "descricao": "Neoplasia maligna da flexura (ângulo) esplênica(o)"
  },
  {
    "codigo": "C186",
    "descricao": "Neoplasia maligna do cólon descendente"
  },
  {
    "codigo": "C187",
    "descricao": "Neoplasia maligna do cólon sigmóide"
  },
  {
    "codigo": "C188",
    "descricao": "Neoplasia maligna do cólon com lesão invasiva"
  },
  {
    "codigo": "C189",
    "descricao": "Neoplasia maligna do cólon, não especificado"
  },
  {
    "codigo": "C19",
    "descricao": "Neoplasia maligna da junção retossigmóide"
  },
  { "codigo": "C20", "descricao": "Neoplasia maligna do reto" },
  {
    "codigo": "C210",
    "descricao": "Neoplasia maligna do ânus, não especificado"
  },
  { "codigo": "C211", "descricao": "Neoplasia maligna do canal anal" },
  {
    "codigo": "C212",
    "descricao": "Neoplasia maligna da zona cloacogênica"
  },
  {
    "codigo": "C218",
    "descricao": "Neoplasia maligna do reto, ânus e do canal anal com lesão invasiva"
  },
  { "codigo": "C220", "descricao": "Carcinoma de células hepáticas" },
  {
    "codigo": "C221",
    "descricao": "Carcinoma de vias biliares intra-hepáticas"
  },
  { "codigo": "C222", "descricao": "Hepatoblastoma" },
  { "codigo": "C223", "descricao": "Angiossarcoma do fígado" },
  { "codigo": "C224", "descricao": "Outros sarcomas do fígado" },
  {
    "codigo": "C227",
    "descricao": "Outros carcinomas especificados do fígado"
  },
  {
    "codigo": "C229",
    "descricao": "Neoplasia maligna do fígado, não especificada"
  },
  { "codigo": "C23", "descricao": "Neoplasia maligna da vesícula biliar" },
  {
    "codigo": "C240",
    "descricao": "Neoplasia maligna das vias biliares extra-hepáticas"
  },
  { "codigo": "C241", "descricao": "Neoplasia maligna da ampola de Vater" },
  {
    "codigo": "C248",
    "descricao": "Neoplasia maligna das vias biliares com lesão invasiva"
  },
  {
    "codigo": "C249",
    "descricao": "Neoplasia maligna da via biliar, não especificada"
  },
  {
    "codigo": "C250",
    "descricao": "Neoplasia maligna da cabeça do pâncreas"
  },
  {
    "codigo": "C251",
    "descricao": "Neoplasia maligna do corpo do pâncreas"
  },
  {
    "codigo": "C252",
    "descricao": "Neoplasia maligna da cauda do pâncreas"
  },
  {
    "codigo": "C253",
    "descricao": "Neoplasia maligna do canal pancreático"
  },
  {
    "codigo": "C254",
    "descricao": "Neoplasia maligna do pâncreas endócrino"
  },
  {
    "codigo": "C257",
    "descricao": "Neoplasia maligna de outras partes do pâncreas"
  },
  {
    "codigo": "C258",
    "descricao": "Neoplasia maligna do pâncreas com lesão invasiva"
  },
  {
    "codigo": "C259",
    "descricao": "Neoplasia maligna do pâncreas, não especificado"
  },
  {
    "codigo": "C260",
    "descricao": "Neoplasia maligna do trato intestinal, parte não especificada"
  },
  { "codigo": "C261", "descricao": "Neoplasia maligna do baço" },
  {
    "codigo": "C268",
    "descricao": "Neoplasia maligna do aparelho digestivo com lesão invasiva"
  },
  {
    "codigo": "C269",
    "descricao": "Neoplasia maligna de localizações mal definidas dentro do aparelho digestivo"
  },
  { "codigo": "C300", "descricao": "Neoplasia maligna da cavidade nasal" },
  { "codigo": "C301", "descricao": "Neoplasia maligna do ouvido médio" },
  { "codigo": "C310", "descricao": "Neoplasia maligna do seio maxilar" },
  { "codigo": "C311", "descricao": "Neoplasia maligna do seio etmoidal" },
  { "codigo": "C312", "descricao": "Neoplasia maligna do seio frontal" },
  { "codigo": "C313", "descricao": "Neoplasia maligna do seio esfenoidal" },
  {
    "codigo": "C318",
    "descricao": "Neoplasia maligna dos seios da face com lesão invasiva"
  },
  {
    "codigo": "C319",
    "descricao": "Neoplasia maligna do seio da face, não especificado"
  },
  { "codigo": "C320", "descricao": "Neoplasia maligna da glote" },
  {
    "codigo": "C321",
    "descricao": "Neoplasia maligna da região supraglótica"
  },
  {
    "codigo": "C322",
    "descricao": "Neoplasia maligna da região subglótica"
  },
  {
    "codigo": "C323",
    "descricao": "Neoplasia maligna das cartilagens da laringe"
  },
  {
    "codigo": "C328",
    "descricao": "Neoplasia maligna da laringe com lesão invasiva"
  },
  {
    "codigo": "C329",
    "descricao": "Neoplasia maligna da laringe, não especificada"
  },
  { "codigo": "C33", "descricao": "Neoplasia maligna da traquéia" },
  {
    "codigo": "C340",
    "descricao": "Neoplasia maligna do brônquio principal"
  },
  {
    "codigo": "C341",
    "descricao": "Neoplasia maligna do lobo superior, brônquio ou pulmão"
  },
  {
    "codigo": "C342",
    "descricao": "Neoplasia maligna do lobo médio, brônquio ou pulmão"
  },
  {
    "codigo": "C343",
    "descricao": "Neoplasia maligna do lobo inferior, brônquio ou pulmão"
  },
  {
    "codigo": "C348",
    "descricao": "Neoplasia maligna dos brônquios e dos pulmões com lesão invasiva"
  },
  {
    "codigo": "C349",
    "descricao": "Neoplasia maligna dos brônquios ou pulmões, não especificado"
  },
  { "codigo": "C37", "descricao": "Neoplasia maligna do timo" },
  { "codigo": "C380", "descricao": "Neoplasia maligna do coração" },
  { "codigo": "C381", "descricao": "Neoplasia maligna do mediastino anterior" },
  {
    "codigo": "C382",
    "descricao": "Neoplasia maligna do mediastino posterior"
  },
  {
    "codigo": "C383",
    "descricao": "Neoplasia maligna do mediastino, porção não especificada"
  },
  { "codigo": "C384", "descricao": "Neoplasia maligna da pleura" },
  {
    "codigo": "C388",
    "descricao": "Neoplasia maligna do coração, mediastino e pleura com lesão invasiva"
  },
  {
    "codigo": "C390",
    "descricao": "Neoplasia maligna do trato respiratório superior, porção não especificada"
  },
  {
    "codigo": "C398",
    "descricao": "Neoplasia maligna do aparelho respiratório e dos órgãos intratorácicos com lesão invasiva"
  },
  {
    "codigo": "C399",
    "descricao": "Neoplasia maligna de localizações mal definidas do aparelho respiratório"
  },
  {
    "codigo": "C400",
    "descricao": "Neoplasia maligna da omoplata [escápula] e ossos longos dos membros superiores"
  },
  {
    "codigo": "C401",
    "descricao": "Neoplasia maligna dos ossos curtos dos membros superiores"
  },
  {
    "codigo": "C402",
    "descricao": "Neoplasia maligna dos ossos longos dos membros inferiores"
  },
  {
    "codigo": "C403",
    "descricao": "Neoplasia maligna dos ossos curtos dos membros inferiores"
  },
  {
    "codigo": "C408",
    "descricao": "Neoplasia maligna dos ossos e cartilagens articulares dos membros com lesão invasiva"
  },
  {
    "codigo": "C409",
    "descricao": "Neoplasia maligna dos ossos e cartilagens articulares de membro não especificado"
  },
  {
    "codigo": "C410",
    "descricao": "Neoplasia maligna dos ossos do crânio e da face"
  },
  { "codigo": "C411", "descricao": "Neoplasia maligna da mandíbula" },
  { "codigo": "C412", "descricao": "Neoplasia maligna da coluna vertebral" },
  {
    "codigo": "C413",
    "descricao": "Neoplasia maligna das costelas, esterno e clavícula"
  },
  {
    "codigo": "C414",
    "descricao": "Neoplasia maligna dos ossos da pelve, sacro e cóccix"
  },
  {
    "codigo": "C418",
    "descricao": "Neoplasia maligna dos ossos e das cartilagens articulares com lesão invasiva"
  },
  {
    "codigo": "C419",
    "descricao": "Neoplasia maligna dos ossos e cartilagens articulares, não especificados"
  },
  { "codigo": "C430", "descricao": "Melanoma maligno do lábio" },
  {
    "codigo": "C431",
    "descricao": "Melanoma maligno da pálpebra, incluindo as comissuras palpebrais"
  },
  {
    "codigo": "C432",
    "descricao": "Melanoma maligno da orelha e do conduto auditivo externo"
  },
  {
    "codigo": "C433",
    "descricao": "Melanoma maligno de outras partes e partes não especificadas da face"
  },
  {
    "codigo": "C434",
    "descricao": "Melanoma maligno do couro cabeludo e do pescoço"
  },
  { "codigo": "C435", "descricao": "Melanoma maligno do tronco" },
  {
    "codigo": "C436",
    "descricao": "Melanoma maligno do membro superior, incluindo ombro"
  },
  {
    "codigo": "C437",
    "descricao": "Melanoma maligno do membro inferior, incluindo quadril"
  },
  { "codigo": "C438", "descricao": "Melanoma maligno invasivo da pele" },
  {
    "codigo": "C439",
    "descricao": "Melanoma maligno de pele, não especificado"
  },
  { "codigo": "C440", "descricao": "Neoplasia maligna da pele do lábio" },
  {
    "codigo": "C441",
    "descricao": "Neoplasia maligna da pele da pálpebra, incluindo o canto"
  },
  {
    "codigo": "C442",
    "descricao": "Neoplasia maligna da pele da orelha e do conduto auditivo externo"
  },
  {
    "codigo": "C443",
    "descricao": "Neoplasia maligna da pele de outras partes e de partes não especificadas da face"
  },
  {
    "codigo": "C444",
    "descricao": "Neoplasia maligna da pele do couro cabeludo e do pescoço"
  },
  { "codigo": "C445", "descricao": "Neoplasia maligna da pele do tronco" },
  {
    "codigo": "C446",
    "descricao": "Neoplasia maligna da pele do membro superior, incluindo ombro"
  },
  {
    "codigo": "C447",
    "descricao": "Neoplasia maligna da pele do membro inferior, incluindo quadril"
  },
  {
    "codigo": "C448",
    "descricao": "Neoplasia maligna da pele com lesão invasiva"
  },
  {
    "codigo": "C449",
    "descricao": "Neoplasia maligna da pele, não especificada"
  },
  { "codigo": "C450", "descricao": "Mesotelioma da pleura" },
  { "codigo": "C451", "descricao": "Mesotelioma do peritônio" },
  { "codigo": "C452", "descricao": "Mesotelioma do pericárdio" },
  {
    "codigo": "C457",
    "descricao": "Mesotelioma de outras localizações"
  },
  { "codigo": "C459", "descricao": "Mesotelioma, não especificado" },
  { "codigo": "C460", "descricao": "Sarcoma de Kaposi da pele" },
  { "codigo": "C461", "descricao": "Sarcoma de Kaposi de tecidos moles" },
  { "codigo": "C462", "descricao": "Sarcoma de Kaposi do palato" },
  {
    "codigo": "C463",
    "descricao": "Sarcoma de Kaposi dos gânglios linfáticos"
  },
  {
    "codigo": "C467",
    "descricao": "Sarcoma de Kaposi de outras localizações"
  },
  {
    "codigo": "C468",
    "descricao": "Sarcoma de Kaposi de múltiplos órgãos"
  },
  { "codigo": "C469", "descricao": "Sarcoma de Kaposi, não especificado" },
  {
    "codigo": "C470",
    "descricao": "Neoplasia maligna dos nervos periféricos da cabeça, face e pescoço"
  },
  {
    "codigo": "C471",
    "descricao": "Neoplasia maligna dos nervos periféricos dos membros superiores, incluindo ombro"
  },
  {
    "codigo": "C472",
    "descricao": "Neoplasia maligna dos nervos periféricos dos membros inferiores, incluindo quadril"
  },
  {
    "codigo": "C473",
    "descricao": "Neoplasia maligna dos nervos periféricos do tórax"
  },
  {
    "codigo": "C474",
    "descricao": "Neoplasia maligna dos nervos periféricos do abdome"
  },
  {
    "codigo": "C475",
    "descricao": "Neoplasia maligna dos nervos periféricos da pelve"
  },
  {
    "codigo": "C476",
    "descricao": "Neoplasia maligna dos nervos periféricos do tronco"
  },
  {
    "codigo": "C478",
    "descricao": "Neoplasia maligna dos nervos periféricos e do sistema nervoso autônomo com lesão invasiva"
  },
  {
    "codigo": "C479",
    "descricao": "Neoplasia maligna dos nervos periféricos e sistema nervoso autônomo, não especificados"
  },
  { "codigo": "C480", "descricao": "Neoplasia maligna do retroperitônio" },
  {
    "codigo": "C481",
    "descricao": "Neoplasia maligna de partes especificadas do peritônio"
  },
  { "codigo": "C482", "descricao": "Neoplasia maligna do peritônio" },
  {
    "codigo": "C488",
    "descricao": "Neoplasia maligna dos tecidos moles do retroperitônio e do peritônio com lesão invasiva"
  },
  {
    "codigo": "C490",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles da cabeça, face e pescoço"
  },
  {
    "codigo": "C491",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles dos membros superiores, incluindo ombro"
  },
  {
    "codigo": "C492",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles dos membros inferiores, incluindo quadril"
  },
  {
    "codigo": "C493",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles do tórax"
  },
  {
    "codigo": "C494",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles do abdome"
  },
  {
    "codigo": "C495",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles da pelve"
  },
  {
    "codigo": "C496",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles do tronco"
  },
  {
    "codigo": "C498",
    "descricao": "Neoplasia maligna do tecido conjuntivo e dos tecidos moles com lesão invasiva"
  },
  {
    "codigo": "C499",
    "descricao": "Neoplasia maligna do tecido conjuntivo e tecidos moles, não especificados"
  },
  {
    "codigo": "C500",
    "descricao": "Neoplasia maligna do mamilo e aréola"
  },
  {
    "codigo": "C501",
    "descricao": "Neoplasia maligna da porção central da mama"
  },
  {
    "codigo": "C502",
    "descricao": "Neoplasia maligna do quadrante superior interno da mama"
  },
  {
    "codigo": "C503",
    "descricao": "Neoplasia maligna do quadrante inferior interno da mama"
  },
  {
    "codigo": "C504",
    "descricao": "Neoplasia maligna do quadrante superior externo da mama"
  },
  {
    "codigo": "C505",
    "descricao": "Neoplasia maligna do quadrante inferior externo da mama"
  },
  {
    "codigo": "C506",
    "descricao": "Neoplasia maligna da porção axilar da mama"
  },
  {
    "codigo": "C508",
    "descricao": "Neoplasia maligna da mama com lesão invasiva"
  },
  {
    "codigo": "C509",
    "descricao": "Neoplasia maligna da mama, não especificada"
  },
  {
    "codigo": "C510",
    "descricao": "Neoplasia maligna dos grandes lábios"
  },
  {
    "codigo": "C511",
    "descricao": "Neoplasia maligna dos pequenos lábios"
  },
  { "codigo": "C512", "descricao": "Neoplasia maligna do clitóris" },
  {
    "codigo": "C518",
    "descricao": "Neoplasia maligna da vulva com lesão invasiva"
  },
  {
    "codigo": "C519",
    "descricao": "Neoplasia maligna da vulva, não especificada"
  },
  { "codigo": "C52", "descricao": "Neoplasia maligna da vagina" },
  { "codigo": "C530", "descricao": "Neoplasia maligna do endocérvix" },
  { "codigo": "C531", "descricao": "Neoplasia maligna do exocérvix" },
  {
    "codigo": "C538",
    "descricao": "Neoplasia maligna do colo do útero com lesão invasiva"
  },
  {
    "codigo": "C539",
    "descricao": "Neoplasia maligna do colo do útero, não especificado"
  },
  { "codigo": "C540", "descricao": "Neoplasia maligna do istmo do útero" },
  { "codigo": "C541", "descricao": "Neoplasia maligna do endométrio" },
  { "codigo": "C542", "descricao": "Neoplasia maligna do miométrio" },
  { "codigo": "C543", "descricao": "Neoplasia maligna do fundo do útero" },
  {
    "codigo": "C548",
    "descricao": "Neoplasia maligna do corpo do útero com lesão invasiva"
  },
  {
    "codigo": "C549",
    "descricao": "Neoplasia maligna do corpo do útero, não especificado"
  },
  {
    "codigo": "C55",
    "descricao": "Neoplasia maligna do útero, porção não especificada"
  },
  { "codigo": "C56", "descricao": "Neoplasia maligna do ovário" },
  {
    "codigo": "C570",
    "descricao": "Neoplasia maligna da trompa de Falópio"
  },
  { "codigo": "C571", "descricao": "Neoplasia maligna do ligamento largo" },
  { "codigo": "C572", "descricao": "Neoplasia maligna do ligamento redondo" },
  { "codigo": "C573", "descricao": "Neoplasia maligna do paramétrio" },
  { "codigo": "C574", "descricao": "Neoplasia maligna dos anexos uterinos" },
  {
    "codigo": "C577",
    "descricao": "Neoplasia maligna de outras partes especificadas dos órgãos genitais femininos"
  },
  {
    "codigo": "C578",
    "descricao": "Neoplasia maligna dos órgãos genitais femininos com lesão invasiva"
  },
  {
    "codigo": "C579",
    "descricao": "Neoplasia maligna de órgão genital feminino, não especificado"
  },
  { "codigo": "C58", "descricao": "Neoplasia maligna da placenta" },
  { "codigo": "C600", "descricao": "Neoplasia maligna do prepúcio" },
  { "codigo": "C601", "descricao": "Neoplasia maligna da glande" },
  { "codigo": "C602", "descricao": "Neoplasia maligna do corpo do pênis" },
  {
    "codigo": "C608",
    "descricao": "Neoplasia maligna do pênis com lesão invasiva"
  },
  {
    "codigo": "C609",
    "descricao": "Neoplasia maligna do pênis, não especificado"
  },
  { "codigo": "C61", "descricao": "Neoplasia maligna da próstata" },
  {
    "codigo": "C620",
    "descricao": "Neoplasia maligna do testículo criptorquídico"
  },
  {
    "codigo": "C621",
    "descricao": "Neoplasia maligna do testículo tópico"
  },
  {
    "codigo": "C629",
    "descricao": "Neoplasia maligna do testículo, sem outras especificações"
  },
  { "codigo": "C630", "descricao": "Neoplasia maligna do epidídimo" },
  {
    "codigo": "C631",
    "descricao": "Neoplasia maligna do cordão espermático"
  },
  { "codigo": "C632", "descricao": "Neoplasia maligna do escroto" },
  {
    "codigo": "C637",
    "descricao": "Neoplasia maligna de outros órgãos genitais masculinos especificados"
  },
  {
    "codigo": "C638",
    "descricao": "Neoplasia maligna dos órgãos genitais masculinos com lesão invasiva"
  },
  {
    "codigo": "C639",
    "descricao": "Neoplasia maligna de órgão genital masculino, não especificado"
  },
  {
    "codigo": "C64",
    "descricao": "Neoplasia maligna do rim, exceto pelve renal"
  },
  { "codigo": "C65", "descricao": "Neoplasia maligna da pelve renal" },
  { "codigo": "C66", "descricao": "Neoplasia maligna dos ureteres" },
  {
    "codigo": "C670",
    "descricao": "Neoplasia maligna do trígono da bexiga"
  },
  {
    "codigo": "C671",
    "descricao": "Neoplasia maligna da cúpula da bexiga"
  },
  {
    "codigo": "C672",
    "descricao": "Neoplasia maligna da parede lateral da bexiga"
  },
  {
    "codigo": "C673",
    "descricao": "Neoplasia maligna da parede anterior da bexiga"
  },
  {
    "codigo": "C674",
    "descricao": "Neoplasia maligna da parede posterior da bexiga"
  },
  { "codigo": "C675", "descricao": "Neoplasia maligna do colo da bexiga" },
  {
    "codigo": "C676",
    "descricao": "Neoplasia maligna do orifício uretérico"
  },
  { "codigo": "C677", "descricao": "Neoplasia maligna do úraco" },
  {
    "codigo": "C678",
    "descricao": "Neoplasia maligna da bexiga com lesão invasiva"
  },
  {
    "codigo": "C679",
    "descricao": "Neoplasia maligna da bexiga, sem outra especificações"
  },
  { "codigo": "C680", "descricao": "Neoplasia maligna da uretra" },
  {
    "codigo": "C681",
    "descricao": "Neoplasia maligna da glândula parauretral"
  },
  {
    "codigo": "C688",
    "descricao": "Neoplasia maligna dos órgãos urinários com lesão invasiva"
  },
  {
    "codigo": "C689",
    "descricao": "Neoplasia maligna de órgão urinário, não especificado"
  },
  { "codigo": "C690", "descricao": "Neoplasia maligna da conjuntiva" },
  { "codigo": "C691", "descricao": "Neoplasia maligna da córnea" },
  { "codigo": "C692", "descricao": "Neoplasia maligna da retina" },
  { "codigo": "C693", "descricao": "Neoplasia maligna da coróide" },
  { "codigo": "C694", "descricao": "Neoplasia maligna do corpo ciliar" },
  {
    "codigo": "C695",
    "descricao": "Neoplasia maligna da glândula e canal lacrimal"
  },
  { "codigo": "C696", "descricao": "Neoplasia maligna da órbita" },
  {
    "codigo": "C698",
    "descricao": "Neoplasia maligna do olho e anexos com lesão invasiva"
  },
  {
    "codigo": "C699",
    "descricao": "Neoplasia maligna do olho, não especificado"
  },
  { "codigo": "C700", "descricao": "Neoplasia maligna das meninges cerebrais" },
  { "codigo": "C701", "descricao": "Neoplasia maligna das meninges espinhais" },
  {
    "codigo": "C709",
    "descricao": "Neoplasia maligna da meninge, não especificada"
  },
  {
    "codigo": "C710",
    "descricao": "Neoplasia maligna do cérebro, exceto lobos e ventrículos"
  },
  { "codigo": "C711", "descricao": "Neoplasia maligna do lobo frontal" },
  { "codigo": "C712", "descricao": "Neoplasia maligna do lobo temporal" },
  { "codigo": "C713", "descricao": "Neoplasia maligna do lobo parietal" },
  { "codigo": "C714", "descricao": "Neoplasia maligna do lobo occipital" },
  {
    "codigo": "C715",
    "descricao": "Neoplasia maligna do ventrículo cerebral"
  },
  { "codigo": "C716", "descricao": "Neoplasia maligna do cerebelo" },
  { "codigo": "C717", "descricao": "Neoplasia maligna do tronco cerebral" },
  {
    "codigo": "C718",
    "descricao": "Neoplasia maligna do encéfalo com lesão invasiva"
  },
  {
    "codigo": "C719",
    "descricao": "Neoplasia maligna do encéfalo, não especificado"
  },
  { "codigo": "C720", "descricao": "Neoplasia maligna da medula espinhal" },
  { "codigo": "C721", "descricao": "Neoplasia maligna da cauda eqüina" },
  { "codigo": "C722", "descricao": "Neoplasia maligna do nervo olfativo" },
  { "codigo": "C723", "descricao": "Neoplasia maligna do nervo óptico" },
  { "codigo": "C724", "descricao": "Neoplasia maligna do nervo acústico" },
  {
    "codigo": "C725",
    "descricao": "Neoplasia maligna de outros nervos cranianos e os não especificados"
  },
  {
    "codigo": "C728",
    "descricao": "Neoplasia maligna do encéfalo e de outras partes do sistema nervoso central com lesão invasiva"
  },
  {
    "codigo": "C729",
    "descricao": "Neoplasia maligna do sistema nervoso central, não especificado"
  },
  {
    "codigo": "C73",
    "descricao": "Neoplasia maligna da glândula tireóide"
  },
  {
    "codigo": "C740",
    "descricao": "Neoplasia maligna do córtex da supra-renal"
  },
  {
    "codigo": "C741",
    "descricao": "Neoplasia maligna da medula da supra-renal"
  },
  {
    "codigo": "C749",
    "descricao": "Neoplasia maligna da glândula supra-renal, não especificada"
  },
  {
    "codigo": "C750",
    "descricao": "Neoplasia maligna da glândula paratireóide"
  },
  {
    "codigo": "C751",
    "descricao": "Neoplasia maligna da glândula hipófise (pituitária)"
  },
  {
    "codigo": "C752",
    "descricao": "Neoplasia maligna do conduto craniofaríngeo"
  },
  {
    "codigo": "C753",
    "descricao": "Neoplasia maligna da glândula pineal"
  },
  {
    "codigo": "C754",
    "descricao": "Neoplasia maligna do corpo carotídeo"
  },
  {
    "codigo": "C755",
    "descricao": "Neoplasia maligna do corpo aórtico e outros paragânglios"
  },
  {
    "codigo": "C758",
    "descricao": "Neoplasia maligna com comprometimento pluriglandular, sem outra especificação"
  },
  {
    "codigo": "C759",
    "descricao": "Neoplasia maligna de glândula endócrina, não especificada"
  },
  {
    "codigo": "C760",
    "descricao": "Neoplasia maligna da cabeça, face e pescoço"
  },
  { "codigo": "C761", "descricao": "Neoplasia maligna do tórax" },
  { "codigo": "C762", "descricao": "Neoplasia maligna do abdome" },
  { "codigo": "C763", "descricao": "Neoplasia maligna da pelve" },
  { "codigo": "C764", "descricao": "Neoplasia maligna do membro superior" },
  { "codigo": "C765", "descricao": "Neoplasia maligna do membro inferior" },
  {
    "codigo": "C767",
    "descricao": "Neoplasia maligna de outras localizações mal definidas"
  },
  {
    "codigo": "C768",
    "descricao": "Neoplasia maligna de outras localizações e das mal definidas com lesão invasiva"
  },
  {
    "codigo": "C770",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos da cabeça, face e pescoço"
  },
  {
    "codigo": "C771",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos intratorácicos"
  },
  {
    "codigo": "C772",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos intra-abdominais"
  },
  {
    "codigo": "C773",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos axilares e dos membros superiores"
  },
  {
    "codigo": "C774",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos inguinais e dos membros inferiores"
  },
  {
    "codigo": "C775",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos intrapélvicos"
  },
  {
    "codigo": "C778",
    "descricao": "Neoplasia maligna secundária e não especificada dos gânglios linfáticos de múltiplas regiões"
  },
  {
    "codigo": "C779",
    "descricao": "Neoplasia maligna secundária e não especificada de gânglio linfático, não especificado"
  },
  {
    "codigo": "C780",
    "descricao": "Neoplasia maligna secundária dos pulmões"
  },
  {
    "codigo": "C781",
    "descricao": "Neoplasia maligna secundária do mediastino"
  },
  {
    "codigo": "C782",
    "descricao": "Neoplasia maligna secundária da pleura"
  },
  {
    "codigo": "C783",
    "descricao": "Neoplasia maligna secundária de outros órgãos respiratórios e não especificados"
  },
  {
    "codigo": "C784",
    "descricao": "Neoplasia maligna secundária do intestino delgado"
  },
  {
    "codigo": "C785",
    "descricao": "Neoplasia maligna secundária do intestino grosso e do reto"
  },
  {
    "codigo": "C786",
    "descricao": "Neoplasia maligna secundária do retroperitônio e do peritônio"
  },
  {
    "codigo": "C787",
    "descricao": "Neoplasia maligna secundária do fígado"
  },
  {
    "codigo": "C788",
    "descricao": "Neoplasia maligna secundária de outros órgãos digestivos e não especificados"
  },
  {
    "codigo": "C790",
    "descricao": "Neoplasia maligna secundária do rim e da pelve renal"
  },
  {
    "codigo": "C791",
    "descricao": "Neoplasia maligna secundária da bexiga, de outro órgão urinário e não especificado"
  },
  {
    "codigo": "C792",
    "descricao": "Neoplasia maligna secundária da pele"
  },
  {
    "codigo": "C793",
    "descricao": "Neoplasia maligna secundária do encéfalo e das meninges cerebrais"
  },
  {
    "codigo": "C794",
    "descricao": "Neoplasia maligna secundária de outras partes do sistema nervoso e não especificadas"
  },
  {
    "codigo": "C795",
    "descricao": "Neoplasia maligna secundária dos ossos e da medula óssea"
  },
  {
    "codigo": "C796",
    "descricao": "Neoplasia maligna secundária do ovário"
  },
  {
    "codigo": "C797",
    "descricao": "Neoplasia maligna secundária das glândulas supra-renais (adrenais)"
  },
  {
    "codigo": "C798",
    "descricao": "Neoplasia maligna secundária de outra localização especificada"
  },
  {
    "codigo": "C80",
    "descricao": "Neoplasia maligna, sem especificação de localização"
  },
  {
    "codigo": "C810",
    "descricao": "Doença de Hodgkin, predominância linfocítica"
  },
  {
    "codigo": "C811",
    "descricao": "Doença de Hodgkin, esclerose nodular"
  },
  {
    "codigo": "C812",
    "descricao": "Doença de Hodgkin, celularidade mista"
  },
  {
    "codigo": "C813",
    "descricao": "Doença de Hodgkin, depleção linfocítica"
  },
  { "codigo": "C817", "descricao": "Outra forma da doença de Hodgkin" },
  {
    "codigo": "C819",
    "descricao": "Doença de Hodgkin, não especificada"
  },
  {
    "codigo": "C820",
    "descricao": "Linfoma não-Hodgkin, pequenas células clivadas, folicular"
  },
  {
    "codigo": "C821",
    "descricao": "Linfoma não-Hodgkin, misto, de pequenas e grandes células clivadas, folicular"
  },
  {
    "codigo": "C822",
    "descricao": "Linfoma não-Hodgkin, grandes células, folicular"
  },
  {
    "codigo": "C827",
    "descricao": "Outros tipos de linfoma não-Hodgkin, folicular"
  },
  {
    "codigo": "C829",
    "descricao": "Linfoma não-Hodgkin, folicular, não especificado"
  },
  {
    "codigo": "C830",
    "descricao": "Linfoma não-Hodgkin difuso, pequenas células (difuso)"
  },
  {
    "codigo": "C831",
    "descricao": "Linfoma não-Hodgkin difuso, pequenas células clivadas (difuso)"
  },
  {
    "codigo": "C832",
    "descricao": "Linfoma não-Hodgkin difuso, misto, de pequenas e grandes células (difuso)"
  },
  {
    "codigo": "C833",
    "descricao": "Linfoma não-Hodgkin difuso, grandes células (difuso)"
  },
  {
    "codigo": "C834",
    "descricao": "Linfoma não-Hodgkin difuso, imunoblástico (difuso)"
  },
  {
    "codigo": "C835",
    "descricao": "Linfoma não-Hodgkin difuso, linfoblástico (difuso)"
  },
  {
    "codigo": "C836",
    "descricao": "Linfoma não-Hodgkin difuso, indiferenciado (difuso)"
  },
  { "codigo": "C837", "descricao": "Tumor de Burkitt" },
  {
    "codigo": "C838",
    "descricao": "Outros tipos de linfoma não-Hodgkin difuso"
  },
  {
    "codigo": "C839",
    "descricao": "Linfoma não-Hodgkin difuso, não especificado"
  },
  { "codigo": "C840", "descricao": "Micose fungóide" },
  { "codigo": "C841", "descricao": "Doença de Sézary" },
  { "codigo": "C842", "descricao": "Linfoma da zona T" },
  { "codigo": "C843", "descricao": "Linfoma linfoepitelióide" },
  {
    "codigo": "C844",
    "descricao": "Linfoma de células T, periférico"
  },
  {
    "codigo": "C845",
    "descricao": "Outros linfomas de células T e os não especificados"
  },
  { "codigo": "C850", "descricao": "Linfossarcoma" },
  {
    "codigo": "C851",
    "descricao": "Linfoma de células B, não especificado"
  },
  {
    "codigo": "C857",
    "descricao": "Outros tipos especificados de linfoma não-Hodgkin"
  },
  {
    "codigo": "C859",
    "descricao": "Linfoma não-Hodgkin de tipo não especificado"
  },
  { "codigo": "C880", "descricao": "Macroglobulinemia de Waldenström" },
  { "codigo": "C881", "descricao": "Doença de cadeia pesada alfa" },
  { "codigo": "C882", "descricao": "Doença de cadeia pesada gama" },
  {
    "codigo": "C883",
    "descricao": "Doença imunoproliferativa do intestino delgado"
  },
  {
    "codigo": "C887",
    "descricao": "Outras doenças imunoproliferativas malignas"
  },
  {
    "codigo": "C889",
    "descricao": "Doença imunoproliferativa maligna, não especificada"
  },
  { "codigo": "C900", "descricao": "Mieloma múltiplo" },
  { "codigo": "C901", "descricao": "Leucemia plasmocitária" },
  { "codigo": "C902", "descricao": "Plasmocitoma extramedular" },
  { "codigo": "C910", "descricao": "Leucemia linfoblástica aguda" },
  { "codigo": "C911", "descricao": "Leucemia linfocítica crônica" },
  { "codigo": "C912", "descricao": "Leucemia linfocítica subaguda" },
  { "codigo": "C913", "descricao": "Leucemia pró-linfocítica" },
  { "codigo": "C914", "descricao": "Leucemia de células pilosas" },
  { "codigo": "C915", "descricao": "Leucemia de células T do adulto" },
  { "codigo": "C917", "descricao": "Outras leucemias linfóides" },
  {
    "codigo": "C919",
    "descricao": "Leucemia linfóide, não especificada"
  },
  { "codigo": "C920", "descricao": "Leucemia mielóide aguda" },
  { "codigo": "C921", "descricao": "Leucemia mielóide crônica" },
  { "codigo": "C922", "descricao": "Leucemia mielóide subaguda" },
  { "codigo": "C923", "descricao": "Sarcoma mielóide" },
  { "codigo": "C924", "descricao": "Leucemia pró-mielocítica aguda" },
  { "codigo": "C925", "descricao": "Leucemia mielomonocítica aguda" },
  { "codigo": "C927", "descricao": "Outras leucemias mielóides" },
  {
    "codigo": "C929",
    "descricao": "Leucemia mielóide, não especificada"
  },
  { "codigo": "C930", "descricao": "Leucemia monocítica aguda" },
  { "codigo": "C931", "descricao": "Leucemia monocítica crônica" },
  { "codigo": "C932", "descricao": "Leucemia monocítica subaguda" },
  { "codigo": "C937", "descricao": "Outras leucemias monocíticas" },
  {
    "codigo": "C939",
    "descricao": "Leucemia monocítica, não especificada"
  },
  { "codigo": "C940", "descricao": "Eritremia e eritroleucemia agudas" },
  { "codigo": "C941", "descricao": "Eritremia crônica" },
  { "codigo": "C942", "descricao": "Leucemia megacarioblástica aguda" },
  { "codigo": "C943", "descricao": "Leucemia de mastócitos" },
  { "codigo": "C944", "descricao": "Panmielose aguda" },
  { "codigo": "C945", "descricao": "Mielofibrose aguda" },
  { "codigo": "C947", "descricao": "Outras leucemias especificadas" },
  {
    "codigo": "C950",
    "descricao": "Leucemia aguda de tipo celular não especificado"
  },
  {
    "codigo": "C951",
    "descricao": "Leucemia crônica de tipo celular não especificado"
  },
  {
    "codigo": "C952",
    "descricao": "Leucemia subaguda de tipo celular não especificado"
  },
  {
    "codigo": "C957",
    "descricao": "Outras leucemias de tipo celular não especificado"
  },
  { "codigo": "C959", "descricao": "Leucemia não especificada" },
  { "codigo": "C960", "descricao": "Doença de Letterer-Siwe" },
  { "codigo": "C961", "descricao": "Histiocitose maligna" },
  { "codigo": "C962", "descricao": "Tumor maligno de mastócitos" },
  { "codigo": "C963", "descricao": "Linfoma histiocítico verdadeiro" },
  {
    "codigo": "C967",
    "descricao": "Outras neoplasias malignas especificadas dos tecidos linfático, hematopoético e tecidos correlatos"
  },
  {
    "codigo": "C969",
    "descricao": "Neoplasia maligna dos tecidos linfático, hematopoético e tecidos correlatos, não especificada"
  },
  {
    "codigo": "C97",
    "descricao": "Neoplasias malignas de localizações múltiplas independentes (primárias)"
  },
  {
    "codigo": "D000",
    "descricao": "Carcinoma in situ dos lábios, cavidade oral e faringe"
  },
  { "codigo": "D001", "descricao": "Carcinoma in situ do esôfago" },
  { "codigo": "D002", "descricao": "Carcinoma in situ do estômago" },
  { "codigo": "D010", "descricao": "Carcinoma in situ do cólon" },
  {
    "codigo": "D011",
    "descricao": "Carcinoma in situ da junção retossigmóide"
  },
  { "codigo": "D012", "descricao": "Carcinoma in situ do reto" },
  {
    "codigo": "D013",
    "descricao": "Carcinoma in situ do ânus e canal anal"
  },
  {
    "codigo": "D014",
    "descricao": "Carcinoma in situ de outras partes do intestino e as não especificadas"
  },
  {
    "codigo": "D015",
    "descricao": "Carcinoma in situ do fígado, vesícula biliar e vias biliares"
  },
  {
    "codigo": "D017",
    "descricao": "Carcinoma in situ de outros órgãos especificados do aparelho digestivo"
  },
  {
    "codigo": "D019",
    "descricao": "Carcinoma in situ de órgãos digestivos, não especificado"
  },
  { "codigo": "D020", "descricao": "Carcinoma in situ da laringe" },
  { "codigo": "D021", "descricao": "Carcinoma in situ da traquéia" },
  {
    "codigo": "D022",
    "descricao": "Carcinoma in situ dos brônquios e pulmões"
  },
  {
    "codigo": "D023",
    "descricao": "Carcinoma in situ de outras partes do aparelho respiratório"
  },
  {
    "codigo": "D029",
    "descricao": "Carcinoma in situ do aparelho respiratório, não especificado"
  },
  { "codigo": "D030", "descricao": "Melanoma in situ do lábio" },
  {
    "codigo": "D031",
    "descricao": "Melanoma in situ da pálpebra, incluindo o canto"
  },
  {
    "codigo": "D032",
    "descricao": "Melanoma in situ da orelha e do conduto auditivo externo"
  },
  {
    "codigo": "D033",
    "descricao": "Melanoma in situ de outras partes, e de partes não especificadas da face"
  },
  {
    "codigo": "D034",
    "descricao": "Melanoma in situ do couro cabeludo e do pescoço"
  },
  { "codigo": "D035", "descricao": "Melanoma in situ do tronco" },
  {
    "codigo": "D036",
    "descricao": "Melanoma in situ dos membros superiores, incluindo ombro"
  },
  {
    "codigo": "D037",
    "descricao": "Melanoma in situ dos membros inferiores, incluindo quadril"
  },
  {
    "codigo": "D038",
    "descricao": "Melanoma in situ de outras localizações"
  },
  { "codigo": "D039", "descricao": "Melanoma in situ, não especificado" },
  { "codigo": "D040", "descricao": "Carcinoma in situ da pele do lábio" },
  {
    "codigo": "D041",
    "descricao": "Carcinoma in situ da pele da pálpebra, incluindo o canto"
  },
  {
    "codigo": "D042",
    "descricao": "Carcinoma in situ da pele da orelha e do conduto auditivo externo"
  },
  {
    "codigo": "D043",
    "descricao": "Carcinoma in situ da pele de outras partes e de partes não especificadas da face"
  },
  {
    "codigo": "D044",
    "descricao": "Carcinoma in situ da pele do couro cabeludo e do pescoço"
  },
  { "codigo": "D045", "descricao": "Carcinoma in situ da pele do tronco" },
  {
    "codigo": "D046",
    "descricao": "Carcinoma in situ da pele dos membros superiores, incluindo ombro"
  },
  {
    "codigo": "D047",
    "descricao": "Carcinoma in situ da pele dos membros inferiores, incluindo quadril"
  },
  {
    "codigo": "D048",
    "descricao": "Carcinoma in situ da pele de outras localizações"
  },
  {
    "codigo": "D049",
    "descricao": "Carcinoma in situ da pele, não especificada"
  },
  { "codigo": "D050", "descricao": "Carcinoma lobular in situ" },
  { "codigo": "D051", "descricao": "Carcinoma intraductal in situ" },
  { "codigo": "D057", "descricao": "Outros carcinomas in situ" },
  {
    "codigo": "D059",
    "descricao": "Carcinoma in situ da mama, não especificado"
  },
  { "codigo": "D060", "descricao": "Carcinoma in situ do endocérvix" },
  { "codigo": "D061", "descricao": "Carcinoma in situ do exocérvix" },
  {
    "codigo": "D067",
    "descricao": "Carcinoma in situ de outras partes do colo do útero"
  },
  {
    "codigo": "D069",
    "descricao": "Carcinoma in situ do colo do útero, não especificado"
  },
  { "codigo": "D070", "descricao": "Carcinoma in situ do endométrio" },
  { "codigo": "D071", "descricao": "Carcinoma in situ da vulva" },
  { "codigo": "D072", "descricao": "Carcinoma in situ da vagina" },
  {
    "codigo": "D073",
    "descricao": "Carcinoma in situ de outros órgãos genitais femininos e os não especificados"
  },
  { "codigo": "D074", "descricao": "Carcinoma in situ do pênis" },
  { "codigo": "D075", "descricao": "Carcinoma in situ da próstata" },
  {
    "codigo": "D076",
    "descricao": "Carcinoma in situ de outros órgãos genitais masculinos e os não especificados"
  },
  { "codigo": "D090", "descricao": "Carcinoma in situ da bexiga" },
  {
    "codigo": "D091",
    "descricao": "Carcinoma in situ de outros órgãos urinários e os não especificados"
  },
  { "codigo": "D092", "descricao": "Carcinoma in situ do olho" },
  {
    "codigo": "D093",
    "descricao": "Carcinoma in situ da tireóide e de outras glândulas endócrinas"
  },
  {
    "codigo": "D097",
    "descricao": "Carcinoma in situ de outras localizações especificadas"
  },
  { "codigo": "D099", "descricao": "Carcinoma in situ, não especificado" },
  { "codigo": "D100", "descricao": "Neoplasia benigna dos lábios" },
  { "codigo": "D101", "descricao": "Neoplasia benigna da língua" },
  { "codigo": "D102", "descricao": "Neoplasia benigna do assoalho da boca" },
  {
    "codigo": "D103",
    "descricao": "Neoplasia benigna de outras partes da boca e as não especificadas"
  },
  { "codigo": "D104", "descricao": "Neoplasia benigna da amígdala" },
  {
    "codigo": "D105",
    "descricao": "Neoplasia benigna de outras partes da orofaringe"
  },
  { "codigo": "D106", "descricao": "Neoplasia benigna da nasofaringe" },
  { "codigo": "D107", "descricao": "Neoplasia benigna da hipofaringe" },
  {
    "codigo": "D109",
    "descricao": "Neoplasia benigna da faringe, não especificada"
  },
  {
    "codigo": "D110",
    "descricao": "Neoplasia benigna da glândula parótida"
  },
  {
    "codigo": "D117",
    "descricao": "Neoplasia benigna de outras glândulas salivares maiores"
  },
  {
    "codigo": "D119",
    "descricao": "Neoplasia benigna da glândula salivar maior, não especificada"
  },
  { "codigo": "D120", "descricao": "Neoplasia benigna do ceco" },
  {
    "codigo": "D121",
    "descricao": "Neoplasia benigna do apêndice (vermiforme)"
  },
  {
    "codigo": "D122",
    "descricao": "Neoplasia benigna do cólon ascendente"
  },
  {
    "codigo": "D123",
    "descricao": "Neoplasia benigna do cólon transverso"
  },
  {
    "codigo": "D124",
    "descricao": "Neoplasia benigna do cólon descendente"
  },
  {
    "codigo": "D125",
    "descricao": "Neoplasia benigna do cólon sigmóide"
  },
  {
    "codigo": "D126",
    "descricao": "Neoplasia benigna do cólon, não especificada"
  },
  {
    "codigo": "D127",
    "descricao": "Neoplasia benigna da junção retossigmóide"
  },
  { "codigo": "D128", "descricao": "Neoplasia benigna do reto" },
  {
    "codigo": "D129",
    "descricao": "Neoplasia benigna do canal anal e ânus"
  },
  { "codigo": "D130", "descricao": "Neoplasia benigna do esôfago" },
  { "codigo": "D131", "descricao": "Neoplasia benigna do estômago" },
  { "codigo": "D132", "descricao": "Neoplasia benigna do duodeno" },
  {
    "codigo": "D133",
    "descricao": "Neoplasia benigna de outras partes e partes não especificadas do intestino delgado"
  },
  { "codigo": "D134", "descricao": "Neoplasia benigna do fígado" },
  {
    "codigo": "D135",
    "descricao": "Neoplasia benigna das vias biliares extra-hepáticas"
  },
  { "codigo": "D136", "descricao": "Neoplasia benigna do pâncreas" },
  {
    "codigo": "D137",
    "descricao": "Neoplasia benigna do pâncreas endócrino"
  },
  {
    "codigo": "D139",
    "descricao": "Neoplasia benigna de localizações mal definidas do aparelho digestivo"
  },
  {
    "codigo": "D140",
    "descricao": "Neoplasia benigna do ouvido médio, cavidade nasal e seios paranasais"
  },
  { "codigo": "D141", "descricao": "Neoplasia benigna da laringe" },
  { "codigo": "D142", "descricao": "Neoplasia benigna da traquéia" },
  {
    "codigo": "D143",
    "descricao": "Neoplasia benigna dos brônquios e pulmão"
  },
  {
    "codigo": "D144",
    "descricao": "Neoplasia benigna do aparelho respiratório, não especificado"
  },
  { "codigo": "D150", "descricao": "Neoplasia benigna do timo" },
  { "codigo": "D151", "descricao": "Neoplasia benigna do coração" },
  { "codigo": "D152", "descricao": "Neoplasia benigna do mediastino" },
  {
    "codigo": "D157",
    "descricao": "Neoplasia benigna de outros órgãos intratorácicos especificados"
  },
  {
    "codigo": "D159",
    "descricao": "Neoplasia benigna de órgão intratorácico, não especificado"
  },
  {
    "codigo": "D160",
    "descricao": "Neoplasia benigna da omoplata [escápula] e ossos longos dos membros superiores"
  },
  {
    "codigo": "D161",
    "descricao": "Neoplasia benigna dos ossos curtos dos membros superiores"
  },
  {
    "codigo": "D162",
    "descricao": "Neoplasia benigna dos ossos longos dos membros inferiores"
  },
  {
    "codigo": "D163",
    "descricao": "Neoplasia benigna dos ossos curtos dos membros inferiores"
  },
  {
    "codigo": "D164",
    "descricao": "Neoplasia benigna dos ossos do crânio e da face"
  },
  {
    "codigo": "D165",
    "descricao": "Neoplasia benigna do osso da mandíbula"
  },
  { "codigo": "D166", "descricao": "Neoplasia benigna da coluna vertebral" },
  {
    "codigo": "D167",
    "descricao": "Neoplasia benigna das costelas, do esterno e da clavícula"
  },
  {
    "codigo": "D168",
    "descricao": "Neoplasia benigna dos ossos pélvicos, sacro e cóccix"
  },
  {
    "codigo": "D169",
    "descricao": "Neoplasia benigna do osso e cartilagem articular, não especificado"
  },
  {
    "codigo": "D170",
    "descricao": "Neoplasia lipomatosa benigna da pele e do tecido subcutâneo da cabeça, face e pescoço"
  },
  {
    "codigo": "D171",
    "descricao": "Neoplasia lipomatosa benigna da pele e tecido subcutâneo do tronco"
  },
  {
    "codigo": "D172",
    "descricao": "Neoplasia lipomatosa benigna da pele e tecido subcutâneo dos membros"
  },
  {
    "codigo": "D173",
    "descricao": "Neoplasia lipomatosa benigna da pele e tecido subcutâneo de outras localizações e de localizações não especificadas"
  },
  {
    "codigo": "D174",
    "descricao": "Neoplasia lipomatosa benigna de órgãos intratorácicos"
  },
  {
    "codigo": "D175",
    "descricao": "Neoplasia lipomatosa benigna de órgãos intra-abdominais"
  },
  {
    "codigo": "D176",
    "descricao": "Neoplasia lipomatosa benigna do cordão espermático"
  },
  {
    "codigo": "D177",
    "descricao": "Neoplasia lipomatosa benigna de outras localizações"
  },
  {
    "codigo": "D179",
    "descricao": "Neoplasia lipomatosa benigna de localização não especificada"
  },
  {
    "codigo": "D180",
    "descricao": "Hemangioma de qualquer localização"
  },
  {
    "codigo": "D181",
    "descricao": "Linfangioma de qualquer localização"
  },
  {
    "codigo": "D190",
    "descricao": "Neoplasia benigna do tecido mesotelial da pleura"
  },
  {
    "codigo": "D191",
    "descricao": "Neoplasia benigna do tecido mesotelial do peritônio"
  },
  {
    "codigo": "D197",
    "descricao": "Neoplasia benigna do tecido mesotelial de outras localizações"
  },
  {
    "codigo": "D199",
    "descricao": "Neoplasia benigna do tecido mesotelial, não especificado"
  },
  { "codigo": "D200", "descricao": "Neoplasia benigna do retroperitônio" },
  { "codigo": "D201", "descricao": "Neoplasia benigna do peritônio" },
  {
    "codigo": "D210",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles da cabeça, face e pescoço"
  },
  {
    "codigo": "D211",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles dos membros superiores, incluindo ombro"
  },
  {
    "codigo": "D212",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles dos membros inferiores, incluindo quadril"
  },
  {
    "codigo": "D213",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles do tórax"
  },
  {
    "codigo": "D214",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles do abdome"
  },
  {
    "codigo": "D215",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles da pelve"
  },
  {
    "codigo": "D216",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles do tronco, não especificado"
  },
  {
    "codigo": "D219",
    "descricao": "Neoplasia benigna do tecido conjuntivo e outros tecidos moles, sem outra especificação"
  },
  { "codigo": "D220", "descricao": "Nevo melanocítico do lábio" },
  {
    "codigo": "D221",
    "descricao": "Nevo melanocítico da pálpebra, incluindo o canto"
  },
  {
    "codigo": "D222",
    "descricao": "Nevo melanocítico da orelha e do conduto auditivo externo"
  },
  {
    "codigo": "D223",
    "descricao": "Nevo melanocítico de outras partes e de partes não especificadas da face"
  },
  {
    "codigo": "D224",
    "descricao": "Nevo melanocítico do couro cabeludo e do pescoço"
  },
  { "codigo": "D225", "descricao": "Nevo melanocítico do tronco" },
  {
    "codigo": "D226",
    "descricao": "Nevo melanocítico dos membros superiores, incluindo ombro"
  },
  {
    "codigo": "D227",
    "descricao": "Nevo melanocítico dos membros inferiores, incluindo quadril"
  },
  {
    "codigo": "D229",
    "descricao": "Nevo melanocítico, não especificado"
  },
  {
    "codigo": "D230",
    "descricao": "Neoplasia benigna da pele dos lábios"
  },
  {
    "codigo": "D231",
    "descricao": "Neoplasia benigna da pele da pálpebra, incluindo o canto"
  },
  {
    "codigo": "D232",
    "descricao": "Neoplasia benigna da pele da orelha e do conduto auditivo externo"
  },
  {
    "codigo": "D233",
    "descricao": "Neoplasia benigna da pele de outras partes e de partes não especificadas da face"
  },
  {
    "codigo": "D234",
    "descricao": "Neoplasia benigna da pele do couro cabeludo e do pescoço"
  },
  { "codigo": "D235", "descricao": "Neoplasia benigna da pele do tronco" },
  {
    "codigo": "D236",
    "descricao": "Neoplasia benigna da pele dos membros superiores, incluindo o ombro"
  },
  {
    "codigo": "D237",
    "descricao": "Neoplasia benigna da pele dos membros inferiores, incluindo o quadril"
  },
  {
    "codigo": "D239",
    "descricao": "Neoplasia benigna da pele, não especificada"
  },
  { "codigo": "D24", "descricao": "Neoplasia benigna da mama" },
  { "codigo": "D250", "descricao": "Leiomioma submucoso do útero" },
  { "codigo": "D251", "descricao": "Leiomioma intramural do útero" },
  { "codigo": "D252", "descricao": "Leiomioma subseroso do útero" },
  {
    "codigo": "D259",
    "descricao": "Leiomioma do útero, não especificado"
  },
  { "codigo": "D260", "descricao": "Neoplasia benigna do colo do útero" },
  { "codigo": "D261", "descricao": "Neoplasia benigna do corpo do útero" },
  {
    "codigo": "D267",
    "descricao": "Neoplasia benigna de outras partes do útero"
  },
  {
    "codigo": "D269",
    "descricao": "Neoplasia benigna do útero, não especificado"
  },
  { "codigo": "D27", "descricao": "Neoplasia benigna do ovário" },
  { "codigo": "D280", "descricao": "Neoplasia benigna da vulva" },
  { "codigo": "D281", "descricao": "Neoplasia benigna da vagina" },
  {
    "codigo": "D282",
    "descricao": "Neoplasia benigna das trompas e ligamentos uterinos"
  },
  {
    "codigo": "D287",
    "descricao": "Neoplasia benigna de outros órgãos genitais femininos especificados"
  },
  {
    "codigo": "D289",
    "descricao": "Neoplasia benigna de órgão genital feminino, não especificado"
  },
  { "codigo": "D290", "descricao": "Neoplasia benigna do pênis" },
  { "codigo": "D291", "descricao": "Neoplasia benigna da próstata" },
  { "codigo": "D292", "descricao": "Neoplasia benigna dos testículos" },
  { "codigo": "D293", "descricao": "Neoplasia benigna do epidídimo" },
  { "codigo": "D294", "descricao": "Neoplasia benigna do escroto" },
  {
    "codigo": "D297",
    "descricao": "Neoplasia benigna de outros órgãos genitais masculinos"
  },
  {
    "codigo": "D299",
    "descricao": "Neoplasia benigna de órgão genital masculino, não especificado"
  },
  { "codigo": "D300", "descricao": "Neoplasia benigna do rim" },
  { "codigo": "D301", "descricao": "Neoplasia benigna da pelve renal" },
  { "codigo": "D302", "descricao": "Neoplasia benigna do ureter" },
  { "codigo": "D303", "descricao": "Neoplasia benigna da bexiga" },
  { "codigo": "D304", "descricao": "Neoplasia benigna da uretra" },
  {
    "codigo": "D307",
    "descricao": "Neoplasia benigna de outros órgãos urinários"
  },
  {
    "codigo": "D309",
    "descricao": "Neoplasia benigna de órgão urinário, não especificado"
  },
  { "codigo": "D310", "descricao": "Neoplasia benigna da conjuntiva" },
  { "codigo": "D311", "descricao": "Neoplasia benigna da córnea" },
  { "codigo": "D312", "descricao": "Neoplasia benigna da retina" },
  { "codigo": "D313", "descricao": "Neoplasia benigna da coróide" },
  { "codigo": "D314", "descricao": "Neoplasia benigna do corpo ciliar" },
  {
    "codigo": "D315",
    "descricao": "Neoplasia benigna das glândulas e dos canais lacrimais"
  },
  {
    "codigo": "D316",
    "descricao": "Neoplasia benigna da órbita, não especificada"
  },
  {
    "codigo": "D319",
    "descricao": "Neoplasia benigna do olho, não especificado"
  },
  { "codigo": "D320", "descricao": "Neoplasia benigna das meninges cerebrais" },
  { "codigo": "D321", "descricao": "Neoplasia benigna das meninges espinhais" },
  {
    "codigo": "D329",
    "descricao": "Neoplasia benigna das meninges, não especificada"
  },
  {
    "codigo": "D330",
    "descricao": "Neoplasia benigna do encéfalo, supratentorial"
  },
  {
    "codigo": "D331",
    "descricao": "Neoplasia benigna do encéfalo, infratentorial"
  },
  {
    "codigo": "D332",
    "descricao": "Neoplasia benigna do encéfalo, não especificado"
  },
  { "codigo": "D333", "descricao": "Neoplasia benigna dos nervos cranianos" },
  { "codigo": "D334", "descricao": "Neoplasia benigna da medula espinhal" },
  {
    "codigo": "D337",
    "descricao": "Neoplasia benigna de outras partes especificadas do sistema nervoso central"
  },
  {
    "codigo": "D339",
    "descricao": "Neoplasia benigna do sistema nervoso central, não especificado"
  },
  {
    "codigo": "D34",
    "descricao": "Neoplasia benigna da glândula tireóide"
  },
  {
    "codigo": "D350",
    "descricao": "Neoplasia benigna da glândula supra-renal (adrenal)"
  },
  {
    "codigo": "D351",
    "descricao": "Neoplasia benigna da glândula paratireóide"
  },
  {
    "codigo": "D352",
    "descricao": "Neoplasia benigna da glândula hipófise (pituitária)"
  },
  {
    "codigo": "D353",
    "descricao": "Neoplasia benigna do conduto craniofaríngeo"
  },
  {
    "codigo": "D354",
    "descricao": "Neoplasia benigna da glândula pineal"
  },
  {
    "codigo": "D355",
    "descricao": "Neoplasia benigna do corpo carotídeo"
  },
  {
    "codigo": "D356",
    "descricao": "Neoplasia benigna dos corpos aórticos e outros paragânglios"
  },
  {
    "codigo": "D357",
    "descricao": "Neoplasia benigna de outras glândulas endócrinas especificadas"
  },
  {
    "codigo": "D358",
    "descricao": "Neoplasia benigna com comprometimento pluriglandular"
  },
  {
    "codigo": "D359",
    "descricao": "Neoplasia benigna de glândula endócrina, não especificada"
  },
  {
    "codigo": "D360",
    "descricao": "Neoplasia benigna dos gânglios linfáticos (linfonodos)"
  },
  {
    "codigo": "D361",
    "descricao": "Neoplasia benigna dos nervos periféricos e sistema nervoso autônomo"
  },
  {
    "codigo": "D367",
    "descricao": "Neoplasia benigna de outras localizações especificadas"
  },
  {
    "codigo": "D369",
    "descricao": "Neoplasia benigna de localização não especificada"
  },
  {
    "codigo": "D370",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do lábio, cavidade oral e faringe"
  },
  {
    "codigo": "D371",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do estômago"
  },
  {
    "codigo": "D372",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do intestino delgado"
  },
  {
    "codigo": "D373",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do apêndice"
  },
  {
    "codigo": "D374",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido dos cólons"
  },
  {
    "codigo": "D375",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do reto"
  },
  {
    "codigo": "D376",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do fígado, vesícula biliar e vias biliares"
  },
  {
    "codigo": "D377",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outros órgãos digestivos"
  },
  {
    "codigo": "D379",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de órgão digestivo, não especificado"
  },
  {
    "codigo": "D380",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da laringe"
  },
  {
    "codigo": "D381",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da traquéia, brônquios e pulmão"
  },
  {
    "codigo": "D382",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da pleura"
  },
  {
    "codigo": "D383",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do mediastino"
  },
  {
    "codigo": "D384",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do timo"
  },
  {
    "codigo": "D385",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outros órgãos respiratórios"
  },
  {
    "codigo": "D386",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de órgão respiratório, não especificado"
  },
  {
    "codigo": "D390",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do útero"
  },
  {
    "codigo": "D391",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do ovário"
  },
  {
    "codigo": "D392",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da placenta"
  },
  {
    "codigo": "D397",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outros órgãos genitais femininos"
  },
  {
    "codigo": "D399",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de órgão genital feminino, não especificado"
  },
  {
    "codigo": "D400",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da próstata"
  },
  {
    "codigo": "D401",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do testículo"
  },
  {
    "codigo": "D407",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outros órgãos genitais masculinos"
  },
  {
    "codigo": "D409",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de órgão genital masculino, não especificado"
  },
  {
    "codigo": "D410",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do rim"
  },
  {
    "codigo": "D411",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da pelve renal"
  },
  {
    "codigo": "D412",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do ureter"
  },
  {
    "codigo": "D413",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da uretra"
  },
  {
    "codigo": "D414",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da bexiga"
  },
  {
    "codigo": "D417",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outros órgãos urinários"
  },
  {
    "codigo": "D419",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de órgão urinário, não especificado"
  },
  {
    "codigo": "D420",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido das meninges cerebrais"
  },
  {
    "codigo": "D421",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido das meninges espinhais"
  },
  {
    "codigo": "D429",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de meninges não especificadas"
  },
  {
    "codigo": "D430",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do encéfalo, supratentorial"
  },
  {
    "codigo": "D431",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do encéfalo, infratentorial"
  },
  {
    "codigo": "D432",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do encéfalo, não especificado"
  },
  {
    "codigo": "D433",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido dos nervos cranianos"
  },
  {
    "codigo": "D434",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da medula espinhal"
  },
  {
    "codigo": "D437",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outras partes do sistema nervoso central"
  },
  {
    "codigo": "D439",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do sistema nervoso central, não especificado"
  },
  {
    "codigo": "D440",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da glândula tireóide"
  },
  {
    "codigo": "D441",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da glândula supra-renal (adrenal)"
  },
  {
    "codigo": "D442",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da glândula paratireóide"
  },
  {
    "codigo": "D443",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da glândula hipófise (pituitária)"
  },
  {
    "codigo": "D444",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do conduto craniofaríngeo"
  },
  {
    "codigo": "D445",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da glândula pineal"
  },
  {
    "codigo": "D446",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do corpo carotídeo"
  },
  {
    "codigo": "D447",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido dos corpos aórticos e outros paragânglios"
  },
  {
    "codigo": "D448",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido com comprometimento pluriglandular"
  },
  {
    "codigo": "D449",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de glândula endócrina, não especificada"
  },
  { "codigo": "D45", "descricao": "Policitemia vera" },
  { "codigo": "D460", "descricao": "Anemia refratária sem sideroblastos" },
  { "codigo": "D461", "descricao": "Anemia refratária com sideroblastos" },
  {
    "codigo": "D462",
    "descricao": "Anemia refratária com excesso de blastos"
  },
  {
    "codigo": "D463",
    "descricao": "Anemia refratária com excesso de blastos com transformação"
  },
  {
    "codigo": "D464",
    "descricao": "Anemia refratária, não especificada"
  },
  {
    "codigo": "D467",
    "descricao": "Outras síndromes mielodisplásicas"
  },
  {
    "codigo": "D469",
    "descricao": "Síndrome mielodisplásica, não especificada"
  },
  {
    "codigo": "D470",
    "descricao": "Tumores de comportamento incerto ou desconhecido de mastócitos e células histiocíticas"
  },
  {
    "codigo": "D471",
    "descricao": "Doença mieloproliferativa crônica"
  },
  { "codigo": "D472", "descricao": "Gamopatia monoclonal" },
  {
    "codigo": "D473",
    "descricao": "Trombocitemia essencial (hemorrágica)"
  },
  {
    "codigo": "D477",
    "descricao": "Outras neoplasias especificadas de comportamento incerto ou desconhecido dos tecidos linfático, hematopoético e tecidos relacionados"
  },
  {
    "codigo": "D479",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido dos tecidos linfático, hematopoético e tecidos correlatos, não especificada"
  },
  {
    "codigo": "D480",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido dos ossos e cartilagens articulares"
  },
  {
    "codigo": "D481",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do tecido conjuntivo e outros tecidos moles"
  },
  {
    "codigo": "D482",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido dos nervos periféricos e do sistema nervoso periférico"
  },
  {
    "codigo": "D483",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do retroperitônio"
  },
  {
    "codigo": "D484",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido do peritônio"
  },
  {
    "codigo": "D485",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da pele"
  },
  {
    "codigo": "D486",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido da mama"
  },
  {
    "codigo": "D487",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido de outras localizações especificadas"
  },
  {
    "codigo": "D489",
    "descricao": "Neoplasia de comportamento incerto ou desconhecido sem outra especificação"
  },
  {
    "codigo": "D500",
    "descricao": "Anemia por deficiência de ferro secundária à perda de sangue (crônica)"
  },
  { "codigo": "D501", "descricao": "Disfagia sideropênica" },
  {
    "codigo": "D508",
    "descricao": "Outras anemias por deficiência de ferro"
  },
  {
    "codigo": "D509",
    "descricao": "Anemia por deficiência de ferro não especificada"
  },
  {
    "codigo": "D510",
    "descricao": "Anemia por deficiência de vitamina B12 devida à deficiência de fator intrínseco"
  },
  {
    "codigo": "D511",
    "descricao": "Anemia por deficiência de vitamina B12 devida à má-absorção seletiva de vitamina B12 com proteinúria"
  },
  { "codigo": "D512", "descricao": "Deficiência de transcobalamina II" },
  {
    "codigo": "D513",
    "descricao": "Outras anemias por deficiência de vitamina B12 na dieta"
  },
  {
    "codigo": "D518",
    "descricao": "Outras anemias por deficiência de vitamina B12"
  },
  {
    "codigo": "D519",
    "descricao": "Anemia por deficiência de vitamina B12 não especificada"
  },
  {
    "codigo": "D520",
    "descricao": "Anemia por deficiência de folato na dieta"
  },
  {
    "codigo": "D521",
    "descricao": "Anemia por deficiência de folato induzida por drogas"
  },
  {
    "codigo": "D528",
    "descricao": "Outras anemias por deficiência de folato"
  },
  {
    "codigo": "D529",
    "descricao": "Anemia por deficiência de folato não especificada"
  },
  {
    "codigo": "D530",
    "descricao": "Anemia por deficiência de proteínas"
  },
  {
    "codigo": "D531",
    "descricao": "Outras anemias megaloblásticas não classificadas em outras partes"
  },
  { "codigo": "D532", "descricao": "Anemia escorbútica" },
  {
    "codigo": "D538",
    "descricao": "Outras anemias nutricionais especificadas"
  },
  { "codigo": "D539", "descricao": "Anemia nutricional não especificada" },
  {
    "codigo": "D550",
    "descricao": "Anemia devida à deficiência de glicose-6-fosfato-desidrogenase [G-6-PD]"
  },
  {
    "codigo": "D551",
    "descricao": "Anemia devida a outros transtornos do metabolismo do glutation"
  },
  {
    "codigo": "D552",
    "descricao": "Anemia devida a transtornos das enzimas glicolíticas"
  },
  {
    "codigo": "D553",
    "descricao": "Anemia devida a transtornos do metabolismo de nucleotídios"
  },
  {
    "codigo": "D558",
    "descricao": "Outras anemias devidas a transtornos enzimáticos"
  },
  {
    "codigo": "D559",
    "descricao": "Anemia devida a transtorno enzimático não especificada"
  },
  { "codigo": "D560", "descricao": "Talassemia alfa" },
  { "codigo": "D561", "descricao": "Talassemia beta" },
  { "codigo": "D562", "descricao": "Talassemia delta-beta" },
  { "codigo": "D563", "descricao": "Estigma talassêmico" },
  {
    "codigo": "D564",
    "descricao": "Persistência hereditária de hemoglobina fetal"
  },
  { "codigo": "D568", "descricao": "Outras talassemias" },
  { "codigo": "D569", "descricao": "Talassemia não especificada" },
  { "codigo": "D570", "descricao": "Anemia falciforme com crise" },
  { "codigo": "D571", "descricao": "Anemia falciforme sem crise" },
  {
    "codigo": "D572",
    "descricao": "Transtornos falciformes heterozigóticos duplos"
  },
  { "codigo": "D573", "descricao": "Estigma falciforme" },
  { "codigo": "D578", "descricao": "Outros transtornos falciformes" },
  { "codigo": "D580", "descricao": "Esferocitose hereditária" },
  { "codigo": "D581", "descricao": "Eliptocitose hereditária" },
  { "codigo": "D582", "descricao": "Outras hemoglobinopatias" },
  {
    "codigo": "D588",
    "descricao": "Outras anemias hemolíticas hereditárias especificadas"
  },
  {
    "codigo": "D589",
    "descricao": "Anemia hemolítica hereditária não especificada"
  },
  {
    "codigo": "D590",
    "descricao": "Anemia hemolítica auto-imune induzida por droga"
  },
  {
    "codigo": "D591",
    "descricao": "Outras anemias hemolíticas auto-imunes"
  },
  {
    "codigo": "D592",
    "descricao": "Anemia hemolítica não-auto-imune induzida por drogas"
  },
  {
    "codigo": "D593",
    "descricao": "Síndrome hemolítico-urêmica"
  },
  {
    "codigo": "D594",
    "descricao": "Outras anemias hemolíticas não-autoimunes"
  },
  {
    "codigo": "D595",
    "descricao": "Hemoglobinúria paroxística noturna [Marchiafava-Micheli]"
  },
  {
    "codigo": "D596",
    "descricao": "Hemoglobinúria devida à hemólise por outras causas externas"
  },
  {
    "codigo": "D598",
    "descricao": "Outras anemias hemolíticas adquiridas"
  },
  {
    "codigo": "D599",
    "descricao": "Anemia hemolítica adquirida não especificada"
  },
  {
    "codigo": "D600",
    "descricao": "Aplasia pura adquirida crônica da série vermelha"
  },
  {
    "codigo": "D601",
    "descricao": "Aplasia pura adquirida transitória da série vermelha"
  },
  {
    "codigo": "D608",
    "descricao": "Outras aplasias puras adquiridas da série vermelha"
  },
  {
    "codigo": "D609",
    "descricao": "Aplasia pura adquirida, não especificada, da série vermelha"
  },
  { "codigo": "D610", "descricao": "Anemia aplástica constitucional" },
  {
    "codigo": "D611",
    "descricao": "Anemia aplástica induzida por drogas"
  },
  {
    "codigo": "D612",
    "descricao": "Anemia aplástica devida a outros agentes externos"
  },
  { "codigo": "D613", "descricao": "Anemia aplástica idiopática" },
  {
    "codigo": "D618",
    "descricao": "Outras anemias aplásticas especificadas"
  },
  {
    "codigo": "D619",
    "descricao": "Anemia aplástica não especificada"
  },
  { "codigo": "D62", "descricao": "Anemia aguda pós-hemorrágica" },
  { "codigo": "D630", "descricao": "Anemia em neoplasias" },
  {
    "codigo": "D638",
    "descricao": "Anemia em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "D640",
    "descricao": "Anemia sideroblástica hereditária"
  },
  {
    "codigo": "D641",
    "descricao": "Anemia sideroblástica secundária a doença"
  },
  {
    "codigo": "D642",
    "descricao": "Anemia sideroblástica secundária ao uso de drogas e a toxinas"
  },
  { "codigo": "D643", "descricao": "Outras anemias sideroblásticas" },
  {
    "codigo": "D644",
    "descricao": "Anemia diseritropoética congênita"
  },
  { "codigo": "D648", "descricao": "Outras anemias especificadas" },
  { "codigo": "D649", "descricao": "Anemia não especificada" },
  {
    "codigo": "D65",
    "descricao": "Coagulação intravascular disseminada [síndrome de desfibrinação]"
  },
  {
    "codigo": "D66",
    "descricao": "Deficiência hereditária do fator VIII"
  },
  {
    "codigo": "D67",
    "descricao": "Deficiência hereditária do fator IX"
  },
  { "codigo": "D680", "descricao": "Doença de Von Willebrand" },
  {
    "codigo": "D681",
    "descricao": "Deficiência hereditária de fator XI"
  },
  {
    "codigo": "D682",
    "descricao": "Deficiência hereditária de outros fatores de coagulação"
  },
  {
    "codigo": "D683",
    "descricao": "Transtorno hemorrágico devido a anticoagulantes circulantes"
  },
  {
    "codigo": "D684",
    "descricao": "Deficiência adquirida de fator de coagulação"
  },
  {
    "codigo": "D688",
    "descricao": "Outros defeitos especificados da coagulação"
  },
  {
    "codigo": "D689",
    "descricao": "Defeito de coagulação não especificado"
  },
  { "codigo": "D690", "descricao": "Púrpura alérgica" },
  { "codigo": "D691", "descricao": "Defeitos qualitativos das plaquetas" },
  {
    "codigo": "D692",
    "descricao": "Outras púrpuras não-trombocitopênicas"
  },
  {
    "codigo": "D693",
    "descricao": "Púrpura trombocitopênica idiopática"
  },
  { "codigo": "D694", "descricao": "Outra trombocitopenia primária" },
  { "codigo": "D695", "descricao": "Trombocitopenia secundária" },
  { "codigo": "D696", "descricao": "Trombocitopenia não especificada" },
  {
    "codigo": "D698",
    "descricao": "Outras afecções hemorrágicas especificadas"
  },
  {
    "codigo": "D699",
    "descricao": "Afecção hemorrágica não especificada"
  },
  { "codigo": "D70", "descricao": "Agranulocitose" },
  {
    "codigo": "D71",
    "descricao": "Transtornos funcionais dos neutrófilos polimorfonucleares"
  },
  {
    "codigo": "D720",
    "descricao": "Anomalias genéticas dos leucócitos"
  },
  { "codigo": "D721", "descricao": "Eosinofilia" },
  {
    "codigo": "D728",
    "descricao": "Outros transtornos especificados dos glóbulos brancos"
  },
  {
    "codigo": "D729",
    "descricao": "Transtornos não especificados dos glóbulos brancos"
  },
  { "codigo": "D730", "descricao": "Hipoesplenismo" },
  { "codigo": "D731", "descricao": "Hiperesplenismo" },
  { "codigo": "D732", "descricao": "Esplenomegalia congestiva crônica" },
  { "codigo": "D733", "descricao": "Abscesso do baço" },
  { "codigo": "D734", "descricao": "Cisto do baço" },
  { "codigo": "D735", "descricao": "Infarto do baço" },
  { "codigo": "D738", "descricao": "Outras doenças do baço" },
  {
    "codigo": "D739",
    "descricao": "Doença não especificada do baço"
  },
  { "codigo": "D740", "descricao": "Metemoglobinemia congênita" },
  { "codigo": "D748", "descricao": "Outras metemoglobinemias" },
  { "codigo": "D749", "descricao": "Metemoglobinemia não especificada" },
  { "codigo": "D750", "descricao": "Eritrocitose familiar" },
  { "codigo": "D751", "descricao": "Policitemia secundária" },
  { "codigo": "D752", "descricao": "Trombocitose essencial" },
  {
    "codigo": "D758",
    "descricao": "Outras doenças especificadas do sangue e dos órgãos hematopoéticos"
  },
  {
    "codigo": "D759",
    "descricao": "Doença não especificada do sangue e dos órgãos hematopoéticos"
  },
  {
    "codigo": "D760",
    "descricao": "Histiocitose das células de Langerhans não classificadas em outra parte"
  },
  { "codigo": "D761", "descricao": "Linfohistiocitose hemofagocítica" },
  {
    "codigo": "D762",
    "descricao": "Síndrome hematofagocítica associada à infecção"
  },
  { "codigo": "D763", "descricao": "Outras síndromes histiocíticas" },
  {
    "codigo": "D77",
    "descricao": "Outros transtornos do sangue e dos órgãos hematopoéticos em doenças classificadas em outra parte"
  },
  { "codigo": "D800", "descricao": "Hipogamaglobulinemia hereditária" },
  { "codigo": "D801", "descricao": "Hipogamaglobulinemia não familiar" },
  {
    "codigo": "D802",
    "descricao": "Deficiência seletiva de imunoglobulina A [IgA]"
  },
  {
    "codigo": "D803",
    "descricao": "Deficiência seletiva de subclasses de imunoglobulina G [IgG]"
  },
  {
    "codigo": "D804",
    "descricao": "Deficiência seletiva de imunoglobulina M [IgM]"
  },
  {
    "codigo": "D805",
    "descricao": "Imunodeficiência com aumento de imunoglobulina M [IgM]"
  },
  {
    "codigo": "D806",
    "descricao": "Deficiência de anticorpos com imunoglobulinas próximas do normal ou com hiperimunoglobulinemia"
  },
  {
    "codigo": "D807",
    "descricao": "Hipogamaglobulinemia transitória da infância"
  },
  {
    "codigo": "D808",
    "descricao": "Outras imunodeficiências com predominância de defeitos de anticorpos"
  },
  {
    "codigo": "D809",
    "descricao": "Imunodeficiência não especificada com predominância de defeitos de anticorpos"
  },
  {
    "codigo": "D810",
    "descricao": "Imunodeficiência combinada grave [SCID] com disgenesia reticular"
  },
  {
    "codigo": "D811",
    "descricao": "Imunodeficiência combinada grave [SCID] com números baixos de células T e B"
  },
  {
    "codigo": "D812",
    "descricao": "Imunodeficiência combinada grave [SCID] com números baixos ou normais de células B"
  },
  {
    "codigo": "D813",
    "descricao": "Deficiência de adenosina-deaminase [ADA]"
  },
  { "codigo": "D814", "descricao": "Síndrome de Nezelof" },
  {
    "codigo": "D815",
    "descricao": "Deficiência de purina-nucleosídeo fosforilase [PNP]"
  },
  {
    "codigo": "D816",
    "descricao": "Deficiência major classe I do complexo de histocompatibilidade"
  },
  {
    "codigo": "D817",
    "descricao": "Deficiência major classe II do complexo de histocompatibilidade"
  },
  {
    "codigo": "D818",
    "descricao": "Outras deficiências imunitárias combinadas"
  },
  {
    "codigo": "D819",
    "descricao": "Deficiências imunitárias combinadas não especificadas"
  },
  { "codigo": "D820", "descricao": "Síndrome de Wiskott-Aldrich" },
  { "codigo": "D821", "descricao": "Síndrome de Di George" },
  {
    "codigo": "D822",
    "descricao": "Imunodeficiência com encurtamento de membros"
  },
  {
    "codigo": "D823",
    "descricao": "Imunodeficiência que se segue à resposta hereditária defeituosa ao vírus de Epstein-Barr (EB)"
  },
  {
    "codigo": "D824",
    "descricao": "Síndrome da hiperimunoglobulina E [IgE]"
  },
  {
    "codigo": "D828",
    "descricao": "Imunodeficiências associadas com outros defeitos major especificados"
  },
  {
    "codigo": "D829",
    "descricao": "Imunodeficiência associada com defeitos major não especificados"
  },
  {
    "codigo": "D830",
    "descricao": "Imunodeficiência comum variável com predominância de anormalidades do número e da função das células B"
  },
  {
    "codigo": "D831",
    "descricao": "Imunodeficiência comum variável com predominância de transtornos imunorregulatórios de células T"
  },
  {
    "codigo": "D832",
    "descricao": "Imunodeficiência comum variável com auto-anticorpos às células B ou T"
  },
  {
    "codigo": "D838",
    "descricao": "Outras imunodeficiências comuns variáveis"
  },
  {
    "codigo": "D839",
    "descricao": "Imunodeficiência comum variável não especificada"
  },
  {
    "codigo": "D840",
    "descricao": "Defeito do antígeno-1 da função de linfócito [LFA-1]"
  },
  { "codigo": "D841", "descricao": "Defeitos no sistema complemento" },
  {
    "codigo": "D848",
    "descricao": "Outras imunodeficiências especificadas"
  },
  {
    "codigo": "D849",
    "descricao": "Imunodeficiência não especificada"
  },
  { "codigo": "D860", "descricao": "Sarcoidose do pulmão" },
  {
    "codigo": "D861",
    "descricao": "Sarcoidose dos gânglios linfáticos"
  },
  {
    "codigo": "D862",
    "descricao": "Sarcoidose do pulmão com sarcoidose dos gânglios linfáticos"
  },
  { "codigo": "D863", "descricao": "Sarcoidose da pele" },
  {
    "codigo": "D868",
    "descricao": "Sarcoidose de outros locais especificados e locais combinados"
  },
  { "codigo": "D869", "descricao": "Sarcoidose não especificada" },
  { "codigo": "D890", "descricao": "Hipergamaglobulinemia policlonal" },
  { "codigo": "D891", "descricao": "Crioglobulinemia" },
  {
    "codigo": "D892",
    "descricao": "Hipergamaglobulinemia não especificada"
  },
  {
    "codigo": "D898",
    "descricao": "Outros transtornos especificados que comprometem o mecanismo imunitário não classificados em outra parte"
  },
  {
    "codigo": "D899",
    "descricao": "Transtornos não especificados que comprometem o mecanismo imunitário"
  },
  {
    "codigo": "E000",
    "descricao": "Síndrome da deficiência congênita de iodo do tipo neurológico"
  },
  {
    "codigo": "E001",
    "descricao": "Síndrome da deficiência congênita de iodo do tipo mixedematoso"
  },
  {
    "codigo": "E002",
    "descricao": "Síndrome da deficiência congênita de iodo do tipo misto"
  },
  {
    "codigo": "E009",
    "descricao": "Síndrome da deficiência congênita de iodo, não especificada"
  },
  {
    "codigo": "E010",
    "descricao": "Bócio (endêmico) difuso por deficiência de iodo"
  },
  {
    "codigo": "E011",
    "descricao": "Bócio (endêmico) multinodular por deficiência de iodo"
  },
  {
    "codigo": "E012",
    "descricao": "Bócio (endêmico) não especificado, por deficiência de iodo"
  },
  {
    "codigo": "E018",
    "descricao": "Outros transtornos tireoidianos e afecções associadas, relacionados com a deficiência de iodo"
  },
  {
    "codigo": "E02",
    "descricao": "Hipotireoidismo subclínico por deficiência de iodo"
  },
  {
    "codigo": "E030",
    "descricao": "Hipotireoidismo congênito com bócio difuso"
  },
  {
    "codigo": "E031",
    "descricao": "Hipotireoidismo congênito sem bócio"
  },
  {
    "codigo": "E032",
    "descricao": "Hipotireoidismo devido a medicamentos e outras substâncias exógenas"
  },
  { "codigo": "E033", "descricao": "Hipotireoidismo pós-infeccioso" },
  { "codigo": "E034", "descricao": "Atrofia (adquirida) da tireóide" },
  { "codigo": "E035", "descricao": "Coma mixedematoso" },
  { "codigo": "E038", "descricao": "Outros hipotireoidismos especificados" },
  { "codigo": "E039", "descricao": "Hipotireoidismo não especificado" },
  { "codigo": "E040", "descricao": "Bócio não-tóxico difuso" },
  {
    "codigo": "E041",
    "descricao": "Bócio não-tóxico uninodular"
  },
  {
    "codigo": "E042",
    "descricao": "Bócio não-tóxico multinodular"
  },
  {
    "codigo": "E048",
    "descricao": "Outro bócio não-tóxico especificado"
  },
  {
    "codigo": "E049",
    "descricao": "Bócio não-tóxico, não especificado"
  },
  { "codigo": "E050", "descricao": "Tireotoxicose com bócio difuso" },
  {
    "codigo": "E051",
    "descricao": "Tireotoxicose com bócio tóxico uninodular"
  },
  {
    "codigo": "E052",
    "descricao": "Tireotoxicose com bócio tóxico multinodular"
  },
  {
    "codigo": "E053",
    "descricao": "Tireotoxicose causada por tecido tireoidiano ectópico"
  },
  { "codigo": "E054", "descricao": "Tireotoxicose factícia" },
  {
    "codigo": "E055",
    "descricao": "Crise ou tempestade tireotóxica"
  },
  { "codigo": "E058", "descricao": "Outras tireotoxicoses" },
  { "codigo": "E059", "descricao": "Tireotoxicose não especificada" },
  { "codigo": "E060", "descricao": "Tireoidite aguda" },
  { "codigo": "E061", "descricao": "Tireoidite subaguda" },
  {
    "codigo": "E062",
    "descricao": "Tireoidite crônica com tireotoxicose transitória"
  },
  { "codigo": "E063", "descricao": "Tireoidite auto-imune" },
  { "codigo": "E064", "descricao": "Tireoidite induzida por droga" },
  { "codigo": "E065", "descricao": "Outras tireoidites crônicas" },
  { "codigo": "E069", "descricao": "Tireoidite não especificada" },
  { "codigo": "E070", "descricao": "Hipersecreção de calcitonina" },
  { "codigo": "E071", "descricao": "Bócio disormonogênico" },
  {
    "codigo": "E078",
    "descricao": "Outros transtornos especificados da tireóide"
  },
  {
    "codigo": "E079",
    "descricao": "Transtorno não especificado da tireóide"
  },
  {
    "codigo": "E100",
    "descricao": "Diabetes mellitus insulino-dependente - com coma"
  },
  {
    "codigo": "E101",
    "descricao": "Diabetes mellitus insulino-dependente - com cetoacidose"
  },
  {
    "codigo": "E102",
    "descricao": "Diabetes mellitus insulino-dependente - com complicações renais"
  },
  {
    "codigo": "E103",
    "descricao": "Diabetes mellitus insulino-dependente - com complicações oftálmicas"
  },
  {
    "codigo": "E104",
    "descricao": "Diabetes mellitus insulino-dependente - com complicações neurológicas"
  },
  {
    "codigo": "E105",
    "descricao": "Diabetes mellitus insulino-dependente - com complicações circulatórias periféricas"
  },
  {
    "codigo": "E106",
    "descricao": "Diabetes mellitus insulino-dependente - com outras complicações especificadas"
  },
  {
    "codigo": "E107",
    "descricao": "Diabetes mellitus insulino-dependente - com complicações múltiplas"
  },
  {
    "codigo": "E108",
    "descricao": "Diabetes mellitus insulino-dependente - com complicações não especificadas"
  },
  {
    "codigo": "E109",
    "descricao": "Diabetes mellitus insulino-dependente - sem complicações"
  },
  {
    "codigo": "E110",
    "descricao": "Diabetes mellitus não-insulino-dependente - com coma"
  },
  {
    "codigo": "E111",
    "descricao": "Diabetes mellitus não-insulino-dependente - com cetoacidose"
  },
  {
    "codigo": "E112",
    "descricao": "Diabetes mellitus não-insulino-dependente - com complicações renais"
  },
  {
    "codigo": "E113",
    "descricao": "Diabetes mellitus não-insulino-dependente - com complicações oftálmicas"
  },
  {
    "codigo": "E114",
    "descricao": "Diabetes mellitus não-insulino-dependente - com complicações neurológicas"
  },
  {
    "codigo": "E115",
    "descricao": "Diabetes mellitus não-insulino-dependente - com complicações circulatórias periféricas"
  },
  {
    "codigo": "E116",
    "descricao": "Diabetes mellitus não-insulino-dependente - com outras complicações especificadas"
  },
  {
    "codigo": "E117",
    "descricao": "Diabetes mellitus não-insulino-dependente - com complicações múltiplas"
  },
  {
    "codigo": "E118",
    "descricao": "Diabetes mellitus não-insulino-dependente - com complicações não especificadas"
  },
  {
    "codigo": "E119",
    "descricao": "Diabetes mellitus não-insulino-dependente - sem complicações"
  },
  {
    "codigo": "E120",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com coma"
  },
  {
    "codigo": "E121",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com cetoacidose"
  },
  {
    "codigo": "E122",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com complicações renais"
  },
  {
    "codigo": "E123",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com complicações oftálmicas"
  },
  {
    "codigo": "E124",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com complicações neurológicas"
  },
  {
    "codigo": "E125",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com complicações circulatórias periféricas"
  },
  {
    "codigo": "E126",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com outras complicações especificadas"
  },
  {
    "codigo": "E127",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com complicações múltiplas"
  },
  {
    "codigo": "E128",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - com complicações não especificadas"
  },
  {
    "codigo": "E129",
    "descricao": "Diabetes mellitus relacionado com a desnutrição - sem complicações"
  },
  {
    "codigo": "E130",
    "descricao": "Outros tipos especificados de diabetes mellitus - com coma"
  },
  {
    "codigo": "E131",
    "descricao": "Outros tipos especificados de diabetes mellitus - com cetoacidose"
  },
  {
    "codigo": "E132",
    "descricao": "Outros tipos especificados de diabetes mellitus - com complicações renais"
  },
  {
    "codigo": "E133",
    "descricao": "Outros tipos especificados de diabetes mellitus - com complicações oftálmicas"
  },
  {
    "codigo": "E134",
    "descricao": "Outros tipos especificados de diabetes mellitus - com complicações neurológicas"
  },
  {
    "codigo": "E135",
    "descricao": "Outros tipos especificados de diabetes mellitus - com complicações circulatórias periféricas"
  },
  {
    "codigo": "E136",
    "descricao": "Outros tipos especificados de diabetes mellitus - com outras complicações especificadas"
  },
  {
    "codigo": "E137",
    "descricao": "Outros tipos especificados de diabetes mellitus - com complicações múltiplas"
  },
  {
    "codigo": "E138",
    "descricao": "Outros tipos especificados de diabetes mellitus - com complicações não especificadas"
  },
  {
    "codigo": "E139",
    "descricao": "Outros tipos especificados de diabetes mellitus - sem complicações"
  },
  {
    "codigo": "E140",
    "descricao": "Diabetes mellitus não especificado - com coma"
  },
  {
    "codigo": "E141",
    "descricao": "Diabetes mellitus não especificado - com cetoacidose"
  },
  {
    "codigo": "E142",
    "descricao": "Diabetes mellitus não especificado - com complicações renais"
  },
  {
    "codigo": "E143",
    "descricao": "Diabetes mellitus não especificado - com complicações oftálmicas"
  },
  {
    "codigo": "E144",
    "descricao": "Diabetes mellitus não especificado - com complicações neurológicas"
  },
  {
    "codigo": "E145",
    "descricao": "Diabetes mellitus não especificado - com complicações circulatórias periféricas"
  },
  {
    "codigo": "E146",
    "descricao": "Diabetes mellitus não especificado - com outras complicações especificadas"
  },
  {
    "codigo": "E147",
    "descricao": "Diabetes mellitus não especificado - com complicações múltiplas"
  },
  {
    "codigo": "E148",
    "descricao": "Diabetes mellitus não especificado - com complicações não especificadas"
  },
  {
    "codigo": "E149",
    "descricao": "Diabetes mellitus não especificado - sem complicações"
  },
  {
    "codigo": "E15",
    "descricao": "Coma hipoglicêmico não-diabético"
  },
  { "codigo": "E160", "descricao": "Hipoglicemia induzida por droga sem coma" },
  { "codigo": "E161", "descricao": "Outra hipoglicemia" },
  { "codigo": "E162", "descricao": "Hipoglicemia não especificada" },
  {
    "codigo": "E163",
    "descricao": "Aumento da secreção de glucagon"
  },
  { "codigo": "E164", "descricao": "Secreção anormal de gastrina" },
  {
    "codigo": "E168",
    "descricao": "Outros transtornos especificados da secreção pancreática interna"
  },
  {
    "codigo": "E169",
    "descricao": "Transtorno não especificado da secreção pancreática interna"
  },
  { "codigo": "E200", "descricao": "Hipoparatireoidismo idiopático" },
  { "codigo": "E201", "descricao": "Pseudohipoparatireoidismo" },
  { "codigo": "E208", "descricao": "Outro hipoparatireoidismo" },
  {
    "codigo": "E209",
    "descricao": "Hipoparatireoidismo não especificado"
  },
  { "codigo": "E210", "descricao": "Hiperparatireoidismo primário" },
  {
    "codigo": "E211",
    "descricao": "Hiperparatireoidismo secundário não classificado em outra parte"
  },
  { "codigo": "E212", "descricao": "Outro hiperparatireoidismo" },
  {
    "codigo": "E213",
    "descricao": "Hiperparatireoidismo não especificado"
  },
  {
    "codigo": "E214",
    "descricao": "Outros transtornos especificados da glândula paratireóide"
  },
  {
    "codigo": "E215",
    "descricao": "Transtorno não especificado da glândula paratireóide"
  },
  {
    "codigo": "E220",
    "descricao": "Acromegalia e gigantismo hipofisário"
  },
  { "codigo": "E221", "descricao": "Hiperprolactinemia" },
  {
    "codigo": "E222",
    "descricao": "Síndrome da secreção inadequada de hormônio antidiurético"
  },
  {
    "codigo": "E228",
    "descricao": "Outras hiperfunções da hipófise"
  },
  {
    "codigo": "E229",
    "descricao": "Hiperfunção não especificada da hipófise"
  },
  { "codigo": "E230", "descricao": "Hipopituitarismo" },
  { "codigo": "E231", "descricao": "Hipopituitarismo induzido por droga" },
  { "codigo": "E232", "descricao": "Diabetes insípido" },
  {
    "codigo": "E233",
    "descricao": "Disfunção hipotalâmica não classificada em outra parte"
  },
  { "codigo": "E236", "descricao": "Outros transtornos da hipófise" },
  {
    "codigo": "E237",
    "descricao": "Transtorno não especificado da hipófise"
  },
  {
    "codigo": "E240",
    "descricao": "Síndrome de Cushing dependente da hipófise"
  },
  { "codigo": "E241", "descricao": "Síndrome de Nelson" },
  {
    "codigo": "E242",
    "descricao": "Síndrome de Cushing induzida por droga"
  },
  { "codigo": "E243", "descricao": "Síndrome do ACTH ectópico" },
  {
    "codigo": "E244",
    "descricao": "Síndrome de pseudo-Cushing induzida pelo álcool"
  },
  { "codigo": "E248", "descricao": "Outra síndrome de Cushing" },
  {
    "codigo": "E249",
    "descricao": "Síndrome de Cushing não especificada"
  },
  {
    "codigo": "E250",
    "descricao": "Transtornos adrenogenitais congênitos associados à deficiência enzimática"
  },
  { "codigo": "E258", "descricao": "Outros transtornos adrenogenitais" },
  {
    "codigo": "E259",
    "descricao": "Transtorno adrenogenital não especificado"
  },
  { "codigo": "E260", "descricao": "Hiperaldosteronismo primário" },
  { "codigo": "E261", "descricao": "Hiperaldosteronismo secundário" },
  { "codigo": "E268", "descricao": "Outro hiperaldosteronismo" },
  {
    "codigo": "E269",
    "descricao": "Hiperaldosteronismo não especificado"
  },
  {
    "codigo": "E270",
    "descricao": "Outros excessos de atividade adrenocortical"
  },
  {
    "codigo": "E271",
    "descricao": "Insuficiência adrenocortical primária"
  },
  { "codigo": "E272", "descricao": "Crise addisoniana" },
  {
    "codigo": "E273",
    "descricao": "Insuficiência adrenocortical induzida por drogas"
  },
  {
    "codigo": "E274",
    "descricao": "Outras insuficiências adrenocorticais e as não especificadas"
  },
  { "codigo": "E275", "descricao": "Hiperfunção adrenomedular" },
  {
    "codigo": "E278",
    "descricao": "Outros transtornos especificados da supra-renal"
  },
  {
    "codigo": "E279",
    "descricao": "Transtorno não especificado da supra-renal"
  },
  { "codigo": "E280", "descricao": "Excesso de estrógeno" },
  { "codigo": "E281", "descricao": "Excesso de andrógenos" },
  {
    "codigo": "E282",
    "descricao": "Síndrome do ovário policístico"
  },
  {
    "codigo": "E283",
    "descricao": "Insuficiência ovariana primária"
  },
  { "codigo": "E288", "descricao": "Outra disfunção ovariana" },
  {
    "codigo": "E289",
    "descricao": "Disfunção ovariana não especificada"
  },
  { "codigo": "E290", "descricao": "Hiperfunção testicular" },
  { "codigo": "E291", "descricao": "Hipofunção testicular" },
  { "codigo": "E298", "descricao": "Outra disfunção testicular" },
  {
    "codigo": "E299",
    "descricao": "Disfunção testicular não especificada"
  },
  { "codigo": "E300", "descricao": "Puberdade retardada" },
  { "codigo": "E301", "descricao": "Puberdade precoce" },
  { "codigo": "E308", "descricao": "Outros transtornos da puberdade" },
  {
    "codigo": "E309",
    "descricao": "Transtorno não especificado da puberdade"
  },
  {
    "codigo": "E310",
    "descricao": "Insuficiência poliglandular auto-imune"
  },
  { "codigo": "E311", "descricao": "Hiperfunção poliglandular" },
  { "codigo": "E318", "descricao": "Outra disfunção poliglandular" },
  {
    "codigo": "E319",
    "descricao": "Disfunção poliglandular não especificada"
  },
  { "codigo": "E320", "descricao": "Hiperplasia persistente do timo" },
  { "codigo": "E321", "descricao": "Abscesso do timo" },
  { "codigo": "E328", "descricao": "Outras doenças do timo" },
  {
    "codigo": "E329",
    "descricao": "Doença do timo não especificada"
  },
  { "codigo": "E340", "descricao": "Síndrome carcinóide" },
  {
    "codigo": "E341",
    "descricao": "Outra hipersecreção de hormônios intestinais"
  },
  {
    "codigo": "E342",
    "descricao": "Secreção hormonal ectópica, não classificada em outra parte"
  },
  {
    "codigo": "E343",
    "descricao": "Nanismo, não classificado em outra parte"
  },
  { "codigo": "E344", "descricao": "Alta estatura constitucional" },
  {
    "codigo": "E345",
    "descricao": "Síndrome de resistência a andrógenos"
  },
  {
    "codigo": "E348",
    "descricao": "Outros transtornos endócrinos especificados"
  },
  {
    "codigo": "E349",
    "descricao": "Transtorno endócrino não especificado"
  },
  {
    "codigo": "E350",
    "descricao": "Transtornos da glândula tireóide em doenças classificadas em outra parte"
  },
  {
    "codigo": "E351",
    "descricao": "Transtornos das supra-renais em doenças classificadas em outra parte"
  },
  {
    "codigo": "E358",
    "descricao": "Transtornos de outras glândulas endócrinas em doenças classificadas em outra parte"
  },
  { "codigo": "E40", "descricao": "Kwashiorkor" },
  { "codigo": "E41", "descricao": "Marasmo nutricional" },
  { "codigo": "E42", "descricao": "Kwashiorkor marasmático" },
  {
    "codigo": "E43",
    "descricao": "Desnutrição protéico-calórica grave não especificada"
  },
  {
    "codigo": "E440",
    "descricao": "Desnutrição protéico-calórica moderada"
  },
  {
    "codigo": "E441",
    "descricao": "Desnutrição protéico-calórica leve"
  },
  {
    "codigo": "E45",
    "descricao": "Atraso do desenvolvimento devido à desnutrição protéico-calórica"
  },
  {
    "codigo": "E46",
    "descricao": "Desnutrição protéico-calórica não especificada"
  },
  {
    "codigo": "E500",
    "descricao": "Deficiência de vitamina A com xerose conjuntival"
  },
  {
    "codigo": "E501",
    "descricao": "Deficiência de vitamina A com mancha de Bitot e xerose conjuntival"
  },
  {
    "codigo": "E502",
    "descricao": "Deficiência de vitamina A com xerose da córnea"
  },
  {
    "codigo": "E503",
    "descricao": "Deficiência de vitamina A com ulceração e xerose da córnea"
  },
  {
    "codigo": "E504",
    "descricao": "Deficiência de vitamina A com ceratomalácia"
  },
  {
    "codigo": "E505",
    "descricao": "Deficiência de vitamina A com cegueira noturna"
  },
  {
    "codigo": "E506",
    "descricao": "Deficiência de vitamina A com cicatrizes xeroftálmicas da córnea"
  },
  {
    "codigo": "E507",
    "descricao": "Outras manifestações oculares devidas a deficiência de vitamina A"
  },
  {
    "codigo": "E508",
    "descricao": "Outras manifestações devidas a deficiência de vitamina A"
  },
  {
    "codigo": "E509",
    "descricao": "Deficiência não especificada de vitamina A"
  },
  { "codigo": "E511", "descricao": "Beribéri" },
  { "codigo": "E512", "descricao": "Encefalopatia de Wernicke" },
  {
    "codigo": "E518",
    "descricao": "Outras manifestações da deficiência de tiamina"
  },
  {
    "codigo": "E519",
    "descricao": "Deficiência não especificada de tiamina"
  },
  { "codigo": "E52", "descricao": "Deficiência de niacina [pelagra]" },
  { "codigo": "E530", "descricao": "Deficiência de riboflavina" },
  { "codigo": "E531", "descricao": "Deficiência de piridoxina" },
  {
    "codigo": "E538",
    "descricao": "Deficiência de outras vitaminas especificadas do grupo B"
  },
  {
    "codigo": "E539",
    "descricao": "Deficiência não especificada de vitamina B"
  },
  {
    "codigo": "E54",
    "descricao": "Deficiência de ácido ascórbico"
  },
  { "codigo": "E550", "descricao": "Raquitismo ativo" },
  {
    "codigo": "E559",
    "descricao": "Deficiência não especificada de vitamina D"
  },
  { "codigo": "E560", "descricao": "Deficiência de vitamina E" },
  { "codigo": "E561", "descricao": "Deficiência de vitamina K" },
  { "codigo": "E568", "descricao": "Deficiência de outras vitaminas" },
  {
    "codigo": "E569",
    "descricao": "Deficiência vitamínica não especificada"
  },
  { "codigo": "E58", "descricao": "Deficiência de cálcio da dieta" },
  { "codigo": "E59", "descricao": "Deficiência de selênio da dieta" },
  { "codigo": "E60", "descricao": "Deficiência de zinco da dieta" },
  { "codigo": "E610", "descricao": "Deficiência de cobre" },
  { "codigo": "E611", "descricao": "Deficiência de ferro" },
  { "codigo": "E612", "descricao": "Deficiência de magnésio" },
  { "codigo": "E613", "descricao": "Deficiência de manganês" },
  { "codigo": "E614", "descricao": "Deficiência de cromo" },
  { "codigo": "E615", "descricao": "Deficiência de molibdênio" },
  { "codigo": "E616", "descricao": "Deficiência de vanádio" },
  {
    "codigo": "E617",
    "descricao": "Deficiência de múltiplos elementos nutrientes"
  },
  {
    "codigo": "E618",
    "descricao": "Deficiência de outros elementos nutrientes especificados"
  },
  {
    "codigo": "E619",
    "descricao": "Deficiência de elementos nutrientes não especificados"
  },
  {
    "codigo": "E630",
    "descricao": "Deficiência de ácido graxo essencial"
  },
  {
    "codigo": "E631",
    "descricao": "Desequilíbrio de constituintes da ingestão de alimentos"
  },
  {
    "codigo": "E638",
    "descricao": "Outras deficiências nutricionais especificadas"
  },
  {
    "codigo": "E639",
    "descricao": "Deficiência nutricional não especificada"
  },
  {
    "codigo": "E640",
    "descricao": "Seqüelas de desnutrição protéico-calórica"
  },
  {
    "codigo": "E641",
    "descricao": "Seqüelas da deficiência de vitamina A"
  },
  {
    "codigo": "E642",
    "descricao": "Seqüelas da deficiência de vitamina C"
  },
  { "codigo": "E643", "descricao": "Seqüelas do raquitismo" },
  {
    "codigo": "E648",
    "descricao": "Seqüelas de outras deficiências nutricionais"
  },
  {
    "codigo": "E649",
    "descricao": "Seqüelas de deficiência nutricional não especificada"
  },
  { "codigo": "E65", "descricao": "Adiposidade localizada" },
  { "codigo": "E660", "descricao": "Obesidade devida a excesso de calorias" },
  { "codigo": "E661", "descricao": "Obesidade induzida por drogas" },
  {
    "codigo": "E662",
    "descricao": "Obesidade extrema com hipoventilação alveolar"
  },
  { "codigo": "E668", "descricao": "Outra obesidade" },
  { "codigo": "E669", "descricao": "Obesidade não especificada" },
  { "codigo": "E670", "descricao": "Hipervitaminose A" },
  { "codigo": "E671", "descricao": "Hipercarotenemia" },
  { "codigo": "E672", "descricao": "Síndrome de megavitamina B6" },
  { "codigo": "E673", "descricao": "Hipervitaminose D" },
  {
    "codigo": "E678",
    "descricao": "Outras formas especificadas de hiperalimentação"
  },
  {
    "codigo": "E68",
    "descricao": "Seqüelas de hiperalimentação"
  },
  { "codigo": "E700", "descricao": "Fenilcetonúria clássica" },
  { "codigo": "E701", "descricao": "Outras hiperfenilalaninemias" },
  {
    "codigo": "E702",
    "descricao": "Distúrbios do metabolismo da tirosina"
  },
  { "codigo": "E703", "descricao": "Albinismo" },
  {
    "codigo": "E708",
    "descricao": "Outros distúrbios do metabolismo de aminoácidos aromáticos"
  },
  {
    "codigo": "E709",
    "descricao": "Distúrbio não especificado do metabolismo de aminoácidos aromáticos"
  },
  {
    "codigo": "E710",
    "descricao": "Doença da urina em xarope de ácer (ou bordo) (Maple-syrup urine disease)"
  },
  {
    "codigo": "E711",
    "descricao": "Outros distúrbios do metabolismo de aminoácidos de cadeia ramificada"
  },
  {
    "codigo": "E712",
    "descricao": "Distúrbio não especificado do metabolismo de aminoácidos de cadeia ramificada"
  },
  {
    "codigo": "E713",
    "descricao": "Distúrbios do metabolismo de ácidos graxos"
  },
  {
    "codigo": "E720",
    "descricao": "Distúrbios do transporte de aminoácidos"
  },
  {
    "codigo": "E721",
    "descricao": "Distúrbios do metabolismo dos aminoácidos que contêm enxofre"
  },
  {
    "codigo": "E722",
    "descricao": "Distúrbios do metabolismo do ciclo da uréia"
  },
  {
    "codigo": "E723",
    "descricao": "Distúrbios do metabolismo da lisina e da hidroxilisina"
  },
  {
    "codigo": "E724",
    "descricao": "Distúrbios do metabolismo da ornitina"
  },
  {
    "codigo": "E725",
    "descricao": "Distúrbios do metabolismo da glicina"
  },
  {
    "codigo": "E728",
    "descricao": "Outros distúrbios especificados do metabolismo dos aminoácidos"
  },
  {
    "codigo": "E729",
    "descricao": "Distúrbio não especificado do metabolismo dos aminoácidos"
  },
  {
    "codigo": "E730",
    "descricao": "Deficiência congênita de lactase"
  },
  {
    "codigo": "E731",
    "descricao": "Deficiência secundária de lactase"
  },
  { "codigo": "E738", "descricao": "Outras intolerâncias à lactose" },
  {
    "codigo": "E739",
    "descricao": "Intolerância à lactose, não especificada"
  },
  {
    "codigo": "E740",
    "descricao": "Doença de depósito de glicogênio"
  },
  {
    "codigo": "E741",
    "descricao": "Distúrbios do metabolismo da frutose"
  },
  {
    "codigo": "E742",
    "descricao": "Distúrbios do metabolismo da galactose"
  },
  {
    "codigo": "E743",
    "descricao": "Outros distúrbios da absorção intestinal de carboidratos"
  },
  {
    "codigo": "E744",
    "descricao": "Distúrbios do metabolismo do piruvato e da gliconeogênese"
  },
  {
    "codigo": "E748",
    "descricao": "Outros distúrbios especificados do metabolismo de carboidratos"
  },
  {
    "codigo": "E749",
    "descricao": "Distúrbio não especificado do metabolismo de carboidratos"
  },
  { "codigo": "E750", "descricao": "Gangliosidose GM2" },
  { "codigo": "E751", "descricao": "Outras gangliosidoses" },
  { "codigo": "E752", "descricao": "Outras esfingolipidoses" },
  { "codigo": "E753", "descricao": "Esfingolipidose não especificada" },
  { "codigo": "E754", "descricao": "Lipofuscinose neuronal ceróide" },
  {
    "codigo": "E755",
    "descricao": "Outros distúrbios do depósito de lípides"
  },
  {
    "codigo": "E756",
    "descricao": "Distúrbio não especificado do depósito de lípides"
  },
  { "codigo": "E760", "descricao": "Mucopolissacaridose do tipo I" },
  { "codigo": "E761", "descricao": "Mucopolissacaridose do tipo II" },
  { "codigo": "E762", "descricao": "Outras mucopolissacaridoses" },
  {
    "codigo": "E763",
    "descricao": "Mucopolissacaridose não especificada"
  },
  {
    "codigo": "E768",
    "descricao": "Outros distúrbios do metabolismo do glicosaminoglicano"
  },
  {
    "codigo": "E769",
    "descricao": "Distúrbio não especificado do metabolismo do glicosaminoglicano"
  },
  {
    "codigo": "E770",
    "descricao": "Defeitos na modificação pós-translacional de enzimas lisossômicas"
  },
  {
    "codigo": "E771",
    "descricao": "Defeitos na degradação das glicoproteínas"
  },
  {
    "codigo": "E778",
    "descricao": "Outros distúrbios do metabolismo de glicoproteínas"
  },
  {
    "codigo": "E779",
    "descricao": "Distúrbio não especificado do metabolismo de glicoproteínas"
  },
  { "codigo": "E780", "descricao": "Hipercolesterolemia pura" },
  { "codigo": "E781", "descricao": "Hipergliceridemia pura" },
  { "codigo": "E782", "descricao": "Hiperlipidemia mista" },
  { "codigo": "E783", "descricao": "Hiperquilomicronemia" },
  { "codigo": "E784", "descricao": "Outras hiperlipidemias" },
  { "codigo": "E785", "descricao": "Hiperlipidemia não especificada" },
  { "codigo": "E786", "descricao": "Deficiências de lipoproteínas" },
  {
    "codigo": "E788",
    "descricao": "Outros distúrbios do metabolismo de lipoproteínas"
  },
  {
    "codigo": "E789",
    "descricao": "Distúrbio não especificado do metabolismo de lipoproteínas"
  },
  {
    "codigo": "E790",
    "descricao": "Hiperuricemia sem sinais de artrite inflamatória e de doença com tofos"
  },
  { "codigo": "E791", "descricao": "Síndrome de Lesch-Nyhan" },
  {
    "codigo": "E798",
    "descricao": "Outros distúrbios do metabolismo da purina e pirimidina"
  },
  {
    "codigo": "E799",
    "descricao": "Distúrbio não especificado do metabolismo da purina e pirimidina"
  },
  {
    "codigo": "E800",
    "descricao": "Porfiria hereditária eritropoética"
  },
  { "codigo": "E801", "descricao": "Porfiria cutânea tardia" },
  { "codigo": "E802", "descricao": "Outras porfirias" },
  { "codigo": "E803", "descricao": "Defeitos da catalase e da peroxidase" },
  { "codigo": "E804", "descricao": "Síndrome de Gilbert" },
  { "codigo": "E805", "descricao": "Síndrome da Crigler-Najjar" },
  {
    "codigo": "E806",
    "descricao": "Outros distúrbios do metabolismo da bilirrubina"
  },
  {
    "codigo": "E807",
    "descricao": "Distúrbio não especificado do metabolismo da bilirrubina"
  },
  { "codigo": "E830", "descricao": "Distúrbios do metabolismo do cobre" },
  { "codigo": "E831", "descricao": "Doença do metabolismo do ferro" },
  { "codigo": "E832", "descricao": "Distúrbios do metabolismo do zinco" },
  {
    "codigo": "E833",
    "descricao": "Distúrbios do metabolismo do fósforo"
  },
  {
    "codigo": "E834",
    "descricao": "Distúrbios do metabolismo do magnésio"
  },
  {
    "codigo": "E835",
    "descricao": "Distúrbios do metabolismo do cálcio"
  },
  {
    "codigo": "E838",
    "descricao": "Outros distúrbios do metabolismo mineral"
  },
  {
    "codigo": "E839",
    "descricao": "Distúrbio não especificado do metabolismo mineral"
  },
  {
    "codigo": "E840",
    "descricao": "Fibrose cística com manifestações pulmonares"
  },
  {
    "codigo": "E841",
    "descricao": "Fibrose cística com manifestações intestinais"
  },
  {
    "codigo": "E848",
    "descricao": "Fibrose cística com outras manifestações"
  },
  {
    "codigo": "E849",
    "descricao": "Fibrose cística não especificada"
  },
  {
    "codigo": "E850",
    "descricao": "Amiloidose heredofamiliar não-neuropática"
  },
  {
    "codigo": "E851",
    "descricao": "Amiloidose heredofamiliar neuropática"
  },
  {
    "codigo": "E852",
    "descricao": "Amiloidose heredofamiliar não especificada"
  },
  {
    "codigo": "E853",
    "descricao": "Amiloidose sistêmica secundária"
  },
  { "codigo": "E854", "descricao": "Amiloidose limitada a órgãos" },
  { "codigo": "E858", "descricao": "Outras amiloidoses" },
  { "codigo": "E859", "descricao": "Amiloidose não especificada" },
  { "codigo": "E86", "descricao": "Depleção de volume" },
  { "codigo": "E870", "descricao": "Hiperosmolaridade e hipernatremia" },
  { "codigo": "E871", "descricao": "Hiposmolaridade e hiponatremia" },
  { "codigo": "E872", "descricao": "Acidose" },
  { "codigo": "E873", "descricao": "Alcalose" },
  {
    "codigo": "E874",
    "descricao": "Distúrbio misto do equilíbrio ácido-básico"
  },
  { "codigo": "E875", "descricao": "Hiperpotassemia" },
  { "codigo": "E876", "descricao": "Hipopotassemia" },
  { "codigo": "E877", "descricao": "Sobrecarga de líquidos" },
  {
    "codigo": "E878",
    "descricao": "Outros transtornos do equilíbrio hidroeletrolítico não classificados em outra parte"
  },
  {
    "codigo": "E880",
    "descricao": "Distúrbios do metabolismo das proteínas plasmáticas não classificados em outra parte"
  },
  {
    "codigo": "E881",
    "descricao": "Lipodistrofia não classificada em outra parte"
  },
  {
    "codigo": "E882",
    "descricao": "Lipomatose não classificada em outra parte"
  },
  {
    "codigo": "E888",
    "descricao": "Outros distúrbios especificados do metabolismo"
  },
  {
    "codigo": "E889",
    "descricao": "Distúrbio metabólico não especificado"
  },
  { "codigo": "E890", "descricao": "Hipotireoidismo pós-procedimento" },
  { "codigo": "E891", "descricao": "Hipoinsulinemia pós-procedimento" },
  {
    "codigo": "E892",
    "descricao": "Hipoparatireoidismo pós-procedimento"
  },
  { "codigo": "E893", "descricao": "Hipopituitarismo pós-procedimento" },
  {
    "codigo": "E894",
    "descricao": "Insuficiência ovariana pós-procedimento"
  },
  {
    "codigo": "E895",
    "descricao": "Hipofunção testicular pós-procedimento"
  },
  {
    "codigo": "E896",
    "descricao": "Hipofunção adrenocortical(-medular) pós-procedimento"
  },
  {
    "codigo": "E898",
    "descricao": "Outros transtornos endócrinos e metabólicos pós-procedimento"
  },
  {
    "codigo": "E899",
    "descricao": "Transtornos endócrinos e metabólicos não especificados pós-procedimento"
  },
  {
    "codigo": "E90",
    "descricao": "Transtornos nutricionais e metabólicos em doenças classificadas em outra parte"
  },
  {
    "codigo": "F000",
    "descricao": "Demência na doença de Alzheimer de início precoce"
  },
  {
    "codigo": "F001",
    "descricao": "Demência na doença de Alzheimer de início tardio"
  },
  {
    "codigo": "F002",
    "descricao": "Demência na doença de Alzheimer, forma atípica ou mista"
  },
  {
    "codigo": "F009",
    "descricao": "Demência não especificada na doença de Alzheimer"
  },
  {
    "codigo": "F010",
    "descricao": "Demência vascular de início agudo"
  },
  {
    "codigo": "F011",
    "descricao": "Demência por infartos múltiplos"
  },
  { "codigo": "F012", "descricao": "Demência vascular subcortical" },
  {
    "codigo": "F013",
    "descricao": "Demência vascular mista, cortical e subcortical"
  },
  { "codigo": "F018", "descricao": "Outra demência vascular" },
  {
    "codigo": "F019",
    "descricao": "Demência vascular não especificada"
  },
  { "codigo": "F020", "descricao": "Demência da doença de Pick" },
  {
    "codigo": "F021",
    "descricao": "Demência na doença de Creutzfeldt-Jakob"
  },
  {
    "codigo": "F022",
    "descricao": "Demência na doença de Huntington"
  },
  {
    "codigo": "F023",
    "descricao": "Demência na doença de Parkinson"
  },
  {
    "codigo": "F024",
    "descricao": "Demência na doença pelo vírus da imunodeficiência humana [HIV]"
  },
  {
    "codigo": "F028",
    "descricao": "Demência em outras doenças especificadas classificadas em outra parte"
  },
  { "codigo": "F03", "descricao": "Demência não especificada" },
  {
    "codigo": "F04",
    "descricao": "Síndrome amnésica orgânica não induzida pelo álcool ou por outras substâncias psicoativas"
  },
  {
    "codigo": "F050",
    "descricao": "Delirium não superposto a uma demência, assim descrito"
  },
  { "codigo": "F051", "descricao": "Delirium superposto a uma demência" },
  { "codigo": "F058", "descricao": "Outro delirium" },
  { "codigo": "F059", "descricao": "Delirium não especificado" },
  { "codigo": "F060", "descricao": "Alucinose orgânica" },
  { "codigo": "F061", "descricao": "Estado catatônico orgânico" },
  {
    "codigo": "F062",
    "descricao": "Transtorno delirante orgânico [tipo esquizofrênico]"
  },
  {
    "codigo": "F063",
    "descricao": "Transtornos do humor [afetivos] orgânicos"
  },
  { "codigo": "F064", "descricao": "Transtornos da ansiedade orgânicos" },
  { "codigo": "F065", "descricao": "Transtorno dissociativo orgânico" },
  {
    "codigo": "F066",
    "descricao": "Transtorno de labilidade emocional [astênico] orgânico"
  },
  { "codigo": "F067", "descricao": "Transtorno cognitivo leve" },
  {
    "codigo": "F068",
    "descricao": "Outros transtornos mentais especificados devidos a uma lesão e disfunção cerebral e a uma doença física"
  },
  {
    "codigo": "F069",
    "descricao": "Transtorno mental não especificado devido a uma lesão e disfunção cerebral e a uma doença física"
  },
  {
    "codigo": "F070",
    "descricao": "Transtorno orgânico da personalidade"
  },
  { "codigo": "F071", "descricao": "Síndrome pós-encefalítica" },
  { "codigo": "F072", "descricao": "Síndrome pós-traumática" },
  {
    "codigo": "F078",
    "descricao": "Outros transtornos orgânicos da personalidade e do comportamento devidos a doença cerebral, lesão e disfunção"
  },
  {
    "codigo": "F079",
    "descricao": "Transtorno orgânico não especificado da personalidade e do comportamento devido a doença cerebral, lesão e disfunção"
  },
  {
    "codigo": "F09",
    "descricao": "Transtorno mental orgânico ou sintomático não especificado"
  },
  {
    "codigo": "F100",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - intoxicação aguda"
  },
  {
    "codigo": "F101",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - uso nocivo para a saúde"
  },
  {
    "codigo": "F102",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome de dependência"
  },
  {
    "codigo": "F103",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F104",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F105",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - transtorno psicótico"
  },
  {
    "codigo": "F106",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome amnésica"
  },
  {
    "codigo": "F107",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F108",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F109",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de álcool - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F110",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - intoxicação aguda"
  },
  {
    "codigo": "F111",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - uso nocivo para a saúde"
  },
  {
    "codigo": "F112",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome de dependência"
  },
  {
    "codigo": "F113",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F114",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F115",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - transtorno psicótico"
  },
  {
    "codigo": "F116",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome amnésica"
  },
  {
    "codigo": "F117",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F118",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F119",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de opiáceos - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F120",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - intoxicação aguda"
  },
  {
    "codigo": "F121",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - uso nocivo para a saúde"
  },
  {
    "codigo": "F122",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome de dependência"
  },
  {
    "codigo": "F123",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F124",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F125",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - transtorno psicótico"
  },
  {
    "codigo": "F126",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome amnésica"
  },
  {
    "codigo": "F127",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F128",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F129",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de canabinóides - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F130",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - intoxicação aguda"
  },
  {
    "codigo": "F131",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - uso nocivo para a saúde"
  },
  {
    "codigo": "F132",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome de dependência"
  },
  {
    "codigo": "F133",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F134",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F135",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - transtorno psicótico"
  },
  {
    "codigo": "F136",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome amnésica"
  },
  {
    "codigo": "F137",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F138",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F139",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F140",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - intoxicação aguda"
  },
  {
    "codigo": "F141",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - uso nocivo para a saúde"
  },
  {
    "codigo": "F142",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome de dependência"
  },
  {
    "codigo": "F143",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F144",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F145",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - transtorno psicótico"
  },
  {
    "codigo": "F146",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome amnésica"
  },
  {
    "codigo": "F147",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F148",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F149",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso da cocaína - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F150",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - intoxicação aguda"
  },
  {
    "codigo": "F151",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - uso nocivo para a saúde"
  },
  {
    "codigo": "F152",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome de dependência"
  },
  {
    "codigo": "F153",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F154",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F155",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - transtorno psicótico"
  },
  {
    "codigo": "F156",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome amnésica"
  },
  {
    "codigo": "F157",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F158",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F159",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F160",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - intoxicação aguda"
  },
  {
    "codigo": "F161",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - uso nocivo para a saúde"
  },
  {
    "codigo": "F162",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome de dependência"
  },
  {
    "codigo": "F163",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F164",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F165",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - transtorno psicótico"
  },
  {
    "codigo": "F166",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome amnésica"
  },
  {
    "codigo": "F167",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F168",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F169",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F170",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - intoxicação aguda"
  },
  {
    "codigo": "F171",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - uso nocivo para a saúde"
  },
  {
    "codigo": "F172",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome de dependência"
  },
  {
    "codigo": "F173",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F174",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F175",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - transtorno psicótico"
  },
  {
    "codigo": "F176",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome amnésica"
  },
  {
    "codigo": "F177",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F178",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F179",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de fumo - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F180",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - intoxicação aguda"
  },
  {
    "codigo": "F181",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - uso nocivo para a saúde"
  },
  {
    "codigo": "F182",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome de dependência"
  },
  {
    "codigo": "F183",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F184",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F185",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - transtorno psicótico"
  },
  {
    "codigo": "F186",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome amnésica"
  },
  {
    "codigo": "F187",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F188",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F189",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - transtorno mental ou comportamental não especificado"
  },
  {
    "codigo": "F190",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - intoxicação aguda"
  },
  {
    "codigo": "F191",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - uso nocivo para a saúde"
  },
  {
    "codigo": "F192",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome de dependência"
  },
  {
    "codigo": "F193",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome [estado] de abstinência"
  },
  {
    "codigo": "F194",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome de abstinência com delirium"
  },
  {
    "codigo": "F195",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - transtorno psicótico"
  },
  {
    "codigo": "F196",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome amnésica"
  },
  {
    "codigo": "F197",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - transtorno psicótico residual ou de instalação tardia"
  },
  {
    "codigo": "F198",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - outros transtornos mentais ou comportamentais"
  },
  {
    "codigo": "F199",
    "descricao": "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - transtorno mental ou comportamental não especificado"
  },
  { "codigo": "F200", "descricao": "Esquizofrenia paranóide" },
  { "codigo": "F201", "descricao": "Esquizofrenia hebefrênica" },
  { "codigo": "F202", "descricao": "Esquizofrenia catatônica" },
  { "codigo": "F203", "descricao": "Esquizofrenia indiferenciada" },
  {
    "codigo": "F204",
    "descricao": "Depressão pós-esquizofrênica"
  },
  { "codigo": "F205", "descricao": "Esquizofrenia residual" },
  { "codigo": "F206", "descricao": "Esquizofrenia simples" },
  { "codigo": "F208", "descricao": "Outras esquizofrenias" },
  { "codigo": "F209", "descricao": "Esquizofrenia não especificada" },
  { "codigo": "F21", "descricao": "Transtorno esquizotípico" },
  { "codigo": "F220", "descricao": "Transtorno delirante" },
  {
    "codigo": "F228",
    "descricao": "Outros transtornos delirantes persistentes"
  },
  {
    "codigo": "F229",
    "descricao": "Transtorno delirante persistente não especificado"
  },
  {
    "codigo": "F230",
    "descricao": "Transtorno psicótico agudo polimorfo, sem sintomas esquizofrênicos"
  },
  {
    "codigo": "F231",
    "descricao": "Transtorno psicótico agudo polimorfo, com sintomas esquizofrênicos"
  },
  {
    "codigo": "F232",
    "descricao": "Transtorno psicótico agudo de tipo esquizofrênico (schizophrenia-like)"
  },
  {
    "codigo": "F233",
    "descricao": "Outros transtornos psicóticos agudos, essencialmente delirantes"
  },
  {
    "codigo": "F238",
    "descricao": "Outros transtornos psicóticos agudos e transitórios"
  },
  {
    "codigo": "F239",
    "descricao": "Transtorno psicótico agudo e transitório não especificado"
  },
  { "codigo": "F24", "descricao": "Transtorno delirante induzido" },
  {
    "codigo": "F250",
    "descricao": "Transtorno esquizoafetivo do tipo maníaco"
  },
  {
    "codigo": "F251",
    "descricao": "Transtorno esquizoafetivo do tipo depressivo"
  },
  { "codigo": "F252", "descricao": "Transtorno esquizoafetivo do tipo misto" },
  { "codigo": "F258", "descricao": "Outros transtornos esquizoafetivos" },
  {
    "codigo": "F259",
    "descricao": "Transtorno esquizoafetivo não especificado"
  },
  {
    "codigo": "F28",
    "descricao": "Outros transtornos psicóticos não-orgânicos"
  },
  {
    "codigo": "F29",
    "descricao": "Psicose não-orgânica não especificada"
  },
  { "codigo": "F300", "descricao": "Hipomania" },
  { "codigo": "F301", "descricao": "Mania sem sintomas psicóticos" },
  { "codigo": "F302", "descricao": "Mania com sintomas psicóticos" },
  { "codigo": "F308", "descricao": "Outros episódios maníacos" },
  {
    "codigo": "F309",
    "descricao": "Episódio maníaco não especificado"
  },
  {
    "codigo": "F310",
    "descricao": "Transtorno afetivo bipolar, episódio atual hipomaníaco"
  },
  {
    "codigo": "F311",
    "descricao": "Transtorno afetivo bipolar, episódio atual maníaco sem sintomas psicóticos"
  },
  {
    "codigo": "F312",
    "descricao": "Transtorno afetivo bipolar, episódio atual maníaco com sintomas psicóticos"
  },
  {
    "codigo": "F313",
    "descricao": "Transtorno afetivo bipolar, episódio atual depressivo leve ou moderado"
  },
  {
    "codigo": "F314",
    "descricao": "Transtorno afetivo bipolar, episódio atual depressivo grave sem sintomas psicóticos"
  },
  {
    "codigo": "F315",
    "descricao": "Transtorno afetivo bipolar, episódio atual depressivo grave com sintomas psicóticos"
  },
  {
    "codigo": "F316",
    "descricao": "Transtorno afetivo bipolar, episódio atual misto"
  },
  {
    "codigo": "F317",
    "descricao": "Transtorno afetivo bipolar, atualmente em remissão"
  },
  { "codigo": "F318", "descricao": "Outros transtornos afetivos bipolares" },
  {
    "codigo": "F319",
    "descricao": "Transtorno afetivo bipolar não especificado"
  },
  { "codigo": "F320", "descricao": "Episódio depressivo leve" },
  { "codigo": "F321", "descricao": "Episódio depressivo moderado" },
  {
    "codigo": "F322",
    "descricao": "Episódio depressivo grave sem sintomas psicóticos"
  },
  {
    "codigo": "F323",
    "descricao": "Episódio depressivo grave com sintomas psicóticos"
  },
  { "codigo": "F328", "descricao": "Outros episódios depressivos" },
  {
    "codigo": "F329",
    "descricao": "Episódio depressivo não especificado"
  },
  {
    "codigo": "F330",
    "descricao": "Transtorno depressivo recorrente, episódio atual leve"
  },
  {
    "codigo": "F331",
    "descricao": "Transtorno depressivo recorrente, episódio atual moderado"
  },
  {
    "codigo": "F332",
    "descricao": "Transtorno depressivo recorrente, episódio atual grave sem sintomas psicóticos"
  },
  {
    "codigo": "F333",
    "descricao": "Transtorno depressivo recorrente, episódio atual grave com sintomas psicóticos"
  },
  {
    "codigo": "F334",
    "descricao": "Transtorno depressivo recorrente, atualmente em remissão"
  },
  {
    "codigo": "F338",
    "descricao": "Outros transtornos depressivos recorrentes"
  },
  {
    "codigo": "F339",
    "descricao": "Transtorno depressivo recorrente sem especificação"
  },
  { "codigo": "F340", "descricao": "Ciclotimia" },
  { "codigo": "F341", "descricao": "Distimia" },
  {
    "codigo": "F348",
    "descricao": "Outros transtornos do humor [afetivos] persistentes"
  },
  {
    "codigo": "F349",
    "descricao": "Transtorno do humor [afetivo] persistente não especificado"
  },
  {
    "codigo": "F380",
    "descricao": "Outros transtornos do humor [afetivos] isolados"
  },
  {
    "codigo": "F381",
    "descricao": "Outros transtornos do humor [afetivos] recorrentes"
  },
  {
    "codigo": "F388",
    "descricao": "Outros transtornos especificados do humor [afetivos]"
  },
  {
    "codigo": "F39",
    "descricao": "Transtorno do humor [afetivo] não especificado"
  },
  { "codigo": "F400", "descricao": "Agorafobia" },
  { "codigo": "F401", "descricao": "Fobias sociais" },
  { "codigo": "F402", "descricao": "Fobias específicas (isoladas)" },
  { "codigo": "F408", "descricao": "Outros transtornos fóbico-ansiosos" },
  {
    "codigo": "F409",
    "descricao": "Transtorno fóbico-ansioso não especificado"
  },
  {
    "codigo": "F410",
    "descricao": "Transtorno de pânico [ansiedade paroxística episódica]"
  },
  { "codigo": "F411", "descricao": "Ansiedade generalizada" },
  { "codigo": "F412", "descricao": "Transtorno misto ansioso e depressivo" },
  { "codigo": "F413", "descricao": "Outros transtornos ansiosos mistos" },
  {
    "codigo": "F418",
    "descricao": "Outros transtornos ansiosos especificados"
  },
  { "codigo": "F419", "descricao": "Transtorno ansioso não especificado" },
  {
    "codigo": "F420",
    "descricao": "Transtorno obsessivo-compulsivo com predominância de idéias ou de ruminações obsessivas"
  },
  {
    "codigo": "F421",
    "descricao": "Transtorno obsessivo-compulsivo com predominância de comportamentos compulsivos [rituais obsessivos]"
  },
  {
    "codigo": "F422",
    "descricao": "Transtorno obsessivo-compulsivo, forma mista, com idéias obsessivas e comportamentos compulsivos"
  },
  { "codigo": "F428", "descricao": "Outros transtornos obsessivo-compulsivos" },
  {
    "codigo": "F429",
    "descricao": "Transtorno obsessivo-compulsivo não especificado"
  },
  { "codigo": "F430", "descricao": "Reação aguda ao stress" },
  {
    "codigo": "F431",
    "descricao": "Estado de stress pós-traumático"
  },
  { "codigo": "F432", "descricao": "Transtornos de adaptação" },
  {
    "codigo": "F438",
    "descricao": "Outras reações ao stress grave"
  },
  {
    "codigo": "F439",
    "descricao": "Reação não especificada a um stress grave"
  },
  { "codigo": "F440", "descricao": "Amnésia dissociativa" },
  { "codigo": "F441", "descricao": "Fuga dissociativa" },
  { "codigo": "F442", "descricao": "Estupor dissociativo" },
  { "codigo": "F443", "descricao": "Estados de transe e de possessão" },
  { "codigo": "F444", "descricao": "Transtornos dissociativos do movimento" },
  { "codigo": "F445", "descricao": "Convulsões dissociativas" },
  {
    "codigo": "F446",
    "descricao": "Anestesia e perda sensorial dissociativas"
  },
  {
    "codigo": "F447",
    "descricao": "Transtorno dissociativo misto [de conversão]"
  },
  {
    "codigo": "F448",
    "descricao": "Outros transtornos dissociativos [de conversão]"
  },
  {
    "codigo": "F449",
    "descricao": "Transtorno dissociativo [de conversão] não especificado"
  },
  { "codigo": "F450", "descricao": "Transtorno de somatização" },
  { "codigo": "F451", "descricao": "Transtorno somatoforme indiferenciado" },
  { "codigo": "F452", "descricao": "Transtorno hipocondríaco" },
  { "codigo": "F453", "descricao": "Transtorno neurovegetativo somatoforme" },
  {
    "codigo": "F454",
    "descricao": "Transtorno doloroso somatoforme persistente"
  },
  { "codigo": "F458", "descricao": "Outros transtornos somatoformes" },
  {
    "codigo": "F459",
    "descricao": "Transtorno somatoforme não especificado"
  },
  { "codigo": "F480", "descricao": "Neurastenia" },
  {
    "codigo": "F481",
    "descricao": "Síndrome de despersonalização-desrealização"
  },
  {
    "codigo": "F488",
    "descricao": "Outros transtornos neuróticos especificados"
  },
  {
    "codigo": "F489",
    "descricao": "Transtorno neurótico não especificado"
  },
  { "codigo": "F500", "descricao": "Anorexia nervosa" },
  { "codigo": "F501", "descricao": "Anorexia nervosa atípica" },
  { "codigo": "F502", "descricao": "Bulimia nervosa" },
  { "codigo": "F503", "descricao": "Bulimia nervosa atípica" },
  {
    "codigo": "F504",
    "descricao": "Hiperfagia associada a outros distúrbios psicológicos"
  },
  {
    "codigo": "F505",
    "descricao": "Vômitos associados a outros distúrbios psicológicos"
  },
  {
    "codigo": "F508",
    "descricao": "Outros transtornos da alimentação"
  },
  {
    "codigo": "F509",
    "descricao": "Transtorno de alimentação não especificado"
  },
  { "codigo": "F510", "descricao": "Insônia não-orgânica" },
  { "codigo": "F511", "descricao": "Hipersonia não-orgânica" },
  {
    "codigo": "F512",
    "descricao": "Transtorno do ciclo vigília-sono devido a fatores não-orgânicos"
  },
  { "codigo": "F513", "descricao": "Sonambulismo" },
  { "codigo": "F514", "descricao": "Terrores noturnos" },
  { "codigo": "F515", "descricao": "Pesadelos" },
  {
    "codigo": "F518",
    "descricao": "Outros transtornos do sono devidos a fatores não-orgânicos"
  },
  {
    "codigo": "F519",
    "descricao": "Transtorno do sono devido a fatores não-orgânicos não especificados"
  },
  { "codigo": "F520", "descricao": "Ausência ou perda do desejo sexual" },
  {
    "codigo": "F521",
    "descricao": "Aversão sexual e ausência de prazer sexual"
  },
  { "codigo": "F522", "descricao": "Falha de resposta genital" },
  { "codigo": "F523", "descricao": "Disfunção orgásmica" },
  { "codigo": "F524", "descricao": "Ejaculação precoce" },
  { "codigo": "F525", "descricao": "Vaginismo não-orgânico" },
  { "codigo": "F526", "descricao": "Dispareunia não-orgânica" },
  { "codigo": "F527", "descricao": "Apetite sexual excessivo" },
  {
    "codigo": "F528",
    "descricao": "Outras disfunções sexuais não devidas a transtorno ou à doença orgânica"
  },
  {
    "codigo": "F529",
    "descricao": "Disfunção sexual não devida a transtorno ou à doença orgânica, não especificada"
  },
  {
    "codigo": "F530",
    "descricao": "Transtornos mentais e comportamentais leves associados ao puerpério não classificados em outra parte"
  },
  {
    "codigo": "F531",
    "descricao": "Transtornos mentais e comportamentais graves associados ao puerpério não classificados em outra parte"
  },
  {
    "codigo": "F538",
    "descricao": "Outros transtornos mentais e comportamentais associados ao puerpério não classificados em outra parte"
  },
  {
    "codigo": "F539",
    "descricao": "Transtorno mental e comportamental associado ao puerpério, não especificado"
  },
  {
    "codigo": "F54",
    "descricao": "Fatores psicológicos ou comportamentais associados a doença ou a transtornos classificados em outra parte"
  },
  {
    "codigo": "F55",
    "descricao": "Abuso de substâncias que não produzem dependência"
  },
  {
    "codigo": "F59",
    "descricao": "Síndromes comportamentais associados a transtornos das funções fisiológicas e a fatores físicos, não especificadas"
  },
  { "codigo": "F600", "descricao": "Personalidade paranóica" },
  { "codigo": "F601", "descricao": "Personalidade esquizóide" },
  { "codigo": "F602", "descricao": "Personalidade dissocial" },
  {
    "codigo": "F603",
    "descricao": "Transtorno de personalidade com instabilidade emocional"
  },
  { "codigo": "F604", "descricao": "Personalidade histriônica" },
  { "codigo": "F605", "descricao": "Personalidade anancástica" },
  { "codigo": "F606", "descricao": "Personalidade ansiosa [esquiva]" },
  { "codigo": "F607", "descricao": "Personalidade dependente" },
  {
    "codigo": "F608",
    "descricao": "Outros transtornos específicos da personalidade"
  },
  {
    "codigo": "F609",
    "descricao": "Transtorno não especificado da personalidade"
  },
  {
    "codigo": "F61",
    "descricao": "Transtornos mistos da personalidade e outros transtornos da personalidade"
  },
  {
    "codigo": "F620",
    "descricao": "Modificação duradoura da personalidade após uma experiência catastrófica"
  },
  {
    "codigo": "F621",
    "descricao": "Modificação duradoura da personalidade após doença psiquiátrica"
  },
  {
    "codigo": "F628",
    "descricao": "Outras modificações duradouras da personalidade"
  },
  {
    "codigo": "F629",
    "descricao": "Modificação duradoura da personalidade, não especificada"
  },
  { "codigo": "F630", "descricao": "Jogo patológico" },
  { "codigo": "F631", "descricao": "Piromania" },
  { "codigo": "F632", "descricao": "Roubo patológico [cleptomania]" },
  { "codigo": "F633", "descricao": "Tricotilomania" },
  {
    "codigo": "F638",
    "descricao": "Outros transtornos dos hábitos e dos impulsos"
  },
  {
    "codigo": "F639",
    "descricao": "Transtorno dos hábitos e impulsos, não especificado"
  },
  { "codigo": "F640", "descricao": "Transexualismo" },
  { "codigo": "F641", "descricao": "Travestismo bivalente" },
  {
    "codigo": "F642",
    "descricao": "Transtorno de identidade sexual na infância"
  },
  { "codigo": "F648", "descricao": "Outros transtornos da identidade sexual" },
  {
    "codigo": "F649",
    "descricao": "Transtorno não especificado da identidade sexual"
  },
  { "codigo": "F650", "descricao": "Fetichismo" },
  { "codigo": "F651", "descricao": "Travestismo fetichista" },
  { "codigo": "F652", "descricao": "Exibicionismo" },
  { "codigo": "F653", "descricao": "Voyeurismo" },
  { "codigo": "F654", "descricao": "Pedofilia" },
  { "codigo": "F655", "descricao": "Sadomasoquismo" },
  {
    "codigo": "F656",
    "descricao": "Transtornos múltiplos da preferência sexual"
  },
  {
    "codigo": "F658",
    "descricao": "Outros transtornos da preferência sexual"
  },
  {
    "codigo": "F659",
    "descricao": "Transtorno da preferência sexual, não especificado"
  },
  { "codigo": "F660", "descricao": "Transtorno da maturação sexual" },
  {
    "codigo": "F661",
    "descricao": "Orientação sexual egodistônica"
  },
  { "codigo": "F662", "descricao": "Transtorno do relacionamento sexual" },
  {
    "codigo": "F668",
    "descricao": "Outros transtornos do desenvolvimento psicossexual"
  },
  {
    "codigo": "F669",
    "descricao": "Transtorno do desenvolvimento sexual, não especificado"
  },
  {
    "codigo": "F680",
    "descricao": "Sintomas físicos aumentados por fatores psicológicos"
  },
  {
    "codigo": "F681",
    "descricao": "Produção deliberada ou simulação de sintomas ou de incapacidades, físicas ou psicológicas [transtorno fictício]"
  },
  {
    "codigo": "F688",
    "descricao": "Outros transtornos especificados da personalidade e do comportamento do adulto"
  },
  {
    "codigo": "F69",
    "descricao": "Transtorno da personalidade e do comportamento do adulto, não especificado"
  },
  {
    "codigo": "F700",
    "descricao": "Retardo mental leve - menção de ausência de ou de comprometimento mínimo do comportamento"
  },
  {
    "codigo": "F701",
    "descricao": "Retardo mental leve - comprometimento significativo do comportamento, requerendo vigilância ou tratamento"
  },
  {
    "codigo": "F708",
    "descricao": "Retardo mental leve - outros comprometimentos do comportamento"
  },
  {
    "codigo": "F709",
    "descricao": "Retardo mental leve - sem menção de comprometimento do comportamento"
  },
  {
    "codigo": "F710",
    "descricao": "Retardo mental moderado - menção de ausência de ou de comprometimento mínimo do comportamento"
  },
  {
    "codigo": "F711",
    "descricao": "Retardo mental moderado - comprometimento significativo do comportamento, requerendo vigilância ou tratamento"
  },
  {
    "codigo": "F718",
    "descricao": "Retardo mental moderado - outros comprometimentos do comportamento"
  },
  {
    "codigo": "F719",
    "descricao": "Retardo mental moderado - sem menção de comprometimento do comportamento"
  },
  {
    "codigo": "F720",
    "descricao": "Retardo mental grave - menção de ausência de ou de comprometimento mínimo do comportamento"
  },
  {
    "codigo": "F721",
    "descricao": "Retardo mental grave - comprometimento significativo do comportamento, requerendo vigilância ou tratamento"
  },
  {
    "codigo": "F728",
    "descricao": "Retardo mental grave - outros comprometimentos do comportamento"
  },
  {
    "codigo": "F729",
    "descricao": "Retardo mental grave - sem menção de comprometimento do comportamento"
  },
  {
    "codigo": "F730",
    "descricao": "Retardo mental profundo - menção de ausência de ou de comprometimento mínimo do comportamento"
  },
  {
    "codigo": "F731",
    "descricao": "Retardo mental profundo - comprometimento significativo do comportamento, requerendo vigilância ou tratamento"
  },
  {
    "codigo": "F738",
    "descricao": "Retardo mental profundo - outros comprometimentos do comportamento"
  },
  {
    "codigo": "F739",
    "descricao": "Retardo mental profundo - sem menção de comprometimento do comportamento"
  },
  {
    "codigo": "F780",
    "descricao": "Outro retardo mental - menção de ausência de ou de comprometimento mínimo do comportamento"
  },
  {
    "codigo": "F781",
    "descricao": "Outro retardo mental - comprometimento significativo do comportamento, requerendo vigilância ou tratamento"
  },
  {
    "codigo": "F788",
    "descricao": "Outro retardo mental - outros comprometimentos do comportamento"
  },
  {
    "codigo": "F789",
    "descricao": "Outro retardo mental - sem menção de comprometimento do comportamento"
  },
  {
    "codigo": "F790",
    "descricao": "Retardo mental não especificado - menção de ausência de ou de comprometimento mínimo do comportamento"
  },
  {
    "codigo": "F791",
    "descricao": "Retardo mental não especificado - comprometimento significativo do comportamento, requerendo vigilância ou tratamento"
  },
  {
    "codigo": "F798",
    "descricao": "Retardo mental não especificado - outros comprometimentos do comportamento"
  },
  {
    "codigo": "F799",
    "descricao": "Retardo mental não especificado - sem menção de comprometimento do comportamento"
  },
  {
    "codigo": "F800",
    "descricao": "Transtorno específico da articulação da fala"
  },
  { "codigo": "F801", "descricao": "Transtorno expressivo de linguagem" },
  { "codigo": "F802", "descricao": "Transtorno receptivo da linguagem" },
  {
    "codigo": "F803",
    "descricao": "Afasia adquirida com epilepsia [síndrome de Landau-Kleffner]"
  },
  {
    "codigo": "F808",
    "descricao": "Outros transtornos de desenvolvimento da fala ou da linguagem"
  },
  {
    "codigo": "F809",
    "descricao": "Transtorno não especificado do desenvolvimento da fala ou da linguagem"
  },
  { "codigo": "F810", "descricao": "Transtorno específico de leitura" },
  {
    "codigo": "F811",
    "descricao": "Transtorno específico da soletração"
  },
  {
    "codigo": "F812",
    "descricao": "Transtorno específico da habilidade em aritmética"
  },
  {
    "codigo": "F813",
    "descricao": "Transtorno misto de habilidades escolares"
  },
  {
    "codigo": "F818",
    "descricao": "Outros transtornos do desenvolvimento das habilidades escolares"
  },
  {
    "codigo": "F819",
    "descricao": "Transtorno não especificado do desenvolvimento das habilidades escolares"
  },
  {
    "codigo": "F82",
    "descricao": "Transtorno específico do desenvolvimento motor"
  },
  {
    "codigo": "F83",
    "descricao": "Transtornos específicos misto do desenvolvimento"
  },
  { "codigo": "F840", "descricao": "Autismo infantil" },
  { "codigo": "F841", "descricao": "Autismo atípico" },
  { "codigo": "F842", "descricao": "Síndrome de Rett" },
  {
    "codigo": "F843",
    "descricao": "Outro transtorno desintegrativo da infância"
  },
  {
    "codigo": "F844",
    "descricao": "Transtorno com hipercinesia associada a retardo mental e a movimentos estereotipados"
  },
  { "codigo": "F845", "descricao": "Síndrome de Asperger" },
  {
    "codigo": "F848",
    "descricao": "Outros transtornos globais do desenvolvimento"
  },
  {
    "codigo": "F849",
    "descricao": "Transtornos globais não especificados do desenvolvimento"
  },
  {
    "codigo": "F88",
    "descricao": "Outros transtornos do desenvolvimento psicológico"
  },
  {
    "codigo": "F89",
    "descricao": "Transtorno do desenvolvimento psicológico não especificado"
  },
  {
    "codigo": "F900",
    "descricao": "Distúrbios da atividade e da atenção"
  },
  { "codigo": "F901", "descricao": "Transtorno hipercinético de conduta" },
  { "codigo": "F908", "descricao": "Outros transtornos hipercinéticos" },
  {
    "codigo": "F909",
    "descricao": "Transtorno hipercinético não especificado"
  },
  {
    "codigo": "F910",
    "descricao": "Distúrbio de conduta restrito ao contexto familiar"
  },
  {
    "codigo": "F911",
    "descricao": "Distúrbio de conduta não-socializado"
  },
  {
    "codigo": "F912",
    "descricao": "Distúrbio de conduta do tipo socializado"
  },
  {
    "codigo": "F913",
    "descricao": "Distúrbio desafiador e de oposição"
  },
  { "codigo": "F918", "descricao": "Outros transtornos de conduta" },
  {
    "codigo": "F919",
    "descricao": "Transtorno de conduta não especificado"
  },
  { "codigo": "F920", "descricao": "Distúrbio depressivo de conduta" },
  {
    "codigo": "F928",
    "descricao": "Outros transtornos mistos da conduta e das emoções"
  },
  {
    "codigo": "F929",
    "descricao": "Transtorno misto da conduta e das emoções não especificado"
  },
  {
    "codigo": "F930",
    "descricao": "Transtorno ligado à angústia de separação"
  },
  {
    "codigo": "F931",
    "descricao": "Transtorno fóbico ansioso da infância"
  },
  {
    "codigo": "F932",
    "descricao": "Distúrbio de ansiedade social da infância"
  },
  {
    "codigo": "F933",
    "descricao": "Transtorno de rivalidade entre irmãos"
  },
  {
    "codigo": "F938",
    "descricao": "Outros transtornos emocionais da infância"
  },
  {
    "codigo": "F939",
    "descricao": "Transtorno emocional da infância não especificado"
  },
  { "codigo": "F940", "descricao": "Mutismo eletivo" },
  {
    "codigo": "F941",
    "descricao": "Distúrbio reativo de vinculação da infância"
  },
  {
    "codigo": "F942",
    "descricao": "Transtorno de fixação da infância, com desinibição"
  },
  {
    "codigo": "F948",
    "descricao": "Outros transtornos do funcionamento social na infância"
  },
  {
    "codigo": "F949",
    "descricao": "Transtorno do funcionamento social da infância não especificado"
  },
  { "codigo": "F950", "descricao": "Tique transitório" },
  { "codigo": "F951", "descricao": "Tique motor ou vocal crônico" },
  {
    "codigo": "F952",
    "descricao": "Tiques vocais e motores múltiplos combinados [doença de Gilles de la Tourette]"
  },
  { "codigo": "F958", "descricao": "Outros tiques" },
  { "codigo": "F959", "descricao": "Tique não especificado" },
  { "codigo": "F980", "descricao": "Enurese de origem não-orgânica" },
  {
    "codigo": "F981",
    "descricao": "Encoprese de origem não-orgânica"
  },
  {
    "codigo": "F982",
    "descricao": "Transtorno de alimentação na infância"
  },
  { "codigo": "F983", "descricao": "Pica do lactente ou da criança" },
  { "codigo": "F984", "descricao": "Estereotipias motoras" },
  { "codigo": "F985", "descricao": "Gagueira [tartamudez]" },
  { "codigo": "F986", "descricao": "Linguagem precipitada" },
  {
    "codigo": "F988",
    "descricao": "Outros transtornos comportamentais e emocionais especificados com início habitualmente na infância ou adolescência"
  },
  {
    "codigo": "F989",
    "descricao": "Transtornos comportamentais e emocionais não especificados com início habitualmente na infância ou adolescência"
  },
  {
    "codigo": "F99",
    "descricao": "Transtorno mental não especificado em outra parte"
  },
  { "codigo": "G000", "descricao": "Meningite por Haemophilus" },
  { "codigo": "G001", "descricao": "Meningite pneumocócica" },
  { "codigo": "G002", "descricao": "Meningite estreptocócica" },
  { "codigo": "G003", "descricao": "Meningite estafilocócica" },
  { "codigo": "G008", "descricao": "Outras meningites bacterianas" },
  {
    "codigo": "G009",
    "descricao": "Meningite bacteriana não especificada"
  },
  {
    "codigo": "G01",
    "descricao": "Meningite em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "G020",
    "descricao": "Meningite em doenças virais classificadas em outra parte"
  },
  { "codigo": "G021", "descricao": "Meningite em micoses" },
  {
    "codigo": "G028",
    "descricao": "Meningite em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  { "codigo": "G030", "descricao": "Meningite não-piogênica" },
  { "codigo": "G031", "descricao": "Meningite crônica" },
  { "codigo": "G032", "descricao": "Meningite recorrente benigna [Mollaret]" },
  {
    "codigo": "G038",
    "descricao": "Meningite devida a outras causas especificadas"
  },
  { "codigo": "G039", "descricao": "Meningite não especificada" },
  { "codigo": "G040", "descricao": "Encefalite aguda disseminada" },
  { "codigo": "G041", "descricao": "Paraplegia espástica tropical" },
  {
    "codigo": "G042",
    "descricao": "Meningoencefalite e meningomielite bacterianas não classificadas em outra parte"
  },
  {
    "codigo": "G048",
    "descricao": "Outras encefalites, mielites e encefalomielites"
  },
  {
    "codigo": "G049",
    "descricao": "Encefalite, mielite e encefalomielite não especificada"
  },
  {
    "codigo": "G050",
    "descricao": "Encefalite, mielite e encefalomielite em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "G051",
    "descricao": "Encefalite, mielite e encefalomielite em doenças virais classificadas em outra parte"
  },
  {
    "codigo": "G052",
    "descricao": "Encefalite, mielite e encefalomielite em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "G058",
    "descricao": "Encefalite, mielite e encefalomielite em outras doenças classificadas em outra parte"
  },
  { "codigo": "G060", "descricao": "Abscesso e granuloma intracranianos" },
  { "codigo": "G061", "descricao": "Abscesso e granuloma intra-raquidianos" },
  {
    "codigo": "G062",
    "descricao": "Abscesso extradural e subdural não especificados"
  },
  {
    "codigo": "G07",
    "descricao": "Abscesso e granuloma intracranianos e intraspinais em doenças classificadas em outra parte"
  },
  {
    "codigo": "G08",
    "descricao": "Flebite e tromboflebite intracranianas e intra-raquidianas"
  },
  {
    "codigo": "G09",
    "descricao": "Seqüelas de doenças inflamatórias do sistema nervoso central"
  },
  { "codigo": "G10", "descricao": "Doença de Huntington" },
  {
    "codigo": "G110",
    "descricao": "Ataxia congênita não-progressiva"
  },
  { "codigo": "G111", "descricao": "Ataxia cerebelar de início precoce" },
  { "codigo": "G112", "descricao": "Ataxia cerebelar de início tardio" },
  {
    "codigo": "G113",
    "descricao": "Ataxia cerebelar com déficit na reparação do DNA"
  },
  {
    "codigo": "G114",
    "descricao": "Paraplegia espástica hereditária"
  },
  { "codigo": "G118", "descricao": "Outras ataxias hereditárias" },
  {
    "codigo": "G119",
    "descricao": "Ataxia hereditária não especificada"
  },
  {
    "codigo": "G120",
    "descricao": "Atrofia muscular espinal infantil tipo I [Werdnig-Hoffman]"
  },
  {
    "codigo": "G121",
    "descricao": "Outras atrofias musculares espinais hereditárias"
  },
  { "codigo": "G122", "descricao": "Doença do neurônio motor" },
  {
    "codigo": "G128",
    "descricao": "Outras atrofias musculares espinais e síndromes musculares correlatas"
  },
  {
    "codigo": "G129",
    "descricao": "Atrofia muscular espinal não especificada"
  },
  {
    "codigo": "G130",
    "descricao": "Neuromiopatia e neuropatia paraneoplásicas"
  },
  {
    "codigo": "G131",
    "descricao": "Outra atrofia sistêmica que afeta primariamente o sistema nervoso central em doenças neoplásicas"
  },
  {
    "codigo": "G132",
    "descricao": "Atrofia sistêmica que afeta primariamente o sistema nervoso central no mixedema"
  },
  {
    "codigo": "G138",
    "descricao": "Atrofia sistêmica que afeta primariamente o sistema nervoso central em outras doenças classificadas em outra parte"
  },
  { "codigo": "G20", "descricao": "Doença de Parkinson" },
  {
    "codigo": "G210",
    "descricao": "Síndrome maligna dos neurolépticos"
  },
  {
    "codigo": "G211",
    "descricao": "Outras formas de parkinsonismo secundário induzido por drogas"
  },
  {
    "codigo": "G212",
    "descricao": "Parkinsonismo secundário devido a outros agentes externos"
  },
  { "codigo": "G213", "descricao": "Parkinsonismo pós-encefalítico" },
  {
    "codigo": "G218",
    "descricao": "Outras formas de parkinsonismo secundário"
  },
  {
    "codigo": "G219",
    "descricao": "Parkinsonismo secundário não especificado"
  },
  {
    "codigo": "G22",
    "descricao": "Parkinsonismo em doenças classificadas em outra parte"
  },
  { "codigo": "G230", "descricao": "Doença de Hallervorden-Spatz" },
  {
    "codigo": "G231",
    "descricao": "Oftalmoplegia supranuclear progressiva [Steele-Richardson-Olszewski]"
  },
  { "codigo": "G232", "descricao": "Degeneração estrionígrica" },
  {
    "codigo": "G238",
    "descricao": "Outras doenças degenerativas especificadas dos gânglios da base"
  },
  {
    "codigo": "G239",
    "descricao": "Doença degenerativa dos gânglios da base, não especificada"
  },
  { "codigo": "G240", "descricao": "Distonia induzida por drogas" },
  { "codigo": "G241", "descricao": "Distonia familiar idiopática" },
  {
    "codigo": "G242",
    "descricao": "Distonia não-familiar idiopática"
  },
  { "codigo": "G243", "descricao": "Torcicolo espasmódico" },
  { "codigo": "G244", "descricao": "Distonia orofacial idiopática" },
  { "codigo": "G245", "descricao": "Blefaroespasmo" },
  { "codigo": "G248", "descricao": "Outras distonias" },
  { "codigo": "G249", "descricao": "Distonia não especificada" },
  { "codigo": "G250", "descricao": "Tremor essencial" },
  { "codigo": "G251", "descricao": "Tremor induzido por drogas" },
  { "codigo": "G252", "descricao": "Outras formas especificadas de tremor" },
  { "codigo": "G253", "descricao": "Mioclonia" },
  { "codigo": "G254", "descricao": "Coréia induzida por droga" },
  { "codigo": "G255", "descricao": "Outras formas de coréia" },
  {
    "codigo": "G256",
    "descricao": "Tiques induzidos por droga e outros tipos de origem orgânica"
  },
  {
    "codigo": "G258",
    "descricao": "Outras doenças extrapiramidais e transtornos dos movimentos, especificados"
  },
  {
    "codigo": "G259",
    "descricao": "Doenças extrapiramidais e transtornos dos movimentos, não especificados"
  },
  {
    "codigo": "G26",
    "descricao": "Doenças extrapiramidais e transtornos dos movimentos em doenças classificadas em outra parte"
  },
  {
    "codigo": "G300",
    "descricao": "Doença de Alzheimer de início precoce"
  },
  {
    "codigo": "G301",
    "descricao": "Doença de Alzheimer de início tardio"
  },
  {
    "codigo": "G308",
    "descricao": "Outras formas de doença de Alzheimer"
  },
  {
    "codigo": "G309",
    "descricao": "Doença de Alzheimer não especificada"
  },
  { "codigo": "G310", "descricao": "Atrofia cerebral circunscrita" },
  {
    "codigo": "G311",
    "descricao": "Degeneração cerebral senil, não classificadas em outra parte"
  },
  {
    "codigo": "G312",
    "descricao": "Degeneração do sistema nervoso devida ao álcool"
  },
  {
    "codigo": "G318",
    "descricao": "Outras doenças degenerativas especificadas do sistema nervoso"
  },
  {
    "codigo": "G319",
    "descricao": "Doença degenerativa do sistema nervoso, não especificada"
  },
  {
    "codigo": "G320",
    "descricao": "Degeneração combinada subaguda da medula espinal em doenças classificadas em outra parte"
  },
  {
    "codigo": "G328",
    "descricao": "Outros transtornos degenerativos especificados do sistema nervoso em doenças classificadas em outra parte"
  },
  { "codigo": "G35", "descricao": "Esclerose múltipla" },
  {
    "codigo": "G360",
    "descricao": "Neuromielite óptica [doença de Devic]"
  },
  {
    "codigo": "G361",
    "descricao": "Leucoencefalite hemorrágica aguda e subaguda [Hurst]"
  },
  {
    "codigo": "G368",
    "descricao": "Outras desmielinizações disseminadas agudas especificadas"
  },
  {
    "codigo": "G369",
    "descricao": "Desmielinização disseminada aguda não especificada"
  },
  { "codigo": "G370", "descricao": "Esclerose difusa" },
  {
    "codigo": "G371",
    "descricao": "Desmielinização central do corpo caloso"
  },
  { "codigo": "G372", "descricao": "Mielinólise central da ponte" },
  {
    "codigo": "G373",
    "descricao": "Mielite transversa aguda em doenças desmielinizantes do sistema nervoso central"
  },
  { "codigo": "G374", "descricao": "Mielite subaguda necrótica" },
  { "codigo": "G375", "descricao": "Esclerose concêntrica [Baló]" },
  {
    "codigo": "G378",
    "descricao": "Outras doenças desmielinizantes especificadas do sistema nervoso central"
  },
  {
    "codigo": "G379",
    "descricao": "Doença desmielinizante do sistema nervoso central, não especificada"
  },
  {
    "codigo": "G400",
    "descricao": "Epilepsia e síndromes epilépticas idiopáticas definidas por sua localização (focal) (parcial) com crises de início focal"
  },
  {
    "codigo": "G401",
    "descricao": "Epilepsia e síndromes epilépticas sintomáticas definidas por sua localização (focal) (parcial) com crises parciais simples"
  },
  {
    "codigo": "G402",
    "descricao": "Epilepsia e síndromes epilépticas sintomáticas definidas por sua localização (focal) (parcial) com crises parciais complexas"
  },
  {
    "codigo": "G403",
    "descricao": "Epilepsia e síndromes epilépticas generalizadas idiopáticas"
  },
  {
    "codigo": "G404",
    "descricao": "Outras epilepsias e síndromes epilépticas generalizadas"
  },
  {
    "codigo": "G405",
    "descricao": "Síndromes epilépticas especiais"
  },
  {
    "codigo": "G406",
    "descricao": "Crise de grande mal, não especificada (com ou sem pequeno mal)"
  },
  {
    "codigo": "G407",
    "descricao": "Pequeno mal não especificado, sem crises de grande mal"
  },
  { "codigo": "G408", "descricao": "Outras epilepsias" },
  { "codigo": "G409", "descricao": "Epilepsia, não especificada" },
  { "codigo": "G410", "descricao": "Estado de grande mal epiléptico" },
  { "codigo": "G411", "descricao": "Estado de pequeno mal epiléptico" },
  {
    "codigo": "G412",
    "descricao": "Estado de mal epiléptico parcial complexo"
  },
  { "codigo": "G418", "descricao": "Outros estados de mal epiléptico" },
  {
    "codigo": "G419",
    "descricao": "Estado de mal epiléptico, não especificado"
  },
  { "codigo": "G430", "descricao": "Enxaqueca sem aura [enxaqueca comum]" },
  {
    "codigo": "G431",
    "descricao": "Enxaqueca com aura [enxaqueca clássica]"
  },
  { "codigo": "G432", "descricao": "Estado de mal enxaquecoso" },
  { "codigo": "G433", "descricao": "Enxaqueca complicada" },
  { "codigo": "G438", "descricao": "Outras formas de enxaqueca" },
  { "codigo": "G439", "descricao": "Enxaqueca, sem especificação" },
  {
    "codigo": "G440",
    "descricao": "Síndrome de cluster-headache"
  },
  {
    "codigo": "G441",
    "descricao": "Cefaléia vascular, não classificada em outra parte"
  },
  { "codigo": "G442", "descricao": "Cefaléia tensional" },
  {
    "codigo": "G443",
    "descricao": "Cefaléia crônica pós-traumática"
  },
  {
    "codigo": "G444",
    "descricao": "Cefaléia induzida por drogas, não classificada em outra parte"
  },
  {
    "codigo": "G448",
    "descricao": "Outras síndromes de cefaléia especificadas"
  },
  {
    "codigo": "G450",
    "descricao": "Síndrome da artéria vértebro-basilar"
  },
  {
    "codigo": "G451",
    "descricao": "Síndrome da artéria carotídea (hemisférica)"
  },
  {
    "codigo": "G452",
    "descricao": "Síndrome das artérias pré-cerebrais, múltiplas e bilaterais"
  },
  { "codigo": "G453", "descricao": "Amaurose fugaz" },
  { "codigo": "G454", "descricao": "Amnésia global transitória" },
  {
    "codigo": "G458",
    "descricao": "Outros acidentes isquêmicos cerebrais transitórios e síndromes correlatas"
  },
  {
    "codigo": "G459",
    "descricao": "Isquemia cerebral transitória não especificada"
  },
  {
    "codigo": "G460",
    "descricao": "Síndrome da artéria cerebral média"
  },
  {
    "codigo": "G461",
    "descricao": "Síndrome da artéria cerebral anterior"
  },
  {
    "codigo": "G462",
    "descricao": "Síndrome da artéria cerebral posterior"
  },
  {
    "codigo": "G463",
    "descricao": "Síndromes vasculares do tronco cerebral"
  },
  { "codigo": "G464", "descricao": "Síndrome vascular cerebelar" },
  { "codigo": "G465", "descricao": "Síndrome lacunar motora pura" },
  { "codigo": "G466", "descricao": "Síndrome lacunar sensorial pura" },
  { "codigo": "G467", "descricao": "Outras síndromes lacunares" },
  {
    "codigo": "G468",
    "descricao": "Outras síndromes vasculares cerebrais em doenças cerebrovasculares"
  },
  {
    "codigo": "G470",
    "descricao": "Distúrbios do início e da manutenção do sono [insônias]"
  },
  {
    "codigo": "G471",
    "descricao": "Distúrbios do sono por sonolência excessiva [hipersonia]"
  },
  {
    "codigo": "G472",
    "descricao": "Distúrbios do ciclo vigília-sono"
  },
  { "codigo": "G473", "descricao": "Apnéia de sono" },
  { "codigo": "G474", "descricao": "Narcolepsia e cataplexia" },
  { "codigo": "G478", "descricao": "Outros distúrbios do sono" },
  {
    "codigo": "G479",
    "descricao": "Distúrbio do sono, não especificado"
  },
  { "codigo": "G500", "descricao": "Nevralgia do trigêmeo" },
  { "codigo": "G501", "descricao": "Dor facial atípica" },
  {
    "codigo": "G508",
    "descricao": "Outros transtornos do nervo trigêmeo"
  },
  {
    "codigo": "G509",
    "descricao": "Transtorno não especificado do nervo trigêmeo"
  },
  { "codigo": "G510", "descricao": "Paralisia de Bell" },
  { "codigo": "G511", "descricao": "Ganglionite geniculada" },
  { "codigo": "G512", "descricao": "Síndrome de Melkersson" },
  { "codigo": "G513", "descricao": "Espasmo hemifacial clônico" },
  { "codigo": "G514", "descricao": "Mioquimia facial" },
  { "codigo": "G518", "descricao": "Outros transtornos do nervo facial" },
  {
    "codigo": "G519",
    "descricao": "Transtorno não especificado do nervo facial"
  },
  { "codigo": "G520", "descricao": "Transtornos do nervo olfatório" },
  { "codigo": "G521", "descricao": "Transtornos do nervo glossofaríngeo" },
  { "codigo": "G522", "descricao": "Transtornos do nervo vago" },
  { "codigo": "G523", "descricao": "Transtornos do nervo hipoglosso" },
  {
    "codigo": "G527",
    "descricao": "Transtornos de múltiplos nervos cranianos"
  },
  {
    "codigo": "G528",
    "descricao": "Transtornos de outros nervos cranianos especificados"
  },
  {
    "codigo": "G529",
    "descricao": "Transtorno de nervo craniano não especificado"
  },
  { "codigo": "G530", "descricao": "Nevralgia pós-zoster" },
  {
    "codigo": "G531",
    "descricao": "Paralisias de múltiplos nervos cranianos em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "G532",
    "descricao": "Paralisias de múltiplos nervos cranianos na sarcoidose"
  },
  {
    "codigo": "G533",
    "descricao": "Paralisias de múltiplos nervos cranianos em doenças neoplásicas"
  },
  {
    "codigo": "G538",
    "descricao": "Outros transtornos de nervos cranianos em outras doenças classificadas em outra parte"
  },
  { "codigo": "G540", "descricao": "Transtornos do plexo braquial" },
  { "codigo": "G541", "descricao": "Transtornos do plexo lombossacral" },
  {
    "codigo": "G542",
    "descricao": "Transtornos das raízes cervicais não classificadas em outra parte"
  },
  {
    "codigo": "G543",
    "descricao": "Transtornos das raízes torácicas não classificadas em outra parte"
  },
  {
    "codigo": "G544",
    "descricao": "Transtornos das raízes lombossacras não classificadas em outra parte"
  },
  { "codigo": "G545", "descricao": "Amiotrofia nevrálgica" },
  {
    "codigo": "G546",
    "descricao": "Síndrome dolorosa do membro fantasma"
  },
  {
    "codigo": "G547",
    "descricao": "Síndrome do membro fantasma sem manifestação dolorosa"
  },
  {
    "codigo": "G548",
    "descricao": "Outros transtornos das raízes e dos plexos nervosos"
  },
  {
    "codigo": "G549",
    "descricao": "Transtorno não especificado das raízes e dos plexos nervosos"
  },
  {
    "codigo": "G550",
    "descricao": "Compressões das raízes e dos plexos nervosos em doenças neoplásicas"
  },
  {
    "codigo": "G551",
    "descricao": "Compressões das raízes e dos plexos nervosos em transtornos dos discos intervertebrais"
  },
  {
    "codigo": "G552",
    "descricao": "Compressões das raízes e dos plexos nervosos na espondilose"
  },
  {
    "codigo": "G553",
    "descricao": "Compressões das raízes e dos plexos nervosos em outras dorsopatias"
  },
  {
    "codigo": "G558",
    "descricao": "Compressões das raízes e dos plexos nervosos em outras doenças classificadas em outra parte"
  },
  { "codigo": "G560", "descricao": "Síndrome do túnel do carpo" },
  { "codigo": "G561", "descricao": "Outras lesões do nervo mediano" },
  { "codigo": "G562", "descricao": "Lesões do nervo cubital [ulnar]" },
  { "codigo": "G563", "descricao": "Lesão do nervo radial" },
  { "codigo": "G564", "descricao": "Causalgia" },
  {
    "codigo": "G568",
    "descricao": "Outras mononeuropatias dos membros superiores"
  },
  {
    "codigo": "G569",
    "descricao": "Mononeuropatia dos membros superiores, não especificada"
  },
  { "codigo": "G570", "descricao": "Lesão do nervo ciático" },
  { "codigo": "G571", "descricao": "Meralgia parestésica" },
  { "codigo": "G572", "descricao": "Lesão do nervo femoral" },
  {
    "codigo": "G573",
    "descricao": "Lesão do nervo poplíteo lateral"
  },
  { "codigo": "G574", "descricao": "Lesão do nervo poplíteo medial" },
  { "codigo": "G575", "descricao": "Síndrome do túnel do tarso" },
  { "codigo": "G576", "descricao": "Lesão do nervo plantar" },
  {
    "codigo": "G578",
    "descricao": "Outras mononeuropatias dos membros inferiores"
  },
  {
    "codigo": "G579",
    "descricao": "Mononeuropatia dos membros inferiores, não especificada"
  },
  { "codigo": "G580", "descricao": "Neuropatia intercostal" },
  { "codigo": "G587", "descricao": "Mononeurite múltipla" },
  { "codigo": "G588", "descricao": "Outras mononeuropatias especificadas" },
  { "codigo": "G589", "descricao": "Mononeuropatia não especificada" },
  { "codigo": "G590", "descricao": "Mononeuropatia diabética" },
  {
    "codigo": "G598",
    "descricao": "Outras mononeuropatias em doenças classificadas em outra parte"
  },
  {
    "codigo": "G600",
    "descricao": "Neuropatia hereditária motora e sensorial"
  },
  { "codigo": "G601", "descricao": "Doença de Refsum" },
  {
    "codigo": "G602",
    "descricao": "Neuropatia associada a ataxia hereditária"
  },
  { "codigo": "G603", "descricao": "Neuropatia progressiva idiopática" },
  {
    "codigo": "G608",
    "descricao": "Outras neuropatias hereditárias e idiopáticas"
  },
  {
    "codigo": "G609",
    "descricao": "Neuropatia hereditária e idiopática não especificada"
  },
  { "codigo": "G610", "descricao": "Síndrome de Guillain-Barré" },
  { "codigo": "G611", "descricao": "Neuropatia sérica" },
  {
    "codigo": "G618",
    "descricao": "Outras polineuropatias inflamatórias"
  },
  {
    "codigo": "G619",
    "descricao": "Polineuropatia inflamatória não especificada"
  },
  { "codigo": "G620", "descricao": "Polineuropatia induzida por drogas" },
  { "codigo": "G621", "descricao": "Polineuropatia alcoólica" },
  {
    "codigo": "G622",
    "descricao": "Polineuropatia devida a outros agentes tóxicos"
  },
  { "codigo": "G628", "descricao": "Outras polineuropatias especificadas" },
  { "codigo": "G629", "descricao": "Polineuropatia não especificada" },
  {
    "codigo": "G630",
    "descricao": "Polineuropatia em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "G631",
    "descricao": "Polineuropatia em doenças neoplásicas"
  },
  { "codigo": "G632", "descricao": "Polineuropatia diabética" },
  {
    "codigo": "G633",
    "descricao": "Polineuropatia em outras doenças endócrinas e metabólicas"
  },
  {
    "codigo": "G634",
    "descricao": "Polineuropatia em deficiências nutricionais"
  },
  {
    "codigo": "G635",
    "descricao": "Polineuropatia em doenças sistêmicas do tecido conjuntivo"
  },
  {
    "codigo": "G636",
    "descricao": "Polineuropatia em outros transtornos osteomusculares"
  },
  {
    "codigo": "G638",
    "descricao": "Polineuropatia em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "G64",
    "descricao": "Outros transtornos do sistema nervoso periférico"
  },
  { "codigo": "G700", "descricao": "Miastenia gravis" },
  { "codigo": "G701", "descricao": "Transtornos mioneurais tóxicos" },
  {
    "codigo": "G702",
    "descricao": "Miastenia congênita e do desenvolvimento"
  },
  {
    "codigo": "G708",
    "descricao": "Outros transtornos mioneurais especificados"
  },
  {
    "codigo": "G709",
    "descricao": "Transtorno mioneural não especificado"
  },
  { "codigo": "G710", "descricao": "Distrofia muscular" },
  { "codigo": "G711", "descricao": "Transtornos miotônicos" },
  { "codigo": "G712", "descricao": "Miopatias congênitas" },
  {
    "codigo": "G713",
    "descricao": "Miopatia mitocondrial não classificada em outra parte"
  },
  {
    "codigo": "G718",
    "descricao": "Outros transtornos primários dos músculos"
  },
  {
    "codigo": "G719",
    "descricao": "Transtorno muscular primário não especificado"
  },
  { "codigo": "G720", "descricao": "Miopatia induzida por drogas" },
  { "codigo": "G721", "descricao": "Miopatia alcoólica" },
  {
    "codigo": "G722",
    "descricao": "Miopatia devida a outros agentes tóxicos"
  },
  { "codigo": "G723", "descricao": "Paralisia periódica" },
  {
    "codigo": "G724",
    "descricao": "Miopatia inflamatória não classificada em outra parte"
  },
  { "codigo": "G728", "descricao": "Outras miopatias especificadas" },
  { "codigo": "G729", "descricao": "Miopatia não especificada" },
  {
    "codigo": "G730",
    "descricao": "Síndromes miastênicas em doenças endócrinas"
  },
  { "codigo": "G731", "descricao": "Síndrome de Eaton-Lambert" },
  {
    "codigo": "G732",
    "descricao": "Outras síndromes miastênicas em doenças neoplásicas"
  },
  {
    "codigo": "G733",
    "descricao": "Síndromes miastênicas em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "G734",
    "descricao": "Miopatia em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  { "codigo": "G735", "descricao": "Miopatia em doenças endócrinas" },
  {
    "codigo": "G736",
    "descricao": "Miopatia em doenças metabólicas"
  },
  {
    "codigo": "G737",
    "descricao": "Miopatia em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "G800",
    "descricao": "Paralisia cerebral quadriplágica espástica"
  },
  {
    "codigo": "G801",
    "descricao": "Paralisia cerebral diplégica espástica"
  },
  {
    "codigo": "G802",
    "descricao": "Paralisia cerebral hemiplégica espástica"
  },
  { "codigo": "G803", "descricao": "Paralisia cerebral discinética" },
  { "codigo": "G804", "descricao": "Paralisia cerebral atáxica" },
  { "codigo": "G808", "descricao": "Outras formas de paralisia cerebral" },
  { "codigo": "G809", "descricao": "Paralisia cerebral não especificada" },
  { "codigo": "G810", "descricao": "Hemiplegia flácida" },
  { "codigo": "G811", "descricao": "Hemiplegia espástica" },
  { "codigo": "G819", "descricao": "Hemiplegia não especificada" },
  { "codigo": "G820", "descricao": "Paraplegia flácida" },
  { "codigo": "G821", "descricao": "Paraplegia espástica" },
  { "codigo": "G822", "descricao": "Paraplegia não especificada" },
  { "codigo": "G823", "descricao": "Tetraplegia flácida" },
  { "codigo": "G824", "descricao": "Tetraplegia espástica" },
  { "codigo": "G825", "descricao": "Tetraplegia não especificada" },
  { "codigo": "G830", "descricao": "Diplegia dos membros superiores" },
  { "codigo": "G831", "descricao": "Monoplegia do membro inferior" },
  { "codigo": "G832", "descricao": "Monoplegia do membro superior" },
  { "codigo": "G833", "descricao": "Monoplegia não especificada" },
  { "codigo": "G834", "descricao": "Síndrome da cauda eqüina" },
  {
    "codigo": "G838",
    "descricao": "Outras síndromes paralíticas especificadas"
  },
  {
    "codigo": "G839",
    "descricao": "Síndrome paralítica não especificada"
  },
  {
    "codigo": "G900",
    "descricao": "Neuropatia autonômica periférica idiopática"
  },
  {
    "codigo": "G901",
    "descricao": "Disautonomia familiar [Síndrome de Riley-Day]"
  },
  { "codigo": "G902", "descricao": "Síndrome de Horner" },
  {
    "codigo": "G903",
    "descricao": "Degeneração multissistêmica"
  },
  { "codigo": "G904", "descricao": "Disreflexia autonômica" },
  {
    "codigo": "G908",
    "descricao": "Outros transtornos do sistema nervoso autônomo"
  },
  {
    "codigo": "G909",
    "descricao": "Transtorno não especificado do sistema nervoso autônomo"
  },
  { "codigo": "G910", "descricao": "Hidrocefalia comunicante" },
  { "codigo": "G911", "descricao": "Hidrocefalia obstrutiva" },
  { "codigo": "G912", "descricao": "Hidrocefalia de pressão normal" },
  {
    "codigo": "G913",
    "descricao": "Hidrocefalia pós-traumática não especificada"
  },
  { "codigo": "G918", "descricao": "Outras formas de hidrocefalia" },
  { "codigo": "G919", "descricao": "Hidrocefalia não especificada" },
  { "codigo": "G92", "descricao": "Encefalopatia tóxica" },
  { "codigo": "G930", "descricao": "Cistos cerebrais" },
  {
    "codigo": "G931",
    "descricao": "Lesão encefálica anóxica, não classificada em outra parte"
  },
  { "codigo": "G932", "descricao": "Hipertensão intracraniana benigna" },
  { "codigo": "G933", "descricao": "Síndrome da fadiga pós-viral" },
  { "codigo": "G934", "descricao": "Encefalopatia não especificada" },
  { "codigo": "G935", "descricao": "Compressão do encéfalo" },
  { "codigo": "G936", "descricao": "Edema cerebral" },
  { "codigo": "G937", "descricao": "Síndrome de Reye" },
  {
    "codigo": "G938",
    "descricao": "Outros transtornos especificados do encéfalo"
  },
  {
    "codigo": "G939",
    "descricao": "Transtorno não especificado do encéfalo"
  },
  {
    "codigo": "G940",
    "descricao": "Hidrocefalia em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "G941",
    "descricao": "Hidrocefalia em doenças neoplásicas"
  },
  {
    "codigo": "G942",
    "descricao": "Hidrocefalia em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "G948",
    "descricao": "Outros transtornos encefálicos especificados em doenças classificadas em outra parte"
  },
  { "codigo": "G950", "descricao": "Siringomielia e siringobulbia" },
  { "codigo": "G951", "descricao": "Mielopatias vasculares" },
  {
    "codigo": "G952",
    "descricao": "Compressão não especificada de medula espinal"
  },
  {
    "codigo": "G958",
    "descricao": "Outras doenças especificadas da medula espinal"
  },
  {
    "codigo": "G959",
    "descricao": "Doença não especificada da medula espinal"
  },
  { "codigo": "G960", "descricao": "Fístula liquórica" },
  {
    "codigo": "G961",
    "descricao": "Transtornos das meninges não classificados em outra parte"
  },
  {
    "codigo": "G968",
    "descricao": "Outros transtornos especificados do sistema nervoso central"
  },
  {
    "codigo": "G969",
    "descricao": "Transtorno não especificado do sistema nervoso central"
  },
  {
    "codigo": "G970",
    "descricao": "Perda de líquor resultante de punção espinal"
  },
  {
    "codigo": "G971",
    "descricao": "Outra reação à punção espinal e lombar"
  },
  {
    "codigo": "G972",
    "descricao": "Hipotensão intracraniana conseqüente à derivação ventricular"
  },
  {
    "codigo": "G978",
    "descricao": "Outros transtornos pós-procedimento do sistema nervoso"
  },
  {
    "codigo": "G979",
    "descricao": "Transtorno pós-procedimento do sistema nervoso, não especificado"
  },
  {
    "codigo": "G98",
    "descricao": "Outros transtornos do sistema nervoso não classificados em outra parte"
  },
  {
    "codigo": "G990",
    "descricao": "Neuropatia autonômica em doenças endócrinas e metabólicas"
  },
  {
    "codigo": "G991",
    "descricao": "Outros transtornos do sistema nervoso autônomo em doenças classificadas em outra parte"
  },
  {
    "codigo": "G992",
    "descricao": "Mielopatia em doenças classificadas em outra parte"
  },
  {
    "codigo": "G998",
    "descricao": "Outros transtornos especificados do sistema nervoso em doenças classificadas em outra parte"
  },
  {
    "codigo": "H000",
    "descricao": "Hordéolo e outras inflamações profundas das pálpebras"
  },
  { "codigo": "H001", "descricao": "Calázio" },
  { "codigo": "H010", "descricao": "Blefarite" },
  {
    "codigo": "H011",
    "descricao": "Dermatoses não infecciosas da pálpebra"
  },
  {
    "codigo": "H018",
    "descricao": "Outras inflamações especificadas da pálpebra"
  },
  {
    "codigo": "H019",
    "descricao": "Inflamação não especificada da pálpebra"
  },
  {
    "codigo": "H020",
    "descricao": "Entrópio e triquíase da pálpebra"
  },
  { "codigo": "H021", "descricao": "Ectrópio da pálpebra" },
  { "codigo": "H022", "descricao": "Lagoftalmo" },
  { "codigo": "H023", "descricao": "Blefarocalásia" },
  { "codigo": "H024", "descricao": "Ptose da pálpebra" },
  {
    "codigo": "H025",
    "descricao": "Outros transtornos que afetam a função da pálpebra"
  },
  { "codigo": "H026", "descricao": "Xantelasma da pálpebra" },
  {
    "codigo": "H027",
    "descricao": "Outros transtornos degenerativos da pálpebra e da área periocular"
  },
  {
    "codigo": "H028",
    "descricao": "Outros transtornos especificados das pálpebras"
  },
  {
    "codigo": "H029",
    "descricao": "Transtorno não especificado da pálpebra"
  },
  {
    "codigo": "H030",
    "descricao": "Infestação parasitária da pálpebra em doenças classificadas em outra parte"
  },
  {
    "codigo": "H031",
    "descricao": "Comprometimento da pálpebra em outras doenças infecciosas classificadas em outra parte"
  },
  {
    "codigo": "H038",
    "descricao": "Comprometimento da pálpebra em outras doenças classificadas em outras partes"
  },
  { "codigo": "H040", "descricao": "Dacrioadenite" },
  {
    "codigo": "H041",
    "descricao": "Outros transtornos da glândula lacrimal"
  },
  { "codigo": "H042", "descricao": "Epífora" },
  {
    "codigo": "H043",
    "descricao": "Inflamação aguda e não especificada dos canais lacrimais"
  },
  {
    "codigo": "H044",
    "descricao": "Inflamação crônica dos canais lacrimais"
  },
  {
    "codigo": "H045",
    "descricao": "Estenose e insuficiência dos canais lacrimais"
  },
  {
    "codigo": "H046",
    "descricao": "Outras alterações nos canais lacrimais"
  },
  { "codigo": "H048", "descricao": "Outros transtornos do aparelho lacrimal" },
  {
    "codigo": "H049",
    "descricao": "Transtorno não especificado do aparelho lacrimal"
  },
  {
    "codigo": "H050",
    "descricao": "Inflamação aguda da órbita"
  },
  {
    "codigo": "H051",
    "descricao": "Transtornos inflamatórios crônicos da órbita"
  },
  { "codigo": "H052", "descricao": "Afecções exoftálmicas" },
  { "codigo": "H053", "descricao": "Deformidade da órbita" },
  { "codigo": "H054", "descricao": "Enoftalmia" },
  {
    "codigo": "H055",
    "descricao": "Corpo estranho (antigo) retido conseqüente a ferimento perfurante da órbita"
  },
  { "codigo": "H058", "descricao": "Outros transtornos da órbita" },
  {
    "codigo": "H059",
    "descricao": "Transtorno não especificado da órbita"
  },
  {
    "codigo": "H060",
    "descricao": "Transtornos do aparelho lacrimal em doenças classificadas em outra parte"
  },
  {
    "codigo": "H061",
    "descricao": "Infestação parasitária da órbita em doenças classificadas em outra parte"
  },
  { "codigo": "H062", "descricao": "Exoftalmo distireoídeo" },
  {
    "codigo": "H063",
    "descricao": "Outros transtornos da órbita em doenças classificadas em outra parte"
  },
  { "codigo": "H100", "descricao": "Conjuntivite mucopurulenta" },
  { "codigo": "H101", "descricao": "Conjuntivite aguda atópica" },
  { "codigo": "H102", "descricao": "Outras conjuntivites agudas" },
  { "codigo": "H103", "descricao": "Conjuntivite aguda não especificada" },
  { "codigo": "H104", "descricao": "Conjuntivite crônica" },
  { "codigo": "H105", "descricao": "Blefaroconjuntivite" },
  { "codigo": "H108", "descricao": "Outras conjuntivites" },
  { "codigo": "H109", "descricao": "Conjuntivite não especificada" },
  { "codigo": "H110", "descricao": "Pterígio" },
  {
    "codigo": "H111",
    "descricao": "Degenerações e depósitos da conjuntiva"
  },
  { "codigo": "H112", "descricao": "Cicatrizes da conjuntiva" },
  { "codigo": "H113", "descricao": "Hemorragia conjuntival" },
  {
    "codigo": "H114",
    "descricao": "Outros transtornos vasculares e cistos conjuntivais"
  },
  {
    "codigo": "H118",
    "descricao": "Outros transtornos especificados da conjuntiva"
  },
  {
    "codigo": "H119",
    "descricao": "Transtorno não especificado da conjuntiva"
  },
  {
    "codigo": "H130",
    "descricao": "Infestação por filárias na conjuntiva"
  },
  {
    "codigo": "H131",
    "descricao": "Conjuntivite em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H132",
    "descricao": "Conjuntivite em outras doenças classificadas em outra parte"
  },
  { "codigo": "H133", "descricao": "Penfigóide ocular" },
  {
    "codigo": "H138",
    "descricao": "Outros transtornos da conjuntiva em doença classificadas em outra parte"
  },
  { "codigo": "H150", "descricao": "Esclerite" },
  { "codigo": "H151", "descricao": "Episclerite" },
  { "codigo": "H158", "descricao": "Outros transtornos da esclerótica" },
  {
    "codigo": "H159",
    "descricao": "Transtorno não especificado da esclerótica"
  },
  { "codigo": "H160", "descricao": "úlcera de córnea" },
  {
    "codigo": "H161",
    "descricao": "Outras ceratites superficiais sem conjuntivite"
  },
  { "codigo": "H162", "descricao": "Ceratoconjuntivite" },
  { "codigo": "H163", "descricao": "Ceratites intersticial e profunda" },
  {
    "codigo": "H164",
    "descricao": "Neovascularização da córnea"
  },
  { "codigo": "H168", "descricao": "Outras ceratites" },
  { "codigo": "H169", "descricao": "Ceratite não especificada" },
  { "codigo": "H170", "descricao": "Leucoma aderente" },
  { "codigo": "H171", "descricao": "Outra opacidade central da córnea" },
  {
    "codigo": "H178",
    "descricao": "Outras cicatrizes e opacidades da córnea"
  },
  {
    "codigo": "H179",
    "descricao": "Cicatriz e opacidade não especificadas da córnea"
  },
  {
    "codigo": "H180",
    "descricao": "Pigmentações e depósitos da córnea"
  },
  { "codigo": "H181", "descricao": "Ceratopatia bolhosa" },
  { "codigo": "H182", "descricao": "Outros edemas da córnea" },
  {
    "codigo": "H183",
    "descricao": "Alterações nas membranas da córnea"
  },
  { "codigo": "H184", "descricao": "Degenerações da córnea" },
  {
    "codigo": "H185",
    "descricao": "Distrofias hereditárias da córnea"
  },
  { "codigo": "H186", "descricao": "Ceratocone" },
  { "codigo": "H187", "descricao": "Outras deformidades da córnea" },
  {
    "codigo": "H188",
    "descricao": "Outros transtornos especificados da córnea"
  },
  {
    "codigo": "H189",
    "descricao": "Transtorno não especificado da córnea"
  },
  {
    "codigo": "H190",
    "descricao": "Esclerite e episclerite em doenças classificadas em outra parte"
  },
  {
    "codigo": "H191",
    "descricao": "Ceratite e ceratoconjuntivite pelo vírus do herpes simples"
  },
  {
    "codigo": "H192",
    "descricao": "Ceratite e ceratoconjuntivite em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H193",
    "descricao": "Ceratite e ceratoconjuntivite em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "H198",
    "descricao": "Outros transtornos de esclerótica e da córnea em doenças classificadas em outra parte"
  },
  { "codigo": "H200", "descricao": "Iridociclite aguda e subaguda" },
  { "codigo": "H201", "descricao": "Iridociclite crônica" },
  { "codigo": "H202", "descricao": "Iridociclite induzida pelo cristalino" },
  { "codigo": "H208", "descricao": "Outras iridociclites" },
  { "codigo": "H209", "descricao": "Iridociclite não especificada" },
  { "codigo": "H210", "descricao": "Hifema" },
  {
    "codigo": "H211",
    "descricao": "Outros transtornos vasculares da íris e do corpo ciliar"
  },
  {
    "codigo": "H212",
    "descricao": "Degenerações da íris e do corpo ciliar"
  },
  {
    "codigo": "H213",
    "descricao": "Cistos da íris, do corpo ciliar e da câmara anterior"
  },
  { "codigo": "H214", "descricao": "Membranas pupilares" },
  {
    "codigo": "H215",
    "descricao": "Outras aderências e roturas da íris e do corpo ciliar"
  },
  {
    "codigo": "H218",
    "descricao": "Outros transtornos especificados da íris e do corpo ciliar"
  },
  {
    "codigo": "H219",
    "descricao": "Transtorno não especificado da íris e do corpo ciliar"
  },
  {
    "codigo": "H220",
    "descricao": "Iridociclite em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H221",
    "descricao": "Iridociclite em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "H228",
    "descricao": "Outros transtornos da íris e do corpo ciliar em doenças classificadas em outra parte"
  },
  { "codigo": "H250", "descricao": "Catarata senil incipiente" },
  { "codigo": "H251", "descricao": "Catarata senil nuclear" },
  { "codigo": "H252", "descricao": "Catarata senil tipo Morgagni" },
  { "codigo": "H258", "descricao": "Outras cataratas senis" },
  { "codigo": "H259", "descricao": "Catarata senil não especificada" },
  {
    "codigo": "H260",
    "descricao": "Catarata infantil, juvenil e pré-senil"
  },
  { "codigo": "H261", "descricao": "Catarata traumática" },
  { "codigo": "H262", "descricao": "Catarata complicada" },
  { "codigo": "H263", "descricao": "Catarata induzida por drogas" },
  { "codigo": "H264", "descricao": "Pós-catarata" },
  { "codigo": "H268", "descricao": "Outras cataratas especificadas" },
  { "codigo": "H269", "descricao": "Catarata não especificada" },
  { "codigo": "H270", "descricao": "Afacia" },
  { "codigo": "H271", "descricao": "Deslocamento do cristalino" },
  {
    "codigo": "H278",
    "descricao": "Outros transtornos especificados do cristalino"
  },
  {
    "codigo": "H279",
    "descricao": "Transtorno não especificado do cristalino"
  },
  { "codigo": "H280", "descricao": "Catarata diabética" },
  {
    "codigo": "H281",
    "descricao": "Catarata em outras doenças endócrinas, nutricionais e metabólicas"
  },
  {
    "codigo": "H282",
    "descricao": "Catarata em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "H288",
    "descricao": "Outros transtorno do cristalino em doenças classificadas em outra parte"
  },
  {
    "codigo": "H300",
    "descricao": "Inflamação coriorretiniana focal"
  },
  {
    "codigo": "H301",
    "descricao": "Inflamação corrorretiniana disseminada"
  },
  { "codigo": "H302", "descricao": "Ciclite posterior" },
  {
    "codigo": "H308",
    "descricao": "Outras inflamações coriorretinianas"
  },
  {
    "codigo": "H309",
    "descricao": "Inflamação não especificada da coróide e da retina"
  },
  { "codigo": "H310", "descricao": "Cicatrizes coriorretinianas" },
  { "codigo": "H311", "descricao": "Degeneração da coróide" },
  {
    "codigo": "H312",
    "descricao": "Distrofia hereditária da coróide"
  },
  { "codigo": "H313", "descricao": "Hemorragia e rotura da coróide" },
  { "codigo": "H314", "descricao": "Descolamento da coróide" },
  {
    "codigo": "H318",
    "descricao": "Outros transtornos especificados da coróide"
  },
  {
    "codigo": "H319",
    "descricao": "Transtorno não especificado da coróide"
  },
  {
    "codigo": "H320",
    "descricao": "Inflamação coriorretiniana em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H328",
    "descricao": "Outros transtornos coriorretinianos em doenças classificadas em outra parte"
  },
  {
    "codigo": "H330",
    "descricao": "Descolamento da retina com defeito retiniano"
  },
  { "codigo": "H331", "descricao": "Retinosquise e cistos da retina" },
  { "codigo": "H332", "descricao": "Descolamento seroso da retina" },
  { "codigo": "H333", "descricao": "Defeitos da retina sem descolamento" },
  {
    "codigo": "H334",
    "descricao": "Descolamento da retina por tração"
  },
  { "codigo": "H335", "descricao": "Outros descolamentos da retina" },
  {
    "codigo": "H340",
    "descricao": "Oclusão arterial retiniana transitória"
  },
  {
    "codigo": "H341",
    "descricao": "Oclusão da artéria retiniana central"
  },
  {
    "codigo": "H342",
    "descricao": "Outras oclusões da artéria retiniana"
  },
  {
    "codigo": "H348",
    "descricao": "Outras oclusões vasculares retinianas"
  },
  {
    "codigo": "H349",
    "descricao": "Oclusão vascular retiniana não especificada"
  },
  {
    "codigo": "H350",
    "descricao": "Retinopatias de fundo e alterações vasculares da retina"
  },
  { "codigo": "H351", "descricao": "Retinopatia da prematuridade" },
  { "codigo": "H352", "descricao": "Outras retinopatias proliferativas" },
  {
    "codigo": "H353",
    "descricao": "Degeneração da mácula e do pólo posterior"
  },
  {
    "codigo": "H354",
    "descricao": "Degenerações periféricas da retina"
  },
  { "codigo": "H355", "descricao": "Distrofias hereditárias da retina" },
  { "codigo": "H356", "descricao": "Hemorragia retiniana" },
  {
    "codigo": "H357",
    "descricao": "Separação das camadas da retina"
  },
  {
    "codigo": "H358",
    "descricao": "Outros transtornos especificados da retina"
  },
  {
    "codigo": "H359",
    "descricao": "Transtorno da retina não especificado"
  },
  { "codigo": "H360", "descricao": "Retinopatia diabética" },
  {
    "codigo": "H368",
    "descricao": "Outros transtornos retinianos em doenças classificadas em outra parte"
  },
  { "codigo": "H400", "descricao": "Suspeita de glaucoma" },
  {
    "codigo": "H401",
    "descricao": "Glaucoma primário de ângulo aberto"
  },
  {
    "codigo": "H402",
    "descricao": "Glaucoma primário de ângulo fechado"
  },
  {
    "codigo": "H403",
    "descricao": "Glaucoma secundário a traumatismo ocular"
  },
  {
    "codigo": "H404",
    "descricao": "Glaucoma secundário a inflamação ocular"
  },
  {
    "codigo": "H405",
    "descricao": "Glaucoma secundário a outros transtornos do olho"
  },
  { "codigo": "H406", "descricao": "Glaucoma secundário a drogas" },
  { "codigo": "H408", "descricao": "Outro glaucoma" },
  { "codigo": "H409", "descricao": "Glaucoma não especificado" },
  {
    "codigo": "H420",
    "descricao": "Glaucoma em doenças endócrinas, nutricionais e metabólicas classificadas em outra parte"
  },
  {
    "codigo": "H428",
    "descricao": "Glaucoma em outras doenças classificadas em outra parte"
  },
  { "codigo": "H430", "descricao": "Prolapso do humor vítreo" },
  { "codigo": "H431", "descricao": "Hemorragia do humor vítreo" },
  {
    "codigo": "H432",
    "descricao": "Depósitos cristalinos no humor vítreo"
  },
  { "codigo": "H433", "descricao": "Outras opacidades do vítreo" },
  { "codigo": "H438", "descricao": "Outros transtornos do humor vítreo" },
  {
    "codigo": "H439",
    "descricao": "Transtorno não especificado do humor vítreo"
  },
  { "codigo": "H440", "descricao": "Endoftalmite purulenta" },
  { "codigo": "H441", "descricao": "Outras endoftalmites" },
  { "codigo": "H442", "descricao": "Miopia degenerativa" },
  {
    "codigo": "H443",
    "descricao": "Outros transtornos degenerativos do globo ocular"
  },
  { "codigo": "H444", "descricao": "Hipotonia do olho" },
  {
    "codigo": "H445",
    "descricao": "Afecções degenerativas do globo ocular"
  },
  {
    "codigo": "H446",
    "descricao": "Corpo estranho retido (antigo) intra-ocular de natureza magnética"
  },
  {
    "codigo": "H447",
    "descricao": "Corpo estranho retido (antigo) intra-ocular de natureza não-magnética"
  },
  { "codigo": "H448", "descricao": "Outros transtornos do globo ocular" },
  {
    "codigo": "H449",
    "descricao": "Transtorno não especificado do globo ocular"
  },
  {
    "codigo": "H450",
    "descricao": "Hemorragia vítrea em doenças classificadas em outra parte"
  },
  {
    "codigo": "H451",
    "descricao": "Endoftalmite em doenças classificadas em outra parte"
  },
  {
    "codigo": "H458",
    "descricao": "Outros transtornos do humor vítreo e do globo ocular em doenças classificadas em outra parte"
  },
  { "codigo": "H46", "descricao": "Neurite óptica" },
  {
    "codigo": "H470",
    "descricao": "Transtornos do nervo óptico não classificados em outra parte"
  },
  { "codigo": "H471", "descricao": "Papiledema não especificado" },
  { "codigo": "H472", "descricao": "Atrofia óptica" },
  { "codigo": "H473", "descricao": "Outros transtornos do disco óptico" },
  { "codigo": "H474", "descricao": "Transtornos do quiasma óptico" },
  { "codigo": "H475", "descricao": "Transtornos de outras vias ópticas" },
  { "codigo": "H476", "descricao": "Transtornos do córtex visual" },
  {
    "codigo": "H477",
    "descricao": "Transtornos não especificados das vias ópticas"
  },
  {
    "codigo": "H480",
    "descricao": "Atrofia óptica em doenças classificadas em outra parte"
  },
  {
    "codigo": "H481",
    "descricao": "Neurite retrobulbar em doenças classificadas em outra parte"
  },
  {
    "codigo": "H488",
    "descricao": "Outros transtornos do nervo óptico e das vias ópticas em doenças classificadas em outra parte"
  },
  { "codigo": "H490", "descricao": "Paralisia do terceiro par [oculomotor]" },
  { "codigo": "H491", "descricao": "Paralisia do quarto par [troclear]" },
  { "codigo": "H492", "descricao": "Paralisia do sexto par [abducente]" },
  { "codigo": "H493", "descricao": "Oftalmoplegia total (externa)" },
  { "codigo": "H494", "descricao": "Oftalmoplegia externa progressiva" },
  { "codigo": "H498", "descricao": "Outros estrabismos paralíticos" },
  {
    "codigo": "H499",
    "descricao": "Estrabismo paralítico não especificado"
  },
  { "codigo": "H500", "descricao": "Estrabismo convergente concomitante" },
  { "codigo": "H501", "descricao": "Estrabismo divergente concomitante" },
  { "codigo": "H502", "descricao": "Estrabismo vertical" },
  { "codigo": "H503", "descricao": "Heterotropia intermitente" },
  {
    "codigo": "H504",
    "descricao": "Outras heterotropias e as não especificadas"
  },
  { "codigo": "H505", "descricao": "Heteroforia" },
  { "codigo": "H506", "descricao": "Estrabismo mecânico" },
  { "codigo": "H508", "descricao": "Outros estrabismos especificados" },
  { "codigo": "H509", "descricao": "Estrabismo não especificado" },
  { "codigo": "H510", "descricao": "Paralisia do olhar conjugado" },
  {
    "codigo": "H511",
    "descricao": "Insuficiência ou excesso de convergência"
  },
  { "codigo": "H512", "descricao": "Oftalmoplegia internuclear" },
  {
    "codigo": "H518",
    "descricao": "Outros transtornos especificados do movimento binocular"
  },
  {
    "codigo": "H519",
    "descricao": "Transtorno não especificado do movimento binocular"
  },
  { "codigo": "H520", "descricao": "Hipermetropia" },
  { "codigo": "H521", "descricao": "Miopia" },
  { "codigo": "H522", "descricao": "Astigmatismo" },
  { "codigo": "H523", "descricao": "Anisometropia e aniseiconia" },
  { "codigo": "H524", "descricao": "Presbiopia" },
  { "codigo": "H525", "descricao": "Transtornos da acomodação" },
  { "codigo": "H526", "descricao": "Outros transtornos da refração" },
  {
    "codigo": "H527",
    "descricao": "Transtorno não especificado da refração"
  },
  { "codigo": "H530", "descricao": "Ambliopia por anopsia" },
  { "codigo": "H531", "descricao": "Distúrbios visuais subjetivos" },
  { "codigo": "H532", "descricao": "Diplopia" },
  {
    "codigo": "H533",
    "descricao": "Outros transtornos da visão binocular"
  },
  { "codigo": "H534", "descricao": "Defeitos do campo visual" },
  {
    "codigo": "H535",
    "descricao": "Deficiências da visão cromática"
  },
  { "codigo": "H536", "descricao": "Cegueira noturna" },
  { "codigo": "H538", "descricao": "Outros distúrbios visuais" },
  {
    "codigo": "H539",
    "descricao": "Distúrbio visual não especificado"
  },
  { "codigo": "H540", "descricao": "Cegueira, ambos os olhos" },
  {
    "codigo": "H541",
    "descricao": "Cegueira em um olho e visão subnormal em outro"
  },
  { "codigo": "H542", "descricao": "Visão subnormal de ambos os olhos" },
  {
    "codigo": "H543",
    "descricao": "Perda não qualificada da visão em ambos os olhos"
  },
  { "codigo": "H544", "descricao": "Cegueira em um olho" },
  { "codigo": "H545", "descricao": "Visão subnormal em um olho" },
  {
    "codigo": "H546",
    "descricao": "Perda não qualificada da visão em um olho"
  },
  {
    "codigo": "H547",
    "descricao": "Perda não especificada da visão"
  },
  {
    "codigo": "H55",
    "descricao": "Nistagmo e outros movimentos irregulares do olho"
  },
  { "codigo": "H570", "descricao": "Anomalias da função pupilar" },
  { "codigo": "H571", "descricao": "Dor ocular" },
  {
    "codigo": "H578",
    "descricao": "Outros transtornos especificados do olho e anexos"
  },
  {
    "codigo": "H579",
    "descricao": "Transtorno não especificado do olho e anexos"
  },
  {
    "codigo": "H580",
    "descricao": "Anomalias da função pupilar em doenças classificadas em outra parte"
  },
  {
    "codigo": "H581",
    "descricao": "Distúrbios visuais em doenças classificadas em outra parte"
  },
  {
    "codigo": "H588",
    "descricao": "Outros transtornos especificados do olho e anexos em doenças classificadas em outra parte"
  },
  {
    "codigo": "H590",
    "descricao": "Ceropatia (bolhosa afácica) subseqüente a cirurgia de catarata"
  },
  {
    "codigo": "H598",
    "descricao": "Outros transtornos do olho e anexos pós-procedimentos"
  },
  {
    "codigo": "H599",
    "descricao": "Transtorno não especificado do olho e anexos pós-procedimento"
  },
  { "codigo": "H600", "descricao": "Abscesso do ouvido externo" },
  { "codigo": "H601", "descricao": "Celulite do ouvido externo" },
  { "codigo": "H602", "descricao": "Otite externa maligna" },
  { "codigo": "H603", "descricao": "Outras otites externas infecciosas" },
  { "codigo": "H604", "descricao": "Colesteatoma do ouvido externo" },
  { "codigo": "H605", "descricao": "Otite externa aguda não-infecciosa" },
  { "codigo": "H608", "descricao": "Outras otites externas" },
  { "codigo": "H609", "descricao": "Otite externa não especificada" },
  { "codigo": "H610", "descricao": "Pericondrite do pavilhão da orelha" },
  {
    "codigo": "H611",
    "descricao": "Transtornos não-infecciosos do pavilhão da orelha"
  },
  { "codigo": "H612", "descricao": "Cerume impactado" },
  {
    "codigo": "H613",
    "descricao": "Estenose adquirida do conduto auditivo externo"
  },
  {
    "codigo": "H618",
    "descricao": "Outros transtornos especificados do ouvido externo"
  },
  {
    "codigo": "H619",
    "descricao": "Transtorno não especificado do ouvido externo"
  },
  {
    "codigo": "H620",
    "descricao": "Otite externa em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "H621",
    "descricao": "Otite externa em doenças virais classificadas em outra parte"
  },
  { "codigo": "H622", "descricao": "Otite externa em micoses" },
  {
    "codigo": "H623",
    "descricao": "Otite externa em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H624",
    "descricao": "Otite externa em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "H628",
    "descricao": "Outros transtornos do ouvido externo em doenças classificadas em outra parte"
  },
  { "codigo": "H650", "descricao": "Otite média aguda serosa" },
  {
    "codigo": "H651",
    "descricao": "Outras otites médias agudas não-supurativas"
  },
  { "codigo": "H652", "descricao": "Otite média serosa crônica" },
  {
    "codigo": "H653",
    "descricao": "Otite média mucóide crônica"
  },
  {
    "codigo": "H654",
    "descricao": "Outras otites médias crônicas não-supurativas"
  },
  {
    "codigo": "H659",
    "descricao": "Otite média não-supurativa, não especificada"
  },
  { "codigo": "H660", "descricao": "Otite média aguda supurativa" },
  {
    "codigo": "H661",
    "descricao": "Otite média tubotimpânica supurativa crônica"
  },
  {
    "codigo": "H662",
    "descricao": "Otite média ático-antral supurativa crônica"
  },
  {
    "codigo": "H663",
    "descricao": "Outras otites médias supurativas crônicas"
  },
  {
    "codigo": "H664",
    "descricao": "Otite média supurativa não especificada"
  },
  { "codigo": "H669", "descricao": "Otite média não especificada" },
  {
    "codigo": "H670",
    "descricao": "Otite média em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "H671",
    "descricao": "Otite média em doenças virais classificadas em outra parte"
  },
  {
    "codigo": "H678",
    "descricao": "Otite média em outras doenças classificadas em outra parte"
  },
  { "codigo": "H680", "descricao": "Salpingite da trompa de Eustáquio" },
  {
    "codigo": "H681",
    "descricao": "Obstrução da trompa de Eustáquio"
  },
  {
    "codigo": "H690",
    "descricao": "Trompa de Eustáquio aberta (patente) (patulosa)"
  },
  {
    "codigo": "H698",
    "descricao": "Outros transtornos especificados da trompa de Eustáquio"
  },
  {
    "codigo": "H699",
    "descricao": "Transtorno não especificado da trompa de Eustáquio"
  },
  { "codigo": "H700", "descricao": "Mastoidite aguda" },
  { "codigo": "H701", "descricao": "Mastoidite crônica" },
  { "codigo": "H702", "descricao": "Petrosite" },
  {
    "codigo": "H708",
    "descricao": "Outras mastoidites e afecções relacionadas com a mastoidite"
  },
  { "codigo": "H709", "descricao": "Mastoidite não especificada" },
  { "codigo": "H71", "descricao": "Colesteatoma do ouvido médio" },
  {
    "codigo": "H720",
    "descricao": "Perfuração central da membrana do tímpano"
  },
  {
    "codigo": "H721",
    "descricao": "Perfuração ática da membrana do tímpano"
  },
  {
    "codigo": "H722",
    "descricao": "Outras perfurações marginais da membrana do tímpano"
  },
  {
    "codigo": "H728",
    "descricao": "Outras perfurações da membrana do tímpano"
  },
  {
    "codigo": "H729",
    "descricao": "Perfuração não especificada da membrana do tímpano"
  },
  { "codigo": "H730", "descricao": "Miringite aguda" },
  { "codigo": "H731", "descricao": "Miringite crônica" },
  {
    "codigo": "H738",
    "descricao": "Outros transtornos especificados da membrana do tímpano"
  },
  {
    "codigo": "H739",
    "descricao": "Transtorno não especificado da membrana do tímpano"
  },
  { "codigo": "H740", "descricao": "Timpanosclerose" },
  { "codigo": "H741", "descricao": "Doença adesiva do ouvido médio" },
  {
    "codigo": "H742",
    "descricao": "Descontinuidade e deslocamento dos ossículos do ouvido"
  },
  {
    "codigo": "H743",
    "descricao": "Outras anormalidades adquiridas dos ossículos do ouvido"
  },
  { "codigo": "H744", "descricao": "Pólipo do ouvido médio" },
  {
    "codigo": "H748",
    "descricao": "Outros transtornos especificados do ouvido médio e da mastóide"
  },
  {
    "codigo": "H749",
    "descricao": "Transtorno não especificado do ouvido médio e da mastóide"
  },
  {
    "codigo": "H750",
    "descricao": "Mastoidite em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H758",
    "descricao": "Outros transtornos especificados do ouvido médio e da mastóide em doenças classificadas em outra parte"
  },
  {
    "codigo": "H800",
    "descricao": "Otosclerose que compromete a janela oval, não-obliterante"
  },
  {
    "codigo": "H801",
    "descricao": "Otosclerose que compromete a janela oval, obliterante"
  },
  { "codigo": "H802", "descricao": "Otosclerose da cóclea" },
  { "codigo": "H808", "descricao": "Outras otoscleroses" },
  { "codigo": "H809", "descricao": "Otosclerose não especificada" },
  { "codigo": "H810", "descricao": "Doença de Méniére" },
  { "codigo": "H811", "descricao": "Vertigem paroxística benigna" },
  { "codigo": "H812", "descricao": "Neuronite vestibular" },
  { "codigo": "H813", "descricao": "Outras vertigens periféricas" },
  { "codigo": "H814", "descricao": "Vertigem de origem central" },
  {
    "codigo": "H818",
    "descricao": "Outros transtornos da função vestibular"
  },
  {
    "codigo": "H819",
    "descricao": "Transtornos não especificados da função vestibular"
  },
  {
    "codigo": "H82",
    "descricao": "Síndromes vertiginosas em doenças classificadas em outra parte"
  },
  { "codigo": "H830", "descricao": "Labirintite" },
  { "codigo": "H831", "descricao": "Fístula do labirinto" },
  { "codigo": "H832", "descricao": "Disfunção do labirinto" },
  {
    "codigo": "H833",
    "descricao": "Efeitos do ruído sobre o ouvido interno"
  },
  {
    "codigo": "H838",
    "descricao": "Outros transtornos especificados do ouvido interno"
  },
  {
    "codigo": "H839",
    "descricao": "Transtorno não especificado do ouvido interno"
  },
  {
    "codigo": "H900",
    "descricao": "Perda de audição bilateral devida a transtorno de condução"
  },
  {
    "codigo": "H901",
    "descricao": "Perda de audição unilateral por transtorno de condução, sem restrição de audição contralateral"
  },
  {
    "codigo": "H902",
    "descricao": "Perda não especificada de audição devida a transtorno de condução"
  },
  {
    "codigo": "H903",
    "descricao": "Perda de audição bilateral neuro-sensorial"
  },
  {
    "codigo": "H904",
    "descricao": "Perda de audição unilateral neuro-sensorial, sem restrição de audição contralateral"
  },
  {
    "codigo": "H905",
    "descricao": "Perda de audição neuro-sensorial não especificada"
  },
  {
    "codigo": "H906",
    "descricao": "Perda de audição bilateral mista, de condução e neuro-sensorial"
  },
  {
    "codigo": "H907",
    "descricao": "Perda de audição unilateral mista, de condução e neuro-sensorial, sem restrição de audição contralateral"
  },
  {
    "codigo": "H908",
    "descricao": "Perda de audição mista, de condução e neuro-sensorial, não especificada"
  },
  {
    "codigo": "H910",
    "descricao": "Perda de audição ototóxica"
  },
  { "codigo": "H911", "descricao": "Presbiacusia" },
  {
    "codigo": "H912",
    "descricao": "Perda de audição súbita idiopática"
  },
  {
    "codigo": "H913",
    "descricao": "Surdo-mudez não classificada em outra parte"
  },
  {
    "codigo": "H918",
    "descricao": "Outras perdas de audição especificadas"
  },
  {
    "codigo": "H919",
    "descricao": "Perda não especificada de audição"
  },
  { "codigo": "H920", "descricao": "Otalgia" },
  { "codigo": "H921", "descricao": "Otorréia" },
  { "codigo": "H922", "descricao": "Otorragia" },
  {
    "codigo": "H930",
    "descricao": "Transtornos degenerativos e vasculares do ouvido"
  },
  { "codigo": "H931", "descricao": "Tinnitus" },
  {
    "codigo": "H932",
    "descricao": "Outras percepções auditivas anormais"
  },
  { "codigo": "H933", "descricao": "Transtornos do nervo acústico" },
  {
    "codigo": "H938",
    "descricao": "Outros transtornos especificados do ouvido"
  },
  {
    "codigo": "H939",
    "descricao": "Transtorno não especificado do ouvido"
  },
  {
    "codigo": "H940",
    "descricao": "Neurite acústica em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "H948",
    "descricao": "Outros transtornos especificados do ouvido em doenças classificadas em outra parte"
  },
  {
    "codigo": "H950",
    "descricao": "Colesteatoma recorrente da cavidade pós-mastoidectomia"
  },
  {
    "codigo": "H951",
    "descricao": "Outros transtornos conseqüentes à mastoidectomia"
  },
  {
    "codigo": "H958",
    "descricao": "Outros transtornos do ouvido e da apófise mastóide pós-procedimento"
  },
  {
    "codigo": "H959",
    "descricao": "Transtorno não especificado do ouvido e da apófise mastóide pós-procedimento"
  },
  {
    "codigo": "I00",
    "descricao": "Febre reumática sem menção de comprometimento do coração"
  },
  { "codigo": "I010", "descricao": "Pericardite reumática aguda" },
  { "codigo": "I011", "descricao": "Endocardite reumática aguda" },
  { "codigo": "I012", "descricao": "Miocardite reumática aguda" },
  {
    "codigo": "I018",
    "descricao": "Outras formas de doença cardíaca reumática aguda"
  },
  {
    "codigo": "I019",
    "descricao": "Doença cardíaca reumática aguda não especificada"
  },
  {
    "codigo": "I020",
    "descricao": "Coréia reumática com comprometimento cardíaco"
  },
  {
    "codigo": "I029",
    "descricao": "Coréia reumática sem comprometimento cardíaco"
  },
  { "codigo": "I050", "descricao": "Estenose mitral" },
  { "codigo": "I051", "descricao": "Insuficiência mitral reumática" },
  { "codigo": "I052", "descricao": "Estenose mitral com insuficiência" },
  { "codigo": "I058", "descricao": "Outras doenças da valva mitral" },
  {
    "codigo": "I059",
    "descricao": "Doença não especificada da valva mitral"
  },
  { "codigo": "I060", "descricao": "Estenose aórtica reumática" },
  {
    "codigo": "I061",
    "descricao": "Insuficiência aórtica reumática"
  },
  {
    "codigo": "I062",
    "descricao": "Estenose aórtica reumática com insuficiência"
  },
  {
    "codigo": "I068",
    "descricao": "Outras doenças reumáticas da valva aórtica"
  },
  {
    "codigo": "I069",
    "descricao": "Doença reumática da valva aórtica, não especificada"
  },
  { "codigo": "I070", "descricao": "Estenose tricúspide" },
  { "codigo": "I071", "descricao": "Insuficiência tricúspide" },
  {
    "codigo": "I072",
    "descricao": "Estenose tricúspide com insuficiência"
  },
  {
    "codigo": "I078",
    "descricao": "Outras doenças da valva tricúspide"
  },
  {
    "codigo": "I079",
    "descricao": "Doença não especificada da valva tricúspide"
  },
  {
    "codigo": "I080",
    "descricao": "Transtornos de valvas mitral e aórtica"
  },
  {
    "codigo": "I081",
    "descricao": "Transtornos de ambas as valvas mitral e tricúspide"
  },
  {
    "codigo": "I082",
    "descricao": "Transtornos de ambas as valvas aórtica e tricúspide"
  },
  {
    "codigo": "I083",
    "descricao": "Transtornos das valvas mitral, aórtica e tricúspide"
  },
  {
    "codigo": "I088",
    "descricao": "Outras doenças de múltiplas valvas"
  },
  {
    "codigo": "I089",
    "descricao": "Doença não especificada de múltiplas valvas"
  },
  { "codigo": "I090", "descricao": "Miocardite reumática" },
  {
    "codigo": "I091",
    "descricao": "Doenças reumáticas do endocárdio, valva não especificada"
  },
  { "codigo": "I092", "descricao": "Pericardite reumática crônica" },
  {
    "codigo": "I098",
    "descricao": "Outras doenças reumáticas especificadas do coração"
  },
  {
    "codigo": "I099",
    "descricao": "Doença cardíaca reumática não especificada"
  },
  {
    "codigo": "I10",
    "descricao": "Hipertensão essencial (primária)"
  },
  {
    "codigo": "I110",
    "descricao": "Doença cardíaca hipertensiva com insuficiência cardíaca (congestiva)"
  },
  {
    "codigo": "I119",
    "descricao": "Doença cardíaca hipertensiva sem insuficiência cardíaca (congestiva)"
  },
  {
    "codigo": "I120",
    "descricao": "Doença renal hipertensiva com insuficiência renal"
  },
  {
    "codigo": "I129",
    "descricao": "Doença renal hipertensiva sem insuficiência renal"
  },
  {
    "codigo": "I130",
    "descricao": "Doença cardíaca e renal hipertensiva com insuficiência cardíaca (congestiva)"
  },
  {
    "codigo": "I131",
    "descricao": "Doença cardíaca e renal hipertensiva com insuficiência renal"
  },
  {
    "codigo": "I132",
    "descricao": "Doença cardíaca e renal hipertensiva com insuficiência cardíaca (congestiva) e insuficiência renal"
  },
  {
    "codigo": "I139",
    "descricao": "Doença cardíaca e renal hipertensiva, não especificada"
  },
  { "codigo": "I150", "descricao": "Hipertensão renovascular" },
  {
    "codigo": "I151",
    "descricao": "Hipertensão secundária a outras afecções renais"
  },
  {
    "codigo": "I152",
    "descricao": "Hipertensão secundária a afecções endócrinas"
  },
  {
    "codigo": "I158",
    "descricao": "Outras formas de hipertensão secundária"
  },
  {
    "codigo": "I159",
    "descricao": "Hipertensão secundária, não especificada"
  },
  { "codigo": "I200", "descricao": "Angina instável" },
  { "codigo": "I201", "descricao": "Angina pectoris com espasmo documentado" },
  { "codigo": "I208", "descricao": "Outras formas de angina pectoris" },
  { "codigo": "I209", "descricao": "Angina pectoris, não especificada" },
  {
    "codigo": "I210",
    "descricao": "Infarto agudo transmural da parede anterior do miocárdio"
  },
  {
    "codigo": "I211",
    "descricao": "Infarto agudo transmural da parede inferior do miocárdio"
  },
  {
    "codigo": "I212",
    "descricao": "Infarto agudo transmural do miocárdio de outras localizações"
  },
  {
    "codigo": "I213",
    "descricao": "Infarto agudo transmural do miocárdio, de localização não especificada"
  },
  {
    "codigo": "I214",
    "descricao": "Infarto agudo subendocárdico do miocárdio"
  },
  {
    "codigo": "I219",
    "descricao": "Infarto agudo do miocárdio não especificado"
  },
  {
    "codigo": "I220",
    "descricao": "Infarto do miocárdio recorrente da parede anterior"
  },
  {
    "codigo": "I221",
    "descricao": "Infarto do miocárdio recorrente da parede inferior"
  },
  {
    "codigo": "I228",
    "descricao": "Infarto do miocárdio recorrente de outras localizações"
  },
  {
    "codigo": "I229",
    "descricao": "Infarto do miocárdio recorrente de localização não especificada"
  },
  {
    "codigo": "I230",
    "descricao": "Hemopericárdio como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I231",
    "descricao": "Comunicação interatrial como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I232",
    "descricao": "Comunicação interventricular como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I233",
    "descricao": "Ruptura da parede do coração sem ocorrência de hemopericárdio como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I234",
    "descricao": "Ruptura de cordoalhas tendíneas como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I235",
    "descricao": "Ruptura de músculos papilares como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I236",
    "descricao": "Trombose de átrio, aurícula e ventrículo como complicação atual subseqüente ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I238",
    "descricao": "Outras complicações atuais subseqüentes ao infarto agudo do miocárdio"
  },
  {
    "codigo": "I240",
    "descricao": "Trombose coronária que não resulta em infarto do miocárdio"
  },
  { "codigo": "I241", "descricao": "Síndrome de Dressler" },
  {
    "codigo": "I248",
    "descricao": "Outras formas de doença isquêmica aguda do coração"
  },
  {
    "codigo": "I249",
    "descricao": "Doença isquêmica aguda do coração não especificada"
  },
  {
    "codigo": "I250",
    "descricao": "Doença cardiovascular aterosclerótica, descrita desta maneira"
  },
  {
    "codigo": "I251",
    "descricao": "Doença aterosclerótica do coração"
  },
  { "codigo": "I252", "descricao": "Infarto antigo do miocárdio" },
  { "codigo": "I253", "descricao": "Aneurisma cardíaco" },
  { "codigo": "I254", "descricao": "Aneurisma de artéria coronária" },
  { "codigo": "I255", "descricao": "Miocardiopatia isquêmica" },
  { "codigo": "I256", "descricao": "Isquemia miocárdica silenciosa" },
  {
    "codigo": "I258",
    "descricao": "Outras formas de doença isquêmica crônica do coração"
  },
  {
    "codigo": "I259",
    "descricao": "Doença isquêmica crônica do coração não especificada"
  },
  {
    "codigo": "I260",
    "descricao": "Embolia pulmonar com menção de cor pulmonale agudo"
  },
  {
    "codigo": "I269",
    "descricao": "Embolia pulmonar sem menção de cor pulmonale agudo"
  },
  { "codigo": "I270", "descricao": "Hipertensão pulmonar primária" },
  { "codigo": "I271", "descricao": "Cardiopatia cifoescoliótica" },
  {
    "codigo": "I272",
    "descricao": "Outra hipertensão pulmonar secundária"
  },
  {
    "codigo": "I278",
    "descricao": "Outras doenças pulmonares do coração especificadas"
  },
  {
    "codigo": "I279",
    "descricao": "Cardiopatia pulmonar não especificada"
  },
  {
    "codigo": "I280",
    "descricao": "Fístula arteriovenosa de vasos pulmonares"
  },
  { "codigo": "I281", "descricao": "Aneurisma da artéria pulmonar" },
  {
    "codigo": "I288",
    "descricao": "Outras doenças especificadas dos vasos pulmonares"
  },
  {
    "codigo": "I289",
    "descricao": "Doença não especificada dos vasos pulmonares"
  },
  {
    "codigo": "I300",
    "descricao": "Pericardite aguda idiopática não específica"
  },
  { "codigo": "I301", "descricao": "Pericardite infecciosa" },
  { "codigo": "I308", "descricao": "Outras formas de pericardite aguda" },
  { "codigo": "I309", "descricao": "Pericardite aguda não especificada" },
  { "codigo": "I310", "descricao": "Pericardite adesiva crônica" },
  { "codigo": "I311", "descricao": "Pericardite constritiva crônica" },
  {
    "codigo": "I312",
    "descricao": "Hemopericárdio não classificado em outra parte"
  },
  {
    "codigo": "I313",
    "descricao": "Derrame pericárdico (não-inflamatório)"
  },
  {
    "codigo": "I318",
    "descricao": "Outras doenças especificadas do pericárdio"
  },
  {
    "codigo": "I319",
    "descricao": "Doença não especificada do pericárdio"
  },
  {
    "codigo": "I320",
    "descricao": "Pericardite em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "I321",
    "descricao": "Pericardite em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "I328",
    "descricao": "Pericardite em outras doenças classificadas em outra parte"
  },
  { "codigo": "I330", "descricao": "Endocardite infecciosa aguda e subaguda" },
  { "codigo": "I339", "descricao": "Endocardite aguda não especificada" },
  { "codigo": "I340", "descricao": "Insuficiência (da valva) mitral" },
  { "codigo": "I341", "descricao": "Prolapso (da valva) mitral" },
  {
    "codigo": "I342",
    "descricao": "Estenose (da valva) mitral, não-reumática"
  },
  {
    "codigo": "I348",
    "descricao": "Outros transtornos não-reumáticos da valva mitral"
  },
  {
    "codigo": "I349",
    "descricao": "Transtornos não-reumáticos da valva mitral, não especificados"
  },
  { "codigo": "I350", "descricao": "Estenose (da valva) aórtica" },
  {
    "codigo": "I351",
    "descricao": "Insuficiência (da valva) aórtica"
  },
  {
    "codigo": "I352",
    "descricao": "Estenose (da valva) aórtica com insuficiência"
  },
  { "codigo": "I358", "descricao": "Outros transtornos da valva aórtica" },
  {
    "codigo": "I359",
    "descricao": "Transtornos não especificados da valva aórtica"
  },
  {
    "codigo": "I360",
    "descricao": "Estenose (da valva) tricúspide não-reumática"
  },
  {
    "codigo": "I361",
    "descricao": "Insuficiência (da valva) tricúspide não-reumática"
  },
  {
    "codigo": "I362",
    "descricao": "Estenose (da valva) tricúspide com insuficiência, não-reumática"
  },
  {
    "codigo": "I368",
    "descricao": "Outros transtornos não-reumáticos da valva tricúspide"
  },
  {
    "codigo": "I369",
    "descricao": "Transtornos não-reumáticos da valva tricúspide, não especificados"
  },
  { "codigo": "I370", "descricao": "Estenose da valva pulmonar" },
  { "codigo": "I371", "descricao": "Insuficiência da valva pulmonar" },
  {
    "codigo": "I372",
    "descricao": "Estenose da valva pulmonar com insuficiência"
  },
  { "codigo": "I378", "descricao": "Outros transtornos da valva pulmonar" },
  {
    "codigo": "I379",
    "descricao": "Transtornos não especificados da valva pulmonar"
  },
  {
    "codigo": "I38",
    "descricao": "Endocardite de valva não especificada"
  },
  {
    "codigo": "I390",
    "descricao": "Transtornos da valva mitral em doenças classificadas em outra parte"
  },
  {
    "codigo": "I391",
    "descricao": "Transtornos da valva aórtica em doenças classificadas em outra parte"
  },
  {
    "codigo": "I392",
    "descricao": "Transtornos da valva tricúspide em doenças classificadas em outra parte"
  },
  {
    "codigo": "I393",
    "descricao": "Transtornos da valva pulmonar em doenças classificadas em outra parte"
  },
  {
    "codigo": "I394",
    "descricao": "Transtornos de múltiplas valvas em doenças classificadas em outra parte"
  },
  {
    "codigo": "I398",
    "descricao": "Endocardite, de valva não especificada, em doenças classificadas em outra parte"
  },
  { "codigo": "I400", "descricao": "Miocardite infecciosa" },
  { "codigo": "I401", "descricao": "Miocardite isolada" },
  { "codigo": "I408", "descricao": "Outras miocardites agudas" },
  { "codigo": "I409", "descricao": "Miocardite aguda não especificada" },
  {
    "codigo": "I410",
    "descricao": "Miocardite em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "I411",
    "descricao": "Miocardite em doenças virais classificadas em outra parte"
  },
  {
    "codigo": "I412",
    "descricao": "Miocardite em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "I418",
    "descricao": "Miocardite em outras doenças classificadas em outra parte"
  },
  { "codigo": "I420", "descricao": "Cardiomiopatia dilatada" },
  {
    "codigo": "I421",
    "descricao": "Cardiomiopatia obstrutiva hipertrófica"
  },
  {
    "codigo": "I422",
    "descricao": "Outras cardiomiopatias hipertróficas"
  },
  {
    "codigo": "I423",
    "descricao": "Doença endomiocárdica (eosinofílica)"
  },
  { "codigo": "I424", "descricao": "Fibroelastose endocárdica" },
  { "codigo": "I425", "descricao": "Outras cardiomiopatias restritivas" },
  { "codigo": "I426", "descricao": "Cardiomiopatia alcoólica" },
  {
    "codigo": "I427",
    "descricao": "Cardiomiopatia devida a drogas e outros agentes externos"
  },
  { "codigo": "I428", "descricao": "Outras cardiomiopatias" },
  { "codigo": "I429", "descricao": "Cardiomiopatia não especificada" },
  {
    "codigo": "I430",
    "descricao": "Cardiomiopatia em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "I431",
    "descricao": "Cardiomiopatia em doenças metabólicas"
  },
  {
    "codigo": "I432",
    "descricao": "Cardiomiopatia em doenças nutricionais"
  },
  {
    "codigo": "I438",
    "descricao": "Cardiomiopatia em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "I440",
    "descricao": "Bloqueio atrioventricular de primeiro grau"
  },
  {
    "codigo": "I441",
    "descricao": "Bloqueio atrioventricular de segundo grau"
  },
  { "codigo": "I442", "descricao": "Bloqueio atrioventricular total" },
  {
    "codigo": "I443",
    "descricao": "Outras formas de bloqueio atrioventricular e as não especificadas"
  },
  {
    "codigo": "I444",
    "descricao": "Bloqueio do fascículo anterior esquerdo"
  },
  {
    "codigo": "I445",
    "descricao": "Bloqueio do fascículo posterior esquerdo"
  },
  {
    "codigo": "I446",
    "descricao": "Outras formas de bloqueio fascicular e as não especificadas"
  },
  {
    "codigo": "I447",
    "descricao": "Bloqueio de ramo esquerdo não especificado"
  },
  { "codigo": "I450", "descricao": "Bloqueio fascicular direito" },
  {
    "codigo": "I451",
    "descricao": "Outras formas de bloqueio de ramo direito e as não especificadas"
  },
  { "codigo": "I452", "descricao": "Bloqueio bifascicular" },
  { "codigo": "I453", "descricao": "Bloqueio trifascicular" },
  {
    "codigo": "I454",
    "descricao": "Bloqueio intraventricular não específico"
  },
  {
    "codigo": "I455",
    "descricao": "Outras formas especificadas de bloqueio cardíaco"
  },
  {
    "codigo": "I456",
    "descricao": "Síndrome de pré-excitação"
  },
  {
    "codigo": "I458",
    "descricao": "Outros transtornos especificados da condução"
  },
  {
    "codigo": "I459",
    "descricao": "Transtorno de condução não especificado"
  },
  {
    "codigo": "I460",
    "descricao": "Parada cardíaca com ressuscitação bem sucedida"
  },
  {
    "codigo": "I461",
    "descricao": "Morte súbita (de origem) cardíaca, descrita desta forma"
  },
  {
    "codigo": "I469",
    "descricao": "Parada cardíaca não especificada"
  },
  { "codigo": "I470", "descricao": "Arritmia ventricular por reentrada" },
  { "codigo": "I471", "descricao": "Taquicardia supraventricular" },
  { "codigo": "I472", "descricao": "Taquicardia ventricular" },
  {
    "codigo": "I479",
    "descricao": "Taquicardia paroxística não especificada"
  },
  { "codigo": "I48", "descricao": "Flutter e fibrilação atrial" },
  {
    "codigo": "I490",
    "descricao": "Flutter e fibrilação ventricular"
  },
  {
    "codigo": "I491",
    "descricao": "Despolarização atrial prematura"
  },
  {
    "codigo": "I492",
    "descricao": "Despolarização juncional prematura"
  },
  {
    "codigo": "I493",
    "descricao": "Despolarização ventricular prematura"
  },
  {
    "codigo": "I494",
    "descricao": "Outras formas de despolarização prematura e as não especificadas"
  },
  { "codigo": "I495", "descricao": "Síndrome do nó sinusal" },
  {
    "codigo": "I498",
    "descricao": "Outras arritmias cardíacas especificadas"
  },
  {
    "codigo": "I499",
    "descricao": "Arritmia cardíaca não especificada"
  },
  {
    "codigo": "I500",
    "descricao": "Insuficiência cardíaca congestiva"
  },
  { "codigo": "I501", "descricao": "Insuficiência ventricular esquerda" },
  {
    "codigo": "I509",
    "descricao": "Insuficiência cardíaca não especificada"
  },
  { "codigo": "I510", "descricao": "Defeito adquirido de septo cardíaco" },
  {
    "codigo": "I511",
    "descricao": "Ruptura de cordoalha tendínea, não classificada em outra parte"
  },
  {
    "codigo": "I512",
    "descricao": "Ruptura de músculo papilar, não classificada em outra parte"
  },
  {
    "codigo": "I513",
    "descricao": "Trombose intracardíaca, não classificada em outra parte"
  },
  { "codigo": "I514", "descricao": "Miocardite não especificada" },
  { "codigo": "I515", "descricao": "Degeneração miocárdica" },
  {
    "codigo": "I516",
    "descricao": "Doença cardiovascular não especificada"
  },
  { "codigo": "I517", "descricao": "Cardiomegalia" },
  {
    "codigo": "I518",
    "descricao": "Outras doenças mal definidas do coração"
  },
  {
    "codigo": "I519",
    "descricao": "Doença não especificada do coração"
  },
  {
    "codigo": "I520",
    "descricao": "Outras afecções cardíacas em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "I521",
    "descricao": "Outras afecções cardíacas em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "I528",
    "descricao": "Outras afecções cardíacas em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "I600",
    "descricao": "Hemorragia subaracnóide proveniente do sifão e da bifurcação da carótida"
  },
  {
    "codigo": "I601",
    "descricao": "Hemorragia subaracnóide proveniente da artéria cerebral média"
  },
  {
    "codigo": "I602",
    "descricao": "Hemorragia subaracnóide proveniente da artéria comunicante anterior"
  },
  {
    "codigo": "I603",
    "descricao": "Hemorragia subaracnóide proveniente da artéria comunicante posterior"
  },
  {
    "codigo": "I604",
    "descricao": "Hemorragia subaracnóide proveniente da artéria basilar"
  },
  {
    "codigo": "I605",
    "descricao": "Hemorragia subaracnóide proveniente da artéria vertebral"
  },
  {
    "codigo": "I606",
    "descricao": "Hemorragia subaracnóide proveniente de outras artérias intracranianas"
  },
  {
    "codigo": "I607",
    "descricao": "Hemorragia subaracnóide proveniente de artéria intracraniana não especificada"
  },
  { "codigo": "I608", "descricao": "Outras hemorragias subaracnóides" },
  {
    "codigo": "I609",
    "descricao": "Hemorragia subaracnóide não especificada"
  },
  {
    "codigo": "I610",
    "descricao": "Hemorragia intracerebral hemisférica subcortical"
  },
  {
    "codigo": "I611",
    "descricao": "Hemorragia intracerebral hemisférica cortical"
  },
  {
    "codigo": "I612",
    "descricao": "Hemorragia intracerebral hemisférica não especificada"
  },
  {
    "codigo": "I613",
    "descricao": "Hemorragia intracerebral do tronco cerebral"
  },
  { "codigo": "I614", "descricao": "Hemorragia intracerebral cerebelar" },
  {
    "codigo": "I615",
    "descricao": "Hemorragia intracerebral intraventricular"
  },
  {
    "codigo": "I616",
    "descricao": "Hemorragia intracerebral de múltiplas localizações"
  },
  { "codigo": "I618", "descricao": "Outras hemorragias intracerebrais" },
  {
    "codigo": "I619",
    "descricao": "Hemorragia intracerebral não especificada"
  },
  {
    "codigo": "I620",
    "descricao": "Hemorragia subdural (aguda) (não-traumática)"
  },
  {
    "codigo": "I621",
    "descricao": "Hemorragia extradural não-traumática"
  },
  {
    "codigo": "I629",
    "descricao": "Hemorragia intracraniana (não-traumática) não especificada"
  },
  {
    "codigo": "I630",
    "descricao": "Infarto cerebral devido a trombose de artérias pré-cerebrais"
  },
  {
    "codigo": "I631",
    "descricao": "Infarto cerebral devido a embolia de artérias pré-cerebrais"
  },
  {
    "codigo": "I632",
    "descricao": "Infarto cerebral devido a oclusão ou estenose não especificadas de artérias pré-cerebrais"
  },
  {
    "codigo": "I633",
    "descricao": "Infarto cerebral devido a trombose de artérias cerebrais"
  },
  {
    "codigo": "I634",
    "descricao": "Infarto cerebral devido a embolia de artérias cerebrais"
  },
  {
    "codigo": "I635",
    "descricao": "Infarto cerebral devido a oclusão ou estenose não especificadas de artérias cerebrais"
  },
  {
    "codigo": "I636",
    "descricao": "Infarto cerebral devido a trombose venosa cerebral não-piogênica"
  },
  { "codigo": "I638", "descricao": "Outros infartos cerebrais" },
  { "codigo": "I639", "descricao": "Infarto cerebral não especificado" },
  {
    "codigo": "I64",
    "descricao": "Acidente vascular cerebral, não especificado como hemorrágico ou isquêmico"
  },
  {
    "codigo": "I650",
    "descricao": "Oclusão e estenose da artéria vertebral"
  },
  {
    "codigo": "I651",
    "descricao": "Oclusão e estenose da artéria basilar"
  },
  {
    "codigo": "I652",
    "descricao": "Oclusão e estenose da artéria carótida"
  },
  {
    "codigo": "I653",
    "descricao": "Oclusão e estenose de artérias pré-cerebrais múltiplas e bilaterais"
  },
  {
    "codigo": "I658",
    "descricao": "Oclusão e estenose de outra artéria pré-cerebral"
  },
  {
    "codigo": "I659",
    "descricao": "Oclusão e estenose de artérias pré-cerebrais não especificadas"
  },
  {
    "codigo": "I660",
    "descricao": "Oclusão e estenose da artéria cerebral média"
  },
  {
    "codigo": "I661",
    "descricao": "Oclusão e estenose da artéria cerebral anterior"
  },
  {
    "codigo": "I662",
    "descricao": "Oclusão e estenose da artéria cerebral posterior"
  },
  {
    "codigo": "I663",
    "descricao": "Oclusão e estenose de artérias cerebelares"
  },
  {
    "codigo": "I664",
    "descricao": "Oclusão e estenose de artérias cerebrais, múltiplas e bilaterais"
  },
  {
    "codigo": "I668",
    "descricao": "Oclusão e estenose de outra artéria cerebral"
  },
  {
    "codigo": "I669",
    "descricao": "Oclusão e estenose de artéria cerebral não especificada"
  },
  {
    "codigo": "I670",
    "descricao": "Dissecção de artérias cerebrais, sem ruptura"
  },
  { "codigo": "I671", "descricao": "Aneurisma cerebral não-roto" },
  { "codigo": "I672", "descricao": "Aterosclerose cerebral" },
  { "codigo": "I673", "descricao": "Leucoencefalopatia vascular progressiva" },
  { "codigo": "I674", "descricao": "Encefalopatia hipertensiva" },
  { "codigo": "I675", "descricao": "Doença de Moyamoya" },
  {
    "codigo": "I676",
    "descricao": "Trombose não-piogênica do sistema venoso intracraniano"
  },
  {
    "codigo": "I677",
    "descricao": "Arterite cerebral não classificada em outra parte"
  },
  {
    "codigo": "I678",
    "descricao": "Outras doenças cerebrovasculares especificadas"
  },
  {
    "codigo": "I679",
    "descricao": "Doença cerebrovascular não especificada"
  },
  { "codigo": "I680", "descricao": "Angiopatia cerebral amiloidótica" },
  {
    "codigo": "I681",
    "descricao": "Arterite cerebral em doenças infecciosas e parasitárias"
  },
  {
    "codigo": "I682",
    "descricao": "Arterite cerebral em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "I688",
    "descricao": "Outros transtornos cerebrovasculares em doenças classificadas em outra parte"
  },
  {
    "codigo": "I690",
    "descricao": "Seqüelas de hemorragia subaracnoídea"
  },
  {
    "codigo": "I691",
    "descricao": "Seqüelas de hemorragia intracerebral"
  },
  {
    "codigo": "I692",
    "descricao": "Seqüelas de outras hemorragias intracranianas não traumáticas"
  },
  { "codigo": "I693", "descricao": "Seqüelas de infarto cerebral" },
  {
    "codigo": "I694",
    "descricao": "Seqüelas de acidente vascular cerebral não especificado como hemorrágico ou isquêmico"
  },
  {
    "codigo": "I698",
    "descricao": "Seqüelas de outras doenças cerebrovasculares e das não especificadas"
  },
  { "codigo": "I700", "descricao": "Aterosclerose da aorta" },
  { "codigo": "I701", "descricao": "Aterosclerose da artéria renal" },
  {
    "codigo": "I702",
    "descricao": "Aterosclerose das artérias das extremidades"
  },
  { "codigo": "I708", "descricao": "Aterosclerose de outras artérias" },
  {
    "codigo": "I709",
    "descricao": "Aterosclerose generalizada e a não especificada"
  },
  {
    "codigo": "I710",
    "descricao": "Aneurisma dissecante da aorta [qualquer porção]"
  },
  { "codigo": "I711", "descricao": "Aneurisma da aorta torácica, roto" },
  {
    "codigo": "I712",
    "descricao": "Aneurisma da aorta torácica, sem menção de ruptura"
  },
  { "codigo": "I713", "descricao": "Aneurisma da aorta abdominal, roto" },
  {
    "codigo": "I714",
    "descricao": "Aneurisma da aorta abdominal, sem menção de ruptura"
  },
  {
    "codigo": "I715",
    "descricao": "Aneurisma da aorta tóraco-abdominal, roto"
  },
  {
    "codigo": "I716",
    "descricao": "Aneurisma da aorta toráco-abdominal, sem menção de ruptura"
  },
  {
    "codigo": "I718",
    "descricao": "Aneurisma da aorta de localização não especificada, roto"
  },
  {
    "codigo": "I719",
    "descricao": "Aneurisma aórtico de localização não especificada, sem menção de ruptura"
  },
  { "codigo": "I720", "descricao": "Aneurisma da artéria carótida" },
  {
    "codigo": "I721",
    "descricao": "Aneurisma de artéria dos membros superiores"
  },
  { "codigo": "I722", "descricao": "Aneurisma da artéria renal" },
  { "codigo": "I723", "descricao": "Aneurisma de artéria ilíaca" },
  {
    "codigo": "I724",
    "descricao": "Aneurisma de artéria dos membros inferiores"
  },
  {
    "codigo": "I728",
    "descricao": "Aneurisma de outras artérias especificadas"
  },
  {
    "codigo": "I729",
    "descricao": "Aneurisma de localização não especificada"
  },
  { "codigo": "I730", "descricao": "Síndrome de Raynaud" },
  {
    "codigo": "I731",
    "descricao": "Tromboangeíte obliterante [doença de Buerger]"
  },
  {
    "codigo": "I738",
    "descricao": "Outras doenças vasculares periféricas especificadas"
  },
  {
    "codigo": "I739",
    "descricao": "Doenças vasculares periféricas não especificada"
  },
  { "codigo": "I740", "descricao": "Embolia e trombose da aorta abdominal" },
  {
    "codigo": "I741",
    "descricao": "Embolia e trombose de outras porções da aorta e das não especificadas"
  },
  {
    "codigo": "I742",
    "descricao": "Embolia e trombose de artérias dos membros superiores"
  },
  {
    "codigo": "I743",
    "descricao": "Embolia e trombose de artérias dos membros inferiores"
  },
  {
    "codigo": "I744",
    "descricao": "Embolia e trombose de artérias dos membros não especificadas"
  },
  {
    "codigo": "I745",
    "descricao": "Embolia e trombose da artéria ilíaca"
  },
  {
    "codigo": "I748",
    "descricao": "Embolia e trombose de outras artérias"
  },
  {
    "codigo": "I749",
    "descricao": "Embolia e trombose de artéria não especificada"
  },
  { "codigo": "I770", "descricao": "Fístula arteriovenosa adquirida" },
  { "codigo": "I771", "descricao": "Estenose de artéria" },
  { "codigo": "I772", "descricao": "Ruptura de artéria" },
  { "codigo": "I773", "descricao": "Displasia fibromuscular arterial" },
  {
    "codigo": "I774",
    "descricao": "Síndrome de compressão da artéria celíaca"
  },
  { "codigo": "I775", "descricao": "Necrose de artéria" },
  { "codigo": "I776", "descricao": "Arterite não especificada" },
  {
    "codigo": "I778",
    "descricao": "Outras afecções especificadas das artérias e das arteríolas"
  },
  {
    "codigo": "I779",
    "descricao": "Afecções de artérias e arteríolas, não especificadas"
  },
  {
    "codigo": "I780",
    "descricao": "Telangiectasia hemorrágica hereditária"
  },
  { "codigo": "I781", "descricao": "Nevo não-neoplásico" },
  { "codigo": "I788", "descricao": "Outras doenças dos capilares" },
  {
    "codigo": "I789",
    "descricao": "Doenças não especificadas dos capilares"
  },
  {
    "codigo": "I790",
    "descricao": "Aneurisma da aorta em doenças classificadas em outra parte"
  },
  {
    "codigo": "I791",
    "descricao": "Aortite em doenças classificadas em outra parte"
  },
  {
    "codigo": "I792",
    "descricao": "Angiopatia periférica em doenças classificadas em outra parte"
  },
  {
    "codigo": "I798",
    "descricao": "Outros transtornos das artérias, das arteríolas e dos capilares em doenças classificadas em outra parte"
  },
  {
    "codigo": "I800",
    "descricao": "Flebite e tromboflebite dos vasos superficiais dos membros inferiores"
  },
  { "codigo": "I801", "descricao": "Flebite e tromboflebite da veia femural" },
  {
    "codigo": "I802",
    "descricao": "Flebite e tromboflebite de outros vasos profundos dos membros inferiores"
  },
  {
    "codigo": "I803",
    "descricao": "Flebite e tromboflebite dos membros inferiores, não especificada"
  },
  {
    "codigo": "I808",
    "descricao": "Flebite e tromboflebite de outras localizações"
  },
  {
    "codigo": "I809",
    "descricao": "Flebite e tromboflebite de localização não especificada"
  },
  { "codigo": "I81", "descricao": "Trombose da veia porta" },
  { "codigo": "I820", "descricao": "Síndrome de Budd-Chiari" },
  { "codigo": "I821", "descricao": "Tromboflebite migratória" },
  { "codigo": "I822", "descricao": "Embolia e trombose de veia cava" },
  { "codigo": "I823", "descricao": "Embolia e trombose de veia renal" },
  {
    "codigo": "I828",
    "descricao": "Embolia e trombose de outras veias especificadas"
  },
  {
    "codigo": "I829",
    "descricao": "Embolia e trombose venosas de veia não especificada"
  },
  {
    "codigo": "I830",
    "descricao": "Varizes dos membros inferiores com úlcera"
  },
  {
    "codigo": "I831",
    "descricao": "Varizes dos membros inferiores com inflamação"
  },
  {
    "codigo": "I832",
    "descricao": "Varizes dos membros inferiores com úlcera e inflamação"
  },
  {
    "codigo": "I839",
    "descricao": "Varizes dos membros inferiores sem úlcera ou inflamação"
  },
  { "codigo": "I840", "descricao": "Hemorróidas internas trombosadas" },
  {
    "codigo": "I841",
    "descricao": "Hemorróidas internas com outras complicações"
  },
  {
    "codigo": "I842",
    "descricao": "Hemorróidas internas sem complicações"
  },
  { "codigo": "I843", "descricao": "Hemorróidas externas trombosadas" },
  {
    "codigo": "I844",
    "descricao": "Hemorróidas externas com outras complicações"
  },
  {
    "codigo": "I845",
    "descricao": "Hemorróidas externas sem complicação"
  },
  { "codigo": "I846", "descricao": "Plicomas hemorroidários residuais" },
  {
    "codigo": "I847",
    "descricao": "Hemorróidas trombosadas, não especificadas"
  },
  {
    "codigo": "I848",
    "descricao": "Hemorróidas não especificadas com outras complicações"
  },
  {
    "codigo": "I849",
    "descricao": "Hemorróidas sem complicações, não especificadas"
  },
  { "codigo": "I850", "descricao": "Varizes esofagianas sangrantes" },
  { "codigo": "I859", "descricao": "Varizes esofagianas sem sangramento" },
  { "codigo": "I860", "descricao": "Varizes sublinguais" },
  { "codigo": "I861", "descricao": "Varizes escrotais" },
  { "codigo": "I862", "descricao": "Varizes pélvicas" },
  { "codigo": "I863", "descricao": "Varizes da vulva" },
  { "codigo": "I864", "descricao": "Varizes gástricas" },
  {
    "codigo": "I868",
    "descricao": "Varizes de outras localizações especificadas"
  },
  { "codigo": "I870", "descricao": "Síndrome pós-flebite" },
  { "codigo": "I871", "descricao": "Compressão venosa" },
  {
    "codigo": "I872",
    "descricao": "Insuficiência venosa (crônica) (periférica)"
  },
  { "codigo": "I878", "descricao": "Outros transtornos venosos especificados" },
  { "codigo": "I879", "descricao": "Transtorno venoso não especificado" },
  {
    "codigo": "I880",
    "descricao": "Linfadenite mesentérica não específica"
  },
  {
    "codigo": "I881",
    "descricao": "Linfadenite crônica, exceto a mesentérica"
  },
  { "codigo": "I888", "descricao": "Outras linfadenites inespecíficas" },
  { "codigo": "I889", "descricao": "Linfadenite não especificada" },
  {
    "codigo": "I890",
    "descricao": "Linfedema não classificado em outra parte"
  },
  { "codigo": "I891", "descricao": "Linfangite" },
  {
    "codigo": "I898",
    "descricao": "Outros transtornos não-infecciosos, especificados, dos vasos linfáticos e dos gânglios linfáticos"
  },
  {
    "codigo": "I899",
    "descricao": "Transtornos não-infecciosos dos vasos linfáticos e dos gânglios linfáticos, não especificados"
  },
  { "codigo": "I950", "descricao": "Hipotensão idiopática" },
  { "codigo": "I951", "descricao": "Hipotensão ortostática" },
  { "codigo": "I952", "descricao": "Hipotensão devida a drogas" },
  { "codigo": "I958", "descricao": "Outros tipos de hipotensão" },
  { "codigo": "I959", "descricao": "Hipotensão não especificada" },
  { "codigo": "I970", "descricao": "Síndrome pós-cardiotomia" },
  {
    "codigo": "I971",
    "descricao": "Outros distúrbios funcionais subseqüentes à cirurgia cardíaca"
  },
  {
    "codigo": "I972",
    "descricao": "Síndrome do linfedema pós-mastectomia"
  },
  {
    "codigo": "I978",
    "descricao": "Outros transtornos do aparelho circulatório subseqüentes a procedimentos não classificados em outra parte"
  },
  {
    "codigo": "I979",
    "descricao": "Transtornos do aparelho circulatório, subseqüentes a procedimento não especificado"
  },
  { "codigo": "I980", "descricao": "Sífilis cardiovascular" },
  {
    "codigo": "I981",
    "descricao": "Transtornos cardiovasculares em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "I982",
    "descricao": "Varizes esofagianas em doenças classificadas em outra parte"
  },
  {
    "codigo": "I988",
    "descricao": "Outros transtornos especificados do aparelho circulatório em doenças classificadas em outra parte"
  },
  {
    "codigo": "I99",
    "descricao": "Outros transtornos do aparelho circulatório e os não especificados"
  },
  { "codigo": "J00", "descricao": "Nasofaringite aguda [resfriado comum]" },
  { "codigo": "J010", "descricao": "Sinusite maxilar aguda" },
  { "codigo": "J011", "descricao": "Sinusite frontal aguda" },
  { "codigo": "J012", "descricao": "Sinusite etmoidal aguda" },
  { "codigo": "J013", "descricao": "Sinusite esfenoidal aguda" },
  { "codigo": "J014", "descricao": "Pansinusite aguda" },
  { "codigo": "J018", "descricao": "Outras sinusites agudas" },
  { "codigo": "J019", "descricao": "Sinusite aguda não especificada" },
  { "codigo": "J020", "descricao": "Faringite estreptocócica" },
  {
    "codigo": "J028",
    "descricao": "Faringite aguda devida a outros microorganismos especificados"
  },
  { "codigo": "J029", "descricao": "Faringite aguda não especificada" },
  { "codigo": "J030", "descricao": "Amigdalite estreptocócica" },
  {
    "codigo": "J038",
    "descricao": "Amigdalite aguda devida a outros microorganismos especificados"
  },
  { "codigo": "J039", "descricao": "Amigdalite aguda não especificada" },
  { "codigo": "J040", "descricao": "Laringite aguda" },
  { "codigo": "J041", "descricao": "Traqueíte aguda" },
  { "codigo": "J042", "descricao": "Laringotraqueíte aguda" },
  { "codigo": "J050", "descricao": "Laringite obstrutiva aguda [crupe]" },
  { "codigo": "J051", "descricao": "Epiglotite aguda" },
  { "codigo": "J060", "descricao": "Laringofaringite aguda" },
  {
    "codigo": "J068",
    "descricao": "Outras infecções agudas das vias aéreas superiores de localizações múltiplas"
  },
  {
    "codigo": "J069",
    "descricao": "Infecção aguda das vias aéreas superiores não especificada"
  },
  {
    "codigo": "J09",
    "descricao": "Influenza [gripe] devida a vírus identificado da gripe aviária"
  },
  {
    "codigo": "J100",
    "descricao": "Influenza com pneumonia devida a outro vírus da influenza [gripe] identificado"
  },
  {
    "codigo": "J101",
    "descricao": "Influenza com outras manifestações respiratórias, devida a outro vírus da influenza [gripe] identificado"
  },
  {
    "codigo": "J108",
    "descricao": "Influenza com outras manifestações, devida a outro vírus da influenza [gripe] identificado"
  },
  {
    "codigo": "J110",
    "descricao": "Influenza [gripe] com pneumonia, devida a vírus não identificado"
  },
  {
    "codigo": "J111",
    "descricao": "Influenza [gripe] com outras manifestações respiratórias, devida a vírus não identificado"
  },
  {
    "codigo": "J118",
    "descricao": "Influenza [gripe] com outras manifestações, devida a vírus não identificado"
  },
  { "codigo": "J120", "descricao": "Pneumonia devida a adenovírus" },
  {
    "codigo": "J121",
    "descricao": "Pneumonia devida a vírus respiratório sincicial"
  },
  { "codigo": "J122", "descricao": "Pneumonia devida à parainfluenza" },
  { "codigo": "J128", "descricao": "Outras pneumonias virais" },
  { "codigo": "J129", "descricao": "Pneumonia viral não especificada" },
  {
    "codigo": "J13",
    "descricao": "Pneumonia devida a Streptococcus pneumoniae"
  },
  { "codigo": "J14", "descricao": "Pneumonia devida a Haemophilus infuenzae" },
  {
    "codigo": "J150",
    "descricao": "Pneumonia devida à Klebsiella pneumoniae"
  },
  { "codigo": "J151", "descricao": "Pneumonia devida a Pseudomonas" },
  { "codigo": "J152", "descricao": "Pneumonia devida a Staphylococcus" },
  {
    "codigo": "J153",
    "descricao": "Pneumonia devida a Streptococcus do grupo B"
  },
  { "codigo": "J154", "descricao": "Pneumonia devida a outros estreptococos" },
  { "codigo": "J155", "descricao": "Pneumonia devida a Escherichia coli" },
  {
    "codigo": "J156",
    "descricao": "Pneumonia devida a outras bactérias aeróbicas gram-negativas"
  },
  { "codigo": "J157", "descricao": "Pneumonia devida a Mycoplasma pneumoniae" },
  { "codigo": "J158", "descricao": "Outras pneumonias bacterianas" },
  {
    "codigo": "J159",
    "descricao": "Pneumonia bacteriana não especificada"
  },
  { "codigo": "J160", "descricao": "Pneumonia devida a clamídias" },
  {
    "codigo": "J168",
    "descricao": "Pneumonia devida a outros microorganismos infecciosos especificados"
  },
  {
    "codigo": "J170",
    "descricao": "Pneumonia em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "J171",
    "descricao": "Pneumonia em doenças virais classificadas em outra parte"
  },
  {
    "codigo": "J172",
    "descricao": "Pneumonia em micoses classificadas em outra parte"
  },
  {
    "codigo": "J173",
    "descricao": "Pneumonia em doenças parasitárias classificadas em outra parte"
  },
  {
    "codigo": "J178",
    "descricao": "Pneumonia em outras doenças classificadas em outra parte"
  },
  { "codigo": "J180", "descricao": "Broncopneumonia não especificada" },
  { "codigo": "J181", "descricao": "Pneumonia lobar não especificada" },
  {
    "codigo": "J182",
    "descricao": "Pneumonia hipostática não especificada"
  },
  {
    "codigo": "J188",
    "descricao": "Outras pneumonias devidas a microorganismos não especificados"
  },
  { "codigo": "J189", "descricao": "Pneumonia não especificada" },
  {
    "codigo": "J200",
    "descricao": "Bronquite aguda devida a Mycoplasma pneumoniae"
  },
  {
    "codigo": "J201",
    "descricao": "Bronquite aguda devida a Haemophilus influenzae"
  },
  { "codigo": "J202", "descricao": "Bronquite aguda devida a estreptococos" },
  {
    "codigo": "J203",
    "descricao": "Bronquite aguda devida a vírus Coxsackie"
  },
  {
    "codigo": "J204",
    "descricao": "Bronquite aguda devida a vírus parainfluenza"
  },
  {
    "codigo": "J205",
    "descricao": "Bronquite aguda devida a vírus sincicial respiratório"
  },
  { "codigo": "J206", "descricao": "Bronquite aguda devida a rinovírus" },
  { "codigo": "J207", "descricao": "Bronquite aguda devida a echovírus" },
  {
    "codigo": "J208",
    "descricao": "Bronquite aguda devida a outros microorganismos especificados"
  },
  { "codigo": "J209", "descricao": "Bronquite aguda não especificada" },
  {
    "codigo": "J210",
    "descricao": "Bronquiolite aguda devida a vírus sincicial respiratório"
  },
  {
    "codigo": "J218",
    "descricao": "Bronquiolite aguda devida a outros microorganismos especificados"
  },
  { "codigo": "J219", "descricao": "Bronquite aguda não especificada" },
  {
    "codigo": "J22",
    "descricao": "Infecções agudas não especificada das vias aéreas inferiores"
  },
  { "codigo": "J300", "descricao": "Rinite vasomotora" },
  { "codigo": "J301", "descricao": "Rinite alérgica devida a pólen" },
  { "codigo": "J302", "descricao": "Outras rinites alérgicas sazonais" },
  { "codigo": "J303", "descricao": "Outras rinites alérgicas" },
  {
    "codigo": "J304",
    "descricao": "Rinite alérgica não especificada"
  },
  { "codigo": "J310", "descricao": "Rinite crônica" },
  { "codigo": "J311", "descricao": "Nasofaringite crônica" },
  { "codigo": "J312", "descricao": "Faringite crônica" },
  { "codigo": "J320", "descricao": "Sinusite maxilar crônica" },
  { "codigo": "J321", "descricao": "Sinusite frontal crônica" },
  { "codigo": "J322", "descricao": "Sinusite etmoidal crônica" },
  { "codigo": "J323", "descricao": "Sinusite esfenoidal crônica" },
  { "codigo": "J324", "descricao": "Pansinusite crônica" },
  { "codigo": "J328", "descricao": "Outras sinusites crônicas" },
  {
    "codigo": "J329",
    "descricao": "Sinusite crônica não especificada"
  },
  { "codigo": "J330", "descricao": "Pólipo da cavidade nasal" },
  {
    "codigo": "J331",
    "descricao": "Degeneração polipóide do seio paranasal"
  },
  { "codigo": "J338", "descricao": "Outros pólipos do seio paranasal" },
  { "codigo": "J339", "descricao": "Pólipo nasal não especificado" },
  {
    "codigo": "J340",
    "descricao": "Abscesso, furúnculo e antraz do nariz"
  },
  {
    "codigo": "J341",
    "descricao": "Cisto e mucocele do nariz e do seio paranasal"
  },
  { "codigo": "J342", "descricao": "Desvio do septo nasal" },
  { "codigo": "J343", "descricao": "Hipertrofia dos cornetos nasais" },
  {
    "codigo": "J348",
    "descricao": "Outros transtornos especificados do nariz e dos seios paranasais"
  },
  { "codigo": "J350", "descricao": "Amigdalite crônica" },
  { "codigo": "J351", "descricao": "Hipertrofia das amígdalas" },
  { "codigo": "J352", "descricao": "Hipertrofia das adenóides" },
  {
    "codigo": "J353",
    "descricao": "Hipertrofia das amígdalas com hipertrofia das adenóides"
  },
  {
    "codigo": "J358",
    "descricao": "Outras doenças crônicas das amígdalas e das adenóides"
  },
  {
    "codigo": "J359",
    "descricao": "Doenças das amígdalas e das adenóides não especificadas"
  },
  { "codigo": "J36", "descricao": "Abscesso periamigdaliano" },
  { "codigo": "J370", "descricao": "Laringite crônica" },
  { "codigo": "J371", "descricao": "Laringotraqueíte crônica" },
  { "codigo": "J380", "descricao": "Paralisia das cordas vocais e da laringe" },
  {
    "codigo": "J381",
    "descricao": "Pólipo das cordas vocais e da laringe"
  },
  { "codigo": "J382", "descricao": "Nódulos das cordas vocais" },
  { "codigo": "J383", "descricao": "Outras doenças das cordas vocais" },
  { "codigo": "J384", "descricao": "Edema da laringe" },
  { "codigo": "J385", "descricao": "Espasmo da laringe" },
  { "codigo": "J386", "descricao": "Estenose da laringe" },
  { "codigo": "J387", "descricao": "Outras doenças da laringe" },
  {
    "codigo": "J390",
    "descricao": "Abscesso retrofaríngeo e parafaríngeo"
  },
  { "codigo": "J391", "descricao": "Outros abscessos da faringe" },
  { "codigo": "J392", "descricao": "Outras doenças da faringe" },
  {
    "codigo": "J393",
    "descricao": "Reação de hipersensibilidade das vias aéreas superiores de localização não especificada"
  },
  {
    "codigo": "J398",
    "descricao": "Outras doenças especificadas das vias aéreas superiores"
  },
  {
    "codigo": "J399",
    "descricao": "Doença não especificada das vias aéreas superiores"
  },
  {
    "codigo": "J40",
    "descricao": "Bronquite não especificada como aguda ou crônica"
  },
  { "codigo": "J410", "descricao": "Bronquite crônica simples" },
  { "codigo": "J411", "descricao": "Bronquite crônica mucopurulenta" },
  {
    "codigo": "J418",
    "descricao": "Bronquite crônica mista, simples e mucopurulenta"
  },
  {
    "codigo": "J42",
    "descricao": "Bronquite crônica não especificada"
  },
  { "codigo": "J430", "descricao": "Síndrome de MacLeod" },
  { "codigo": "J431", "descricao": "Enfisema panlobular" },
  { "codigo": "J432", "descricao": "Enfisema centrolobular" },
  { "codigo": "J438", "descricao": "Outras formas de enfisema" },
  { "codigo": "J439", "descricao": "Enfisema não especificado" },
  {
    "codigo": "J440",
    "descricao": "Doença pulmonar obstrutiva crônica com infecção respiratória aguda do trato respiratório inferior"
  },
  {
    "codigo": "J441",
    "descricao": "Doença pulmonar obstrutiva crônica com exacerbação aguda não especificada"
  },
  {
    "codigo": "J448",
    "descricao": "Outras formas especificadas de doença pulmonar obstrutiva crônica"
  },
  {
    "codigo": "J449",
    "descricao": "Doença pulmonar obstrutiva crônica não especificada"
  },
  { "codigo": "J450", "descricao": "Asma predominantemente alérgica" },
  { "codigo": "J451", "descricao": "Asma não-alérgica" },
  { "codigo": "J458", "descricao": "Asma mista" },
  { "codigo": "J459", "descricao": "Asma não especificada" },
  { "codigo": "J46", "descricao": "Estado de mal asmático" },
  { "codigo": "J47", "descricao": "Bronquectasia" },
  { "codigo": "J60", "descricao": "Pneumoconiose dos mineiros de carvão" },
  {
    "codigo": "J61",
    "descricao": "Pneumoconiose devida a amianto [asbesto] e outras fibras minerais"
  },
  { "codigo": "J620", "descricao": "Pneumoconiose devida a pó de talco" },
  {
    "codigo": "J628",
    "descricao": "Pneumoconiose devida a outras poeiras que contenham sílica"
  },
  { "codigo": "J630", "descricao": "Aluminose (do pulmão)" },
  {
    "codigo": "J631",
    "descricao": "Fibrose (do pulmão) causada por bauxita"
  },
  { "codigo": "J632", "descricao": "Beriliose" },
  {
    "codigo": "J633",
    "descricao": "Fibrose (do pulmão) causada por grafite"
  },
  { "codigo": "J634", "descricao": "Siderose" },
  { "codigo": "J635", "descricao": "Estanose" },
  {
    "codigo": "J638",
    "descricao": "Pneumoconiose devida a outras poeira inorgânicas especificados"
  },
  { "codigo": "J64", "descricao": "Pneumoconiose não especificada" },
  { "codigo": "J65", "descricao": "Pneumoconiose associada com tuberculose" },
  { "codigo": "J660", "descricao": "Bissinose" },
  { "codigo": "J661", "descricao": "Doença dos cardadores de linho" },
  { "codigo": "J662", "descricao": "Canabinose" },
  {
    "codigo": "J668",
    "descricao": "Doenças das vias aéreas devida a outras poeiras orgânicas específicas"
  },
  { "codigo": "J670", "descricao": "Pulmão de fazendeiro" },
  { "codigo": "J671", "descricao": "Bagaçose" },
  {
    "codigo": "J672",
    "descricao": "Pulmão dos criadores de pássaros"
  },
  { "codigo": "J673", "descricao": "Suberose" },
  { "codigo": "J674", "descricao": "Pulmão dos trabalhadores do malte" },
  {
    "codigo": "J675",
    "descricao": "Pulmão dos que trabalham com cogumelos"
  },
  {
    "codigo": "J676",
    "descricao": "Pulmão dos cortadores de casca do bordo"
  },
  {
    "codigo": "J677",
    "descricao": "Doença pulmonar devida aos sistemas de ar condicionado e de umidificação do ar"
  },
  {
    "codigo": "J678",
    "descricao": "Pneumonites de hipersensibilidade, devidas a outras poeiras orgânicas"
  },
  {
    "codigo": "J679",
    "descricao": "Pneumonite de hipersensibilidade devida a poeira orgânica não especificada"
  },
  {
    "codigo": "J680",
    "descricao": "Bronquite e pneumonite devida a produtos químicos, gases, fumaças e vapores"
  },
  {
    "codigo": "J681",
    "descricao": "Edema pulmonar devido a produtos químicos, gases, fumaças e vapores"
  },
  {
    "codigo": "J682",
    "descricao": "Inflamação das vias aéreas superiores devida a produtos químicos, gases, fumaças e vapores, não classificada em outra parte"
  },
  {
    "codigo": "J683",
    "descricao": "Outras afecções respiratórias agudas e subagudas devidas a produtos químicos, gases, fumaças e vapores"
  },
  {
    "codigo": "J684",
    "descricao": "Afecções respiratórias crônicas devidas a produtos químicos, gases, fumaças e vapores"
  },
  {
    "codigo": "J688",
    "descricao": "Outras afecções respiratórias devida a produtos químicos, gases, fumaças e vapores"
  },
  {
    "codigo": "J689",
    "descricao": "Afecção respiratória não especificada devida a produtos químicos, gases, fumaça e vapores"
  },
  {
    "codigo": "J690",
    "descricao": "Pneumonite devida a alimento ou vômito"
  },
  {
    "codigo": "J691",
    "descricao": "Pneumonite devida a óleos e essências"
  },
  {
    "codigo": "J698",
    "descricao": "Pneumonite devida a outros sólidos e líquidos"
  },
  {
    "codigo": "J700",
    "descricao": "Manifestações pulmonares agudas devidas à radiação"
  },
  {
    "codigo": "J701",
    "descricao": "Manifestações pulmonares crônicas e outras, devidas a radiação"
  },
  {
    "codigo": "J702",
    "descricao": "Transtornos pulmonares intersticiais agudos, induzidos por droga"
  },
  {
    "codigo": "J703",
    "descricao": "Transtornos pulmonares intersticiais crônicos induzidos por droga"
  },
  {
    "codigo": "J704",
    "descricao": "Transtornos pulmonar intersticial não especificado induzido por droga"
  },
  {
    "codigo": "J708",
    "descricao": "Afecções respiratórias devidas a outros agentes externos especificados"
  },
  {
    "codigo": "J709",
    "descricao": "Afecções respiratórias devidas a agentes externos não especificados"
  },
  {
    "codigo": "J80",
    "descricao": "Síndrome do desconforto respiratório do adulto"
  },
  {
    "codigo": "J81",
    "descricao": "Edema pulmonar, não especificado de outra forma"
  },
  {
    "codigo": "J82",
    "descricao": "Eosinofilia pulmonar, não classificada em outra parte"
  },
  {
    "codigo": "J840",
    "descricao": "Afecções alveolares e parieto-alveolares"
  },
  {
    "codigo": "J841",
    "descricao": "Outras doenças pulmonares intersticiais com fibrose"
  },
  {
    "codigo": "J848",
    "descricao": "Outras doenças pulmonares intersticiais especificadas"
  },
  {
    "codigo": "J849",
    "descricao": "Doença pulmonar intersticial não especificadas"
  },
  { "codigo": "J850", "descricao": "Gangrena e necrose do pulmão" },
  { "codigo": "J851", "descricao": "Abscesso do pulmão com pneumonia" },
  { "codigo": "J852", "descricao": "Abscesso do pulmão sem pneumonia" },
  { "codigo": "J853", "descricao": "Abscesso do mediastino" },
  { "codigo": "J860", "descricao": "Piotórax com fístula" },
  { "codigo": "J869", "descricao": "Piotórax sem fístula" },
  {
    "codigo": "J90",
    "descricao": "Derrame pleural não classificado em outra parte"
  },
  {
    "codigo": "J91",
    "descricao": "Derrame pleural em afecções classificadas em outra parte"
  },
  {
    "codigo": "J920",
    "descricao": "Placas pleurais com presença de amianto [asbesto]"
  },
  {
    "codigo": "J929",
    "descricao": "Placas pleurais sem presença de amianto [asbesto]"
  },
  {
    "codigo": "J930",
    "descricao": "Pneumotórax de tensão, espontâneo"
  },
  {
    "codigo": "J931",
    "descricao": "Outras formas de pneumotórax espontâneo"
  },
  {
    "codigo": "J938",
    "descricao": "Outros tipos de pneumotórax, especificados"
  },
  { "codigo": "J939", "descricao": "Pneumotórax não especificado" },
  { "codigo": "J940", "descricao": "Derrame quiloso ou quiliforme" },
  { "codigo": "J941", "descricao": "Fibrotórax" },
  { "codigo": "J942", "descricao": "Hemotórax" },
  {
    "codigo": "J948",
    "descricao": "Outras afecções pleurais especificadas"
  },
  {
    "codigo": "J949",
    "descricao": "Afecção pleural não especificada"
  },
  { "codigo": "J950", "descricao": "Mau funcionamento de traqueostomia" },
  {
    "codigo": "J951",
    "descricao": "Insuficiência pulmonar aguda subsequente a cirurgia torácica"
  },
  {
    "codigo": "J952",
    "descricao": "Insuficiência pulmonar aguda subsequente a cirurgia não torácica"
  },
  {
    "codigo": "J953",
    "descricao": "Insuficiência pulmonar crônica pós-cirúrgica"
  },
  { "codigo": "J954", "descricao": "Síndrome de Mendelson" },
  {
    "codigo": "J955",
    "descricao": "Estenose subglótica pós-procedimento"
  },
  {
    "codigo": "J958",
    "descricao": "Outros transtornos respiratórios pós-procedimentos"
  },
  {
    "codigo": "J959",
    "descricao": "Transtornos respiratórios pós-procedimentos não especificados"
  },
  {
    "codigo": "J960",
    "descricao": "Insuficiência respiratória aguda"
  },
  {
    "codigo": "J961",
    "descricao": "Insuficiência respiratória crônica"
  },
  {
    "codigo": "J969",
    "descricao": "Insuficiência respiratória não especificada"
  },
  {
    "codigo": "J980",
    "descricao": "Outras doenças dos brônquios não classificadas em outra parte"
  },
  { "codigo": "J981", "descricao": "Colapso pulmonar" },
  { "codigo": "J982", "descricao": "Enfisema intersticial" },
  { "codigo": "J983", "descricao": "Enfisema compensatório" },
  { "codigo": "J984", "descricao": "Outros transtornos pulmonares" },
  {
    "codigo": "J985",
    "descricao": "Doenças do mediastino não classificadas em outra parte"
  },
  { "codigo": "J986", "descricao": "Transtornos do diafragma" },
  {
    "codigo": "J988",
    "descricao": "Outros transtornos respiratórios especificados"
  },
  {
    "codigo": "J989",
    "descricao": "Transtorno respiratório não especificados"
  },
  { "codigo": "J990", "descricao": "Doença pulmonar reumatóide" },
  {
    "codigo": "J991",
    "descricao": "Transtornos respiratórios em outras doenças sistêmicas do tecido conjuntivo classificadas em outra parte"
  },
  {
    "codigo": "J998",
    "descricao": "Transtornos respiratórios em outras doenças classificadas em outra parte"
  },
  { "codigo": "K000", "descricao": "Anodontia" },
  { "codigo": "K001", "descricao": "Dentes supranumerários" },
  {
    "codigo": "K002",
    "descricao": "Anomalias do tamanho e da forma dos dentes"
  },
  { "codigo": "K003", "descricao": "Dentes manchados" },
  {
    "codigo": "K004",
    "descricao": "Distúrbios na formação dos dentes"
  },
  {
    "codigo": "K005",
    "descricao": "Anomalias hereditárias da estrutura dentária não classificadas em outra parte"
  },
  {
    "codigo": "K006",
    "descricao": "Distúrbios da erupção dentária"
  },
  {
    "codigo": "K007",
    "descricao": "Síndrome da erupção dentária"
  },
  {
    "codigo": "K008",
    "descricao": "Outros distúrbios do desenvolvimento dos dentes"
  },
  {
    "codigo": "K009",
    "descricao": "Distúrbio não especificado do desenvolvimento dentário"
  },
  { "codigo": "K010", "descricao": "Dentes inclusos" },
  { "codigo": "K011", "descricao": "Dentes impactados" },
  { "codigo": "K020", "descricao": "Cáries limitadas ao esmalte" },
  { "codigo": "K021", "descricao": "Cáries da dentina" },
  { "codigo": "K022", "descricao": "Cárie do cemento" },
  { "codigo": "K023", "descricao": "Cáries dentárias estáveis" },
  { "codigo": "K024", "descricao": "Odontoclasia" },
  { "codigo": "K028", "descricao": "Outras cáries dentárias" },
  {
    "codigo": "K029",
    "descricao": "Cárie dentária, sem outra especificação"
  },
  { "codigo": "K030", "descricao": "Atrito dentário excessivo" },
  { "codigo": "K031", "descricao": "Abrasão dentária" },
  { "codigo": "K032", "descricao": "Erosão dentária" },
  {
    "codigo": "K033",
    "descricao": "Reabsorção patológica dos dentes"
  },
  { "codigo": "K034", "descricao": "Hipercementose" },
  { "codigo": "K035", "descricao": "Ancilose dentária" },
  { "codigo": "K036", "descricao": "Depósitos nos dentes" },
  {
    "codigo": "K037",
    "descricao": "Alterações pós-eruptivas da cor dos tecidos duros dos dentes"
  },
  {
    "codigo": "K038",
    "descricao": "Outras doenças especificadas dos tecidos duros dos dentes"
  },
  {
    "codigo": "K039",
    "descricao": "Doença dos tecidos duros dos dentes, não especificada"
  },
  { "codigo": "K040", "descricao": "Pulpite" },
  { "codigo": "K041", "descricao": "Necrose da polpa" },
  { "codigo": "K042", "descricao": "Degeneração da polpa" },
  {
    "codigo": "K043",
    "descricao": "Formação anormal de tecidos duros na polpa"
  },
  {
    "codigo": "K044",
    "descricao": "Periodontite apical aguda de origem pulpar"
  },
  { "codigo": "K045", "descricao": "Periodontite apical crônica" },
  { "codigo": "K046", "descricao": "Abscesso periapical com fístula" },
  { "codigo": "K047", "descricao": "Abscesso periapical sem fístula" },
  { "codigo": "K048", "descricao": "Cisto radicular" },
  {
    "codigo": "K049",
    "descricao": "Outras doenças da polpa e dos tecidos periapicais e as não especificadas"
  },
  { "codigo": "K050", "descricao": "Gengivite aguda" },
  { "codigo": "K051", "descricao": "Gengivite crônica" },
  { "codigo": "K052", "descricao": "Periodontite aguda" },
  { "codigo": "K053", "descricao": "Periodontite crônica" },
  { "codigo": "K054", "descricao": "Periodontose" },
  { "codigo": "K055", "descricao": "Outras doenças periodontais" },
  {
    "codigo": "K056",
    "descricao": "Doença periodontal, sem outra especificação"
  },
  { "codigo": "K060", "descricao": "Retração gengival" },
  { "codigo": "K061", "descricao": "Hiperplasia gengival" },
  {
    "codigo": "K062",
    "descricao": "Lesões da gengiva e do rebordo alveolar sem dentes, associadas a traumatismos"
  },
  {
    "codigo": "K068",
    "descricao": "Outros transtornos especificados da gengiva e do rebordo alveolar sem dentes"
  },
  {
    "codigo": "K069",
    "descricao": "Transtorno da gengiva e do rebordo alveolar sem dentes sem outra especificação"
  },
  {
    "codigo": "K070",
    "descricao": "Anomalias importantes (major) do tamanho da mandíbula"
  },
  {
    "codigo": "K071",
    "descricao": "Anomalias da relação entre a mandíbula com a base do crânio"
  },
  {
    "codigo": "K072",
    "descricao": "Anomalias da relação entre as arcadas dentárias"
  },
  {
    "codigo": "K073",
    "descricao": "Anomalias da posição dos dentes"
  },
  { "codigo": "K074", "descricao": "Maloclusão, não especificada" },
  { "codigo": "K075", "descricao": "Anormalidades dentofaciais funcionais" },
  {
    "codigo": "K076",
    "descricao": "Transtornos da articulação temporomandibular"
  },
  { "codigo": "K078", "descricao": "Outras anomalias dentofaciais" },
  {
    "codigo": "K079",
    "descricao": "Anomalia dentofacial, sem outra especificação"
  },
  {
    "codigo": "K080",
    "descricao": "Exfoliação dos dentes devida a causas sistêmicas"
  },
  {
    "codigo": "K081",
    "descricao": "Perda de dentes devida a acidente, extração ou a doenças periodontais localizadas"
  },
  { "codigo": "K082", "descricao": "Atrofia do rebordo alveolar sem dentes" },
  { "codigo": "K083", "descricao": "Raiz dentária retida" },
  {
    "codigo": "K088",
    "descricao": "Outros transtornos especificados dos dentes e das estruturas de sustentação"
  },
  {
    "codigo": "K089",
    "descricao": "Transtorno dos dentes e de suas estruturas de sustentação, sem outra especificação"
  },
  {
    "codigo": "K090",
    "descricao": "Cistos odontogênicos de desenvolvimento"
  },
  {
    "codigo": "K091",
    "descricao": "Cistos de desenvolvimento (não-odontogênicos) da região bucal"
  },
  { "codigo": "K092", "descricao": "Outros cistos das mandíbulas" },
  {
    "codigo": "K098",
    "descricao": "Outros cistos da região oral não classificados em outra parte"
  },
  {
    "codigo": "K099",
    "descricao": "Cistos da região oral, sem outras especificações"
  },
  {
    "codigo": "K100",
    "descricao": "Transtornos do desenvolvimento dos maxilares"
  },
  {
    "codigo": "K101",
    "descricao": "Granuloma central de células gigantes"
  },
  {
    "codigo": "K102",
    "descricao": "Afecções inflamatórias dos maxilares"
  },
  { "codigo": "K103", "descricao": "Alveolite maxilar" },
  {
    "codigo": "K108",
    "descricao": "Outras doenças especificadas dos maxilares"
  },
  {
    "codigo": "K109",
    "descricao": "Doença dos maxilares, sem outra especificação"
  },
  { "codigo": "K110", "descricao": "Atrofia de glândula salivar" },
  { "codigo": "K111", "descricao": "Hipertrofia de glândula salivar" },
  { "codigo": "K112", "descricao": "Sialadenite" },
  { "codigo": "K113", "descricao": "Abscesso de glândula salivar" },
  { "codigo": "K114", "descricao": "Fístula de glândula salivar" },
  { "codigo": "K115", "descricao": "Sialolitíase" },
  { "codigo": "K116", "descricao": "Mucocele de glândula salivar" },
  {
    "codigo": "K117",
    "descricao": "Alterações da secreção salivar"
  },
  {
    "codigo": "K118",
    "descricao": "Outras doenças das glândulas salivares"
  },
  {
    "codigo": "K119",
    "descricao": "Doença de glândula salivar, sem outra especificação"
  },
  { "codigo": "K120", "descricao": "Aftas bucais recidivantes" },
  { "codigo": "K121", "descricao": "Outras formas de estomatite" },
  { "codigo": "K122", "descricao": "Celulite e abscesso da boca" },
  { "codigo": "K130", "descricao": "Doenças dos lábios" },
  {
    "codigo": "K131",
    "descricao": "Mordedura da mucosa das bochechas e dos lábios"
  },
  {
    "codigo": "K132",
    "descricao": "Leucoplasia e outras afecções do epitélio oral, inclusive da língua"
  },
  { "codigo": "K133", "descricao": "Leucoplasia pilosa" },
  {
    "codigo": "K134",
    "descricao": "Lesões granulomatosas e granulomatóides da mucosa oral"
  },
  { "codigo": "K135", "descricao": "Fibrose oral submucosa" },
  { "codigo": "K136", "descricao": "Hiperplasia irritativa da mucosa oral" },
  {
    "codigo": "K137",
    "descricao": "Outras lesões e as não especificadas da mucosa oral"
  },
  { "codigo": "K140", "descricao": "Glossite" },
  { "codigo": "K141", "descricao": "Língua geográfica" },
  { "codigo": "K142", "descricao": "Glossite rombóide mediana" },
  { "codigo": "K143", "descricao": "Hipertrofia das papilas linguais" },
  { "codigo": "K144", "descricao": "Atrofia das papilas linguais" },
  { "codigo": "K145", "descricao": "Língua escrotal" },
  { "codigo": "K146", "descricao": "Glossodínia" },
  { "codigo": "K148", "descricao": "Outras doenças da língua" },
  {
    "codigo": "K149",
    "descricao": "Doença da língua, sem outra especificação"
  },
  { "codigo": "K20", "descricao": "Esofagite" },
  {
    "codigo": "K210",
    "descricao": "Doença de refluxo gastroesofágico com esofagite"
  },
  {
    "codigo": "K219",
    "descricao": "Doença de refluxo gastroesofágico sem esofagite"
  },
  { "codigo": "K220", "descricao": "Acalásia do cárdia" },
  { "codigo": "K221", "descricao": "úlcera do esôfago" },
  { "codigo": "K222", "descricao": "Obstrução do esôfago" },
  { "codigo": "K223", "descricao": "Perfuração do esôfago" },
  { "codigo": "K224", "descricao": "Discinesia do esôfago" },
  {
    "codigo": "K225",
    "descricao": "Divertículo do esôfago adquirido"
  },
  {
    "codigo": "K226",
    "descricao": "Síndrome da laceração hemorrágica gastroesofágica"
  },
  { "codigo": "K227", "descricao": "Esôfago de Barret" },
  {
    "codigo": "K228",
    "descricao": "Outras doenças especificadas do esôfago"
  },
  {
    "codigo": "K229",
    "descricao": "Doença do esôfago, sem outra especificação"
  },
  { "codigo": "K230", "descricao": "Esofagite tuberculosa" },
  {
    "codigo": "K231",
    "descricao": "Megaesôfago na doença de Chagas"
  },
  {
    "codigo": "K238",
    "descricao": "Transtornos do esôfago em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "K250",
    "descricao": "úlcera gástrica - aguda com hemorragia"
  },
  {
    "codigo": "K251",
    "descricao": "úlcera gástrica - aguda com perfuração"
  },
  {
    "codigo": "K252",
    "descricao": "úlcera gástrica - aguda com hemorragia e perfuração"
  },
  {
    "codigo": "K253",
    "descricao": "úlcera gástrica - aguda sem hemorragia ou perfuração"
  },
  {
    "codigo": "K254",
    "descricao": "úlcera gástrica - crônica ou não especificada com hemorragia"
  },
  {
    "codigo": "K255",
    "descricao": "úlcera gástrica - crônica ou não especificada com perfuração"
  },
  {
    "codigo": "K256",
    "descricao": "úlcera gástrica - crônica ou não especificada com hemorragia e perfuração"
  },
  {
    "codigo": "K257",
    "descricao": "úlcera gástrica - crônica sem hemorragia ou perfuração"
  },
  {
    "codigo": "K259",
    "descricao": "úlcera gástrica - não especificada como aguda ou crônica, sem hemorragia ou perfuração"
  },
  {
    "codigo": "K260",
    "descricao": "úlcera duodenal - aguda com hemorragia"
  },
  {
    "codigo": "K261",
    "descricao": "úlcera duodenal - aguda com perfuração"
  },
  {
    "codigo": "K262",
    "descricao": "úlcera duodenal - aguda com hemorragia e perfuração"
  },
  {
    "codigo": "K263",
    "descricao": "úlcera duodenal - aguda sem hemorragia ou perfuração"
  },
  {
    "codigo": "K264",
    "descricao": "úlcera duodenal - crônica ou não especificada com hemorragia"
  },
  {
    "codigo": "K265",
    "descricao": "úlcera duodenal - crônica ou não especificada com perfuração"
  },
  {
    "codigo": "K266",
    "descricao": "úlcera duodenal - crônica ou não especificada com hemorragia e perfuração"
  },
  {
    "codigo": "K267",
    "descricao": "úlcera duodenal - crônica sem hemorragia ou perfuração"
  },
  {
    "codigo": "K269",
    "descricao": "úlcera duodenal - não especificada como aguda ou crônica, sem hemorragia ou perfuração"
  },
  {
    "codigo": "K270",
    "descricao": "úlcera péptica de localização não especificada - aguda com hemorragia"
  },
  {
    "codigo": "K271",
    "descricao": "úlcera péptica de localização não especificada - aguda com perfuração"
  },
  {
    "codigo": "K272",
    "descricao": "úlcera péptica de localização não especificada - aguda com hemorragia e perfuração"
  },
  {
    "codigo": "K273",
    "descricao": "úlcera péptica de localização não especificada - aguda sem hemorragia ou perfuração"
  },
  {
    "codigo": "K274",
    "descricao": "úlcera péptica de localização não especificada - crônica ou não especificada com hemorragia"
  },
  {
    "codigo": "K275",
    "descricao": "úlcera péptica de localização não especificada - crônica ou não especificada com perfuração"
  },
  {
    "codigo": "K276",
    "descricao": "úlcera péptica de localização não especificada - crônica ou não especificada com hemorragia e perfuração"
  },
  {
    "codigo": "K277",
    "descricao": "úlcera péptica de localização não especificada - crônica sem hemorragia ou perfuração"
  },
  {
    "codigo": "K279",
    "descricao": "úlcera péptica de localização não especificada - não especificada como aguda ou crônica, sem hemorragia ou perfuração"
  },
  {
    "codigo": "K280",
    "descricao": "úlcera gastrojejunal - aguda com hemorragia"
  },
  {
    "codigo": "K281",
    "descricao": "úlcera gastrojejunal - aguda com perfuração"
  },
  {
    "codigo": "K282",
    "descricao": "úlcera gastrojejunal - aguda com hemorragia e perfuração"
  },
  {
    "codigo": "K283",
    "descricao": "úlcera gastrojejunal - aguda sem hemorragia ou perfuração"
  },
  {
    "codigo": "K284",
    "descricao": "úlcera gastrojejunal - crônica ou não especificada com hemorragia"
  },
  {
    "codigo": "K285",
    "descricao": "úlcera gastrojejunal - crônica ou não especificada com perfuração"
  },
  {
    "codigo": "K286",
    "descricao": "úlcera gastrojejunal - crônica ou não especificada com hemorragia e perfuração"
  },
  {
    "codigo": "K287",
    "descricao": "úlcera gastrojejunal - crônica sem hemorragia ou perfuração"
  },
  {
    "codigo": "K289",
    "descricao": "úlcera gastrojejunal - não especificada como aguda ou crônica, sem hemorragia ou perfuração"
  },
  { "codigo": "K290", "descricao": "Gastrite hemorrágica aguda" },
  { "codigo": "K291", "descricao": "Outras gastrites agudas" },
  { "codigo": "K292", "descricao": "Gastrite alcoólica" },
  { "codigo": "K293", "descricao": "Gastrite superficial crônica" },
  { "codigo": "K294", "descricao": "Gastrite atrófica crônica" },
  {
    "codigo": "K295",
    "descricao": "Gastrite crônica, sem outra especificação"
  },
  { "codigo": "K296", "descricao": "Outras gastrites" },
  { "codigo": "K297", "descricao": "Gastrite não especificada" },
  { "codigo": "K298", "descricao": "Duodenite" },
  {
    "codigo": "K299",
    "descricao": "Gastroduodenite, sem outra especificação"
  },
  { "codigo": "K30", "descricao": "Dispepsia" },
  {
    "codigo": "K310",
    "descricao": "Dilatação aguda do estômago"
  },
  {
    "codigo": "K311",
    "descricao": "Estenose pilórica hipertrófica do adulto"
  },
  {
    "codigo": "K312",
    "descricao": "Estenose e estreitamento em ampulheta do estômago"
  },
  {
    "codigo": "K313",
    "descricao": "Espasmo do piloro não classificado em outra parte"
  },
  { "codigo": "K314", "descricao": "Divertículo gástrico" },
  { "codigo": "K315", "descricao": "Obstrução do duodeno" },
  {
    "codigo": "K316",
    "descricao": "Fístula do estômago e do duodeno"
  },
  {
    "codigo": "K317",
    "descricao": "Pólipo do estômago e do duodeno"
  },
  {
    "codigo": "K318",
    "descricao": "Outras doenças especificadas do estômago e do duodeno"
  },
  {
    "codigo": "K319",
    "descricao": "Doenças do estômago e do duodeno, sem outra especificação"
  },
  {
    "codigo": "K350",
    "descricao": "Apendicite aguda com peritonite generalizada"
  },
  { "codigo": "K351", "descricao": "Apendicite aguda com abscesso peritonial" },
  {
    "codigo": "K359",
    "descricao": "Apendicite aguda sem outra especificação"
  },
  { "codigo": "K36", "descricao": "Outras formas de apendicite" },
  {
    "codigo": "K37",
    "descricao": "Apendicite, sem outras especificações"
  },
  { "codigo": "K380", "descricao": "Hiperplasia do apêndice" },
  { "codigo": "K381", "descricao": "Concreções apendiculares" },
  { "codigo": "K382", "descricao": "Divertículo do apêndice" },
  { "codigo": "K383", "descricao": "Fístula do apêndice" },
  {
    "codigo": "K388",
    "descricao": "Outras doenças especificadas do apêndice"
  },
  {
    "codigo": "K389",
    "descricao": "Doença do apêndice, sem outra especificação"
  },
  {
    "codigo": "K400",
    "descricao": "Hérnia inguinal bilateral, com obstrução, sem gangrena"
  },
  {
    "codigo": "K401",
    "descricao": "Hérnia inguinal bilateral, com gangrena"
  },
  {
    "codigo": "K402",
    "descricao": "Hérnia inguinal bilateral, sem obstrução ou gangrena"
  },
  {
    "codigo": "K403",
    "descricao": "Hérnia inguinal unilateral ou não especificada, com obstrução sem gangrena"
  },
  {
    "codigo": "K404",
    "descricao": "Hérnia inguinal unilateral ou não especificada, com gangrena"
  },
  {
    "codigo": "K409",
    "descricao": "Hérnia inguinal unilateral ou não especificada, sem obstrução ou gangrena"
  },
  {
    "codigo": "K410",
    "descricao": "Hérnia femoral bilateral, com obstrução, sem gangrena"
  },
  {
    "codigo": "K411",
    "descricao": "Hérnia femoral bilateral, com gangrena"
  },
  {
    "codigo": "K412",
    "descricao": "Hérnia femoral bilateral, sem obstrução ou gangrena"
  },
  {
    "codigo": "K413",
    "descricao": "Hérnia femoral unilateral ou não especificada, com obstrução, sem gangrena"
  },
  {
    "codigo": "K414",
    "descricao": "Hérnia femoral unilateral ou não especificada, com gangrena"
  },
  {
    "codigo": "K419",
    "descricao": "Hérnia femoral unilateral ou não especificada, sem obstrução ou gangrena"
  },
  {
    "codigo": "K420",
    "descricao": "Hérnia umbilical com obstrução, sem gangrena"
  },
  { "codigo": "K421", "descricao": "Hérnia umbilical com gangrena" },
  {
    "codigo": "K429",
    "descricao": "Hérnia umbilical sem obstrução ou gangrena"
  },
  {
    "codigo": "K430",
    "descricao": "Hérnia ventral com obstrução, sem gangrena"
  },
  { "codigo": "K431", "descricao": "Hérnia ventral com gangrena" },
  {
    "codigo": "K439",
    "descricao": "Hérnia ventral sem obstrução ou gangrena"
  },
  {
    "codigo": "K440",
    "descricao": "Hérnia diafragmática com obstrução, sem gangrena"
  },
  {
    "codigo": "K441",
    "descricao": "Hérnia diafragmática com gangrena"
  },
  {
    "codigo": "K449",
    "descricao": "Hérnia diafragmática sem obstrução ou gangrena"
  },
  {
    "codigo": "K450",
    "descricao": "Outras hérnias abdominais especificadas, com obstrução, sem gangrena"
  },
  {
    "codigo": "K451",
    "descricao": "Outras hérnias abdominais especificadas, com gangrena"
  },
  {
    "codigo": "K458",
    "descricao": "Outras hérnias abdominais especificadas, sem obstrução ou gangrena"
  },
  {
    "codigo": "K460",
    "descricao": "Hérnia abdominal não especificada, com obstrução, sem gangrena"
  },
  {
    "codigo": "K461",
    "descricao": "Hérnia abdominal não especificada com gangrena"
  },
  {
    "codigo": "K469",
    "descricao": "Hérnia abdominal não especificada, sem obstrução ou gangrena"
  },
  {
    "codigo": "K500",
    "descricao": "Doença de Crohn do intestino delgado"
  },
  { "codigo": "K501", "descricao": "Doença de Crohn do intestino grosso" },
  { "codigo": "K508", "descricao": "Outra forma de doença de Crohn" },
  {
    "codigo": "K509",
    "descricao": "Doença de Crohn de localização não especificada"
  },
  { "codigo": "K510", "descricao": "Enterocolite ulcerativa (crônica)" },
  { "codigo": "K511", "descricao": "Ileocolite ulcerativa (crônica)" },
  { "codigo": "K512", "descricao": "Proctite ulcerativa (crônica)" },
  {
    "codigo": "K513",
    "descricao": "Retossigmoidite ulcerativa (crônica)"
  },
  { "codigo": "K514", "descricao": "Pseudopolipose do cólon" },
  { "codigo": "K515", "descricao": "Proctocolite mucosa" },
  { "codigo": "K518", "descricao": "Outras colites ulcerativas" },
  {
    "codigo": "K519",
    "descricao": "Colite ulcerativa, sem outra especificação"
  },
  {
    "codigo": "K520",
    "descricao": "Gastroenterite e colite devida à radiação"
  },
  { "codigo": "K521", "descricao": "Gastroenterite e colite tóxicas" },
  {
    "codigo": "K522",
    "descricao": "Gastroenterite e colite alérgicas ou ligadas à dieta"
  },
  {
    "codigo": "K528",
    "descricao": "Outras gastroenterites e colites especificadas, não-infecciosas"
  },
  {
    "codigo": "K529",
    "descricao": "Gastroenterite e colite não-infecciosas, não especificadas"
  },
  {
    "codigo": "K550",
    "descricao": "Transtornos vasculares agudos do intestino"
  },
  {
    "codigo": "K551",
    "descricao": "Transtornos vasculares crônicos do intestino"
  },
  { "codigo": "K552", "descricao": "Angiodisplasia do cólon" },
  {
    "codigo": "K558",
    "descricao": "Outros transtornos vasculares do intestino"
  },
  {
    "codigo": "K559",
    "descricao": "Transtorno vascular do intestino, sem outra especificação"
  },
  { "codigo": "K560", "descricao": "íleo paralítico" },
  { "codigo": "K561", "descricao": "Intussuscepção" },
  { "codigo": "K562", "descricao": "Volvo" },
  { "codigo": "K563", "descricao": "íleo biliar" },
  { "codigo": "K564", "descricao": "Outras obstruções do intestino" },
  {
    "codigo": "K565",
    "descricao": "Aderências intestinais (bridas) com obstrução"
  },
  {
    "codigo": "K566",
    "descricao": "Outras formas de obstrução intestinal, e as não especificadas"
  },
  { "codigo": "K567", "descricao": "íleo, não especificado" },
  {
    "codigo": "K570",
    "descricao": "Doença diverticular do intestino delgado com perfuração e abscesso"
  },
  {
    "codigo": "K571",
    "descricao": "Doença diverticular do intestino delgado sem perfuração ou abscesso"
  },
  {
    "codigo": "K572",
    "descricao": "Doença diverticular do intestino grosso com perfuração e abscesso"
  },
  {
    "codigo": "K573",
    "descricao": "Doença diverticular do intestino grosso sem perfuração ou abscesso"
  },
  {
    "codigo": "K574",
    "descricao": "Doença diverticular concomitante dos intestinos delgado e grosso com perfuração e abscesso"
  },
  {
    "codigo": "K575",
    "descricao": "Doença diverticular concomitante dos intestinos delgado e grosso sem perfuração ou abscesso"
  },
  {
    "codigo": "K578",
    "descricao": "Doença diverticular do intestino, de localização não especificada, com perfuração e abscesso"
  },
  {
    "codigo": "K579",
    "descricao": "Doença diverticular do intestino, de localização não especificada, sem perfuração ou abscesso"
  },
  {
    "codigo": "K580",
    "descricao": "Síndrome do cólon irritável com diarréia"
  },
  {
    "codigo": "K589",
    "descricao": "Síndrome do cólon irritável sem diarréia"
  },
  { "codigo": "K590", "descricao": "Constipação" },
  { "codigo": "K591", "descricao": "Diarréia funcional" },
  {
    "codigo": "K592",
    "descricao": "Cólon neurogênico não classificado em outra parte"
  },
  {
    "codigo": "K593",
    "descricao": "Megacólon não classificado em outra parte"
  },
  { "codigo": "K594", "descricao": "Espasmo anal" },
  {
    "codigo": "K598",
    "descricao": "Outros transtornos funcionais especificados do intestino"
  },
  {
    "codigo": "K599",
    "descricao": "Transtorno intestinal funcional, não especificado"
  },
  { "codigo": "K600", "descricao": "Fissura anal aguda" },
  { "codigo": "K601", "descricao": "Fissura anal crônica" },
  { "codigo": "K602", "descricao": "Fissura anal, não especificada" },
  { "codigo": "K603", "descricao": "Fístula anal" },
  { "codigo": "K604", "descricao": "Fístula retal" },
  { "codigo": "K605", "descricao": "Fístula anorretal" },
  { "codigo": "K610", "descricao": "Abscesso anal" },
  { "codigo": "K611", "descricao": "Abscesso retal" },
  { "codigo": "K612", "descricao": "Abscesso anorretal" },
  { "codigo": "K613", "descricao": "Abscesso ísquio-retal" },
  { "codigo": "K614", "descricao": "Abscesso intra-esfincteriano" },
  { "codigo": "K620", "descricao": "Pólipo anal" },
  { "codigo": "K621", "descricao": "Pólipo retal" },
  { "codigo": "K622", "descricao": "Prolapso anal" },
  { "codigo": "K623", "descricao": "Prolapso retal" },
  { "codigo": "K624", "descricao": "Estenose do ânus e do reto" },
  { "codigo": "K625", "descricao": "Hemorragia do ânus e do reto" },
  { "codigo": "K626", "descricao": "úlcera do ânus e do reto" },
  { "codigo": "K627", "descricao": "Proctite por radiação" },
  {
    "codigo": "K628",
    "descricao": "Outras doenças especificadas do ânus e do reto"
  },
  {
    "codigo": "K629",
    "descricao": "Doença do ânus e do reto, sem outra especificação"
  },
  { "codigo": "K630", "descricao": "Abscesso do intestino" },
  {
    "codigo": "K631",
    "descricao": "Perfuração do intestino (não-traumática)"
  },
  { "codigo": "K632", "descricao": "Fístula do intestino" },
  { "codigo": "K633", "descricao": "úlcera do intestino" },
  { "codigo": "K634", "descricao": "Enteroptose" },
  { "codigo": "K635", "descricao": "Pólipo do cólon" },
  {
    "codigo": "K638",
    "descricao": "Outras doenças especificadas do intestino"
  },
  {
    "codigo": "K639",
    "descricao": "Doença do intestino, sem outra especificação"
  },
  { "codigo": "K650", "descricao": "Peritonite aguda" },
  { "codigo": "K658", "descricao": "Outras peritonites" },
  {
    "codigo": "K659",
    "descricao": "Peritonite, sem outras especificações"
  },
  { "codigo": "K660", "descricao": "Aderências peritoniais" },
  { "codigo": "K661", "descricao": "Hemoperitônio" },
  {
    "codigo": "K668",
    "descricao": "Outras afecções especificadas do peritônio"
  },
  {
    "codigo": "K669",
    "descricao": "Afecções do peritônio, sem outra especificação"
  },
  { "codigo": "K670", "descricao": "Peritonite por clamídias" },
  { "codigo": "K671", "descricao": "Peritonite gonocócica" },
  { "codigo": "K672", "descricao": "Peritonite sifilítica" },
  { "codigo": "K673", "descricao": "Peritonite tuberculosa" },
  {
    "codigo": "K678",
    "descricao": "Outras formas de comprometimento do peritônio em doenças infecciosas classificadas em outra parte"
  },
  { "codigo": "K700", "descricao": "Fígado gorduroso alcoólico" },
  { "codigo": "K701", "descricao": "Hepatite alcoólica" },
  {
    "codigo": "K702",
    "descricao": "Fibrose e esclerose alcoólicas do fígado"
  },
  { "codigo": "K703", "descricao": "Cirrose hepática alcoólica" },
  {
    "codigo": "K704",
    "descricao": "Insuficiência hepática alcoólica"
  },
  {
    "codigo": "K709",
    "descricao": "Doença alcoólica do fígado, sem outra especificação"
  },
  {
    "codigo": "K710",
    "descricao": "Doença hepática tóxica com colestase"
  },
  {
    "codigo": "K711",
    "descricao": "Doença hepática tóxica com necrose hepática"
  },
  {
    "codigo": "K712",
    "descricao": "Doença hepática tóxica com hepatite aguda"
  },
  {
    "codigo": "K713",
    "descricao": "Doença hepática tóxica com hepatite crônica persistente"
  },
  {
    "codigo": "K714",
    "descricao": "Doença hepática tóxica com hepatite crônica lobular"
  },
  {
    "codigo": "K715",
    "descricao": "Doença hepática tóxica com hepatite crônica ativa"
  },
  {
    "codigo": "K716",
    "descricao": "Doença hepática tóxica com hepatite não classificada em outra parte"
  },
  {
    "codigo": "K717",
    "descricao": "Doença hepática tóxica com fibrose e cirrose hepáticas"
  },
  {
    "codigo": "K718",
    "descricao": "Doença hepática tóxica com outros transtornos do fígado"
  },
  {
    "codigo": "K719",
    "descricao": "Doença hepática tóxica, sem outra especificação"
  },
  {
    "codigo": "K720",
    "descricao": "Insuficiência hepática aguda e subaguda"
  },
  {
    "codigo": "K721",
    "descricao": "Insuficiência hepática crônica"
  },
  {
    "codigo": "K729",
    "descricao": "Insuficiência hepática, sem outras especificações"
  },
  {
    "codigo": "K730",
    "descricao": "Hepatite crônica persistente, não classificada em outra parte"
  },
  {
    "codigo": "K731",
    "descricao": "Hepatite crônica lobular, não classificada em outra parte"
  },
  {
    "codigo": "K732",
    "descricao": "Hepatite crônica ativa, não classificada em outra parte"
  },
  {
    "codigo": "K738",
    "descricao": "Outras hepatites crônicas não classificada em outra parte"
  },
  {
    "codigo": "K739",
    "descricao": "Hepatite crônica, sem outra especificação"
  },
  { "codigo": "K740", "descricao": "Fibrose hepática" },
  { "codigo": "K741", "descricao": "Esclerose hepática" },
  {
    "codigo": "K742",
    "descricao": "Fibrose hepática com esclerose hepática"
  },
  { "codigo": "K743", "descricao": "Cirrose biliar primária" },
  { "codigo": "K744", "descricao": "Cirrose biliar secundária" },
  {
    "codigo": "K745",
    "descricao": "Cirrose biliar, sem outra especificação"
  },
  {
    "codigo": "K746",
    "descricao": "Outras formas de cirrose hepática e as não especificadas"
  },
  { "codigo": "K750", "descricao": "Abscesso hepático" },
  { "codigo": "K751", "descricao": "Flebite da veia porta" },
  {
    "codigo": "K752",
    "descricao": "Hepatite reativa não-específica"
  },
  {
    "codigo": "K753",
    "descricao": "Hepatite granulomatosa não classificada em outra parte"
  },
  { "codigo": "K754", "descricao": "Hepatite autoimune" },
  {
    "codigo": "K758",
    "descricao": "Outras doenças hepáticas inflamatórias especificadas"
  },
  {
    "codigo": "K759",
    "descricao": "Doença hepática inflamatória, sem outra especificação"
  },
  {
    "codigo": "K760",
    "descricao": "Degeneração gordurosa do fígado não classificada em outra parte"
  },
  {
    "codigo": "K761",
    "descricao": "Congestão passiva crônica do fígado"
  },
  {
    "codigo": "K762",
    "descricao": "Necrose hemorrágica central do fígado"
  },
  { "codigo": "K763", "descricao": "Infarto do fígado" },
  { "codigo": "K764", "descricao": "Peliose hepática" },
  { "codigo": "K765", "descricao": "Doença hepática veno-oclusiva" },
  { "codigo": "K766", "descricao": "Hipertensão portal" },
  { "codigo": "K767", "descricao": "Síndrome hepatorrenal" },
  {
    "codigo": "K768",
    "descricao": "Outras doenças especificadas do fígado"
  },
  {
    "codigo": "K769",
    "descricao": "Doença hepática, sem outra especificação"
  },
  {
    "codigo": "K770",
    "descricao": "Transtornos hepáticos em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "K778",
    "descricao": "Transtornos hepáticos em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "K800",
    "descricao": "Calculose da vesícula biliar com colicistite aguda"
  },
  {
    "codigo": "K801",
    "descricao": "Calculose da vesícula biliar com outras formas de colecistite"
  },
  {
    "codigo": "K802",
    "descricao": "Calculose da vesícula biliar sem colecistite"
  },
  { "codigo": "K803", "descricao": "Calculose de via biliar com colangite" },
  { "codigo": "K804", "descricao": "Calculose de via biliar com colecistite" },
  {
    "codigo": "K805",
    "descricao": "Calculose de via biliar sem colangite ou colecistite"
  },
  { "codigo": "K808", "descricao": "Outras colelitíases" },
  { "codigo": "K810", "descricao": "Colecistite aguda" },
  { "codigo": "K811", "descricao": "Colecistite crônica" },
  { "codigo": "K818", "descricao": "Outras colecistites" },
  {
    "codigo": "K819",
    "descricao": "Colecistite, sem outra especificação"
  },
  {
    "codigo": "K820",
    "descricao": "Obstrução da vesícula biliar"
  },
  { "codigo": "K821", "descricao": "Hidropsia da vesícula biliar" },
  {
    "codigo": "K822",
    "descricao": "Perfuração da vesícula biliar"
  },
  { "codigo": "K823", "descricao": "Fístula da vesícula biliar" },
  { "codigo": "K824", "descricao": "Colesterolose da vesícula biliar" },
  {
    "codigo": "K828",
    "descricao": "Outras doenças especificadas da vesícula biliar"
  },
  {
    "codigo": "K829",
    "descricao": "Doença da vesícula biliar, sem outra especificação"
  },
  { "codigo": "K830", "descricao": "Colangite" },
  { "codigo": "K831", "descricao": "Obstrução de via biliar" },
  { "codigo": "K832", "descricao": "Perfuração de via biliar" },
  { "codigo": "K833", "descricao": "Fístula de via biliar" },
  { "codigo": "K834", "descricao": "Espasmo do esfíncter de Oddi" },
  { "codigo": "K835", "descricao": "Cisto biliar" },
  {
    "codigo": "K838",
    "descricao": "Outras doenças especificadas das vias biliares"
  },
  {
    "codigo": "K839",
    "descricao": "Doença das vias biliares, sem outra especificação"
  },
  { "codigo": "K850", "descricao": "Pancreatite aguda idiopática" },
  { "codigo": "K851", "descricao": "Pancreatite aguda biliar" },
  {
    "codigo": "K852",
    "descricao": "Pancreatite aguda induzida por álcool"
  },
  { "codigo": "K853", "descricao": "Pancreatite aguda induzida por droga" },
  { "codigo": "K858", "descricao": "Outras pancreatites agudas" },
  { "codigo": "K859", "descricao": "Pancreatite aguda, não especificada" },
  {
    "codigo": "K860",
    "descricao": "Pancreatite crônica induzida por álcool"
  },
  { "codigo": "K861", "descricao": "Outras pancreatites crônicas" },
  { "codigo": "K862", "descricao": "Cisto do pâncreas" },
  { "codigo": "K863", "descricao": "Pseudocisto do pâncreas" },
  {
    "codigo": "K868",
    "descricao": "Outras doenças especificadas do pâncreas"
  },
  {
    "codigo": "K869",
    "descricao": "Doença do pâncreas, sem outra especificação"
  },
  {
    "codigo": "K870",
    "descricao": "Transtornos da vesícula biliar e das vias biliares em doenças classificadas em outra parte"
  },
  {
    "codigo": "K871",
    "descricao": "Transtornos do pâncreas em doenças classificadas em outra parte"
  },
  { "codigo": "K900", "descricao": "Doença celíaca" },
  { "codigo": "K901", "descricao": "Espru tropical" },
  {
    "codigo": "K902",
    "descricao": "Síndrome da alça cega não classificada em outra parte"
  },
  { "codigo": "K903", "descricao": "Esteatorréia pancreática" },
  {
    "codigo": "K904",
    "descricao": "Má-absorção devida a intolerância não classificada em outra parte"
  },
  {
    "codigo": "K908",
    "descricao": "Outras formas de má-absorção intestinal"
  },
  {
    "codigo": "K909",
    "descricao": "Má-absorção intestinal, sem outra especificação"
  },
  {
    "codigo": "K910",
    "descricao": "Vômitos pós-cirurgia gastrointestinal"
  },
  {
    "codigo": "K911",
    "descricao": "Síndromes pós-cirurgia gástrica"
  },
  {
    "codigo": "K912",
    "descricao": "Má-absorção pós-cirúrgica não classificada em outra parte"
  },
  {
    "codigo": "K913",
    "descricao": "Obstrução intestinal pós-operatória"
  },
  {
    "codigo": "K914",
    "descricao": "Mau funcionamento de colostomia e enterostomia"
  },
  { "codigo": "K915", "descricao": "Síndrome pós-colecistectomia" },
  {
    "codigo": "K918",
    "descricao": "Outros transtornos do aparelho digestivo, pós-cirúrgicos, não classificados em outra parte"
  },
  {
    "codigo": "K919",
    "descricao": "Transtorno do aparelho digestivo pós procedimento"
  },
  { "codigo": "K920", "descricao": "Hematêmese" },
  { "codigo": "K921", "descricao": "Melena" },
  {
    "codigo": "K922",
    "descricao": "Hemorragia gastrointestinal, sem outra especificação"
  },
  {
    "codigo": "K928",
    "descricao": "Outras doenças especificadas do aparelho digestivo"
  },
  {
    "codigo": "K929",
    "descricao": "Doença do aparelho digestivo, sem outra especificação"
  },
  {
    "codigo": "K930",
    "descricao": "Transtornos devido a tuberculose do intestino, do peritônio e dos gânglios do mesentério"
  },
  { "codigo": "K931", "descricao": "Megacólon na doença de Chagas" },
  {
    "codigo": "K938",
    "descricao": "Transtornos de outros órgãos digestivos especificados em doenças classificadas em outra parte"
  },
  {
    "codigo": "L00",
    "descricao": "Síndrome da pele escaldada estafilocócica do recém-nascido"
  },
  {
    "codigo": "L010",
    "descricao": "Impetigo [qualquer localização] [qualquer microorganismo]"
  },
  {
    "codigo": "L011",
    "descricao": "Impetiginização de outras dermatoses"
  },
  {
    "codigo": "L020",
    "descricao": "Abscesso cutâneo, furúnculo e antraz da face"
  },
  {
    "codigo": "L021",
    "descricao": "Abscesso cutâneo, furúnculo e antraz do pescoço"
  },
  {
    "codigo": "L022",
    "descricao": "Abscesso cutâneo, furúnculo e antraz do tronco"
  },
  {
    "codigo": "L023",
    "descricao": "Abscesso cutâneo, furúnculo e antraz da nádega"
  },
  {
    "codigo": "L024",
    "descricao": "Abscesso cutâneo, furúnculo e antraz do(s) membro(s)"
  },
  {
    "codigo": "L028",
    "descricao": "Abscesso cutâneo, furúnculo e antraz de outras localizações"
  },
  {
    "codigo": "L029",
    "descricao": "Abscesso cutâneo, furúnculo e antraz de localização não especificada"
  },
  {
    "codigo": "L030",
    "descricao": "Celulite de dedos das mãos e dos pés"
  },
  {
    "codigo": "L031",
    "descricao": "Celulite de outras partes do(s) membro(s)"
  },
  { "codigo": "L032", "descricao": "Celulite da face" },
  { "codigo": "L033", "descricao": "Celulite do tronco" },
  { "codigo": "L038", "descricao": "Celulite de outros locais" },
  { "codigo": "L039", "descricao": "Celulite não especificada" },
  {
    "codigo": "L040",
    "descricao": "Linfadenite aguda de face, cabeça e pescoço"
  },
  { "codigo": "L041", "descricao": "Linfadenite aguda do tronco" },
  { "codigo": "L042", "descricao": "Linfadenite aguda de membro superior" },
  { "codigo": "L043", "descricao": "Linfadenite aguda de membro inferior" },
  {
    "codigo": "L048",
    "descricao": "Linfadenite aguda de outras localizações"
  },
  {
    "codigo": "L049",
    "descricao": "Linfadenite aguda de localização não especificada"
  },
  { "codigo": "L050", "descricao": "Cisto pilonidal com abscesso" },
  { "codigo": "L059", "descricao": "Cisto pilonidal sem abscesso" },
  { "codigo": "L080", "descricao": "Piodermite" },
  { "codigo": "L081", "descricao": "Eritrasma" },
  {
    "codigo": "L088",
    "descricao": "Outras infecções localizadas, especificadas, da pele e do tecido subcutâneo"
  },
  {
    "codigo": "L089",
    "descricao": "Infecção localizada da pele e do tecido subcutâneo, não especificada"
  },
  { "codigo": "L100", "descricao": "Pênfigo vulgar" },
  { "codigo": "L101", "descricao": "Pênfigo vegetante" },
  { "codigo": "L102", "descricao": "Pênfigo foliáceo" },
  { "codigo": "L103", "descricao": "Pênfigo brasileiro [fogo selvagem]" },
  { "codigo": "L104", "descricao": "Pênfigo eritematoso" },
  { "codigo": "L105", "descricao": "Pênfigo induzido por drogas" },
  { "codigo": "L108", "descricao": "Outras formas de pênfigo" },
  { "codigo": "L109", "descricao": "Pênfigo, não especificado" },
  { "codigo": "L110", "descricao": "Ceratose folicular adquirida" },
  {
    "codigo": "L111",
    "descricao": "Dermatose acantolítica transitória [Grover]"
  },
  {
    "codigo": "L118",
    "descricao": "Outras afecções acantolíticas especificadas"
  },
  {
    "codigo": "L119",
    "descricao": "Afecções acantolíticas não especificadas"
  },
  { "codigo": "L120", "descricao": "Penfigóide bolhoso" },
  { "codigo": "L121", "descricao": "Penfigóide cicatricial" },
  {
    "codigo": "L122",
    "descricao": "Doença bolhosa crônica da infância"
  },
  { "codigo": "L123", "descricao": "Epidermólise bolhosa adquirida" },
  { "codigo": "L128", "descricao": "Outras formas de penfigóide" },
  { "codigo": "L129", "descricao": "Penfigóide, não especificado" },
  { "codigo": "L130", "descricao": "Dermatite herpetiforme" },
  { "codigo": "L131", "descricao": "Dermatite pustular subcorneana" },
  {
    "codigo": "L138",
    "descricao": "Outras afecções bolhosas especificadas"
  },
  {
    "codigo": "L139",
    "descricao": "Doença bolhosa, não especificada"
  },
  {
    "codigo": "L14",
    "descricao": "Afecções bolhosas em doenças classificadas em outra parte"
  },
  { "codigo": "L200", "descricao": "Prurigo de Besnier" },
  { "codigo": "L208", "descricao": "Outras dermatites atópicas" },
  {
    "codigo": "L209",
    "descricao": "Dermatite atópica, não especificada"
  },
  { "codigo": "L210", "descricao": "Seborréia do couro cabeludo" },
  { "codigo": "L211", "descricao": "Dermatite seborréica infantil" },
  { "codigo": "L218", "descricao": "Outras dermatites seborréicas" },
  {
    "codigo": "L219",
    "descricao": "Dermatite seborréica, não especificada"
  },
  { "codigo": "L22", "descricao": "Dermatite das fraldas" },
  {
    "codigo": "L230",
    "descricao": "Dermatite alérgica de contato devida a metais"
  },
  {
    "codigo": "L231",
    "descricao": "Dermatite alérgica de contato devida a adesivos"
  },
  {
    "codigo": "L232",
    "descricao": "Dermatite alérgica de contato devida a cosméticos"
  },
  {
    "codigo": "L233",
    "descricao": "Dermatite alérgica de contato devido a drogas em contato com a pele"
  },
  {
    "codigo": "L234",
    "descricao": "Dermatite alérgica de contato devida a corantes"
  },
  {
    "codigo": "L235",
    "descricao": "Dermatite alérgica de contato devida a outros produtos químicos"
  },
  {
    "codigo": "L236",
    "descricao": "Dermatite alérgica de contato devida a alimentos em contato com a pele"
  },
  {
    "codigo": "L237",
    "descricao": "Dermatite alérgica de contato devido a plantas, exceto alimentos"
  },
  {
    "codigo": "L238",
    "descricao": "Dermatite alérgica de contato devido a outros agentes"
  },
  {
    "codigo": "L239",
    "descricao": "Dermatite alérgica de contato, de causa não especificada"
  },
  {
    "codigo": "L240",
    "descricao": "Dermatite de contato por irritantes devida a detergentes"
  },
  {
    "codigo": "L241",
    "descricao": "Dermatite de contato por irritantes devido a óleos e gorduras"
  },
  {
    "codigo": "L242",
    "descricao": "Dermatite de contato por irritantes devida a solventes"
  },
  {
    "codigo": "L243",
    "descricao": "Dermatite de contato por irritantes devida a cosméticos"
  },
  {
    "codigo": "L244",
    "descricao": "Dermatite de contato por irritantes devida a drogas em contato com a pele"
  },
  {
    "codigo": "L245",
    "descricao": "Dermatite de contato por irritantes devida a outros produtos químicos"
  },
  {
    "codigo": "L246",
    "descricao": "Dermatite de contato por irritantes devida a alimentos em contato com a pele"
  },
  {
    "codigo": "L247",
    "descricao": "Dermatite de contato por irritantes devido a plantas, exceto alimentos"
  },
  {
    "codigo": "L248",
    "descricao": "Dermatite de contato por irritantes devido a outros agentes"
  },
  {
    "codigo": "L249",
    "descricao": "Dermatite de contato por irritantes, de causa não especificada"
  },
  {
    "codigo": "L250",
    "descricao": "Dermatite de contato não especificada devida a cosméticos"
  },
  {
    "codigo": "L251",
    "descricao": "Dermatite de contato não especificada devida a drogas em contato com a pele"
  },
  {
    "codigo": "L252",
    "descricao": "Dermatite de contato não especificada devida a corantes"
  },
  {
    "codigo": "L253",
    "descricao": "Dermatite de contato não especificada devida a outros produtos químicos"
  },
  {
    "codigo": "L254",
    "descricao": "Dermatite de contato não especificada devida a alimentos em contato com a pele"
  },
  {
    "codigo": "L255",
    "descricao": "Dermatite de contato não especificada devida a plantas, exceto alimentos"
  },
  {
    "codigo": "L258",
    "descricao": "Dermatite de contato não especificada devida a outros agentes"
  },
  {
    "codigo": "L259",
    "descricao": "Dermatite de contato não especificada, de causa não especificada"
  },
  { "codigo": "L26", "descricao": "Dermatite esfoliativa" },
  {
    "codigo": "L270",
    "descricao": "Erupção cutânea generalizada devida a drogas e medicamentos"
  },
  {
    "codigo": "L271",
    "descricao": "Erupção cutânea localizada devida a drogas e medicamentos"
  },
  {
    "codigo": "L272",
    "descricao": "Dermatite devida à ingestão de alimentos"
  },
  {
    "codigo": "L278",
    "descricao": "Dermatite devida a outras substâncias de uso interno"
  },
  {
    "codigo": "L279",
    "descricao": "Dermatite devida a substância não especificada de uso interno"
  },
  { "codigo": "L280", "descricao": "Líquen simples crônico" },
  { "codigo": "L281", "descricao": "Prurigo nodular" },
  { "codigo": "L282", "descricao": "Outras formas de prurigo" },
  { "codigo": "L290", "descricao": "Prurido anal" },
  { "codigo": "L291", "descricao": "Prurido escrotal" },
  { "codigo": "L292", "descricao": "Prurido vulvar" },
  {
    "codigo": "L293",
    "descricao": "Prurido anogenital, não especificado"
  },
  { "codigo": "L298", "descricao": "Outras formas de prurido" },
  { "codigo": "L299", "descricao": "Prurido não especificado" },
  { "codigo": "L300", "descricao": "Dermatite numular" },
  { "codigo": "L301", "descricao": "Disidrose [pompholyx]" },
  {
    "codigo": "L302",
    "descricao": "Autossensibilização cutânea"
  },
  { "codigo": "L303", "descricao": "Dermatite infectada" },
  { "codigo": "L304", "descricao": "Intertrigo eritematoso" },
  { "codigo": "L305", "descricao": "Pitiríase alba" },
  { "codigo": "L308", "descricao": "Outras dermatites especificadas" },
  { "codigo": "L309", "descricao": "Dermatite não especificada" },
  { "codigo": "L400", "descricao": "Psoríase vulgar" },
  { "codigo": "L401", "descricao": "Psoríase pustulosa generalizada" },
  { "codigo": "L402", "descricao": "Acrodermatite contínua" },
  { "codigo": "L403", "descricao": "Pustulose palmar e plantar" },
  { "codigo": "L404", "descricao": "Psoríase gutata" },
  { "codigo": "L405", "descricao": "Artropatia psoriásica" },
  { "codigo": "L408", "descricao": "Outras formas de psoríase" },
  { "codigo": "L409", "descricao": "Psoríase não especificada" },
  {
    "codigo": "L410",
    "descricao": "Pitiríase liquenóide e varioliforme aguda"
  },
  {
    "codigo": "L411",
    "descricao": "Pitiríase liquenóide crônica"
  },
  { "codigo": "L412", "descricao": "Papulose linfomatóide" },
  { "codigo": "L413", "descricao": "Parapsoríase de pequenas placas" },
  { "codigo": "L414", "descricao": "Parapsoríase de grandes placas" },
  { "codigo": "L415", "descricao": "Parapsoríase retiforme" },
  { "codigo": "L418", "descricao": "Outras parapsoríases" },
  { "codigo": "L419", "descricao": "Parapsoríase, não especificada" },
  { "codigo": "L42", "descricao": "Pitiríase rósea" },
  { "codigo": "L430", "descricao": "Líquen plano hipertrófico" },
  { "codigo": "L431", "descricao": "Líquen plano bolhoso" },
  { "codigo": "L432", "descricao": "Reação liquenóide a droga" },
  { "codigo": "L433", "descricao": "Líquen plano subagudo (ativo)" },
  { "codigo": "L438", "descricao": "Outras formas de líquen plano" },
  { "codigo": "L439", "descricao": "Líquen plano, não especificado" },
  { "codigo": "L440", "descricao": "Pitiríase rubra pilar" },
  { "codigo": "L441", "descricao": "Líquen nítido" },
  { "codigo": "L442", "descricao": "Líquen estriado" },
  { "codigo": "L443", "descricao": "Líquen rubro moniliforme" },
  {
    "codigo": "L444",
    "descricao": "Acrodermatite papular infantil [Síndrome de Gianotti-Crosti]"
  },
  {
    "codigo": "L448",
    "descricao": "Outras afecções pápulo-descamativas especificadas"
  },
  {
    "codigo": "L449",
    "descricao": "Afecções pápulo-descamativas, não especificadas"
  },
  {
    "codigo": "L45",
    "descricao": "Afecções pápulo-descamativas em doenças classificadas em outra parte"
  },
  { "codigo": "L500", "descricao": "Urticária alérgica" },
  { "codigo": "L501", "descricao": "Urticária idiopática" },
  { "codigo": "L502", "descricao": "Urticária devida a frio e a calor" },
  { "codigo": "L503", "descricao": "Urticária dermatográfica" },
  { "codigo": "L504", "descricao": "Urticária vibratória" },
  { "codigo": "L505", "descricao": "Urticária colinérgica" },
  { "codigo": "L506", "descricao": "Urticária de contato" },
  { "codigo": "L508", "descricao": "Outras urticárias" },
  { "codigo": "L509", "descricao": "Urticária não especificada" },
  { "codigo": "L510", "descricao": "Eritema multiforme não bolhoso" },
  { "codigo": "L511", "descricao": "Eritema multiforme bolhoso" },
  {
    "codigo": "L512",
    "descricao": "Necrólise epidérmica tóxica [Síndrome de Lyell]"
  },
  { "codigo": "L518", "descricao": "Outras formas de eritema multiforme" },
  {
    "codigo": "L519",
    "descricao": "Eritema multiforme, não especificado"
  },
  { "codigo": "L52", "descricao": "Eritema nodoso" },
  { "codigo": "L530", "descricao": "Eritema tóxico" },
  { "codigo": "L531", "descricao": "Eritema anular centrífugo" },
  { "codigo": "L532", "descricao": "Eritema marginado" },
  {
    "codigo": "L533",
    "descricao": "Outras formas de eritema figurado crônico"
  },
  {
    "codigo": "L538",
    "descricao": "Outras afecções eritematosas especificadas"
  },
  {
    "codigo": "L539",
    "descricao": "Afecções eritematosas, não especificadas"
  },
  {
    "codigo": "L540",
    "descricao": "Eritema marginado na febre reumática aguda"
  },
  {
    "codigo": "L548",
    "descricao": "Eritema em outras doenças classificadas em outra parte"
  },
  { "codigo": "L550", "descricao": "Queimadura solar de primeiro grau" },
  { "codigo": "L551", "descricao": "Queimadura solar de segundo grau" },
  { "codigo": "L552", "descricao": "Queimadura solar de terceiro grau" },
  { "codigo": "L558", "descricao": "Outras queimaduras solares" },
  { "codigo": "L559", "descricao": "Queimadura solar, não especificada" },
  { "codigo": "L560", "descricao": "Resposta fototóxica a drogas" },
  { "codigo": "L561", "descricao": "Resposta fotoalérgica a drogas" },
  {
    "codigo": "L562",
    "descricao": "Dermatite por fotocontato [dermatite do berloque]"
  },
  { "codigo": "L563", "descricao": "Urticária solar" },
  { "codigo": "L564", "descricao": "Erupção polimorfa à luz" },
  {
    "codigo": "L568",
    "descricao": "Outras alterações agudas especificadas da pele devidas a radiação ultravioleta"
  },
  {
    "codigo": "L569",
    "descricao": "Alterações agudas da pele devidas a radiação ultravioleta, não especificadas"
  },
  { "codigo": "L570", "descricao": "Ceratose actínica" },
  { "codigo": "L571", "descricao": "Reticulóide actínica" },
  { "codigo": "L572", "descricao": "Cútis romboidal da nuca" },
  { "codigo": "L573", "descricao": "Poiquilodermia de Civatte" },
  { "codigo": "L574", "descricao": "Cútis laxa senil" },
  { "codigo": "L575", "descricao": "Granuloma actínico" },
  {
    "codigo": "L578",
    "descricao": "Outras alterações da pele devidas a exposição crônica à radiação não-ionizante"
  },
  {
    "codigo": "L579",
    "descricao": "Alterações da pele devidas a exposição crônica a radiação não-ionizante, não especificadas"
  },
  { "codigo": "L580", "descricao": "Radiodermatite aguda" },
  { "codigo": "L581", "descricao": "Radiodermatite crônica" },
  { "codigo": "L589", "descricao": "Radiodermatite, não especificada" },
  {
    "codigo": "L590",
    "descricao": "Eritema devido ao calor ou ao fogo [dermatite ab igne]"
  },
  {
    "codigo": "L598",
    "descricao": "Outras afecções especificadas da pele e do tecido subcutâneo relacionadas com a radiação"
  },
  {
    "codigo": "L599",
    "descricao": "Afecções da pele e do tecido subcutâneo relacionadas com a radiação, não especificadas"
  },
  { "codigo": "L600", "descricao": "Unha encravada" },
  { "codigo": "L601", "descricao": "Onicólise" },
  { "codigo": "L602", "descricao": "Onicogrifose" },
  { "codigo": "L603", "descricao": "Distrofia ungueal" },
  { "codigo": "L604", "descricao": "Linhas de Beau" },
  { "codigo": "L605", "descricao": "Síndrome das unhas amareladas" },
  { "codigo": "L608", "descricao": "Outras afecções das unhas" },
  {
    "codigo": "L609",
    "descricao": "Afecções das unhas, não especificadas"
  },
  {
    "codigo": "L620",
    "descricao": "Paquidermoperiostose com unhas hipocráticas (em vidro de relógio)"
  },
  {
    "codigo": "L628",
    "descricao": "Afecções das unhas em outras doenças classificadas em outra parte"
  },
  { "codigo": "L630", "descricao": "Alopécia total (capitis)" },
  { "codigo": "L631", "descricao": "Alopécia universal" },
  { "codigo": "L632", "descricao": "Ofíase" },
  { "codigo": "L638", "descricao": "Outras formas de alopécia areata" },
  {
    "codigo": "L639",
    "descricao": "Alopécia areata, não especificada"
  },
  {
    "codigo": "L640",
    "descricao": "Alopécia androgênica induzida por droga"
  },
  { "codigo": "L648", "descricao": "Outras alopécias androgênicas" },
  {
    "codigo": "L649",
    "descricao": "Alopécia androgênica, não especificada"
  },
  { "codigo": "L650", "descricao": "Eflúvio telógeno" },
  { "codigo": "L651", "descricao": "Eflúvio anágeno" },
  { "codigo": "L652", "descricao": "Alopécia mucinosa" },
  {
    "codigo": "L658",
    "descricao": "Outras formas, especificadas, não cicatriciais, de perda de cabelos ou pêlos"
  },
  {
    "codigo": "L659",
    "descricao": "Perda de cabelos ou pêlos não cicatriciais, não especificada"
  },
  { "codigo": "L660", "descricao": "Pseudopelada" },
  { "codigo": "L661", "descricao": "Líquen planopilar" },
  { "codigo": "L662", "descricao": "Foliculite descalvante" },
  { "codigo": "L663", "descricao": "Perifoliculite abscedante da cabeça" },
  { "codigo": "L664", "descricao": "Foliculite uleritematosa reticulada" },
  {
    "codigo": "L668",
    "descricao": "Outras formas de alopécia cicatricial"
  },
  {
    "codigo": "L669",
    "descricao": "Alopécia cicatricial, não especificada"
  },
  { "codigo": "L670", "descricao": "Tricorrexe nodosa" },
  {
    "codigo": "L671",
    "descricao": "Variações da cor dos cabelos ou pêlos"
  },
  {
    "codigo": "L678",
    "descricao": "Outras anormalidades da cor e do pedículo dos cabelos ou pêlos"
  },
  {
    "codigo": "L679",
    "descricao": "Anormalidades da cor e do pedículo dos cabelos e dos pêlos, não especificadas"
  },
  { "codigo": "L680", "descricao": "Hirsutismo" },
  { "codigo": "L681", "descricao": "Hipertricose lanuginosa adquirida" },
  { "codigo": "L682", "descricao": "Hipertricose localizada" },
  { "codigo": "L683", "descricao": "Politríquia" },
  { "codigo": "L688", "descricao": "Outras formas de hipertricose" },
  { "codigo": "L689", "descricao": "Hipertricose, não especificada" },
  { "codigo": "L700", "descricao": "Acne vulgar" },
  { "codigo": "L701", "descricao": "Acne conglobata" },
  { "codigo": "L702", "descricao": "Acne varioliforme" },
  { "codigo": "L703", "descricao": "Acne tropical" },
  { "codigo": "L704", "descricao": "Acne infantil" },
  { "codigo": "L705", "descricao": "Acne escoriada das mulheres jovens" },
  { "codigo": "L708", "descricao": "Outras formas de acne" },
  { "codigo": "L709", "descricao": "Acne, não especificada" },
  { "codigo": "L710", "descricao": "Dermatite perioral" },
  { "codigo": "L711", "descricao": "Rinofima" },
  { "codigo": "L718", "descricao": "Outras formas de rosácea" },
  { "codigo": "L719", "descricao": "Rosácea, não especificada" },
  { "codigo": "L720", "descricao": "Cisto epidérmico" },
  { "codigo": "L721", "descricao": "Cisto triquilemal" },
  { "codigo": "L722", "descricao": "Esteatocistoma múltiplo" },
  {
    "codigo": "L728",
    "descricao": "Outras formas de cistos foliculares da pele e do tecido subcutâneo"
  },
  {
    "codigo": "L729",
    "descricao": "Cisto folicular, não especificado da pele e do tecido subcutâneo"
  },
  { "codigo": "L730", "descricao": "Acne quelóide" },
  { "codigo": "L731", "descricao": "Pseudofoliculite da barba" },
  { "codigo": "L732", "descricao": "Hidradenite supurativa" },
  {
    "codigo": "L738",
    "descricao": "Outras afecções foliculares especificadas"
  },
  {
    "codigo": "L739",
    "descricao": "Afecções foliculares, não especificadas"
  },
  { "codigo": "L740", "descricao": "Miliária rubra" },
  { "codigo": "L741", "descricao": "Miliária cristalina" },
  { "codigo": "L742", "descricao": "Miliária profunda" },
  { "codigo": "L743", "descricao": "Miliária, não especificada" },
  { "codigo": "L744", "descricao": "Anidrose" },
  {
    "codigo": "L748",
    "descricao": "Outras afecções das glândulas sudoríparas écrinas"
  },
  {
    "codigo": "L749",
    "descricao": "Afecções das glândulas sudoríparas écrinas, não especificadas"
  },
  { "codigo": "L750", "descricao": "Bromidrose" },
  { "codigo": "L751", "descricao": "Cromidrose" },
  { "codigo": "L752", "descricao": "Miliária apócrina" },
  {
    "codigo": "L758",
    "descricao": "Outras afecções das glândulas sudoríparas apócrinas"
  },
  {
    "codigo": "L759",
    "descricao": "Afecções de glândulas sudoríparas apócrinas, não especificadas"
  },
  { "codigo": "L80", "descricao": "Vitiligo" },
  {
    "codigo": "L810",
    "descricao": "Hiperpigmentação pós-inflamatória"
  },
  { "codigo": "L811", "descricao": "Cloasma" },
  { "codigo": "L812", "descricao": "Sardas" },
  { "codigo": "L813", "descricao": "Manchas café-com-leite" },
  {
    "codigo": "L814",
    "descricao": "Outras formas de hiperpigmentação pela melanina"
  },
  {
    "codigo": "L815",
    "descricao": "Leucodermia não classificada em outra parte"
  },
  {
    "codigo": "L816",
    "descricao": "Outros transtornos ligados à diminuição da formação de melanina"
  },
  { "codigo": "L817", "descricao": "Dermatose purpúrica pigmentada" },
  {
    "codigo": "L818",
    "descricao": "Outros transtornos especificados da pigmentação"
  },
  {
    "codigo": "L819",
    "descricao": "Transtorno da pigmentação, não especificado"
  },
  { "codigo": "L82", "descricao": "Ceratose seborréica" },
  { "codigo": "L83", "descricao": "Acantose nigricans" },
  { "codigo": "L84", "descricao": "Calos e calosidades" },
  { "codigo": "L850", "descricao": "Ictiose adquirida" },
  {
    "codigo": "L851",
    "descricao": "Ceratose adquirida [ceratodermia] palmar e plantar"
  },
  { "codigo": "L852", "descricao": "Ceratose punctata (palmar e plantar)" },
  { "codigo": "L853", "descricao": "Xerose cutânea" },
  {
    "codigo": "L858",
    "descricao": "Outras formas de espessamento epidérmico"
  },
  {
    "codigo": "L859",
    "descricao": "Espessamento epidérmico, não especificado"
  },
  {
    "codigo": "L86",
    "descricao": "Ceratodermia em doenças classificadas em outra parte"
  },
  {
    "codigo": "L870",
    "descricao": "Ceratose folicular e parafolicular penetrante na pele [Síndrome de Kyrle]"
  },
  { "codigo": "L871", "descricao": "Colagenose perfurante reativa" },
  { "codigo": "L872", "descricao": "Elastose perfurante serpiginosa" },
  {
    "codigo": "L878",
    "descricao": "Outros transtornos da eliminação transepidérmica"
  },
  {
    "codigo": "L879",
    "descricao": "Transtorno da eliminação transepidérmica, não especificado"
  },
  { "codigo": "L88", "descricao": "Piodermite gangrenosa" },
  { "codigo": "L89", "descricao": "úlcera de decúbito" },
  { "codigo": "L900", "descricao": "Líquen escleroso e atrófico" },
  { "codigo": "L901", "descricao": "Anetodermia de Schweninger-Buzzi" },
  { "codigo": "L902", "descricao": "Anetodermia de Jadassohn-Pellizzari" },
  { "codigo": "L903", "descricao": "Atrofodermia de Pasini e Pierini" },
  { "codigo": "L904", "descricao": "Acrodermatite crônica atrófica" },
  { "codigo": "L905", "descricao": "Cicatrizes e fibrose cutânea" },
  { "codigo": "L906", "descricao": "Estrias atróficas" },
  {
    "codigo": "L908",
    "descricao": "Outras afecções atróficas da pele"
  },
  {
    "codigo": "L909",
    "descricao": "Afecções atróficas da pele, não especificadas"
  },
  { "codigo": "L910", "descricao": "Cicatriz quelóide" },
  {
    "codigo": "L918",
    "descricao": "Outras afecções hipertróficas da pele"
  },
  {
    "codigo": "L919",
    "descricao": "Afecções hipertróficas da pele, não especificadas"
  },
  { "codigo": "L920", "descricao": "Granuloma anular" },
  {
    "codigo": "L921",
    "descricao": "Necrobiose lipoídica não classificada em outra parte"
  },
  {
    "codigo": "L922",
    "descricao": "Granuloma facial [granuloma eosinófilo da pele]"
  },
  {
    "codigo": "L923",
    "descricao": "Granuloma de corpo estranho da pele e do tecido subcutâneo"
  },
  {
    "codigo": "L928",
    "descricao": "Outras afecções granulomatosas da pele e do tecido subcutâneo"
  },
  {
    "codigo": "L929",
    "descricao": "Afecções granulomatosas da pele e do tecido subcutâneo, não especificados"
  },
  { "codigo": "L930", "descricao": "Lúpus eritematoso discóide" },
  {
    "codigo": "L931",
    "descricao": "Lúpus eritematoso cutâneo subagudo"
  },
  {
    "codigo": "L932",
    "descricao": "Outras formas de lúpus eritematoso localizado"
  },
  { "codigo": "L940", "descricao": "Esclerodermia localizada [morféia]" },
  { "codigo": "L941", "descricao": "Esclerodermia linear" },
  { "codigo": "L942", "descricao": "Calcinose cutânea" },
  { "codigo": "L943", "descricao": "Esclerodactilia" },
  { "codigo": "L944", "descricao": "Pápulas de Gottron" },
  { "codigo": "L945", "descricao": "Poiquilodermia vascular atrófica" },
  { "codigo": "L946", "descricao": "Ainhum" },
  {
    "codigo": "L948",
    "descricao": "Outras afecções localizadas do tecido conjuntivo, especificadas"
  },
  {
    "codigo": "L949",
    "descricao": "Afecções localizadas do tecido conjuntivo, não especificadas"
  },
  { "codigo": "L950", "descricao": "Vasculite livedóide" },
  { "codigo": "L951", "descricao": "Eritema elevado diutino (duradouro)" },
  { "codigo": "L958", "descricao": "Outras vasculites limitadas a pele" },
  {
    "codigo": "L959",
    "descricao": "Vasculites limitadas a pele, não especificadas"
  },
  {
    "codigo": "L97",
    "descricao": "úlcera dos membros inferiores não classificada em outra parte"
  },
  { "codigo": "L980", "descricao": "Granuloma piogênico" },
  { "codigo": "L981", "descricao": "Dermatite factícia" },
  {
    "codigo": "L982",
    "descricao": "Dermatose neutrofílica febril [Sweet]"
  },
  { "codigo": "L983", "descricao": "Celulite eosinofílica [Wells]" },
  {
    "codigo": "L984",
    "descricao": "úlcera crônica da pele, não classificada em outra parte"
  },
  { "codigo": "L985", "descricao": "Mucinose da pele" },
  {
    "codigo": "L986",
    "descricao": "Outras afecções infiltrativas da pele e do tecido subcutâneo"
  },
  {
    "codigo": "L988",
    "descricao": "Outras afecções especificadas da pele e do tecido subcutâneo"
  },
  {
    "codigo": "L989",
    "descricao": "Afecções da pele e do tecido subcutâneo, não especificados"
  },
  { "codigo": "L990", "descricao": "Amiloidose cutânea" },
  {
    "codigo": "L998",
    "descricao": "Outras afecções especificadas da pele e do tecido subcutâneo em doenças classificadas em outra parte"
  },
  {
    "codigo": "M000",
    "descricao": "Artrite e pioliartrite estafilocócicas"
  },
  { "codigo": "M001", "descricao": "Artrite e poliartrite pneumocócicas" },
  {
    "codigo": "M002",
    "descricao": "Outras artrites e poliartrites estreptocócicas"
  },
  {
    "codigo": "M008",
    "descricao": "Artrite e poliartrite devidas a outro agente bacteriano especificado"
  },
  {
    "codigo": "M009",
    "descricao": "Artrite piogênica, não especificada"
  },
  { "codigo": "M010", "descricao": "Artrite meningocócica" },
  { "codigo": "M011", "descricao": "Artrite tuberculosa" },
  { "codigo": "M012", "descricao": "Artrite na doença de Lyme" },
  {
    "codigo": "M013",
    "descricao": "Artrite em outras doenças bacterianas classificadas em outra parte"
  },
  { "codigo": "M014", "descricao": "Artrite na rubéola" },
  {
    "codigo": "M015",
    "descricao": "Artrite em outras doenças virais classificadas em outra parte"
  },
  { "codigo": "M016", "descricao": "Artrite em micoses" },
  {
    "codigo": "M018",
    "descricao": "Artrite em outras doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "M020",
    "descricao": "Artropatia pós-derivação intestinal"
  },
  { "codigo": "M021", "descricao": "Artropatia pós-desintérica" },
  { "codigo": "M022", "descricao": "Artropatia pós-imunização" },
  { "codigo": "M023", "descricao": "Doença de Reiter" },
  { "codigo": "M028", "descricao": "Outras artropatias reacionais" },
  {
    "codigo": "M029",
    "descricao": "Artropatia reacional não especificada"
  },
  { "codigo": "M030", "descricao": "Artrite pós-meningocócica" },
  {
    "codigo": "M031",
    "descricao": "Artropatia pós-infecciosa na sífilis"
  },
  {
    "codigo": "M032",
    "descricao": "Outras artropatias pós-infecciosas em doenças classificadas em outra parte"
  },
  {
    "codigo": "M036",
    "descricao": "Artropatia reacional em outras doenças classificadas em outra parte"
  },
  { "codigo": "M050", "descricao": "Síndrome de Felty" },
  {
    "codigo": "M051",
    "descricao": "Doença reumatóide do pulmão"
  },
  { "codigo": "M052", "descricao": "Vasculite reumatóide" },
  {
    "codigo": "M053",
    "descricao": "Artrite reumatóide com comprometimento de outros órgãos e sistemas"
  },
  {
    "codigo": "M058",
    "descricao": "Outras artrites reumatóides soro-positivas"
  },
  {
    "codigo": "M059",
    "descricao": "Artrite reumatóide soro-positiva não especificada"
  },
  { "codigo": "M060", "descricao": "Artrite reumatóide soro-negativa" },
  { "codigo": "M061", "descricao": "Doença de Still do adulto" },
  { "codigo": "M062", "descricao": "Bursite reumatóide" },
  { "codigo": "M063", "descricao": "Nódulo reumatóide" },
  { "codigo": "M064", "descricao": "Poliartropatia inflamatória" },
  {
    "codigo": "M068",
    "descricao": "Outras artrites reumatóides especificadas"
  },
  {
    "codigo": "M069",
    "descricao": "Artrite reumatóide não especificada"
  },
  {
    "codigo": "M070",
    "descricao": "Artropatia psoriásica interfalangiana distal"
  },
  { "codigo": "M071", "descricao": "Artrite mutilante" },
  { "codigo": "M072", "descricao": "Espondilite psoriásica" },
  { "codigo": "M073", "descricao": "Outras artropatias psoriásicas" },
  {
    "codigo": "M074",
    "descricao": "Artropatia na doença de Crohn [enterite regional]"
  },
  { "codigo": "M075", "descricao": "Artropatia na colite ulcerativa" },
  { "codigo": "M076", "descricao": "Outras artropatias enteropáticas" },
  { "codigo": "M080", "descricao": "Artrite reumatóide juvenil" },
  { "codigo": "M081", "descricao": "Espondilite ancilosante juvenil" },
  {
    "codigo": "M082",
    "descricao": "Artrite juvenil com início sistêmico"
  },
  { "codigo": "M083", "descricao": "Poliartrite juvenil (soro-negativa)" },
  { "codigo": "M084", "descricao": "Artrite juvenil pauciarticular" },
  { "codigo": "M088", "descricao": "Outras artrites juvenis" },
  { "codigo": "M089", "descricao": "Artrite juvenil não especificada" },
  { "codigo": "M090", "descricao": "Artrite juvenil na psoríase" },
  {
    "codigo": "M091",
    "descricao": "Artrite juvenil na doença de Chron [enterite regional]"
  },
  { "codigo": "M092", "descricao": "Artrite juvenil na colite ulcerativa" },
  {
    "codigo": "M098",
    "descricao": "Artrite juvenil em outras doenças classificadas em outra parte"
  },
  { "codigo": "M100", "descricao": "Gota idiopática" },
  { "codigo": "M101", "descricao": "Gota induzida por chumbo" },
  { "codigo": "M102", "descricao": "Gota induzida por drogas" },
  {
    "codigo": "M103",
    "descricao": "Gota devida à disfunção renal"
  },
  { "codigo": "M104", "descricao": "Outra gota secundária" },
  { "codigo": "M109", "descricao": "Gota, não especificada" },
  {
    "codigo": "M110",
    "descricao": "Doença por deposição de hidroxiapatita"
  },
  { "codigo": "M111", "descricao": "Condrocalcinose familiar" },
  { "codigo": "M112", "descricao": "Outras condrocalcinoses" },
  {
    "codigo": "M118",
    "descricao": "Outras artropatias especificadas por deposição de cristais"
  },
  {
    "codigo": "M119",
    "descricao": "Artropatia por deposição de cristais, não especificada"
  },
  {
    "codigo": "M120",
    "descricao": "Artropatia pós-reumática crônica [Jaccoud]"
  },
  { "codigo": "M121", "descricao": "Doença de Kashin-Beck" },
  { "codigo": "M122", "descricao": "Sinovite vilonodular (pigmentada)" },
  { "codigo": "M123", "descricao": "Reumatismo palindrômico" },
  { "codigo": "M124", "descricao": "Hidrartrose intermitente" },
  { "codigo": "M125", "descricao": "Artropatia traumática" },
  {
    "codigo": "M128",
    "descricao": "Outras artropatias específicas não classificadas em outra parte"
  },
  { "codigo": "M130", "descricao": "Poliartrite não especificada" },
  {
    "codigo": "M131",
    "descricao": "Monoartrites não classificadas em outra parte"
  },
  { "codigo": "M138", "descricao": "Outras artrites especificadas" },
  { "codigo": "M139", "descricao": "Artrite não especificada" },
  {
    "codigo": "M140",
    "descricao": "Artropatia gotosa devida a defeitos enzimáticos e a outras doenças hereditárias"
  },
  {
    "codigo": "M141",
    "descricao": "Artropatia por depósito de cristais em outras doenças metabólicas classificadas em outra parte"
  },
  { "codigo": "M142", "descricao": "Artropatia diabética" },
  { "codigo": "M143", "descricao": "Dermatoartrite lipóide" },
  { "codigo": "M144", "descricao": "Artropatia na amiloidose" },
  {
    "codigo": "M145",
    "descricao": "Artropatias em outras doenças endócrinas, nutricionais e metabólicas"
  },
  { "codigo": "M146", "descricao": "Artropatia neuropática" },
  {
    "codigo": "M148",
    "descricao": "Artropatias em outras doenças especificadas classificadas em outra parte"
  },
  {
    "codigo": "M150",
    "descricao": "(Osteo)artrose primária generalizada"
  },
  {
    "codigo": "M151",
    "descricao": "Nódulos de Heberden (com artropatia)"
  },
  {
    "codigo": "M152",
    "descricao": "Nódulos de Bouchard (com artropatia)"
  },
  { "codigo": "M153", "descricao": "Artrose múltipla secundária" },
  { "codigo": "M154", "descricao": "(Osteo)artrose erosiva" },
  { "codigo": "M158", "descricao": "Outras poliartroses" },
  { "codigo": "M159", "descricao": "Poliartrose não especificada" },
  { "codigo": "M160", "descricao": "Coxartrose primária bilateral" },
  { "codigo": "M161", "descricao": "Outras coxartroses primárias" },
  {
    "codigo": "M162",
    "descricao": "Coxartrose bilateral resultante de displasia"
  },
  { "codigo": "M163", "descricao": "Outras coxartroses displásicas" },
  {
    "codigo": "M164",
    "descricao": "Coxartrose bilateral pós-traumática"
  },
  {
    "codigo": "M165",
    "descricao": "Outras coxartroses pós-traumáticas"
  },
  {
    "codigo": "M166",
    "descricao": "Outras coxartroses secundárias bilaterais"
  },
  { "codigo": "M167", "descricao": "Outras coxartroses secundárias" },
  { "codigo": "M169", "descricao": "Coxartrose não especificada" },
  { "codigo": "M170", "descricao": "Gonartrose primária bilateral" },
  { "codigo": "M171", "descricao": "Outras gonartroses primárias" },
  {
    "codigo": "M172",
    "descricao": "Gonartrose pós-traumática bilateral"
  },
  {
    "codigo": "M173",
    "descricao": "Outras gonartroses pós-traumática"
  },
  {
    "codigo": "M174",
    "descricao": "Outras gonartroses secundárias bilaterais"
  },
  { "codigo": "M175", "descricao": "Outras gonartroses secundárias" },
  { "codigo": "M179", "descricao": "Gonartrose não especificada" },
  {
    "codigo": "M180",
    "descricao": "Artrose primária bilateral das primeiras articulações carpometacarpianas"
  },
  {
    "codigo": "M181",
    "descricao": "Outras artroses primárias da primeira articulação carpometacarpiana"
  },
  {
    "codigo": "M182",
    "descricao": "Artrose pós-traumática bilateral da primeira articulação carpometacarpiana"
  },
  {
    "codigo": "M183",
    "descricao": "Outras artroses pós-traumáticas da primeira articulação carpometacarpiana"
  },
  {
    "codigo": "M184",
    "descricao": "Outras cartroses secundárias bilaterais das primeiras articulações carpometacarpianas"
  },
  {
    "codigo": "M185",
    "descricao": "Outras artroses secundárias da primeira articulação carpometacarpiana"
  },
  {
    "codigo": "M189",
    "descricao": "Artrose não especificada da primeira articulação carpometacarpiana"
  },
  {
    "codigo": "M190",
    "descricao": "Artrose primária de outras articulações"
  },
  {
    "codigo": "M191",
    "descricao": "Artrose pós-traumática de outras articulações"
  },
  { "codigo": "M192", "descricao": "Outras artroses secundárias" },
  { "codigo": "M198", "descricao": "Outras artroses especificadas" },
  { "codigo": "M199", "descricao": "Artrose não especificada" },
  {
    "codigo": "M200",
    "descricao": "Deformidade(s) do(s) dedo(s) das mãos"
  },
  { "codigo": "M201", "descricao": "Hallux valgo (adquirido)" },
  { "codigo": "M202", "descricao": "Hallux rigidus" },
  { "codigo": "M203", "descricao": "Outra deformidade do hallux (adquirida)" },
  { "codigo": "M204", "descricao": "Dedo(s) do pé em malho (adquirido)" },
  {
    "codigo": "M205",
    "descricao": "Outras deformidades (adquiridas) do(s) dedo(s) dos pés"
  },
  {
    "codigo": "M206",
    "descricao": "Deformidade adquirida não especificada de dedo(s) do pé"
  },
  {
    "codigo": "M210",
    "descricao": "Deformidade em valgo não classificada em outra parte"
  },
  {
    "codigo": "M211",
    "descricao": "Deformidade em varo não classificada em outra parte"
  },
  { "codigo": "M212", "descricao": "Deformidade em flexão" },
  {
    "codigo": "M213",
    "descricao": "Mão (pulso) ou pé pendente (adquirido)"
  },
  {
    "codigo": "M214",
    "descricao": "Pé chato [pé plano] (adquirido)"
  },
  {
    "codigo": "M215",
    "descricao": "Mão e pé em garra e mão e pé tortos adquiridos"
  },
  {
    "codigo": "M216",
    "descricao": "Outras deformidades adquiridas do tornozelo e do pé"
  },
  {
    "codigo": "M217",
    "descricao": "Desigualdade (adquirida) do comprimento dos membros"
  },
  {
    "codigo": "M218",
    "descricao": "Outras deformidades adquiridas especificadas dos membros"
  },
  {
    "codigo": "M219",
    "descricao": "Deformidade adquirida não especificada de membro"
  },
  { "codigo": "M220", "descricao": "Deslocamento recidivante da rótula" },
  {
    "codigo": "M221",
    "descricao": "Subluxação recidivante da rótula"
  },
  { "codigo": "M222", "descricao": "Transtornos femuropatelares" },
  { "codigo": "M223", "descricao": "Outros desarranjos da rótula" },
  { "codigo": "M224", "descricao": "Condromalácia da rótula" },
  { "codigo": "M228", "descricao": "Outros transtornos da rótula" },
  {
    "codigo": "M229",
    "descricao": "Transtorno da rótula, não especificado"
  },
  { "codigo": "M230", "descricao": "Menisco cístico" },
  { "codigo": "M231", "descricao": "Menisco discóide (congênito)" },
  {
    "codigo": "M232",
    "descricao": "Transtorno do menisco devido à ruptura ou lesão antiga"
  },
  { "codigo": "M233", "descricao": "Outros transtornos do menisco" },
  { "codigo": "M234", "descricao": "Corpo flutuante no joelho" },
  { "codigo": "M235", "descricao": "Instabilidade crônica do joelho" },
  {
    "codigo": "M236",
    "descricao": "Outras rupturas espontâneas de ligamento(s) do joelho"
  },
  { "codigo": "M238", "descricao": "Outros transtornos internos do joelho" },
  {
    "codigo": "M239",
    "descricao": "Transtorno interno não especificado do joelho"
  },
  { "codigo": "M240", "descricao": "Corpo flutuante em articulação" },
  {
    "codigo": "M241",
    "descricao": "Outros transtornos das cartilagens articulares"
  },
  { "codigo": "M242", "descricao": "Transtornos de ligamentos" },
  {
    "codigo": "M243",
    "descricao": "Deslocamento e subluxação patológicas de articulação, não classificada em outra parte"
  },
  {
    "codigo": "M244",
    "descricao": "Deslocamento e subluxação de articulação recidivantes"
  },
  { "codigo": "M245", "descricao": "Contratura articular" },
  { "codigo": "M246", "descricao": "Ancilose articular" },
  { "codigo": "M247", "descricao": "Protusão do acetábulo" },
  {
    "codigo": "M248",
    "descricao": "Outros transtornos articulares específicos, não classificados em outra parte"
  },
  {
    "codigo": "M249",
    "descricao": "Desarranjo articular não especificado"
  },
  { "codigo": "M250", "descricao": "Hemartrose" },
  { "codigo": "M251", "descricao": "Fístula articular" },
  { "codigo": "M252", "descricao": "Flail joint" },
  { "codigo": "M253", "descricao": "Outras instabilidades articulares" },
  { "codigo": "M254", "descricao": "Derrame articular" },
  { "codigo": "M255", "descricao": "Dor articular" },
  {
    "codigo": "M256",
    "descricao": "Rigidez articular não classificada em outra parte"
  },
  { "codigo": "M257", "descricao": "Osteofito" },
  {
    "codigo": "M258",
    "descricao": "Outros transtornos articulares especificados"
  },
  {
    "codigo": "M259",
    "descricao": "Transtorno articular não especificado"
  },
  { "codigo": "M300", "descricao": "Poliarterite nodosa" },
  {
    "codigo": "M301",
    "descricao": "Poliarterite com comprometimento pulmonar [Churg-Strauss]"
  },
  { "codigo": "M302", "descricao": "Poliarterite juvenil" },
  {
    "codigo": "M303",
    "descricao": "Síndrome de linfonodos mucocutâneos [Kawasaki]"
  },
  {
    "codigo": "M308",
    "descricao": "Outras afecções comuns na poliarterite nodosa"
  },
  { "codigo": "M310", "descricao": "Angeíte de hipersensibilidade" },
  { "codigo": "M311", "descricao": "Microangiopatia trombótica" },
  { "codigo": "M312", "descricao": "Granuloma da linha média letal" },
  { "codigo": "M313", "descricao": "Granulomatose de Wegener" },
  {
    "codigo": "M314",
    "descricao": "Síndrome do arco aórtico [Takayasu]"
  },
  {
    "codigo": "M315",
    "descricao": "Arterite de células gigantes com polimialgia reumática"
  },
  {
    "codigo": "M316",
    "descricao": "Outras arterites de células gigantes"
  },
  { "codigo": "M317", "descricao": "Poliangeíte microscópica" },
  {
    "codigo": "M318",
    "descricao": "Outras vasculopatias necrotizantes especificadas"
  },
  {
    "codigo": "M319",
    "descricao": "Vasculopatia necrotizante não especificada"
  },
  {
    "codigo": "M320",
    "descricao": "Lúpus eritematoso disseminado [sistêmico] induzido por drogas"
  },
  {
    "codigo": "M321",
    "descricao": "Lúpus eritematoso disseminado [sistêmico] com comprometimento de outros órgãos e sistemas"
  },
  {
    "codigo": "M328",
    "descricao": "Outras formas de lúpus eritematoso disseminado [sistêmico]"
  },
  {
    "codigo": "M329",
    "descricao": "Lúpus eritematoso disseminado [sistêmico] não especificado"
  },
  { "codigo": "M330", "descricao": "Dermatomiosite juvenil" },
  { "codigo": "M331", "descricao": "Outras dermatomiosites" },
  { "codigo": "M332", "descricao": "Polimiosite" },
  { "codigo": "M339", "descricao": "Dermatopolimiosite não especificada" },
  { "codigo": "M340", "descricao": "Esclerose sistêmica progressiva" },
  { "codigo": "M341", "descricao": "Síndrome CR(E)ST" },
  {
    "codigo": "M342",
    "descricao": "Esclerose sistêmica induzida por droga e substâncias químicas"
  },
  {
    "codigo": "M348",
    "descricao": "Outras formas de esclerose sistêmica"
  },
  {
    "codigo": "M349",
    "descricao": "Esclerose sistêmica não especificada"
  },
  { "codigo": "M350", "descricao": "Síndrome seca [Sjögren]" },
  { "codigo": "M351", "descricao": "Outras síndromes superpostas" },
  { "codigo": "M352", "descricao": "Doença de Behçet" },
  { "codigo": "M353", "descricao": "Polimialgia reumática" },
  { "codigo": "M354", "descricao": "Fasciíte (eosinofílica) difusa" },
  { "codigo": "M355", "descricao": "Fibroesclerose multifocal" },
  { "codigo": "M356", "descricao": "Paniculite recidivante [Weber-Christian]" },
  { "codigo": "M357", "descricao": "Síndrome de hipermobilidade" },
  {
    "codigo": "M358",
    "descricao": "Outro comprometimento sistêmico especificado do tecido conjuntivo"
  },
  {
    "codigo": "M359",
    "descricao": "Comprometimento sistêmico não especificado do tecido conjuntivo"
  },
  {
    "codigo": "M360",
    "descricao": "Dermato(poli)miosite em doenças neoplásicas"
  },
  {
    "codigo": "M361",
    "descricao": "Artropatia em doenças neoplásicas classificadas"
  },
  { "codigo": "M362", "descricao": "Artropatia hemofílica" },
  {
    "codigo": "M363",
    "descricao": "Artropatias em outras doenças hematológicas"
  },
  {
    "codigo": "M364",
    "descricao": "Artropatia associada a reações de hipersensibilidade classificadas em outra parte"
  },
  {
    "codigo": "M368",
    "descricao": "Doenças sistêmicas do tecido conjuntivo em outras doenças classificadas em outra parte"
  },
  { "codigo": "M400", "descricao": "Cifose postural" },
  { "codigo": "M401", "descricao": "Outras cifoses secundárias" },
  {
    "codigo": "M402",
    "descricao": "Outras cifoses e as não especificadas"
  },
  {
    "codigo": "M403",
    "descricao": "Síndrome da retificação da coluna vertebral"
  },
  { "codigo": "M404", "descricao": "Outras lordoses" },
  { "codigo": "M405", "descricao": "Lordose não especificada" },
  { "codigo": "M410", "descricao": "Escoliose idiopática infantil" },
  { "codigo": "M411", "descricao": "Escoliose idiopática juvenil" },
  { "codigo": "M412", "descricao": "Outras escolioses idiopáticas" },
  { "codigo": "M413", "descricao": "Escoliose toracogênica" },
  { "codigo": "M414", "descricao": "Escoliose neuromuscular" },
  { "codigo": "M415", "descricao": "Outras escolioses secundárias" },
  { "codigo": "M418", "descricao": "Outras formas de escoliose" },
  { "codigo": "M419", "descricao": "Escoliose não especificada" },
  { "codigo": "M420", "descricao": "Osteocondrose vertebral juvenil" },
  { "codigo": "M421", "descricao": "Osteocondrose vertebral do adulto" },
  {
    "codigo": "M429",
    "descricao": "Osteocondrose vertebral, não especificada"
  },
  { "codigo": "M430", "descricao": "Espondilólise" },
  { "codigo": "M431", "descricao": "Espondilolistese" },
  { "codigo": "M432", "descricao": "Outras fusões da coluna vertebral" },
  {
    "codigo": "M433",
    "descricao": "Subluxação atlanto-axial recidivante com mielopatia"
  },
  {
    "codigo": "M434",
    "descricao": "Outras subluxações atlanto-axiais recidivantes"
  },
  {
    "codigo": "M435",
    "descricao": "Outras subluxações vertebrais recidivantes"
  },
  { "codigo": "M436", "descricao": "Torcicolo" },
  {
    "codigo": "M438",
    "descricao": "Outras dorsopatias deformantes especificadas"
  },
  {
    "codigo": "M439",
    "descricao": "Dorsopatia deformante, não especificada"
  },
  { "codigo": "M45", "descricao": "Espondilite ancilosante" },
  { "codigo": "M460", "descricao": "Entesopatia vertebral" },
  {
    "codigo": "M461",
    "descricao": "Sacroileíte não classificada em outra parte"
  },
  { "codigo": "M462", "descricao": "Osteomielite das vértebras" },
  {
    "codigo": "M463",
    "descricao": "Infecção (piogênica) do disco intervertebral"
  },
  { "codigo": "M464", "descricao": "Discite não especificada" },
  { "codigo": "M465", "descricao": "Outras espondilopatias infecciosas" },
  {
    "codigo": "M468",
    "descricao": "Outras espondilopatias inflamatórias especificadas"
  },
  {
    "codigo": "M469",
    "descricao": "Espondilopatia inflamatória não especificada"
  },
  {
    "codigo": "M470",
    "descricao": "Síndromes de compressão da artéria espinhal anterior ou vertebral anterior"
  },
  { "codigo": "M471", "descricao": "Outras espondiloses com mielopatia" },
  { "codigo": "M472", "descricao": "Outras espondiloses com radiculopatias" },
  { "codigo": "M478", "descricao": "Outras espondiloses" },
  { "codigo": "M479", "descricao": "Espondilose não especificada" },
  { "codigo": "M480", "descricao": "Estenose da coluna vertebral" },
  { "codigo": "M481", "descricao": "Hiperostose ancilosante [Forestier]" },
  { "codigo": "M482", "descricao": "Kissing spine" },
  { "codigo": "M483", "descricao": "Espondilopatia traumática" },
  { "codigo": "M484", "descricao": "Fratura de fadiga de vértebra" },
  {
    "codigo": "M485",
    "descricao": "Vértebra colapsada não classificada em outra parte"
  },
  { "codigo": "M488", "descricao": "Outras espondilopatias especificadas" },
  { "codigo": "M489", "descricao": "Espondilopatia não especificada" },
  { "codigo": "M490", "descricao": "Tuberculose da coluna vertebral" },
  { "codigo": "M491", "descricao": "Espondilite por Brucella" },
  { "codigo": "M492", "descricao": "Espondilite por enterobactérias" },
  {
    "codigo": "M493",
    "descricao": "Espondilopatia em outras doenças infeciosas e parasitárias classificadas em outra parte"
  },
  { "codigo": "M494", "descricao": "Espondilopatia neuropática" },
  {
    "codigo": "M495",
    "descricao": "Vértebra colapsada em doenças classificadas em outra parte"
  },
  {
    "codigo": "M498",
    "descricao": "Espondilopatia em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "M500",
    "descricao": "Transtorno do disco cervical com mielopatia"
  },
  {
    "codigo": "M501",
    "descricao": "Transtorno do disco cervical com radiculopatia"
  },
  { "codigo": "M502", "descricao": "Outro deslocamento de disco cervical" },
  {
    "codigo": "M503",
    "descricao": "Outra degeneração de disco cervical"
  },
  { "codigo": "M508", "descricao": "Outros transtornos de discos cervicais" },
  {
    "codigo": "M509",
    "descricao": "Transtorno não especificado de disco cervical"
  },
  {
    "codigo": "M510",
    "descricao": "Transtornos de discos lombares e de outros discos intervertebrais com mielopatia"
  },
  {
    "codigo": "M511",
    "descricao": "Transtornos de discos lombares e de outros discos intervertebrais com radiculopatia"
  },
  {
    "codigo": "M512",
    "descricao": "Outros deslocamentos discais intervertebrais especificados"
  },
  {
    "codigo": "M513",
    "descricao": "Outra degeneração especificada de disco intervertebral"
  },
  { "codigo": "M514", "descricao": "Nódulos de Schmorl" },
  {
    "codigo": "M518",
    "descricao": "Outros transtornos especificados de discos intervertebrais"
  },
  {
    "codigo": "M519",
    "descricao": "Transtorno não especificado de disco intervertebral"
  },
  { "codigo": "M530", "descricao": "Síndrome cervicocraniana" },
  { "codigo": "M531", "descricao": "Síndrome cervicobraquial" },
  { "codigo": "M532", "descricao": "Instabilidades da coluna vertebral" },
  {
    "codigo": "M533",
    "descricao": "Transtornos sacroccígeos não classificados em outra parte"
  },
  { "codigo": "M538", "descricao": "Outras dorsopatias especificadas" },
  { "codigo": "M539", "descricao": "Dorsopatia não especificada" },
  {
    "codigo": "M540",
    "descricao": "Paniculite atingindo regiões do pescoço e do dorso"
  },
  { "codigo": "M541", "descricao": "Radiculopatia" },
  { "codigo": "M542", "descricao": "Cervicalgia" },
  { "codigo": "M543", "descricao": "Ciática" },
  { "codigo": "M544", "descricao": "Lumbago com ciática" },
  { "codigo": "M545", "descricao": "Dor lombar baixa" },
  { "codigo": "M546", "descricao": "Dor na coluna torácica" },
  { "codigo": "M548", "descricao": "Outra dorsalgia" },
  { "codigo": "M549", "descricao": "Dorsalgia não especificada" },
  { "codigo": "M600", "descricao": "Miosite infecciosa" },
  { "codigo": "M601", "descricao": "Miosite intersticial" },
  {
    "codigo": "M602",
    "descricao": "Granuloma de corpo estranho no tecido mole não classificado em outra parte"
  },
  { "codigo": "M608", "descricao": "Outras miosites" },
  { "codigo": "M609", "descricao": "Miosite não especificada" },
  { "codigo": "M610", "descricao": "Miosite ossificante traumática" },
  { "codigo": "M611", "descricao": "Miosite ossificante progressiva" },
  {
    "codigo": "M612",
    "descricao": "Calcificação e ossificação paralítica de músculo"
  },
  {
    "codigo": "M613",
    "descricao": "Calcificação e ossificação de músculo associadas com queimaduras"
  },
  {
    "codigo": "M614",
    "descricao": "Outra calcificação de músculo"
  },
  {
    "codigo": "M615",
    "descricao": "Outra ossificação de músculo"
  },
  {
    "codigo": "M619",
    "descricao": "Calcificação e ossificação de músculo não especificada"
  },
  { "codigo": "M620", "descricao": "Diástase de músculo" },
  {
    "codigo": "M621",
    "descricao": "Outras rupturas musculares (não-traumáticas)"
  },
  { "codigo": "M622", "descricao": "Infarto isquêmico do músculo" },
  {
    "codigo": "M623",
    "descricao": "Síndrome de imobilidade (paraplégica)"
  },
  { "codigo": "M624", "descricao": "Contratura de músculo" },
  {
    "codigo": "M625",
    "descricao": "Perda e atrofia muscular não classificadas em outra parte"
  },
  { "codigo": "M626", "descricao": "Distensão muscular" },
  {
    "codigo": "M628",
    "descricao": "Outros transtornos musculares especificados"
  },
  {
    "codigo": "M629",
    "descricao": "Transtorno muscular não especificado"
  },
  {
    "codigo": "M630",
    "descricao": "Miosite em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "M631",
    "descricao": "Miosite em doenças infecciosas causadas por protozoários e parasitas classificadas em outra parte"
  },
  {
    "codigo": "M632",
    "descricao": "Miosite em outras doenças infecciosas classificadas em outra parte"
  },
  { "codigo": "M633", "descricao": "Miosite na sarcoidose" },
  {
    "codigo": "M638",
    "descricao": "Outros transtornos musculares em doenças classificadas em outra parte"
  },
  { "codigo": "M650", "descricao": "Abscesso da bainha tendínea" },
  { "codigo": "M651", "descricao": "Outras (teno)sinovites infecciosas" },
  { "codigo": "M652", "descricao": "Tendinite calcificada" },
  { "codigo": "M653", "descricao": "Dedo em gatilho" },
  {
    "codigo": "M654",
    "descricao": "Tenossinovite estilóide radial [de Quervain]"
  },
  { "codigo": "M658", "descricao": "Outras sinovites e tenossinovites" },
  {
    "codigo": "M659",
    "descricao": "Sinovite e tenossinovite não especificadas"
  },
  { "codigo": "M660", "descricao": "Ruptura de cisto poplíteo" },
  { "codigo": "M661", "descricao": "Ruptura de sinóvia" },
  {
    "codigo": "M662",
    "descricao": "Ruptura espontânea de tendões extensores"
  },
  {
    "codigo": "M663",
    "descricao": "Ruptura espontânea de tendões flexores"
  },
  {
    "codigo": "M664",
    "descricao": "Ruptura espontânea de outros tendões"
  },
  {
    "codigo": "M665",
    "descricao": "Ruptura espontânea de tendões não especificados"
  },
  { "codigo": "M670", "descricao": "Tendão de Aquiles curto (adquirido)" },
  { "codigo": "M671", "descricao": "Outra contratura de tendão (bainha)" },
  {
    "codigo": "M672",
    "descricao": "Hipertrofia sinovial não classificada em outra parte"
  },
  { "codigo": "M673", "descricao": "Sinovite transitória" },
  { "codigo": "M674", "descricao": "Gânglios" },
  {
    "codigo": "M678",
    "descricao": "Outros transtornos especificados da sinóvia e do tendão"
  },
  {
    "codigo": "M679",
    "descricao": "Transtorno não especificado da sinóvia e do tendão"
  },
  {
    "codigo": "M680",
    "descricao": "Sinovite e tenossinovite em doenças bacterianas classificadas em outra parte"
  },
  {
    "codigo": "M688",
    "descricao": "Outros transtornos de sinóvias e de tendões em doenças classificadas em outra parte"
  },
  {
    "codigo": "M700",
    "descricao": "Sinovite crepitante crônica da mão e do punho"
  },
  { "codigo": "M701", "descricao": "Bursite da mão" },
  { "codigo": "M702", "descricao": "Bursite do olécrano" },
  { "codigo": "M703", "descricao": "Outras bursites do cotovelo" },
  { "codigo": "M704", "descricao": "Bursite pré-patelar" },
  { "codigo": "M705", "descricao": "Outras bursites do joelho" },
  { "codigo": "M706", "descricao": "Bursite trocantérica" },
  { "codigo": "M707", "descricao": "Outras bursites do quadril" },
  {
    "codigo": "M708",
    "descricao": "Outros transtornos dos tecidos moles relacionados com o uso, uso excessivo e pressão"
  },
  {
    "codigo": "M709",
    "descricao": "Transtorno não especificado dos tecidos moles relacionados com o uso, uso excessivo e pressão"
  },
  { "codigo": "M710", "descricao": "Abscesso de bolsa sinovial" },
  { "codigo": "M711", "descricao": "Outras bursites infecciosas" },
  {
    "codigo": "M712",
    "descricao": "Cisto sinovial do espaço poplíteo [Baker]"
  },
  { "codigo": "M713", "descricao": "Outros cistos de bolsa sinovial" },
  {
    "codigo": "M714",
    "descricao": "Depósito de cálcio em bolsa sinovial"
  },
  {
    "codigo": "M715",
    "descricao": "Outras bursites não classificadas em outra parte"
  },
  { "codigo": "M718", "descricao": "Outras bursopatias especificadas" },
  { "codigo": "M719", "descricao": "Bursopatia não especificada" },
  {
    "codigo": "M720",
    "descricao": "Fibromatose de fáscia palmar [Dupuytren]"
  },
  {
    "codigo": "M721",
    "descricao": "Coxins interfalângicos (nó dos dedos)"
  },
  { "codigo": "M722", "descricao": "Fibromatose da fáscia plantar" },
  { "codigo": "M724", "descricao": "Fibromatose pseudossarcomatosa" },
  { "codigo": "M726", "descricao": "Fasciíte necrosante" },
  { "codigo": "M728", "descricao": "Outros transtornos fibroblásticos" },
  {
    "codigo": "M729",
    "descricao": "Transtorno fibroblástico não especificado"
  },
  { "codigo": "M730", "descricao": "Bursite gonocócica" },
  { "codigo": "M731", "descricao": "Bursite sifilítica" },
  {
    "codigo": "M738",
    "descricao": "Outros transtornos dos tecidos moles em outras doenças classificadas em outra parte"
  },
  { "codigo": "M750", "descricao": "Capsulite adesiva do ombro" },
  { "codigo": "M751", "descricao": "Síndrome do manguito rotador" },
  { "codigo": "M752", "descricao": "Tendinite bicepital" },
  { "codigo": "M753", "descricao": "Tendinite calcificante do ombro" },
  { "codigo": "M754", "descricao": "Síndrome de colisão do ombro" },
  { "codigo": "M755", "descricao": "Bursite do ombro" },
  { "codigo": "M758", "descricao": "Outras lesões do ombro" },
  {
    "codigo": "M759",
    "descricao": "Lesão não especificada do ombro"
  },
  { "codigo": "M760", "descricao": "Tendinite glútea" },
  { "codigo": "M761", "descricao": "Tendinite do psoas" },
  { "codigo": "M762", "descricao": "Esporão da crista ilíaca" },
  { "codigo": "M763", "descricao": "Síndrome da faixa iliotibial" },
  {
    "codigo": "M764",
    "descricao": "Bursite tibial colateral [Pellegrini-Stieda]"
  },
  { "codigo": "M765", "descricao": "Tendinite patelar" },
  { "codigo": "M766", "descricao": "Tendinite aquileana" },
  { "codigo": "M767", "descricao": "Tendinite do perôneo" },
  {
    "codigo": "M768",
    "descricao": "Outras entesopatias do membro inferior, excluindo o pé"
  },
  {
    "codigo": "M769",
    "descricao": "Entesopatia do membro inferior não especificada"
  },
  { "codigo": "M770", "descricao": "Epicondilite medial" },
  { "codigo": "M771", "descricao": "Epicondilite lateral" },
  { "codigo": "M772", "descricao": "Periartrite do punho" },
  { "codigo": "M773", "descricao": "Esporão do calcâneo" },
  { "codigo": "M774", "descricao": "Metatarsalgia" },
  { "codigo": "M775", "descricao": "Outra entesopatia do pé" },
  {
    "codigo": "M778",
    "descricao": "Outras entesopatias não classificadas em outra parte"
  },
  { "codigo": "M779", "descricao": "Entesopatia não especificada" },
  { "codigo": "M790", "descricao": "Reumatismo não especificado" },
  { "codigo": "M791", "descricao": "Mialgia" },
  {
    "codigo": "M792",
    "descricao": "Nevralgia e neurite não especificadas"
  },
  { "codigo": "M793", "descricao": "Paniculite não especificada" },
  {
    "codigo": "M794",
    "descricao": "Hipertrofia do coxim gorduroso (infrapatelar)"
  },
  { "codigo": "M795", "descricao": "Corpo estranho residual no tecido mole" },
  { "codigo": "M796", "descricao": "Dor em membro" },
  { "codigo": "M797", "descricao": "Fibromialgia" },
  {
    "codigo": "M798",
    "descricao": "Outros transtornos especificados dos tecidos moles"
  },
  {
    "codigo": "M799",
    "descricao": "Transtorno dos tecidos moles não especificado"
  },
  {
    "codigo": "M800",
    "descricao": "Osteoporose pós-menopáusica com fratura patológica"
  },
  {
    "codigo": "M801",
    "descricao": "Osteoporose pós-ooforectomia com fratura patológica"
  },
  {
    "codigo": "M802",
    "descricao": "Osteoporose de desuso com fratura patológica"
  },
  {
    "codigo": "M803",
    "descricao": "Osteoporose por má-absorção pós-cirúrgica com fratura patológica"
  },
  {
    "codigo": "M804",
    "descricao": "Osteoporose induzida por drogas com fratura patológica"
  },
  {
    "codigo": "M805",
    "descricao": "Osteoporose idiopática com fratura patológica"
  },
  {
    "codigo": "M808",
    "descricao": "Outras osteoporoses com fratura patológica"
  },
  {
    "codigo": "M809",
    "descricao": "Osteoporose não especificada com fratura patológica"
  },
  { "codigo": "M810", "descricao": "Osteoporose pós-menopáusica" },
  { "codigo": "M811", "descricao": "Osteoporose pós-ooforectomia" },
  { "codigo": "M812", "descricao": "Osteoporose de desuso" },
  {
    "codigo": "M813",
    "descricao": "Osteoporose devida à má-absorção pós-cirúrgica"
  },
  { "codigo": "M814", "descricao": "Osteoporose induzida por drogas" },
  { "codigo": "M815", "descricao": "Osteoporose idiopática" },
  { "codigo": "M816", "descricao": "Osteoporose localizada [Lequesne]" },
  { "codigo": "M818", "descricao": "Outras osteoporoses" },
  { "codigo": "M819", "descricao": "Osteoporose não especificada" },
  { "codigo": "M820", "descricao": "Osteoporose na mielomatose múltipla" },
  {
    "codigo": "M821",
    "descricao": "Osteoporose em distúrbios endócrinos"
  },
  {
    "codigo": "M828",
    "descricao": "Osteoporose em outras doenças classificadas em outra parte"
  },
  { "codigo": "M830", "descricao": "Osteomalácia puerperal" },
  { "codigo": "M831", "descricao": "Osteomalácia senil" },
  {
    "codigo": "M832",
    "descricao": "Osteomalácia do adulto devida a má-absorção"
  },
  {
    "codigo": "M833",
    "descricao": "Osteomalácia do adulto devido à desnutrição"
  },
  {
    "codigo": "M834",
    "descricao": "Doença óssea pelo alumínio"
  },
  {
    "codigo": "M835",
    "descricao": "Outras osteomalácias do adulto induzidas por drogas"
  },
  { "codigo": "M838", "descricao": "Outra osteomalácia do adulto" },
  {
    "codigo": "M839",
    "descricao": "Osteomalácia não especificada do adulto"
  },
  {
    "codigo": "M840",
    "descricao": "Defeito de consolidação da fratura"
  },
  {
    "codigo": "M841",
    "descricao": "Ausência de consolidação da fratura [pseudo-artrose]"
  },
  {
    "codigo": "M842",
    "descricao": "Atraso de consolidação de fratura"
  },
  {
    "codigo": "M843",
    "descricao": "Fratura de fadiga (stress) não classificada em outra parte"
  },
  {
    "codigo": "M844",
    "descricao": "Fratura patológica não classificada em outra parte"
  },
  {
    "codigo": "M848",
    "descricao": "Outros transtornos da continuidade do osso"
  },
  {
    "codigo": "M849",
    "descricao": "Transtorno não especificado da continuidade do osso"
  },
  { "codigo": "M850", "descricao": "Displasia fibrosa (monostótica)" },
  { "codigo": "M851", "descricao": "Fluorose esquelética" },
  { "codigo": "M852", "descricao": "Hiperostose do crânio" },
  { "codigo": "M853", "descricao": "Osteíte condensante" },
  { "codigo": "M854", "descricao": "Cisto ósseo solitário" },
  { "codigo": "M855", "descricao": "Cisto ósseo aneurismático" },
  { "codigo": "M856", "descricao": "Outro cisto ósseo" },
  {
    "codigo": "M858",
    "descricao": "Outros transtornos especificados da densidade e da estrutura ósseas"
  },
  {
    "codigo": "M859",
    "descricao": "Transtorno não especificado da densidade e da estrutura ósseas"
  },
  { "codigo": "M860", "descricao": "Osteomielite aguda hematogênica" },
  { "codigo": "M861", "descricao": "Outra osteomielite aguda" },
  { "codigo": "M862", "descricao": "Osteomielite subaguda" },
  { "codigo": "M863", "descricao": "Osteomielite crônica multifocal" },
  {
    "codigo": "M864",
    "descricao": "Osteomielite crônica com seio drenante"
  },
  {
    "codigo": "M865",
    "descricao": "Outra osteomielite crônica hematogênica"
  },
  { "codigo": "M866", "descricao": "Outra osteomielite crônica" },
  { "codigo": "M868", "descricao": "Outra osteomielite" },
  { "codigo": "M869", "descricao": "Osteomielite não especificada" },
  {
    "codigo": "M870",
    "descricao": "Necrose asséptica idiopática do osso"
  },
  { "codigo": "M871", "descricao": "Osteonecrose devida a drogas" },
  {
    "codigo": "M872",
    "descricao": "Osteonecrose devida a traumatismo anterior"
  },
  { "codigo": "M873", "descricao": "Outras osteonecroses secundárias" },
  { "codigo": "M878", "descricao": "Outras osteonecroses" },
  { "codigo": "M879", "descricao": "Osteonecrose não especificada" },
  { "codigo": "M880", "descricao": "Doença de Paget do crânio" },
  { "codigo": "M888", "descricao": "Doença de Paget de outros ossos" },
  {
    "codigo": "M889",
    "descricao": "Doença de Paget de osso não especificado"
  },
  { "codigo": "M890", "descricao": "Algoneurodistrofia" },
  { "codigo": "M891", "descricao": "Parada de crescimento epifisário" },
  {
    "codigo": "M892",
    "descricao": "Outros transtornos do desenvolvimento e do crescimento ósseo"
  },
  { "codigo": "M893", "descricao": "Hipertrofia óssea" },
  {
    "codigo": "M894",
    "descricao": "Outras osteoartropatias hipertróficas"
  },
  { "codigo": "M895", "descricao": "Osteolise" },
  { "codigo": "M896", "descricao": "Osteopatia pós-poliomielite" },
  { "codigo": "M898", "descricao": "Outros transtornos especificados do osso" },
  { "codigo": "M899", "descricao": "Transtorno não especificado do osso" },
  { "codigo": "M900", "descricao": "Tuberculose óssea" },
  {
    "codigo": "M901",
    "descricao": "Periostite em outras doenças infecciosas classificadas em outra parte"
  },
  {
    "codigo": "M902",
    "descricao": "Osteopatia em outras doenças infecciosas classificadas em outra parte"
  },
  {
    "codigo": "M903",
    "descricao": "Osteonecrose em mal dos caixões"
  },
  {
    "codigo": "M904",
    "descricao": "Osteonecrose devida à hemoglobinopatia"
  },
  {
    "codigo": "M905",
    "descricao": "Osteonecrose em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "M906",
    "descricao": "Osteíte deformante em doenças neoplásicas"
  },
  {
    "codigo": "M907",
    "descricao": "Fratura óssea em doenças neoplásicas"
  },
  {
    "codigo": "M908",
    "descricao": "Osteopatia em outras doenças classificadas em outra parte"
  },
  { "codigo": "M910", "descricao": "Osteocondrose juvenil da pelve" },
  {
    "codigo": "M911",
    "descricao": "Osteocondrose juvenil da cabeça do fêmur [Legg-Calvé-Perthes]"
  },
  { "codigo": "M912", "descricao": "Coxa plana" },
  { "codigo": "M913", "descricao": "Pseudocoxalgia" },
  {
    "codigo": "M918",
    "descricao": "Outras osteocondroses juvenis do quadril e da pelve"
  },
  {
    "codigo": "M919",
    "descricao": "Osteocondrose juvenil não especificada do quadril e da pelve"
  },
  { "codigo": "M920", "descricao": "Osteocondrose juvenil do úmero" },
  {
    "codigo": "M921",
    "descricao": "Osteocondrose juvenil do rádio e do cúbito [ulna]"
  },
  { "codigo": "M922", "descricao": "Osteocondrose juvenil da mão" },
  {
    "codigo": "M923",
    "descricao": "Outras osteocondroses juvenis do membro superior"
  },
  {
    "codigo": "M924",
    "descricao": "Osteocondrose juvenil da rótula [patela]"
  },
  {
    "codigo": "M925",
    "descricao": "Osteocondrose juvenil da tíbia e perônio [fíbula]"
  },
  { "codigo": "M926", "descricao": "Osteocondrose juvenil do tarso" },
  { "codigo": "M927", "descricao": "Osteocondrose juvenil do metatarso" },
  {
    "codigo": "M928",
    "descricao": "Outras osteocondroses juvenis especificadas"
  },
  {
    "codigo": "M929",
    "descricao": "Osteocondrose juvenil, não especificada"
  },
  {
    "codigo": "M930",
    "descricao": "Luxação (não-traumática) da epífise superior do fêmur"
  },
  { "codigo": "M931", "descricao": "Doença de Kienböck do adulto" },
  { "codigo": "M932", "descricao": "Osteocondrite dissecante" },
  { "codigo": "M938", "descricao": "Outras osteocondropatias, especificadas" },
  { "codigo": "M939", "descricao": "Osteocondropatias, não especificada" },
  {
    "codigo": "M940",
    "descricao": "Síndrome da junção condrocostal [Tietze]"
  },
  { "codigo": "M941", "descricao": "Policondrite recidivante" },
  { "codigo": "M942", "descricao": "Condromalácia" },
  { "codigo": "M943", "descricao": "Condrolise" },
  {
    "codigo": "M948",
    "descricao": "Outros transtornos especificados da cartilagem"
  },
  {
    "codigo": "M949",
    "descricao": "Transtornos não especificados da cartilagem"
  },
  { "codigo": "M950", "descricao": "Deformidade adquirida do nariz" },
  { "codigo": "M951", "descricao": "Orelha em couve-flor" },
  {
    "codigo": "M952",
    "descricao": "Outras deformidades adquiridas da cabeça"
  },
  { "codigo": "M953", "descricao": "Deformidade adquirida do pescoço" },
  {
    "codigo": "M954",
    "descricao": "Deformidade adquirida do tórax e das costelas"
  },
  { "codigo": "M955", "descricao": "Deformidade adquirida da pelve" },
  {
    "codigo": "M958",
    "descricao": "Outras deformidades adquiridas especificadas do sistema osteomuscular"
  },
  {
    "codigo": "M959",
    "descricao": "Deformidade adquirida do sistema osteomuscular não especificada"
  },
  {
    "codigo": "M960",
    "descricao": "Pseudo-artrose após fusão ou artrodese"
  },
  {
    "codigo": "M961",
    "descricao": "Síndrome pós-laminectomia não classificada em outra parte"
  },
  { "codigo": "M962", "descricao": "Cifose pós-radiação" },
  { "codigo": "M963", "descricao": "Cifose pós-laminectomia" },
  { "codigo": "M964", "descricao": "Lordose pós-cirúrgica" },
  { "codigo": "M965", "descricao": "Escoliose pós-radiação" },
  {
    "codigo": "M966",
    "descricao": "Fratura de osso subsequente a implante ortopédico, prótese articular ou placa óssea"
  },
  {
    "codigo": "M968",
    "descricao": "Outros transtornos osteomusculares pós-procedimentos"
  },
  {
    "codigo": "M969",
    "descricao": "Transtorno osteomuscular não especificado pós-procedimento"
  },
  {
    "codigo": "M990",
    "descricao": "Disfunção segmentar e somática"
  },
  {
    "codigo": "M991",
    "descricao": "Complexo de subluxação (vertebral)"
  },
  {
    "codigo": "M992",
    "descricao": "Estenose de subluxação do canal medular"
  },
  { "codigo": "M993", "descricao": "Estenose óssea do canal medular" },
  {
    "codigo": "M994",
    "descricao": "Estenose de tecido conjuntivo do canal medular"
  },
  {
    "codigo": "M995",
    "descricao": "Estenose de disco intervertebral do canal medular"
  },
  {
    "codigo": "M996",
    "descricao": "Estenose óssea e subluxação dos forames intervertebrais"
  },
  {
    "codigo": "M997",
    "descricao": "Estenose de tecido conjuntivo e do disco dos forames intervertebrais"
  },
  { "codigo": "M998", "descricao": "Outras lesões biomecânicas" },
  {
    "codigo": "M999",
    "descricao": "Lesão biomecânica não especificada"
  },
  {
    "codigo": "N000",
    "descricao": "Síndrome nefrítica aguda - anormalidade glomerular minor"
  },
  {
    "codigo": "N001",
    "descricao": "Síndrome nefrítica aguda - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N002",
    "descricao": "Síndrome nefrítica aguda - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N003",
    "descricao": "Síndrome nefrítica aguda - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N004",
    "descricao": "Síndrome nefrítica aguda - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N005",
    "descricao": "Síndrome nefrítica aguda - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N006",
    "descricao": "Síndrome nefrítica aguda - doença de depósito denso"
  },
  {
    "codigo": "N007",
    "descricao": "Síndrome nefrítica aguda - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N008",
    "descricao": "Síndrome nefrítica aguda - outras"
  },
  {
    "codigo": "N009",
    "descricao": "Síndrome nefrítica aguda - não especificada"
  },
  {
    "codigo": "N010",
    "descricao": "Síndrome nefrítica rapidamente progressiva - anormalidade glomerular minor"
  },
  {
    "codigo": "N011",
    "descricao": "Síndrome nefrítica rapidamente progressiva - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N012",
    "descricao": "Síndrome nefrítica rapidamente progressiva - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N013",
    "descricao": "Síndrome nefrítica rapidamente progressiva - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N014",
    "descricao": "Síndrome nefrítica rapidamente progressiva - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N015",
    "descricao": "Síndrome nefrítica rapidamente progressiva - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N016",
    "descricao": "Síndrome nefrítica rapidamente progressiva - doença de depósito denso"
  },
  {
    "codigo": "N017",
    "descricao": "Síndrome nefrítica rapidamente progressiva - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N018",
    "descricao": "Síndrome nefrítica rapidamente progressiva - outras"
  },
  {
    "codigo": "N019",
    "descricao": "Síndrome nefrítica rapidamente progressiva - não especificada"
  },
  {
    "codigo": "N020",
    "descricao": "Hematúria recidivante e persistente - anormalidade glomerular minor"
  },
  {
    "codigo": "N021",
    "descricao": "Hematúria recidivante e persistente - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N022",
    "descricao": "Hematúria recidivante e persistente - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N023",
    "descricao": "Hematúria recidivante e persistente - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N024",
    "descricao": "Hematúria recidivante e persistente - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N025",
    "descricao": "Hematúria recidivante e persistente - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N026",
    "descricao": "Hematúria recidivante e persistente - doença de depósito denso"
  },
  {
    "codigo": "N027",
    "descricao": "Hematúria recidivante e persistente - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N028",
    "descricao": "Hematúria recidivante e persistente - outras"
  },
  {
    "codigo": "N029",
    "descricao": "Hematúria recidivante e persistente - não especificada"
  },
  {
    "codigo": "N030",
    "descricao": "Síndrome nefrítica crônica - anormalidade glomerular minor"
  },
  {
    "codigo": "N031",
    "descricao": "Síndrome nefrítica crônica - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N032",
    "descricao": "Síndrome nefrítica crônica - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N033",
    "descricao": "Síndrome nefrítica crônica - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N034",
    "descricao": "Síndrome nefrítica crônica - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N035",
    "descricao": "Síndrome nefrítica crônica - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N036",
    "descricao": "Síndrome nefrítica crônica - doença de depósito denso"
  },
  {
    "codigo": "N037",
    "descricao": "Síndrome nefrítica crônica - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N038",
    "descricao": "Síndrome nefrítica crônica - outras"
  },
  {
    "codigo": "N039",
    "descricao": "Síndrome nefrítica crônica - não especificada"
  },
  {
    "codigo": "N040",
    "descricao": "Síndrome nefrótica - anormalidade glomerular minor"
  },
  {
    "codigo": "N041",
    "descricao": "Síndrome nefrótica - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N042",
    "descricao": "Síndrome nefrótica - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N043",
    "descricao": "Síndrome nefrótica - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N044",
    "descricao": "Síndrome nefrótica - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N045",
    "descricao": "Síndrome nefrótica - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N046",
    "descricao": "Síndrome nefrótica - doença de depósito denso"
  },
  {
    "codigo": "N047",
    "descricao": "Síndrome nefrótica - glomerulonefrite difusa em crescente"
  },
  { "codigo": "N048", "descricao": "Síndrome nefrótica - outras" },
  {
    "codigo": "N049",
    "descricao": "Síndrome nefrótica - não especificada"
  },
  {
    "codigo": "N050",
    "descricao": "Síndrome nefrítica não especificada - anormalidade glomerular minor"
  },
  {
    "codigo": "N051",
    "descricao": "Síndrome nefrítica não especificada - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N052",
    "descricao": "Síndrome nefrítica não especificada - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N053",
    "descricao": "Síndrome nefrítica não especificada - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N054",
    "descricao": "Síndrome nefrítica não especificada - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N055",
    "descricao": "Síndrome nefrítica não especificada - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N056",
    "descricao": "Síndrome nefrítica não especificada - doença de depósito denso"
  },
  {
    "codigo": "N057",
    "descricao": "Síndrome nefrítica não especificada - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N058",
    "descricao": "Síndrome nefrítica não especificada - outras"
  },
  {
    "codigo": "N059",
    "descricao": "Síndrome nefrítica não especificada - não especificada"
  },
  {
    "codigo": "N060",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - anormalidade glomerular minor"
  },
  {
    "codigo": "N061",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N062",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N063",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N064",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N065",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N066",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - doença de depósito denso"
  },
  {
    "codigo": "N067",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N068",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - outras"
  },
  {
    "codigo": "N069",
    "descricao": "Proteinúria isolada com lesão morfológica especificada - não especificada"
  },
  {
    "codigo": "N070",
    "descricao": "Nefropatia hereditária não classificada em outra parte - anormalidade glomerular minor"
  },
  {
    "codigo": "N071",
    "descricao": "Nefropatia hereditária não classificada em outra parte - lesões glomerulares focais e segmentares"
  },
  {
    "codigo": "N072",
    "descricao": "Nefropatia hereditária não classificada em outra parte - glomerulonefrite membranosa difusa"
  },
  {
    "codigo": "N073",
    "descricao": "Nefropatia hereditária não classificada em outra parte - glomerulonefrite proliferativa mesangial difusa"
  },
  {
    "codigo": "N074",
    "descricao": "Nefropatia hereditária não classificada em outra parte - glomerulonefrite proliferativa endocapilar difusa"
  },
  {
    "codigo": "N075",
    "descricao": "Nefropatia hereditária não classificada em outra parte - glomerulonefrite mesangiocapilar difusa"
  },
  {
    "codigo": "N076",
    "descricao": "Nefropatia hereditária não classificada em outra parte - doença de depósito denso"
  },
  {
    "codigo": "N077",
    "descricao": "Nefropatia hereditária não classificada em outra parte - glomerulonefrite difusa em crescente"
  },
  {
    "codigo": "N078",
    "descricao": "Nefropatia hereditária não classificada em outra parte - outras"
  },
  {
    "codigo": "N079",
    "descricao": "Nefropatia hereditária não classificada em outra parte - não especificada"
  },
  {
    "codigo": "N080",
    "descricao": "Transtornos glomerulares em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "N081",
    "descricao": "Transtornos glomerulares em doenças neoplásicas"
  },
  {
    "codigo": "N082",
    "descricao": "Transtornos glomerulares em doenças do sangue e transtornos imunitários"
  },
  {
    "codigo": "N083",
    "descricao": "Transtornos glomerulares no diabetes mellitus"
  },
  {
    "codigo": "N084",
    "descricao": "Transtornos glomerulares em doenças endócrinas, nutricionais e metabólicas"
  },
  {
    "codigo": "N085",
    "descricao": "Transtornos glomerulares em doenças sistêmicas do tecido conjuntivo"
  },
  {
    "codigo": "N088",
    "descricao": "Transtornos glomerulares em outras doenças classificadas em outra parte"
  },
  { "codigo": "N10", "descricao": "Nefrite túbulo-intersticial aguda" },
  {
    "codigo": "N110",
    "descricao": "Pielonefrite não-obstrutiva crônica associada a refluxo"
  },
  { "codigo": "N111", "descricao": "Pielonefrite obstrutiva crônica" },
  {
    "codigo": "N118",
    "descricao": "Outras nefrites túbulo-intersticiais crônicas"
  },
  {
    "codigo": "N119",
    "descricao": "Nefrite túbulo-intersticial crônica não especificada"
  },
  {
    "codigo": "N12",
    "descricao": "Nefrite túbulo-intersticial não especificada se aguda ou crônica"
  },
  {
    "codigo": "N130",
    "descricao": "Hidronefrose com obstrução da junção uretero-pélvica"
  },
  {
    "codigo": "N131",
    "descricao": "Hidronefrose com estreitamento de ureter não classificada em outra parte"
  },
  {
    "codigo": "N132",
    "descricao": "Hidronefrose com obstrução por calculose renal e ureteral"
  },
  {
    "codigo": "N133",
    "descricao": "Outras hidronefroses e as não especificadas"
  },
  { "codigo": "N134", "descricao": "Hidroureter" },
  {
    "codigo": "N135",
    "descricao": "Torção e estreitamento do ureter sem hidronefrose"
  },
  { "codigo": "N136", "descricao": "Pionefrose" },
  {
    "codigo": "N137",
    "descricao": "Uropatia associada a refluxo vésico-ureteral"
  },
  {
    "codigo": "N138",
    "descricao": "Outras uropatias obstrutivas e por refluxo"
  },
  {
    "codigo": "N139",
    "descricao": "Uropatia obstrutiva e por refluxo não especificada"
  },
  { "codigo": "N140", "descricao": "Nefropatia induzida por analgésico" },
  {
    "codigo": "N141",
    "descricao": "Nefropatia induzida por outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "N142",
    "descricao": "Nefropatia induzida por drogas, medicamentos e substâncias biológicas não especificadas"
  },
  { "codigo": "N143", "descricao": "Nefropatia induzida por metais pesados" },
  {
    "codigo": "N144",
    "descricao": "Nefropatia tóxica não classificada em outra parte"
  },
  { "codigo": "N150", "descricao": "Nefropatia dos Bálcãs" },
  { "codigo": "N151", "descricao": "Abscesso renal e perinefrético" },
  {
    "codigo": "N158",
    "descricao": "Outras doenças renais túbulo-intersticiais especificadas"
  },
  {
    "codigo": "N159",
    "descricao": "Doença renal túbulo-intersticial não especificada"
  },
  {
    "codigo": "N160",
    "descricao": "Transtornos renais túbulo-intersticiais em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "N161",
    "descricao": "Transtornos renais túbulo-intersticiais em doenças neoplásicas"
  },
  {
    "codigo": "N162",
    "descricao": "Transtornos renais túbulo-intersticiais em doenças do sangue e transtornos imunitários"
  },
  {
    "codigo": "N163",
    "descricao": "Transtornos renais túbulo-intersticiais em doenças metabólicas"
  },
  {
    "codigo": "N164",
    "descricao": "Transtornos renais túbulo-intersticiais em doenças do tecido conjuntivo"
  },
  {
    "codigo": "N165",
    "descricao": "Transtornos renais túbulo-intersticiais em rejeição a transplantes"
  },
  {
    "codigo": "N168",
    "descricao": "Transtornos renais túbulo-intersticiais em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "N170",
    "descricao": "Insuficiência renal aguda com necrose tubular"
  },
  {
    "codigo": "N171",
    "descricao": "Insuficiência renal aguda com necrose cortical aguda"
  },
  {
    "codigo": "N172",
    "descricao": "Insuficiência renal aguda com necrose medular"
  },
  {
    "codigo": "N178",
    "descricao": "Outro tipo de insuficiência renal aguda"
  },
  {
    "codigo": "N179",
    "descricao": "Insuficiência renal aguda não especificada"
  },
  { "codigo": "N180", "descricao": "Doença renal em estádio final" },
  {
    "codigo": "N188",
    "descricao": "Outra insuficiência renal crônica"
  },
  {
    "codigo": "N189",
    "descricao": "Insuficiência renal crônica não especificada"
  },
  {
    "codigo": "N19",
    "descricao": "Insuficiência renal não especificada"
  },
  { "codigo": "N200", "descricao": "Calculose do rim" },
  { "codigo": "N201", "descricao": "Calculose do ureter" },
  {
    "codigo": "N202",
    "descricao": "Calculose do rim com cálculo do ureter"
  },
  {
    "codigo": "N209",
    "descricao": "Calculose urinária, não especificada"
  },
  { "codigo": "N210", "descricao": "Calculose na bexiga" },
  { "codigo": "N211", "descricao": "Cálculo uretral" },
  {
    "codigo": "N218",
    "descricao": "Outros cálculos do trato urinário inferior"
  },
  {
    "codigo": "N219",
    "descricao": "Cálculo do trato urinário inferior, porção não especificada"
  },
  {
    "codigo": "N220",
    "descricao": "Cálculo urinário na esquistossomose [bilharziose] [schistosomíase]"
  },
  {
    "codigo": "N228",
    "descricao": "Calculose do trato urinário em outras doenças classificadas em outra parte"
  },
  {
    "codigo": "N23",
    "descricao": "Cólica nefrética não especificada"
  },
  { "codigo": "N250", "descricao": "Osteodistrofia renal" },
  { "codigo": "N251", "descricao": "Diabetes insípido nefrogênico" },
  {
    "codigo": "N258",
    "descricao": "Outros transtornos resultantes de função renal tubular alterada"
  },
  {
    "codigo": "N259",
    "descricao": "Transtornos resultante de função tubular alterada"
  },
  { "codigo": "N26", "descricao": "Rim contraído, não especificado" },
  { "codigo": "N270", "descricao": "Hipoplasia renal unilateral" },
  { "codigo": "N271", "descricao": "Hipoplasia renal bilateral" },
  { "codigo": "N279", "descricao": "Hipoplasia renal não especificada" },
  { "codigo": "N280", "descricao": "Isquemia e infarto renal" },
  { "codigo": "N281", "descricao": "Cisto do rim, adquirido" },
  {
    "codigo": "N288",
    "descricao": "Outros transtornos especificados do rim e do ureter"
  },
  {
    "codigo": "N289",
    "descricao": "Transtorno não especificado do rim e do ureter"
  },
  { "codigo": "N290", "descricao": "Sífilis tardia renal" },
  {
    "codigo": "N291",
    "descricao": "Outros transtornos do rim e do ureter em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "N298",
    "descricao": "Outros transtornos do rim e do ureter em outras doenças classificadas em outra parte"
  },
  { "codigo": "N300", "descricao": "Cistite aguda" },
  { "codigo": "N301", "descricao": "Cistite intersticial (crônica)" },
  { "codigo": "N302", "descricao": "Outras cistites crônicas" },
  { "codigo": "N303", "descricao": "Trigonite" },
  { "codigo": "N304", "descricao": "Cistite por radiação" },
  { "codigo": "N308", "descricao": "Outras cistites" },
  { "codigo": "N309", "descricao": "Cistite, não especificada" },
  {
    "codigo": "N310",
    "descricao": "Bexiga neuropática não-inibida não classificada em outra parte"
  },
  {
    "codigo": "N311",
    "descricao": "Bexiga neuropática reflexa não classificada em outra parte"
  },
  {
    "codigo": "N312",
    "descricao": "Bexiga neuropática flácida não classificada em outra parte"
  },
  {
    "codigo": "N318",
    "descricao": "Outra disfunção neuromuscular da bexiga"
  },
  {
    "codigo": "N319",
    "descricao": "Disfunção neuromuscular não especificada da bexiga"
  },
  { "codigo": "N320", "descricao": "Obstrução do colo da bexiga" },
  { "codigo": "N321", "descricao": "Fístula êntero-vesical" },
  {
    "codigo": "N322",
    "descricao": "Fístula vesical não classificada em outra parte"
  },
  { "codigo": "N323", "descricao": "Divertículo da bexiga" },
  {
    "codigo": "N324",
    "descricao": "Ruptura da bexiga, não-traumática"
  },
  {
    "codigo": "N328",
    "descricao": "Outros transtornos especificados da bexiga"
  },
  {
    "codigo": "N329",
    "descricao": "Transtorno não especificado da bexiga"
  },
  { "codigo": "N330", "descricao": "Cistite tuberculosa" },
  {
    "codigo": "N338",
    "descricao": "Transtornos da bexiga em outras doenças classificadas em outra parte"
  },
  { "codigo": "N340", "descricao": "Abscesso uretral" },
  { "codigo": "N341", "descricao": "Uretrites não específicas" },
  { "codigo": "N342", "descricao": "Outras uretrites" },
  {
    "codigo": "N343",
    "descricao": "Síndrome uretral, não especificada"
  },
  {
    "codigo": "N350",
    "descricao": "Estenose uretral pós-traumática"
  },
  {
    "codigo": "N351",
    "descricao": "Estenose uretral pós-infecciosa não classificada em outra parte"
  },
  { "codigo": "N358", "descricao": "Outra estenose (estreitamento) uretral" },
  {
    "codigo": "N359",
    "descricao": "Estenose (estreitamento) uretral não especificada(o)"
  },
  { "codigo": "N360", "descricao": "Fístula uretral" },
  { "codigo": "N361", "descricao": "Divertículo uretral" },
  { "codigo": "N362", "descricao": "Carúncula uretral" },
  { "codigo": "N363", "descricao": "Prolapso da mucosa uretral" },
  {
    "codigo": "N368",
    "descricao": "Outros transtornos especificados da uretra"
  },
  {
    "codigo": "N369",
    "descricao": "Transtorno não especificado da uretra"
  },
  {
    "codigo": "N370",
    "descricao": "Uretrite em doenças classificadas em outra parte"
  },
  {
    "codigo": "N378",
    "descricao": "Outros transtornos da uretra em doenças classificadas em outra parte"
  },
  {
    "codigo": "N390",
    "descricao": "Infecção do trato urinário de localização não especificada"
  },
  {
    "codigo": "N391",
    "descricao": "Proteinúria persistente não especificada"
  },
  {
    "codigo": "N392",
    "descricao": "Proteinúria ortostática não especificada"
  },
  {
    "codigo": "N393",
    "descricao": "Incontinência de tensão (stress)"
  },
  {
    "codigo": "N394",
    "descricao": "Outras incontinências urinárias especificadas"
  },
  {
    "codigo": "N398",
    "descricao": "Outros transtornos especificados do aparelho urinário"
  },
  {
    "codigo": "N399",
    "descricao": "Transtornos não especificados do aparelho urinário"
  },
  { "codigo": "N40", "descricao": "Hiperplasia da próstata" },
  { "codigo": "N410", "descricao": "Prostatite aguda" },
  { "codigo": "N411", "descricao": "Prostatite crônica" },
  { "codigo": "N412", "descricao": "Abscesso da próstata" },
  { "codigo": "N413", "descricao": "Prostatocistite" },
  {
    "codigo": "N418",
    "descricao": "Outras doenças inflamatórias da próstata"
  },
  {
    "codigo": "N419",
    "descricao": "Doença inflamatória não especificada da próstata"
  },
  { "codigo": "N420", "descricao": "Cálculo da próstata" },
  {
    "codigo": "N421",
    "descricao": "Congestão e hemorragia da próstata"
  },
  { "codigo": "N422", "descricao": "Atrofia da próstata" },
  {
    "codigo": "N428",
    "descricao": "Outras afecções especificadas da próstata"
  },
  {
    "codigo": "N429",
    "descricao": "Afecção não especificada da próstata"
  },
  { "codigo": "N430", "descricao": "Hidrocele encistada" },
  { "codigo": "N431", "descricao": "Hidrocele infectada" },
  { "codigo": "N432", "descricao": "Outra hidrocele" },
  { "codigo": "N433", "descricao": "Hidrocele não especificada" },
  { "codigo": "N434", "descricao": "Espermatocele" },
  { "codigo": "N44", "descricao": "Torção do testículo" },
  {
    "codigo": "N450",
    "descricao": "Orquite, epididimite e epidídimo-orquite, com menção de abscesso"
  },
  {
    "codigo": "N459",
    "descricao": "Orquite, epididimite e epidídimo-orquite, sem menção de abscesso"
  },
  { "codigo": "N46", "descricao": "Infertilidade masculina" },
  {
    "codigo": "N47",
    "descricao": "Hipertrofia do prepúcio, fimose e parafimose"
  },
  { "codigo": "N480", "descricao": "Leucoplasia do pênis" },
  { "codigo": "N481", "descricao": "Balanopostite" },
  {
    "codigo": "N482",
    "descricao": "Outros transtornos inflamatórios do pênis"
  },
  { "codigo": "N483", "descricao": "Priapismo" },
  { "codigo": "N484", "descricao": "Impotência de origem orgânica" },
  { "codigo": "N485", "descricao": "úlcera do pênis" },
  { "codigo": "N486", "descricao": "Induratio penis plastica" },
  {
    "codigo": "N488",
    "descricao": "Outros transtornos especificados do pênis"
  },
  {
    "codigo": "N489",
    "descricao": "Transtorno não especificado do pênis"
  },
  {
    "codigo": "N490",
    "descricao": "Transtornos inflamatórios da vesícula seminal"
  },
  {
    "codigo": "N491",
    "descricao": "Transtornos inflamatórios do cordão espermático, túnica vaginal e vasos deferentes"
  },
  { "codigo": "N492", "descricao": "Transtorno inflamatório do escroto" },
  {
    "codigo": "N498",
    "descricao": "Transtornos inflamatórios de outros órgãos genitais masculinos especificados"
  },
  {
    "codigo": "N499",
    "descricao": "Transtorno inflamatório de órgão genital masculino, não especificado"
  },
  { "codigo": "N500", "descricao": "Atrofia do testículo" },
  {
    "codigo": "N501",
    "descricao": "Transtornos vasculares dos órgãos genitais masculinos"
  },
  {
    "codigo": "N508",
    "descricao": "Outros transtornos especificados dos órgãos genitais masculinos"
  },
  {
    "codigo": "N509",
    "descricao": "Transtorno não especificado dos órgãos genitais masculinos"
  },
  {
    "codigo": "N510",
    "descricao": "Transtornos da próstata em doenças classificadas em outra parte"
  },
  {
    "codigo": "N511",
    "descricao": "Transtornos do testículo e do epidídimo em doenças classificadas em outra parte"
  },
  {
    "codigo": "N512",
    "descricao": "Balanite em doenças classificadas em outra parte"
  },
  {
    "codigo": "N518",
    "descricao": "Outros transtornos dos órgãos genitais masculinos em doenças classificadas em outra parte"
  },
  { "codigo": "N600", "descricao": "Cisto solitário da mama" },
  { "codigo": "N601", "descricao": "Mastopatia cística difusa" },
  { "codigo": "N602", "descricao": "Fibroadenose da mama" },
  { "codigo": "N603", "descricao": "Fibrosclerose da mama" },
  { "codigo": "N604", "descricao": "Ectasia de dutos mamários" },
  { "codigo": "N608", "descricao": "Outras displasias mamárias benignas" },
  {
    "codigo": "N609",
    "descricao": "Displasia mamária benigna não especificada"
  },
  { "codigo": "N61", "descricao": "Transtornos inflamatórios da mama" },
  { "codigo": "N62", "descricao": "Hipertrofia da mama" },
  {
    "codigo": "N63",
    "descricao": "Nódulo mamário não especificado"
  },
  { "codigo": "N640", "descricao": "Fissura e fístula do mamilo" },
  { "codigo": "N641", "descricao": "Necrose gordurosa da mama" },
  { "codigo": "N642", "descricao": "Atrofia da mama" },
  {
    "codigo": "N643",
    "descricao": "Galactorréia não-associada ao parto"
  },
  { "codigo": "N644", "descricao": "Mastodinia" },
  { "codigo": "N645", "descricao": "Outros sintomas e sinais da mama" },
  { "codigo": "N648", "descricao": "Outros transtornos especificados da mama" },
  { "codigo": "N649", "descricao": "Transtorno da mama não especificado" },
  { "codigo": "N700", "descricao": "Salpingite e ooforite agudas" },
  { "codigo": "N701", "descricao": "Salpingite e ooforite crônicas" },
  {
    "codigo": "N709",
    "descricao": "Salpingite e ooforite não especificadas"
  },
  {
    "codigo": "N710",
    "descricao": "Doença inflamatória aguda do útero"
  },
  {
    "codigo": "N711",
    "descricao": "Doença inflamatória crônica do útero"
  },
  {
    "codigo": "N719",
    "descricao": "Doença inflamatória não especificada do útero"
  },
  {
    "codigo": "N72",
    "descricao": "Doença inflamatória do colo do útero"
  },
  {
    "codigo": "N730",
    "descricao": "Parametrite e celulite pélvicas agudas"
  },
  {
    "codigo": "N731",
    "descricao": "Parametrite e celulite pélvicas crônicas"
  },
  {
    "codigo": "N732",
    "descricao": "Parametrite e celulite pélvicas não especificadas"
  },
  { "codigo": "N733", "descricao": "Pelviperitonite aguda feminina" },
  { "codigo": "N734", "descricao": "Pelviperitonite crônica feminina" },
  {
    "codigo": "N735",
    "descricao": "Pelviperitonite não especificada feminina"
  },
  {
    "codigo": "N736",
    "descricao": "Aderências pelviperitonais femininas"
  },
  {
    "codigo": "N738",
    "descricao": "Outras doenças inflamatórias especificadas da pelve feminina"
  },
  {
    "codigo": "N739",
    "descricao": "Doença inflamatória não especificada da pelve feminina"
  },
  { "codigo": "N740", "descricao": "Tuberculose do colo do útero" },
  { "codigo": "N741", "descricao": "Tuberculose da pelve feminina" },
  { "codigo": "N742", "descricao": "Sífilis pélvica feminina" },
  {
    "codigo": "N743",
    "descricao": "Infecção gonocócica pélvica feminina"
  },
  {
    "codigo": "N744",
    "descricao": "Infecção pélvica feminina por clamídia"
  },
  {
    "codigo": "N748",
    "descricao": "Inflamação pélvica feminina em outras doenças classificadas em outra parte"
  },
  { "codigo": "N750", "descricao": "Cisto da glândula de Bartholin" },
  { "codigo": "N751", "descricao": "Abscesso da glândula de Bartholin" },
  {
    "codigo": "N758",
    "descricao": "Outras doenças da glândula de Bartholin"
  },
  {
    "codigo": "N759",
    "descricao": "Doença não especificada da glândula de Bartholin"
  },
  { "codigo": "N760", "descricao": "Vaginite aguda" },
  { "codigo": "N761", "descricao": "Vaginite subaguda e crônica" },
  { "codigo": "N762", "descricao": "Vulvite aguda" },
  { "codigo": "N763", "descricao": "Vulvite subaguda e crônica" },
  { "codigo": "N764", "descricao": "Abscesso vulvar" },
  { "codigo": "N765", "descricao": "Ulceração vaginal" },
  { "codigo": "N766", "descricao": "Ulceração vulvar" },
  {
    "codigo": "N768",
    "descricao": "Outras inflamações especificadas da vagina e da vulva"
  },
  {
    "codigo": "N770",
    "descricao": "Ulceração da vulva em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "N771",
    "descricao": "Vaginite, vulvite e vulvovaginite em doenças infecciosas e parasitárias classificadas em outra parte"
  },
  {
    "codigo": "N778",
    "descricao": "Ulceração e inflamação vulvovaginais em outras doenças classificadas em outra parte"
  },
  { "codigo": "N800", "descricao": "Endometriose do útero" },
  { "codigo": "N801", "descricao": "Endometriose do ovário" },
  { "codigo": "N802", "descricao": "Endometriose da trompa de Falópio" },
  {
    "codigo": "N803",
    "descricao": "Endometriose do peritônio pélvico"
  },
  {
    "codigo": "N804",
    "descricao": "Endometriose do septo retovaginal e da vagina"
  },
  { "codigo": "N805", "descricao": "Endometriose do intestino" },
  { "codigo": "N806", "descricao": "Endometriose em cicatriz cutânea" },
  { "codigo": "N808", "descricao": "Outra endometriose" },
  { "codigo": "N809", "descricao": "Endometriose não especificada" },
  { "codigo": "N810", "descricao": "Uretrocele feminina" },
  { "codigo": "N811", "descricao": "Cistocele" },
  { "codigo": "N812", "descricao": "Prolapso uterovaginal incompleto" },
  { "codigo": "N813", "descricao": "Prolapso uterovaginal completo" },
  {
    "codigo": "N814",
    "descricao": "Prolapso uterovaginal não especificado"
  },
  { "codigo": "N815", "descricao": "Enterocele vaginal" },
  { "codigo": "N816", "descricao": "Retocele" },
  { "codigo": "N818", "descricao": "Outro prolapso genital feminino" },
  {
    "codigo": "N819",
    "descricao": "Prolapso genital feminino não especificado"
  },
  { "codigo": "N820", "descricao": "Fístula vesicovaginal" },
  {
    "codigo": "N821",
    "descricao": "Outras fístulas do trato geniturinário feminino"
  },
  { "codigo": "N822", "descricao": "Fístula vagina-intestino delgado" },
  { "codigo": "N823", "descricao": "Fístula vagina-cólon" },
  {
    "codigo": "N824",
    "descricao": "Outras fístulas genito-intestinais femininas"
  },
  { "codigo": "N825", "descricao": "Fístula genitocutânea feminina" },
  {
    "codigo": "N828",
    "descricao": "Outras fístulas do trato genital feminino"
  },
  {
    "codigo": "N829",
    "descricao": "Fístulas não especificadas do trato genital feminino"
  },
  { "codigo": "N830", "descricao": "Cisto folicular do ovário" },
  { "codigo": "N831", "descricao": "Cisto do corpo lúteo" },
  {
    "codigo": "N832",
    "descricao": "Outros cistos ovarianos e os não especificados"
  },
  {
    "codigo": "N833",
    "descricao": "Atrofia adquirida do ovário e da trompa de Falópio"
  },
  {
    "codigo": "N834",
    "descricao": "Prolapso e hérnia do ovário e da trompa de Falópio"
  },
  {
    "codigo": "N835",
    "descricao": "Torção do ovário, do pedículo ovariano e da trompa de Falópio"
  },
  { "codigo": "N836", "descricao": "Hematossalpinge" },
  { "codigo": "N837", "descricao": "Hematoma do ligamento largo" },
  {
    "codigo": "N838",
    "descricao": "Outros transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo"
  },
  {
    "codigo": "N839",
    "descricao": "Transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo, não especificados"
  },
  { "codigo": "N840", "descricao": "Pólipo do corpo do útero" },
  { "codigo": "N841", "descricao": "Pólipo do colo do útero" },
  { "codigo": "N842", "descricao": "Pólipo da vagina" },
  { "codigo": "N843", "descricao": "Pólipo da vulva" },
  {
    "codigo": "N848",
    "descricao": "Pólipo de outras partes do trato genital feminino"
  },
  {
    "codigo": "N849",
    "descricao": "Pólipo do trato genital feminino não especificado"
  },
  { "codigo": "N850", "descricao": "Hiperplasia glandular endometrial" },
  { "codigo": "N851", "descricao": "Hiperplasia adenomatosa endometrial" },
  { "codigo": "N852", "descricao": "Hipertrofia do útero" },
  { "codigo": "N853", "descricao": "Subinvolução do útero" },
  { "codigo": "N854", "descricao": "Posição anormal do útero" },
  { "codigo": "N855", "descricao": "Inversão do útero" },
  { "codigo": "N856", "descricao": "Sinéquias intra-uterinas" },
  { "codigo": "N857", "descricao": "Hematometra" },
  {
    "codigo": "N858",
    "descricao": "Outros transtornos não-inflamatórios especificados do útero"
  },
  {
    "codigo": "N859",
    "descricao": "Transtornos não-inflamatórios do útero, não especificados"
  },
  {
    "codigo": "N86",
    "descricao": "Erosão e ectrópio do colo do útero"
  },
  { "codigo": "N870", "descricao": "Displasia cervical leve" },
  { "codigo": "N871", "descricao": "Displasia cervical moderada" },
  {
    "codigo": "N872",
    "descricao": "Displasia cervical grave, não classificada em outra parte"
  },
  {
    "codigo": "N879",
    "descricao": "Displasia do colo do útero, não especificada"
  },
  { "codigo": "N880", "descricao": "Leucoplasia do colo do útero" },
  {
    "codigo": "N881",
    "descricao": "Laceração antiga do colo do útero"
  },
  {
    "codigo": "N882",
    "descricao": "Estreitamento e estenose do colo do útero"
  },
  { "codigo": "N883", "descricao": "Incompetência do colo do útero" },
  {
    "codigo": "N884",
    "descricao": "Alongamento hipertrófico do colo do útero"
  },
  {
    "codigo": "N888",
    "descricao": "Outros transtornos não-inflamatórios especificados do colo do útero"
  },
  {
    "codigo": "N889",
    "descricao": "Transtorno não-inflamatório e não especificado do colo do útero"
  },
  { "codigo": "N890", "descricao": "Displasia vaginal leve" },
  { "codigo": "N891", "descricao": "Displasia vaginal moderada" },
  {
    "codigo": "N892",
    "descricao": "Displasia vaginal grave, não classificada em outra parte"
  },
  {
    "codigo": "N893",
    "descricao": "Displasia da vagina, não especificada"
  },
  { "codigo": "N894", "descricao": "Leucoplasia da vagina" },
  { "codigo": "N895", "descricao": "Estreitamento e atresia da vagina" },
  { "codigo": "N896", "descricao": "Anel himenal apertado" },
  { "codigo": "N897", "descricao": "Hematocolpos" },
  {
    "codigo": "N898",
    "descricao": "Outros transtornos não-inflamatórios especificados da vagina"
  },
  {
    "codigo": "N899",
    "descricao": "Transtorno não-inflamatório da vagina, não especificado"
  },
  { "codigo": "N900", "descricao": "Displasia vulvar leve" },
  { "codigo": "N901", "descricao": "Displasia vulvar moderada" },
  {
    "codigo": "N902",
    "descricao": "Displasia vulvar grave, não classificada em outra parte"
  },
  {
    "codigo": "N903",
    "descricao": "Displasia de vulva, não especificada"
  },
  { "codigo": "N904", "descricao": "Leucoplasia de vulva" },
  { "codigo": "N905", "descricao": "Atrofia da vulva" },
  { "codigo": "N906", "descricao": "Hipertrofia da vulva" },
  { "codigo": "N907", "descricao": "Cisto vulvar" },
  {
    "codigo": "N908",
    "descricao": "Outros transtornos não-inflamatórios especificados da vulva e do períneo"
  },
  {
    "codigo": "N909",
    "descricao": "Transtorno não-inflamatório e não especificado da vulva e do períneo"
  },
  { "codigo": "N910", "descricao": "Amenorréia primária" },
  { "codigo": "N911", "descricao": "Amenorréia secundária" },
  { "codigo": "N912", "descricao": "Amenorréia, não especificada" },
  { "codigo": "N913", "descricao": "Oligomenorréia primária" },
  { "codigo": "N914", "descricao": "Oligomenorréia secundária" },
  {
    "codigo": "N915",
    "descricao": "Oligomenorréia, não especificada"
  },
  {
    "codigo": "N920",
    "descricao": "Menstruação excessiva e freqüente com ciclo regular"
  },
  {
    "codigo": "N921",
    "descricao": "Menstruação excessiva e freqüente com ciclo irregular"
  },
  {
    "codigo": "N922",
    "descricao": "Menstruação excessiva na puberdade"
  },
  { "codigo": "N923", "descricao": "Sangramento da ovulação" },
  {
    "codigo": "N924",
    "descricao": "Sangramento abundante na pré-menopausa"
  },
  {
    "codigo": "N925",
    "descricao": "Outros tipos especificados de irregularidade da menstruação"
  },
  {
    "codigo": "N926",
    "descricao": "Menstruação irregular, não especificada"
  },
  {
    "codigo": "N930",
    "descricao": "Sangramentos pós-coito ou de contato"
  },
  {
    "codigo": "N938",
    "descricao": "Outros sangramentos anormais especificados do útero e da vagina"
  },
  {
    "codigo": "N939",
    "descricao": "Sangramento anormal do útero ou da vagina, não especificado"
  },
  {
    "codigo": "N940",
    "descricao": "Ovulação dolorosa [Mittelschmerz]"
  },
  { "codigo": "N941", "descricao": "Dispareunia" },
  { "codigo": "N942", "descricao": "Vaginismo" },
  {
    "codigo": "N943",
    "descricao": "Síndrome de tensão pré-menstrual"
  },
  { "codigo": "N944", "descricao": "Dismenorréia primária" },
  { "codigo": "N945", "descricao": "Dismenorréia secundária" },
  { "codigo": "N946", "descricao": "Dismenorréia não especificada" },
  {
    "codigo": "N948",
    "descricao": "Outras afecções especificadas associadas com os órgãos genitais femininos e com o ciclo menstrual"
  },
  {
    "codigo": "N949",
    "descricao": "Afecções não especificadas associadas com os órgãos genitais femininos e com o ciclo menstrual"
  },
  { "codigo": "N950", "descricao": "Sangramento pós-menopausa" },
  {
    "codigo": "N951",
    "descricao": "Estado da menopausa e do climatério feminino"
  },
  {
    "codigo": "N952",
    "descricao": "Vaginite atrófica pós-menopausa"
  },
  {
    "codigo": "N953",
    "descricao": "Condições associadas com a menopausa artificial"
  },
  {
    "codigo": "N958",
    "descricao": "Outros transtornos especificados da menopausa e da perimenopausa"
  },
  {
    "codigo": "N959",
    "descricao": "Transtorno não especificado da menopausa e da perimenopausa"
  },
  { "codigo": "N96", "descricao": "Abortamento habitual" },
  {
    "codigo": "N970",
    "descricao": "Infertilidade feminina associada à anovulação"
  },
  {
    "codigo": "N971",
    "descricao": "Infertilidade feminina de origem tubária"
  },
  { "codigo": "N972", "descricao": "Infertilidade feminina de origem uterina" },
  {
    "codigo": "N973",
    "descricao": "Infertilidade feminina de origem cervical"
  },
  {
    "codigo": "N974",
    "descricao": "Infertilidade feminina associada à fatores do parceiro"
  },
  { "codigo": "N978", "descricao": "Infertilidade feminina de outra origem" },
  {
    "codigo": "N979",
    "descricao": "Infertilidade feminina não especificada"
  },
  {
    "codigo": "N980",
    "descricao": "Infecção associada à inseminação artificial"
  },
  {
    "codigo": "N981",
    "descricao": "Hiperestimulação dos ovários"
  },
  {
    "codigo": "N982",
    "descricao": "Complicações relacionadas com a tentativa de introdução do óvulo fecundado artificialmente (in vitro)"
  },
  {
    "codigo": "N983",
    "descricao": "Complicações relacionadas com a tentativa de transferência do embrião"
  },
  {
    "codigo": "N988",
    "descricao": "Outras complicações associadas à fecundação artificial"
  },
  {
    "codigo": "N989",
    "descricao": "Complicações não especificadas associadas à fecundação artificial"
  },
  {
    "codigo": "N990",
    "descricao": "Insuficiência renal pós-procedimentos"
  },
  {
    "codigo": "N991",
    "descricao": "Estreitamento de uretra pós-procedimentos"
  },
  {
    "codigo": "N992",
    "descricao": "Aderências pós-operatórias da vagina"
  },
  {
    "codigo": "N993",
    "descricao": "Prolapso de cúpula de vagina pós-histerectomia"
  },
  {
    "codigo": "N994",
    "descricao": "Aderências do peritônio pélvico pós-procedimentos"
  },
  {
    "codigo": "N995",
    "descricao": "Mau funcionamento de abertura externa (estoma) do trato urinário"
  },
  {
    "codigo": "N998",
    "descricao": "Outros transtornos pós-procedimentos do aparelho geniturinário"
  },
  {
    "codigo": "N999",
    "descricao": "Transtorno pós-procedimento não especificado do aparelho geniturinário"
  },
  { "codigo": "O000", "descricao": "Gravidez abdominal" },
  { "codigo": "O001", "descricao": "Gravidez tubária" },
  { "codigo": "O002", "descricao": "Gravidez ovariana" },
  { "codigo": "O008", "descricao": "Outras formas de gravidez ectópica" },
  {
    "codigo": "O009",
    "descricao": "Gravidez ectópica, não especificada"
  },
  { "codigo": "O010", "descricao": "Mola hidatiforme clássica" },
  { "codigo": "O011", "descricao": "Mola hidatiforme incompleta ou parcial" },
  { "codigo": "O019", "descricao": "Mola hidatiforme não especificada" },
  { "codigo": "O020", "descricao": "Ovo claro e mola não-hidatiforme" },
  { "codigo": "O021", "descricao": "Aborto retido" },
  {
    "codigo": "O028",
    "descricao": "Outros produtos anormais da concepção especificados"
  },
  {
    "codigo": "O029",
    "descricao": "Produto anormal da concepção, não especificado"
  },
  {
    "codigo": "O030",
    "descricao": "Aborto espontâneo - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O031",
    "descricao": "Aborto espontâneo - incompleto, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O032",
    "descricao": "Aborto espontâneo - incompleto, complicado por embolia"
  },
  {
    "codigo": "O033",
    "descricao": "Aborto espontâneo - incompleto, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O034",
    "descricao": "Aborto espontâneo - incompleto, sem complicações"
  },
  {
    "codigo": "O035",
    "descricao": "Aborto espontâneo - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O036",
    "descricao": "Aborto espontâneo - completo ou não especificado, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O037",
    "descricao": "Aborto espontâneo - completo ou não especificado, complicado por embolia"
  },
  {
    "codigo": "O038",
    "descricao": "Aborto espontâneo - completo ou não especificado, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O039",
    "descricao": "Aborto espontâneo - completo ou não especificado, sem complicações"
  },
  {
    "codigo": "O040",
    "descricao": "Aborto por razões médicas e legais - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O041",
    "descricao": "Aborto por razões médicas e legais - incompleto, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O042",
    "descricao": "Aborto por razões médicas e legais - incompleto, complicado por embolia"
  },
  {
    "codigo": "O043",
    "descricao": "Aborto por razões médicas e legais - incompleto, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O044",
    "descricao": "Aborto por razões médicas e legais - incompleto, sem complicações"
  },
  {
    "codigo": "O045",
    "descricao": "Aborto por razões médicas e legais - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O046",
    "descricao": "Aborto por razões médicas e legais - completo ou não especificado, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O047",
    "descricao": "Aborto por razões médicas e legais - completo ou não especificado, complicado por embolia"
  },
  {
    "codigo": "O048",
    "descricao": "Aborto por razões médicas e legais - completo ou não especificado, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O049",
    "descricao": "Aborto por razões médicas e legais - completo ou não especificado, sem complicações"
  },
  {
    "codigo": "O050",
    "descricao": "Outros tipos de aborto - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O051",
    "descricao": "Outros tipos de aborto - incompleto, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O052",
    "descricao": "Outros tipos de aborto - incompleto, complicado por embolia"
  },
  {
    "codigo": "O053",
    "descricao": "Outros tipos de aborto - incompleto, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O054",
    "descricao": "Outros tipos de aborto - incompleto, sem complicações"
  },
  {
    "codigo": "O055",
    "descricao": "Outros tipos de aborto - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O056",
    "descricao": "Outros tipos de aborto - completo ou não especificado, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O057",
    "descricao": "Outros tipos de aborto - completo ou não especificado, complicado por embolia"
  },
  {
    "codigo": "O058",
    "descricao": "Outros tipos de aborto - completo ou não especificado, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O059",
    "descricao": "Outros tipos de aborto - completo ou não especificado, sem complicações"
  },
  {
    "codigo": "O060",
    "descricao": "Aborto não especificado - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O061",
    "descricao": "Aborto não especificado - incompleto, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O062",
    "descricao": "Aborto não especificado - incompleto, complicado por embolia"
  },
  {
    "codigo": "O063",
    "descricao": "Aborto não especificado - incompleto, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O064",
    "descricao": "Aborto não especificado - incompleto, sem complicações"
  },
  {
    "codigo": "O065",
    "descricao": "Aborto não especificado - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O066",
    "descricao": "Aborto não especificado - completo ou não especificado, complicado por hemorragia excessiva ou tardia"
  },
  {
    "codigo": "O067",
    "descricao": "Aborto não especificado - completo ou não especificado, complicado por embolia"
  },
  {
    "codigo": "O068",
    "descricao": "Aborto não especificado - completo ou não especificado, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O069",
    "descricao": "Aborto não especificado - completo ou não especificado, sem complicações"
  },
  {
    "codigo": "O070",
    "descricao": "Falha de aborto provocado por razões médicas, complicado por infecção do trato genital ou dos órgãos pélvicos"
  },
  {
    "codigo": "O071",
    "descricao": "Falha de aborto provocado por razões médicas, complicado por hemorragia tardia ou excessiva"
  },
  {
    "codigo": "O072",
    "descricao": "Falha de aborto provocado por razões médicas, complicado por embolia"
  },
  {
    "codigo": "O073",
    "descricao": "Falha de aborto provocado por razões médicas, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O074",
    "descricao": "Falha de aborto provocado por razões médicas, sem complicações"
  },
  {
    "codigo": "O075",
    "descricao": "Outras formas, e as não especificadas, de falha na provocação de aborto, complicadas por infecção do trato genital e por infecção dos órgãos pélvicos"
  },
  {
    "codigo": "O076",
    "descricao": "Outras formas, e as não especificadas, de falha na provocação de aborto, complicadas por hemorragia tardia ou excessiva"
  },
  {
    "codigo": "O077",
    "descricao": "Outras formas, e as não especificadas, de falha na provocação de aborto, complicadas por embolia"
  },
  {
    "codigo": "O078",
    "descricao": "Outras formas, e as não especificadas, de falha na provocação de aborto, com outras complicações ou com complicações não especificadas"
  },
  {
    "codigo": "O079",
    "descricao": "Outras formas, e as não especificadas, de falha na provocação de aborto, sem complicação"
  },
  {
    "codigo": "O080",
    "descricao": "Infecção do trato genital e dos órgãos pélvicos conseqüente a aborto e gravidez ectópica e molar"
  },
  {
    "codigo": "O081",
    "descricao": "Hemorragia tardia ou excessiva conseqüente a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O082",
    "descricao": "Embolia conseqüente a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O083",
    "descricao": "Choque conseqüente a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O084",
    "descricao": "Insuficiência renal conseqüente a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O085",
    "descricao": "Distúrbios metabólicos conseqüentes a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O086",
    "descricao": "Lesão a órgãos e a tecidos pélvicos conseqüentes a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O087",
    "descricao": "Outras complicações venosas conseqüentes a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O088",
    "descricao": "Outras complicações conseqüentes a aborto e a gravidez ectópica e molar"
  },
  {
    "codigo": "O089",
    "descricao": "Complicações conseqüentes a aborto e gravidez ectópica e molar, não especificadas"
  },
  {
    "codigo": "O100",
    "descricao": "Hipertensão essencial pré-existente complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O101",
    "descricao": "Doença cardíaca hipertensiva pré-existente complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O102",
    "descricao": "Doença renal hipertensiva pré-existente complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O103",
    "descricao": "Doença cardíaca e renal hipertensiva pré-existente complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O104",
    "descricao": "Hipertensão secundária pré-existente complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O109",
    "descricao": "Hipertensão pré-existente não especificada, complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O11",
    "descricao": "Distúrbio hipertensivo pré-existente com proteinúria superposta"
  },
  { "codigo": "O120", "descricao": "Edema gestacional" },
  { "codigo": "O121", "descricao": "Proteinúria gestacional" },
  { "codigo": "O122", "descricao": "Edema gestacional com proteinúria" },
  {
    "codigo": "O13",
    "descricao": "Hipertensão gestacional [induzida pela gravidez] sem proteinúria significativa"
  },
  { "codigo": "O140", "descricao": "Pré-eclâmpsia moderada" },
  { "codigo": "O141", "descricao": "Pré-eclâmpsia grave" },
  {
    "codigo": "O149",
    "descricao": "Pré-eclâmpsia não especificada"
  },
  { "codigo": "O150", "descricao": "Eclâmpsia na gravidez" },
  { "codigo": "O151", "descricao": "Eclâmpsia no trabalho de parto" },
  { "codigo": "O152", "descricao": "Eclâmpsia no puerpério" },
  {
    "codigo": "O159",
    "descricao": "Eclâmpsia não especificada quanto ao período"
  },
  {
    "codigo": "O16",
    "descricao": "Hipertensão materna não especificada"
  },
  { "codigo": "O200", "descricao": "Ameaça de aborto" },
  {
    "codigo": "O208",
    "descricao": "Outras hemorragias do início da gravidez"
  },
  {
    "codigo": "O209",
    "descricao": "Hemorragia do início da gravidez, não especificada"
  },
  { "codigo": "O210", "descricao": "Hiperêmese gravídica leve" },
  {
    "codigo": "O211",
    "descricao": "Hiperêmese gravídica com distúrbio metabólico"
  },
  { "codigo": "O212", "descricao": "Vômitos tardios da gravidez" },
  {
    "codigo": "O218",
    "descricao": "Outras formas de vômitos complicando a gravidez"
  },
  {
    "codigo": "O219",
    "descricao": "Vômitos da gravidez, não especificados"
  },
  {
    "codigo": "O220",
    "descricao": "Varizes dos membros inferiores na gravidez"
  },
  { "codigo": "O221", "descricao": "Varizes genitais na gravidez" },
  { "codigo": "O222", "descricao": "Tromboflebite superficial na gravidez" },
  { "codigo": "O223", "descricao": "Flebotrombose profunda na gravidez" },
  { "codigo": "O224", "descricao": "Hemorróidas na gravidez" },
  { "codigo": "O225", "descricao": "Trombose venosa cerebral na gravidez" },
  {
    "codigo": "O228",
    "descricao": "Outras complicações venosas na gravidez"
  },
  {
    "codigo": "O229",
    "descricao": "Complicações venosas na gravidez, não especificadas"
  },
  { "codigo": "O230", "descricao": "Infecções do rim na gravidez" },
  {
    "codigo": "O231",
    "descricao": "Infecções da bexiga na gravidez"
  },
  {
    "codigo": "O232",
    "descricao": "Infecções da uretra na gravidez"
  },
  {
    "codigo": "O233",
    "descricao": "Infecções de outras partes do trato urinário na gravidez"
  },
  {
    "codigo": "O234",
    "descricao": "Infecção não especificada do trato urinário na gravidez"
  },
  {
    "codigo": "O235",
    "descricao": "Infecções do trato genital na gravidez"
  },
  {
    "codigo": "O239",
    "descricao": "Outras infecções e as não especificadas do trato urinário na gravidez"
  },
  {
    "codigo": "O240",
    "descricao": "Diabetes mellitus pré-existente, insulino-dependente"
  },
  {
    "codigo": "O241",
    "descricao": "Diabetes mellitus pré-existente, não-insulino-dependente"
  },
  {
    "codigo": "O242",
    "descricao": "Diabetes mellitus pré-existente, relacionado com a desnutrição"
  },
  {
    "codigo": "O243",
    "descricao": "Diabetes mellitus pré-existente, não especificado"
  },
  {
    "codigo": "O244",
    "descricao": "Diabetes mellitus que surge durante a gravidez"
  },
  {
    "codigo": "O249",
    "descricao": "Diabetes mellitus na gravidez, não especificado"
  },
  { "codigo": "O25", "descricao": "Desnutrição na gravidez" },
  { "codigo": "O260", "descricao": "Ganho excessivo de peso na gravidez" },
  { "codigo": "O261", "descricao": "Ganho de peso insuficiente na gravidez" },
  {
    "codigo": "O262",
    "descricao": "Assistência à gravidez por motivo de abortamento habitual"
  },
  {
    "codigo": "O263",
    "descricao": "Retenção de dispositivo intra-uterino contraceptivo (DIU) na gravidez"
  },
  { "codigo": "O264", "descricao": "Herpes gestacional" },
  { "codigo": "O265", "descricao": "Síndrome da hipotensão materna" },
  {
    "codigo": "O266",
    "descricao": "Transtornos do fígado na gravidez, no parto e no puerpério"
  },
  {
    "codigo": "O267",
    "descricao": "Subluxação da sínfise (púbica) na gravidez, parto e puerpério"
  },
  {
    "codigo": "O268",
    "descricao": "Outras afecções especificadas, ligadas a gravidez"
  },
  {
    "codigo": "O269",
    "descricao": "Afecções ligadas a gravidez, não especificadas"
  },
  {
    "codigo": "O280",
    "descricao": "Achados hematológicos anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O281",
    "descricao": "Achados bioquímicos anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O282",
    "descricao": "Achados citológicos anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O283",
    "descricao": "Achados ultrassonográficos anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O284",
    "descricao": "Achados radiológicos anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O285",
    "descricao": "Achados cromossômicos e genéticos anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O288",
    "descricao": "Outros achados anormais do rastreamento [screening] antenatal da mãe"
  },
  {
    "codigo": "O289",
    "descricao": "Achados anormais do rastreamento [screening] antenatal da mãe, não especificados"
  },
  {
    "codigo": "O290",
    "descricao": "Complicações pulmonares de anestesia administrada durante a gravidez"
  },
  {
    "codigo": "O291",
    "descricao": "Complicações cardíacas de anestesia administrada durante a gravidez"
  },
  {
    "codigo": "O292",
    "descricao": "Complicações relativas ao sistema nervoso central devidas a anestesia administrada durante a gravidez"
  },
  {
    "codigo": "O293",
    "descricao": "Reação tóxica causada por anestesia local administrada durante a gravidez"
  },
  {
    "codigo": "O294",
    "descricao": "Cefaléia causada por anestesia raquidiana ou peridural administradas durante a gravidez"
  },
  {
    "codigo": "O295",
    "descricao": "Outras complicações de anestesia raquidiana ou peridural administradas durante a gravidez"
  },
  {
    "codigo": "O296",
    "descricao": "Falha ou dificuldade na entubação durante a gravidez"
  },
  {
    "codigo": "O298",
    "descricao": "Outras complicações de anestesia durante a gravidez"
  },
  {
    "codigo": "O299",
    "descricao": "Complicações de anestesia durante a gravidez, não especificadas"
  },
  { "codigo": "O300", "descricao": "Gravidez dupla" },
  { "codigo": "O301", "descricao": "Gravidez trigemelar" },
  { "codigo": "O302", "descricao": "Gravidez quadrigemelar" },
  {
    "codigo": "O308",
    "descricao": "Outras gestações múltiplas"
  },
  {
    "codigo": "O309",
    "descricao": "Gestação múltipla, não especificada"
  },
  { "codigo": "O310", "descricao": "Feto papiráceo" },
  {
    "codigo": "O311",
    "descricao": "Continuação da gravidez após aborto de um ou mais fetos"
  },
  {
    "codigo": "O312",
    "descricao": "Continuação da gravidez após a morte intra-uterina de um ou mais fetos"
  },
  {
    "codigo": "O318",
    "descricao": "Outras complicações específicas de gestação múltipla"
  },
  {
    "codigo": "O320",
    "descricao": "Assistência prestada à mãe por posição fetal instável"
  },
  {
    "codigo": "O321",
    "descricao": "Assistência prestada à mãe por apresentação pélvica"
  },
  {
    "codigo": "O322",
    "descricao": "Assistência prestada à mãe por posição fetal transversa e oblíqua"
  },
  {
    "codigo": "O323",
    "descricao": "Assistência prestada à mãe por apresentação de face, mento e fronte"
  },
  {
    "codigo": "O324",
    "descricao": "Assistência prestada à mãe por polo cefálico alto em gestação a termo"
  },
  {
    "codigo": "O325",
    "descricao": "Assistência prestada à mãe por gestação múltipla com apresentação anormal de um ou mais fetos"
  },
  {
    "codigo": "O326",
    "descricao": "Assistência prestada à mãe por apresentação composta"
  },
  {
    "codigo": "O328",
    "descricao": "Assistência prestada à mãe por outras formas de apresentação anormal do feto"
  },
  {
    "codigo": "O329",
    "descricao": "Assistência prestada à mãe por apresentação anormal do feto, não especificada"
  },
  {
    "codigo": "O330",
    "descricao": "Assistência prestada à mãe por uma desproporção devida à deformidade da pelve óssea materna"
  },
  {
    "codigo": "O331",
    "descricao": "Assistência prestada à mãe por uma desproporção devida à contração geral da pelve"
  },
  {
    "codigo": "O332",
    "descricao": "Assistência prestada à mãe por uma desproporção devida à contração do estreito superior da pelve"
  },
  {
    "codigo": "O333",
    "descricao": "Assistência prestada à mãe por uma desproporção devida à contração do estreito inferior da pelve"
  },
  {
    "codigo": "O334",
    "descricao": "Assistência prestada à mãe por uma desproporção de origem mista, materna e fetal"
  },
  {
    "codigo": "O335",
    "descricao": "Assistência prestada à mãe por uma desproporção devida a feto excepcionalmente grande"
  },
  {
    "codigo": "O336",
    "descricao": "Assistência prestada à mãe por uma desproporção devida a feto hidrocefálico"
  },
  {
    "codigo": "O337",
    "descricao": "Assistência prestada à mãe por uma desproporção devida a outras deformidades fetais"
  },
  {
    "codigo": "O338",
    "descricao": "Assistência prestada à mãe por uma desproporção de outra origem"
  },
  {
    "codigo": "O339",
    "descricao": "Assistência prestada à mãe por uma desproporção, não especificada"
  },
  {
    "codigo": "O340",
    "descricao": "Assistência à mãe por malformação congênita do útero"
  },
  {
    "codigo": "O341",
    "descricao": "Assistência prestada à mãe por tumor do corpo do útero"
  },
  {
    "codigo": "O342",
    "descricao": "Assistência prestada à mãe por cicatriz uterina devida a uma cirurgia anterior"
  },
  {
    "codigo": "O343",
    "descricao": "Assistência prestada à mãe por incompetência cervical"
  },
  {
    "codigo": "O344",
    "descricao": "Assistência prestada à mãe por outras anormalidades do colo do útero"
  },
  {
    "codigo": "O345",
    "descricao": "Assistência prestada à mãe por outras anormalidades do útero gravídico"
  },
  {
    "codigo": "O346",
    "descricao": "Assistência prestada à mãe por anormalidade da vagina"
  },
  {
    "codigo": "O347",
    "descricao": "Assistência prestada à mãe por anormalidade da vulva e do períneo"
  },
  {
    "codigo": "O348",
    "descricao": "Assistência prestada à mãe por outras anormalidades dos órgãos pélvicos"
  },
  {
    "codigo": "O349",
    "descricao": "Assistência prestada à mãe por anormalidade de órgãos pélvicos, não especificada"
  },
  {
    "codigo": "O350",
    "descricao": "Assistência prestada à mãe por malformação do sistema nervoso central do feto (suspeitada)"
  },
  {
    "codigo": "O351",
    "descricao": "Assistência prestada à mãe por anormalidade cromossômica (suspeitada) do feto"
  },
  {
    "codigo": "O352",
    "descricao": "Assistência prestada à mãe por doença hereditária (suspeitada) do feto"
  },
  {
    "codigo": "O353",
    "descricao": "Assistência prestada à mãe por lesão fetal (suspeitada) causada por doença viral materna"
  },
  {
    "codigo": "O354",
    "descricao": "Assistência prestada à mãe por lesão (suspeitada) causada ao feto por alcoolismo materno"
  },
  {
    "codigo": "O355",
    "descricao": "Assistência prestada à mãe por lesão fetal (suspeitada) causada por drogas"
  },
  {
    "codigo": "O356",
    "descricao": "Assistência prestada à mãe por lesão fetal (suspeitada) causada por radiação"
  },
  {
    "codigo": "O357",
    "descricao": "Assistência prestada à mãe por lesão fetal (suspeitada) causada por outros procedimentos médicos"
  },
  {
    "codigo": "O358",
    "descricao": "Assistência prestada à mãe por outras anormalidades ou lesões fetais (suspeitadas)"
  },
  {
    "codigo": "O359",
    "descricao": "Assistência prestada à mãe por lesão ou anormalidade fetais (suspeitadas), não especificadas"
  },
  {
    "codigo": "O360",
    "descricao": "Assistência prestada à mãe por isoimunização Rh"
  },
  {
    "codigo": "O361",
    "descricao": "Assistência prestada à mãe por outros tipos de isoimunização"
  },
  {
    "codigo": "O362",
    "descricao": "Assistência prestada à mãe por hidropsia fetal"
  },
  {
    "codigo": "O363",
    "descricao": "Assistência prestada à mãe por sinais de hipóxia fetal"
  },
  {
    "codigo": "O364",
    "descricao": "Assistência prestada à mãe por morte intra-uterina"
  },
  {
    "codigo": "O365",
    "descricao": "Assistência prestada à mãe por insuficiência de crescimento fetal"
  },
  {
    "codigo": "O366",
    "descricao": "Assistência prestada à mãe por crescimento fetal excessivo"
  },
  {
    "codigo": "O367",
    "descricao": "Assistência prestada à mãe por feto viável em gravidez abdominal"
  },
  {
    "codigo": "O368",
    "descricao": "Assistência prestada à mãe por outros problemas fetais especificados"
  },
  {
    "codigo": "O369",
    "descricao": "Assistência prestada à mãe por problema fetal não especificado"
  },
  { "codigo": "O40", "descricao": "Polihidrâmnio" },
  { "codigo": "O410", "descricao": "Oligohidrâmnio" },
  {
    "codigo": "O411",
    "descricao": "Infecção do saco amniótico e das membranas"
  },
  {
    "codigo": "O418",
    "descricao": "Outros transtornos especificados do líquido amniótico e das membranas"
  },
  {
    "codigo": "O419",
    "descricao": "Transtornos do líquido amniótico e das membranas não especificados"
  },
  {
    "codigo": "O420",
    "descricao": "Ruptura prematura de membranas, com início do trabalho de parto dentro de 24 horas"
  },
  {
    "codigo": "O421",
    "descricao": "Ruptura prematura de membranas, com início do trabalho de parto depois das primeiras 24 horas"
  },
  {
    "codigo": "O422",
    "descricao": "Ruptura prematura de membranas, com trabalho de parto retardado por terapêutica"
  },
  {
    "codigo": "O429",
    "descricao": "Ruptura prematura de membranas, não especificada"
  },
  {
    "codigo": "O430",
    "descricao": "Síndromes de transfusão placentária"
  },
  { "codigo": "O431", "descricao": "Malformação da placenta" },
  { "codigo": "O438", "descricao": "Outros transtornos da placenta" },
  {
    "codigo": "O439",
    "descricao": "Transtorno da placenta, não especificado"
  },
  {
    "codigo": "O440",
    "descricao": "Placenta prévia especificada como sem hemorragia"
  },
  { "codigo": "O441", "descricao": "Placenta prévia com hemorragia" },
  {
    "codigo": "O450",
    "descricao": "Descolamento prematuro da placenta com deficiência de coagulação"
  },
  { "codigo": "O458", "descricao": "Outro descolamento prematuro da placenta" },
  {
    "codigo": "O459",
    "descricao": "Descolamento prematuro da placenta, não especificado"
  },
  {
    "codigo": "O460",
    "descricao": "Hemorragia anteparto com deficiência de coagulação"
  },
  { "codigo": "O468", "descricao": "Outra forma de hemorragia anteparto" },
  {
    "codigo": "O469",
    "descricao": "Hemorragia anteparto, não especificada"
  },
  {
    "codigo": "O470",
    "descricao": "Falso trabalho de parto antes de se completarem 37 semanas de gestação"
  },
  {
    "codigo": "O471",
    "descricao": "Falso trabalho de parto na 37 semana completa ou depois dela"
  },
  {
    "codigo": "O479",
    "descricao": "Falso trabalho de parto, não especificado"
  },
  { "codigo": "O48", "descricao": "Gravidez prolongada" },
  {
    "codigo": "O600",
    "descricao": "Trabalho de parto pré-termo sem parto"
  },
  {
    "codigo": "O601",
    "descricao": "Trabalho de parto pré-termo com parto pré-termo"
  },
  {
    "codigo": "O602",
    "descricao": "Trabalho de parto pré-termo com parto a termo"
  },
  {
    "codigo": "O610",
    "descricao": "Falha na indução médica do trabalho de parto"
  },
  {
    "codigo": "O611",
    "descricao": "Falha na indução instrumental do trabalho de parto"
  },
  {
    "codigo": "O618",
    "descricao": "Outras formas de falha na indução do trabalho de parto"
  },
  {
    "codigo": "O619",
    "descricao": "Falha na indução do trabalho de parto, não especificada"
  },
  {
    "codigo": "O620",
    "descricao": "Contrações iniciais inadequadas"
  },
  { "codigo": "O621", "descricao": "Inércia uterina secundária" },
  { "codigo": "O622", "descricao": "Outras formas de inércia uterina" },
  { "codigo": "O623", "descricao": "Trabalho de parto precipitado" },
  {
    "codigo": "O624",
    "descricao": "Contrações uterinas hipertônicas, incoordenadas ou prolongadas"
  },
  {
    "codigo": "O628",
    "descricao": "Outras anormalidades da contração uterina"
  },
  {
    "codigo": "O629",
    "descricao": "Anormalidades da contração uterina, não especificadas"
  },
  {
    "codigo": "O630",
    "descricao": "Prolongamento do primeiro período (dilatação)"
  },
  {
    "codigo": "O631",
    "descricao": "Prolongamento do segundo período (expulsão)"
  },
  {
    "codigo": "O632",
    "descricao": "Parto retardado do segundo gêmeo, do terceiro gêmeo etc"
  },
  {
    "codigo": "O639",
    "descricao": "Trabalho de parto prolongado, não especificado"
  },
  {
    "codigo": "O640",
    "descricao": "Obstrução do trabalho de parto devida à rotação incompleta do polo cefálico fetal"
  },
  {
    "codigo": "O641",
    "descricao": "Obstrução do trabalho de parto devida à apresentação pélvica"
  },
  {
    "codigo": "O642",
    "descricao": "Obstrução do trabalho de parto devida à apresentação de face"
  },
  {
    "codigo": "O643",
    "descricao": "Obstrução do trabalho de parto devida à apresentação de fronte"
  },
  {
    "codigo": "O644",
    "descricao": "Obstrução do trabalho de parto devida à apresentação de ombro"
  },
  {
    "codigo": "O645",
    "descricao": "Obstrução do trabalho de parto devida à apresentação complexa"
  },
  {
    "codigo": "O648",
    "descricao": "Obstrução do trabalho de parto devida a outras formas de má-apresentação e má-posição"
  },
  {
    "codigo": "O649",
    "descricao": "Obstrução do trabalho de parto devida a má-posição ou má-apresentação, não especificadas"
  },
  {
    "codigo": "O650",
    "descricao": "Obstrução do trabalho de parto devida a deformidade pélvica"
  },
  {
    "codigo": "O651",
    "descricao": "Obstrução do trabalho de parto devida à contração geral da pelve"
  },
  {
    "codigo": "O652",
    "descricao": "Obstrução do trabalho de parto devida à contração do estreito superior"
  },
  {
    "codigo": "O653",
    "descricao": "Obstrução do trabalho de parto devida à contração do estreito inferior ou da cavidade mediana"
  },
  {
    "codigo": "O654",
    "descricao": "Obstrução do trabalho de parto devida a desproporção feto-pélvica, não especificada"
  },
  {
    "codigo": "O655",
    "descricao": "Obstrução do trabalho de parto devida a anormalidade dos órgãos pélvicos maternos"
  },
  {
    "codigo": "O658",
    "descricao": "Obstrução do trabalho de parto devida a outras anormalidades pélvicas maternas"
  },
  {
    "codigo": "O659",
    "descricao": "Obstrução do trabalho de parto devida a anormalidades pélvicas maternas não especificadas"
  },
  {
    "codigo": "O660",
    "descricao": "Obstrução do trabalho de parto devido a distócia de ombro"
  },
  {
    "codigo": "O661",
    "descricao": "Obstrução do trabalho de parto por colisão de gêmeos"
  },
  {
    "codigo": "O662",
    "descricao": "Obstrução do trabalho de parto por feto excepcionalmente grande"
  },
  {
    "codigo": "O663",
    "descricao": "Obstrução do trabalho de parto devido a outras anormalidades do feto"
  },
  {
    "codigo": "O664",
    "descricao": "Falha de tentativa de trabalho de parto, não especificada"
  },
  {
    "codigo": "O665",
    "descricao": "Falha de aplicação de fórceps e vácuo-extrator, não especificada"
  },
  {
    "codigo": "O668",
    "descricao": "Outras formas de obstrução do trabalho de parto, especificadas"
  },
  {
    "codigo": "O669",
    "descricao": "Obstrução do trabalho de parto, não especificado"
  },
  {
    "codigo": "O670",
    "descricao": "Hemorragia intraparto com deficiência de coagulação"
  },
  { "codigo": "O678", "descricao": "Outras hemorragias intraparto" },
  {
    "codigo": "O679",
    "descricao": "Hemorragia intraparto não especificada"
  },
  {
    "codigo": "O680",
    "descricao": "Trabalho de parto e parto complicados por anormalidade da freqüência cardíaca fetal"
  },
  {
    "codigo": "O681",
    "descricao": "Trabalho de parto e parto complicados por mecônio no líquido amniótico"
  },
  {
    "codigo": "O682",
    "descricao": "Trabalho de parto e parto complicados por anormalidade na freqüência cardíaca fetal com mecônio no líquido amniótico"
  },
  {
    "codigo": "O683",
    "descricao": "Trabalho de parto e parto complicados por evidências bioquímicas de sofrimento fetal"
  },
  {
    "codigo": "O688",
    "descricao": "Trabalho de parto e parto complicados por outras evidências de sofrimento fetal"
  },
  {
    "codigo": "O689",
    "descricao": "Trabalho de parto e parto complicados por sofrimento fetal, não especificado"
  },
  {
    "codigo": "O690",
    "descricao": "Trabalho de parto e parto complicados por prolapso do cordão"
  },
  {
    "codigo": "O691",
    "descricao": "Trabalho de parto e parto complicados por circular de cordão, com compressão"
  },
  {
    "codigo": "O692",
    "descricao": "Trabalho de parto e parto complicados por outros enovelamentos do cordão"
  },
  {
    "codigo": "O693",
    "descricao": "Trabalho de parto e parto complicados por cordão curto"
  },
  {
    "codigo": "O694",
    "descricao": "Trabalho de parto e parto complicados por vasa praevia"
  },
  {
    "codigo": "O695",
    "descricao": "Trabalho de parto e parto complicados por lesão vascular do cordão"
  },
  {
    "codigo": "O698",
    "descricao": "Trabalho de parto e parto complicados por outras complicações do cordão"
  },
  {
    "codigo": "O699",
    "descricao": "Trabalho de parto e parto complicados por complicações do cordão, não especificadas"
  },
  {
    "codigo": "O700",
    "descricao": "Laceração de períneo de primeiro grau durante o parto"
  },
  {
    "codigo": "O701",
    "descricao": "Laceração de períneo de segundo grau durante o parto"
  },
  {
    "codigo": "O702",
    "descricao": "Laceração de períneo de terceiro grau durante o parto"
  },
  {
    "codigo": "O703",
    "descricao": "Laceração de períneo de quarto grau durante o parto"
  },
  {
    "codigo": "O709",
    "descricao": "Laceração de períneo durante o parto, não especificada"
  },
  {
    "codigo": "O710",
    "descricao": "Ruptura do útero antes do início do trabalho de parto"
  },
  {
    "codigo": "O711",
    "descricao": "Ruptura do útero durante o trabalho de parto"
  },
  {
    "codigo": "O712",
    "descricao": "Inversão do útero pós-parto"
  },
  {
    "codigo": "O713",
    "descricao": "Laceração obstétrica do colo do útero"
  },
  {
    "codigo": "O714",
    "descricao": "Laceração vaginal alta isolada, de origem obstétrica"
  },
  {
    "codigo": "O715",
    "descricao": "Outras lesões obstétricas aos órgãos pélvicos"
  },
  {
    "codigo": "O716",
    "descricao": "Lesão obstétrica das articulações e dos ligamentos pélvicos"
  },
  { "codigo": "O717", "descricao": "Hematoma obstétrico da pelve" },
  {
    "codigo": "O718",
    "descricao": "Outros traumatismos obstétricos especificados"
  },
  {
    "codigo": "O719",
    "descricao": "Traumatismo obstétrico, não especificado"
  },
  { "codigo": "O720", "descricao": "Hemorragia do terceiro estágio" },
  {
    "codigo": "O721",
    "descricao": "Outras hemorragias do pós-parto imediato"
  },
  {
    "codigo": "O722",
    "descricao": "Hemorragias pós-parto, tardias e secundárias"
  },
  {
    "codigo": "O723",
    "descricao": "Deficiências de coagulação pós-parto"
  },
  {
    "codigo": "O730",
    "descricao": "Retenção da placenta sem hemorragia"
  },
  {
    "codigo": "O731",
    "descricao": "Retenção de partes da placenta ou das membranas, sem hemorragia"
  },
  {
    "codigo": "O740",
    "descricao": "Pneumonite aspirativa devida a anestesia durante o trabalho de parto e o parto"
  },
  {
    "codigo": "O741",
    "descricao": "Outras complicações pulmonares devidas a anestesia durante o trabalho de parto e no parto"
  },
  {
    "codigo": "O742",
    "descricao": "Complicações cardíacas devidas a anestesia durante o trabalho de parto e no parto"
  },
  {
    "codigo": "O743",
    "descricao": "Complicações do sistema nervoso central devidas a anestesia durante o trabalho de parto e no parto"
  },
  {
    "codigo": "O744",
    "descricao": "Reação tóxica a anestesia local durante trabalho de parto e o parto"
  },
  {
    "codigo": "O745",
    "descricao": "Cefaléia pós-anestesia raquidiana e peridural, durante o trabalho de parto e parto"
  },
  {
    "codigo": "O746",
    "descricao": "Outras complicações de anestesia raquidiana ou peridural, durante o trabalho de parto e parto"
  },
  {
    "codigo": "O747",
    "descricao": "Falha ou dificuldade de entubação durante o trabalho de parto e parto"
  },
  {
    "codigo": "O748",
    "descricao": "Outras complicações de anestesia durante o trabalho de parto e parto"
  },
  {
    "codigo": "O749",
    "descricao": "Complicações de anestesia durante o trabalho de parto e parto, não especificadas"
  },
  {
    "codigo": "O750",
    "descricao": "Sofrimento materno durante o trabalho de parto e o parto"
  },
  {
    "codigo": "O751",
    "descricao": "Choque durante ou subseqüente ao trabalho de parto e o parto"
  },
  {
    "codigo": "O752",
    "descricao": "Febre durante o trabalho de parto não classificada em outra parte"
  },
  {
    "codigo": "O753",
    "descricao": "Outras infecções durante o trabalho de parto"
  },
  {
    "codigo": "O754",
    "descricao": "Outras complicações de procedimentos ou de cirurgia obstétrica"
  },
  {
    "codigo": "O755",
    "descricao": "Demora do parto após ruptura artificial das membranas"
  },
  {
    "codigo": "O756",
    "descricao": "Demora do parto após ruptura espontânea ou não especificada das membranas"
  },
  {
    "codigo": "O757",
    "descricao": "Parto vaginal subseqüente a cesariana anterior"
  },
  {
    "codigo": "O758",
    "descricao": "Outras complicações especificadas do trabalho de parto e do parto"
  },
  {
    "codigo": "O759",
    "descricao": "Complicações do trabalho de parto e do parto, não especificadas"
  },
  { "codigo": "O800", "descricao": "Parto espontâneo cefálico" },
  { "codigo": "O801", "descricao": "Parto espontâneo pélvico" },
  {
    "codigo": "O808",
    "descricao": "Outros tipos de parto único espontâneo"
  },
  {
    "codigo": "O809",
    "descricao": "Parto único espontâneo, não especificado"
  },
  { "codigo": "O810", "descricao": "Parto por fórceps baixo" },
  {
    "codigo": "O811",
    "descricao": "Parto por fórceps da cavidade mediana"
  },
  {
    "codigo": "O812",
    "descricao": "Fórceps da cavidade mediana com rotação"
  },
  {
    "codigo": "O813",
    "descricao": "Outros tipos de parto por fórceps e os não especificados"
  },
  { "codigo": "O814", "descricao": "Parto por vácuo-extrator" },
  {
    "codigo": "O815",
    "descricao": "Parto por combinação de fórceps e vácuo-extrator"
  },
  { "codigo": "O820", "descricao": "Parto por cesariana eletiva" },
  { "codigo": "O821", "descricao": "Parto por cesariana de emergência" },
  { "codigo": "O822", "descricao": "Parto por cesariana para histerectomia" },
  {
    "codigo": "O828",
    "descricao": "Outros tipos de parto único por cesariana"
  },
  {
    "codigo": "O829",
    "descricao": "Parto por cesariana, não especificada"
  },
  { "codigo": "O830", "descricao": "Extração pélvica" },
  {
    "codigo": "O831",
    "descricao": "Outros tipos de parto pélvico assistido"
  },
  {
    "codigo": "O832",
    "descricao": "Outras formas de parto com o auxílio de manipulação"
  },
  {
    "codigo": "O833",
    "descricao": "Parto de gravidez intra-abdominal com feto viável"
  },
  {
    "codigo": "O834",
    "descricao": "Cirurgia destrutiva para facilitar o parto"
  },
  {
    "codigo": "O838",
    "descricao": "Outros tipos especificados de parto único assistido"
  },
  {
    "codigo": "O839",
    "descricao": "Parto único assistido, não especificado"
  },
  {
    "codigo": "O840",
    "descricao": "Parto múltiplo, todos espontâneos"
  },
  {
    "codigo": "O841",
    "descricao": "Parto múltiplo, todos por fórceps ou vácuo-extrator"
  },
  { "codigo": "O842", "descricao": "Parto múltiplo, todos por cesariana" },
  { "codigo": "O848", "descricao": "Outro tipo de parto múltiplo" },
  {
    "codigo": "O849",
    "descricao": "Parto múltiplo, não especificado"
  },
  { "codigo": "O85", "descricao": "Infecção puerperal" },
  {
    "codigo": "O860",
    "descricao": "Infecção da incisão cirúrgica de origem obstétrica"
  },
  {
    "codigo": "O861",
    "descricao": "Outras infecções dos órgãos genitais subseqüentes ao parto"
  },
  {
    "codigo": "O862",
    "descricao": "Infecção das vias urinárias subseqüentes ao parto"
  },
  {
    "codigo": "O863",
    "descricao": "Outras infecções das vias genitourinárias subseqüentes ao parto"
  },
  {
    "codigo": "O864",
    "descricao": "Febre de origem desconhecida subseqüente ao parto"
  },
  {
    "codigo": "O868",
    "descricao": "Outras infecções puerperais especificadas"
  },
  {
    "codigo": "O870",
    "descricao": "Tromboflebite superficial no puerpério"
  },
  { "codigo": "O871", "descricao": "Tromboflebite profunda no puerpério" },
  { "codigo": "O872", "descricao": "Hemorróidas no puerpério" },
  {
    "codigo": "O873",
    "descricao": "Trombose venosa cerebral no puerpério"
  },
  {
    "codigo": "O878",
    "descricao": "Outras complicações venosas no puerpério"
  },
  {
    "codigo": "O879",
    "descricao": "Complicações venosas no puerpério, não especificadas"
  },
  { "codigo": "O880", "descricao": "Embolia gasosa de origem obstétrica" },
  { "codigo": "O881", "descricao": "Embolia amniótica" },
  {
    "codigo": "O882",
    "descricao": "Embolia obstétrica por coágulo de sangue"
  },
  {
    "codigo": "O883",
    "descricao": "Embolia séptica ou piêmica de origem obstétrica"
  },
  {
    "codigo": "O888",
    "descricao": "Outras embolias de origem obstétrica"
  },
  {
    "codigo": "O890",
    "descricao": "Complicações pulmonares da anestesia durante o puerpério"
  },
  {
    "codigo": "O891",
    "descricao": "Complicações cardíacas devidas a anestesia durante o puerpério"
  },
  {
    "codigo": "O892",
    "descricao": "Complicações relativas ao sistema nervoso central devidas a anestesia durante o puerpério"
  },
  {
    "codigo": "O893",
    "descricao": "Reação tóxica a uma anestesia local durante o puerpério"
  },
  {
    "codigo": "O894",
    "descricao": "Cefaléia provocada por uma anestesia raquidiana ou peridural, durante o puerpério"
  },
  {
    "codigo": "O895",
    "descricao": "Outras complicações de anestesia raquidiana ou peridural, durante o puerpério"
  },
  {
    "codigo": "O896",
    "descricao": "Falha na ou dificuldade de entubação, durante o puerpério"
  },
  {
    "codigo": "O898",
    "descricao": "Outras complicações da anestesia durante o puerpério"
  },
  {
    "codigo": "O899",
    "descricao": "Complicação devida a anestesia, durante o puerpério, não especificada"
  },
  { "codigo": "O900", "descricao": "Ruptura da incisão de cesariana" },
  {
    "codigo": "O901",
    "descricao": "Ruptura da incisão obstétrica, no períneo"
  },
  { "codigo": "O902", "descricao": "Hematoma da incisão obstétrica" },
  { "codigo": "O903", "descricao": "Cardiomiopatia no puerpério" },
  {
    "codigo": "O904",
    "descricao": "Insuficiência renal aguda do pós-parto"
  },
  { "codigo": "O905", "descricao": "Tireoidite do pós-parto" },
  {
    "codigo": "O908",
    "descricao": "Outras complicações do puerpério, não classificadas em outra parte"
  },
  {
    "codigo": "O909",
    "descricao": "Complicação do puerpério não especificada"
  },
  {
    "codigo": "O910",
    "descricao": "Infecção do mamilo associada ao parto"
  },
  { "codigo": "O911", "descricao": "Abscesso da mama associada ao parto" },
  {
    "codigo": "O912",
    "descricao": "Mastite não purulenta associada ao parto"
  },
  { "codigo": "O920", "descricao": "Mamilo retraído associado ao parto" },
  { "codigo": "O921", "descricao": "Fissuras do mamilo associadas ao parto" },
  {
    "codigo": "O922",
    "descricao": "Outras afecções da mama, e as não especificadas, associadas ao parto"
  },
  { "codigo": "O923", "descricao": "Agalactia" },
  { "codigo": "O924", "descricao": "Hipogalactia" },
  { "codigo": "O925", "descricao": "Suspensão da lactação" },
  { "codigo": "O926", "descricao": "Galactorréia" },
  {
    "codigo": "O927",
    "descricao": "Outros distúrbios da lactação e os não especificados"
  },
  {
    "codigo": "O94",
    "descricao": "Seqüelas de complicações da gravidez, parto e puerpério"
  },
  {
    "codigo": "O95",
    "descricao": "Morte obstétrica de causa não especificada"
  },
  {
    "codigo": "O96",
    "descricao": "Morte, por qualquer causa obstétrica, que ocorre mais de 42 dias, mas menos de 1 ano, após o parto"
  },
  {
    "codigo": "O97",
    "descricao": "Morte por seqüelas de causas obstétricas diretas"
  },
  {
    "codigo": "O980",
    "descricao": "Tuberculose complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O981",
    "descricao": "Sífilis complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O982",
    "descricao": "Gonorréia complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O983",
    "descricao": "Outras infecções em que a via de transmissão é predominantemente sexual, complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O984",
    "descricao": "Hepatite viral complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O985",
    "descricao": "Outras doenças virais complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O986",
    "descricao": "Doenças causadas por protozoários complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O988",
    "descricao": "Outras doenças infecciosas e parasitárias maternas complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O989",
    "descricao": "Doenças infecciosas e parasitárias maternas, não especificadas, complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O990",
    "descricao": "Anemia complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O991",
    "descricao": "Outras doenças do sangue e dos órgãos hematopoéticos e alguns transtornos que comprometem o sistema imunológico, complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O992",
    "descricao": "Doenças endócrinas, nutricionais e metabólicas complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O993",
    "descricao": "Transtornos mentais e doenças do sistema nervoso complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O994",
    "descricao": "Doenças do aparelho circulatório complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O995",
    "descricao": "Doenças do aparelho respiratório complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O996",
    "descricao": "Doenças do aparelho digestivo complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O997",
    "descricao": "Doenças da pele e do tecido subcutâneo complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "O998",
    "descricao": "Outras doenças e afecções especificadas complicando a gravidez, o parto e o puerpério"
  },
  {
    "codigo": "P000",
    "descricao": "Feto e recém-nascido afetados por transtornos maternos hipertensivos"
  },
  {
    "codigo": "P001",
    "descricao": "Feto e recém-nascido afetados por doenças maternas renais e das vias urinárias"
  },
  {
    "codigo": "P002",
    "descricao": "Feto e recém-nascido afetados por doenças infecciosas e parasitárias da mãe"
  },
  {
    "codigo": "P003",
    "descricao": "Feto e recém-nascido afetados por outras doenças circulatórias e respiratórias maternas"
  },
  {
    "codigo": "P004",
    "descricao": "Feto e recém-nascido afetados por transtornos nutricionais maternos"
  },
  {
    "codigo": "P005",
    "descricao": "Feto e recém-nascido afetados por traumatismo materno"
  },
  {
    "codigo": "P006",
    "descricao": "Feto e recém-nascido afetados por intervenção cirúrgica na mãe"
  },
  {
    "codigo": "P007",
    "descricao": "Feto e recém-nascido afetados por outros procedimentos médicos na mãe, não classificados em outra parte"
  },
  {
    "codigo": "P008",
    "descricao": "Feto e recém-nascido afetados por outras afecções maternas"
  },
  {
    "codigo": "P009",
    "descricao": "Feto e recém-nascido afetados por afecção materna não especificada"
  },
  {
    "codigo": "P010",
    "descricao": "Feto e recém-nascido afetados por incompetência do colo uterino"
  },
  {
    "codigo": "P011",
    "descricao": "Feto e recém-nascido afetados por ruptura prematura das membranas"
  },
  {
    "codigo": "P012",
    "descricao": "Feto e recém-nascido afetados por oligohidrâmnio"
  },
  {
    "codigo": "P013",
    "descricao": "Feto e recém-nascido afetados por polihidrâmnio"
  },
  {
    "codigo": "P014",
    "descricao": "Feto e recém-nascido afetados por gravidez ectópica"
  },
  {
    "codigo": "P015",
    "descricao": "Feto e recém-nascido afetados por gravidez múltipla"
  },
  {
    "codigo": "P016",
    "descricao": "Feto e recém-nascido afetados por morte materna"
  },
  {
    "codigo": "P017",
    "descricao": "Feto e recém-nascido afetados por apresentação anormal antes do trabalho de parto"
  },
  {
    "codigo": "P018",
    "descricao": "Feto e recém-nascido afetados por outras complicações maternas da gravidez"
  },
  {
    "codigo": "P019",
    "descricao": "Feto e recém-nascido afetados por afecções maternas da gravidez, não especificadas"
  },
  {
    "codigo": "P020",
    "descricao": "Feto e recém-nascido afetados por placenta prévia"
  },
  {
    "codigo": "P021",
    "descricao": "Feto e recém-nascido afetados por outras formas de descolamento da placenta e hemorragia"
  },
  {
    "codigo": "P022",
    "descricao": "Feto e recém-nascido afetados por outras anormalidades morfológicas e funcionais da placenta e as não especificadas"
  },
  {
    "codigo": "P023",
    "descricao": "Feto e recém-nascido afetados por síndromes de transfusão placentária"
  },
  {
    "codigo": "P024",
    "descricao": "Feto e recém-nascido afetados por prolapso de cordão umbilical"
  },
  {
    "codigo": "P025",
    "descricao": "Feto e recém-nascido afetados por outras compressões do cordão umbilical"
  },
  {
    "codigo": "P026",
    "descricao": "Feto e recém-nascido afetados por outras afecções do cordão umbilical e as não especificadas"
  },
  {
    "codigo": "P027",
    "descricao": "Feto e recém-nascido afetados por corioamnionite"
  },
  {
    "codigo": "P028",
    "descricao": "Feto e recém-nascido afetados por outras anormalidades das membranas"
  },
  {
    "codigo": "P029",
    "descricao": "Feto e recém-nascido afetados por anormalidade não especificada das membranas"
  },
  {
    "codigo": "P030",
    "descricao": "Feto e recém-nascido afetados por parto e extração pélvicas"
  },
  {
    "codigo": "P031",
    "descricao": "Feto e recém-nascido afetados por outras apresentações anormais, má-posições e desproporções durante o trabalho de parto e o parto"
  },
  {
    "codigo": "P032",
    "descricao": "Feto e recém-nascido afetados por parto por fórceps"
  },
  {
    "codigo": "P033",
    "descricao": "Feto e recém-nascido afetados por parto por vácuo-extrator [ventosa]"
  },
  {
    "codigo": "P034",
    "descricao": "Feto e recém-nascido afetados por parto por cesariana"
  },
  {
    "codigo": "P035",
    "descricao": "Feto e recém-nascido afetados por parto precipitado"
  },
  {
    "codigo": "P036",
    "descricao": "Feto e recém-nascido afetados por contrações uterinas anormais"
  },
  {
    "codigo": "P038",
    "descricao": "Feto e recém-nascido afetados por outras complicações especificadas do trabalho de parto e do parto"
  },
  {
    "codigo": "P039",
    "descricao": "Feto e recém-nascido afetados por complicações não especificadas do trabalho de parto e do parto"
  },
  {
    "codigo": "P040",
    "descricao": "Feto e recém-nascido afetados por anestesia e analgesia materna durante a gravidez, trabalho de parto e parto"
  },
  {
    "codigo": "P041",
    "descricao": "Feto e recém-nascido afetados por outros medicamentos utilizados pela mãe"
  },
  {
    "codigo": "P042",
    "descricao": "Feto e recém-nascido afetados pelo uso de fumo pela mãe"
  },
  {
    "codigo": "P043",
    "descricao": "Feto e recém-nascido afetados pelo uso de álcool pela mãe"
  },
  {
    "codigo": "P044",
    "descricao": "Feto e recém-nascido afetados pelo uso de drogas que causam dependência pela mãe"
  },
  {
    "codigo": "P045",
    "descricao": "Feto e recém-nascido afetados por uso materno de substâncias químicas dos alimentos"
  },
  {
    "codigo": "P046",
    "descricao": "Feto e recém-nascido afetados pela exposição da mãe a substâncias químicas do meio ambiente"
  },
  {
    "codigo": "P048",
    "descricao": "Feto e recém-nascido afetados por outras influências nocivas maternas"
  },
  {
    "codigo": "P049",
    "descricao": "Feto e recém-nascido afetados por influências nocivas maternas não especificadas"
  },
  {
    "codigo": "P050",
    "descricao": "Recém-nascido de baixo peso para a idade gestacional"
  },
  { "codigo": "P051", "descricao": "Pequeno para a idade gestacional" },
  {
    "codigo": "P052",
    "descricao": "Desnutrição fetal sem menção de peso e comprimento baixos para a idade gestacional"
  },
  {
    "codigo": "P059",
    "descricao": "Retardo não especificado do crescimento fetal"
  },
  { "codigo": "P070", "descricao": "Recém-nascido com peso muito baixo" },
  { "codigo": "P071", "descricao": "Outros recém-nascidos de peso baixo" },
  { "codigo": "P072", "descricao": "Imaturidade extrema" },
  {
    "codigo": "P073",
    "descricao": "Outros recém-nascidos de pré-termo"
  },
  {
    "codigo": "P080",
    "descricao": "Recém-nascido de tamanho excessivamente grande"
  },
  {
    "codigo": "P081",
    "descricao": "Outros recém-nascidos grandes para a idade gestacional"
  },
  {
    "codigo": "P082",
    "descricao": "Recém-nascido pós-termo, não-grande para a idade gestacional"
  },
  {
    "codigo": "P100",
    "descricao": "Hemorragia subdural devida a traumatismo de parto"
  },
  {
    "codigo": "P101",
    "descricao": "Hemorragia cerebral devida a traumatismo de parto"
  },
  {
    "codigo": "P102",
    "descricao": "Hemorragia intraventricular devida a traumatismo de parto"
  },
  {
    "codigo": "P103",
    "descricao": "Hemorragia subaracnoídea devida a traumatismo de parto"
  },
  {
    "codigo": "P104",
    "descricao": "Ruptura tentorial devida a traumatismo de parto"
  },
  {
    "codigo": "P108",
    "descricao": "Outras lacerações e hemorragias intracranianas devidas a traumatismo de parto"
  },
  {
    "codigo": "P109",
    "descricao": "Laceração e hemorragia intracranianas não especificadas devidas a traumatismo de parto"
  },
  {
    "codigo": "P110",
    "descricao": "Edema cerebral devido a traumatismo de parto"
  },
  {
    "codigo": "P111",
    "descricao": "Outras lesões cerebrais especificadas devidas a traumatismo de parto"
  },
  {
    "codigo": "P112",
    "descricao": "Lesão cerebral não especificada devida a traumatismo de parto"
  },
  { "codigo": "P113", "descricao": "Traumatismo de parto do nervo facial" },
  {
    "codigo": "P114",
    "descricao": "Traumatismo de parto de outros nervos cranianos"
  },
  {
    "codigo": "P115",
    "descricao": "Traumatismo de parto da coluna e da medula espinhal"
  },
  {
    "codigo": "P119",
    "descricao": "Traumatismo de parto não especificado do sistema nervoso central"
  },
  {
    "codigo": "P120",
    "descricao": "Céfalo-hematoma devido a traumatismo de parto"
  },
  { "codigo": "P121", "descricao": "Chignon devido a traumatismo de parto" },
  {
    "codigo": "P122",
    "descricao": "Hemorragia subaponeurótica epicraniana devida a traumatismo de parto"
  },
  {
    "codigo": "P123",
    "descricao": "Esmagamento do couro cabeludo devido a traumatismo de parto"
  },
  {
    "codigo": "P124",
    "descricao": "Lesão por monitorização do couro cabeludo do recém-nascido"
  },
  {
    "codigo": "P128",
    "descricao": "Outras lesões do couro cabeludo devidas a traumatismo de parto"
  },
  {
    "codigo": "P129",
    "descricao": "Lesão não especificada do couro cabeludo devida a traumatismo de parto"
  },
  {
    "codigo": "P130",
    "descricao": "Fratura de crânio devida a traumatismo de parto"
  },
  {
    "codigo": "P131",
    "descricao": "Outras lesões cranianas devidas a traumatismo de parto"
  },
  {
    "codigo": "P132",
    "descricao": "Lesão do fêmur devida a traumatismo de parto"
  },
  {
    "codigo": "P133",
    "descricao": "Lesão de outros ossos longos devida a traumatismo de parto"
  },
  {
    "codigo": "P134",
    "descricao": "Fratura da clavícula devida a traumatismo de parto"
  },
  {
    "codigo": "P138",
    "descricao": "Lesões de outras partes do esqueleto devidas a traumatismo de parto"
  },
  {
    "codigo": "P139",
    "descricao": "Lesões não especificadas do esqueleto devidas a traumatismo de parto"
  },
  {
    "codigo": "P140",
    "descricao": "Paralisia de Erb devida a traumatismo de parto"
  },
  {
    "codigo": "P141",
    "descricao": "Paralisia de Klumpke devida a traumatismo de parto"
  },
  {
    "codigo": "P142",
    "descricao": "Paralisia do nervo frênico devida a traumatismo de parto"
  },
  {
    "codigo": "P143",
    "descricao": "Outras lesões do plexo braquial devidas a traumatismo de parto"
  },
  {
    "codigo": "P148",
    "descricao": "Outras lesões de outras partes do sistema nervoso periférico devidas a traumatismo de parto"
  },
  {
    "codigo": "P149",
    "descricao": "Lesão não especificada do sistema nervoso periférico devida a traumatismo de parto"
  },
  {
    "codigo": "P150",
    "descricao": "Lesão do fígado devida a traumatismo de parto"
  },
  {
    "codigo": "P151",
    "descricao": "Lesão do baço devida a traumatismo de parto"
  },
  {
    "codigo": "P152",
    "descricao": "Lesão do esternomastóide devida a traumatismo de parto"
  },
  {
    "codigo": "P153",
    "descricao": "Lesão dos olhos devida a traumatismo de parto"
  },
  { "codigo": "P154", "descricao": "Lesão da face ao nascer" },
  {
    "codigo": "P155",
    "descricao": "Lesão dos órgãos genitais externos, devida a traumatismo de parto"
  },
  {
    "codigo": "P156",
    "descricao": "Necrose de gordura subcutânea devida a traumatismo de parto"
  },
  {
    "codigo": "P158",
    "descricao": "Outros traumatismos de parto especificados"
  },
  {
    "codigo": "P159",
    "descricao": "Traumatismo de parto não especificado"
  },
  {
    "codigo": "P200",
    "descricao": "Hipóxia intra-uterina diagnosticada antes do início do trabalho de parto"
  },
  {
    "codigo": "P201",
    "descricao": "Hipóxia intra-uterina diagnosticada durante o trabalho de parto e o parto"
  },
  {
    "codigo": "P209",
    "descricao": "Hipóxia intra-uterina não especificada"
  },
  { "codigo": "P210", "descricao": "Asfixia grave ao nascer" },
  { "codigo": "P211", "descricao": "Asfixia leve ou moderada ao nascer" },
  { "codigo": "P219", "descricao": "Asfixia ao nascer, não especificada" },
  {
    "codigo": "P220",
    "descricao": "Síndrome da angústia respiratória do recém-nascido"
  },
  {
    "codigo": "P221",
    "descricao": "Taquipnéia transitória do recém-nascido"
  },
  {
    "codigo": "P228",
    "descricao": "Outros desconfortos respiratórios do recém-nascido"
  },
  {
    "codigo": "P229",
    "descricao": "Desconforto respiratório não especificado do recém-nascido"
  },
  {
    "codigo": "P230",
    "descricao": "Pneumonia congênita devida a agente viral"
  },
  {
    "codigo": "P231",
    "descricao": "Pneumonia congênita devida a Clamídia"
  },
  {
    "codigo": "P232",
    "descricao": "Pneumonia congênita devida a estafilococo"
  },
  {
    "codigo": "P233",
    "descricao": "Pneumonia congênita devida a estreptococo do grupo B"
  },
  {
    "codigo": "P234",
    "descricao": "Pneumonia congênita devida a Escherichia coli"
  },
  {
    "codigo": "P235",
    "descricao": "Pneumonia congênita devida a Pseudomonas"
  },
  {
    "codigo": "P236",
    "descricao": "Pneumonia congênita devida a outros agentes bacterianos"
  },
  {
    "codigo": "P238",
    "descricao": "Pneumonia congênita devida a outros organismos"
  },
  {
    "codigo": "P239",
    "descricao": "Pneumonia congênita não especificada"
  },
  {
    "codigo": "P240",
    "descricao": "Aspiração neonatal de mecônio"
  },
  {
    "codigo": "P241",
    "descricao": "Aspiração neonatal de líquido amniótico e muco"
  },
  { "codigo": "P242", "descricao": "Aspiração neonatal de sangue" },
  {
    "codigo": "P243",
    "descricao": "Aspiração neonatal de leite e alimento regurgitados"
  },
  {
    "codigo": "P248",
    "descricao": "Outras síndromes de aspiração neonatais"
  },
  {
    "codigo": "P249",
    "descricao": "Síndrome de aspiração neonatal não especificada"
  },
  {
    "codigo": "P250",
    "descricao": "Enfisema intersticial originado no período perinatal"
  },
  {
    "codigo": "P251",
    "descricao": "Pneumotórax originado no período perinatal"
  },
  {
    "codigo": "P252",
    "descricao": "Pneumomediastino originado no período perinatal"
  },
  {
    "codigo": "P253",
    "descricao": "Pneumopericárdio originado no período perinatal"
  },
  {
    "codigo": "P258",
    "descricao": "Outras afecções relacionadas com o enfisema intersticial originadas no período perinatal"
  },
  {
    "codigo": "P260",
    "descricao": "Hemorragia traqueobrônquica originada no período perinatal"
  },
  {
    "codigo": "P261",
    "descricao": "Hemorragia pulmonar maciça originada no período perinatal"
  },
  {
    "codigo": "P268",
    "descricao": "Outras hemorragias pulmonares originadas no período perinatal"
  },
  {
    "codigo": "P269",
    "descricao": "Hemorragia pulmonar não especificada originada no período perinatal"
  },
  { "codigo": "P270", "descricao": "Síndrome de Wilson-Mikity" },
  {
    "codigo": "P271",
    "descricao": "Displasia broncopulmonar originada no período perinatal"
  },
  {
    "codigo": "P278",
    "descricao": "Outras doenças respiratórias crônicas originadas no período perinatal"
  },
  {
    "codigo": "P279",
    "descricao": "Doença respiratória crônica não especificada originada no período perinatal"
  },
  {
    "codigo": "P280",
    "descricao": "Atelectasia primária do recém-nascido"
  },
  {
    "codigo": "P281",
    "descricao": "Outras atelectasias do recém-nascido e as não especificadas"
  },
  {
    "codigo": "P282",
    "descricao": "Crises cianóticas do recém-nascido"
  },
  {
    "codigo": "P283",
    "descricao": "Apnéia primária do sono do recém-nascido"
  },
  {
    "codigo": "P284",
    "descricao": "Outras apnéias do recém-nascido"
  },
  {
    "codigo": "P285",
    "descricao": "Insuficiência respiratória do recém-nascido"
  },
  {
    "codigo": "P288",
    "descricao": "Outras afecções respiratórias especificadas do recém-nascido"
  },
  {
    "codigo": "P289",
    "descricao": "Afecção respiratória do recém-nascido, não especificada"
  },
  {
    "codigo": "P290",
    "descricao": "Insuficiência cardíaca neonatal"
  },
  { "codigo": "P291", "descricao": "Disritmia cardíaca neonatal" },
  { "codigo": "P292", "descricao": "Hipertensão neonatal" },
  {
    "codigo": "P293",
    "descricao": "Persistência da circulação fetal"
  },
  {
    "codigo": "P294",
    "descricao": "Isquemia miocárdica transitória do recém-nascido"
  },
  {
    "codigo": "P298",
    "descricao": "Outros transtornos cardiovasculares originados no período perinatal"
  },
  {
    "codigo": "P299",
    "descricao": "Transtornos cardiovasculares não especificados originados no período perinatal"
  },
  {
    "codigo": "P350",
    "descricao": "Síndrome da rubéola congênita"
  },
  {
    "codigo": "P351",
    "descricao": "Infecção congênita por citomegalovirus"
  },
  {
    "codigo": "P352",
    "descricao": "Infecção congênita por vírus do herpes [simples]"
  },
  { "codigo": "P353", "descricao": "Hepatite viral congênita" },
  {
    "codigo": "P358",
    "descricao": "Outras doenças virais congênitas"
  },
  {
    "codigo": "P359",
    "descricao": "Doença viral congênita não especificada"
  },
  {
    "codigo": "P360",
    "descricao": "Septicemia do recém-nascido devida a estreptococo do grupo B"
  },
  {
    "codigo": "P361",
    "descricao": "Septicemia do recém-nascido devida a outros estreptococos e aos não especificados"
  },
  {
    "codigo": "P362",
    "descricao": "Septicemia do recém-nascido devida ao Stafilococcus aureus"
  },
  {
    "codigo": "P363",
    "descricao": "Septicemia do recém-nascido devida a outros estafilococos e aos não especificados"
  },
  {
    "codigo": "P364",
    "descricao": "Septicemia do recém-nascido devida a Escherichia coli"
  },
  {
    "codigo": "P365",
    "descricao": "Septicemia do recém-nascido devida a anaeróbios"
  },
  {
    "codigo": "P368",
    "descricao": "Outras septicemias bacterianas do recém-nascido"
  },
  {
    "codigo": "P369",
    "descricao": "Septicemia bacteriana não especificada do recém-nascido"
  },
  { "codigo": "P370", "descricao": "Tuberculose congênita" },
  { "codigo": "P371", "descricao": "Toxoplasmose congênita" },
  { "codigo": "P372", "descricao": "Listeriose neonatal (disseminada)" },
  { "codigo": "P373", "descricao": "Malária falciparum congênita" },
  { "codigo": "P374", "descricao": "Outras malárias congênitas" },
  { "codigo": "P375", "descricao": "Candidíase neonatal" },
  {
    "codigo": "P378",
    "descricao": "Outras doenças infecciosas e parasitárias congênitas especificadas"
  },
  {
    "codigo": "P379",
    "descricao": "Doença infecciosa ou parasitária congênita não especificada"
  },
  {
    "codigo": "P38",
    "descricao": "Onfalite do recém-nascido com ou sem hemorragia leve"
  },
  { "codigo": "P390", "descricao": "Mastite infecciosa neonatal" },
  { "codigo": "P391", "descricao": "Conjuntivite e dacriocistite neonatais" },
  {
    "codigo": "P392",
    "descricao": "Infecção intra-amniótica do feto não classificada em outra parte"
  },
  {
    "codigo": "P393",
    "descricao": "Infecção neonatal do trato urinário"
  },
  { "codigo": "P394", "descricao": "Infecção neonatal da pele" },
  {
    "codigo": "P398",
    "descricao": "Outras infecções especificadas próprias do período perinatal"
  },
  {
    "codigo": "P399",
    "descricao": "Infecção própria do período perinatal não especificada"
  },
  {
    "codigo": "P500",
    "descricao": "Perda sangüínea fetal devida a vasa prévia"
  },
  {
    "codigo": "P501",
    "descricao": "Perda sangüínea fetal por ruptura do cordão"
  },
  {
    "codigo": "P502",
    "descricao": "Perda sangüínea fetal originada da placenta"
  },
  {
    "codigo": "P503",
    "descricao": "Hemorragia para a circulação do outro gêmeo"
  },
  {
    "codigo": "P504",
    "descricao": "Hemorragia para a circulação materna"
  },
  {
    "codigo": "P505",
    "descricao": "Perda sangüínea devido a secção do cordão umbilical do outro gemelar"
  },
  {
    "codigo": "P508",
    "descricao": "Outras perdas sangüíneas fetais"
  },
  {
    "codigo": "P509",
    "descricao": "Perda sangüínea fetal não especificada"
  },
  {
    "codigo": "P510",
    "descricao": "Hemorragia umbilical maciça do recém-nascido"
  },
  {
    "codigo": "P518",
    "descricao": "Outras hemorragias umbilicais do recém-nascido"
  },
  {
    "codigo": "P519",
    "descricao": "Hemorragia umbilical do recém-nascido, não especificada"
  },
  {
    "codigo": "P520",
    "descricao": "Hemorragia intraventricular (não-traumática) grau 1, do feto e do recém-nascido"
  },
  {
    "codigo": "P521",
    "descricao": "Hemorragia intraventricular (não-traumática) grau 2, do feto e do recém-nascido"
  },
  {
    "codigo": "P522",
    "descricao": "Hemorragia intraventricular (não-traumática) grau 3, do feto e do recém-nascido"
  },
  {
    "codigo": "P523",
    "descricao": "Hemorragia intraventricular (não-traumática) não especificada, do feto ou do recém-nascido"
  },
  {
    "codigo": "P524",
    "descricao": "Hemorragia intracerebral (não-traumática) do feto e do recém-nascido"
  },
  {
    "codigo": "P525",
    "descricao": "Hemorragia subaracnoídea (não-traumática) do feto e do recém-nascido"
  },
  {
    "codigo": "P526",
    "descricao": "Hemorragia cerebelar (não-traumática) e da fossa posterior do feto ou recém-nascido"
  },
  {
    "codigo": "P528",
    "descricao": "Outras hemorragias intracranianas (não-traumáticas) do feto e do recém-nascido"
  },
  {
    "codigo": "P529",
    "descricao": "Hemorragia intracraniana (não-traumática) do feto e do recém-nascido, não especificada"
  },
  {
    "codigo": "P53",
    "descricao": "Doença hemorrágica do feto e do recém-nascido"
  },
  { "codigo": "P540", "descricao": "Hematêmese neonatal" },
  { "codigo": "P541", "descricao": "Melena neonatal" },
  { "codigo": "P542", "descricao": "Hemorragia retal neonatal" },
  {
    "codigo": "P543",
    "descricao": "Outras hemorragias gastrointestinais do período neonatal"
  },
  { "codigo": "P544", "descricao": "Hemorragia supra-renal neonatal" },
  { "codigo": "P545", "descricao": "Hemorragia cutânea neonatal" },
  { "codigo": "P546", "descricao": "Hemorragia vaginal neonatal" },
  {
    "codigo": "P548",
    "descricao": "Outras hemorragias fetais e neonatais especificadas"
  },
  {
    "codigo": "P549",
    "descricao": "Hemorragia fetal e neonatal não especificada"
  },
  {
    "codigo": "P550",
    "descricao": "Isoimunização Rh do feto e do recém-nascido"
  },
  {
    "codigo": "P551",
    "descricao": "Isoimunização ABO do feto e do recém-nascido"
  },
  {
    "codigo": "P558",
    "descricao": "Outras doenças hemolíticas do feto e do recém-nascido"
  },
  {
    "codigo": "P559",
    "descricao": "Doença hemolítica não especificada do feto e do recém-nascido"
  },
  {
    "codigo": "P560",
    "descricao": "Hidropsia fetal devida à isoimunização"
  },
  {
    "codigo": "P569",
    "descricao": "Hidropsia fetal devida a outras doenças hemolíticas e às não especificadas"
  },
  {
    "codigo": "P570",
    "descricao": "Kernicterus devido à isoimunização"
  },
  { "codigo": "P578", "descricao": "Outros kernicterus especificados" },
  { "codigo": "P579", "descricao": "Kernicterus não especificado" },
  {
    "codigo": "P580",
    "descricao": "Icterícia neonatal devida a contusões"
  },
  {
    "codigo": "P581",
    "descricao": "Icterícia neonatal devida a sangramento"
  },
  {
    "codigo": "P582",
    "descricao": "Icterícia neonatal devida a infecção"
  },
  {
    "codigo": "P583",
    "descricao": "Icterícia neonatal devida a policitemia"
  },
  {
    "codigo": "P584",
    "descricao": "Icterícia neonatal devida a drogas ou toxinas transmitidas pela mãe ou administradas ao recém-nascido"
  },
  {
    "codigo": "P585",
    "descricao": "Icterícia neonatal devida à deglutação de sangue materno"
  },
  {
    "codigo": "P588",
    "descricao": "Icterícia neonatal devida a outras hemólises excessivas especificadas"
  },
  {
    "codigo": "P589",
    "descricao": "Icterícia neonatal devida a hemólise excessiva não especificada"
  },
  {
    "codigo": "P590",
    "descricao": "Icterícia neonatal associada ao parto prematuro"
  },
  { "codigo": "P591", "descricao": "Síndrome da bile espessa" },
  {
    "codigo": "P592",
    "descricao": "Icterícia neonatal de outras lesões hepatocelulares e das não especificadas"
  },
  {
    "codigo": "P593",
    "descricao": "Icterícia neonatal devida a inibidores do leite materno"
  },
  {
    "codigo": "P598",
    "descricao": "Icterícia neonatal devida a outras causas especificadas"
  },
  {
    "codigo": "P599",
    "descricao": "Icterícia neonatal não especificada"
  },
  {
    "codigo": "P60",
    "descricao": "Coagulação intravascular disseminada do feto e do recém-nascido"
  },
  {
    "codigo": "P610",
    "descricao": "Trombocitopenia transitória neonatal"
  },
  { "codigo": "P611", "descricao": "Policitemia neonatal" },
  { "codigo": "P612", "descricao": "Anemia da prematuridade" },
  {
    "codigo": "P613",
    "descricao": "Anemia congênita por perda sangüínea fetal"
  },
  {
    "codigo": "P614",
    "descricao": "Outras anemias congênitas não classificada em outra parte"
  },
  { "codigo": "P615", "descricao": "Neutropenia transitória neonatal" },
  {
    "codigo": "P616",
    "descricao": "Outros transtornos transitórios de coagulação do período neonatal"
  },
  {
    "codigo": "P618",
    "descricao": "Outros transtornos hematológicos perinatais especificados"
  },
  {
    "codigo": "P619",
    "descricao": "Transtorno hematológico perinatal não especificado"
  },
  {
    "codigo": "P700",
    "descricao": "Síndrome do filho de mãe com diabetes gestacional"
  },
  {
    "codigo": "P701",
    "descricao": "Síndrome do filho de mãe diabética"
  },
  { "codigo": "P702", "descricao": "Diabetes mellitus neonatal" },
  { "codigo": "P703", "descricao": "Hipoglicemia neonatal iatrogênica" },
  { "codigo": "P704", "descricao": "Outras hipoglicemias neonatais" },
  {
    "codigo": "P708",
    "descricao": "Outros transtornos transitórios do metabolismo dos carboidratos do feto e do recém-nascido"
  },
  {
    "codigo": "P709",
    "descricao": "Transtorno transitório não especificado do metabolismo dos carboidratos do feto e do recém-nascido"
  },
  {
    "codigo": "P710",
    "descricao": "Hipocalcemia do recém-nascido pelo uso de leite de vaca"
  },
  { "codigo": "P711", "descricao": "Outras hipocalcemias neonatais" },
  { "codigo": "P712", "descricao": "Hipomagnesemia neonatal" },
  {
    "codigo": "P713",
    "descricao": "Tetania neonatal sem deficiência de cálcio ou magnésio"
  },
  {
    "codigo": "P714",
    "descricao": "Hipoparatiroidismo neonatal transitório"
  },
  {
    "codigo": "P718",
    "descricao": "Outros transtornos transitórios do metabolismo do cálcio e do magnésio do período neonatal"
  },
  {
    "codigo": "P719",
    "descricao": "Transtornos transitórios não especificados do metabolismo do cálcio e do magnésio do período neonatal"
  },
  {
    "codigo": "P720",
    "descricao": "Bócio neonatal, não classificado em outra parte"
  },
  {
    "codigo": "P721",
    "descricao": "Hipotireoidismo neonatal transitório"
  },
  {
    "codigo": "P722",
    "descricao": "Outros transtornos transitórios da função de tireóide não classificados em outra parte"
  },
  {
    "codigo": "P728",
    "descricao": "Outros transtornos endócrinos transitórios e especificados do período neonatal"
  },
  {
    "codigo": "P729",
    "descricao": "Transtorno endócrino neonatal transitório não especificado"
  },
  {
    "codigo": "P740",
    "descricao": "Acidose metabólica tardia do recém-nascido"
  },
  {
    "codigo": "P741",
    "descricao": "Desidratação do recém-nascido"
  },
  {
    "codigo": "P742",
    "descricao": "Distúrbios do equilíbrio de sódio do recém-nascido"
  },
  {
    "codigo": "P743",
    "descricao": "Distúrbios do equilíbrio de potássio do recém-nascido"
  },
  {
    "codigo": "P744",
    "descricao": "Outros distúrbios eletrolíticos transitórios do recém-nascido"
  },
  {
    "codigo": "P745",
    "descricao": "Tirosinemia transitória do recém-nascido"
  },
  {
    "codigo": "P748",
    "descricao": "Outros distúrbios metabólicos transitórios do recém-nascido"
  },
  {
    "codigo": "P749",
    "descricao": "Distúrbio metabólico transitório não especificado do recém-nascido"
  },
  { "codigo": "P75", "descricao": "íleo meconial" },
  {
    "codigo": "P760",
    "descricao": "Síndrome da rolha de mecônio"
  },
  {
    "codigo": "P761",
    "descricao": "íleo transitório do recém-nascido"
  },
  {
    "codigo": "P762",
    "descricao": "Obstrução intestinal devido a leite espesso"
  },
  {
    "codigo": "P768",
    "descricao": "Outras obstruções intestinais especificadas do recém-nascido"
  },
  {
    "codigo": "P769",
    "descricao": "Obstrução intestinal não especificada do recém-nascido"
  },
  {
    "codigo": "P77",
    "descricao": "Enterocolite necrotizante do feto e do recém-nascido"
  },
  {
    "codigo": "P780",
    "descricao": "Perfuração intestinal no período perinatal"
  },
  { "codigo": "P781", "descricao": "Outras peritonites neonatais" },
  {
    "codigo": "P782",
    "descricao": "Hematemese e melena devidas a deglutição de sangue materno"
  },
  {
    "codigo": "P783",
    "descricao": "Diarréia neonatal não-infecciosa"
  },
  {
    "codigo": "P788",
    "descricao": "Outros transtornos perinatais especificados do aparelho digestivo"
  },
  {
    "codigo": "P789",
    "descricao": "Transtorno perinatal não especificado do aparelho digestivo"
  },
  { "codigo": "P800", "descricao": "Síndrome de lesão pelo frio" },
  { "codigo": "P808", "descricao": "Outras hipotermias do recém-nascido" },
  {
    "codigo": "P809",
    "descricao": "Hipotermia não especificada do recém-nascido"
  },
  {
    "codigo": "P810",
    "descricao": "Hipertermia ambiental do recém-nascido"
  },
  {
    "codigo": "P818",
    "descricao": "Outros distúrbios especificados da regulação térmica do recém-nascido"
  },
  {
    "codigo": "P819",
    "descricao": "Distúrbio não especificado da regulação térmica do recém-nascido"
  },
  { "codigo": "P830", "descricao": "Esclerema neonatal" },
  { "codigo": "P831", "descricao": "Eritema tóxico neonatal" },
  {
    "codigo": "P832",
    "descricao": "Hidropsia fetal não-devida à doença hemolítica"
  },
  {
    "codigo": "P833",
    "descricao": "Outros edemas e os não especificados próprios do feto e do recém-nascido"
  },
  {
    "codigo": "P834",
    "descricao": "Intumescimento mamário do recém-nascido"
  },
  { "codigo": "P835", "descricao": "Hidrocele congênita" },
  {
    "codigo": "P836",
    "descricao": "Pólipo umbilical do recém-nascido"
  },
  {
    "codigo": "P838",
    "descricao": "Outras afecções especificadas do tegumento próprias do feto e do recém-nascido"
  },
  {
    "codigo": "P839",
    "descricao": "Afecção não especificada do tegumento própria do feto e do recém-nascido"
  },
  { "codigo": "P90", "descricao": "Convulsões do recém-nascido" },
  { "codigo": "P910", "descricao": "Isquemia cerebral neonatal" },
  {
    "codigo": "P911",
    "descricao": "Cistos periventriculares adquiridos do recém-nascido"
  },
  { "codigo": "P912", "descricao": "Leucomalácia cerebral neonatal" },
  { "codigo": "P913", "descricao": "Irritabilidade cerebral neonatal" },
  { "codigo": "P914", "descricao": "Depressão cerebral neonatal" },
  { "codigo": "P915", "descricao": "Coma neonatal" },
  {
    "codigo": "P916",
    "descricao": "Encefalopatia hipóxico-isquêmica do recém-nascido"
  },
  {
    "codigo": "P918",
    "descricao": "Outros distúrbios especificados da função cerebral do recém-nascido"
  },
  {
    "codigo": "P919",
    "descricao": "Distúrbio não especificado da função cerebral do recém-nascido"
  },
  { "codigo": "P920", "descricao": "Vômitos no recém-nascido" },
  {
    "codigo": "P921",
    "descricao": "Regurgitação e ruminação no recém-nascido"
  },
  {
    "codigo": "P922",
    "descricao": "Alimentação vagarosa do recém-nascido"
  },
  {
    "codigo": "P923",
    "descricao": "Subalimentação do recém-nascido"
  },
  {
    "codigo": "P924",
    "descricao": "Hiperalimentação do recém-nascido"
  },
  {
    "codigo": "P925",
    "descricao": "Dificuldade neonatal na amamentação no peito"
  },
  {
    "codigo": "P928",
    "descricao": "Outros problemas de alimentação do recém-nascido"
  },
  {
    "codigo": "P929",
    "descricao": "Problema não especificado de alimentação do recém-nascido"
  },
  {
    "codigo": "P93",
    "descricao": "Reações e intoxicações devidas a drogas administradas ao feto e ao recém-nascido"
  },
  {
    "codigo": "P940",
    "descricao": "Miastenia grave neonatal transitória"
  },
  { "codigo": "P941", "descricao": "Hipertonia congênita" },
  { "codigo": "P942", "descricao": "Hipotonia congênita" },
  {
    "codigo": "P948",
    "descricao": "Outros transtornos do tônus muscular do recém-nascido"
  },
  {
    "codigo": "P949",
    "descricao": "Transtorno não especificado do tônus muscular do recém-nascido"
  },
  {
    "codigo": "P95",
    "descricao": "Morte fetal de causa não especificada"
  },
  { "codigo": "P960", "descricao": "Insuficiência renal congênita" },
  {
    "codigo": "P961",
    "descricao": "Sintomas de abstinência neonatal de drogas utilizadas pela mãe"
  },
  {
    "codigo": "P962",
    "descricao": "Sintomas de abstinência do uso de drogas terapêuticas no recém-nascido"
  },
  {
    "codigo": "P963",
    "descricao": "Suturas cranianas amplas no recém-nascido"
  },
  {
    "codigo": "P964",
    "descricao": "Interrupção de gravidez, afetando o feto e recém-nascido"
  },
  {
    "codigo": "P965",
    "descricao": "Complicações de procedimentos intra-uterinos não classificados em outra parte"
  },
  {
    "codigo": "P968",
    "descricao": "Outras afecções especificadas originadas no período perinatal"
  },
  {
    "codigo": "P969",
    "descricao": "Afecções originadas no período perinatal não especificadas"
  },
  { "codigo": "Q000", "descricao": "Anencefalia" },
  { "codigo": "Q001", "descricao": "Craniorraquisquise" },
  { "codigo": "Q002", "descricao": "Iniencefalia" },
  { "codigo": "Q010", "descricao": "Encefalocele frontal" },
  { "codigo": "Q011", "descricao": "Encefalocele nasofrontal" },
  { "codigo": "Q012", "descricao": "Encefalocele occipital" },
  {
    "codigo": "Q018",
    "descricao": "Encefalocele de outras localizações"
  },
  { "codigo": "Q019", "descricao": "Encefalocele não especificada" },
  { "codigo": "Q02", "descricao": "Microcefalia" },
  {
    "codigo": "Q030",
    "descricao": "Malformações do aqueduto de Sylvius"
  },
  {
    "codigo": "Q031",
    "descricao": "Atresia das fendas de Luschka e do forâmen de Magendie"
  },
  { "codigo": "Q038", "descricao": "Outra hidrocefalia congênita" },
  {
    "codigo": "Q039",
    "descricao": "Hidrocefalia congênita não especificada"
  },
  {
    "codigo": "Q040",
    "descricao": "Malformações congênitas do corpo caloso"
  },
  { "codigo": "Q041", "descricao": "Arrinencefalia" },
  { "codigo": "Q042", "descricao": "Holoprosencefalia" },
  {
    "codigo": "Q043",
    "descricao": "Outras deformidades por redução do encéfalo"
  },
  {
    "codigo": "Q044",
    "descricao": "Displasia do septo e das vias ópticas"
  },
  { "codigo": "Q045", "descricao": "Megalencefalia" },
  { "codigo": "Q046", "descricao": "Cistos cerebrais congênitos" },
  {
    "codigo": "Q048",
    "descricao": "Outras malformações congênitas especificadas do encéfalo"
  },
  {
    "codigo": "Q049",
    "descricao": "Malformação congênita não especificada do encéfalo"
  },
  {
    "codigo": "Q050",
    "descricao": "Espinha bífida cervical com hidrocefalia"
  },
  {
    "codigo": "Q051",
    "descricao": "Espinha bífida torácica com hidrocefalia"
  },
  {
    "codigo": "Q052",
    "descricao": "Espinha bífida lombar com hidrocefalia"
  },
  {
    "codigo": "Q053",
    "descricao": "Espinha bífida sacra com hidrocefalia"
  },
  {
    "codigo": "Q054",
    "descricao": "Espinha bífida não especificada, com hidrocefalia"
  },
  {
    "codigo": "Q055",
    "descricao": "Espinha bífida cervical, sem hidrocefalia"
  },
  {
    "codigo": "Q056",
    "descricao": "Espinha bífida torácica, sem hidrocefalia"
  },
  {
    "codigo": "Q057",
    "descricao": "Espinha bífida lombar, sem hidrocefalia"
  },
  {
    "codigo": "Q058",
    "descricao": "Espinha bífida sacra, sem hidrocefalia"
  },
  {
    "codigo": "Q059",
    "descricao": "Espinha bífida não especificada"
  },
  { "codigo": "Q060", "descricao": "Amielia" },
  { "codigo": "Q061", "descricao": "Hipoplasia e displasia da medula espinal" },
  { "codigo": "Q062", "descricao": "Diastematomielia" },
  {
    "codigo": "Q063",
    "descricao": "Outras malformações congênitas da cauda eqüina"
  },
  { "codigo": "Q064", "descricao": "Hidromielia" },
  {
    "codigo": "Q068",
    "descricao": "Outras malformações congênitas especificadas da medula espinal"
  },
  {
    "codigo": "Q069",
    "descricao": "Malformação congênita não especificada da medula espinal"
  },
  { "codigo": "Q070", "descricao": "Síndrome de Arnold-Chiari" },
  {
    "codigo": "Q078",
    "descricao": "Outras malformações congênitas especificadas do sistema nervoso"
  },
  {
    "codigo": "Q079",
    "descricao": "Malformação congênita não especificada do sistema nervoso"
  },
  { "codigo": "Q100", "descricao": "Ptose congênita" },
  { "codigo": "Q101", "descricao": "Ectrópio congênito" },
  { "codigo": "Q102", "descricao": "Entrópio congênito" },
  {
    "codigo": "Q103",
    "descricao": "Outras malformações congênitas das pálpebras"
  },
  {
    "codigo": "Q104",
    "descricao": "Ausência ou agenesia do aparelho lacrimal"
  },
  {
    "codigo": "Q105",
    "descricao": "Estenose ou estreitamento congênito do canal lacrimal"
  },
  {
    "codigo": "Q106",
    "descricao": "Outras malformações congênitas do aparelho lacrimal"
  },
  {
    "codigo": "Q107",
    "descricao": "Malformação congênita da órbita"
  },
  { "codigo": "Q110", "descricao": "Olho cístico" },
  { "codigo": "Q111", "descricao": "Outras formas de anoftalmia" },
  { "codigo": "Q112", "descricao": "Microftalmia" },
  { "codigo": "Q113", "descricao": "Macroftalmia" },
  { "codigo": "Q120", "descricao": "Catarata congênita" },
  {
    "codigo": "Q121",
    "descricao": "Luxação congênita do cristalino"
  },
  { "codigo": "Q122", "descricao": "Coloboma do cristalino" },
  { "codigo": "Q123", "descricao": "Afaquia congênita" },
  { "codigo": "Q124", "descricao": "Esferofaquia" },
  {
    "codigo": "Q128",
    "descricao": "Outras malformações congênitas do cristalino"
  },
  {
    "codigo": "Q129",
    "descricao": "Malformação congênita não especificada do cristalino"
  },
  { "codigo": "Q130", "descricao": "Coloboma da íris" },
  { "codigo": "Q131", "descricao": "Ausência de íris" },
  {
    "codigo": "Q132",
    "descricao": "Outras malformações congênitas da íris"
  },
  { "codigo": "Q133", "descricao": "Opacidade congênita da córnea" },
  {
    "codigo": "Q134",
    "descricao": "Outras malformações congênitas da córnea"
  },
  { "codigo": "Q135", "descricao": "Esclerótica azul" },
  {
    "codigo": "Q138",
    "descricao": "Outras malformações congênitas da câmara anterior do olho"
  },
  {
    "codigo": "Q139",
    "descricao": "Malformação congênita não especificada da câmara anterior do olho"
  },
  {
    "codigo": "Q140",
    "descricao": "Malformação congênita do humor vítreo"
  },
  {
    "codigo": "Q141",
    "descricao": "Malformação congênita da retina"
  },
  {
    "codigo": "Q142",
    "descricao": "Malformação congênita do disco óptico"
  },
  {
    "codigo": "Q143",
    "descricao": "Malformação congênita da coróide"
  },
  {
    "codigo": "Q148",
    "descricao": "Outras malformações congênitas da câmara posterior do olho"
  },
  {
    "codigo": "Q149",
    "descricao": "Malformação congênita não especificada da câmara posterior do olho"
  },
  { "codigo": "Q150", "descricao": "Glaucoma congênito" },
  {
    "codigo": "Q158",
    "descricao": "Outras malformações congênitas especificadas do olho"
  },
  {
    "codigo": "Q159",
    "descricao": "Malformação congênita não especificada do olho"
  },
  {
    "codigo": "Q160",
    "descricao": "Ausência congênita do pavilhão auricular [orelha]"
  },
  {
    "codigo": "Q161",
    "descricao": "Ausência, atresia e estreitamento congênitos do conduto auditivo (externo)"
  },
  {
    "codigo": "Q162",
    "descricao": "Ausência da trompa de Eustáquio"
  },
  {
    "codigo": "Q163",
    "descricao": "Malformação congênita dos ossículos do ouvido"
  },
  {
    "codigo": "Q164",
    "descricao": "Outras malformações congênitas do ouvido médio"
  },
  {
    "codigo": "Q165",
    "descricao": "Malformação congênita do ouvido interno"
  },
  {
    "codigo": "Q169",
    "descricao": "Malformação congênita do ouvido não especificada causando comprometimento da audição"
  },
  { "codigo": "Q170", "descricao": "Pavilhão supranumerário" },
  { "codigo": "Q171", "descricao": "Macrotia" },
  { "codigo": "Q172", "descricao": "Microtia" },
  { "codigo": "Q173", "descricao": "Outras deformidades da orelha" },
  { "codigo": "Q174", "descricao": "Anomalia de posição da orelha" },
  { "codigo": "Q175", "descricao": "Orelhas proeminentes" },
  {
    "codigo": "Q178",
    "descricao": "Outras malformações congênitas especificadas da orelha"
  },
  {
    "codigo": "Q179",
    "descricao": "Malformação congênita não especificada da orelha"
  },
  {
    "codigo": "Q180",
    "descricao": "Seio, fístula e cisto de origem branquial"
  },
  {
    "codigo": "Q181",
    "descricao": "Seio, fístula e cisto pré-auricular"
  },
  {
    "codigo": "Q182",
    "descricao": "Outras malformações da fenda branquial"
  },
  { "codigo": "Q183", "descricao": "Pescoço alado" },
  { "codigo": "Q184", "descricao": "Macrostomia" },
  { "codigo": "Q185", "descricao": "Microstomia" },
  { "codigo": "Q186", "descricao": "Macroqueilia" },
  { "codigo": "Q187", "descricao": "Microqueilia" },
  {
    "codigo": "Q188",
    "descricao": "Outras malformações congênitas especificadas da face e do pescoço"
  },
  {
    "codigo": "Q189",
    "descricao": "Malformação congênita não especificada da face e do pescoço"
  },
  { "codigo": "Q200", "descricao": "Tronco arterial comum" },
  {
    "codigo": "Q201",
    "descricao": "Ventrículo direito com dupla via de saída"
  },
  {
    "codigo": "Q202",
    "descricao": "Ventrículo esquerdo com dupla via de saída"
  },
  {
    "codigo": "Q203",
    "descricao": "Comunicação ventrículo-atrial discordante"
  },
  { "codigo": "Q204", "descricao": "Ventrículo com dupla via de entrada" },
  {
    "codigo": "Q205",
    "descricao": "Comunicação átrio-ventricular discordante"
  },
  { "codigo": "Q206", "descricao": "Isomerismo dos apêndices atriais" },
  {
    "codigo": "Q208",
    "descricao": "Outras malformações congênitas das câmaras e das comunicações cardíacas"
  },
  {
    "codigo": "Q209",
    "descricao": "Malformação congênita não especificada das câmaras e das comunicações cardíacas"
  },
  { "codigo": "Q210", "descricao": "Comunicação interventricular" },
  { "codigo": "Q211", "descricao": "Comunicação interatrial" },
  { "codigo": "Q212", "descricao": "Comunicação atrioventricular" },
  { "codigo": "Q213", "descricao": "Tetralogia de Fallot" },
  { "codigo": "Q214", "descricao": "Comunicação aortopulmonar" },
  {
    "codigo": "Q218",
    "descricao": "Outras malformações congênitas dos septos cardíacos"
  },
  {
    "codigo": "Q219",
    "descricao": "Malformação congênita não especificada de septo cardíaco"
  },
  { "codigo": "Q220", "descricao": "Atresia da valva pulmonar" },
  {
    "codigo": "Q221",
    "descricao": "Estenose congênita da valva pulmonar"
  },
  {
    "codigo": "Q222",
    "descricao": "Insuficiência congênita da valva pulmonar"
  },
  {
    "codigo": "Q223",
    "descricao": "Outras malformações congênitas da valva pulmonar"
  },
  {
    "codigo": "Q224",
    "descricao": "Estenose congênita da valva tricúspide"
  },
  { "codigo": "Q225", "descricao": "Anomalia de Ebstein" },
  {
    "codigo": "Q226",
    "descricao": "Síndrome do coração direito hipoplásico"
  },
  {
    "codigo": "Q228",
    "descricao": "Outras malformações congênitas da valva tricúspide"
  },
  {
    "codigo": "Q229",
    "descricao": "Malformação congênita não especificada da valva tricúspide"
  },
  {
    "codigo": "Q230",
    "descricao": "Estenose congênita da valva aórtica"
  },
  {
    "codigo": "Q231",
    "descricao": "Insuficiência congênita da valva aórtica"
  },
  { "codigo": "Q232", "descricao": "Estenose mitral congênita" },
  { "codigo": "Q233", "descricao": "Insuficiência mitral congênita" },
  {
    "codigo": "Q234",
    "descricao": "Síndrome do coração esquerdo hipoplásico"
  },
  {
    "codigo": "Q238",
    "descricao": "Outras malformações congênitas das valvas aórtica e mitral"
  },
  {
    "codigo": "Q239",
    "descricao": "Malformação congênita não especificada das valvas aórtica e mitral"
  },
  { "codigo": "Q240", "descricao": "Dextrocardia" },
  { "codigo": "Q241", "descricao": "Levocardia" },
  { "codigo": "Q242", "descricao": "Cor triatriatum" },
  { "codigo": "Q243", "descricao": "Estenose do infundíbulo pulmonar" },
  { "codigo": "Q244", "descricao": "Estenose subaórtica congênita" },
  {
    "codigo": "Q245",
    "descricao": "Malformações dos vasos coronários"
  },
  {
    "codigo": "Q246",
    "descricao": "Bloqueio congênito do coração"
  },
  {
    "codigo": "Q248",
    "descricao": "Outras malformações congênitas especificadas do coração"
  },
  {
    "codigo": "Q249",
    "descricao": "Malformação não especificada do coração"
  },
  { "codigo": "Q250", "descricao": "Permeabilidade do canal arterial" },
  { "codigo": "Q251", "descricao": "Coartação da aorta" },
  { "codigo": "Q252", "descricao": "Atresia da aorta" },
  { "codigo": "Q253", "descricao": "Estenose da aorta" },
  {
    "codigo": "Q254",
    "descricao": "Outras malformações congênitas da aorta"
  },
  { "codigo": "Q255", "descricao": "Atresia da artéria pulmonar" },
  { "codigo": "Q256", "descricao": "Estenose da artéria pulmonar" },
  {
    "codigo": "Q257",
    "descricao": "Outras malformações congênitas da artéria pulmonar"
  },
  {
    "codigo": "Q258",
    "descricao": "Outras malformações congênitas das grandes artérias"
  },
  {
    "codigo": "Q259",
    "descricao": "Malformação congênita não especificada das grandes artérias"
  },
  { "codigo": "Q260", "descricao": "Estenose congênita da veia cava" },
  {
    "codigo": "Q261",
    "descricao": "Persistência da veia cava superior esquerda"
  },
  {
    "codigo": "Q262",
    "descricao": "Comunicação venosa pulmonar anormal total"
  },
  {
    "codigo": "Q263",
    "descricao": "Comunicação venosa pulmonar anormal parcial"
  },
  {
    "codigo": "Q264",
    "descricao": "Comunicação venosa pulmonar anormal não especificado"
  },
  {
    "codigo": "Q265",
    "descricao": "Comunicação venosa portal anormal"
  },
  {
    "codigo": "Q266",
    "descricao": "Fístula entre a veia porta e a artéria hepática"
  },
  {
    "codigo": "Q268",
    "descricao": "Outras malformações congênitas das grandes veias"
  },
  {
    "codigo": "Q269",
    "descricao": "Malformação congênita não especificada de grande veia"
  },
  {
    "codigo": "Q270",
    "descricao": "Ausência congênita e hipoplasia da artéria umbilical"
  },
  {
    "codigo": "Q271",
    "descricao": "Estenose congênita da artéria renal"
  },
  {
    "codigo": "Q272",
    "descricao": "Outras malformações congênitas da artéria renal"
  },
  {
    "codigo": "Q273",
    "descricao": "Malformação artério-venosa periférica"
  },
  {
    "codigo": "Q274",
    "descricao": "Ectasia venosa (flebectasia) congênita"
  },
  {
    "codigo": "Q278",
    "descricao": "Outras malformações congênitas especificadas do sistema vascular periférico"
  },
  {
    "codigo": "Q279",
    "descricao": "Malformação congênita não especificada do sistema vascular periférico"
  },
  {
    "codigo": "Q280",
    "descricao": "Malformação arteriovenosa de vasos pré-cerebrais"
  },
  {
    "codigo": "Q281",
    "descricao": "Outras malformações dos vasos pré-cerebrais"
  },
  {
    "codigo": "Q282",
    "descricao": "Malformação arteriovenosa dos vasos cerebrais"
  },
  {
    "codigo": "Q283",
    "descricao": "Outras malformações dos vasos cerebrais"
  },
  {
    "codigo": "Q288",
    "descricao": "Outras malformações congênitas especificadas do aparelho circulatório"
  },
  {
    "codigo": "Q289",
    "descricao": "Malformação congênita não especificada do aparelho circulatório"
  },
  { "codigo": "Q300", "descricao": "Atresia das coanas" },
  { "codigo": "Q301", "descricao": "Agenesia ou hipoplasia do nariz" },
  { "codigo": "Q302", "descricao": "Fissura, entalhe ou fenda nasal" },
  {
    "codigo": "Q303",
    "descricao": "Perfuração congênita do septo nasal"
  },
  {
    "codigo": "Q308",
    "descricao": "Outras malformações congênitas do nariz"
  },
  {
    "codigo": "Q309",
    "descricao": "Malformação congênita não especificada do nariz"
  },
  { "codigo": "Q310", "descricao": "Pterígio da laringe" },
  { "codigo": "Q311", "descricao": "Estenose subglótica congênita" },
  { "codigo": "Q312", "descricao": "Hipoplasia da laringe" },
  { "codigo": "Q313", "descricao": "Laringocele" },
  { "codigo": "Q315", "descricao": "Laringomalácia congênita" },
  {
    "codigo": "Q318",
    "descricao": "Outras malformações congênitas da laringe"
  },
  {
    "codigo": "Q319",
    "descricao": "Malformação congênita não especificada da laringe"
  },
  { "codigo": "Q320", "descricao": "Traqueomalácia congênita" },
  {
    "codigo": "Q321",
    "descricao": "Outras malformações congênitas da traquéia"
  },
  { "codigo": "Q322", "descricao": "Broncomalácia congênita" },
  {
    "codigo": "Q323",
    "descricao": "Estenose congênita dos brônquios"
  },
  {
    "codigo": "Q324",
    "descricao": "Outras malformações congênitas dos brônquios"
  },
  { "codigo": "Q330", "descricao": "Pulmão cístico congênito" },
  { "codigo": "Q331", "descricao": "Lobo pulmonar supranumerário" },
  { "codigo": "Q332", "descricao": "Seqüestro pulmonar" },
  { "codigo": "Q333", "descricao": "Agenesia do pulmão" },
  { "codigo": "Q334", "descricao": "Bronquectasia congênita" },
  { "codigo": "Q335", "descricao": "Tecido ectópico intrapulmonar" },
  { "codigo": "Q336", "descricao": "Hipoplasia e displasia do pulmão" },
  {
    "codigo": "Q338",
    "descricao": "Outras malformações congênitas do pulmão"
  },
  {
    "codigo": "Q339",
    "descricao": "Malformação congênita não especificada do pulmão"
  },
  { "codigo": "Q340", "descricao": "Anomalia da pleura" },
  { "codigo": "Q341", "descricao": "Cisto congênito do mediastino" },
  {
    "codigo": "Q348",
    "descricao": "Outras malformações congênitas especificadas do aparelho respiratório"
  },
  {
    "codigo": "Q349",
    "descricao": "Malformação congênita não especificada do aparelho respiratório"
  },
  { "codigo": "Q351", "descricao": "Fenda do palato duro" },
  { "codigo": "Q353", "descricao": "Fenda do palato mole" },
  {
    "codigo": "Q355",
    "descricao": "Fenda do palato duro com fenda do palato mole"
  },
  { "codigo": "Q357", "descricao": "Fenda da úvula" },
  { "codigo": "Q359", "descricao": "Fenda palatina não especificada" },
  { "codigo": "Q360", "descricao": "Fenda labial bilateral" },
  { "codigo": "Q361", "descricao": "Fenda labial mediana" },
  { "codigo": "Q369", "descricao": "Fenda labial unilateral" },
  {
    "codigo": "Q370",
    "descricao": "Fenda do palato duro com fenda labial bilateral"
  },
  {
    "codigo": "Q371",
    "descricao": "Fenda do palato duro com fenda labial unilateral"
  },
  {
    "codigo": "Q372",
    "descricao": "Fenda do palato mole com fenda labial bilateral"
  },
  {
    "codigo": "Q373",
    "descricao": "Fenda do palato mole com fenda labial unilateral"
  },
  {
    "codigo": "Q374",
    "descricao": "Fenda dos palatos duro e mole com fenda labial bilateral"
  },
  {
    "codigo": "Q375",
    "descricao": "Fenda dos palatos duro e mole com fenda labial unilateral"
  },
  {
    "codigo": "Q378",
    "descricao": "Fenda do palato com fenda labial bilateral, não especificada"
  },
  {
    "codigo": "Q379",
    "descricao": "Fenda do palato com fenda labial unilateral, não especificada"
  },
  {
    "codigo": "Q380",
    "descricao": "Malformações congênitas dos lábios, não classificadas em outra parte"
  },
  { "codigo": "Q381", "descricao": "Anquiloglossia" },
  { "codigo": "Q382", "descricao": "Macroglossia" },
  {
    "codigo": "Q383",
    "descricao": "Outras malformações congênitas da língua"
  },
  {
    "codigo": "Q384",
    "descricao": "Malformações congênitas das glândulas e dutos salivares"
  },
  {
    "codigo": "Q385",
    "descricao": "Malformações congênitas do palato não classificadas em outra parte"
  },
  {
    "codigo": "Q386",
    "descricao": "Outras malformações congênitas da boca"
  },
  { "codigo": "Q387", "descricao": "Bolsa faríngea" },
  {
    "codigo": "Q388",
    "descricao": "Outras malformações congênitas da faringe"
  },
  {
    "codigo": "Q390",
    "descricao": "Atresia de esôfago, sem fístula"
  },
  {
    "codigo": "Q391",
    "descricao": "Atresia de esôfago, com fístula traqueoesofágica"
  },
  {
    "codigo": "Q392",
    "descricao": "Fístula traqueoesofágica congênita, sem atresia"
  },
  {
    "codigo": "Q393",
    "descricao": "Estenose congênita e estreitamento congênito do esôfago"
  },
  { "codigo": "Q394", "descricao": "Pterígio do esôfago" },
  {
    "codigo": "Q395",
    "descricao": "Dilatação congênita do esôfago"
  },
  { "codigo": "Q396", "descricao": "Divertículo do esôfago" },
  {
    "codigo": "Q398",
    "descricao": "Outras malformações congênitas do esôfago"
  },
  {
    "codigo": "Q399",
    "descricao": "Malformação congênita não especificada do esôfago"
  },
  {
    "codigo": "Q400",
    "descricao": "Estenose hipertrófica congênita do piloro"
  },
  { "codigo": "Q401", "descricao": "Hérnia congênita de hiato" },
  {
    "codigo": "Q402",
    "descricao": "Outras malformações congênitas especificadas do estômago"
  },
  {
    "codigo": "Q403",
    "descricao": "Malformação congênita não especificada do estômago"
  },
  {
    "codigo": "Q408",
    "descricao": "Outras malformações congênitas especificadas do trato digestivo superior"
  },
  {
    "codigo": "Q409",
    "descricao": "Malformação congênita não especificada do trato digestivo superior"
  },
  {
    "codigo": "Q410",
    "descricao": "Ausência, atresia e estenose congênita do duodeno"
  },
  {
    "codigo": "Q411",
    "descricao": "Ausência, atresia e estenose congênita do jejuno"
  },
  {
    "codigo": "Q412",
    "descricao": "Ausência, atresia e estenose congênita do íleo"
  },
  {
    "codigo": "Q418",
    "descricao": "Ausência, atresia e estenose congênita de outras partes especificadas do intestino delgado"
  },
  {
    "codigo": "Q419",
    "descricao": "Ausência, atresia e estenose congênita do intestino delgado, sem especificação de localização"
  },
  {
    "codigo": "Q420",
    "descricao": "Ausência, atresia e estenose congênita do reto, com fístula"
  },
  {
    "codigo": "Q421",
    "descricao": "Ausência, atresia e estenose congênita do reto, sem fístula"
  },
  {
    "codigo": "Q422",
    "descricao": "Ausência, atresia e estenose congênita do ânus, com fístula"
  },
  {
    "codigo": "Q423",
    "descricao": "Ausência, atresia e estenose congênita do ânus, sem fístula"
  },
  {
    "codigo": "Q428",
    "descricao": "Ausência, atresia e estenose congênita de outras partes do cólon (intestino grosso)"
  },
  {
    "codigo": "Q429",
    "descricao": "Ausência, atresia e estenose congênita de partes não especificadas do cólon (intestino grosso)"
  },
  { "codigo": "Q430", "descricao": "Divertículo de Meckel" },
  { "codigo": "Q431", "descricao": "Doença de Hirschsprung" },
  {
    "codigo": "Q432",
    "descricao": "Outros transtornos funcionais congênitos do cólon"
  },
  {
    "codigo": "Q433",
    "descricao": "Malformações congênitas da fixação do intestino"
  },
  { "codigo": "Q434", "descricao": "Duplicação do intestino" },
  { "codigo": "Q435", "descricao": "ânus ectópico" },
  {
    "codigo": "Q436",
    "descricao": "Fístula congênita do reto e do ânus"
  },
  { "codigo": "Q437", "descricao": "Persistência de cloaca" },
  {
    "codigo": "Q438",
    "descricao": "Outras malformações congênitas especificadas do intestino"
  },
  {
    "codigo": "Q439",
    "descricao": "Malformação congênita não especificada do intestino"
  },
  {
    "codigo": "Q440",
    "descricao": "Agenesia, aplasia e hipoplasia da vesícula biliar"
  },
  {
    "codigo": "Q441",
    "descricao": "Outras malformações congênitas da vesícula biliar"
  },
  { "codigo": "Q442", "descricao": "Atresia das vias biliares" },
  {
    "codigo": "Q443",
    "descricao": "Estenose e estreitamento congênitos das vias biliares"
  },
  { "codigo": "Q444", "descricao": "Cisto do colédoco" },
  {
    "codigo": "Q445",
    "descricao": "Outras malformações congênitas das vias biliares"
  },
  { "codigo": "Q446", "descricao": "Doença cística do fígado" },
  {
    "codigo": "Q447",
    "descricao": "Outras malformações congênitas do fígado"
  },
  {
    "codigo": "Q450",
    "descricao": "Agenesia, aplasia e hipoplasia do pâncreas"
  },
  { "codigo": "Q451", "descricao": "Pâncreas anular" },
  { "codigo": "Q452", "descricao": "Cisto pancreático congênito" },
  {
    "codigo": "Q453",
    "descricao": "Outras malformações congênitas do pâncreas e do duto pancreático"
  },
  {
    "codigo": "Q458",
    "descricao": "Outras malformações congênitas especificadas do aparelho digestivo"
  },
  {
    "codigo": "Q459",
    "descricao": "Malformação congênita não especificada do aparelho digestivo"
  },
  {
    "codigo": "Q500",
    "descricao": "Ausência congênita dos ovários"
  },
  { "codigo": "Q501", "descricao": "Cisto ovariano de desenvolvimento" },
  {
    "codigo": "Q502",
    "descricao": "Torsão congênita do ovário"
  },
  {
    "codigo": "Q503",
    "descricao": "Outras malformações congênitas do ovário"
  },
  {
    "codigo": "Q504",
    "descricao": "Cisto embrionário da trompa de Falópio"
  },
  {
    "codigo": "Q505",
    "descricao": "Cisto embrionário do ligamento largo"
  },
  {
    "codigo": "Q506",
    "descricao": "Outras malformações congênitas das trompas de Falópio e dos ligamentos largos"
  },
  { "codigo": "Q510", "descricao": "Agenesia e aplasia do útero" },
  {
    "codigo": "Q511",
    "descricao": "útero duplo com duplicação do colo uterino e da vagina"
  },
  { "codigo": "Q512", "descricao": "Outra duplicação do útero" },
  { "codigo": "Q513", "descricao": "útero bicórneo" },
  { "codigo": "Q514", "descricao": "útero unicórneo" },
  { "codigo": "Q515", "descricao": "Agenesia e aplasia do colo do útero" },
  {
    "codigo": "Q516",
    "descricao": "Cisto embrionário do colo do útero"
  },
  {
    "codigo": "Q517",
    "descricao": "Fístula congênita útero-digestiva ou útero-urinária"
  },
  {
    "codigo": "Q518",
    "descricao": "Outras malformações congênitas do útero e do colo do útero"
  },
  {
    "codigo": "Q519",
    "descricao": "Malformação congênita não especificada do útero e do colo do útero SOE"
  },
  { "codigo": "Q520", "descricao": "Ausência congênita da vagina" },
  { "codigo": "Q521", "descricao": "Duplicação da vagina" },
  { "codigo": "Q522", "descricao": "Fístula reto-vaginal congênita" },
  { "codigo": "Q523", "descricao": "Imperfuração do hímen" },
  {
    "codigo": "Q524",
    "descricao": "Outras malformações congênitas da vagina"
  },
  { "codigo": "Q525", "descricao": "Fusão dos lábios vulvares" },
  {
    "codigo": "Q526",
    "descricao": "Malformação congênita do clitóris"
  },
  {
    "codigo": "Q527",
    "descricao": "Outras malformações congênitas da vulva"
  },
  {
    "codigo": "Q528",
    "descricao": "Outras malformações congênitas especificadas dos órgãos genitais femininos"
  },
  {
    "codigo": "Q529",
    "descricao": "Malformação congênita não especificada dos órgãos genitais femininos"
  },
  { "codigo": "Q530", "descricao": "Testículo ectópico" },
  {
    "codigo": "Q531",
    "descricao": "Testículo não-descido, unilateral"
  },
  {
    "codigo": "Q532",
    "descricao": "Testículo não-descido, bilateral"
  },
  {
    "codigo": "Q539",
    "descricao": "Testículo não-descido, não especificado"
  },
  { "codigo": "Q540", "descricao": "Hipospádia balânica" },
  { "codigo": "Q541", "descricao": "Hipospádia peniana" },
  { "codigo": "Q542", "descricao": "Hipospádia penoscrotal" },
  { "codigo": "Q543", "descricao": "Hipospádia perineal" },
  { "codigo": "Q544", "descricao": "Corda venérea congênita" },
  { "codigo": "Q548", "descricao": "Outras hipospádias" },
  { "codigo": "Q549", "descricao": "Hipospádia não especificada" },
  {
    "codigo": "Q550",
    "descricao": "Ausência e aplasia do testículo"
  },
  {
    "codigo": "Q551",
    "descricao": "Hipoplasia do(s) testículo(s) e do escroto"
  },
  {
    "codigo": "Q552",
    "descricao": "Outras malformações congênitas do(s) testículo(s) e do escroto"
  },
  { "codigo": "Q553", "descricao": "Atresia do canal deferente" },
  {
    "codigo": "Q554",
    "descricao": "Outras malformações congênitas do canal deferente, do epidídimo, das vesículas seminais e da próstata"
  },
  {
    "codigo": "Q555",
    "descricao": "Ausência e aplasia congênitas do pênis"
  },
  {
    "codigo": "Q556",
    "descricao": "Outras malformações congênitas do pênis"
  },
  {
    "codigo": "Q558",
    "descricao": "Outras malformações congênitas especificadas dos órgãos genitais masculinos"
  },
  {
    "codigo": "Q559",
    "descricao": "Malformação congênita não especificada dos órgãos genitais masculinos"
  },
  {
    "codigo": "Q560",
    "descricao": "Hermafroditismo não classificado em outra parte"
  },
  {
    "codigo": "Q561",
    "descricao": "Pseudo-hermafroditismo masculino, não classificado em outra parte"
  },
  {
    "codigo": "Q562",
    "descricao": "Pseudo-hermafroditismo feminino, não classificado em outra parte"
  },
  {
    "codigo": "Q563",
    "descricao": "Pseudo-hermafroditismo não especificado"
  },
  {
    "codigo": "Q564",
    "descricao": "Sexo indeterminado, não especificado"
  },
  { "codigo": "Q600", "descricao": "Agenesia unilateral do rim" },
  { "codigo": "Q601", "descricao": "Agenesia bilateral do rim" },
  { "codigo": "Q602", "descricao": "Agenesia renal não especificada" },
  { "codigo": "Q603", "descricao": "Hipoplasia renal unilateral" },
  { "codigo": "Q604", "descricao": "Hipoplasia renal bilateral" },
  { "codigo": "Q605", "descricao": "Hipoplasia renal não especificada" },
  { "codigo": "Q606", "descricao": "Síndrome de Potter" },
  { "codigo": "Q610", "descricao": "Cisto congênito único do rim" },
  {
    "codigo": "Q611",
    "descricao": "Rim policístico, autossômico recessivo"
  },
  {
    "codigo": "Q612",
    "descricao": "Rim policístico, autossômico dominante"
  },
  {
    "codigo": "Q613",
    "descricao": "Rim policístico não especificado"
  },
  { "codigo": "Q614", "descricao": "Displasia renal" },
  { "codigo": "Q615", "descricao": "Cisto medular do rim" },
  { "codigo": "Q618", "descricao": "Outras doenças císticas do rim" },
  {
    "codigo": "Q619",
    "descricao": "Doença cística não especificada do rim"
  },
  { "codigo": "Q620", "descricao": "Hidronefrose congênita" },
  { "codigo": "Q621", "descricao": "Atresia e estenose do ureter" },
  { "codigo": "Q622", "descricao": "Megaureter congênito" },
  {
    "codigo": "Q623",
    "descricao": "Outras anomalias obstrutivas da pelve renal e do ureter"
  },
  { "codigo": "Q624", "descricao": "Agenesia do ureter" },
  { "codigo": "Q625", "descricao": "Duplicação do ureter" },
  { "codigo": "Q626", "descricao": "Má-posição do ureter" },
  {
    "codigo": "Q627",
    "descricao": "Refluxo vésico-uretero-renal congênito"
  },
  {
    "codigo": "Q628",
    "descricao": "Outras malformações congênitas do ureter"
  },
  { "codigo": "Q630", "descricao": "Rim supranumerário" },
  { "codigo": "Q631", "descricao": "Rim lobulado, fundido ou em ferradura" },
  { "codigo": "Q632", "descricao": "Rim ectópico" },
  { "codigo": "Q633", "descricao": "Rim hiperplásico e gigante" },
  {
    "codigo": "Q638",
    "descricao": "Outras malformações congênitas especificadas do rim"
  },
  {
    "codigo": "Q639",
    "descricao": "Malformação congênita não especificada do rim"
  },
  { "codigo": "Q640", "descricao": "Epispádias" },
  { "codigo": "Q641", "descricao": "Extrofia vesical" },
  {
    "codigo": "Q642",
    "descricao": "Válvulas uretrais posteriores congênitas"
  },
  {
    "codigo": "Q643",
    "descricao": "Outras formas de atresia e de estenose de uretra e do colo da bexiga"
  },
  { "codigo": "Q644", "descricao": "Malformação do úraco" },
  {
    "codigo": "Q645",
    "descricao": "Ausência congênita da bexiga e da uretra"
  },
  {
    "codigo": "Q646",
    "descricao": "Divertículo congênito da bexiga"
  },
  {
    "codigo": "Q647",
    "descricao": "Outras malformações congênitas da bexiga e da uretra"
  },
  {
    "codigo": "Q648",
    "descricao": "Outras malformações congênitas especificadas do aparelho urinário"
  },
  {
    "codigo": "Q649",
    "descricao": "Malformação congênita não especificada do aparelho urinário"
  },
  {
    "codigo": "Q650",
    "descricao": "Luxação congênita unilateral do quadril"
  },
  {
    "codigo": "Q651",
    "descricao": "Luxação congênita bilateral do quadril"
  },
  {
    "codigo": "Q652",
    "descricao": "Luxação congênita não especificada do quadril"
  },
  {
    "codigo": "Q653",
    "descricao": "Subluxação congênita unilateral do quadril"
  },
  {
    "codigo": "Q654",
    "descricao": "Subluxação congênita bilateral do quadril"
  },
  {
    "codigo": "Q655",
    "descricao": "Subluxação congênita não especificada do quadril"
  },
  { "codigo": "Q656", "descricao": "Quadril instável" },
  {
    "codigo": "Q658",
    "descricao": "Outras deformidades congênitas do quadril"
  },
  {
    "codigo": "Q659",
    "descricao": "Deformidade congênita não especificada do quadril"
  },
  { "codigo": "Q660", "descricao": "Pé torto eqüinovaro" },
  { "codigo": "Q661", "descricao": "Pé torto calcaneovaro" },
  { "codigo": "Q662", "descricao": "Metatarso varo" },
  {
    "codigo": "Q663",
    "descricao": "Outras deformidades congênitas dos pés em varo"
  },
  { "codigo": "Q664", "descricao": "Pé torto calcaneovalgo" },
  { "codigo": "Q665", "descricao": "Pé chato congênito" },
  {
    "codigo": "Q666",
    "descricao": "Outras deformidades congênitas dos pés em valgo"
  },
  { "codigo": "Q667", "descricao": "Pé cavo" },
  {
    "codigo": "Q668",
    "descricao": "Outras deformidades congênitas do pé"
  },
  {
    "codigo": "Q669",
    "descricao": "Deformidade congênita não especificada do pé"
  },
  { "codigo": "Q670", "descricao": "Assimetria facial" },
  { "codigo": "Q671", "descricao": "Deformidade facial por compressão" },
  { "codigo": "Q672", "descricao": "Dolicocefalia" },
  { "codigo": "Q673", "descricao": "Plagiocefalia" },
  {
    "codigo": "Q674",
    "descricao": "Outras deformidades congênitas do crânio, da face e da mandíbula"
  },
  {
    "codigo": "Q675",
    "descricao": "Deformidades congênitas da coluna vertebral"
  },
  { "codigo": "Q676", "descricao": "Tórax escavado" },
  { "codigo": "Q677", "descricao": "Tórax carinado" },
  {
    "codigo": "Q678",
    "descricao": "Outras deformidades congênitas do tórax"
  },
  {
    "codigo": "Q680",
    "descricao": "Deformidade congênita do músculo esternocleidomastoideu"
  },
  { "codigo": "Q681", "descricao": "Deformidade congênita da mão" },
  { "codigo": "Q682", "descricao": "Deformidade congênita do joelho" },
  {
    "codigo": "Q683",
    "descricao": "Encurvamento congênito do fêmur"
  },
  {
    "codigo": "Q684",
    "descricao": "Encurvamento congênito da tíbia e da perônio [fíbula]"
  },
  {
    "codigo": "Q685",
    "descricao": "Encurvamento congênito de ossos longos não especificados do membro inferior"
  },
  {
    "codigo": "Q688",
    "descricao": "Outras deformidades osteomusculares congênitas"
  },
  {
    "codigo": "Q690",
    "descricao": "Dedo(s) da mão supranumerário(s)"
  },
  { "codigo": "Q691", "descricao": "Polegar(es) supranumerário(s)" },
  { "codigo": "Q692", "descricao": "Artelho(s) supranumerário(s)" },
  { "codigo": "Q699", "descricao": "Polidactilia não especificada" },
  {
    "codigo": "Q700",
    "descricao": "Coalescência dos dedos (dedos da mão fundidos)"
  },
  { "codigo": "Q701", "descricao": "Dedos palmados" },
  {
    "codigo": "Q702",
    "descricao": "Coalescência dos artelhos (artelhos fundidos)"
  },
  { "codigo": "Q703", "descricao": "Artelhos palmados" },
  { "codigo": "Q704", "descricao": "Polissindactilia" },
  { "codigo": "Q709", "descricao": "Sindactilia não especificada" },
  {
    "codigo": "Q710",
    "descricao": "Ausência congênita completa do(s) membro(s) superior(es)"
  },
  {
    "codigo": "Q711",
    "descricao": "Ausência congênita do braço e do antebraço, com mão presente"
  },
  {
    "codigo": "Q712",
    "descricao": "Ausência congênita do antebraço e da mão"
  },
  {
    "codigo": "Q713",
    "descricao": "Ausência congênita da mão e de dedo(s)"
  },
  {
    "codigo": "Q714",
    "descricao": "Defeito de redução longitudinal do rádio"
  },
  {
    "codigo": "Q715",
    "descricao": "Defeito de redução longitudinal do cúbito [ulna]"
  },
  { "codigo": "Q716", "descricao": "Mão em garra de lagosta" },
  {
    "codigo": "Q718",
    "descricao": "Outros defeitos de redução do membro superior"
  },
  {
    "codigo": "Q719",
    "descricao": "Defeito por redução do membro superior, não especificado"
  },
  {
    "codigo": "Q720",
    "descricao": "Ausência congênita completa do(s) membro(s) inferior(es)"
  },
  {
    "codigo": "Q721",
    "descricao": "Ausência congênita da coxa e da perna com pé presente"
  },
  {
    "codigo": "Q722",
    "descricao": "Ausência congênita da perna e do pé"
  },
  {
    "codigo": "Q723",
    "descricao": "Ausência congênita do pé e de artelho(s)"
  },
  {
    "codigo": "Q724",
    "descricao": "Defeito por redução longitudinal da tíbia"
  },
  {
    "codigo": "Q725",
    "descricao": "Defeito por redução longitudinal da tíbia"
  },
  {
    "codigo": "Q726",
    "descricao": "Defeito por redução longitudinal do perônio [fíbula]"
  },
  { "codigo": "Q727", "descricao": "Pé bífido" },
  {
    "codigo": "Q728",
    "descricao": "Outros defeitos por redução do(s) membro(s) inferior(es)"
  },
  {
    "codigo": "Q729",
    "descricao": "Defeito não especificado por redução do membro inferior"
  },
  {
    "codigo": "Q730",
    "descricao": "Ausência congênita de membro(s) não especificado(s)"
  },
  {
    "codigo": "Q731",
    "descricao": "Focomelia, membro(s) não especificado(s)"
  },
  {
    "codigo": "Q738",
    "descricao": "Outros defeitos por redução de membro(s) não especificado(s)"
  },
  {
    "codigo": "Q740",
    "descricao": "Outras malformações congênitas do(s) membro(s) superiores, inclusive da cintura escapular"
  },
  {
    "codigo": "Q741",
    "descricao": "Malformação congênita do joelho"
  },
  {
    "codigo": "Q742",
    "descricao": "Outras malformações congênitas do(s) membro(s) inferiores, inclusive da cintura pélvica"
  },
  {
    "codigo": "Q743",
    "descricao": "Artrogripose congênita múltipla"
  },
  {
    "codigo": "Q748",
    "descricao": "Outras malformações congênitas especificadas de membro(s)"
  },
  {
    "codigo": "Q749",
    "descricao": "Malformações congênitas não especificadas de membro(s)"
  },
  { "codigo": "Q750", "descricao": "Craniossinostose" },
  { "codigo": "Q751", "descricao": "Disostose craniofacial" },
  { "codigo": "Q752", "descricao": "Hipertelorismo" },
  { "codigo": "Q753", "descricao": "Macrocefalia" },
  { "codigo": "Q754", "descricao": "Disostose mandíbulo-facial" },
  { "codigo": "Q755", "descricao": "Disostose óculo-mandibular" },
  {
    "codigo": "Q758",
    "descricao": "Outras malformações congênitas especificadas dos ossos do crânio e da face"
  },
  {
    "codigo": "Q759",
    "descricao": "Malformação congênita não especificada dos ossos do crânio e da face"
  },
  { "codigo": "Q760", "descricao": "Espinha bífida oculta" },
  { "codigo": "Q761", "descricao": "Síndrome de Klippel-Feil" },
  { "codigo": "Q762", "descricao": "Espondilolistese congênita" },
  {
    "codigo": "Q763",
    "descricao": "Escoliose congênita devida à malformação óssea congênita"
  },
  {
    "codigo": "Q764",
    "descricao": "Outras malformações congênitas da coluna vertebral não-associadas com escoliose"
  },
  { "codigo": "Q765", "descricao": "Costela cervical" },
  {
    "codigo": "Q766",
    "descricao": "Outras malformações congênitas das costelas"
  },
  {
    "codigo": "Q767",
    "descricao": "Malformação congênita do esterno"
  },
  {
    "codigo": "Q768",
    "descricao": "Outras malformações congênitas dos ossos do tórax"
  },
  {
    "codigo": "Q769",
    "descricao": "Malformação congênita não especificada dos ossos do tórax"
  },
  { "codigo": "Q770", "descricao": "Acondrogenesia" },
  { "codigo": "Q771", "descricao": "Nanismo tanatofórico" },
  { "codigo": "Q772", "descricao": "Síndrome das costelas curtas" },
  { "codigo": "Q773", "descricao": "Condrodisplasia puntacta" },
  { "codigo": "Q774", "descricao": "Acondroplasia" },
  { "codigo": "Q775", "descricao": "Displasia diastrófica" },
  { "codigo": "Q776", "descricao": "Displasia condroectodérmica" },
  { "codigo": "Q777", "descricao": "Displasia espondiloepifisária" },
  {
    "codigo": "Q778",
    "descricao": "Outras osteocondrodisplasias com anomalias do crescimento dos ossos longos e da coluna vertebral"
  },
  {
    "codigo": "Q779",
    "descricao": "Osteocondrodisplasia não especificada com anomalias do crescimento dos ossos longos e da coluna vertebral"
  },
  { "codigo": "Q780", "descricao": "Osteogênese imperfeita" },
  { "codigo": "Q781", "descricao": "Displasia poliostótica fibrosa" },
  { "codigo": "Q782", "descricao": "Osteopetrose" },
  { "codigo": "Q783", "descricao": "Displasia diafisária progressiva" },
  { "codigo": "Q784", "descricao": "Encondromatose" },
  { "codigo": "Q785", "descricao": "Displasia metafisária" },
  { "codigo": "Q786", "descricao": "Exostoses congênitas múltiplas" },
  {
    "codigo": "Q788",
    "descricao": "Outras osteocondrodisplasias especificadas"
  },
  {
    "codigo": "Q789",
    "descricao": "Osteocondrodisplasia não especificada"
  },
  {
    "codigo": "Q790",
    "descricao": "Hérnia diafragmática congênita"
  },
  {
    "codigo": "Q791",
    "descricao": "Outras malformações congênitas do diafragma"
  },
  { "codigo": "Q792", "descricao": "Exonfalia" },
  { "codigo": "Q793", "descricao": "Gastrosquise" },
  {
    "codigo": "Q794",
    "descricao": "Síndrome do abdome em ameixa seca (prune belly syndrome)"
  },
  {
    "codigo": "Q795",
    "descricao": "Outras malformações congênitas da parede abdominal"
  },
  { "codigo": "Q796", "descricao": "Síndrome de Ehlers-Danlos" },
  {
    "codigo": "Q798",
    "descricao": "Outras malformações congênitas do sistema osteomuscular"
  },
  {
    "codigo": "Q799",
    "descricao": "Malformação congênita não especificada do sistema osteomuscular"
  },
  { "codigo": "Q800", "descricao": "Ictiose vulgar" },
  { "codigo": "Q801", "descricao": "Ictiose ligada ao cromossomo X" },
  { "codigo": "Q802", "descricao": "Ictiose lamelar" },
  {
    "codigo": "Q803",
    "descricao": "Eritrodermia ictiosiforme bulhosa congênita"
  },
  { "codigo": "Q804", "descricao": "Feto arlequim" },
  { "codigo": "Q808", "descricao": "Outras ictioses congênitas" },
  {
    "codigo": "Q809",
    "descricao": "Ictiose congênita não especificada"
  },
  { "codigo": "Q810", "descricao": "Epidermólise bolhosa simples" },
  { "codigo": "Q811", "descricao": "Epidermólise bolhosa letal" },
  {
    "codigo": "Q812",
    "descricao": "Epidermólise bolhosa distrófica"
  },
  { "codigo": "Q818", "descricao": "Outras epidermólises bolhosas" },
  {
    "codigo": "Q819",
    "descricao": "Epidermólise bolhosa não especificada"
  },
  { "codigo": "Q820", "descricao": "Linfedema hereditário" },
  { "codigo": "Q821", "descricao": "Xeroderma pigmentoso" },
  { "codigo": "Q822", "descricao": "Mastocitose" },
  { "codigo": "Q823", "descricao": "Incontinentia pigmenti" },
  {
    "codigo": "Q824",
    "descricao": "Displasia ectodérmica (anidrótica)"
  },
  {
    "codigo": "Q825",
    "descricao": "Nevo não-neoplásico congênito"
  },
  {
    "codigo": "Q828",
    "descricao": "Outras malformações congênitas especificadas da pele"
  },
  {
    "codigo": "Q829",
    "descricao": "Malformação congênita não especificada da pele"
  },
  {
    "codigo": "Q830",
    "descricao": "Ausência congênita da mama com ausência do mamilo"
  },
  { "codigo": "Q831", "descricao": "Mama supranumerária" },
  { "codigo": "Q832", "descricao": "Ausência de mamilo" },
  { "codigo": "Q833", "descricao": "Mamilo acessório" },
  {
    "codigo": "Q838",
    "descricao": "Outras malformações congênitas da mama"
  },
  {
    "codigo": "Q839",
    "descricao": "Malformação congênita não especificada da mama"
  },
  { "codigo": "Q840", "descricao": "Alopécia congênita" },
  {
    "codigo": "Q841",
    "descricao": "Alterações morfológicas congênitas dos cabelos não classificadas em outra parte"
  },
  {
    "codigo": "Q842",
    "descricao": "Outras malformações congênitas dos cabelos"
  },
  { "codigo": "Q843", "descricao": "Anoníquia" },
  { "codigo": "Q844", "descricao": "Leuconíquia congênita" },
  { "codigo": "Q845", "descricao": "Hipertrofia e alargamento das unhas" },
  {
    "codigo": "Q846",
    "descricao": "Outras malformações congênitas das unhas"
  },
  {
    "codigo": "Q848",
    "descricao": "Outras malformações congênitas especificadas do tegumento"
  },
  {
    "codigo": "Q849",
    "descricao": "Malformação congênita não especificada do tegumento"
  },
  { "codigo": "Q850", "descricao": "Neurofibromatose (não-maligna)" },
  { "codigo": "Q851", "descricao": "Esclerose tuberosa" },
  {
    "codigo": "Q858",
    "descricao": "Outras facomatoses não classificadas em outra parte"
  },
  { "codigo": "Q859", "descricao": "Facomatose não especificada" },
  {
    "codigo": "Q860",
    "descricao": "Síndrome fetal alcoólico (dismórfico)"
  },
  {
    "codigo": "Q861",
    "descricao": "Síndrome fetal devida à hidantoína"
  },
  { "codigo": "Q862", "descricao": "Dismorfismo devido ao Warfarin" },
  {
    "codigo": "Q868",
    "descricao": "Outras síndromes com malformações congênitas devidas a causas exógenas conhecidas"
  },
  {
    "codigo": "Q870",
    "descricao": "Síndromes com malformações congênitas afetando predominantemente o aspecto da face"
  },
  {
    "codigo": "Q871",
    "descricao": "Síndromes com malformações congênitas associadas predominantemente com nanismo"
  },
  {
    "codigo": "Q872",
    "descricao": "Síndromes com malformações congênitas afetando predominantemente os membros"
  },
  {
    "codigo": "Q873",
    "descricao": "Síndromes com malformações congênitas com hipercrescimento precoce"
  },
  { "codigo": "Q874", "descricao": "Síndrome de Marfan" },
  {
    "codigo": "Q875",
    "descricao": "Outras síndromes com malformações congênitas com outras alterações do esqueleto"
  },
  {
    "codigo": "Q878",
    "descricao": "Outras síndromes com malformações congênitas especificadas, não classificadas em outra parte"
  },
  {
    "codigo": "Q890",
    "descricao": "Malformações congênitas do baço"
  },
  {
    "codigo": "Q891",
    "descricao": "Malformações congênitas das supra-renais"
  },
  {
    "codigo": "Q892",
    "descricao": "Malformações congênitas de outras glândulas endócrinas"
  },
  { "codigo": "Q893", "descricao": "Situs inversus" },
  { "codigo": "Q894", "descricao": "Reunião de gêmeos" },
  {
    "codigo": "Q897",
    "descricao": "Malformações congênitas múltiplas, não classificadas em outra parte"
  },
  {
    "codigo": "Q898",
    "descricao": "Outras malformações congênitas especificadas"
  },
  {
    "codigo": "Q899",
    "descricao": "Malformações congênitas não especificadas"
  },
  {
    "codigo": "Q900",
    "descricao": "Trissomia 21, não-disjunção meiótica"
  },
  {
    "codigo": "Q901",
    "descricao": "Trissomia 21, mosaicismo (não-disjunção mitótica)"
  },
  { "codigo": "Q902", "descricao": "Trissomia 21, translocação" },
  {
    "codigo": "Q909",
    "descricao": "Síndrome de Down não especificada"
  },
  {
    "codigo": "Q910",
    "descricao": "Trissomia 18, não-disjunção meiótica"
  },
  {
    "codigo": "Q911",
    "descricao": "Trissomia 18, mosaicismo cromossômico (não-disjunção mitótica)"
  },
  { "codigo": "Q912", "descricao": "Trissomia 18, translocação" },
  {
    "codigo": "Q913",
    "descricao": "Síndrome de Edwards não especificada"
  },
  {
    "codigo": "Q914",
    "descricao": "Trissomia 13, não-disjunção meiótica"
  },
  {
    "codigo": "Q915",
    "descricao": "Trissomia 13, mosaicismo cromossômico (não-disjunção mitótica)"
  },
  { "codigo": "Q916", "descricao": "Trissomia 13, translocação" },
  {
    "codigo": "Q917",
    "descricao": "Síndrome de Patau não especificada"
  },
  {
    "codigo": "Q920",
    "descricao": "Trissomia de um cromossomo inteiro, não-disjunção meiótica"
  },
  {
    "codigo": "Q921",
    "descricao": "Trissomia de um cromossomo inteiro, mosaicismo cromossômico (não-disjunção mitótica)"
  },
  { "codigo": "Q922", "descricao": "Trissomia parcial major" },
  { "codigo": "Q923", "descricao": "Trissomia parcial minor" },
  {
    "codigo": "Q924",
    "descricao": "Duplicações vistas somente na prometáfase"
  },
  {
    "codigo": "Q925",
    "descricao": "Duplicação com outros rearranjos complexos"
  },
  { "codigo": "Q926", "descricao": "Cromossomos marcadores suplementares" },
  { "codigo": "Q927", "descricao": "Triploidia e poliploidia" },
  {
    "codigo": "Q928",
    "descricao": "Outras trissomias especificadas e trissomias parciais dos autossomos"
  },
  {
    "codigo": "Q929",
    "descricao": "Trissomia e trissomia parcial não especificada dos autossomos"
  },
  {
    "codigo": "Q930",
    "descricao": "Monossomia de cromossomo inteiro, não-disjunção meiótica"
  },
  {
    "codigo": "Q931",
    "descricao": "Monossomia de cromossomo inteiro, mosaicismo cromossômico (não-disjunção mitótica)"
  },
  {
    "codigo": "Q932",
    "descricao": "Cromossomo substituído por anel ou dicêntrico"
  },
  {
    "codigo": "Q933",
    "descricao": "Deleção do braço curto do cromossomo 4"
  },
  {
    "codigo": "Q934",
    "descricao": "Deleção do braço curto do cromossomo 5"
  },
  {
    "codigo": "Q935",
    "descricao": "Outras deleções parciais de cromossomo"
  },
  {
    "codigo": "Q936",
    "descricao": "Deleções vistas somente na prometáfase"
  },
  {
    "codigo": "Q937",
    "descricao": "Deleções com outros rearranjos complexos"
  },
  { "codigo": "Q938", "descricao": "Outras deleções dos autossomos" },
  {
    "codigo": "Q939",
    "descricao": "Deleções não especificadas dos autossomos"
  },
  {
    "codigo": "Q950",
    "descricao": "Translocação ou inserção equilibrada em sujeito normal"
  },
  {
    "codigo": "Q951",
    "descricao": "Inversão cromossômica em sujeito normal"
  },
  {
    "codigo": "Q952",
    "descricao": "Rearranjo autossômico equilibrado em sujeito anormal"
  },
  {
    "codigo": "Q953",
    "descricao": "Rearranjo sexual/autossômico equilibrado em sujeito anormal"
  },
  { "codigo": "Q954", "descricao": "Sujeito com marcador de heterocromatina" },
  {
    "codigo": "Q955",
    "descricao": "Sujeito com sítio autossômico frágil"
  },
  {
    "codigo": "Q958",
    "descricao": "Outros rearranjos e marcadores equilibrados"
  },
  {
    "codigo": "Q959",
    "descricao": "Rearranjos e marcadores equilibrados não especificados"
  },
  { "codigo": "Q960", "descricao": "Cariótipo 45, X" },
  { "codigo": "Q961", "descricao": "Cariótipo 46, X iso (Xq)" },
  {
    "codigo": "Q962",
    "descricao": "Cariótipo 46, X com cromossomo sexual anormal, salvo iso (Xq)"
  },
  {
    "codigo": "Q963",
    "descricao": "Mosaicismo cromossômico, 45, X/46, XX ou XY"
  },
  {
    "codigo": "Q964",
    "descricao": "Mosaicismo cromossômico, 45, X/outra(s) linhagens celular(es) com cromossomo sexual anormal"
  },
  {
    "codigo": "Q968",
    "descricao": "Outras variantes da síndrome de Turner"
  },
  {
    "codigo": "Q969",
    "descricao": "Síndrome de Turner não especificada"
  },
  { "codigo": "Q970", "descricao": "Cariótipo 47, XXX" },
  {
    "codigo": "Q971",
    "descricao": "Mulher com mais de três cromossomos X"
  },
  {
    "codigo": "Q972",
    "descricao": "Mosaicismo cromossômico, linhagens com diversos números de cromossomos X"
  },
  { "codigo": "Q973", "descricao": "Mulher com cariótipo 46, XY" },
  {
    "codigo": "Q978",
    "descricao": "Outras anomalias especificadas dos cromossomos sexuais, fenótipo feminino"
  },
  {
    "codigo": "Q979",
    "descricao": "Anomalias não especificadas dos cromossomos sexuais, fenótipo feminino"
  },
  {
    "codigo": "Q980",
    "descricao": "Síndrome de Klinefelter, cariótipo 47, XXY"
  },
  {
    "codigo": "Q981",
    "descricao": "Síndrome de Klinefelter, homem com mais de dois cromossomos X"
  },
  {
    "codigo": "Q982",
    "descricao": "Síndrome de Klinefelter, homem com cariótipo 46, XX"
  },
  { "codigo": "Q983", "descricao": "Outro homem com cariótipo 46, XX" },
  {
    "codigo": "Q984",
    "descricao": "Síndrome de Klinefelter não especificada"
  },
  { "codigo": "Q985", "descricao": "Cariótipo 47, XYY" },
  {
    "codigo": "Q986",
    "descricao": "Homem com cromossomos sexuais de estrutura anormal"
  },
  {
    "codigo": "Q987",
    "descricao": "Homem com mosaicismo dos cromossomos sexuais"
  },
  {
    "codigo": "Q988",
    "descricao": "Outras anomalias especificadas dos cromossomos sexuais, fenótipo masculino"
  },
  {
    "codigo": "Q989",
    "descricao": "Anomalias não especificadas dos cromossomos sexuais, fenótipo masculino"
  },
  { "codigo": "Q990", "descricao": "Quimera 46, XX/46, XY" },
  { "codigo": "Q991", "descricao": "Hermafrodite verdadeiro 46, XX" },
  { "codigo": "Q992", "descricao": "Cromossomo X frágil" },
  {
    "codigo": "Q998",
    "descricao": "Outras anomalias cromossômicas especificadas"
  },
  {
    "codigo": "Q999",
    "descricao": "Anomalia cromossômica não especificada"
  },
  { "codigo": "R000", "descricao": "Taquicardia não especificada" },
  { "codigo": "R001", "descricao": "Bradicardia não especificada" },
  { "codigo": "R002", "descricao": "Palpitações" },
  {
    "codigo": "R008",
    "descricao": "Outras anormalidades e as não especificadas do batimento cardíaco"
  },
  {
    "codigo": "R010",
    "descricao": "Sopros cardíacos benignos ou inocentes"
  },
  {
    "codigo": "R011",
    "descricao": "Sopro cardíaco, não especificado"
  },
  { "codigo": "R012", "descricao": "Outros ruídos cardíacos" },
  {
    "codigo": "R02",
    "descricao": "Gangrena não classificada em outra parte"
  },
  {
    "codigo": "R030",
    "descricao": "Valor elevado da pressão arterial sem o diagnóstico de hipertensão"
  },
  {
    "codigo": "R031",
    "descricao": "Valor baixo da pressão arterial não específico"
  },
  { "codigo": "R040", "descricao": "Epistaxis" },
  { "codigo": "R041", "descricao": "Hemorragia da garganta" },
  { "codigo": "R042", "descricao": "Hemoptise" },
  {
    "codigo": "R048",
    "descricao": "Hemorragia de outras localizações das vias respiratórias"
  },
  {
    "codigo": "R049",
    "descricao": "Hemorragia não especificada das vias respiratórias"
  },
  { "codigo": "R05", "descricao": "Tosse" },
  { "codigo": "R060", "descricao": "Dispnéia" },
  { "codigo": "R061", "descricao": "Estridor" },
  { "codigo": "R062", "descricao": "Respiração ofegante" },
  { "codigo": "R063", "descricao": "Respiração periódica" },
  { "codigo": "R064", "descricao": "Hiperventilação" },
  { "codigo": "R065", "descricao": "Respiração pela boca" },
  { "codigo": "R066", "descricao": "Soluço" },
  { "codigo": "R067", "descricao": "Espirro" },
  {
    "codigo": "R068",
    "descricao": "Outras anormalidades e as não especificadas da respiração"
  },
  { "codigo": "R070", "descricao": "Dor de garganta" },
  { "codigo": "R071", "descricao": "Dor torácica ao respirar" },
  { "codigo": "R072", "descricao": "Dor precordial" },
  { "codigo": "R073", "descricao": "Outra dor torácica" },
  { "codigo": "R074", "descricao": "Dor torácica, não especificada" },
  { "codigo": "R090", "descricao": "Asfixia" },
  { "codigo": "R091", "descricao": "Pleurisia" },
  { "codigo": "R092", "descricao": "Parada respiratória" },
  { "codigo": "R093", "descricao": "Escarro anormal" },
  {
    "codigo": "R098",
    "descricao": "Outros sintomas e sinais especificados relativos aos aparelhos circulatório e respiratório"
  },
  { "codigo": "R100", "descricao": "Abdome agudo" },
  { "codigo": "R101", "descricao": "Dor localizada no abdome superior" },
  { "codigo": "R102", "descricao": "Dor pélvica e perineal" },
  {
    "codigo": "R103",
    "descricao": "Dor localizada em outras partes do abdome inferior"
  },
  {
    "codigo": "R104",
    "descricao": "Outras dores abdominais e as não especificadas"
  },
  { "codigo": "R11", "descricao": "Náusea e vômitos" },
  { "codigo": "R12", "descricao": "Pirose" },
  { "codigo": "R13", "descricao": "Disfagia" },
  {
    "codigo": "R14",
    "descricao": "Flatulência e afecções correlatas"
  },
  { "codigo": "R15", "descricao": "Incontinência fecal" },
  {
    "codigo": "R160",
    "descricao": "Hepatomegalia não classificada em outra parte"
  },
  {
    "codigo": "R161",
    "descricao": "Esplenomegalia não classificada em outra parte"
  },
  {
    "codigo": "R162",
    "descricao": "Hepatomegalia com esplenomegalia não classificada em outra parte"
  },
  { "codigo": "R17", "descricao": "Icterícia não especificada" },
  { "codigo": "R18", "descricao": "Ascite" },
  {
    "codigo": "R190",
    "descricao": "Massa, tumoração ou tumefação intra-abdominal e pélvica"
  },
  { "codigo": "R191", "descricao": "Ruídos hidroaéreos anormais" },
  { "codigo": "R192", "descricao": "Peristaltismo visível" },
  { "codigo": "R193", "descricao": "Rigidez abdominal" },
  {
    "codigo": "R194",
    "descricao": "Alteração do hábito intestinal"
  },
  { "codigo": "R195", "descricao": "Outras anormalidades fecais" },
  { "codigo": "R196", "descricao": "Halitose" },
  {
    "codigo": "R198",
    "descricao": "Outros sintomas e sinais especificados relativos ao aparelho digestivo e ao abdome"
  },
  { "codigo": "R200", "descricao": "Anestesia cutânea" },
  { "codigo": "R201", "descricao": "Hipoestesia cutânea" },
  { "codigo": "R202", "descricao": "Parestesias cutâneas" },
  { "codigo": "R203", "descricao": "Hiperestesia" },
  {
    "codigo": "R208",
    "descricao": "Outros distúrbios e os não especificadas da sensibilidade cutânea"
  },
  {
    "codigo": "R21",
    "descricao": "Eritema e outras erupções cutâneas não especificadas"
  },
  {
    "codigo": "R220",
    "descricao": "Tumefação, massa ou tumoração localizadas da cabeça"
  },
  {
    "codigo": "R221",
    "descricao": "Tumefação, massa ou tumoração localizadas do pescoço"
  },
  {
    "codigo": "R222",
    "descricao": "Tumefação, massa ou tumoração localizadas do tronco"
  },
  {
    "codigo": "R223",
    "descricao": "Tumefação, massa ou tumoração localizadas de membro superior"
  },
  {
    "codigo": "R224",
    "descricao": "Tumefação, massa ou tumoração localizadas no membro inferior"
  },
  {
    "codigo": "R227",
    "descricao": "Tumefação, massa ou tumoração localizadas de múltiplas localizações"
  },
  {
    "codigo": "R229",
    "descricao": "Tumefação, massa ou tumoração não especificadas, localizadas"
  },
  { "codigo": "R230", "descricao": "Cianose" },
  { "codigo": "R231", "descricao": "Palidez" },
  { "codigo": "R232", "descricao": "Rubor" },
  { "codigo": "R233", "descricao": "Equimoses espontâneas" },
  { "codigo": "R234", "descricao": "Alterações na textura da pele" },
  {
    "codigo": "R238",
    "descricao": "Outras alterações da pele e as não especificadas"
  },
  { "codigo": "R250", "descricao": "Movimentos anormais da cabeça" },
  { "codigo": "R251", "descricao": "Tremor não especificado" },
  { "codigo": "R252", "descricao": "Cãibras e espasmos" },
  { "codigo": "R253", "descricao": "Fasciculação" },
  {
    "codigo": "R258",
    "descricao": "Outros movimentos involuntários anormais e os não especificados"
  },
  { "codigo": "R260", "descricao": "Marcha atáxica" },
  { "codigo": "R261", "descricao": "Marcha paralítica" },
  {
    "codigo": "R262",
    "descricao": "Dificuldade para andar não classificada em outra parte"
  },
  {
    "codigo": "R268",
    "descricao": "Outras anormalidades da marcha e da mobilidade e as não especificadas"
  },
  { "codigo": "R270", "descricao": "Ataxia não especificada" },
  {
    "codigo": "R278",
    "descricao": "Outros distúrbios da coordenação e os não especificados"
  },
  { "codigo": "R290", "descricao": "Tetania" },
  { "codigo": "R291", "descricao": "Meningismo" },
  { "codigo": "R292", "descricao": "Reflexos anormais" },
  { "codigo": "R293", "descricao": "Postura anormal" },
  { "codigo": "R294", "descricao": "Quadril estalante" },
  {
    "codigo": "R296",
    "descricao": "Tendência a queda, não classificada em outra parte"
  },
  {
    "codigo": "R298",
    "descricao": "Outros sintomas e sinais relativos aos sistemas nervoso e osteomuscular e os não especificados"
  },
  { "codigo": "R300", "descricao": "Disúria" },
  { "codigo": "R301", "descricao": "Tenesmo vesical" },
  {
    "codigo": "R309",
    "descricao": "Micção dolorosa, não especificada"
  },
  { "codigo": "R31", "descricao": "Hematúria não especificada" },
  {
    "codigo": "R32",
    "descricao": "Incontinência urinária não especificada"
  },
  { "codigo": "R33", "descricao": "Retenção urinária" },
  { "codigo": "R34", "descricao": "Anúria e oligúria" },
  { "codigo": "R35", "descricao": "Poliúria" },
  { "codigo": "R36", "descricao": "Secreção uretral" },
  { "codigo": "R390", "descricao": "Extravasamento de urina" },
  {
    "codigo": "R391",
    "descricao": "Outras dificuldades à micção"
  },
  { "codigo": "R392", "descricao": "Uremia extra-renal" },
  {
    "codigo": "R398",
    "descricao": "Outros sintomas e sinais relativos ao aparelho urinário e os não especificados"
  },
  { "codigo": "R400", "descricao": "Sonolência" },
  { "codigo": "R401", "descricao": "Estupor" },
  { "codigo": "R402", "descricao": "Coma não especificado" },
  {
    "codigo": "R410",
    "descricao": "Desorientação não especificada"
  },
  { "codigo": "R411", "descricao": "Amnésia anterógrada" },
  { "codigo": "R412", "descricao": "Amnésia retrógrada" },
  { "codigo": "R413", "descricao": "Outra amnésia" },
  {
    "codigo": "R418",
    "descricao": "Outros sintomas e sinais especificados relativos às funções cognitivas e à consciência"
  },
  { "codigo": "R42", "descricao": "Tontura e instabilidade" },
  { "codigo": "R430", "descricao": "Anosmia" },
  { "codigo": "R431", "descricao": "Parosmia" },
  { "codigo": "R432", "descricao": "Parageusia" },
  {
    "codigo": "R438",
    "descricao": "Outros distúrbios do olfato e do paladar e os não especificados"
  },
  { "codigo": "R440", "descricao": "Alucinações auditivas" },
  { "codigo": "R441", "descricao": "Alucinações visuais" },
  { "codigo": "R442", "descricao": "Outras alucinações" },
  {
    "codigo": "R443",
    "descricao": "Alucinações não especificadas"
  },
  {
    "codigo": "R448",
    "descricao": "Outros sintomas e sinais especificados relativos às sensações e percepções gerais"
  },
  { "codigo": "R450", "descricao": "Nervosismo" },
  {
    "codigo": "R451",
    "descricao": "Agitação e inquietação"
  },
  { "codigo": "R452", "descricao": "Tristeza" },
  { "codigo": "R453", "descricao": "Apatia e desinteresse" },
  { "codigo": "R454", "descricao": "Irritabilidade e mau humor" },
  { "codigo": "R455", "descricao": "Hostilidade" },
  { "codigo": "R456", "descricao": "Violência física" },
  {
    "codigo": "R457",
    "descricao": "Estado de choque emocional e tensão, não especificado"
  },
  {
    "codigo": "R458",
    "descricao": "Outros sintomas e sinais relativos ao estado emocional"
  },
  { "codigo": "R460", "descricao": "Baixo nível de higiene pessoal" },
  { "codigo": "R461", "descricao": "Aparência pessoal bizarra" },
  {
    "codigo": "R462",
    "descricao": "Comportamento estranho e inexplicável"
  },
  { "codigo": "R463", "descricao": "Hiperatividade" },
  { "codigo": "R464", "descricao": "Lentidão e baixa reatividade" },
  { "codigo": "R465", "descricao": "Personalidade suspeita e evasiva" },
  {
    "codigo": "R466",
    "descricao": "Inquietação e preocupação exageradas com acontecimentos estressantes"
  },
  {
    "codigo": "R467",
    "descricao": "Verborragia e pormenores circunstanciais mascarando o motivo da consulta"
  },
  {
    "codigo": "R468",
    "descricao": "Outros sintomas e sinais relativos à aparência e ao comportamento"
  },
  { "codigo": "R470", "descricao": "Disfasia e afasia" },
  { "codigo": "R471", "descricao": "Disartria e anartria" },
  {
    "codigo": "R478",
    "descricao": "Outros distúrbios da fala e os não especificados"
  },
  { "codigo": "R480", "descricao": "Dislexia e alexia" },
  { "codigo": "R481", "descricao": "Agnosia" },
  { "codigo": "R482", "descricao": "Apraxia" },
  {
    "codigo": "R488",
    "descricao": "Outras disfunções simbólicas e as não especificadas"
  },
  { "codigo": "R490", "descricao": "Disfonia" },
  { "codigo": "R491", "descricao": "Afonia" },
  { "codigo": "R492", "descricao": "Hipernasalidade e hiponasalidade" },
  {
    "codigo": "R498",
    "descricao": "Outros distúrbios da voz e os não especificados"
  },
  { "codigo": "R502", "descricao": "Febre induzida por drogas" },
  { "codigo": "R508", "descricao": "Outra febre especificada" },
  { "codigo": "R509", "descricao": "Febre não especificada" },
  { "codigo": "R51", "descricao": "Cefaléia" },
  { "codigo": "R520", "descricao": "Dor aguda" },
  { "codigo": "R521", "descricao": "Dor crônica intratável" },
  { "codigo": "R522", "descricao": "Outra dor crônica" },
  { "codigo": "R529", "descricao": "Dor não especificada" },
  { "codigo": "R53", "descricao": "Mal estar, fadiga" },
  { "codigo": "R54", "descricao": "Senilidade" },
  { "codigo": "R55", "descricao": "Síncope e colapso" },
  { "codigo": "R560", "descricao": "Convulsões febris" },
  {
    "codigo": "R568",
    "descricao": "Outras convulsões e as não especificadas"
  },
  { "codigo": "R570", "descricao": "Choque cardiogênico" },
  { "codigo": "R571", "descricao": "Choque hipovolêmico" },
  { "codigo": "R578", "descricao": "Outras formas de choque" },
  { "codigo": "R579", "descricao": "Choque não especificado" },
  {
    "codigo": "R58",
    "descricao": "Hemorragia não classificada em outra parte"
  },
  {
    "codigo": "R590",
    "descricao": "Aumento de volume localizado de gânglios linfáticos"
  },
  {
    "codigo": "R591",
    "descricao": "Aumento de volume generalizado de gânglios linfáticos"
  },
  {
    "codigo": "R599",
    "descricao": "Adenomegalia ou aumento de volume dos gânglios linfáticos, não especificado"
  },
  { "codigo": "R600", "descricao": "Edema localizado" },
  { "codigo": "R601", "descricao": "Edema generalizado" },
  { "codigo": "R609", "descricao": "Edema não especificado" },
  { "codigo": "R610", "descricao": "Hiperidrose localizada" },
  { "codigo": "R611", "descricao": "Hiperidrose generalizada" },
  { "codigo": "R619", "descricao": "Hiperidrose não especificada" },
  { "codigo": "R620", "descricao": "Retardo de maturação" },
  {
    "codigo": "R628",
    "descricao": "Outras formas de retardo do desenvolvimento fisiológico normal"
  },
  {
    "codigo": "R629",
    "descricao": "Retardo do desenvolvimento fisiológico normal, não especificado"
  },
  { "codigo": "R630", "descricao": "Anorexia" },
  { "codigo": "R631", "descricao": "Polidipsia" },
  { "codigo": "R632", "descricao": "Polifagia" },
  {
    "codigo": "R633",
    "descricao": "Dificuldades de alimentação e erros na administração de alimentos"
  },
  { "codigo": "R634", "descricao": "Perda de peso anormal" },
  { "codigo": "R635", "descricao": "Ganho de peso anormal" },
  {
    "codigo": "R638",
    "descricao": "Outros sintomas e sinais relativos a ingestão de alimentos e de líquidos"
  },
  { "codigo": "R64", "descricao": "Caquexia" },
  {
    "codigo": "R680",
    "descricao": "Hipotermia não associada à baixa temperatura ambiental"
  },
  {
    "codigo": "R681",
    "descricao": "Sintomas não específicos peculiares à infância"
  },
  { "codigo": "R682", "descricao": "Boca seca, não especificada" },
  { "codigo": "R683", "descricao": "Baqueteamento dos dedos" },
  {
    "codigo": "R688",
    "descricao": "Outros sintomas e sinais gerais especificados"
  },
  {
    "codigo": "R69",
    "descricao": "Causas desconhecidas e não especificadas de morbidade"
  },
  {
    "codigo": "R700",
    "descricao": "Velocidade de hemossedimentação elevada"
  },
  { "codigo": "R701", "descricao": "Viscosidade plasmática anormal" },
  { "codigo": "R71", "descricao": "Anormalidade das hemácias" },
  {
    "codigo": "R72",
    "descricao": "Anormalidade dos leucócitos não classificada em outra parte"
  },
  {
    "codigo": "R730",
    "descricao": "Teste de tolerância a glicose anormal"
  },
  { "codigo": "R739", "descricao": "Hiperglicemia não especificada" },
  {
    "codigo": "R740",
    "descricao": "Aumento dos níveis de transaminases e da desidrogenase lática (DHL)"
  },
  {
    "codigo": "R748",
    "descricao": "Níveis anormais de outras enzimas séricas"
  },
  {
    "codigo": "R749",
    "descricao": "Anormalidade dos níveis de enzimas séricas, não especificada"
  },
  {
    "codigo": "R75",
    "descricao": "Evidência laboratorial do vírus da imunodeficiência humana [HIV]"
  },
  { "codigo": "R760", "descricao": "Título aumentado de anticorpos" },
  {
    "codigo": "R761",
    "descricao": "Reação anormal ao teste da tuberculina"
  },
  {
    "codigo": "R762",
    "descricao": "Exame sorológico falso positivo para sífilis"
  },
  {
    "codigo": "R768",
    "descricao": "Outros achados imunológicos especificados anormais no soro"
  },
  {
    "codigo": "R769",
    "descricao": "Achado anormal de exame imunológico sérico, não especificado"
  },
  { "codigo": "R770", "descricao": "Anormalidade da albumina" },
  { "codigo": "R771", "descricao": "Anormalidade das globulinas" },
  { "codigo": "R772", "descricao": "Anormalidade da alfafetoproteína" },
  {
    "codigo": "R778",
    "descricao": "Outras anormalidades especificadas das proteínas plasmáticas"
  },
  {
    "codigo": "R779",
    "descricao": "Anormalidades de proteína plasmática, não especificadas"
  },
  { "codigo": "R780", "descricao": "Presença de álcool no sangue" },
  { "codigo": "R781", "descricao": "Presença de opiáceos no sangue" },
  { "codigo": "R782", "descricao": "Presença de cocaína no sangue" },
  {
    "codigo": "R783",
    "descricao": "Presença de alucinógeno no sangue"
  },
  {
    "codigo": "R784",
    "descricao": "Presença de outras drogas com potencial de causar dependência, no sangue"
  },
  {
    "codigo": "R785",
    "descricao": "Presença de droga psicotrópica no sangue"
  },
  {
    "codigo": "R786",
    "descricao": "Presença de agente esteróide no sangue"
  },
  {
    "codigo": "R787",
    "descricao": "Presença de nível anormal de metais pesados no sangue"
  },
  {
    "codigo": "R788",
    "descricao": "Presença de outras substâncias especificadas não normalmente encontradas no sangue"
  },
  {
    "codigo": "R789",
    "descricao": "Presença de substância não especificada normalmente não encontrada no sangue"
  },
  {
    "codigo": "R790",
    "descricao": "Anormalidade do nível de elementos minerais no sangue"
  },
  {
    "codigo": "R798",
    "descricao": "Outros achados anormais especificados de exames químicos do sangue"
  },
  {
    "codigo": "R799",
    "descricao": "Achado anormal de exame químico do sangue, não especificado"
  },
  { "codigo": "R80", "descricao": "Proteinúria isolada" },
  { "codigo": "R81", "descricao": "Glicosúria" },
  { "codigo": "R820", "descricao": "Quilúria" },
  { "codigo": "R821", "descricao": "Mioglobinúria" },
  { "codigo": "R822", "descricao": "Biliúria" },
  { "codigo": "R823", "descricao": "Hemoglobinúria" },
  { "codigo": "R824", "descricao": "Acetonúria" },
  {
    "codigo": "R825",
    "descricao": "Níveis urinários elevados de drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R826",
    "descricao": "Níveis urinários anormais de substâncias cuja origem é essencialmente não-medicinal"
  },
  {
    "codigo": "R827",
    "descricao": "Achados anormais ao exame microbiológico da urina"
  },
  {
    "codigo": "R828",
    "descricao": "Achados anormais ao exame citológico e histológico da urina"
  },
  {
    "codigo": "R829",
    "descricao": "Outros achados anormais na urina e os não especificados"
  },
  {
    "codigo": "R830",
    "descricao": "Achados anormais no líquido cefalorraquidiano - nível enzimático anormal"
  },
  {
    "codigo": "R831",
    "descricao": "Achados anormais no líquido cefalorraquidiano - nível hormonal anormal"
  },
  {
    "codigo": "R832",
    "descricao": "Achados anormais no líquido cefalorraquidiano - nível anormal de outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R833",
    "descricao": "Achados anormais no líquido cefalorraquidiano - nível anormal de substâncias cuja origem é essencialmente não medicinal"
  },
  {
    "codigo": "R834",
    "descricao": "Achados anormais no líquido cefalorraquidiano - achados imunológicos anormais"
  },
  {
    "codigo": "R835",
    "descricao": "Achados anormais no líquido cefalorraquidiano - achados microbiológicos anormais"
  },
  {
    "codigo": "R836",
    "descricao": "Achados anormais no líquido cefalorraquidiano - achados citológicos anormais"
  },
  {
    "codigo": "R837",
    "descricao": "Achados anormais no líquido cefalorraquidiano - achados histológicos anormais"
  },
  {
    "codigo": "R838",
    "descricao": "Achados anormais no líquido cefalorraquidiano - outros achados anormais"
  },
  {
    "codigo": "R839",
    "descricao": "Achados anormais no líquido cefalorraquidiano - achado anormal não especificado"
  },
  {
    "codigo": "R840",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível enzimático anormal"
  },
  {
    "codigo": "R841",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível hormonal anormal"
  },
  {
    "codigo": "R842",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível anormal de outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R843",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível anormal de substâncias cuja origem é essencialmente não medicinal"
  },
  {
    "codigo": "R844",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados imunológicos anormais"
  },
  {
    "codigo": "R845",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados microbiológicos anormais"
  },
  {
    "codigo": "R846",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados citológicos anormais"
  },
  {
    "codigo": "R847",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados histológicos anormais"
  },
  {
    "codigo": "R848",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - outros achados anormais"
  },
  {
    "codigo": "R849",
    "descricao": "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achado anormal não especificado"
  },
  {
    "codigo": "R850",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível enzimático anormal"
  },
  {
    "codigo": "R851",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível hormonal anormal"
  },
  {
    "codigo": "R852",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível anormal de outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R853",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível anormal de substâncias cuja origem é essencialmente não medicinal"
  },
  {
    "codigo": "R854",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados imunológicos anormais"
  },
  {
    "codigo": "R855",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados microbiológicos anormais"
  },
  {
    "codigo": "R856",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados citológicos anormais"
  },
  {
    "codigo": "R857",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados histológicos anormais"
  },
  {
    "codigo": "R858",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - outros achados anormais"
  },
  {
    "codigo": "R859",
    "descricao": "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achado anormal não especificado"
  },
  {
    "codigo": "R860",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - nível enzimático anormal"
  },
  {
    "codigo": "R861",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - nível hormonal anormal"
  },
  {
    "codigo": "R862",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - nível anormal de outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R863",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - nível anormal de substâncias cuja origem é essencialmente não medicinal"
  },
  {
    "codigo": "R864",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - achados imunológicos anormais"
  },
  {
    "codigo": "R865",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - achados microbiológicos anormais"
  },
  {
    "codigo": "R866",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - achados citológicos anormais"
  },
  {
    "codigo": "R867",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - achados histológicos anormais"
  },
  {
    "codigo": "R868",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - outros achados anormais"
  },
  {
    "codigo": "R869",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais masculinos - achado anormal não especificado"
  },
  {
    "codigo": "R870",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - nível enzimático anormal"
  },
  {
    "codigo": "R871",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - nível hormonal anormal"
  },
  {
    "codigo": "R872",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - nível anormal de outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R873",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - nível anormal de substâncias cuja origem é essencialmente não medicinal"
  },
  {
    "codigo": "R874",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - achados imunológicos anormais"
  },
  {
    "codigo": "R875",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - achados microbiológicos anormais"
  },
  {
    "codigo": "R876",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - achados citológicos anormais"
  },
  {
    "codigo": "R877",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - achados histológicos anormais"
  },
  {
    "codigo": "R878",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - outros achados anormais"
  },
  {
    "codigo": "R879",
    "descricao": "Achados anormais de material proveniente dos órgãos genitais femininos - achado anormal não especificado"
  },
  {
    "codigo": "R890",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível enzimático anormal"
  },
  {
    "codigo": "R891",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível hormonal anormal"
  },
  {
    "codigo": "R892",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível anormal de outras drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "R893",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível anormal de substâncias cuja origem é essencialmente não medicinal"
  },
  {
    "codigo": "R894",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados imunológicos anormais"
  },
  {
    "codigo": "R895",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados microbiológicos anormais"
  },
  {
    "codigo": "R896",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados citológicos anormais"
  },
  {
    "codigo": "R897",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados histológicos anormais"
  },
  {
    "codigo": "R898",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - outros achados anormais"
  },
  {
    "codigo": "R899",
    "descricao": "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achado anormal não especificado"
  },
  {
    "codigo": "R900",
    "descricao": "Lesão intracraniana ocupando espaço"
  },
  {
    "codigo": "R908",
    "descricao": "Outros resultados anormais de exames para diagnóstico por imagem do sistema nervoso central"
  },
  {
    "codigo": "R91",
    "descricao": "Achados anormais, de exames para diagnóstico por imagem, do pulmão"
  },
  {
    "codigo": "R92",
    "descricao": "Achados anormais, de exames para diagnóstico por imagem, da mama"
  },
  {
    "codigo": "R930",
    "descricao": "Achados anormais de exames para diagnóstico por imagem do crânio e da cabeça não classificados em outra parte"
  },
  {
    "codigo": "R931",
    "descricao": "Achados anormais de exames para diagnóstico por imagem do coração e da circulação coronariana"
  },
  {
    "codigo": "R932",
    "descricao": "Achados anormais de exames para diagnóstico por imagem do fígado e das vias biliares"
  },
  {
    "codigo": "R933",
    "descricao": "Achados anormais de exames para diagnóstico por imagem de outras partes do tubo digestivo"
  },
  {
    "codigo": "R934",
    "descricao": "Achados anormais de exames para diagnóstico por imagem dos órgãos urinários"
  },
  {
    "codigo": "R935",
    "descricao": "Achados anormais de exames para diagnóstico por imagem de outras regiões do abdome, incluindo o retroperitônio"
  },
  {
    "codigo": "R936",
    "descricao": "Achados anormais de exames para diagnóstico por imagem dos membros"
  },
  {
    "codigo": "R937",
    "descricao": "Achados anormais de exames para diagnóstico por imagem de outras partes do sistema osteomuscular"
  },
  {
    "codigo": "R938",
    "descricao": "Achados anormais de exames diagnósticos por imagem de outras estruturas somáticas especificadas"
  },
  {
    "codigo": "R940",
    "descricao": "Resultados anormais de estudos de função do sistema nervoso central"
  },
  {
    "codigo": "R941",
    "descricao": "Resultados anormais de estudos de função do sistema nervoso periférico e dos órgãos dos sentidos"
  },
  {
    "codigo": "R942",
    "descricao": "Resultados anormais de estudos da função pulmonar"
  },
  {
    "codigo": "R943",
    "descricao": "Resultados anormais de estudos da função cardiovascular"
  },
  {
    "codigo": "R944",
    "descricao": "Resultados anormais de estudos de função renal"
  },
  {
    "codigo": "R945",
    "descricao": "Resultados anormais de estudos de função hepática"
  },
  {
    "codigo": "R946",
    "descricao": "Resultados anormais de estudos de função tireoidiana"
  },
  {
    "codigo": "R947",
    "descricao": "Resultados anormais de estudos de função de outras glândulas endócrinas"
  },
  {
    "codigo": "R948",
    "descricao": "Resultados anormais de estudos de função de outros órgãos, aparelhos e sistemas"
  },
  {
    "codigo": "R95",
    "descricao": "Síndrome da morte súbita na infância"
  },
  { "codigo": "R960", "descricao": "Morte instantânea" },
  {
    "codigo": "R961",
    "descricao": "Morte que ocorre em menos de 24 horas após o início dos sintomas, que não pode ser explicada"
  },
  { "codigo": "R98", "descricao": "Morte sem assistência" },
  {
    "codigo": "R99",
    "descricao": "Outras causas mal definidas e as não especificadas de mortalidade"
  },
  {
    "codigo": "S000",
    "descricao": "Traumatismo superficial do couro cabeludo"
  },
  {
    "codigo": "S001",
    "descricao": "Contusão da pálpebra e da região periocular"
  },
  {
    "codigo": "S002",
    "descricao": "Outros traumatismos superficiais da pálpebra e da região periocular"
  },
  { "codigo": "S003", "descricao": "Traumatismo superficial do nariz" },
  { "codigo": "S004", "descricao": "Traumatismo superficial do ouvido" },
  {
    "codigo": "S005",
    "descricao": "Traumatismo superficial dos lábios e da cavidade oral"
  },
  {
    "codigo": "S007",
    "descricao": "Traumatismos superficiais múltiplos da cabeça"
  },
  {
    "codigo": "S008",
    "descricao": "Traumatismo superficial de outras partes da cabeça"
  },
  {
    "codigo": "S009",
    "descricao": "Traumatismo superficial da cabeça, parte não especificada"
  },
  { "codigo": "S010", "descricao": "Ferimento do couro cabeludo" },
  {
    "codigo": "S011",
    "descricao": "Ferimento da pálpebra e da região periocular"
  },
  { "codigo": "S012", "descricao": "Ferimento do nariz" },
  { "codigo": "S013", "descricao": "Ferimento do ouvido" },
  {
    "codigo": "S014",
    "descricao": "Ferimento da bochecha e região têmporo-mandibular"
  },
  {
    "codigo": "S015",
    "descricao": "Ferimento do lábio e da cavidade oral"
  },
  { "codigo": "S017", "descricao": "Ferimentos múltiplos da cabeça" },
  {
    "codigo": "S018",
    "descricao": "Ferimento na cabeça, de outras localizações"
  },
  {
    "codigo": "S019",
    "descricao": "Ferimento na cabeça, parte não especificada"
  },
  { "codigo": "S020", "descricao": "Fratura da abóbada do crânio" },
  { "codigo": "S021", "descricao": "Fratura da base do crânio" },
  { "codigo": "S022", "descricao": "Fratura dos ossos nasais" },
  { "codigo": "S023", "descricao": "Fratura do assoalho orbital" },
  { "codigo": "S024", "descricao": "Fratura dos ossos malares e maxilares" },
  { "codigo": "S025", "descricao": "Fratura de dentes" },
  { "codigo": "S026", "descricao": "Fratura de mandíbula" },
  {
    "codigo": "S027",
    "descricao": "Fraturas múltiplas envolvendo os ossos do crânio e da face"
  },
  {
    "codigo": "S028",
    "descricao": "Outras fraturas do crânio e dos ossos da face"
  },
  {
    "codigo": "S029",
    "descricao": "Fratura do crânio ou dos ossos da face, parte não especificada"
  },
  { "codigo": "S030", "descricao": "Luxação do maxilar" },
  {
    "codigo": "S031",
    "descricao": "Luxação da cartilagem do septo nasal"
  },
  { "codigo": "S032", "descricao": "Luxação dentária" },
  {
    "codigo": "S033",
    "descricao": "Luxação de outras partes da cabeça e das não especificadas"
  },
  { "codigo": "S034", "descricao": "Entorse e distensão do maxilar" },
  {
    "codigo": "S035",
    "descricao": "Entorse e distensão da articulações e dos ligamentos de outras localizações e de localizações não especificadas da cabeça"
  },
  {
    "codigo": "S040",
    "descricao": "Traumatismo do nervo e das vias ópticas"
  },
  { "codigo": "S041", "descricao": "Traumatismo do nervo oculomotor" },
  { "codigo": "S042", "descricao": "Traumatismo do nervo troclear" },
  { "codigo": "S043", "descricao": "Traumatismo do nervo trigêmeo" },
  { "codigo": "S044", "descricao": "Traumatismo do nervo abducente" },
  { "codigo": "S045", "descricao": "Traumatismo do nervo facial" },
  { "codigo": "S046", "descricao": "Traumatismo do nervo acústico" },
  { "codigo": "S047", "descricao": "Traumatismo do nervo acessório" },
  { "codigo": "S048", "descricao": "Traumatismo de outros nervos cranianos" },
  {
    "codigo": "S049",
    "descricao": "Traumatismo de nervo craniano não especificado"
  },
  {
    "codigo": "S050",
    "descricao": "Traumatismo da conjuntiva e abrasão da córnea sem menção de corpo estranho"
  },
  {
    "codigo": "S051",
    "descricao": "Contusão do globo ocular e dos tecidos da órbita"
  },
  {
    "codigo": "S052",
    "descricao": "Laceração e ruptura ocular com prolapso ou perda de tecido intra-ocular"
  },
  {
    "codigo": "S053",
    "descricao": "Laceração ocular sem prolapso ou perda de tecido intra-ocular"
  },
  {
    "codigo": "S054",
    "descricao": "Ferimento penetrante da órbita com ou sem corpo estranho"
  },
  {
    "codigo": "S055",
    "descricao": "Ferimento penetrante do globo ocular com corpo estranho"
  },
  {
    "codigo": "S056",
    "descricao": "Ferimento penetrante do globo ocular sem corpo estranho"
  },
  { "codigo": "S057", "descricao": "Avulsão do olho" },
  {
    "codigo": "S058",
    "descricao": "Outros traumatismos do olho e da órbita"
  },
  {
    "codigo": "S059",
    "descricao": "Traumatismo do olho e da órbita, não especificado"
  },
  { "codigo": "S060", "descricao": "Concussão cerebral" },
  { "codigo": "S061", "descricao": "Edema cerebral traumático" },
  { "codigo": "S062", "descricao": "Traumatismo cerebral difuso" },
  { "codigo": "S063", "descricao": "Traumatismo cerebral focal" },
  { "codigo": "S064", "descricao": "Hemorragia epidural" },
  { "codigo": "S065", "descricao": "Hemorragia subdural devida a traumatismo" },
  {
    "codigo": "S066",
    "descricao": "Hemorragia subaracnóide devida a traumatismo"
  },
  {
    "codigo": "S067",
    "descricao": "Traumatismo intracraniano com coma prolongado"
  },
  { "codigo": "S068", "descricao": "Outros traumatismos intracranianos" },
  {
    "codigo": "S069",
    "descricao": "Traumatismo intracraniano, não especificado"
  },
  { "codigo": "S070", "descricao": "Lesão por esmagamento da face" },
  {
    "codigo": "S071",
    "descricao": "Lesão por esmagamento do crânio"
  },
  {
    "codigo": "S078",
    "descricao": "Lesão por esmagamento de outras partes da cabeça"
  },
  {
    "codigo": "S079",
    "descricao": "Lesão por esmagamento da cabeça, parte não especificada"
  },
  { "codigo": "S080", "descricao": "Avulsão do couro cabeludo" },
  {
    "codigo": "S081",
    "descricao": "Amputação traumática da orelha"
  },
  {
    "codigo": "S088",
    "descricao": "Amputação traumática de outras partes da cabeça"
  },
  {
    "codigo": "S089",
    "descricao": "Amputação traumática de parte não especificada da cabeça"
  },
  {
    "codigo": "S090",
    "descricao": "Traumatismo dos vasos sangüíneos da cabeça não classificados em outra parte"
  },
  {
    "codigo": "S091",
    "descricao": "Traumatismo dos músculos e dos tendões da cabeça"
  },
  { "codigo": "S092", "descricao": "Ruptura traumática do tímpano" },
  {
    "codigo": "S097",
    "descricao": "Traumatismos múltiplos da cabeça"
  },
  {
    "codigo": "S098",
    "descricao": "Outros traumatismos especificados da cabeça"
  },
  {
    "codigo": "S099",
    "descricao": "Traumatismo não especificado da cabeça"
  },
  { "codigo": "S100", "descricao": "Contusão da garganta" },
  {
    "codigo": "S101",
    "descricao": "Outros traumatismos superficiais da garganta e os não especificados"
  },
  {
    "codigo": "S107",
    "descricao": "Traumatismos superficiais múltiplos do pescoço"
  },
  {
    "codigo": "S108",
    "descricao": "Traumatismo superficial de outras localizações do pescoço"
  },
  {
    "codigo": "S109",
    "descricao": "Traumatismo superficial do pescoço, parte não especificada"
  },
  {
    "codigo": "S110",
    "descricao": "Ferimento envolvendo a laringe e a traquéia"
  },
  { "codigo": "S111", "descricao": "Ferimento da glândula tireóide" },
  {
    "codigo": "S112",
    "descricao": "Ferimento envolvendo a faringe e o esôfago cervical"
  },
  {
    "codigo": "S117",
    "descricao": "Ferimentos múltiplos do pescoço"
  },
  {
    "codigo": "S118",
    "descricao": "Ferimentos de outras partes do pescoço"
  },
  {
    "codigo": "S119",
    "descricao": "Ferimentos do pescoço, parte não especificada"
  },
  {
    "codigo": "S120",
    "descricao": "Fratura da primeira vértebra cervical"
  },
  {
    "codigo": "S121",
    "descricao": "Fratura da segunda vértebra cervical"
  },
  {
    "codigo": "S122",
    "descricao": "Fratura de outras vértebras cervicais especificadas"
  },
  {
    "codigo": "S127",
    "descricao": "Fraturas múltiplas da coluna cervical"
  },
  { "codigo": "S128", "descricao": "Fratura de outras partes do pescoço" },
  {
    "codigo": "S129",
    "descricao": "Fratura do pescoço, parte não especificada"
  },
  {
    "codigo": "S130",
    "descricao": "Ruptura traumática de disco intervertebral cervical"
  },
  {
    "codigo": "S131",
    "descricao": "Luxação de vértebra cervical"
  },
  {
    "codigo": "S132",
    "descricao": "Luxação de outras partes do pescoço e das não especificadas"
  },
  {
    "codigo": "S133",
    "descricao": "Luxações múltiplas do pescoço"
  },
  {
    "codigo": "S134",
    "descricao": "Distensão e entorse da coluna cervical"
  },
  {
    "codigo": "S135",
    "descricao": "Distensão e entorse da região tireoidiana"
  },
  {
    "codigo": "S136",
    "descricao": "Distensão e entorse de articulações e de ligamentos de outras localizações e das não especificadas do pescoço"
  },
  {
    "codigo": "S140",
    "descricao": "Concussão e edema da medula cervical"
  },
  {
    "codigo": "S141",
    "descricao": "Outros traumatismos e os não especificados da medula cervical"
  },
  {
    "codigo": "S142",
    "descricao": "Traumatismo da raiz nervosa da coluna cervical"
  },
  { "codigo": "S143", "descricao": "Traumatismo do plexo braquial" },
  {
    "codigo": "S144",
    "descricao": "Traumatismo dos nervos periféricos do pescoço"
  },
  {
    "codigo": "S145",
    "descricao": "Traumatismo dos nervos simpáticos do pescoço"
  },
  {
    "codigo": "S146",
    "descricao": "Traumatismo de outros nervos e dos não especificados do pescoço"
  },
  {
    "codigo": "S150",
    "descricao": "Traumatismo da artéria carótida"
  },
  { "codigo": "S151", "descricao": "Traumatismo da artéria vertebral" },
  { "codigo": "S152", "descricao": "Traumatismo da veia jugular externa" },
  { "codigo": "S153", "descricao": "Traumatismo da veia jugular interna" },
  {
    "codigo": "S157",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos ao nível do pescoço"
  },
  {
    "codigo": "S158",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível do pescoço"
  },
  {
    "codigo": "S159",
    "descricao": "Traumatismo de vasos sangüíneos não especificados ao nível do pescoço"
  },
  {
    "codigo": "S16",
    "descricao": "Traumatismo de tendões e de músculos do pescoço"
  },
  {
    "codigo": "S170",
    "descricao": "Lesão por esmagamento da laringe e da traquéia"
  },
  {
    "codigo": "S178",
    "descricao": "Lesão por esmagamento de outras partes do pescoço"
  },
  {
    "codigo": "S179",
    "descricao": "Lesão por esmagamento do pescoço, parte não especificada"
  },
  {
    "codigo": "S18",
    "descricao": "Amputação traumática ao nível do pescoço"
  },
  {
    "codigo": "S197",
    "descricao": "Traumatismos múltiplos do pescoço"
  },
  {
    "codigo": "S198",
    "descricao": "Outros traumatismos especificados do pescoço"
  },
  {
    "codigo": "S199",
    "descricao": "Traumatismo não especificado do pescoço"
  },
  { "codigo": "S200", "descricao": "Contusão da mama" },
  {
    "codigo": "S201",
    "descricao": "Outros traumatismos superficiais da mama e os não especificados"
  },
  { "codigo": "S202", "descricao": "Contusão do tórax" },
  {
    "codigo": "S203",
    "descricao": "Outros traumatismos superficiais da parede anterior do tórax"
  },
  {
    "codigo": "S204",
    "descricao": "Outros traumatismos superficiais da parede posterior do tórax"
  },
  {
    "codigo": "S207",
    "descricao": "Traumatismos superficiais múltiplos do tórax"
  },
  {
    "codigo": "S208",
    "descricao": "Traumatismo superficial de outras partes especificadas do tórax e das não especificadas"
  },
  { "codigo": "S210", "descricao": "Ferimento da mama" },
  {
    "codigo": "S211",
    "descricao": "Ferimento da parede anterior do tórax"
  },
  {
    "codigo": "S212",
    "descricao": "Ferimento da parede posterior do tórax"
  },
  {
    "codigo": "S217",
    "descricao": "Ferimentos múltiplos da parede torácica"
  },
  { "codigo": "S218", "descricao": "Ferimento de outras partes do tórax" },
  {
    "codigo": "S219",
    "descricao": "Ferimento do tórax parte não especificada"
  },
  { "codigo": "S220", "descricao": "Fratura de vértebra torácica" },
  {
    "codigo": "S221",
    "descricao": "Fraturas múltiplas da coluna torácica"
  },
  { "codigo": "S222", "descricao": "Fratura do esterno" },
  { "codigo": "S223", "descricao": "Fratura de costela" },
  { "codigo": "S224", "descricao": "Fraturas múltiplas de costelas" },
  { "codigo": "S225", "descricao": "Tórax fundido (oscilante)" },
  {
    "codigo": "S228",
    "descricao": "Fraturas de outras partes dos ossos do tórax"
  },
  {
    "codigo": "S229",
    "descricao": "Fratura dos ossos do tórax, parte não especificada"
  },
  {
    "codigo": "S230",
    "descricao": "Ruptura traumática de disco intervertebral torácico"
  },
  {
    "codigo": "S231",
    "descricao": "Luxação de vértebra torácica"
  },
  {
    "codigo": "S232",
    "descricao": "Luxação de outras partes do tórax e das não especificadas"
  },
  {
    "codigo": "S233",
    "descricao": "Entorse e distensão da coluna torácica"
  },
  {
    "codigo": "S234",
    "descricao": "Entorse e distensão das costelas e do esterno"
  },
  {
    "codigo": "S235",
    "descricao": "Entorse e distensão de outras partes do tórax e de partes não especificadas"
  },
  {
    "codigo": "S240",
    "descricao": "Concussão e edema da medula espinhal torácica"
  },
  {
    "codigo": "S241",
    "descricao": "Outros traumatismos da medula espinhal torácica e os não especificados"
  },
  {
    "codigo": "S242",
    "descricao": "Traumatismo da raiz de nervo da coluna torácica"
  },
  {
    "codigo": "S243",
    "descricao": "Traumatismo de nervos periféricos do tórax"
  },
  {
    "codigo": "S244",
    "descricao": "Traumatismo de nervos simpáticos do tórax"
  },
  {
    "codigo": "S245",
    "descricao": "Traumatismo de outros nervos do tórax"
  },
  {
    "codigo": "S246",
    "descricao": "Traumatismo de nervo do tórax não especificado"
  },
  { "codigo": "S250", "descricao": "Traumatismo da aorta torácica" },
  {
    "codigo": "S251",
    "descricao": "Traumatismo da artéria subclávia ou inominada [braquiocefálica]"
  },
  { "codigo": "S252", "descricao": "Traumatismo da veia cava superior" },
  {
    "codigo": "S253",
    "descricao": "Traumatismo da veia subclávia ou inominada [braquiocefálica]"
  },
  {
    "codigo": "S254",
    "descricao": "Traumatismo de vasos sangüíneos pulmonares"
  },
  {
    "codigo": "S255",
    "descricao": "Traumatismo de vasos sangüíneos intercostais"
  },
  {
    "codigo": "S257",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos do tórax"
  },
  {
    "codigo": "S258",
    "descricao": "Traumatismo de outros vasos sangüíneos do tórax"
  },
  {
    "codigo": "S259",
    "descricao": "Traumatismo de vaso sangüíneo não especificado do tórax"
  },
  {
    "codigo": "S260",
    "descricao": "Traumatismo do coração com hemopericárdio"
  },
  { "codigo": "S268", "descricao": "Outros traumatismos do coração" },
  {
    "codigo": "S269",
    "descricao": "Traumatismo do coração não especificado"
  },
  { "codigo": "S270", "descricao": "Pneumotórax traumático" },
  { "codigo": "S271", "descricao": "Hemotórax traumático" },
  { "codigo": "S272", "descricao": "Hemopneumotórax traumático" },
  { "codigo": "S273", "descricao": "Outros traumatismos do pulmão" },
  { "codigo": "S274", "descricao": "Traumatismo dos brônquios" },
  {
    "codigo": "S275",
    "descricao": "Traumatismo da traquéia torácica"
  },
  { "codigo": "S276", "descricao": "Traumatismo da pleura" },
  {
    "codigo": "S277",
    "descricao": "Traumatismos múltiplos de órgãos intratorácicos"
  },
  {
    "codigo": "S278",
    "descricao": "Traumatismo de outros órgãos intratorácicos especificados"
  },
  {
    "codigo": "S279",
    "descricao": "Traumatismo de órgão intratorácico não especificado"
  },
  { "codigo": "S280", "descricao": "Esmagamento do tórax" },
  {
    "codigo": "S281",
    "descricao": "Amputação traumática de parte do tórax"
  },
  {
    "codigo": "S290",
    "descricao": "Traumatismo de músculo e de tendão ao nível tóracico"
  },
  {
    "codigo": "S297",
    "descricao": "Traumatismos múltiplos do tórax"
  },
  {
    "codigo": "S298",
    "descricao": "Outros traumatismos especificados do tórax"
  },
  {
    "codigo": "S299",
    "descricao": "Traumatismo não especificado do tórax"
  },
  { "codigo": "S300", "descricao": "Contusão do dorso e da pelve" },
  { "codigo": "S301", "descricao": "Contusão da parede abdominal" },
  {
    "codigo": "S302",
    "descricao": "Contusão dos órgãos genitais externos"
  },
  {
    "codigo": "S307",
    "descricao": "Traumatismos superficiais múltiplos do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S308",
    "descricao": "Outros traumatismos superficiais do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S309",
    "descricao": "Traumatismo superficial de parte não especificada do abdome, do dorso e da pelve"
  },
  { "codigo": "S310", "descricao": "Ferimento do dorso e da pelve" },
  { "codigo": "S311", "descricao": "Ferimento da parede abdominal" },
  { "codigo": "S312", "descricao": "Ferimento do pênis" },
  { "codigo": "S313", "descricao": "Ferimento do escroto e do testículo" },
  { "codigo": "S314", "descricao": "Ferimento da vagina e da vulva" },
  {
    "codigo": "S315",
    "descricao": "Ferimento de outros órgãos genitais externos e os não especificados"
  },
  {
    "codigo": "S317",
    "descricao": "Ferimentos múltiplos do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S318",
    "descricao": "Ferimento de outras partes e de partes não especificadas do abdome"
  },
  { "codigo": "S320", "descricao": "Fratura de vértebra lombar" },
  { "codigo": "S321", "descricao": "Fratura do sacro" },
  { "codigo": "S322", "descricao": "Fratura do cóccix" },
  { "codigo": "S323", "descricao": "Fratura do ílio" },
  { "codigo": "S324", "descricao": "Fratura do acetábulo" },
  { "codigo": "S325", "descricao": "Fratura do púbis" },
  {
    "codigo": "S327",
    "descricao": "Fraturas múltiplas de coluna lombar e da pelve"
  },
  {
    "codigo": "S328",
    "descricao": "Fratura de outras partes da coluna lombossacra e da pelve e de partes não especificadas"
  },
  {
    "codigo": "S330",
    "descricao": "Ruptura traumática do disco intervertebral lombar"
  },
  {
    "codigo": "S331",
    "descricao": "Luxação da vértebra lombar"
  },
  {
    "codigo": "S332",
    "descricao": "Luxação das articulações sacroilíaca e sacrococcígea"
  },
  {
    "codigo": "S333",
    "descricao": "Luxação de outras partes e das não especificadas da coluna lombar e da pelve"
  },
  {
    "codigo": "S334",
    "descricao": "Ruptura traumática da sínfise púbica"
  },
  {
    "codigo": "S335",
    "descricao": "Entorse e distensão da coluna lombar"
  },
  {
    "codigo": "S336",
    "descricao": "Entorse e distensão da articulação sacroilíaca"
  },
  {
    "codigo": "S337",
    "descricao": "Entorse e distensão de outras partes e das não especificadas da coluna lombar e da pelve"
  },
  { "codigo": "S340", "descricao": "Concussão e edema da medula lombar" },
  { "codigo": "S341", "descricao": "Outro traumatismo da medula lombar" },
  {
    "codigo": "S342",
    "descricao": "Traumatismo de raiz nervosa da medula lombar e sacra"
  },
  { "codigo": "S343", "descricao": "Traumatismo de cauda eqüina" },
  { "codigo": "S344", "descricao": "Traumatismo do plexo lombossacro" },
  {
    "codigo": "S345",
    "descricao": "Traumatismo de nervos simpáticos lombares, sacros e pélvicos"
  },
  {
    "codigo": "S346",
    "descricao": "Traumatismo do(s) nervo(s) periférico(s) do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S348",
    "descricao": "Traumatismo de outros nervos e dos não especificados ao nível do abdome, do dorso e da pelve"
  },
  { "codigo": "S350", "descricao": "Traumatismo da aorta abdominal" },
  { "codigo": "S351", "descricao": "Traumatismo da veia cava inferior" },
  {
    "codigo": "S352",
    "descricao": "Traumatismo da artéria celíaca ou mesentérica"
  },
  {
    "codigo": "S353",
    "descricao": "Traumatismo da veia porta ou esplênica"
  },
  {
    "codigo": "S354",
    "descricao": "Traumatismo de vaso sangüíneo renal"
  },
  {
    "codigo": "S355",
    "descricao": "Traumatismo de vaso sangüíneo ilíaco"
  },
  {
    "codigo": "S357",
    "descricao": "Traumatismo de vasos múltiplos ao nível do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S358",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S359",
    "descricao": "Traumatismo de vaso sangüíneo não especificado ao nível do abdome, do dorso e da pelve"
  },
  { "codigo": "S360", "descricao": "Traumatismo do baço" },
  {
    "codigo": "S361",
    "descricao": "Traumatismo do fígado ou da vesícula biliar"
  },
  { "codigo": "S362", "descricao": "Traumatismo do pâncreas" },
  { "codigo": "S363", "descricao": "Traumatismo do estômago" },
  { "codigo": "S364", "descricao": "Traumatismo do intestino delgado" },
  { "codigo": "S365", "descricao": "Traumatismo do cólon" },
  { "codigo": "S366", "descricao": "Traumatismo do reto" },
  {
    "codigo": "S367",
    "descricao": "Traumatismo de múltiplos órgãos intra-abdominais"
  },
  {
    "codigo": "S368",
    "descricao": "Traumatismo de outros órgãos intra-abdominais"
  },
  {
    "codigo": "S369",
    "descricao": "Traumatismo de órgão intra-abdominal não especificado"
  },
  { "codigo": "S370", "descricao": "Traumatismo do rim" },
  { "codigo": "S371", "descricao": "Traumatismo do ureter" },
  { "codigo": "S372", "descricao": "Traumatismo da bexiga" },
  { "codigo": "S373", "descricao": "Traumatismo da uretra" },
  { "codigo": "S374", "descricao": "Traumatismo do ovário" },
  { "codigo": "S375", "descricao": "Traumatismo da trompa de Falópio" },
  { "codigo": "S376", "descricao": "Traumatismo do útero" },
  {
    "codigo": "S377",
    "descricao": "Traumatismo de múltiplos órgãos pélvicos"
  },
  {
    "codigo": "S378",
    "descricao": "Traumatismo de outros órgãos pélvicos"
  },
  {
    "codigo": "S379",
    "descricao": "Traumatismo de órgão pélvico não especificado"
  },
  {
    "codigo": "S380",
    "descricao": "Lesão por esmagamento dos órgãos genitais externos"
  },
  {
    "codigo": "S381",
    "descricao": "Lesão por esmagamento de outras partes e de partes não especificadas do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S382",
    "descricao": "Amputação traumática de órgãos genitais externos"
  },
  {
    "codigo": "S383",
    "descricao": "Amputação traumática de outras partes e de partes não especificadas do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S390",
    "descricao": "Traumatismo de músculo e de tendão do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S396",
    "descricao": "Traumatismo de órgão(s) intra-abdominal(is) com órgão(s) pélvico(s)"
  },
  {
    "codigo": "S397",
    "descricao": "Outros traumatismos múltiplos do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S398",
    "descricao": "Outros traumatismos especificados do abdome, do dorso e da pelve"
  },
  {
    "codigo": "S399",
    "descricao": "Traumatismo não especificado do abdome, do dorso e da pelve"
  },
  { "codigo": "S400", "descricao": "Contusão do ombro e do braço" },
  {
    "codigo": "S407",
    "descricao": "Traumatismos superficiais múltiplos do ombro e do braço"
  },
  {
    "codigo": "S408",
    "descricao": "Outros traumatismos superficiais do ombro e do braço"
  },
  {
    "codigo": "S409",
    "descricao": "Traumatismo superficial não especificado do ombro e do braço"
  },
  { "codigo": "S410", "descricao": "Ferimento do ombro" },
  { "codigo": "S411", "descricao": "Ferimento do braço" },
  {
    "codigo": "S417",
    "descricao": "Ferimentos múltiplos do ombro e do braço"
  },
  {
    "codigo": "S418",
    "descricao": "Ferimento de outras partes e de partes não especificadas da cintura escapular"
  },
  { "codigo": "S420", "descricao": "Fratura da clavícula" },
  { "codigo": "S421", "descricao": "Fratura da omoplata [escápula]" },
  {
    "codigo": "S422",
    "descricao": "Fratura da extremidade superior do úmero"
  },
  { "codigo": "S423", "descricao": "Fratura da diáfise do úmero" },
  {
    "codigo": "S424",
    "descricao": "Fratura da extremidade inferior do úmero"
  },
  {
    "codigo": "S427",
    "descricao": "Fraturas múltiplas da clavícula, da omoplata [escápula] e do úmero"
  },
  {
    "codigo": "S428",
    "descricao": "Fratura de outras partes do ombro e do braço"
  },
  {
    "codigo": "S429",
    "descricao": "Fratura da cintura escapular, parte não especificada"
  },
  {
    "codigo": "S430",
    "descricao": "Luxação da articulação do ombro"
  },
  {
    "codigo": "S431",
    "descricao": "Luxação da articulação acromioclavicular"
  },
  {
    "codigo": "S432",
    "descricao": "Luxação da articulação esternoclavicular"
  },
  {
    "codigo": "S433",
    "descricao": "Luxação de outras partes e das não especificadas da cintura escapular"
  },
  {
    "codigo": "S434",
    "descricao": "Entorse e distensão de articulação do ombro"
  },
  {
    "codigo": "S435",
    "descricao": "Entorse e distensão da articulação acromioclavicular"
  },
  {
    "codigo": "S436",
    "descricao": "Entorse e distensão da articulação esternoclavicular"
  },
  {
    "codigo": "S437",
    "descricao": "Entorse e distensão de outras partes e de partes não especificadas da cintura escapular"
  },
  {
    "codigo": "S440",
    "descricao": "Traumatismo do nervo cubital [ulnar] ao nível do braço"
  },
  {
    "codigo": "S441",
    "descricao": "Traumatismo do nervo mediano ao nível do braço"
  },
  {
    "codigo": "S442",
    "descricao": "Traumatismo do nervo radial ao nível do braço"
  },
  { "codigo": "S443", "descricao": "Traumatismo do nervo axilar" },
  { "codigo": "S444", "descricao": "Traumatismo do nervo musculocutâneo" },
  {
    "codigo": "S445",
    "descricao": "Traumatismo do nervo sensitivo cutâneo ao nível do ombro e do braço"
  },
  {
    "codigo": "S447",
    "descricao": "Traumatismo de múltiplos nervos ao nível do ombro e do braço"
  },
  {
    "codigo": "S448",
    "descricao": "Traumatismo de outros nervos ao nível do ombro e do braço"
  },
  {
    "codigo": "S449",
    "descricao": "Traumatismo de nervo não especificado ao nível do ombro e do braço"
  },
  { "codigo": "S450", "descricao": "Traumatismo da artéria axilar" },
  { "codigo": "S451", "descricao": "Traumatismo da artéria braquial" },
  { "codigo": "S452", "descricao": "Traumatismo da veia axilar ou braquial" },
  {
    "codigo": "S453",
    "descricao": "Traumatismo de veia superficial ao nível do ombro e do braço"
  },
  {
    "codigo": "S457",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos ao nível do ombro e do braço"
  },
  {
    "codigo": "S458",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível do ombro e do braço"
  },
  {
    "codigo": "S459",
    "descricao": "Traumatismo de vaso sangüíneo não especificado ao nível do ombro e do braço"
  },
  {
    "codigo": "S460",
    "descricao": "Traumatismo do tendão do manguito rotador do ombro"
  },
  {
    "codigo": "S461",
    "descricao": "Traumatismo do músculo e tendão da cabeça longa do bíceps"
  },
  {
    "codigo": "S462",
    "descricao": "Traumatismo do músculo e tendão de outras partes do bíceps"
  },
  {
    "codigo": "S463",
    "descricao": "Traumatismo do músculo e tendão do tríceps"
  },
  {
    "codigo": "S467",
    "descricao": "Traumatismo de múltiplos músculos e tendões ao nível do ombro e do braço"
  },
  {
    "codigo": "S468",
    "descricao": "Traumatismo de outros músculos e tendões ao nível do ombro e do braço"
  },
  {
    "codigo": "S469",
    "descricao": "Traumatismo de músculo e tendão não especificado ao nível do ombro e do braço"
  },
  {
    "codigo": "S47",
    "descricao": "Lesão por esmagamento do ombro e do braço"
  },
  {
    "codigo": "S480",
    "descricao": "Amputação traumática da articulação do ombro"
  },
  {
    "codigo": "S481",
    "descricao": "Amputação traumática de localização entre o ombro e o cotovelo"
  },
  {
    "codigo": "S489",
    "descricao": "Amputação traumática do ombro e do braço, de localização não especificada"
  },
  {
    "codigo": "S497",
    "descricao": "Traumatismos múltiplos do ombro e do braço"
  },
  {
    "codigo": "S498",
    "descricao": "Outros traumatismos especificados do ombro e do braço"
  },
  {
    "codigo": "S499",
    "descricao": "Traumatismo não especificado do ombro e do braço"
  },
  { "codigo": "S500", "descricao": "Contusão do cotovelo" },
  {
    "codigo": "S501",
    "descricao": "Contusão de outras partes e de partes não especificadas do antebraço"
  },
  {
    "codigo": "S507",
    "descricao": "Traumatismos superficiais múltiplos do antebraço"
  },
  {
    "codigo": "S508",
    "descricao": "Outros traumatismos superficiais do antebraço"
  },
  {
    "codigo": "S509",
    "descricao": "Traumatismo superficial do antebraço, não especificado"
  },
  { "codigo": "S510", "descricao": "Ferimento do cotovelo" },
  {
    "codigo": "S517",
    "descricao": "Ferimentos múltiplos do antebraço"
  },
  {
    "codigo": "S518",
    "descricao": "Ferimento de outras partes do antebraço"
  },
  {
    "codigo": "S519",
    "descricao": "Ferimento do antebraço, parte não especificado"
  },
  {
    "codigo": "S520",
    "descricao": "Fratura da extremidade superior do cúbito [ulna]"
  },
  {
    "codigo": "S521",
    "descricao": "Fratura da extremidade superior do rádio"
  },
  {
    "codigo": "S522",
    "descricao": "Fratura da diáfise do cúbito [ulna]"
  },
  { "codigo": "S523", "descricao": "Fratura da diáfise do rádio" },
  {
    "codigo": "S524",
    "descricao": "Fratura das diáfises do rádio e do cúbito [ulna]"
  },
  {
    "codigo": "S525",
    "descricao": "Fratura da extremidade distal do rádio"
  },
  {
    "codigo": "S526",
    "descricao": "Fratura da extremidade distal do rádio e do cúbito [ulna]"
  },
  {
    "codigo": "S527",
    "descricao": "Fraturas múltiplas do antebraço"
  },
  {
    "codigo": "S528",
    "descricao": "Fratura de outras partes do antebraço"
  },
  {
    "codigo": "S529",
    "descricao": "Fratura do antebraço, parte não especificada"
  },
  {
    "codigo": "S530",
    "descricao": "Luxação da cabeça do rádio"
  },
  {
    "codigo": "S531",
    "descricao": "Luxação do cotovelo, não especificada"
  },
  {
    "codigo": "S532",
    "descricao": "Ruptura traumática do ligamento colateral do rádio"
  },
  {
    "codigo": "S533",
    "descricao": "Ruptura traumática do ligamento colateral do cúbito [ulna]"
  },
  { "codigo": "S534", "descricao": "Entorse e distensão do cotovelo" },
  {
    "codigo": "S540",
    "descricao": "Traumatismo do nervo cubital [ulnar] ao nível do antebraço"
  },
  {
    "codigo": "S541",
    "descricao": "Traumatismo do nervo mediano ao nível do antebraço"
  },
  {
    "codigo": "S542",
    "descricao": "Traumatismo do nervo radial ao nível do antebraço"
  },
  {
    "codigo": "S543",
    "descricao": "Traumatismo do nervo cutâneo sensitivo ao nível do antebraço"
  },
  {
    "codigo": "S547",
    "descricao": "Traumatismo de múltiplos nervos ao nível do antebraço"
  },
  {
    "codigo": "S548",
    "descricao": "Traumatismo de outros nervos ao nível do antebraço"
  },
  {
    "codigo": "S549",
    "descricao": "Traumatismo de nervo não especificado ao nível do antebraço"
  },
  {
    "codigo": "S550",
    "descricao": "Traumatismo da artéria cubital [ulnar] ao nível do antebraço"
  },
  {
    "codigo": "S551",
    "descricao": "Traumatismo da artéria radial ao nível do antebraço"
  },
  {
    "codigo": "S552",
    "descricao": "Traumatismo de veia ao nível do antebraço"
  },
  {
    "codigo": "S557",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos ao nível do antebraço"
  },
  {
    "codigo": "S558",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível do antebraço"
  },
  {
    "codigo": "S559",
    "descricao": "Traumatismo de vasos sangüíneos não especificados ao nível do antebraço"
  },
  {
    "codigo": "S560",
    "descricao": "Traumatismo do músculo flexor e tendão do polegar ao nível do antebraço"
  },
  {
    "codigo": "S561",
    "descricao": "Traumatismo do músculo flexor e tendão de outro(s) dedo(s) ao nível do antebraço"
  },
  {
    "codigo": "S562",
    "descricao": "Traumatismo de outro músculo flexor e tendão ao nível do antebraço"
  },
  {
    "codigo": "S563",
    "descricao": "Traumatismo dos músculos extensor ou abdutor e tendões do polegar ao nível do antebraço"
  },
  {
    "codigo": "S564",
    "descricao": "Traumatismo do músculo extensor e tendão de outro(s) dedo(s) ao nível do antebraço"
  },
  {
    "codigo": "S565",
    "descricao": "Traumatismo de outros músculos extensores e tendões ao nível do antebraço"
  },
  {
    "codigo": "S567",
    "descricao": "Traumatismo de músculos e tendões múltiplos ao nível do antebraço"
  },
  {
    "codigo": "S568",
    "descricao": "Traumatismo de outros músculos e tendões e dos não especificados ao nível do antebraço"
  },
  { "codigo": "S570", "descricao": "Lesão por esmagamento do cotovelo" },
  {
    "codigo": "S578",
    "descricao": "Lesão por esmagamento de outras partes do antebraço"
  },
  {
    "codigo": "S579",
    "descricao": "Lesão por esmagamento de parte não especificada do antebraço"
  },
  {
    "codigo": "S580",
    "descricao": "Amputação traumática ao nível do cotovelo"
  },
  {
    "codigo": "S581",
    "descricao": "Amputação traumática do antebraço entre o cotovelo e o punho"
  },
  {
    "codigo": "S589",
    "descricao": "Amputação traumática do antebraço, nível não especificado"
  },
  { "codigo": "S597", "descricao": "Traumatismos múltiplos do cotovelo" },
  {
    "codigo": "S598",
    "descricao": "Outros traumatismos especificados do antebraço"
  },
  {
    "codigo": "S599",
    "descricao": "Traumatismo não especificado do antebraço"
  },
  {
    "codigo": "S600",
    "descricao": "Contusão de dedo(s) sem lesão da unha"
  },
  {
    "codigo": "S601",
    "descricao": "Contusão de dedo(s) com lesão da unha"
  },
  {
    "codigo": "S602",
    "descricao": "Contusão de outras partes do punho e da mão"
  },
  {
    "codigo": "S607",
    "descricao": "Traumatismos superficiais múltiplos do punho e da mão"
  },
  {
    "codigo": "S608",
    "descricao": "Outros traumatismos superficiais do punho e da mão"
  },
  {
    "codigo": "S609",
    "descricao": "Traumatismo superficial não especificado do punho e da mão"
  },
  {
    "codigo": "S610",
    "descricao": "Ferimento de dedo(s) sem lesão da unha"
  },
  {
    "codigo": "S611",
    "descricao": "Ferimento de dedo(s) com lesão da unha"
  },
  {
    "codigo": "S617",
    "descricao": "Ferimentos múltiplos do punho e da mão"
  },
  {
    "codigo": "S618",
    "descricao": "Ferimento de outras partes do punho e da mão"
  },
  {
    "codigo": "S619",
    "descricao": "Ferimento do punho e da mão, parte não especificada"
  },
  {
    "codigo": "S620",
    "descricao": "Fratura do osso navicular [escafoíde] da mão"
  },
  { "codigo": "S621", "descricao": "Fratura de outro(s) osso(s) do carpo" },
  { "codigo": "S622", "descricao": "Fratura do primeiro metacarpiano" },
  { "codigo": "S623", "descricao": "Fratura de outros ossos do metacarpo" },
  {
    "codigo": "S624",
    "descricao": "Fraturas múltiplas de ossos metacarpianos"
  },
  { "codigo": "S625", "descricao": "Fratura do polegar" },
  { "codigo": "S626", "descricao": "Fratura de outros dedos" },
  { "codigo": "S627", "descricao": "Fraturas múltiplas de dedo(s)" },
  {
    "codigo": "S628",
    "descricao": "Fratura de outras partes e de partes não especificadas do punho e da mão"
  },
  { "codigo": "S630", "descricao": "Luxação do punho" },
  { "codigo": "S631", "descricao": "Luxação do dedo" },
  {
    "codigo": "S632",
    "descricao": "Luxações múltiplas dos dedos"
  },
  {
    "codigo": "S633",
    "descricao": "Ruptura traumática de ligamento(s) do punho e do carpo"
  },
  {
    "codigo": "S634",
    "descricao": "Ruptura traumática do ligamento do dedo nas articulações metacarpofalangianas e interfalangianas"
  },
  { "codigo": "S635", "descricao": "Entorse e distensão do punho" },
  { "codigo": "S636", "descricao": "Entorse e distensão do(s) dedo(s)" },
  {
    "codigo": "S637",
    "descricao": "Entorse e distensão de outras partes e das não especificadas da mão"
  },
  {
    "codigo": "S640",
    "descricao": "Traumatismo do nervo cubital [ulnar] ao nível do punho e da mão"
  },
  {
    "codigo": "S641",
    "descricao": "Traumatismo do nervo mediano ao nível do punho e da mão"
  },
  {
    "codigo": "S642",
    "descricao": "Traumatismo do nervo radial ao nível do punho e da mão"
  },
  { "codigo": "S643", "descricao": "Traumatismo do nervo digital do polegar" },
  {
    "codigo": "S644",
    "descricao": "Traumatismo do nervo digital de outro dedo"
  },
  {
    "codigo": "S647",
    "descricao": "Traumatismo de múltiplos nervos ao nível de punho e da mão"
  },
  {
    "codigo": "S648",
    "descricao": "Traumatismo de outros nervos ao nível do punho e da mão"
  },
  {
    "codigo": "S649",
    "descricao": "Traumatismo de nervo não especificado ao nível do punho e da mão"
  },
  {
    "codigo": "S650",
    "descricao": "Traumatismo da artéria cubital [ulnar] ao nível do punho e da mão"
  },
  {
    "codigo": "S651",
    "descricao": "Traumatismo da artéria radial ao nível do punho e da mão"
  },
  { "codigo": "S652", "descricao": "Traumatismo do arco palmar superficial" },
  { "codigo": "S653", "descricao": "Traumatismo do arco palmar profundo" },
  {
    "codigo": "S654",
    "descricao": "Traumatismo de vaso(s) sangüíneo(s) do polegar"
  },
  {
    "codigo": "S655",
    "descricao": "Traumatismo de vaso(s) sangüíneo(s) de outro dedo"
  },
  {
    "codigo": "S657",
    "descricao": "Traumatismo de vasos sangüíneos múltiplos ao nível de punho e da mão"
  },
  {
    "codigo": "S658",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível do punho e de mão"
  },
  {
    "codigo": "S659",
    "descricao": "Traumatismo de vaso sangüíneo não especificado ao nível do punho e da mão"
  },
  {
    "codigo": "S660",
    "descricao": "Traumatismo do músculo flexor longo e tendão do polegar ao nível do punho e da mão"
  },
  {
    "codigo": "S661",
    "descricao": "Traumatismo do músculo flexor e tendão de outro dedo ao nível do punho e da mão"
  },
  {
    "codigo": "S662",
    "descricao": "Traumatismo do músculo extensor e tendão do polegar ao nível do punho e da mão"
  },
  {
    "codigo": "S663",
    "descricao": "Traumatismo do músculo extensor e tendão de outro dedo ao nível do punho e da mão"
  },
  {
    "codigo": "S664",
    "descricao": "Traumatismo do músculo intrínseco e tendão do polegar ao nível do punho e da mão"
  },
  {
    "codigo": "S665",
    "descricao": "Traumatismo do músculo intrínseco e tendão de outro dedo ao nível do punho e da mão"
  },
  {
    "codigo": "S666",
    "descricao": "Traumatismo de músculos flexores e tendões múltiplos ao nível do punho e da mão"
  },
  {
    "codigo": "S667",
    "descricao": "Traumatismo de músculos extensores e tendões múltiplos ao nível do punho e da mão"
  },
  {
    "codigo": "S668",
    "descricao": "Traumatismo de outros músculos e tendões ao nível do punho e da mão"
  },
  {
    "codigo": "S669",
    "descricao": "Traumatismo de músculo e tendão não especificado ao nível do punho e da mão"
  },
  {
    "codigo": "S670",
    "descricao": "Lesão por esmagamento do polegar e de outro(s) dedo(s)"
  },
  {
    "codigo": "S678",
    "descricao": "Lesão por esmagamento de outras partes e das não especificadas do punho e da mão"
  },
  {
    "codigo": "S680",
    "descricao": "Amputação traumática do polegar (completa) (parcial)"
  },
  {
    "codigo": "S681",
    "descricao": "Amputação traumática de um outro dedo apenas (completa) (parcial)"
  },
  {
    "codigo": "S682",
    "descricao": "Amputação traumática de dois ou mais dedos somente (completa) (parcial)"
  },
  {
    "codigo": "S683",
    "descricao": "Amputação traumática combinada de (partes de) dedo(s) associada a outras partes do punho e da mão"
  },
  {
    "codigo": "S684",
    "descricao": "Amputação traumática da mão ao nível do punho"
  },
  {
    "codigo": "S688",
    "descricao": "Amputação traumática de outras partes do punho e da mão"
  },
  {
    "codigo": "S689",
    "descricao": "Amputação traumática do punho e da mão, nível não especificado"
  },
  {
    "codigo": "S697",
    "descricao": "Traumatismos múltiplos do punho e da mão"
  },
  {
    "codigo": "S698",
    "descricao": "Outros traumatismos especificados do punho e da mão"
  },
  {
    "codigo": "S699",
    "descricao": "Traumatismo não especificados do punho e da mão"
  },
  { "codigo": "S700", "descricao": "Contusão do quadril" },
  { "codigo": "S701", "descricao": "Contusão da coxa" },
  {
    "codigo": "S707",
    "descricao": "Traumatismos superficiais múltiplos do quadril e da coxa"
  },
  {
    "codigo": "S708",
    "descricao": "Outros traumatismos superficiais do quadril e da coxa"
  },
  {
    "codigo": "S709",
    "descricao": "Traumatismo superficial não especificado do quadril e da coxa"
  },
  { "codigo": "S710", "descricao": "Ferimento do quadril" },
  { "codigo": "S711", "descricao": "Ferimento da coxa" },
  {
    "codigo": "S717",
    "descricao": "Ferimentos múltiplos do quadril e da coxa"
  },
  {
    "codigo": "S718",
    "descricao": "Ferimentos de outras partes e das não especificadas da cintura pélvica"
  },
  { "codigo": "S720", "descricao": "Fratura do colo do fêmur" },
  { "codigo": "S721", "descricao": "Fratura pertrocantérica" },
  { "codigo": "S722", "descricao": "Fratura subtrocantérica" },
  { "codigo": "S723", "descricao": "Fratura da diáfise do fêmur" },
  {
    "codigo": "S724",
    "descricao": "Fratura da extremidade distal do fêmur"
  },
  { "codigo": "S727", "descricao": "Fraturas múltiplas do fêmur" },
  { "codigo": "S728", "descricao": "Fraturas de outras partes do fêmur" },
  {
    "codigo": "S729",
    "descricao": "Fratura do fêmur, parte não especificada"
  },
  {
    "codigo": "S730",
    "descricao": "Luxação da articulação do quadril"
  },
  { "codigo": "S731", "descricao": "Entorse e distensão do quadril" },
  {
    "codigo": "S740",
    "descricao": "Traumatismo do nervo ciático ao nível do quadril e da coxa"
  },
  {
    "codigo": "S741",
    "descricao": "Traumatismo do nervo femural ao nível do quadril e da coxa"
  },
  {
    "codigo": "S742",
    "descricao": "Traumatismo do nervo sensitivo cutâneo ao nível do quadril e da coxa"
  },
  {
    "codigo": "S747",
    "descricao": "Traumatismo de nervos múltiplos ao nível do quadril e da coxa"
  },
  {
    "codigo": "S748",
    "descricao": "Traumatismo de outros nervos ao nível do quadril e da coxa"
  },
  {
    "codigo": "S749",
    "descricao": "Traumatismo de nervo não especificado ao nível do quadril e da coxa"
  },
  { "codigo": "S750", "descricao": "Traumatismo da artéria femural" },
  {
    "codigo": "S751",
    "descricao": "Traumatismo da veia femural ao nível do quadril e da coxa"
  },
  {
    "codigo": "S752",
    "descricao": "Traumatismo da veia grande safena ao nível do quadril e da coxa"
  },
  {
    "codigo": "S757",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos ao nível do quadril e da coxa"
  },
  {
    "codigo": "S758",
    "descricao": "Traumatismo de outros vaso sangüíneos ao nível do quadril e da coxa"
  },
  {
    "codigo": "S759",
    "descricao": "Traumatismo de vaso sangüíneo não especificado ao nível do quadril e da coxa"
  },
  {
    "codigo": "S760",
    "descricao": "Traumatismo do músculo e do tendão do quadril"
  },
  {
    "codigo": "S761",
    "descricao": "Traumatismo do músculo e do tendão do quadríceps"
  },
  {
    "codigo": "S762",
    "descricao": "Traumatismo do músculo e do tendão do adutor da coxa"
  },
  {
    "codigo": "S763",
    "descricao": "Traumatismo do músculo e do tendão dos músculos posteriores ao nível da coxa"
  },
  {
    "codigo": "S764",
    "descricao": "Traumatismo de outros músculos e tendões e os não especificados ao nível da coxa"
  },
  {
    "codigo": "S767",
    "descricao": "Traumatismo de múltiplos músculos e tendões ao nível do quadril e da coxa"
  },
  { "codigo": "S770", "descricao": "Lesão por esmagamento do quadril" },
  { "codigo": "S771", "descricao": "Lesão por esmagamento da coxa" },
  {
    "codigo": "S772",
    "descricao": "Lesão por esmagamento do quadril e da coxa"
  },
  {
    "codigo": "S780",
    "descricao": "Amputação traumática na articulação do quadril"
  },
  {
    "codigo": "S781",
    "descricao": "Amputação traumática localizada entre o joelho e o quadril"
  },
  {
    "codigo": "S789",
    "descricao": "Amputação traumática do quadril e coxa nível não especificado"
  },
  {
    "codigo": "S797",
    "descricao": "Traumatismos múltiplos do quadril e da coxa"
  },
  {
    "codigo": "S798",
    "descricao": "Outros traumatismos especificados do quadril e da coxa"
  },
  {
    "codigo": "S799",
    "descricao": "Traumatismo não especificado do quadril e da coxa"
  },
  { "codigo": "S800", "descricao": "Contusão do joelho" },
  {
    "codigo": "S801",
    "descricao": "Contusão de outras partes e de partes não especificadas da perna"
  },
  {
    "codigo": "S807",
    "descricao": "Traumatismos superficiais múltiplos da perna"
  },
  {
    "codigo": "S808",
    "descricao": "Outros traumatismos superficiais da perna"
  },
  {
    "codigo": "S809",
    "descricao": "Traumatismo superficial não especificado da perna"
  },
  { "codigo": "S810", "descricao": "Ferimento do joelho" },
  { "codigo": "S817", "descricao": "Ferimentos múltiplos da perna" },
  { "codigo": "S818", "descricao": "Ferimento de outras partes da perna" },
  {
    "codigo": "S819",
    "descricao": "Ferimento da perna, parte não especificada"
  },
  { "codigo": "S820", "descricao": "Fratura da rótula [patela]" },
  {
    "codigo": "S821",
    "descricao": "Fratura da extremidade proximal da tíbia"
  },
  { "codigo": "S822", "descricao": "Fratura da diáfise da tíbia" },
  {
    "codigo": "S823",
    "descricao": "Fratura da extremidade distal da tíbia"
  },
  { "codigo": "S824", "descricao": "Fratura do perônio [fíbula]" },
  { "codigo": "S825", "descricao": "Fratura do maléolo medial" },
  { "codigo": "S826", "descricao": "Fratura do maléolo lateral" },
  { "codigo": "S827", "descricao": "Fraturas múltiplas da perna" },
  { "codigo": "S828", "descricao": "Fratura de outras partes da perna" },
  {
    "codigo": "S829",
    "descricao": "Fratura da perna, parte não especificada"
  },
  {
    "codigo": "S830",
    "descricao": "Luxação da rótula [patela]"
  },
  { "codigo": "S831", "descricao": "Luxação do joelho" },
  { "codigo": "S832", "descricao": "Ruptura do menisco, atual" },
  {
    "codigo": "S833",
    "descricao": "Ruptura atual da cartilagem da articulação do joelho"
  },
  {
    "codigo": "S834",
    "descricao": "Entorse e distensão envolvendo ligamento colateral (peronial) (tibial) do joelho"
  },
  {
    "codigo": "S835",
    "descricao": "Entorse e distensão envolvendo ligamento cruzado (anterior) (posterior) do joelho"
  },
  {
    "codigo": "S836",
    "descricao": "Entorse e distensão de outras partes e das não especificadas do joelho"
  },
  {
    "codigo": "S837",
    "descricao": "Traumatismo de estruturas múltiplas do joelho"
  },
  {
    "codigo": "S840",
    "descricao": "Traumatismo do nervo tibial ao nível da perna"
  },
  {
    "codigo": "S841",
    "descricao": "Traumatismo do nervo peronial ao nível da perna"
  },
  {
    "codigo": "S842",
    "descricao": "Traumatismo do nervo cutâneo sensitivo ao nível da perna"
  },
  {
    "codigo": "S847",
    "descricao": "Traumatismo de múltiplos nervos ao nível da perna"
  },
  {
    "codigo": "S848",
    "descricao": "Traumatismo de outros nervos ao nível da perna"
  },
  {
    "codigo": "S849",
    "descricao": "Traumatismo de nervo não especificado ao nível da perna"
  },
  {
    "codigo": "S850",
    "descricao": "Traumatismo da artéria poplítea"
  },
  {
    "codigo": "S851",
    "descricao": "Traumatismo da artéria tibial (anterior) (posterior)"
  },
  { "codigo": "S852", "descricao": "Traumatismo da artéria peronial" },
  {
    "codigo": "S853",
    "descricao": "Traumatismo da veia grande safena ao nível da perna"
  },
  {
    "codigo": "S854",
    "descricao": "Traumatismo da veia safena menor ao nível da perna"
  },
  { "codigo": "S855", "descricao": "Traumatismo da veia poplítea" },
  {
    "codigo": "S857",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos ao nível da perna"
  },
  {
    "codigo": "S858",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível da perna"
  },
  {
    "codigo": "S859",
    "descricao": "Traumatismo de vasos sangüíneos não especificados ao nível da perna"
  },
  { "codigo": "S860", "descricao": "Traumatismo do tendão de Aquiles" },
  {
    "codigo": "S861",
    "descricao": "Traumatismo de outro(s) músculo(s) e tendão(ões) do grupo muscular posterior ao nível da perna"
  },
  {
    "codigo": "S862",
    "descricao": "Traumatismo do(s) músculo(s) e tendão(ões) do grupo muscular anterior ao nível da perna"
  },
  {
    "codigo": "S863",
    "descricao": "Traumatismo do(s) músculo(s) e tendão(ões) do grupo muscular peronial ao nível da perna"
  },
  {
    "codigo": "S867",
    "descricao": "Traumatismo de múltiplos músculos e tendões ao nível da perna"
  },
  {
    "codigo": "S868",
    "descricao": "Traumatismos de outros músculos e tendões ao nível da perna"
  },
  {
    "codigo": "S869",
    "descricao": "Traumatismo de músculo e de tendão não especificado ao nível da perna"
  },
  { "codigo": "S870", "descricao": "Traumatismo por esmagamento do joelho" },
  {
    "codigo": "S878",
    "descricao": "Traumatismo por esmagamento de outra parte e de partes não especificadas da perna"
  },
  {
    "codigo": "S880",
    "descricao": "Amputação traumática ao nível do joelho"
  },
  {
    "codigo": "S881",
    "descricao": "Amputação traumática entre o joelho e o tornozelo"
  },
  {
    "codigo": "S889",
    "descricao": "Amputação traumática da perna ao nível não especificado"
  },
  { "codigo": "S890", "descricao": "Traumatismos múltiplos da perna" },
  {
    "codigo": "S898",
    "descricao": "Outros traumatismos especificados da perna"
  },
  {
    "codigo": "S899",
    "descricao": "Traumatismo não especificado da perna"
  },
  { "codigo": "S900", "descricao": "Contusão do tornozelo" },
  {
    "codigo": "S901",
    "descricao": "Contusão de artelho sem lesão da unha"
  },
  {
    "codigo": "S902",
    "descricao": "Contusão de artelho(s) com lesão da unha"
  },
  {
    "codigo": "S903",
    "descricao": "Contusão de outras partes e partes não especificadas do pé"
  },
  {
    "codigo": "S907",
    "descricao": "Traumatismos superficiais múltiplos do tornozelo e do pé"
  },
  {
    "codigo": "S908",
    "descricao": "Outros traumatismos superficiais do tornozelo e do pé"
  },
  {
    "codigo": "S909",
    "descricao": "Traumatismo superficial do tornozelo e do pé, não especificado"
  },
  { "codigo": "S910", "descricao": "Ferimento do tornozelo" },
  {
    "codigo": "S911",
    "descricao": "Ferimento do(s) artelho(s) sem lesão da unha"
  },
  {
    "codigo": "S912",
    "descricao": "Ferimento do(s) artelho(s) com lesão da unha"
  },
  { "codigo": "S913", "descricao": "Ferimento de outras partes do pé" },
  {
    "codigo": "S917",
    "descricao": "Ferimentos múltiplos do tornozelo e do pé"
  },
  { "codigo": "S920", "descricao": "Fratura do calcâneo" },
  { "codigo": "S921", "descricao": "Fratura do astrágalo" },
  { "codigo": "S922", "descricao": "Fratura de outros ossos do tarso" },
  { "codigo": "S923", "descricao": "Fratura de ossos do metatarso" },
  { "codigo": "S924", "descricao": "Fratura do hálux" },
  { "codigo": "S925", "descricao": "Fratura de outro artelho" },
  { "codigo": "S927", "descricao": "Fraturas múltiplas do pé" },
  { "codigo": "S929", "descricao": "Fratura do pé não especificada" },
  {
    "codigo": "S930",
    "descricao": "Luxação da articulação do tornozelo"
  },
  { "codigo": "S931", "descricao": "Luxação do(s) artelho(s)" },
  {
    "codigo": "S932",
    "descricao": "Ruptura de ligamentos ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S933",
    "descricao": "Luxação de outras partes e das não especificadas do pé"
  },
  { "codigo": "S934", "descricao": "Entorse e distensão do tornozelo" },
  {
    "codigo": "S935",
    "descricao": "Entorse e distensão do(s) artelho(s)"
  },
  {
    "codigo": "S936",
    "descricao": "Entorse e distensão de outras partes e de partes não especificadas do pé"
  },
  {
    "codigo": "S940",
    "descricao": "Traumatismo do nervo plantar externo (lateral)"
  },
  {
    "codigo": "S941",
    "descricao": "Traumatismo do nervo plantar interno (medial)"
  },
  {
    "codigo": "S942",
    "descricao": "Traumatismo nervo peronial [fibular] profundo ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S943",
    "descricao": "Traumatismo do nervo sensitivo cutâneo ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S947",
    "descricao": "Traumatismo de múltiplos nervos ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S948",
    "descricao": "Traumatismo de outros nervos ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S949",
    "descricao": "Traumatismo de nervo não especificado, ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S950",
    "descricao": "Traumatismo da artéria dorsal do pé"
  },
  {
    "codigo": "S951",
    "descricao": "Traumatismo da artéria plantar do pé"
  },
  { "codigo": "S952", "descricao": "Traumatismo da veia dorsal do pé" },
  {
    "codigo": "S957",
    "descricao": "Traumatismo de múltiplos vasos sangüíneos ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S958",
    "descricao": "Traumatismo de outros vasos sangüíneos ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S959",
    "descricao": "Traumatismo de vaso sangüíneo não especificado ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S960",
    "descricao": "Traumatismo do músculo e tendão do músculo flexor longo do(s) artelho(s) ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S961",
    "descricao": "Traumatismo do músculo e tendão do extensor longo do(s) artelho(s) ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S962",
    "descricao": "Traumatismo de músculo intrínseco e tendão ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S967",
    "descricao": "Traumatismo de múltiplos músculos e tendões ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S968",
    "descricao": "Traumatismo de outros tendões e músculos ao nível do tornozelo e do pé"
  },
  {
    "codigo": "S969",
    "descricao": "Traumatismo de músculo e tendão não especificados do tornozelo e do pé"
  },
  { "codigo": "S970", "descricao": "Lesão por esmagamento do tornozelo" },
  {
    "codigo": "S971",
    "descricao": "Lesão por esmagamento do(s) artelho(s)"
  },
  {
    "codigo": "S978",
    "descricao": "Lesão por esmagamento de outras partes do tornozelo e do pé"
  },
  {
    "codigo": "S980",
    "descricao": "Amputação traumática do pé ao nível do tornozelo"
  },
  {
    "codigo": "S981",
    "descricao": "Amputação traumática de apenas um artelho"
  },
  {
    "codigo": "S982",
    "descricao": "Amputação traumática de dois ou mais artelhos"
  },
  {
    "codigo": "S983",
    "descricao": "Amputação traumática de outras partes do pé"
  },
  {
    "codigo": "S984",
    "descricao": "Amputação traumática do pé ao nível não especificado"
  },
  {
    "codigo": "S997",
    "descricao": "Traumatismos múltiplos do tornozelo e do pé"
  },
  {
    "codigo": "S998",
    "descricao": "Outros traumatismos especificados do tornozelo e do pé"
  },
  {
    "codigo": "S999",
    "descricao": "Traumatismos não especificados do tornozelo e do pé"
  },
  {
    "codigo": "T000",
    "descricao": "Traumatismos superficiais envolvendo a cabeça com o pescoço"
  },
  {
    "codigo": "T001",
    "descricao": "Traumatismos superficiais envolvendo o tórax com o abdome, parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T002",
    "descricao": "Traumatismos superficiais envolvendo regiões múltiplas do(s) membro(s) superior(es)"
  },
  {
    "codigo": "T003",
    "descricao": "Traumatismos superficiais envolvendo regiões múltiplas do(s) membro(s) inferior(es)"
  },
  {
    "codigo": "T006",
    "descricao": "Traumatismos superficiais envolvendo regiões múltiplas dos membros superiores com membro(s) inferiores"
  },
  {
    "codigo": "T008",
    "descricao": "Traumatismos superficiais envolvendo outras combinações de regiões do corpo"
  },
  {
    "codigo": "T009",
    "descricao": "Traumatismos superficiais múltiplos não especificados"
  },
  {
    "codigo": "T010",
    "descricao": "Ferimentos envolvendo a cabeça com o pescoço"
  },
  {
    "codigo": "T011",
    "descricao": "Ferimentos envolvendo o tórax com o abdome, parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T012",
    "descricao": "Ferimentos envolvendo regiões múltiplas do(s) membro(s) superior(es)"
  },
  {
    "codigo": "T013",
    "descricao": "Ferimentos envolvendo múltiplas regiões do(s) membro(s) inferior(es)"
  },
  {
    "codigo": "T016",
    "descricao": "Ferimentos envolvendo regiões múltiplas do(s) membro(s) superior(es) com membro(s) inferior(es)"
  },
  {
    "codigo": "T018",
    "descricao": "Ferimentos envolvendo outras combinações de regiões do corpo"
  },
  {
    "codigo": "T019",
    "descricao": "Ferimentos múltiplos não especificados"
  },
  {
    "codigo": "T020",
    "descricao": "Fraturas envolvendo cabeça com pescoço"
  },
  {
    "codigo": "T021",
    "descricao": "Fraturas envolvendo tórax com parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T022",
    "descricao": "Fraturas envolvendo regiões múltiplas de um membro superior"
  },
  {
    "codigo": "T023",
    "descricao": "Fraturas envolvendo regiões múltiplas de um membro inferior"
  },
  {
    "codigo": "T024",
    "descricao": "Fraturas envolvendo regiões múltiplas de ambos os membros superiores"
  },
  {
    "codigo": "T025",
    "descricao": "Fraturas envolvendo regiões múltiplas de ambos os membros inferiores"
  },
  {
    "codigo": "T026",
    "descricao": "Fraturas envolvendo regiões múltiplas do(s) membro(s) superior(es) com inferior(es)"
  },
  {
    "codigo": "T027",
    "descricao": "Fraturas envolvendo tórax com parte inferior do dorso e pelve com membro(s)"
  },
  {
    "codigo": "T028",
    "descricao": "Fraturas envolvendo outras combinações de regiões do corpo"
  },
  {
    "codigo": "T029",
    "descricao": "Fraturas múltiplas não especificadas"
  },
  {
    "codigo": "T030",
    "descricao": "Luxações, entorses e distensões envolvendo a cabeça com o pescoço"
  },
  {
    "codigo": "T031",
    "descricao": "Luxações, entorses e distensões envolvendo tórax com parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T032",
    "descricao": "Luxações, entorses e distensões envolvendo regiões múltiplas de membro(s) superior(es)"
  },
  {
    "codigo": "T033",
    "descricao": "Luxações, entorses e distensões envolvendo regiões múltiplas do(s) membro(s) inferior(es)"
  },
  {
    "codigo": "T034",
    "descricao": "Luxações, entorses e distensões envolvendo regiões múltiplas dos membros superiores com inferiores"
  },
  {
    "codigo": "T038",
    "descricao": "Luxações, entorses e distensões envolvendo outras combinações de regiões do corpo"
  },
  {
    "codigo": "T039",
    "descricao": "Luxações, entorses e distensões múltiplas, não especificadas"
  },
  {
    "codigo": "T040",
    "descricao": "Traumatismos por esmagamento envolvendo a cabeça com o pescoço"
  },
  {
    "codigo": "T041",
    "descricao": "Traumatismos por esmagamento envolvendo tórax com o abdome, parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T042",
    "descricao": "Traumatismos por esmagamento envolvendo regiões múltiplas do(s) membro(s) superior(es)"
  },
  {
    "codigo": "T043",
    "descricao": "Traumatismos por esmagamento envolvendo regiões múltiplas do(s) membro(s) inferior(es)"
  },
  {
    "codigo": "T044",
    "descricao": "Traumatismos por esmagamento envolvendo regiões múltiplas do(s) membro(s) superior(es) com inferior(es)"
  },
  {
    "codigo": "T047",
    "descricao": "Traumatismo por esmagamento do tórax com abdome, parte inferior do dorso, pelve e membro(s)"
  },
  {
    "codigo": "T048",
    "descricao": "Traumatismos por esmagamento envolvendo outras combinações de regiões do corpo"
  },
  {
    "codigo": "T049",
    "descricao": "Traumatismo múltiplos por esmagamento não especificados"
  },
  {
    "codigo": "T050",
    "descricao": "Amputação traumática de ambas as mãos"
  },
  {
    "codigo": "T051",
    "descricao": "Amputação traumática de uma mão e de um outro braço [qualquer nível, exceto mão]"
  },
  {
    "codigo": "T052",
    "descricao": "Amputação traumática de ambos os braços [qualquer nível]"
  },
  {
    "codigo": "T053",
    "descricao": "Amputação traumática de ambos os pés"
  },
  {
    "codigo": "T054",
    "descricao": "Amputação traumática de um pé e outra perna [qualquer nível, exceto pé]"
  },
  {
    "codigo": "T055",
    "descricao": "Amputação traumática de ambas as pernas [qualquer nível]"
  },
  {
    "codigo": "T056",
    "descricao": "Amputação traumática de membros superiores e inferiores, qualquer combinação [qualquer nível]"
  },
  {
    "codigo": "T058",
    "descricao": "Amputações traumáticas envolvendo outras combinações de regiões do corpo"
  },
  {
    "codigo": "T059",
    "descricao": "Amputações traumáticas múltiplas não especificadas"
  },
  {
    "codigo": "T060",
    "descricao": "Traumatismos de cérebro e nervos cranianos com traumatismos de nervos e da medula espinhal ao nível do pescoço"
  },
  {
    "codigo": "T061",
    "descricao": "Traumatismos de nervos e da medula espinhal envolvendo outras regiões múltiplas do corpo"
  },
  {
    "codigo": "T062",
    "descricao": "Traumatismos de nervos envolvendo regiões múltiplas do corpo"
  },
  {
    "codigo": "T063",
    "descricao": "Traumatismos de vasos sangüíneos envolvendo regiões múltiplas do corpo"
  },
  {
    "codigo": "T064",
    "descricao": "Traumatismos de músculos e de tendões envolvendo regiões múltiplas do corpo"
  },
  {
    "codigo": "T065",
    "descricao": "Traumatismos de órgãos intratorácicos com órgãos intra-abdominais e pélvicos"
  },
  {
    "codigo": "T068",
    "descricao": "Outros traumatismos especificados envolvendo regiões múltiplas do corpo"
  },
  {
    "codigo": "T07",
    "descricao": "Traumatismos múltiplos não especificados"
  },
  {
    "codigo": "T08",
    "descricao": "Fratura da coluna, nível não especificado"
  },
  {
    "codigo": "T090",
    "descricao": "Traumatismo superficial do tronco, nível não especificado"
  },
  {
    "codigo": "T091",
    "descricao": "Ferimento do tronco, nível não especificado"
  },
  {
    "codigo": "T092",
    "descricao": "Luxação, entorse e distensão de articulações e ligamentos não especificados do tronco"
  },
  {
    "codigo": "T093",
    "descricao": "Traumatismo de medula espinhal, nível não especificado"
  },
  {
    "codigo": "T094",
    "descricao": "Traumatismo de nervos, de raízes de nervos espinhais e de plexos não especificados do tronco"
  },
  {
    "codigo": "T095",
    "descricao": "Traumatismo de músculo e tendão não especificados do tronco"
  },
  {
    "codigo": "T096",
    "descricao": "Amputação traumática do tronco, nível não especificado"
  },
  {
    "codigo": "T098",
    "descricao": "Outros traumatismos especificados do tronco, nível não especificado"
  },
  {
    "codigo": "T099",
    "descricao": "Traumatismo não especificado do tronco, nível não especificado"
  },
  {
    "codigo": "T10",
    "descricao": "Fratura do membro superior, nível não especificado"
  },
  {
    "codigo": "T110",
    "descricao": "Traumatismo superficial do membro superior, nível não especificado"
  },
  {
    "codigo": "T111",
    "descricao": "Ferimento do membro superior, nível não especificado"
  },
  {
    "codigo": "T112",
    "descricao": "Luxação, entorse e distensão de articulação e ligamento não especificados do membro superior, nível não especificado"
  },
  {
    "codigo": "T113",
    "descricao": "Traumatismo de nervo não especificado do membro superior, nível não especificado"
  },
  {
    "codigo": "T114",
    "descricao": "Traumatismo de vaso sangüíneo não especificado, do membro superior, nível não especificado"
  },
  {
    "codigo": "T115",
    "descricao": "Traumatismo de músculo e tendão não especificado do membro superior, nível não especificado"
  },
  {
    "codigo": "T116",
    "descricao": "Amputação traumática do membro superior, nível não especificado"
  },
  {
    "codigo": "T118",
    "descricao": "Outros traumatismos especificados do membro superior nível não especificado"
  },
  {
    "codigo": "T119",
    "descricao": "Traumatismo não especificado do membro superior nível não especificado"
  },
  {
    "codigo": "T12",
    "descricao": "Fratura do membro inferior, nível não especificado"
  },
  {
    "codigo": "T130",
    "descricao": "Traumatismo superficial de membro inferior, nível não especificado"
  },
  {
    "codigo": "T131",
    "descricao": "Ferimento de membro inferior, nível não especificado"
  },
  {
    "codigo": "T132",
    "descricao": "Luxação, entorse e distensão de articulação e ligamento não especificados de membro inferior, nível não especificado"
  },
  {
    "codigo": "T133",
    "descricao": "Traumatismo de nervo não especificado de membro inferior, nível não especificado"
  },
  {
    "codigo": "T134",
    "descricao": "Traumatismo de vaso sangüíneo não especificado de membro inferior, nível não especificado"
  },
  {
    "codigo": "T135",
    "descricao": "Traumatismo de músculo e tendão não especificado de membro inferior, nível não especificado"
  },
  {
    "codigo": "T136",
    "descricao": "Amputação traumática de membro inferior, nível não especificado"
  },
  {
    "codigo": "T138",
    "descricao": "Outros traumatismos especificados de membro inferior, nível não especificado"
  },
  {
    "codigo": "T139",
    "descricao": "Traumatismo não especificado do membro inferior, nível não especificado"
  },
  {
    "codigo": "T140",
    "descricao": "Traumatismo superficial de região não especificada do corpo"
  },
  {
    "codigo": "T141",
    "descricao": "Ferimento de região não especificada do corpo"
  },
  {
    "codigo": "T142",
    "descricao": "Fratura de região não especificada do corpo"
  },
  {
    "codigo": "T143",
    "descricao": "Luxação, entorse e distensão de região não especificada do corpo"
  },
  {
    "codigo": "T144",
    "descricao": "Traumatismo de nervo(s) de região não especificada do corpo"
  },
  {
    "codigo": "T145",
    "descricao": "Traumatismo de vaso(s) sangüíneo(s) de região não especificada do corpo"
  },
  {
    "codigo": "T146",
    "descricao": "Traumatismo de músculos e tendões de região não especificada do corpo"
  },
  {
    "codigo": "T147",
    "descricao": "Traumatismo por esmagamento e amputação traumática de regiões não especificadas do corpo"
  },
  {
    "codigo": "T148",
    "descricao": "Outros traumatismos de região não especificada do corpo"
  },
  { "codigo": "T149", "descricao": "Traumatismo não especificado" },
  { "codigo": "T150", "descricao": "Corpo estranho na córnea" },
  { "codigo": "T151", "descricao": "Corpo estranho no saco conjuntival" },
  {
    "codigo": "T158",
    "descricao": "Corpo estranho em outros locais e em locais múltiplos da parte externa do olho"
  },
  {
    "codigo": "T159",
    "descricao": "Corpo estranho em parte não especificada da região externa do olho"
  },
  { "codigo": "T16", "descricao": "Corpo estranho no ouvido" },
  { "codigo": "T170", "descricao": "Corpo estranho no seio nasal" },
  { "codigo": "T171", "descricao": "Corpo estranho na narina" },
  { "codigo": "T172", "descricao": "Corpo estranho na faringe" },
  { "codigo": "T173", "descricao": "Corpo estranho na laringe" },
  { "codigo": "T174", "descricao": "Corpo estranho na traquéia" },
  { "codigo": "T175", "descricao": "Corpo estranho no brônquio" },
  {
    "codigo": "T178",
    "descricao": "Corpo estranho em outras partes e partes múltiplas do trato respiratório"
  },
  {
    "codigo": "T179",
    "descricao": "Corpo estranho no trato respiratório, parte não especificada"
  },
  { "codigo": "T180", "descricao": "Corpo estranho na boca" },
  { "codigo": "T181", "descricao": "Corpo estranho no esôfago" },
  { "codigo": "T182", "descricao": "Corpo estranho no estômago" },
  { "codigo": "T183", "descricao": "Corpo estranho no intestino delgado" },
  { "codigo": "T184", "descricao": "Corpo estranho no cólon" },
  { "codigo": "T185", "descricao": "Corpo estranho no ânus e reto" },
  {
    "codigo": "T188",
    "descricao": "Corpo estranho em outras partes e partes múltiplas do aparelho digestivo"
  },
  {
    "codigo": "T189",
    "descricao": "Corpo estranho em parte não especificada do aparelho digestivo"
  },
  { "codigo": "T190", "descricao": "Corpo estranho na uretra" },
  { "codigo": "T191", "descricao": "Corpo estranho na bexiga" },
  { "codigo": "T192", "descricao": "Corpo estranho na vulva e vagina" },
  {
    "codigo": "T193",
    "descricao": "Corpo estranho no útero [qualquer parte]"
  },
  {
    "codigo": "T198",
    "descricao": "Corpo estranho em outras partes e partes múltiplas do trato geniturinário"
  },
  {
    "codigo": "T199",
    "descricao": "Corpo estranho no trato geniturinário, parte não especificada"
  },
  {
    "codigo": "T200",
    "descricao": "Queimadura da cabeça e do pescoço, grau não especificado"
  },
  {
    "codigo": "T201",
    "descricao": "Queimadura de primeiro grau da cabeça e do pescoço"
  },
  {
    "codigo": "T202",
    "descricao": "Queimadura de segundo grau da cabeça e do pescoço"
  },
  {
    "codigo": "T203",
    "descricao": "Queimadura de terceiro grau da cabeça e do pescoço"
  },
  {
    "codigo": "T204",
    "descricao": "Corrosão da cabeça e do pescoço, grau não especificado"
  },
  {
    "codigo": "T205",
    "descricao": "Corrosão de primeiro grau da cabeça e do pescoço"
  },
  {
    "codigo": "T206",
    "descricao": "Corrosão de segundo grau da cabeça e do pescoço"
  },
  {
    "codigo": "T207",
    "descricao": "Corrosão de terceiro grau da cabeça e do pescoço"
  },
  {
    "codigo": "T210",
    "descricao": "Queimadura do tronco, grau não especificado"
  },
  { "codigo": "T211", "descricao": "Queimadura de primeiro grau do tronco" },
  { "codigo": "T212", "descricao": "Queimadura de segundo grau do tronco" },
  { "codigo": "T213", "descricao": "Queimadura de terceiro grau do tronco" },
  {
    "codigo": "T214",
    "descricao": "Corrosão do tronco, grau não especificado"
  },
  { "codigo": "T215", "descricao": "Corrosão de primeiro grau do tronco" },
  { "codigo": "T216", "descricao": "Corrosão de segundo grau do tronco" },
  { "codigo": "T217", "descricao": "Corrosão de terceiro grau do tronco" },
  {
    "codigo": "T220",
    "descricao": "Queimadura do ombro e do membro superior, exceto punho e mão, grau não especificado"
  },
  {
    "codigo": "T221",
    "descricao": "Queimadura de primeiro grau do ombro e do membro superior, exceto punho e mão"
  },
  {
    "codigo": "T222",
    "descricao": "Queimadura de segundo grau do ombro e do membro superior, exceto punho e mão"
  },
  {
    "codigo": "T223",
    "descricao": "Queimadura de terceiro grau do ombro e do membro superior, exceto punho e mão"
  },
  {
    "codigo": "T224",
    "descricao": "Corrosão do ombro e do membro superior, exceto punho e mão, grau não especificado"
  },
  {
    "codigo": "T225",
    "descricao": "Corrosão de primeiro grau do ombro e do membro superior, exceto punho e mão"
  },
  {
    "codigo": "T226",
    "descricao": "Corrosão de segundo grau do ombro e do membro superior, exceto punho e mão"
  },
  {
    "codigo": "T227",
    "descricao": "Corrosão de terceiro grau do ombro e do membro superior, exceto punho e mão"
  },
  {
    "codigo": "T230",
    "descricao": "Queimadura do punho e da mão, grau não especificado"
  },
  {
    "codigo": "T231",
    "descricao": "Queimadura de primeiro grau do punho e da mão"
  },
  {
    "codigo": "T232",
    "descricao": "Queimadura de segundo grau do punho e da mão"
  },
  {
    "codigo": "T233",
    "descricao": "Queimadura de terceiro grau do punho e da mão"
  },
  {
    "codigo": "T234",
    "descricao": "Corrosão do punho e da mão, grau não especificado"
  },
  {
    "codigo": "T235",
    "descricao": "Corrosão de primeiro grau do punho e da mão"
  },
  {
    "codigo": "T236",
    "descricao": "Corrosão de segundo grau do punho e da mão"
  },
  {
    "codigo": "T237",
    "descricao": "Corrosão de terceiro grau do punho e da mão"
  },
  {
    "codigo": "T240",
    "descricao": "Queimadura do quadril e membro inferior, exceto tornozelo e do pé, grau não especificado"
  },
  {
    "codigo": "T241",
    "descricao": "Queimadura de primeiro grau do quadril e do membro inferior, exceto tornozelo e do pé"
  },
  {
    "codigo": "T242",
    "descricao": "Queimadura de segundo grau do quadril e do membro inferior, exceto tornozelo e do pé"
  },
  {
    "codigo": "T243",
    "descricao": "Queimadura de terceiro grau do quadril e do membro inferior, exceto tornozelo e do pé"
  },
  {
    "codigo": "T244",
    "descricao": "Corrosão do quadril e do membro inferior, exceto tornozelo e do pé, grau não especificado"
  },
  {
    "codigo": "T245",
    "descricao": "Corrosão de primeiro grau do quadril e do membro inferior, exceto tornozelo e do pé"
  },
  {
    "codigo": "T246",
    "descricao": "Corrosão de segundo grau do quadril e do membro inferior, exceto tornozelo e do pé"
  },
  {
    "codigo": "T247",
    "descricao": "Corrosão de terceiro grau do quadril e do membro inferior, exceto tornozelo e do pé"
  },
  {
    "codigo": "T250",
    "descricao": "Queimadura do tornozelo e do pé, grau não especificado"
  },
  {
    "codigo": "T251",
    "descricao": "Queimadura de primeiro grau do tornozelo e do pé"
  },
  {
    "codigo": "T252",
    "descricao": "Queimadura de segundo grau do tornozelo e do pé"
  },
  {
    "codigo": "T253",
    "descricao": "Queimadura de terceiro grau do tornozelo e do pé"
  },
  {
    "codigo": "T254",
    "descricao": "Corrosão do tornozelo e do pé, grau não especificado"
  },
  {
    "codigo": "T255",
    "descricao": "Corrosão de primeiro grau do tornozelo e do pé"
  },
  {
    "codigo": "T256",
    "descricao": "Corrosão de segundo grau do tornozelo e do pé"
  },
  {
    "codigo": "T257",
    "descricao": "Corrosão de terceiro grau do tornozelo e do pé"
  },
  {
    "codigo": "T260",
    "descricao": "Queimadura da pálpebra e da região periocular"
  },
  {
    "codigo": "T261",
    "descricao": "Queimadura da córnea e do saco conjuntival"
  },
  {
    "codigo": "T262",
    "descricao": "Queimadura com resultante ruptura e destruição do globo ocular"
  },
  {
    "codigo": "T263",
    "descricao": "Queimadura de outras partes do olho e anexos"
  },
  {
    "codigo": "T264",
    "descricao": "Queimadura do olho e anexos, parte não especificada"
  },
  {
    "codigo": "T265",
    "descricao": "Corrosão da pálpebra e da região periocular"
  },
  {
    "codigo": "T266",
    "descricao": "Corrosão da córnea e do saco conjuntival"
  },
  {
    "codigo": "T267",
    "descricao": "Corrosão com resultante ruptura e destruição do globo ocular"
  },
  {
    "codigo": "T268",
    "descricao": "Corrosão de outras partes do olho e anexos"
  },
  {
    "codigo": "T269",
    "descricao": "Corrosão do olho e anexos, parte não especificada"
  },
  { "codigo": "T270", "descricao": "Queimadura da laringe e traquéia" },
  {
    "codigo": "T271",
    "descricao": "Queimadura da laringe, traquéia com pulmão"
  },
  {
    "codigo": "T272",
    "descricao": "Queimadura de outras partes do trato respiratório"
  },
  {
    "codigo": "T273",
    "descricao": "Queimadura do trato respiratório, parte não especificada"
  },
  { "codigo": "T274", "descricao": "Corrosão da laringe e traquéia" },
  {
    "codigo": "T275",
    "descricao": "Corrosão da laringe, traquéia com corrosão de pulmão"
  },
  {
    "codigo": "T276",
    "descricao": "Corrosão de outras partes do trato respiratório"
  },
  {
    "codigo": "T277",
    "descricao": "Corrosão do trato respiratório, parte não especificada"
  },
  { "codigo": "T280", "descricao": "Queimadura da boca e da faringe" },
  { "codigo": "T281", "descricao": "Queimadura do esôfago" },
  {
    "codigo": "T282",
    "descricao": "Queimadura de outras partes do trato alimentar"
  },
  {
    "codigo": "T283",
    "descricao": "Queimadura dos órgãos geniturinários internos"
  },
  {
    "codigo": "T284",
    "descricao": "Queimadura de outros órgãos internos e dos não especificados"
  },
  { "codigo": "T285", "descricao": "Corrosão da boca e faringe" },
  { "codigo": "T286", "descricao": "Corrosão do esôfago" },
  {
    "codigo": "T287",
    "descricao": "Corrosão de outras partes do trato alimentar"
  },
  {
    "codigo": "T288",
    "descricao": "Corrosão de órgãos geniturinários internos"
  },
  {
    "codigo": "T289",
    "descricao": "Corrosão de outros órgãos internos e dos não especificados"
  },
  {
    "codigo": "T290",
    "descricao": "Queimaduras múltiplas, grau não especificado"
  },
  {
    "codigo": "T291",
    "descricao": "Queimaduras múltiplas, sem mencionar queimadura(s) ultrapassando o primeiro grau"
  },
  {
    "codigo": "T292",
    "descricao": "Queimaduras múltiplas, sem mencionar queimadura(s) ultrapassando o segundo grau"
  },
  {
    "codigo": "T293",
    "descricao": "Queimaduras múltiplas, mencionando ao menos uma queimadura de terceiro grau"
  },
  {
    "codigo": "T294",
    "descricao": "Corrosões múltiplas, grau não especificado"
  },
  {
    "codigo": "T295",
    "descricao": "Corrosões múltiplas, sem mencionar corrosão(ões) ultrapassando o primeiro grau"
  },
  {
    "codigo": "T296",
    "descricao": "Corrosões múltiplas, sem mencionar corrosão(ões) ultrapassando o segundo grau"
  },
  {
    "codigo": "T297",
    "descricao": "Corrosões múltiplas, mencionado ao menos uma corrosão de terceiro grau"
  },
  {
    "codigo": "T300",
    "descricao": "Queimadura, parte do corpo não especificada, grau não especificado"
  },
  {
    "codigo": "T301",
    "descricao": "Queimadura de primeiro grau, parte do corpo não especificada"
  },
  {
    "codigo": "T302",
    "descricao": "Queimadura de segundo grau, parte do corpo não especificada"
  },
  {
    "codigo": "T303",
    "descricao": "Queimadura de terceiro grau, parte do corpo não especificada"
  },
  {
    "codigo": "T304",
    "descricao": "Corrosão, parte do corpo não especificada, grau não especificado"
  },
  {
    "codigo": "T305",
    "descricao": "Corrosão de primeiro grau, parte do corpo não especificada"
  },
  {
    "codigo": "T306",
    "descricao": "Corrosão de segundo grau, parte do corpo não especificada"
  },
  {
    "codigo": "T307",
    "descricao": "Corrosão de terceiro grau, parte do corpo não especificada"
  },
  {
    "codigo": "T310",
    "descricao": "Queimaduras envolvendo menos de 10% da superfície corporal"
  },
  {
    "codigo": "T311",
    "descricao": "Queimaduras envolvendo de 10 - 19% da superfície corporal"
  },
  {
    "codigo": "T312",
    "descricao": "Queimaduras envolvendo de 20 - 29% da superfície corporal"
  },
  {
    "codigo": "T313",
    "descricao": "Queimaduras envolvendo de 30 - 39% da superfície corporal"
  },
  {
    "codigo": "T314",
    "descricao": "Queimaduras envolvendo de 40 - 49% da superfície corporal"
  },
  {
    "codigo": "T315",
    "descricao": "Queimaduras envolvendo de 50 - 59% da superfície corporal"
  },
  {
    "codigo": "T316",
    "descricao": "Queimaduras envolvendo de 60 - 69% da superfície corporal"
  },
  {
    "codigo": "T317",
    "descricao": "Queimaduras envolvendo de 70 - 79% da superfície corporal"
  },
  {
    "codigo": "T318",
    "descricao": "Queimaduras envolvendo de 80 - 89% da superfície corporal"
  },
  {
    "codigo": "T319",
    "descricao": "Queimaduras envolvendo 90% ou mais da superfície corporal"
  },
  {
    "codigo": "T320",
    "descricao": "Corrosões envolvendo menos de 10% da superfície corporal"
  },
  {
    "codigo": "T321",
    "descricao": "Corrosões envolvendo 10 - 19% da superfície corporal"
  },
  {
    "codigo": "T322",
    "descricao": "Corrosões envolvendo 20 - 29% da superfície corporal"
  },
  {
    "codigo": "T323",
    "descricao": "Corrosões envolvendo 30 - 39% da superfície corporal"
  },
  {
    "codigo": "T324",
    "descricao": "Corrosões envolvendo 40 - 49% da superfície corporal"
  },
  {
    "codigo": "T325",
    "descricao": "Corrosões envolvendo 50 - 59% da superfície corporal"
  },
  {
    "codigo": "T326",
    "descricao": "Corrosões envolvendo 60 - 69% da superfície corporal"
  },
  {
    "codigo": "T327",
    "descricao": "Corrosões envolvendo 70 - 79% da superfície corporal"
  },
  {
    "codigo": "T328",
    "descricao": "Corrosões envolvendo 80 - 89% da superfície corporal"
  },
  {
    "codigo": "T329",
    "descricao": "Corrosões envolvendo 90% ou mais da superfície corporal"
  },
  { "codigo": "T330", "descricao": "Geladura superficial da cabeça" },
  { "codigo": "T331", "descricao": "Geladura superficial do pescoço" },
  { "codigo": "T332", "descricao": "Geladura superficial do tórax" },
  {
    "codigo": "T333",
    "descricao": "Geladura superficial da parede abdominal, parte inferior do dorso e da pelve"
  },
  { "codigo": "T334", "descricao": "Geladura superficial do braço" },
  {
    "codigo": "T335",
    "descricao": "Geladura superficial do punho e da mão"
  },
  {
    "codigo": "T336",
    "descricao": "Geladura superficial do quadril e da coxa"
  },
  {
    "codigo": "T337",
    "descricao": "Geladura superficial do joelho e da perna"
  },
  {
    "codigo": "T338",
    "descricao": "Geladura superficial do tornozelo e do pé"
  },
  {
    "codigo": "T339",
    "descricao": "Geladura superficial de outros locais e de locais não especificados"
  },
  {
    "codigo": "T340",
    "descricao": "Geladura, com necrose de tecidos, da cabeça"
  },
  {
    "codigo": "T341",
    "descricao": "Geladura, com necrose de tecidos, do pescoço"
  },
  {
    "codigo": "T342",
    "descricao": "Geladura, com necrose de tecidos, do tórax"
  },
  {
    "codigo": "T343",
    "descricao": "Geladura, com necrose de tecidos, da parede abdominal, parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T344",
    "descricao": "Geladura, com necrose de tecidos, do braço"
  },
  {
    "codigo": "T345",
    "descricao": "Geladura, com necrose de tecidos, do punho e da mão"
  },
  {
    "codigo": "T346",
    "descricao": "Geladura, com necrose de tecidos, do quadril e da coxa"
  },
  {
    "codigo": "T347",
    "descricao": "Geladura, com necrose de tecidos, do joelho e da perna"
  },
  {
    "codigo": "T348",
    "descricao": "Geladura, com necrose de tecidos, do tornozelo e do pé"
  },
  {
    "codigo": "T349",
    "descricao": "Geladura, com necrose de tecidos, de localização não especificada"
  },
  {
    "codigo": "T350",
    "descricao": "Geladura superficial envolvendo múltiplas partes do corpo"
  },
  {
    "codigo": "T351",
    "descricao": "Geladura, com necrose de tecidos, de múltiplas partes do corpo"
  },
  {
    "codigo": "T352",
    "descricao": "Geladura, de grau não especificado, da cabeça e do pescoço"
  },
  {
    "codigo": "T353",
    "descricao": "Geladura, de grau não especificado, do tórax, abdome, parte inferior do dorso e da pelve"
  },
  {
    "codigo": "T354",
    "descricao": "Geladura, de grau não especificado, do membro superior"
  },
  {
    "codigo": "T355",
    "descricao": "Geladura, de grau não especificado, do membro inferior"
  },
  {
    "codigo": "T356",
    "descricao": "Geladura, de grau não especificado, de múltiplas partes do corpo"
  },
  {
    "codigo": "T357",
    "descricao": "Geladura, de grau não especificado, de localização não especificada"
  },
  { "codigo": "T360", "descricao": "Intoxicação por penicilinas" },
  {
    "codigo": "T361",
    "descricao": "Intoxicação por cefalosporinas e outras betalactaminas"
  },
  {
    "codigo": "T362",
    "descricao": "Intoxicação pelo grupo do cloranfenicol"
  },
  {
    "codigo": "T363",
    "descricao": "Intoxicação por macrolídios"
  },
  { "codigo": "T364", "descricao": "Intoxicação por tetraciclinas" },
  {
    "codigo": "T365",
    "descricao": "Intoxicação por aminoglicosídeos"
  },
  { "codigo": "T366", "descricao": "Intoxicação por rifamicinas" },
  {
    "codigo": "T367",
    "descricao": "Intoxicação por antibióticos antifúngicos administrados por via sistêmica"
  },
  {
    "codigo": "T368",
    "descricao": "Intoxicação por outros antibióticos sistêmicos"
  },
  {
    "codigo": "T369",
    "descricao": "Intoxicação por antibióticos sistêmicos não especificados"
  },
  { "codigo": "T370", "descricao": "Intoxicação por sulfonamidas" },
  {
    "codigo": "T371",
    "descricao": "Intoxicação por drogas antimicobacterianas"
  },
  {
    "codigo": "T372",
    "descricao": "Intoxicação por antimaláricos e drogas que atuam sobre outros protozoários do sangue"
  },
  {
    "codigo": "T373",
    "descricao": "Intoxicação por outros medicamentos antiprotozoários"
  },
  {
    "codigo": "T374",
    "descricao": "Intoxicação por anti-helmínticos"
  },
  {
    "codigo": "T375",
    "descricao": "Intoxicação por drogas antivirais"
  },
  {
    "codigo": "T378",
    "descricao": "Intoxicação por outros agentes antiinfecciosos e antiparasitários especificados"
  },
  {
    "codigo": "T379",
    "descricao": "Intoxicação por agentes antiinfecciosos e antiparasitários sistêmicos não especificados"
  },
  {
    "codigo": "T380",
    "descricao": "Intoxicação por glicocorticóides e análogos sintéticos"
  },
  {
    "codigo": "T381",
    "descricao": "Intoxicação por hormônios tireoidianos e seus derivados"
  },
  {
    "codigo": "T382",
    "descricao": "Intoxicação por medicamentos antitireoidianos"
  },
  {
    "codigo": "T383",
    "descricao": "Intoxicação por insulina e drogas hipoglicemiantes orais [antidiabéticos]"
  },
  {
    "codigo": "T384",
    "descricao": "Intoxicação por anticoncepcionais orais"
  },
  {
    "codigo": "T385",
    "descricao": "Intoxicação por outros estrógenos e progestágenos"
  },
  {
    "codigo": "T386",
    "descricao": "Intoxicação por antigonadotrofinas, antiestrógenos, antiandrógenos não classificados em outra parte"
  },
  {
    "codigo": "T387",
    "descricao": "Intoxicação por andrógenos e anabolizantes congêneres"
  },
  {
    "codigo": "T388",
    "descricao": "Intoxicação por outros hormônios e substitutos sintéticos, e os não especificados"
  },
  {
    "codigo": "T389",
    "descricao": "Intoxicação por outros antagonistas hormonais, e os não especificados"
  },
  { "codigo": "T390", "descricao": "Intoxicação por salicilatos" },
  {
    "codigo": "T391",
    "descricao": "Intoxicação por derivados do 4-aminofenol"
  },
  {
    "codigo": "T392",
    "descricao": "Intoxicação por derivados pirazolônicos"
  },
  {
    "codigo": "T393",
    "descricao": "Intoxicação por outros antiinflamatórios não esteróides"
  },
  {
    "codigo": "T394",
    "descricao": "Intoxicação por anti-reumáticos não classificados em outra parte"
  },
  {
    "codigo": "T398",
    "descricao": "Intoxicação por outros analgésicos não opiáceos e antipiréticos não classificados em outra parte"
  },
  {
    "codigo": "T399",
    "descricao": "Intoxicação por analgésico não-opiáceo, antipirético e anti-reumático, não especificados"
  },
  { "codigo": "T400", "descricao": "Intoxicação por ópio" },
  { "codigo": "T401", "descricao": "Intoxicação por heroína" },
  {
    "codigo": "T402",
    "descricao": "Intoxicação por outros opiáceos"
  },
  { "codigo": "T403", "descricao": "Intoxicação por metadona" },
  {
    "codigo": "T404",
    "descricao": "Intoxicação por outros narcóticos sintéticos"
  },
  { "codigo": "T405", "descricao": "Intoxicação por cocaína" },
  {
    "codigo": "T406",
    "descricao": "Intoxicação por outros narcóticos e os não especificados"
  },
  {
    "codigo": "T407",
    "descricao": "Intoxicação por cannabis (derivados)"
  },
  {
    "codigo": "T408",
    "descricao": "Intoxicação por lisergida [LSD]"
  },
  {
    "codigo": "T409",
    "descricao": "Intoxicação por outros psicodislépticos e os não especificados [alucinógenos]"
  },
  {
    "codigo": "T410",
    "descricao": "Intoxicação por anestésicos inalatórios"
  },
  {
    "codigo": "T411",
    "descricao": "Intoxicação por anestésicos intravenosos"
  },
  {
    "codigo": "T412",
    "descricao": "Intoxicação por outros anestésicos gerais e os não especificados"
  },
  {
    "codigo": "T413",
    "descricao": "Intoxicação por anestésicos locais"
  },
  {
    "codigo": "T414",
    "descricao": "Intoxicação por anestésico não especificado"
  },
  {
    "codigo": "T415",
    "descricao": "Intoxicação por gases terapêuticos"
  },
  {
    "codigo": "T420",
    "descricao": "Intoxicação por derivados da hidantoína"
  },
  { "codigo": "T421", "descricao": "Intoxicação por iminostilbenos" },
  {
    "codigo": "T422",
    "descricao": "Intoxicação por succinimidas e oxazolidinadionas"
  },
  {
    "codigo": "T423",
    "descricao": "Intoxicação por barbitúricos"
  },
  {
    "codigo": "T424",
    "descricao": "Intoxicação por benzodiazepinas"
  },
  {
    "codigo": "T425",
    "descricao": "Intoxicação por antiepiléticos em associação, não classificados em outra parte"
  },
  {
    "codigo": "T426",
    "descricao": "Intoxicação por outras drogas antiepilépticas e sedativos-hipnóticos"
  },
  {
    "codigo": "T427",
    "descricao": "Intoxicação por drogas antiepilépticos, sedativos-hipnóticos, não especificados"
  },
  {
    "codigo": "T428",
    "descricao": "Intoxicação por drogas antiparkinsonianos e outros depressores centrais do tônus muscular"
  },
  {
    "codigo": "T430",
    "descricao": "Intoxicação por antidepressivos tricíclicos e tetracíclicos"
  },
  {
    "codigo": "T431",
    "descricao": "Intoxicação por antidepressivos inibidores da monoaminooxidase (MAO)"
  },
  {
    "codigo": "T432",
    "descricao": "Intoxicação por outros antidepressivos e os não especificados"
  },
  {
    "codigo": "T433",
    "descricao": "Intoxicação por antipsicóticos e neurolépticos derivados da fenotiazina"
  },
  {
    "codigo": "T434",
    "descricao": "Intoxicação por neurolépticos do tipo butirofenona e tioxanteno"
  },
  {
    "codigo": "T435",
    "descricao": "Intoxicação por outros antipsicóticos e neurolépticos e os não especificados"
  },
  {
    "codigo": "T436",
    "descricao": "Intoxicação por psicoestimulantes que potencialmente podem provocar dependência"
  },
  {
    "codigo": "T438",
    "descricao": "Intoxicação por outras drogas psicotrópicas não classificadas em outra parte"
  },
  {
    "codigo": "T439",
    "descricao": "Intoxicação por droga psicotrópica não especificada"
  },
  {
    "codigo": "T440",
    "descricao": "Intoxicação por agentes anticolinesterase"
  },
  {
    "codigo": "T441",
    "descricao": "Intoxicação por outros agentes parassimpaticomiméticos [colinérgicos]"
  },
  {
    "codigo": "T442",
    "descricao": "Intoxicação por bloqueadores ganglionares não classificados em outra parte"
  },
  {
    "codigo": "T443",
    "descricao": "Intoxicação por outros parassimpaticolíticos [anticolinérgicos e antimuscarínicos] e espasmolíticos não classificados em outra parte"
  },
  {
    "codigo": "T444",
    "descricao": "Intoxicação por agonistas principalmente alfa-adrenorreceptores não classificados em outra parte"
  },
  {
    "codigo": "T445",
    "descricao": "Intoxicação por agonistas principalmente beta-adrenorreceptores não classificados em outra parte"
  },
  {
    "codigo": "T446",
    "descricao": "Intoxicação por antagonistas alfa-adrenorreceptores não classificados em outra parte"
  },
  {
    "codigo": "T447",
    "descricao": "Intoxicação por antagonistas beta-adrenorreceptores não classificados em outra parte"
  },
  {
    "codigo": "T448",
    "descricao": "Intoxicação por bloqueadores neuronais adrenérgicos e de ação central, não classificados em outra parte"
  },
  {
    "codigo": "T449",
    "descricao": "Intoxicação por outras drogas que afetam principalmente o sistema nervoso autônomo e as não especificadas"
  },
  {
    "codigo": "T450",
    "descricao": "Intoxicação por drogas antialérgicas e antieméticas"
  },
  {
    "codigo": "T451",
    "descricao": "Intoxicação por drogas antineoplásicas e imunomupressoras"
  },
  {
    "codigo": "T452",
    "descricao": "Intoxicação por vitaminas, não classificadas em outra parte"
  },
  {
    "codigo": "T453",
    "descricao": "Intoxicação por enzimas, não classificadas em outra parte"
  },
  {
    "codigo": "T454",
    "descricao": "Intoxicação por ferro e seus compostos"
  },
  {
    "codigo": "T455",
    "descricao": "Intoxicação por anticoagulantes"
  },
  {
    "codigo": "T456",
    "descricao": "Intoxicação por drogas que afetam a fibrinólise"
  },
  {
    "codigo": "T457",
    "descricao": "Intoxicação por antagonistas de anticoagulantes, vitamina K e de outros fatores de coagulação"
  },
  {
    "codigo": "T458",
    "descricao": "Intoxicação por outras substâncias primariamente sistêmicas e hematológicas"
  },
  {
    "codigo": "T459",
    "descricao": "Intoxicação por substância essencialmente sistêmica e hematológica não especificada"
  },
  {
    "codigo": "T460",
    "descricao": "Intoxicação por glicosídeos estimulantes do coração e substâncias de ação semelhante"
  },
  {
    "codigo": "T461",
    "descricao": "Intoxicação por bloqueadores dos canais de cálcio"
  },
  {
    "codigo": "T462",
    "descricao": "Intoxicação por outras drogas antidisrítmicas não classificadas em outra parte"
  },
  {
    "codigo": "T463",
    "descricao": "Intoxicação por vasodilatadores coronarianos, não classificados em outra parte"
  },
  {
    "codigo": "T464",
    "descricao": "Intoxicação por inibidores da enzima de conversão da angiotensina"
  },
  {
    "codigo": "T465",
    "descricao": "Intoxicação por outras drogas anti-hipertensivas não classificadas em outra parte"
  },
  {
    "codigo": "T466",
    "descricao": "Intoxicação por drogas antihiperlipêmicas e antiarterioscleróticas"
  },
  {
    "codigo": "T467",
    "descricao": "Intoxicação por vasodilatadores periféricos"
  },
  {
    "codigo": "T468",
    "descricao": "Intoxicação por drogas antivaricosas, incluindo substâncias esclerosantes"
  },
  {
    "codigo": "T469",
    "descricao": "Intoxicação por outras substâncias e as não especificadas que agem essencialmente sobre o aparelho circulatório"
  },
  {
    "codigo": "T470",
    "descricao": "Intoxicação por antagonistas dos receptores H2 da histamina"
  },
  {
    "codigo": "T471",
    "descricao": "Intoxicação por outros antiácidos e drogas que inibem a secreção gástrica"
  },
  {
    "codigo": "T472",
    "descricao": "Intoxicação por laxativos estimulantes"
  },
  {
    "codigo": "T473",
    "descricao": "Intoxicação por laxativos salinos e osmóticos"
  },
  {
    "codigo": "T474",
    "descricao": "Intoxicação por outros laxativos"
  },
  { "codigo": "T475", "descricao": "Intoxicação por digestivos" },
  {
    "codigo": "T476",
    "descricao": "Intoxicação por drogas antidiarréicas"
  },
  { "codigo": "T477", "descricao": "Intoxicação por eméticos" },
  {
    "codigo": "T478",
    "descricao": "Intoxicação por outras substâncias que atuam primariamente sobre o aparelho gastrintestinal"
  },
  {
    "codigo": "T479",
    "descricao": "Intoxicação por substância não especificada que atua primariamente sobre o aparelho gastrointestinal"
  },
  {
    "codigo": "T480",
    "descricao": "Intoxicação por drogas ocitócicas"
  },
  {
    "codigo": "T481",
    "descricao": "Intoxicação por relaxantes musculares esqueléticos [bloqueadores neuromusculares]"
  },
  {
    "codigo": "T482",
    "descricao": "Intoxicação por outras substâncias e as não especificadas que atuam sobre os músculos"
  },
  {
    "codigo": "T483",
    "descricao": "Intoxicação por antitussígenos"
  },
  { "codigo": "T484", "descricao": "Intoxicação por expectorantes" },
  {
    "codigo": "T485",
    "descricao": "Intoxicação por drogas anti-resfriado"
  },
  {
    "codigo": "T486",
    "descricao": "Intoxicação por antiasmáticos não classificados em outra parte"
  },
  {
    "codigo": "T487",
    "descricao": "Intoxicação por outras substâncias e as não especificadas que atuam primariamente sobre o aparelho respiratório"
  },
  {
    "codigo": "T490",
    "descricao": "Intoxicação por antifúngicos, antiinfecciosos e antiinflamatórios de uso tópico, não classificados em outra parte"
  },
  {
    "codigo": "T491",
    "descricao": "Intoxicação por antipruriginosos"
  },
  {
    "codigo": "T492",
    "descricao": "Intoxicação por adstringentes e detergentes de uso local"
  },
  {
    "codigo": "T493",
    "descricao": "Intoxicação por emolientes, demulcentes e protetores"
  },
  {
    "codigo": "T494",
    "descricao": "Intoxicação por ceratolíticos, ceratoplásticos e outras drogas e preparados para o tratamento dos cabelos"
  },
  {
    "codigo": "T495",
    "descricao": "Intoxicação por drogas e preparações de uso oftalmológico"
  },
  {
    "codigo": "T496",
    "descricao": "Intoxicação por drogas e preparações de uso otorrinolaringológico"
  },
  {
    "codigo": "T497",
    "descricao": "Intoxicação por drogas dentárias de uso tópico"
  },
  {
    "codigo": "T498",
    "descricao": "Intoxicação por outras drogas de uso tópico"
  },
  {
    "codigo": "T499",
    "descricao": "Intoxicação por preparado de uso tópico, não especificado"
  },
  {
    "codigo": "T500",
    "descricao": "Intoxicação por mineralocorticóides e seus antagonistas"
  },
  {
    "codigo": "T501",
    "descricao": "Intoxicação por diuréticos de alça"
  },
  {
    "codigo": "T502",
    "descricao": "Intoxicação por inibidores da anidrase carbônica, benzotiazidas e outros diuréticos"
  },
  {
    "codigo": "T503",
    "descricao": "Intoxicação por produtos que agem sobre o equilíbrio eletrolítico, calórico e hídrico"
  },
  {
    "codigo": "T504",
    "descricao": "Intoxicação por drogas que agem sobre o metabolismo do ácido úrico"
  },
  {
    "codigo": "T505",
    "descricao": "Intoxicação por inibidores do apetite"
  },
  {
    "codigo": "T506",
    "descricao": "Intoxicação por antídotos e quelantes não classificados em outra parte"
  },
  {
    "codigo": "T507",
    "descricao": "Intoxicação por analépticos e antagonistas dos receptores de opiáceos"
  },
  {
    "codigo": "T508",
    "descricao": "Intoxicação por agentes de diagnóstico"
  },
  {
    "codigo": "T509",
    "descricao": "Intoxicação por outras drogas, medicamentos e substâncias biológicas e as não especificadas"
  },
  { "codigo": "T510", "descricao": "Efeito tóxico do etanol" },
  { "codigo": "T511", "descricao": "Efeito tóxico do metanol" },
  { "codigo": "T512", "descricao": "Efeito tóxico do 2-propanol" },
  { "codigo": "T513", "descricao": "Efeito tóxico do óleo de fusel" },
  {
    "codigo": "T518",
    "descricao": "Efeito tóxico de outros álcoois"
  },
  {
    "codigo": "T519",
    "descricao": "Efeito tóxico de álcool não especificado"
  },
  {
    "codigo": "T520",
    "descricao": "Efeito tóxico de derivados do petróleo"
  },
  { "codigo": "T521", "descricao": "Efeito tóxico do benzeno" },
  {
    "codigo": "T522",
    "descricao": "Efeito tóxico de homólogos do benzeno"
  },
  { "codigo": "T523", "descricao": "Efeito tóxico de glicóis" },
  { "codigo": "T524", "descricao": "Efeito tóxico de cetonas" },
  {
    "codigo": "T528",
    "descricao": "Efeito tóxico de outros solventes orgânicos"
  },
  {
    "codigo": "T529",
    "descricao": "Efeito tóxico de solvente orgânico não especificado"
  },
  {
    "codigo": "T530",
    "descricao": "Efeito tóxico do tetracloreto de carbono"
  },
  { "codigo": "T531", "descricao": "Efeito tóxico do clorofórmio" },
  { "codigo": "T532", "descricao": "Efeito tóxico do tricloroetileno" },
  { "codigo": "T533", "descricao": "Efeito tóxico do tetracloroetileno" },
  { "codigo": "T534", "descricao": "Efeito tóxico do diclorometano" },
  { "codigo": "T535", "descricao": "Efeito tóxico do clorofluorcarbono" },
  {
    "codigo": "T536",
    "descricao": "Efeito tóxico de outros derivados halogênicos de hidrocarbonetos alifáticos"
  },
  {
    "codigo": "T537",
    "descricao": "Efeito tóxico de derivados halogênicos de hidrocarbonetos aromáticos"
  },
  {
    "codigo": "T539",
    "descricao": "Efeito tóxico de derivados halogênicos de hidrocarbonetos alifáticos e aromáticos não especificados"
  },
  {
    "codigo": "T540",
    "descricao": "Efeito tóxico do fenol e homólogos do fenol"
  },
  {
    "codigo": "T541",
    "descricao": "Efeito tóxico de outros compostos corrosivos orgânicos"
  },
  {
    "codigo": "T542",
    "descricao": "Efeito tóxico de ácidos corrosivos e substâncias semelhantes"
  },
  {
    "codigo": "T543",
    "descricao": "Efeito tóxico de bases (álcalis) cáusticas(os) e substâncias semelhantes"
  },
  {
    "codigo": "T549",
    "descricao": "Efeito tóxico de substância corrosiva não especificada"
  },
  {
    "codigo": "T55",
    "descricao": "Efeito tóxico de sabões e detergentes"
  },
  {
    "codigo": "T560",
    "descricao": "Efeito tóxico do chumbo e seus compostos"
  },
  {
    "codigo": "T561",
    "descricao": "Efeito tóxico do mercúrio e seus compostos"
  },
  {
    "codigo": "T562",
    "descricao": "Efeito tóxico do cromo e seus compostos"
  },
  {
    "codigo": "T563",
    "descricao": "Efeito tóxico do cádmio e seus compostos"
  },
  {
    "codigo": "T564",
    "descricao": "Efeito tóxico do cobre e seus compostos"
  },
  {
    "codigo": "T565",
    "descricao": "Efeito tóxico do zinco e seus compostos"
  },
  {
    "codigo": "T566",
    "descricao": "Efeito tóxico do estanho e seus compostos"
  },
  {
    "codigo": "T567",
    "descricao": "Efeito tóxico do berilo e seus compostos"
  },
  { "codigo": "T568", "descricao": "Efeito tóxico de outros metais" },
  {
    "codigo": "T569",
    "descricao": "Efeito tóxico de metal, não especificado"
  },
  {
    "codigo": "T570",
    "descricao": "Efeito tóxico do arsênico e seus compostos"
  },
  {
    "codigo": "T571",
    "descricao": "Efeito tóxico do fósforo e seus compostos"
  },
  {
    "codigo": "T572",
    "descricao": "Efeito tóxico do manganês e seus compostos"
  },
  {
    "codigo": "T573",
    "descricao": "Efeito tóxico do cianeto de hidrogênio (ácido cianídrico)"
  },
  {
    "codigo": "T578",
    "descricao": "Efeito tóxico de outras substâncias inorgânicas especificadas"
  },
  {
    "codigo": "T579",
    "descricao": "Efeito tóxico de substância inorgânica não especificada"
  },
  {
    "codigo": "T58",
    "descricao": "Efeito tóxico do monóxido de carbono"
  },
  {
    "codigo": "T590",
    "descricao": "Efeito tóxico de óxidos de nitrogênio"
  },
  {
    "codigo": "T591",
    "descricao": "Efeito tóxico do dióxido de enxofre"
  },
  { "codigo": "T592", "descricao": "Efeito tóxico de formaldeído" },
  {
    "codigo": "T593",
    "descricao": "Efeito tóxico do gás lacrimogêneo"
  },
  { "codigo": "T594", "descricao": "Efeito tóxico do cloro gasoso" },
  {
    "codigo": "T595",
    "descricao": "Efeito tóxico do fluoreto gasoso e ácido fluorídrico"
  },
  {
    "codigo": "T596",
    "descricao": "Efeito tóxico do ácido sulfídrico"
  },
  {
    "codigo": "T597",
    "descricao": "Efeito tóxico do dióxido de carbono"
  },
  {
    "codigo": "T598",
    "descricao": "Efeito tóxico de outros gases, fumaças e vapores especificados"
  },
  {
    "codigo": "T599",
    "descricao": "Efeito tóxico de gases, fumaças e vapores não especificados"
  },
  {
    "codigo": "T600",
    "descricao": "Efeito tóxico de inseticidas organofosforados e carbamatos"
  },
  {
    "codigo": "T601",
    "descricao": "Efeito tóxico de inseticidas halogenados"
  },
  { "codigo": "T602", "descricao": "Efeito tóxico de outros inseticidas" },
  {
    "codigo": "T603",
    "descricao": "Efeito tóxico de herbicidas e fungicidas"
  },
  { "codigo": "T604", "descricao": "Efeito tóxico de rodenticidas" },
  { "codigo": "T608", "descricao": "Efeito tóxico de outros pesticidas" },
  {
    "codigo": "T609",
    "descricao": "Efeito tóxico de pesticida não especificado"
  },
  {
    "codigo": "T610",
    "descricao": "Intoxicação pelo peixe ciguatera"
  },
  {
    "codigo": "T611",
    "descricao": "Intoxicação por peixes escombrídeos"
  },
  {
    "codigo": "T612",
    "descricao": "Outras intoxicações por peixes e mariscos"
  },
  {
    "codigo": "T618",
    "descricao": "Efeito tóxico de outros frutos do mar"
  },
  {
    "codigo": "T619",
    "descricao": "Efeito tóxico de frutos do mar não especificados"
  },
  {
    "codigo": "T620",
    "descricao": "Efeito tóxico de ingestão de cogumelos"
  },
  {
    "codigo": "T621",
    "descricao": "Efeito tóxico de ingestão de frutos"
  },
  {
    "codigo": "T622",
    "descricao": "Efeito tóxico de ingestão de outras (partes de) plantas"
  },
  {
    "codigo": "T628",
    "descricao": "Efeito tóxico de outras substâncias nocivas ingeridas como alimento"
  },
  {
    "codigo": "T629",
    "descricao": "Efeito tóxico de substâncias nocivas ingeridas como alimento, não especificadas"
  },
  { "codigo": "T630", "descricao": "Efeito tóxico do veneno de serpente" },
  {
    "codigo": "T631",
    "descricao": "Efeito tóxico do veneno de outros répteis"
  },
  {
    "codigo": "T632",
    "descricao": "Efeito tóxico do veneno de escorpião"
  },
  { "codigo": "T633", "descricao": "Efeito tóxico do veneno de aranha" },
  {
    "codigo": "T634",
    "descricao": "Efeito tóxico do veneno de outros artrópodes"
  },
  { "codigo": "T635", "descricao": "Efeito tóxico de contato com peixe" },
  {
    "codigo": "T636",
    "descricao": "Efeito tóxico de contato com outros animais marinhos"
  },
  {
    "codigo": "T638",
    "descricao": "Efeito tóxico de contato com outros animais venenosos"
  },
  {
    "codigo": "T639",
    "descricao": "Efeito tóxico de contato com animal venenoso não especificado"
  },
  {
    "codigo": "T64",
    "descricao": "Efeito tóxico da aflatoxina e de outras micotoxinas contaminantes de alimentos"
  },
  { "codigo": "T650", "descricao": "Efeito tóxico de cianetos" },
  {
    "codigo": "T651",
    "descricao": "Efeito tóxico da estricnina e seus sais"
  },
  {
    "codigo": "T652",
    "descricao": "Efeito tóxico do tabaco e da nicotina"
  },
  {
    "codigo": "T653",
    "descricao": "Efeito tóxico de nitroderivados e aminoderivados do benzeno e seus homólogos"
  },
  {
    "codigo": "T654",
    "descricao": "Efeito tóxico do dissulfeto de carbono"
  },
  {
    "codigo": "T655",
    "descricao": "Efeito tóxico da nitroglicerina e outros ácidos e ésteres nítricos"
  },
  {
    "codigo": "T656",
    "descricao": "Efeito tóxico de tinturas e corantes, não classificadas em outra parte"
  },
  {
    "codigo": "T658",
    "descricao": "Efeito tóxico de outras substâncias especificadas"
  },
  {
    "codigo": "T659",
    "descricao": "Efeito tóxico de substância não especificada"
  },
  {
    "codigo": "T66",
    "descricao": "Efeitos não especificados de radiação"
  },
  { "codigo": "T670", "descricao": "Golpe de calor e insolação" },
  { "codigo": "T671", "descricao": "Síncope devida ao calor" },
  { "codigo": "T672", "descricao": "Cãibras devidas ao calor" },
  {
    "codigo": "T673",
    "descricao": "Exaustão devida ao calor e à perda hídrica"
  },
  {
    "codigo": "T674",
    "descricao": "Exaustão devida ao calor e à perda de sal"
  },
  {
    "codigo": "T675",
    "descricao": "Exaustão devida ao calor, sem especificação"
  },
  { "codigo": "T676", "descricao": "Fadiga transitória devida ao calor" },
  { "codigo": "T677", "descricao": "Edema devido ao calor" },
  { "codigo": "T678", "descricao": "Outros efeitos do calor e da luz" },
  {
    "codigo": "T679",
    "descricao": "Efeito do calor e da luz não especificado"
  },
  { "codigo": "T68", "descricao": "Hipotermia" },
  { "codigo": "T690", "descricao": "Mão e pé de imersão" },
  { "codigo": "T691", "descricao": "Congelamento [chilblains]" },
  {
    "codigo": "T698",
    "descricao": "Outros efeitos especificados de temperatura reduzida"
  },
  {
    "codigo": "T699",
    "descricao": "Efeito não especificado de temperatura reduzida"
  },
  { "codigo": "T700", "descricao": "Otite barotraumática" },
  { "codigo": "T701", "descricao": "Sinusite barotraumática" },
  {
    "codigo": "T702",
    "descricao": "Outros efeitos das grandes altitude e os não especificados"
  },
  {
    "codigo": "T703",
    "descricao": "Mal dos caixões [doença de descompressão]"
  },
  { "codigo": "T704", "descricao": "Efeito dos fluidos em alta pressão" },
  {
    "codigo": "T708",
    "descricao": "Outros efeitos da pressão atmosférica ou da pressão da água"
  },
  {
    "codigo": "T709",
    "descricao": "Efeitos não especificados da pressão atmosférica ou da pressão da água"
  },
  { "codigo": "T71", "descricao": "Asfixia" },
  { "codigo": "T730", "descricao": "Efeitos da fome" },
  { "codigo": "T731", "descricao": "Efeitos da sede" },
  {
    "codigo": "T732",
    "descricao": "Exaustão devida às intempéries"
  },
  {
    "codigo": "T733",
    "descricao": "Exaustão devido a um esforço intenso"
  },
  { "codigo": "T738", "descricao": "Outros efeitos de privação" },
  {
    "codigo": "T739",
    "descricao": "Efeito não especificado de privação"
  },
  { "codigo": "T740", "descricao": "Abandono" },
  { "codigo": "T741", "descricao": "Sevícias físicas" },
  { "codigo": "T742", "descricao": "Abuso sexual" },
  { "codigo": "T743", "descricao": "Abuso psicológico" },
  {
    "codigo": "T748",
    "descricao": "Outras síndromes especificadas de maus tratos"
  },
  {
    "codigo": "T749",
    "descricao": "Síndrome não especificada de maus tratos"
  },
  { "codigo": "T750", "descricao": "Efeitos do raio" },
  {
    "codigo": "T751",
    "descricao": "Afogamento e submersão não mortal"
  },
  { "codigo": "T752", "descricao": "Efeitos da vibração" },
  { "codigo": "T753", "descricao": "Doença causada pelo movimento" },
  { "codigo": "T754", "descricao": "Efeitos da corrente elétrica" },
  {
    "codigo": "T758",
    "descricao": "Outros efeitos especificados de causas externas"
  },
  {
    "codigo": "T780",
    "descricao": "Choque anafilático devido à intolerância alimentar"
  },
  {
    "codigo": "T781",
    "descricao": "Outras reações de intolerância alimentar não classificadas em outra parte"
  },
  {
    "codigo": "T782",
    "descricao": "Choque anafilático não especificado"
  },
  { "codigo": "T783", "descricao": "Edema angioneurótico" },
  { "codigo": "T784", "descricao": "Alergia não especificada" },
  {
    "codigo": "T788",
    "descricao": "Outros efeitos adversos não classificados em outra parte"
  },
  { "codigo": "T789", "descricao": "Efeito adverso não especificado" },
  { "codigo": "T790", "descricao": "Embolia gasosa (traumática)" },
  { "codigo": "T791", "descricao": "Embolia gordurosa (traumática)" },
  {
    "codigo": "T792",
    "descricao": "Hemorragia traumática secundária e recidivante"
  },
  {
    "codigo": "T793",
    "descricao": "Infecção pós-traumática de ferimento não classificada em outra parte"
  },
  { "codigo": "T794", "descricao": "Choque traumático" },
  { "codigo": "T795", "descricao": "Anúria traumática" },
  { "codigo": "T796", "descricao": "Isquemia muscular traumática" },
  {
    "codigo": "T797",
    "descricao": "Enfisema subcutâneo de origem traumática"
  },
  {
    "codigo": "T798",
    "descricao": "Outras complicações precoces de traumatismo"
  },
  {
    "codigo": "T799",
    "descricao": "Complicação precoce não especificada de traumatismo"
  },
  {
    "codigo": "T800",
    "descricao": "Embolia aérea subseqüentes à infusão, transfusão e injeção terapêutica"
  },
  {
    "codigo": "T801",
    "descricao": "Complicações vasculares subseqüentes à infusão, transfusão e injeção terapêutica"
  },
  {
    "codigo": "T802",
    "descricao": "Infecções subseqüentes à infusão, transfusão e injeção terapêutica"
  },
  {
    "codigo": "T803",
    "descricao": "Reação de incompatibilidade ABO"
  },
  { "codigo": "T804", "descricao": "Reação de incompatibilidade Rh" },
  { "codigo": "T805", "descricao": "Choque anafilático devido a soro" },
  { "codigo": "T806", "descricao": "Outras reações ao soro" },
  {
    "codigo": "T808",
    "descricao": "Outras complicações subseqüentes à infusão, transfusão e injeção terapêutica"
  },
  {
    "codigo": "T809",
    "descricao": "Complicação não especificada subseqüente à infusão, transfusão e injeção terapêutica"
  },
  {
    "codigo": "T810",
    "descricao": "Hemorragia e hematoma complicando procedimento não classificado em outra parte"
  },
  {
    "codigo": "T811",
    "descricao": "Choque durante ou resultante de procedimento não classificado em outra parte"
  },
  {
    "codigo": "T812",
    "descricao": "Perfuração e laceração acidentais durante um procedimento não classificado em outra parte"
  },
  {
    "codigo": "T813",
    "descricao": "Deiscência de ferida cirúrgica não classificada em outra parte"
  },
  {
    "codigo": "T814",
    "descricao": "Infecção subseqüente a procedimento não classificada em outra parte"
  },
  {
    "codigo": "T815",
    "descricao": "Corpo estranho deixado acidentalmente em cavidade corporal ou em ferida operatória subseqüente a procedimento"
  },
  {
    "codigo": "T816",
    "descricao": "Reação aguda à substância estranha deixada acidentalmente durante um procedimento"
  },
  {
    "codigo": "T817",
    "descricao": "Complicações vasculares subseqüentes a um procedimento não classificado em outra parte"
  },
  {
    "codigo": "T818",
    "descricao": "Outras complicações de procedimentos não classificadas em outra parte"
  },
  {
    "codigo": "T819",
    "descricao": "Complicação não especificada de procedimento"
  },
  {
    "codigo": "T820",
    "descricao": "Complicação mecânica de prótese valvular cardíaca"
  },
  {
    "codigo": "T821",
    "descricao": "Complicação mecânica de dispositivo eletrônico cardíaco"
  },
  {
    "codigo": "T822",
    "descricao": "Complicação mecânica de enxerto de ponte coronária e implantes de valvas"
  },
  {
    "codigo": "T823",
    "descricao": "Complicações mecânicas de outros enxertos vasculares"
  },
  {
    "codigo": "T824",
    "descricao": "Complicação mecânica de cateter vascular de diálise"
  },
  {
    "codigo": "T825",
    "descricao": "Complicações mecânicas de outros dispositivos e implantes cardiovasculares"
  },
  {
    "codigo": "T826",
    "descricao": "Infecção e reação inflamatórias devidas à prótese valvular cardíaca"
  },
  {
    "codigo": "T827",
    "descricao": "Infecção e reação inflamatórias devidas a outros dispositivos, implantes e enxertos cardíacos e vasculares"
  },
  {
    "codigo": "T828",
    "descricao": "Outras complicações de dispositivos protéticos, implantes e enxertos cardíacos e vasculares"
  },
  {
    "codigo": "T829",
    "descricao": "Complicações não especificada de dispositivo protético, implante e enxerto cardíacos e vasculares"
  },
  {
    "codigo": "T830",
    "descricao": "Complicação mecânica de cateter (de demora) urinário"
  },
  {
    "codigo": "T831",
    "descricao": "Complicação mecânica de outros dispositivos e implantes urinários"
  },
  {
    "codigo": "T832",
    "descricao": "Complicação mecânica de enxerto de órgão urinário"
  },
  {
    "codigo": "T833",
    "descricao": "Complicação mecânica de dispositivo intra-uterino (anticoncepcional)"
  },
  {
    "codigo": "T834",
    "descricao": "Complicação mecânica de outros dispositivos protéticos, implantes e enxertos do trato genital"
  },
  {
    "codigo": "T835",
    "descricao": "Infecção e reação inflamatória devidas à dispositivo protético, implante e enxerto no aparelho urinário"
  },
  {
    "codigo": "T836",
    "descricao": "Infecção e reação inflamatória devidas à dispositivo protético, implante e enxerto no trato genital"
  },
  {
    "codigo": "T838",
    "descricao": "Outras complicações de dispositivos protéticos, implantes e enxertos geniturinários"
  },
  {
    "codigo": "T839",
    "descricao": "Complicação não especificada de prótese, implante e enxerto geniturinários"
  },
  {
    "codigo": "T840",
    "descricao": "Complicação mecânica de prótese articular interna"
  },
  {
    "codigo": "T841",
    "descricao": "Complicação mecânica de dispositivo de fixação interna de ossos dos membros"
  },
  {
    "codigo": "T842",
    "descricao": "Complicação mecânica de dispositivo de fixação interna de outros ossos"
  },
  {
    "codigo": "T843",
    "descricao": "Complicação mecânica de outros dispositivos, implantes e enxertos ósseos"
  },
  {
    "codigo": "T844",
    "descricao": "Complicação mecânica de outros dispositivos, implantes e enxertos ortopédicos internos"
  },
  {
    "codigo": "T845",
    "descricao": "Infecção e reação inflamatória devidas à prótese articular interna"
  },
  {
    "codigo": "T846",
    "descricao": "Infecção e reação inflamatória devidas a dispositivo de fixação interna [qualquer local]"
  },
  {
    "codigo": "T847",
    "descricao": "Infecção e reação inflamatória devidas a outros dispositivos protéticos, implantes e enxertos ortopédicos internos"
  },
  {
    "codigo": "T848",
    "descricao": "Outras complicações de dispositivos protéticos, implantes e enxertos ortopédicos internos"
  },
  {
    "codigo": "T849",
    "descricao": "Complicação não especificada de dispositivo protético, implante e enxerto ortopédicos internos"
  },
  {
    "codigo": "T850",
    "descricao": "Complicação mecânica de shunt ventricular (comunicação) intracraniano"
  },
  {
    "codigo": "T851",
    "descricao": "Complicação mecânica de estimulador eletrônico implantado no sistema nervoso"
  },
  {
    "codigo": "T852",
    "descricao": "Complicação mecânica de lente intraocular"
  },
  {
    "codigo": "T853",
    "descricao": "Complicações mecânicas de outros dispositivos protéticos, implantes e enxertos oculares"
  },
  {
    "codigo": "T854",
    "descricao": "Complicação mecânica de prótese e implante mamários"
  },
  {
    "codigo": "T855",
    "descricao": "Complicação mecânica de dispositivos protéticos, implante e enxerto gastrointestinais"
  },
  {
    "codigo": "T856",
    "descricao": "Complicação mecânica de outros dispositivos protéticos, implantes e enxertos internos especificados"
  },
  {
    "codigo": "T857",
    "descricao": "Infecção e reação inflamatória devida a outros dispositivos protéticos, implantes e enxertos internos"
  },
  {
    "codigo": "T858",
    "descricao": "Outras complicações de dispositivos protéticos, implantes e enxertos internos não classificados em outra parte"
  },
  {
    "codigo": "T859",
    "descricao": "Complicação não especificada de outros dispositivos protéticos, implantes e enxertos internos"
  },
  {
    "codigo": "T860",
    "descricao": "Rejeição a transplante de medula óssea"
  },
  {
    "codigo": "T861",
    "descricao": "Falência ou rejeição de transplante de rim"
  },
  {
    "codigo": "T862",
    "descricao": "Falência ou rejeição de transplante de coração"
  },
  {
    "codigo": "T863",
    "descricao": "Falência ou rejeição de transplante duplo de coração e pulmão"
  },
  {
    "codigo": "T864",
    "descricao": "Falência ou rejeição de transplante de fígado"
  },
  {
    "codigo": "T868",
    "descricao": "Outra insuficiência ou rejeição de outros órgãos ou tecidos transplantados"
  },
  {
    "codigo": "T869",
    "descricao": "Falência e rejeição a transplante de órgão ou tecido não especificado"
  },
  {
    "codigo": "T870",
    "descricao": "Complicações de reimplante (de parte) de extremidade superior"
  },
  {
    "codigo": "T871",
    "descricao": "Complicações de reimplante (de parte) de extremidade inferior"
  },
  {
    "codigo": "T872",
    "descricao": "Complicações de outras partes reimplantadas do corpo"
  },
  { "codigo": "T873", "descricao": "Neuroma de coto da amputação" },
  {
    "codigo": "T874",
    "descricao": "Infecção de coto da amputação"
  },
  { "codigo": "T875", "descricao": "Necrose do coto da amputação" },
  {
    "codigo": "T876",
    "descricao": "Outras complicações e as não especificadas do coto de amputação"
  },
  {
    "codigo": "T880",
    "descricao": "Infecção subseqüente à imunização"
  },
  {
    "codigo": "T881",
    "descricao": "Outras complicações subseqüentes à imunização não classificadas em outra parte"
  },
  { "codigo": "T882", "descricao": "Choque devido à anestesia" },
  {
    "codigo": "T883",
    "descricao": "Hipertermia maligna devida à anestesia"
  },
  {
    "codigo": "T884",
    "descricao": "Entubação falha ou difícil"
  },
  {
    "codigo": "T885",
    "descricao": "Outras complicações de anestesia"
  },
  {
    "codigo": "T886",
    "descricao": "Choque anafilático devido a efeito adverso de droga ou medicamento corretos e administrados de maneira apropriada"
  },
  {
    "codigo": "T887",
    "descricao": "Efeito adverso não especificado de droga ou medicamento"
  },
  {
    "codigo": "T888",
    "descricao": "Outras complicações de cuidados médicos e cirúrgicos especificados não classificados em outra parte"
  },
  {
    "codigo": "T889",
    "descricao": "Complicação não especificada de cuidados médicos e cirúrgicos"
  },
  {
    "codigo": "T900",
    "descricao": "Seqüelas de traumatismo superficial da cabeça"
  },
  {
    "codigo": "T901",
    "descricao": "Seqüelas de ferimento da cabeça"
  },
  {
    "codigo": "T902",
    "descricao": "Seqüelas de fratura de crânio e de ossos da face"
  },
  {
    "codigo": "T903",
    "descricao": "Seqüelas de traumatismo de nervos cranianos"
  },
  {
    "codigo": "T904",
    "descricao": "Seqüelas de traumatismo do olho e da órbita"
  },
  {
    "codigo": "T905",
    "descricao": "Seqüelas de traumatismo intracraniano"
  },
  {
    "codigo": "T908",
    "descricao": "Seqüelas de outros traumatismos especificados da cabeça"
  },
  {
    "codigo": "T909",
    "descricao": "Seqüelas de traumatismo não especificado da cabeça"
  },
  {
    "codigo": "T910",
    "descricao": "Seqüelas de traumatismo superficial e ferimento do pescoço e do tronco"
  },
  {
    "codigo": "T911",
    "descricao": "Seqüelas de fratura de coluna vertebral"
  },
  {
    "codigo": "T912",
    "descricao": "Seqüelas de outra fratura do tórax e da pelve"
  },
  {
    "codigo": "T913",
    "descricao": "Seqüelas de traumatismo de medula espinhal"
  },
  {
    "codigo": "T914",
    "descricao": "Seqüelas de traumatismo de órgãos intratorácicos"
  },
  {
    "codigo": "T915",
    "descricao": "Seqüelas de traumatismo de órgãos intra-abdominais e órgãos pélvicos"
  },
  {
    "codigo": "T918",
    "descricao": "Seqüelas de outros traumatismos especificados do pescoço e do tronco"
  },
  {
    "codigo": "T919",
    "descricao": "Seqüelas de traumatismo não especificado do pescoço e do tronco"
  },
  {
    "codigo": "T920",
    "descricao": "Seqüelas de ferimento do membro superior"
  },
  { "codigo": "T921", "descricao": "Seqüelas de fratura do braço" },
  {
    "codigo": "T922",
    "descricao": "Seqüelas de fratura ao nível do punho e da mão"
  },
  {
    "codigo": "T923",
    "descricao": "Seqüelas de luxação, entorse e distensão do membro superior"
  },
  {
    "codigo": "T924",
    "descricao": "Seqüelas de traumatismo de nervo de membro superior"
  },
  {
    "codigo": "T925",
    "descricao": "Seqüelas de traumatismo de músculo e tendão do membro superior"
  },
  {
    "codigo": "T926",
    "descricao": "Seqüelas de esmagamento e amputação traumática do membro superior"
  },
  {
    "codigo": "T928",
    "descricao": "Seqüelas de outros traumatismos especificados do membro superior"
  },
  {
    "codigo": "T929",
    "descricao": "Seqüelas de traumatismo não especificado do membro superior"
  },
  {
    "codigo": "T930",
    "descricao": "Seqüelas de ferimento do membro inferior"
  },
  { "codigo": "T931", "descricao": "Seqüelas de fratura do fêmur" },
  {
    "codigo": "T932",
    "descricao": "Seqüelas de outras fraturas do membro inferior"
  },
  {
    "codigo": "T933",
    "descricao": "Seqüelas de luxação, entorse e distensão do membro inferior"
  },
  {
    "codigo": "T934",
    "descricao": "Seqüelas de traumatismo de nervo do membro inferior"
  },
  {
    "codigo": "T935",
    "descricao": "Seqüelas de traumatismo de músculo e tendão do membro inferior"
  },
  {
    "codigo": "T936",
    "descricao": "Seqüelas de esmagamento e amputação traumática do membro inferior"
  },
  {
    "codigo": "T938",
    "descricao": "Seqüelas de outros traumatismos especificados do membro inferior"
  },
  {
    "codigo": "T939",
    "descricao": "Seqüelas de traumatismo não especificado do membro inferior"
  },
  {
    "codigo": "T940",
    "descricao": "Seqüelas de traumatismos envolvendo regiões múltiplas do corpo"
  },
  {
    "codigo": "T941",
    "descricao": "Seqüelas de traumatismos não especificados por regiões do corpo"
  },
  {
    "codigo": "T950",
    "descricao": "Seqüelas de queimadura, corrosão e geladura da cabeça e pescoço"
  },
  {
    "codigo": "T951",
    "descricao": "Seqüelas de queimadura, corrosão e geladura do tronco"
  },
  {
    "codigo": "T952",
    "descricao": "Seqüelas de queimadura, corrosão e geladura do membro superior"
  },
  {
    "codigo": "T953",
    "descricao": "Seqüelas de queimadura, corrosão e geladura do membro inferior"
  },
  {
    "codigo": "T954",
    "descricao": "Seqüelas de queimadura e corrosão classificável só de acordo com a extensão da área corporal envolvida"
  },
  {
    "codigo": "T958",
    "descricao": "Seqüelas de outra queimadura, corrosões e geladuras especificadas"
  },
  {
    "codigo": "T959",
    "descricao": "Seqüelas de queimadura, corrosão e geladura de local não especificado"
  },
  {
    "codigo": "T96",
    "descricao": "Seqüelas de intoxicação por drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "T97",
    "descricao": "Seqüelas de efeitos tóxicos de substâncias de origem predominantemente não-medicinal"
  },
  {
    "codigo": "T980",
    "descricao": "Seqüelas dos efeitos da penetração de corpo estranho através de orifício natural"
  },
  {
    "codigo": "T981",
    "descricao": "Seqüelas em outros efeitos de causas externas e os não especificados"
  },
  {
    "codigo": "T982",
    "descricao": "Seqüelas de algumas complicações precoces de traumatismos"
  },
  {
    "codigo": "T983",
    "descricao": "Seqüelas de complicações dos cuidados médicos e cirúrgicos não classificados em outra parte"
  },
  {
    "codigo": "V010",
    "descricao": "Pedestre traumatizado em colisão com um veículo a pedal - acidente não-de-trânsito"
  },
  {
    "codigo": "V011",
    "descricao": "Pedestre traumatizado em colisão com um veículo a pedal - acidente de trânsito"
  },
  {
    "codigo": "V019",
    "descricao": "Pedestre traumatizado em colisão com um veículo a pedal - acidente não especificado se de trânsito ou não de trânsito"
  },
  {
    "codigo": "V020",
    "descricao": "Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas - acidente não-de-trânsito"
  },
  {
    "codigo": "V021",
    "descricao": "Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas - acidente de trânsito"
  },
  {
    "codigo": "V029",
    "descricao": "Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas - acidente não especificado se de trânsito ou não de trânsito"
  },
  {
    "codigo": "V030",
    "descricao": "Pedestre traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - acidente não-de-trânsito"
  },
  {
    "codigo": "V031",
    "descricao": "Pedestre traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - acidente de trânsito"
  },
  {
    "codigo": "V039",
    "descricao": "Pedestre traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - acidente não especificado se de trânsito ou não de trânsito"
  },
  {
    "codigo": "V040",
    "descricao": "Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus - acidente não-de-trânsito"
  },
  {
    "codigo": "V041",
    "descricao": "Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus - acidente de trânsito"
  },
  {
    "codigo": "V049",
    "descricao": "Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus - acidente não especificado se de trânsito ou não de trânsito"
  },
  {
    "codigo": "V050",
    "descricao": "Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário - acidente não-de-trânsito"
  },
  {
    "codigo": "V051",
    "descricao": "Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário - acidente de trânsito"
  },
  {
    "codigo": "V059",
    "descricao": "Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário - acidente não especificado se de trânsito ou não de trânsito"
  },
  {
    "codigo": "V060",
    "descricao": "Pedestre traumatizado em colisão com outro veículo não-motorizado - acidente não-de-trânsito"
  },
  {
    "codigo": "V061",
    "descricao": "Pedestre traumatizado em colisão com outro veículo não-motorizado - acidente de trânsito"
  },
  {
    "codigo": "V069",
    "descricao": "Pedestre traumatizado em colisão com outro veículo não-motorizado - acidente não especificado se de trânsito ou não de trânsito"
  },
  {
    "codigo": "V090",
    "descricao": "Pedestre traumatizado em um acidente não-de-trânsito, envolvendo outros veículos a motor e os não especificados"
  },
  {
    "codigo": "V091",
    "descricao": "Pedestre traumatizado em um acidente não-de-trânsito não especificado"
  },
  {
    "codigo": "V092",
    "descricao": "Pedestre traumatizado em um acidente de trânsito envolvendo outros veículos e os não especificados, a motor"
  },
  {
    "codigo": "V093",
    "descricao": "Pedestre traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V099",
    "descricao": "Pedestre traumatizado em um acidente de transporte não especificado"
  },
  {
    "codigo": "V100",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V101",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V102",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V103",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V104",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V105",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V109",
    "descricao": "Ciclista traumatizado em colisão com um pedestre ou um animal - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V110",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V111",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V112",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V113",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V114",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V115",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V119",
    "descricao": "Ciclista traumatizado em colisão com outro veículo a pedal - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V120",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V121",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V122",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V123",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V124",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V125",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V129",
    "descricao": "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V130",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V131",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V132",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V133",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V134",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V135",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V139",
    "descricao": "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V140",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V141",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V142",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V143",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V144",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V145",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V149",
    "descricao": "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V150",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V151",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V152",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V153",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V154",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V155",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V159",
    "descricao": "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V160",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V161",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V162",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V163",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V164",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V165",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V169",
    "descricao": "Ciclista traumatizado em colisão com outro veículo não-motorizado - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V170",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V171",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V172",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V173",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V174",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V175",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V179",
    "descricao": "Ciclista traumatizado em colisão com um objeto fixo ou parado - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V180",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V181",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V182",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - ciclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V183",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V184",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V185",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V189",
    "descricao": "Ciclista traumatizado em um acidente de transporte sem colisão - ciclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V190",
    "descricao": "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V191",
    "descricao": "Passageiro traumatizado em colisão com outro veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V192",
    "descricao": "Ciclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V193",
    "descricao": "Ciclista [qualquer] traumatizado em um acidente não especificado e não-de-trânsito"
  },
  {
    "codigo": "V194",
    "descricao": "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V195",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V196",
    "descricao": "Ciclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V198",
    "descricao": "Ciclista [qualquer] traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V199",
    "descricao": "Ciclista [qualquer] traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V200",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V201",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V202",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V203",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V204",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V205",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V209",
    "descricao": "Motociclista traumatizado em colisão com um pedestre ou um animal - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V210",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V211",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V212",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V213",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V214",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V215",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V219",
    "descricao": "Motociclista traumatizado em colisão com um veículo a pedal - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V220",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V221",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V222",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V223",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V224",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V225",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V229",
    "descricao": "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V230",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V231",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V232",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V233",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V234",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V235",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V239",
    "descricao": "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V240",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V241",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V242",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V243",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V244",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V245",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V249",
    "descricao": "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V250",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V251",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V252",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V253",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V254",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V255",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V259",
    "descricao": "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V260",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V261",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V262",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V263",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V264",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V265",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V269",
    "descricao": "Motociclista traumatizado em colisão com outro veículo não-motorizado - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V270",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V271",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V272",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V273",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V274",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V275",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V279",
    "descricao": "Motociclista traumatizado em colisão com um objeto fixo ou parado - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V280",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V281",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V282",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - motociclista não especificado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V283",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V284",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V285",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V289",
    "descricao": "Motociclista traumatizado em um acidente de transporte sem colisão - motociclista não especificado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V290",
    "descricao": "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V291",
    "descricao": "Passageiro traumatizado em colisão com outros veículos com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V292",
    "descricao": "Motociclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V293",
    "descricao": "Motociclista [qualquer] traumatizado em um acidente não especificado, não-de-trânsito"
  },
  {
    "codigo": "V294",
    "descricao": "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V295",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V296",
    "descricao": "Motociclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor em um acidente de trânsito"
  },
  {
    "codigo": "V298",
    "descricao": "Motociclista [qualquer] traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V299",
    "descricao": "Motociclista [qualquer] traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V300",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V301",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V302",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V303",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V304",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V305",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V306",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V307",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V309",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V310",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V311",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V312",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V313",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V314",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V315",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V316",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V317",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V319",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V320",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V321",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V322",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V323",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V324",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V325",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V326",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V327",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V329",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V330",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V331",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V332",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V333",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V334",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V335",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V336",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V337",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V339",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V340",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V341",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V342",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V343",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V344",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V345",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V346",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V347",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V349",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V350",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V351",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V352",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V353",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V354",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V355",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V356",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V357",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V359",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V360",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V361",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V362",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V363",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V364",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V365",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V366",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V367",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V369",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V370",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V371",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V372",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V373",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V374",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V375",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V376",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V377",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V379",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V380",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - condutor traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V381",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V382",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V383",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito"
  },
  {
    "codigo": "V384",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V385",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - condutor traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V386",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V387",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V389",
    "descricao": "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V390",
    "descricao": "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V391",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V392",
    "descricao": "Ocupante não especificado de um triciclo motorizado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V393",
    "descricao": "Ocupante [qualquer] de um triciclo motorizado traumatizado em um acidente não especificado e não-de-trânsito"
  },
  {
    "codigo": "V394",
    "descricao": "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V395",
    "descricao": "Passageiro traumatizado em colisão outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V396",
    "descricao": "Ocupante não especificado de um triciclo motorizado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V398",
    "descricao": "Ocupante [qualquer] de um triciclo motorizado traumatizado em outros acidentes de trânsito especificados"
  },
  {
    "codigo": "V399",
    "descricao": "Ocupante [qualquer] de um triciclo motorizado, traumatizado em acidente de trânsito não especificado"
  },
  {
    "codigo": "V400",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V401",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V402",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V403",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V404",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V405",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V406",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V407",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V409",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V410",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V411",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V412",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V413",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V414",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V415",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V416",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V417",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V419",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V420",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V421",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V422",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V423",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V424",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V425",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V426",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V427",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V429",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V430",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V431",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V432",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V433",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V434",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V435",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V436",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V437",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V439",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V440",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V441",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V442",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V443",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V444",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V445",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V446",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V447",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V449",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V450",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V451",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V452",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V453",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V454",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V455",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V456",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V457",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V459",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V460",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V461",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V462",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V463",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V464",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V465",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V466",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V467",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V469",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V470",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V471",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V472",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V473",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V474",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V475",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V476",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V477",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V479",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V480",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V481",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V482",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V483",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V484",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - pessoa traumatizada ao subir ou descer do veículo"
  },
  {
    "codigo": "V485",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V486",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V487",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V489",
    "descricao": "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V490",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor em um acidente não-de-trânsito"
  },
  {
    "codigo": "V491",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V492",
    "descricao": "Ocupante não especificado de um automóvel [carro] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V493",
    "descricao": "Ocupante [qualquer] de um automóvel [carro] traumatizado em um acidente não especificado e não-de-trânsito"
  },
  {
    "codigo": "V494",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V495",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V496",
    "descricao": "Ocupante não especificado de um automóvel [carro] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V498",
    "descricao": "Ocupante [qualquer] de um automóvel [carro] traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V499",
    "descricao": "Ocupante [qualquer] de um automóvel [carro], traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V500",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V501",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V502",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V503",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V504",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V505",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V506",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V507",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V509",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V510",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V511",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V512",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V513",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V514",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V515",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V516",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V517",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V519",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V520",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V521",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V522",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V523",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V524",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V525",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V526",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V527",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V529",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V530",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V531",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V532",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V533",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V534",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V535",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V536",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V537",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V539",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V540",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V541",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V542",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V543",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V544",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V545",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V546",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V547",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V549",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V550",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V551",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V552",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V553",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V554",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V555",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V556",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V557",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V559",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V560",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V561",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V562",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V563",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V564",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V565",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V566",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V567",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V569",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V570",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V571",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V572",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V573",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V574",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V575",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V576",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V577",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V579",
    "descricao": "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V580",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V581",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V582",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V583",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V584",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V585",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V586",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V587",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V589",
    "descricao": "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V590",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V591",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V592",
    "descricao": "Ocupante não especificado de uma caminhonete traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V593",
    "descricao": "Ocupante [qualquer] de uma caminhonete traumatizado em um acidente não especificado e não-de-trânsito"
  },
  {
    "codigo": "V594",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V595",
    "descricao": "Passageiro de uma caminhonete traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V596",
    "descricao": "Ocupante não especificado de uma caminhonete traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V598",
    "descricao": "Ocupante [qualquer] de uma caminhonete traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V599",
    "descricao": "Ocupante [qualquer] de uma caminhonete traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V600",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V601",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V602",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V603",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V604",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V605",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V606",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V607",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V609",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V610",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V611",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V612",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V613",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V614",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V615",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V616",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V617",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V619",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V620",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V621",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V622",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V623",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V624",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V625",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V626",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V627",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V629",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V630",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V631",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V632",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V633",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V634",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V635",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V636",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V637",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V639",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V640",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V641",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V642",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V643",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V644",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V645",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V646",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V647",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V649",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V650",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V651",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V652",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V653",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V654",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V655",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V656",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V657",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V659",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V660",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V661",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V662",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V663",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V664",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V665",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V666",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V667",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V669",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V670",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V671",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V672",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V673",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V674",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V675",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V676",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V677",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V679",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V680",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V681",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V682",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V683",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V684",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V685",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V686",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V687",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V689",
    "descricao": "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V690",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V691",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V692",
    "descricao": "Ocupante não especificado de um veículo de transporte pesado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V693",
    "descricao": "Ocupante [qualquer] de um veículo de transporte pesado traumatizado em um acidente não especificado e não-de-trânsito"
  },
  {
    "codigo": "V694",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V695",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V696",
    "descricao": "Ocupante não especificado de um veículo de transporte pesado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V698",
    "descricao": "Ocupante [qualquer] de um veículo de transporte pesado traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V699",
    "descricao": "Ocupante [qualquer] de um veículo de transporte pesado traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V700",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V701",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V702",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V703",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V704",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V705",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V706",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V707",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V709",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V710",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V711",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V712",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V713",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V714",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V715",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V716",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V717",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V719",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V720",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V721",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V722",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V723",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V724",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V725",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V726",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V727",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V729",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V730",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V731",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V732",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V733",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V734",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V735",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V736",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V737",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V739",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V740",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V741",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V742",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V743",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V744",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V745",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V746",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V747",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V749",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V750",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V751",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V752",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V753",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V754",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V755",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V756",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V757",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V759",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V760",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V761",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V762",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V763",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V764",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V765",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V766",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V767",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V769",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V770",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V771",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V772",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V773",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V774",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V775",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V776",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V777",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V779",
    "descricao": "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V780",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V781",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V782",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V783",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V784",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo"
  },
  {
    "codigo": "V785",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V786",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V787",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito"
  },
  {
    "codigo": "V789",
    "descricao": "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V790",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V791",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V792",
    "descricao": "Ocupante não especificado de um ônibus traumatizado em colisão com outros veículos e com um veículos não especificados, a motor, em um acidente não-de-trânsito"
  },
  {
    "codigo": "V793",
    "descricao": "Ocupante [qualquer] de um ônibus traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V794",
    "descricao": "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V795",
    "descricao": "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V796",
    "descricao": "Ocupante não especificado de um ônibus traumatizado em colisão com um outros veículos e com um veículos não especificados, a motor, em um acidente de trânsito"
  },
  {
    "codigo": "V798",
    "descricao": "Ocupante [ qualquer] de um ônibus traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V799",
    "descricao": "Ocupante [qualquer] de um ônibus traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V800",
    "descricao": "Queda ou ejeção de uma pessoa montada em animal ou ocupante de um veículo a tração animal em um acidente sem colisão"
  },
  {
    "codigo": "V801",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um pedestre ou um animal"
  },
  {
    "codigo": "V802",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um veículo a pedal"
  },
  {
    "codigo": "V803",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um veículo a motor de duas ou três rodas"
  },
  {
    "codigo": "V804",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um automóvel [carro], uma caminhonete, um veículo de transporte pesado ou um ônibus"
  },
  {
    "codigo": "V805",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um outro veículo a motor especificado"
  },
  {
    "codigo": "V806",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um trem [comboio] ou um veículo ferroviário"
  },
  {
    "codigo": "V807",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um outro veículo não-motorizado"
  },
  {
    "codigo": "V808",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um objeto fixo ou parado"
  },
  {
    "codigo": "V809",
    "descricao": "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em outros acidentes de transporte e em acidentes de transporte não especificados"
  },
  {
    "codigo": "V810",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com um veículo a motor em um acidente não-de-trânsito"
  },
  {
    "codigo": "V811",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com um veículo a motor em um acidente de trânsito"
  },
  {
    "codigo": "V812",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com ou atingido por material rodante"
  },
  {
    "codigo": "V813",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com outro objeto"
  },
  {
    "codigo": "V814",
    "descricao": "Pessoa traumatizada ao subir ou descer de um trem [comboio] ou de um veículo ferroviário"
  },
  {
    "codigo": "V815",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado devido a uma queda no interior dos mesmos"
  },
  {
    "codigo": "V816",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado devido a uma queda do trem ou do veículo ferroviário"
  },
  {
    "codigo": "V817",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em razão de um descarrilhamento sem colisão antecedente"
  },
  {
    "codigo": "V818",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em outro acidente ferroviário especificado"
  },
  {
    "codigo": "V819",
    "descricao": "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em um acidente ferroviário não especificado"
  },
  {
    "codigo": "V820",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com um veículo a motor em um acidente não-de-trânsito"
  },
  {
    "codigo": "V821",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com um veículo a motor em um acidente de trânsito"
  },
  {
    "codigo": "V822",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com ou atingido por material rodante"
  },
  {
    "codigo": "V823",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com outro objeto"
  },
  {
    "codigo": "V824",
    "descricao": "Pessoa traumatizada ao subir ou descer de um bonde [carro elétrico]"
  },
  {
    "codigo": "V825",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado devido a uma queda no interior do mesmo"
  },
  {
    "codigo": "V826",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado devido a uma queda do mesmo"
  },
  {
    "codigo": "V827",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado devido a um descarrilhamento sem colisão antecedente"
  },
  {
    "codigo": "V828",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado em outros acidentes de transporte especificados"
  },
  {
    "codigo": "V829",
    "descricao": "Ocupante de um bonde [carro elétrico] traumatizado em um acidente de trânsito não especificado"
  },
  {
    "codigo": "V830",
    "descricao": "Condutor [motorista] de um veículo especial a motor usado principalmente em áreas industrias traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V831",
    "descricao": "Passageiro de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V832",
    "descricao": "Pessoa viajando no exterior de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V833",
    "descricao": "Ocupante não especificado de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V834",
    "descricao": "Pessoa traumatizada ao subir ou descer de um veículo especial a motor usado principalmente em áreas industriais"
  },
  {
    "codigo": "V835",
    "descricao": "Condutor [motorista] de um veículo especial a motor usado principalmente em áreas industrias traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V836",
    "descricao": "Passageiro de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V837",
    "descricao": "Pessoa viajando no exterior de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V839",
    "descricao": "Ocupante não especificado de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V840",
    "descricao": "Condutor [motorista] de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V841",
    "descricao": "Passageiro de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V842",
    "descricao": "Pessoa viajando no exterior de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V843",
    "descricao": "Ocupante não especificado de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V844",
    "descricao": "Pessoa traumatizado ao subir ou descer de um veículo especial a motor de uso essencialmente agrícola"
  },
  {
    "codigo": "V845",
    "descricao": "Condutor [motorista] de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V846",
    "descricao": "Passageiro de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V847",
    "descricao": "Pessoa viajando no exterior de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V849",
    "descricao": "Ocupante não especificado de um veículo especial a motor de uso essencialmente agrícola traumatizada em um acidente não-de-trânsito"
  },
  {
    "codigo": "V850",
    "descricao": "Condutor [motorista] de um veículo a motor especial de construções traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V851",
    "descricao": "Passageiro de um veículo a motor especial de construções traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V852",
    "descricao": "Pessoa viajando no exterior de um veículo a motor especial de construções traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V853",
    "descricao": "Ocupante não especificado de um veículo a motor especial de construções traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V854",
    "descricao": "Pessoa traumatizada ao subir ou descer de um veículo a motor especial de construções"
  },
  {
    "codigo": "V855",
    "descricao": "Condutor [motorista] de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V856",
    "descricao": "Passageiro de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V857",
    "descricao": "Pessoa viajando no exterior de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V859",
    "descricao": "Ocupante não especificado de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V860",
    "descricao": "Condutor [motorista] de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V861",
    "descricao": "Passageiro de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V862",
    "descricao": "Pessoa viajando no exterior de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V863",
    "descricao": "Ocupante não especificado de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente de trânsito"
  },
  {
    "codigo": "V864",
    "descricao": "Pessoa traumatizada ao subir ou descer de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública"
  },
  {
    "codigo": "V865",
    "descricao": "Condutor [motorista] de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V866",
    "descricao": "Passageiro de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V867",
    "descricao": "Pessoa viajando no exterior de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V869",
    "descricao": "Ocupante não especificado de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito"
  },
  {
    "codigo": "V870",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo a motor de duas ou três rodas (acidente de trânsito)"
  },
  {
    "codigo": "V871",
    "descricao": "Pessoa traumatizada em uma colisão entre outro veículo a motor e um veículo a motor de duas ou três rodas (acidente de trânsito)"
  },
  {
    "codigo": "V872",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e uma caminhonete (acidente de trânsito)"
  },
  {
    "codigo": "V873",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um ônibus (acidente de trânsito)"
  },
  {
    "codigo": "V874",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo de transporte pesado (acidente de trânsito)"
  },
  {
    "codigo": "V875",
    "descricao": "Pessoa traumatizada em uma colisão entre um veículo de transporte pesado e um ônibus (acidente de trânsito)"
  },
  {
    "codigo": "V876",
    "descricao": "Pessoa traumatizada em uma colisão entre um trem ou um veículo ferroviário e um automóvel [carro] (acidente de trânsito)"
  },
  {
    "codigo": "V877",
    "descricao": "Pessoa traumatizada em uma colisão entre outros veículos a motor especificados (acidente de trânsito)"
  },
  {
    "codigo": "V878",
    "descricao": "Pessoa traumatizada em outros acidentes de transporte especificados com veículo a motor, sem colisão (acidente de trânsito)"
  },
  {
    "codigo": "V879",
    "descricao": "Pessoa traumatizada em outros acidentes de transporte especificados (com colisão) (sem colisão) envolvendo um veículo não-motorizado (acidente de trânsito)"
  },
  {
    "codigo": "V880",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo a motor de duas ou três rodas, não-de-trânsito"
  },
  {
    "codigo": "V881",
    "descricao": "Pessoa traumatizada em uma colisão entre outro veículo a motor e um veículo a motor de duas ou três rodas, não-de-trânsito"
  },
  {
    "codigo": "V882",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e uma caminhonete, não-de-trânsito"
  },
  {
    "codigo": "V883",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um ônibus, não-de-trânsito"
  },
  {
    "codigo": "V884",
    "descricao": "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo de transporte pesado, não-de-trânsito"
  },
  {
    "codigo": "V885",
    "descricao": "Pessoa traumatizada em uma colisão entre um veículo de transporte pesado e um ônibus, não-de-trânsito"
  },
  {
    "codigo": "V886",
    "descricao": "Pessoa traumatizada em uma colisão entre um trem ou um veículo ferroviário e um automóvel [carro], não-de-trânsito"
  },
  {
    "codigo": "V887",
    "descricao": "Pessoa traumatizada em uma colisão entre outros veículos a motor especificados, não-de-trânsito"
  },
  {
    "codigo": "V888",
    "descricao": "Pessoa traumatizada em outros acidentes de transporte especificados, com um veículo a motor, sem colisão, não-de-trânsito"
  },
  {
    "codigo": "V889",
    "descricao": "Pessoa traumatizada em outros acidente de transporte especificados (com colisão) (sem colisão), envolvendo um veículo não-motorizado, não-de-trânsito"
  },
  {
    "codigo": "V890",
    "descricao": "Pessoa traumatizada em um acidente com um veículo a motor não especificado, não-de-trânsito"
  },
  {
    "codigo": "V891",
    "descricao": "Pessoa traumatizada em um acidente com veículo não-motorizado, não especificado, não-de-trânsito"
  },
  {
    "codigo": "V892",
    "descricao": "Pessoa traumatizada em um acidente de trânsito com um veículo a motor não especificado"
  },
  {
    "codigo": "V893",
    "descricao": "Pessoa traumatizado em um acidente de trânsito com um veículo não-motorizado, não especificado"
  },
  {
    "codigo": "V899",
    "descricao": "Pessoa traumatizada em um acidente com um veículo não especificado"
  },
  {
    "codigo": "V900",
    "descricao": "Acidente com embarcação causando afogamento e submersão - navio mercante"
  },
  {
    "codigo": "V901",
    "descricao": "Acidente com embarcação causando afogamento e submersão - navio de passageiros"
  },
  {
    "codigo": "V902",
    "descricao": "Acidente com embarcação causando afogamento e submersão - barco de pesca"
  },
  {
    "codigo": "V903",
    "descricao": "Acidente com embarcação causando afogamento e submersão - outras embarcações a motor"
  },
  {
    "codigo": "V904",
    "descricao": "Acidente com embarcação causando afogamento e submersão - barco a vela"
  },
  {
    "codigo": "V905",
    "descricao": "Acidente com embarcação causando afogamento e submersão - caiaque ou canoa"
  },
  {
    "codigo": "V906",
    "descricao": "Acidente com embarcação causando afogamento e submersão - bote inflável (sem motor)"
  },
  {
    "codigo": "V907",
    "descricao": "Acidente com embarcação causando afogamento e submersão - esqui aquático"
  },
  {
    "codigo": "V908",
    "descricao": "Acidente com embarcação causando afogamento e submersão - outras embarcações sem motor"
  },
  {
    "codigo": "V909",
    "descricao": "Acidente com embarcação causando afogamento e submersão - embarcação não especificada"
  },
  {
    "codigo": "V910",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - navio mercante"
  },
  {
    "codigo": "V911",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - navio de passageiros"
  },
  {
    "codigo": "V912",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - barco de pesca"
  },
  {
    "codigo": "V913",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - outras embarcações a motor"
  },
  {
    "codigo": "V914",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - barco a vela"
  },
  {
    "codigo": "V915",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - caiaque ou canoa"
  },
  {
    "codigo": "V916",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - bote inflável (sem motor)"
  },
  {
    "codigo": "V917",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - esqui aquático"
  },
  {
    "codigo": "V918",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - outras embarcações sem motor"
  },
  {
    "codigo": "V919",
    "descricao": "Acidente com embarcação causando outro tipo de traumatismo - embarcação não especificada"
  },
  {
    "codigo": "V920",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - navio mercante"
  },
  {
    "codigo": "V921",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - navio de passageiros"
  },
  {
    "codigo": "V922",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - barco de pesca"
  },
  {
    "codigo": "V923",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - outras embarcações a motor"
  },
  {
    "codigo": "V924",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - barco a vela"
  },
  {
    "codigo": "V925",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - caiaque ou canoa"
  },
  {
    "codigo": "V926",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - bote inflável (sem motor)"
  },
  {
    "codigo": "V927",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - esqui aquático"
  },
  {
    "codigo": "V928",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - outras embarcações sem motor"
  },
  {
    "codigo": "V929",
    "descricao": "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - embarcação não especificada"
  },
  {
    "codigo": "V930",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - navio mercante"
  },
  {
    "codigo": "V931",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - navio de passageiros"
  },
  {
    "codigo": "V932",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - barco de pesca"
  },
  {
    "codigo": "V933",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - outras embarcações a motor"
  },
  {
    "codigo": "V934",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - barco a vela"
  },
  {
    "codigo": "V935",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - caiaque ou canoa"
  },
  {
    "codigo": "V936",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - bote inflável (sem motor)"
  },
  {
    "codigo": "V937",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - esqui aquático"
  },
  {
    "codigo": "V938",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - outras embarcações sem motor"
  },
  {
    "codigo": "V939",
    "descricao": "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - embarcação não especificada"
  },
  {
    "codigo": "V940",
    "descricao": "Outros acidentes de transporte por água e os não especificados - navio mercante"
  },
  {
    "codigo": "V941",
    "descricao": "Outros acidentes de transporte por água e os não especificados - navio de passageiros"
  },
  {
    "codigo": "V942",
    "descricao": "Outros acidentes de transporte por água e os não especificados - barco de pesca"
  },
  {
    "codigo": "V943",
    "descricao": "Outros acidentes de transporte por água e os não especificados - outras embarcações a motor"
  },
  {
    "codigo": "V944",
    "descricao": "Outros acidentes de transporte por água e os não especificados - barco a vela"
  },
  {
    "codigo": "V945",
    "descricao": "Outros acidentes de transporte por água e os não especificados - caiaque ou canoa"
  },
  {
    "codigo": "V946",
    "descricao": "Outros acidentes de transporte por água e os não especificados - bote inflável (sem motor)"
  },
  {
    "codigo": "V947",
    "descricao": "Outros acidentes de transporte por água e os não especificados - esqui aquático"
  },
  {
    "codigo": "V948",
    "descricao": "Outros acidentes de transporte por água e os não especificados - outras embarcações sem motor"
  },
  {
    "codigo": "V949",
    "descricao": "Outros acidentes de transporte por água e os não especificados - embarcação não especificada"
  },
  {
    "codigo": "V950",
    "descricao": "Acidente de helicóptero causando traumatismo a ocupante"
  },
  {
    "codigo": "V951",
    "descricao": "Acidente de planador motorizado e ultraleve causando traumatismo a ocupante"
  },
  {
    "codigo": "V952",
    "descricao": "Acidente de outra aeronave de asa fixa de uso particular causando traumatismo a ocupante"
  },
  {
    "codigo": "V953",
    "descricao": "Acidente de aeronave de asa fixa de uso comercial causando traumatismo a ocupante"
  },
  {
    "codigo": "V954",
    "descricao": "Acidente de nave espacial causando traumatismo a ocupante"
  },
  {
    "codigo": "V958",
    "descricao": "Outros acidente de aeronaves causando traumatismo a ocupante"
  },
  {
    "codigo": "V959",
    "descricao": "Acidente de aeronave não especificada causando traumatismo a ocupante"
  },
  {
    "codigo": "V960",
    "descricao": "Acidente de balão causando traumatismo a ocupante"
  },
  {
    "codigo": "V961",
    "descricao": "Acidente de asa delta causando traumatismo a ocupante"
  },
  {
    "codigo": "V962",
    "descricao": "Acidente de planador (não-motorizado) causando traumatismo a ocupante"
  },
  {
    "codigo": "V968",
    "descricao": "Outros acidentes de aeronaves não-motorizadas causando traumatismo a ocupante"
  },
  {
    "codigo": "V969",
    "descricao": "Acidente não especificado de aeronave não-motorizada causando traumatismo a ocupante"
  },
  {
    "codigo": "V970",
    "descricao": "Ocupante de uma aeronave traumatizado em outros acidentes de transporte aéreo especificados"
  },
  {
    "codigo": "V971",
    "descricao": "Pessoa traumatizada ao embarcar em ou desembarcar de uma aeronave"
  },
  {
    "codigo": "V972",
    "descricao": "Paraquedista traumatizado em acidente de transporte aéreo"
  },
  {
    "codigo": "V973",
    "descricao": "Pessoa em terra traumatizada em acidente de transporte aéreo"
  },
  {
    "codigo": "V978",
    "descricao": "Outros acidentes de transporte aéreo não classificados em outra parte"
  },
  {
    "codigo": "V98",
    "descricao": "Outros acidentes de transporte especificados"
  },
  {
    "codigo": "V99",
    "descricao": "Acidente de transporte não especificado"
  },
  {
    "codigo": "W000",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - residência"
  },
  {
    "codigo": "W001",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - habitação coletiva"
  },
  {
    "codigo": "W002",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W003",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W004",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - rua e estrada"
  },
  {
    "codigo": "W005",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - áreas de comércio e de serviços"
  },
  {
    "codigo": "W006",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - áreas industriais e em construção"
  },
  {
    "codigo": "W007",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - fazenda"
  },
  {
    "codigo": "W008",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - outros locais especificados"
  },
  {
    "codigo": "W009",
    "descricao": "Queda no mesmo nível envolvendo gelo e neve - local não especificado"
  },
  {
    "codigo": "W010",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - residência"
  },
  {
    "codigo": "W011",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - habitação coletiva"
  },
  {
    "codigo": "W012",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W013",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W014",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - rua e estrada"
  },
  {
    "codigo": "W015",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - áreas de comércio e de serviços"
  },
  {
    "codigo": "W016",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - áreas industriais e em construção"
  },
  {
    "codigo": "W017",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - fazenda"
  },
  {
    "codigo": "W018",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - outros locais especificados"
  },
  {
    "codigo": "W019",
    "descricao": "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - local não especificado"
  },
  {
    "codigo": "W020",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - residência"
  },
  {
    "codigo": "W021",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - habitação coletiva"
  },
  {
    "codigo": "W022",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W023",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W024",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - rua e estrada"
  },
  {
    "codigo": "W025",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W026",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - áreas industriais e em construção"
  },
  {
    "codigo": "W027",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - fazenda"
  },
  {
    "codigo": "W028",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - outros locais especificados"
  },
  {
    "codigo": "W029",
    "descricao": "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - local não especificado"
  },
  {
    "codigo": "W030",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - residência"
  },
  {
    "codigo": "W031",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - habitação coletiva"
  },
  {
    "codigo": "W032",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W033",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W034",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - rua e estrada"
  },
  {
    "codigo": "W035",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - áreas de comércio e de serviços"
  },
  {
    "codigo": "W036",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - áreas industriais e em construção"
  },
  {
    "codigo": "W037",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - fazenda"
  },
  {
    "codigo": "W038",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - outros locais especificados"
  },
  {
    "codigo": "W039",
    "descricao": "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - local não especificado"
  },
  {
    "codigo": "W040",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - residência"
  },
  {
    "codigo": "W041",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - habitação coletiva"
  },
  {
    "codigo": "W042",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W043",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W044",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - rua e estrada"
  },
  {
    "codigo": "W045",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - áreas de comércio e de serviços"
  },
  {
    "codigo": "W046",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - áreas industriais e em construção"
  },
  {
    "codigo": "W047",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - fazenda"
  },
  {
    "codigo": "W048",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - outros locais especificados"
  },
  {
    "codigo": "W049",
    "descricao": "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - local não especificado"
  },
  {
    "codigo": "W050",
    "descricao": "Queda envolvendo uma cadeira de rodas - residência"
  },
  {
    "codigo": "W051",
    "descricao": "Queda envolvendo uma cadeira de rodas - habitação coletiva"
  },
  {
    "codigo": "W052",
    "descricao": "Queda envolvendo uma cadeira de rodas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W053",
    "descricao": "Queda envolvendo uma cadeira de rodas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W054",
    "descricao": "Queda envolvendo uma cadeira de rodas - rua e estrada"
  },
  {
    "codigo": "W055",
    "descricao": "Queda envolvendo uma cadeira de rodas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W056",
    "descricao": "Queda envolvendo uma cadeira de rodas - áreas industriais e em construção"
  },
  {
    "codigo": "W057",
    "descricao": "Queda envolvendo uma cadeira de rodas - fazenda"
  },
  {
    "codigo": "W058",
    "descricao": "Queda envolvendo uma cadeira de rodas - outros locais especificados"
  },
  {
    "codigo": "W059",
    "descricao": "Queda envolvendo uma cadeira de rodas - local não especificado"
  },
  { "codigo": "W060", "descricao": "Queda de um leito - residência" },
  {
    "codigo": "W061",
    "descricao": "Queda de um leito - habitação coletiva"
  },
  {
    "codigo": "W062",
    "descricao": "Queda de um leito - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W063",
    "descricao": "Queda de um leito - área para a prática de esportes e atletismo"
  },
  { "codigo": "W064", "descricao": "Queda de um leito - rua e estrada" },
  {
    "codigo": "W065",
    "descricao": "Queda de um leito - áreas de comércio e de serviços"
  },
  {
    "codigo": "W066",
    "descricao": "Queda de um leito - áreas industriais e em construção"
  },
  { "codigo": "W067", "descricao": "Queda de um leito - fazenda" },
  {
    "codigo": "W068",
    "descricao": "Queda de um leito - outros locais especificados"
  },
  {
    "codigo": "W069",
    "descricao": "Queda de um leito - local não especificado"
  },
  { "codigo": "W070", "descricao": "Queda de uma cadeira - residência" },
  {
    "codigo": "W071",
    "descricao": "Queda de uma cadeira - habitação coletiva"
  },
  {
    "codigo": "W072",
    "descricao": "Queda de uma cadeira - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W073",
    "descricao": "Queda de uma cadeira - área para a prática de esportes e atletismo"
  },
  { "codigo": "W074", "descricao": "Queda de uma cadeira - rua e estrada" },
  {
    "codigo": "W075",
    "descricao": "Queda de uma cadeira - áreas de comércio e de serviços"
  },
  {
    "codigo": "W076",
    "descricao": "Queda de uma cadeira - áreas industriais e em construção"
  },
  { "codigo": "W077", "descricao": "Queda de uma cadeira - fazenda" },
  {
    "codigo": "W078",
    "descricao": "Queda de uma cadeira - outros locais especificados"
  },
  {
    "codigo": "W079",
    "descricao": "Queda de uma cadeira - local não especificado"
  },
  {
    "codigo": "W080",
    "descricao": "Queda de outro tipo de mobília - residência"
  },
  {
    "codigo": "W081",
    "descricao": "Queda de outro tipo de mobília - habitação coletiva"
  },
  {
    "codigo": "W082",
    "descricao": "Queda de outro tipo de mobília - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W083",
    "descricao": "Queda de outro tipo de mobília - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W084",
    "descricao": "Queda de outro tipo de mobília - rua e estrada"
  },
  {
    "codigo": "W085",
    "descricao": "Queda de outro tipo de mobília - áreas de comércio e de serviços"
  },
  {
    "codigo": "W086",
    "descricao": "Queda de outro tipo de mobília - áreas industriais e em construção"
  },
  {
    "codigo": "W087",
    "descricao": "Queda de outro tipo de mobília - fazenda"
  },
  {
    "codigo": "W088",
    "descricao": "Queda de outro tipo de mobília - outros locais especificados"
  },
  {
    "codigo": "W089",
    "descricao": "Queda de outro tipo de mobília - local não especificado"
  },
  {
    "codigo": "W090",
    "descricao": "Queda envolvendo equipamento de playground - residência"
  },
  {
    "codigo": "W091",
    "descricao": "Queda envolvendo equipamento de playground - habitação coletiva"
  },
  {
    "codigo": "W092",
    "descricao": "Queda envolvendo equipamento de playground - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W093",
    "descricao": "Queda envolvendo equipamento de playground - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W094",
    "descricao": "Queda envolvendo equipamento de playground - rua e estrada"
  },
  {
    "codigo": "W095",
    "descricao": "Queda envolvendo equipamento de playground - áreas de comércio e de serviços"
  },
  {
    "codigo": "W096",
    "descricao": "Queda envolvendo equipamento de playground - áreas industriais e em construção"
  },
  {
    "codigo": "W097",
    "descricao": "Queda envolvendo equipamento de playground - fazenda"
  },
  {
    "codigo": "W098",
    "descricao": "Queda envolvendo equipamento de playground - outros locais especificados"
  },
  {
    "codigo": "W099",
    "descricao": "Queda envolvendo equipamento de playground - local não especificado"
  },
  {
    "codigo": "W100",
    "descricao": "Queda em ou de escadas ou degraus - residência"
  },
  {
    "codigo": "W101",
    "descricao": "Queda em ou de escadas ou degraus - habitação coletiva"
  },
  {
    "codigo": "W102",
    "descricao": "Queda em ou de escadas ou degraus - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W103",
    "descricao": "Queda em ou de escadas ou degraus - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W104",
    "descricao": "Queda em ou de escadas ou degraus - rua e estrada"
  },
  {
    "codigo": "W105",
    "descricao": "Queda em ou de escadas ou degraus - áreas de comércio e de serviços"
  },
  {
    "codigo": "W106",
    "descricao": "Queda em ou de escadas ou degraus - áreas industriais e em construção"
  },
  {
    "codigo": "W107",
    "descricao": "Queda em ou de escadas ou degraus - fazenda"
  },
  {
    "codigo": "W108",
    "descricao": "Queda em ou de escadas ou degraus - outros locais especificados"
  },
  {
    "codigo": "W109",
    "descricao": "Queda em ou de escadas ou degraus - local não especificado"
  },
  {
    "codigo": "W110",
    "descricao": "Queda em ou de escadas de mão - residência"
  },
  {
    "codigo": "W111",
    "descricao": "Queda em ou de escadas de mão - habitação coletiva"
  },
  {
    "codigo": "W112",
    "descricao": "Queda em ou de escadas de mão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W113",
    "descricao": "Queda em ou de escadas de mão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W114",
    "descricao": "Queda em ou de escadas de mão - rua e estrada"
  },
  {
    "codigo": "W115",
    "descricao": "Queda em ou de escadas de mão - áreas de comércio e de serviços"
  },
  {
    "codigo": "W116",
    "descricao": "Queda em ou de escadas de mão - áreas industriais e em construção"
  },
  {
    "codigo": "W117",
    "descricao": "Queda em ou de escadas de mão - fazenda"
  },
  {
    "codigo": "W118",
    "descricao": "Queda em ou de escadas de mão - outros locais especificados"
  },
  {
    "codigo": "W119",
    "descricao": "Queda em ou de escadas de mão - local não especificado"
  },
  {
    "codigo": "W120",
    "descricao": "Queda em ou de um andaime - residência"
  },
  {
    "codigo": "W121",
    "descricao": "Queda em ou de um andaime - habitação coletiva"
  },
  {
    "codigo": "W122",
    "descricao": "Queda em ou de um andaime - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W123",
    "descricao": "Queda em ou de um andaime - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W124",
    "descricao": "Queda em ou de um andaime - rua e estrada"
  },
  {
    "codigo": "W125",
    "descricao": "Queda em ou de um andaime - áreas de comércio e de serviços"
  },
  {
    "codigo": "W126",
    "descricao": "Queda em ou de um andaime - áreas industriais e em construção"
  },
  { "codigo": "W127", "descricao": "Queda em ou de um andaime - fazenda" },
  {
    "codigo": "W128",
    "descricao": "Queda em ou de um andaime - outros locais especificados"
  },
  {
    "codigo": "W129",
    "descricao": "Queda em ou de um andaime - local não especificado"
  },
  {
    "codigo": "W130",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - residência"
  },
  {
    "codigo": "W131",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - habitação coletiva"
  },
  {
    "codigo": "W132",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W133",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W134",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - rua e estrada"
  },
  {
    "codigo": "W135",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W136",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - áreas industriais e em construção"
  },
  {
    "codigo": "W137",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - fazenda"
  },
  {
    "codigo": "W138",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - outros locais especificados"
  },
  {
    "codigo": "W139",
    "descricao": "Queda de ou para fora de edifícios ou outras estruturas - local não especificado"
  },
  { "codigo": "W140", "descricao": "Queda de árvore - residência" },
  {
    "codigo": "W141",
    "descricao": "Queda de árvore - habitação coletiva"
  },
  {
    "codigo": "W142",
    "descricao": "Queda de árvore - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W143",
    "descricao": "Queda de árvore - área para a prática de esportes e atletismo"
  },
  { "codigo": "W144", "descricao": "Queda de árvore - rua e estrada" },
  {
    "codigo": "W145",
    "descricao": "Queda de árvore - áreas de comércio e de serviços"
  },
  {
    "codigo": "W146",
    "descricao": "Queda de árvore - áreas industriais e em construção"
  },
  { "codigo": "W147", "descricao": "Queda de árvore - fazenda" },
  {
    "codigo": "W148",
    "descricao": "Queda de árvore - outros locais especificados"
  },
  {
    "codigo": "W149",
    "descricao": "Queda de árvore - local não especificado"
  },
  { "codigo": "W150", "descricao": "Queda de penhasco - residência" },
  {
    "codigo": "W151",
    "descricao": "Queda de penhasco - habitação coletiva"
  },
  {
    "codigo": "W152",
    "descricao": "Queda de penhasco - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W153",
    "descricao": "Queda de penhasco - área para a prática de esportes e atletismo"
  },
  { "codigo": "W154", "descricao": "Queda de penhasco - rua e estrada" },
  {
    "codigo": "W155",
    "descricao": "Queda de penhasco - áreas de comércio e de serviços"
  },
  {
    "codigo": "W156",
    "descricao": "Queda de penhasco - áreas industriais e em construção"
  },
  { "codigo": "W157", "descricao": "Queda de penhasco - fazenda" },
  {
    "codigo": "W158",
    "descricao": "Queda de penhasco - outros locais especificados"
  },
  {
    "codigo": "W159",
    "descricao": "Queda de penhasco - local não especificado"
  },
  {
    "codigo": "W160",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - residência"
  },
  {
    "codigo": "W161",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - habitação coletiva"
  },
  {
    "codigo": "W162",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W163",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W164",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - rua e estrada"
  },
  {
    "codigo": "W165",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - áreas de comércio e de serviços"
  },
  {
    "codigo": "W166",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - áreas industriais e em construção"
  },
  {
    "codigo": "W167",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - fazenda"
  },
  {
    "codigo": "W168",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - outros locais especificados"
  },
  {
    "codigo": "W169",
    "descricao": "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - local não especificado"
  },
  {
    "codigo": "W170",
    "descricao": "Outras quedas de um nível a outro - residência"
  },
  {
    "codigo": "W171",
    "descricao": "Outras quedas de um nível a outro - habitação coletiva"
  },
  {
    "codigo": "W172",
    "descricao": "Outras quedas de um nível a outro - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W173",
    "descricao": "Outras quedas de um nível a outro - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W174",
    "descricao": "Outras quedas de um nível a outro - rua e estrada"
  },
  {
    "codigo": "W175",
    "descricao": "Outras quedas de um nível a outro - áreas de comércio e de serviços"
  },
  {
    "codigo": "W176",
    "descricao": "Outras quedas de um nível a outro - áreas industriais e em construção"
  },
  {
    "codigo": "W177",
    "descricao": "Outras quedas de um nível a outro - fazenda"
  },
  {
    "codigo": "W178",
    "descricao": "Outras quedas de um nível a outro - outros locais especificados"
  },
  {
    "codigo": "W179",
    "descricao": "Outras quedas de um nível a outro - local não especificado"
  },
  {
    "codigo": "W180",
    "descricao": "Outras quedas no mesmo nível - residência"
  },
  {
    "codigo": "W181",
    "descricao": "Outras quedas no mesmo nível - habitação coletiva"
  },
  {
    "codigo": "W182",
    "descricao": "Outras quedas no mesmo nível - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W183",
    "descricao": "Outras quedas no mesmo nível - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W184",
    "descricao": "Outras quedas no mesmo nível - rua e estrada"
  },
  {
    "codigo": "W185",
    "descricao": "Outras quedas no mesmo nível - áreas de comércio e de serviços"
  },
  {
    "codigo": "W186",
    "descricao": "Outras quedas no mesmo nível - áreas industriais e em construção"
  },
  {
    "codigo": "W187",
    "descricao": "Outras quedas no mesmo nível - fazenda"
  },
  {
    "codigo": "W188",
    "descricao": "Outras quedas no mesmo nível - outros locais especificados"
  },
  {
    "codigo": "W189",
    "descricao": "Outras quedas no mesmo nível - local não especificado"
  },
  {
    "codigo": "W190",
    "descricao": "Queda sem especificação - residência"
  },
  {
    "codigo": "W191",
    "descricao": "Queda sem especificação - habitação coletiva"
  },
  {
    "codigo": "W192",
    "descricao": "Queda sem especificação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W193",
    "descricao": "Queda sem especificação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W194",
    "descricao": "Queda sem especificação - rua e estrada"
  },
  {
    "codigo": "W195",
    "descricao": "Queda sem especificação - áreas de comércio e de serviços"
  },
  {
    "codigo": "W196",
    "descricao": "Queda sem especificação - áreas industriais e em construção"
  },
  {
    "codigo": "W197",
    "descricao": "Queda sem especificação - fazenda"
  },
  {
    "codigo": "W198",
    "descricao": "Queda sem especificação - outros locais especificados"
  },
  {
    "codigo": "W199",
    "descricao": "Queda sem especificação - local não especificado"
  },
  {
    "codigo": "W200",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - residência"
  },
  {
    "codigo": "W201",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - habitação coletiva"
  },
  {
    "codigo": "W202",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W203",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W204",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - rua e estrada"
  },
  {
    "codigo": "W205",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - áreas de comércio e de serviços"
  },
  {
    "codigo": "W206",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - áreas industriais e em construção"
  },
  {
    "codigo": "W207",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - fazenda"
  },
  {
    "codigo": "W208",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - outros locais especificados"
  },
  {
    "codigo": "W209",
    "descricao": "Impacto causado por objeto lançado, projetado ou em queda - local não especificado"
  },
  {
    "codigo": "W210",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - residência"
  },
  {
    "codigo": "W211",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - habitação coletiva"
  },
  {
    "codigo": "W212",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W213",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W214",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - rua e estrada"
  },
  {
    "codigo": "W215",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - áreas de comércio e de serviços"
  },
  {
    "codigo": "W216",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - áreas industriais e em construção"
  },
  {
    "codigo": "W217",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - fazenda"
  },
  {
    "codigo": "W218",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - outros locais especificados"
  },
  {
    "codigo": "W219",
    "descricao": "Impacto acidental ativo ou passivo causado por equipamento esportivo - local não especificado"
  },
  {
    "codigo": "W220",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - residência"
  },
  {
    "codigo": "W221",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - habitação coletiva"
  },
  {
    "codigo": "W222",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W223",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W224",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - rua e estrada"
  },
  {
    "codigo": "W225",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - áreas de comércio e de serviços"
  },
  {
    "codigo": "W226",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - áreas industriais e em construção"
  },
  {
    "codigo": "W227",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - fazenda"
  },
  {
    "codigo": "W228",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - outros locais especificados"
  },
  {
    "codigo": "W229",
    "descricao": "Impacto acidental ativo ou passivo causado por outros objetos - local não especificado"
  },
  {
    "codigo": "W230",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - residência"
  },
  {
    "codigo": "W231",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - habitação coletiva"
  },
  {
    "codigo": "W232",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W233",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W234",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - rua e estrada"
  },
  {
    "codigo": "W235",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - áreas de comércio e de serviços"
  },
  {
    "codigo": "W236",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - áreas industriais e em construção"
  },
  {
    "codigo": "W237",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - fazenda"
  },
  {
    "codigo": "W238",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - outros locais especificados"
  },
  {
    "codigo": "W239",
    "descricao": "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - local não especificado"
  },
  {
    "codigo": "W240",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - residência"
  },
  {
    "codigo": "W241",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - habitação coletiva"
  },
  {
    "codigo": "W242",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W243",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W244",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - rua e estrada"
  },
  {
    "codigo": "W245",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - áreas de comércio e de serviços"
  },
  {
    "codigo": "W246",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - áreas industriais e em construção"
  },
  {
    "codigo": "W247",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - fazenda"
  },
  {
    "codigo": "W248",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - outros locais especificados"
  },
  {
    "codigo": "W249",
    "descricao": "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - local não especificado"
  },
  {
    "codigo": "W250",
    "descricao": "Contato com vidro cortante - residência"
  },
  {
    "codigo": "W251",
    "descricao": "Contato com vidro cortante - habitação coletiva"
  },
  {
    "codigo": "W252",
    "descricao": "Contato com vidro cortante - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W253",
    "descricao": "Contato com vidro cortante - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W254",
    "descricao": "Contato com vidro cortante - rua e estrada"
  },
  {
    "codigo": "W255",
    "descricao": "Contato com vidro cortante - áreas de comércio e de serviços"
  },
  {
    "codigo": "W256",
    "descricao": "Contato com vidro cortante - áreas industriais e em construção"
  },
  { "codigo": "W257", "descricao": "Contato com vidro cortante - fazenda" },
  {
    "codigo": "W258",
    "descricao": "Contato com vidro cortante - outros locais especificados"
  },
  {
    "codigo": "W259",
    "descricao": "Contato com vidro cortante - local não especificado"
  },
  {
    "codigo": "W260",
    "descricao": "Contato com faca, espada e punhal - residência"
  },
  {
    "codigo": "W261",
    "descricao": "Contato com faca, espada e punhal - habitação coletiva"
  },
  {
    "codigo": "W262",
    "descricao": "Contato com faca, espada e punhal - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W263",
    "descricao": "Contato com faca, espada e punhal - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W264",
    "descricao": "Contato com faca, espada e punhal - rua e estrada"
  },
  {
    "codigo": "W265",
    "descricao": "Contato com faca, espada e punhal - áreas de comércio e de serviços"
  },
  {
    "codigo": "W266",
    "descricao": "Contato com faca, espada e punhal - áreas industriais e em construção"
  },
  {
    "codigo": "W267",
    "descricao": "Contato com faca, espada e punhal - fazenda"
  },
  {
    "codigo": "W268",
    "descricao": "Contato com faca, espada e punhal - outros locais especificados"
  },
  {
    "codigo": "W269",
    "descricao": "Contato com faca, espada e punhal - local não especificado"
  },
  {
    "codigo": "W270",
    "descricao": "Contato com ferramentas manuais sem motor - residência"
  },
  {
    "codigo": "W271",
    "descricao": "Contato com ferramentas manuais sem motor - habitação coletiva"
  },
  {
    "codigo": "W272",
    "descricao": "Contato com ferramentas manuais sem motor - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W273",
    "descricao": "Contato com ferramentas manuais sem motor - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W274",
    "descricao": "Contato com ferramentas manuais sem motor - rua e estrada"
  },
  {
    "codigo": "W275",
    "descricao": "Contato com ferramentas manuais sem motor - áreas de comércio e de serviços"
  },
  {
    "codigo": "W276",
    "descricao": "Contato com ferramentas manuais sem motor - áreas industriais e em construção"
  },
  {
    "codigo": "W277",
    "descricao": "Contato com ferramentas manuais sem motor - fazenda"
  },
  {
    "codigo": "W278",
    "descricao": "Contato com ferramentas manuais sem motor - outros locais especificados"
  },
  {
    "codigo": "W279",
    "descricao": "Contato com ferramentas manuais sem motor - local não especificado"
  },
  {
    "codigo": "W280",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - residência"
  },
  {
    "codigo": "W281",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - habitação coletiva"
  },
  {
    "codigo": "W282",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W283",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W284",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - rua e estrada"
  },
  {
    "codigo": "W285",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - áreas de comércio e de serviços"
  },
  {
    "codigo": "W286",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - áreas industriais e em construção"
  },
  {
    "codigo": "W287",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - fazenda"
  },
  {
    "codigo": "W288",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - outros locais especificados"
  },
  {
    "codigo": "W289",
    "descricao": "Contato com segadeira motorizada para cortar ou aparar a grama - local não especificado"
  },
  {
    "codigo": "W290",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - residência"
  },
  {
    "codigo": "W291",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - habitação coletiva"
  },
  {
    "codigo": "W292",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W293",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W294",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - rua e estrada"
  },
  {
    "codigo": "W295",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - áreas de comércio e de serviços"
  },
  {
    "codigo": "W296",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - áreas industriais e em construção"
  },
  {
    "codigo": "W297",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - fazenda"
  },
  {
    "codigo": "W298",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - outros locais especificados"
  },
  {
    "codigo": "W299",
    "descricao": "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - local não especificado"
  },
  {
    "codigo": "W300",
    "descricao": "Contato com maquinaria agrícola - residência"
  },
  {
    "codigo": "W301",
    "descricao": "Contato com maquinaria agrícola - habitação coletiva"
  },
  {
    "codigo": "W302",
    "descricao": "Contato com maquinaria agrícola - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W303",
    "descricao": "Contato com maquinaria agrícola - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W304",
    "descricao": "Contato com maquinaria agrícola - rua e estrada"
  },
  {
    "codigo": "W305",
    "descricao": "Contato com maquinaria agrícola - áreas de comércio e de serviços"
  },
  {
    "codigo": "W306",
    "descricao": "Contato com maquinaria agrícola - áreas industriais e em construção"
  },
  {
    "codigo": "W307",
    "descricao": "Contato com maquinaria agrícola - fazenda"
  },
  {
    "codigo": "W308",
    "descricao": "Contato com maquinaria agrícola - outros locais especificados"
  },
  {
    "codigo": "W309",
    "descricao": "Contato com maquinaria agrícola - local não especificado"
  },
  {
    "codigo": "W310",
    "descricao": "Contato com outras máquinas e com as não especificadas - residência"
  },
  {
    "codigo": "W311",
    "descricao": "Contato com outras máquinas e com as não especificadas - habitação coletiva"
  },
  {
    "codigo": "W312",
    "descricao": "Contato com outras máquinas e com as não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W313",
    "descricao": "Contato com outras máquinas e com as não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W314",
    "descricao": "Contato com outras máquinas e com as não especificadas - rua e estrada"
  },
  {
    "codigo": "W315",
    "descricao": "Contato com outras máquinas e com as não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W316",
    "descricao": "Contato com outras máquinas e com as não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "W317",
    "descricao": "Contato com outras máquinas e com as não especificadas - fazenda"
  },
  {
    "codigo": "W318",
    "descricao": "Contato com outras máquinas e com as não especificadas - outros locais especificados"
  },
  {
    "codigo": "W319",
    "descricao": "Contato com outras máquinas e com as não especificadas - local não especificado"
  },
  {
    "codigo": "W320",
    "descricao": "Projétil de revólver - residência"
  },
  {
    "codigo": "W321",
    "descricao": "Projétil de revólver - habitação coletiva"
  },
  {
    "codigo": "W322",
    "descricao": "Projétil de revólver - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W323",
    "descricao": "Projétil de revólver - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W324",
    "descricao": "Projétil de revólver - rua e estrada"
  },
  {
    "codigo": "W325",
    "descricao": "Projétil de revólver - áreas de comércio e de serviços"
  },
  {
    "codigo": "W326",
    "descricao": "Projétil de revólver - áreas industriais e em construção"
  },
  { "codigo": "W327", "descricao": "Projétil de revólver - fazenda" },
  {
    "codigo": "W328",
    "descricao": "Projétil de revólver - outros locais especificados"
  },
  {
    "codigo": "W329",
    "descricao": "Projétil de revólver - local não especificado"
  },
  {
    "codigo": "W330",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - residência"
  },
  {
    "codigo": "W331",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - habitação coletiva"
  },
  {
    "codigo": "W332",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W333",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W334",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - rua e estrada"
  },
  {
    "codigo": "W335",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - áreas de comércio e de serviços"
  },
  {
    "codigo": "W336",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - áreas industriais e em construção"
  },
  {
    "codigo": "W337",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - fazenda"
  },
  {
    "codigo": "W338",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - outros locais especificados"
  },
  {
    "codigo": "W339",
    "descricao": "Rifle, espingarda e armas de fogo de maior tamanho - local não especificado"
  },
  {
    "codigo": "W340",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - residência"
  },
  {
    "codigo": "W341",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - habitação coletiva"
  },
  {
    "codigo": "W342",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W343",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W344",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - rua e estrada"
  },
  {
    "codigo": "W345",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W346",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "W347",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - fazenda"
  },
  {
    "codigo": "W348",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - outros locais especificados"
  },
  {
    "codigo": "W349",
    "descricao": "Projéteis de outras armas de fogo e das não especificadas - local não especificado"
  },
  {
    "codigo": "W350",
    "descricao": "Explosão ou ruptura de caldeira - residência"
  },
  {
    "codigo": "W351",
    "descricao": "Explosão ou ruptura de caldeira - habitação coletiva"
  },
  {
    "codigo": "W352",
    "descricao": "Explosão ou ruptura de caldeira - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W353",
    "descricao": "Explosão ou ruptura de caldeira - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W354",
    "descricao": "Explosão ou ruptura de caldeira - rua e estrada"
  },
  {
    "codigo": "W355",
    "descricao": "Explosão ou ruptura de caldeira - áreas de comércio e de serviços"
  },
  {
    "codigo": "W356",
    "descricao": "Explosão ou ruptura de caldeira - áreas industriais e em construção"
  },
  {
    "codigo": "W357",
    "descricao": "Explosão ou ruptura de caldeira - fazenda"
  },
  {
    "codigo": "W358",
    "descricao": "Explosão ou ruptura de caldeira - outros locais especificados"
  },
  {
    "codigo": "W359",
    "descricao": "Explosão ou ruptura de caldeira - local não especificado"
  },
  {
    "codigo": "W360",
    "descricao": "Explosão ou ruptura de cilindro de gás - residência"
  },
  {
    "codigo": "W361",
    "descricao": "Explosão ou ruptura de cilindro de gás - habitação coletiva"
  },
  {
    "codigo": "W362",
    "descricao": "Explosão ou ruptura de cilindro de gás - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W363",
    "descricao": "Explosão ou ruptura de cilindro de gás - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W364",
    "descricao": "Explosão ou ruptura de cilindro de gás - rua e estrada"
  },
  {
    "codigo": "W365",
    "descricao": "Explosão ou ruptura de cilindro de gás - áreas de comércio e de serviços"
  },
  {
    "codigo": "W366",
    "descricao": "Explosão ou ruptura de cilindro de gás - áreas industriais e em construção"
  },
  {
    "codigo": "W367",
    "descricao": "Explosão ou ruptura de cilindro de gás - fazenda"
  },
  {
    "codigo": "W368",
    "descricao": "Explosão ou ruptura de cilindro de gás - outros locais especificados"
  },
  {
    "codigo": "W369",
    "descricao": "Explosão ou ruptura de cilindro de gás - local não especificado"
  },
  {
    "codigo": "W370",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - residência"
  },
  {
    "codigo": "W371",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - habitação coletiva"
  },
  {
    "codigo": "W372",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W373",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W374",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - rua e estrada"
  },
  {
    "codigo": "W375",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - áreas de comércio e de serviços"
  },
  {
    "codigo": "W376",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - áreas industriais e em construção"
  },
  {
    "codigo": "W377",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - fazenda"
  },
  {
    "codigo": "W378",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - outros locais especificados"
  },
  {
    "codigo": "W379",
    "descricao": "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - local não especificado"
  },
  {
    "codigo": "W380",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - residência"
  },
  {
    "codigo": "W381",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - habitação coletiva"
  },
  {
    "codigo": "W382",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W383",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W384",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - rua e estrada"
  },
  {
    "codigo": "W385",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "W386",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - áreas industriais e em construção"
  },
  {
    "codigo": "W387",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - fazenda"
  },
  {
    "codigo": "W388",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - outros locais especificados"
  },
  {
    "codigo": "W389",
    "descricao": "Explosão ou ruptura de outros aparelhos pressurizados especificados - local não especificado"
  },
  {
    "codigo": "W390",
    "descricao": "Queima de fogos de artifício - residência"
  },
  {
    "codigo": "W391",
    "descricao": "Queima de fogos de artifício - habitação coletiva"
  },
  {
    "codigo": "W392",
    "descricao": "Queima de fogos de artifício - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W393",
    "descricao": "Queima de fogos de artifício - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W394",
    "descricao": "Queima de fogos de artifício - rua e estrada"
  },
  {
    "codigo": "W395",
    "descricao": "Queima de fogos de artifício - áreas de comércio e de serviços"
  },
  {
    "codigo": "W396",
    "descricao": "Queima de fogos de artifício - áreas industriais e em construção"
  },
  {
    "codigo": "W397",
    "descricao": "Queima de fogos de artifício - fazenda"
  },
  {
    "codigo": "W398",
    "descricao": "Queima de fogos de artifício - outros locais especificados"
  },
  {
    "codigo": "W399",
    "descricao": "Queima de fogos de artifício - local não especificado"
  },
  {
    "codigo": "W400",
    "descricao": "Explosão de outros materiais - residência"
  },
  {
    "codigo": "W401",
    "descricao": "Explosão de outros materiais - habitação coletiva"
  },
  {
    "codigo": "W402",
    "descricao": "Explosão de outros materiais - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W403",
    "descricao": "Explosão de outros materiais - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W404",
    "descricao": "Explosão de outros materiais - rua e estrada"
  },
  {
    "codigo": "W405",
    "descricao": "Explosão de outros materiais - áreas de comércio e de serviços"
  },
  {
    "codigo": "W406",
    "descricao": "Explosão de outros materiais - áreas industriais e em construção"
  },
  {
    "codigo": "W407",
    "descricao": "Explosão de outros materiais - fazenda"
  },
  {
    "codigo": "W408",
    "descricao": "Explosão de outros materiais - outros locais especificados"
  },
  {
    "codigo": "W409",
    "descricao": "Explosão de outros materiais - local não especificado"
  },
  {
    "codigo": "W410",
    "descricao": "Exposição a um jato de alta pressão - residência"
  },
  {
    "codigo": "W411",
    "descricao": "Exposição a um jato de alta pressão - habitação coletiva"
  },
  {
    "codigo": "W412",
    "descricao": "Exposição a um jato de alta pressão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W413",
    "descricao": "Exposição a um jato de alta pressão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W414",
    "descricao": "Exposição a um jato de alta pressão - rua e estrada"
  },
  {
    "codigo": "W415",
    "descricao": "Exposição a um jato de alta pressão - áreas de comércio e de serviços"
  },
  {
    "codigo": "W416",
    "descricao": "Exposição a um jato de alta pressão - áreas industriais e em construção"
  },
  {
    "codigo": "W417",
    "descricao": "Exposição a um jato de alta pressão - fazenda"
  },
  {
    "codigo": "W418",
    "descricao": "Exposição a um jato de alta pressão - outros locais especificados"
  },
  {
    "codigo": "W419",
    "descricao": "Exposição a um jato de alta pressão - local não especificado"
  },
  {
    "codigo": "W420",
    "descricao": "Exposição ao ruído - residência"
  },
  {
    "codigo": "W421",
    "descricao": "Exposição ao ruído - habitação coletiva"
  },
  {
    "codigo": "W422",
    "descricao": "Exposição ao ruído - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W423",
    "descricao": "Exposição ao ruído - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W424",
    "descricao": "Exposição ao ruído - rua e estrada"
  },
  {
    "codigo": "W425",
    "descricao": "Exposição ao ruído - áreas de comércio e de serviços"
  },
  {
    "codigo": "W426",
    "descricao": "Exposição ao ruído - áreas industriais e em construção"
  },
  {
    "codigo": "W427",
    "descricao": "Exposição ao ruído - fazenda"
  },
  {
    "codigo": "W428",
    "descricao": "Exposição ao ruído - outros locais especificados"
  },
  {
    "codigo": "W429",
    "descricao": "Exposição ao ruído - local não especificado"
  },
  {
    "codigo": "W430",
    "descricao": "Exposição à vibração - residência"
  },
  {
    "codigo": "W431",
    "descricao": "Exposição à vibração - habitação coletiva"
  },
  {
    "codigo": "W432",
    "descricao": "Exposição à vibração - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W433",
    "descricao": "Exposição à vibração - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W434",
    "descricao": "Exposição à vibração - rua e estrada"
  },
  {
    "codigo": "W435",
    "descricao": "Exposição à vibração - áreas de comércio e de serviços"
  },
  {
    "codigo": "W436",
    "descricao": "Exposição à vibração - áreas industriais e em construção"
  },
  {
    "codigo": "W437",
    "descricao": "Exposição à vibração - fazenda"
  },
  {
    "codigo": "W438",
    "descricao": "Exposição à vibração - outros locais especificados"
  },
  {
    "codigo": "W439",
    "descricao": "Exposição à vibração - local não especificado"
  },
  {
    "codigo": "W440",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - residência"
  },
  {
    "codigo": "W441",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - habitação coletiva"
  },
  {
    "codigo": "W442",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W443",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W444",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - rua e estrada"
  },
  {
    "codigo": "W445",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - áreas de comércio e de serviços"
  },
  {
    "codigo": "W446",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - áreas industriais e em construção"
  },
  {
    "codigo": "W447",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - fazenda"
  },
  {
    "codigo": "W448",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - outros locais especificados"
  },
  {
    "codigo": "W449",
    "descricao": "Penetração de corpo estranho no ou através de olho ou orifício natural - local não especificado"
  },
  {
    "codigo": "W450",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - residência"
  },
  {
    "codigo": "W451",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - habitação coletiva"
  },
  {
    "codigo": "W452",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W453",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W454",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - rua e estrada"
  },
  {
    "codigo": "W455",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - áreas de comércio e de serviços"
  },
  {
    "codigo": "W456",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - áreas industriais e em construção"
  },
  {
    "codigo": "W457",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - fazenda"
  },
  {
    "codigo": "W458",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - outros locais especificados"
  },
  {
    "codigo": "W459",
    "descricao": "Penetração de corpo ou objeto estranho através da pele - local não especificado"
  },
  {
    "codigo": "W460",
    "descricao": "Contato com agulha hipodérmica - residência"
  },
  {
    "codigo": "W461",
    "descricao": "Contato com agulha hipodérmica - habitação coletiva"
  },
  {
    "codigo": "W462",
    "descricao": "Contato com agulha hipodérmica - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W463",
    "descricao": "Contato com agulha hipodérmica - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W464",
    "descricao": "Contato com agulha hipodérmica - rua e estrada"
  },
  {
    "codigo": "W465",
    "descricao": "Contato com agulha hipodérmica - áreas de comércio e de serviços"
  },
  {
    "codigo": "W466",
    "descricao": "Contato com agulha hipodérmica - áreas industriais e em construção"
  },
  {
    "codigo": "W467",
    "descricao": "Contato com agulha hipodérmica - fazenda"
  },
  {
    "codigo": "W468",
    "descricao": "Contato com agulha hipodérmica - outros locais especificados"
  },
  {
    "codigo": "W469",
    "descricao": "Contato com agulha hipodérmica - local não especificado"
  },
  {
    "codigo": "W490",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - residência"
  },
  {
    "codigo": "W491",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - habitação coletiva"
  },
  {
    "codigo": "W492",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W493",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W494",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - rua e estrada"
  },
  {
    "codigo": "W495",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W496",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "W497",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - fazenda"
  },
  {
    "codigo": "W498",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - outros locais especificados"
  },
  {
    "codigo": "W499",
    "descricao": "Exposição a outras forças mecânicas inanimadas e às não especificadas - local não especificado"
  },
  {
    "codigo": "W500",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - residência"
  },
  {
    "codigo": "W501",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - habitação coletiva"
  },
  {
    "codigo": "W502",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W503",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W504",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - rua e estrada"
  },
  {
    "codigo": "W505",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - áreas de comércio e de serviços"
  },
  {
    "codigo": "W506",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - áreas industriais e em construção"
  },
  {
    "codigo": "W507",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - fazenda"
  },
  {
    "codigo": "W508",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - outros locais especificados"
  },
  {
    "codigo": "W509",
    "descricao": "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - local não especificado"
  },
  {
    "codigo": "W510",
    "descricao": "Colisão entre duas pessoas - residência"
  },
  {
    "codigo": "W511",
    "descricao": "Colisão entre duas pessoas - habitação coletiva"
  },
  {
    "codigo": "W512",
    "descricao": "Colisão entre duas pessoas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W513",
    "descricao": "Colisão entre duas pessoas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W514",
    "descricao": "Colisão entre duas pessoas - rua e estrada"
  },
  {
    "codigo": "W515",
    "descricao": "Colisão entre duas pessoas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W516",
    "descricao": "Colisão entre duas pessoas - áreas industriais e em construção"
  },
  {
    "codigo": "W517",
    "descricao": "Colisão entre duas pessoas - fazenda"
  },
  {
    "codigo": "W518",
    "descricao": "Colisão entre duas pessoas - outros locais especificados"
  },
  {
    "codigo": "W519",
    "descricao": "Colisão entre duas pessoas - local não especificado"
  },
  {
    "codigo": "W520",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - residência"
  },
  {
    "codigo": "W521",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - habitação coletiva"
  },
  {
    "codigo": "W522",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W523",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W524",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - rua e estrada"
  },
  {
    "codigo": "W525",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W526",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - áreas industriais e em construção"
  },
  {
    "codigo": "W527",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - fazenda"
  },
  {
    "codigo": "W528",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - outros locais especificados"
  },
  {
    "codigo": "W529",
    "descricao": "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - local não especificado"
  },
  { "codigo": "W530", "descricao": "Mordedura de rato - residência" },
  {
    "codigo": "W531",
    "descricao": "Mordedura de rato - habitação coletiva"
  },
  {
    "codigo": "W532",
    "descricao": "Mordedura de rato - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W533",
    "descricao": "Mordedura de rato - área para a prática de esportes e atletismo"
  },
  { "codigo": "W534", "descricao": "Mordedura de rato - rua e estrada" },
  {
    "codigo": "W535",
    "descricao": "Mordedura de rato - áreas de comércio e de serviços"
  },
  {
    "codigo": "W536",
    "descricao": "Mordedura de rato - áreas industriais e em construção"
  },
  { "codigo": "W537", "descricao": "Mordedura de rato - fazenda" },
  {
    "codigo": "W538",
    "descricao": "Mordedura de rato - outros locais especificados"
  },
  {
    "codigo": "W539",
    "descricao": "Mordedura de rato - local não especificado"
  },
  {
    "codigo": "W540",
    "descricao": "Mordedura ou golpe provocado por cão - residência"
  },
  {
    "codigo": "W541",
    "descricao": "Mordedura ou golpe provocado por cão - habitação coletiva"
  },
  {
    "codigo": "W542",
    "descricao": "Mordedura ou golpe provocado por cão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W543",
    "descricao": "Mordedura ou golpe provocado por cão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W544",
    "descricao": "Mordedura ou golpe provocado por cão - rua e estrada"
  },
  {
    "codigo": "W545",
    "descricao": "Mordedura ou golpe provocado por cão - áreas de comércio e de serviços"
  },
  {
    "codigo": "W546",
    "descricao": "Mordedura ou golpe provocado por cão - áreas industriais e em construção"
  },
  {
    "codigo": "W547",
    "descricao": "Mordedura ou golpe provocado por cão - fazenda"
  },
  {
    "codigo": "W548",
    "descricao": "Mordedura ou golpe provocado por cão - outros locais especificados"
  },
  {
    "codigo": "W549",
    "descricao": "Mordedura ou golpe provocado por cão - local não especificado"
  },
  {
    "codigo": "W550",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - residência"
  },
  {
    "codigo": "W551",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - habitação coletiva"
  },
  {
    "codigo": "W552",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W553",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W554",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - rua e estrada"
  },
  {
    "codigo": "W555",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - áreas de comércio e de serviços"
  },
  {
    "codigo": "W556",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - áreas industriais e em construção"
  },
  {
    "codigo": "W557",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - fazenda"
  },
  {
    "codigo": "W558",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - outros locais especificados"
  },
  {
    "codigo": "W559",
    "descricao": "Mordedura ou golpe provocado por outros animais mamíferos - local não especificado"
  },
  {
    "codigo": "W560",
    "descricao": "Contato com animais marinhos - residência"
  },
  {
    "codigo": "W561",
    "descricao": "Contato com animais marinhos - habitação coletiva"
  },
  {
    "codigo": "W562",
    "descricao": "Contato com animais marinhos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W563",
    "descricao": "Contato com animais marinhos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W564",
    "descricao": "Contato com animais marinhos - rua e estrada"
  },
  {
    "codigo": "W565",
    "descricao": "Contato com animais marinhos - áreas de comércio e de serviços"
  },
  {
    "codigo": "W566",
    "descricao": "Contato com animais marinhos - áreas industriais e em construção"
  },
  { "codigo": "W567", "descricao": "Contato com animais marinhos - fazenda" },
  {
    "codigo": "W568",
    "descricao": "Contato com animais marinhos - outros locais especificados"
  },
  {
    "codigo": "W569",
    "descricao": "Contato com animais marinhos - local não especificado"
  },
  {
    "codigo": "W570",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - residência"
  },
  {
    "codigo": "W571",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - habitação coletiva"
  },
  {
    "codigo": "W572",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W573",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W574",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - rua e estrada"
  },
  {
    "codigo": "W575",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - áreas de comércio e de serviços"
  },
  {
    "codigo": "W576",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - áreas industriais e em construção"
  },
  {
    "codigo": "W577",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - fazenda"
  },
  {
    "codigo": "W578",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - outros locais especificados"
  },
  {
    "codigo": "W579",
    "descricao": "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - local não especificado"
  },
  {
    "codigo": "W580",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - residência"
  },
  {
    "codigo": "W581",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - habitação coletiva"
  },
  {
    "codigo": "W582",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W583",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W584",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - rua e estrada"
  },
  {
    "codigo": "W585",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - áreas de comércio e de serviços"
  },
  {
    "codigo": "W586",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - áreas industriais e em construção"
  },
  {
    "codigo": "W587",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - fazenda"
  },
  {
    "codigo": "W588",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - outros locais especificados"
  },
  {
    "codigo": "W589",
    "descricao": "Mordedura ou golpe provocado por crocodilo ou aligátor - local não especificado"
  },
  {
    "codigo": "W590",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - residência"
  },
  {
    "codigo": "W591",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - habitação coletiva"
  },
  {
    "codigo": "W592",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W593",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W594",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - rua e estrada"
  },
  {
    "codigo": "W595",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - áreas de comércio e de serviços"
  },
  {
    "codigo": "W596",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - áreas industriais e em construção"
  },
  {
    "codigo": "W597",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - fazenda"
  },
  {
    "codigo": "W598",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - outros locais especificados"
  },
  {
    "codigo": "W599",
    "descricao": "Mordedura ou esmagamento provocado por outros répteis - local não especificado"
  },
  {
    "codigo": "W600",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - residência"
  },
  {
    "codigo": "W601",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - habitação coletiva"
  },
  {
    "codigo": "W602",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W603",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W604",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - rua e estrada"
  },
  {
    "codigo": "W605",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W606",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - áreas industriais e em construção"
  },
  {
    "codigo": "W607",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - fazenda"
  },
  {
    "codigo": "W608",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - outros locais especificados"
  },
  {
    "codigo": "W609",
    "descricao": "Contato com espinhos de plantas ou com folhas aguçadas - local não especificado"
  },
  {
    "codigo": "W640",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - residência"
  },
  {
    "codigo": "W641",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - habitação coletiva"
  },
  {
    "codigo": "W642",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W643",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W644",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - rua e estrada"
  },
  {
    "codigo": "W645",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "W646",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "W647",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - fazenda"
  },
  {
    "codigo": "W648",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - outros locais especificados"
  },
  {
    "codigo": "W649",
    "descricao": "Exposição a outras forças mecânicas animadas e às não especificadas - local não especificado"
  },
  {
    "codigo": "W650",
    "descricao": "Afogamento e submersão durante banho em banheira - residência"
  },
  {
    "codigo": "W651",
    "descricao": "Afogamento e submersão durante banho em banheira - habitação coletiva"
  },
  {
    "codigo": "W652",
    "descricao": "Afogamento e submersão durante banho em banheira - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W653",
    "descricao": "Afogamento e submersão durante banho em banheira - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W654",
    "descricao": "Afogamento e submersão durante banho em banheira - rua e estrada"
  },
  {
    "codigo": "W655",
    "descricao": "Afogamento e submersão durante banho em banheira - áreas de comércio e de serviços"
  },
  {
    "codigo": "W656",
    "descricao": "Afogamento e submersão durante banho em banheira - áreas industriais e em construção"
  },
  {
    "codigo": "W657",
    "descricao": "Afogamento e submersão durante banho em banheira - fazenda"
  },
  {
    "codigo": "W658",
    "descricao": "Afogamento e submersão durante banho em banheira - outros locais especificados"
  },
  {
    "codigo": "W659",
    "descricao": "Afogamento e submersão durante banho em banheira - local não especificado"
  },
  {
    "codigo": "W660",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - residência"
  },
  {
    "codigo": "W661",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - habitação coletiva"
  },
  {
    "codigo": "W662",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W663",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W664",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - rua e estrada"
  },
  {
    "codigo": "W665",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - áreas de comércio e de serviços"
  },
  {
    "codigo": "W666",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - áreas industriais e em construção"
  },
  {
    "codigo": "W667",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - fazenda"
  },
  {
    "codigo": "W668",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - outros locais especificados"
  },
  {
    "codigo": "W669",
    "descricao": "Afogamento e submersão consecutiva a queda dentro de uma banheira - local não especificado"
  },
  {
    "codigo": "W670",
    "descricao": "Afogamento e submersão em piscina - residência"
  },
  {
    "codigo": "W671",
    "descricao": "Afogamento e submersão em piscina - habitação coletiva"
  },
  {
    "codigo": "W672",
    "descricao": "Afogamento e submersão em piscina - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W673",
    "descricao": "Afogamento e submersão em piscina - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W674",
    "descricao": "Afogamento e submersão em piscina - rua e estrada"
  },
  {
    "codigo": "W675",
    "descricao": "Afogamento e submersão em piscina - áreas de comércio e de serviços"
  },
  {
    "codigo": "W676",
    "descricao": "Afogamento e submersão em piscina - áreas industriais e em construção"
  },
  {
    "codigo": "W677",
    "descricao": "Afogamento e submersão em piscina - fazenda"
  },
  {
    "codigo": "W678",
    "descricao": "Afogamento e submersão em piscina - outros locais especificados"
  },
  {
    "codigo": "W679",
    "descricao": "Afogamento e submersão em piscina - local não especificado"
  },
  {
    "codigo": "W680",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - residência"
  },
  {
    "codigo": "W681",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - habitação coletiva"
  },
  {
    "codigo": "W682",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W683",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W684",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - rua e estrada"
  },
  {
    "codigo": "W685",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - áreas de comércio e de serviços"
  },
  {
    "codigo": "W686",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - áreas industriais e em construção"
  },
  {
    "codigo": "W687",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - fazenda"
  },
  {
    "codigo": "W688",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - outros locais especificados"
  },
  {
    "codigo": "W689",
    "descricao": "Afogamento e submersão conseqüente a queda dentro de uma piscina - local não especificado"
  },
  {
    "codigo": "W690",
    "descricao": "Afogamento e submersão em águas naturais - residência"
  },
  {
    "codigo": "W691",
    "descricao": "Afogamento e submersão em águas naturais - habitação coletiva"
  },
  {
    "codigo": "W692",
    "descricao": "Afogamento e submersão em águas naturais - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W693",
    "descricao": "Afogamento e submersão em águas naturais - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W694",
    "descricao": "Afogamento e submersão em águas naturais - rua e estrada"
  },
  {
    "codigo": "W695",
    "descricao": "Afogamento e submersão em águas naturais - áreas de comércio e de serviços"
  },
  {
    "codigo": "W696",
    "descricao": "Afogamento e submersão em águas naturais - áreas industriais e em construção"
  },
  {
    "codigo": "W697",
    "descricao": "Afogamento e submersão em águas naturais - fazenda"
  },
  {
    "codigo": "W698",
    "descricao": "Afogamento e submersão em águas naturais - outros locais especificados"
  },
  {
    "codigo": "W699",
    "descricao": "Afogamento e submersão em águas naturais - local não especificado"
  },
  {
    "codigo": "W700",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - residência"
  },
  {
    "codigo": "W701",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - habitação coletiva"
  },
  {
    "codigo": "W702",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W703",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W704",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - rua e estrada"
  },
  {
    "codigo": "W705",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - áreas de comércio e de serviços"
  },
  {
    "codigo": "W706",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - áreas industriais e em construção"
  },
  {
    "codigo": "W707",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - fazenda"
  },
  {
    "codigo": "W708",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - outros locais especificados"
  },
  {
    "codigo": "W709",
    "descricao": "Afogamento e submersão conseqüentes a queda dentro de águas naturais - local não especificado"
  },
  {
    "codigo": "W730",
    "descricao": "Outros afogamentos e submersão especificados - residência"
  },
  {
    "codigo": "W731",
    "descricao": "Outros afogamentos e submersão especificados - habitação coletiva"
  },
  {
    "codigo": "W732",
    "descricao": "Outros afogamentos e submersão especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W733",
    "descricao": "Outros afogamentos e submersão especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W734",
    "descricao": "Outros afogamentos e submersão especificados - rua e estrada"
  },
  {
    "codigo": "W735",
    "descricao": "Outros afogamentos e submersão especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "W736",
    "descricao": "Outros afogamentos e submersão especificados - áreas industriais e em construção"
  },
  {
    "codigo": "W737",
    "descricao": "Outros afogamentos e submersão especificados - fazenda"
  },
  {
    "codigo": "W738",
    "descricao": "Outros afogamentos e submersão especificados - outros locais especificados"
  },
  {
    "codigo": "W739",
    "descricao": "Outros afogamentos e submersão especificados - local não especificado"
  },
  {
    "codigo": "W740",
    "descricao": "Afogamento e submersão não especificados - residência"
  },
  {
    "codigo": "W741",
    "descricao": "Afogamento e submersão não especificados - habitação coletiva"
  },
  {
    "codigo": "W742",
    "descricao": "Afogamento e submersão não especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W743",
    "descricao": "Afogamento e submersão não especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W744",
    "descricao": "Afogamento e submersão não especificados - rua e estrada"
  },
  {
    "codigo": "W745",
    "descricao": "Afogamento e submersão não especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "W746",
    "descricao": "Afogamento e submersão não especificados - áreas industriais e em construção"
  },
  {
    "codigo": "W747",
    "descricao": "Afogamento e submersão não especificados - fazenda"
  },
  {
    "codigo": "W748",
    "descricao": "Afogamento e submersão não especificados - outros locais especificados"
  },
  {
    "codigo": "W749",
    "descricao": "Afogamento e submersão não especificados - local não especificado"
  },
  {
    "codigo": "W750",
    "descricao": "Sufocação e estrangulamento acidental na cama - residência"
  },
  {
    "codigo": "W751",
    "descricao": "Sufocação e estrangulamento acidental na cama - habitação coletiva"
  },
  {
    "codigo": "W752",
    "descricao": "Sufocação e estrangulamento acidental na cama - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W753",
    "descricao": "Sufocação e estrangulamento acidental na cama - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W754",
    "descricao": "Sufocação e estrangulamento acidental na cama - rua e estrada"
  },
  {
    "codigo": "W755",
    "descricao": "Sufocação e estrangulamento acidental na cama - áreas de comércio e de serviços"
  },
  {
    "codigo": "W756",
    "descricao": "Sufocação e estrangulamento acidental na cama - áreas industriais e em construção"
  },
  {
    "codigo": "W757",
    "descricao": "Sufocação e estrangulamento acidental na cama - fazenda"
  },
  {
    "codigo": "W758",
    "descricao": "Sufocação e estrangulamento acidental na cama - outros locais especificados"
  },
  {
    "codigo": "W759",
    "descricao": "Sufocação e estrangulamento acidental na cama - local não especificado"
  },
  {
    "codigo": "W760",
    "descricao": "Outro enforcamento e estrangulamento acidental - residência"
  },
  {
    "codigo": "W761",
    "descricao": "Outro enforcamento e estrangulamento acidental - habitação coletiva"
  },
  {
    "codigo": "W762",
    "descricao": "Outro enforcamento e estrangulamento acidental - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W763",
    "descricao": "Outro enforcamento e estrangulamento acidental - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W764",
    "descricao": "Outro enforcamento e estrangulamento acidental - rua e estrada"
  },
  {
    "codigo": "W765",
    "descricao": "Outro enforcamento e estrangulamento acidental - áreas de comércio e de serviços"
  },
  {
    "codigo": "W766",
    "descricao": "Outro enforcamento e estrangulamento acidental - áreas industriais e em construção"
  },
  {
    "codigo": "W767",
    "descricao": "Outro enforcamento e estrangulamento acidental - fazenda"
  },
  {
    "codigo": "W768",
    "descricao": "Outro enforcamento e estrangulamento acidental - outros locais especificados"
  },
  {
    "codigo": "W769",
    "descricao": "Outro enforcamento e estrangulamento acidental - local não especificado"
  },
  {
    "codigo": "W770",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - residência"
  },
  {
    "codigo": "W771",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - habitação coletiva"
  },
  {
    "codigo": "W772",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W773",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W774",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - rua e estrada"
  },
  {
    "codigo": "W775",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - áreas de comércio e de serviços"
  },
  {
    "codigo": "W776",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - áreas industriais e em construção"
  },
  {
    "codigo": "W777",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - fazenda"
  },
  {
    "codigo": "W778",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - outros locais especificados"
  },
  {
    "codigo": "W779",
    "descricao": "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - local não especificado"
  },
  {
    "codigo": "W780",
    "descricao": "Inalação do conteúdo gástrico - residência"
  },
  {
    "codigo": "W781",
    "descricao": "Inalação do conteúdo gástrico - habitação coletiva"
  },
  {
    "codigo": "W782",
    "descricao": "Inalação do conteúdo gástrico - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W783",
    "descricao": "Inalação do conteúdo gástrico - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W784",
    "descricao": "Inalação do conteúdo gástrico - rua e estrada"
  },
  {
    "codigo": "W785",
    "descricao": "Inalação do conteúdo gástrico - áreas de comércio e de serviços"
  },
  {
    "codigo": "W786",
    "descricao": "Inalação do conteúdo gástrico - áreas industriais e em construção"
  },
  {
    "codigo": "W787",
    "descricao": "Inalação do conteúdo gástrico - fazenda"
  },
  {
    "codigo": "W788",
    "descricao": "Inalação do conteúdo gástrico - outros locais especificados"
  },
  {
    "codigo": "W789",
    "descricao": "Inalação do conteúdo gástrico - local não especificado"
  },
  {
    "codigo": "W790",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - residência"
  },
  {
    "codigo": "W791",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - habitação coletiva"
  },
  {
    "codigo": "W792",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W793",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W794",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - rua e estrada"
  },
  {
    "codigo": "W795",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - áreas de comércio e de serviços"
  },
  {
    "codigo": "W796",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - áreas industriais e em construção"
  },
  {
    "codigo": "W797",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - fazenda"
  },
  {
    "codigo": "W798",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - outros locais especificados"
  },
  {
    "codigo": "W799",
    "descricao": "Inalação e ingestão de alimentos causando obstrução do trato respiratório - local não especificado"
  },
  {
    "codigo": "W800",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - residência"
  },
  {
    "codigo": "W801",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - habitação coletiva"
  },
  {
    "codigo": "W802",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W803",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W804",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - rua e estrada"
  },
  {
    "codigo": "W805",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - áreas de comércio e de serviços"
  },
  {
    "codigo": "W806",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - áreas industriais e em construção"
  },
  {
    "codigo": "W807",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - fazenda"
  },
  {
    "codigo": "W808",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - outros locais especificados"
  },
  {
    "codigo": "W809",
    "descricao": "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - local não especificado"
  },
  {
    "codigo": "W810",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - residência"
  },
  {
    "codigo": "W811",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - habitação coletiva"
  },
  {
    "codigo": "W812",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W813",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W814",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - rua e estrada"
  },
  {
    "codigo": "W815",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - áreas de comércio e de serviços"
  },
  {
    "codigo": "W816",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - áreas industriais e em construção"
  },
  {
    "codigo": "W817",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - fazenda"
  },
  {
    "codigo": "W818",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - outros locais especificados"
  },
  {
    "codigo": "W819",
    "descricao": "Confinado ou aprisionado em um ambiente pobre em oxigênio - local não especificado"
  },
  {
    "codigo": "W830",
    "descricao": "Outros riscos especificados à respiração - residência"
  },
  {
    "codigo": "W831",
    "descricao": "Outros riscos especificados à respiração - habitação coletiva"
  },
  {
    "codigo": "W832",
    "descricao": "Outros riscos especificados à respiração - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W833",
    "descricao": "Outros riscos especificados à respiração - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W834",
    "descricao": "Outros riscos especificados à respiração - rua e estrada"
  },
  {
    "codigo": "W835",
    "descricao": "Outros riscos especificados à respiração - áreas de comércio e de serviços"
  },
  {
    "codigo": "W836",
    "descricao": "Outros riscos especificados à respiração - áreas industriais e em construção"
  },
  {
    "codigo": "W837",
    "descricao": "Outros riscos especificados à respiração - fazenda"
  },
  {
    "codigo": "W838",
    "descricao": "Outros riscos especificados à respiração - outros locais especificados"
  },
  {
    "codigo": "W839",
    "descricao": "Outros riscos especificados à respiração - local não especificado"
  },
  {
    "codigo": "W840",
    "descricao": "Riscos não especificados à respiração - residência"
  },
  {
    "codigo": "W841",
    "descricao": "Riscos não especificados à respiração - habitação coletiva"
  },
  {
    "codigo": "W842",
    "descricao": "Riscos não especificados à respiração - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W843",
    "descricao": "Riscos não especificados à respiração - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W844",
    "descricao": "Riscos não especificados à respiração - rua e estrada"
  },
  {
    "codigo": "W845",
    "descricao": "Riscos não especificados à respiração - áreas de comércio e de serviços"
  },
  {
    "codigo": "W846",
    "descricao": "Riscos não especificados à respiração - áreas industriais e em construção"
  },
  {
    "codigo": "W847",
    "descricao": "Riscos não especificados à respiração - fazenda"
  },
  {
    "codigo": "W848",
    "descricao": "Riscos não especificados à respiração - outros locais especificados"
  },
  {
    "codigo": "W849",
    "descricao": "Riscos não especificados à respiração - local não especificado"
  },
  {
    "codigo": "W850",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - residência"
  },
  {
    "codigo": "W851",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - habitação coletiva"
  },
  {
    "codigo": "W852",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W853",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W854",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - rua e estrada"
  },
  {
    "codigo": "W855",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - áreas de comércio e de serviços"
  },
  {
    "codigo": "W856",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - áreas industriais e em construção"
  },
  {
    "codigo": "W857",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - fazenda"
  },
  {
    "codigo": "W858",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - outros locais especificados"
  },
  {
    "codigo": "W859",
    "descricao": "Exposição a linhas de transmissão de corrente elétrica - local não especificado"
  },
  {
    "codigo": "W860",
    "descricao": "Exposição a outra corrente elétrica especificada - residência"
  },
  {
    "codigo": "W861",
    "descricao": "Exposição a outra corrente elétrica especificada - habitação coletiva"
  },
  {
    "codigo": "W862",
    "descricao": "Exposição a outra corrente elétrica especificada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W863",
    "descricao": "Exposição a outra corrente elétrica especificada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W864",
    "descricao": "Exposição a outra corrente elétrica especificada - rua e estrada"
  },
  {
    "codigo": "W865",
    "descricao": "Exposição a outra corrente elétrica especificada - áreas de comércio e de serviços"
  },
  {
    "codigo": "W866",
    "descricao": "Exposição a outra corrente elétrica especificada - áreas industriais e em construção"
  },
  {
    "codigo": "W867",
    "descricao": "Exposição a outra corrente elétrica especificada - fazenda"
  },
  {
    "codigo": "W868",
    "descricao": "Exposição a outra corrente elétrica especificada - outros locais especificados"
  },
  {
    "codigo": "W869",
    "descricao": "Exposição a outra corrente elétrica especificada - local não especificado"
  },
  {
    "codigo": "W870",
    "descricao": "Exposição a corrente elétrica não especificada - residência"
  },
  {
    "codigo": "W871",
    "descricao": "Exposição a corrente elétrica não especificada - habitação coletiva"
  },
  {
    "codigo": "W872",
    "descricao": "Exposição a corrente elétrica não especificada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W873",
    "descricao": "Exposição a corrente elétrica não especificada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W874",
    "descricao": "Exposição a corrente elétrica não especificada - rua e estrada"
  },
  {
    "codigo": "W875",
    "descricao": "Exposição a corrente elétrica não especificada - áreas de comércio e de serviços"
  },
  {
    "codigo": "W876",
    "descricao": "Exposição a corrente elétrica não especificada - áreas industriais e em construção"
  },
  {
    "codigo": "W877",
    "descricao": "Exposição a corrente elétrica não especificada - fazenda"
  },
  {
    "codigo": "W878",
    "descricao": "Exposição a corrente elétrica não especificada - outros locais especificados"
  },
  {
    "codigo": "W879",
    "descricao": "Exposição a corrente elétrica não especificada - local não especificado"
  },
  {
    "codigo": "W880",
    "descricao": "Exposição a radiação ionizante - residência"
  },
  {
    "codigo": "W881",
    "descricao": "Exposição a radiação ionizante - habitação coletiva"
  },
  {
    "codigo": "W882",
    "descricao": "Exposição a radiação ionizante - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W883",
    "descricao": "Exposição a radiação ionizante - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W884",
    "descricao": "Exposição a radiação ionizante - rua e estrada"
  },
  {
    "codigo": "W885",
    "descricao": "Exposição a radiação ionizante - áreas de comércio e de serviços"
  },
  {
    "codigo": "W886",
    "descricao": "Exposição a radiação ionizante - áreas industriais e em construção"
  },
  {
    "codigo": "W887",
    "descricao": "Exposição a radiação ionizante - fazenda"
  },
  {
    "codigo": "W888",
    "descricao": "Exposição a radiação ionizante - outros locais especificados"
  },
  {
    "codigo": "W889",
    "descricao": "Exposição a radiação ionizante - local não especificado"
  },
  {
    "codigo": "W890",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - residência"
  },
  {
    "codigo": "W891",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - habitação coletiva"
  },
  {
    "codigo": "W892",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W893",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W894",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - rua e estrada"
  },
  {
    "codigo": "W895",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - áreas de comércio e de serviços"
  },
  {
    "codigo": "W896",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - áreas industriais e em construção"
  },
  {
    "codigo": "W897",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - fazenda"
  },
  {
    "codigo": "W898",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - outros locais especificados"
  },
  {
    "codigo": "W899",
    "descricao": "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - local não especificado"
  },
  {
    "codigo": "W900",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - residência"
  },
  {
    "codigo": "W901",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - habitação coletiva"
  },
  {
    "codigo": "W902",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W903",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W904",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - rua e estrada"
  },
  {
    "codigo": "W905",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - áreas de comércio e de serviços"
  },
  {
    "codigo": "W906",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - áreas industriais e em construção"
  },
  {
    "codigo": "W907",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - fazenda"
  },
  {
    "codigo": "W908",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - outros locais especificados"
  },
  {
    "codigo": "W909",
    "descricao": "Exposição a outros tipos de radiação não-ionizante - local não especificado"
  },
  {
    "codigo": "W910",
    "descricao": "Exposição a tipo não especificado de radiação - residência"
  },
  {
    "codigo": "W911",
    "descricao": "Exposição a tipo não especificado de radiação - habitação coletiva"
  },
  {
    "codigo": "W912",
    "descricao": "Exposição a tipo não especificado de radiação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W913",
    "descricao": "Exposição a tipo não especificado de radiação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W914",
    "descricao": "Exposição a tipo não especificado de radiação - rua e estrada"
  },
  {
    "codigo": "W915",
    "descricao": "Exposição a tipo não especificado de radiação - áreas de comércio e de serviços"
  },
  {
    "codigo": "W916",
    "descricao": "Exposição a tipo não especificado de radiação - áreas industriais e em construção"
  },
  {
    "codigo": "W917",
    "descricao": "Exposição a tipo não especificado de radiação - fazenda"
  },
  {
    "codigo": "W918",
    "descricao": "Exposição a tipo não especificado de radiação - outros locais especificados"
  },
  {
    "codigo": "W919",
    "descricao": "Exposição a tipo não especificado de radiação - local não especificado"
  },
  {
    "codigo": "W920",
    "descricao": "Exposição a um calor excessivo de origem artificial - residência"
  },
  {
    "codigo": "W921",
    "descricao": "Exposição a um calor excessivo de origem artificial - habitação coletiva"
  },
  {
    "codigo": "W922",
    "descricao": "Exposição a um calor excessivo de origem artificial - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W923",
    "descricao": "Exposição a um calor excessivo de origem artificial - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W924",
    "descricao": "Exposição a um calor excessivo de origem artificial - rua e estrada"
  },
  {
    "codigo": "W925",
    "descricao": "Exposição a um calor excessivo de origem artificial - áreas de comércio e de serviços"
  },
  {
    "codigo": "W926",
    "descricao": "Exposição a um calor excessivo de origem artificial - áreas industriais e em construção"
  },
  {
    "codigo": "W927",
    "descricao": "Exposição a um calor excessivo de origem artificial - fazenda"
  },
  {
    "codigo": "W928",
    "descricao": "Exposição a um calor excessivo de origem artificial - outros locais especificados"
  },
  {
    "codigo": "W929",
    "descricao": "Exposição a um calor excessivo de origem artificial - local não especificado"
  },
  {
    "codigo": "W930",
    "descricao": "Exposição a um frio excessivo de origem artificial - residência"
  },
  {
    "codigo": "W931",
    "descricao": "Exposição a um frio excessivo de origem artificial - habitação coletiva"
  },
  {
    "codigo": "W932",
    "descricao": "Exposição a um frio excessivo de origem artificial - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W933",
    "descricao": "Exposição a um frio excessivo de origem artificial - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W934",
    "descricao": "Exposição a um frio excessivo de origem artificial - rua e estrada"
  },
  {
    "codigo": "W935",
    "descricao": "Exposição a um frio excessivo de origem artificial - áreas de comércio e de serviços"
  },
  {
    "codigo": "W936",
    "descricao": "Exposição a um frio excessivo de origem artificial - áreas industriais e em construção"
  },
  {
    "codigo": "W937",
    "descricao": "Exposição a um frio excessivo de origem artificial - fazenda"
  },
  {
    "codigo": "W938",
    "descricao": "Exposição a um frio excessivo de origem artificial - outros locais especificados"
  },
  {
    "codigo": "W939",
    "descricao": "Exposição a um frio excessivo de origem artificial - local não especificado"
  },
  {
    "codigo": "W940",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - residência"
  },
  {
    "codigo": "W941",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - habitação coletiva"
  },
  {
    "codigo": "W942",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W943",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W944",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - rua e estrada"
  },
  {
    "codigo": "W945",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - áreas de comércio e de serviços"
  },
  {
    "codigo": "W946",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - áreas industriais e em construção"
  },
  {
    "codigo": "W947",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - fazenda"
  },
  {
    "codigo": "W948",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - outros locais especificados"
  },
  {
    "codigo": "W949",
    "descricao": "Exposição a alta, baixa e a variações da pressão atmosférica - local não especificado"
  },
  {
    "codigo": "W990",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - residência"
  },
  {
    "codigo": "W991",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - habitação coletiva"
  },
  {
    "codigo": "W992",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "W993",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "W994",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - rua e estrada"
  },
  {
    "codigo": "W995",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "W996",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - áreas industriais e em construção"
  },
  {
    "codigo": "W997",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - fazenda"
  },
  {
    "codigo": "W998",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - outros locais especificados"
  },
  {
    "codigo": "W999",
    "descricao": "Exposição a outros fatores ambientais artificiais e aos não especificados - local não especificado"
  },
  {
    "codigo": "X000",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - residência"
  },
  {
    "codigo": "X001",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - habitação coletiva"
  },
  {
    "codigo": "X002",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X003",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X004",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - rua e estrada"
  },
  {
    "codigo": "X005",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - áreas de comércio e de serviços"
  },
  {
    "codigo": "X006",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - áreas industriais e em construção"
  },
  {
    "codigo": "X007",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - fazenda"
  },
  {
    "codigo": "X008",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - outros locais especificados"
  },
  {
    "codigo": "X009",
    "descricao": "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - local não especificado"
  },
  {
    "codigo": "X010",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - residência"
  },
  {
    "codigo": "X011",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - habitação coletiva"
  },
  {
    "codigo": "X012",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X013",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X014",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - rua e estrada"
  },
  {
    "codigo": "X015",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - áreas de comércio e de serviços"
  },
  {
    "codigo": "X016",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - áreas industriais e em construção"
  },
  {
    "codigo": "X017",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - fazenda"
  },
  {
    "codigo": "X018",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - outros locais especificados"
  },
  {
    "codigo": "X019",
    "descricao": "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - local não especificado"
  },
  {
    "codigo": "X020",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - residência"
  },
  {
    "codigo": "X021",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - habitação coletiva"
  },
  {
    "codigo": "X022",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X023",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X024",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - rua e estrada"
  },
  {
    "codigo": "X025",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - áreas de comércio e de serviços"
  },
  {
    "codigo": "X026",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - áreas industriais e em construção"
  },
  {
    "codigo": "X027",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - fazenda"
  },
  {
    "codigo": "X028",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - outros locais especificados"
  },
  {
    "codigo": "X029",
    "descricao": "Exposição a fogo controlado em um edifício ou outro tipo de construção - local não especificado"
  },
  {
    "codigo": "X030",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - residência"
  },
  {
    "codigo": "X031",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - habitação coletiva"
  },
  {
    "codigo": "X032",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X033",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X034",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - rua e estrada"
  },
  {
    "codigo": "X035",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - áreas de comércio e de serviços"
  },
  {
    "codigo": "X036",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - áreas industriais e em construção"
  },
  {
    "codigo": "X037",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - fazenda"
  },
  {
    "codigo": "X038",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - outros locais especificados"
  },
  {
    "codigo": "X039",
    "descricao": "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - local não especificado"
  },
  {
    "codigo": "X040",
    "descricao": "Exposição a combustão de substância muito inflamável - residência"
  },
  {
    "codigo": "X041",
    "descricao": "Exposição a combustão de substância muito inflamável - habitação coletiva"
  },
  {
    "codigo": "X042",
    "descricao": "Exposição a combustão de substância muito inflamável - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X043",
    "descricao": "Exposição a combustão de substância muito inflamável - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X044",
    "descricao": "Exposição a combustão de substância muito inflamável - rua e estrada"
  },
  {
    "codigo": "X045",
    "descricao": "Exposição a combustão de substância muito inflamável - áreas de comércio e de serviços"
  },
  {
    "codigo": "X046",
    "descricao": "Exposição a combustão de substância muito inflamável - áreas industriais e em construção"
  },
  {
    "codigo": "X047",
    "descricao": "Exposição a combustão de substância muito inflamável - fazenda"
  },
  {
    "codigo": "X048",
    "descricao": "Exposição a combustão de substância muito inflamável - outros locais especificados"
  },
  {
    "codigo": "X049",
    "descricao": "Exposição a combustão de substância muito inflamável - local não especificado"
  },
  {
    "codigo": "X050",
    "descricao": "Exposição a combustão de roupa de dormir - residência"
  },
  {
    "codigo": "X051",
    "descricao": "Exposição a combustão de roupa de dormir - habitação coletiva"
  },
  {
    "codigo": "X052",
    "descricao": "Exposição a combustão de roupa de dormir - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X053",
    "descricao": "Exposição a combustão de roupa de dormir - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X054",
    "descricao": "Exposição a combustão de roupa de dormir - rua e estrada"
  },
  {
    "codigo": "X055",
    "descricao": "Exposição a combustão de roupa de dormir - áreas de comércio e de serviços"
  },
  {
    "codigo": "X056",
    "descricao": "Exposição a combustão de roupa de dormir - áreas industriais e em construção"
  },
  {
    "codigo": "X057",
    "descricao": "Exposição a combustão de roupa de dormir - fazenda"
  },
  {
    "codigo": "X058",
    "descricao": "Exposição a combustão de roupa de dormir - outros locais especificados"
  },
  {
    "codigo": "X059",
    "descricao": "Exposição a combustão de roupa de dormir - local não especificado"
  },
  {
    "codigo": "X060",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - residência"
  },
  {
    "codigo": "X061",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - habitação coletiva"
  },
  {
    "codigo": "X062",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X063",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X064",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - rua e estrada"
  },
  {
    "codigo": "X065",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - áreas de comércio e de serviços"
  },
  {
    "codigo": "X066",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - áreas industriais e em construção"
  },
  {
    "codigo": "X067",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - fazenda"
  },
  {
    "codigo": "X068",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - outros locais especificados"
  },
  {
    "codigo": "X069",
    "descricao": "Exposição a combustão de outro tipo de roupa ou de acessórios - local não especificado"
  },
  {
    "codigo": "X080",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - residência"
  },
  {
    "codigo": "X081",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - habitação coletiva"
  },
  {
    "codigo": "X082",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X083",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X084",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - rua e estrada"
  },
  {
    "codigo": "X085",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X086",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - áreas industriais e em construção"
  },
  {
    "codigo": "X087",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - fazenda"
  },
  {
    "codigo": "X088",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - outros locais especificados"
  },
  {
    "codigo": "X089",
    "descricao": "Exposição a outro tipo especificado de fumaça, fogo ou chamas - local não especificado"
  },
  {
    "codigo": "X090",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - residência"
  },
  {
    "codigo": "X091",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - habitação coletiva"
  },
  {
    "codigo": "X092",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X093",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X094",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - rua e estrada"
  },
  {
    "codigo": "X095",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X096",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - áreas industriais e em construção"
  },
  {
    "codigo": "X097",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - fazenda"
  },
  {
    "codigo": "X098",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - outros locais especificados"
  },
  {
    "codigo": "X099",
    "descricao": "Exposição a tipo não especificado de fumaça, fogo ou chamas - local não especificado"
  },
  {
    "codigo": "X100",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - residência"
  },
  {
    "codigo": "X101",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - habitação coletiva"
  },
  {
    "codigo": "X102",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X103",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X104",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - rua e estrada"
  },
  {
    "codigo": "X105",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X106",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X107",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - fazenda"
  },
  {
    "codigo": "X108",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - outros locais especificados"
  },
  {
    "codigo": "X109",
    "descricao": "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - local não especificado"
  },
  {
    "codigo": "X110",
    "descricao": "Contato com água corrente quente de torneira - residência"
  },
  {
    "codigo": "X111",
    "descricao": "Contato com água corrente quente de torneira - habitação coletiva"
  },
  {
    "codigo": "X112",
    "descricao": "Contato com água corrente quente de torneira - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X113",
    "descricao": "Contato com água corrente quente de torneira - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X114",
    "descricao": "Contato com água corrente quente de torneira - rua e estrada"
  },
  {
    "codigo": "X115",
    "descricao": "Contato com água corrente quente de torneira - áreas de comércio e de serviços"
  },
  {
    "codigo": "X116",
    "descricao": "Contato com água corrente quente de torneira - áreas industriais e em construção"
  },
  {
    "codigo": "X117",
    "descricao": "Contato com água corrente quente de torneira - fazenda"
  },
  {
    "codigo": "X118",
    "descricao": "Contato com água corrente quente de torneira - outros locais especificados"
  },
  {
    "codigo": "X119",
    "descricao": "Contato com água corrente quente de torneira - local não especificado"
  },
  {
    "codigo": "X120",
    "descricao": "Contato com outros líquidos quentes - residência"
  },
  {
    "codigo": "X121",
    "descricao": "Contato com outros líquidos quentes - habitação coletiva"
  },
  {
    "codigo": "X122",
    "descricao": "Contato com outros líquidos quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X123",
    "descricao": "Contato com outros líquidos quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X124",
    "descricao": "Contato com outros líquidos quentes - rua e estrada"
  },
  {
    "codigo": "X125",
    "descricao": "Contato com outros líquidos quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X126",
    "descricao": "Contato com outros líquidos quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X127",
    "descricao": "Contato com outros líquidos quentes - fazenda"
  },
  {
    "codigo": "X128",
    "descricao": "Contato com outros líquidos quentes - outros locais especificados"
  },
  {
    "codigo": "X129",
    "descricao": "Contato com outros líquidos quentes - local não especificado"
  },
  {
    "codigo": "X130",
    "descricao": "Contato com vapor d'água e com vapores quentes - residência"
  },
  {
    "codigo": "X131",
    "descricao": "Contato com vapor d'água e com vapores quentes - habitação coletiva"
  },
  {
    "codigo": "X132",
    "descricao": "Contato com vapor d'água e com vapores quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X133",
    "descricao": "Contato com vapor d'água e com vapores quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X134",
    "descricao": "Contato com vapor d'água e com vapores quentes - rua e estrada"
  },
  {
    "codigo": "X135",
    "descricao": "Contato com vapor d'água e com vapores quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X136",
    "descricao": "Contato com vapor d'água e com vapores quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X137",
    "descricao": "Contato com vapor d'água e com vapores quentes - fazenda"
  },
  {
    "codigo": "X138",
    "descricao": "Contato com vapor d'água e com vapores quentes - outros locais especificados"
  },
  {
    "codigo": "X139",
    "descricao": "Contato com vapor d'água e com vapores quentes - local não especificado"
  },
  {
    "codigo": "X140",
    "descricao": "Contato com ar e gases quentes - residência"
  },
  {
    "codigo": "X141",
    "descricao": "Contato com ar e gases quentes - habitação coletiva"
  },
  {
    "codigo": "X142",
    "descricao": "Contato com ar e gases quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X143",
    "descricao": "Contato com ar e gases quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X144",
    "descricao": "Contato com ar e gases quentes - rua e estrada"
  },
  {
    "codigo": "X145",
    "descricao": "Contato com ar e gases quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X146",
    "descricao": "Contato com ar e gases quentes - áreas industriais e em construção"
  },
  { "codigo": "X147", "descricao": "Contato com ar e gases quentes - fazenda" },
  {
    "codigo": "X148",
    "descricao": "Contato com ar e gases quentes - outros locais especificados"
  },
  {
    "codigo": "X149",
    "descricao": "Contato com ar e gases quentes - local não especificado"
  },
  {
    "codigo": "X150",
    "descricao": "Contato com aparelhos domésticos quentes - residência"
  },
  {
    "codigo": "X151",
    "descricao": "Contato com aparelhos domésticos quentes - habitação coletiva"
  },
  {
    "codigo": "X152",
    "descricao": "Contato com aparelhos domésticos quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X153",
    "descricao": "Contato com aparelhos domésticos quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X154",
    "descricao": "Contato com aparelhos domésticos quentes - rua e estrada"
  },
  {
    "codigo": "X155",
    "descricao": "Contato com aparelhos domésticos quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X156",
    "descricao": "Contato com aparelhos domésticos quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X157",
    "descricao": "Contato com aparelhos domésticos quentes - fazenda"
  },
  {
    "codigo": "X158",
    "descricao": "Contato com aparelhos domésticos quentes - outros locais especificados"
  },
  {
    "codigo": "X159",
    "descricao": "Contato com aparelhos domésticos quentes - local não especificado"
  },
  {
    "codigo": "X160",
    "descricao": "Contato com aquecedores, radiadores e tubulação - residência"
  },
  {
    "codigo": "X161",
    "descricao": "Contato com aquecedores, radiadores e tubulação - habitação coletiva"
  },
  {
    "codigo": "X162",
    "descricao": "Contato com aquecedores, radiadores e tubulação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X163",
    "descricao": "Contato com aquecedores, radiadores e tubulação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X164",
    "descricao": "Contato com aquecedores, radiadores e tubulação - rua e estrada"
  },
  {
    "codigo": "X165",
    "descricao": "Contato com aquecedores, radiadores e tubulação - áreas de comércio e de serviços"
  },
  {
    "codigo": "X166",
    "descricao": "Contato com aquecedores, radiadores e tubulação - áreas industriais e em construção"
  },
  {
    "codigo": "X167",
    "descricao": "Contato com aquecedores, radiadores e tubulação - fazenda"
  },
  {
    "codigo": "X168",
    "descricao": "Contato com aquecedores, radiadores e tubulação - outros locais especificados"
  },
  {
    "codigo": "X169",
    "descricao": "Contato com aquecedores, radiadores e tubulação - local não especificado"
  },
  {
    "codigo": "X170",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - residência"
  },
  {
    "codigo": "X171",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - habitação coletiva"
  },
  {
    "codigo": "X172",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X173",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X174",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - rua e estrada"
  },
  {
    "codigo": "X175",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X176",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X177",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - fazenda"
  },
  {
    "codigo": "X178",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - outros locais especificados"
  },
  {
    "codigo": "X179",
    "descricao": "Contato com motores, máquinas e ferramentas quentes - local não especificado"
  },
  {
    "codigo": "X180",
    "descricao": "Contato com outros metais quentes - residência"
  },
  {
    "codigo": "X181",
    "descricao": "Contato com outros metais quentes - habitação coletiva"
  },
  {
    "codigo": "X182",
    "descricao": "Contato com outros metais quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X183",
    "descricao": "Contato com outros metais quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X184",
    "descricao": "Contato com outros metais quentes - rua e estrada"
  },
  {
    "codigo": "X185",
    "descricao": "Contato com outros metais quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X186",
    "descricao": "Contato com outros metais quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X187",
    "descricao": "Contato com outros metais quentes - fazenda"
  },
  {
    "codigo": "X188",
    "descricao": "Contato com outros metais quentes - outros locais especificados"
  },
  {
    "codigo": "X189",
    "descricao": "Contato com outros metais quentes - local não especificado"
  },
  {
    "codigo": "X190",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - residência"
  },
  {
    "codigo": "X191",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - habitação coletiva"
  },
  {
    "codigo": "X192",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X193",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X194",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - rua e estrada"
  },
  {
    "codigo": "X195",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X196",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X197",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - fazenda"
  },
  {
    "codigo": "X198",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - outros locais especificados"
  },
  {
    "codigo": "X199",
    "descricao": "Contato com outras fontes de calor ou com substâncias quentes não especificados - local não especificado"
  },
  {
    "codigo": "X200",
    "descricao": "Contato com serpentes e lagartos venenosos - residência"
  },
  {
    "codigo": "X201",
    "descricao": "Contato com serpentes e lagartos venenosos - habitação coletiva"
  },
  {
    "codigo": "X202",
    "descricao": "Contato com serpentes e lagartos venenosos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X203",
    "descricao": "Contato com serpentes e lagartos venenosos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X204",
    "descricao": "Contato com serpentes e lagartos venenosos - rua e estrada"
  },
  {
    "codigo": "X205",
    "descricao": "Contato com serpentes e lagartos venenosos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X206",
    "descricao": "Contato com serpentes e lagartos venenosos - áreas industriais e em construção"
  },
  {
    "codigo": "X207",
    "descricao": "Contato com serpentes e lagartos venenosos - fazenda"
  },
  {
    "codigo": "X208",
    "descricao": "Contato com serpentes e lagartos venenosos - outros locais especificados"
  },
  {
    "codigo": "X209",
    "descricao": "Contato com serpentes e lagartos venenosos - local não especificado"
  },
  {
    "codigo": "X210",
    "descricao": "Contato com aranhas venenosas - residência"
  },
  {
    "codigo": "X211",
    "descricao": "Contato com aranhas venenosas - habitação coletiva"
  },
  {
    "codigo": "X212",
    "descricao": "Contato com aranhas venenosas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X213",
    "descricao": "Contato com aranhas venenosas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X214",
    "descricao": "Contato com aranhas venenosas - rua e estrada"
  },
  {
    "codigo": "X215",
    "descricao": "Contato com aranhas venenosas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X216",
    "descricao": "Contato com aranhas venenosas - áreas industriais e em construção"
  },
  { "codigo": "X217", "descricao": "Contato com aranhas venenosas - fazenda" },
  {
    "codigo": "X218",
    "descricao": "Contato com aranhas venenosas - outros locais especificados"
  },
  {
    "codigo": "X219",
    "descricao": "Contato com aranhas venenosas - local não especificado"
  },
  {
    "codigo": "X220",
    "descricao": "Contato com escorpiões - residência"
  },
  {
    "codigo": "X221",
    "descricao": "Contato com escorpiões - habitação coletiva"
  },
  {
    "codigo": "X222",
    "descricao": "Contato com escorpiões - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X223",
    "descricao": "Contato com escorpiões - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X224",
    "descricao": "Contato com escorpiões - rua e estrada"
  },
  {
    "codigo": "X225",
    "descricao": "Contato com escorpiões - áreas de comércio e de serviços"
  },
  {
    "codigo": "X226",
    "descricao": "Contato com escorpiões - áreas industriais e em construção"
  },
  { "codigo": "X227", "descricao": "Contato com escorpiões - fazenda" },
  {
    "codigo": "X228",
    "descricao": "Contato com escorpiões - outros locais especificados"
  },
  {
    "codigo": "X229",
    "descricao": "Contato com escorpiões - local não especificado"
  },
  {
    "codigo": "X230",
    "descricao": "Contato com abelhas, vespas e vespões - residência"
  },
  {
    "codigo": "X231",
    "descricao": "Contato com abelhas, vespas e vespões - habitação coletiva"
  },
  {
    "codigo": "X232",
    "descricao": "Contato com abelhas, vespas e vespões - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X233",
    "descricao": "Contato com abelhas, vespas e vespões - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X234",
    "descricao": "Contato com abelhas, vespas e vespões - rua e estrada"
  },
  {
    "codigo": "X235",
    "descricao": "Contato com abelhas, vespas e vespões - áreas de comércio e de serviços"
  },
  {
    "codigo": "X236",
    "descricao": "Contato com abelhas, vespas e vespões - áreas industriais e em construção"
  },
  {
    "codigo": "X237",
    "descricao": "Contato com abelhas, vespas e vespões - fazenda"
  },
  {
    "codigo": "X238",
    "descricao": "Contato com abelhas, vespas e vespões - outros locais especificados"
  },
  {
    "codigo": "X239",
    "descricao": "Contato com abelhas, vespas e vespões - local não especificado"
  },
  {
    "codigo": "X240",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - residência"
  },
  {
    "codigo": "X241",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - habitação coletiva"
  },
  {
    "codigo": "X242",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X243",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X244",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - rua e estrada"
  },
  {
    "codigo": "X245",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - áreas de comércio e de serviços"
  },
  {
    "codigo": "X246",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - áreas industriais e em construção"
  },
  {
    "codigo": "X247",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - fazenda"
  },
  {
    "codigo": "X248",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - outros locais especificados"
  },
  {
    "codigo": "X249",
    "descricao": "Contato com centopéias e miriápodes venenosas (tropicais) - local não especificado"
  },
  {
    "codigo": "X250",
    "descricao": "Contato com outros artrópodes venenosos - residência"
  },
  {
    "codigo": "X251",
    "descricao": "Contato com outros artrópodes venenosos - habitação coletiva"
  },
  {
    "codigo": "X252",
    "descricao": "Contato com outros artrópodes venenosos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X253",
    "descricao": "Contato com outros artrópodes venenosos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X254",
    "descricao": "Contato com outros artrópodes venenosos - rua e estrada"
  },
  {
    "codigo": "X255",
    "descricao": "Contato com outros artrópodes venenosos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X256",
    "descricao": "Contato com outros artrópodes venenosos - áreas industriais e em construção"
  },
  {
    "codigo": "X257",
    "descricao": "Contato com outros artrópodes venenosos - fazenda"
  },
  {
    "codigo": "X258",
    "descricao": "Contato com outros artrópodes venenosos - outros locais especificados"
  },
  {
    "codigo": "X259",
    "descricao": "Contato com outros artrópodes venenosos - local não especificado"
  },
  {
    "codigo": "X260",
    "descricao": "Contato com animais e plantas marinhos venenosos - residência"
  },
  {
    "codigo": "X261",
    "descricao": "Contato com animais e plantas marinhos venenosos - habitação coletiva"
  },
  {
    "codigo": "X262",
    "descricao": "Contato com animais e plantas marinhos venenosos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X263",
    "descricao": "Contato com animais e plantas marinhos venenosos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X264",
    "descricao": "Contato com animais e plantas marinhos venenosos - rua e estrada"
  },
  {
    "codigo": "X265",
    "descricao": "Contato com animais e plantas marinhos venenosos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X266",
    "descricao": "Contato com animais e plantas marinhos venenosos - áreas industriais e em construção"
  },
  {
    "codigo": "X267",
    "descricao": "Contato com animais e plantas marinhos venenosos - fazenda"
  },
  {
    "codigo": "X268",
    "descricao": "Contato com animais e plantas marinhos venenosos - outros locais especificados"
  },
  {
    "codigo": "X269",
    "descricao": "Contato com animais e plantas marinhos venenosos - local não especificado"
  },
  {
    "codigo": "X270",
    "descricao": "Contato com outros animais venenosos especificados - residência"
  },
  {
    "codigo": "X271",
    "descricao": "Contato com outros animais venenosos especificados - habitação coletiva"
  },
  {
    "codigo": "X272",
    "descricao": "Contato com outros animais venenosos especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X273",
    "descricao": "Contato com outros animais venenosos especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X274",
    "descricao": "Contato com outros animais venenosos especificados - rua e estrada"
  },
  {
    "codigo": "X275",
    "descricao": "Contato com outros animais venenosos especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X276",
    "descricao": "Contato com outros animais venenosos especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X277",
    "descricao": "Contato com outros animais venenosos especificados - fazenda"
  },
  {
    "codigo": "X278",
    "descricao": "Contato com outros animais venenosos especificados - outros locais especificados"
  },
  {
    "codigo": "X279",
    "descricao": "Contato com outros animais venenosos especificados - local não especificado"
  },
  {
    "codigo": "X280",
    "descricao": "Contato com outras plantas venenosas especificadas - residência"
  },
  {
    "codigo": "X281",
    "descricao": "Contato com outras plantas venenosas especificadas - habitação coletiva"
  },
  {
    "codigo": "X282",
    "descricao": "Contato com outras plantas venenosas especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X283",
    "descricao": "Contato com outras plantas venenosas especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X284",
    "descricao": "Contato com outras plantas venenosas especificadas - rua e estrada"
  },
  {
    "codigo": "X285",
    "descricao": "Contato com outras plantas venenosas especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X286",
    "descricao": "Contato com outras plantas venenosas especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "X287",
    "descricao": "Contato com outras plantas venenosas especificadas - fazenda"
  },
  {
    "codigo": "X288",
    "descricao": "Contato com outras plantas venenosas especificadas - outros locais especificados"
  },
  {
    "codigo": "X289",
    "descricao": "Contato com outras plantas venenosas especificadas - local não especificado"
  },
  {
    "codigo": "X290",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - residência"
  },
  {
    "codigo": "X291",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - habitação coletiva"
  },
  {
    "codigo": "X292",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X293",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X294",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - rua e estrada"
  },
  {
    "codigo": "X295",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - áreas de comércio e de serviços"
  },
  {
    "codigo": "X296",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - áreas industriais e em construção"
  },
  {
    "codigo": "X297",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - fazenda"
  },
  {
    "codigo": "X298",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - outros locais especificados"
  },
  {
    "codigo": "X299",
    "descricao": "Contato com animais ou plantas venenosos, sem especificação - local não especificado"
  },
  {
    "codigo": "X300",
    "descricao": "Exposição a calor natural excessivo - residência"
  },
  {
    "codigo": "X301",
    "descricao": "Exposição a calor natural excessivo - habitação coletiva"
  },
  {
    "codigo": "X302",
    "descricao": "Exposição a calor natural excessivo - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X303",
    "descricao": "Exposição a calor natural excessivo - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X304",
    "descricao": "Exposição a calor natural excessivo - rua e estrada"
  },
  {
    "codigo": "X305",
    "descricao": "Exposição a calor natural excessivo - áreas de comércio e de serviços"
  },
  {
    "codigo": "X306",
    "descricao": "Exposição a calor natural excessivo - áreas industriais e em construção"
  },
  {
    "codigo": "X307",
    "descricao": "Exposição a calor natural excessivo - fazenda"
  },
  {
    "codigo": "X308",
    "descricao": "Exposição a calor natural excessivo - outros locais especificados"
  },
  {
    "codigo": "X309",
    "descricao": "Exposição a calor natural excessivo - local não especificado"
  },
  {
    "codigo": "X310",
    "descricao": "Exposição a frio natural excessivo - residência"
  },
  {
    "codigo": "X311",
    "descricao": "Exposição a frio natural excessivo - habitação coletiva"
  },
  {
    "codigo": "X312",
    "descricao": "Exposição a frio natural excessivo - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X313",
    "descricao": "Exposição a frio natural excessivo - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X314",
    "descricao": "Exposição a frio natural excessivo - rua e estrada"
  },
  {
    "codigo": "X315",
    "descricao": "Exposição a frio natural excessivo - áreas de comércio e de serviços"
  },
  {
    "codigo": "X316",
    "descricao": "Exposição a frio natural excessivo - áreas industriais e em construção"
  },
  {
    "codigo": "X317",
    "descricao": "Exposição a frio natural excessivo - fazenda"
  },
  {
    "codigo": "X318",
    "descricao": "Exposição a frio natural excessivo - outros locais especificados"
  },
  {
    "codigo": "X319",
    "descricao": "Exposição a frio natural excessivo - local não especificado"
  },
  {
    "codigo": "X320",
    "descricao": "Exposição à luz solar - residência"
  },
  {
    "codigo": "X321",
    "descricao": "Exposição à luz solar - habitação coletiva"
  },
  {
    "codigo": "X322",
    "descricao": "Exposição à luz solar - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X323",
    "descricao": "Exposição à luz solar - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X324",
    "descricao": "Exposição à luz solar - rua e estrada"
  },
  {
    "codigo": "X325",
    "descricao": "Exposição à luz solar - áreas de comércio e de serviços"
  },
  {
    "codigo": "X326",
    "descricao": "Exposição à luz solar - áreas industriais e em construção"
  },
  {
    "codigo": "X327",
    "descricao": "Exposição à luz solar - fazenda"
  },
  {
    "codigo": "X328",
    "descricao": "Exposição à luz solar - outros locais especificados"
  },
  {
    "codigo": "X329",
    "descricao": "Exposição à luz solar - local não especificado"
  },
  { "codigo": "X330", "descricao": "Vítima de raio - residência" },
  {
    "codigo": "X331",
    "descricao": "Vítima de raio - habitação coletiva"
  },
  {
    "codigo": "X332",
    "descricao": "Vítima de raio - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X333",
    "descricao": "Vítima de raio - área para a prática de esportes e atletismo"
  },
  { "codigo": "X334", "descricao": "Vítima de raio - rua e estrada" },
  {
    "codigo": "X335",
    "descricao": "Vítima de raio - áreas de comércio e de serviços"
  },
  {
    "codigo": "X336",
    "descricao": "Vítima de raio - áreas industriais e em construção"
  },
  { "codigo": "X337", "descricao": "Vítima de raio - fazenda" },
  {
    "codigo": "X338",
    "descricao": "Vítima de raio - outros locais especificados"
  },
  {
    "codigo": "X339",
    "descricao": "Vítima de raio - local não especificado"
  },
  {
    "codigo": "X340",
    "descricao": "Vítima de terremoto - residência"
  },
  {
    "codigo": "X341",
    "descricao": "Vítima de terremoto - habitação coletiva"
  },
  {
    "codigo": "X342",
    "descricao": "Vítima de terremoto - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X343",
    "descricao": "Vítima de terremoto - área para a prática de esportes e atletismo"
  },
  { "codigo": "X344", "descricao": "Vítima de terremoto - rua e estrada" },
  {
    "codigo": "X345",
    "descricao": "Vítima de terremoto - áreas de comércio e de serviços"
  },
  {
    "codigo": "X346",
    "descricao": "Vítima de terremoto - áreas industriais e em construção"
  },
  { "codigo": "X347", "descricao": "Vítima de terremoto - fazenda" },
  {
    "codigo": "X348",
    "descricao": "Vítima de terremoto - outros locais especificados"
  },
  {
    "codigo": "X349",
    "descricao": "Vítima de terremoto - local não especificado"
  },
  {
    "codigo": "X350",
    "descricao": "Vítima de erupção vulcânica - residência"
  },
  {
    "codigo": "X351",
    "descricao": "Vítima de erupção vulcânica - habitação coletiva"
  },
  {
    "codigo": "X352",
    "descricao": "Vítima de erupção vulcânica - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X353",
    "descricao": "Vítima de erupção vulcânica - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X354",
    "descricao": "Vítima de erupção vulcânica - rua e estrada"
  },
  {
    "codigo": "X355",
    "descricao": "Vítima de erupção vulcânica - áreas de comércio e de serviços"
  },
  {
    "codigo": "X356",
    "descricao": "Vítima de erupção vulcânica - áreas industriais e em construção"
  },
  {
    "codigo": "X357",
    "descricao": "Vítima de erupção vulcânica - fazenda"
  },
  {
    "codigo": "X358",
    "descricao": "Vítima de erupção vulcânica - outros locais especificados"
  },
  {
    "codigo": "X359",
    "descricao": "Vítima de erupção vulcânica - local não especificado"
  },
  {
    "codigo": "X360",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - residência"
  },
  {
    "codigo": "X361",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - habitação coletiva"
  },
  {
    "codigo": "X362",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X363",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X364",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - rua e estrada"
  },
  {
    "codigo": "X365",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - áreas de comércio e de serviços"
  },
  {
    "codigo": "X366",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - áreas industriais e em construção"
  },
  {
    "codigo": "X367",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - fazenda"
  },
  {
    "codigo": "X368",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - outros locais especificados"
  },
  {
    "codigo": "X369",
    "descricao": "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - local não especificado"
  },
  {
    "codigo": "X370",
    "descricao": "Vítima de tempestade cataclísmica - residência"
  },
  {
    "codigo": "X371",
    "descricao": "Vítima de tempestade cataclísmica - habitação coletiva"
  },
  {
    "codigo": "X372",
    "descricao": "Vítima de tempestade cataclísmica - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X373",
    "descricao": "Vítima de tempestade cataclísmica - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X374",
    "descricao": "Vítima de tempestade cataclísmica - rua e estrada"
  },
  {
    "codigo": "X375",
    "descricao": "Vítima de tempestade cataclísmica - áreas de comércio e de serviços"
  },
  {
    "codigo": "X376",
    "descricao": "Vítima de tempestade cataclísmica - áreas industriais e em construção"
  },
  {
    "codigo": "X377",
    "descricao": "Vítima de tempestade cataclísmica - fazenda"
  },
  {
    "codigo": "X378",
    "descricao": "Vítima de tempestade cataclísmica - outros locais especificados"
  },
  {
    "codigo": "X379",
    "descricao": "Vítima de tempestade cataclísmica - local não especificado"
  },
  {
    "codigo": "X380",
    "descricao": "Vítima de inundação - residência"
  },
  {
    "codigo": "X381",
    "descricao": "Vítima de inundação - habitação coletiva"
  },
  {
    "codigo": "X382",
    "descricao": "Vítima de inundação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X383",
    "descricao": "Vítima de inundação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X384",
    "descricao": "Vítima de inundação - rua e estrada"
  },
  {
    "codigo": "X385",
    "descricao": "Vítima de inundação - áreas de comércio e de serviços"
  },
  {
    "codigo": "X386",
    "descricao": "Vítima de inundação - áreas industriais e em construção"
  },
  {
    "codigo": "X387",
    "descricao": "Vítima de inundação - fazenda"
  },
  {
    "codigo": "X388",
    "descricao": "Vítima de inundação - outros locais especificados"
  },
  {
    "codigo": "X389",
    "descricao": "Vítima de inundação - local não especificado"
  },
  {
    "codigo": "X390",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - residência"
  },
  {
    "codigo": "X391",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - habitação coletiva"
  },
  {
    "codigo": "X392",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X393",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X394",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - rua e estrada"
  },
  {
    "codigo": "X395",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X396",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "X397",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - fazenda"
  },
  {
    "codigo": "X398",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - outros locais especificados"
  },
  {
    "codigo": "X399",
    "descricao": "Exposição a outras forças da natureza e às não especificadas - local não especificado"
  },
  {
    "codigo": "X400",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - residência"
  },
  {
    "codigo": "X401",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - habitação coletiva"
  },
  {
    "codigo": "X402",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X403",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X404",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - rua e estrada"
  },
  {
    "codigo": "X405",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X406",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas industriais e em construção"
  },
  {
    "codigo": "X407",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - fazenda"
  },
  {
    "codigo": "X408",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - outros locais especificados"
  },
  {
    "codigo": "X409",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - local não especificado"
  },
  {
    "codigo": "X410",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - residência"
  },
  {
    "codigo": "X411",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - habitação coletiva"
  },
  {
    "codigo": "X412",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X413",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X414",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - rua e estrada"
  },
  {
    "codigo": "X415",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - áreas de comércio e de serviços"
  },
  {
    "codigo": "X416",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - áreas industriais e em construção"
  },
  {
    "codigo": "X417",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - fazenda"
  },
  {
    "codigo": "X418",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - outros locais especificados"
  },
  {
    "codigo": "X419",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - local não especificado"
  },
  {
    "codigo": "X420",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - residência"
  },
  {
    "codigo": "X421",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - habitação coletiva"
  },
  {
    "codigo": "X422",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X423",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X424",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - rua e estrada"
  },
  {
    "codigo": "X425",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas de comércio e de serviços"
  },
  {
    "codigo": "X426",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas industriais e em construção"
  },
  {
    "codigo": "X427",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - fazenda"
  },
  {
    "codigo": "X428",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - outros locais especificados"
  },
  {
    "codigo": "X429",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - local não especificado"
  },
  {
    "codigo": "X430",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - residência"
  },
  {
    "codigo": "X431",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - habitação coletiva"
  },
  {
    "codigo": "X432",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X433",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X434",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - rua e estrada"
  },
  {
    "codigo": "X435",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas de comércio e de serviços"
  },
  {
    "codigo": "X436",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas industriais e em construção"
  },
  {
    "codigo": "X437",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - fazenda"
  },
  {
    "codigo": "X438",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - outros locais especificados"
  },
  {
    "codigo": "X439",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - local não especificado"
  },
  {
    "codigo": "X440",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - residência"
  },
  {
    "codigo": "X441",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - habitação coletiva"
  },
  {
    "codigo": "X442",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X443",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X444",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - rua e estrada"
  },
  {
    "codigo": "X445",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X446",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "X447",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - fazenda"
  },
  {
    "codigo": "X448",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - outros locais especificados"
  },
  {
    "codigo": "X449",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - local não especificado"
  },
  {
    "codigo": "X450",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - residência"
  },
  {
    "codigo": "X451",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - habitação coletiva"
  },
  {
    "codigo": "X452",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X453",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X454",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - rua e estrada"
  },
  {
    "codigo": "X455",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - áreas de comércio e de serviços"
  },
  {
    "codigo": "X456",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - áreas industriais e em construção"
  },
  {
    "codigo": "X457",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - fazenda"
  },
  {
    "codigo": "X458",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - outros locais especificados"
  },
  {
    "codigo": "X459",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição ao álcool - local não especificado"
  },
  {
    "codigo": "X460",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - residência"
  },
  {
    "codigo": "X461",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - habitação coletiva"
  },
  {
    "codigo": "X462",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X463",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X464",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - rua e estrada"
  },
  {
    "codigo": "X465",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - áreas de comércio e de serviços"
  },
  {
    "codigo": "X466",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - áreas industriais e em construção"
  },
  {
    "codigo": "X467",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - fazenda"
  },
  {
    "codigo": "X468",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - outros locais especificados"
  },
  {
    "codigo": "X469",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - local não especificado"
  },
  {
    "codigo": "X470",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - residência"
  },
  {
    "codigo": "X471",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - habitação coletiva"
  },
  {
    "codigo": "X472",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X473",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X474",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - rua e estrada"
  },
  {
    "codigo": "X475",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - áreas de comércio e de serviços"
  },
  {
    "codigo": "X476",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - áreas industriais e em construção"
  },
  {
    "codigo": "X477",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - fazenda"
  },
  {
    "codigo": "X478",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - outros locais especificados"
  },
  {
    "codigo": "X479",
    "descricao": "Intoxicação acidental por e exposição a outros gases e vapores - local não especificado"
  },
  {
    "codigo": "X480",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - residência"
  },
  {
    "codigo": "X481",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - habitação coletiva"
  },
  {
    "codigo": "X482",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X483",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X484",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - rua e estrada"
  },
  {
    "codigo": "X485",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X486",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - áreas industriais e em construção"
  },
  {
    "codigo": "X487",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - fazenda"
  },
  {
    "codigo": "X488",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - outros locais especificados"
  },
  {
    "codigo": "X489",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a pesticidas - local não especificado"
  },
  {
    "codigo": "X490",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - residência"
  },
  {
    "codigo": "X491",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - habitação coletiva"
  },
  {
    "codigo": "X492",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X493",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X494",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - rua e estrada"
  },
  {
    "codigo": "X495",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X496",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "X497",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - fazenda"
  },
  {
    "codigo": "X498",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - outros locais especificados"
  },
  {
    "codigo": "X499",
    "descricao": "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - local não especificado"
  },
  {
    "codigo": "X500",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - residência"
  },
  {
    "codigo": "X501",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - habitação coletiva"
  },
  {
    "codigo": "X502",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X503",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X504",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - rua e estrada"
  },
  {
    "codigo": "X505",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X506",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - áreas industriais e em construção"
  },
  {
    "codigo": "X507",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - fazenda"
  },
  {
    "codigo": "X508",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - outros locais especificados"
  },
  {
    "codigo": "X509",
    "descricao": "Excesso de exercícios e movimentos vigorosos ou repetitivos - local não especificado"
  },
  { "codigo": "X510", "descricao": "Viagem e movimento - residência" },
  {
    "codigo": "X511",
    "descricao": "Viagem e movimento - habitação coletiva"
  },
  {
    "codigo": "X512",
    "descricao": "Viagem e movimento - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X513",
    "descricao": "Viagem e movimento - área para a prática de esportes e atletismo"
  },
  { "codigo": "X514", "descricao": "Viagem e movimento - rua e estrada" },
  {
    "codigo": "X515",
    "descricao": "Viagem e movimento - áreas de comércio e de serviços"
  },
  {
    "codigo": "X516",
    "descricao": "Viagem e movimento - áreas industriais e em construção"
  },
  { "codigo": "X517", "descricao": "Viagem e movimento - fazenda" },
  {
    "codigo": "X518",
    "descricao": "Viagem e movimento - outros locais especificados"
  },
  {
    "codigo": "X519",
    "descricao": "Viagem e movimento - local não especificado"
  },
  {
    "codigo": "X520",
    "descricao": "Estadia prolongada em ambiente agravitacional - residência"
  },
  {
    "codigo": "X521",
    "descricao": "Estadia prolongada em ambiente agravitacional - habitação coletiva"
  },
  {
    "codigo": "X522",
    "descricao": "Estadia prolongada em ambiente agravitacional - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X523",
    "descricao": "Estadia prolongada em ambiente agravitacional - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X524",
    "descricao": "Estadia prolongada em ambiente agravitacional - rua e estrada"
  },
  {
    "codigo": "X525",
    "descricao": "Estadia prolongada em ambiente agravitacional - áreas de comércio e de serviços"
  },
  {
    "codigo": "X526",
    "descricao": "Estadia prolongada em ambiente agravitacional - áreas industriais e em construção"
  },
  {
    "codigo": "X527",
    "descricao": "Estadia prolongada em ambiente agravitacional - fazenda"
  },
  {
    "codigo": "X528",
    "descricao": "Estadia prolongada em ambiente agravitacional - outros locais especificados"
  },
  {
    "codigo": "X529",
    "descricao": "Estadia prolongada em ambiente agravitacional - local não especificado"
  },
  { "codigo": "X530", "descricao": "Falta de alimento - residência" },
  {
    "codigo": "X531",
    "descricao": "Falta de alimento - habitação coletiva"
  },
  {
    "codigo": "X532",
    "descricao": "Falta de alimento - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X533",
    "descricao": "Falta de alimento - área para a prática de esportes e atletismo"
  },
  { "codigo": "X534", "descricao": "Falta de alimento - rua e estrada" },
  {
    "codigo": "X535",
    "descricao": "Falta de alimento - áreas de comércio e de serviços"
  },
  {
    "codigo": "X536",
    "descricao": "Falta de alimento - áreas industriais e em construção"
  },
  { "codigo": "X537", "descricao": "Falta de alimento - fazenda" },
  {
    "codigo": "X538",
    "descricao": "Falta de alimento - outros locais especificados"
  },
  {
    "codigo": "X539",
    "descricao": "Falta de alimento - local não especificado"
  },
  { "codigo": "X540", "descricao": "Falta de água - residência" },
  {
    "codigo": "X541",
    "descricao": "Falta de água - habitação coletiva"
  },
  {
    "codigo": "X542",
    "descricao": "Falta de água - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X543",
    "descricao": "Falta de água - área para a prática de esportes e atletismo"
  },
  { "codigo": "X544", "descricao": "Falta de água - rua e estrada" },
  {
    "codigo": "X545",
    "descricao": "Falta de água - áreas de comércio e de serviços"
  },
  {
    "codigo": "X546",
    "descricao": "Falta de água - áreas industriais e em construção"
  },
  { "codigo": "X547", "descricao": "Falta de água - fazenda" },
  {
    "codigo": "X548",
    "descricao": "Falta de água - outros locais especificados"
  },
  {
    "codigo": "X549",
    "descricao": "Falta de água - local não especificado"
  },
  {
    "codigo": "X570",
    "descricao": "Privação não especificada - residência"
  },
  {
    "codigo": "X571",
    "descricao": "Privação não especificada - habitação coletiva"
  },
  {
    "codigo": "X572",
    "descricao": "Privação não especificada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X573",
    "descricao": "Privação não especificada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X574",
    "descricao": "Privação não especificada - rua e estrada"
  },
  {
    "codigo": "X575",
    "descricao": "Privação não especificada - áreas de comércio e de serviços"
  },
  {
    "codigo": "X576",
    "descricao": "Privação não especificada - áreas industriais e em construção"
  },
  {
    "codigo": "X577",
    "descricao": "Privação não especificada - fazenda"
  },
  {
    "codigo": "X578",
    "descricao": "Privação não especificada - outros locais especificados"
  },
  {
    "codigo": "X579",
    "descricao": "Privação não especificada - local não especificado"
  },
  {
    "codigo": "X580",
    "descricao": "Exposição a outros fatores especificados - residência"
  },
  {
    "codigo": "X581",
    "descricao": "Exposição a outros fatores especificados - habitação coletiva"
  },
  {
    "codigo": "X582",
    "descricao": "Exposição a outros fatores especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X583",
    "descricao": "Exposição a outros fatores especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X584",
    "descricao": "Exposição a outros fatores especificados - rua e estrada"
  },
  {
    "codigo": "X585",
    "descricao": "Exposição a outros fatores especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X586",
    "descricao": "Exposição a outros fatores especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X587",
    "descricao": "Exposição a outros fatores especificados - fazenda"
  },
  {
    "codigo": "X588",
    "descricao": "Exposição a outros fatores especificados - outros locais especificados"
  },
  {
    "codigo": "X589",
    "descricao": "Exposição a outros fatores especificados - local não especificado"
  },
  {
    "codigo": "X590",
    "descricao": "Exposição a fator não especificado causando fratura"
  },
  {
    "codigo": "X599",
    "descricao": "Exposição a fator não especificado causando outro tr|aumatismo e traumatismo não especificado"
  },
  {
    "codigo": "X600",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - residência"
  },
  {
    "codigo": "X601",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - habitação coletiva"
  },
  {
    "codigo": "X602",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X603",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X604",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - rua e estrada"
  },
  {
    "codigo": "X605",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X606",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas industriais e em construção"
  },
  {
    "codigo": "X607",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - fazenda"
  },
  {
    "codigo": "X608",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - outros locais especificados"
  },
  {
    "codigo": "X609",
    "descricao": "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - local não especificado"
  },
  {
    "codigo": "X610",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - residência"
  },
  {
    "codigo": "X611",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - habitação coletiva"
  },
  {
    "codigo": "X612",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X613",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X614",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - rua e estrada"
  },
  {
    "codigo": "X615",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - áreas de comércio e de serviços"
  },
  {
    "codigo": "X616",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - áreas industriais e em construção"
  },
  {
    "codigo": "X617",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - fazenda"
  },
  {
    "codigo": "X618",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - outros locais especificados"
  },
  {
    "codigo": "X619",
    "descricao": "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - local não especificado"
  },
  {
    "codigo": "X620",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - residência"
  },
  {
    "codigo": "X621",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - habitação coletiva"
  },
  {
    "codigo": "X622",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X623",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X624",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - rua e estrada"
  },
  {
    "codigo": "X625",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas de comércio e de serviços"
  },
  {
    "codigo": "X626",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas industriais e em construção"
  },
  {
    "codigo": "X627",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - fazenda"
  },
  {
    "codigo": "X628",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - outros locais especificados"
  },
  {
    "codigo": "X629",
    "descricao": "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - local não especificado"
  },
  {
    "codigo": "X630",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - residência"
  },
  {
    "codigo": "X631",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - habitação coletiva"
  },
  {
    "codigo": "X632",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X633",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X634",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - rua e estrada"
  },
  {
    "codigo": "X635",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas de comércio e de serviços"
  },
  {
    "codigo": "X636",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas industriais e em construção"
  },
  {
    "codigo": "X637",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - fazenda"
  },
  {
    "codigo": "X638",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - outros locais especificados"
  },
  {
    "codigo": "X639",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - local não especificado"
  },
  {
    "codigo": "X640",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - residência"
  },
  {
    "codigo": "X641",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - habitação coletiva"
  },
  {
    "codigo": "X642",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X643",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X644",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - rua e estrada"
  },
  {
    "codigo": "X645",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X646",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "X647",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - fazenda"
  },
  {
    "codigo": "X648",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - outros locais especificados"
  },
  {
    "codigo": "X649",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - local não especificado"
  },
  {
    "codigo": "X650",
    "descricao": "Auto-intoxicação voluntária por álcool - residência"
  },
  {
    "codigo": "X651",
    "descricao": "Auto-intoxicação voluntária por álcool - habitação coletiva"
  },
  {
    "codigo": "X652",
    "descricao": "Auto-intoxicação voluntária por álcool - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X653",
    "descricao": "Auto-intoxicação voluntária por álcool - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X654",
    "descricao": "Auto-intoxicação voluntária por álcool - rua e estrada"
  },
  {
    "codigo": "X655",
    "descricao": "Auto-intoxicação voluntária por álcool - áreas de comércio e de serviços"
  },
  {
    "codigo": "X656",
    "descricao": "Auto-intoxicação voluntária por álcool - áreas industriais e em construção"
  },
  {
    "codigo": "X657",
    "descricao": "Auto-intoxicação voluntária por álcool - fazenda"
  },
  {
    "codigo": "X658",
    "descricao": "Auto-intoxicação voluntária por álcool - outros locais especificados"
  },
  {
    "codigo": "X659",
    "descricao": "Auto-intoxicação voluntária por álcool - local não especificado"
  },
  {
    "codigo": "X660",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - residência"
  },
  {
    "codigo": "X661",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - habitação coletiva"
  },
  {
    "codigo": "X662",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X663",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X664",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - rua e estrada"
  },
  {
    "codigo": "X665",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - áreas de comércio e de serviços"
  },
  {
    "codigo": "X666",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - áreas industriais e em construção"
  },
  {
    "codigo": "X667",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - fazenda"
  },
  {
    "codigo": "X668",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - outros locais especificados"
  },
  {
    "codigo": "X669",
    "descricao": "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - local não especificado"
  },
  {
    "codigo": "X670",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - residência"
  },
  {
    "codigo": "X671",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - habitação coletiva"
  },
  {
    "codigo": "X672",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X673",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X674",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - rua e estrada"
  },
  {
    "codigo": "X675",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - áreas de comércio e de serviços"
  },
  {
    "codigo": "X676",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - áreas industriais e em construção"
  },
  {
    "codigo": "X677",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - fazenda"
  },
  {
    "codigo": "X678",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - outros locais especificados"
  },
  {
    "codigo": "X679",
    "descricao": "Auto-intoxicação intencional por outros gases e vapores - local não especificado"
  },
  {
    "codigo": "X680",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - residência"
  },
  {
    "codigo": "X681",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - habitação coletiva"
  },
  {
    "codigo": "X682",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X683",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X684",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - rua e estrada"
  },
  {
    "codigo": "X685",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X686",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - áreas industriais e em construção"
  },
  {
    "codigo": "X687",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - fazenda"
  },
  {
    "codigo": "X688",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - outros locais especificados"
  },
  {
    "codigo": "X689",
    "descricao": "Auto-intoxicação por e exposição, intencional, a pesticidas - local não especificado"
  },
  {
    "codigo": "X690",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - residência"
  },
  {
    "codigo": "X691",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - habitação coletiva"
  },
  {
    "codigo": "X692",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X693",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X694",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - rua e estrada"
  },
  {
    "codigo": "X695",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X696",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - áreas industriais e em construção"
  },
  {
    "codigo": "X697",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - fazenda"
  },
  {
    "codigo": "X698",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - outros locais especificados"
  },
  {
    "codigo": "X699",
    "descricao": "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - local não especificado"
  },
  {
    "codigo": "X700",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - residência"
  },
  {
    "codigo": "X701",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - habitação coletiva"
  },
  {
    "codigo": "X702",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X703",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X704",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - rua e estrada"
  },
  {
    "codigo": "X705",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - áreas de comércio e de serviços"
  },
  {
    "codigo": "X706",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - áreas industriais e em construção"
  },
  {
    "codigo": "X707",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - fazenda"
  },
  {
    "codigo": "X708",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - outros locais especificados"
  },
  {
    "codigo": "X709",
    "descricao": "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - local não especificado"
  },
  {
    "codigo": "X710",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - residência"
  },
  {
    "codigo": "X711",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - habitação coletiva"
  },
  {
    "codigo": "X712",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X713",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X714",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - rua e estrada"
  },
  {
    "codigo": "X715",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - áreas de comércio e de serviços"
  },
  {
    "codigo": "X716",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - áreas industriais e em construção"
  },
  {
    "codigo": "X717",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - fazenda"
  },
  {
    "codigo": "X718",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - outros locais especificados"
  },
  {
    "codigo": "X719",
    "descricao": "Lesão autoprovocada intencionalmente por afogamento e submersão - local não especificado"
  },
  {
    "codigo": "X720",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - residência"
  },
  {
    "codigo": "X721",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - habitação coletiva"
  },
  {
    "codigo": "X722",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X723",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X724",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - rua e estrada"
  },
  {
    "codigo": "X725",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - áreas de comércio e de serviços"
  },
  {
    "codigo": "X726",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - áreas industriais e em construção"
  },
  {
    "codigo": "X727",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - fazenda"
  },
  {
    "codigo": "X728",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - outros locais especificados"
  },
  {
    "codigo": "X729",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - local não especificado"
  },
  {
    "codigo": "X730",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - residência"
  },
  {
    "codigo": "X731",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - habitação coletiva"
  },
  {
    "codigo": "X732",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X733",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X734",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - rua e estrada"
  },
  {
    "codigo": "X735",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - áreas de comércio e de serviços"
  },
  {
    "codigo": "X736",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - áreas industriais e em construção"
  },
  {
    "codigo": "X737",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - fazenda"
  },
  {
    "codigo": "X738",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - outros locais especificados"
  },
  {
    "codigo": "X739",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - local não especificado"
  },
  {
    "codigo": "X740",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - residência"
  },
  {
    "codigo": "X741",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - habitação coletiva"
  },
  {
    "codigo": "X742",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X743",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X744",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - rua e estrada"
  },
  {
    "codigo": "X745",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - áreas de comércio e de serviços"
  },
  {
    "codigo": "X746",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - áreas industriais e em construção"
  },
  {
    "codigo": "X747",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - fazenda"
  },
  {
    "codigo": "X748",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - outros locais especificados"
  },
  {
    "codigo": "X749",
    "descricao": "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - local não especificado"
  },
  {
    "codigo": "X750",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - residência"
  },
  {
    "codigo": "X751",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - habitação coletiva"
  },
  {
    "codigo": "X752",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X753",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X754",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - rua e estrada"
  },
  {
    "codigo": "X755",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - áreas de comércio e de serviços"
  },
  {
    "codigo": "X756",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - áreas industriais e em construção"
  },
  {
    "codigo": "X757",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - fazenda"
  },
  {
    "codigo": "X758",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - outros locais especificados"
  },
  {
    "codigo": "X759",
    "descricao": "Lesão autoprovocada intencionalmente por dispositivos explosivos - local não especificado"
  },
  {
    "codigo": "X760",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - residência"
  },
  {
    "codigo": "X761",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - habitação coletiva"
  },
  {
    "codigo": "X762",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X763",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X764",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - rua e estrada"
  },
  {
    "codigo": "X765",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X766",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - áreas industriais e em construção"
  },
  {
    "codigo": "X767",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - fazenda"
  },
  {
    "codigo": "X768",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - outros locais especificados"
  },
  {
    "codigo": "X769",
    "descricao": "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - local não especificado"
  },
  {
    "codigo": "X770",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - residência"
  },
  {
    "codigo": "X771",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - habitação coletiva"
  },
  {
    "codigo": "X772",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X773",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X774",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - rua e estrada"
  },
  {
    "codigo": "X775",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X776",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X777",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - fazenda"
  },
  {
    "codigo": "X778",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - outros locais especificados"
  },
  {
    "codigo": "X779",
    "descricao": "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - local não especificado"
  },
  {
    "codigo": "X780",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - residência"
  },
  {
    "codigo": "X781",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - habitação coletiva"
  },
  {
    "codigo": "X782",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X783",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X784",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - rua e estrada"
  },
  {
    "codigo": "X785",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - áreas de comércio e de serviços"
  },
  {
    "codigo": "X786",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - áreas industriais e em construção"
  },
  {
    "codigo": "X787",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - fazenda"
  },
  {
    "codigo": "X788",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - outros locais especificados"
  },
  {
    "codigo": "X789",
    "descricao": "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - local não especificado"
  },
  {
    "codigo": "X790",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - residência"
  },
  {
    "codigo": "X791",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - habitação coletiva"
  },
  {
    "codigo": "X792",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X793",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X794",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - rua e estrada"
  },
  {
    "codigo": "X795",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - áreas de comércio e de serviços"
  },
  {
    "codigo": "X796",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - áreas industriais e em construção"
  },
  {
    "codigo": "X797",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - fazenda"
  },
  {
    "codigo": "X798",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - outros locais especificados"
  },
  {
    "codigo": "X799",
    "descricao": "Lesão autoprovocada intencionalmente por objeto contundente - local não especificado"
  },
  {
    "codigo": "X800",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - residência"
  },
  {
    "codigo": "X801",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - habitação coletiva"
  },
  {
    "codigo": "X802",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X803",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X804",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - rua e estrada"
  },
  {
    "codigo": "X805",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - áreas de comércio e de serviços"
  },
  {
    "codigo": "X806",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - áreas industriais e em construção"
  },
  {
    "codigo": "X807",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - fazenda"
  },
  {
    "codigo": "X808",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - outros locais especificados"
  },
  {
    "codigo": "X809",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - local não especificado"
  },
  {
    "codigo": "X810",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - residência"
  },
  {
    "codigo": "X811",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - habitação coletiva"
  },
  {
    "codigo": "X812",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X813",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X814",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - rua e estrada"
  },
  {
    "codigo": "X815",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - áreas de comércio e de serviços"
  },
  {
    "codigo": "X816",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - áreas industriais e em construção"
  },
  {
    "codigo": "X817",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - fazenda"
  },
  {
    "codigo": "X818",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - outros locais especificados"
  },
  {
    "codigo": "X819",
    "descricao": "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - local não especificado"
  },
  {
    "codigo": "X820",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - residência"
  },
  {
    "codigo": "X821",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - habitação coletiva"
  },
  {
    "codigo": "X822",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X823",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X824",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - rua e estrada"
  },
  {
    "codigo": "X825",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - áreas de comércio e de serviços"
  },
  {
    "codigo": "X826",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - áreas industriais e em construção"
  },
  {
    "codigo": "X827",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - fazenda"
  },
  {
    "codigo": "X828",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - outros locais especificados"
  },
  {
    "codigo": "X829",
    "descricao": "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - local não especificado"
  },
  {
    "codigo": "X830",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - residência"
  },
  {
    "codigo": "X831",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - habitação coletiva"
  },
  {
    "codigo": "X832",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X833",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X834",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - rua e estrada"
  },
  {
    "codigo": "X835",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X836",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X837",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - fazenda"
  },
  {
    "codigo": "X838",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - outros locais especificados"
  },
  {
    "codigo": "X839",
    "descricao": "Lesão autoprovocada intencionalmente por outros meios especificados - local não especificado"
  },
  {
    "codigo": "X840",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - residência"
  },
  {
    "codigo": "X841",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - habitação coletiva"
  },
  {
    "codigo": "X842",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X843",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X844",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - rua e estrada"
  },
  {
    "codigo": "X845",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X846",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X847",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - fazenda"
  },
  {
    "codigo": "X848",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - outros locais especificados"
  },
  {
    "codigo": "X849",
    "descricao": "Lesão autoprovocada intencionalmente por meios não especificados - local não especificado"
  },
  {
    "codigo": "X850",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - residência"
  },
  {
    "codigo": "X851",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - habitação coletiva"
  },
  {
    "codigo": "X852",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X853",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X854",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - rua e estrada"
  },
  {
    "codigo": "X855",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X856",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - áreas industriais e em construção"
  },
  {
    "codigo": "X857",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - fazenda"
  },
  {
    "codigo": "X858",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - outros locais especificados"
  },
  {
    "codigo": "X859",
    "descricao": "Agressão por meio de drogas, medicamentos e substâncias biológicas - local não especificado"
  },
  {
    "codigo": "X860",
    "descricao": "Agressão por meio de substâncias corrosivas - residência"
  },
  {
    "codigo": "X861",
    "descricao": "Agressão por meio de substâncias corrosivas - habitação coletiva"
  },
  {
    "codigo": "X862",
    "descricao": "Agressão por meio de substâncias corrosivas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X863",
    "descricao": "Agressão por meio de substâncias corrosivas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X864",
    "descricao": "Agressão por meio de substâncias corrosivas - rua e estrada"
  },
  {
    "codigo": "X865",
    "descricao": "Agressão por meio de substâncias corrosivas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X866",
    "descricao": "Agressão por meio de substâncias corrosivas - áreas industriais e em construção"
  },
  {
    "codigo": "X867",
    "descricao": "Agressão por meio de substâncias corrosivas - fazenda"
  },
  {
    "codigo": "X868",
    "descricao": "Agressão por meio de substâncias corrosivas - outros locais especificados"
  },
  {
    "codigo": "X869",
    "descricao": "Agressão por meio de substâncias corrosivas - local não especificado"
  },
  {
    "codigo": "X870",
    "descricao": "Agressão por pesticidas - residência"
  },
  {
    "codigo": "X871",
    "descricao": "Agressão por pesticidas - habitação coletiva"
  },
  {
    "codigo": "X872",
    "descricao": "Agressão por pesticidas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X873",
    "descricao": "Agressão por pesticidas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X874",
    "descricao": "Agressão por pesticidas - rua e estrada"
  },
  {
    "codigo": "X875",
    "descricao": "Agressão por pesticidas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X876",
    "descricao": "Agressão por pesticidas - áreas industriais e em construção"
  },
  { "codigo": "X877", "descricao": "Agressão por pesticidas - fazenda" },
  {
    "codigo": "X878",
    "descricao": "Agressão por pesticidas - outros locais especificados"
  },
  {
    "codigo": "X879",
    "descricao": "Agressão por pesticidas - local não especificado"
  },
  {
    "codigo": "X880",
    "descricao": "Agressão por meio de gases e vapores - residência"
  },
  {
    "codigo": "X881",
    "descricao": "Agressão por meio de gases e vapores - habitação coletiva"
  },
  {
    "codigo": "X882",
    "descricao": "Agressão por meio de gases e vapores - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X883",
    "descricao": "Agressão por meio de gases e vapores - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X884",
    "descricao": "Agressão por meio de gases e vapores - rua e estrada"
  },
  {
    "codigo": "X885",
    "descricao": "Agressão por meio de gases e vapores - áreas de comércio e de serviços"
  },
  {
    "codigo": "X886",
    "descricao": "Agressão por meio de gases e vapores - áreas industriais e em construção"
  },
  {
    "codigo": "X887",
    "descricao": "Agressão por meio de gases e vapores - fazenda"
  },
  {
    "codigo": "X888",
    "descricao": "Agressão por meio de gases e vapores - outros locais especificados"
  },
  {
    "codigo": "X889",
    "descricao": "Agressão por meio de gases e vapores - local não especificado"
  },
  {
    "codigo": "X890",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - residência"
  },
  {
    "codigo": "X891",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - habitação coletiva"
  },
  {
    "codigo": "X892",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X893",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X894",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - rua e estrada"
  },
  {
    "codigo": "X895",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X896",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X897",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - fazenda"
  },
  {
    "codigo": "X898",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - outros locais especificados"
  },
  {
    "codigo": "X899",
    "descricao": "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - local não especificado"
  },
  {
    "codigo": "X900",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - residência"
  },
  {
    "codigo": "X901",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - habitação coletiva"
  },
  {
    "codigo": "X902",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X903",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X904",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - rua e estrada"
  },
  {
    "codigo": "X905",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "X906",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - áreas industriais e em construção"
  },
  {
    "codigo": "X907",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - fazenda"
  },
  {
    "codigo": "X908",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - outros locais especificados"
  },
  {
    "codigo": "X909",
    "descricao": "Agressão por meio de produtos químicos e substâncias nocivas não especificados - local não especificado"
  },
  {
    "codigo": "X910",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - residência"
  },
  {
    "codigo": "X911",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - habitação coletiva"
  },
  {
    "codigo": "X912",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X913",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X914",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - rua e estrada"
  },
  {
    "codigo": "X915",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - áreas de comércio e de serviços"
  },
  {
    "codigo": "X916",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - áreas industriais e em construção"
  },
  {
    "codigo": "X917",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - fazenda"
  },
  {
    "codigo": "X918",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - outros locais especificados"
  },
  {
    "codigo": "X919",
    "descricao": "Agressão por meio de enforcamento, estrangulamento e sufocação - local não especificado"
  },
  {
    "codigo": "X920",
    "descricao": "Agressão por meio de afogamento e submersão - residência"
  },
  {
    "codigo": "X921",
    "descricao": "Agressão por meio de afogamento e submersão - habitação coletiva"
  },
  {
    "codigo": "X922",
    "descricao": "Agressão por meio de afogamento e submersão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X923",
    "descricao": "Agressão por meio de afogamento e submersão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X924",
    "descricao": "Agressão por meio de afogamento e submersão - rua e estrada"
  },
  {
    "codigo": "X925",
    "descricao": "Agressão por meio de afogamento e submersão - áreas de comércio e de serviços"
  },
  {
    "codigo": "X926",
    "descricao": "Agressão por meio de afogamento e submersão - áreas industriais e em construção"
  },
  {
    "codigo": "X927",
    "descricao": "Agressão por meio de afogamento e submersão - fazenda"
  },
  {
    "codigo": "X928",
    "descricao": "Agressão por meio de afogamento e submersão - outros locais especificados"
  },
  {
    "codigo": "X929",
    "descricao": "Agressão por meio de afogamento e submersão - local não especificado"
  },
  {
    "codigo": "X930",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - residência"
  },
  {
    "codigo": "X931",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - habitação coletiva"
  },
  {
    "codigo": "X932",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X933",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X934",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - rua e estrada"
  },
  {
    "codigo": "X935",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - áreas de comércio e de serviços"
  },
  {
    "codigo": "X936",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - áreas industriais e em construção"
  },
  {
    "codigo": "X937",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - fazenda"
  },
  {
    "codigo": "X938",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - outros locais especificados"
  },
  {
    "codigo": "X939",
    "descricao": "Agressão por meio de disparo de arma de fogo de mão - local não especificado"
  },
  {
    "codigo": "X940",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - residência"
  },
  {
    "codigo": "X941",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - habitação coletiva"
  },
  {
    "codigo": "X942",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X943",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X944",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - rua e estrada"
  },
  {
    "codigo": "X945",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - áreas de comércio e de serviços"
  },
  {
    "codigo": "X946",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - áreas industriais e em construção"
  },
  {
    "codigo": "X947",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - fazenda"
  },
  {
    "codigo": "X948",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - outros locais especificados"
  },
  {
    "codigo": "X949",
    "descricao": "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - local não especificado"
  },
  {
    "codigo": "X950",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - residência"
  },
  {
    "codigo": "X951",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - habitação coletiva"
  },
  {
    "codigo": "X952",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X953",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X954",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - rua e estrada"
  },
  {
    "codigo": "X955",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - áreas de comércio e de serviços"
  },
  {
    "codigo": "X956",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - áreas industriais e em construção"
  },
  {
    "codigo": "X957",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - fazenda"
  },
  {
    "codigo": "X958",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - outros locais especificados"
  },
  {
    "codigo": "X959",
    "descricao": "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - local não especificado"
  },
  {
    "codigo": "X960",
    "descricao": "Agressão por meio de material explosivo - residência"
  },
  {
    "codigo": "X961",
    "descricao": "Agressão por meio de material explosivo - habitação coletiva"
  },
  {
    "codigo": "X962",
    "descricao": "Agressão por meio de material explosivo - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X963",
    "descricao": "Agressão por meio de material explosivo - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X964",
    "descricao": "Agressão por meio de material explosivo - rua e estrada"
  },
  {
    "codigo": "X965",
    "descricao": "Agressão por meio de material explosivo - áreas de comércio e de serviços"
  },
  {
    "codigo": "X966",
    "descricao": "Agressão por meio de material explosivo - áreas industriais e em construção"
  },
  {
    "codigo": "X967",
    "descricao": "Agressão por meio de material explosivo - fazenda"
  },
  {
    "codigo": "X968",
    "descricao": "Agressão por meio de material explosivo - outros locais especificados"
  },
  {
    "codigo": "X969",
    "descricao": "Agressão por meio de material explosivo - local não especificado"
  },
  {
    "codigo": "X970",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - residência"
  },
  {
    "codigo": "X971",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - habitação coletiva"
  },
  {
    "codigo": "X972",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X973",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X974",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - rua e estrada"
  },
  {
    "codigo": "X975",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - áreas de comércio e de serviços"
  },
  {
    "codigo": "X976",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - áreas industriais e em construção"
  },
  {
    "codigo": "X977",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - fazenda"
  },
  {
    "codigo": "X978",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - outros locais especificados"
  },
  {
    "codigo": "X979",
    "descricao": "Agressão por meio de fumaça, fogo e chamas - local não especificado"
  },
  {
    "codigo": "X980",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - residência"
  },
  {
    "codigo": "X981",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - habitação coletiva"
  },
  {
    "codigo": "X982",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X983",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X984",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - rua e estrada"
  },
  {
    "codigo": "X985",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - áreas de comércio e de serviços"
  },
  {
    "codigo": "X986",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - áreas industriais e em construção"
  },
  {
    "codigo": "X987",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - fazenda"
  },
  {
    "codigo": "X988",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - outros locais especificados"
  },
  {
    "codigo": "X989",
    "descricao": "Agressão por meio de vapor de água, gases ou objetos quentes - local não especificado"
  },
  {
    "codigo": "X990",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - residência"
  },
  {
    "codigo": "X991",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - habitação coletiva"
  },
  {
    "codigo": "X992",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "X993",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "X994",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - rua e estrada"
  },
  {
    "codigo": "X995",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - áreas de comércio e de serviços"
  },
  {
    "codigo": "X996",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - áreas industriais e em construção"
  },
  {
    "codigo": "X997",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - fazenda"
  },
  {
    "codigo": "X998",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - outros locais especificados"
  },
  {
    "codigo": "X999",
    "descricao": "Agressão por meio de objeto cortante ou penetrante - local não especificado"
  },
  {
    "codigo": "Y000",
    "descricao": "Agressão por meio de um objeto contundente - residência"
  },
  {
    "codigo": "Y001",
    "descricao": "Agressão por meio de um objeto contundente - habitação coletiva"
  },
  {
    "codigo": "Y002",
    "descricao": "Agressão por meio de um objeto contundente - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y003",
    "descricao": "Agressão por meio de um objeto contundente - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y004",
    "descricao": "Agressão por meio de um objeto contundente - rua e estrada"
  },
  {
    "codigo": "Y005",
    "descricao": "Agressão por meio de um objeto contundente - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y006",
    "descricao": "Agressão por meio de um objeto contundente - áreas industriais e em construção"
  },
  {
    "codigo": "Y007",
    "descricao": "Agressão por meio de um objeto contundente - fazenda"
  },
  {
    "codigo": "Y008",
    "descricao": "Agressão por meio de um objeto contundente - outros locais especificados"
  },
  {
    "codigo": "Y009",
    "descricao": "Agressão por meio de um objeto contundente - local não especificado"
  },
  {
    "codigo": "Y010",
    "descricao": "Agressão por meio de projeção de um lugar elevado - residência"
  },
  {
    "codigo": "Y011",
    "descricao": "Agressão por meio de projeção de um lugar elevado - habitação coletiva"
  },
  {
    "codigo": "Y012",
    "descricao": "Agressão por meio de projeção de um lugar elevado - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y013",
    "descricao": "Agressão por meio de projeção de um lugar elevado - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y014",
    "descricao": "Agressão por meio de projeção de um lugar elevado - rua e estrada"
  },
  {
    "codigo": "Y015",
    "descricao": "Agressão por meio de projeção de um lugar elevado - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y016",
    "descricao": "Agressão por meio de projeção de um lugar elevado - áreas industriais e em construção"
  },
  {
    "codigo": "Y017",
    "descricao": "Agressão por meio de projeção de um lugar elevado - fazenda"
  },
  {
    "codigo": "Y018",
    "descricao": "Agressão por meio de projeção de um lugar elevado - outros locais especificados"
  },
  {
    "codigo": "Y019",
    "descricao": "Agressão por meio de projeção de um lugar elevado - local não especificado"
  },
  {
    "codigo": "Y020",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - residência"
  },
  {
    "codigo": "Y021",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - habitação coletiva"
  },
  {
    "codigo": "Y022",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y023",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y024",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - rua e estrada"
  },
  {
    "codigo": "Y025",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y026",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - áreas industriais e em construção"
  },
  {
    "codigo": "Y027",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - fazenda"
  },
  {
    "codigo": "Y028",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - outros locais especificados"
  },
  {
    "codigo": "Y029",
    "descricao": "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - local não especificado"
  },
  {
    "codigo": "Y030",
    "descricao": "Agressão por meio de impacto de um veículo a motor - residência"
  },
  {
    "codigo": "Y031",
    "descricao": "Agressão por meio de impacto de um veículo a motor - habitação coletiva"
  },
  {
    "codigo": "Y032",
    "descricao": "Agressão por meio de impacto de um veículo a motor - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y033",
    "descricao": "Agressão por meio de impacto de um veículo a motor - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y034",
    "descricao": "Agressão por meio de impacto de um veículo a motor - rua e estrada"
  },
  {
    "codigo": "Y035",
    "descricao": "Agressão por meio de impacto de um veículo a motor - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y036",
    "descricao": "Agressão por meio de impacto de um veículo a motor - áreas industriais e em construção"
  },
  {
    "codigo": "Y037",
    "descricao": "Agressão por meio de impacto de um veículo a motor - fazenda"
  },
  {
    "codigo": "Y038",
    "descricao": "Agressão por meio de impacto de um veículo a motor - outros locais especificados"
  },
  {
    "codigo": "Y039",
    "descricao": "Agressão por meio de impacto de um veículo a motor - local não especificado"
  },
  {
    "codigo": "Y040",
    "descricao": "Agressão por meio de força corporal - residência"
  },
  {
    "codigo": "Y041",
    "descricao": "Agressão por meio de força corporal - habitação coletiva"
  },
  {
    "codigo": "Y042",
    "descricao": "Agressão por meio de força corporal - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y043",
    "descricao": "Agressão por meio de força corporal - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y044",
    "descricao": "Agressão por meio de força corporal - rua e estrada"
  },
  {
    "codigo": "Y045",
    "descricao": "Agressão por meio de força corporal - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y046",
    "descricao": "Agressão por meio de força corporal - áreas industriais e em construção"
  },
  {
    "codigo": "Y047",
    "descricao": "Agressão por meio de força corporal - fazenda"
  },
  {
    "codigo": "Y048",
    "descricao": "Agressão por meio de força corporal - outros locais especificados"
  },
  {
    "codigo": "Y049",
    "descricao": "Agressão por meio de força corporal - local não especificado"
  },
  {
    "codigo": "Y050",
    "descricao": "Agressão sexual por meio de força física - residência"
  },
  {
    "codigo": "Y051",
    "descricao": "Agressão sexual por meio de força física - habitação coletiva"
  },
  {
    "codigo": "Y052",
    "descricao": "Agressão sexual por meio de força física - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y053",
    "descricao": "Agressão sexual por meio de força física - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y054",
    "descricao": "Agressão sexual por meio de força física - rua e estrada"
  },
  {
    "codigo": "Y055",
    "descricao": "Agressão sexual por meio de força física - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y056",
    "descricao": "Agressão sexual por meio de força física - áreas industriais e em construção"
  },
  {
    "codigo": "Y057",
    "descricao": "Agressão sexual por meio de força física - fazenda"
  },
  {
    "codigo": "Y058",
    "descricao": "Agressão sexual por meio de força física - outros locais especificados"
  },
  {
    "codigo": "Y059",
    "descricao": "Agressão sexual por meio de força física - local não especificado"
  },
  {
    "codigo": "Y060",
    "descricao": "Negligência e abandono pelo esposo ou companheiro"
  },
  { "codigo": "Y061", "descricao": "Negligência e abandono pelos pais" },
  {
    "codigo": "Y062",
    "descricao": "Negligência e abandono por conhecido ou amigo"
  },
  {
    "codigo": "Y068",
    "descricao": "Negligência e abandono por outra pessoa especificada"
  },
  {
    "codigo": "Y069",
    "descricao": "Negligência e abandono por pessoa não especificada"
  },
  {
    "codigo": "Y070",
    "descricao": "Outras síndromes de maus tratos pelo esposo ou companheiro"
  },
  {
    "codigo": "Y071",
    "descricao": "Outras síndromes de maus tratos pelos pais"
  },
  {
    "codigo": "Y072",
    "descricao": "Outras síndromes de maus tratos por conhecido ou amigo"
  },
  {
    "codigo": "Y073",
    "descricao": "Outras síndromes de maus tratos por autoridades oficiais"
  },
  {
    "codigo": "Y078",
    "descricao": "Outras síndromes de maus tratos por outra pessoa especificada"
  },
  {
    "codigo": "Y079",
    "descricao": "Outras síndromes de maus tratos por pessoa não especificada"
  },
  {
    "codigo": "Y080",
    "descricao": "Agressão por outros meios especificados - residência"
  },
  {
    "codigo": "Y081",
    "descricao": "Agressão por outros meios especificados - habitação coletiva"
  },
  {
    "codigo": "Y082",
    "descricao": "Agressão por outros meios especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y083",
    "descricao": "Agressão por outros meios especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y084",
    "descricao": "Agressão por outros meios especificados - rua e estrada"
  },
  {
    "codigo": "Y085",
    "descricao": "Agressão por outros meios especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y086",
    "descricao": "Agressão por outros meios especificados - áreas industriais e em construção"
  },
  {
    "codigo": "Y087",
    "descricao": "Agressão por outros meios especificados - fazenda"
  },
  {
    "codigo": "Y088",
    "descricao": "Agressão por outros meios especificados - outros locais especificados"
  },
  {
    "codigo": "Y089",
    "descricao": "Agressão por outros meios especificados - local não especificado"
  },
  {
    "codigo": "Y090",
    "descricao": "Agressão por meios não especificados - residência"
  },
  {
    "codigo": "Y091",
    "descricao": "Agressão por meios não especificados - habitação coletiva"
  },
  {
    "codigo": "Y092",
    "descricao": "Agressão por meios não especificados - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y093",
    "descricao": "Agressão por meios não especificados - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y094",
    "descricao": "Agressão por meios não especificados - rua e estrada"
  },
  {
    "codigo": "Y095",
    "descricao": "Agressão por meios não especificados - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y096",
    "descricao": "Agressão por meios não especificados - áreas industriais e em construção"
  },
  {
    "codigo": "Y097",
    "descricao": "Agressão por meios não especificados - fazenda"
  },
  {
    "codigo": "Y098",
    "descricao": "Agressão por meios não especificados - outros locais especificados"
  },
  {
    "codigo": "Y099",
    "descricao": "Agressão por meios não especificados - local não especificado"
  },
  {
    "codigo": "Y100",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - residência"
  },
  {
    "codigo": "Y101",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y102",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y103",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y104",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y105",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y106",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y107",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y108",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y109",
    "descricao": "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y110",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - residência"
  },
  {
    "codigo": "Y111",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y112",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y113",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y114",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y115",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y116",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y117",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y118",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y119",
    "descricao": "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y120",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - residência"
  },
  {
    "codigo": "Y121",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y122",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y123",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y124",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y125",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y126",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y127",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y128",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y129",
    "descricao": "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y130",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - residência"
  },
  {
    "codigo": "Y131",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y132",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y133",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y134",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y135",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y136",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y137",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y138",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y139",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y140",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - residência"
  },
  {
    "codigo": "Y141",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y142",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y143",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y144",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y145",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y146",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y147",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y148",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y149",
    "descricao": "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y150",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - residência"
  },
  {
    "codigo": "Y151",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y152",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y153",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y154",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y155",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y156",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y157",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y158",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y159",
    "descricao": "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y160",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - residência"
  },
  {
    "codigo": "Y161",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y162",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y163",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y164",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y165",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y166",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y167",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y168",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y169",
    "descricao": "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y170",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - residência"
  },
  {
    "codigo": "Y171",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y172",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y173",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y174",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y175",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y176",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y177",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y178",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y179",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y180",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - residência"
  },
  {
    "codigo": "Y181",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y182",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y183",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y184",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y185",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y186",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y187",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y188",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y189",
    "descricao": "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y190",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - residência"
  },
  {
    "codigo": "Y191",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y192",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y193",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y194",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y195",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y196",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y197",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y198",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y199",
    "descricao": "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y200",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - residência"
  },
  {
    "codigo": "Y201",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y202",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y203",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y204",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y205",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y206",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y207",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y208",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y209",
    "descricao": "Enforcamento, estrangulamento e sufocação, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y210",
    "descricao": "Afogamento e submersão, intenção não determinada - residência"
  },
  {
    "codigo": "Y211",
    "descricao": "Afogamento e submersão, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y212",
    "descricao": "Afogamento e submersão, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y213",
    "descricao": "Afogamento e submersão, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y214",
    "descricao": "Afogamento e submersão, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y215",
    "descricao": "Afogamento e submersão, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y216",
    "descricao": "Afogamento e submersão, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y217",
    "descricao": "Afogamento e submersão, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y218",
    "descricao": "Afogamento e submersão, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y219",
    "descricao": "Afogamento e submersão, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y220",
    "descricao": "Disparo de pistola, intenção não determinada - residência"
  },
  {
    "codigo": "Y221",
    "descricao": "Disparo de pistola, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y222",
    "descricao": "Disparo de pistola, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y223",
    "descricao": "Disparo de pistola, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y224",
    "descricao": "Disparo de pistola, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y225",
    "descricao": "Disparo de pistola, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y226",
    "descricao": "Disparo de pistola, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y227",
    "descricao": "Disparo de pistola, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y228",
    "descricao": "Disparo de pistola, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y229",
    "descricao": "Disparo de pistola, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y230",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - residência"
  },
  {
    "codigo": "Y231",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y232",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y233",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y234",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y235",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y236",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y237",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y238",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y239",
    "descricao": "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y240",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - residência"
  },
  {
    "codigo": "Y241",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y242",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y243",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y244",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y245",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y246",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y247",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y248",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y249",
    "descricao": "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y250",
    "descricao": "Contato com material explosivo, intenção não determinada - residência"
  },
  {
    "codigo": "Y251",
    "descricao": "Contato com material explosivo, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y252",
    "descricao": "Contato com material explosivo, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y253",
    "descricao": "Contato com material explosivo, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y254",
    "descricao": "Contato com material explosivo, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y255",
    "descricao": "Contato com material explosivo, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y256",
    "descricao": "Contato com material explosivo, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y257",
    "descricao": "Contato com material explosivo, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y258",
    "descricao": "Contato com material explosivo, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y259",
    "descricao": "Contato com material explosivo, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y260",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - residência"
  },
  {
    "codigo": "Y261",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y262",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y263",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y264",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y265",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y266",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y267",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y268",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y269",
    "descricao": "Exposição a fumaça, fogo e chamas, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y270",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - residência"
  },
  {
    "codigo": "Y271",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y272",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y273",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y274",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y275",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y276",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y277",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y278",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y279",
    "descricao": "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y280",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - residência"
  },
  {
    "codigo": "Y281",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y282",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y283",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y284",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y285",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y286",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y287",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y288",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y289",
    "descricao": "Contato com objeto cortante ou penetrante, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y290",
    "descricao": "Contato com objeto contundente, intenção não determinada - residência"
  },
  {
    "codigo": "Y291",
    "descricao": "Contato com objeto contundente, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y292",
    "descricao": "Contato com objeto contundente, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y293",
    "descricao": "Contato com objeto contundente, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y294",
    "descricao": "Contato com objeto contundente, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y295",
    "descricao": "Contato com objeto contundente, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y296",
    "descricao": "Contato com objeto contundente, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y297",
    "descricao": "Contato com objeto contundente, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y298",
    "descricao": "Contato com objeto contundente, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y299",
    "descricao": "Contato com objeto contundente, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y300",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - residência"
  },
  {
    "codigo": "Y301",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y302",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y303",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y304",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y305",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y306",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y307",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y308",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y309",
    "descricao": "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y310",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - residência"
  },
  {
    "codigo": "Y311",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y312",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y313",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y314",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y315",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y316",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y317",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y318",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y319",
    "descricao": "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y320",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - residência"
  },
  {
    "codigo": "Y321",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y322",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y323",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y324",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y325",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y326",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y327",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y328",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y329",
    "descricao": "Impacto de um veículo a motor, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y330",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - residência"
  },
  {
    "codigo": "Y331",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y332",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y333",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y334",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y335",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y336",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y337",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - fazenda"
  },
  {
    "codigo": "Y338",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y339",
    "descricao": "Outros fatos ou eventos especificados, intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y340",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - residência"
  },
  {
    "codigo": "Y341",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - habitação coletiva"
  },
  {
    "codigo": "Y342",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - escolas, outras instituições e áreas de administração pública"
  },
  {
    "codigo": "Y343",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - área para a prática de esportes e atletismo"
  },
  {
    "codigo": "Y344",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - rua e estrada"
  },
  {
    "codigo": "Y345",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - áreas de comércio e de serviços"
  },
  {
    "codigo": "Y346",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - áreas industriais e em construção"
  },
  {
    "codigo": "Y347",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - fazenda"
  },
  {
    "codigo": "Y348",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - outros locais especificados"
  },
  {
    "codigo": "Y349",
    "descricao": "Fatos ou eventos não especificados e intenção não determinada - local não especificado"
  },
  {
    "codigo": "Y350",
    "descricao": "Intervenção legal envolvendo o uso de armas de fogo"
  },
  {
    "codigo": "Y351",
    "descricao": "Intervenção legal envolvendo o uso de explosivos"
  },
  {
    "codigo": "Y352",
    "descricao": "Intervenção legal envolvendo o uso de gás"
  },
  {
    "codigo": "Y353",
    "descricao": "Intervenção legal envolvendo o uso de objetos contundentes"
  },
  {
    "codigo": "Y354",
    "descricao": "Intervenção legal envolvendo o uso de objetos cortantes e penetrantes"
  },
  { "codigo": "Y355", "descricao": "Execução legal" },
  {
    "codigo": "Y356",
    "descricao": "Intervenção legal envolvendo o uso de outros meios especificados"
  },
  {
    "codigo": "Y357",
    "descricao": "Intervenção legal, meio não especificado"
  },
  {
    "codigo": "Y360",
    "descricao": "Operações de guerra envolvendo explosão de armamento naval"
  },
  {
    "codigo": "Y361",
    "descricao": "Operações de guerra envolvendo destruição de aeronave"
  },
  {
    "codigo": "Y362",
    "descricao": "Operações de guerra envolvendo outras explosões ou fragmentos"
  },
  {
    "codigo": "Y363",
    "descricao": "Operações de guerra envolvendo incêndio, fogo e substâncias quentes"
  },
  {
    "codigo": "Y364",
    "descricao": "Operações de guerra envolvendo disparo de armas de fogo e de outras formas convencionais de combate"
  },
  {
    "codigo": "Y365",
    "descricao": "Operações de guerra envolvendo armamento nuclear"
  },
  {
    "codigo": "Y366",
    "descricao": "Operações de guerra envolvendo armas biológicas"
  },
  {
    "codigo": "Y367",
    "descricao": "Operações de guerra envolvendo armas químicas e outros meios de combate não-convencionais"
  },
  {
    "codigo": "Y368",
    "descricao": "Operações de guerra ocorridas após a cessação das hostilidades"
  },
  {
    "codigo": "Y369",
    "descricao": "Operações de guerra não especificadas"
  },
  { "codigo": "Y400", "descricao": "Efeitos adversos de penicilinas" },
  {
    "codigo": "Y401",
    "descricao": "Efeitos adversos de cefalosporinas e outras betalactaminas"
  },
  {
    "codigo": "Y402",
    "descricao": "Efeitos adversos do grupo do cloranfenicol"
  },
  { "codigo": "Y403", "descricao": "Efeitos adversos de macrolídeos" },
  { "codigo": "Y404", "descricao": "Efeitos adversos de tetraciclinas" },
  {
    "codigo": "Y405",
    "descricao": "Efeitos adversos de aminoglicosídeos"
  },
  { "codigo": "Y406", "descricao": "Efeitos adversos de rifamicinas" },
  {
    "codigo": "Y407",
    "descricao": "Efeitos adversos de antibióticos antifúngicos administrados por via sistêmica"
  },
  {
    "codigo": "Y408",
    "descricao": "Efeitos adversos de outros antibióticos administrados por via sistêmica"
  },
  {
    "codigo": "Y409",
    "descricao": "Efeitos adversos de antibiótico sistêmico, não especificado"
  },
  { "codigo": "Y410", "descricao": "Efeitos adversos de sulfonamidas" },
  {
    "codigo": "Y411",
    "descricao": "Efeitos adversos de drogas antimicobacterianas"
  },
  {
    "codigo": "Y412",
    "descricao": "Efeitos adversos de antimaláricos e drogas que atuam sobre outros protozoários parasitas do sangue"
  },
  {
    "codigo": "Y413",
    "descricao": "Efeitos adversos de outras drogas antiprotozoárias"
  },
  {
    "codigo": "Y414",
    "descricao": "Efeitos adversos de anti-helmínticos"
  },
  { "codigo": "Y415", "descricao": "Efeitos adversos de drogas antivirais" },
  {
    "codigo": "Y418",
    "descricao": "Efeitos adversos de outros antiinfecciosos e antiparasitários especificados"
  },
  {
    "codigo": "Y419",
    "descricao": "Efeitos adversos de antiinfecciosos e antiparasitários sistêmicos não especificados"
  },
  {
    "codigo": "Y420",
    "descricao": "Efeitos adversos de glicocorticóides e análogos sintéticos"
  },
  {
    "codigo": "Y421",
    "descricao": "Efeitos adversos de hormônios e substitutos da tireóide"
  },
  {
    "codigo": "Y422",
    "descricao": "Efeitos adversos de drogas antitireoidianas"
  },
  {
    "codigo": "Y423",
    "descricao": "Efeitos adversos de insulina e hipoglicêmicos orais (antidiabéticos)"
  },
  {
    "codigo": "Y424",
    "descricao": "Efeitos adversos de anticoncepcionais [contraceptivos] orais"
  },
  {
    "codigo": "Y425",
    "descricao": "Efeitos adversos de outros estrógenos e progestágenos"
  },
  {
    "codigo": "Y426",
    "descricao": "Efeitos adversos de antigonadotrofinas, antiestrógenos e antiandrógenos, não classificados em outra parte"
  },
  {
    "codigo": "Y427",
    "descricao": "Efeitos adversos de andrógenos e anabolizantes congêneres"
  },
  {
    "codigo": "Y428",
    "descricao": "Efeitos adversos de outros hormônios e os não especificados e seus substitutos sintéticos"
  },
  {
    "codigo": "Y429",
    "descricao": "Efeitos adversos de outros antagonistas hormonais e os não especificados"
  },
  {
    "codigo": "Y430",
    "descricao": "Efeitos adversos de drogas antialérgicas e antieméticas"
  },
  {
    "codigo": "Y431",
    "descricao": "Efeitos adversos de antimetabólicos antineoplásicos"
  },
  {
    "codigo": "Y432",
    "descricao": "Efeitos adversos de produtos naturais antineoplásicos"
  },
  {
    "codigo": "Y433",
    "descricao": "Efeitos adversos de outras drogas antineoplásicas"
  },
  {
    "codigo": "Y434",
    "descricao": "Efeitos adversos de agentes imunossupressores"
  },
  {
    "codigo": "Y435",
    "descricao": "Efeitos adversos de acidificantes e alcalinizantes"
  },
  {
    "codigo": "Y436",
    "descricao": "Efeitos adversos de enzimas, não classificadas em outra parte"
  },
  {
    "codigo": "Y438",
    "descricao": "Efeitos adversos de outras substâncias farmacológicas de ação primariamente sistêmica, não classificadas em outra parte"
  },
  {
    "codigo": "Y439",
    "descricao": "Efeitos adversos de substância farmacológica de ação sistêmica, não especificada"
  },
  {
    "codigo": "Y440",
    "descricao": "Efeitos adversos de preparações a base de ferro e outros preparações prescritas na anemia hipocrômica"
  },
  {
    "codigo": "Y441",
    "descricao": "Efeitos adversos da vitamina B12, ácido fólico e outros preparações prescritas nas anemias megaloblásticas"
  },
  { "codigo": "Y442", "descricao": "Efeitos adversos de anticoagulantes" },
  {
    "codigo": "Y443",
    "descricao": "Efeitos adversos de antagonistas de anticoagulantes, vitamina K e outros coagulantes"
  },
  {
    "codigo": "Y444",
    "descricao": "Efeitos adversos de drogas antitrombóticas [inibidores da agregação de plaquetas]"
  },
  { "codigo": "Y445", "descricao": "Efeitos adversos de trombolíticos" },
  {
    "codigo": "Y446",
    "descricao": "Efeitos adversos do sangue natural e produtos do sangue"
  },
  {
    "codigo": "Y447",
    "descricao": "Efeitos adversos de substitutos do plasma"
  },
  {
    "codigo": "Y449",
    "descricao": "Efeitos adversos de outras substâncias farmacológicas que atuam sobre os constituintes do sangue e as não especificadas"
  },
  {
    "codigo": "Y450",
    "descricao": "Efeitos adversos de opiáceos e analgésicos correlatos"
  },
  { "codigo": "Y451", "descricao": "Efeitos adversos de salicilatos" },
  {
    "codigo": "Y452",
    "descricao": "Efeitos adversos de derivados do ácido propiônico"
  },
  {
    "codigo": "Y453",
    "descricao": "Efeitos adversos de outras drogas antiinflamatórias não-esteróides"
  },
  { "codigo": "Y454", "descricao": "Efeitos adversos de anti-reumáticos" },
  {
    "codigo": "Y455",
    "descricao": "Efeitos adversos de derivados do 4-aminofenol"
  },
  {
    "codigo": "Y458",
    "descricao": "Efeitos adversos de outros analgésicos e antipiréticos"
  },
  {
    "codigo": "Y459",
    "descricao": "Efeitos adversos de substâncias analgésicas e antipiréticas não especificadas"
  },
  { "codigo": "Y460", "descricao": "Efeitos adversos de succimidas" },
  { "codigo": "Y461", "descricao": "Efeitos adversos de oxazolidínas" },
  {
    "codigo": "Y462",
    "descricao": "Efeitos adversos de derivados da hidantoína"
  },
  {
    "codigo": "Y463",
    "descricao": "Efeitos adversos de desoxibarbitúricos"
  },
  { "codigo": "Y464", "descricao": "Efeitos adversos de iminostilbenos" },
  {
    "codigo": "Y465",
    "descricao": "Efeitos adversos do ácido valpróico"
  },
  {
    "codigo": "Y466",
    "descricao": "Efeitos adversos de outros anticonvulsivantes (antiepilépticos) e os não especificados"
  },
  {
    "codigo": "Y467",
    "descricao": "Efeitos adversos de drogas antiparkinsonianas"
  },
  {
    "codigo": "Y468",
    "descricao": "Efeitos adversos de substâncias miorrelaxantes (anti-espásticas)"
  },
  {
    "codigo": "Y470",
    "descricao": "Efeitos adversos de barbitúricos, não classificados em outra parte"
  },
  {
    "codigo": "Y471",
    "descricao": "Efeitos adversos de benzodiazepínicos"
  },
  { "codigo": "Y472", "descricao": "Efeitos adversos de derivados do cloral" },
  { "codigo": "Y473", "descricao": "Efeitos adversos de paraldeído" },
  { "codigo": "Y474", "descricao": "Efeitos adversos de compostos de bromo" },
  {
    "codigo": "Y475",
    "descricao": "Efeitos adversos de sedativos e hipnóticos em associações não classificados em outra parte"
  },
  {
    "codigo": "Y478",
    "descricao": "Efeitos adversos de outros sedativos, hipnóticos e tranquilizantes [ansiolíticos]"
  },
  {
    "codigo": "Y479",
    "descricao": "Efeitos adversos de sedativos, hipnóticos e tranquilizantes [ansiolíticos], não especificados"
  },
  {
    "codigo": "Y480",
    "descricao": "Efeitos adversos de anestésicos por inalação"
  },
  {
    "codigo": "Y481",
    "descricao": "Efeitos adversos de anestésicos por via parenteral"
  },
  {
    "codigo": "Y482",
    "descricao": "Efeitos adversos de outros anestésicos gerais e os não especificados"
  },
  {
    "codigo": "Y483",
    "descricao": "Efeitos adversos de anestésicos locais"
  },
  {
    "codigo": "Y484",
    "descricao": "Efeitos adversos de anestésico, não especificado"
  },
  {
    "codigo": "Y485",
    "descricao": "Efeitos adversos de gases terapêuticos"
  },
  {
    "codigo": "Y490",
    "descricao": "Efeitos adversos de antidepressivos triciclos e tetracíclicos"
  },
  {
    "codigo": "Y491",
    "descricao": "Efeitos adversos de antidepressivos inibidores da mono-amino-oxidase"
  },
  {
    "codigo": "Y492",
    "descricao": "Efeitos adversos de outros antidepressivos e os não especificados"
  },
  {
    "codigo": "Y493",
    "descricao": "Efeitos adversos de antipsicóticos e neurolépticos fenotiazínicos"
  },
  {
    "codigo": "Y494",
    "descricao": "Efeitos adversos de neurolépticos a base da butirofenona e do tioxanteno"
  },
  {
    "codigo": "Y495",
    "descricao": "Efeitos adversos de outros antipsicóticos e neurolépticos"
  },
  {
    "codigo": "Y496",
    "descricao": "Efeitos adversos de psicodislépticos [alucinógenos]"
  },
  {
    "codigo": "Y497",
    "descricao": "Efeitos adversos de psicoestimulantes que podem provocar dependência"
  },
  {
    "codigo": "Y498",
    "descricao": "Efeitos adversos de outras substâncias psicotrópicas, não classificados em outra parte"
  },
  {
    "codigo": "Y499",
    "descricao": "Efeitos adversos de substância psicotrópica, não especificada"
  },
  { "codigo": "Y500", "descricao": "Efeitos adversos de analépticos" },
  {
    "codigo": "Y501",
    "descricao": "Efeitos adversos de antagonistas de receptores dos opiáceos"
  },
  {
    "codigo": "Y502",
    "descricao": "Efeitos adversos de metilxantinas, não classificadas em outra parte"
  },
  {
    "codigo": "Y508",
    "descricao": "Efeitos adversos de outros estimulantes do sistema nervoso central"
  },
  {
    "codigo": "Y509",
    "descricao": "Efeitos adversos de estimulante do sistema nervoso central, não especificado"
  },
  {
    "codigo": "Y510",
    "descricao": "Efeitos adversos de agentes anticolinesterásicos"
  },
  {
    "codigo": "Y511",
    "descricao": "Efeitos adversos de outros parasimpaticomiméticos [colinérgicos]"
  },
  {
    "codigo": "Y512",
    "descricao": "Efeitos adversos de ganglioplégicos, não classificados em outra parte"
  },
  {
    "codigo": "Y513",
    "descricao": "Efeitos adversos de outros parassimpaticolíticos [anticolinérgicos e antimuscarínicos] e espasmolíticos, não classificados em outra parte"
  },
  {
    "codigo": "Y514",
    "descricao": "Efeitos adversos de agonistas predominantemente alfa-adrenorreceptores, não classificados em outra parte"
  },
  {
    "codigo": "Y515",
    "descricao": "Efeitos adversos de agonistas predominantemente beta-adrenorreceptores, não classificados em outra parte"
  },
  {
    "codigo": "Y516",
    "descricao": "Efeitos adversos de antagonistas alfa-adrenorreceptores, não classificados em outra parte"
  },
  {
    "codigo": "Y517",
    "descricao": "Efeitos adversos de antagonistas beta-adrenorreceptores, não classificados em outra parte"
  },
  {
    "codigo": "Y518",
    "descricao": "Efeitos adversos de bloqueadores nervosos de ação central e adrenérgicos, não classificados em outra parte"
  },
  {
    "codigo": "Y519",
    "descricao": "Efeitos adversos de outras drogas que atuam primariamente sobre o sistema nervoso autônomo e as não especificadas"
  },
  {
    "codigo": "Y520",
    "descricao": "Efeitos adversos de glicosídeos estimulantes do coração e substâncias de ação semelhante"
  },
  {
    "codigo": "Y521",
    "descricao": "Efeitos adversos de antagonistas do cálcio [inibidores do cálcio]"
  },
  {
    "codigo": "Y522",
    "descricao": "Efeitos adversos de outras drogas antidisrrítmicas, não classificadas em outra parte"
  },
  {
    "codigo": "Y523",
    "descricao": "Efeitos adversos de vasodilatadores coronarianos, não classificados em outra parte"
  },
  {
    "codigo": "Y524",
    "descricao": "Efeitos adversos de inibidores da enzima de conversão da angiotensina"
  },
  {
    "codigo": "Y525",
    "descricao": "Efeitos adversos de outras drogas anti-hipertensivas, não classificadas em outra parte"
  },
  {
    "codigo": "Y526",
    "descricao": "Efeitos adversos de drogas anti-hiperlipidêmicas e antiarterioscleróticas"
  },
  {
    "codigo": "Y527",
    "descricao": "Efeitos adversos de vasodilatadores periféricos"
  },
  {
    "codigo": "Y528",
    "descricao": "Efeitos adversos de drogas antivaricosas, incluindo os agentes esclerosantes"
  },
  {
    "codigo": "Y529",
    "descricao": "Efeitos adversos de outras drogas que atuam primariamente sobre o aparelho cardiovascular e as não especificadas"
  },
  {
    "codigo": "Y530",
    "descricao": "Efeitos adversos de antagonistas dos receptores H2 da histamina"
  },
  {
    "codigo": "Y531",
    "descricao": "Efeitos adversos de outros medicamentos antiácidos e anti-secreção gástrica"
  },
  {
    "codigo": "Y532",
    "descricao": "Efeitos adversos de laxativos estimulantes"
  },
  {
    "codigo": "Y533",
    "descricao": "Efeitos adversos de laxativos salinos e osmóticos"
  },
  { "codigo": "Y534", "descricao": "Efeitos adversos de outros laxativos" },
  {
    "codigo": "Y535",
    "descricao": "Efeitos adversos de medicamentos que facilitam a digestão"
  },
  { "codigo": "Y536", "descricao": "Efeitos adversos de antidiarréicos" },
  { "codigo": "Y537", "descricao": "Efeitos adversos de eméticos" },
  {
    "codigo": "Y538",
    "descricao": "Efeitos adversos de outras substâncias que atuam primariamente sobre o aparelho gastrointestinal"
  },
  {
    "codigo": "Y539",
    "descricao": "Efeitos adversos de substâncias que atuam primariamente sobre o aparelho gastrointestinal, não especificadas"
  },
  {
    "codigo": "Y540",
    "descricao": "Efeitos adversos de mineralocorticóides"
  },
  {
    "codigo": "Y541",
    "descricao": "Efeitos adversos de antagonistas dos mineralocorticóides [antagonistas da aldosterona]"
  },
  {
    "codigo": "Y542",
    "descricao": "Efeitos adversos de inibidores da anidrase carbônica"
  },
  {
    "codigo": "Y543",
    "descricao": "Efeitos adversos de derivados da benzotiadiazína"
  },
  {
    "codigo": "Y544",
    "descricao": "Efeitos adversos de diuréticos de alça"
  },
  {
    "codigo": "Y545",
    "descricao": "Efeitos adversos de outros diuréticos"
  },
  {
    "codigo": "Y546",
    "descricao": "Efeitos adversos de agentes que atuam sobre o equilíbrio eletrolítico, calórico e hídrico"
  },
  {
    "codigo": "Y547",
    "descricao": "Efeitos adversos de medicamentos que atuam sobre o metabolismo de cálcio"
  },
  {
    "codigo": "Y548",
    "descricao": "Efeitos adversos de drogas que atuam sobre o metabolismo de ácido úrico"
  },
  {
    "codigo": "Y549",
    "descricao": "Efeitos adversos de sais minerais não classificados em outra parte"
  },
  { "codigo": "Y550", "descricao": "Efeitos adversos de ocitócicos" },
  {
    "codigo": "Y551",
    "descricao": "Efeitos adversos de relaxantes da musculatura esquelética [bloqueadores neuromusculares] [curarizantes]"
  },
  {
    "codigo": "Y552",
    "descricao": "Efeitos adversos de outras substâncias que atuam primariamente sobre os músculos e as não especificadas"
  },
  { "codigo": "Y553", "descricao": "Efeitos adversos de antitússicos" },
  { "codigo": "Y554", "descricao": "Efeitos adversos de expectorantes" },
  {
    "codigo": "Y555",
    "descricao": "Efeitos adversos de drogas anti-resfriado comum"
  },
  {
    "codigo": "Y556",
    "descricao": "Efeitos adversos de antiasmáticos, não classificados em outra parte"
  },
  {
    "codigo": "Y559",
    "descricao": "Efeitos adversos de outras drogas que atuam sobre o aparelho respiratório e as não especificadas"
  },
  {
    "codigo": "Y560",
    "descricao": "Efeitos adversos de drogas antifúngicas, antiinfecciosas e antiinflamatórias de uso local, não classificadas em outra parte"
  },
  { "codigo": "Y561", "descricao": "Efeitos adversos de antipruriginosos" },
  {
    "codigo": "Y562",
    "descricao": "Efeitos adversos de adstringentes e detergentes locais"
  },
  {
    "codigo": "Y563",
    "descricao": "Efeitos adversos de emolientes, suavizantes e protetores da pele"
  },
  {
    "codigo": "Y564",
    "descricao": "Efeitos adversos de ceratolíticos, ceratoplásticos e outros medicamentos e preparações capilares"
  },
  {
    "codigo": "Y565",
    "descricao": "Efeitos adversos de medicamentos e preparações de uso oftalmológico"
  },
  {
    "codigo": "Y566",
    "descricao": "Efeitos adversos de medicamentos e preparações usados em otorrinolaringologia"
  },
  {
    "codigo": "Y567",
    "descricao": "Efeitos adversos de drogas de uso dentário aplicadas topicamente"
  },
  {
    "codigo": "Y568",
    "descricao": "Efeitos adversos de outras substâncias de uso tópico"
  },
  {
    "codigo": "Y569",
    "descricao": "Efeitos adversos de substância não especificada de uso tópico"
  },
  {
    "codigo": "Y570",
    "descricao": "Efeitos adversos de depressores do apetite [anoréxicos]"
  },
  { "codigo": "Y571", "descricao": "Efeitos adversos de lipotrópicos" },
  {
    "codigo": "Y572",
    "descricao": "Efeitos adversos de antídotos e agentes quelantes, não classificados em outra parte"
  },
  {
    "codigo": "Y573",
    "descricao": "Efeitos adversos de substâncias para restringir a ingestão de álcool"
  },
  {
    "codigo": "Y574",
    "descricao": "Efeitos adversos de excipientes farmacêuticos"
  },
  {
    "codigo": "Y575",
    "descricao": "Efeitos adversos de substâncias utilizadas como contraste em radiologia"
  },
  {
    "codigo": "Y576",
    "descricao": "Efeitos adversos de outras substâncias para fins diagnósticos"
  },
  {
    "codigo": "Y577",
    "descricao": "Efeitos adversos de vitaminas, não classificadas em outra parte"
  },
  {
    "codigo": "Y578",
    "descricao": "Efeitos adversos de outras drogas e medicamentos"
  },
  {
    "codigo": "Y579",
    "descricao": "Efeitos adversos de droga e medicamento não especificado"
  },
  { "codigo": "Y580", "descricao": "Efeitos adversos da vacina BCG" },
  {
    "codigo": "Y581",
    "descricao": "Efeitos adversos de vacinas antitifoídica e antiparafoídica"
  },
  {
    "codigo": "Y582",
    "descricao": "Efeitos adversos da vacina anticolérica"
  },
  { "codigo": "Y583", "descricao": "Efeitos adversos da vacina antipeste" },
  {
    "codigo": "Y584",
    "descricao": "Efeitos adversos da vacina antitetânica"
  },
  {
    "codigo": "Y585",
    "descricao": "Efeitos adversos da vacina antidiftérica"
  },
  {
    "codigo": "Y586",
    "descricao": "Efeitos adversos da vacina antipertussis, incluindo as combinações em que um dos componentes é a antipertussis"
  },
  {
    "codigo": "Y588",
    "descricao": "Efeitos adversos de vacinas bacterianas mistas, exceto as combinações em que um dos componentes é a antipertussis"
  },
  {
    "codigo": "Y589",
    "descricao": "Efeitos adversos de outras vacinas bacterianas e as não especificadas"
  },
  { "codigo": "Y590", "descricao": "Efeitos adversos de vacinas antivirais" },
  {
    "codigo": "Y591",
    "descricao": "Efeitos adversos de vacinas anti-rickettsias"
  },
  {
    "codigo": "Y592",
    "descricao": "Efeitos adversos de vacinas antiprotozoários"
  },
  { "codigo": "Y593", "descricao": "Efeitos adversos da imunoglobulina" },
  {
    "codigo": "Y598",
    "descricao": "Efeitos adversos de outras vacinas e substâncias biológicas especificadas"
  },
  {
    "codigo": "Y599",
    "descricao": "Efeitos adversos de vacina ou substância biológica, não especificada"
  },
  {
    "codigo": "Y600",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante intervenção cirúrgica"
  },
  {
    "codigo": "Y601",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante infusão ou transfusão"
  },
  {
    "codigo": "Y602",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante hemodiálise ou outras perfusões"
  },
  {
    "codigo": "Y603",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante injeção ou vacinação (imunização)"
  },
  {
    "codigo": "Y604",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante exame endoscópico"
  },
  {
    "codigo": "Y605",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante cateterismo cardíaco"
  },
  {
    "codigo": "Y606",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante aspiração, punção ou outro tipo de cateterização"
  },
  {
    "codigo": "Y607",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante a administração de enema"
  },
  {
    "codigo": "Y608",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante a prestação de outros cuidados cirúrgicos e médicos"
  },
  {
    "codigo": "Y609",
    "descricao": "Corte, punção, perfuração ou hemorragia acidentais durante a prestação de cuidado cirúrgico e médico, não especificados"
  },
  {
    "codigo": "Y610",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante intervenção cirúrgica"
  },
  {
    "codigo": "Y611",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante infusão ou transfusão"
  },
  {
    "codigo": "Y612",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante hemodiálise ou outras perfusões"
  },
  {
    "codigo": "Y613",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante injeção ou vacinação (imunização)"
  },
  {
    "codigo": "Y614",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante exame endoscópico"
  },
  {
    "codigo": "Y615",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante cateterismo cardíaco"
  },
  {
    "codigo": "Y616",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante aspiração, punção ou outro tipo de cateterização"
  },
  {
    "codigo": "Y617",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante a extração de um cateter ou de compressas"
  },
  {
    "codigo": "Y618",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante a prestação de outro cuidado cirúrgico e médico"
  },
  {
    "codigo": "Y619",
    "descricao": "Objeto estranho deixado acidentalmente no corpo durante a prestação de cuidado cirúrgico e médico não especificados"
  },
  {
    "codigo": "Y620",
    "descricao": "Assepsia insuficiente durante intervenção cirúrgica"
  },
  {
    "codigo": "Y621",
    "descricao": "Assepsia insuficiente durante infusão ou transfusão"
  },
  {
    "codigo": "Y622",
    "descricao": "Assepsia insuficiente durante hemodiálise ou outras perfusões"
  },
  {
    "codigo": "Y623",
    "descricao": "Assepsia insuficiente durante injeção ou vacinação (imunização)"
  },
  {
    "codigo": "Y624",
    "descricao": "Assepsia insuficiente durante exame endoscópico"
  },
  {
    "codigo": "Y625",
    "descricao": "Assepsia insuficiente durante cateterismo cardíaco"
  },
  {
    "codigo": "Y626",
    "descricao": "Assepsia insuficiente durante aspiração, punção ou outro tipo de cateterização"
  },
  {
    "codigo": "Y628",
    "descricao": "Assepsia insuficiente durante a prestação de outro cuidado cirúrgico e médico"
  },
  {
    "codigo": "Y629",
    "descricao": "Assepsia insuficiente durante a prestação de cuidado cirúrgico e médico não especificados"
  },
  {
    "codigo": "Y630",
    "descricao": "Administração de quantidade excessiva de sangue ou de um outro líquido durante transfusão ou infusão"
  },
  {
    "codigo": "Y631",
    "descricao": "Diluição incorreta de líquido usado durante a infusão"
  },
  {
    "codigo": "Y632",
    "descricao": "Superdosagem de radiação administrada durante a terapia"
  },
  {
    "codigo": "Y633",
    "descricao": "Exposição inadvertida de paciente a radiação durante a prestação de cuidado médico"
  },
  {
    "codigo": "Y634",
    "descricao": "Erro de dosagem na terapia por eletrochoque ou choque insulínico"
  },
  {
    "codigo": "Y635",
    "descricao": "Temperatura inadequada durante aplicação local ou curativo"
  },
  {
    "codigo": "Y636",
    "descricao": "Não administração de droga, medicamento ou substância biológica necessária"
  },
  {
    "codigo": "Y638",
    "descricao": "Erros de dosagem durante a prestação de outro cuidado médico e cirúrgico"
  },
  {
    "codigo": "Y639",
    "descricao": "Erros de dosagem durante a prestação de cuidados médico e cirúrgico, não especificados"
  },
  {
    "codigo": "Y640",
    "descricao": "Transfusão ou infusão de medicamento ou substância biológica contaminados"
  },
  {
    "codigo": "Y641",
    "descricao": "Injeção ou vacinação com medicamento ou substância biológica contaminados"
  },
  {
    "codigo": "Y648",
    "descricao": "Administração por outros meios de medicamento ou substância biológicas contaminados"
  },
  {
    "codigo": "Y649",
    "descricao": "Medicamento e substância biológica contaminados, administrada por meios não especificados"
  },
  {
    "codigo": "Y650",
    "descricao": "Uso de sangue incompatível em transfusão"
  },
  {
    "codigo": "Y651",
    "descricao": "Uso de líquido errado em infusão"
  },
  {
    "codigo": "Y652",
    "descricao": "Falha de sutura ou de ligadura durante intervenção cirúrgica"
  },
  {
    "codigo": "Y653",
    "descricao": "Cânula endotraqueal mal posicionada durante procedimento anestésico"
  },
  {
    "codigo": "Y654",
    "descricao": "Falha na introdução ou na remoção de outras cânulas ou instrumentos"
  },
  {
    "codigo": "Y655",
    "descricao": "Realização de uma operação inapropriada"
  },
  {
    "codigo": "Y658",
    "descricao": "Outros acidentes especificados durante a prestação de cuidado médico e cirúrgico"
  },
  {
    "codigo": "Y66",
    "descricao": "Não administração de cuidado médico e cirúrgico"
  },
  {
    "codigo": "Y69",
    "descricao": "Acidente não especificado durante a prestação de cuidado médico e cirúrgico"
  },
  {
    "codigo": "Y700",
    "descricao": "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y701",
    "descricao": "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y702",
    "descricao": "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y703",
    "descricao": "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y708",
    "descricao": "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y710",
    "descricao": "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y711",
    "descricao": "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y712",
    "descricao": "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y713",
    "descricao": "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y718",
    "descricao": "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y720",
    "descricao": "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y721",
    "descricao": "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y722",
    "descricao": "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y723",
    "descricao": "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y728",
    "descricao": "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y730",
    "descricao": "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y731",
    "descricao": "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y732",
    "descricao": "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y733",
    "descricao": "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y738",
    "descricao": "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y740",
    "descricao": "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y741",
    "descricao": "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y742",
    "descricao": "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y743",
    "descricao": "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y748",
    "descricao": "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y750",
    "descricao": "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y751",
    "descricao": "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y752",
    "descricao": "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y753",
    "descricao": "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y758",
    "descricao": "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y760",
    "descricao": "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y761",
    "descricao": "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y762",
    "descricao": "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y763",
    "descricao": "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y768",
    "descricao": "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y770",
    "descricao": "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y771",
    "descricao": "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y772",
    "descricao": "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y773",
    "descricao": "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y778",
    "descricao": "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y780",
    "descricao": "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y781",
    "descricao": "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y782",
    "descricao": "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y783",
    "descricao": "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y788",
    "descricao": "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y790",
    "descricao": "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y791",
    "descricao": "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y792",
    "descricao": "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y793",
    "descricao": "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y798",
    "descricao": "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y800",
    "descricao": "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y801",
    "descricao": "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y802",
    "descricao": "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y803",
    "descricao": "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y808",
    "descricao": "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y810",
    "descricao": "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y811",
    "descricao": "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y812",
    "descricao": "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y813",
    "descricao": "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y818",
    "descricao": "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y820",
    "descricao": "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - dispositivos (aparelhos) para fins diagnósticos ou de monitorização"
  },
  {
    "codigo": "Y821",
    "descricao": "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação"
  },
  {
    "codigo": "Y822",
    "descricao": "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - próteses e outros implantes, incluindo materiais e acessórios"
  },
  {
    "codigo": "Y823",
    "descricao": "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)"
  },
  {
    "codigo": "Y828",
    "descricao": "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte"
  },
  {
    "codigo": "Y830",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com transplante de todo o órgão, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y831",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com implante de uma prótese interna, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y832",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com anastomose, derivação (bypass) ou enxerto, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y833",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com formação de estoma externo, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y834",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por outras cirurgias reparadoras, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y835",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por amputação de membro(s), sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y836",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por remoção de um outro órgão (parcial) (total), sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y838",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por outras intervenções ou procedimentos cirúrgicos, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y839",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica, não especificada, sem menção de acidente durante a intervenção"
  },
  {
    "codigo": "Y840",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por cateterismo cardíaco, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y841",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por hemodiálise, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y842",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por procedimento radiológico e radioterapia, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y843",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por terapia por choque, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y844",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por aspiração de líquido, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y845",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por colocação de sonda gástrica ou duodenal, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y846",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por cateterismo urinário, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y847",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por amostra de sangue, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y848",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por outros procedimentos médicos, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y849",
    "descricao": "Reação anormal em paciente ou complicação tardia, causadas por procedimento médico, não especificado, sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y850",
    "descricao": "Seqüelas de um acidente de veículo a motor"
  },
  {
    "codigo": "Y859",
    "descricao": "Seqüelas de outros acidentes de transporte e dos não especificados"
  },
  { "codigo": "Y86", "descricao": "Seqüelas de outros acidentes" },
  {
    "codigo": "Y870",
    "descricao": "Seqüelas de lesões autoprovocadas intencionalmente"
  },
  { "codigo": "Y871", "descricao": "Seqüelas de uma agressão" },
  {
    "codigo": "Y872",
    "descricao": "Seqüelas de um fato cuja intenção é indeterminada"
  },
  {
    "codigo": "Y880",
    "descricao": "Seqüelas de efeitos adversos causados por drogas, medicamentos ou substâncias biológicas usados com finalidade terapêutica"
  },
  {
    "codigo": "Y881",
    "descricao": "Seqüelas de acidentes durante a prestação de cuidado médico e cirúrgico"
  },
  {
    "codigo": "Y882",
    "descricao": "Seqüelas de incidentes adversos associados com o uso de dispositivos (aparelhos) médicos durante atos diagnósticos ou terapêuticos"
  },
  {
    "codigo": "Y883",
    "descricao": "Seqüelas de reação anormal em paciente ou complicação tardia causada por procedimento médico e cirúrgico sem menção de acidente durante o procedimento"
  },
  {
    "codigo": "Y890",
    "descricao": "Seqüelas de intervenção legal"
  },
  {
    "codigo": "Y891",
    "descricao": "Seqüelas de operações de guerra"
  },
  {
    "codigo": "Y899",
    "descricao": "Seqüelas de causa externa não especificada"
  },
  { "codigo": "Y900", "descricao": "Alcoolemia inferior a 20 mg/100 ml" },
  { "codigo": "Y901", "descricao": "Alcoolemia de 20-39 mg/100ml" },
  { "codigo": "Y902", "descricao": "Alcoolemia de 40-59 mg/100ml" },
  { "codigo": "Y903", "descricao": "Alcoolemia de 60-79 mg/100ml" },
  { "codigo": "Y904", "descricao": "Alcoolemia de 80-99 mg/100ml" },
  { "codigo": "Y905", "descricao": "Alcoolemia de 100-119 mg/100ml" },
  { "codigo": "Y906", "descricao": "Alcoolemia de 120-199 mg/100ml" },
  { "codigo": "Y907", "descricao": "Alcoolemia de 200-239 mg/100ml" },
  {
    "codigo": "Y908",
    "descricao": "Alcoolemia igual ou superior a 240 mg/100ml"
  },
  {
    "codigo": "Y909",
    "descricao": "Presença de álcool no sangue, taxa não especificada"
  },
  {
    "codigo": "Y910",
    "descricao": "Intoxicação alcoólica leve"
  },
  {
    "codigo": "Y911",
    "descricao": "Intoxicação alcoólica moderada"
  },
  {
    "codigo": "Y912",
    "descricao": "Intoxicação alcoólica grave"
  },
  {
    "codigo": "Y913",
    "descricao": "Intoxicação alcoólica muito grave"
  },
  {
    "codigo": "Y919",
    "descricao": "Envolvimento com álcool não especificado de outra forma"
  },
  {
    "codigo": "Y95",
    "descricao": "Circunstância relativa as condições nosocomiais (hospitalares)"
  },
  {
    "codigo": "Y96",
    "descricao": "Circunstância relativa às condições de trabalho"
  },
  {
    "codigo": "Y97",
    "descricao": "Circunstâncias relativas a condições de poluição ambiental"
  },
  {
    "codigo": "Y98",
    "descricao": "Circunstâncias relativas a condições do modo de vida"
  },
  { "codigo": "Z000", "descricao": "Exame médico geral" },
  {
    "codigo": "Z001",
    "descricao": "Exame de rotina de saúde da criança"
  },
  {
    "codigo": "Z002",
    "descricao": "Exame no período de crescimento rápido na infância"
  },
  {
    "codigo": "Z003",
    "descricao": "Exame do adolescente durante o crescimento na puberdade"
  },
  {
    "codigo": "Z004",
    "descricao": "Exame psiquiátrico geral não classificado em outra parte"
  },
  {
    "codigo": "Z005",
    "descricao": "Exame de doador potencial de órgão e tecido"
  },
  {
    "codigo": "Z006",
    "descricao": "Exame para comparação ou de controle de normalidade num programa de investigação clínica"
  },
  { "codigo": "Z008", "descricao": "Outros exames gerais" },
  { "codigo": "Z010", "descricao": "Exame dos olhos e da visão" },
  { "codigo": "Z011", "descricao": "Exame dos ouvidos e da audição" },
  { "codigo": "Z012", "descricao": "Exame dentário" },
  { "codigo": "Z013", "descricao": "Exame da pressão arterial" },
  {
    "codigo": "Z014",
    "descricao": "Exame ginecológico (geral) (de rotina)"
  },
  {
    "codigo": "Z015",
    "descricao": "Testes cutâneos de diagnóstico e sensibilização"
  },
  {
    "codigo": "Z016",
    "descricao": "Exame radiológico não classificado em outra parte"
  },
  { "codigo": "Z017", "descricao": "Exame de laboratório" },
  { "codigo": "Z018", "descricao": "Outros exames especiais especificados" },
  { "codigo": "Z019", "descricao": "Exame especial não especificado" },
  {
    "codigo": "Z020",
    "descricao": "Exame para admissão a instituição educacional"
  },
  {
    "codigo": "Z021",
    "descricao": "Exame pré-emprego (pré-admissional)"
  },
  {
    "codigo": "Z022",
    "descricao": "Exame para admissão em instituição residencial"
  },
  {
    "codigo": "Z023",
    "descricao": "Exame para a incorporação nas forças armadas"
  },
  {
    "codigo": "Z024",
    "descricao": "Exame para licença para a condução de veículos (carteira de motorista)"
  },
  {
    "codigo": "Z025",
    "descricao": "Exame para participação em esporte"
  },
  { "codigo": "Z026", "descricao": "Exame para fins de seguro" },
  {
    "codigo": "Z027",
    "descricao": "Obtenção de atestado médico"
  },
  {
    "codigo": "Z028",
    "descricao": "Outros exames para propósitos administrativos"
  },
  {
    "codigo": "Z029",
    "descricao": "Exame não especificado com finalidades administrativas"
  },
  {
    "codigo": "Z030",
    "descricao": "Observação por suspeita de tuberculose"
  },
  {
    "codigo": "Z031",
    "descricao": "Observação por suspeita de neoplasia maligna"
  },
  {
    "codigo": "Z032",
    "descricao": "Observação por suspeita de transtornos mentais e do comportamento"
  },
  {
    "codigo": "Z033",
    "descricao": "Observação por suspeita de transtorno do sistema nervoso"
  },
  {
    "codigo": "Z034",
    "descricao": "Observação por suspeita de infarto do miocárdio"
  },
  {
    "codigo": "Z035",
    "descricao": "Observação por suspeita de outras doenças cardiovasculares"
  },
  {
    "codigo": "Z036",
    "descricao": "Observação por suspeita de efeito tóxico de substância ingerida"
  },
  {
    "codigo": "Z038",
    "descricao": "Observação por suspeita de outras doenças e afecções"
  },
  {
    "codigo": "Z039",
    "descricao": "Observação por suspeita de doença ou afecção não especificada"
  },
  {
    "codigo": "Z040",
    "descricao": "Teste para álcool e para drogas no sangue"
  },
  {
    "codigo": "Z041",
    "descricao": "Exame e observação após acidente de transporte"
  },
  {
    "codigo": "Z042",
    "descricao": "Exame e observação após acidente de trabalho"
  },
  {
    "codigo": "Z043",
    "descricao": "Exame e observação após outro acidente"
  },
  {
    "codigo": "Z044",
    "descricao": "Exame e observação após alegação de estupro e sedução"
  },
  {
    "codigo": "Z045",
    "descricao": "Exame e observação após outros ferimentos infligidos"
  },
  {
    "codigo": "Z046",
    "descricao": "Exame psiquiátrico geral, requerido por autoridade"
  },
  {
    "codigo": "Z048",
    "descricao": "Exame e observação por outras razões especificadas"
  },
  {
    "codigo": "Z049",
    "descricao": "Exame e observação por razão não especificada"
  },
  {
    "codigo": "Z080",
    "descricao": "Exame de seguimento após tratamento cirúrgico de neoplasia maligna"
  },
  {
    "codigo": "Z081",
    "descricao": "Exame de seguimento após radioterapia por neoplasia maligna"
  },
  {
    "codigo": "Z082",
    "descricao": "Exame de seguimento após quimioterapia por neoplasia maligna"
  },
  {
    "codigo": "Z087",
    "descricao": "Exame de seguimento após tratamento misto por neoplasia maligna"
  },
  {
    "codigo": "Z088",
    "descricao": "Exame de seguimento após outro tratamento por neoplasia maligna"
  },
  {
    "codigo": "Z089",
    "descricao": "Exame de seguimento após tratamento não especificado por neoplasia maligna"
  },
  {
    "codigo": "Z090",
    "descricao": "Exame de seguimento após cirurgia por outras afecções"
  },
  {
    "codigo": "Z091",
    "descricao": "Exame de seguimento após radioterapia por outras afecções"
  },
  {
    "codigo": "Z092",
    "descricao": "Exame de seguimento após quimioterapia por outras afecções"
  },
  {
    "codigo": "Z093",
    "descricao": "Exame de seguimento após psicoterapia"
  },
  {
    "codigo": "Z094",
    "descricao": "Exame de seguimento após tratamento de fratura"
  },
  {
    "codigo": "Z097",
    "descricao": "Exame de seguimento após tratamento combinado por outras afecções"
  },
  {
    "codigo": "Z098",
    "descricao": "Exame de seguimento após outro tratamento por outras afecções"
  },
  {
    "codigo": "Z099",
    "descricao": "Exame de seguimento após tratamento não especificado por outras afecções"
  },
  { "codigo": "Z100", "descricao": "Exame de saúde ocupacional" },
  {
    "codigo": "Z101",
    "descricao": "Exame geral de rotina de residentes de instituições"
  },
  {
    "codigo": "Z102",
    "descricao": "Exame geral de rotina de pessoas das forças armadas"
  },
  {
    "codigo": "Z103",
    "descricao": "Exame geral de rotina de equipe esportiva"
  },
  {
    "codigo": "Z108",
    "descricao": "Exame geral de rotina de outra subpopulação definida"
  },
  {
    "codigo": "Z110",
    "descricao": "Exame especial de rastreamento de doenças infecciosas intestinais"
  },
  {
    "codigo": "Z111",
    "descricao": "Exame especial de rastreamento de tuberculose pulmonar"
  },
  {
    "codigo": "Z112",
    "descricao": "Exame especial de rastreamento de outras doenças bacterianas"
  },
  {
    "codigo": "Z113",
    "descricao": "Exame especial de rastreamento de infecções de transmissão predominantemente sexual"
  },
  {
    "codigo": "Z114",
    "descricao": "Exame especial de rastreamento de vírus da imunodeficiência humana [HIV]"
  },
  {
    "codigo": "Z115",
    "descricao": "Exame especial de rastreamento de outras doenças virais"
  },
  {
    "codigo": "Z116",
    "descricao": "Exame especial de rastreamento de outras doenças por protozoários e por helmintos"
  },
  {
    "codigo": "Z118",
    "descricao": "Exame especial de rastreamento de outras doenças infecciosas e parasitárias"
  },
  {
    "codigo": "Z119",
    "descricao": "Exame especial de rastreamento para doença infecciosa e parasitária não especificada"
  },
  {
    "codigo": "Z120",
    "descricao": "Exame especial de rastreamento de neoplasia do estômago"
  },
  {
    "codigo": "Z121",
    "descricao": "Exame especial de rastreamento de neoplasia do trato intestinal"
  },
  {
    "codigo": "Z122",
    "descricao": "Exame especial de rastreamento de neoplasia de órgãos respiratórios"
  },
  {
    "codigo": "Z123",
    "descricao": "Exame especial de rastreamento de neoplasia de mama"
  },
  {
    "codigo": "Z124",
    "descricao": "Exame especial de rastreamento de neoplasia do colo do útero"
  },
  {
    "codigo": "Z125",
    "descricao": "Exame especial de rastreamento de neoplasia da próstata"
  },
  {
    "codigo": "Z126",
    "descricao": "Exame especial de rastreamento de neoplasia da bexiga"
  },
  {
    "codigo": "Z128",
    "descricao": "Exame especial de rastreamento de neoplasias de outras localizações"
  },
  {
    "codigo": "Z129",
    "descricao": "Exame especial de rastreamento de neoplasia não especificada"
  },
  {
    "codigo": "Z130",
    "descricao": "Exame especial de rastreamento de doenças do sangue e dos órgãos hematopoéticos de alguns transtornos imunitários"
  },
  {
    "codigo": "Z131",
    "descricao": "Exame especial de rastreamento de diabetes mellitus"
  },
  {
    "codigo": "Z132",
    "descricao": "Exame especial de rastreamento de transtornos nutricionais"
  },
  {
    "codigo": "Z133",
    "descricao": "Exame especial de rastreamento de transtornos mentais e do comportamento"
  },
  {
    "codigo": "Z134",
    "descricao": "Exame especial de rastreamento de alguns transtornos do desenvolvimento na infância"
  },
  {
    "codigo": "Z135",
    "descricao": "Exame especial de rastreamento de doenças dos ouvidos e dos olhos"
  },
  {
    "codigo": "Z136",
    "descricao": "Exame especial de rastreamento de doenças cardiovasculares"
  },
  {
    "codigo": "Z137",
    "descricao": "Exame especial de rastreamento de malformações congênitas, deformidades e anomalias cromossômicas"
  },
  {
    "codigo": "Z138",
    "descricao": "Exame especial de rastreamento de outras doenças e transtornos especificados"
  },
  {
    "codigo": "Z139",
    "descricao": "Exame especial de rastreamento não especificado"
  },
  {
    "codigo": "Z200",
    "descricao": "Contato com e exposição a doenças infecciosas intestinais"
  },
  {
    "codigo": "Z201",
    "descricao": "Contato com e exposição à tuberculose"
  },
  {
    "codigo": "Z202",
    "descricao": "Contato com e exposição a infecções de transmissão predominantemente sexual"
  },
  {
    "codigo": "Z203",
    "descricao": "Contato com e exposição à raiva"
  },
  {
    "codigo": "Z204",
    "descricao": "Contato com e exposição à rubéola"
  },
  {
    "codigo": "Z205",
    "descricao": "Contato com e exposição à hepatite viral"
  },
  {
    "codigo": "Z206",
    "descricao": "Contato com e exposição ao vírus da imunodeficiência humana [HIV]"
  },
  {
    "codigo": "Z207",
    "descricao": "Contato com e exposição à pediculose, acaríase e outras infestações"
  },
  {
    "codigo": "Z208",
    "descricao": "Contato com e exposição a outras doenças transmissíveis"
  },
  {
    "codigo": "Z209",
    "descricao": "Contato com e exposição a doença transmissível não especificada"
  },
  {
    "codigo": "Z21",
    "descricao": "Estado de infecção assintomática pelo vírus da imunodeficiência humana [HIV]"
  },
  { "codigo": "Z220", "descricao": "Portador de febre tifóide" },
  {
    "codigo": "Z221",
    "descricao": "Portador de outras doenças infecciosas intestinais"
  },
  { "codigo": "Z222", "descricao": "Portador de difteria" },
  {
    "codigo": "Z223",
    "descricao": "Portador de outras doenças bacterianas especificadas"
  },
  {
    "codigo": "Z224",
    "descricao": "Portador de infecções com modo de transmissão predominantemente sexual"
  },
  { "codigo": "Z225", "descricao": "Portador de hepatite viral" },
  {
    "codigo": "Z226",
    "descricao": "Portador de infecção pelo vírus T-linfotrópico tipo 1 [HTLV-1]"
  },
  {
    "codigo": "Z228",
    "descricao": "Portador de outras doenças infecciosas"
  },
  {
    "codigo": "Z229",
    "descricao": "Portador de doença infecciosa não especificada"
  },
  {
    "codigo": "Z230",
    "descricao": "Necessidade de imunização somente contra cólera"
  },
  {
    "codigo": "Z231",
    "descricao": "Necessidade de imunização somente contra febre tifóide e paratifóide [TAB]"
  },
  {
    "codigo": "Z232",
    "descricao": "Necessidade de imunização contra tuberculose [BCG]"
  },
  {
    "codigo": "Z233",
    "descricao": "Necessidade de imunização contra a peste"
  },
  {
    "codigo": "Z234",
    "descricao": "Necessidade de imunização contra a tularemia"
  },
  {
    "codigo": "Z235",
    "descricao": "Necessidade de imunização somente contra o tétano"
  },
  {
    "codigo": "Z236",
    "descricao": "Necessidade de imunização somente contra a difteria"
  },
  {
    "codigo": "Z237",
    "descricao": "Necessidade de imunização somente contra a coqueluche"
  },
  {
    "codigo": "Z238",
    "descricao": "Vacinação contra outras doenças bacterianas únicas"
  },
  {
    "codigo": "Z240",
    "descricao": "Necessidade de imunização contra a poliomielite"
  },
  {
    "codigo": "Z241",
    "descricao": "Necessidade de imunização contra a encefalite viral transmitida por artrópodes"
  },
  {
    "codigo": "Z242",
    "descricao": "Necessidade de imunização contra a raiva"
  },
  {
    "codigo": "Z243",
    "descricao": "Necessidade de imunização contra a febre amarela"
  },
  {
    "codigo": "Z244",
    "descricao": "Necessidade de imunização somente contra o sarampo"
  },
  {
    "codigo": "Z245",
    "descricao": "Necessidade de imunização somente contra a rubéola"
  },
  {
    "codigo": "Z246",
    "descricao": "Necessidade de imunização somente contra a hepatite viral"
  },
  {
    "codigo": "Z250",
    "descricao": "Necessidade de imunização somente contra a caxumba [parotidite epidêmica]"
  },
  {
    "codigo": "Z251",
    "descricao": "Necessidade de imunização somente contra a influenza [gripe]"
  },
  {
    "codigo": "Z258",
    "descricao": "Necessidade de imunização contra outras doenças virais únicas especificadas"
  },
  {
    "codigo": "Z260",
    "descricao": "Necessidade de imunização contra a leishmaniose"
  },
  {
    "codigo": "Z268",
    "descricao": "Necessidade de imunização contra outras doenças infecciosas especificadas únicas"
  },
  {
    "codigo": "Z269",
    "descricao": "Necessidade de imunização contra doença infecciosa não especificada"
  },
  {
    "codigo": "Z270",
    "descricao": "Necessidade de imunização contra a cólera e as febres tifóide e paratifóides [Cólera + TAB]"
  },
  {
    "codigo": "Z271",
    "descricao": "Necessidade de imunização associada contra a difteria-pertussis-tétano, combinada [DPT]"
  },
  {
    "codigo": "Z272",
    "descricao": "Necessidade de imunização contra a difteria-pertussis-tétano com febres tifóide e paratifóides [DPT + TAB]"
  },
  {
    "codigo": "Z273",
    "descricao": "Necessidade de imunização contra a difteria-pertussis-tétano com poliomielite [DPT + pólio]"
  },
  {
    "codigo": "Z274",
    "descricao": "Necessidade de imunização contra o sarampo-caxumba-rubéola"
  },
  {
    "codigo": "Z278",
    "descricao": "Necessidade de imunização contra outras combinações de doenças infecciosas"
  },
  {
    "codigo": "Z279",
    "descricao": "Necessidade de imunização contra combinações não especificadas de doenças infecciosas"
  },
  {
    "codigo": "Z280",
    "descricao": "Imunização não realizada por contra-indicação"
  },
  {
    "codigo": "Z281",
    "descricao": "Imunização não realizada por decisão do paciente devida à crença ou a grupo de pressão"
  },
  {
    "codigo": "Z282",
    "descricao": "Imunização não realizada por decisão do paciente devida a razões outras e às não especificadas"
  },
  {
    "codigo": "Z288",
    "descricao": "Imunização não realizada por outras razões"
  },
  {
    "codigo": "Z289",
    "descricao": "Imunização não realizada por razões não especificadas"
  },
  { "codigo": "Z290", "descricao": "Isolamento" },
  { "codigo": "Z291", "descricao": "Imunoterapia profilática" },
  { "codigo": "Z292", "descricao": "Outras quimioterapias profiláticas" },
  {
    "codigo": "Z298",
    "descricao": "Outras medidas profiláticas especificadas"
  },
  {
    "codigo": "Z299",
    "descricao": "Medida profilática não especificada"
  },
  {
    "codigo": "Z300",
    "descricao": "Aconselhamento geral sobre contracepção"
  },
  {
    "codigo": "Z301",
    "descricao": "Inserção de dispositivo anticoncepcional (intra-uterino)"
  },
  { "codigo": "Z302", "descricao": "Esterilização" },
  { "codigo": "Z303", "descricao": "Extração menstrual" },
  {
    "codigo": "Z304",
    "descricao": "Supervisão do uso de medicamentos anticoncepcionais"
  },
  {
    "codigo": "Z305",
    "descricao": "Supervisão de dispositivo anticoncepcional (intra-uterino)"
  },
  { "codigo": "Z308", "descricao": "Outro procedimento anticoncepcional" },
  {
    "codigo": "Z309",
    "descricao": "Procedimento anticoncepcional não especificado"
  },
  {
    "codigo": "Z310",
    "descricao": "Tuboplastia ou vasoplastia após esterilização prévia"
  },
  { "codigo": "Z311", "descricao": "Inseminação artificial" },
  { "codigo": "Z312", "descricao": "Fecundação in vitro" },
  {
    "codigo": "Z313",
    "descricao": "Outros métodos assistidos de fertilização"
  },
  {
    "codigo": "Z314",
    "descricao": "Investigação e testes com relação à procriação"
  },
  { "codigo": "Z315", "descricao": "Aconselhamento genético" },
  {
    "codigo": "Z316",
    "descricao": "Aconselhamento geral sobre a procriação"
  },
  {
    "codigo": "Z318",
    "descricao": "Outra medida especificada de procriação"
  },
  { "codigo": "Z319", "descricao": "Medida procriativa não especificada" },
  { "codigo": "Z320", "descricao": "Gravidez (ainda) não confirmada" },
  { "codigo": "Z321", "descricao": "Gravidez confirmada" },
  { "codigo": "Z33", "descricao": "Gravidez como achado casual" },
  {
    "codigo": "Z340",
    "descricao": "Supervisão de primeira gravidez normal"
  },
  { "codigo": "Z348", "descricao": "Supervisão de outra gravidez normal" },
  {
    "codigo": "Z349",
    "descricao": "Supervisão de gravidez normal, não especificada"
  },
  {
    "codigo": "Z350",
    "descricao": "Supervisão de gravidez com história de esterilidade"
  },
  {
    "codigo": "Z351",
    "descricao": "Supervisão de gravidez com história de aborto"
  },
  {
    "codigo": "Z352",
    "descricao": "Supervisão de gravidez com outros antecedentes de procriação problemática"
  },
  {
    "codigo": "Z353",
    "descricao": "Supervisão de gravidez com história de assistência pré-natal insuficiente"
  },
  {
    "codigo": "Z354",
    "descricao": "Supervisão de gravidez com grande multiparidade"
  },
  { "codigo": "Z355", "descricao": "Supervisão de primigesta idosa" },
  {
    "codigo": "Z356",
    "descricao": "Supervisão de primigesta muito jovem"
  },
  {
    "codigo": "Z357",
    "descricao": "Supervisão de gravidez de alto risco devido a problemas sociais"
  },
  {
    "codigo": "Z358",
    "descricao": "Supervisão de outras gravidezes de alto risco"
  },
  {
    "codigo": "Z359",
    "descricao": "Supervisão não especificada de gravidez de alto risco"
  },
  {
    "codigo": "Z360",
    "descricao": "Rastreamento de anomalias cromossômicas"
  },
  {
    "codigo": "Z361",
    "descricao": "Rastreamento pré-natal de taxa elevada de alfa-fetoproteínas"
  },
  {
    "codigo": "Z362",
    "descricao": "Outros rastreamentos pré-natais por amniocentese"
  },
  {
    "codigo": "Z363",
    "descricao": "Rastreamento pré-natal de malformações por ultrassom e outros meios físicos"
  },
  {
    "codigo": "Z364",
    "descricao": "Rastreamento pré-natal do retardo de crescimento do feto por ultrassom e outros métodos físicos"
  },
  {
    "codigo": "Z365",
    "descricao": "Rastreamento pré-natal de isoimunização"
  },
  { "codigo": "Z368", "descricao": "Outros rastreamentos pré-natais" },
  {
    "codigo": "Z369",
    "descricao": "Rastreamento pré-natal não especificado"
  },
  {
    "codigo": "Z370",
    "descricao": "Nascimento único, nativivo [nado-vivo]"
  },
  {
    "codigo": "Z371",
    "descricao": "Nascimento único, natimorto [feto-morto]"
  },
  {
    "codigo": "Z372",
    "descricao": "Nascimento gemelar, gêmeos (ambos) nativivos [nado-vivos]"
  },
  {
    "codigo": "Z373",
    "descricao": "Nascimento gemelar, um dos gêmeos, nativivo [nado-vivo], o outro, natimorto [feto-morto]"
  },
  {
    "codigo": "Z374",
    "descricao": "Nascimento gemelar, gêmeos natimortos [feto-mortos]"
  },
  {
    "codigo": "Z375",
    "descricao": "Outros nascimentos múltiplos, todos nativivos [nado-vivos]"
  },
  {
    "codigo": "Z376",
    "descricao": "Outros nascimentos múltiplos, alguns nativivos [nado-vivos]"
  },
  {
    "codigo": "Z377",
    "descricao": "Outros nascimentos múltiplos, todos natimortos [feto-mortos]"
  },
  { "codigo": "Z379", "descricao": "Nascimento não especificado" },
  {
    "codigo": "Z380",
    "descricao": "Criança única, nascida em hospital"
  },
  {
    "codigo": "Z381",
    "descricao": "Criança única, nascida fora do hospital"
  },
  {
    "codigo": "Z382",
    "descricao": "Criança única, não especificado quanto ao local de nascimento"
  },
  {
    "codigo": "Z383",
    "descricao": "Gêmeos (duplos), nascidos em hospital"
  },
  {
    "codigo": "Z384",
    "descricao": "Gêmeos (duplos), nascidos fora de hospital"
  },
  {
    "codigo": "Z385",
    "descricao": "Gêmeos, não especificados quanto ao local de nascimento"
  },
  {
    "codigo": "Z386",
    "descricao": "Outros nascimentos múltiplos, em hospital"
  },
  {
    "codigo": "Z387",
    "descricao": "Outros nascimentos múltiplos, fora de hospital"
  },
  {
    "codigo": "Z388",
    "descricao": "Outros nascimentos múltiplos, não especificados quanto ao local de nascimento"
  },
  {
    "codigo": "Z390",
    "descricao": "Assistência e exame imediatamente após o parto"
  },
  {
    "codigo": "Z391",
    "descricao": "Assistência e exame da mãe nutriz"
  },
  { "codigo": "Z392", "descricao": "Seguimento pós-parto de rotina" },
  {
    "codigo": "Z400",
    "descricao": "Cirurgia profilática para fatores de risco relacionados com neoplasias malignas"
  },
  { "codigo": "Z408", "descricao": "Outra cirurgia profilática" },
  {
    "codigo": "Z409",
    "descricao": "Cirurgia profilática não especificada"
  },
  { "codigo": "Z410", "descricao": "Transplante de cabelo" },
  {
    "codigo": "Z411",
    "descricao": "Outras intervenções de cirurgia plástica por razões estéticas"
  },
  { "codigo": "Z412", "descricao": "Circuncisão ritual e de rotina" },
  { "codigo": "Z413", "descricao": "Perfuração do lobo da orelha" },
  {
    "codigo": "Z418",
    "descricao": "Outros procedimentos para outros propósitos exceto cuidados de saúde"
  },
  {
    "codigo": "Z419",
    "descricao": "Procedimentos não especificado para outros propósitos exceto cuidados de saúde"
  },
  {
    "codigo": "Z420",
    "descricao": "Seguimento envolvendo cirurgia plástica da cabeça e do pescoço"
  },
  {
    "codigo": "Z421",
    "descricao": "Seguimento envolvendo cirurgia plástica de mama"
  },
  {
    "codigo": "Z422",
    "descricao": "Seguimento envolvendo cirurgia plástica de outras partes do tronco"
  },
  {
    "codigo": "Z423",
    "descricao": "Seguimento envolvendo cirurgia plástica das extremidades superiores"
  },
  {
    "codigo": "Z424",
    "descricao": "Seguimento envolvendo cirurgia plástica das extremidades inferiores"
  },
  {
    "codigo": "Z428",
    "descricao": "Seguimento envolvendo cirurgia plástica de outras partes do corpo"
  },
  {
    "codigo": "Z429",
    "descricao": "Seguimento envolvendo cirurgia plástica não especificada"
  },
  { "codigo": "Z430", "descricao": "Cuidados à traqueostomia" },
  { "codigo": "Z431", "descricao": "Cuidados à gastrostomia" },
  { "codigo": "Z432", "descricao": "Cuidados à ileostomia" },
  { "codigo": "Z433", "descricao": "Cuidados à colostomia" },
  {
    "codigo": "Z434",
    "descricao": "Cuidados a outros orifícios artificiais do tubo digestivo"
  },
  { "codigo": "Z435", "descricao": "Cuidados à cistostomia" },
  {
    "codigo": "Z436",
    "descricao": "Cuidados a outros orifícios artificiais das vias urinárias"
  },
  { "codigo": "Z437", "descricao": "Cuidados à vagina artificial" },
  {
    "codigo": "Z438",
    "descricao": "Cuidados a outros orifícios artificiais"
  },
  {
    "codigo": "Z439",
    "descricao": "Cuidados a orifício artificial não especificado"
  },
  {
    "codigo": "Z440",
    "descricao": "Colocação e ajustamento de braço artificial (parcial) (total)"
  },
  {
    "codigo": "Z441",
    "descricao": "Colocação e ajustamento de perna artificial (parcial) (total)"
  },
  {
    "codigo": "Z442",
    "descricao": "Colocação e ajustamento de olho artificial"
  },
  {
    "codigo": "Z443",
    "descricao": "Colocação e ajustamento de prótese externa de mama"
  },
  {
    "codigo": "Z448",
    "descricao": "Colocação e ajustamento de outros aparelhos de prótese externa"
  },
  {
    "codigo": "Z449",
    "descricao": "Colocação e ajustamento de aparelho de prótese externa não especificado"
  },
  {
    "codigo": "Z450",
    "descricao": "Ajustamento e manuseio de marca-passo cardíaco"
  },
  {
    "codigo": "Z451",
    "descricao": "Ajustamento e manuseio de bomba de infusão"
  },
  {
    "codigo": "Z452",
    "descricao": "Ajustamento e manuseio de dispositivo de acesso vascular"
  },
  {
    "codigo": "Z453",
    "descricao": "Ajustamento e manuseio de dispositivo implantado de audição"
  },
  {
    "codigo": "Z458",
    "descricao": "Ajustamento e manuseio de outros dispositivos implantados"
  },
  {
    "codigo": "Z459",
    "descricao": "Ajustamento e manuseio de dispositivo implantado não especificado"
  },
  {
    "codigo": "Z460",
    "descricao": "Colocação e ajustamento de óculos e lentes de contato"
  },
  {
    "codigo": "Z461",
    "descricao": "Colocação e ajustamento de aparelho auditivo"
  },
  {
    "codigo": "Z462",
    "descricao": "Colocação e ajustamento de outros aparelhos relacionados com o sistema nervoso e órgãos dos sentidos"
  },
  {
    "codigo": "Z463",
    "descricao": "Colocação e ajustamento de dispositivo de prótese dentária"
  },
  {
    "codigo": "Z464",
    "descricao": "Colocação e ajustamento de aparelho ortodôntico"
  },
  {
    "codigo": "Z465",
    "descricao": "Colocação e ajustamento de ileostomia e de outros dispositivos intestinais"
  },
  {
    "codigo": "Z466",
    "descricao": "Colocação e ajustamento de prótese urinária"
  },
  {
    "codigo": "Z467",
    "descricao": "Colocação e ajustamento de aparelho ortopédico"
  },
  {
    "codigo": "Z468",
    "descricao": "Colocação e ajustamento de outros aparelhos especificados"
  },
  {
    "codigo": "Z469",
    "descricao": "Colocação e ajustamento de aparelho não especificado"
  },
  {
    "codigo": "Z470",
    "descricao": "Seguimento envolvendo remoção de placa de fratura e outros dispositivos de fixação interna"
  },
  {
    "codigo": "Z478",
    "descricao": "Outros seguimentos ortopédicos especificados"
  },
  {
    "codigo": "Z479",
    "descricao": "Seguimento ortopédico não especificado"
  },
  {
    "codigo": "Z480",
    "descricao": "Cuidados a curativos e suturas cirúrgicas"
  },
  {
    "codigo": "Z488",
    "descricao": "Outro seguimento cirúrgico especificado"
  },
  {
    "codigo": "Z489",
    "descricao": "Seguimento cirúrgico não especificado"
  },
  {
    "codigo": "Z490",
    "descricao": "Cuidado preparatório para diálise"
  },
  { "codigo": "Z491", "descricao": "Diálise extracorpórea" },
  { "codigo": "Z492", "descricao": "Outras diálises" },
  { "codigo": "Z500", "descricao": "Reabilitação cardíaca" },
  { "codigo": "Z501", "descricao": "Outra fisioterapia" },
  {
    "codigo": "Z502",
    "descricao": "Reabilitação de alcoólatra"
  },
  {
    "codigo": "Z503",
    "descricao": "Reabilitação de toxicodependentes"
  },
  {
    "codigo": "Z504",
    "descricao": "Psicoterapia, não classificada em outra parte"
  },
  { "codigo": "Z505", "descricao": "Reabilitação da linguagem" },
  { "codigo": "Z506", "descricao": "Treinamento ortóptico" },
  {
    "codigo": "Z507",
    "descricao": "Terapia ocupacional e reabilitação vocacional não classificada em outra parte"
  },
  {
    "codigo": "Z508",
    "descricao": "Cuidados envolvendo uso de outros procedimentos de reabilitação"
  },
  {
    "codigo": "Z509",
    "descricao": "Cuidados envolvendo uso de procedimento de reabilitação não especificado"
  },
  { "codigo": "Z510", "descricao": "Sessão de radioterapia" },
  {
    "codigo": "Z511",
    "descricao": "Sessão de quimioterapia por neoplasia"
  },
  { "codigo": "Z512", "descricao": "Outra quimioterapia" },
  {
    "codigo": "Z513",
    "descricao": "Transfusão de sangue, sem diagnóstico registrado"
  },
  {
    "codigo": "Z514",
    "descricao": "Cuidado preparatório para tratamento subsequente não classificado em outra parte"
  },
  { "codigo": "Z515", "descricao": "Cuidado paliativo" },
  {
    "codigo": "Z516",
    "descricao": "Dessensibilização a alérgenos"
  },
  { "codigo": "Z518", "descricao": "Outro cuidado médico especificado" },
  {
    "codigo": "Z519",
    "descricao": "Cuidado médico não especificado"
  },
  { "codigo": "Z520", "descricao": "Doador de sangue" },
  { "codigo": "Z521", "descricao": "Doador de pele" },
  { "codigo": "Z522", "descricao": "Doador de osso" },
  { "codigo": "Z523", "descricao": "Doador de medula óssea" },
  { "codigo": "Z524", "descricao": "Doador de rim" },
  { "codigo": "Z525", "descricao": "Doador de córnea" },
  { "codigo": "Z526", "descricao": "Doador de fígado" },
  { "codigo": "Z527", "descricao": "Doador de coração" },
  {
    "codigo": "Z528",
    "descricao": "Doador de outros órgãos ou tecidos"
  },
  {
    "codigo": "Z529",
    "descricao": "Doador de órgão ou tecido não especificado"
  },
  {
    "codigo": "Z530",
    "descricao": "Procedimento não realizado devido à contra-indicação"
  },
  {
    "codigo": "Z531",
    "descricao": "Procedimento não realizado devido à decisão do paciente por razões de crença ou grupo de pressão"
  },
  {
    "codigo": "Z532",
    "descricao": "Procedimento não realizado devido à decisão do paciente por outras razões e as não especificadas"
  },
  {
    "codigo": "Z538",
    "descricao": "Procedimento não realizado por outras razões"
  },
  {
    "codigo": "Z539",
    "descricao": "Procedimento não realizado por razão não especificada"
  },
  { "codigo": "Z540", "descricao": "Convalescença após cirurgia" },
  {
    "codigo": "Z541",
    "descricao": "Convalescença após radioterapia"
  },
  {
    "codigo": "Z542",
    "descricao": "Convalescença após quimioterapia"
  },
  {
    "codigo": "Z543",
    "descricao": "Convalescença após psicoterapia"
  },
  {
    "codigo": "Z544",
    "descricao": "Convalescença após tratamento de fratura"
  },
  {
    "codigo": "Z547",
    "descricao": "Convalescença após tratamento combinado"
  },
  {
    "codigo": "Z548",
    "descricao": "Convalescença após outro tratamento"
  },
  {
    "codigo": "Z549",
    "descricao": "Convalescença após tratamento não especificado"
  },
  { "codigo": "Z550", "descricao": "Analfabetismo e baixa escolaridade" },
  {
    "codigo": "Z551",
    "descricao": "Escolarização não disponível e impossível"
  },
  { "codigo": "Z552", "descricao": "Reprovação em exames" },
  { "codigo": "Z553", "descricao": "Maus resultados escolares" },
  {
    "codigo": "Z554",
    "descricao": "Má adaptação escolar e dificuldades com professores e colegas"
  },
  {
    "codigo": "Z558",
    "descricao": "Outros problemas relacionados com a educação e com a alfabetização"
  },
  {
    "codigo": "Z559",
    "descricao": "Problemas não especificados relacionados com a educação e com a alfabetização"
  },
  { "codigo": "Z560", "descricao": "Desemprego não especificado" },
  { "codigo": "Z561", "descricao": "Mudança de emprego" },
  { "codigo": "Z562", "descricao": "Ameaça de perda de emprego" },
  { "codigo": "Z563", "descricao": "Ritmo de trabalho penoso" },
  {
    "codigo": "Z564",
    "descricao": "Desacordo com patrão e colegas de trabalho"
  },
  { "codigo": "Z565", "descricao": "Má adaptação ao trabalho" },
  {
    "codigo": "Z566",
    "descricao": "Outras dificuldades físicas e mentais relacionadas ao trabalho"
  },
  {
    "codigo": "Z567",
    "descricao": "Outros problemas e os não especificados relacionados com o emprego"
  },
  {
    "codigo": "Z570",
    "descricao": "Exposição ocupacional ao ruído"
  },
  {
    "codigo": "Z571",
    "descricao": "Exposição ocupacional à radiação"
  },
  {
    "codigo": "Z572",
    "descricao": "Exposição ocupacional à poeira (pó)"
  },
  {
    "codigo": "Z573",
    "descricao": "Exposição ocupacional a outros contaminantes do ar"
  },
  {
    "codigo": "Z574",
    "descricao": "Exposição ocupacional a agentes tóxicos na agricultura"
  },
  {
    "codigo": "Z575",
    "descricao": "Exposição ocupacional a agentes tóxicos em outras indústrias"
  },
  {
    "codigo": "Z576",
    "descricao": "Exposição ocupacional a temperaturas extremas"
  },
  {
    "codigo": "Z577",
    "descricao": "Exposição ocupacional à vibração"
  },
  {
    "codigo": "Z578",
    "descricao": "Exposição ocupacional a outros fatores de risco"
  },
  {
    "codigo": "Z579",
    "descricao": "Exposição ocupacional a fator de risco não especificado"
  },
  { "codigo": "Z580", "descricao": "Exposição ao ruído" },
  {
    "codigo": "Z581",
    "descricao": "Exposição à poluição atmosférica"
  },
  {
    "codigo": "Z582",
    "descricao": "Exposição à poluição da água"
  },
  {
    "codigo": "Z583",
    "descricao": "Exposição à poluição do solo"
  },
  {
    "codigo": "Z584",
    "descricao": "Exposição à radiação"
  },
  {
    "codigo": "Z585",
    "descricao": "Exposição a outras poluições"
  },
  { "codigo": "Z586", "descricao": "água potável insuficiente" },
  {
    "codigo": "Z587",
    "descricao": "Exposição à fumaça de tabaco"
  },
  {
    "codigo": "Z588",
    "descricao": "Outros problemas relacionados com o ambiente físico"
  },
  {
    "codigo": "Z589",
    "descricao": "Problema não especificado relacionado com o ambiente físico"
  },
  { "codigo": "Z590", "descricao": "Falta de domicílio fixo" },
  { "codigo": "Z591", "descricao": "Habitação inadequada" },
  {
    "codigo": "Z592",
    "descricao": "Desacordo com vizinhos, locatários e proprietários"
  },
  {
    "codigo": "Z593",
    "descricao": "Problemas relacionados com a vida em instituição residencial"
  },
  { "codigo": "Z594", "descricao": "Falta de alimentação adequada" },
  { "codigo": "Z595", "descricao": "Pobreza extrema" },
  { "codigo": "Z596", "descricao": "Baixo rendimento" },
  {
    "codigo": "Z597",
    "descricao": "Seguro social e medidas de bem-estar social insuficientes"
  },
  {
    "codigo": "Z598",
    "descricao": "Outros problemas relacionados com a habitação e com as circunstâncias econômicas"
  },
  {
    "codigo": "Z599",
    "descricao": "Circunstâncias não especificadas econômicas ou de habitação"
  },
  {
    "codigo": "Z600",
    "descricao": "Problemas de adaptação às transições do ciclo de vida"
  },
  { "codigo": "Z601", "descricao": "Situação parental atípica" },
  { "codigo": "Z602", "descricao": "Viver só" },
  { "codigo": "Z603", "descricao": "Dificuldade de aculturação" },
  {
    "codigo": "Z604",
    "descricao": "Exclusão e rejeição sociais"
  },
  {
    "codigo": "Z605",
    "descricao": "Objeto de discriminação e perseguição percebidas"
  },
  {
    "codigo": "Z608",
    "descricao": "Outros problemas relacionados com o meio social"
  },
  {
    "codigo": "Z609",
    "descricao": "Problema não especificado relacionado com o meio social"
  },
  {
    "codigo": "Z610",
    "descricao": "Perda de relação afetiva na infância"
  },
  {
    "codigo": "Z611",
    "descricao": "Remoção do lar na infância"
  },
  {
    "codigo": "Z612",
    "descricao": "Padrão alterado de relações familiares na infância"
  },
  {
    "codigo": "Z613",
    "descricao": "Eventos que originam a perda de auto-estima na infância"
  },
  {
    "codigo": "Z614",
    "descricao": "Problemas relacionados com abuso sexual alegado de uma criança por uma pessoa de dentro de seu grupo"
  },
  {
    "codigo": "Z615",
    "descricao": "Problemas relacionados com abuso sexual alegado de uma criança por pessoa de fora de seu grupo"
  },
  {
    "codigo": "Z616",
    "descricao": "Problemas relacionados com abuso físico alegado da criança"
  },
  {
    "codigo": "Z617",
    "descricao": "Experiência pessoal amedrontadora na infância"
  },
  {
    "codigo": "Z618",
    "descricao": "Outros eventos da vida pessoal negativos na infância"
  },
  {
    "codigo": "Z619",
    "descricao": "Evento pessoal negativo não especificado na infância"
  },
  {
    "codigo": "Z620",
    "descricao": "Supervisão e controle inadequado por parte dos pais"
  },
  {
    "codigo": "Z621",
    "descricao": "Superproteção por parte dos pais"
  },
  {
    "codigo": "Z622",
    "descricao": "Educação numa instituição"
  },
  {
    "codigo": "Z623",
    "descricao": "Hostilidade com relação a uma criança, transformada em bode expiatório"
  },
  {
    "codigo": "Z624",
    "descricao": "Negligência emocional da criança"
  },
  {
    "codigo": "Z625",
    "descricao": "Outros problemas relacionados com negligência na educação dos filhos"
  },
  {
    "codigo": "Z626",
    "descricao": "Pressões não apropriadas por parte dos pais e outras falhas de educação"
  },
  {
    "codigo": "Z628",
    "descricao": "Outros problemas especificados relacionados com a educação dos filhos"
  },
  {
    "codigo": "Z629",
    "descricao": "Problema não especificado relacionado com a educação dos filhos"
  },
  {
    "codigo": "Z630",
    "descricao": "Problemas nas relações com cônjuge ou parceiro"
  },
  {
    "codigo": "Z631",
    "descricao": "Problemas nas relações com os pais ou com os sogros"
  },
  { "codigo": "Z632", "descricao": "Suporte familiar inadequado" },
  {
    "codigo": "Z633",
    "descricao": "Ausência de um dos membros da família"
  },
  {
    "codigo": "Z634",
    "descricao": "Desaparecimento ou falecimento de um membro da família"
  },
  {
    "codigo": "Z635",
    "descricao": "Rompimento da família por separação ou divórcio"
  },
  {
    "codigo": "Z636",
    "descricao": "Parente dependente de cuidados, residente no domicílio"
  },
  {
    "codigo": "Z637",
    "descricao": "Outros acontecimentos difíceis com incidência na família ou no lar"
  },
  {
    "codigo": "Z638",
    "descricao": "Outros problemas especificados relacionados com o grupo primário de suporte"
  },
  {
    "codigo": "Z639",
    "descricao": "Problema não especificado relacionado com o grupo primário de suporte"
  },
  {
    "codigo": "Z640",
    "descricao": "Problemas relacionados com uma gravidez não desejada"
  },
  { "codigo": "Z641", "descricao": "Problemas relacionados com multiparidade" },
  {
    "codigo": "Z642",
    "descricao": "Procura e aceitação de intervenções físicas, nutricionais, ou químicas conhecidas como perigosas ou nocivas"
  },
  {
    "codigo": "Z643",
    "descricao": "Procura e aceitação de intervenções comportamentais ou psicológicas conhecidas como perigosas ou nocivas"
  },
  { "codigo": "Z644", "descricao": "Desacordo com conselheiros" },
  {
    "codigo": "Z650",
    "descricao": "Condenação, sem prisão, por tribunal civil ou criminal"
  },
  { "codigo": "Z651", "descricao": "Prisão ou encarceramento" },
  {
    "codigo": "Z652",
    "descricao": "Problemas ligados à libertação de prisão"
  },
  {
    "codigo": "Z653",
    "descricao": "Problemas ligados à outras circunstâncias legais"
  },
  {
    "codigo": "Z654",
    "descricao": "Vítima de crime ou de atos terroristas"
  },
  {
    "codigo": "Z655",
    "descricao": "Exposição a catástrofe, guerra e outras hostilidades"
  },
  {
    "codigo": "Z658",
    "descricao": "Outros problemas especificados relacionados com circunstâncias psicossociais"
  },
  {
    "codigo": "Z659",
    "descricao": "Problemas relacionados com circunstâncias psicossociais não especificadas"
  },
  {
    "codigo": "Z700",
    "descricao": "Acompanhamento relativo às atitudes em matéria de sexualidade"
  },
  {
    "codigo": "Z701",
    "descricao": "Aconselhamento relativo ao comportamento e à orientação sexual do sujeito"
  },
  {
    "codigo": "Z702",
    "descricao": "Aconselhamento relativo ao comportamento e à orientação sexual de um terceiro"
  },
  {
    "codigo": "Z703",
    "descricao": "Aconselhamento relativo a preocupações associadas relacionadas com as atitudes, o comportamento e orientação em matéria de sexualidade"
  },
  {
    "codigo": "Z708",
    "descricao": "Outros aconselhamentos em matéria de sexualidade"
  },
  {
    "codigo": "Z709",
    "descricao": "Aconselhamento não especificado em matéria de sexualidade"
  },
  {
    "codigo": "Z710",
    "descricao": "Pessoa que consulta no interesse de um terceiro"
  },
  {
    "codigo": "Z711",
    "descricao": "Pessoa com medo de uma queixa para a qual não foi feito diagnóstico"
  },
  {
    "codigo": "Z712",
    "descricao": "Pessoa que consulta para explicação de achados de exame"
  },
  {
    "codigo": "Z713",
    "descricao": "Aconselhamento e supervisão dietéticos"
  },
  {
    "codigo": "Z714",
    "descricao": "Aconselhamento e supervisão para abuso de álcool"
  },
  {
    "codigo": "Z715",
    "descricao": "Aconselhamento e supervisão para abuso de drogas"
  },
  { "codigo": "Z716", "descricao": "Aconselhamento para o abuso de fumo" },
  {
    "codigo": "Z717",
    "descricao": "Aconselhamento a propósito do vírus da imunodeficiência humana [HIV]"
  },
  { "codigo": "Z718", "descricao": "Outros aconselhamentos especificados" },
  { "codigo": "Z719", "descricao": "Aconselhamento não especificado" },
  { "codigo": "Z720", "descricao": "Uso do tabaco" },
  { "codigo": "Z721", "descricao": "Uso de álcool" },
  { "codigo": "Z722", "descricao": "Uso de droga" },
  { "codigo": "Z723", "descricao": "Falta de exercício físico" },
  {
    "codigo": "Z724",
    "descricao": "Regime e hábitos alimentares inadequados"
  },
  { "codigo": "Z725", "descricao": "Comportamento sexual de alto risco" },
  { "codigo": "Z726", "descricao": "Mania de jogo e apostas" },
  {
    "codigo": "Z728",
    "descricao": "Outros problemas relacionados com o estilo de vida"
  },
  {
    "codigo": "Z729",
    "descricao": "Problema não especificado relacionado com o estilo de vida"
  },
  { "codigo": "Z730", "descricao": "Esgotamento" },
  {
    "codigo": "Z731",
    "descricao": "Acentuação de traços de personalidade"
  },
  { "codigo": "Z732", "descricao": "Falta de repouso e de lazer" },
  {
    "codigo": "Z733",
    "descricao": "Stress não classificado em outra parte"
  },
  {
    "codigo": "Z734",
    "descricao": "Habilidades sociais inadequadas não classificadas em outra parte"
  },
  {
    "codigo": "Z735",
    "descricao": "Conflito sobre o papel social, não classificado em outra parte"
  },
  {
    "codigo": "Z736",
    "descricao": "Limites impostos às atividades por invalidez"
  },
  {
    "codigo": "Z738",
    "descricao": "Outros problemas relacionados com a organização do seu modo de vida"
  },
  {
    "codigo": "Z739",
    "descricao": "Problema relacionado com a organização de seu modo de vida não especificado"
  },
  { "codigo": "Z740", "descricao": "Mobilidade reduzida" },
  {
    "codigo": "Z741",
    "descricao": "Necessidade de assistência com cuidados pessoais"
  },
  {
    "codigo": "Z742",
    "descricao": "Necessidade de assistência a domicílio, sendo que nenhuma pessoa do lar é capaz de assegurar os cuidados"
  },
  {
    "codigo": "Z743",
    "descricao": "Necessidade de supervisão contínua"
  },
  {
    "codigo": "Z748",
    "descricao": "Outros problemas relacionados com a dependência de pessoa que oferece cuidados de saúde"
  },
  {
    "codigo": "Z749",
    "descricao": "Problema não especificado relacionado com a dependência de pessoa que oferece cuidados de saúde"
  },
  {
    "codigo": "Z750",
    "descricao": "Falta de disponibilidade de serviços médicos no domicílio"
  },
  {
    "codigo": "Z751",
    "descricao": "Pessoa esperando ser admitida em estabelecimento médico adequado em outra parte"
  },
  {
    "codigo": "Z752",
    "descricao": "Outro período de espera para investigação e tratamento"
  },
  {
    "codigo": "Z753",
    "descricao": "Facilidades de saúde não disponíveis ou não acessíveis"
  },
  {
    "codigo": "Z754",
    "descricao": "Outras organizações de cuidados não disponíveis ou não acessíveis"
  },
  {
    "codigo": "Z755",
    "descricao": "Cuidados dispensados para ajudar às famílias durante as férias"
  },
  {
    "codigo": "Z758",
    "descricao": "Outras dificuldades especificadas relacionadas com facilidades médicas e com outros cuidados de saúde"
  },
  {
    "codigo": "Z759",
    "descricao": "Problema não especificado relacionado com facilidades médicas e com outros cuidados de saúde"
  },
  {
    "codigo": "Z760",
    "descricao": "Emissão de prescrição de repetição"
  },
  {
    "codigo": "Z761",
    "descricao": "Supervisão e cuidado de saúde de crianças assistidas"
  },
  {
    "codigo": "Z762",
    "descricao": "Supervisão de cuidado de saúde de outras crianças ou recém-nascidos sadios"
  },
  {
    "codigo": "Z763",
    "descricao": "Pessoa em boa saúde acompanhando pessoa doente"
  },
  {
    "codigo": "Z764",
    "descricao": "Outros pensionistas em estabelecimentos de cuidados de saúde"
  },
  {
    "codigo": "Z765",
    "descricao": "Pessoa fingindo ser doente [simulação consciente]"
  },
  {
    "codigo": "Z768",
    "descricao": "Contatos com serviços de saúde por outras circunstâncias especificadas"
  },
  {
    "codigo": "Z769",
    "descricao": "Pessoa em contato com serviços de saúde em circunstâncias não especificadas"
  },
  {
    "codigo": "Z800",
    "descricao": "História familiar de neoplasia maligna de órgãos digestivos"
  },
  {
    "codigo": "Z801",
    "descricao": "História familiar de neoplasia maligna de traquéia, brônquio e pulmão"
  },
  {
    "codigo": "Z802",
    "descricao": "História familiar de neoplasia maligna de outros órgãos respiratórios e intratorácicos"
  },
  {
    "codigo": "Z803",
    "descricao": "História familiar de neoplasia de mama"
  },
  {
    "codigo": "Z804",
    "descricao": "História familiar de neoplasia maligna de órgãos genitais"
  },
  {
    "codigo": "Z805",
    "descricao": "História familiar de neoplasia maligna do trato urinário"
  },
  { "codigo": "Z806", "descricao": "História familiar de leucemia" },
  {
    "codigo": "Z807",
    "descricao": "História familiar de outras neoplasias malignas dos tecidos linfático, hematopoético e correlacionados"
  },
  {
    "codigo": "Z808",
    "descricao": "História familiar de neoplasia maligna de outros órgãos ou aparelhos"
  },
  {
    "codigo": "Z809",
    "descricao": "História familiar de neoplasia maligna não especificada"
  },
  { "codigo": "Z810", "descricao": "História familiar de retardo mental" },
  {
    "codigo": "Z811",
    "descricao": "História familiar de abuso de álcool"
  },
  { "codigo": "Z812", "descricao": "História familiar de abuso de fumo" },
  {
    "codigo": "Z813",
    "descricao": "História familiar de abuso de outra substância psicoativa"
  },
  {
    "codigo": "Z814",
    "descricao": "História familiar de abuso de outra substância"
  },
  {
    "codigo": "Z818",
    "descricao": "História familiar de outros transtornos mentais e comportamentais"
  },
  {
    "codigo": "Z820",
    "descricao": "História familiar de epilepsia e de outras doenças do sistema nervoso"
  },
  {
    "codigo": "Z821",
    "descricao": "História familiar de cegueira e perda de visão"
  },
  {
    "codigo": "Z822",
    "descricao": "História familiar de surdez e perda de audição"
  },
  {
    "codigo": "Z823",
    "descricao": "História familiar de acidente vascular cerebral"
  },
  {
    "codigo": "Z824",
    "descricao": "História familiar de doença isquêmica do coração e outras doenças do aparelho circulatório"
  },
  {
    "codigo": "Z825",
    "descricao": "História familiar de asma e outras doenças respiratórias inferiores crônicas"
  },
  {
    "codigo": "Z826",
    "descricao": "História familiar de artrite e outras doenças do sistema osteomuscular e tecido conjuntivo"
  },
  {
    "codigo": "Z827",
    "descricao": "História familiar de malformações e deformações congênitas e anomalias cromossômicas"
  },
  {
    "codigo": "Z828",
    "descricao": "História familiar de outras incapacidades e doenças crônicas que conduzem a incapacitação, não classificadas em outra parte"
  },
  {
    "codigo": "Z830",
    "descricao": "História familiar de doença pelo vírus da imunodeficiência humana [HIV]"
  },
  {
    "codigo": "Z831",
    "descricao": "História familiar de outras doenças infecciosas e parasitárias"
  },
  {
    "codigo": "Z832",
    "descricao": "História familiar de doenças do sangue e dos órgãos hematopoéticos e alguns transtornos imunitários"
  },
  {
    "codigo": "Z833",
    "descricao": "História familiar de diabetes mellitus"
  },
  {
    "codigo": "Z834",
    "descricao": "História familiar de outras doenças endócrinas, nutricionais e metabólicas"
  },
  {
    "codigo": "Z835",
    "descricao": "História familiar de outros transtornos dos olhos e dos ouvidos"
  },
  {
    "codigo": "Z836",
    "descricao": "História familiar de doenças do aparelho respiratório"
  },
  {
    "codigo": "Z837",
    "descricao": "História familiar das doenças do aparelho digestivo"
  },
  {
    "codigo": "Z840",
    "descricao": "História familiar de doenças da pele e do tecido subcutâneo"
  },
  {
    "codigo": "Z841",
    "descricao": "História familiar de transtornos do rim e ureter"
  },
  {
    "codigo": "Z842",
    "descricao": "História familiar de outras doenças do aparelho geniturinário"
  },
  {
    "codigo": "Z843",
    "descricao": "História familiar de consangüinidade"
  },
  {
    "codigo": "Z848",
    "descricao": "História familiar de outras afecções especificadas"
  },
  {
    "codigo": "Z850",
    "descricao": "História pessoal de neoplasia maligna de órgãos digestivos"
  },
  {
    "codigo": "Z851",
    "descricao": "História pessoal de neoplasia maligna de traquéia, brônquio e pulmão"
  },
  {
    "codigo": "Z852",
    "descricao": "História pessoal de neoplasia maligna de outros órgãos respiratórios e intratorácicos"
  },
  {
    "codigo": "Z853",
    "descricao": "História pessoal de neoplasia maligna de mama"
  },
  {
    "codigo": "Z854",
    "descricao": "História pessoal de neoplasia maligna de órgãos genitais"
  },
  {
    "codigo": "Z855",
    "descricao": "História pessoal de neoplasia maligna de trato urinário"
  },
  { "codigo": "Z856", "descricao": "História pessoal de leucemia" },
  {
    "codigo": "Z857",
    "descricao": "História pessoal de outras neoplasias malignas do tecido linfático, hematopoético e tecidos correlatos"
  },
  {
    "codigo": "Z858",
    "descricao": "História pessoal de neoplasias malignas de outros órgãos ou aparelhos"
  },
  {
    "codigo": "Z859",
    "descricao": "História pessoal de neoplasia maligna não especificada"
  },
  {
    "codigo": "Z860",
    "descricao": "História pessoal de outras neoplasias"
  },
  {
    "codigo": "Z861",
    "descricao": "História pessoal de doenças infecciosas e parasitárias"
  },
  {
    "codigo": "Z862",
    "descricao": "História pessoal de doenças do sangue e dos órgãos hematopoéticos e alguns transtornos imunitários"
  },
  {
    "codigo": "Z863",
    "descricao": "História pessoal de doenças endócrinas, nutricionais e metabólicas"
  },
  {
    "codigo": "Z864",
    "descricao": "História pessoal de abuso de substâncias psicoativas"
  },
  {
    "codigo": "Z865",
    "descricao": "História pessoal de outros transtornos mentais e comportamentais"
  },
  {
    "codigo": "Z866",
    "descricao": "História pessoal de doenças do sistema nervoso e dos órgãos dos sentidos"
  },
  {
    "codigo": "Z867",
    "descricao": "História pessoal de doenças do aparelho circulatório"
  },
  {
    "codigo": "Z870",
    "descricao": "História pessoal de doenças do aparelho respiratório"
  },
  {
    "codigo": "Z871",
    "descricao": "História pessoal de doenças do aparelho digestivo"
  },
  {
    "codigo": "Z872",
    "descricao": "História pessoal de doenças da pele e tecido subcutâneo"
  },
  {
    "codigo": "Z873",
    "descricao": "História pessoal de doenças do sistema osteomuscular e tecido conjuntivo"
  },
  {
    "codigo": "Z874",
    "descricao": "História pessoal de doenças do aparelho geniturinário"
  },
  {
    "codigo": "Z875",
    "descricao": "História pessoal de complicações da gravidez, parto e puerpério"
  },
  {
    "codigo": "Z876",
    "descricao": "História pessoal de algumas afecções originadas no período perinatal"
  },
  {
    "codigo": "Z877",
    "descricao": "História pessoal de malformações e deformidades congênitas e anomalias cromossômicas"
  },
  {
    "codigo": "Z878",
    "descricao": "História pessoal de outras afecções especificadas"
  },
  {
    "codigo": "Z880",
    "descricao": "História pessoal de alergia à penicilina"
  },
  {
    "codigo": "Z881",
    "descricao": "História pessoal de alergia a outro agente antibiótico"
  },
  {
    "codigo": "Z882",
    "descricao": "História pessoal de alergia às sulfonamidas"
  },
  {
    "codigo": "Z883",
    "descricao": "História pessoal de alergia a outros agentes antiinfecciosos"
  },
  {
    "codigo": "Z884",
    "descricao": "História pessoal de alergia a agente anestésico"
  },
  {
    "codigo": "Z885",
    "descricao": "História pessoal de alergia a agente narcótico"
  },
  {
    "codigo": "Z886",
    "descricao": "História pessoal de alergia a agente analgésico"
  },
  {
    "codigo": "Z887",
    "descricao": "História pessoal de alergia a soro e à vacina"
  },
  {
    "codigo": "Z888",
    "descricao": "História pessoal de alergia a outros drogas, medicamentos e substâncias biológicas"
  },
  {
    "codigo": "Z889",
    "descricao": "História pessoal de alergia a drogas, medicamentos e substâncias biológicas não especificadas"
  },
  {
    "codigo": "Z890",
    "descricao": "Ausência adquirida de dedo(s) da mão [inclusive polegar] unilateral"
  },
  {
    "codigo": "Z891",
    "descricao": "Ausência adquirida de mão e punho"
  },
  {
    "codigo": "Z892",
    "descricao": "Ausência adquirida de braço acima do punho"
  },
  {
    "codigo": "Z893",
    "descricao": "Ausência adquirida de ambos membros superiores [qualquer nível]"
  },
  {
    "codigo": "Z894",
    "descricao": "Ausência adquirida de pé e tornozelo"
  },
  {
    "codigo": "Z895",
    "descricao": "Ausência adquirida da perna ao nível ou abaixo do joelho"
  },
  {
    "codigo": "Z896",
    "descricao": "Ausência adquirida da perna acima do joelho"
  },
  {
    "codigo": "Z897",
    "descricao": "Ausência adquirida de ambos membros inferiores [qualquer nível, exceto somente artelhos]"
  },
  {
    "codigo": "Z898",
    "descricao": "Ausência adquirida dos membros superiores e inferiores [qualquer nível]"
  },
  {
    "codigo": "Z899",
    "descricao": "Ausência adquirida de membro não especificado"
  },
  {
    "codigo": "Z900",
    "descricao": "Ausência adquirida de parte da cabeça e do pescoço"
  },
  { "codigo": "Z901", "descricao": "Ausência adquirida da(s) mama(s)" },
  {
    "codigo": "Z902",
    "descricao": "Ausência adquirida [de parte] do pulmão"
  },
  {
    "codigo": "Z903",
    "descricao": "Ausência adquirida de parte do estômago"
  },
  {
    "codigo": "Z904",
    "descricao": "Ausência adquirida de outras partes do trato digestivo"
  },
  { "codigo": "Z905", "descricao": "Ausência adquirida do rim" },
  {
    "codigo": "Z906",
    "descricao": "Ausência adquirida de outras partes do trato urinário"
  },
  {
    "codigo": "Z907",
    "descricao": "Ausência adquirida de órgão(s) genital(is)"
  },
  {
    "codigo": "Z908",
    "descricao": "Ausência adquirida de outros órgãos"
  },
  {
    "codigo": "Z910",
    "descricao": "História pessoal de alergia exceto a drogas e a substâncias biológicas"
  },
  {
    "codigo": "Z911",
    "descricao": "História pessoal de não aderência a tratamento ou regime médico"
  },
  {
    "codigo": "Z912",
    "descricao": "História pessoal de má higiene pessoal"
  },
  {
    "codigo": "Z913",
    "descricao": "História pessoal de ciclo vigília-sono anormal"
  },
  {
    "codigo": "Z914",
    "descricao": "História pessoal de trauma psicológico não classificado em outra parte"
  },
  {
    "codigo": "Z915",
    "descricao": "História pessoal de auto agressão"
  },
  {
    "codigo": "Z916",
    "descricao": "História pessoal de outros traumas físicos"
  },
  {
    "codigo": "Z918",
    "descricao": "História pessoal de outros fatores de risco especificados não classificados em outra parte"
  },
  {
    "codigo": "Z920",
    "descricao": "História pessoal de anticoncepção"
  },
  {
    "codigo": "Z921",
    "descricao": "História pessoal de uso de longo prazo (atual) de anticoagulantes"
  },
  {
    "codigo": "Z922",
    "descricao": "História pessoal de uso de longo prazo (atual) de outros medicamentos"
  },
  {
    "codigo": "Z923",
    "descricao": "História pessoal de irradiação"
  },
  {
    "codigo": "Z924",
    "descricao": "História pessoal de cirurgia de grande porte não classificada em outra parte"
  },
  {
    "codigo": "Z925",
    "descricao": "História pessoal de medidas de reabilitação"
  },
  {
    "codigo": "Z926",
    "descricao": "História pessoal de quimioterapia para doença neoplásica"
  },
  {
    "codigo": "Z928",
    "descricao": "História pessoal de outros tratamentos médicos"
  },
  {
    "codigo": "Z929",
    "descricao": "História pessoal de tratamento médico não especificado"
  },
  { "codigo": "Z930", "descricao": "Traqueostomia" },
  { "codigo": "Z931", "descricao": "Gastrostomia" },
  { "codigo": "Z932", "descricao": "Ileostomia" },
  { "codigo": "Z933", "descricao": "Colostomia" },
  {
    "codigo": "Z934",
    "descricao": "Outros orifícios artificiais do trato gastrointestinal"
  },
  { "codigo": "Z935", "descricao": "Cistostomia" },
  {
    "codigo": "Z936",
    "descricao": "Outros orifícios artificiais do aparelho urinário"
  },
  { "codigo": "Z938", "descricao": "Outros orifícios artificiais" },
  {
    "codigo": "Z939",
    "descricao": "Orifício artificial não especificado"
  },
  { "codigo": "Z940", "descricao": "Rim transplantado" },
  { "codigo": "Z941", "descricao": "Coração transplantado" },
  { "codigo": "Z942", "descricao": "Pulmão transplantado" },
  {
    "codigo": "Z943",
    "descricao": "Coração e pulmões transplantados"
  },
  { "codigo": "Z944", "descricao": "Fígado transplantado" },
  { "codigo": "Z945", "descricao": "Pele transplantada" },
  { "codigo": "Z946", "descricao": "Osso transplantado" },
  { "codigo": "Z947", "descricao": "Córnea transplantada" },
  {
    "codigo": "Z948",
    "descricao": "Outros órgãos e tecidos transplantados"
  },
  {
    "codigo": "Z949",
    "descricao": "órgão e tecido não especificado transplantado"
  },
  {
    "codigo": "Z950",
    "descricao": "Presença de marca-passo cardíaco"
  },
  {
    "codigo": "Z951",
    "descricao": "Presença de enxerto de ponte [bypass] aortocoronária"
  },
  {
    "codigo": "Z952",
    "descricao": "Presença de prótese de válvula cardíaca"
  },
  {
    "codigo": "Z953",
    "descricao": "Presença de valva cardíaca xenógena"
  },
  {
    "codigo": "Z954",
    "descricao": "Presença de outras substituições valvares cardíacas"
  },
  {
    "codigo": "Z955",
    "descricao": "Presença de implante e enxerto de angioplastia coronária"
  },
  {
    "codigo": "Z958",
    "descricao": "Presença de outros implantes e enxertos cardíacos e vasculares"
  },
  {
    "codigo": "Z959",
    "descricao": "Presença de implante e enxerto cardíaco e vascular não especificado"
  },
  { "codigo": "Z960", "descricao": "Presença de implantes urogenitais" },
  { "codigo": "Z961", "descricao": "Presença de lente intra-ocular" },
  {
    "codigo": "Z962",
    "descricao": "Presença de implante otológicos e audiológicos"
  },
  { "codigo": "Z963", "descricao": "Presença de laringe artificial" },
  {
    "codigo": "Z964",
    "descricao": "Presença de implantes endócrinos"
  },
  {
    "codigo": "Z965",
    "descricao": "Presença de raiz dentária e implantes mandibulares"
  },
  {
    "codigo": "Z966",
    "descricao": "Presença de implantes articulares ortopédicos"
  },
  {
    "codigo": "Z967",
    "descricao": "Presença de outros implantes de osso e tendão"
  },
  {
    "codigo": "Z968",
    "descricao": "Presença de outros implantes funcionais especificados"
  },
  {
    "codigo": "Z969",
    "descricao": "Presença de implante funcional não especificado"
  },
  { "codigo": "Z970", "descricao": "Presença de olho artificial" },
  {
    "codigo": "Z971",
    "descricao": "Presença de membro artificial (completo) (parcial)"
  },
  {
    "codigo": "Z972",
    "descricao": "Presença de dispositivo protético dentário (completo) (parcial)"
  },
  {
    "codigo": "Z973",
    "descricao": "Presença de óculos e de lentes de contato"
  },
  {
    "codigo": "Z974",
    "descricao": "Presença de aparelho externo de surdez"
  },
  {
    "codigo": "Z975",
    "descricao": "Presença de dispositivo anticoncepcional intra-uterino [DIU]"
  },
  {
    "codigo": "Z978",
    "descricao": "Presença de outros dispositivos especificados"
  },
  {
    "codigo": "Z980",
    "descricao": "Derivação intestinal ou anastomose intestinal"
  },
  { "codigo": "Z981", "descricao": "Artrodese" },
  {
    "codigo": "Z982",
    "descricao": "Presença de dispositivo de drenagem do líquor"
  },
  {
    "codigo": "Z988",
    "descricao": "Outros estados pós-cirúrgicos especificados"
  },
  { "codigo": "Z990", "descricao": "Dependência de aspirador" },
  { "codigo": "Z991", "descricao": "Dependência de respirador" },
  { "codigo": "Z992", "descricao": "Dependência de diálise renal" },
  { "codigo": "Z993", "descricao": "Dependência de cadeira de rodas" },
  {
    "codigo": "Z998",
    "descricao": "Dependência de outras máquinas e aparelhos capacitantes"
  },
  {
    "codigo": "Z999",
    "descricao": "Dependência de máquina e aparelho capacitante não especificado"
  },
  {
    "codigo": "U049",
    "descricao": "Síndrome respiratória aguda grave [Severe acute respiratory syndrome) [SARS], não especificada"
  },
  { "codigo": "U800", "descricao": "Agente resistente à penicilina" },
  { "codigo": "U801", "descricao": "Agente resistente à meticilina" },
  {
    "codigo": "U808",
    "descricao": "Agente resistente a outro antibiótico relacionado à penicilina"
  },
  { "codigo": "U810", "descricao": "Agente resistente à vancomicina" },
  {
    "codigo": "U818",
    "descricao": "Agente resistente a outros antibióticos relacionado à vancomicina"
  },
  {
    "codigo": "U88",
    "descricao": "Agente resistente a múltiplos antibióticos"
  },
  {
    "codigo": "U898",
    "descricao": "Agente resistente a outro antibiótico especificado único"
  },
  {
    "codigo": "U899",
    "descricao": "Agente resistente a antibiótico não especificado"
  },
  {
    "codigo": "U99",
    "descricao": "CID 10 Revisão não disponível"
  }
]
