import React, {useState} from 'react'
import styles from '../classificacaoDecreto3048/ClassificacaoDecreto3048.module.css'

export const ClassificacaoDecreto3048 = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ classificacaoDecreto3048, setClassificacaoDecreto3048] = useState([])
   
    const adicionarClassificacaoDecreto3048 = (e) => {

        e.preventDefault()

        const arrClassificacaoDecreto3048 = []
    
        arrClassificacaoDecreto3048.push({
          //  perguntaClassificacaoDecreto30480: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoDecreto30480: formValues.respostaClassificacaoDecreto30480 !== undefined ? formValues.respostaClassificacaoDecreto30480 : '',
            comentarioClassificacaoDecreto30480: formValues.comentarioClassificacaoDecreto30480 !== undefined ? formValues.comentarioClassificacaoDecreto30480 : '',     
        })
        setClassificacaoDecreto3048([...arrClassificacaoDecreto3048])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _72_classificacaoDecreto3048: [{
           //     perguntaClassificacaoDecreto30480: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoDecreto30480: formValues.respostaClassificacaoDecreto30480 !== undefined ? formValues.respostaClassificacaoDecreto30480 : '',
         comentarioClassificacaoDecreto30480: formValues.comentarioClassificacaoDecreto30480 !== undefined ? formValues.comentarioClassificacaoDecreto30480 : '',
            }]
      }))
    }

    const excluirClassificacaoDecreto3048 = (e) => {
        e.preventDefault()  

        setClassificacaoDecreto3048([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _72_classificacaoDecreto3048: []
              }))
    }

    const handleCarregarFormClassificacaoDecreto3048 = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._72_classificacaoDecreto3048 && tudo._72_classificacaoDecreto3048.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._72_classificacaoDecreto3048 !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._72_classificacaoDecreto3048 !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._72_classificacaoDecreto3048 : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
   // perguntaClassificacaoDecreto30480: e !== undefined && e.perguntaClassificacaoDecreto30480 !== undefined ? e.perguntaClassificacaoDecreto30480 : '',
            respostaClassificacaoDecreto30480: e !== undefined &&  e.respostaClassificacaoDecreto30480 !== undefined ? e.respostaClassificacaoDecreto30480 : '',
            comentarioClassificacaoDecreto30480: e !== undefined &&  e.comentarioClassificacaoDecreto30480 !== undefined ? e.comentarioClassificacaoDecreto30480 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparClassificacaoDecreto3048 = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaClassificacaoDecreto3048'+e.target.attributes.value.value]: '', ['comentarioClassificacaoDecreto3048'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxClassificacaoDecreto3048} />
            <label htmlFor={styles.checkboxClassificacaoDecreto3048}>
                <h2 className={styles.titulo}>Classificacao Segundo Decreto 3048</h2>
            </label>
            <fieldset id={styles.conteudoClassificacaoDecreto3048}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {/* <th>Pergunta</th> */}
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0"> 
                        <th><button onClick={handleLimparClassificacaoDecreto3048}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>
                        <select 
                                name="respostaClassificacaoDecreto30480"
                                onChange={handleInputChange}
                                value={formValues.respostaClassificacaoDecreto30480 !== undefined ? formValues.respostaClassificacaoDecreto30480 : ''}
                            >
                                <option value="" defaultValue>Selecione:</option>
                                <option value="Classe 0 || Ausência de incapacidade laboral.">Classe 0 || Ausência de incapacidade laboral.</option>
                                    <option value="Classe I || Redução da capacidade para o trabalho que habitualmente exercia; conforme anexo III do Decreto 3048/99.">Classe I || Redução da capacidade para o trabalho que habitualmente exercia; conforme anexo III do Decreto 3048/99.</option>
                                    <option value="Classe II || Redução da capacidade para o trabalho que habitualmente exercia e exija maior esforço para o desempenho da mesma atividade que exercia à época do acidente.">Classe II || Redução da capacidade para o trabalho que habitualmente exercia e exija maior esforço para o desempenho da mesma atividade que exercia à época do acidente.</option>
                                    <option value="Classe III || Impossibilidade de desempenho da atividade que exercia à época do acidente, porém permita o desempenho de outra, após processo de reabilitação profissional.">Classe III || Impossibilidade de desempenho da atividade que exercia à época do acidente, porém permita o desempenho de outra, após processo de reabilitação profissional.</option>
                                    <option value="Classe IV || Incapacidade laboral total.">Classe IV || Incapacidade laboral total.</option>
                            </select> 
                            </th>
                        <th>
                        <textarea 
                            name="comentarioClassificacaoDecreto30480"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite seu comentário aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioClassificacaoDecreto30480 !== undefined ? formValues.comentarioClassificacaoDecreto30480 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarClassificacaoDecreto3048}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormClassificacaoDecreto3048}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados da Classificacao segundo Decreto 3048</legend>
            {
            classificacaoDecreto3048.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                           {/*  <th>Pergunta</th> */}
                            <th>Resposta</th>
                            <th>Comentário</th> 
                        </tr>
                    </thead>
                        {classificacaoDecreto3048.length > 0 && classificacaoDecreto3048.map(({ 
                           // perguntaClassificacaoDecreto30480,
                            respostaClassificacaoDecreto30480,
                            comentarioClassificacaoDecreto30480,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                               {/*  <th>{perguntaClassificacaoDecreto30480}</th> */}
                                <th>{respostaClassificacaoDecreto30480}</th>
                                 <th>{comentarioClassificacaoDecreto30480}</th> 
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirClassificacaoDecreto3048}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}
