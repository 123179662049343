import React, { useState, useEffect } from 'react'
import styles from './DadosPerito.module.css'

export const DadosPerito = () => {

  const [formValues, setFormValues] = useState({})

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === 'checkbox'
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  }

  const [dadosPerito, setDadosPerito] = useState({})


  const adicionarDadosPerito = () => {

    //console.log(formValues)

    setDadosPerito({
      nome: formValues.nome !== undefined && formValues.nome !== '' ? formValues.nome : 'Nome inválido!',
      cpf: formValues.cpf !== undefined && formValues.cpf.length === 14 ? formValues.cpf : 'CPF inválido!',
      email: formValues.emailInputado === formValues.email ? formValues.email : 'Email inválido!',
      numCrm: formValues.numCrm !== undefined && formValues.numCrm !== '' && formValues.numCrm.length > 3 ? formValues.numCrm : 'Número do CRM inválido!',
      estadoCrm: formValues.estadoCrm !== undefined && formValues.estadoCrm !== '' ? formValues.estadoCrm : 'Estado inválido!',
      telefone: formValues.telefone !== undefined && formValues.telefone !== '' && formValues.telefone.length === 15 ? formValues.telefone : 'Telefone inválido!',
    })

    localStorage.setItem('tudo', JSON.stringify({
      ...JSON.parse(localStorage.getItem('tudo')) || [],
      _81_dadosPerito: {
        nome: formValues.nome !== undefined && formValues.nome !== '' ? formValues.nome : '',
      cpf: formValues.cpf !== undefined && formValues.cpf.length === 14 ? formValues.cpf : '',
      email: formValues.emailInputado === formValues.email ? formValues.email : '',
      numCrm: formValues.numCrm !== undefined && formValues.numCrm !== '' && formValues.numCrm.length > 3 ? formValues.numCrm : '',
      estadoCrm: formValues.estadoCrm !== undefined && formValues.estadoCrm !== '' ? formValues.estadoCrm : '',
      telefone: formValues.telefone !== undefined && formValues.telefone !== '' && formValues.telefone.length === 15 ? formValues.telefone : '',
      } 
    }))   
  }

  const excluirDadosPerito = (e) => {
    e.preventDefault()
    if(e.target.offsetParent.nextElementSibling.innerText === dadosPerito.nome) {

      setDadosPerito({})
      
      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _81_dadosPerito: {}
      }))
    }
}


  useEffect(() => {


    if (formValues.cpf !== undefined) {
      //console.log(formValues.cpf.length)
        formValues.cpf = formValues.cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+$/, '$1')

        //console.log(formValues.cpf.length)
      }
    

    if (formValues.emailInputado !== undefined && formValues.emailInputado && formValues.emailInputado.length > 4) {

      const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/)
      const isValid = emailRegex.test(formValues.emailInputado)

      //console.log(formValues.emailInputado, '***isValid', isValid)

      if (isValid) {
        formValues.email = formValues.emailInputado 
      }
    } 

    if (formValues.numCrm !== undefined && formValues.numCrm.length > 3) {
      formValues.numCrm = formValues.numCrm
        .replace(/\D/g, '')     
    }

    if (formValues.telefone !== undefined ) {
//console.log(formValues.telefone.length)

formValues.telefone = formValues.telefone.replace(/\D/g, "")

if (formValues.telefone.length === 11) {
         formValues.telefone = formValues.telefone
        .replace(/\D/g, "")
        .replace(/^(\d{2})(\d)/g, "($1) $2")
        .replace(/(\d)(\d{4})$/, "$1-$2")    }

        //console.log(formValues.telefone.length)
    } 

  }, [
    formValues, dadosPerito
  ])


  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxDadosPerito} />
        <label htmlFor={styles.checkboxDadosPerito}>
          <h2 className={styles.titulo}>Dados Básicos do Perito</h2>
        </label>
        <fieldset id={styles.conteudoDadosPerito}>
          <table className={styles.table}></table>
          <input
            type="text"
            name="nome"
            placeholder="Nome completo do perito"
            onChange={handleInputChange}
            value={formValues.nome !== undefined ? formValues.nome : ''}
          />
          <input
            type="text"
            name="cpf"
            size="14"
            minLength="14"
            maxLength="14"
            placeholder="CPF"
            onChange={handleInputChange}
            value={formValues.cpf !== undefined ? formValues.cpf.trim()
              .replace(/\D/g, '')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d)/, '$1.$2')
              .replace(/(\d{3})(\d{1,2})/, '$1-$2')
              .replace(/(-\d{2})\d+$/, '$1') : ''}
          />
          <input
            type="text"
            name="emailInputado"
            placeholder="Email"
            onChange={handleInputChange}
            value={formValues.emailInputado !== undefined ? formValues.emailInputado.trim() : ''}
          />
          <input
            type="text"
            name="numCrm"
            size="6"
            minLength="6"
            maxLength="6"
            className="crm"
            placeholder="CRM"
            onChange={handleInputChange}
            value={formValues.numCrm !== undefined ? formValues.numCrm.trim().replace(/\D/g, '') : ''}
          />
          <select
            name="estadoCrm"
            className="estadoCrm"
            onChange={handleInputChange}
            value={formValues.estadoCrm !== undefined ? formValues.estadoCrm.trim() : ''}
          >
            <option value=''>Selecione o estado de inscrição do CRM</option>
            <option value='AC'>AC</option>
            <option value='AL'>AL</option>
            <option value='AM'>AM</option>
            <option value='AP'>AP</option>
            <option value='BA'>BA</option>
            <option value='CE'>CE</option>
            <option value='DF'>DF</option>
            <option value='MA'>MA</option>
            <option value='MG'>MG</option>
            <option value='MS'>MS</option>
            <option value='MT'>MT</option>
            <option value='PA'>PA</option>
            <option value='PB'>PB</option>
            <option value='PE'>PE</option>
            <option value='PI'>PI</option>
            <option value='PR'>PR</option>
            <option value='SP'>SP</option>
            <option value='RJ'>RJ</option>
            <option value='RN'>RN</option>
            <option value='RO'>RO</option>
            <option value='RR'>RR</option>
            <option value='RS'>RS</option>
            <option value='TO'>TO</option>
          </select>
          <input
            type="text"
            name="telefone"
            data-js="telefone"
            size="15"
            minLength="15"
            maxLength="15"
            placeholder="Telefone"
            onChange={handleInputChange}
            value={formValues.telefone !== undefined ? formValues.telefone.trim()
              .replace(/\D/g, "")
              .replace(/^(\d{2})(\d)/g, "($1) $2")
              .replace(/(\d)(\d{4})$/, "$1-$2")  : ''}
          />


          <button onClick={adicionarDadosPerito}>Adicionar Dados do Perito</button>
          <fieldset>
            <legend>Dados registrados do perito</legend>
            {!dadosPerito.nome ? <p>Sem dados registrados</p> :
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Email</th>
                      <th>CRM/Estado</th>
                      <th>Telefone</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {[dadosPerito].length > 0 && [dadosPerito].map(({
                      nome,
                      cpf,
                      email,
                      numCrm,
                      estadoCrm,
                      telefone,
                      
                    }, i) => {

                      return (
                        <tr key={i}>
                          <th><button onClick={excluirDadosPerito}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                          <th>{nome}</th>
                          <th>{cpf}</th>
                          <th>{email}</th>
                          <th>{numCrm} {estadoCrm}</th>
                          <th>{telefone}</th>
                          {/* <th>
                            {especialidade.length === 0 ? <p>Não registrado</p> : especialidade.map(({id, especialidade},i)=>{
                                return <p key={i}>{especialidade}</p>
                            })
                            }
                            </th> */}
                        </tr>)
                    }
                    )}
                  </tbody>
                </table>
              </>}
          </fieldset>
        </fieldset>
      </fieldset>
    </>
  )

}
