import React, {useState} from 'react'
import styles from '../classificacaoWelitonBarbosaSantos/ClassificacaoWelitonBarbosaSantos.module.css'

export const ClassificacaoWelitonBarbosaSantos = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ classificacaoWelitonBarbosaSantos, setClassificacaoWelitonBarbosaSantos] = useState([])
   
    const adicionarClassificacaoWelitonBarbosaSantos = (e) => {

        e.preventDefault()

        const arrClassificacaoWelitonBarbosaSantos = []
    
        arrClassificacaoWelitonBarbosaSantos.push({
          //  perguntaClassificacaoWelitonBarbosaSantos0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoWelitonBarbosaSantos0: formValues.respostaClassificacaoWelitonBarbosaSantos0 !== undefined ? formValues.respostaClassificacaoWelitonBarbosaSantos0 : '',
            comentarioClassificacaoWelitonBarbosaSantos0: formValues.comentarioClassificacaoWelitonBarbosaSantos0 !== undefined ? formValues.comentarioClassificacaoWelitonBarbosaSantos0 : '',     
        })
        setClassificacaoWelitonBarbosaSantos([...arrClassificacaoWelitonBarbosaSantos])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _71_classificacaoWelitonBarbosaSantos: [{
           //     perguntaClassificacaoWelitonBarbosaSantos0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaClassificacaoWelitonBarbosaSantos0: formValues.respostaClassificacaoWelitonBarbosaSantos0 !== undefined ? formValues.respostaClassificacaoWelitonBarbosaSantos0 : '',
         comentarioClassificacaoWelitonBarbosaSantos0: formValues.comentarioClassificacaoWelitonBarbosaSantos0 !== undefined ? formValues.comentarioClassificacaoWelitonBarbosaSantos0 : '',
            }]
      }))
    }

    const excluirClassificacaoWelitonBarbosaSantos = (e) => {
        e.preventDefault()  

        setClassificacaoWelitonBarbosaSantos([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _71_classificacaoWelitonBarbosaSantos: []
              }))
    }

    const handleCarregarFormClassificacaoWelitonBarbosaSantos = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._71_classificacaoWelitonBarbosaSantos && tudo._71_classificacaoWelitonBarbosaSantos.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._71_classificacaoWelitonBarbosaSantos !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._71_classificacaoWelitonBarbosaSantos !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._71_classificacaoWelitonBarbosaSantos : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
   // perguntaClassificacaoWelitonBarbosaSantos0: e !== undefined && e.perguntaClassificacaoWelitonBarbosaSantos0 !== undefined ? e.perguntaClassificacaoWelitonBarbosaSantos0 : '',
            respostaClassificacaoWelitonBarbosaSantos0: e !== undefined &&  e.respostaClassificacaoWelitonBarbosaSantos0 !== undefined ? e.respostaClassificacaoWelitonBarbosaSantos0 : '',
            comentarioClassificacaoWelitonBarbosaSantos0: e !== undefined &&  e.comentarioClassificacaoWelitonBarbosaSantos0 !== undefined ? e.comentarioClassificacaoWelitonBarbosaSantos0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparClassificacaoWelitonBarbosaSantos = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaClassificacaoWelitonBarbosaSantos'+e.target.attributes.value.value]: '', ['comentarioClassificacaoWelitonBarbosaSantos'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxClassificacaoWelitonBarbosaSantos} />
            <label htmlFor={styles.checkboxClassificacaoWelitonBarbosaSantos}>
                <h2 className={styles.titulo}>Classificacao de Weliton Barbosa Santos</h2>
            </label>
            <fieldset id={styles.conteudoClassificacaoWelitonBarbosaSantos}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {/* <th>Pergunta</th> */}
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0"> 
                        <th><button onClick={handleLimparClassificacaoWelitonBarbosaSantos}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>
                        <select 
                                name="respostaClassificacaoWelitonBarbosaSantos0"
                                onChange={handleInputChange}
                                value={formValues.respostaClassificacaoWelitonBarbosaSantos0 !== undefined ? formValues.respostaClassificacaoWelitonBarbosaSantos0 : ''}
                            >
                                <option value="" defaultValue>Selecione:</option>
                                <option value="Classe 1 (0-5%)|| A sequela é totalmente compatível com a atividade laborativa.">Classe 1 (0-5%)|| A sequela é totalmente compatível com a atividade laborativa.</option>
                                    <option value="Classe 2 (6-15%)|| A sequela determina um esforço acrescido para a realização da mesma atividade profissional.">Classe 2 (6-15%)|| A sequela determina um esforço acrescido para a realização da mesma atividade profissional.</option>
                                    <option value="Classe 3 (16-25%)|| A sequela afeta a função inerente ao desempenho do posto de trabalho, sem necessidade de nenhuma ajuda técnica.">Classe 3 (16-25%)|| A sequela afeta a função inerente ao desempenho do posto de trabalho, sem necessidade de nenhuma ajuda técnica.</option>
                                    <option value="Classe 4 (26-35%)|| A sequela exige uma ajuda técnica, para realizar a mesma função.">Classe 4 (26-35%)|| A sequela exige uma ajuda técnica, para realizar a mesma função.</option>
                                    <option value="Classe 5 (36-50%)|| É necessária uma reabilitação profissional, as possibilidades técnico-profissionais não interferem na capacidade de produção e ganho.">Classe 5 (36-50%)|| É necessária uma reabilitação profissional, as possibilidades técnico-profissionais não interferem na capacidade de produção e ganho.</option>
                                    <option value="Classe 6 (51-60%)|| A sequela determina uma reabilitação, necessitando uma ajuda técnica, apesar de se manterem no mesmo nível técnico profissional.">Classe 6 (51-60%)|| A sequela determina uma reabilitação, necessitando uma ajuda técnica, apesar de se manterem no mesmo nível técnico profissional.</option>
                                    <option value="Classe 7 (61-70%)|| A sequela permite uma reabilitação, mas num nível técnico-profissional inferior ao da época do acidente.">Classe 7 (61-70%)|| A sequela permite uma reabilitação, mas num nível técnico-profissional inferior ao da época do acidente.</option>
                                    <option value="Classe 8 (71-80%)|| A sequela permite uma reabilitação, mas num nível técnico-profissional inferior ao da época do acidente com redução da capacidade de produção.">Classe 8 (71-80%)|| A sequela permite uma reabilitação, mas num nível técnico-profissional inferior ao da época do acidente com redução da capacidade de produção.</option>
                                    <option value="Classe 9 (81-100%)|| Insusceptível de reabilitação.">Classe 9 (81-100%)|| Insusceptível de reabilitação.</option>
                            </select> 
                            </th>
                        <th>
                        <textarea 
                            name="comentarioClassificacaoWelitonBarbosaSantos0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite seu comentário aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioClassificacaoWelitonBarbosaSantos0 !== undefined ? formValues.comentarioClassificacaoWelitonBarbosaSantos0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarClassificacaoWelitonBarbosaSantos}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormClassificacaoWelitonBarbosaSantos}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados da Classificacao de Weliton Barbosa Santos</legend>
            {
            classificacaoWelitonBarbosaSantos.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                           {/*  <th>Pergunta</th> */}
                            <th>Resposta</th>
                            <th>Comentário</th> 
                        </tr>
                    </thead>
                        {classificacaoWelitonBarbosaSantos.length > 0 && classificacaoWelitonBarbosaSantos.map(({ 
                           // perguntaClassificacaoWelitonBarbosaSantos0,
                            respostaClassificacaoWelitonBarbosaSantos0,
                            comentarioClassificacaoWelitonBarbosaSantos0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                               {/*  <th>{perguntaClassificacaoWelitonBarbosaSantos0}</th> */}
                                <th>{respostaClassificacaoWelitonBarbosaSantos0}</th>
                                 <th>{comentarioClassificacaoWelitonBarbosaSantos0}</th> 
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirClassificacaoWelitonBarbosaSantos}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )   
}