import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../services/firebaseConfig";

const provider = new GoogleAuthProvider();

export const AuthGoogleContext = createContext();

export const AuthGoogleProvider = ({ children }) => {
  const [dadosUsuario, setDadosUsuario] = useState(null);

  useEffect(() => {
    const carregaSessionStorage = () => {
      const sessionToken = sessionStorage.getItem("@AuthFirebase:token");
      const sessionUser = JSON.parse(
        sessionStorage.getItem("@AuthFirebase:user")
      );
      //console.log(sessionUser)

      if (
        sessionToken &&
        sessionUser.emailVerified === true &&
        sessionUser.isAnonimous === false
      ) {
        setDadosUsuario(sessionUser);
      }
    };

    carregaSessionStorage();
  }, []);

  const entrarComGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        setDadosUsuario(user);
        //console.log(dadosUsuario)

        sessionStorage.setItem("@AuthFirebase:token", token);
        sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
      })
      .catch((error) => {
        console.log(error);
        /* const errorCode = error.code
          const errorMessage = error.message
          const email = error.customData.email
          const credential = GoogleAuthProvider.credentialFromError(error) */
      });
  };

  const sairComGoogle = () => {
    sessionStorage.clear();
    setDadosUsuario(null);
    return <Navigate to="/" />;
  };

  return (
    <AuthGoogleContext.Provider
      value={{
        entrarComGoogle,
        logado: !!dadosUsuario,
        dadosUsuario,
        sairComGoogle,
      }}
    >
      {children}
    </AuthGoogleContext.Provider>
  );
};
