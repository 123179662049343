import React, { useContext, useState } from "react";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../services/firebaseConfig";
import styles from "../admin2/Admin2.module.css";
import { TipoUsuarioContexto } from "../../../contexts/tipoUsuarioContexto";

export const Admin2 = () => {
  const { usuario } =
    useContext(TipoUsuarioContexto);
  const [dadosPerito, setDadosPerito] = useState([]);

  const mostrarDadosPeritoBd = async () => {
    if (usuario) {
      try {
        const getRefs = (docs) => {
          return docs.map((doc) =>
            doc ? doc.ref : console.log(`Um erro ocorreu`)
          );
        };
        const getIds = (refs) => {
          return refs.map((ref) =>
            ref ? ref.id : console.log(`Um erro ocorreu`)
          );
        };
        const getPaths = (refs) => {
          return refs.map((ref) =>
            ref ? ref.path : console.log(`Um erro ocorreu`)
          );
        };
        const getDatas = (docs) => {
          return docs.map((doc) =>
            doc ? doc.data() : console.log(`Um erro ocorreu`)
          );
        };
        const getPropsPerito = (docdata) => {
          return docdata.map((x) =>
            x ? x.propsPerito : console.log(`Um erro ocorreu`)
          );
        };

        const analiseBd = {
          peritos: {
            docRef: collection(db, "users"),
            docs: (await getDocs(collection(db, "users"))).docs,
            refs: getRefs((await getDocs(collection(db, "users"))).docs),
            ids: getIds(getRefs((await getDocs(collection(db, "users"))).docs)),
            paths: getPaths(
              getRefs((await getDocs(collection(db, "users"))).docs)
            ),
            datas: getDatas((await getDocs(collection(db, "users"))).docs),
            propsPerito: getPropsPerito(
              getDatas((await getDocs(collection(db, "users"))).docs)
            ),
          },
        };

        const { datas, docs, ids, paths, refs, propsPerito } =
          analiseBd.peritos;

        const separadorPerito = (
          array1,
          array2,
          array3,
          array4,
          array5,
          array6
        ) => {
          const x = [];
          array1.map((e, i) => {
            const y = [].concat({
              numeroProcesso: array1[i] ? array1[i] : [],
              path: array2[i] ? array2[i] : [],
              data: array3[i] ? array3[i] : [],
              doc: array4[i] ? array4[i] : [],
              ref: array5[i] ? array5[i] : [],
              propsPerito: array6[i] ? array6[i] : [],
            });
            return x.push(...y);
          });

          return x;
        };
        const dadosPeritoBasico = separadorPerito(
          ids,
          paths,
          datas,
          docs,
          refs,
          propsPerito
        );
  
        setDadosPerito([dadosPeritoBasico[0].propsPerito]);
        //console.log([dadosPeritoBasico[0].propsPerito])
        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _99_dadosPeritoBd: [dadosPeritoBasico[0].propsPerito],
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  //console.log(dadosPerito);

  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxAdmin2} />
        <label htmlFor={styles.checkboxAdmin2}>
          <h2 className={styles.titulo}>Mostrar análise de BD</h2>
        </label>
        <fieldset id={styles.conteudoAdmin2}>
          <table className={styles.table}></table>
          <button onClick={mostrarDadosPeritoBd}>BD</button>
          <fieldset>
            <legend>Dados registrados da análise de BD</legend>
            {dadosPerito && dadosPerito.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {dadosPerito && dadosPerito.lenght === 0 ? (
                    <p>Não registrado</p>
                  ) : (
                    dadosPerito.map(
                      (
                        {
                          crms,
                          endCom,
                          codigoSecreto,
                          especialidades,
                          uidSecretaria,
                          acessoSecretaria,
                        },
                        i
                      ) => {
                        return (
                          <>
                            <tbody key={i}>
                              <tr>
                                <th>acessoSecretaria</th>
                                <th>
                                  {acessoSecretaria
                                    ? acessoSecretaria.toString()
                                    : ""}
                                </th>
                              </tr>
                              <tr>
                                <th>codigoSecreto</th>
                                <th>
                                  {codigoSecreto
                                    ? codigoSecreto.toString()
                                    : ""}
                                </th>
                              </tr>
                              <tr>
                                <th>uidSecretaria</th>
                                <th>
                                  {uidSecretaria
                                    ? uidSecretaria.toString()
                                    : ""}
                                </th>
                              </tr>

                              {[crms] && [crms.crm].lenght === 0 ? (
                                <p>Não registrado</p>
                              ) : (
                                [crms.crm].map(
                                  ({ numeroCrm, estadoCrm }, i) => {
                                    return (
                                      <>
                                        <tr key={i}>
                                          <th>numeroCrmestadoCrm</th>
                                          <th>
                                            {numeroCrm ? numeroCrm : ""}
                                            {estadoCrm ? estadoCrm : ""}
                                          </th>
                                        </tr>
                                      </>
                                    );
                                  }
                                )
                              )}

                              {[especialidades] &&
                              [especialidades].lenght === 0 ? (
                                <p>Não registrado</p>
                              ) : (
                                [especialidades].map(({ especialidade }, i) => {
                                  return (
                                    <>
                                      <tr key={i}>
                                        <th>Especialidade</th>
                                        <th>
                                          {especialidade ? especialidade : ""}
                                        </th>
                                      </tr>
                                    </>
                                  );
                                })
                              )}

                              {[endCom] && [endCom].lenght === 0 ? (
                                <p>Não registrado</p>
                              ) : (
                                [endCom].map(
                                  (
                                    {
                                      logradouro,
                                      numero,
                                      complemento,
                                      bairro,
                                      cidade,
                                      estado,
                                      cep,
                                      coordenadas,
                                    },
                                    i
                                  ) => {
                                    return (
                                      <>
                                        <tr key={i}>
                                          <th>endCom</th>
                                          <th>
                                            {logradouro}
                                            {numero}
                                            {complemento}
                                            {bairro}
                                            {cidade}
                                            {estado}
                                            {cep}
                                          </th>
                                        </tr>

                                        {[coordenadas] &&
                                        [coordenadas].lenght === 0 ? (
                                          <p>Não registrado</p>
                                        ) : (
                                          [coordenadas].map(
                                            ({ latitude, longitude }, i) => {
                                              return (
                                                <>
                                                  <tr key={i}>
                                                    <th>coordenadas</th>
                                                    <th>
                                                      {latitude}
                                                      {longitude}
                                                    </th>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        )}
                                      </>
                                    );
                                  }
                                )
                              )}
                            </tbody>
                          </>
                        );
                      }
                    )
                  )}
                </table>
              </>
            )}
          </fieldset>
        </fieldset>
      </fieldset>
    </>
  );
};

export const GravarDadosPerito = () => {
  const { usuario } = useContext(TipoUsuarioContexto);

  const gravarDadosPerito = async () => {
    if (usuario) {
      const tudo = JSON.parse(localStorage.getItem("tudo"));
      
      await setDoc(
        doc(db, "users", usuario.uid),
        { propsPerito: tudo._99_dadosPeritoBd },
        { merge: true } 
      );
  
      alert("Dados salvos no firebase com sucesso!");
    }
  };

  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxGravarDadosPerito} />
        <label htmlFor={styles.checkboxGravarDadosPerito}>
          <h2 className={styles.titulo}>Salvar Perito</h2>
        </label>
        <fieldset id={styles.conteudoGravarDadosPerito}>
          <button onClick={gravarDadosPerito}>Salvar</button>
        </fieldset>
      </fieldset>
    </>
  );
};

export const GravarDadosUsuario = () => {
  const { usuario } = useContext(TipoUsuarioContexto);

  const gravarDadosUsuario = async () => {
    if (usuario) {
      const tudo = JSON.parse(localStorage.getItem("tudo"));
    
      await setDoc(
        doc(db, "users", usuario.uid),
        { propsUsuario: tudo._99_dadosUsuarioBd },
        { merge: true } 
      );

      alert("Dados salvos no firebase com sucesso!");
    }
  };

  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxGravarDadosUsuario} />
        <label htmlFor={styles.checkboxGravarDadosUsuario}>
          <h2 className={styles.titulo}>Salvar Usuario</h2>
        </label>
        <fieldset id={styles.conteudoGravarDadosUsuario}>
          <button onClick={gravarDadosUsuario}>Salvar</button>
        </fieldset>
      </fieldset>
    </>
  );
};
