import React, {useState} from 'react'
import styles from '../nexoProfissional/NexoProfissional.module.css'

import { NtepFap3Modificado } from '../../../data/NtepFap3Modificado'

export const NexoProfissional = () => {

    const [ nexoProfissional, setNexoProfissional] = useState([])

    const adicionarNexoProfissional = (e) => {
        e.preventDefault()

        const getAtestados = localStorage.getItem('tudo') !== null && localStorage.getItem('tudo')._11_atestados !== null ? JSON.parse(localStorage.getItem('tudo'))._11_atestados : localStorage.setItem('tudo', JSON.stringify({...JSON.parse(localStorage.getItem('tudo')) || [], _51_nexoProfissional: []}))

        let cidAtestados = []
        if(getAtestados !== null && getAtestados !== undefined) {
            getAtestados.map(({cid},i)=>{
                return cidAtestados.push(...cid)
            })
        }
        

        const cidModificado = []
        cidAtestados.map(({codigo, descricao}, i)=>{
            
            if (codigo.length <3) {
                cidModificado.push({
                    codigo: codigo+'.-',
                    descricao: descricao
            })
            } else {
                cidModificado.push({
                    codigo: codigo,
                    descricao: descricao
                })
            } 
            //console.log('***cidAtestados',codigo.length, typeof(codigo), codigo.substring(0,2))
            return null   
        })
        //console.log('***cidModificado',cidModificado)

        const arrNexoProfissional = []
        cidModificado.map(({codigo, descricao}, i)=>{
            NtepFap3Modificado.filter(({
                idNtepFap3,
                tipoDoenca,
                grupo,
                patologias, 
                agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional
            }, i)=>{
                
               // const b =  (patologias.slice(-2-4,-1))
                //console.log(b, patologias) 
                
                /* if (b.includes(codigo.substring(0,3)+'.'+codigo.substring(3)) || b.includes(codigo)){ */
                if (patologias.includes('('+ codigo.substring(0,3)+'.'+codigo.substring(3)+')') || patologias.includes('('+codigo.substring(0,3)+'.-)')){   
                    return arrNexoProfissional.push({
                        id: String(i),
                        codigo: codigo,
                        descricao: descricao,
                        nexoProfissional: 'Existe nexo profissional',
                        idNtep: idNtepFap3 || '',
                        tipoDoenca: tipoDoenca || '',
                        grupo: grupo || '',
                        patologias: patologias || '',
                        agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional: agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional || ''
                    })    
                } 
                return null       
            })
            return null
        })
        
        let x = []
    arrNexoProfissional.map(({codigo})=> x.push(codigo))

    cidAtestados.map(({codigo, descricao},i)=>{
        if(!x.includes(codigo)){
            return arrNexoProfissional.push({
                id: String(i),
                codigo: codigo,
                descricao: descricao,
                nexoProfissional: 'Não existe nexo profissional',
                idNtep: '',
                tipoDoenca:'',
                grupo:'',
                patologias:'',
                agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional: ''
            }) 
        }
        return null
    })
 
        setNexoProfissional(arrNexoProfissional) 

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _51_nexoProfissional: arrNexoProfissional
      }))
        
   }

   const excluirNexoProfissional = (e) => {
    e.preventDefault()      
     nexoProfissional.filter((
        el, i
    )=>{
        if (e.target.attributes.value !== undefined && e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                
            nexoProfissional.splice(i, 1)
            setNexoProfissional([...nexoProfissional])

            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _51_nexoProfissional: nexoProfissional
          }))
        }    
        return null
    }) 
}

const handleCarregarFormNexoProfissional = () => {
        
    const tudo = JSON.parse(localStorage.getItem('tudo'))
    if (tudo._51_nexoProfissional && tudo._51_nexoProfissional.length > 0) {
        const a = JSON.parse(localStorage.getItem('tudo'))._51_nexoProfissional !== undefined
        ||JSON.parse(localStorage.getItem('tudo'))._51_nexoProfissional !== null ? 
        JSON.parse(localStorage.getItem('tudo'))._51_nexoProfissional : []

        //console.log('***',a)
        const arrNexoProfissional = []
    a.map((e)=> 
        arrNexoProfissional.push({
            codigo: e !== undefined && e.codigo !== undefined ? e.codigo : '',
            descricao: e !== undefined &&  e.descricao !== undefined ? e.descricao : '',
            nexoProfissional: e !== undefined &&  e.nexoProfissional !== undefined ? e.nexoProfissional : '',
            tipoDoenca: e !== undefined &&  e.tipoDoenca !== undefined ? e.tipoDoenca : '',
            grupo: e !== undefined && e.grupo !== undefined ? e.grupo : '',
            patologias: e !== undefined &&  e.patologias !== undefined ? e.patologias : '',
            agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional: e !== undefined && e.agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional !== undefined ? e.agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional : '',   
        }))
        
        setNexoProfissional(arrNexoProfissional)
}

}

const handleReset = () => {
    setNexoProfissional([])
}

//console.log(nexoProfissional)

   return (
    <>
    <fieldset>
        <legend></legend>
    <input type="checkbox" id={styles.checkboxNexoProfissional} />
            <label htmlFor={styles.checkboxNexoProfissional}>
                <h2 className={styles.titulo}>Nexo Profissional</h2>
            </label>
            <fieldset id={styles.conteudoNexoProfissional}>  
            <table className={styles.table}></table>
            <div>
            <button onClick={adicionarNexoProfissional}><i className="fas fa-plus"></i></button>
    <button onClick={handleCarregarFormNexoProfissional}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
        <legend>Nexo Profissional Registrado</legend>
        {nexoProfissional.length === 0 ? <p>Sem dados registrados</p> :
        <table>
        <thead>
            <tr>
                <th>Ação</th>
                <th>CID</th>
                <th>Nexo Profissional</th>
                <th>Patologias</th>
                <th>Agentes Etiológicos</th>
            </tr>
        </thead>
        <tbody>
          { nexoProfissional.length > 0 ?
            nexoProfissional.map(({
                id,
                codigo, 
                descricao,
                nexoProfissional,
                patologias, 
                agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional, 
            }, i) => {
                return (
                    <tr key={i}>
                        <th><button onClick={excluirNexoProfissional}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                        <th>{codigo} - {descricao}</th>
                        <th>{nexoProfissional}</th>
                        <th>{patologias}</th>
                        <th>{agentesEtiologicosOuFatoresDeRiscoDeNaturezaOcupacional}</th>
                    </tr>
                )                                                                                             
            }) : []
        }  
        </tbody>
    </table>}
    </fieldset>   
    </fieldset>
    </fieldset>
   </>
  )
}
