import React, {useState} from 'react'
import styles from '../criteriosConcausa/CriteriosConcausa.module.css'

export const CriteriosConcausa = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
      //  console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ criteriosConcausa, setCriteriosConcausa] = useState([])
   
    const adicionarCriteriosConcausa = (e) => {

        e.preventDefault()

        const arrCriteriosConcausa = []
    
        arrCriteriosConcausa.push({
            perguntaCriteriosConcausa0: "Natureza da exposição: o “agente patogênico” é claramente identificável pela história ocupacional e/ou pelas informações colhidas no local de trabalho e/ou de fontes idôneas familiarizadas com o ambiente ou local de trabalho do Segurado?",
            respostaCriteriosConcausa0: formValues.respostaCriteriosConcausa0 !== undefined ? formValues.respostaCriteriosConcausa0 : '',
            comentarioCriteriosConcausa0: formValues.comentarioCriteriosConcausa0 !== undefined ? formValues.comentarioCriteriosConcausa0 : '',
            perguntaCriteriosConcausa1: "Especificidade” da relação causal e “força” da associação causal: o “agente patogênico” ou o “fator de risco” podem estar pesando de forma importante entre os fatores causais da doença?",
            respostaCriteriosConcausa1: formValues.respostaCriteriosConcausa1 !== undefined ? formValues.respostaCriteriosConcausa1 : '',
            comentarioCriteriosConcausa1: formValues.comentarioCriteriosConcausa1 !== undefined ? formValues.comentarioCriteriosConcausa1 : '',
            perguntaCriteriosConcausa2: "Tipo de relação causal com o trabalho: o trabalho é causa necessária (Tipo I)? Fator de risco contributivo de doença de  etiologia multicausal (Tipo II)? Fator desencadeante ou agravante de doença pré-existente (Tipo III)?",
            respostaCriteriosConcausa2: formValues.respostaCriteriosConcausa2 !== undefined ? formValues.respostaCriteriosConcausa2 : '',
            comentarioCriteriosConcausa2: formValues.comentarioCriteriosConcausa2 !== undefined ? formValues.comentarioCriteriosConcausa2 : '',
            perguntaCriteriosConcausa3: "No caso de doenças relacionadas com o trabalho, do tipo II, foram as outras causas gerais, não ocupacionais, devidamente analisadas e, no caso concreto, excluídas ou colocadas em hierarquia inferior às causas de natureza ocupacional?",
            respostaCriteriosConcausa3: formValues.respostaCriteriosConcausa3 !== undefined ? formValues.respostaCriteriosConcausa3 : '',
            comentarioCriteriosConcausa3: formValues.comentarioCriteriosConcausa3 !== undefined ? formValues.comentarioCriteriosConcausa3 : '',
            perguntaCriteriosConcausa4: "Grau ou intensidade da exposição: é ele compatível com a produção da doença?",
            respostaCriteriosConcausa4: formValues.respostaCriteriosConcausa4 !== undefined ? formValues.respostaCriteriosConcausa4 : '',
            comentarioCriteriosConcausa4: formValues.comentarioCriteriosConcausa4 !== undefined ? formValues.comentarioCriteriosConcausa4 : '',
            perguntaCriteriosConcausa5: "Tempo de exposição: é ele suficiente para produzir a doença?",
            respostaCriteriosConcausa5: formValues.respostaCriteriosConcausa5 !== undefined ? formValues.respostaCriteriosConcausa5 : '',
            comentarioCriteriosConcausa5: formValues.comentarioCriteriosConcausa5 !== undefined ? formValues.comentarioCriteriosConcausa5 : '',
            perguntaCriteriosConcausa6: "Tempo de latência: é suficiente para que a doença se desenvolva e apareça?",
            respostaCriteriosConcausa6: formValues.respostaCriteriosConcausa6 !== undefined ? formValues.respostaCriteriosConcausa6 : '',
            comentarioCriteriosConcausa6: formValues.comentarioCriteriosConcausa6 !== undefined ? formValues.comentarioCriteriosConcausa6 : '',
            perguntaCriteriosConcausa7: "Há o registro do “estado anterior” do trabalhador segurado?",
            respostaCriteriosConcausa7: formValues.respostaCriteriosConcausa7 !== undefined ? formValues.respostaCriteriosConcausa7 : '',
            comentarioCriteriosConcausa7: formValues.comentarioCriteriosConcausa7 !== undefined ? formValues.comentarioCriteriosConcausa7 : '',     
        })
        setCriteriosConcausa([...arrCriteriosConcausa])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _62_criteriosConcausa: [{
                perguntaCriteriosConcausa0: "Natureza da exposição: o “agente patogênico” é claramente identificável pela história ocupacional e/ou pelas informações colhidas no local de trabalho e/ou de fontes idôneas familiarizadas com o ambiente ou local de trabalho do Segurado?",
            respostaCriteriosConcausa0: formValues.respostaCriteriosConcausa0 !== undefined ? formValues.respostaCriteriosConcausa0 : '',
            comentarioCriteriosConcausa0: formValues.comentarioCriteriosConcausa0 !== undefined ? formValues.comentarioCriteriosConcausa0 : '',
            perguntaCriteriosConcausa1: "Especificidade” da relação causal e “força” da associação causal: o “agente patogênico” ou o “fator de risco” podem estar pesando de forma importante entre os fatores causais da doença?",
            respostaCriteriosConcausa1: formValues.respostaCriteriosConcausa1 !== undefined ? formValues.respostaCriteriosConcausa1 : '',
            comentarioCriteriosConcausa1: formValues.comentarioCriteriosConcausa1 !== undefined ? formValues.comentarioCriteriosConcausa1 : '',
            perguntaCriteriosConcausa2: "Tipo de relação causal com o trabalho: o trabalho é causa necessária (Tipo I)? Fator de risco contributivo de doença de  etiologia multicausal (Tipo II)? Fator desencadeante ou agravante de doença pré-existente (Tipo III)?",
            respostaCriteriosConcausa2: formValues.respostaCriteriosConcausa2 !== undefined ? formValues.respostaCriteriosConcausa2 : '',
            comentarioCriteriosConcausa2: formValues.comentarioCriteriosConcausa2 !== undefined ? formValues.comentarioCriteriosConcausa2 : '',
            perguntaCriteriosConcausa3: "No caso de doenças relacionadas com o trabalho, do tipo II, foram as outras causas gerais, não ocupacionais, devidamente analisadas e, no caso concreto, excluídas ou colocadas em hierarquia inferior às causas de natureza ocupacional?",
            respostaCriteriosConcausa3: formValues.respostaCriteriosConcausa3 !== undefined ? formValues.respostaCriteriosConcausa3 : '',
            comentarioCriteriosConcausa3: formValues.comentarioCriteriosConcausa3 !== undefined ? formValues.comentarioCriteriosConcausa3 : '',
            perguntaCriteriosConcausa4: "Grau ou intensidade da exposição: é ele compatível com a produção da doença?",
            respostaCriteriosConcausa4: formValues.respostaCriteriosConcausa4 !== undefined ? formValues.respostaCriteriosConcausa4 : '',
            comentarioCriteriosConcausa4: formValues.comentarioCriteriosConcausa4 !== undefined ? formValues.comentarioCriteriosConcausa4 : '',
            perguntaCriteriosConcausa5: "Tempo de exposição: é ele suficiente para produzir a doença?",
            respostaCriteriosConcausa5: formValues.respostaCriteriosConcausa5 !== undefined ? formValues.respostaCriteriosConcausa5 : '',
            comentarioCriteriosConcausa5: formValues.comentarioCriteriosConcausa5 !== undefined ? formValues.comentarioCriteriosConcausa5 : '',
            perguntaCriteriosConcausa6: "Tempo de latência: é suficiente para que a doença se desenvolva e apareça?",
            respostaCriteriosConcausa6: formValues.respostaCriteriosConcausa6 !== undefined ? formValues.respostaCriteriosConcausa6 : '',
            comentarioCriteriosConcausa6: formValues.comentarioCriteriosConcausa6 !== undefined ? formValues.comentarioCriteriosConcausa6 : '',
            perguntaCriteriosConcausa7: "Há o registro do “estado anterior” do trabalhador segurado?",
            respostaCriteriosConcausa7: formValues.respostaCriteriosConcausa7 !== undefined ? formValues.respostaCriteriosConcausa7 : '',
            comentarioCriteriosConcausa7: formValues.comentarioCriteriosConcausa7 !== undefined ? formValues.comentarioCriteriosConcausa7 : '',
            }]
      }))
    }

    const excluirCriteriosConcausa = (e) => {
        e.preventDefault()  

        setCriteriosConcausa([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _62_criteriosConcausa: []
              }))
    }

    const handleCarregarFormCriteriosConcausa = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._62_criteriosConcausa && tudo._62_criteriosConcausa.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._62_criteriosConcausa !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._62_criteriosConcausa !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._62_criteriosConcausa : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaCriteriosConcausa0: e !== undefined && e.perguntaCriteriosConcausa0 !== undefined ? e.perguntaCriteriosConcausa0 : '',
            respostaCriteriosConcausa0: e !== undefined &&  e.respostaCriteriosConcausa0 !== undefined ? e.respostaCriteriosConcausa0 : '',
            comentarioCriteriosConcausa0: e !== undefined &&  e.comentarioCriteriosConcausa0 !== undefined ? e.comentarioCriteriosConcausa0 : '',
            perguntaCriteriosConcausa1: e !== undefined && e.perguntaCriteriosConcausa1 !== undefined ? e.perguntaCriteriosConcausa1 : '',
            respostaCriteriosConcausa1: e !== undefined &&  e.respostaCriteriosConcausa1 !== undefined ? e.respostaCriteriosConcausa1 : '',
            comentarioCriteriosConcausa1: e !== undefined &&  e.comentarioCriteriosConcausa1 !== undefined ? e.comentarioCriteriosConcausa1 : '',
            perguntaCriteriosConcausa2: e !== undefined && e.perguntaCriteriosConcausa2 !== undefined ? e.perguntaCriteriosConcausa2 : '',
            respostaCriteriosConcausa2: e !== undefined &&  e.respostaCriteriosConcausa2 !== undefined ? e.respostaCriteriosConcausa2 : '',
            comentarioCriteriosConcausa2: e !== undefined &&  e.comentarioCriteriosConcausa2 !== undefined ? e.comentarioCriteriosConcausa2 : '',
            perguntaCriteriosConcausa3: e !== undefined && e.perguntaCriteriosConcausa3 !== undefined ? e.perguntaCriteriosConcausa3 : '',
            respostaCriteriosConcausa3: e !== undefined &&  e.respostaCriteriosConcausa3 !== undefined ? e.respostaCriteriosConcausa3 : '',
            comentarioCriteriosConcausa3: e !== undefined &&  e.comentarioCriteriosConcausa3 !== undefined ? e.comentarioCriteriosConcausa3 : '',
            perguntaCriteriosConcausa4: e !== undefined && e.perguntaCriteriosConcausa4 !== undefined ? e.perguntaCriteriosConcausa4 : '',
            respostaCriteriosConcausa4: e !== undefined &&  e.respostaCriteriosConcausa4 !== undefined ? e.respostaCriteriosConcausa4 : '',
            comentarioCriteriosConcausa4: e !== undefined &&  e.comentarioCriteriosConcausa4 !== undefined ? e.comentarioCriteriosConcausa4 : '',
            perguntaCriteriosConcausa5: e !== undefined && e.perguntaCriteriosConcausa5 !== undefined ? e.perguntaCriteriosConcausa5 : '',
            respostaCriteriosConcausa5: e !== undefined &&  e.respostaCriteriosConcausa5 !== undefined ? e.respostaCriteriosConcausa5 : '',
            comentarioCriteriosConcausa5: e !== undefined &&  e.comentarioCriteriosConcausa5 !== undefined ? e.comentarioCriteriosConcausa5 : '',
            perguntaCriteriosConcausa6: e !== undefined && e.perguntaCriteriosConcausa6 !== undefined ? e.perguntaCriteriosConcausa6 : '',
            respostaCriteriosConcausa6: e !== undefined &&  e.respostaCriteriosConcausa6 !== undefined ? e.respostaCriteriosConcausa6 : '',
            comentarioCriteriosConcausa6: e !== undefined &&  e.comentarioCriteriosConcausa6 !== undefined ? e.comentarioCriteriosConcausa6 : '',
            perguntaCriteriosConcausa7: e !== undefined && e.perguntaCriteriosConcausa7 !== undefined ? e.perguntaCriteriosConcausa7 : '',
            respostaCriteriosConcausa7: e !== undefined &&  e.respostaCriteriosConcausa7 !== undefined ? e.respostaCriteriosConcausa7 : '',
            comentarioCriteriosConcausa7: e !== undefined &&  e.comentarioCriteriosConcausa7 !== undefined ? e.comentarioCriteriosConcausa7 : '', 
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparCriteriosConcausa = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaCriteriosConcausa'+e.target.attributes.value.value]: '', ['comentarioCriteriosConcausa'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxCriteriosConcausa} />
            <label htmlFor={styles.checkboxCriteriosConcausa}>
                <h2 className={styles.titulo}>Criterios de Concausa</h2>
            </label>
            <fieldset id={styles.conteudoCriteriosConcausa}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="A doença discutida nos autos tem origem multicausal?"> 
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>A doença discutida nos autos tem origem multicausal?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosConcausa0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa0 !== undefined ? formValues.respostaCriteriosConcausa0 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosConcausa0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa0 !== undefined ? formValues.respostaCriteriosConcausa0 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa0 !== undefined ? formValues.respostaCriteriosConcausa0 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa0 !== undefined ? formValues.respostaCriteriosConcausa0 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa0"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa0 !== undefined ? formValues.comentarioCriteriosConcausa0 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="No caso de doença multicausal, foi encontrado efetivamente nas atividades laborais existência de fator de risco capaz de agravar a doença ou atuar de forma concorrente no aparecimento do dano, considerando tempo e intensidade de exposição?">
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'1'}></i></button></th>
                        <th>No caso de doença multicausal, foi encontrado efetivamente nas atividades laborais existência de fator de risco capaz de agravar a doença ou atuar de forma concorrente no aparecimento do dano, considerando tempo e intensidade de exposição?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosConcausa1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa1 !== undefined ? formValues.respostaCriteriosConcausa1 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosConcausa1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa1 !== undefined ? formValues.respostaCriteriosConcausa1 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa1 !== undefined ? formValues.respostaCriteriosConcausa1 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa1 !== undefined ? formValues.respostaCriteriosConcausa1 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa1"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa1 !== undefined ? formValues.comentarioCriteriosConcausa1 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Identificado o risco, há como afirmar que o mesmo atuou de forma a alterar a história natural de evolução da doença?">
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'2'}></i></button></th>
                        <th>Identificado o risco, há como afirmar que o mesmo atuou de forma a alterar a história natural de evolução da doença?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosConcausa2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa2 !== undefined ? formValues.respostaCriteriosConcausa2 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosConcausa2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa2 !== undefined ? formValues.respostaCriteriosConcausa2 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa2 !== undefined ? formValues.respostaCriteriosConcausa2 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa2 !== undefined ? formValues.respostaCriteriosConcausa2 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa2"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa2 !== undefined ? formValues.comentarioCriteriosConcausa2 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Trata-se de concausa pré existente ou concorrente? Identifica-se a presença de concausas supervenientes?">
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'3'}></i></button></th>
                        <th>Trata-se de concausa pré existente ou concorrente? Identifica-se a presença de concausas supervenientes?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Pré existente"
                                    name="respostaCriteriosConcausa3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa3 !== undefined ? formValues.respostaCriteriosConcausa3 === 'Pré existente' : ''}
                                />
                                    Pré existente
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Concorrente"
                                    name="respostaCriteriosConcausa3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa3 !== undefined ? formValues.respostaCriteriosConcausa3 === 'Concorrente' : ''}
                                />
                                    Concorrente
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa3 !== undefined ? formValues.respostaCriteriosConcausa3 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa3 !== undefined ? formValues.respostaCriteriosConcausa3 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa3"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa3 !== undefined ? formValues.comentarioCriteriosConcausa3 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Trata-se de uma concausa temporária ou permanente?">
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'4'}></i></button></th>
                        <th>Trata-se de uma concausa temporária ou permanente?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Temporária"
                                    name="respostaCriteriosConcausa4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa4 !== undefined ? formValues.respostaCriteriosConcausa4 === 'Temporária' : ''}
                                />
                                    Temporária
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Permanente"
                                    name="respostaCriteriosConcausa4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa4 !== undefined ? formValues.respostaCriteriosConcausa4 === 'Permanente' : ''}
                                />
                                    Permanente
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa4 !== undefined ? formValues.respostaCriteriosConcausa4 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa4 !== undefined ? formValues.respostaCriteriosConcausa4 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa4"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa4 !== undefined ? formValues.comentarioCriteriosConcausa4 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="A empresa deixou de cumprir alguma norma de segurança e prevenção que contribuiu para a ocorrência do dano ?">
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'5'}></i></button></th>
                        <th>A empresa deixou de cumprir alguma norma de segurança e prevenção que contribuiu para a ocorrência do dano ?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosConcausa5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa5 !== undefined ? formValues.respostaCriteriosConcausa5 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosConcausa5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa5 !== undefined ? formValues.respostaCriteriosConcausa5 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa5 !== undefined ? formValues.respostaCriteriosConcausa5 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa5 !== undefined ? formValues.respostaCriteriosConcausa5 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa5"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa5 !== undefined ? formValues.comentarioCriteriosConcausa5 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Qual o grau de contribuição do fator laboral em comparação com os fatores extra-laborais?"> 
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'6'}></i></button></th>
                        <th>Qual o grau de contribuição do fator laboral em comparação com os fatores extra-laborais?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Leve - 25%"
                                    name="respostaCriteriosConcausa6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa6 !== undefined ? formValues.respostaCriteriosConcausa6 === 'Leve - 25%' : ''}
                                />
                                    Leve - 25%
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Moderada - 50%"
                                    name="respostaCriteriosConcausa6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa6 !== undefined ? formValues.respostaCriteriosConcausa6 === 'Moderada - 50%' : ''}
                                />
                                    Moderada - 50%
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Grave - 75%"
                                    name="respostaCriteriosConcausa6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa6 !== undefined ? formValues.respostaCriteriosConcausa6 === 'Grave - 75%' : ''}
                                />
                                    Grave - 75%
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa6 !== undefined ? formValues.respostaCriteriosConcausa6 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa6"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa6 !== undefined ? formValues.comentarioCriteriosConcausa6 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="conclusaoCriteriosConcausa"> 
                        <th><button onClick={handleLimparCriteriosConcausa}><i className="fas fa-trash perigo" value={'7'}></i></button></th>
                        <th>conclusaoCriteriosConcausa</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Não é plausível o nexo concausal"
                                    name="respostaCriteriosConcausa7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa7 !== undefined ? formValues.respostaCriteriosConcausa7 === 'Não é plausível o nexo concausal' : ''}
                                />
                                    Não é plausível o nexo concausal
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="É plausível o nexo concausal"
                                    name="respostaCriteriosConcausa7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa7 !== undefined ? formValues.respostaCriteriosConcausa7 === 'É plausível o nexo concausal' : ''}
                                />
                                    É plausível o nexo concausal
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosConcausa7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa7 !== undefined ? formValues.respostaCriteriosConcausa7 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosConcausa7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosConcausa7 !== undefined ? formValues.respostaCriteriosConcausa7 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosConcausa7"
                                placeholder="Comentários do criterio de Concausa"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosConcausa7 !== undefined ? formValues.comentarioCriteriosConcausa7 : ''}
                            />
                        </th>
                    </tr>                   
                </tbody>
            </table>
            <div>
            <button onClick={adicionarCriteriosConcausa}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormCriteriosConcausa}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados dos Critérios de Concausa</legend>
            {
            criteriosConcausa.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            {/* <th>Ação</th> */}
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th>Comentário</th>
                        </tr>
                    </thead>
                        {criteriosConcausa.length > 0 && criteriosConcausa.map(({ 
                            perguntaCriteriosConcausa0,
                            respostaCriteriosConcausa0,
                            comentarioCriteriosConcausa0,
                            perguntaCriteriosConcausa1,
                            respostaCriteriosConcausa1,
                            comentarioCriteriosConcausa1,
                            perguntaCriteriosConcausa2,
                            respostaCriteriosConcausa2,
                            comentarioCriteriosConcausa2,
                            perguntaCriteriosConcausa3,
                            respostaCriteriosConcausa3,
                            comentarioCriteriosConcausa3,
                            perguntaCriteriosConcausa4,
                            respostaCriteriosConcausa4,
                            comentarioCriteriosConcausa4,
                            perguntaCriteriosConcausa5,
                            respostaCriteriosConcausa5,
                            comentarioCriteriosConcausa5,
                            perguntaCriteriosConcausa6,
                            respostaCriteriosConcausa6,
                            comentarioCriteriosConcausa6,
                            perguntaCriteriosConcausa7,
                            respostaCriteriosConcausa7,
                            comentarioCriteriosConcausa7,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaCriteriosConcausa0}</th>
                                <th>{respostaCriteriosConcausa0}</th>
                                <th>{comentarioCriteriosConcausa0}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa1}</th>
                                <th>{respostaCriteriosConcausa1}</th>
                                <th>{comentarioCriteriosConcausa1}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa2}</th>
                                <th>{respostaCriteriosConcausa2}</th>
                                <th>{comentarioCriteriosConcausa2}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa3}</th>
                                <th>{respostaCriteriosConcausa3}</th>
                                <th>{comentarioCriteriosConcausa3}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa4}</th>
                                <th>{respostaCriteriosConcausa4}</th>
                                <th>{comentarioCriteriosConcausa4}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa5}</th>
                                <th>{respostaCriteriosConcausa5}</th>
                                <th>{comentarioCriteriosConcausa5}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa6}</th>
                                <th>{respostaCriteriosConcausa6}</th>
                                <th>{comentarioCriteriosConcausa6}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosConcausa7}</th>
                                <th>{respostaCriteriosConcausa7}</th>
                                <th>{comentarioCriteriosConcausa7}</th>
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirCriteriosConcausa}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )   
}
