import React, { useState } from "react";

export const DadosInicial = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log(
      "***data",
      data,
      "***formValues",
      formValues,
      "***newValue",
      newValue
    );
  };

  const [inicial, setInicial] = useState([]);

  const adicionarInicial = () => {
    const arrInicial = [];
    arrInicial.push({
      idInicial: formValues.idInicial !== undefined ? formValues.idInicial : "",
      resumoInicial:
        formValues.resumoInicial !== undefined ? formValues.resumoInicial : "",
    });

    setInicial(arrInicial);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _09_inicial: arrInicial,
      })
    );
  };

  const excluirInicial = (e) => {
    e.preventDefault();
    inicial.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        inicial.splice(i, 1);
        setInicial([...inicial]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _09_inicial: [...inicial],
          })
        );
      }
      return null;
    });
  };

  const excluirTudoInicial = (e) => {
    e.preventDefault();
    // console.log(e.target.attributes.value.value)

    setInicial([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _09_inicial: [],
      })
    );
  };

  const handleCarregarFormInicial = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
      if (tudo._09_inicial && tudo._09_inicial.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._09_inicial !== undefined ||
          JSON.parse(localStorage.getItem("tudo"))._09_inicial !== null
            ? JSON.parse(localStorage.getItem("tudo"))._09_inicial
            : [];

        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            idInicial:
              e !== undefined && e.idInicial !== undefined && e.idInicial !== ""
                ? e.idInicial
                : "",

            resumoInicial:
              e !== undefined &&
              e.resumoInicial !== undefined &&
              e.resumoInicial !== ""
                ? e.resumoInicial
                : "",
          })
        );
      }
    };

    const y = () => {
      if (tudo._09_inicial && tudo._09_inicial.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._09_inicial !== undefined ||
          JSON.parse(localStorage.getItem("tudo"))._09_inicial !== null
            ? JSON.parse(localStorage.getItem("tudo"))._09_inicial
            : [];

        //console.log('***',a)
        const arrInicial = [];
        a.map((e) => {
          return arrInicial.push({
            idInicial:
              e !== undefined && e.idInicial !== undefined && e.idInicial !== ""
                ? e.idInicial
                : "",

            resumoInicial:
              e !== undefined &&
              e.resumoInicial !== undefined &&
              e.resumoInicial !== ""
                ? e.resumoInicial
                : "",
          });
        });

        setInicial(arrInicial);
      }
    };

    x();
    y();
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  return (
    <>
      <fieldset>
        <legend>Resumo da Petição Inicial</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>Limpar Campo</th>
                <th>Informação</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"idInicial"}></i>
                  </button>
                </th>
                <th>
                  <input
                    type="text"
                    name="idInicial"
                    maxLength="22"
                    className="capturaIdInicial"
                    placeholder="Id - página"
                    onChange={handleInputChange}
                    value={
                      formValues.idInicial !== undefined
                        ? formValues.idInicial
                        : ""
                    }
                  />
                </th>
              </tr>

              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"resumoInicial"}
                    ></i>
                  </button>
                </th>
                <th>
                  <textarea
                    name="resumoInicial"
                    rows="6"
                    cols="40"
                    placeholder="Insira aqui o resumo da inicial"
                    onChange={handleInputChange}
                    value={
                      formValues.resumoInicial !== undefined
                        ? formValues.resumoInicial
                        : ""
                    }
                  />
                </th>
              </tr>
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarInicial}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormInicial}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Dados registrados de documentos da inicial</legend>
            {inicial.length === 0 ? 
              <p>Sem Dados registrados</p>
             : 
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {inicial.length > 0 &&
                    inicial.map(({ idInicial, resumoInicial }, i) => {
                      return (
                        <tbody key={i}>
                          <tr >
                            <th>Registro número</th>
                            <th> {i + 1}</th>
                          </tr>
                          <tr>
                            <th>Id</th>
                            <th>{idInicial}</th>
                          </tr>

                          <tr>
                            <th>Resumo</th>
                            <th>{resumoInicial}</th>
                          </tr>

                          <tr>
                            <th>Ação</th>
                            <th >
                              <button onClick={excluirInicial}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoInicial}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoInicial"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
