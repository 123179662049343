import React, {useState} from 'react'

export const DadosDocSst = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ docSst, setDocSst] = useState([])

    const adicionarDocSst = () => {

        const arrDocSst = []

        arrDocSst.push({
            //id: docSst !== undefined ? docSst.length + 1 : '',
            idDocSst: formValues.idDocSst !== undefined ? formValues.idDocSst : '',
            detalheDocSst: formValues.detalheDocSst !== undefined ? formValues.detalheDocSst : '',
        })

        setDocSst([...docSst, ...arrDocSst])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _15_docSst: [...docSst,{
               // id: docSst !== undefined ? docSst.length + 1 : '',
                idDocSst: formValues.idDocSst !== undefined ? formValues.idDocSst : '',
                detalheDocSst: formValues.detalheDocSst !== undefined ? formValues.detalheDocSst : '',
            }]
      }))
    }

    const excluirDocSst = (e) => {

        e.preventDefault()      
        docSst.filter((
            el, i
            )=>{         
                if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                    
                        docSst.splice(i, 1)
                        setDocSst([...docSst])

                        localStorage.setItem('tudo', JSON.stringify({
                            ...JSON.parse(localStorage.getItem('tudo')) || [],
                            _15_docSst: docSst
                      }))                       
                    }    
                    return null
        })
    }

    const excluirTudoDocSst = (e) => {
        e.preventDefault();
       // console.log(e.target.attributes.value.value)

          setDocSst([])
  
           localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _15_docSst: []
            })
          ); 
      }
  
      const handleCarregarFormDocSst = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._15_docSst && tudo._15_docSst.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._15_docSst !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._15_docSst !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._15_docSst : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              idDocSst: e !== undefined && e.idDocSst !== undefined && e.idDocSst !== ""
            ? e.idDocSst
            : "",

            detalheDocSst: e !== undefined && e.detalheDocSst !== undefined && e.detalheDocSst !== ""
            ? e.detalheDocSst
            : "",
            
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._15_docSst && tudo._15_docSst.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._15_docSst !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._15_docSst !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._15_docSst : []
      
              //console.log('***',a)
              const arrDocSst = []
          a.map((e)=> {
  
          return  arrDocSst.push({
            idDocSst: e !== undefined && e.idDocSst !== undefined && e.idDocSst !== ""
            ? e.idDocSst
            : "",

            detalheDocSst: e !== undefined && e.detalheDocSst !== undefined && e.detalheDocSst !== ""
            ? e.detalheDocSst
            : "",
        }) 
    
    }
      )  

      setDocSst(arrDocSst)
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }

    return (
        <>
            <fieldset>
        <legend>Documentos de Saúde e Segurança do Trabalho</legend>
        <div >
        <fieldset >
          <table >
                    <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idDocSst"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="idDocSst"
                            maxLength="22"
                            className="capturaIdDocSst"
                            placeholder="Id - página"
                            onChange={handleInputChange}
                            value={formValues.idDocSst !== undefined ? formValues.idDocSst : ''}
                        /></th>
                </tr>

                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"detalheDocSst"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="detalheDocSst"
                            placeholder="Insira o doc de SST / Treinamento"
                            onChange={handleInputChange}
                            value={formValues.detalheDocSst !== undefined ? formValues.detalheDocSst : ''}
                        /></th>
                </tr>
                     </tbody>
                     </table>   
                        
                     <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarDocSst}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormDocSst}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>


                    <fieldset>
                        <legend>Dados registrados de documentos de SST</legend>
                        {docSst.length === 0 ? <p>Sem Dados registrados</p> :
                            <>
                            <table>
                              <thead>
                                <tr>
                                  <th>Tópico</th>
                                  <th>Informação</th>
                                </tr>
                              </thead>
                                    {docSst.length > 0 && docSst.map(({
                                        
                                        idDocSst,
                                        detalheDocSst
                                    }, i) => {

                                        return (
                                            <tbody key={i}>
                                            <tr >
                                              <th>Registro número</th>
                                              <th> {i + 1}</th>
                                            </tr>
                                            <tr>
                                              <th>Id</th>
                                              <th>{idDocSst}</th>
                                            </tr>
            
                                            <tr>
                                              <th>Detalhe</th>
                                              <th>{detalheDocSst}</th>
                                            </tr>
            
                            
                                            <tr>
                                              <th>Ação</th>
                                              <th >
                                                <button onClick={excluirDocSst}>
                                                  <i
                                                    className="fas fa-solid fa-trash perigo"
                                                    value={i}
                                                  ></i>
                                                </button>
                                              </th>
                                            </tr>
                                          </tbody>
                                        
                                      );
                                    }
                                  )}
                              </table>
                              <fieldset>
                                <legend>Ação</legend>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Excluir todos os documentos?</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr >
                                      <th>
                                        <button onClick={excluirTudoDocSst}>
                                          <i
                                            className="fas fa-solid fa-trash perigo"
                                            value={"excluirTudoDocSst"}
                                          ></i>
                                        </button>
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </fieldset>
                            </>
                          }
                        </fieldset>
                      </fieldset>
                  </div>  </fieldset>
                  </>
                );
}
