import React, { useState, useEffect } from 'react'
import styles from './DadosPeritoEndRes.module.css'

export const DadosPeritoEndRes = () => {

  const [formValues, setFormValues] = useState({})

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === 'checkbox'
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  }

  const [dadosPeritoEndRes, setDadosPeritoEndRes] = useState({})

  const adicionarDadosPeritoEndRes = () => {

    const tudo = JSON.parse(localStorage.getItem('tudo')) || {}
    let getDadosApiEndRes = {}
    if (tudo._83_dadosApiEndRes) {
      getDadosApiEndRes = JSON.parse(localStorage.getItem('tudo'))._83_dadosApiEndRes /* || {} */
    } else {
      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _83_dadosApiEndRes: {}
        
        })
      )
      getDadosApiEndRes = JSON.parse(localStorage.getItem('tudo'))._83_dadosApiEndRes
    }


/* const getDadosApiEndRes = JSON.parse(localStorage.getItem('tudo'))._83_dadosApiEndRes || {}
console.log(getDadosApiEndRes) */

if(getDadosApiEndRes.cep && getDadosApiEndRes.logradouro && getDadosApiEndRes.cidade && getDadosApiEndRes.bairro && getDadosApiEndRes.estado) {
console.log(getDadosApiEndRes.cep)
  return ( setDadosPeritoEndRes({
        cep: getDadosApiEndRes.cep !== '' && getDadosApiEndRes.cep !==undefined ? getDadosApiEndRes.cep: formValues.cep.replace(/\D/g, "").replace(/(\d)(\d{3})$/, "$1-$2") ,
        cidade: getDadosApiEndRes.cidade !== '' && getDadosApiEndRes.cidade !==undefined ? getDadosApiEndRes.cidade: formValues.cidade ,
        bairro: getDadosApiEndRes.bairro !== '' && getDadosApiEndRes.bairro !==undefined ? getDadosApiEndRes.bairro: formValues.bairro ,
        estado: getDadosApiEndRes.estado !== ''&& getDadosApiEndRes.estado !==undefined ? getDadosApiEndRes.estado: formValues.estado ,
        logradouro: getDadosApiEndRes.logradouro !== '' && getDadosApiEndRes.estado !==undefined ? getDadosApiEndRes.logradouro: formValues.logradouro ,
        numero: formValues.numero !== '' && formValues.numero !==undefined ? formValues.numero : '' ,
        complemento: formValues.complemento !== '' && formValues.complemento !==undefined ? formValues.complemento: ''  ,
  }),
  
  localStorage.setItem('tudo', JSON.stringify({
    ...JSON.parse(localStorage.getItem('tudo')) || [],
    _84_dadosPeritoEndRes: {
        cep: getDadosApiEndRes.cep !== '' && getDadosApiEndRes.cep !==undefined ? getDadosApiEndRes.cep: formValues.cep.replace(/\D/g, "").replace(/(\d)(\d{3})$/, "$1-$2") ,
        cidade: getDadosApiEndRes.cidade !== '' && getDadosApiEndRes.cidade !==undefined ? getDadosApiEndRes.cidade: formValues.cidade ,
        bairro: getDadosApiEndRes.bairro !== '' && getDadosApiEndRes.bairro !==undefined ? getDadosApiEndRes.bairro: formValues.bairro ,
        estado: getDadosApiEndRes.estado !== '' && getDadosApiEndRes.estado !==undefined ? getDadosApiEndRes.estado: formValues.estado ,
        logradouro: getDadosApiEndRes.logradouro !== '' && getDadosApiEndRes.logradouro !==undefined ? getDadosApiEndRes.logradouro: formValues.logradouro ,
        numero: formValues.numero !== '' && formValues.numero !==undefined ? formValues.numero : '' ,
        complemento: formValues.complemento !== '' && formValues.complemento !==undefined ? formValues.complemento: ''  , 
    }
  })))

} else {
  return (setDadosPeritoEndRes({
    cep: formValues.cep !== undefined && formValues.cep.length === 8 ? formValues.cep.replace(/(\d)(\d{3})$/, "$1-$2") : 'CEP inválido!',
        cidade: formValues.cidade !== '' && formValues.cidade !==undefined ? formValues.cidade : getDadosApiEndRes.cidade ,
        bairro: formValues.bairro !== '' && formValues.bairro !==undefined ? formValues.bairro : getDadosApiEndRes.bairro ,
        estado: formValues.estado !== '' && formValues.estado !==undefined ? formValues.estado : getDadosApiEndRes.estado ,
        logradouro: formValues.logradouro !== '' && formValues.logradouro !==undefined ? formValues.logradouro : getDadosApiEndRes.logradouro ,
        numero: formValues.numero !== '' && formValues.numero !==undefined ? formValues.numero : '' ,
        complemento: formValues.complemento !== '' && formValues.complemento !==undefined ? formValues.complemento: '' ,
  }),
  
  localStorage.setItem('tudo', JSON.stringify({
    ...JSON.parse(localStorage.getItem('tudo')) || [],
    _84_dadosPeritoEndRes: {
        cep: formValues.cep !== undefined && formValues.cep.length === 8 ? formValues.cep.replace(/(\d)(\d{3})$/, "$1-$2") : '',
        cidade: formValues.cidade !== '' && formValues.cidade !==undefined ? formValues.cidade: dadosPeritoEndRes.cidade ,
        bairro: formValues.bairro !== '' && formValues.bairro !==undefined ? formValues.bairro: dadosPeritoEndRes.bairro ,
        estado: formValues.estado !== '' && formValues.estado !==undefined ? formValues.estado: dadosPeritoEndRes.estado ,
        logradouro: formValues.logradouro !== '' && formValues.logradouro !==undefined ? formValues.logradouro: dadosPeritoEndRes.logradouro ,
        numero: formValues.numero !== '' && formValues.numero !==undefined ? formValues.numero: dadosPeritoEndRes.numero ,
        complemento: formValues.complemento !== '' && formValues.complemento !==undefined ? formValues.complemento: dadosPeritoEndRes.complemento , 
    }
  }))
  )
}
  }

  const excluirDadosPeritoEndRes = (e) => {
    e.preventDefault()

    if (e.target.offsetParent.nextElementSibling.innerText === dadosPeritoEndRes.logradouro) {

      setDadosPeritoEndRes({})

      localStorage.setItem('tudo', JSON.stringify({
        ...JSON.parse(localStorage.getItem('tudo')) || [],
        _84_dadosPeritoEndRes: {}
    }))
  }   
  
  if (e.target.offsetParent
      .nextElementSibling
      .nextElementSibling
      .nextElementSibling
      .nextElementSibling
      .nextElementSibling
      .nextElementSibling
      .nextElementSibling
      .innerText === dadosPeritoEndRes.cep || 'CEP inválido!') {

    setDadosPeritoEndRes({})

    localStorage.setItem('tudo', JSON.stringify({
      ...JSON.parse(localStorage.getItem('tudo')) || [],
      _84_dadosPeritoEndRes: {}
  }))
} 
}

  useEffect(() => {

    if (formValues.cep !== undefined) {
      //console.log(formValues.cep.length)
      formValues.cep = formValues.cep.replace(/\D/g, "")

      if (formValues.cep.length === 8) {
        formValues.cep = formValues.cep
          .replace(/\D/g, "")
      }
        //console.log(formValues.cep.length)  
    }
  }, [dadosPeritoEndRes, formValues])
  

  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxDadosPeritoEndRes} />
        <label htmlFor={styles.checkboxDadosPeritoEndRes}>
          <h2 className={styles.titulo}>EndRes</h2>
        </label>
        <fieldset id={styles.conteudoDadosPeritoEndRes}>
          <table className={styles.table}></table>
          <fieldset>
            
            <legend></legend>
            <button onClick={adicionarDadosPeritoEndRes} >Incluir endereço residencial do perito</button>
            <input
            type="text"
            name="cep"
            size="8"
            minLength="8"
            maxLength="8"
            placeholder="Digite o CEP "
            onChange={handleInputChange}
            value={formValues.cep !== undefined ? formValues.cep.replace(/\D/g, '') : ''}
          />
            <input
              type="text"
              name="logradouro"
              size="30"
              minLength="0"
              maxLength="30"
              placeholder="Logradouro"
              onChange={handleInputChange}
              value={formValues.logradouro !== undefined ? formValues.logradouro : ''}
            />
            <input
              type="text"
              name="bairro"
              size="30"
              minLength="0"
              maxLength="30"
              placeholder="Bairro"
              onChange={handleInputChange}
              value={formValues.bairro !== undefined ? formValues.bairro : ''}
            />
            <input
              type="text"
              name="cidade"
              size="30"
              minLength="0"
              maxLength="30"
              placeholder="Cidade"
              onChange={handleInputChange} 
              value={formValues.cidade !== undefined ? formValues.cidade : ''}
            />
            <select
            name="estado"
            onChange={handleInputChange}
            value={formValues.estado !== undefined ? formValues.estado : ''}
          >
            <option defaultValue=''>Selecione o estado</option>
            <option value='AC'>AC</option>
            <option value='AL'>AL</option>
            <option value='AM'>AM</option>
            <option value='AP'>AP</option>
            <option value='BA'>BA</option>
            <option value='CE'>CE</option>
            <option value='DF'>DF</option>
            <option value='MA'>MA</option>
            <option value='MG'>MG</option>
            <option value='MS'>MS</option>
            <option value='MT'>MT</option>
            <option value='PA'>PA</option>
            <option value='PB'>PB</option>
            <option value='PE'>PE</option>
            <option value='PI'>PI</option>
            <option value='PR'>PR</option>
            <option value='SP'>SP</option>
            <option value='RJ'>RJ</option>
            <option value='RN'>RN</option>
            <option value='RO'>RO</option>
            <option value='RR'>RR</option>
            <option value='RS'>RS</option>
            <option value='TO'>TO</option>
          </select>
             {/* <input
              type="text"
              name="coordenadas"
              size="30"
              minLength="0"
              maxLength="30"
              placeholder="Coordenadas"
              onChange={handleInputChange}
              value={formValues.coordenadas || ''}
            /> */}
            <input
              type="text"
              name="numero"
              size="10"
              minLength="0"
              maxLength="10"
              placeholder="Numero"
              onChange={handleInputChange}
              value={formValues.numero !== undefined ? formValues.numero.replace(/\D/g, "") : ''}
            />
            <input
              type="text"
              name="complemento"
              size="30"
              minLength="0"
              maxLength="30"
              placeholder="Complemento"
              onChange={handleInputChange}
              value={formValues.complemento !== undefined ? formValues.complemento : ''}
            />       
            <fieldset>
              <legend></legend>
              {!dadosPeritoEndRes.cep ? <p>Sem dados registrados</p> : <table>
                <thead>
                  <tr>
                    <th>Ação</th>
                    <th>Logradouro</th>
                    <th>Número</th>
                    <th>Complemento</th>
                    <th>Bairro</th>
                    <th>Cidade</th>
                    <th>Estado</th>
                    <th>CEP</th>
                    {/* <th>Coordenadas</th> */}
                  </tr>
                </thead>
                <tbody>
                  {[dadosPeritoEndRes].length > 0 && [dadosPeritoEndRes].map(({
                    id,
                    logradouro,
                    numero,
                    complemento,
                    bairro,
                    cidade,
                    estado,
                    cep,
                    coordenadas
                  }, i) => {

                    return (
                      <tr key={i}>
                        <th><button onClick={excluirDadosPeritoEndRes}><i className="fas fa-solid fa-trash perigo" value={i}></i></button></th>
                        <th>{logradouro}</th>
                        <th>{numero}</th>
                        <th>{complemento}</th>
                        <th>{bairro}</th>
                        <th>{cidade}</th>
                        <th>{estado}</th>
                        <th>{cep}</th>
                        {/* <th>
                          {[coordenadas].length === 0 ? <p>Não registrado</p> : [coordenadas].map(({ latitude, longitude }, i) => {
                            return (
                              <div key={i}>
                                <p>Latitude: {latitude}</p>
                                <p>Longitude: {longitude}</p>
                              </div>
                            )
                          })
                          } 
                        </th>*/}
                      </tr>)
                  })}
                </tbody>
              </table>}
              
              </fieldset>
            </fieldset>
            </fieldset>
            </fieldset>
            
            </>
            ) 

}