export const CnaeAcentuado = [
  { "id_cnae": "1", "subclasse": "0111-3/01", "denominacao": "Cultivo de arroz" },
  { "id_cnae": "2", "subclasse": "0111-3/02", "denominacao": "Cultivo de milho" },
  { "id_cnae": "3", "subclasse": "0111-3/03", "denominacao": "Cultivo de trigo" },
  {
    "id_cnae": "4",
    "subclasse": "0111-3/99",
    "denominacao": "Cultivo de outros cereais não especificados anteriormente"
  },
  {
    "id_cnae": "5",
    "subclasse": "0112-1/01",
    "denominacao": "Cultivo de algodão herbáceo"
  },
  { "id_cnae": "6", "subclasse": "0112-1/02", "denominacao": "Cultivo de juta" },
  {
    "id_cnae": "7",
    "subclasse": "0112-1/99",
    "denominacao": "Cultivo de outras fibras de lavoura temporária não especificadas anteriormente"
  },
  {
    "id_cnae": "8",
    "subclasse": "0113-0/00",
    "denominacao": "Cultivo de cana-de-açúcar"
  },
  { "id_cnae": "9", "subclasse": "0114-8/00", "denominacao": "Cultivo de fumo" },
  { "id_cnae": "10", "subclasse": "0115-6/00", "denominacao": "Cultivo de soja" },
  {
    "id_cnae": "11",
    "subclasse": "0116-4/01",
    "denominacao": "Cultivo de amendoim"
  },
  {
    "id_cnae": "12",
    "subclasse": "0116-4/02",
    "denominacao": "Cultivo de girassol"
  },
  {
    "id_cnae": "13",
    "subclasse": "0116-4/03",
    "denominacao": "Cultivo de mamona"
  },
  {
    "id_cnae": "14",
    "subclasse": "0116-4/99",
    "denominacao": "Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente"
  },
  {
    "id_cnae": "15",
    "subclasse": "0119-9/01",
    "denominacao": "Cultivo de abacaxi"
  },
  { "id_cnae": "16", "subclasse": "0119-9/02", "denominacao": "Cultivo de alho" },
  {
    "id_cnae": "17",
    "subclasse": "0119-9/03",
    "denominacao": "Cultivo de batata-inglesa"
  },
  {
    "id_cnae": "18",
    "subclasse": "0119-9/04",
    "denominacao": "Cultivo de cebola"
  },
  {
    "id_cnae": "19",
    "subclasse": "0119-9/05",
    "denominacao": "Cultivo de feijão"
  },
  {
    "id_cnae": "20",
    "subclasse": "0119-9/06",
    "denominacao": "Cultivo de mandioca"
  },
  {
    "id_cnae": "21",
    "subclasse": "0119-9/07",
    "denominacao": "Cultivo de melão"
  },
  {
    "id_cnae": "22",
    "subclasse": "0119-9/08",
    "denominacao": "Cultivo de melancia"
  },
  {
    "id_cnae": "23",
    "subclasse": "0119-9/09",
    "denominacao": "Cultivo de tomate rasteiro"
  },
  {
    "id_cnae": "24",
    "subclasse": "0119-9/99",
    "denominacao": "Cultivo de outras plantas de lavoura temporária não especificadas anteriormente"
  },
  {
    "id_cnae": "25",
    "subclasse": "0121-1/01",
    "denominacao": "Horticultura, exceto morango"
  },
  {
    "id_cnae": "26",
    "subclasse": "0121-1/02",
    "denominacao": "Cultivo de morango"
  },
  {
    "id_cnae": "27",
    "subclasse": "0122-9/00",
    "denominacao": "Cultivo de flores e plantas ornamentais"
  },
  {
    "id_cnae": "28",
    "subclasse": "0131-8/00",
    "denominacao": "Cultivo de laranja"
  },
  { "id_cnae": "29", "subclasse": "0132-6/00", "denominacao": "Cultivo de uva" },
  {
    "id_cnae": "30",
    "subclasse": "0133-4/01",
    "denominacao": "Cultivo de açaí"
  },
  {
    "id_cnae": "31",
    "subclasse": "0133-4/02",
    "denominacao": "Cultivo de banana"
  },
  { "id_cnae": "32", "subclasse": "0133-4/03", "denominacao": "Cultivo de caju" },
  {
    "id_cnae": "33",
    "subclasse": "0133-4/04",
    "denominacao": "Cultivo de cítricos, exceto laranja"
  },
  {
    "id_cnae": "34",
    "subclasse": "0133-4/05",
    "denominacao": "Cultivo de coco-da-baía"
  },
  {
    "id_cnae": "35",
    "subclasse": "0133-4/06",
    "denominacao": "Cultivo de guaraná"
  },
  {
    "id_cnae": "36",
    "subclasse": "0133-4/07",
    "denominacao": "Cultivo de maçã"
  },
  {
    "id_cnae": "37",
    "subclasse": "0133-4/08",
    "denominacao": "Cultivo de mamão"
  },
  {
    "id_cnae": "38",
    "subclasse": "0133-4/09",
    "denominacao": "Cultivo de maracujá"
  },
  {
    "id_cnae": "39",
    "subclasse": "0133-4/10",
    "denominacao": "Cultivo de manga"
  },
  {
    "id_cnae": "40",
    "subclasse": "0133-4/11",
    "denominacao": "Cultivo de pêssego"
  },
  {
    "id_cnae": "41",
    "subclasse": "0133-4/99",
    "denominacao": "Cultivo de frutas de lavoura permanente não especificadas anteriormente"
  },
  {
    "id_cnae": "42",
    "subclasse": "0134-2/00",
    "denominacao": "Cultivo de café"
  },
  {
    "id_cnae": "43",
    "subclasse": "0135-1/00",
    "denominacao": "Cultivo de cacau"
  },
  {
    "id_cnae": "44",
    "subclasse": "0139-3/01",
    "denominacao": "Cultivo de chá-da-índia"
  },
  {
    "id_cnae": "45",
    "subclasse": "0139-3/02",
    "denominacao": "Cultivo de erva-mate"
  },
  {
    "id_cnae": "46",
    "subclasse": "0139-3/03",
    "denominacao": "Cultivo de pimenta-do-reino"
  },
  {
    "id_cnae": "47",
    "subclasse": "0139-3/04",
    "denominacao": "Cultivo de plantas para condimento, exceto pimenta-do-reino"
  },
  {
    "id_cnae": "48",
    "subclasse": "0139-3/05",
    "denominacao": "Cultivo de dendê"
  },
  {
    "id_cnae": "49",
    "subclasse": "0139-3/06",
    "denominacao": "Cultivo de seringueira"
  },
  {
    "id_cnae": "50",
    "subclasse": "0139-3/99",
    "denominacao": "Cultivo de outras plantas de lavoura permanente não especificadas anteriormente"
  },
  {
    "id_cnae": "51",
    "subclasse": "0141-5/01",
    "denominacao": "Produção de sementes certificadas, exceto de forrageiras para pasto"
  },
  {
    "id_cnae": "52",
    "subclasse": "0141-5/02",
    "denominacao": "Produção de sementes certificadas de forrageiras para formação de pasto"
  },
  {
    "id_cnae": "53",
    "subclasse": "0142-3/00",
    "denominacao": "Produção de mudas e outras formas de propagação vegetal, certificadas"
  },
  {
    "id_cnae": "54",
    "subclasse": "0151-2/01",
    "denominacao": "Criação de bovinos para corte"
  },
  {
    "id_cnae": "55",
    "subclasse": "0151-2/02",
    "denominacao": "Criação de bovinos para leite"
  },
  {
    "id_cnae": "56",
    "subclasse": "0151-2/03",
    "denominacao": "Criação de bovinos, exceto para corte e leite"
  },
  {
    "id_cnae": "57",
    "subclasse": "0152-1/01",
    "denominacao": "Criação de bufalinos"
  },
  {
    "id_cnae": "58",
    "subclasse": "0152-1/02",
    "denominacao": "Criação de equinos"
  },
  {
    "id_cnae": "59",
    "subclasse": "0152-1/03",
    "denominacao": "Criação de asininos e muares"
  },
  {
    "id_cnae": "60",
    "subclasse": "0153-9/01",
    "denominacao": "Criação de caprinos"
  },
  {
    "id_cnae": "61",
    "subclasse": "0153-9/02",
    "denominacao": "Criação de ovinos, inclusive para produção de lã"
  },
  {
    "id_cnae": "62",
    "subclasse": "0154-7/00",
    "denominacao": "Criação de suínos"
  },
  {
    "id_cnae": "63",
    "subclasse": "0155-5/01",
    "denominacao": "Criação de frangos para corte"
  },
  {
    "id_cnae": "64",
    "subclasse": "0155-5/02",
    "denominacao": "Produção de pintos de um dia"
  },
  {
    "id_cnae": "65",
    "subclasse": "0155-5/03",
    "denominacao": "Criação de outros galináceos, exceto para corte"
  },
  {
    "id_cnae": "66",
    "subclasse": "0155-5/04",
    "denominacao": "Criação de aves, exceto galináceos"
  },
  {
    "id_cnae": "67",
    "subclasse": "0155-5/05",
    "denominacao": "Produção de ovos"
  },
  { "id_cnae": "68", "subclasse": "0159-8/01", "denominacao": "Apicultura" },
  {
    "id_cnae": "69",
    "subclasse": "0159-8/02",
    "denominacao": "Criação de animais de estimação"
  },
  {
    "id_cnae": "70",
    "subclasse": "0159-8/03",
    "denominacao": "Criação de escargô"
  },
  {
    "id_cnae": "71",
    "subclasse": "0159-8/04",
    "denominacao": "Criação de bicho-da-seda"
  },
  {
    "id_cnae": "72",
    "subclasse": "0159-8/99",
    "denominacao": "Criação de outros animais não especificados anteriormente"
  },
  {
    "id_cnae": "73",
    "subclasse": "0161-0/01",
    "denominacao": "Serviço de pulverização e controle de pragas agrícolas"
  },
  {
    "id_cnae": "74",
    "subclasse": "0161-0/02",
    "denominacao": "Serviço de poda de árvores para lavouras"
  },
  {
    "id_cnae": "75",
    "subclasse": "0161-0/03",
    "denominacao": "Serviço de preparação de terreno, cultivo e colheita"
  },
  {
    "id_cnae": "76",
    "subclasse": "0161-0/99",
    "denominacao": "Atividades de apoio à agricultura não especificadas anteriormente"
  },
  {
    "id_cnae": "77",
    "subclasse": "0162-8/01",
    "denominacao": "Serviço de inseminação artificial em animais"
  },
  {
    "id_cnae": "78",
    "subclasse": "0162-8/02",
    "denominacao": "Serviço de tosquiamento de ovinos"
  },
  {
    "id_cnae": "79",
    "subclasse": "0162-8/03",
    "denominacao": "Serviço de manejo de animais"
  },
  {
    "id_cnae": "80",
    "subclasse": "0162-8/99",
    "denominacao": "Atividades de apoio à pecuária não especificadas anteriormente"
  },
  {
    "id_cnae": "81",
    "subclasse": "0163-6/00",
    "denominacao": "Atividades de pós-colheita"
  },
  {
    "id_cnae": "82",
    "subclasse": "0170-9/00",
    "denominacao": "Caça e serviços relacionados"
  },
  {
    "id_cnae": "83",
    "subclasse": "0210-1/01",
    "denominacao": "Cultivo de eucalipto"
  },
  {
    "id_cnae": "84",
    "subclasse": "0210-1/02",
    "denominacao": "Cultivo de acácia-negra"
  },
  {
    "id_cnae": "85",
    "subclasse": "0210-1/03",
    "denominacao": "Cultivo de pinus"
  },
  { "id_cnae": "86", "subclasse": "0210-1/04", "denominacao": "Cultivo de teca" },
  {
    "id_cnae": "87",
    "subclasse": "0210-1/05",
    "denominacao": "Cultivo de espécies madeireiras, exceto eucalipto, acácia-negra, pinus e teca"
  },
  {
    "id_cnae": "88",
    "subclasse": "0210-1/06",
    "denominacao": "Cultivo de mudas em viveiros florestais"
  },
  {
    "id_cnae": "89",
    "subclasse": "0210-1/07",
    "denominacao": "Extração de madeira em florestas plantadas"
  },
  {
    "id_cnae": "90",
    "subclasse": "0210-1/08",
    "denominacao": "Produção de carvão vegetal - florestas plantadas"
  },
  {
    "id_cnae": "91",
    "subclasse": "0210-1/09",
    "denominacao": "Produção de casca de acácia-negra - florestas plantadas"
  },
  {
    "id_cnae": "92",
    "subclasse": "0210-1/99",
    "denominacao": "Produção de produtos não madeireiros não especificados anteriormente em florestas plantadas"
  },
  {
    "id_cnae": "93",
    "subclasse": "0220-9/01",
    "denominacao": "Extração de madeira em florestas nativas"
  },
  {
    "id_cnae": "94",
    "subclasse": "0220-9/02",
    "denominacao": "Produção de carvão vegetal - florestas nativas"
  },
  {
    "id_cnae": "95",
    "subclasse": "0220-9/03",
    "denominacao": "Coleta de castanha-do-pará em florestas nativas"
  },
  {
    "id_cnae": "96",
    "subclasse": "0220-9/04",
    "denominacao": "Coleta de látex em florestas nativas"
  },
  {
    "id_cnae": "97",
    "subclasse": "0220-9/05",
    "denominacao": "Coleta de palmito em florestas nativas"
  },
  {
    "id_cnae": "98",
    "subclasse": "0220-9/06",
    "denominacao": "Conservação de florestas nativas"
  },
  {
    "id_cnae": "99",
    "subclasse": "0220-9/99",
    "denominacao": "Coleta de produtos não madeireiros não especificados anteriormente em florestas nativas"
  },
  {
    "id_cnae": "100",
    "subclasse": "0230-6/00",
    "denominacao": "Atividades de apoio à produção florestal"
  },
  {
    "id_cnae": "101",
    "subclasse": "0311-6/01",
    "denominacao": "Pesca de peixes em água salgada"
  },
  {
    "id_cnae": "102",
    "subclasse": "0311-6/02",
    "denominacao": "Pesca de crustáceos e moluscos em água salgada"
  },
  {
    "id_cnae": "103",
    "subclasse": "0311-6/03",
    "denominacao": "Coleta de outros produtos marinhos"
  },
  {
    "id_cnae": "104",
    "subclasse": "0311-6/04",
    "denominacao": "Atividades de apoio à pesca em água salgada"
  },
  {
    "id_cnae": "105",
    "subclasse": "0312-4/01",
    "denominacao": "Pesca de peixes em água doce"
  },
  {
    "id_cnae": "106",
    "subclasse": "0312-4/02",
    "denominacao": "Pesca de crustáceos e moluscos em água doce"
  },
  {
    "id_cnae": "107",
    "subclasse": "0312-4/03",
    "denominacao": "Coleta de outros produtos aquáticos de água doce"
  },
  {
    "id_cnae": "108",
    "subclasse": "0312-4/04",
    "denominacao": "Atividades de apoio à pesca em água doce"
  },
  {
    "id_cnae": "109",
    "subclasse": "0321-3/01",
    "denominacao": "Criação de peixes em água salgada e salobra"
  },
  {
    "id_cnae": "110",
    "subclasse": "0321-3/02",
    "denominacao": "Criação de camarões em água salgada e salobra"
  },
  {
    "id_cnae": "111",
    "subclasse": "0321-3/03",
    "denominacao": "Criação de ostras e mexilhões em água salgada e salobra"
  },
  {
    "id_cnae": "112",
    "subclasse": "0321-3/04",
    "denominacao": "Criação de peixes ornamentais em água salgada e salobra"
  },
  {
    "id_cnae": "113",
    "subclasse": "0321-3/05",
    "denominacao": "Atividades de apoio à aquicultura em água salgada e salobra"
  },
  {
    "id_cnae": "114",
    "subclasse": "0321-3/99",
    "denominacao": "Cultivos e semicultivos da aquicultura em água salgada e salobra não especificados anteriormente"
  },
  {
    "id_cnae": "115",
    "subclasse": "0322-1/01",
    "denominacao": "Criação de peixes em água doce"
  },
  {
    "id_cnae": "116",
    "subclasse": "0322-1/02",
    "denominacao": "Criação de camarões em água doce"
  },
  {
    "id_cnae": "117",
    "subclasse": "0322-1/03",
    "denominacao": "Criação de ostras e mexilhões em água doce"
  },
  {
    "id_cnae": "118",
    "subclasse": "0322-1/04",
    "denominacao": "Criação de peixes ornamentais em água doce"
  },
  { "id_cnae": "119", "subclasse": "0322-1/05", "denominacao": "Ranicultura" },
  {
    "id_cnae": "120",
    "subclasse": "0322-1/06",
    "denominacao": "Criação de jacaré"
  },
  {
    "id_cnae": "121",
    "subclasse": "0322-1/07",
    "denominacao": "Atividades de apoio à aquicultura em água doce"
  },
  {
    "id_cnae": "122",
    "subclasse": "0322-1/99",
    "denominacao": "Cultivos e semicultivos da aquicultura em água doce não especificados anteriormente"
  },
  {
    "id_cnae": "123",
    "subclasse": "0500-3/01",
    "denominacao": "Extração de carvão mineral"
  },
  {
    "id_cnae": "124",
    "subclasse": "0500-3/02",
    "denominacao": "Beneficiamento de carvão mineral"
  },
  {
    "id_cnae": "125",
    "subclasse": "0600-0/01",
    "denominacao": "Extração de petróleo e gás natural"
  },
  {
    "id_cnae": "126",
    "subclasse": "0600-0/02",
    "denominacao": "Extração e beneficiamento de xisto"
  },
  {
    "id_cnae": "127",
    "subclasse": "0600-0/03",
    "denominacao": "Extração e beneficiamento de areias betuminosas"
  },
  {
    "id_cnae": "128",
    "subclasse": "0710-3/01",
    "denominacao": "Extração de minério de ferro"
  },
  {
    "id_cnae": "129",
    "subclasse": "0710-3/02",
    "denominacao": "Pelotização, sinterização e outros beneficiamentos de minério de ferro"
  },
  {
    "id_cnae": "130",
    "subclasse": "0721-9/01",
    "denominacao": "Extração de minério de alumínio"
  },
  {
    "id_cnae": "131",
    "subclasse": "0721-9/02",
    "denominacao": "Beneficiamento de minério de alumínio"
  },
  {
    "id_cnae": "132",
    "subclasse": "0722-7/01",
    "denominacao": "Extração de minério de estanho"
  },
  {
    "id_cnae": "133",
    "subclasse": "0722-7/02",
    "denominacao": "Beneficiamento de minério de estanho"
  },
  {
    "id_cnae": "134",
    "subclasse": "0723-5/01",
    "denominacao": "Extração de minério de manganês"
  },
  {
    "id_cnae": "135",
    "subclasse": "0723-5/02",
    "denominacao": "Beneficiamento de minério de manganês"
  },
  {
    "id_cnae": "136",
    "subclasse": "0724-3/01",
    "denominacao": "Extração de minério de metais preciosos"
  },
  {
    "id_cnae": "137",
    "subclasse": "0724-3/02",
    "denominacao": "Beneficiamento de minério de metais preciosos"
  },
  {
    "id_cnae": "138",
    "subclasse": "0725-1/00",
    "denominacao": "Extração de minerais radioativos"
  },
  {
    "id_cnae": "139",
    "subclasse": "0729-4/01",
    "denominacao": "Extração de minérios de nióbio e titânio"
  },
  {
    "id_cnae": "140",
    "subclasse": "0729-4/02",
    "denominacao": "Extração de minério de tungstênio"
  },
  {
    "id_cnae": "141",
    "subclasse": "0729-4/03",
    "denominacao": "Extração de minério de níquel"
  },
  {
    "id_cnae": "142",
    "subclasse": "0729-4/04",
    "denominacao": "Extração de minérios de cobre, chumbo, zinco e outros minerais metálicos não ferrosos não especificados anteriormente"
  },
  {
    "id_cnae": "143",
    "subclasse": "0729-4/05",
    "denominacao": "Beneficiamento de minérios de cobre, chumbo, zinco e outros minerais metálicos não ferrosos não especificados anteriormente"
  },
  {
    "id_cnae": "144",
    "subclasse": "0810-0/01",
    "denominacao": "Extração de ardósia e beneficiamento associado"
  },
  {
    "id_cnae": "145",
    "subclasse": "0810-0/02",
    "denominacao": "Extração de granito e beneficiamento associado"
  },
  {
    "id_cnae": "146",
    "subclasse": "0810-0/03",
    "denominacao": "Extração de mármore e beneficiamento associado"
  },
  {
    "id_cnae": "147",
    "subclasse": "0810-0/04",
    "denominacao": "Extração de calcário e dolomita e beneficiamento associado"
  },
  {
    "id_cnae": "148",
    "subclasse": "0810-0/05",
    "denominacao": "Extração de gesso e caulim"
  },
  {
    "id_cnae": "149",
    "subclasse": "0810-0/06",
    "denominacao": "Extração de areia, cascalho ou pedregulho e beneficiamento associado"
  },
  {
    "id_cnae": "150",
    "subclasse": "0810-0/07",
    "denominacao": "Extração de argila e beneficiamento associado"
  },
  {
    "id_cnae": "151",
    "subclasse": "0810-0/08",
    "denominacao": "Extração de saibro e beneficiamento associado"
  },
  {
    "id_cnae": "152",
    "subclasse": "0810-0/09",
    "denominacao": "Extração de basalto e beneficiamento associado"
  },
  {
    "id_cnae": "153",
    "subclasse": "0810-0/10",
    "denominacao": "Beneficiamento de gesso e caulim associado à extração"
  },
  {
    "id_cnae": "154",
    "subclasse": "0810-0/99",
    "denominacao": "Extração e britamento de pedras e outros materiais para construção e beneficiamento associado"
  },
  {
    "id_cnae": "155",
    "subclasse": "0891-6/00",
    "denominacao": "Extração de minerais para fabricação de adubos, fertilizantes e outros produtos químicos"
  },
  {
    "id_cnae": "156",
    "subclasse": "0892-4/01",
    "denominacao": "Extração de sal marinho"
  },
  {
    "id_cnae": "157",
    "subclasse": "0892-4/02",
    "denominacao": "Extração de sal-gema"
  },
  {
    "id_cnae": "158",
    "subclasse": "0892-4/03",
    "denominacao": "Refino e outros tratamentos do sal"
  },
  {
    "id_cnae": "159",
    "subclasse": "0893-2/00",
    "denominacao": "Extração de gemas (pedras preciosas e semipreciosas)"
  },
  {
    "id_cnae": "160",
    "subclasse": "0899-1/01",
    "denominacao": "Extração de grafita"
  },
  {
    "id_cnae": "161",
    "subclasse": "0899-1/02",
    "denominacao": "Extração de quartzo"
  },
  {
    "id_cnae": "162",
    "subclasse": "0899-1/03",
    "denominacao": "Extração de amianto"
  },
  {
    "id_cnae": "163",
    "subclasse": "0899-1/99",
    "denominacao": "Extração de outros minerais não metálicos não especificados anteriormente"
  },
  {
    "id_cnae": "164",
    "subclasse": "0910-6/00",
    "denominacao": "Atividades de apoio à extração de petróleo e gás natural"
  },
  {
    "id_cnae": "165",
    "subclasse": "0990-4/01",
    "denominacao": "Atividades de apoio à extração de minério de ferro"
  },
  {
    "id_cnae": "166",
    "subclasse": "0990-4/02",
    "denominacao": "Atividades de apoio à extração de minerais metálicos não ferrosos"
  },
  {
    "id_cnae": "167",
    "subclasse": "0990-4/03",
    "denominacao": "Atividades de apoio à extração de minerais não metálicos"
  },
  {
    "id_cnae": "168",
    "subclasse": "1011-2/01",
    "denominacao": "Frigorífico - abate de bovinos"
  },
  {
    "id_cnae": "169",
    "subclasse": "1011-2/02",
    "denominacao": "Frigorífico - abate de equinos"
  },
  {
    "id_cnae": "170",
    "subclasse": "1011-2/03",
    "denominacao": "Frigorífico - abate de ovinos e caprinos"
  },
  {
    "id_cnae": "171",
    "subclasse": "1011-2/04",
    "denominacao": "Frigorífico - abate de bufalinos"
  },
  {
    "id_cnae": "172",
    "subclasse": "1011-2/05",
    "denominacao": "Matadouro - abate de reses sob contrato, exceto abate de suínos"
  },
  { "id_cnae": "173", "subclasse": "1012-1/01", "denominacao": "Abate de aves" },
  {
    "id_cnae": "174",
    "subclasse": "1012-1/02",
    "denominacao": "Abate de pequenos animais"
  },
  {
    "id_cnae": "175",
    "subclasse": "1012-1/03",
    "denominacao": "Frigorífico - abate de suínos"
  },
  {
    "id_cnae": "176",
    "subclasse": "1012-1/04",
    "denominacao": "Matadouro - abate de suínos sob contrato"
  },
  {
    "id_cnae": "177",
    "subclasse": "1013-9/01",
    "denominacao": "Fabricação de produtos de carne"
  },
  {
    "id_cnae": "178",
    "subclasse": "1013-9/02",
    "denominacao": "Preparação de subprodutos do abate"
  },
  {
    "id_cnae": "179",
    "subclasse": "1020-1/01",
    "denominacao": "Preservação de peixes, crustáceos e moluscos"
  },
  {
    "id_cnae": "180",
    "subclasse": "1020-1/02",
    "denominacao": "Fabricação de conservas de peixes, crustáceos e moluscos"
  },
  {
    "id_cnae": "181",
    "subclasse": "1031-7/00",
    "denominacao": "Fabricação de conservas de frutas"
  },
  {
    "id_cnae": "182",
    "subclasse": "1032-5/01",
    "denominacao": "Fabricação de conservas de palmito"
  },
  {
    "id_cnae": "183",
    "subclasse": "1032-5/99",
    "denominacao": "Fabricação de conservas de legumes e outros vegetais, exceto palmito"
  },
  {
    "id_cnae": "184",
    "subclasse": "1033-3/01",
    "denominacao": "Fabricação de sucos concentrados de frutas, hortaliças e legumes"
  },
  {
    "id_cnae": "185",
    "subclasse": "1033-3/02",
    "denominacao": "Fabricação de sucos de frutas, hortaliças e legumes, exceto concentrados"
  },
  {
    "id_cnae": "186",
    "subclasse": "1041-4/00",
    "denominacao": "Fabricação de óleos vegetais em bruto, exceto óleo de milho"
  },
  {
    "id_cnae": "187",
    "subclasse": "1042-2/00",
    "denominacao": "Fabricação de óleos vegetais refinados, exceto óleo de milho"
  },
  {
    "id_cnae": "188",
    "subclasse": "1043-1/00",
    "denominacao": "Fabricação de margarina e outras gorduras vegetais e de óleos não comestíveis de animais"
  },
  {
    "id_cnae": "189",
    "subclasse": "1051-1/00",
    "denominacao": "Preparação do leite"
  },
  {
    "id_cnae": "190",
    "subclasse": "1052-0/00",
    "denominacao": "Fabricação de laticínios"
  },
  {
    "id_cnae": "191",
    "subclasse": "1053-8/00",
    "denominacao": "Fabricação de sorvetes e outros gelados comestíveis"
  },
  {
    "id_cnae": "192",
    "subclasse": "1061-9/01",
    "denominacao": "Beneficiamento de arroz"
  },
  {
    "id_cnae": "193",
    "subclasse": "1061-9/02",
    "denominacao": "Fabricação de produtos do arroz"
  },
  {
    "id_cnae": "194",
    "subclasse": "1062-7/00",
    "denominacao": "Moagem de trigo e fabricação de derivados"
  },
  {
    "id_cnae": "195",
    "subclasse": "1063-5/00",
    "denominacao": "Fabricação de farinha de mandioca e derivados"
  },
  {
    "id_cnae": "196",
    "subclasse": "1064-3/00",
    "denominacao": "Fabricação de farinha de milho e derivados, exceto óleos de milho"
  },
  {
    "id_cnae": "197",
    "subclasse": "1065-1/01",
    "denominacao": "Fabricação de amidos e féculas de vegetais"
  },
  {
    "id_cnae": "198",
    "subclasse": "1065-1/02",
    "denominacao": "Fabricação de óleo de milho em bruto"
  },
  {
    "id_cnae": "199",
    "subclasse": "1065-1/03",
    "denominacao": "Fabricação de óleo de milho refinado"
  },
  {
    "id_cnae": "200",
    "subclasse": "1066-0/00",
    "denominacao": "Fabricação de alimentos para animais"
  },
  {
    "id_cnae": "201",
    "subclasse": "1069-4/00",
    "denominacao": "Moagem e fabricação de produtos de origem vegetal não especificados anteriormente"
  },
  {
    "id_cnae": "202",
    "subclasse": "1071-6/00",
    "denominacao": "Fabricação de açúcar em bruto"
  },
  {
    "id_cnae": "203",
    "subclasse": "1072-4/01",
    "denominacao": "Fabricação de açúcar de cana refinado"
  },
  {
    "id_cnae": "204",
    "subclasse": "1072-4/02",
    "denominacao": "Fabricação de açúcar de cereais (dextrose) e de beterraba"
  },
  {
    "id_cnae": "205",
    "subclasse": "1081-3/01",
    "denominacao": "Beneficiamento de café"
  },
  {
    "id_cnae": "206",
    "subclasse": "1081-3/02",
    "denominacao": "Torrefação e moagem de café"
  },
  {
    "id_cnae": "207",
    "subclasse": "1082-1/00",
    "denominacao": "Fabricação de produtos à base de café"
  },
  {
    "id_cnae": "208",
    "subclasse": "1091-1/01",
    "denominacao": "Fabricação de produtos de panificação industrial"
  },
  {
    "id_cnae": "209",
    "subclasse": "1091-1/02",
    "denominacao": "Fabricação de produtos de padaria e confeitaria com predominância de produção própria"
  },
  {
    "id_cnae": "210",
    "subclasse": "1092-9/00",
    "denominacao": "Fabricação de biscoitos e bolachas"
  },
  {
    "id_cnae": "211",
    "subclasse": "1093-7/01",
    "denominacao": "Fabricação de produtos derivados do cacau e de chocolates"
  },
  {
    "id_cnae": "212",
    "subclasse": "1093-7/02",
    "denominacao": "Fabricação de frutas cristalizadas, balas e semelhantes"
  },
  {
    "id_cnae": "213",
    "subclasse": "1094-5/00",
    "denominacao": "Fabricação de massas alimentícias"
  },
  {
    "id_cnae": "214",
    "subclasse": "1095-3/00",
    "denominacao": "Fabricação de especiarias, molhos, temperos e condimentos"
  },
  {
    "id_cnae": "215",
    "subclasse": "1096-1/00",
    "denominacao": "Fabricação de alimentos e pratos prontos"
  },
  {
    "id_cnae": "216",
    "subclasse": "1099-6/01",
    "denominacao": "Fabricação de vinagres"
  },
  {
    "id_cnae": "217",
    "subclasse": "1099-6/02",
    "denominacao": "Fabricação de pós-alimentícios"
  },
  {
    "id_cnae": "218",
    "subclasse": "1099-6/03",
    "denominacao": "Fabricação de fermentos e leveduras"
  },
  {
    "id_cnae": "219",
    "subclasse": "1099-6/04",
    "denominacao": "Fabricação de gelo comum"
  },
  {
    "id_cnae": "220",
    "subclasse": "1099-6/05",
    "denominacao": "Fabricação de produtos para infusão (chá, mate, etc.)"
  },
  {
    "id_cnae": "221",
    "subclasse": "1099-6/06",
    "denominacao": "Fabricação de adoçantes naturais e artificiais"
  },
  {
    "id_cnae": "222",
    "subclasse": "1099-6/07",
    "denominacao": "Fabricação de alimentos dietéticos e complementos alimentares"
  },
  {
    "id_cnae": "223",
    "subclasse": "1099-6/99",
    "denominacao": "Fabricação de outros produtos alimentícios não especificados anteriormente"
  },
  {
    "id_cnae": "224",
    "subclasse": "1111-9/01",
    "denominacao": "Fabricação de aguardente de cana-de-açúcar"
  },
  {
    "id_cnae": "225",
    "subclasse": "1111-9/02",
    "denominacao": "Fabricação de outras aguardentes e bebidas destiladas"
  },
  {
    "id_cnae": "226",
    "subclasse": "1112-7/00",
    "denominacao": "Fabricação de vinho"
  },
  {
    "id_cnae": "227",
    "subclasse": "1113-5/01",
    "denominacao": "Fabricação de malte, inclusive malte uísque"
  },
  {
    "id_cnae": "228",
    "subclasse": "1113-5/02",
    "denominacao": "Fabricação de cervejas e chopes"
  },
  {
    "id_cnae": "229",
    "subclasse": "1121-6/00",
    "denominacao": "Fabricação de águas envasadas"
  },
  {
    "id_cnae": "230",
    "subclasse": "1122-4/01",
    "denominacao": "Fabricação de refrigerantes"
  },
  {
    "id_cnae": "231",
    "subclasse": "1122-4/02",
    "denominacao": "Fabricação de chá mate e outros chás prontos para consumo"
  },
  {
    "id_cnae": "232",
    "subclasse": "1122-4/03",
    "denominacao": "Fabricação de refrescos, xaropes e pós para refrescos, exceto refrescos de frutas"
  },
  {
    "id_cnae": "233",
    "subclasse": "1122-4/04",
    "denominacao": "Fabricação de bebidas isotônicas"
  },
  {
    "id_cnae": "234",
    "subclasse": "1122-4/99",
    "denominacao": "Fabricação de outras bebidas não alcoólicas não especificadas anteriormente"
  },
  {
    "id_cnae": "235",
    "subclasse": "1210-7/00",
    "denominacao": "Processamento industrial do fumo"
  },
  {
    "id_cnae": "236",
    "subclasse": "1220-4/01",
    "denominacao": "Fabricação de cigarros"
  },
  {
    "id_cnae": "237",
    "subclasse": "1220-4/02",
    "denominacao": "Fabricação de cigarrilhas e charutos"
  },
  {
    "id_cnae": "238",
    "subclasse": "1220-4/03",
    "denominacao": "Fabricação de filtros para cigarros"
  },
  {
    "id_cnae": "239",
    "subclasse": "1220-4/99",
    "denominacao": "Fabricação de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos"
  },
  {
    "id_cnae": "240",
    "subclasse": "1311-1/00",
    "denominacao": "Preparação e fiação de fibras de algodão"
  },
  {
    "id_cnae": "241",
    "subclasse": "1312-0/00",
    "denominacao": "Preparação e fiação de fibras têxteis naturais, exceto algodão"
  },
  {
    "id_cnae": "242",
    "subclasse": "1313-8/00",
    "denominacao": "Fiação de fibras artificiais e sintéticas"
  },
  {
    "id_cnae": "243",
    "subclasse": "1314-6/00",
    "denominacao": "Fabricação de linhas para costurar e bordar"
  },
  {
    "id_cnae": "244",
    "subclasse": "1321-9/00",
    "denominacao": "Tecelagem de fios de algodão"
  },
  {
    "id_cnae": "245",
    "subclasse": "1322-7/00",
    "denominacao": "Tecelagem de fios de fibras têxteis naturais, exceto algodão"
  },
  {
    "id_cnae": "246",
    "subclasse": "1323-5/00",
    "denominacao": "Tecelagem de fios de fibras artificiais e sintéticas"
  },
  {
    "id_cnae": "247",
    "subclasse": "1330-8/00",
    "denominacao": "Fabricação de tecidos de malha"
  },
  {
    "id_cnae": "248",
    "subclasse": "1340-5/01",
    "denominacao": "Estamparia e texturização em fios, tecidos, artefatos têxteis e peças do vestuário"
  },
  {
    "id_cnae": "249",
    "subclasse": "1340-5/02",
    "denominacao": "Alvejamento, tingimento e torção em fios, tecidos, artefatos têxteis e peças do vestuário"
  },
  {
    "id_cnae": "250",
    "subclasse": "1340-5/99",
    "denominacao": "Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário"
  },
  {
    "id_cnae": "251",
    "subclasse": "1351-1/00",
    "denominacao": "Fabricação de artefatos têxteis para uso doméstico"
  },
  {
    "id_cnae": "252",
    "subclasse": "1352-9/00",
    "denominacao": "Fabricação de artefatos de tapeçaria"
  },
  {
    "id_cnae": "253",
    "subclasse": "1353-7/00",
    "denominacao": "Fabricação de artefatos de cordoaria"
  },
  {
    "id_cnae": "254",
    "subclasse": "1354-5/00",
    "denominacao": "Fabricação de tecidos especiais, inclusive artefatos"
  },
  {
    "id_cnae": "255",
    "subclasse": "1359-6/00",
    "denominacao": "Fabricação de outros produtos têxteis não especificados anteriormente"
  },
  {
    "id_cnae": "256",
    "subclasse": "1411-8/01",
    "denominacao": "Confecção de roupas íntimas"
  },
  {
    "id_cnae": "257",
    "subclasse": "1411-8/02",
    "denominacao": "Facção de roupas íntimas"
  },
  {
    "id_cnae": "258",
    "subclasse": "1412-6/01",
    "denominacao": "Confecção de peças do vestuário, exceto roupas íntimas e as confeccionadas sob medida"
  },
  {
    "id_cnae": "259",
    "subclasse": "1412-6/02",
    "denominacao": "Confecção, sob medida, de peças do vestuário, exceto roupas íntimas"
  },
  {
    "id_cnae": "260",
    "subclasse": "1412-6/03",
    "denominacao": "Facção de peças do vestuário, exceto roupas íntimas"
  },
  {
    "id_cnae": "261",
    "subclasse": "1413-4/01",
    "denominacao": "Confecção de roupas profissionais, exceto sob medida"
  },
  {
    "id_cnae": "262",
    "subclasse": "1413-4/02",
    "denominacao": "Confecção, sob medida, de roupas profissionais"
  },
  {
    "id_cnae": "263",
    "subclasse": "1413-4/03",
    "denominacao": "Facção de roupas profissionais"
  },
  {
    "id_cnae": "264",
    "subclasse": "1414-2/00",
    "denominacao": "Fabricação de acessórios do vestuário, exceto para segurança e proteção"
  },
  {
    "id_cnae": "265",
    "subclasse": "1421-5/00",
    "denominacao": "Fabricação de meias"
  },
  {
    "id_cnae": "266",
    "subclasse": "1422-3/00",
    "denominacao": "Fabricação de artigos do vestuário, produzidos em malharias e tricotagens, exceto meias"
  },
  {
    "id_cnae": "267",
    "subclasse": "1510-6/00",
    "denominacao": "Curtimento e outras preparações de couro"
  },
  {
    "id_cnae": "268",
    "subclasse": "1521-1/00",
    "denominacao": "Fabricação de artigos para viagem, bolsas e semelhantes de qualquer material"
  },
  {
    "id_cnae": "269",
    "subclasse": "1529-7/00",
    "denominacao": "Fabricação de artefatos de couro não especificados anteriormente"
  },
  {
    "id_cnae": "270",
    "subclasse": "1531-9/01",
    "denominacao": "Fabricação de calçados de couro"
  },
  {
    "id_cnae": "271",
    "subclasse": "1531-9/02",
    "denominacao": "Acabamento de calçados de couro sob contrato"
  },
  {
    "id_cnae": "272",
    "subclasse": "1532-7/00",
    "denominacao": "Fabricação de tênis de qualquer material"
  },
  {
    "id_cnae": "273",
    "subclasse": "1533-5/00",
    "denominacao": "Fabricação de calçados de material sintético"
  },
  {
    "id_cnae": "274",
    "subclasse": "1539-4/00",
    "denominacao": "Fabricação de calçados de materiais não especificados anteriormente"
  },
  {
    "id_cnae": "275",
    "subclasse": "1540-8/00",
    "denominacao": "Fabricação de partes para calçados, de qualquer material"
  },
  {
    "id_cnae": "276",
    "subclasse": "1610-2/03",
    "denominacao": "Serrarias com desdobramento de madeira em bruto"
  },
  {
    "id_cnae": "277",
    "subclasse": "1610-2/04",
    "denominacao": "Serrarias sem desdobramento de madeira em bruto - Resseragem"
  },
  {
    "id_cnae": "278",
    "subclasse": "1610-2/05",
    "denominacao": "Serviço de tratamento de madeira realizado sob contrato"
  },
  {
    "id_cnae": "279",
    "subclasse": "1621-8/00",
    "denominacao": "Fabricação de madeira laminada e de chapas de madeira compensada, prensada e aglomerada"
  },
  {
    "id_cnae": "280",
    "subclasse": "1622-6/01",
    "denominacao": "Fabricação de casas de madeira pré-fabricadas"
  },
  {
    "id_cnae": "281",
    "subclasse": "1622-6/02",
    "denominacao": "Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais"
  },
  {
    "id_cnae": "282",
    "subclasse": "1622-6/99",
    "denominacao": "Fabricação de outros artigos de carpintaria para construção"
  },
  {
    "id_cnae": "283",
    "subclasse": "1623-4/00",
    "denominacao": "Fabricação de artefatos de tanoaria e de embalagens de madeira"
  },
  {
    "id_cnae": "284",
    "subclasse": "1629-3/01",
    "denominacao": "Fabricação de artefatos diversos de madeira, exceto móveis"
  },
  {
    "id_cnae": "285",
    "subclasse": "1629-3/02",
    "denominacao": "Fabricação de artefatos diversos de cortiça, bambu, palha, vime e outros materiais trançados, exceto móveis"
  },
  {
    "id_cnae": "286",
    "subclasse": "1710-9/00",
    "denominacao": "Fabricação de celulose e outras pastas para a fabricação de papel"
  },
  {
    "id_cnae": "287",
    "subclasse": "1721-4/00",
    "denominacao": "Fabricação de papel"
  },
  {
    "id_cnae": "288",
    "subclasse": "1722-2/00",
    "denominacao": "Fabricação de cartolina e papel-cartão"
  },
  {
    "id_cnae": "289",
    "subclasse": "1731-1/00",
    "denominacao": "Fabricação de embalagens de papel"
  },
  {
    "id_cnae": "290",
    "subclasse": "1732-0/00",
    "denominacao": "Fabricação de embalagens de cartolina e papel-cartão"
  },
  {
    "id_cnae": "291",
    "subclasse": "1733-8/00",
    "denominacao": "Fabricação de chapas e de embalagens de papelão ondulado"
  },
  {
    "id_cnae": "292",
    "subclasse": "1741-9/01",
    "denominacao": "Fabricação de formulários contínuos"
  },
  {
    "id_cnae": "293",
    "subclasse": "1741-9/02",
    "denominacao": "Fabricação de produtos de papel, cartolina, papel-cartão e papelão ondulado para uso comercial e de escritório"
  },
  {
    "id_cnae": "294",
    "subclasse": "1742-7/01",
    "denominacao": "Fabricação de fraldas descartáveis"
  },
  {
    "id_cnae": "295",
    "subclasse": "1742-7/02",
    "denominacao": "Fabricação de absorventes higiênicos"
  },
  {
    "id_cnae": "296",
    "subclasse": "1742-7/99",
    "denominacao": "Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente"
  },
  {
    "id_cnae": "297",
    "subclasse": "1749-4/00",
    "denominacao": "Fabricação de produtos de pastas celulósicas, papel, cartolina, papel-cartão e papelão ondulado não especificados anteriormente"
  },
  {
    "id_cnae": "298",
    "subclasse": "1811-3/01",
    "denominacao": "Impressão de jornais"
  },
  {
    "id_cnae": "299",
    "subclasse": "1811-3/02",
    "denominacao": "Impressão de livros, revistas e outras publicações periódicas"
  },
  {
    "id_cnae": "300",
    "subclasse": "1812-1/00",
    "denominacao": "Impressão de material de segurança"
  },
  {
    "id_cnae": "301",
    "subclasse": "1813-0/01",
    "denominacao": "Impressão de material para uso publicitário"
  },
  {
    "id_cnae": "302",
    "subclasse": "1813-0/99",
    "denominacao": "Impressão de material para outros usos"
  },
  {
    "id_cnae": "303",
    "subclasse": "1821-1/00",
    "denominacao": "Serviços de pré-impressão"
  },
  {
    "id_cnae": "304",
    "subclasse": "1822-9/01",
    "denominacao": "Serviços de encadernação e plastificação"
  },
  {
    "id_cnae": "305",
    "subclasse": "1822-9/99",
    "denominacao": "Serviços de acabamentos gráficos, exceto encadernação e plastificação"
  },
  {
    "id_cnae": "306",
    "subclasse": "1830-0/01",
    "denominacao": "Reprodução de som em qualquer suporte"
  },
  {
    "id_cnae": "307",
    "subclasse": "1830-0/02",
    "denominacao": "Reprodução de vídeo em qualquer suporte"
  },
  {
    "id_cnae": "308",
    "subclasse": "1830-0/03",
    "denominacao": "Reprodução de software em qualquer suporte"
  },
  { "id_cnae": "309", "subclasse": "1910-1/00", "denominacao": "Coquerias" },
  {
    "id_cnae": "310",
    "subclasse": "1921-7/00",
    "denominacao": "Fabricação de produtos do refino de petróleo"
  },
  {
    "id_cnae": "311",
    "subclasse": "1922-5/01",
    "denominacao": "Formulação de combustíveis"
  },
  {
    "id_cnae": "312",
    "subclasse": "1922-5/02",
    "denominacao": "Rerrefino de óleos lubrificantes"
  },
  {
    "id_cnae": "313",
    "subclasse": "1922-5/99",
    "denominacao": "Fabricação de outros produtos derivados do petróleo, exceto produtos do refino"
  },
  {
    "id_cnae": "314",
    "subclasse": "1931-4/00",
    "denominacao": "Fabricação de álcool"
  },
  {
    "id_cnae": "315",
    "subclasse": "1932-2/00",
    "denominacao": "Fabricação de biocombustíveis, exceto álcool"
  },
  {
    "id_cnae": "316",
    "subclasse": "2011-8/00",
    "denominacao": "Fabricação de cloro e álcalis"
  },
  {
    "id_cnae": "317",
    "subclasse": "2012-6/00",
    "denominacao": "Fabricação de intermediários para fertilizantes"
  },
  {
    "id_cnae": "318",
    "subclasse": "2013-4/01",
    "denominacao": "Fabricação de adubos e fertilizantes organo-minerais"
  },
  {
    "id_cnae": "319",
    "subclasse": "2013-4/02",
    "denominacao": "Fabricação de adubos e fertilizantes, exceto organo-minerais"
  },
  {
    "id_cnae": "320",
    "subclasse": "2014-2/00",
    "denominacao": "Fabricação de gases industriais"
  },
  {
    "id_cnae": "321",
    "subclasse": "2019-3/01",
    "denominacao": "Elaboração de combustíveis nucleares"
  },
  {
    "id_cnae": "322",
    "subclasse": "2019-3/99",
    "denominacao": "Fabricação de outros produtos químicos inorgânicos não especificados anteriormente"
  },
  {
    "id_cnae": "323",
    "subclasse": "2021-5/00",
    "denominacao": "Fabricação de produtos petroquímicos básicos"
  },
  {
    "id_cnae": "324",
    "subclasse": "2022-3/00",
    "denominacao": "Fabricação de intermediários para plastificantes, resinas e fibras"
  },
  {
    "id_cnae": "325",
    "subclasse": "2029-1/00",
    "denominacao": "Fabricação de produtos químicos orgânicos não especificados anteriormente"
  },
  {
    "id_cnae": "326",
    "subclasse": "2031-2/00",
    "denominacao": "Fabricação de resinas termoplásticas"
  },
  {
    "id_cnae": "327",
    "subclasse": "2032-1/00",
    "denominacao": "Fabricação de resinas termofixas"
  },
  {
    "id_cnae": "328",
    "subclasse": "2033-9/00",
    "denominacao": "Fabricação de elastômeros"
  },
  {
    "id_cnae": "329",
    "subclasse": "2040-1/00",
    "denominacao": "Fabricação de fibras artificiais e sintéticas"
  },
  {
    "id_cnae": "330",
    "subclasse": "2051-7/00",
    "denominacao": "Fabricação de defensivos agrícolas"
  },
  {
    "id_cnae": "331",
    "subclasse": "2052-5/00",
    "denominacao": "Fabricação de desinfestantes domissanitários"
  },
  {
    "id_cnae": "332",
    "subclasse": "2061-4/00",
    "denominacao": "Fabricação de sabões e detergentes sintéticos"
  },
  {
    "id_cnae": "333",
    "subclasse": "2062-2/00",
    "denominacao": "Fabricação de produtos de limpeza e polimento"
  },
  {
    "id_cnae": "334",
    "subclasse": "2063-1/00",
    "denominacao": "Fabricação de cosméticos, produtos de perfumaria e de higiene pessoal"
  },
  {
    "id_cnae": "335",
    "subclasse": "2071-1/00",
    "denominacao": "Fabricação de tintas, vernizes, esmaltes e lacas"
  },
  {
    "id_cnae": "336",
    "subclasse": "2072-0/00",
    "denominacao": "Fabricação de tintas de impressão"
  },
  {
    "id_cnae": "337",
    "subclasse": "2073-8/00",
    "denominacao": "Fabricação de impermeabilizantes, solventes e produtos afins"
  },
  {
    "id_cnae": "338",
    "subclasse": "2091-6/00",
    "denominacao": "Fabricação de adesivos e selantes"
  },
  {
    "id_cnae": "339",
    "subclasse": "2092-4/01",
    "denominacao": "Fabricação de pólvoras, explosivos e detonantes"
  },
  {
    "id_cnae": "340",
    "subclasse": "2092-4/02",
    "denominacao": "Fabricação de artigos pirotécnicos"
  },
  {
    "id_cnae": "341",
    "subclasse": "2092-4/03",
    "denominacao": "Fabricação de fósforos de segurança"
  },
  {
    "id_cnae": "342",
    "subclasse": "2093-2/00",
    "denominacao": "Fabricação de aditivos de uso industrial"
  },
  {
    "id_cnae": "343",
    "subclasse": "2094-1/00",
    "denominacao": "Fabricação de catalisadores"
  },
  {
    "id_cnae": "344",
    "subclasse": "2099-1/01",
    "denominacao": "Fabricação de chapas, filmes, papéis e outros materiais e produtos químicos para fotografia"
  },
  {
    "id_cnae": "345",
    "subclasse": "2099-1/99",
    "denominacao": "Fabricação de outros produtos químicos não especificados anteriormente"
  },
  {
    "id_cnae": "346",
    "subclasse": "2110-6/00",
    "denominacao": "Fabricação de produtos farmoquímicos"
  },
  {
    "id_cnae": "347",
    "subclasse": "2121-1/01",
    "denominacao": "Fabricação de medicamentos alopáticos para uso humano"
  },
  {
    "id_cnae": "348",
    "subclasse": "2121-1/02",
    "denominacao": "Fabricação de medicamentos homeopáticos para uso humano"
  },
  {
    "id_cnae": "349",
    "subclasse": "2121-1/03",
    "denominacao": "Fabricação de medicamentos fitoterápicos para uso humano"
  },
  {
    "id_cnae": "350",
    "subclasse": "2122-0/00",
    "denominacao": "Fabricação de medicamentos para uso veterinário"
  },
  {
    "id_cnae": "351",
    "subclasse": "2123-8/00",
    "denominacao": "Fabricação de preparações farmacêuticas"
  },
  {
    "id_cnae": "352",
    "subclasse": "2211-1/00",
    "denominacao": "Fabricação de pneumáticos e de câmaras-de-ar"
  },
  {
    "id_cnae": "353",
    "subclasse": "2212-9/00",
    "denominacao": "Reforma de pneumáticos usados"
  },
  {
    "id_cnae": "354",
    "subclasse": "2219-6/00",
    "denominacao": "Fabricação de artefatos de borracha não especificados  anteriormente"
  },
  {
    "id_cnae": "355",
    "subclasse": "2221-8/00",
    "denominacao": "Fabricação de laminados planos e tubulares de material plástico"
  },
  {
    "id_cnae": "356",
    "subclasse": "2222-6/00",
    "denominacao": "Fabricação de embalagens de material plástico"
  },
  {
    "id_cnae": "357",
    "subclasse": "2223-4/00",
    "denominacao": "Fabricação de tubos e acessórios de material plástico para uso na construção"
  },
  {
    "id_cnae": "358",
    "subclasse": "2229-3/01",
    "denominacao": "Fabricação de artefatos de material plástico para uso pessoal e doméstico"
  },
  {
    "id_cnae": "359",
    "subclasse": "2229-3/02",
    "denominacao": "Fabricação de artefatos de material plástico para usos industriais"
  },
  {
    "id_cnae": "360",
    "subclasse": "2229-3/03",
    "denominacao": "Fabricação de artefatos de material plástico para uso na construção, exceto tubos e acessórios"
  },
  {
    "id_cnae": "361",
    "subclasse": "2229-3/99",
    "denominacao": "Fabricação de artefatos de material plástico para outros usos não especificados anteriormente"
  },
  {
    "id_cnae": "362",
    "subclasse": "2311-7/00",
    "denominacao": "Fabricação de vidro plano e de segurança"
  },
  {
    "id_cnae": "363",
    "subclasse": "2312-5/00",
    "denominacao": "Fabricação de embalagens de vidro"
  },
  {
    "id_cnae": "364",
    "subclasse": "2319-2/00",
    "denominacao": "Fabricação de artigos de vidro"
  },
  {
    "id_cnae": "365",
    "subclasse": "2320-6/00",
    "denominacao": "Fabricação de cimento"
  },
  {
    "id_cnae": "366",
    "subclasse": "2330-3/01",
    "denominacao": "Fabricação de estruturas pré-moldadas de concreto armado, em série e sob encomenda"
  },
  {
    "id_cnae": "367",
    "subclasse": "2330-3/02",
    "denominacao": "Fabricação de artefatos de cimento para uso na construção"
  },
  {
    "id_cnae": "368",
    "subclasse": "2330-3/03",
    "denominacao": "Fabricação de artefatos de fibrocimento para uso na construção"
  },
  {
    "id_cnae": "369",
    "subclasse": "2330-3/04",
    "denominacao": "Fabricação de casas pré-moldadas de concreto"
  },
  {
    "id_cnae": "370",
    "subclasse": "2330-3/05",
    "denominacao": "Preparação de massa de concreto e argamassa para construção"
  },
  {
    "id_cnae": "371",
    "subclasse": "2330-3/99",
    "denominacao": "Fabricação de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes"
  },
  {
    "id_cnae": "372",
    "subclasse": "2341-9/00",
    "denominacao": "Fabricação de produtos cerâmicos refratários"
  },
  {
    "id_cnae": "373",
    "subclasse": "2342-7/01",
    "denominacao": "Fabricação de azulejos e pisos"
  },
  {
    "id_cnae": "374",
    "subclasse": "2342-7/02",
    "denominacao": "Fabricação de artefatos de cerâmica e barro cozido para uso na construção, exceto azulejos e pisos"
  },
  {
    "id_cnae": "375",
    "subclasse": "2349-4/01",
    "denominacao": "Fabricação de material sanitário de cerâmica"
  },
  {
    "id_cnae": "376",
    "subclasse": "2349-4/99",
    "denominacao": "Fabricação de produtos cerâmicos não refratários não especificados anteriormente"
  },
  {
    "id_cnae": "377",
    "subclasse": "2391-5/01",
    "denominacao": "Britamento de pedras, exceto associado à extração"
  },
  {
    "id_cnae": "378",
    "subclasse": "2391-5/02",
    "denominacao": "Aparelhamento de pedras para construção, exceto associado à extração"
  },
  {
    "id_cnae": "379",
    "subclasse": "2391-5/03",
    "denominacao": "Aparelhamento de placas e execução de trabalhos em mármore, granito, ardósia e outras pedras"
  },
  {
    "id_cnae": "380",
    "subclasse": "2392-3/00",
    "denominacao": "Fabricação de cal e gesso"
  },
  {
    "id_cnae": "381",
    "subclasse": "2399-1/01",
    "denominacao": "Decoração, lapidação, gravação, vitrificação e outros trabalhos em cerâmica, louça, vidro e cristal"
  },
  {
    "id_cnae": "382",
    "subclasse": "2399-1/02",
    "denominacao": "Fabricação de abrasivos"
  },
  {
    "id_cnae": "383",
    "subclasse": "2399-1/99",
    "denominacao": "Fabricação de outros produtos de minerais não metálicos não especificados anteriormente"
  },
  {
    "id_cnae": "384",
    "subclasse": "2411-3/00",
    "denominacao": "Produção de ferro-gusa"
  },
  {
    "id_cnae": "385",
    "subclasse": "2412-1/00",
    "denominacao": "Produção de ferroligas"
  },
  {
    "id_cnae": "386",
    "subclasse": "2421-1/00",
    "denominacao": "Produção de semiacabados de aço"
  },
  {
    "id_cnae": "387",
    "subclasse": "2422-9/01",
    "denominacao": "Produção de laminados planos de aço ao carbono, revestidos ou não"
  },
  {
    "id_cnae": "388",
    "subclasse": "2422-9/02",
    "denominacao": "Produção de laminados planos de aços especiais"
  },
  {
    "id_cnae": "389",
    "subclasse": "2423-7/01",
    "denominacao": "Produção de tubos de aço sem costura"
  },
  {
    "id_cnae": "390",
    "subclasse": "2423-7/02",
    "denominacao": "Produção de laminados longos de aço, exceto tubos"
  },
  {
    "id_cnae": "391",
    "subclasse": "2424-5/01",
    "denominacao": "Produção de arames de aço"
  },
  {
    "id_cnae": "392",
    "subclasse": "2424-5/02",
    "denominacao": "Produção de relaminados, trefilados e perfilados de aço, exceto arames"
  },
  {
    "id_cnae": "393",
    "subclasse": "2431-8/00",
    "denominacao": "Produção de tubos de aço com costura"
  },
  {
    "id_cnae": "394",
    "subclasse": "2439-3/00",
    "denominacao": "Produção de outros tubos de ferro e aço"
  },
  {
    "id_cnae": "395",
    "subclasse": "2441-5/01",
    "denominacao": "Produção de alumínio e suas ligas em formas primárias"
  },
  {
    "id_cnae": "396",
    "subclasse": "2441-5/02",
    "denominacao": "Produção de laminados de alumínio"
  },
  {
    "id_cnae": "397",
    "subclasse": "2442-3/00",
    "denominacao": "Metalurgia dos metais preciosos"
  },
  {
    "id_cnae": "398",
    "subclasse": "2443-1/00",
    "denominacao": "Metalurgia do cobre"
  },
  {
    "id_cnae": "399",
    "subclasse": "2449-1/01",
    "denominacao": "Produção de zinco em formas primárias"
  },
  {
    "id_cnae": "400",
    "subclasse": "2449-1/02",
    "denominacao": "Produção de laminados de zinco"
  },
  {
    "id_cnae": "401",
    "subclasse": "2449-1/03",
    "denominacao": "Fabricação de ânodos para galvanoplastia"
  },
  {
    "id_cnae": "402",
    "subclasse": "2449-1/99",
    "denominacao": "Metalurgia de outros metais não ferrosos e suas ligas não especificados anteriormente"
  },
  {
    "id_cnae": "403",
    "subclasse": "2451-2/00",
    "denominacao": "Fundição de ferro e aço"
  },
  {
    "id_cnae": "404",
    "subclasse": "2452-1/00",
    "denominacao": "Fundição de metais não ferrosos e suas ligas"
  },
  {
    "id_cnae": "405",
    "subclasse": "2511-0/00",
    "denominacao": "Fabricação de estruturas metálicas"
  },
  {
    "id_cnae": "406",
    "subclasse": "2512-8/00",
    "denominacao": "Fabricação de esquadrias de metal"
  },
  {
    "id_cnae": "407",
    "subclasse": "2513-6/00",
    "denominacao": "Fabricação de obras de caldeiraria pesada"
  },
  {
    "id_cnae": "408",
    "subclasse": "2521-7/00",
    "denominacao": "Fabricação de tanques, reservatórios metálicos e caldeiras para aquecimento central"
  },
  {
    "id_cnae": "409",
    "subclasse": "2522-5/00",
    "denominacao": "Fabricação de caldeiras geradoras de vapor, exceto para aquecimento central e para veículos"
  },
  {
    "id_cnae": "410",
    "subclasse": "2531-4/01",
    "denominacao": "Produção de forjados de aço"
  },
  {
    "id_cnae": "411",
    "subclasse": "2531-4/02",
    "denominacao": "Produção de forjados de metais não ferrosos e suas ligas"
  },
  {
    "id_cnae": "412",
    "subclasse": "2532-2/01",
    "denominacao": "Produção de artefatos estampados de metal"
  },
  {
    "id_cnae": "413",
    "subclasse": "2532-2/02",
    "denominacao": "Metalurgia do pó"
  },
  {
    "id_cnae": "414",
    "subclasse": "2539-0/01",
    "denominacao": "Serviços de usinagem, torneiria e solda"
  },
  {
    "id_cnae": "415",
    "subclasse": "2539-0/02",
    "denominacao": "Serviços de tratamento e revestimento em metais"
  },
  {
    "id_cnae": "416",
    "subclasse": "2541-1/00",
    "denominacao": "Fabricação de artigos de cutelaria"
  },
  {
    "id_cnae": "417",
    "subclasse": "2542-0/00",
    "denominacao": "Fabricação de artigos de serralheria, exceto esquadrias"
  },
  {
    "id_cnae": "418",
    "subclasse": "2543-8/00",
    "denominacao": "Fabricação de ferramentas"
  },
  {
    "id_cnae": "419",
    "subclasse": "2550-1/01",
    "denominacao": "Fabricação de equipamento bélico pesado, exceto veículos militares de combate"
  },
  {
    "id_cnae": "420",
    "subclasse": "2550-1/02",
    "denominacao": "Fabricação de armas de fogo, outras armas e munições"
  },
  {
    "id_cnae": "421",
    "subclasse": "2591-8/00",
    "denominacao": "Fabricação de embalagens metálicas"
  },
  {
    "id_cnae": "422",
    "subclasse": "2592-6/01",
    "denominacao": "Fabricação de produtos de trefilados de metal padronizados"
  },
  {
    "id_cnae": "423",
    "subclasse": "2592-6/02",
    "denominacao": "Fabricação de produtos de trefilados de metal, exceto padronizados"
  },
  {
    "id_cnae": "424",
    "subclasse": "2593-4/00",
    "denominacao": "Fabricação de artigos de metal para uso doméstico e pessoal"
  },
  {
    "id_cnae": "425",
    "subclasse": "2599-3/01",
    "denominacao": "Serviços de confecção de armações metálicas para a construção"
  },
  {
    "id_cnae": "426",
    "subclasse": "2599-3/02",
    "denominacao": "Serviço de corte e dobra de metais"
  },
  {
    "id_cnae": "427",
    "subclasse": "2599-3/99",
    "denominacao": "Fabricação de outros produtos de metal não especificados anteriormente"
  },
  {
    "id_cnae": "428",
    "subclasse": "2610-8/00",
    "denominacao": "Fabricação de componentes eletrônicos"
  },
  {
    "id_cnae": "429",
    "subclasse": "2621-3/00",
    "denominacao": "Fabricação de equipamentos de informática"
  },
  {
    "id_cnae": "430",
    "subclasse": "2622-1/00",
    "denominacao": "Fabricação de periféricos para equipamentos de informática"
  },
  {
    "id_cnae": "431",
    "subclasse": "2631-1/00",
    "denominacao": "Fabricação de equipamentos transmissores de comunicação, peças e acessórios"
  },
  {
    "id_cnae": "432",
    "subclasse": "2632-9/00",
    "denominacao": "Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação, peças e acessórios"
  },
  {
    "id_cnae": "433",
    "subclasse": "2640-0/00",
    "denominacao": "Fabricação de aparelhos de recepção, reprodução, gravação e amplificação de áudio e vídeo"
  },
  {
    "id_cnae": "434",
    "subclasse": "2651-5/00",
    "denominacao": "Fabricação de aparelhos e equipamentos de medida, teste e controle"
  },
  {
    "id_cnae": "435",
    "subclasse": "2652-3/00",
    "denominacao": "Fabricação de cronômetros e relógios"
  },
  {
    "id_cnae": "436",
    "subclasse": "2660-4/00",
    "denominacao": "Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação"
  },
  {
    "id_cnae": "437",
    "subclasse": "2670-1/01",
    "denominacao": "Fabricação de equipamentos e instrumentos ópticos, peças e acessórios"
  },
  {
    "id_cnae": "438",
    "subclasse": "2670-1/02",
    "denominacao": "Fabricação de aparelhos fotográficos e cinematográficos, peças e acessórios"
  },
  {
    "id_cnae": "439",
    "subclasse": "2680-9/00",
    "denominacao": "Fabricação de mídias virgens, magnéticas e ópticas"
  },
  {
    "id_cnae": "440",
    "subclasse": "2710-4/01",
    "denominacao": "Fabricação de geradores de corrente contínua e alternada, peças e acessórios"
  },
  {
    "id_cnae": "441",
    "subclasse": "2710-4/02",
    "denominacao": "Fabricação de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessórios"
  },
  {
    "id_cnae": "442",
    "subclasse": "2710-4/03",
    "denominacao": "Fabricação de motores elétricos, peças e acessórios"
  },
  {
    "id_cnae": "443",
    "subclasse": "2721-0/00",
    "denominacao": "Fabricação de pilhas, baterias e acumuladores elétricos, exceto para veículos automotores"
  },
  {
    "id_cnae": "444",
    "subclasse": "2722-8/01",
    "denominacao": "Fabricação de baterias e acumuladores para veículos automotores"
  },
  {
    "id_cnae": "445",
    "subclasse": "2722-8/02",
    "denominacao": "Recondicionamento de baterias e acumuladores para veículos automotores"
  },
  {
    "id_cnae": "446",
    "subclasse": "2731-7/00",
    "denominacao": "Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica"
  },
  {
    "id_cnae": "447",
    "subclasse": "2732-5/00",
    "denominacao": "Fabricação de material elétrico para instalações em circuito de consumo"
  },
  {
    "id_cnae": "448",
    "subclasse": "2733-3/00",
    "denominacao": "Fabricação de fios, cabos e condutores elétricos isolados"
  },
  {
    "id_cnae": "449",
    "subclasse": "2740-6/01",
    "denominacao": "Fabricação de lâmpadas"
  },
  {
    "id_cnae": "450",
    "subclasse": "2740-6/02",
    "denominacao": "Fabricação de luminárias e outros equipamentos de iluminação"
  },
  {
    "id_cnae": "451",
    "subclasse": "2751-1/00",
    "denominacao": "Fabricação de fogões, refrigeradores e máquinas de lavar e secar para uso doméstico, peças e acessórios"
  },
  {
    "id_cnae": "452",
    "subclasse": "2759-7/01",
    "denominacao": "Fabricação de aparelhos elétricos de uso pessoal, peças e acessórios"
  },
  {
    "id_cnae": "453",
    "subclasse": "2759-7/99",
    "denominacao": "Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente, peças e acessórios"
  },
  {
    "id_cnae": "454",
    "subclasse": "2790-2/01",
    "denominacao": "Fabricação de eletrodos, contatos e outros artigos de carvão e grafita para uso elétrico, eletroímãs e isoladores"
  },
  {
    "id_cnae": "455",
    "subclasse": "2790-2/02",
    "denominacao": "Fabricação de equipamentos para sinalização e alarme"
  },
  {
    "id_cnae": "456",
    "subclasse": "2790-2/99",
    "denominacao": "Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente"
  },
  {
    "id_cnae": "457",
    "subclasse": "2811-9/00",
    "denominacao": "Fabricação de motores e turbinas, peças e acessórios, exceto para aviões e veículos rodoviários"
  },
  {
    "id_cnae": "458",
    "subclasse": "2812-7/00",
    "denominacao": "Fabricação de equipamentos hidráulicos e pneumáticos, peças e acessórios, exceto válvulas"
  },
  {
    "id_cnae": "459",
    "subclasse": "2813-5/00",
    "denominacao": "Fabricação de válvulas, registros e dispositivos semelhantes, peças e acessórios"
  },
  {
    "id_cnae": "460",
    "subclasse": "2814-3/01",
    "denominacao": "Fabricação de compressores para uso industrial, peças e acessórios"
  },
  {
    "id_cnae": "461",
    "subclasse": "2814-3/02",
    "denominacao": "Fabricação de compressores para uso não industrial, peças e acessórios"
  },
  {
    "id_cnae": "462",
    "subclasse": "2815-1/01",
    "denominacao": "Fabricação de rolamentos para fins industriais"
  },
  {
    "id_cnae": "463",
    "subclasse": "2815-1/02",
    "denominacao": "Fabricação de equipamentos de transmissão para fins industriais, exceto rolamentos"
  },
  {
    "id_cnae": "464",
    "subclasse": "2821-6/01",
    "denominacao": "Fabricação de fornos industriais, aparelhos e equipamentos não elétricos para instalações térmicas, peças e acessórios"
  },
  {
    "id_cnae": "465",
    "subclasse": "2821-6/02",
    "denominacao": "Fabricação de estufas e fornos elétricos para fins industriais, peças e acessórios"
  },
  {
    "id_cnae": "466",
    "subclasse": "2822-4/01",
    "denominacao": "Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de pessoas, peças e acessórios"
  },
  {
    "id_cnae": "467",
    "subclasse": "2822-4/02",
    "denominacao": "Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas, peças e acessórios"
  },
  {
    "id_cnae": "468",
    "subclasse": "2823-2/00",
    "denominacao": "Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial, peças e acessórios"
  },
  {
    "id_cnae": "469",
    "subclasse": "2824-1/01",
    "denominacao": "Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial"
  },
  {
    "id_cnae": "470",
    "subclasse": "2824-1/02",
    "denominacao": "Fabricação de aparelhos e equipamentos de ar condicionado para uso não industrial"
  },
  {
    "id_cnae": "471",
    "subclasse": "2825-9/00",
    "denominacao": "Fabricação de máquinas e equipamentos para saneamento básico e ambiental, peças e acessórios"
  },
  {
    "id_cnae": "472",
    "subclasse": "2829-1/01",
    "denominacao": "Fabricação de máquinas de escrever, calcular e outros equipamentos não eletrônicos para escritório, peças e acessórios"
  },
  {
    "id_cnae": "473",
    "subclasse": "2829-1/99",
    "denominacao": "Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente, peças e acessórios"
  },
  {
    "id_cnae": "474",
    "subclasse": "2831-3/00",
    "denominacao": "Fabricação de tratores agrícolas, peças e acessórios"
  },
  {
    "id_cnae": "475",
    "subclasse": "2832-1/00",
    "denominacao": "Fabricação de equipamentos para irrigação agrícola, peças e acessórios"
  },
  {
    "id_cnae": "476",
    "subclasse": "2833-0/00",
    "denominacao": "Fabricação de máquinas e equipamentos para a agricultura e pecuária, peças e acessórios, exceto para irrigação"
  },
  {
    "id_cnae": "477",
    "subclasse": "2840-2/00",
    "denominacao": "Fabricação de máquinas-ferramenta, peças e acessórios"
  },
  {
    "id_cnae": "478",
    "subclasse": "2851-8/00",
    "denominacao": "Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo, peças e acessórios"
  },
  {
    "id_cnae": "479",
    "subclasse": "2852-6/00",
    "denominacao": "Fabricação de outras máquinas e equipamentos para uso na extração mineral, peças e acessórios, exceto na extração de petróleo"
  },
  {
    "id_cnae": "480",
    "subclasse": "2853-4/00",
    "denominacao": "Fabricação de tratores, peças e acessórios, exceto agrícolas"
  },
  {
    "id_cnae": "481",
    "subclasse": "2854-2/00",
    "denominacao": "Fabricação de máquinas e equipamentos para terraplenagem, pavimentação e construção, peças e acessórios, exceto tratores"
  },
  {
    "id_cnae": "482",
    "subclasse": "2861-5/00",
    "denominacao": "Fabricação de máquinas para a indústria metalúrgica, peças e acessórios, exceto máquinas-ferramenta"
  },
  {
    "id_cnae": "483",
    "subclasse": "2862-3/00",
    "denominacao": "Fabricação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo, peças e acessórios"
  },
  {
    "id_cnae": "484",
    "subclasse": "2863-1/00",
    "denominacao": "Fabricação de máquinas e equipamentos para a indústria têxtil, peças e acessórios"
  },
  {
    "id_cnae": "485",
    "subclasse": "2864-0/00",
    "denominacao": "Fabricação de máquinas e equipamentos para as indústrias do vestuário, do couro e de calçados, peças e acessórios"
  },
  {
    "id_cnae": "486",
    "subclasse": "2865-8/00",
    "denominacao": "Fabricação de máquinas e equipamentos para as indústrias de celulose, papel e papelão e artefatos, peças e acessórios"
  },
  {
    "id_cnae": "487",
    "subclasse": "2866-6/00",
    "denominacao": "Fabricação de máquinas e equipamentos para a indústria do plástico, peças e acessórios"
  },
  {
    "id_cnae": "488",
    "subclasse": "2869-1/00",
    "denominacao": "Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente, peças e acessórios"
  },
  {
    "id_cnae": "489",
    "subclasse": "2910-7/01",
    "denominacao": "Fabricação de automóveis, camionetas e utilitários"
  },
  {
    "id_cnae": "490",
    "subclasse": "2910-7/02",
    "denominacao": "Fabricação de chassis com motor para automóveis, camionetas e utilitários"
  },
  {
    "id_cnae": "491",
    "subclasse": "2910-7/03",
    "denominacao": "Fabricação de motores para automóveis, camionetas e utilitários"
  },
  {
    "id_cnae": "492",
    "subclasse": "2920-4/01",
    "denominacao": "Fabricação de caminhões e ônibus"
  },
  {
    "id_cnae": "493",
    "subclasse": "2920-4/02",
    "denominacao": "Fabricação de motores para caminhões e ônibus"
  },
  {
    "id_cnae": "494",
    "subclasse": "2930-1/01",
    "denominacao": "Fabricação de cabines, carrocerias e reboques para caminhões"
  },
  {
    "id_cnae": "495",
    "subclasse": "2930-1/02",
    "denominacao": "Fabricação de carrocerias para ônibus"
  },
  {
    "id_cnae": "496",
    "subclasse": "2930-1/03",
    "denominacao": "Fabricação de cabines, carrocerias e reboques para outros veículos automotores, exceto caminhões e ônibus"
  },
  {
    "id_cnae": "497",
    "subclasse": "2941-7/00",
    "denominacao": "Fabricação de peças e acessórios para o sistema motor de veículos automotores"
  },
  {
    "id_cnae": "498",
    "subclasse": "2942-5/00",
    "denominacao": "Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores"
  },
  {
    "id_cnae": "499",
    "subclasse": "2943-3/00",
    "denominacao": "Fabricação de peças e acessórios para o sistema de freios de veículos automotores"
  },
  {
    "id_cnae": "500",
    "subclasse": "2944-1/00",
    "denominacao": "Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores"
  },
  {
    "id_cnae": "501",
    "subclasse": "2945-0/00",
    "denominacao": "Fabricação de material elétrico e eletrônico para veículos automotores, exceto baterias"
  },
  {
    "id_cnae": "502",
    "subclasse": "2949-2/01",
    "denominacao": "Fabricação de bancos e estofados para veículos automotores"
  },
  {
    "id_cnae": "503",
    "subclasse": "2949-2/99",
    "denominacao": "Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente"
  },
  {
    "id_cnae": "504",
    "subclasse": "2950-6/00",
    "denominacao": "Recondicionamento e recuperação de motores para veículos automotores"
  },
  {
    "id_cnae": "505",
    "subclasse": "3011-3/01",
    "denominacao": "Construção de embarcações de grande porte"
  },
  {
    "id_cnae": "506",
    "subclasse": "3011-3/02",
    "denominacao": "Construção de embarcações para uso comercial e para usos especiais, exceto de grande porte"
  },
  {
    "id_cnae": "507",
    "subclasse": "3012-1/00",
    "denominacao": "Construção de embarcações para esporte e lazer"
  },
  {
    "id_cnae": "508",
    "subclasse": "3031-8/00",
    "denominacao": "Fabricação de locomotivas, vagões e outros materiais rodantes"
  },
  {
    "id_cnae": "509",
    "subclasse": "3032-6/00",
    "denominacao": "Fabricação de peças e acessórios para veículos ferroviários"
  },
  {
    "id_cnae": "510",
    "subclasse": "3041-5/00",
    "denominacao": "Fabricação de aeronaves"
  },
  {
    "id_cnae": "511",
    "subclasse": "3042-3/00",
    "denominacao": "Fabricação de turbinas, motores e outros componentes e peças para aeronaves"
  },
  {
    "id_cnae": "512",
    "subclasse": "3050-4/00",
    "denominacao": "Fabricação de veículos militares de combate"
  },
  {
    "id_cnae": "513",
    "subclasse": "3091-1/01",
    "denominacao": "Fabricação de motocicletas"
  },
  {
    "id_cnae": "514",
    "subclasse": "3091-1/02",
    "denominacao": "Fabricação de peças e acessórios para motocicletas"
  },
  {
    "id_cnae": "515",
    "subclasse": "3092-0/00",
    "denominacao": "Fabricação de bicicletas e triciclos não motorizados, peças e acessórios"
  },
  {
    "id_cnae": "516",
    "subclasse": "3099-7/00",
    "denominacao": "Fabricação de equipamentos de transporte não especificados anteriormente"
  },
  {
    "id_cnae": "517",
    "subclasse": "3101-2/00",
    "denominacao": "Fabricação de móveis com predominância de madeira"
  },
  {
    "id_cnae": "518",
    "subclasse": "3102-1/00",
    "denominacao": "Fabricação de móveis com predominância de metal"
  },
  {
    "id_cnae": "519",
    "subclasse": "3103-9/00",
    "denominacao": "Fabricação de móveis de outros materiais, exceto madeira e metal"
  },
  {
    "id_cnae": "520",
    "subclasse": "3104-7/00",
    "denominacao": "Fabricação de colchões"
  },
  {
    "id_cnae": "521",
    "subclasse": "3211-6/01",
    "denominacao": "Lapidação de gemas"
  },
  {
    "id_cnae": "522",
    "subclasse": "3211-6/02",
    "denominacao": "Fabricação de artefatos de joalheria e ourivesaria"
  },
  {
    "id_cnae": "523",
    "subclasse": "3211-6/03",
    "denominacao": "Cunhagem de moedas e medalhas"
  },
  {
    "id_cnae": "524",
    "subclasse": "3212-4/00",
    "denominacao": "Fabricação de bijuterias e artefatos semelhantes"
  },
  {
    "id_cnae": "525",
    "subclasse": "3220-5/00",
    "denominacao": "Fabricação de instrumentos musicais, peças e acessórios"
  },
  {
    "id_cnae": "526",
    "subclasse": "3230-2/00",
    "denominacao": "Fabricação de artefatos para pesca e esporte"
  },
  {
    "id_cnae": "527",
    "subclasse": "3240-0/01",
    "denominacao": "Fabricação de jogos eletrônicos"
  },
  {
    "id_cnae": "528",
    "subclasse": "3240-0/02",
    "denominacao": "Fabricação de mesas de bilhar, de sinuca e acessórios não associada à locação"
  },
  {
    "id_cnae": "529",
    "subclasse": "3240-0/03",
    "denominacao": "Fabricação de mesas de bilhar, de sinuca e acessórios associada à locação"
  },
  {
    "id_cnae": "530",
    "subclasse": "3240-0/99",
    "denominacao": "Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente"
  },
  {
    "id_cnae": "531",
    "subclasse": "3250-7/01",
    "denominacao": "Fabricação de instrumentos não eletrônicos e utensílios para uso médico, cirúrgico, odontológico e de laboratório"
  },
  {
    "id_cnae": "532",
    "subclasse": "3250-7/02",
    "denominacao": "Fabricação de mobiliário para uso médico, cirúrgico, odontológico e de laboratório"
  },
  {
    "id_cnae": "533",
    "subclasse": "3250-7/03",
    "denominacao": "Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda"
  },
  {
    "id_cnae": "534",
    "subclasse": "3250-7/04",
    "denominacao": "Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral, exceto sob encomenda"
  },
  {
    "id_cnae": "535",
    "subclasse": "3250-7/05",
    "denominacao": "Fabricação de materiais para medicina e odontologia"
  },
  {
    "id_cnae": "536",
    "subclasse": "3250-7/06",
    "denominacao": "Serviços de prótese dentária"
  },
  {
    "id_cnae": "537",
    "subclasse": "3250-7/07",
    "denominacao": "Fabricação de artigos ópticos"
  },
  {
    "id_cnae": "538",
    "subclasse": "3250-7/09",
    "denominacao": "Serviço de laboratório óptico"
  },
  {
    "id_cnae": "539",
    "subclasse": "3291-4/00",
    "denominacao": "Fabricação de escovas, pincéis e vassouras"
  },
  {
    "id_cnae": "540",
    "subclasse": "3292-2/01",
    "denominacao": "Fabricação de roupas de proteção e segurança e resistentes a fogo"
  },
  {
    "id_cnae": "541",
    "subclasse": "3292-2/02",
    "denominacao": "Fabricação de equipamentos e acessórios para segurança pessoal e profissional"
  },
  {
    "id_cnae": "542",
    "subclasse": "3299-0/01",
    "denominacao": "Fabricação de guarda-chuvas e similares"
  },
  {
    "id_cnae": "543",
    "subclasse": "3299-0/02",
    "denominacao": "Fabricação de canetas, lápis e outros artigos para escritório"
  },
  {
    "id_cnae": "544",
    "subclasse": "3299-0/03",
    "denominacao": "Fabricação de letras, letreiros e placas de qualquer material, exceto luminosos"
  },
  {
    "id_cnae": "545",
    "subclasse": "3299-0/04",
    "denominacao": "Fabricação de painéis e letreiros luminosos"
  },
  {
    "id_cnae": "546",
    "subclasse": "3299-0/05",
    "denominacao": "Fabricação de aviamentos para costura"
  },
  {
    "id_cnae": "547",
    "subclasse": "3299-0/06",
    "denominacao": "Fabricação de velas, inclusive decorativas"
  },
  {
    "id_cnae": "548",
    "subclasse": "3299-0/99",
    "denominacao": "Fabricação de produtos diversos não especificados anteriormente"
  },
  {
    "id_cnae": "549",
    "subclasse": "3311-2/00",
    "denominacao": "Manutenção e reparação de tanques, reservatórios metálicos e caldeiras, exceto para veículos"
  },
  {
    "id_cnae": "550",
    "subclasse": "3312-1/02",
    "denominacao": "Manutenção e reparação de aparelhos e instrumentos de medida, teste e controle"
  },
  {
    "id_cnae": "551",
    "subclasse": "3312-1/03",
    "denominacao": "Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação"
  },
  {
    "id_cnae": "552",
    "subclasse": "3312-1/04",
    "denominacao": "Manutenção e reparação de equipamentos e instrumentos ópticos"
  },
  {
    "id_cnae": "553",
    "subclasse": "3313-9/01",
    "denominacao": "Manutenção e reparação de geradores, transformadores e motores elétricos"
  },
  {
    "id_cnae": "554",
    "subclasse": "3313-9/02",
    "denominacao": "Manutenção e reparação de baterias e acumuladores elétricos, exceto para veículos"
  },
  {
    "id_cnae": "555",
    "subclasse": "3313-9/99",
    "denominacao": "Manutenção e reparação de máquinas, aparelhos e materiais elétricos não especificados anteriormente"
  },
  {
    "id_cnae": "556",
    "subclasse": "3314-7/01",
    "denominacao": "Manutenção e reparação de máquinas motrizes não elétricas"
  },
  {
    "id_cnae": "557",
    "subclasse": "3314-7/02",
    "denominacao": "Manutenção e reparação de equipamentos hidráulicos e pneumáticos, exceto válvulas"
  },
  {
    "id_cnae": "558",
    "subclasse": "3314-7/03",
    "denominacao": "Manutenção e reparação de válvulas industriais"
  },
  {
    "id_cnae": "559",
    "subclasse": "3314-7/04",
    "denominacao": "Manutenção e reparação de compressores"
  },
  {
    "id_cnae": "560",
    "subclasse": "3314-7/05",
    "denominacao": "Manutenção e reparação de equipamentos de transmissão para fins industriais"
  },
  {
    "id_cnae": "561",
    "subclasse": "3314-7/06",
    "denominacao": "Manutenção e reparação de máquinas, aparelhos e equipamentos para instalações térmicas"
  },
  {
    "id_cnae": "562",
    "subclasse": "3314-7/07",
    "denominacao": "Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial"
  },
  {
    "id_cnae": "563",
    "subclasse": "3314-7/08",
    "denominacao": "Manutenção e reparação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas"
  },
  {
    "id_cnae": "564",
    "subclasse": "3314-7/09",
    "denominacao": "Manutenção e reparação de máquinas de escrever, calcular e de outros equipamentos não eletrônicos para escritório"
  },
  {
    "id_cnae": "565",
    "subclasse": "3314-7/10",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente"
  },
  {
    "id_cnae": "566",
    "subclasse": "3314-7/11",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária"
  },
  {
    "id_cnae": "567",
    "subclasse": "3314-7/12",
    "denominacao": "Manutenção e reparação de tratores agrícolas"
  },
  {
    "id_cnae": "568",
    "subclasse": "3314-7/13",
    "denominacao": "Manutenção e reparação de máquinas-ferramenta"
  },
  {
    "id_cnae": "569",
    "subclasse": "3314-7/14",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo"
  },
  {
    "id_cnae": "570",
    "subclasse": "3314-7/15",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo"
  },
  {
    "id_cnae": "571",
    "subclasse": "3314-7/16",
    "denominacao": "Manutenção e reparação de tratores, exceto agrícolas"
  },
  {
    "id_cnae": "572",
    "subclasse": "3314-7/17",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos de terraplenagem, pavimentação e construção, exceto tratores"
  },
  {
    "id_cnae": "573",
    "subclasse": "3314-7/18",
    "denominacao": "Manutenção e reparação de máquinas para a indústria metalúrgica, exceto máquinas-ferramenta"
  },
  {
    "id_cnae": "574",
    "subclasse": "3314-7/19",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo"
  },
  {
    "id_cnae": "575",
    "subclasse": "3314-7/20",
    "denominacao": "Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do vestuário, do couro e calçados"
  },
  {
    "id_cnae": "576",
    "subclasse": "3314-7/21",
    "denominacao": "Manutenção e reparação de máquinas e aparelhos para a indústria de celulose, papel e papelão e artefatos"
  },
  {
    "id_cnae": "577",
    "subclasse": "3314-7/22",
    "denominacao": "Manutenção e reparação de máquinas e aparelhos para a indústria do plástico"
  },
  {
    "id_cnae": "578",
    "subclasse": "3314-7/99",
    "denominacao": "Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente"
  },
  {
    "id_cnae": "579",
    "subclasse": "3315-5/00",
    "denominacao": "Manutenção e reparação de veículos ferroviários"
  },
  {
    "id_cnae": "580",
    "subclasse": "3316-3/01",
    "denominacao": "Manutenção e reparação de aeronaves, exceto a manutenção na pista"
  },
  {
    "id_cnae": "581",
    "subclasse": "3316-3/02",
    "denominacao": "Manutenção de aeronaves na pista"
  },
  {
    "id_cnae": "582",
    "subclasse": "3317-1/01",
    "denominacao": "Manutenção e reparação de embarcações e estruturas flutuantes"
  },
  {
    "id_cnae": "583",
    "subclasse": "3317-1/02",
    "denominacao": "Manutenção e reparação de embarcações para esporte e lazer"
  },
  {
    "id_cnae": "584",
    "subclasse": "3319-8/00",
    "denominacao": "Manutenção e reparação de equipamentos e produtos não especificados anteriormente"
  },
  {
    "id_cnae": "585",
    "subclasse": "3321-0/00",
    "denominacao": "Instalação de máquinas e equipamentos industriais"
  },
  {
    "id_cnae": "586",
    "subclasse": "3329-5/01",
    "denominacao": "Serviços de montagem de móveis de qualquer material"
  },
  {
    "id_cnae": "587",
    "subclasse": "3329-5/99",
    "denominacao": "Instalação de outros equipamentos não especificados anteriormente"
  },
  {
    "id_cnae": "588",
    "subclasse": "3511-5/01",
    "denominacao": "Geração de energia elétrica"
  },
  {
    "id_cnae": "589",
    "subclasse": "3511-5/02",
    "denominacao": "Atividades de coordenação e controle da operação da geração e transmissão de energia elétrica"
  },
  {
    "id_cnae": "590",
    "subclasse": "3512-3/00",
    "denominacao": "Transmissão de energia elétrica"
  },
  {
    "id_cnae": "591",
    "subclasse": "3513-1/00",
    "denominacao": "Comércio atacadista de energia elétrica"
  },
  {
    "id_cnae": "592",
    "subclasse": "3514-0/00",
    "denominacao": "Distribuição de energia elétrica"
  },
  {
    "id_cnae": "593",
    "subclasse": "3520-4/01",
    "denominacao": "Produção de gás; processamento de gás natural"
  },
  {
    "id_cnae": "594",
    "subclasse": "3520-4/02",
    "denominacao": "Distribuição de combustíveis gasosos por redes urbanas"
  },
  {
    "id_cnae": "595",
    "subclasse": "3530-1/00",
    "denominacao": "Produção e distribuição de vapor, água quente e ar condicionado"
  },
  {
    "id_cnae": "596",
    "subclasse": "3600-6/01",
    "denominacao": "Captação, tratamento e distribuição de água"
  },
  {
    "id_cnae": "597",
    "subclasse": "3600-6/02",
    "denominacao": "Distribuição de água por caminhões"
  },
  {
    "id_cnae": "598",
    "subclasse": "3701-1/00",
    "denominacao": "Gestão de redes de esgoto"
  },
  {
    "id_cnae": "599",
    "subclasse": "3702-9/00",
    "denominacao": "Atividades relacionadas a esgoto, exceto a gestão de redes"
  },
  {
    "id_cnae": "600",
    "subclasse": "3811-4/00",
    "denominacao": "Coleta de resíduos não perigosos"
  },
  {
    "id_cnae": "601",
    "subclasse": "3812-2/00",
    "denominacao": "Coleta de resíduos perigosos"
  },
  {
    "id_cnae": "602",
    "subclasse": "3821-1/00",
    "denominacao": "Tratamento e disposição de resíduos não perigosos"
  },
  {
    "id_cnae": "603",
    "subclasse": "3822-0/00",
    "denominacao": "Tratamento e disposição de resíduos perigosos"
  },
  {
    "id_cnae": "604",
    "subclasse": "3831-9/01",
    "denominacao": "Recuperação de sucatas de alumínio"
  },
  {
    "id_cnae": "605",
    "subclasse": "3831-9/99",
    "denominacao": "Recuperação de materiais metálicos, exceto alumínio"
  },
  {
    "id_cnae": "606",
    "subclasse": "3832-7/00",
    "denominacao": "Recuperação de materiais plásticos"
  },
  {
    "id_cnae": "607",
    "subclasse": "3839-4/01",
    "denominacao": "Usinas de compostagem"
  },
  {
    "id_cnae": "608",
    "subclasse": "3839-4/99",
    "denominacao": "Recuperação de materiais não especificados anteriormente"
  },
  {
    "id_cnae": "609",
    "subclasse": "3900-5/00",
    "denominacao": "Descontaminação e outros serviços de gestão de resíduos"
  },
  {
    "id_cnae": "610",
    "subclasse": "4110-7/00",
    "denominacao": "Incorporação de empreendimentos imobiliários"
  },
  {
    "id_cnae": "611",
    "subclasse": "4120-4/00",
    "denominacao": "Construção de edifícios"
  },
  {
    "id_cnae": "612",
    "subclasse": "4211-1/01",
    "denominacao": "Construção de rodovias e ferrovias"
  },
  {
    "id_cnae": "613",
    "subclasse": "4211-1/02",
    "denominacao": "Pintura para sinalização em pistas rodoviárias e aeroportos"
  },
  {
    "id_cnae": "614",
    "subclasse": "4212-0/00",
    "denominacao": "Construção de obras de arte especiais"
  },
  {
    "id_cnae": "615",
    "subclasse": "4213-8/00",
    "denominacao": "Obras de urbanização - ruas, praças e calçadas"
  },
  {
    "id_cnae": "616",
    "subclasse": "4221-9/01",
    "denominacao": "Construção de barragens e represas para geração de energia  elétrica"
  },
  {
    "id_cnae": "617",
    "subclasse": "4221-9/02",
    "denominacao": "Construção de estações e redes de distribuição de energia elétrica"
  },
  {
    "id_cnae": "618",
    "subclasse": "4221-9/03",
    "denominacao": "Manutenção de redes de distribuição de energia elétrica"
  },
  {
    "id_cnae": "619",
    "subclasse": "4221-9/04",
    "denominacao": "Construção de estações e redes de telecomunicações"
  },
  {
    "id_cnae": "620",
    "subclasse": "4221-9/05",
    "denominacao": "Manutenção de estações e redes de telecomunicações"
  },
  {
    "id_cnae": "621",
    "subclasse": "4222-7/01",
    "denominacao": "Construção de redes de abastecimento de água, coleta de esgoto e construções correlatas, exceto obras de irrigação"
  },
  {
    "id_cnae": "622",
    "subclasse": "4222-7/02",
    "denominacao": "Obras de irrigação"
  },
  {
    "id_cnae": "623",
    "subclasse": "4223-5/00",
    "denominacao": "Construção de redes de transportes por dutos, exceto para água e esgoto"
  },
  {
    "id_cnae": "624",
    "subclasse": "4291-0/00",
    "denominacao": "Obras portuárias, marítimas e fluviais"
  },
  {
    "id_cnae": "625",
    "subclasse": "4292-8/01",
    "denominacao": "Montagem de estruturas metálicas"
  },
  {
    "id_cnae": "626",
    "subclasse": "4292-8/02",
    "denominacao": "Obras de montagem industrial"
  },
  {
    "id_cnae": "627",
    "subclasse": "4299-5/01",
    "denominacao": "Construção de instalações esportivas e recreativas"
  },
  {
    "id_cnae": "628",
    "subclasse": "4299-5/99",
    "denominacao": "Outras obras de engenharia civil não especificadas anteriormente"
  },
  {
    "id_cnae": "629",
    "subclasse": "4311-8/01",
    "denominacao": "Demolição de edifícios e outras estruturas"
  },
  {
    "id_cnae": "630",
    "subclasse": "4311-8/02",
    "denominacao": "Preparação de canteiro e limpeza de terreno"
  },
  {
    "id_cnae": "631",
    "subclasse": "4312-6/00",
    "denominacao": "Perfurações e sondagens"
  },
  {
    "id_cnae": "632",
    "subclasse": "4313-4/00",
    "denominacao": "Obras de terraplenagem"
  },
  {
    "id_cnae": "633",
    "subclasse": "4319-3/00",
    "denominacao": "Serviços de preparação do terreno não especificados anteriormente"
  },
  {
    "id_cnae": "634",
    "subclasse": "4321-5/00",
    "denominacao": "Instalação e manutenção elétrica"
  },
  {
    "id_cnae": "635",
    "subclasse": "4322-3/01",
    "denominacao": "Instalações hidráulicas, sanitárias e de gás"
  },
  {
    "id_cnae": "636",
    "subclasse": "4322-3/02",
    "denominacao": "Instalação e manutenção de sistemas centrais de ar condicionado, de ventilação e refrigeração"
  },
  {
    "id_cnae": "637",
    "subclasse": "4322-3/03",
    "denominacao": "Instalações de sistema de prevenção contra incêndio"
  },
  {
    "id_cnae": "638",
    "subclasse": "4329-1/01",
    "denominacao": "Instalação de painéis publicitários"
  },
  {
    "id_cnae": "639",
    "subclasse": "4329-1/02",
    "denominacao": "Instalação de equipamentos para orientação à navegação marítima, fluvial e lacustre"
  },
  {
    "id_cnae": "640",
    "subclasse": "4329-1/03",
    "denominacao": "Instalação, manutenção e reparação de elevadores, escadas e esteiras rolantes"
  },
  {
    "id_cnae": "641",
    "subclasse": "4329-1/04",
    "denominacao": "Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas, portos e aeroportos"
  },
  {
    "id_cnae": "642",
    "subclasse": "4329-1/05",
    "denominacao": "Tratamentos térmicos, acústicos ou de vibração"
  },
  {
    "id_cnae": "643",
    "subclasse": "4329-1/99",
    "denominacao": "Outras obras de instalações em construções não especificadas anteriormente"
  },
  {
    "id_cnae": "644",
    "subclasse": "4330-4/01",
    "denominacao": "Impermeabilização em obras de engenharia civil"
  },
  {
    "id_cnae": "645",
    "subclasse": "4330-4/02",
    "denominacao": "Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material"
  },
  {
    "id_cnae": "646",
    "subclasse": "4330-4/03",
    "denominacao": "Obras de acabamento em gesso e estuque"
  },
  {
    "id_cnae": "647",
    "subclasse": "4330-4/04",
    "denominacao": "Serviços de pintura de edifícios em geral"
  },
  {
    "id_cnae": "648",
    "subclasse": "4330-4/05",
    "denominacao": "Aplicação de revestimentos e de resinas em interiores e exteriores"
  },
  {
    "id_cnae": "649",
    "subclasse": "4330-4/99",
    "denominacao": "Outras obras de acabamento da construção"
  },
  {
    "id_cnae": "650",
    "subclasse": "4391-6/00",
    "denominacao": "Obras de fundações"
  },
  {
    "id_cnae": "651",
    "subclasse": "4399-1/01",
    "denominacao": "Administração de obras"
  },
  {
    "id_cnae": "652",
    "subclasse": "4399-1/02",
    "denominacao": "Montagem e desmontagem de andaimes e outras estruturas temporárias"
  },
  {
    "id_cnae": "653",
    "subclasse": "4399-1/03",
    "denominacao": "Obras de alvenaria"
  },
  {
    "id_cnae": "654",
    "subclasse": "4399-1/04",
    "denominacao": "Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras"
  },
  {
    "id_cnae": "655",
    "subclasse": "4399-1/05",
    "denominacao": "Perfuração e construção de poços de água"
  },
  {
    "id_cnae": "656",
    "subclasse": "4399-1/99",
    "denominacao": "Serviços especializados para construção não especificados anteriormente"
  },
  {
    "id_cnae": "657",
    "subclasse": "4511-1/01",
    "denominacao": "Comércio a varejo de automóveis, camionetas e utilitários novos"
  },
  {
    "id_cnae": "658",
    "subclasse": "4511-1/02",
    "denominacao": "Comércio a varejo de automóveis, camionetas e utilitários usados"
  },
  {
    "id_cnae": "659",
    "subclasse": "4511-1/03",
    "denominacao": "Comércio por atacado de automóveis, camionetas e utilitários novos e usados"
  },
  {
    "id_cnae": "660",
    "subclasse": "4511-1/04",
    "denominacao": "Comércio por atacado de caminhões novos e usados"
  },
  {
    "id_cnae": "661",
    "subclasse": "4511-1/05",
    "denominacao": "Comércio por atacado de reboques e semireboques novos e usados"
  },
  {
    "id_cnae": "662",
    "subclasse": "4511-1/06",
    "denominacao": "Comércio por atacado de ônibus e micro-ônibus novos e usados"
  },
  {
    "id_cnae": "663",
    "subclasse": "4512-9/01",
    "denominacao": "Representantes comerciais e agentes do comércio de veículos automotores"
  },
  {
    "id_cnae": "664",
    "subclasse": "4512-9/02",
    "denominacao": "Comércio sob consignação de veículos automotores"
  },
  {
    "id_cnae": "665",
    "subclasse": "4520-0/01",
    "denominacao": "Serviços de manutenção e reparação mecânica de veículos automotores"
  },
  {
    "id_cnae": "666",
    "subclasse": "4520-0/02",
    "denominacao": "Serviços de lanternagem ou funilaria e pintura de veículos automotores"
  },
  {
    "id_cnae": "667",
    "subclasse": "4520-0/03",
    "denominacao": "Serviços de manutenção e reparação elétrica de veículos automotores"
  },
  {
    "id_cnae": "668",
    "subclasse": "4520-0/04",
    "denominacao": "Serviços de alinhamento e balanceamento de veículos automotores"
  },
  {
    "id_cnae": "669",
    "subclasse": "4520-0/05",
    "denominacao": "Serviços de lavagem, lubrificação e polimento de veículos automotores"
  },
  {
    "id_cnae": "670",
    "subclasse": "4520-0/06",
    "denominacao": "Serviços de borracharia para veículos automotores"
  },
  {
    "id_cnae": "671",
    "subclasse": "4520-0/07",
    "denominacao": "Serviços de instalação, manutenção e reparação de acessórios para veículos automotores"
  },
  {
    "id_cnae": "672",
    "subclasse": "4520-0/08",
    "denominacao": "Serviços de capotaria"
  },
  {
    "id_cnae": "673",
    "subclasse": "4530-7/01",
    "denominacao": "Comércio por atacado de peças e acessórios novos para veículos automotores"
  },
  {
    "id_cnae": "674",
    "subclasse": "4530-7/02",
    "denominacao": "Comércio por atacado de pneumáticos e câmaras-de-ar"
  },
  {
    "id_cnae": "675",
    "subclasse": "4530-7/03",
    "denominacao": "Comércio a varejo de peças e acessórios novos para veículos automotores"
  },
  {
    "id_cnae": "676",
    "subclasse": "4530-7/04",
    "denominacao": "Comércio a varejo de peças e acessórios usados para veículos automotores"
  },
  {
    "id_cnae": "677",
    "subclasse": "4530-7/05",
    "denominacao": "Comércio a varejo de pneumáticos e câmaras-de-ar"
  },
  {
    "id_cnae": "678",
    "subclasse": "4530-7/06",
    "denominacao": "Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores"
  },
  {
    "id_cnae": "679",
    "subclasse": "4541-2/01",
    "denominacao": "Comércio por atacado de motocicletas e motonetas"
  },
  {
    "id_cnae": "680",
    "subclasse": "4541-2/02",
    "denominacao": "Comércio por atacado de peças e acessórios para motocicletas e motonetas"
  },
  {
    "id_cnae": "681",
    "subclasse": "4541-2/03",
    "denominacao": "Comércio a varejo de motocicletas e motonetas novas"
  },
  {
    "id_cnae": "682",
    "subclasse": "4541-2/04",
    "denominacao": "Comércio a varejo de motocicletas e motonetas usadas"
  },
  {
    "id_cnae": "683",
    "subclasse": "4541-2/06",
    "denominacao": "Comércio a varejo de peças e acessórios novos para motocicletas e motonetas"
  },
  {
    "id_cnae": "684",
    "subclasse": "4541-2/07",
    "denominacao": "Comércio a varejo de peças e acessórios usados para motocicletas e motonetas"
  },
  {
    "id_cnae": "685",
    "subclasse": "4542-1/01",
    "denominacao": "Representantes comerciais e agentes do comércio de motocicletas e motonetas, peças e acessórios"
  },
  {
    "id_cnae": "686",
    "subclasse": "4542-1/02",
    "denominacao": "Comércio sob consignação de motocicletas e motonetas"
  },
  {
    "id_cnae": "687",
    "subclasse": "4543-9/00",
    "denominacao": "Manutenção e reparação de motocicletas e motonetas"
  },
  {
    "id_cnae": "688",
    "subclasse": "4611-7/00",
    "denominacao": "Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos"
  },
  {
    "id_cnae": "689",
    "subclasse": "4612-5/00",
    "denominacao": "Representantes comerciais e agentes do comércio de combustíveis, minerais, produtos siderúrgicos e químicos"
  },
  {
    "id_cnae": "690",
    "subclasse": "4613-3/00",
    "denominacao": "Representantes comerciais e agentes do comércio de madeira, material de construção e ferragens"
  },
  {
    "id_cnae": "691",
    "subclasse": "4614-1/00",
    "denominacao": "Representantes comerciais e agentes do comércio de máquinas, equipamentos, embarcações e aeronaves"
  },
  {
    "id_cnae": "692",
    "subclasse": "4615-0/00",
    "denominacao": "Representantes comerciais e agentes do comércio de eletrodomésticos, móveis e artigos de uso doméstico"
  },
  {
    "id_cnae": "693",
    "subclasse": "4616-8/00",
    "denominacao": "Representantes comerciais e agentes do comércio de têxteis, vestuário, calçados e artigos de viagem"
  },
  {
    "id_cnae": "694",
    "subclasse": "4617-6/00",
    "denominacao": "Representantes comerciais e agentes do comércio de produtos alimentícios, bebidas e fumo"
  },
  {
    "id_cnae": "695",
    "subclasse": "4618-4/01",
    "denominacao": "Representantes comerciais e agentes do comércio de medicamentos, cosméticos e produtos de perfumaria"
  },
  {
    "id_cnae": "696",
    "subclasse": "4618-4/02",
    "denominacao": "Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares"
  },
  {
    "id_cnae": "697",
    "subclasse": "4618-4/03",
    "denominacao": "Representantes comerciais e agentes do comércio de jornais, revistas e outras publicações"
  },
  {
    "id_cnae": "698",
    "subclasse": "4618-4/99",
    "denominacao": "Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente"
  },
  {
    "id_cnae": "699",
    "subclasse": "4619-2/00",
    "denominacao": "Representantes comerciais e agentes do comércio de mercadorias em geral não especializado"
  },
  {
    "id_cnae": "700",
    "subclasse": "4621-4/00",
    "denominacao": "Comércio atacadista de café em grão"
  },
  {
    "id_cnae": "701",
    "subclasse": "4622-2/00",
    "denominacao": "Comércio atacadista de soja"
  },
  {
    "id_cnae": "702",
    "subclasse": "4623-1/01",
    "denominacao": "Comércio atacadista de animais vivos"
  },
  {
    "id_cnae": "703",
    "subclasse": "4623-1/02",
    "denominacao": "Comércio atacadista de couros, lãs, peles e outros subprodutos não comestíveis de origem animal"
  },
  {
    "id_cnae": "704",
    "subclasse": "4623-1/03",
    "denominacao": "Comércio atacadista de algodão"
  },
  {
    "id_cnae": "705",
    "subclasse": "4623-1/04",
    "denominacao": "Comércio atacadista de fumo em folha não beneficiado"
  },
  {
    "id_cnae": "706",
    "subclasse": "4623-1/05",
    "denominacao": "Comércio atacadista de cacau"
  },
  {
    "id_cnae": "707",
    "subclasse": "4623-1/06",
    "denominacao": "Comércio atacadista de sementes, flores, plantas e gramas"
  },
  {
    "id_cnae": "708",
    "subclasse": "4623-1/07",
    "denominacao": "Comércio atacadista de sisal"
  },
  {
    "id_cnae": "709",
    "subclasse": "4623-1/08",
    "denominacao": "Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada"
  },
  {
    "id_cnae": "710",
    "subclasse": "4623-1/09",
    "denominacao": "Comércio atacadista de alimentos para animais"
  },
  {
    "id_cnae": "711",
    "subclasse": "4623-1/99",
    "denominacao": "Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente"
  },
  {
    "id_cnae": "712",
    "subclasse": "4631-1/00",
    "denominacao": "Comércio atacadista de leite e laticínios"
  },
  {
    "id_cnae": "713",
    "subclasse": "4632-0/01",
    "denominacao": "Comércio atacadista de cereais e leguminosas beneficiados"
  },
  {
    "id_cnae": "714",
    "subclasse": "4632-0/02",
    "denominacao": "Comércio atacadista de farinhas, amidos e féculas"
  },
  {
    "id_cnae": "715",
    "subclasse": "4632-0/03",
    "denominacao": "Comércio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e féculas, com atividade de fracionamento e acondicionamento associada"
  },
  {
    "id_cnae": "716",
    "subclasse": "4633-8/01",
    "denominacao": "Comércio atacadista de frutas, verduras, raízes, tubérculos, hortaliças e legumes frescos"
  },
  {
    "id_cnae": "717",
    "subclasse": "4633-8/02",
    "denominacao": "Comércio atacadista de aves vivas e ovos"
  },
  {
    "id_cnae": "718",
    "subclasse": "4633-8/03",
    "denominacao": "Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação"
  },
  {
    "id_cnae": "719",
    "subclasse": "4634-6/01",
    "denominacao": "Comércio atacadista de carnes bovinas e suínas e derivados"
  },
  {
    "id_cnae": "720",
    "subclasse": "4634-6/02",
    "denominacao": "Comércio atacadista de aves abatidas e derivados"
  },
  {
    "id_cnae": "721",
    "subclasse": "4634-6/03",
    "denominacao": "Comércio atacadista de pescados e frutos do mar"
  },
  {
    "id_cnae": "722",
    "subclasse": "4634-6/99",
    "denominacao": "Comércio atacadista de carnes e derivados de outros animais"
  },
  {
    "id_cnae": "723",
    "subclasse": "4635-4/01",
    "denominacao": "Comércio atacadista de água mineral"
  },
  {
    "id_cnae": "724",
    "subclasse": "4635-4/02",
    "denominacao": "Comércio atacadista de cerveja, chope e refrigerante"
  },
  {
    "id_cnae": "725",
    "subclasse": "4635-4/03",
    "denominacao": "Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada"
  },
  {
    "id_cnae": "726",
    "subclasse": "4635-4/99",
    "denominacao": "Comércio atacadista de bebidas não especificadas anteriormente"
  },
  {
    "id_cnae": "727",
    "subclasse": "4636-2/01",
    "denominacao": "Comércio atacadista de fumo beneficiado"
  },
  {
    "id_cnae": "728",
    "subclasse": "4636-2/02",
    "denominacao": "Comércio atacadista de cigarros, cigarrilhas e charutos"
  },
  {
    "id_cnae": "729",
    "subclasse": "4637-1/01",
    "denominacao": "Comércio atacadista de café torrado, moído e solúvel"
  },
  {
    "id_cnae": "730",
    "subclasse": "4637-1/02",
    "denominacao": "Comércio atacadista de açúcar"
  },
  {
    "id_cnae": "731",
    "subclasse": "4637-1/03",
    "denominacao": "Comércio atacadista de óleos e gorduras"
  },
  {
    "id_cnae": "732",
    "subclasse": "4637-1/04",
    "denominacao": "Comércio atacadista de pães, bolos, biscoitos e similares"
  },
  {
    "id_cnae": "733",
    "subclasse": "4637-1/05",
    "denominacao": "Comércio atacadista de massas alimentícias"
  },
  {
    "id_cnae": "734",
    "subclasse": "4637-1/06",
    "denominacao": "Comércio atacadista de sorvetes"
  },
  {
    "id_cnae": "735",
    "subclasse": "4637-1/07",
    "denominacao": "Comércio atacadista de chocolates, confeitos, balas, bombons e semelhantes"
  },
  {
    "id_cnae": "736",
    "subclasse": "4637-1/99",
    "denominacao": "Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente"
  },
  {
    "id_cnae": "737",
    "subclasse": "4639-7/01",
    "denominacao": "Comércio atacadista de produtos alimentícios em geral"
  },
  {
    "id_cnae": "738",
    "subclasse": "4639-7/02",
    "denominacao": "Comércio atacadista de produtos alimentícios em geral, com atividade de fracionamento e acondicionamento associada"
  },
  {
    "id_cnae": "739",
    "subclasse": "4641-9/01",
    "denominacao": "Comércio atacadista de tecidos"
  },
  {
    "id_cnae": "740",
    "subclasse": "4641-9/02",
    "denominacao": "Comércio atacadista de artigos de cama, mesa e banho"
  },
  {
    "id_cnae": "741",
    "subclasse": "4641-9/03",
    "denominacao": "Comércio atacadista de artigos de armarinho"
  },
  {
    "id_cnae": "742",
    "subclasse": "4642-7/01",
    "denominacao": "Comércio atacadista de artigos do vestuário e acessórios, exceto profissionais e de segurança"
  },
  {
    "id_cnae": "743",
    "subclasse": "4642-7/02",
    "denominacao": "Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho"
  },
  {
    "id_cnae": "744",
    "subclasse": "4643-5/01",
    "denominacao": "Comércio atacadista de calçados"
  },
  {
    "id_cnae": "745",
    "subclasse": "4643-5/02",
    "denominacao": "Comércio atacadista de bolsas, malas e artigos de viagem"
  },
  {
    "id_cnae": "746",
    "subclasse": "4644-3/01",
    "denominacao": "Comércio atacadista de medicamentos e drogas de uso humano"
  },
  {
    "id_cnae": "747",
    "subclasse": "4644-3/02",
    "denominacao": "Comércio atacadista de medicamentos e drogas de uso veterinário"
  },
  {
    "id_cnae": "748",
    "subclasse": "4645-1/01",
    "denominacao": "Comércio atacadista de instrumentos e materiais para uso médico, cirúrgico, hospitalar e de laboratórios"
  },
  {
    "id_cnae": "749",
    "subclasse": "4645-1/02",
    "denominacao": "Comércio atacadista de próteses e artigos de ortopedia"
  },
  {
    "id_cnae": "750",
    "subclasse": "4645-1/03",
    "denominacao": "Comércio atacadista de produtos odontológicos"
  },
  {
    "id_cnae": "751",
    "subclasse": "4646-0/01",
    "denominacao": "Comércio atacadista de cosméticos e produtos de perfumaria"
  },
  {
    "id_cnae": "752",
    "subclasse": "4646-0/02",
    "denominacao": "Comércio atacadista de produtos de higiene pessoal"
  },
  {
    "id_cnae": "753",
    "subclasse": "4647-8/01",
    "denominacao": "Comércio atacadista de artigos de escritório e de papelaria"
  },
  {
    "id_cnae": "754",
    "subclasse": "4647-8/02",
    "denominacao": "Comércio atacadista de livros, jornais e outras publicações"
  },
  {
    "id_cnae": "755",
    "subclasse": "4649-4/01",
    "denominacao": "Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico"
  },
  {
    "id_cnae": "756",
    "subclasse": "4649-4/02",
    "denominacao": "Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico"
  },
  {
    "id_cnae": "757",
    "subclasse": "4649-4/03",
    "denominacao": "Comércio atacadista de bicicletas, triciclos e outros veículos recreativos"
  },
  {
    "id_cnae": "758",
    "subclasse": "4649-4/04",
    "denominacao": "Comércio atacadista de móveis e artigos de colchoaria"
  },
  {
    "id_cnae": "759",
    "subclasse": "4649-4/05",
    "denominacao": "Comércio atacadista de artigos de tapeçaria; persianas e cortinas"
  },
  {
    "id_cnae": "760",
    "subclasse": "4649-4/06",
    "denominacao": "Comércio atacadista de lustres, luminárias e abajures"
  },
  {
    "id_cnae": "761",
    "subclasse": "4649-4/07",
    "denominacao": "Comércio atacadista de filmes, CDs, DVDs, fitas e discos"
  },
  {
    "id_cnae": "762",
    "subclasse": "4649-4/08",
    "denominacao": "Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar"
  },
  {
    "id_cnae": "763",
    "subclasse": "4649-4/09",
    "denominacao": "Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar, com atividade de fracionamento e acondicionamento associada"
  },
  {
    "id_cnae": "764",
    "subclasse": "4649-4/10",
    "denominacao": "Comércio atacadista de jóias, relógios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas"
  },
  {
    "id_cnae": "765",
    "subclasse": "4649-4/99",
    "denominacao": "Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente"
  },
  {
    "id_cnae": "766",
    "subclasse": "4651-6/01",
    "denominacao": "Comércio atacadista de equipamentos de informática"
  },
  {
    "id_cnae": "767",
    "subclasse": "4651-6/02",
    "denominacao": "Comércio atacadista de suprimentos para informática"
  },
  {
    "id_cnae": "768",
    "subclasse": "4652-4/00",
    "denominacao": "Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação"
  },
  {
    "id_cnae": "769",
    "subclasse": "4661-3/00",
    "denominacao": "Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário; partes e peças"
  },
  {
    "id_cnae": "770",
    "subclasse": "4662-1/00",
    "denominacao": "Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção; partes e peças"
  },
  {
    "id_cnae": "771",
    "subclasse": "4663-0/00",
    "denominacao": "Comércio atacadista de máquinas e equipamentos para uso industrial; partes e peças"
  },
  {
    "id_cnae": "772",
    "subclasse": "4664-8/00",
    "denominacao": "Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar; partes e peças"
  },
  {
    "id_cnae": "773",
    "subclasse": "4665-6/00",
    "denominacao": "Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças"
  },
  {
    "id_cnae": "774",
    "subclasse": "4669-9/01",
    "denominacao": "Comércio atacadista de bombas e compressores; partes e peças"
  },
  {
    "id_cnae": "775",
    "subclasse": "4669-9/99",
    "denominacao": "Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente; partes e peças"
  },
  {
    "id_cnae": "776",
    "subclasse": "4671-1/00",
    "denominacao": "Comércio atacadista de madeira e produtos derivados"
  },
  {
    "id_cnae": "777",
    "subclasse": "4672-9/00",
    "denominacao": "Comércio atacadista de ferragens e ferramentas"
  },
  {
    "id_cnae": "778",
    "subclasse": "4673-7/00",
    "denominacao": "Comércio atacadista de material elétrico"
  },
  {
    "id_cnae": "779",
    "subclasse": "4674-5/00",
    "denominacao": "Comércio atacadista de cimento"
  },
  {
    "id_cnae": "780",
    "subclasse": "4679-6/01",
    "denominacao": "Comércio atacadista de tintas, vernizes e similares"
  },
  {
    "id_cnae": "781",
    "subclasse": "4679-6/02",
    "denominacao": "Comércio atacadista de mármores e granitos"
  },
  {
    "id_cnae": "782",
    "subclasse": "4679-6/03",
    "denominacao": "Comércio atacadista de vidros, espelhos e vitrais"
  },
  {
    "id_cnae": "783",
    "subclasse": "4679-6/04",
    "denominacao": "Comércio atacadista especializado de materiais de construção não especificados anteriormente"
  },
  {
    "id_cnae": "784",
    "subclasse": "4679-6/99",
    "denominacao": "Comércio atacadista de materiais de construção em geral"
  },
  {
    "id_cnae": "785",
    "subclasse": "4681-8/01",
    "denominacao": "Comércio atacadista de álcool carburante, biodiesel, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador retalhista (TRR)"
  },
  {
    "id_cnae": "786",
    "subclasse": "4681-8/02",
    "denominacao": "Comércio atacadista de combustíveis realizado por transportador retalhista (TRR)"
  },
  {
    "id_cnae": "787",
    "subclasse": "4681-8/03",
    "denominacao": "Comércio atacadista de combustíveis de origem vegetal, exceto álcool carburante"
  },
  {
    "id_cnae": "788",
    "subclasse": "4681-8/04",
    "denominacao": "Comércio atacadista de combustíveis de origem mineral em bruto"
  },
  {
    "id_cnae": "789",
    "subclasse": "4681-8/05",
    "denominacao": "Comércio atacadista de lubrificantes"
  },
  {
    "id_cnae": "790",
    "subclasse": "4682-6/00",
    "denominacao": "Comércio atacadista de gás liquefeito de petróleo (GLP)"
  },
  {
    "id_cnae": "791",
    "subclasse": "4683-4/00",
    "denominacao": "Comércio atacadista de defensivos agrícolas, adubos, fertilizantes e corretivos do solo"
  },
  {
    "id_cnae": "792",
    "subclasse": "4684-2/01",
    "denominacao": "Comércio atacadista de resinas e elastômeros"
  },
  {
    "id_cnae": "793",
    "subclasse": "4684-2/02",
    "denominacao": "Comércio atacadista de solventes"
  },
  {
    "id_cnae": "794",
    "subclasse": "4684-2/99",
    "denominacao": "Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente"
  },
  {
    "id_cnae": "795",
    "subclasse": "4685-1/00",
    "denominacao": "Comércio atacadista de produtos siderúrgicos e metalúrgicos, exceto para construção"
  },
  {
    "id_cnae": "796",
    "subclasse": "4686-9/01",
    "denominacao": "Comércio atacadista de papel e papelão em bruto"
  },
  {
    "id_cnae": "797",
    "subclasse": "4686-9/02",
    "denominacao": "Comércio atacadista de embalagens"
  },
  {
    "id_cnae": "798",
    "subclasse": "4687-7/01",
    "denominacao": "Comércio atacadista de resíduos de papel e papelão"
  },
  {
    "id_cnae": "799",
    "subclasse": "4687-7/02",
    "denominacao": "Comércio atacadista de resíduos e sucatas não metálicos, exceto de papel e papelão"
  },
  {
    "id_cnae": "800",
    "subclasse": "4687-7/03",
    "denominacao": "Comércio atacadista de resíduos e sucatas metálicos"
  },
  {
    "id_cnae": "801",
    "subclasse": "4689-3/01",
    "denominacao": "Comércio atacadista de produtos da extração mineral, exceto combustíveis"
  },
  {
    "id_cnae": "802",
    "subclasse": "4689-3/02",
    "denominacao": "Comércio atacadista de fios e fibras beneficiados"
  },
  {
    "id_cnae": "803",
    "subclasse": "4689-3/99",
    "denominacao": "Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente"
  },
  {
    "id_cnae": "804",
    "subclasse": "4691-5/00",
    "denominacao": "Comércio atacadista de mercadorias em geral, com predominância de produtos alimentícios"
  },
  {
    "id_cnae": "805",
    "subclasse": "4692-3/00",
    "denominacao": "Comércio atacadista de mercadorias em geral, com predominância de insumos agropecuários"
  },
  {
    "id_cnae": "806",
    "subclasse": "4693-1/00",
    "denominacao": "Comércio atacadista de mercadorias em geral, sem predominância de alimentos ou de insumos agropecuários"
  },
  {
    "id_cnae": "807",
    "subclasse": "4711-3/01",
    "denominacao": "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - hipermercados"
  },
  {
    "id_cnae": "808",
    "subclasse": "4711-3/02",
    "denominacao": "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - supermercados"
  },
  {
    "id_cnae": "809",
    "subclasse": "4712-1/00",
    "denominacao": "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns"
  },
  {
    "id_cnae": "810",
    "subclasse": "4713-0/02",
    "denominacao": "Lojas de variedades, exceto lojas de departamentos ou magazines"
  },
  {
    "id_cnae": "811",
    "subclasse": "4713-0/04",
    "denominacao": "Lojas de departamentos ou magazines, exceto lojas francas (Duty free)"
  },
  {
    "id_cnae": "812",
    "subclasse": "4713-0/05",
    "denominacao": "Lojas francas (Duty Free) de aeroportos, portos e em fronteiras terrestres"
  },
  {
    "id_cnae": "813",
    "subclasse": "4721-1/02",
    "denominacao": "Padaria e confeitaria com predominância de revenda"
  },
  {
    "id_cnae": "814",
    "subclasse": "4721-1/03",
    "denominacao": "Comércio varejista de laticínios e frios"
  },
  {
    "id_cnae": "815",
    "subclasse": "4721-1/04",
    "denominacao": "Comércio varejista de doces, balas, bombons e semelhantes"
  },
  {
    "id_cnae": "816",
    "subclasse": "4722-9/01",
    "denominacao": "Comércio varejista de carnes - açougues"
  },
  { "id_cnae": "817", "subclasse": "4722-9/02", "denominacao": "Peixaria" },
  {
    "id_cnae": "818",
    "subclasse": "4723-7/00",
    "denominacao": "Comércio varejista de bebidas"
  },
  {
    "id_cnae": "819",
    "subclasse": "4724-5/00",
    "denominacao": "Comércio varejista de hortifrutigranjeiros"
  },
  { "id_cnae": "820", "subclasse": "4729-6/01", "denominacao": "Tabacaria" },
  {
    "id_cnae": "821",
    "subclasse": "4729-6/02",
    "denominacao": "Comércio varejista de mercadorias em lojas de conveniência"
  },
  {
    "id_cnae": "822",
    "subclasse": "4729-6/99",
    "denominacao": "Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente"
  },
  {
    "id_cnae": "823",
    "subclasse": "4731-8/00",
    "denominacao": "Comércio varejista de combustíveis para veículos automotores"
  },
  {
    "id_cnae": "824",
    "subclasse": "4732-6/00",
    "denominacao": "Comércio varejista de lubrificantes"
  },
  {
    "id_cnae": "825",
    "subclasse": "4741-5/00",
    "denominacao": "Comércio varejista de tintas e materiais para pintura"
  },
  {
    "id_cnae": "826",
    "subclasse": "4742-3/00",
    "denominacao": "Comércio varejista de material elétrico"
  },
  {
    "id_cnae": "827",
    "subclasse": "4743-1/00",
    "denominacao": "Comércio varejista de vidros"
  },
  {
    "id_cnae": "828",
    "subclasse": "4744-0/01",
    "denominacao": "Comércio varejista de ferragens e ferramentas"
  },
  {
    "id_cnae": "829",
    "subclasse": "4744-0/02",
    "denominacao": "Comércio varejista de madeira e artefatos"
  },
  {
    "id_cnae": "830",
    "subclasse": "4744-0/03",
    "denominacao": "Comércio varejista de materiais hidráulicos"
  },
  {
    "id_cnae": "831",
    "subclasse": "4744-0/04",
    "denominacao": "Comércio varejista de cal, areia, pedra britada, tijolos e telhas"
  },
  {
    "id_cnae": "832",
    "subclasse": "4744-0/05",
    "denominacao": "Comércio varejista de materiais de construção não especificados anteriormente"
  },
  {
    "id_cnae": "833",
    "subclasse": "4744-0/06",
    "denominacao": "Comércio varejista de pedras para revestimento"
  },
  {
    "id_cnae": "834",
    "subclasse": "4744-0/99",
    "denominacao": "Comércio varejista de materiais de construção em geral"
  },
  {
    "id_cnae": "835",
    "subclasse": "4751-2/01",
    "denominacao": "Comércio varejista especializado de equipamentos e suprimentos de informática"
  },
  {
    "id_cnae": "836",
    "subclasse": "4751-2/02",
    "denominacao": "Recarga de cartuchos para equipamentos de informática"
  },
  {
    "id_cnae": "837",
    "subclasse": "4752-1/00",
    "denominacao": "Comércio varejista especializado de equipamentos de telefonia e comunicação"
  },
  {
    "id_cnae": "838",
    "subclasse": "4753-9/00",
    "denominacao": "Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo"
  },
  {
    "id_cnae": "839",
    "subclasse": "4754-7/01",
    "denominacao": "Comércio varejista de móveis"
  },
  {
    "id_cnae": "840",
    "subclasse": "4754-7/02",
    "denominacao": "Comércio varejista de artigos de colchoaria"
  },
  {
    "id_cnae": "841",
    "subclasse": "4754-7/03",
    "denominacao": "Comércio varejista de artigos de iluminação"
  },
  {
    "id_cnae": "842",
    "subclasse": "4755-5/01",
    "denominacao": "Comércio varejista de tecidos"
  },
  {
    "id_cnae": "843",
    "subclasse": "4755-5/02",
    "denominacao": "Comercio varejista de artigos de armarinho"
  },
  {
    "id_cnae": "844",
    "subclasse": "4755-5/03",
    "denominacao": "Comercio varejista de artigos de cama, mesa e banho"
  },
  {
    "id_cnae": "845",
    "subclasse": "4756-3/00",
    "denominacao": "Comércio varejista especializado de instrumentos musicais e acessórios"
  },
  {
    "id_cnae": "846",
    "subclasse": "4757-1/00",
    "denominacao": "Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico, exceto informática e comunicação"
  },
  {
    "id_cnae": "847",
    "subclasse": "4759-8/01",
    "denominacao": "Comércio varejista de artigos de tapeçaria, cortinas e persianas"
  },
  {
    "id_cnae": "848",
    "subclasse": "4759-8/99",
    "denominacao": "Comércio varejista de outros artigos de uso doméstico não especificados anteriormente"
  },
  {
    "id_cnae": "849",
    "subclasse": "4761-0/01",
    "denominacao": "Comércio varejista de livros"
  },
  {
    "id_cnae": "850",
    "subclasse": "4761-0/02",
    "denominacao": "Comércio varejista de jornais e revistas"
  },
  {
    "id_cnae": "851",
    "subclasse": "4761-0/03",
    "denominacao": "Comércio varejista de artigos de papelaria"
  },
  {
    "id_cnae": "852",
    "subclasse": "4762-8/00",
    "denominacao": "Comércio varejista de discos, CDs, DVDs e fitas"
  },
  {
    "id_cnae": "853",
    "subclasse": "4763-6/01",
    "denominacao": "Comércio varejista de brinquedos e artigos recreativos"
  },
  {
    "id_cnae": "854",
    "subclasse": "4763-6/02",
    "denominacao": "Comércio varejista de artigos esportivos"
  },
  {
    "id_cnae": "855",
    "subclasse": "4763-6/03",
    "denominacao": "Comércio varejista de bicicletas e triciclos; peças e acessórios"
  },
  {
    "id_cnae": "856",
    "subclasse": "4763-6/04",
    "denominacao": "Comércio varejista de artigos de caça, pesca e camping"
  },
  {
    "id_cnae": "857",
    "subclasse": "4763-6/05",
    "denominacao": "Comércio varejista de embarcações e outros veículos recreativos; peças e acessórios"
  },
  {
    "id_cnae": "858",
    "subclasse": "4771-7/01",
    "denominacao": "Comércio varejista de produtos farmacêuticos, sem manipulação de fórmulas"
  },
  {
    "id_cnae": "859",
    "subclasse": "4771-7/02",
    "denominacao": "Comércio varejista de produtos farmacêuticos, com manipulação de fórmulas"
  },
  {
    "id_cnae": "860",
    "subclasse": "4771-7/03",
    "denominacao": "Comércio varejista de produtos farmacêuticos homeopáticos"
  },
  {
    "id_cnae": "861",
    "subclasse": "4771-7/04",
    "denominacao": "Comércio varejista de medicamentos veterinários"
  },
  {
    "id_cnae": "862",
    "subclasse": "4772-5/00",
    "denominacao": "Comércio varejista de cosméticos, produtos de perfumaria e de higiene pessoal"
  },
  {
    "id_cnae": "863",
    "subclasse": "4773-3/00",
    "denominacao": "Comércio varejista de artigos médicos e ortopédicos"
  },
  {
    "id_cnae": "864",
    "subclasse": "4774-1/00",
    "denominacao": "Comércio varejista de artigos de óptica"
  },
  {
    "id_cnae": "865",
    "subclasse": "4781-4/00",
    "denominacao": "Comércio varejista de artigos do vestuário e acessórios"
  },
  {
    "id_cnae": "866",
    "subclasse": "4782-2/01",
    "denominacao": "Comércio varejista de calçados"
  },
  {
    "id_cnae": "867",
    "subclasse": "4782-2/02",
    "denominacao": "Comércio varejista de artigos de viagem"
  },
  {
    "id_cnae": "868",
    "subclasse": "4783-1/01",
    "denominacao": "Comércio varejista de artigos de joalheria"
  },
  {
    "id_cnae": "869",
    "subclasse": "4783-1/02",
    "denominacao": "Comércio varejista de artigos de relojoaria"
  },
  {
    "id_cnae": "870",
    "subclasse": "4784-9/00",
    "denominacao": "Comércio varejista de gás liq uefeito de petróleo (GLP)"
  },
  {
    "id_cnae": "871",
    "subclasse": "4785-7/01",
    "denominacao": "Comércio varejista de antiguidades"
  },
  {
    "id_cnae": "872",
    "subclasse": "4785-7/99",
    "denominacao": "Comércio varejista de outros artigos usados"
  },
  {
    "id_cnae": "873",
    "subclasse": "4789-0/01",
    "denominacao": "Comércio varejista de suvenires, bijuterias e artesanatos"
  },
  {
    "id_cnae": "874",
    "subclasse": "4789-0/02",
    "denominacao": "Comércio varejista de plantas e flores naturais"
  },
  {
    "id_cnae": "875",
    "subclasse": "4789-0/03",
    "denominacao": "Comércio varejista de objetos de arte"
  },
  {
    "id_cnae": "876",
    "subclasse": "4789-0/04",
    "denominacao": "Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação"
  },
  {
    "id_cnae": "877",
    "subclasse": "4789-0/05",
    "denominacao": "Comércio varejista de produtos saneantes domissanitários"
  },
  {
    "id_cnae": "878",
    "subclasse": "4789-0/06",
    "denominacao": "Comércio varejista de fogos de artifício e artigos pirotécnicos"
  },
  {
    "id_cnae": "879",
    "subclasse": "4789-0/07",
    "denominacao": "Comércio varejista de equipamentos para escritório"
  },
  {
    "id_cnae": "880",
    "subclasse": "4789-0/08",
    "denominacao": "Comércio varejista de artigos fotográficos e para filmagem"
  },
  {
    "id_cnae": "881",
    "subclasse": "4789-0/09",
    "denominacao": "Comércio varejista de armas e munições"
  },
  {
    "id_cnae": "882",
    "subclasse": "4789-0/99",
    "denominacao": "Comércio varejista de outros produtos não especificados anteriormente"
  },
  {
    "id_cnae": "883",
    "subclasse": "4911-6/00",
    "denominacao": "Transporte ferroviário de carga"
  },
  {
    "id_cnae": "884",
    "subclasse": "4912-4/01",
    "denominacao": "Transporte ferroviário de passageiros intermunicipal e interestadual"
  },
  {
    "id_cnae": "885",
    "subclasse": "4912-4/02",
    "denominacao": "Transporte ferroviário de passageiros municipal e em região metropolitana"
  },
  {
    "id_cnae": "886",
    "subclasse": "4912-4/03",
    "denominacao": "Transporte metroviário"
  },
  {
    "id_cnae": "887",
    "subclasse": "4921-3/01",
    "denominacao": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, municipal"
  },
  {
    "id_cnae": "888",
    "subclasse": "4921-3/02",
    "denominacao": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal em região metropolitana"
  },
  {
    "id_cnae": "889",
    "subclasse": "4922-1/01",
    "denominacao": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal, exceto em região metropolitana"
  },
  {
    "id_cnae": "890",
    "subclasse": "4922-1/02",
    "denominacao": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, interestadual"
  },
  {
    "id_cnae": "891",
    "subclasse": "4922-1/03",
    "denominacao": "Transporte rodoviário coletivo de passageiros, com itinerário fixo, internacional"
  },
  {
    "id_cnae": "892",
    "subclasse": "4923-0/01",
    "denominacao": "Serviço de táxi"
  },
  {
    "id_cnae": "893",
    "subclasse": "4923-0/02",
    "denominacao": "Serviço de transporte de passageiros - locação de automóveis com motorista"
  },
  {
    "id_cnae": "894",
    "subclasse": "4924-8/00",
    "denominacao": "Transporte escolar"
  },
  {
    "id_cnae": "895",
    "subclasse": "4929-9/01",
    "denominacao": "Transporte rodoviário coletivo de passageiros, sob regime de fretamento, municipal"
  },
  {
    "id_cnae": "896",
    "subclasse": "4929-9/02",
    "denominacao": "Transporte rodoviário coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional"
  },
  {
    "id_cnae": "897",
    "subclasse": "4929-9/03",
    "denominacao": "Organização de excursões em veículos rodoviários próprios, municipal"
  },
  {
    "id_cnae": "898",
    "subclasse": "4929-9/04",
    "denominacao": "Organização de excursões em veículos rodoviários próprios, intermunicipal, interestadual e internacional"
  },
  {
    "id_cnae": "899",
    "subclasse": "4929-9/99",
    "denominacao": "Outros transportes rodoviários de passageiros não especificados anteriormente"
  },
  {
    "id_cnae": "900",
    "subclasse": "4930-2/01",
    "denominacao": "Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal"
  },
  {
    "id_cnae": "901",
    "subclasse": "4930-2/02",
    "denominacao": "Transporte rodoviário de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional"
  },
  {
    "id_cnae": "902",
    "subclasse": "4930-2/03",
    "denominacao": "Transporte rodoviário de produtos perigosos"
  },
  {
    "id_cnae": "903",
    "subclasse": "4930-2/04",
    "denominacao": "Transporte rodoviário de mudanças"
  },
  {
    "id_cnae": "904",
    "subclasse": "4940-0/00",
    "denominacao": "Transporte dutoviário"
  },
  {
    "id_cnae": "905",
    "subclasse": "4950-7/00",
    "denominacao": "Trens turísticos, teleféricos e similares"
  },
  {
    "id_cnae": "906",
    "subclasse": "5011-4/01",
    "denominacao": "Transporte marítimo de cabotagem - Carga"
  },
  {
    "id_cnae": "907",
    "subclasse": "5011-4/02",
    "denominacao": "Transporte marítimo de cabotagem - Passageiros"
  },
  {
    "id_cnae": "908",
    "subclasse": "5012-2/01",
    "denominacao": "Transporte marítimo de longo curso - Carga"
  },
  {
    "id_cnae": "909",
    "subclasse": "5012-2/02",
    "denominacao": "Transporte marítimo de longo curso - Passageiros"
  },
  {
    "id_cnae": "910",
    "subclasse": "5021-1/01",
    "denominacao": "Transporte por navegação interior de carga, municipal, exceto travessia"
  },
  {
    "id_cnae": "911",
    "subclasse": "5021-1/02",
    "denominacao": "Transporte por navegação interior de carga, intermunicipal, interestadual e internacional, exceto travessia"
  },
  {
    "id_cnae": "912",
    "subclasse": "5022-0/01",
    "denominacao": "Transporte por navegação interior de passageiros em linhas regulares, municipal, exceto travessia"
  },
  {
    "id_cnae": "913",
    "subclasse": "5022-0/02",
    "denominacao": "Transporte por navegação interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia"
  },
  {
    "id_cnae": "914",
    "subclasse": "5030-1/01",
    "denominacao": "Navegação de apoio marítimo"
  },
  {
    "id_cnae": "915",
    "subclasse": "5030-1/02",
    "denominacao": "Navegação de apoio portuário"
  },
  {
    "id_cnae": "916",
    "subclasse": "5030-1/03",
    "denominacao": "Serviço de rebocadores e empurradores"
  },
  {
    "id_cnae": "917",
    "subclasse": "5091-2/01",
    "denominacao": "Transporte por navegação de travessia, municipal"
  },
  {
    "id_cnae": "918",
    "subclasse": "5091-2/02",
    "denominacao": "Transporte por navegação de travessia, intermunicipal, interestadual e internacional"
  },
  {
    "id_cnae": "919",
    "subclasse": "5099-8/01",
    "denominacao": "Transporte aquaviário para passeios turísticos"
  },
  {
    "id_cnae": "920",
    "subclasse": "5099-8/99",
    "denominacao": "Outros transportes aquaviários não especificados anteriormente"
  },
  {
    "id_cnae": "921",
    "subclasse": "5111-1/00",
    "denominacao": "Transporte aéreo de passageiros regular"
  },
  {
    "id_cnae": "922",
    "subclasse": "5112-9/01",
    "denominacao": "Serviço de táxi aéreo e locação de aeronaves com tripulação"
  },
  {
    "id_cnae": "923",
    "subclasse": "5112-9/99",
    "denominacao": "Outros serviços de transporte aéreo de passageiros não regular"
  },
  {
    "id_cnae": "924",
    "subclasse": "5120-0/00",
    "denominacao": "Transporte aéreo de carga"
  },
  {
    "id_cnae": "925",
    "subclasse": "5130-7/00",
    "denominacao": "Transporte espacial"
  },
  {
    "id_cnae": "926",
    "subclasse": "5211-7/01",
    "denominacao": "Armazéns gerais - emissão de warrant"
  },
  {
    "id_cnae": "927",
    "subclasse": "5211-7/02",
    "denominacao": "Guarda-móveis"
  },
  {
    "id_cnae": "928",
    "subclasse": "5211-7/99",
    "denominacao": "Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis"
  },
  {
    "id_cnae": "929",
    "subclasse": "5212-5/00",
    "denominacao": "Carga e descarga"
  },
  {
    "id_cnae": "930",
    "subclasse": "5221-4/00",
    "denominacao": "Concessionárias de rodovias, pontes, túneis e serviços relacionados"
  },
  {
    "id_cnae": "931",
    "subclasse": "5222-2/00",
    "denominacao": "Terminais rodoviários e ferroviários"
  },
  {
    "id_cnae": "932",
    "subclasse": "5223-1/00",
    "denominacao": "Estacionamento de veículos"
  },
  {
    "id_cnae": "933",
    "subclasse": "5229-0/01",
    "denominacao": "Serviços de apoio ao transporte por táxi, inclusive centrais de chamada"
  },
  {
    "id_cnae": "934",
    "subclasse": "5229-0/02",
    "denominacao": "Serviços de reboque de veículos"
  },
  {
    "id_cnae": "935",
    "subclasse": "5229-0/99",
    "denominacao": "Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente"
  },
  {
    "id_cnae": "936",
    "subclasse": "5231-1/01",
    "denominacao": "Administração da infraestrutura portuária"
  },
  {
    "id_cnae": "937",
    "subclasse": "5231-1/02",
    "denominacao": "Atividades do Operador Portuário"
  },
  {
    "id_cnae": "938",
    "subclasse": "5231-1/03",
    "denominacao": "Gestão de terminais aquaviários"
  },
  {
    "id_cnae": "939",
    "subclasse": "5232-0/00",
    "denominacao": "Atividades de agenciamento marítimo"
  },
  {
    "id_cnae": "940",
    "subclasse": "5239-7/01",
    "denominacao": "Serviços de praticagem"
  },
  {
    "id_cnae": "941",
    "subclasse": "5239-7/99",
    "denominacao": "Atividades auxiliares dos transportes aquaviários não especificadas anteriormente"
  },
  {
    "id_cnae": "942",
    "subclasse": "5240-1/01",
    "denominacao": "Operação dos aeroportos e campos de aterrissagem"
  },
  {
    "id_cnae": "943",
    "subclasse": "5240-1/99",
    "denominacao": "Atividades auxiliares dos transportes aéreos, exceto operação dos aeroportos e campos de aterrissagem"
  },
  {
    "id_cnae": "944",
    "subclasse": "5250-8/01",
    "denominacao": "Comissaria de despachos"
  },
  {
    "id_cnae": "945",
    "subclasse": "5250-8/02",
    "denominacao": "Atividades de despachantes aduaneiros"
  },
  {
    "id_cnae": "946",
    "subclasse": "5250-8/03",
    "denominacao": "Agenciamento de cargas, exceto para o transporte marítimo"
  },
  {
    "id_cnae": "947",
    "subclasse": "5250-8/04",
    "denominacao": "Organização logística do transporte de carga"
  },
  {
    "id_cnae": "948",
    "subclasse": "5250-8/05",
    "denominacao": "Operador de transporte multimodal - OTM"
  },
  {
    "id_cnae": "949",
    "subclasse": "5310-5/01",
    "denominacao": "Atividades do Correio Nacional"
  },
  {
    "id_cnae": "950",
    "subclasse": "5310-5/02",
    "denominacao": "Atividades de franqueadas e permissionárias do Correio Nacional"
  },
  {
    "id_cnae": "951",
    "subclasse": "5320-2/01",
    "denominacao": "Serviços de malote não realizados pelo Correio Nacional"
  },
  {
    "id_cnae": "952",
    "subclasse": "5320-2/02",
    "denominacao": "Serviços de entrega rápida"
  },
  { "id_cnae": "953", "subclasse": "5510-8/01", "denominacao": "Hotéis" },
  {
    "id_cnae": "954",
    "subclasse": "5510-8/02",
    "denominacao": "Apart-hotéis"
  },
  { "id_cnae": "955", "subclasse": "5510-8/03", "denominacao": "Motéis" },
  {
    "id_cnae": "956",
    "subclasse": "5590-6/01",
    "denominacao": "Albergues, exceto assistenciais"
  },
  { "id_cnae": "957", "subclasse": "5590-6/02", "denominacao": "Campings" },
  {
    "id_cnae": "958",
    "subclasse": "5590-6/03",
    "denominacao": "Pensões (alojamento)"
  },
  {
    "id_cnae": "959",
    "subclasse": "5590-6/99",
    "denominacao": "Outros alojamentos não especificados anteriormente"
  },
  {
    "id_cnae": "960",
    "subclasse": "5611-2/01",
    "denominacao": "Restaurantes e similares"
  },
  {
    "id_cnae": "961",
    "subclasse": "5611-2/03",
    "denominacao": "Lanchonetes, casas de chá, de sucos e similares"
  },
  {
    "id_cnae": "962",
    "subclasse": "5611-2/04",
    "denominacao": "Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento"
  },
  {
    "id_cnae": "963",
    "subclasse": "5611-2/05",
    "denominacao": "Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento"
  },
  {
    "id_cnae": "964",
    "subclasse": "5612-1/00",
    "denominacao": "Serviços ambulantes de alimentação"
  },
  {
    "id_cnae": "965",
    "subclasse": "5620-1/01",
    "denominacao": "Fornecimento de alimentos preparados preponderantemente para empresas"
  },
  {
    "id_cnae": "966",
    "subclasse": "5620-1/02",
    "denominacao": "Serviços de alimentação para eventos e recepções - bufê"
  },
  {
    "id_cnae": "967",
    "subclasse": "5620-1/03",
    "denominacao": "Cantinas - serviços de alimentação privativos"
  },
  {
    "id_cnae": "968",
    "subclasse": "5620-1/04",
    "denominacao": "Fornecimento de alimentos preparados preponderantemente para consumo domiciliar"
  },
  {
    "id_cnae": "969",
    "subclasse": "5811-5/00",
    "denominacao": "Edição de livros"
  },
  {
    "id_cnae": "970",
    "subclasse": "5812-3/01",
    "denominacao": "Edição de jornais diários"
  },
  {
    "id_cnae": "971",
    "subclasse": "5812-3/02",
    "denominacao": "Edição de jornais não diários"
  },
  {
    "id_cnae": "972",
    "subclasse": "5813-1/00",
    "denominacao": "Edição de revistas"
  },
  {
    "id_cnae": "973",
    "subclasse": "5819-1/00",
    "denominacao": "Edição de cadastros, listas e outros produtos gráficos"
  },
  {
    "id_cnae": "974",
    "subclasse": "5821-2/00",
    "denominacao": "Edição integrada à impressão de livros"
  },
  {
    "id_cnae": "975",
    "subclasse": "5822-1/01",
    "denominacao": "Edição integrada à impressão de jornais diários"
  },
  {
    "id_cnae": "976",
    "subclasse": "5822-1/02",
    "denominacao": "Edição integrada à impressão de jornais não diários"
  },
  {
    "id_cnae": "977",
    "subclasse": "5823-9/00",
    "denominacao": "Edição integrada à impressão de revistas"
  },
  {
    "id_cnae": "978",
    "subclasse": "5829-8/00",
    "denominacao": "Edição integrada à impressão de cadastros, listas e outros produtos gráficos"
  },
  {
    "id_cnae": "979",
    "subclasse": "5911-1/01",
    "denominacao": "Estúdios cinematográficos"
  },
  {
    "id_cnae": "980",
    "subclasse": "5911-1/02",
    "denominacao": "Produção de filmes para publicidade"
  },
  {
    "id_cnae": "981",
    "subclasse": "5911-1/99",
    "denominacao": "Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente"
  },
  {
    "id_cnae": "982",
    "subclasse": "5912-0/01",
    "denominacao": "Serviços de dublagem"
  },
  {
    "id_cnae": "983",
    "subclasse": "5912-0/02",
    "denominacao": "Serviços de mixagem sonora em produção audiovisual"
  },
  {
    "id_cnae": "984",
    "subclasse": "5912-0/99",
    "denominacao": "Atividades de pós-produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente"
  },
  {
    "id_cnae": "985",
    "subclasse": "5913-8/00",
    "denominacao": "Distribuição cinematográfica, de vídeo e de programas de televisão"
  },
  {
    "id_cnae": "986",
    "subclasse": "5914-6/00",
    "denominacao": "Atividades de exibição cinematográfica"
  },
  {
    "id_cnae": "987",
    "subclasse": "5920-1/00",
    "denominacao": "Atividades de gravação de som e de edição de música"
  },
  {
    "id_cnae": "988",
    "subclasse": "6010-1/00",
    "denominacao": "Atividades de rádio"
  },
  {
    "id_cnae": "989",
    "subclasse": "6021-7/00",
    "denominacao": "Atividades de televisão aberta"
  },
  { "id_cnae": "990", "subclasse": "6022-5/01", "denominacao": "Programadoras" },
  {
    "id_cnae": "991",
    "subclasse": "6022-5/02",
    "denominacao": "Atividades relacionadas à televisão por assinatura, exceto programadoras"
  },
  {
    "id_cnae": "992",
    "subclasse": "6110-8/01",
    "denominacao": "Serviços de telefonia fixa comutada - STFC"
  },
  {
    "id_cnae": "993",
    "subclasse": "6110-8/02",
    "denominacao": "Serviços de redes de transporte de telecomunicações - SRTT"
  },
  {
    "id_cnae": "994",
    "subclasse": "6110-8/03",
    "denominacao": "Serviços de comunicação multimídia - SCM"
  },
  {
    "id_cnae": "995",
    "subclasse": "6110-8/99",
    "denominacao": "Serviços de telecomunicações por fio não especificados anteriormente"
  },
  {
    "id_cnae": "996",
    "subclasse": "6120-5/01",
    "denominacao": "Telefonia móvel celular"
  },
  {
    "id_cnae": "997",
    "subclasse": "6120-5/02",
    "denominacao": "Serviço móvel especializado - SME"
  },
  {
    "id_cnae": "998",
    "subclasse": "6120-5/99",
    "denominacao": "Serviços de telecomunicações sem fio não especificados anteriormente"
  },
  {
    "id_cnae": "999",
    "subclasse": "6130-2/00",
    "denominacao": "Telecomunicações por satélite"
  },
  {
    "id_cnae": "1000",
    "subclasse": "6141-8/00",
    "denominacao": "Operadoras de televisão por assinatura por cabo"
  },
  {
    "id_cnae": "1001",
    "subclasse": "6142-6/00",
    "denominacao": "Operadoras de televisão por assinatura por micro-ondas"
  },
  {
    "id_cnae": "1002",
    "subclasse": "6143-4/00",
    "denominacao": "Operadoras de televisão por assinatura por satélite"
  },
  {
    "id_cnae": "1003",
    "subclasse": "6190-6/01",
    "denominacao": "Provedores de acesso às redes de comunicações"
  },
  {
    "id_cnae": "1004",
    "subclasse": "6190-6/02",
    "denominacao": "Provedores de voz sobre protocolo Internet - VOIP"
  },
  {
    "id_cnae": "1005",
    "subclasse": "6190-6/99",
    "denominacao": "Outras atividades de telecomunicações não especificadas anteriormente"
  },
  {
    "id_cnae": "1006",
    "subclasse": "6201-5/01",
    "denominacao": "Desenvolvimento de programas de computador sob encomenda"
  },
  { "id_cnae": "1007", "subclasse": "6201-5/02", "denominacao": "Web desing" },
  {
    "id_cnae": "1008",
    "subclasse": "6202-3/00",
    "denominacao": "Desenvolvimento e licenciamento de programas de computador customizáveis"
  },
  {
    "id_cnae": "1009",
    "subclasse": "6203-1/00",
    "denominacao": "Desenvolvimento e licenciamento de programas de computador não customizáveis"
  },
  {
    "id_cnae": "1010",
    "subclasse": "6204-0/00",
    "denominacao": "Consultoria em tecnologia da informação"
  },
  {
    "id_cnae": "1011",
    "subclasse": "6209-1/00",
    "denominacao": "Suporte técnico, manutenção e outros serviços em tecnologia da informação"
  },
  {
    "id_cnae": "1012",
    "subclasse": "6311-9/00",
    "denominacao": "Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na Internet"
  },
  {
    "id_cnae": "1013",
    "subclasse": "6319-4/00",
    "denominacao": "Portais, provedores de conteúdo e outros serviços de informação na Internet"
  },
  {
    "id_cnae": "1014",
    "subclasse": "6391-7/00",
    "denominacao": "Agências de notícias"
  },
  {
    "id_cnae": "1015",
    "subclasse": "6399-2/00",
    "denominacao": "Outras atividades de prestação de serviços de informação não especificadas anteriormente"
  },
  { "id_cnae": "1016", "subclasse": "6410-7/00", "denominacao": "Banco Central" },
  {
    "id_cnae": "1017",
    "subclasse": "6421-2/00",
    "denominacao": "Bancos comerciais"
  },
  {
    "id_cnae": "1018",
    "subclasse": "6422-1/00",
    "denominacao": "Bancos múltiplos, com carteira comercial"
  },
  {
    "id_cnae": "1019",
    "subclasse": "6423-9/00",
    "denominacao": "Caixas econômicas"
  },
  {
    "id_cnae": "1020",
    "subclasse": "6424-7/01",
    "denominacao": "Bancos cooperativos"
  },
  {
    "id_cnae": "1021",
    "subclasse": "6424-7/02",
    "denominacao": "Cooperativas centrais de crédito"
  },
  {
    "id_cnae": "1022",
    "subclasse": "6424-7/03",
    "denominacao": "Cooperativas de crédito mútuo"
  },
  {
    "id_cnae": "1023",
    "subclasse": "6424-7/04",
    "denominacao": "Cooperativas de crédito rural"
  },
  {
    "id_cnae": "1024",
    "subclasse": "6431-0/00",
    "denominacao": "Bancos múltiplos, sem carteira comercial"
  },
  {
    "id_cnae": "1025",
    "subclasse": "6432-8/00",
    "denominacao": "Bancos de investimento"
  },
  {
    "id_cnae": "1026",
    "subclasse": "6433-6/00",
    "denominacao": "Bancos de desenvolvimento"
  },
  {
    "id_cnae": "1027",
    "subclasse": "6434-4/00",
    "denominacao": "Agências de fomento"
  },
  {
    "id_cnae": "1028",
    "subclasse": "6435-2/01",
    "denominacao": "Sociedades de crédito imobiliário"
  },
  {
    "id_cnae": "1029",
    "subclasse": "6435-2/02",
    "denominacao": "Associações de poupança e empréstimo"
  },
  {
    "id_cnae": "1030",
    "subclasse": "6435-2/03",
    "denominacao": "Companhias hipotecárias"
  },
  {
    "id_cnae": "1031",
    "subclasse": "6436-1/00",
    "denominacao": "Sociedades de crédito, financiamento e investimento - financeiras"
  },
  {
    "id_cnae": "1032",
    "subclasse": "6437-9/00",
    "denominacao": "Sociedades de crédito ao microempreendedor"
  },
  {
    "id_cnae": "1033",
    "subclasse": "6438-7/01",
    "denominacao": "Bancos de câmbio"
  },
  {
    "id_cnae": "1034",
    "subclasse": "6438-7/99",
    "denominacao": "Outras instituições de intermediação não monetária não especificadas anteriormente"
  },
  {
    "id_cnae": "1035",
    "subclasse": "6440-9/00",
    "denominacao": "Arrendamento mercantil"
  },
  {
    "id_cnae": "1036",
    "subclasse": "6450-6/00",
    "denominacao": "Sociedades de capitalização"
  },
  {
    "id_cnae": "1037",
    "subclasse": "6461-1/00",
    "denominacao": "Holdings de instituições financeiras"
  },
  {
    "id_cnae": "1038",
    "subclasse": "6462-0/00",
    "denominacao": "Holdings de instituições não financeiras"
  },
  {
    "id_cnae": "1039",
    "subclasse": "6463-8/00",
    "denominacao": "Outras sociedades de participação, exceto holdings"
  },
  {
    "id_cnae": "1040",
    "subclasse": "6470-1/01",
    "denominacao": "Fundos de investimento, exceto previdenciários e imobiliários"
  },
  {
    "id_cnae": "1041",
    "subclasse": "6470-1/02",
    "denominacao": "Fundos de investimento previdenciários"
  },
  {
    "id_cnae": "1042",
    "subclasse": "6470-1/03",
    "denominacao": "Fundos de investimento imobiliários"
  },
  {
    "id_cnae": "1043",
    "subclasse": "6491-3/00",
    "denominacao": "Sociedades de fomento mercantil - factoring"
  },
  {
    "id_cnae": "1044",
    "subclasse": "6492-1/00",
    "denominacao": "Securitização de créditos"
  },
  {
    "id_cnae": "1045",
    "subclasse": "6493-0/00",
    "denominacao": "Administração de consórcios para aquisição de bens e direitos"
  },
  {
    "id_cnae": "1046",
    "subclasse": "6499-9/01",
    "denominacao": "Clubes de investimento"
  },
  {
    "id_cnae": "1047",
    "subclasse": "6499-9/02",
    "denominacao": "Sociedades de investimento"
  },
  {
    "id_cnae": "1048",
    "subclasse": "6499-9/03",
    "denominacao": "Fundo garantidor de crédito"
  },
  {
    "id_cnae": "1049",
    "subclasse": "6499-9/04",
    "denominacao": "Caixas de financiamento de corporações"
  },
  {
    "id_cnae": "1050",
    "subclasse": "6499-9/05",
    "denominacao": "Concessão de crédito pelas OSCIP"
  },
  {
    "id_cnae": "1051",
    "subclasse": "6499-9/99",
    "denominacao": "Outras atividades de serviços financeiros não especificadas anteriormente"
  },
  {
    "id_cnae": "1052",
    "subclasse": "6511-1/01",
    "denominacao": "Sociedade seguradora de seguros vida"
  },
  {
    "id_cnae": "1053",
    "subclasse": "6511-1/02",
    "denominacao": "Planos de auxílio-funeral"
  },
  {
    "id_cnae": "1054",
    "subclasse": "6512-0/00",
    "denominacao": "Sociedade seguradora de seguros não vida"
  },
  {
    "id_cnae": "1055",
    "subclasse": "6520-1/00",
    "denominacao": "Sociedade seguradora de seguros-saúde"
  },
  { "id_cnae": "1056", "subclasse": "6530-8/00", "denominacao": "Resseguros" },
  {
    "id_cnae": "1057",
    "subclasse": "6541-3/00",
    "denominacao": "Previdência complementar fechada"
  },
  {
    "id_cnae": "1058",
    "subclasse": "6542-1/00",
    "denominacao": "Previdência complementar aberta"
  },
  {
    "id_cnae": "1059",
    "subclasse": "6550-2/00",
    "denominacao": "Planos de saúde"
  },
  {
    "id_cnae": "1060",
    "subclasse": "6611-8/01",
    "denominacao": "Bolsa de valores"
  },
  {
    "id_cnae": "1061",
    "subclasse": "6611-8/02",
    "denominacao": "Bolsa de mercadorias"
  },
  {
    "id_cnae": "1062",
    "subclasse": "6611-8/03",
    "denominacao": "Bolsa de mercadorias e futuros"
  },
  {
    "id_cnae": "1063",
    "subclasse": "6611-8/04",
    "denominacao": "Administração de mercados de balcão organizados"
  },
  {
    "id_cnae": "1064",
    "subclasse": "6612-6/01",
    "denominacao": "Corretoras de títulos e valores mobiliários"
  },
  {
    "id_cnae": "1065",
    "subclasse": "6612-6/02",
    "denominacao": "Distribuidoras de títulos e valores mobiliários"
  },
  {
    "id_cnae": "1066",
    "subclasse": "6612-6/03",
    "denominacao": "Corretoras de câmbio"
  },
  {
    "id_cnae": "1067",
    "subclasse": "6612-6/04",
    "denominacao": "Corretoras de contratos de mercadorias"
  },
  {
    "id_cnae": "1068",
    "subclasse": "6612-6/05",
    "denominacao": "Agentes de investimentos em aplicações financeiras"
  },
  {
    "id_cnae": "1069",
    "subclasse": "6613-4/00",
    "denominacao": "Administração de cartões de crédito"
  },
  {
    "id_cnae": "1070",
    "subclasse": "6619-3/01",
    "denominacao": "Serviços de liquidação e custódia"
  },
  {
    "id_cnae": "1071",
    "subclasse": "6619-3/02",
    "denominacao": "Correspondentes de instituições financeiras"
  },
  {
    "id_cnae": "1072",
    "subclasse": "6619-3/03",
    "denominacao": "Representações de bancos estrangeiros"
  },
  {
    "id_cnae": "1073",
    "subclasse": "6619-3/04",
    "denominacao": "Caixas eletrônicos"
  },
  {
    "id_cnae": "1074",
    "subclasse": "6619-3/05",
    "denominacao": "Operadoras de cartões de débito"
  },
  {
    "id_cnae": "1075",
    "subclasse": "6619-3/99",
    "denominacao": "Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente"
  },
  {
    "id_cnae": "1076",
    "subclasse": "6621-5/01",
    "denominacao": "Peritos e avaliadores de seguros"
  },
  {
    "id_cnae": "1077",
    "subclasse": "6621-5/02",
    "denominacao": "Auditoria e consultoria atuarial"
  },
  {
    "id_cnae": "1078",
    "subclasse": "6622-3/00",
    "denominacao": "Corretores e agentes de seguros, de planos de previdência complementar e de saúde"
  },
  {
    "id_cnae": "1079",
    "subclasse": "6629-1/00",
    "denominacao": "Atividades auxiliares dos seguros, da previdência complementar e dos planos de saúde não especificadas anteriormente"
  },
  {
    "id_cnae": "1080",
    "subclasse": "6630-4/00",
    "denominacao": "Atividades de administração de fundos por contrato ou comissão"
  },
  {
    "id_cnae": "1081",
    "subclasse": "6810-2/01",
    "denominacao": "Compra e venda de imóveis próprios"
  },
  {
    "id_cnae": "1082",
    "subclasse": "6810-2/02",
    "denominacao": "Aluguel de imóveis próprios"
  },
  {
    "id_cnae": "1083",
    "subclasse": "6810-2/03",
    "denominacao": "Loteamento de imóveis próprios"
  },
  {
    "id_cnae": "1084",
    "subclasse": "6821-8/01",
    "denominacao": "Corretagem na compra e venda e avaliação de imóveis"
  },
  {
    "id_cnae": "1085",
    "subclasse": "6821-8/02",
    "denominacao": "Corretagem no aluguel de imóveis"
  },
  {
    "id_cnae": "1086",
    "subclasse": "6822-6/00",
    "denominacao": "Gestão e administração da propriedade imobiliária"
  },
  {
    "id_cnae": "1087",
    "subclasse": "6911-7/01",
    "denominacao": "Serviços advocatícios"
  },
  {
    "id_cnae": "1088",
    "subclasse": "6911-7/02",
    "denominacao": "Atividades auxiliares da justiça"
  },
  {
    "id_cnae": "1089",
    "subclasse": "6911-7/03",
    "denominacao": "Agente de propriedade industrial"
  },
  {
    "id_cnae": "1090",
    "subclasse": "6912-5/00",
    "denominacao": "Cartórios"
  },
  {
    "id_cnae": "1091",
    "subclasse": "6920-6/01",
    "denominacao": "Atividades de contabilidade"
  },
  {
    "id_cnae": "1092",
    "subclasse": "6920-6/02",
    "denominacao": "Atividades de consultoria e auditoria contábil e tributária"
  },
  {
    "id_cnae": "1093",
    "subclasse": "7020-4/00",
    "denominacao": "Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica"
  },
  {
    "id_cnae": "1094",
    "subclasse": "7111-1/00",
    "denominacao": "Serviços de arquitetura"
  },
  {
    "id_cnae": "1095",
    "subclasse": "7112-0/00",
    "denominacao": "Serviços de engenharia"
  },
  {
    "id_cnae": "1096",
    "subclasse": "7119-7/01",
    "denominacao": "Serviços de cartografia, topografia e geodésia"
  },
  {
    "id_cnae": "1097",
    "subclasse": "7119-7/02",
    "denominacao": "Atividades de estudos geológicos"
  },
  {
    "id_cnae": "1098",
    "subclasse": "7119-7/03",
    "denominacao": "Serviços de desenho técnico relacionados à arquitetura e engenharia"
  },
  {
    "id_cnae": "1099",
    "subclasse": "7119-7/04",
    "denominacao": "Serviços de perícia técnica relacionados à segurança do trabalho"
  },
  {
    "id_cnae": "1100",
    "subclasse": "7119-7/99",
    "denominacao": "Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente"
  },
  {
    "id_cnae": "1101",
    "subclasse": "7120-1/00",
    "denominacao": "Testes e análises técnicas"
  },
  {
    "id_cnae": "1102",
    "subclasse": "7210-0/00",
    "denominacao": "Pesquisa e desenvolvimento experimental em ciências físicas e naturais"
  },
  {
    "id_cnae": "1103",
    "subclasse": "7220-7/00",
    "denominacao": "Pesquisa e desenvolvimento experimental em ciências sociais e humanas"
  },
  {
    "id_cnae": "1104",
    "subclasse": "7311-4/00",
    "denominacao": "Agências de publicidade"
  },
  {
    "id_cnae": "1105",
    "subclasse": "7312-2/00",
    "denominacao": "Agenciamento de espaços para publicidade, exceto em veículos de comunicação"
  },
  {
    "id_cnae": "1106",
    "subclasse": "7319-0/01",
    "denominacao": "Criação de estandes para feiras e exposições"
  },
  {
    "id_cnae": "1107",
    "subclasse": "7319-0/02",
    "denominacao": "Promoção de vendas"
  },
  {
    "id_cnae": "1108",
    "subclasse": "7319-0/03",
    "denominacao": "Marketing direto"
  },
  {
    "id_cnae": "1109",
    "subclasse": "7319-0/04",
    "denominacao": "Consultoria em publicidade"
  },
  {
    "id_cnae": "1110",
    "subclasse": "7319-0/99",
    "denominacao": "Outras atividades de publicidade não especificadas anteriormente"
  },
  {
    "id_cnae": "1111",
    "subclasse": "7320-3/00",
    "denominacao": "Pesquisas de mercado e de opinião pública"
  },
  {
    "id_cnae": "1112",
    "subclasse": "7410-2/02",
    "denominacao": "Design de interiores"
  },
  {
    "id_cnae": "1113",
    "subclasse": "7410-2/03",
    "denominacao": "Desing de produto"
  },
  {
    "id_cnae": "1114",
    "subclasse": "7410-2/99",
    "denominacao": "Atividades de desing não especificadas anteriormente"
  },
  {
    "id_cnae": "1115",
    "subclasse": "7420-0/01",
    "denominacao": "Atividades de produção de fotografias, exceto aérea e submarina"
  },
  {
    "id_cnae": "1116",
    "subclasse": "7420-0/02",
    "denominacao": "Atividades de produção de fotografias aéreas e submarinas"
  },
  {
    "id_cnae": "1117",
    "subclasse": "7420-0/03",
    "denominacao": "Laboratórios fotográficos"
  },
  {
    "id_cnae": "1118",
    "subclasse": "7420-0/04",
    "denominacao": "Filmagem de festas e eventos"
  },
  {
    "id_cnae": "1119",
    "subclasse": "7420-0/05",
    "denominacao": "Serviços de microfilmagem"
  },
  {
    "id_cnae": "1120",
    "subclasse": "7490-1/01",
    "denominacao": "Serviços de tradução, interpretação e similares"
  },
  {
    "id_cnae": "1121",
    "subclasse": "7490-1/02",
    "denominacao": "Escafandria e mergulho"
  },
  {
    "id_cnae": "1122",
    "subclasse": "7490-1/03",
    "denominacao": "Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias"
  },
  {
    "id_cnae": "1123",
    "subclasse": "7490-1/04",
    "denominacao": "Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários"
  },
  {
    "id_cnae": "1124",
    "subclasse": "7490-1/05",
    "denominacao": "Agenciamento de profissionais para atividades esportivas, culturais e artísticas"
  },
  {
    "id_cnae": "1125",
    "subclasse": "7490-1/99",
    "denominacao": "Outras atividades profissionais, científicas e técnicas não especificadas anteriormente"
  },
  {
    "id_cnae": "1126",
    "subclasse": "7500-1/00",
    "denominacao": "Atividades veterinárias"
  },
  {
    "id_cnae": "1127",
    "subclasse": "7711-0/00",
    "denominacao": "Locação de automóveis sem condutor"
  },
  {
    "id_cnae": "1128",
    "subclasse": "7719-5/01",
    "denominacao": "Locação de embarcações sem tripulação, exceto para fins recreativos"
  },
  {
    "id_cnae": "1129",
    "subclasse": "7719-5/02",
    "denominacao": "Locação de aeronaves sem tripulação"
  },
  {
    "id_cnae": "1130",
    "subclasse": "7719-5/99",
    "denominacao": "Locação de outros meios de transporte não especificados anteriormente, sem condutor"
  },
  {
    "id_cnae": "1131",
    "subclasse": "7721-7/00",
    "denominacao": "Aluguel de equipamentos recreativos e esportivos"
  },
  {
    "id_cnae": "1132",
    "subclasse": "7722-5/00",
    "denominacao": "Aluguel de fitas de vídeo, DVDs e similares"
  },
  {
    "id_cnae": "1133",
    "subclasse": "7723-3/00",
    "denominacao": "Aluguel de objetos do vestuário, jóias e acessórios"
  },
  {
    "id_cnae": "1134",
    "subclasse": "7729-2/01",
    "denominacao": "Aluguel de aparelhos de jogos eletrônicos"
  },
  {
    "id_cnae": "1135",
    "subclasse": "7729-2/02",
    "denominacao": "Aluguel de móveis, utensílios e aparelhos de uso doméstico e pessoal; instrumentos musicais"
  },
  {
    "id_cnae": "1136",
    "subclasse": "7729-2/03",
    "denominacao": "Aluguel de material médico"
  },
  {
    "id_cnae": "1137",
    "subclasse": "7729-2/99",
    "denominacao": "Aluguel de outros objetos pessoais e domésticos não especificados anteriormente"
  },
  {
    "id_cnae": "1138",
    "subclasse": "7731-4/00",
    "denominacao": "Aluguel de máquinas e equipamentos agrícolas sem operador"
  },
  {
    "id_cnae": "1139",
    "subclasse": "7732-2/01",
    "denominacao": "Aluguel de máquinas e equipamentos para construção sem operador, exceto andaimes"
  },
  {
    "id_cnae": "1140",
    "subclasse": "7732-2/02",
    "denominacao": "Aluguel de andaimes"
  },
  {
    "id_cnae": "1141",
    "subclasse": "7733-1/00",
    "denominacao": "Aluguel de máquinas e equipamentos para escritório"
  },
  {
    "id_cnae": "1142",
    "subclasse": "7739-0/01",
    "denominacao": "Aluguel de máquinas e equipamentos para extração de minérios e petróleo, sem operador"
  },
  {
    "id_cnae": "1143",
    "subclasse": "7739-0/02",
    "denominacao": "Aluguel de equipamentos científicos, médicos e hospitalares, sem operador"
  },
  {
    "id_cnae": "1144",
    "subclasse": "7739-0/03",
    "denominacao": "Aluguel de palcos, coberturas e outras estruturas de uso temporário, exceto andaimes"
  },
  {
    "id_cnae": "1145",
    "subclasse": "7739-0/99",
    "denominacao": "Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador"
  },
  {
    "id_cnae": "1146",
    "subclasse": "7740-3/00",
    "denominacao": "Gestão de ativos intangíveis não financeiros"
  },
  {
    "id_cnae": "1147",
    "subclasse": "7810-8/00",
    "denominacao": "Seleção e agenciamento de mão de obra"
  },
  {
    "id_cnae": "1148",
    "subclasse": "7820-5/00",
    "denominacao": "Locação de mão de obra temporária"
  },
  {
    "id_cnae": "1149",
    "subclasse": "7830-2/00",
    "denominacao": "Fornecimento e gestão de recursos humanos para terceiros"
  },
  {
    "id_cnae": "1150",
    "subclasse": "7911-2/00",
    "denominacao": "Agências de viagens"
  },
  {
    "id_cnae": "1151",
    "subclasse": "7912-1/00",
    "denominacao": "Operadores turísticos"
  },
  {
    "id_cnae": "1152",
    "subclasse": "7990-2/00",
    "denominacao": "Serviços de reservas e outros serviços de turismo não especificados anteriormente"
  },
  {
    "id_cnae": "1153",
    "subclasse": "8011-1/01",
    "denominacao": "Atividades de vigilância e segurança privada"
  },
  {
    "id_cnae": "1154",
    "subclasse": "8011-1/02",
    "denominacao": "Serviços de adestramento de cães de guarda"
  },
  {
    "id_cnae": "1155",
    "subclasse": "8012-9/00",
    "denominacao": "Atividades de transporte de valores"
  },
  {
    "id_cnae": "1156",
    "subclasse": "8020-0/01",
    "denominacao": "Atividades de monitoramento de sistemas de segurança eletrônico"
  },
  {
    "id_cnae": "1157",
    "subclasse": "8020-0/02",
    "denominacao": "Outras atividades de serviços de segurança"
  },
  {
    "id_cnae": "1158",
    "subclasse": "8030-7/00",
    "denominacao": "Atividades de investigação particular"
  },
  {
    "id_cnae": "1159",
    "subclasse": "8111-7/00",
    "denominacao": "Serviços combinados para apoio a edifícios, exceto condomínios prediais"
  },
  {
    "id_cnae": "1160",
    "subclasse": "8112-5/00",
    "denominacao": "Condomínios prediais"
  },
  {
    "id_cnae": "1161",
    "subclasse": "8121-4/00",
    "denominacao": "Limpeza em prédios e em domicílios"
  },
  {
    "id_cnae": "1162",
    "subclasse": "8122-2/00",
    "denominacao": "Imunização e controle de pragas urbanas"
  },
  {
    "id_cnae": "1163",
    "subclasse": "8129-0/00",
    "denominacao": "Atividades de limpeza não especificadas anteriormente"
  },
  {
    "id_cnae": "1164",
    "subclasse": "8130-3/00",
    "denominacao": "Atividades paisagísticas"
  },
  {
    "id_cnae": "1165",
    "subclasse": "8211-3/00",
    "denominacao": "Serviços combinados de escritório e apoio administrativo"
  },
  {
    "id_cnae": "1166",
    "subclasse": "8219-9/01",
    "denominacao": "Fotocópias"
  },
  {
    "id_cnae": "1167",
    "subclasse": "8219-9/99",
    "denominacao": "Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente"
  },
  {
    "id_cnae": "1168",
    "subclasse": "8220-2/00",
    "denominacao": "Atividades de teleatendimento"
  },
  {
    "id_cnae": "1169",
    "subclasse": "8230-0/01",
    "denominacao": "Serviços de organização de feiras, congressos, exposições e festas"
  },
  {
    "id_cnae": "1170",
    "subclasse": "8230-0/02",
    "denominacao": "Casas de festas e eventos"
  },
  {
    "id_cnae": "1171",
    "subclasse": "8291-1/00",
    "denominacao": "Atividades de cobrança e informações cadastrais"
  },
  {
    "id_cnae": "1172",
    "subclasse": "8292-0/00",
    "denominacao": "Envasamento e empacotamento sob contrato"
  },
  {
    "id_cnae": "1173",
    "subclasse": "8299-7/01",
    "denominacao": "Medição de consumo de energia elétrica, gás e água"
  },
  {
    "id_cnae": "1174",
    "subclasse": "8299-7/02",
    "denominacao": "Emissão de vales-alimentação, vales-transporte e similares"
  },
  {
    "id_cnae": "1175",
    "subclasse": "8299-7/03",
    "denominacao": "Serviços de gravação de carimbos, exceto confecção"
  },
  {
    "id_cnae": "1176",
    "subclasse": "8299-7/04",
    "denominacao": "Leiloeiros independentes"
  },
  {
    "id_cnae": "1177",
    "subclasse": "8299-7/05",
    "denominacao": "Serviços de levantamento de fundos sob contrato"
  },
  {
    "id_cnae": "1178",
    "subclasse": "8299-7/06",
    "denominacao": "Casas lotéricas"
  },
  {
    "id_cnae": "1179",
    "subclasse": "8299-7/07",
    "denominacao": "Salas de acesso à Internet"
  },
  {
    "id_cnae": "1180",
    "subclasse": "8299-7/99",
    "denominacao": "Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente"
  },
  {
    "id_cnae": "1181",
    "subclasse": "8411-6/00",
    "denominacao": "Administração pública em geral"
  },
  {
    "id_cnae": "1182",
    "subclasse": "8412-4/00",
    "denominacao": "Regulação das atividades de saúde, educação, serviços culturais e outros serviços sociais"
  },
  {
    "id_cnae": "1183",
    "subclasse": "8413-2/00",
    "denominacao": "Regulação das atividades econômicas"
  },
  {
    "id_cnae": "1184",
    "subclasse": "8421-3/00",
    "denominacao": "Relações exteriores"
  },
  { "id_cnae": "1185", "subclasse": "8422-1/00", "denominacao": "Defesa" },
  { "id_cnae": "1186", "subclasse": "8423-0/00", "denominacao": "Justiça" },
  {
    "id_cnae": "1187",
    "subclasse": "8424-8/00",
    "denominacao": "Segurança e ordem pública"
  },
  { "id_cnae": "1188", "subclasse": "8425-6/00", "denominacao": "Defesa Civil" },
  {
    "id_cnae": "1189",
    "subclasse": "8430-2/00",
    "denominacao": "Seguridade social obrigatória"
  },
  {
    "id_cnae": "1190",
    "subclasse": "8511-2/00",
    "denominacao": "Educação infantil - creche"
  },
  {
    "id_cnae": "1191",
    "subclasse": "8512-1/00",
    "denominacao": "Educação infantil - pré-escola"
  },
  {
    "id_cnae": "1192",
    "subclasse": "8513-9/00",
    "denominacao": "Ensino fundamental"
  },
  {
    "id_cnae": "1193",
    "subclasse": "8520-1/00",
    "denominacao": "Ensino médio"
  },
  {
    "id_cnae": "1194",
    "subclasse": "8531-7/00",
    "denominacao": "Educação superior - graduação"
  },
  {
    "id_cnae": "1195",
    "subclasse": "8532-5/00",
    "denominacao": "Educação superior - graduação e pós-graduação"
  },
  {
    "id_cnae": "1196",
    "subclasse": "8533-3/00",
    "denominacao": "Educação superior - pós-graduação e extensão"
  },
  {
    "id_cnae": "1197",
    "subclasse": "8541-4/00",
    "denominacao": "Educação profissional de nível técnico"
  },
  {
    "id_cnae": "1198",
    "subclasse": "8542-2/00",
    "denominacao": "Educação profissional de nível tecnológico"
  },
  {
    "id_cnae": "1199",
    "subclasse": "8550-3/01",
    "denominacao": "Administração de caixas escolares"
  },
  {
    "id_cnae": "1200",
    "subclasse": "8550-3/02",
    "denominacao": "Atividades de apoio à educação, exceto caixas escolares"
  },
  {
    "id_cnae": "1201",
    "subclasse": "8591-1/00",
    "denominacao": "Ensino de esportes"
  },
  {
    "id_cnae": "1202",
    "subclasse": "8592-9/01",
    "denominacao": "Ensino de dança"
  },
  {
    "id_cnae": "1203",
    "subclasse": "8592-9/02",
    "denominacao": "Ensino de artes cênicas, exceto dança"
  },
  {
    "id_cnae": "1204",
    "subclasse": "8592-9/03",
    "denominacao": "Ensino de música"
  },
  {
    "id_cnae": "1205",
    "subclasse": "8592-9/99",
    "denominacao": "Ensino de arte e cultura não especificado anteriormente"
  },
  {
    "id_cnae": "1206",
    "subclasse": "8593-7/00",
    "denominacao": "Ensino de idiomas"
  },
  {
    "id_cnae": "1207",
    "subclasse": "8599-6/01",
    "denominacao": "Formação de condutores"
  },
  {
    "id_cnae": "1208",
    "subclasse": "8599-6/02",
    "denominacao": "Cursos de pilotagem"
  },
  {
    "id_cnae": "1209",
    "subclasse": "8599-6/03",
    "denominacao": "Treinamento em informática"
  },
  {
    "id_cnae": "1210",
    "subclasse": "8599-6/04",
    "denominacao": "Treinamento em desenvolvimento profissional e gerencial"
  },
  {
    "id_cnae": "1211",
    "subclasse": "8599-6/05",
    "denominacao": "Cursos preparatórios para concursos"
  },
  {
    "id_cnae": "1212",
    "subclasse": "8599-6/99",
    "denominacao": "Outras atividades de ensino não especificadas anteriormente"
  },
  {
    "id_cnae": "1213",
    "subclasse": "8610-1/01",
    "denominacao": "Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgências"
  },
  {
    "id_cnae": "1214",
    "subclasse": "8610-1/02",
    "denominacao": "Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências"
  },
  {
    "id_cnae": "1215",
    "subclasse": "8621-6/01",
    "denominacao": "UTI móvel"
  },
  {
    "id_cnae": "1216",
    "subclasse": "8621-6/02",
    "denominacao": "Serviços móveis de atendimento a urgências, exceto por UTI móvel"
  },
  {
    "id_cnae": "1217",
    "subclasse": "8622-4/00",
    "denominacao": "Serviços de remoção de pacientes, exceto os serviços móveis de atendimento a urgências"
  },
  {
    "id_cnae": "1218",
    "subclasse": "8630-5/01",
    "denominacao": "Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos"
  },
  {
    "id_cnae": "1219",
    "subclasse": "8630-5/02",
    "denominacao": "Atividade médica ambulatorial com recursos para realização de exames complementares"
  },
  {
    "id_cnae": "1220",
    "subclasse": "8630-5/03",
    "denominacao": "Atividade médica ambulatorial restrita a consultas"
  },
  {
    "id_cnae": "1221",
    "subclasse": "8630-5/04",
    "denominacao": "Atividade odontológica"
  },
  {
    "id_cnae": "1222",
    "subclasse": "8630-5/06",
    "denominacao": "Serviços de vacinação e imunização humana"
  },
  {
    "id_cnae": "1223",
    "subclasse": "8630-5/07",
    "denominacao": "Atividades de reprodução humana assistida"
  },
  {
    "id_cnae": "1224",
    "subclasse": "8630-5/99",
    "denominacao": "Atividades de atenção ambulatorial não especificadas anteriormente"
  },
  {
    "id_cnae": "1225",
    "subclasse": "8640-2/01",
    "denominacao": "Laboratórios de anatomia patológica e citológica"
  },
  {
    "id_cnae": "1226",
    "subclasse": "8640-2/02",
    "denominacao": "Laboratórios clínicos"
  },
  {
    "id_cnae": "1227",
    "subclasse": "8640-2/03",
    "denominacao": "Serviços de diálise e nefrologia"
  },
  {
    "id_cnae": "1228",
    "subclasse": "8640-2/04",
    "denominacao": "Serviços de tomografia"
  },
  {
    "id_cnae": "1229",
    "subclasse": "8640-2/05",
    "denominacao": "Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia"
  },
  {
    "id_cnae": "1230",
    "subclasse": "8640-2/06",
    "denominacao": "Serviços de ressonância magnética"
  },
  {
    "id_cnae": "1231",
    "subclasse": "8640-2/07",
    "denominacao": "Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética"
  },
  {
    "id_cnae": "1232",
    "subclasse": "8640-2/08",
    "denominacao": "Serviços de diagnóstico por registro gráfico - ECG, EEG e outros exames análogos"
  },
  {
    "id_cnae": "1233",
    "subclasse": "8640-2/09",
    "denominacao": "Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos"
  },
  {
    "id_cnae": "1234",
    "subclasse": "8640-2/10",
    "denominacao": "Serviços de quimioterapia"
  },
  {
    "id_cnae": "1235",
    "subclasse": "8640-2/11",
    "denominacao": "Serviços de radioterapia"
  },
  {
    "id_cnae": "1236",
    "subclasse": "8640-2/12",
    "denominacao": "Serviços de hemoterapia"
  },
  {
    "id_cnae": "1237",
    "subclasse": "8640-2/13",
    "denominacao": "Serviços de litotripsia"
  },
  {
    "id_cnae": "1238",
    "subclasse": "8640-2/14",
    "denominacao": "Serviços de bancos de células e tecidos humanos"
  },
  {
    "id_cnae": "1239",
    "subclasse": "8640-2/99",
    "denominacao": "Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente"
  },
  {
    "id_cnae": "1240",
    "subclasse": "8650-0/01",
    "denominacao": "Atividades de enfermagem"
  },
  {
    "id_cnae": "1241",
    "subclasse": "8650-0/02",
    "denominacao": "Atividades de profissionais da nutrição"
  },
  {
    "id_cnae": "1242",
    "subclasse": "8650-0/03",
    "denominacao": "Atividades de psicologia e psicanálise"
  },
  {
    "id_cnae": "1243",
    "subclasse": "8650-0/04",
    "denominacao": "Atividades de fisioterapia"
  },
  {
    "id_cnae": "1244",
    "subclasse": "8650-0/05",
    "denominacao": "Atividades de terapia ocupacional"
  },
  {
    "id_cnae": "1245",
    "subclasse": "8650-0/06",
    "denominacao": "Atividades de fonoaudiologia"
  },
  {
    "id_cnae": "1246",
    "subclasse": "8650-0/07",
    "denominacao": "Atividades de terapia de nutrição enteral e parenteral"
  },
  {
    "id_cnae": "1247",
    "subclasse": "8650-0/99",
    "denominacao": "Atividades de profissionais da área de saúde não especificadas anteriormente"
  },
  {
    "id_cnae": "1248",
    "subclasse": "8660-7/00",
    "denominacao": "Atividades de apoio à gestão de saúde"
  },
  {
    "id_cnae": "1249",
    "subclasse": "8690-9/01",
    "denominacao": "Atividades de práticas integrativas e complementares em saúde humana"
  },
  {
    "id_cnae": "1250",
    "subclasse": "8690-9/02",
    "denominacao": "Atividades de bancos de leite humano"
  },
  {
    "id_cnae": "1251",
    "subclasse": "8690-9/03",
    "denominacao": "Atividades de acupuntura"
  },
  {
    "id_cnae": "1252",
    "subclasse": "8690-9/04",
    "denominacao": "Atividades de podologia"
  },
  {
    "id_cnae": "1253",
    "subclasse": "8690-9/99",
    "denominacao": "Outras atividades de atenção à saúde humana não especificadas anteriormente"
  },
  {
    "id_cnae": "1254",
    "subclasse": "8711-5/01",
    "denominacao": "Clínicas e residências geriátricas"
  },
  {
    "id_cnae": "1255",
    "subclasse": "8711-5/02",
    "denominacao": "Instituições de longa permanência para idosos"
  },
  {
    "id_cnae": "1256",
    "subclasse": "8711-5/03",
    "denominacao": "Atividades de assistência a deficientes físicos, imunodeprimidos e convalescentes"
  },
  {
    "id_cnae": "1257",
    "subclasse": "8711-5/04",
    "denominacao": "Centros de apoio a pacientes com câncer e com AIDS"
  },
  {
    "id_cnae": "1258",
    "subclasse": "8711-5/05",
    "denominacao": "Condomínios residenciais para idosos"
  },
  {
    "id_cnae": "1259",
    "subclasse": "8712-3/00",
    "denominacao": "Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio"
  },
  {
    "id_cnae": "1260",
    "subclasse": "8720-4/01",
    "denominacao": "Atividades de centros de assistência psicossocial"
  },
  {
    "id_cnae": "1261",
    "subclasse": "8720-4/99",
    "denominacao": "Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência química e grupos similares não especificadas anteriormente"
  },
  { "id_cnae": "1262", "subclasse": "8730-1/01", "denominacao": "Orfanatos" },
  {
    "id_cnae": "1263",
    "subclasse": "8730-1/02",
    "denominacao": "Albergues assistenciais"
  },
  {
    "id_cnae": "1264",
    "subclasse": "8730-1/99",
    "denominacao": "Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente"
  },
  {
    "id_cnae": "1265",
    "subclasse": "8800-6/00",
    "denominacao": "Serviços de assistência social sem alojamento"
  },
  {
    "id_cnae": "1266",
    "subclasse": "9001-9/01",
    "denominacao": "Produção teatral"
  },
  {
    "id_cnae": "1267",
    "subclasse": "9001-9/02",
    "denominacao": "Produção musical"
  },
  {
    "id_cnae": "1268",
    "subclasse": "9001-9/03",
    "denominacao": "Produção de espetáculos de dança"
  },
  {
    "id_cnae": "1269",
    "subclasse": "9001-9/04",
    "denominacao": "Produção de espetáculos circenses, de marionetes e similares"
  },
  {
    "id_cnae": "1270",
    "subclasse": "9001-9/05",
    "denominacao": "Produção de espetáculos de rodeios, vaquejadas e similares"
  },
  {
    "id_cnae": "1271",
    "subclasse": "9001-9/06",
    "denominacao": "Atividades de sonorização e de iluminação"
  },
  {
    "id_cnae": "1272",
    "subclasse": "9001-9/99",
    "denominacao": "Artes cênicas, espetáculos e atividades complementares não especificados anteriormente"
  },
  {
    "id_cnae": "1273",
    "subclasse": "9002-7/01",
    "denominacao": "Atividades de artistas plásticos, jornalistas independentes e escritores"
  },
  {
    "id_cnae": "1274",
    "subclasse": "9002-7/02",
    "denominacao": "Restauração de obras de arte"
  },
  {
    "id_cnae": "1275",
    "subclasse": "9003-5/00",
    "denominacao": "Gestão de espaços para artes cênicas, espetáculos e outras atividades artísticas"
  },
  {
    "id_cnae": "1276",
    "subclasse": "9101-5/00",
    "denominacao": "Atividades de bibliotecas e arquivos"
  },
  {
    "id_cnae": "1277",
    "subclasse": "9102-3/01",
    "denominacao": "Atividades de museus e de exploração de lugares e prédios históricos e atrações similares"
  },
  {
    "id_cnae": "1278",
    "subclasse": "9102-3/02",
    "denominacao": "Restauração e conservação de lugares e prédios históricos"
  },
  {
    "id_cnae": "1279",
    "subclasse": "9103-1/00",
    "denominacao": "Atividades de jardins botânicos, zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental"
  },
  {
    "id_cnae": "1280",
    "subclasse": "9200-3/01",
    "denominacao": "Casas de bingo"
  },
  {
    "id_cnae": "1281",
    "subclasse": "9200-3/02",
    "denominacao": "Exploração de apostas em corridas de cavalos"
  },
  {
    "id_cnae": "1282",
    "subclasse": "9200-3/99",
    "denominacao": "Exploração de jogos de azar e apostas não especificados anteriormente"
  },
  {
    "id_cnae": "1283",
    "subclasse": "9311-5/00",
    "denominacao": "Gestão de instalações de esportes"
  },
  {
    "id_cnae": "1284",
    "subclasse": "9312-3/00",
    "denominacao": "Clubes sociais, esportivos e similares"
  },
  {
    "id_cnae": "1285",
    "subclasse": "9313-1/00",
    "denominacao": "Atividades de condicionamento físico"
  },
  {
    "id_cnae": "1286",
    "subclasse": "9319-1/01",
    "denominacao": "Produção e promoção de eventos esportivos"
  },
  {
    "id_cnae": "1287",
    "subclasse": "9319-1/99",
    "denominacao": "Outras atividades esportivas não especificadas anteriormente"
  },
  {
    "id_cnae": "1288",
    "subclasse": "9321-2/00",
    "denominacao": "Parques de diversão e parques temáticos"
  },
  {
    "id_cnae": "1289",
    "subclasse": "9329-8/01",
    "denominacao": "Discotecas, danceterias, salões de dança e similares"
  },
  {
    "id_cnae": "1290",
    "subclasse": "9329-8/02",
    "denominacao": "Exploração de boliches"
  },
  {
    "id_cnae": "1291",
    "subclasse": "9329-8/03",
    "denominacao": "Exploração de jogos de sinuca, bilhar e similares"
  },
  {
    "id_cnae": "1292",
    "subclasse": "9329-8/04",
    "denominacao": "Exploração de jogos eletrônicos recreativos"
  },
  {
    "id_cnae": "1293",
    "subclasse": "9329-8/99",
    "denominacao": "Outras atividades de recreação e lazer não especificadas anteriormente"
  },
  {
    "id_cnae": "1294",
    "subclasse": "9411-1/00",
    "denominacao": "Atividades de organizações associativas patronais e empresariais"
  },
  {
    "id_cnae": "1295",
    "subclasse": "9412-0/01",
    "denominacao": "Atividades de fiscalização profissional"
  },
  {
    "id_cnae": "1296",
    "subclasse": "9412-0/99",
    "denominacao": "Outras atividades associativas profissionais"
  },
  {
    "id_cnae": "1297",
    "subclasse": "9420-1/00",
    "denominacao": "Atividades de organizações sindicais"
  },
  {
    "id_cnae": "1298",
    "subclasse": "9430-8/00",
    "denominacao": "Atividades de associações de defesa de direitos sociais"
  },
  {
    "id_cnae": "1299",
    "subclasse": "9491-0/00",
    "denominacao": "Atividades de organizações religiosas ou filosóficas"
  },
  {
    "id_cnae": "1300",
    "subclasse": "9492-8/00",
    "denominacao": "Atividades de organizações políticas"
  },
  {
    "id_cnae": "1301",
    "subclasse": "9493-6/00",
    "denominacao": "Atividades de organizações associativas ligadas à cultura e à arte"
  },
  {
    "id_cnae": "1302",
    "subclasse": "9499-5/00",
    "denominacao": "Atividades associativas não especificadas anteriormente"
  },
  {
    "id_cnae": "1303",
    "subclasse": "9511-8/00",
    "denominacao": "Reparação e manutenção de computadores e de equipamentos periféricos"
  },
  {
    "id_cnae": "1304",
    "subclasse": "9512-6/00",
    "denominacao": "Reparação e manutenção de equipamentos de comunicação"
  },
  {
    "id_cnae": "1305",
    "subclasse": "9521-5/00",
    "denominacao": "Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico"
  },
  {
    "id_cnae": "1306",
    "subclasse": "9529-1/01",
    "denominacao": "Reparação de calçados, bolsas e artigos de viagem"
  },
  { "id_cnae": "1307", "subclasse": "9529-1/02", "denominacao": "Chaveiros" },
  {
    "id_cnae": "1308",
    "subclasse": "9529-1/03",
    "denominacao": "Reparação de relógios"
  },
  {
    "id_cnae": "1309",
    "subclasse": "9529-1/04",
    "denominacao": "Reparação de bicicletas, triciclos e outros veículos não motorizados"
  },
  {
    "id_cnae": "1310",
    "subclasse": "9529-1/05",
    "denominacao": "Reparação de artigos do mobiliário"
  },
  {
    "id_cnae": "1311",
    "subclasse": "9529-1/06",
    "denominacao": "Reparação de jóias"
  },
  {
    "id_cnae": "1312",
    "subclasse": "9529-1/99",
    "denominacao": "Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente"
  },
  { "id_cnae": "1313", "subclasse": "9601-7/01", "denominacao": "Lavanderias" },
  { "id_cnae": "1314", "subclasse": "9601-7/02", "denominacao": "Tinturarias" },
  { "id_cnae": "1315", "subclasse": "9601-7/03", "denominacao": "Toalheiros" },
  {
    "id_cnae": "1316",
    "subclasse": "9602-5/01",
    "denominacao": "Cabeleireiros, manicure e pedicure"
  },
  {
    "id_cnae": "1317",
    "subclasse": "9602-5/02",
    "denominacao": "Atividades de estética e outros serviços de cuidados com a beleza"
  },
  {
    "id_cnae": "1318",
    "subclasse": "9603-3/01",
    "denominacao": "Gestão e manutenção de cemitérios"
  },
  {
    "id_cnae": "1319",
    "subclasse": "9603-3/02",
    "denominacao": "Serviços de cremação"
  },
  {
    "id_cnae": "1320",
    "subclasse": "9603-3/03",
    "denominacao": "Serviços de sepultamento"
  },
  {
    "id_cnae": "1321",
    "subclasse": "9603-3/04",
    "denominacao": "Serviços de funerárias"
  },
  {
    "id_cnae": "1322",
    "subclasse": "9603-3/05",
    "denominacao": "Serviços de somatoconservação"
  },
  {
    "id_cnae": "1323",
    "subclasse": "9603-3/99",
    "denominacao": "Atividades funerárias e serviços relacionados não especificados anteriormente"
  },
  {
    "id_cnae": "1324",
    "subclasse": "9609-2/02",
    "denominacao": "Agências matrimoniais"
  },
  {
    "id_cnae": "1325",
    "subclasse": "9609-2/04",
    "denominacao": "Exploração de máquinas de serviços pessoais acionadas por moeda"
  },
  {
    "id_cnae": "1326",
    "subclasse": "9609-2/05",
    "denominacao": "Atividades de sauna e banhos"
  },
  {
    "id_cnae": "1327",
    "subclasse": "9609-2/06",
    "denominacao": "Serviços de tatuagem e colocação de piercing"
  },
  {
    "id_cnae": "1328",
    "subclasse": "9609-2/07",
    "denominacao": "Alojamento de animais domésticos"
  },
  {
    "id_cnae": "1329",
    "subclasse": "9609-2/08",
    "denominacao": "Higiene e embelezamento de animais domésticos"
  },
  {
    "id_cnae": "1330",
    "subclasse": "9609-2/99",
    "denominacao": "Outras atividades de serviços pessoais não especificadas anteriormente"
  },
  {
    "id_cnae": "1331",
    "subclasse": "9700-5/00",
    "denominacao": "Serviços domésticos"
  }
]
