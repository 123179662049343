import React, {useState} from 'react'
import styles from '../criteriosCfm/CriteriosCfm.module.css'

export const CriteriosCfm = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ criteriosCfm, setCriteriosCfm] = useState([])
   
    const adicionarCriteriosCfm = (e) => {

        e.preventDefault()

        const arrCriteriosCfm = []
    
        arrCriteriosCfm.push({
            perguntaCriteriosCfm0: "Natureza da exposição: o “agente patogênico” é claramente identificável pela história ocupacional e/ou pelas informações colhidas no local de trabalho e/ou de fontes idôneas familiarizadas com o ambiente ou local de trabalho do Segurado?",
            respostaCriteriosCfm0: formValues.respostaCriteriosCfm0 !== undefined ? formValues.respostaCriteriosCfm0 : '',
            comentarioCriteriosCfm0: formValues.comentarioCriteriosCfm0 !== undefined ? formValues.comentarioCriteriosCfm0 : '',
            perguntaCriteriosCfm1: "Especificidade” da relação causal e “força” da associação causal: o “agente patogênico” ou o “fator de risco” podem estar pesando de forma importante entre os fatores causais da doença?",
            respostaCriteriosCfm1: formValues.respostaCriteriosCfm1 !== undefined ? formValues.respostaCriteriosCfm1 : '',
            comentarioCriteriosCfm1: formValues.comentarioCriteriosCfm1 !== undefined ? formValues.comentarioCriteriosCfm1 : '',
            perguntaCriteriosCfm2: "Tipo de relação causal com o trabalho: o trabalho é causa necessária (Tipo I)? Fator de risco contributivo de doença de  etiologia multicausal (Tipo II)? Fator desencadeante ou agravante de doença pré-existente (Tipo III)?",
            respostaCriteriosCfm2: formValues.respostaCriteriosCfm2 !== undefined ? formValues.respostaCriteriosCfm2 : '',
            comentarioCriteriosCfm2: formValues.comentarioCriteriosCfm2 !== undefined ? formValues.comentarioCriteriosCfm2 : '',
            perguntaCriteriosCfm3: "No caso de doenças relacionadas com o trabalho, do tipo II, foram as outras causas gerais, não ocupacionais, devidamente analisadas e, no caso concreto, excluídas ou colocadas em hierarquia inferior às causas de natureza ocupacional?",
            respostaCriteriosCfm3: formValues.respostaCriteriosCfm3 !== undefined ? formValues.respostaCriteriosCfm3 : '',
            comentarioCriteriosCfm3: formValues.comentarioCriteriosCfm3 !== undefined ? formValues.comentarioCriteriosCfm3 : '',
            perguntaCriteriosCfm4: "Grau ou intensidade da exposição: é ele compatível com a produção da doença?",
            respostaCriteriosCfm4: formValues.respostaCriteriosCfm4 !== undefined ? formValues.respostaCriteriosCfm4 : '',
            comentarioCriteriosCfm4: formValues.comentarioCriteriosCfm4 !== undefined ? formValues.comentarioCriteriosCfm4 : '',
            perguntaCriteriosCfm5: "Tempo de exposição: é ele suficiente para produzir a doença?",
            respostaCriteriosCfm5: formValues.respostaCriteriosCfm5 !== undefined ? formValues.respostaCriteriosCfm5 : '',
            comentarioCriteriosCfm5: formValues.comentarioCriteriosCfm5 !== undefined ? formValues.comentarioCriteriosCfm5 : '',
            perguntaCriteriosCfm6: "Tempo de latência: é suficiente para que a doença se desenvolva e apareça?",
            respostaCriteriosCfm6: formValues.respostaCriteriosCfm6 !== undefined ? formValues.respostaCriteriosCfm6 : '',
            comentarioCriteriosCfm6: formValues.comentarioCriteriosCfm6 !== undefined ? formValues.comentarioCriteriosCfm6 : '',
            perguntaCriteriosCfm7: "Há o registro do “estado anterior” do trabalhador segurado?",
            respostaCriteriosCfm7: formValues.respostaCriteriosCfm7 !== undefined ? formValues.respostaCriteriosCfm7 : '',
            comentarioCriteriosCfm7: formValues.comentarioCriteriosCfm7 !== undefined ? formValues.comentarioCriteriosCfm7 : '', 
            perguntaCriteriosCfm8: "O conhecimento do “estado anterior” favorece o estabelecimento do nexo causal entre o “estado atual” e o trabalho?",
            respostaCriteriosCfm8: formValues.respostaCriteriosCfm8 !== undefined ? formValues.respostaCriteriosCfm8 : '',
            comentarioCriteriosCfm8: formValues.comentarioCriteriosCfm8 !== undefined ? formValues.comentarioCriteriosCfm8 : '',
            perguntaCriteriosCfm9: "Existem outras evidências epidemiológicas que reforçam a hipótese de relação causal entre a doença e o trabalho presente ou pregresso do segurado?",
            respostaCriteriosCfm9: formValues.respostaCriteriosCfm9 !== undefined ? formValues.respostaCriteriosCfm9 : '',
            comentarioCriteriosCfm9: formValues.comentarioCriteriosCfm9 !== undefined ? formValues.comentarioCriteriosCfm9 : '',
            perguntaCriteriosCfm10: "conclusaoCriteriosCfm",
            respostaCriteriosCfm10: formValues.respostaCriteriosCfm10 !== undefined ? formValues.respostaCriteriosCfm10 : '',
            comentarioCriteriosCfm10: formValues.comentarioCriteriosCfm10 !== undefined ? formValues.comentarioCriteriosCfm10 : '',    
        })
        setCriteriosCfm([...arrCriteriosCfm])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _60_criteriosCfm: [{
                perguntaCriteriosCfm0: "Natureza da exposição: o “agente patogênico” é claramente identificável pela história ocupacional e/ou pelas informações colhidas no local de trabalho e/ou de fontes idôneas familiarizadas com o ambiente ou local de trabalho do Segurado?",
            respostaCriteriosCfm0: formValues.respostaCriteriosCfm0 !== undefined ? formValues.respostaCriteriosCfm0 : '',
            comentarioCriteriosCfm0: formValues.comentarioCriteriosCfm0 !== undefined ? formValues.comentarioCriteriosCfm0 : '',
            perguntaCriteriosCfm1: "Especificidade” da relação causal e “força” da associação causal: o “agente patogênico” ou o “fator de risco” podem estar pesando de forma importante entre os fatores causais da doença?",
            respostaCriteriosCfm1: formValues.respostaCriteriosCfm1 !== undefined ? formValues.respostaCriteriosCfm1 : '',
            comentarioCriteriosCfm1: formValues.comentarioCriteriosCfm1 !== undefined ? formValues.comentarioCriteriosCfm1 : '',
            perguntaCriteriosCfm2: "Tipo de relação causal com o trabalho: o trabalho é causa necessária (Tipo I)? Fator de risco contributivo de doença de  etiologia multicausal (Tipo II)? Fator desencadeante ou agravante de doença pré-existente (Tipo III)?",
            respostaCriteriosCfm2: formValues.respostaCriteriosCfm2 !== undefined ? formValues.respostaCriteriosCfm2 : '',
            comentarioCriteriosCfm2: formValues.comentarioCriteriosCfm2 !== undefined ? formValues.comentarioCriteriosCfm2 : '',
            perguntaCriteriosCfm3: "No caso de doenças relacionadas com o trabalho, do tipo II, foram as outras causas gerais, não ocupacionais, devidamente analisadas e, no caso concreto, excluídas ou colocadas em hierarquia inferior às causas de natureza ocupacional?",
            respostaCriteriosCfm3: formValues.respostaCriteriosCfm3 !== undefined ? formValues.respostaCriteriosCfm3 : '',
            comentarioCriteriosCfm3: formValues.comentarioCriteriosCfm3 !== undefined ? formValues.comentarioCriteriosCfm3 : '',
            perguntaCriteriosCfm4: "Grau ou intensidade da exposição: é ele compatível com a produção da doença?",
            respostaCriteriosCfm4: formValues.respostaCriteriosCfm4 !== undefined ? formValues.respostaCriteriosCfm4 : '',
            comentarioCriteriosCfm4: formValues.comentarioCriteriosCfm4 !== undefined ? formValues.comentarioCriteriosCfm4 : '',
            perguntaCriteriosCfm5: "Tempo de exposição: é ele suficiente para produzir a doença?",
            respostaCriteriosCfm5: formValues.respostaCriteriosCfm5 !== undefined ? formValues.respostaCriteriosCfm5 : '',
            comentarioCriteriosCfm5: formValues.comentarioCriteriosCfm5 !== undefined ? formValues.comentarioCriteriosCfm5 : '',
            perguntaCriteriosCfm6: "Tempo de latência: é suficiente para que a doença se desenvolva e apareça?",
            respostaCriteriosCfm6: formValues.respostaCriteriosCfm6 !== undefined ? formValues.respostaCriteriosCfm6 : '',
            comentarioCriteriosCfm6: formValues.comentarioCriteriosCfm6 !== undefined ? formValues.comentarioCriteriosCfm6 : '',
            perguntaCriteriosCfm7: "Há o registro do “estado anterior” do trabalhador segurado?",
            respostaCriteriosCfm7: formValues.respostaCriteriosCfm7 !== undefined ? formValues.respostaCriteriosCfm7 : '',
            comentarioCriteriosCfm7: formValues.comentarioCriteriosCfm7 !== undefined ? formValues.comentarioCriteriosCfm7 : '',
            perguntaCriteriosCfm8: "O conhecimento do “estado anterior” favorece o estabelecimento do nexo causal entre o “estado atual” e o trabalho?",
            respostaCriteriosCfm8: formValues.respostaCriteriosCfm8 !== undefined ? formValues.respostaCriteriosCfm8 : '',
            comentarioCriteriosCfm8: formValues.comentarioCriteriosCfm8 !== undefined ? formValues.comentarioCriteriosCfm8 : '',
            perguntaCriteriosCfm9: "Existem outras evidências epidemiológicas que reforçam a hipótese de relação causal entre a doença e o trabalho presente ou pregresso do segurado?",
            respostaCriteriosCfm9: formValues.respostaCriteriosCfm9 !== undefined ? formValues.respostaCriteriosCfm9 : '',
            comentarioCriteriosCfm9: formValues.comentarioCriteriosCfm9 !== undefined ? formValues.comentarioCriteriosCfm9 : '',
            perguntaCriteriosCfm10: "conclusaoCriteriosCfm",
            respostaCriteriosCfm10: formValues.respostaCriteriosCfm10 !== undefined ? formValues.respostaCriteriosCfm10 : '',
            comentarioCriteriosCfm10: formValues.comentarioCriteriosCfm10 !== undefined ? formValues.comentarioCriteriosCfm10 : '',
            }]
      }))
    }

    const excluirCriteriosCfm = (e) => {
        e.preventDefault()  

        setCriteriosCfm([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _60_criteriosCfm: []
              }))
    }

    const handleCarregarFormCriteriosCfm = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._60_criteriosCfm && tudo._60_criteriosCfm.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._60_criteriosCfm !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._60_criteriosCfm !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._60_criteriosCfm : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaCriteriosCfm0: e !== undefined && e.perguntaCriteriosCfm0 !== undefined ? e.perguntaCriteriosCfm0 : '',
            respostaCriteriosCfm0: e !== undefined &&  e.respostaCriteriosCfm0 !== undefined ? e.respostaCriteriosCfm0 : '',
            comentarioCriteriosCfm0: e !== undefined &&  e.comentarioCriteriosCfm0 !== undefined ? e.comentarioCriteriosCfm0 : '',
            perguntaCriteriosCfm1: e !== undefined && e.perguntaCriteriosCfm1 !== undefined ? e.perguntaCriteriosCfm1 : '',
            respostaCriteriosCfm1: e !== undefined &&  e.respostaCriteriosCfm1 !== undefined ? e.respostaCriteriosCfm1 : '',
            comentarioCriteriosCfm1: e !== undefined &&  e.comentarioCriteriosCfm1 !== undefined ? e.comentarioCriteriosCfm1 : '',
            perguntaCriteriosCfm2: e !== undefined && e.perguntaCriteriosCfm2 !== undefined ? e.perguntaCriteriosCfm2 : '',
            respostaCriteriosCfm2: e !== undefined &&  e.respostaCriteriosCfm2 !== undefined ? e.respostaCriteriosCfm2 : '',
            comentarioCriteriosCfm2: e !== undefined &&  e.comentarioCriteriosCfm2 !== undefined ? e.comentarioCriteriosCfm2 : '',
            perguntaCriteriosCfm3: e !== undefined && e.perguntaCriteriosCfm3 !== undefined ? e.perguntaCriteriosCfm3 : '',
            respostaCriteriosCfm3: e !== undefined &&  e.respostaCriteriosCfm3 !== undefined ? e.respostaCriteriosCfm3 : '',
            comentarioCriteriosCfm3: e !== undefined &&  e.comentarioCriteriosCfm3 !== undefined ? e.comentarioCriteriosCfm3 : '',
            perguntaCriteriosCfm4: e !== undefined && e.perguntaCriteriosCfm4 !== undefined ? e.perguntaCriteriosCfm4 : '',
            respostaCriteriosCfm4: e !== undefined &&  e.respostaCriteriosCfm4 !== undefined ? e.respostaCriteriosCfm4 : '',
            comentarioCriteriosCfm4: e !== undefined &&  e.comentarioCriteriosCfm4 !== undefined ? e.comentarioCriteriosCfm4 : '',
            perguntaCriteriosCfm5: e !== undefined && e.perguntaCriteriosCfm5 !== undefined ? e.perguntaCriteriosCfm5 : '',
            respostaCriteriosCfm5: e !== undefined &&  e.respostaCriteriosCfm5 !== undefined ? e.respostaCriteriosCfm5 : '',
            comentarioCriteriosCfm5: e !== undefined &&  e.comentarioCriteriosCfm5 !== undefined ? e.comentarioCriteriosCfm5 : '',
            perguntaCriteriosCfm6: e !== undefined && e.perguntaCriteriosCfm6 !== undefined ? e.perguntaCriteriosCfm6 : '',
            respostaCriteriosCfm6: e !== undefined &&  e.respostaCriteriosCfm6 !== undefined ? e.respostaCriteriosCfm6 : '',
            comentarioCriteriosCfm6: e !== undefined &&  e.comentarioCriteriosCfm6 !== undefined ? e.comentarioCriteriosCfm6 : '',
            perguntaCriteriosCfm7: e !== undefined && e.perguntaCriteriosCfm7 !== undefined ? e.perguntaCriteriosCfm7 : '',
            respostaCriteriosCfm7: e !== undefined &&  e.respostaCriteriosCfm7 !== undefined ? e.respostaCriteriosCfm7 : '',
            comentarioCriteriosCfm7: e !== undefined &&  e.comentarioCriteriosCfm7 !== undefined ? e.comentarioCriteriosCfm7 : '', 
            perguntaCriteriosCfm8: e !== undefined && e.perguntaCriteriosCfm8 !== undefined ? e.perguntaCriteriosCfm8 : '',
            respostaCriteriosCfm8: e !== undefined &&  e.respostaCriteriosCfm8 !== undefined ? e.respostaCriteriosCfm8 : '',
            comentarioCriteriosCfm8: e !== undefined &&  e.comentarioCriteriosCfm8 !== undefined ? e.comentarioCriteriosCfm8 : '',
            perguntaCriteriosCfm9: e !== undefined && e.perguntaCriteriosCfm9 !== undefined ? e.perguntaCriteriosCfm9 : '',
            respostaCriteriosCfm9: e !== undefined &&  e.respostaCriteriosCfm9 !== undefined ? e.respostaCriteriosCfm9 : '',
            comentarioCriteriosCfm9: e !== undefined &&  e.comentarioCriteriosCfm9 !== undefined ? e.comentarioCriteriosCfm9 : '',
            perguntaCriteriosCfm10: e !== undefined && e.perguntaCriteriosCfm10 !== undefined ? e.perguntaCriteriosCfm10 : '',
            respostaCriteriosCfm10: e !== undefined &&  e.respostaCriteriosCfm10 !== undefined ? e.respostaCriteriosCfm10 : '',
            comentarioCriteriosCfm10: e !== undefined &&  e.comentarioCriteriosCfm10 !== undefined ? e.comentarioCriteriosCfm10 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparCriteriosCfm = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaCriteriosCfm'+e.target.attributes.value.value]: '', ['comentarioCriteriosCfm'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxCriteriosCfm} />
            <label htmlFor={styles.checkboxCriteriosCfm}>
                <h2 className={styles.titulo}>Criterios de Cfm</h2>
            </label>
            <fieldset id={styles.conteudoCriteriosCfm}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="Natureza da exposição: o “agente patogênico” é claramente identificável pela história ocupacional e/ou pelas informações colhidas no local de trabalho e/ou de fontes idôneas familiarizadas com o ambiente ou local de trabalho do Segurado?"> 
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>Natureza da exposição: o “agente patogênico” é claramente identificável pela história ocupacional e/ou pelas informações colhidas no local de trabalho e/ou de fontes idôneas familiarizadas com o ambiente ou local de trabalho do Segurado?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm0 !== undefined ? formValues.respostaCriteriosCfm0 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm0 !== undefined ? formValues.respostaCriteriosCfm0 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm0 !== undefined ? formValues.respostaCriteriosCfm0 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm0 !== undefined ? formValues.respostaCriteriosCfm0 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm0"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm0 !== undefined ? formValues.comentarioCriteriosCfm0 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Especificidade” da relação causal e “força” da associação causal: o “agente patogênico” ou o “fator de risco” podem estar pesando de forma importante entre os fatores causais da doença?">
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'1'}></i></button></th>
                        <th>Especificidade” da relação causal e “força” da associação causal: o “agente patogênico” ou o “fator de risco” podem estar pesando de forma importante entre os fatores causais da doença?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm1 !== undefined ? formValues.respostaCriteriosCfm1 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm1 !== undefined ? formValues.respostaCriteriosCfm1 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm1 !== undefined ? formValues.respostaCriteriosCfm1 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm1 !== undefined ? formValues.respostaCriteriosCfm1 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm1"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm1 !== undefined ? formValues.comentarioCriteriosCfm1 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Tipo de relação causal com o trabalho: o trabalho é causa necessária (Tipo I)? Fator de risco contributivo de doença de  etiologia multicausal (Tipo II)? Fator desencadeante ou agravante de doença pré-existente (Tipo III)?">
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'2'}></i></button></th>
                        <th>Tipo de relação causal com o trabalho: o trabalho é causa necessária (Tipo I)? Fator de risco contributivo de doença de  etiologia multicausal (Tipo II)? Fator desencadeante ou agravante de doença pré-existente (Tipo III)?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm2 !== undefined ? formValues.respostaCriteriosCfm2 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm2 !== undefined ? formValues.respostaCriteriosCfm2 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm2 !== undefined ? formValues.respostaCriteriosCfm2 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm2 !== undefined ? formValues.respostaCriteriosCfm2 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm2"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm2 !== undefined ? formValues.comentarioCriteriosCfm2 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="No caso de doenças relacionadas com o trabalho, do tipo II, foram as outras causas gerais, não ocupacionais, devidamente analisadas e, no caso concreto, excluídas ou colocadas em hierarquia inferior às causas de natureza ocupacional?">
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'3'}></i></button></th>
                        <th>No caso de doenças relacionadas com o trabalho, do tipo II, foram as outras causas gerais, não ocupacionais, devidamente analisadas e, no caso concreto, excluídas ou colocadas em hierarquia inferior às causas de natureza ocupacional?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm3 !== undefined ? formValues.respostaCriteriosCfm3 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm3 !== undefined ? formValues.respostaCriteriosCfm3 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm3 !== undefined ? formValues.respostaCriteriosCfm3 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm3 !== undefined ? formValues.respostaCriteriosCfm3 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm3"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm3 !== undefined ? formValues.comentarioCriteriosCfm3 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Grau ou intensidade da exposição: é ele compatível com a produção da doença?">
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'4'}></i></button></th>
                        <th>Grau ou intensidade da exposição: é ele compatível com a produção da doença?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm4 !== undefined ? formValues.respostaCriteriosCfm4 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm4 !== undefined ? formValues.respostaCriteriosCfm4 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm4 !== undefined ? formValues.respostaCriteriosCfm4 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm4 !== undefined ? formValues.respostaCriteriosCfm4 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm4"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm4 !== undefined ? formValues.comentarioCriteriosCfm4 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Tempo de exposição: é ele suficiente para produzir a doença?">
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'5'}></i></button></th>
                        <th>Tempo de exposição: é ele suficiente para produzir a doença?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm5 !== undefined ? formValues.respostaCriteriosCfm5 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm5 !== undefined ? formValues.respostaCriteriosCfm5 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm5 !== undefined ? formValues.respostaCriteriosCfm5 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm5 !== undefined ? formValues.respostaCriteriosCfm5 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm5"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm5 !== undefined ? formValues.comentarioCriteriosCfm5 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Tempo de latência: é suficiente para que a doença se desenvolva e apareça?"> 
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'6'}></i></button></th>
                        <th>Tempo de latência: é suficiente para que a doença se desenvolva e apareça?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm6 !== undefined ? formValues.respostaCriteriosCfm6 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm6 !== undefined ? formValues.respostaCriteriosCfm6 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm6 !== undefined ? formValues.respostaCriteriosCfm6 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm6 !== undefined ? formValues.respostaCriteriosCfm6 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm6"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm6 !== undefined ? formValues.comentarioCriteriosCfm6 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Há o registro do “estado anterior” do trabalhador segurado?"> 
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'7'}></i></button></th>
                        <th>Há o registro do “estado anterior” do trabalhador segurado?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm7 !== undefined ? formValues.respostaCriteriosCfm7 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm7 !== undefined ? formValues.respostaCriteriosCfm7 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm7 !== undefined ? formValues.respostaCriteriosCfm7 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm7 !== undefined ? formValues.respostaCriteriosCfm7 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm7"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm7 !== undefined ? formValues.comentarioCriteriosCfm7 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="O conhecimento do “estado anterior” favorece o estabelecimento do nexo causal entre o “estado atual” e o trabalho?"> 
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'8'}></i></button></th>
                        <th>O conhecimento do “estado anterior” favorece o estabelecimento do nexo causal entre o “estado atual” e o trabalho?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm8 !== undefined ? formValues.respostaCriteriosCfm8 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm8 !== undefined ? formValues.respostaCriteriosCfm8 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm8 !== undefined ? formValues.respostaCriteriosCfm8 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm8 !== undefined ? formValues.respostaCriteriosCfm8 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm8"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm8 !== undefined ? formValues.comentarioCriteriosCfm8 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Existem outras evidências epidemiológicas que reforçam a hipótese de relação causal entre a doença e o trabalho presente ou pregresso do segurado?"> 
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'9'}></i></button></th>
                        <th>Existem outras evidências epidemiológicas que reforçam a hipótese de relação causal entre a doença e o trabalho presente ou pregresso do segurado?</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm9 !== undefined ? formValues.respostaCriteriosCfm9 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm9 !== undefined ? formValues.respostaCriteriosCfm9 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm9 !== undefined ? formValues.respostaCriteriosCfm9 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm9"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm9 !== undefined ? formValues.respostaCriteriosCfm9 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm9"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm9 !== undefined ? formValues.comentarioCriteriosCfm9 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="conclusaoCriteriosCfm"> 
                        <th><button onClick={handleLimparCriteriosCfm}><i className="fas fa-trash perigo" value={'10'}></i></button></th>
                        <th>conclusaoCriteriosCfm</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosCfm10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm10 !== undefined ? formValues.respostaCriteriosCfm10 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosCfm10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm10 !== undefined ? formValues.respostaCriteriosCfm10 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosCfm10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm10 !== undefined ? formValues.respostaCriteriosCfm10 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosCfm10"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosCfm10 !== undefined ? formValues.respostaCriteriosCfm10 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosCfm10"
                                placeholder="Comentários do criterio de Cfm"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosCfm10 !== undefined ? formValues.comentarioCriteriosCfm10 : ''}
                            />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarCriteriosCfm}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormCriteriosCfm}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados dos Critérios de Cfm</legend>
            {
            criteriosCfm.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            {/* <th>Ação</th> */}
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th>Comentário</th>
                        </tr>
                    </thead>
                        {criteriosCfm.length > 0 && criteriosCfm.map(({ 
                            perguntaCriteriosCfm0,
                            respostaCriteriosCfm0,
                            comentarioCriteriosCfm0,
                            perguntaCriteriosCfm1,
                            respostaCriteriosCfm1,
                            comentarioCriteriosCfm1,
                            perguntaCriteriosCfm2,
                            respostaCriteriosCfm2,
                            comentarioCriteriosCfm2,
                            perguntaCriteriosCfm3,
                            respostaCriteriosCfm3,
                            comentarioCriteriosCfm3,
                            perguntaCriteriosCfm4,
                            respostaCriteriosCfm4,
                            comentarioCriteriosCfm4,
                            perguntaCriteriosCfm5,
                            respostaCriteriosCfm5,
                            comentarioCriteriosCfm5,
                            perguntaCriteriosCfm6,
                            respostaCriteriosCfm6,
                            comentarioCriteriosCfm6,
                            perguntaCriteriosCfm7,
                            respostaCriteriosCfm7,
                            comentarioCriteriosCfm7,
                            perguntaCriteriosCfm8,
                            respostaCriteriosCfm8,
                            comentarioCriteriosCfm8,
                            perguntaCriteriosCfm9,
                            respostaCriteriosCfm9,
                            comentarioCriteriosCfm9,
                            perguntaCriteriosCfm10,
                            respostaCriteriosCfm10,
                            comentarioCriteriosCfm10,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaCriteriosCfm0}</th>
                                <th>{respostaCriteriosCfm0}</th>
                                <th>{comentarioCriteriosCfm0}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm1}</th>
                                <th>{respostaCriteriosCfm1}</th>
                                <th>{comentarioCriteriosCfm1}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm2}</th>
                                <th>{respostaCriteriosCfm2}</th>
                                <th>{comentarioCriteriosCfm2}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm3}</th>
                                <th>{respostaCriteriosCfm3}</th>
                                <th>{comentarioCriteriosCfm3}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm4}</th>
                                <th>{respostaCriteriosCfm4}</th>
                                <th>{comentarioCriteriosCfm4}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm5}</th>
                                <th>{respostaCriteriosCfm5}</th>
                                <th>{comentarioCriteriosCfm5}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm6}</th>
                                <th>{respostaCriteriosCfm6}</th>
                                <th>{comentarioCriteriosCfm6}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm7}</th>
                                <th>{respostaCriteriosCfm7}</th>
                                <th>{comentarioCriteriosCfm7}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm8}</th>
                                <th>{respostaCriteriosCfm8}</th>
                                <th>{comentarioCriteriosCfm8}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm9}</th>
                                <th>{respostaCriteriosCfm9}</th>
                                <th>{comentarioCriteriosCfm9}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosCfm10}</th>
                                <th>{respostaCriteriosCfm10}</th>
                                <th>{comentarioCriteriosCfm10}</th>
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirCriteriosCfm}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )   
}
