import React from "react"

import { DadosIniciaisProcesso } from "./dadosIniciaisProcesso/DadosIniciaisProcesso"
import { DadosPolo1 } from "./polo1/DadosPolo1"
import { BuscarApiCnpj } from "./buscarApiCnpj/BuscarApiCnpj"
import { DadosPolo2 } from "./polo2/DadosPolo2"
import { DocHonorarios } from "./docHonorarios/DocHonorarios"
import { DocReclamada } from "./docReclamada/DocReclamada"
import { DocReclamante } from "./docReclamante/DocReclamante"
import { DocSecVara } from "./docSecVara/DocSecVara"
import { AgendamentoPericia } from "./agendamento/AgendamentoPericia"
import { Outlet } from "react-router-dom"

export const DadosIniciais = () => {
  return (
    <>
          <Outlet>
          <DadosIniciaisProcesso />
          <DadosPolo1 />
          <BuscarApiCnpj/>
          <DadosPolo2 />
          <DocHonorarios />
          <DocReclamada />
          <DocReclamante />
          <DocSecVara />
          <AgendamentoPericia />
          </Outlet>
    </>
  );
};