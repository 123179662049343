export const NtepListaC = [
 {
  "intervaloCid10": "A15-A19",
  "cnae": "0810 1091 1411 1412 1533 1540 2330 3011 3701 3702 3811 3812 3821 3822 3839 3900"
 },
 {
  "intervaloCid10": "A15-A19",
  "cnae": "4120 4211 4213 4222 4223 4291 4299 4312 4321 4391 4399 4687 4711 4713 4721 4741"
 },
 {
  "intervaloCid10": "A15-A19",
  "cnae": "4742 4743 4744 4789 4921 4923 4924 4929 5611 7810 7820 7830 8121 8122 8129 8610"
 },
 {
  "intervaloCid10": "A15-A19",
  "cnae": "9420 9601"
 },
 {
  "intervaloCid10": "E10-E14",
  "cnae": "1091 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291"
 },
 {
  "intervaloCid10": "E10-E14",
  "cnae": "4292 4299 4313 4319 4329 4399 4721 4921 4922 4923 4924 4929 4930 5030 5231 5239"
 },
 {
  "intervaloCid10": "E10-E14",
  "cnae": "8011 8012 8020 8030 8121 8122 8129 8411 9420"
 },
 {
  "intervaloCid10": "F10-F19",
  "cnae": "0710 0990 1011 1012 1013 1220 1532 1622 1732 1733 2211 2330 2342 2451 2511 2512"
 },
 {
  "intervaloCid10": "F10-F19",
  "cnae": "2531 2539 2542 2543 2593 2814 2822 2840 2861 2866 2869 2920 2930 3101 3102 3329"
 },
 {
  "intervaloCid10": "F10-F19",
  "cnae": "3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4292 4299 4313"
 },
 {
  "intervaloCid10": "F10-F19",
  "cnae": "4319 4321 4329 4399 4520 4912 4921 5030 5212 5221 5222 5223 5229 5231 5232 5239"
 },
 {
  "intervaloCid10": "F10-F19",
  "cnae": "5250 5310 6423 7810 7820 7830 8121 8122 8129 8411 8423 8424 9420"
 },
 {
  "intervaloCid10": "F20-F29",
  "cnae": "0710 0990 1011 1012 1013 1031 1071 1321 1411 1412 2330 2342 2511 2543 2592 2861"
 },
 {
  "intervaloCid10": "F20-F29",
  "cnae": "2866 2869 2942 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223"
 },
 {
  "intervaloCid10": "F20-F29",
  "cnae": "4291 4292 4299 4312 4391 4399 4921 4922 4923 4924 4929 5212 5310 6423 7732 7810"
 },
 {
  "intervaloCid10": "F20-F29",
  "cnae": "7820 7830 8011 8012 8020 8030 8121 8122 8129 8423 9420"
 },
 {
  "intervaloCid10": "F30-F39",
  "cnae": "0710 0892 0990 1011 1012 1013 1031 1220 1311 1313 1314 1321 1330 1340 1351 1359"
 },
 {
  "intervaloCid10": "F30-F39",
  "cnae": "1411 1412 1413 1422 1531 1532 1540 2091 2123 2511 2710 2751 2861 2930 2945 3299"
 },
 {
  "intervaloCid10": "F30-F39",
  "cnae": "3600 4636 4711 4753 4756 4759 4762 4911 4912 4921 4922 4923 4924 4929 5111 5120"
 },
 {
  "intervaloCid10": "F30-F39",
  "cnae": "5221 5222 5223 5229 5310 5620 6110 6120 6130 6141 6142 6143 6190 6311 6422 6423"
 },
 {
  "intervaloCid10": "F30-F39",
  "cnae": "6431 6550 8121 8122 8129 8411 8413 8423 8424 8610 8711 8720 8730 8800"
 },
 {
  "intervaloCid10": "F40-F48",
  "cnae": "0710 0990 1311 1321 1351 1411 1412 1421 1532 2945 3600 4711 4753 4756 4759 4762"
 },
 {
  "intervaloCid10": "F40-F48",
  "cnae": "4911 4912 4921 4922 4923 4924 4929 5111 5120 5221 5222 5223 5229 5310 6110 6120"
 },
 {
  "intervaloCid10": "F40-F48",
  "cnae": "6130 6141 6142 6143 6190 6311 6422 6423 8011 8012 8020 8030 8121 8122 8129 8411"
 },
 {
  "intervaloCid10": "F40-F48",
  "cnae": "8423 8424 8610"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "0113 0210 0220 0810 1011 1012 1013 1321 1411 1412 1610 1621 1732 1733 1931 2330"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "2342 2511 2539 2861 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "4223 4291 4292 4299 4313 4319 4399 4921 4922 4923 4924 4929 4930 5212 8011 8012"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "8020 8030 8121 8122 8129"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "0155 1011 1012 1013 1062 1093 1095 1313 1351 1411 1412 1421 1529 1531 1532 1533"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "1539 1540 2063 2123 2211 2222 2223 2229 2349 2542 2593 2640 2710 2759 2944 2945"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "3240 3250 4711 5611 5612 5620 6110 6120 6130 6141 6142 6143 6190 6422 6423 8121"
 },
 {
  "intervaloCid10": "G40-G47",
  "cnae": "8122 8129 8610"
 },
 {
  "intervaloCid10": "H53-H54",
  "cnae": "0210 0220 0810 1071 1220 1610 1622 2330 2342 3701 3702 3811 3812 3821 3822 3839"
 },
 {
  "intervaloCid10": "H53-H54",
  "cnae": "3900 4120 4211 4212 4213 4222 4223 4291 4299 4312 4313 4319 4321 4329 4391 4399"
 },
 {
  "intervaloCid10": "H53-H54",
  "cnae": "4741 4742 4743 4744 4789 4921 4922 4923 4924 4929 4930 8011 8012 8020 8030 8121"
 },
 {
  "intervaloCid10": "H53-H54",
  "cnae": "8122 8129"
 },
 {
  "intervaloCid10": "I05-I09",
  "cnae": "4921"
 },
 {
  "intervaloCid10": "I10-I15",
  "cnae": "0111 1411 1412 4921 4922 4923 4924 4929 5111 5120"
 },
 {
  "intervaloCid10": "I20-I25",
  "cnae": "1621 4120 4211 4213 4221 4222 4223 4291 4299 4329 4399 4921 4922 4930 6110 6120"
 },
 {
  "intervaloCid10": "I20-I25",
  "cnae": "6130 6141 6142 6143 6190"
 },
 {
  "intervaloCid10": "I30-I52",
  "cnae": "0113 0210 0220 0810 1011 1012 1013 1061 1071 1411 1412 1610 1931 2029 2330 2342"
 },
 {
  "intervaloCid10": "I30-I52",
  "cnae": "3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4222 4223 4291 4292"
 },
 {
  "intervaloCid10": "I30-I52",
  "cnae": "4299 4312 4313 4319 4391 4399 4621 4622 4623 4921 4922 4923 4924 4929 4930 8121"
 },
 {
  "intervaloCid10": "I30-I52",
  "cnae": "8122 8129 8411 9420"
 },
 {
  "intervaloCid10": "I60-I69",
  "cnae": "0810 1071 2330 2342 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213"
 },
 {
  "intervaloCid10": "I60-I69",
  "cnae": "4222 4223 4291 4299 4312 4313 4319 4321 4391 4399 4921 4922 4923 4924 4929 4930"
 },
 {
  "intervaloCid10": "I60-I69",
  "cnae": "8112 8121 8122 8129 8411 8591 9200 9311 9312 9313 9319 9420"
 },
 {
  "intervaloCid10": "I80-I89",
  "cnae": "1011 1012 1013 1020 1031 1033 1091 1092 1220 1311 1321 1351 1411 1412 1413 1422"
 },
 {
  "intervaloCid10": "I80-I89",
  "cnae": "1510 1531 1532 1540 1621 1622 2123 2342 2542 2710 2813 2832 2833 2920 2930 2944"
 },
 {
  "intervaloCid10": "I80-I89",
  "cnae": "2945 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4621 4622 4623 4721"
 },
 {
  "intervaloCid10": "I80-I89",
  "cnae": "4722 4921 4922 5611 5612 5620 8011 8012 8020 8030 8121 8122 8129 8411 8610 9420"
 },
 {
  "intervaloCid10": "I80-I89",
  "cnae": "9491 9601"
 },
 {
  "intervaloCid10": "J40-J47",
  "cnae": "0810 1031 1220 1311 1321 1351 1411 1412 1610 1622 1629 2330 2342 2539 3101 3102"
 },
 {
  "intervaloCid10": "J40-J47",
  "cnae": "3329 4120 4211 4213 4292 4299 4313 4319 4399 4921 8121 8122 8129 8411"
 },
 {
  "intervaloCid10": "K35-K38",
  "cnae": "0810 1011 1012 1013 1071 1411 1412 1531 1540 1610 1621 1732 1733 2451 2511 2512"
 },
 {
  "intervaloCid10": "K35-K38",
  "cnae": "2832 2833 2930 3101 3329 4621 4622 4623 4921 4922 8610"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "0113 0210 0220 0230 0810 1011 1012 1013 1020 1031 1033 1041 1051 1061 1066 1071"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "1091 1122 1321 1354 1510 1610 1621 1622 1629 1722 1732 1733 1931 2211 2212 2219"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "2330 2341 2342 2349 2443 2449 2451 2511 2512 2521 2539 2541 2542 2543 2592 2593"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "2710 2815 2822 2832 2833 2861 2866 2869 2930 2943 2944 2945 3011 3101 3102 3329"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4212 4213 4221 4222 4223 4291"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "4292 4299 4312 4313 4319 4321 4329 4391 4399 4621 4622 4623 4632 4634 4687 4721"
 },
 {
  "intervaloCid10": "K40-K46",
  "cnae": "4722 4741 4742 4743 4744 4789 4921 4922 4930 5212 8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "L60-L75",
  "cnae": "8610"
 },
 {
  "intervaloCid10": "L80-L99",
  "cnae": "0113 1011 1012 1013 1071 1411 1412 1610 1621 1931 2451 5611 5620 8121 8122 8129"
 },
 {
  "intervaloCid10": "L80-L99",
  "cnae": "8610"
 },
 {
  "intervaloCid10": "L80-L99",
  "cnae": "0113 0131 0133 0210 0220 0810 0892 0910 1011 1012 1013 1020 1031 1033 1041 1051"
 },
 {
  "intervaloCid10": "L80-L99",
  "cnae": "1052 1061 1064 1071 1072 1091 1122 1220 1311 1321 1351 1354 1411 1412 1413 1532"
 },
 {
  "intervaloCid10": "L80-L99",
  "cnae": "1621 1732 1733 1931 2012 2019 2312 2330 2341 2342 2349 2431 2443 2449 2511 2522"
 },
 {
  "intervaloCid10": "L80-L99",
  "cnae": "2539 2543 2550 2710 2813 2815 2822 2852 2853 2854 2861 2862 2865 2866 2869 2920"
 },
 {
  "intervaloCid10": "M00-M25",
  "cnae": "2930 2944 2945 2950 3011 3102 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120"
 },
 {
  "intervaloCid10": "M00-M25",
  "cnae": "4211 4212 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399"
 },
 {
  "intervaloCid10": "M00-M25",
  "cnae": "4621 4622 4623 4636 4661 4711 4721 4921 4922 4923 4924 4929 4930 5012 5021 5212"
 },
 {
  "intervaloCid10": "M00-M25",
  "cnae": "5310 5611 5620 7719 8121 8122 8129 8411 8424 8430 8591 8610 9200 9311 9312 9313"
 },
 {
  "intervaloCid10": "M00-M25",
  "cnae": "9319 9420 9491 9601"
 },
 {
  "intervaloCid10": "M30-M36",
  "cnae": "1412 8121 8122 8129 8610"
 },
 {
  "intervaloCid10": "M30-M36",
  "cnae": "0113 0131 0133 0210 0220 0230 0500 0710 0810 0892 0910 0990 1011 1012 1013 1020"
 },
 {
  "intervaloCid10": "M30-M36",
  "cnae": "1031 1033 1041 1051 1052 1061 1062 1064 1071 1072 1092 1122 1311 1312 1321 1323"
 },
 {
  "intervaloCid10": "M30-M36",
  "cnae": "1340 1351 1354 1411 1412 1413 1421 1422 1510 1532 1610 1621 1622 1623 1629 1710"
 },
 {
  "intervaloCid10": "M30-M36",
  "cnae": "1721 1722 1732 1733 1931 2012 2019 2029 2040 2091 2093 2123 2211 2212 2219 2221"
 },
 {
  "intervaloCid10": "M30-M36",
  "cnae": "2222 2312 2320 2330 2341 2342 2349 2391 2431 2439 2441 2443 2449 2451 2511 2513"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "2521 2522 2539 2542 2543 2550 2592 2593 2710 2722 2733 2813 2815 2822 2832 2833 2852 2853 2854 2861 2862 2864 2866 2869 2920 2930 2942 2943 2944 2945 2950 3011"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "3101 3102 3240 3321 3329 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "4212 4213 4222 4223 4291 4292 4299 4311 4312 4313 4319 4321 4329 4391 4399 4621"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "4622 4623 4632 4636 4661 4681 4682 4685 4686 4687 4689 4921 4922 4923 4924 4929"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "4930 5012 5021 5211 5212 5221 5222 5223 5229 5310 5612 5620 6431 7719 7732 8121"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "8122 8129 8424 8430 8610 9420"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "0113 0155 0210 0220 1011 1012 1013 1020 1031 1033 1051 1052 1062 1064 1092 1093"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "1094 1095 1096 1099 1122 1311 1314 1321 1323 1340 1351 1352 1354 1359 1411 1412"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "1413 1414 1421 1510 1521 1529 1531 1532 1533 1540 1623 1732 1733 1742 1749 2040"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "2063 2091 2110 2121 2123 2211 2219 2221 2222 2223 2229 2312 2319 2342 2349 2439"
 },
 {
  "intervaloCid10": "M40-M54",
  "cnae": "2443 2449 2451 2531 2539 2541 2542 2543 2550 2591 2592 2593 2610 2631 2632 2640"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "2651 2710 2721 2722 2732 2733 2740 2751 2759 2813 2814 2815 2822 2823 2824 2840 2853 2854 2861 2864 2866 2869 2920 2930 2941 2942 2943 2944 2945 2949 3092 3101"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "3102 3104 3230 3240 3250 3291 3299 3316 3329 3701 3702 3811 3812 3821 3822 3839"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "3900 4221 4632 4634 4711 4713 4912 5111 5120 5212 5221 5222 5223 5229 5310 5320"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "5612 5620 6021 6022 6110 6120 6130 6141 6142 6143 6190 6209 6311 6399 6422 6423"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "6431 65507410 7490 7719 7733 8121 8122 8129 8211 8219 8220 8230 8291 8292 8299"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "8610 9420 9601"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "0210 0220 0230 0810 1011 1012 1013 1033 1041 1061 1071 1122 1321 1510 1532 1610"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "1621 1622 1732 1733 1931 2212 2330 2342 2391 2511 2512 2539 2542 2543 2593 2832"
 },
 {
  "intervaloCid10": "M60-M79",
  "cnae": "2833 2866 2869 2930 3011 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900"
 },
 {
  "intervaloCid10": "S00-S09",
  "cnae": "4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399"
 },
 {
  "intervaloCid10": "S00-S09",
  "cnae": "4520 4530 4541 4542 4621 4622 4623 4635 4671 4672 4673 4674 4679 4687 4731 4732"
 },
 {
  "intervaloCid10": "S00-S09",
  "cnae": "4741 4742 4743 4744 4789 4921 4922 4930 5212 5320 7810 7820 7830 8011 8012 8020"
 },
 {
  "intervaloCid10": "S00-S09",
  "cnae": "8030 8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "S00-S09",
  "cnae": "0113 0131 0133 0210 0220 0230 0810 1011 1012 1013 1071 1321 1510 1610 1621 1622"
 },
 {
  "intervaloCid10": "S00-S09",
  "cnae": "1629 1732 1733 1931 2330 2342 2512 2539 2543 2832 2833 2866 2869 3600 3701 3702"
 },
 {
  "intervaloCid10": "S20-S29",
  "cnae": "3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4321"
 },
 {
  "intervaloCid10": "S20-S29",
  "cnae": "4399 4621 4622 4623 4632 4687 4741 4742 4743 4744 4789 4921 4922 4930 5212 5310"
 },
 {
  "intervaloCid10": "S20-S29",
  "cnae": "8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "S20-S29",
  "cnae": "0131 0133 0210 0220 1011 1012 1013 1061 1071 1610 1621 2330 2342 2511 2512 3101"
 },
 {
  "intervaloCid10": "S30-S39",
  "cnae": "3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4299 4312 4313 4319 4321 4329 4391 4399 4621 4622 4623 4687 4722 4741 4742 4743"
 },
 {
  "intervaloCid10": "S30-S39",
  "cnae": "4744 4789 4921 4930 5212 5221 5222 5223 5229 7810 7820 7830 8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "S30-S39",
  "cnae": "0131 0133 0210 0220 0500 0810 1011 1012 1013 1031 1033 1041 1051 1061 1064 1071"
 },
 {
  "intervaloCid10": "S30-S39",
  "cnae": "1091 1122 1321 1351 1354 1411 1412 1510 1531 1532 1533 1540 1610 1621 1622 1623"
 },
 {
  "intervaloCid10": "S30-S39",
  "cnae": "1629 1722 1732 1733 1931 2212 2221 2222 2223 2229 2330 2342 2349 2391 2451 2511"
 },
 {
  "intervaloCid10": "S30-S39",
  "cnae": "2512 2539 2542 2543 2592 2593 2710 2813 2815 2822 2823 2832 2833 2861 2866 2869"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "2930 2944 2945 2950 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4329 4391 4399 4520"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "4530 4541 4542 4618 4621 4622 4623 4635 4661 4671 4672 4673 4674 4679 4687 4721"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "4722 4731 4732 4741 4742 4743 4744 4784 4789 4921 4922 4930 5212 5221 5222 5223"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "5229 5310 5320 7719 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "0210 0220 0810 1011 1012 1013 1031 1033 1041 1051 1061 1064 1071 1091 1092 1093"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "1096 1099 1122 1311 1321 1354 1411 1412 1510 1531 1532 1533 1540 1610 1621 1622"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "1623 1629 1722 1732 1733 2211 2221 2222 2223 2229 2330 2341 2342 2391 2511 2512"
 },
 {
  "intervaloCid10": "S40-S49",
  "cnae": "2539 2542 2543 2592 2593 2710 2759 2813 2822 2823 2832 2833 2861 2866 2869 2930"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "2944 2945 2950 3011 3101 3102 3329 3701 3702 3811 3812 3821 3822 3839 3900 4120"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4322 4329 4391 4399"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "4520 4621 4622 4623 4635 4661 4685 4686 4687 4689 4711 4721 4722 4741 4742 4743"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "4744 4784 4789 4921 4923 4924 4929 4930 5212 5221 5222 5223 5229 5310 5320 7719"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "0113 0210 0220 0500 0810 1011 1012 1013 1031 1033 1041 1042 1051 1052 1061 1062"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "1063 1064 1071 1072 1091 1092 1093 1094 1096 1099 1122 1311 1312 1321 1323 1340"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "1351 1353 1354 1359 1411 1412 1510 1529 1531 1532 1533 1540 1610 1621 1622 1623"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "1629 1710 1721 1722 1731 1732 1733 1741 1742 1749 1813 1931 2012 2019 2029 2061"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "2063 2091 2092 2123 2211 2212 2219 2221 2222 2223 2229 2311 2312 2319 2330 2341"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "2342 2349 2391 2392 2399 2431 2439 2441 2443 2449 2451 2452 2511 2512 2513 2521"
 },
 {
  "intervaloCid10": "S50-S59",
  "cnae": "2522 2531 2532 2539 2541 2542 2543 2550 2591 2592 2593 2599 2632 2651 2710 2721"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "2722 2732 2733 2740 2751 2759 2790 2811 2812 2813 2814 2815 2821 2822 2823 2824"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "2825 2829 2831 2832 2833 2840 2852 2853 2854 2861 2862 2864 2865 2866 2869 2920"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "2930 2941 2942 2943 2944 2945 2949 2950 3011 3012 3032 3091 3092 3099 3101 3102"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "3103 3104 3220 3230 3240 3250 3291 3299 3319 3329 3701 3702 3811 3812 3821 3822"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "3832 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319 4321"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "4322 4329 4391 4399 4520 4621 4622 4623 4632 4634 4661 4671 4672 4673 4674 4679"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "4681 4682 4685 46864687 4689 4711 4721 4722 4741 4742 4743 4744 4789 4930 5211"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "5212 5320 5819 5829 7719 7732 7810 7820 7830 8121 8122 8129 8423 9420 9529"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "0210 0220 1011 1012 1013 1033 1122 1610 1621 1622 2330 2391 2511 2512 2539 3101"
 },
 {
  "intervaloCid10": "S60-S69",
  "cnae": "3329 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "4299 4312 4321 4391 4399 4520 4530 4541 4542 4618 4687 4731 4732 4741 4742 4743"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "4744 4784 4789 4921 4930 5212 5221 5222 5223 5229 5232 5250 5320 7810 7820 7830"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "8011 8012 8020 8030 8121 8122 8129 9420"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "0210 0220 0230 0500 0710 0810 0990 1011 1012 1013 1031 1033 1041 1051 1061 1062"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "1064 1071 1072 1092 1096 1099 1122 1321 1351 1354 1411 1412 1510 1531 1532 1540"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "1610 1621 1622 1623 1629 1710 1721 1722 1732 1733 1931 2012 2019 2029 2073 2091"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "2211 2219 2222 2312 2320 2330 2341 2342 2391 2439 2443 2449 2451 2511 2512 2521"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "2522 2539 2542 2543 2550 2592 2593 2651 2710 2812 2813 2815 2821 2822 2823 2831"
 },
 {
  "intervaloCid10": "S70-S79",
  "cnae": "2832 2833 2840 2852 2854 2861 2862 2864 2865 2866 2869 2930 2943 2944 2945 2950"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "3011 3101 3102 3329 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211 4213"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "4221 4222 4223 4291 4292 4299 4312 4313 4319 4321 4322 4329 4391 4399 4520 4530"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "4541 4542 4618 4621 4622 4623 4632 4635 4636 4637 4639 4661 4671 4672 4673 4674"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "4679 4681 4682 4685 4686 4687 4689 4711 4722 4723 4731 4732 4741 4742 4743 4744"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "4784 4789 4912 4921 4922 4923 4924 4929 4930 5211 5212 5221 5222 5223 5229 5232"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "5250 5310 5320 7719 7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129 8423"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "8424 9420"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "0210 0220 0500 0810 1011 1012 1013 1031 1033 1041 1051 1061 1062 1064 1071 1072"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "1092 1093 1122 1311 1321 1351 1354 1411 1412 1510 1532 1610 1621 1622 1623 1629"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "1710 1721 1722 1732 1733 1931 2029 2091 2219 2221 2222 2312 2330 2341 2342 2391"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "2431 2439 2441 2443 2449 2451 2511 2512 2513 2521 2522 2531 2539 2542 2543 2592"
 },
 {
  "intervaloCid10": "S80-S89",
  "cnae": "2593 2710 2722 2815 2822 2831 2832 2833 2840 2852 2853 2854 2861 2862 28652866"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "2869 2920 2930 2943 2944 2945 2950 3011 3101 3102 3329 3600 3701 3702 3811 3812"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "3821 3822 3839 3900 4120 4211 4213 4221 4222 4223 4291 4292 4299 4312 4313 4319"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "4321 4322 4329 4391 4399 4621 4622 4623 4661 4681 4682 4685 4686 4687 4689"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "4711 4784 4912 4921 4922 4930 5111 5120 5212 5221 5222 5223 5229 5232 5250 5310"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "5320 6423 6431 6550 7719 7732 7810 7820 7830 8011 8012 8020 8030 8121 8122 8129"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "8423 8424 8610 9420"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "0210 0220 0710 0810 0892 0910 1011 1013 1020 1031 1033 1041 1042 1061 1062 1071"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "1072 1091 1092 1093 1122 1220 1311 1312 1321 1351 1352 1353 1411 1412 1510 1531"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "1532 1533 1540 1610 1621 1622 1629 1733 1932 2014 2019 2029 2032 2091 2211 2221"
 },
 {
  "intervaloCid10": "S90-S99",
  "cnae": "2223 2229 2312 2320 2330 2341 2342 2391 2451 2511 2512 2521 2522 2539 2542 2592"
 },
 {
  "intervaloCid10": "T90-T98",
  "cnae": "2593 2640 2740 2751 2790 2813 2814 2822 2862 2864 2866 2869 2920 2930 2944 2945 2950 3091 3092 3101 3102 3600 3701 3702 3811 3812 3821 3822 3839 3900 4120 4211"
 },
 {
  "intervaloCid10": "T90-T98",
  "cnae": "4213 4221 4291 4292 4299 4312 4313 4319 4321 4322 4391 4399 4635 4661 4681 4682"
 },
 {
  "intervaloCid10": "T90-T98",
  "cnae": "4687 4721 4741 4743 4744 4784 4922 4923 4924 4929 4930 5012 5021 5030 5212 5221"
 },
 {
  "intervaloCid10": "T90-T98",
  "cnae": "5222 5223 5229 5231 5232 5239 5250 5310 5320 7719 7732 8011 8012 8020 8030 8121"
 },
 {
  "intervaloCid10": "T90-T98",
  "cnae": "8122 9420"
 }
]