import React, {useState} from 'react'
import styles from '../deficitFuncionalTemporario/DeficitFuncionalTemporario.module.css'

export const DeficitFuncionalTemporario = () => {
    const [formValues, setFormValues] = useState({})
    
        const handleInputChange = (e) => {
            const { name, value, type } = e.target;
        
            const data = formValues[name] || {};
            const isCheckbox = type === 'checkbox'
            const newValue = isCheckbox  ? data : value;
            
            setFormValues({...formValues,  [name]: newValue  });
           // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
        }
    
        const [ deficitFuncionalTemporario, setDeficitFuncionalTemporario] = useState([])
       
        const adicionarDeficitFuncionalTemporario = (e) => {
    
            e.preventDefault()
    
            const arrDeficitFuncionalTemporario = []
        
            arrDeficitFuncionalTemporario.push({
                perguntaDeficitFuncionalTemporario0: "O período de déficit total corresponde a interrupção completa das atividades pessoais (hospitalização e convalescença), nos quais a vítima é confinada a sua moradia recorrendo-se normalmente a necessidade de terceira pessoa. O déficit temporário parcial é aquele onde já existe um retorno à suas atividades habituais, porém parcialmente, indo até a data da consolidação da lesão (com cura total ou com sequela não passível mais de melhora.",
                respostaDeficitFuncionalTemporario0: formValues.respostaDeficitFuncionalTemporario0 !== undefined ? formValues.respostaDeficitFuncionalTemporario0 : '',
               // comentarioDeficitFuncionalTemporario0: formValues.comentarioDeficitFuncionalTemporario0 !== undefined ? formValues.comentarioDeficitFuncionalTemporario0 : '',     
            })
            setDeficitFuncionalTemporario([...arrDeficitFuncionalTemporario])
    
            localStorage.setItem('tudo', JSON.stringify({
                ...JSON.parse(localStorage.getItem('tudo')) || [],
                _65_deficitFuncionalTemporario: [{
                    perguntaDeficitFuncionalTemporario0: "O período de déficit total corresponde a interrupção completa das atividades pessoais (hospitalização e convalescença), nos quais a vítima é confinada a sua moradia recorrendo-se normalmente a necessidade de terceira pessoa. O déficit temporário parcial é aquele onde já existe um retorno à suas atividades habituais, porém parcialmente, indo até a data da consolidação da lesão (com cura total ou com sequela não passível mais de melhora.",
                respostaDeficitFuncionalTemporario0: formValues.respostaDeficitFuncionalTemporario0 !== undefined ? formValues.respostaDeficitFuncionalTemporario0 : '',
               // comentarioDeficitFuncionalTemporario0: formValues.comentarioDeficitFuncionalTemporario0 !== undefined ? formValues.comentarioDeficitFuncionalTemporario0 : '',
                }]
          }))
        }
    
        const excluirDeficitFuncionalTemporario = (e) => {
            e.preventDefault()  
    
            setDeficitFuncionalTemporario([])
    
                    localStorage.setItem('tudo', JSON.stringify({
                        ...JSON.parse(localStorage.getItem('tudo')) || [],
                        _65_deficitFuncionalTemporario: []
                  }))
        }
    
        const handleCarregarFormDeficitFuncionalTemporario = () => {
            
            const tudo = JSON.parse(localStorage.getItem('tudo'))
            if (tudo._65_deficitFuncionalTemporario && tudo._65_deficitFuncionalTemporario.length > 0) {
                const a = JSON.parse(localStorage.getItem('tudo'))._65_deficitFuncionalTemporario !== undefined
                ||JSON.parse(localStorage.getItem('tudo'))._65_deficitFuncionalTemporario !== null ? 
                JSON.parse(localStorage.getItem('tudo'))._65_deficitFuncionalTemporario : []
        
                //console.log('***',a)
            a.map((e)=> 
                setFormValues({
        perguntaDeficitFuncionalTemporario0: e !== undefined && e.perguntaDeficitFuncionalTemporario0 !== undefined ? e.perguntaDeficitFuncionalTemporario0 : '',
                respostaDeficitFuncionalTemporario0: e !== undefined &&  e.respostaDeficitFuncionalTemporario0 !== undefined ? e.respostaDeficitFuncionalTemporario0 : '',
                //comentarioDeficitFuncionalTemporario0: e !== undefined &&  e.comentarioDeficitFuncionalTemporario0 !== undefined ? e.comentarioDeficitFuncionalTemporario0 : '',
            }) 
        )  
    }
    
    }
    
        const handleReset = () => {
            setFormValues({})
        }
    
        const handleLimparDeficitFuncionalTemporario = (e) => {
            e.preventDefault()
            if (e.target.attributes.value) {
                setFormValues({...formValues,  ['respostaDeficitFuncionalTemporario'+e.target.attributes.value.value]: '', ['comentarioDeficitFuncionalTemporario'+e.target.attributes.value.value]: ''})
            }
        }
    
    
        return (
        <>
        <fieldset>
            <legend></legend>
            <input type="checkbox" id={styles.checkboxDeficitFuncionalTemporario} />
                <label htmlFor={styles.checkboxDeficitFuncionalTemporario}>
                    <h2 className={styles.titulo}>Déficit Funcional Temporário</h2>
                </label>
                <fieldset id={styles.conteudoDeficitFuncionalTemporario}>  
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Ação</th>
                            <th>Pergunta</th>
                            <th>Resposta</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key="O período de déficit total corresponde a interrupção completa das atividades pessoais (hospitalização e convalescença), nos quais a vítima é confinada a sua moradia recorrendo-se normalmente a necessidade de terceira pessoa. O déficit temporário parcial é aquele onde já existe um retorno à suas atividades habituais, porém parcialmente, indo até a data da consolidação da lesão (com cura total ou com sequela não passível mais de melhora."> 
                            <th><button onClick={handleLimparDeficitFuncionalTemporario}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                            <th>O período de déficit total corresponde a interrupção completa das atividades pessoais (hospitalização e convalescença), nos quais a vítima é confinada a sua moradia recorrendo-se normalmente a necessidade de terceira pessoa. O déficit temporário parcial é aquele onde já existe um retorno à suas atividades habituais, porém parcialmente, indo até a data da consolidação da lesão (com cura total ou com sequela não passível mais de melhora.</th>
                            <th>
                            <textarea 
                                name="respostaDeficitFuncionalTemporario0"  
                                cols="30" 
                                rows="10" 
                                placeholder="Digite sua valoração aqui."
                                onChange={handleInputChange}
                                value={formValues.respostaDeficitFuncionalTemporario0 !== undefined ? formValues.respostaDeficitFuncionalTemporario0 : ''}
                            />
                            </th>
                        </tr>                    
                    </tbody>
                </table>
                <div>
                <button onClick={adicionarDeficitFuncionalTemporario}><i className="fas fa-plus"></i></button>
                <button onClick={handleCarregarFormDeficitFuncionalTemporario}>Carregar formulário</button>
                <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
                </div>
            <fieldset>
                <legend>Dados registrados do déficit funcional temporário</legend>
                {
                deficitFuncionalTemporario.length === 0 ? <p>Sem dados registrados</p> : 
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Pergunta</th>
                                <th>Resposta</th>
                               {/*  <th>Comentário</th> */}
                            </tr>
                        </thead>
                            {deficitFuncionalTemporario.length > 0 && deficitFuncionalTemporario.map(({ 
                                perguntaDeficitFuncionalTemporario0,
                                respostaDeficitFuncionalTemporario0,
                               // comentarioDeficitFuncionalTemporario0,
                            }, i)=>{
    
                            return(
                            
                                <tbody key={i}>
                                    
                                <tr >
                                    <th>{perguntaDeficitFuncionalTemporario0}</th>
                                    <th>{respostaDeficitFuncionalTemporario0}</th>
                                   {/*  <th>{comentarioDeficitFuncionalTemporario0}</th> */}
                                </tr>
                                </tbody>
                                
                        )})}
                        
                        </table>
                        <div>
                        <button onClick={excluirDeficitFuncionalTemporario}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                        </div>
                    </>
                    }
                </fieldset>
                </fieldset>
            </fieldset>
            </>
            ) 
}