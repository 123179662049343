export const ValoracaoDanoMao = [
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo amputação ao nível de metade da falange distal",
    "baremoEuropeu": "6.5",
    "susep": "9",
    "dpvat": "12.5",
    "baremoArgentino": "8",
    "baremoPortugues": "5,00 a 10,00",
    "louisMelennec": "12",
    "baremoEspanhol": "8,00 a 10,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo limitação na mobilização falange distal",
    "baremoEuropeu": "3",
    "susep": "4.5",
    "dpvat": "6.25",
    "baremoArgentino": "0",
    "baremoPortugues": "2,00 a 4,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo amputação ao nível falange distal completa",
    "baremoEuropeu": "10",
    "susep": "9",
    "dpvat": "12.5",
    "baremoArgentino": "15",
    "baremoPortugues": "5,00 a 10,00",
    "louisMelennec": "12",
    "baremoEspanhol": "8,00 a 10,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez falange distal em extensão",
    "baremoEuropeu": "5",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez falange distal em flexão",
    "baremoEuropeu": "6.5",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez falange distal em posição funcional",
    "baremoEuropeu": "3",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo amputação ao nível de metade da falange proximal",
    "baremoEuropeu": "13",
    "susep": "18",
    "dpvat": "25",
    "baremoArgentino": "25",
    "baremoPortugues": "11,00 a 15,00",
    "louisMelennec": "15",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo amputação ao nível de falange proximal completa",
    "baremoEuropeu": "18",
    "susep": "18",
    "dpvat": "25",
    "baremoArgentino": "25",
    "baremoPortugues": "11,00 a 15,00",
    "louisMelennec": "15",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo limitação na mobilização ao nível articulação metacarpo-falangiana",
    "baremoEuropeu": "3.5",
    "susep": "9",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação metacarpo-falangiana em posição funcional",
    "baremoEuropeu": "3.5",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação metacarpo-falangiana em extensão",
    "baremoEuropeu": "5",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação metacarpo-falangiana em flexão",
    "baremoEuropeu": "4",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo amputação ao nível de metade do metacarpo",
    "baremoEuropeu": "20.5",
    "susep": "25",
    "dpvat": "25",
    "baremoArgentino": "30",
    "baremoPortugues": "17,00 a 20,00",
    "louisMelennec": "20",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo limitação na mobilização ao nível articulação trapézio-metacarpiana em posição funcional",
    "baremoEuropeu": "7",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo amputação ao nível do metacarpo completo",
    "baremoEuropeu": "22",
    "susep": "25",
    "dpvat": "25",
    "baremoArgentino": "30",
    "baremoPortugues": "17,00 a 20,00",
    "louisMelennec": "20",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação trapézio-metacarpiana em adução",
    "baremoEuropeu": "22",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 12,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação trapézio-metacarpiana em abdução",
    "baremoEuropeu": "16",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 12,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação trapézio-metacarpiana em posição funcional",
    "baremoEuropeu": "7",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 12,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "3",
    "susep": "5",
    "dpvat": "5",
    "baremoArgentino": "9",
    "baremoPortugues": "1,00 a 2,00",
    "louisMelennec": "5",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "5",
    "susep": "3.75",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "1,00 a 3,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo amputação ao nível de falange média",
    "baremoEuropeu": "5",
    "susep": "10",
    "dpvat": "7.5",
    "baremoArgentino": "11",
    "baremoPortugues": "3,00 a 4,00",
    "louisMelennec": "6",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "7",
    "susep": "7.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "7",
    "susep": "15",
    "dpvat": "10",
    "baremoArgentino": "14",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "8",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "6",
    "susep": "7.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "2o dedo limitação na mobilização",
    "baremoEuropeu": "5",
    "susep": "3.75",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "3",
    "susep": "4",
    "dpvat": "5",
    "baremoArgentino": "6",
    "baremoPortugues": "1,00 a 2,00",
    "louisMelennec": "3",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "5",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "1,00 a 2,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo amputação ao nível de falange média",
    "baremoEuropeu": "5",
    "susep": "8",
    "dpvat": "7.5",
    "baremoArgentino": "8",
    "baremoPortugues": "3,00 a 4,00",
    "louisMelennec": "5",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "7",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 7,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "7",
    "susep": "12",
    "dpvat": "10",
    "baremoArgentino": "11",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "6",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "6",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 7,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "3o dedo limitação na mobilização",
    "baremoEuropeu": "5",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "1",
    "susep": "3",
    "dpvat": "5",
    "baremoArgentino": "5",
    "baremoPortugues": "1",
    "louisMelennec": "2",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "3",
    "susep": "2.25",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "2",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo amputação ao nível de falange média",
    "baremoEuropeu": "2",
    "susep": "6",
    "dpvat": "7.5",
    "baremoArgentino": "6",
    "baremoPortugues": "2,00 a 3,00",
    "louisMelennec": "3",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "4",
    "susep": "4.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "3,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "4",
    "susep": "9",
    "dpvat": "10",
    "baremoArgentino": "8",
    "baremoPortugues": "4,00 a 5,00",
    "louisMelennec": "4",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "3.5",
    "susep": "4.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "3,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "4o dedo limitação na mobilização",
    "baremoEuropeu": "3",
    "susep": "2.25",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "2",
    "susep": "4",
    "dpvat": "5",
    "baremoArgentino": "3",
    "baremoPortugues": "1",
    "louisMelennec": "1",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "4",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "1",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo amputação ao nível de falange média",
    "baremoEuropeu": "3.5",
    "susep": "8",
    "dpvat": "7.5",
    "baremoArgentino": "4",
    "baremoPortugues": "2,00 a 3,00",
    "louisMelennec": "2",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "5",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "2,00 a 5,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "5",
    "susep": "12",
    "dpvat": "10",
    "baremoArgentino": "5",
    "baremoPortugues": "4,00 a 5,00",
    "louisMelennec": "3",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "4",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "2,00 a 5,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Esquerda",
    "descricao": "5o dedo limitação na mobilização",
    "baremoEuropeu": "4",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo amputação ao nível de metade da falange distal",
    "baremoEuropeu": "8",
    "susep": "9",
    "dpvat": "12.5",
    "baremoArgentino": "8",
    "baremoPortugues": "5,00 a 10,00",
    "louisMelennec": "15",
    "baremoEspanhol": "8,00 a 10,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo limitação na mobilização falange distal",
    "baremoEuropeu": "4",
    "susep": "4.5",
    "dpvat": "6.25",
    "baremoArgentino": "0",
    "baremoPortugues": "2,00 a 4,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo amputação ao nível falange distal completa",
    "baremoEuropeu": "12",
    "susep": "9",
    "dpvat": "12.5",
    "baremoArgentino": "15",
    "baremoPortugues": "5,00 a 10,00",
    "louisMelennec": "15",
    "baremoEspanhol": "8,00 a 10,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez falange distal em extensão",
    "baremoEuropeu": "6",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez falange distal em posição funcional",
    "baremoEuropeu": "4",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez falange distal em flexão",
    "baremoEuropeu": "8",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo amputação ao nível de metade da falange proximal",
    "baremoEuropeu": "15",
    "susep": "18",
    "dpvat": "25",
    "baremoArgentino": "25",
    "baremoPortugues": "11,00 a 15,00",
    "louisMelennec": "20",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo amputação ao nível de falange proximal completa",
    "baremoEuropeu": "21",
    "susep": "18",
    "dpvat": "25",
    "baremoArgentino": "25",
    "baremoPortugues": "11,00 a 15,00",
    "louisMelennec": "20",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo limitação na mobilização ao nível articulação metacarpo-falangiana",
    "baremoEuropeu": "4",
    "susep": "9",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação metacarpo-falangiana em extensão",
    "baremoEuropeu": "6",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação metacarpo-falangiana em flexão",
    "baremoEuropeu": "5",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação metacarpo-falangiana em posição funcional",
    "baremoEuropeu": "4",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo amputação ao nível de metade do metacarpo",
    "baremoEuropeu": "23",
    "susep": "25",
    "dpvat": "25",
    "baremoArgentino": "30",
    "baremoPortugues": "17,00 a 20,00",
    "louisMelennec": "25",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo limitação na mobilização ao nível articulação trapézio-metacarpiana em posição funcional",
    "baremoEuropeu": "8",
    "susep": "12.5",
    "dpvat": "12.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo amputação ao nível do metacarpo completo",
    "baremoEuropeu": "26",
    "susep": "25",
    "dpvat": "25",
    "baremoArgentino": "30",
    "baremoPortugues": "17,00 a 20,00",
    "louisMelennec": "25",
    "baremoEspanhol": "15,00 a 20,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação trapézio-metacarpiana em posição funcional",
    "baremoEuropeu": "8",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 12,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação trapézio-metacarpiana em abdução",
    "baremoEuropeu": "18",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 12,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "1o dedo anquilose/rigidez ao nível articulação trapézio-metacarpiana em adução",
    "baremoEuropeu": "26",
    "susep": "18.75",
    "dpvat": "18.75",
    "baremoArgentino": "0",
    "baremoPortugues": "6,00 a 12,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "3",
    "susep": "5",
    "dpvat": "5",
    "baremoArgentino": "9",
    "baremoPortugues": "1,00 a 2,00",
    "louisMelennec": "6",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "6",
    "susep": "3.75",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "1,00 a 3,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo amputação ao nível de falange média",
    "baremoEuropeu": "5.5",
    "susep": "10",
    "dpvat": "7.5",
    "baremoArgentino": "11",
    "baremoPortugues": "3,00 a 4,00",
    "louisMelennec": "8",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "8",
    "susep": "7.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "8",
    "susep": "15",
    "dpvat": "10",
    "baremoArgentino": "14",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "10",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "7",
    "susep": "7.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "2o dedo limitação na mobilização",
    "baremoEuropeu": "6",
    "susep": "3.75",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "3",
    "susep": "4",
    "dpvat": "5",
    "baremoArgentino": "6",
    "baremoPortugues": "1,00 a 2,00",
    "louisMelennec": "4",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "6",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "1,00 a 2,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo amputação ao nível de falange média",
    "baremoEuropeu": "5.5",
    "susep": "8",
    "dpvat": "7.5",
    "baremoArgentino": "8",
    "baremoPortugues": "3,00 a 4,00",
    "louisMelennec": "6",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "8",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 7,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "8",
    "susep": "12",
    "dpvat": "10",
    "baremoArgentino": "11",
    "baremoPortugues": "5,00 a 8,00",
    "louisMelennec": "8",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "7",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "4,00 a 7,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "3o dedo limitação na mobilização",
    "baremoEuropeu": "6",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "2",
    "susep": "3",
    "dpvat": "5",
    "baremoArgentino": "5",
    "baremoPortugues": "1",
    "louisMelennec": "3",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "4",
    "susep": "2.25",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "2",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo amputação ao nível de falange média",
    "baremoEuropeu": "3.5",
    "susep": "6",
    "dpvat": "7.5",
    "baremoArgentino": "6",
    "baremoPortugues": "2,00 a 3,00",
    "louisMelennec": "4",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "8",
    "susep": "4.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "3,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "5",
    "susep": "9",
    "dpvat": "10",
    "baremoArgentino": "8",
    "baremoPortugues": "4,00 a 5,00",
    "louisMelennec": "5",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "4.5",
    "susep": "4.5",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "3,00 a 6,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "4o dedo limitação na mobilização",
    "baremoEuropeu": "4",
    "susep": "2.25",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo amputação ao nível de falange distal",
    "baremoEuropeu": "2",
    "susep": "4",
    "dpvat": "5",
    "baremoArgentino": "3",
    "baremoPortugues": "1",
    "louisMelennec": "2",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo anquilose/rigidez em posição favorável",
    "baremoEuropeu": "4.5",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "1",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo amputação ao nível de falange média",
    "baremoEuropeu": "4",
    "susep": "8",
    "dpvat": "7.5",
    "baremoArgentino": "4",
    "baremoPortugues": "2,00 a 3,00",
    "louisMelennec": "3",
    "baremoEspanhol": "5,00 a 6,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo anquilose/rigidez em posição desfavorável em flexão",
    "baremoEuropeu": "6",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "2,00 a 5,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo amputação ao nível de falange proximal",
    "baremoEuropeu": "6",
    "susep": "12",
    "dpvat": "10",
    "baremoArgentino": "5",
    "baremoPortugues": "4,00 a 5,00",
    "louisMelennec": "4",
    "baremoEspanhol": "6,00 a 7,00"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo anquilose/rigidez em posição desfavorável em extensão",
    "baremoEuropeu": "5",
    "susep": "6",
    "dpvat": "5",
    "baremoArgentino": "0",
    "baremoPortugues": "2,00 a 5,00",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  },
  {
    "mao": "Mão Direita",
    "descricao": "5o dedo limitação na mobilização",
    "baremoEuropeu": "4.5",
    "susep": "3",
    "dpvat": "2.5",
    "baremoArgentino": "0",
    "baremoPortugues": "0",
    "louisMelennec": "0",
    "baremoEspanhol": "0"
  }
]
