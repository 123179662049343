import React, {useState} from 'react'

export const DadosContestacao = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [contestacao, setContestacao] = useState([]);

  const adicionarContestacao = () => {
    const arrContestacao = [];
    arrContestacao.push({
      idContestacao: formValues.idContestacao !== undefined ? formValues.idContestacao : "",
      resumoContestacao:
        formValues.resumoContestacao !== undefined ? formValues.resumoContestacao : "",
    });

    setContestacao(arrContestacao);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _10_contestacao: arrContestacao,
      })
    );
  };

  const excluirContestacao = (e) => {
    e.preventDefault();
    contestacao.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        contestacao.splice(i, 1);
        setContestacao([...contestacao]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _10_contestacao: [...contestacao],
          })
        );
      }
      return null;
    });
  };

  const excluirTudoContestacao = (e) => {
    e.preventDefault();
    // console.log(e.target.attributes.value.value)

    setContestacao([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _10_contestacao: [],
      })
    );
  };

  const handleCarregarFormContestacao = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
      if (tudo._10_contestacao && tudo._10_contestacao.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._10_contestacao !== undefined ||
          JSON.parse(localStorage.getItem("tudo"))._10_contestacao !== null
            ? JSON.parse(localStorage.getItem("tudo"))._10_contestacao
            : [];

        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            idContestacao:
              e !== undefined && e.idContestacao !== undefined && e.idContestacao !== ""
                ? e.idContestacao
                : "",

            resumoContestacao:
              e !== undefined &&
              e.resumoContestacao !== undefined &&
              e.resumoContestacao !== ""
                ? e.resumoContestacao
                : "",
          })
        );
      }
    };

    const y = () => {
      if (tudo._10_contestacao && tudo._10_contestacao.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._10_contestacao !== undefined ||
          JSON.parse(localStorage.getItem("tudo"))._10_contestacao !== null
            ? JSON.parse(localStorage.getItem("tudo"))._10_contestacao
            : [];

        //console.log('***',a)
        const arrContestacao = [];
        a.map((e) => {
          return arrContestacao.push({
            idContestacao:
              e !== undefined && e.idContestacao !== undefined && e.idContestacao !== ""
                ? e.idContestacao
                : "",

            resumoContestacao:
              e !== undefined &&
              e.resumoContestacao !== undefined &&
              e.resumoContestacao !== ""
                ? e.resumoContestacao
                : "",
          });
        });

        setContestacao(arrContestacao);
      }
    };

    x();
    y();
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  return (
    <>
      <fieldset>
        <legend>Contestação</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>Limpar Campo</th>
                <th>Informação</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"idContestacao"}></i>
                  </button>
                </th>
                <th>
                  <input
                    type="text"
                    name="idContestacao"
                    maxLength="22"
                    className="capturaIdContestacao"
                    placeholder="Id - página"
                    onChange={handleInputChange}
                    value={
                      formValues.idContestacao !== undefined
                        ? formValues.idContestacao
                        : ""
                    }
                  />
                </th>
              </tr>

              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"resumoContestacao"}
                    ></i>
                  </button>
                </th>
                <th>
                  <textarea
                    name="resumoContestacao"
                    rows="6"
                    cols="40"
                    placeholder="Insira aqui o resumo da contestacao"
                    onChange={handleInputChange}
                    value={
                      formValues.resumoContestacao !== undefined
                        ? formValues.resumoContestacao
                        : ""
                    }
                  />
                </th>
              </tr>
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarContestacao}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormContestacao}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Dados registrados de documentos da contestacao</legend>
            {contestacao.length === 0 ? (
              <p>Sem Dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {contestacao.length > 0 &&
                    contestacao.map(({ idContestacao, resumoContestacao }, i) => {
                      return (
                        <tbody key={i}>
                          <tr >
                            <th>Registro número</th>
                            <th> {i + 1}</th>
                          </tr>
                          <tr>
                            <th>Id</th>
                            <th>{idContestacao}</th>
                          </tr>

                          <tr>
                            <th>Resumo</th>
                            <th>{resumoContestacao}</th>
                          </tr>

                          <tr>
                            <th>Ação</th>
                            <th >
                              <button onClick={excluirContestacao}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoContestacao}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoContestacao"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            )}
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
