import React, {useState} from 'react'
import { UtilitariosBuscaSofrimentosPadecidosQualitativos } from "../../utilitarios/UtilitariosBusca"
import styles from '../quantumDoloris/QuantumDoloris.module.css'

export const QuantumDoloris = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ quantumDoloris, setQuantumDoloris] = useState([])
   
    const adicionarQuantumDoloris = (e) => {

        e.preventDefault()

        const arrQuantumDoloris = []
    
        arrQuantumDoloris.push({
            perguntaQuantumDoloris0: "Compreende a valoração do sofrimento físico e psíquico sofrido pela vítima em decorrência do dano.",
            respostaQuantumDoloris0: formValues.respostaQuantumDoloris0 !== undefined ? formValues.respostaQuantumDoloris0 : '',
           comentarioQuantumDoloris0: formValues.comentarioQuantumDoloris0 !== undefined ? formValues.comentarioQuantumDoloris0 : '',     
        })
        setQuantumDoloris([...arrQuantumDoloris])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _67_quantumDoloris: [{
                perguntaQuantumDoloris0: "Compreende a valoração do sofrimento físico e psíquico sofrido pela vítima em decorrência do dano.",
            respostaQuantumDoloris0: formValues.respostaQuantumDoloris0 !== undefined ? formValues.respostaQuantumDoloris0 : '',
           comentarioQuantumDoloris0: formValues.comentarioQuantumDoloris0 !== undefined ? formValues.comentarioQuantumDoloris0 : '',
            }]
      }))
    }

    const excluirQuantumDoloris = (e) => {
        e.preventDefault()  

        setQuantumDoloris([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _67_quantumDoloris: []
              }))
    }

    const handleCarregarFormQuantumDoloris = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._67_quantumDoloris && tudo._67_quantumDoloris.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._67_quantumDoloris !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._67_quantumDoloris !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._67_quantumDoloris : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaQuantumDoloris0: e !== undefined && e.perguntaQuantumDoloris0 !== undefined ? e.perguntaQuantumDoloris0 : '',
            respostaQuantumDoloris0: e !== undefined &&  e.respostaQuantumDoloris0 !== undefined ? e.respostaQuantumDoloris0 : '',
            comentarioQuantumDoloris0: e !== undefined &&  e.comentarioQuantumDoloris0 !== undefined ? e.comentarioQuantumDoloris0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparQuantumDoloris = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaQuantumDoloris'+e.target.attributes.value.value]: '', ['comentarioQuantumDoloris'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxQuantumDoloris} />
            <label htmlFor={styles.checkboxQuantumDoloris}>
                <h2 className={styles.titulo}>Quantum Doloris</h2>
            </label>
            <fieldset id={styles.conteudoQuantumDoloris}>  
            <fieldset>
                <legend>Utilitários Sofrimentos Padecidos</legend>
                <>
                <UtilitariosBuscaSofrimentosPadecidosQualitativos/>
                </>
            </fieldset> 
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="Compreende a valoração do sofrimento físico e psíquico sofrido pela vítima em decorrência do dano."> 
                        <th><button onClick={handleLimparQuantumDoloris}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>Compreende a valoração do sofrimento físico e psíquico sofrido pela vítima em decorrência do dano.</th>
                        <th>
                        <select
                            name="respostaQuantumDoloris0"  
                            onChange={handleInputChange}
                            value={formValues.respostaQuantumDoloris0 !== undefined ? formValues.respostaQuantumDoloris0 : ''}
                            >
                                <option value="" defaultValue>Selecione a valoração desejada:</option>
                                <option value="Ausente">Ausente</option>
                                <option value="Nao mensurável">Nao mensurável</option>
                                <option value="1/7 Muito leve">1/7 Muito leve</option>
                                <option value="2/7 Leve">2/7 Leve</option>
                                <option value="3/7 Médio">3/7 Médio</option>
                                <option value="4/7 Moderado">4/7 Moderado</option>
                                <option value="5/7 Considerável">5/7 Considerável</option>
                                <option value="6/7 Importante">6/7 Importante</option>
                                <option value="7/7 Muito importante">7/7 Muito importante</option>
                            </select>
                        </th>
                        <th>
                        <textarea 
                            name="comentarioQuantumDoloris0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite sua valoração aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioQuantumDoloris0 !== undefined ? formValues.comentarioQuantumDoloris0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarQuantumDoloris}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormQuantumDoloris}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados da incapacidade laboral temporária</legend>
            {
            quantumDoloris.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            <th>Pergunta</th>
                            <th>Resposta</th>
                             <th>Comentário</th> 
                        </tr>
                    </thead>
                        {quantumDoloris.length > 0 && quantumDoloris.map(({ 
                            perguntaQuantumDoloris0,
                            respostaQuantumDoloris0,
                            comentarioQuantumDoloris0,
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaQuantumDoloris0}</th>
                                <th>{respostaQuantumDoloris0}</th>
                                <th>{comentarioQuantumDoloris0}</th> 
                            </tr>
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirQuantumDoloris}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )  
}