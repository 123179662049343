import React, {useState} from 'react'

export const DadosDocOutros = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ docOutros, setDocOutros] = useState([])
    
    const adicionarDocOutros = () => {

        const arrDocOutros = []
        arrDocOutros.push({
            //id: docOutros !== undefined ? docOutros.length + 1 : '',
            idDocOutros: formValues.idDocOutros !== undefined ? formValues.idDocOutros : '',
            detalheDocOutros: formValues.detalheDocOutros !== undefined ? formValues.detalheDocOutros : ''
        })

        setDocOutros([...docOutros, ...arrDocOutros])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _16_docOutros: [...docOutros,{
                //id: docOutros !== undefined ? docOutros.length + 1 : '',
            idDocOutros: formValues.idDocOutros !== undefined ? formValues.idDocOutros : '',
            detalheDocOutros: formValues.detalheDocOutros !== undefined ? formValues.detalheDocOutros : ''
            }]
      }))
    }

    const excluirDocOutros = (e) => {

        e.preventDefault()      
        docOutros.filter((
            el, i
            )=>{   
                if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {             
                        docOutros.splice(i, 1)
                        setDocOutros([...docOutros])

                        localStorage.setItem('tudo', JSON.stringify({
                            ...JSON.parse(localStorage.getItem('tudo')) || [],
                            _16_docOutros: [...docOutros]
                      }))                  
                    }    
                    return null
        })
    }

    const excluirTudoDocOutros = (e) => {
        e.preventDefault();
       // console.log(e.target.attributes.value.value)

          setDocOutros([])
  
           localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _16_docOutros: []
            })
          ); 
      }
  
      const handleCarregarFormDocOutros = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._16_docOutros && tudo._16_docOutros.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._16_docOutros !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._16_docOutros !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._16_docOutros : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              idDocOutros: e !== undefined && e.idDocOutros !== undefined && e.idDocOutros !== ""
            ? e.idDocOutros
            : "",

            detalheDocOutros: e !== undefined && e.detalheDocOutros !== undefined && e.detalheDocOutros !== ""
            ? e.detalheDocOutros
            : "",
            
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._16_docOutros && tudo._16_docOutros.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._16_docOutros !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._16_docOutros !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._16_docOutros : []
      
              //console.log('***',a)
              const arrDocOutros = []
          a.map((e)=> {
  
          return  arrDocOutros.push({
            idDocOutros: e !== undefined && e.idDocOutros !== undefined && e.idDocOutros !== ""
            ? e.idDocOutros
            : "",

            detalheDocOutros: e !== undefined && e.detalheDocOutros !== undefined && e.detalheDocOutros !== ""
            ? e.detalheDocOutros
            : "",
        }) 
    
    }
      )  

      setDocOutros(arrDocOutros)
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }

    return (

        <>
            <fieldset>
        <legend>Outros Documentos</legend>
        <div >
        <fieldset >
          <table >
                    <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>

              <tbody>
              <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idDocOutros"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="idDocOutros"
                            maxLength="22"
                            className="capturaIdDocOutros"
                            placeholder="Id - página"
                            onChange={handleInputChange}
                            value={formValues.idDocOutros !== undefined ? formValues.idDocOutros : ''}
                        /></th>
                </tr>

                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"detalheDocOutros"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="detalheDocOutros"
                            placeholder="Insira o doc de SST / Treinamento"
                            onChange={handleInputChange}
                            value={formValues.detalheDocOutros !== undefined ? formValues.detalheDocOutros : ''}
                        /></th>
                </tr>
                     </tbody>
                     </table>   
                        
                     <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarDocOutros}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormDocOutros}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>


                    <fieldset>
                        <legend>Dados registrados de documentos de SST</legend>
                        {docOutros.length === 0 ? <p>Sem Dados registrados</p> :
                            <>
                            <table>
                              <thead>
                                <tr>
                                  <th>Tópico</th>
                                  <th>Informação</th>
                                </tr>
                              </thead>
                                    {docOutros.length > 0 && docOutros.map(({
                                        
                                        idDocOutros,
                                        detalheDocOutros
                                    }, i) => {

                                        return (
                                            <tbody key={i}>
                                            <tr >
                                              <th>Registro número</th>
                                              <th> {i + 1}</th>
                                            </tr>
                                            <tr>
                                              <th>Id</th>
                                              <th>{idDocOutros}</th>
                                            </tr>
            
                                            <tr>
                                              <th>Detalhe</th>
                                              <th>{detalheDocOutros}</th>
                                            </tr>
            
                            
                                            <tr>
                                              <th>Ação</th>
                                              <th >
                                                <button onClick={excluirDocOutros}>
                                                  <i
                                                    className="fas fa-solid fa-trash perigo"
                                                    value={i}
                                                  ></i>
                                                </button>
                                              </th>
                                            </tr>
                                          </tbody>
                                        
                                      );
                                    }
                                  )}
                              </table>
                              <fieldset>
                                <legend>Ação</legend>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Excluir todos os documentos?</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr >
                                      <th>
                                        <button onClick={excluirTudoDocOutros}>
                                          <i
                                            className="fas fa-solid fa-trash perigo"
                                            value={"excluirTudoDocOutros"}
                                          ></i>
                                        </button>
                                      </th>
                                    </tr>
                                  </tbody>
                                </table>
                              </fieldset>
                            </>
                          }
                        </fieldset>
                      </fieldset>
                  </div>  </fieldset>
                  </>
                );
}
