export const Baremos = [
  {
    "descricao": "Afasia Global",
    "baremoEuropeu": "70-85 pontos",
    "susep": "50.00",
    "dpvat": "50.00",
    "baremoArgentino": "0.00",
    "baremoPortugues": "60-75 pontos",
    "louisMelennec": "0.00",
    "baremoEspanhol": "50-65 pontos"
  },
  {
    "descricao": "Afasia motora de Broca",
    "baremoEuropeu": "40-60 pontos",
    "susep": "0.00",
    "dpvat": "50.00",
    "baremoArgentino": "0.00",
    "louisMelennec": "0.00"
  },
  {
    "descricao": "Afasia sensorial de Wernicke",
    "baremoEuropeu": "50-70 pontos",
    "susep": "0.00",
    "dpvat": "50.00",
    "baremoArgentino": "0.00",
    "louisMelennec": "0.00"
  },
  {
    "descricao": "Agravamento de artose prévia ao traumatismo",
    "susep": "0",
    "dpvat": "0",
    "baremoArgentino": "0",
    "baremoPortugues": "1-3 pontos",
    "louisMelennec": "0"
  },
  {
    "descricao": "Amputação ao nível da perna (coxa)",
    "baremoEuropeu": "50 a 60%",
    "susep": "70%",
    "dpvat": "100%",
    "baremoArgentino": "a nível proximal: 40 - 50% a nível distal: 50-60%",
    "baremoPortugues": "60%",
    "louisMelennec": "50%",
    "baremoEspanhol": "50-60 pontos"
  },
  {
    "descricao": "Amputação ao nivel do joelho",
    "baremoEuropeu": "40%",
    "susep": "35%",
    "dpvat": "50%",
    "baremoArgentino": "55 a 60%",
    "baremoPortugues": "40%",
    "louisMelennec": "40%"
  },
  {
    "descricao": "Amputação ao nível do tornozelo",
    "baremoEuropeu": "tibio-tarsiano: 25%",
    "susep": "50%",
    "dpvat": "50%",
    "baremoArgentino": "50-60%",
    "baremoPortugues": "tibio-tarsiano: 40%",
    "baremoEspanhol": "tibio-tarsiano unilateral: 30-40% tibio-tarsiano bilateral: 60-70%"
  },
  {
    "descricao": "Amputação da falange distal do 1o dedo do pé",
    "baremoEuropeu": "6%",
    "susep": "5%",
    "dpvat": "5%",
    "baremoArgentino": "3 a 6%",
    "baremoPortugues": "2 a 3%",
    "louisMelennec": "5%",
    "baremoEspanhol": "5%"
  },
  {
    "descricao": "Amputação da mão",
    "baremoEuropeu": "Dominante: 50% Não dominante: 45%",
    "susep": "60%",
    "dpvat": "70%",
    "baremoArgentino": "Punho: 50 a 60% Transmetacarpiana: 45 a 55%",
    "baremoPortugues": "Total: 35 a 40% Transmetacarpiana: 18 a 20%",
    "louisMelennec": "Dominante: 60% Não dominante: 50%",
    "baremoEspanhol": "Unilateral: 35 a 40% Bilateral: 65%"
  },
  {
    "descricao": "Amputação da parte móvel da lingua",
    "baremoEuropeu": "3-30%",
    "susep": "não faz referência",
    "dpvat": "100% (?)",
    "baremoArgentino": "Parcial sem alteração na fonação ou deglutição: 10 a 15% Parcial com alteração na fonação ou deglutição: avalia-se pela disfagia e disartria Total: 30 a 50%",
    "baremoPortugues": "Terço anterior: 5 a 15% 2/3 anteriores: 16 a 25% Total: 26 a 40%",
    "louisMelennec": "30 a 60% (quando extensa)",
    "baremoEspanhol": "menos de 50%: 5 a 20% mais de 50%: 20 a 45% Total: 45% Total: 26 a 40%"
  },
  {
    "descricao": "Amputação de pé",
    "baremoEuropeu": "Transmetatarsiana(Lisfranc) 20% Médio társica (Chopart): 20% Tarsica: 25% Tibio-társica(Syme): 25%",
    "susep": "25%",
    "dpvat": "50%",
    "baremoArgentino": "Transmetatarsiana(Lisfranc): 20% Médio társica (Chopart): 35-45%",
    "baremoPortugues": "Transmetatarsiana(Lisfranc): 10% Médio társica (Chopart): 20% Tarsica: 20% Tibio-társica(Syme): 25%",
    "louisMelennec": "Transmetatarsiana: 15% Pé: 25%",
    "baremoEspanhol": "Unilateral: 15-30% Bilateral: 30-60%"
  },
  {
    "descricao": "Amputação de um ou mais outros dedos do pé",
    "susep": "0.00",
    "baremoPortugues": "2%"
  },
  {
    "descricao": "Amputação do 1o dedo do pé",
    "baremoEuropeu": "Total com perda do 1o metatarso: 10% Total somente o dedo: 6%",
    "susep": "10%",
    "dpvat": "10%",
    "baremoArgentino": "3 a 6%",
    "baremoPortugues": "Falange distal: 2 a 3% Todo o dedo: 5 a 7%",
    "louisMelennec": "5%",
    "baremoEspanhol": "10%"
  },
  {
    "descricao": "Amputação do antebraço",
    "baremoEuropeu": "Dominante: 50% Não dominante: 45%",
    "susep": "60% (considerado a perda do uso da mão e não considerando a perda total do uso do membro)",
    "dpvat": "70% (considerado a perda do uso da mão e não considerando a perda total do uso do membro)",
    "baremoArgentino": "Dominante 50-60 % Não dominante 45-50 %",
    "baremoPortugues": "40-45%",
    "louisMelennec": "Dominante 50-55 % Não dominante 40-55 %",
    "baremoEspanhol": "Unilateral: 40-45% Bilateral; 70-75%"
  },
  {
    "descricao": "Amputação do braço",
    "baremoEuropeu": "Dominante: 60% Não dominante: 55%",
    "susep": "70%",
    "dpvat": "100%",
    "baremoArgentino": "Com conservação do ombro Dominante: 50-60% Não dominante: 44-55% Desarticulação do ombro Dominante: 70-80% Não dominante: 65-70%",
    "baremoPortugues": "Com cintura escapular móvel: 46-50% Total: 51-55%",
    "louisMelennec": "Dominante: 55-60% Não dominante: 45-50%",
    "baremoEspanhol": "Unilateral: 45-50% Bilateral: 80%"
  },
  {
    "descricao": "Amputação dos 5 dedos do pé e do 1o metatarso",
    "baremoEuropeu": "10%",
    "susep": "25%",
    "dpvat": "25%",
    "baremoArgentino": "10-20%",
    "baremoPortugues": "8%",
    "louisMelennec": "15%"
  },
  {
    "descricao": "Anexos do olho(entropio , ectrópio,má oclusão, ptose) bilateral",
    "baremoEuropeu": "1-10%",
    "susep": "0.00"
  },
  {
    "descricao": "Anosmia(ausência de olfação), hiposmia (diminuição), Parosmia\\Disosmia (distorção da olfação)",
    "baremoEuropeu": "Anosmia: 15% Disosmia: até 3%",
    "baremoArgentino": "Anosmia: 6-10% Disosmia: 0-5% Hiposmia: 0-5%",
    "baremoPortugues": "Anosmia: 15% Disosmia: 1-10%",
    "louisMelennec": "Anosmia: 5% Disosmia: 5% Hiposmia: 1-5%",
    "baremoEspanhol": "Anosmia: 7% Disosmia: 2% Hiposmia: 3-6% Anosmia com alterações gustativas: 7 a 10%"
  },
  {
    "descricao": "Anquilose de cotovelo",
    "baremoEuropeu": "Dominante com pronossupinação conservada: 24% Dominante com pronossupinação abolida: 34% Não dominante com pronossupinação conservada: 20% Não dominante com pronossupinação abolida: 30%",
    "susep": "Completa: 25% Parcial: considerar o graus leve(25% do valor total), Médio(50% do valor total) e máximo (75% do valor total)",
    "dpvat": "Completa: 25% Parcial: considerar o graus leve(25% do valor total), Médio(50% do valor total) e máximo (75% do valor total)",
    "baremoPortugues": "Pronossupinação conservada: 15-12% Pronossupinação abolida: 21-25%",
    "louisMelennec": "20-25%",
    "baremoEspanhol": "Em posição funcional: 10-15% Em posição não funcional: 20-30%"
  },
  {
    "descricao": "Anquilose ou Artrodese de quadril",
    "baremoEuropeu": "em posição não funcional: 31-65%",
    "susep": "20%",
    "dpvat": "25%",
    "baremoPortugues": "em posição favorável: 25% em posição viciosa: 26-35%",
    "baremoEspanhol": "em posição funcional: 25% em posição não funcional: 25-35%"
  },
  {
    "descricao": "Anquilose/artrodese de joelho",
    "baremoEuropeu": "em posição funcional: 25% em posição não funcional: avaliar de acordo com a limitação",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "baremoPortugues": "23 a 25%",
    "baremoEspanhol": "em posição funcional: 20% em posição não funcional: 20-30%"
  },
  {
    "descricao": "Anquilose/artrodese de tornozelo",
    "baremoEuropeu": "Perda total da Flexão de tornozelo: 5% Perda total da Extensão de tornozelo: 5% Anquilose ou Artrodese em posição funcional: 10%",
    "susep": "Completa: 20% Por analogia Grau leve: 5% Grau Médio: 10% Grau Máximo: 15%",
    "dpvat": "Completa: 20% Por analogia Grau leve: 5% Grau Médio: 10%",
    "baremoPortugues": "Em posição favorável:20% Em posição desfavorável:30%",
    "louisMelennec": "15%",
    "baremoEspanhol": "em posição funcional: 12% em posição não funcional: 12-20%"
  },
  {
    "descricao": "Artrodese ou Anquilose de ombro",
    "baremoEuropeu": "Dominante com omoplata fixa: 30% Dominante com omoplata móvel: 25% Não dominante com omoplata fixa: 25% Não dominante com omoplata móvel: 20%",
    "susep": "Limitação na mobilização Graus leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Limitação na mobilização Graus leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "Com omoplata fixa: 21-25% Com omoplata móvel: 18-20%",
    "baremoEspanhol": "Em posição funcional: 20% Em posição não funcional: 25%"
  },
  {
    "descricao": "Artrodese ou Anquilose de punho em posição funcional",
    "baremoEuropeu": "Dominante com pronossupinação conservada: 10% Dominante com pronossupinação abolida: 20% Não dominante com pronossupinação conservada: 8% Não dominante com pronossupinação abolida: 16%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "Pronossupinação conservada: 6-8% Pronossupinação abolida: 13-16%",
    "baremoEspanhol": "Em posição funcional: 8-10% Em posição não funcional:10-15%"
  },
  {
    "descricao": "Artrodese ou Anquilose médio-talar(Chopart) e tarso-metatarsica(Lisfranc)",
    "baremoEuropeu": "4-6%"
  },
  { "descricao": "Artrodese ou Anquilose sub-talar", "baremoEuropeu": "7-9%" },
  {
    "descricao": "Artrodese, Anquilose ou limitação de coluna cervical",
    "baremoEuropeu": "sem sintomas acompanhantes 3-10%",
    "susep": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "Em posição funcional: 10-20% até 10 graus de desvio: 20-25% 11 a 20 graus de desvio: 25 a 30% 21 a 30 graus de desvio: 30-40% Mais de 30 graus: 40 a 50%",
    "baremoPortugues": "sem sintomas acompanhantes 3-10%",
    "louisMelennec": "a) Transtorno leve. Sintomas pouco importantes ou intermitentes, sinais ausentes ou quase normais e exames complementares com poucos significados do ponto de vista funcional: 1 a 5% b) Transtorno moderado: sintomas mais evidentes, exame clinico com pontos doloroso, podendo haver manifestação à distância. Força muscular e exame neurológico normal: Evidencia de uso de tratamento(medicação, fisioterapias): 5 a 15% c) Transtorno médio: Sintomas importantes mas não graves. Em todos os casos identifica-se sinais clínicos locais e à distância. A capacidade de esforços está diminuída . Necessidade de tratamentos contínuos: 15 a 30% d) Transtorno importante: A alteração funcional é permanente e incapacitante com exame clinico evidente. A capacidade de esforço está muito reduzida mas a autonomia ainda é completa. Necessidade de tratamentos contínuos: 30 a 60% e) Transtornos graves: Já existem complicações secundárias (respiratórias, cardíacas, neurológicas, etc) já com comprometimento da autonomia: Mais de 60%",
    "baremoEspanhol": "Limitação da mobilidade da coluna cervical: 5-15%"
  },
  {
    "descricao": "Artrose de joelho",
    "baremoEuropeu": "5-8%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "baremoArgentino": "Pinçamento incipiente da luz articular ou osteófitos marginal incipiente sem esclerose subcondral (artrose leve): Unicompatimental (3-6%) e bicompatimental (5-8%) Pinçamento parcial da luz articular com osteófitos marginal e esclerose subcondral incipiente(artrose média): Unicompatimenta (7-15%) e bicompatimental (9-20%) Pinçamento total da luz articular com osteófitos marginal e esclerose subcondral com calcificações (artrose avançada): 21-30%)",
    "baremoPortugues": "1-8%",
    "baremoEspanhol": "1-10%"
  },
  {
    "descricao": "Artrose de punho",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "1-3%",
    "baremoEspanhol": "1-3%"
  },
  {
    "descricao": "Artrose de quadril",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "Pinçamento incipiente da luz articular ou osteófitos marginal incipiente sem esclerose subcondral (artrose leve): 5-10% Pinçamento parcial da luz articular com osteófitos marginal e esclerose subcondral incipiente(artrose média): 10-20% Pinçamento total da luz articular com osteófitos marginal e esclerose subcondral com calcificações (artrose avançada): 20-30%",
    "baremoPortugues": "1-10%",
    "baremoEspanhol": "1-10%"
  },
  {
    "descricao": "Artrose do cotovelo",
    "susep": "Graus leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Graus leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "Pinçamento incipiente da luz articular ou osteófitos marginal incipiente sem esclerose subcondral (artrose leve): Dominante (5-8%) e não dominante (3-6%) Pinçamento parcial da luz articular com osteófitos marginal e esclerose subcondral incipiente(artrose média): Dominante (6-12%) e não dominante (8-18%) Pinçamento total da luz articular com osteófitos marginal e esclerose subcondral com calcificações (artrose avançada): Dominante (14-20%) e não dominante (12-18%)",
    "baremoPortugues": "1-5%",
    "louisMelennec": "15-30%",
    "baremoEspanhol": "1-5%"
  },
  {
    "descricao": "Artrose do tornozelo",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "Pinçamento parcial da luz articular com osteófitos marginal e esclerose subcondral"
  },
  {
    "descricao": "Artrose pós-traumática do ombro",
    "susep": "Graus leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Graus leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "Pinçamento incipiente da luz articular ou osteófitos marginal incipiente sem esclerose subcondral (artrose leve): Dominante (5-10%) e não dominante (3-8%) Pinçamento parcial da luz articular com osteófitos marginal e esclerose subcondral incipiente(artrose média): Dominante (10-20%) e não dominante (8-18%) Pinçamento total da luz articular com osteófitos marginal e esclerose subcondral com calcificações (artrose avançada): Dominante (10-30%) e não dominante (18-28%)",
    "baremoPortugues": "1-5%",
    "baremoEspanhol": "Ombro doloroso: 3%"
  },
  {
    "descricao": "Bursite de ombro",
    "baremoArgentino": "Dominante: 4-8% Não dominante: 3-7%",
    "baremoPortugues": "Ombro doloroso: 3%",
    "baremoEspanhol": "Ombro doloroso:1-5%"
  },
  {
    "descricao": "Cardiopatias insuficiência cardíaca",
    "baremoEuropeu": "a) Cardiopatias com sintomas em repouso (Fração de ejeção menor 20%): maior que 55% b) Cardiopatias com sintomas em esforços moderados (Fração de ejeção entre 20% -25%): 45 a 55% c) Cardiopatias com sintomas em esforços moderados com exigência de terapêutica considerável(Fração de ejeção entre 25% -30%): 40 a 45% d) Cardiopatias com limitação na atividade da vida diária , como a marcha rápida(Fração de ejeção entre 30% -35%):35 a 4% e) Cardiopatias com limitação para esforços comuns, como subir dois andares(Fração de ejeção entre 35% -40%): 25 a 35% f) Cardiopatias com limitação para esforços significativos(Fração de ejeção entre 40% -50%): 15 a 25% g) Cardiopatias com limitação para esforços importantes (Fração de ejeção entre 50% -60%): 8 a 15% h) Sem limitação funcional(Fração de ejeção maior que 60%): menor que 8%",
    "baremoPortugues": "a) Sintomatologia funcional mesmo em repouso (confirmada por dados clínicos e para -clínicos; exigências terapêuticas muito importantes e hospitalizações frequentes; fração de ejecção <20%: 81 a 90% b) Limitação funcional para esforços moderados (com manifestações de falência miocárdica –edema pulmonar - ou associada a complicações vasculares periféricas ou a perturbações do ritmo complexas com exigências terapêuticas importantes e vigilância apertada; fração de ejecção 20 a 25 %): 66 a 80% c) Idem com exigência terapêutica considerável (com ou sem perturbações do ritmo associadas; fração de ejecção 25 a 30 %) : 41 a 65 d) Limitação funcional com implicações na atividade diária (marcha rápida) (alteração franca dos parâmetros ecográficos ou eco -dopler; intolerância ao esforço com anomalias do ECG de esforço e com exigência terapêutica; fracção de ejecção 30 a 35 %): 31 a 40% e) Limitação funcional alegada para esforços comuns (2 andares) (confirmada por ECG de esforço ou existência de sinais de disfunção miocárdica; contra -indicação de esforços físicos consideráveis e exigência terapêutica com vigilância cardiológica próxima; fração de ejeção 35 a 40 %) : 21 a 30% f) Limitação funcional alegada para esforços significativos (com sinais de disfunção miocárdica, exigências terapêuticas e vigilância próxima; fracção de ejeção 40 a 50 %). 11 a 20% g) Limitação funcional alegada para esforços importantes (desporto) (sem sinais de disfunção ou isquemia miocárdica, com necessidades terapêuticas e vigilância regular; fracção de ejecção 50 a 60 %): 3 a 10% h) Sem limitação funcional (boa tolerância ao esforço; segundo as necessidades terapêuticas e/ou vigilância regular; fração de ejecção >60 %) Até 2%",
    "baremoEspanhol": "a) Grau I: dispneia aos esforços (fração de ejeção: 60- 50%).: 1-10% b) Grau II: dispneia ao esforço moderado (fração de ejeção: 50% - 40%). 10-30% c) Grau III: dispnéia pequenos esforços (fração de ejeção: 40% - 30%): 30-60% d) Grau IV: dispnéia em repouso (fração de ejeção:< 30%): 60-90%"
  },
  {
    "descricao": "Cervicalgia (cervical)",
    "baremoEuropeu": "a)Cervicalgia sem lesões ósseas ou disco-ligamentares implicando em uso medicações constantes: 1-3% b) Cervicalgia com lesões ósseas ou disco-ligamentares documentadas implicando em uso medicação ocasional:3 a 10% c) Cervicalgia com lesões ósseas ou disco-ligamentares documentadas ,muito frequentes, com comprometimento funcional, porém com movimentação possível: 10 a 15% d) Cervicalgia com lesões ósseas ou disco-ligamentares documentadas ,muito frequentes, com comprometimento funcional, com rigidez importante em vários níveis: 15 a 25%",
    "baremoArgentino": "a) Sintomas subjetivos de dor não confirmados por alterações estruturais patológicas nas radiografias, sem contratura dolorosa involuntária paravertebral: 0% b) Contratura muscular dolorosa persistente, perda da lordose nas radiografias e redução da mobilidade da coluna: 4 a 8% c) Contratura muscular e rigidez com alterações degenerativas discais: 6 a 12%",
    "baremoPortugues": "a) Sem lesão óssea ou disco -ligamentar documentada (dores intermitentes, implicando medicação analgésica e ou anti-inflamatória, com reduzido compromisso da mobilidade): 1 a 3% b) Com lesões ósseas ou disco-ligamentares documentadas com dores muito frequentes e ou intensas, com acentuada limitação Funcional clinicamente objetivável, implicando terapêutica continuada: 10 a 15% c) Dores frequentes com limitação funcional clinicamente objetivável, implicando terapêutica ocasional: 4 a 9% d) Artrodese ou anquilose sem outras queixas, segundo o número de níveis: 3 a 10%.",
    "louisMelennec": "a) Transtorno leve. Sintomas pouco importantes ou intermitentes, sinais ausentes ou quase normais e exames complementares com poucos significados do ponto de vista funcional: 1 a 5% b) Transtorno moderado: sintomas mais evidentes, exame clinico com pontos doloroso, podendo haver manifestação à distância. Força muscular e exame neurológico normal: Evidencia de uso de tratamento(medicação, fisioterapias): 5 a 15% c) Transtorno médio: Sintomas importantes mas não graves. Em todos os casos identifica-se sinais clínicos locais e à distância. A capacidade de esforços está diminuída . Necessidade de tratamentos contínuos: 15 a 30% d) Transtorno importante: A alteração funcional é permanente e incapacitante com exame clinico evidente. A capacidade de esforço está muito reduzida mas a autonomia ainda é completa. Necessidade de tratamentos contínuos: 30 a 60% e) Transtornos graves: Já existem complicações secundárias (respiratórias, cardíacas, neurológicas, etc) já com comprometimento da autonomia: Mais de 60%",
    "baremoEspanhol": "a) Sem compromisso radicular; 1-5% b) Com compromisso radicular: 5 a 10%"
  },
  {
    "descricao": "Cervicobraquialgia (cervical)",
    "baremoEuropeu": "1 a 12%",
    "baremoArgentino": "1) Sintomas subjetivos de dor sem comprovação de doença:0% 2) Contratura muscular, perda da lordose, redução da mobilidade com eletroneuromigrafia alterada sem discopatia: 6 a 10% 3) Contratura muscular com alterações degenerativas discais e eletroneuromigrafia alterada unilateral: 8 a 12% 4) Contratura muscular com alterações degenerativas discais e eletroneuromigrafia alterada unilateral: 10 a 20%",
    "baremoPortugues": "1 a 12%"
  },
  {
    "descricao": "Cifose (segundo arco de curvatura e graus) dorsal",
    "baremoArgentino": "a) Não operada, sem manifestações clínicas (contratura muscular), radiográficas (artroses localizada) nem neurológicas (eletroneuromiografia normal):2 a 4% b) Não operada, com manifestações clínicas (contratura muscular), radiográficas (artroses localizada) mas sem manifestações neurológicas (eletroneuromiografia normal): 4 a 8% c) Operada com artrodeses consolidada, sem ou com pouca contratura muscular paravertebral: 4 a 8% d) Operada com artrodeses consolidada, com contratura muscular paravertebral importante: 8 a 15% e) Operada com artrodeses não consolidada: 15 a 30% f) Operada com alterações respiratórias : valorar separadamente",
    "baremoPortugues": "a) Pequena a moderada: 1 a 5% b) Pronunciada: 6 a 10% c) Alterações da estática vertebral (cifose, escoliose, lordose) (segundo o arco de curvatura e graus): 1 a 12%"
  },
  {
    "descricao": "Colostomias ileostomias estomias",
    "baremoEuropeu": "Colostomia e ileostomia: 30%",
    "baremoArgentino": "Transitória, já tendo reconstruído o transito no momento da avalição: 5 a 15% Definitiva sem disfunção intestinal: 40 a 50% Definitiva com disfunção intestinal: soma de 30% a incapacidade correspondente segundo o grau de disfunção intestinal até no máximo de 70%",
    "baremoPortugues": "Colostomia e ileostomia: 30%",
    "baremoEspanhol": "Colostomia: 40% a 50%"
  },
  {
    "descricao": "Condromalácea de joelho",
    "baremoEuropeu": "1-3%",
    "susep": "Por analogia: 5%",
    "dpvat": "Por analogia: 5%",
    "baremoArgentino": "5-15%",
    "baremoPortugues": "Joelho doloroso: 3%",
    "baremoEspanhol": "1-5%"
  },
  {
    "descricao": "Costelas arcos costais",
    "baremoArgentino": "a) Fraturas de uma costela com deslocamento: 0 a 5% b) Fraturas de várias costelas com deslocamento e sem complicação respiratória: 5 a 15% b) Fraturas de várias costelas com deslocamento e com complicação respiratória: Adicionar a incapacidade por fratura correspondente para a sequela respiratória",
    "baremoPortugues": "costelas: Dores intercostais pós fratura 1-3%",
    "baremoEspanhol": "Fraturas de costelas com nevralgias intercostais: 1 a 6%"
  },
  { "descricao": "Cotovelo doloroso", "baremoPortugues": "3%" },
  {
    "descricao": "Deformidades em pé",
    "baremoArgentino": "Pé cavo que pode ser muito melhorado com palmilhas: 5-10% Pé cavo que melhora parcialmente com palmilhas: 10-20% Pé cavo sem melhora com palmilhas: 21-40% Pé plano que pode ser muito melhorado com palmilhas: 5-10% Pé plano que melhora parcialmente com palmilhas: 10-15% Pé plano sem melhora com palmilhas: 15-25%",
    "baremoPortugues": "Pé plano com depressão moderada da abóbada plantar: 1-5% Pé plano, com desmoronamento completo da abóbada plantar : 6-10% Pé cavo pós -traumático ou outra deformação grave: 10-30%",
    "baremoEspanhol": "1-10%"
  },
  {
    "descricao": "Demências (Todos os tipos valorando segundo o aspecto clinico)",
    "baremoArgentino": "a) Grau I: eles estão relacionados com situações cotidianas, a magnitude é leve e não interfere nas atividades da vida diária, nem a adaptação do ambiente. Eles não requerem tratamento em uma base permanente. 0% b) Grau II: os traços de personalidade de base são enfatizados, não apresentaram alterações no pensamento, concentração ou memória. Às vezes eles precisam de algum tipo de droga ou tratamento psicoterapêutico: 10% c) Grau III: exigem tratamento mais intensivo. Existem remissão dos sintomas mais agudos em cerca de 3 meses. Distúrbios de memória e concentração são verificados durante o exame psiquiátrico. As formas de apresentação são desde a depressão, a crises conversivas, crises de pânico, fobias e obsessões. Eles são reversíveis com o tratamento psicoterapêutico e psicofarmacológico adequado. Fazem controle por cerca de um ano: 20% d) Grau IV: Exigem uma assistência permanente de terceiros. A neurose Fóbicas, conversões histéricas, são as manifestações clínicas que ais invalidam neste grau. Depressões neuróticas também podem ser muito incapacitantes. 30%",
    "baremoPortugues": "a) Perturbação grave (com apragmatismo e alterações graves da inserção social e familiar.) 61 a 85% b) Perturbação importante (com alteração das condutas instintivas, perda da iniciativa, perturbações do humor, inserção familiar e social precária.) 36 a 60% c) Perturbação moderada (com bradipsiquismo relativo, dificuldade de memorização, perturbações do humor e repercussões na inserção social e familiar.) 21 a 35% d) Perturbação ligeira (com distratibilidade, lentificação, dificuldades de memorização e de elaboração de estratégias complexas; escassas ou nulas perturbações da inserção social e familiar.) 10 a 20%"
  },
  {
    "descricao": "Dentes (boca)",
    "baremoEuropeu": "a) Perda do incisivo ou canino: 1% b) perda do pré-molar ou molar: 1,5% c) Edentação completa insusceptível de correção por prótese: 28%",
    "susep": "Perda de dentes não dão direito a indenização por invalidez permanente.",
    "baremoArgentino": "POR DENTE a) Incisivo central superior: 0,9% b) Incisivo central inferior: 0,7% c) Incisivo lateral superior: 0,8% d) Incisivo lateral inferior: 0,6% e) Canino superior: 0,9% f) Canino inferior: 0,7% g) Primeiros pré-molares: 0,6% h) Segundos pré-molares: 0,55% i) Primeiros molares: 0,7% j) Segundos molares: 0,65% k) Terceiros molares: 0,20%",
    "baremoPortugues": "a) Perda dos oitavos dentes (dentes do siso) : 0% b) Por cada dente incisivo: 1% c) Por cada dente canino: 6% d) Por cada dente pré –molar: 1,25% e) Por cada dente molar 5% Quando ocorrer a perda total ou parcial dos dentes, e se estes forem substituídos por prótese, a incapacidade resulta do somatório das perdas calculadas pelas alíneas anteriores, dividindo -se a soma aritmética por três(prótese fixa) ou por dois (prótese móvel)",
    "baremoEspanhol": "1% cada dente. Somente não se gradua perda do 3% molar"
  },
  {
    "descricao": "Desarticulação do quadril",
    "baremoEuropeu": "65%",
    "baremoPortugues": "65%",
    "baremoEspanhol": "Unilateral: 60-70% Bilateral: 90-955"
  },
  {
    "descricao": "Diabete",
    "baremoEuropeu": "descompensada: 20-40%",
    "baremoArgentino": "Tipo I compensada com tratamento, sem repercussão orgânica:30-40% Tipo I que no pode ser ven compensada con tratamiento, sin repercusión orgánica: 50-70% Tipo I com lesiones orgânicas: se avalia de acordo com as lesões Tipo II compensada com dieta, sem repercussão orgânica: 10-20% Tipo II compensada com hipoglicemiantes orais, sem repercussão orgânica: 20-30% Tipo II compensada com insulina, sem repercussão orgânica: 30-40% Tipo II que no pode ser bem compensada tratamento, sem repercussão orgânica: 40-60% Tipo II com lesiones orgânicas: se avalia de acordo com as lesões",
    "baremoPortugues": "compensada 15-19% descompensada: 20-40%",
    "baremoEspanhol": "15-30%"
  },
  {
    "descricao": "Diplopia (visão dupla)",
    "baremoEuropeu": "1-25%",
    "baremoArgentino": "a)Susceptível de correção com lentes ou posição compensadora da cabeça: 5 a 10% b)Não susceptível de correção com lentes ou posição compensadora da cabeça acompanhada ou não de ptose palpebral, com ou sem oftalmoplegia que autorize a oclusão de um olho: 25 a 30% c)Não susceptível de correção com lentes ou posição compensadora da originando desvio da cabeça para fixar para além da oclusão de um olho: 30 a 40%",
    "baremoPortugues": "a) Nos campos superiores: 1 a 10% b) No campo lateral: 5 a 15% c) Nos campos inferiores: 10 a 20% d) Em todas as direções, obrigando a ocluir um olho: 20 a 25%",
    "baremoEspanhol": "a) Nos campos superiores: 1 a 10% b) No campo lateral: 5 a 15% c) Nos campos inferiores: 10 a 20% d) Em todas as direções, obrigando a ocluir um olho: 20 a 25%"
  },
  {
    "descricao": "Disfonia (é toda e qualquer dificuldade na emissão vocal) Afonia (perda da propriedade de modular a voz mas não de reproduzir sons). Disartria (dificuldade de utilizar os músculos da fala)",
    "baremoEuropeu": "a) Disfonia leve: 1 a 10% b) Disfonia acentuado: 11 a 20% c) Afonia: 30% d) Disartria: 5% e) Ligeiras perturbações da expressão oral: 5%",
    "susep": "Mudez: 50% Por analogia Disfonia grau leve: 12,5% Disfonia grau médio: 25% Disfonia grau acentuado: 37,5%",
    "dpvat": "Mudez: 50% Por analogia Disfonia grau leve: 12,5% Disfonia grau médio: 25% Disfonia grau acentuado: 37,5%",
    "baremoArgentino": "a) Alterações da voz ou da articulação , porém mantém um dialogo fluido: 0 a 5% b) Entende-se a emissão da voz com certa dificuldade em ambiente ruidosos: 5 a 10% c) Entende-se a emissão com dificuldade e deve repetir ocasionalmente para aclarar seu discurso: 10 a 17% d) Requer a repetição frequente e/ou o discurso é difícil de seguir: 17 a 25% e) Afonia: 25 a 30%",
    "baremoPortugues": "a) rouquidão e outras perturbações que afetam ligeira ou moderadamente: 1 a 10% b) disfonia acentuado: 11 a 20% c) Estenose parcial de laringe com disfonia marcante: 21 a 30% d) Estenose parcial de laringe com disfonia e dispneia 31 a 40% e) Disartria: 5 a 20%",
    "louisMelennec": "a) Leve: deformação simples da voz , mas que continua inteligível; 0 a 5% b) Moderado: nota-se alteração qualitativa e quantitativa da voz, mas que continua compreensível em seu essencial: 5 a 15% c) Médio: alteração muito evidente da voz que somente é audível e compreensível parcialmente: 15 a 30% d) Importante: Impossibilidade de comunicar-se na forma oral: 30 a 60% e) Muito importante: não se aplica",
    "baremoEspanhol": "Disartria: 10 a 20%"
  },
  {
    "descricao": "Disfunção erétil (pênis)",
    "baremoArgentino": ") Dificuldades leves, menos de 40 anos: 8 a 15% b) Dificuldades leves, de 40 a 65 anos: 5 a 10% c) Dificuldades leves, más de 65 anos: 3 a 5% d) Dificuldades moderadas (A função reflexa é possível mas não tem desejos), menos de 40 anos: 30 a 40% e) Dificuldades moderadas (A função reflexa é possível mas não tem desejos), de 40 a 65 anos: 20 a 30% f) Dificuldades moderadas (A função reflexa é possível mas não tem desejos), mais de 65 anos: 10 a 20% g) Impotência sexual, menos de 40 anos: 40 a 60% h) Impotência sexual, menos de 40 a 65 anos: 30 a 50% i) Impotência sexual, mais de 65 anos: 20 a 40% Obs: lesão de pênis e testículo buscar e quadro específico",
    "baremoPortugues": "a) Dificuldade na ereção, na ejaculação ou perturbações do orgasmo com função reprodutora conservada : 5 a 15% b) Ereção suficiente, com coito possível, mas sem ejaculação: 16 a 20% c) Ausência total de ereção (disfunção erétil neurológica ou vascular pós -traumática): 21 a 30% d) Perda parcial ou total do pênis, com consequente meato ectópico: 36 a 45 e) Perda do pênis e dos testículos: 46 a 60%",
    "baremoEspanhol": "Impotência (segundo repercussão funcional): 2 a 20%"
  },
  {
    "descricao": "Disfunção Mandibular",
    "baremoEuropeu": "Disfunção Mandibular com limitação da abertura igual ou inferior 10 mm: 25 a 28% Disfunção Mandibular com limitação entre 10 e 30 mm:5 a 25%",
    "baremoArgentino": "a) Com abertura bucal inferior a 6 mm: 15 a 25% b) Com abertura bucal entre 6 e 20 mm: 5 a 15% c) Com abertura bucal maior que 20 mm: 0 a 5%",
    "baremoPortugues": "a) Afastamento entre 21 mm e 30 mm: 5% b) Afastamento entre 11 mm e 20 mm : 10% c) Idem, entre 0 mm e 10 mm:. 20% Mobilidade da mandíbula — A abertura da boca é medida pela distância que separa os incisivos dos dois maxilares, sem queixas do observando. O afastamento normal é de 40 mm.",
    "baremoEspanhol": "a) Limitação na abertura da articulação temporo-madibular(segundo sua repercussão - 0-45mm): 1 a 30% b) Luxação recidivante temporo-mandibular (entre 20 a 45mm): 5 a 10% c) Luxação recidivante temporo-mandibular (entre 20 a 45mm): 10 a 25% c) Subluxação recidivante temporo-mandibular (entre 20 a 45mm): 1 a 15%"
  },
  {
    "descricao": "Dispnéia Insuficiência respiratória",
    "baremoEuropeu": "1) Dispnéia aos pequenos esforços (CV ou CPT inferior a 50% VEMS inferior a 40%, hipóxia de repouso, necessidade de oxigênioterapia : maior que 50 até 90% 2) Dispnéia em marcha no seu próprio ritmo (CV ou CPT entre 50% e 60% VEMS entre 40-60% e hipóxia de repouso 30 a 50% 3) Dispnéia em marcha normal (CV ou CPT entre 60% e 70% VEMS entre 60-70%: 15 a 30% 4) Dispnéia em marcha rápida (CV ou CPT entre 70% e 80% VEMS entre 70-80%: 5 a 15% 5) Dispnéia para esforços importantes com alterações discretas nas provas funcionais: 2-5%",
    "baremoPortugues": "1) Dispneia para pequenos esforços: CV ou CPT <50%; VEMS<40 % hipoxemia<60 mm Hg associada com eventual necessidade de oxigenoterapia: 51 a 90% 2) Dispneia na marcha em terreno plano no seu ritmo próprio, CV ou CPT entre 50 e 60 %; VEMS entre 40 e 60 % hipoxémia de repouso (PaO2) entre 60 a 70mmHg: 31 a 50% 3) Dispneia na marcha normal em superfície plana: CV ou CPT entre 60 e 70 %; VEMS entre 60 e 70 %; DLCO/VA inferior a 60%: 16 a 30% 4) Dispneia na subida a um andar em marcha rápida, ou em inclinação ligeira, CV ou CPT entre 70 e 80 %; VEMS entre 70 e 80 %; DLCO/VA entre 60 e 70 %. : 6 a 15% 5) Dispneia para esforços importantes com alteração discreta das provas funcionais: 2 a 5%",
    "louisMelennec": "a) Transtorno leve. Sintomas pouco importantes ou intermitentes, ausculta pulmonar normal ou pouco significativa, coração é normal, espirometria normal, marcha rápida é normal , paciente é autônomo: 0 a 5% b) Transtorno moderado: Sintomas mais importantes, ausculta pulmonar normal ou pouco significativa, coração é normal, espirometria tem uma queda de 15 a 25% normal, marcha rápida é normal , subida de mais de 3 pisos normal, corrida á distância normal , atividades desportivas simples normais, paciente é autônomo: 5 a 15% c) Transtorno médio: Sintomas já criam uma fadiga, ausculta pulmonar pode mostrar alterações, coração é normal, espirometria tem uma queda de 20 a 40% normal, marcha rápida já está comprometida, bem como esforços maiores , paciente é autônomo:: 15 a 30% d) Transtorno importante: Sintomas já são importantes, ausculta pulmonar alterada , existe repercussão para a área cardíaca, espirometria tem uma queda de 30 a 60% normal, marchacomum já começa a ficar comprometida, paciente é autônomo: 30 a 60% e) Transtornos graves: mais graves que os anteriores com comprometimento da autonomia: Mais de 60%",
    "baremoEspanhol": "1) Restrição Tipo I ( 100 a 80%) segundo a espirometria: 1 a 10% 2) Restrição Tipo II ( 80 a 60%) segundo a espirometria: 10 a 30% 3) Restrição Tipo III( 60 a 50%) segundo a espirometria: 30 a 60% 4) Restrição Tipo IV ( < 50%) segundo a espirometria: 60 a 90%"
  },
  {
    "descricao": "Doença de Addison",
    "baremoArgentino": "compensada: 30-50% descompensada: 60-80¨%"
  },
  {
    "descricao": "Doença de Cushing",
    "baremoArgentino": "Compensada com tratamento: 30-50% Descompensada: 60-70%"
  },
  {
    "descricao": "Doença de De Quervain (Punho)",
    "baremoArgentino": "Não operada leve(limita esforços com a mão) Dominante: 4-7% Não dominante: 3-6% Não operada severa(limita a mobilidade da mão) Dominante: 7-15% Não dominante: 6-13% Operada: se valora segundo as sequelas"
  },
  {
    "descricao": "Doença de Kienbock (punho) (mão) semilunar semi-lunar",
    "baremoArgentino": "Dominante: 10-15% Não Dominante: 8-12%"
  },
  {
    "descricao": "Doenças da Patela (Rótula) Joelho",
    "baremoArgentino": "Luxação recidivante: 11-20% Instabilidade crônica: 5-10% Ressecção parcial: 5-15% Ressecção total: 15-20%",
    "baremoPortugues": "Patelectomia parcial ou total: 5-10% Fratura com sequelas: 3-10%",
    "baremoEspanhol": "Patelectomia total: 15% Patelectomia parcial: 1-10% Luxação: 1-5% Condropatia: 1-5%"
  },
  {
    "descricao": "Dor no pé (esporão) (talalgia)",
    "baremoArgentino": "Metatarsalgia sem melhora com palmillhas: 6-9% Metatarsalgia com melhora com palmillhas: 9-15% Talagia por fratura de calcaneo:se valora pela fratura talalgia por esporão calcâneo: 5-15%",
    "baremoPortugues": "1-3%",
    "baremoEspanhol": "1-5%"
  },
  {
    "descricao": "Dorsalgia (dorsal) (coluna)",
    "baremoEuropeu": "a) Dorsalgia sem lesões ósseas ou disco-ligamentares implicando em uso medicações constantes: 1 a 3% b) Dorsalgia com lesões ósseas ou disco-ligamentares documentadas , com comprometimento funcional, implicando em uso medicação ocasional: 3 a 10% c) Dorsalgia com lesões ósseas ou disco-ligamentares documentadas , com comprometimento funcional, implicando em uso medicação cosntante: 10 a 15%",
    "baremoArgentino": "a) Sintomas subjetivos de dor não confirmados por alterações estruturais patológicas nas radiografias , sem contratura dolorosa involuntária paravertebral: 0% b) Contratura muscular dolorosa persistente, com alterações degenerativas (artrose localizada): 4 a 8%"
  },
  {
    "descricao": "Encurtamento de clavícula maior 1,5 cm com limitação na elevação (ombro)",
    "baremoEuropeu": "adicionar na valoração: 1-3%"
  },
  {
    "descricao": "Encurtamento de membro inferior (dismetrias) (perna)",
    "baremoEuropeu": "de 1 a 3 cm: 2-3% 3 a 5 cm: 4-5% Maior que 5 cm: 6-8%",
    "susep": "Até 2,9 cm: 0% 3 cm: 6% 4 cm: 10% 5 a mais: 15%",
    "baremoArgentino": "Até 1 cm: 0% 1 a 3 cm: 5% 3 a 5 cm: 10% Mais de 5 cm: 15%",
    "baremoPortugues": "de 1 a 3 cm: 2-3% 3 a 5 cm: 4-5% Maior que 5 cm: 6-8%",
    "baremoEspanhol": "de 1 a 3 cm: 3-12% 3 a 6 cm: 12-24% Maior que 6 cm: 24-40%"
  },
  {
    "descricao": "Enfermidade de Dupuytren (punho) (mão)",
    "baremoArgentino": "Com nódulos palmares Dominante: 5-15% Não dominante: 3-12% Com retração de 4º e 5º dedos Dominante: 15-25% Não dominante: 12-22% Com retração de 2º a 5º dedos Dominante: 20-40% Não dominante: 22-37%"
  },
  {
    "descricao": "Epicondilite crônica de cotovelo",
    "baremoEuropeu": "Dominante: até 5% Não dominante: até 3%",
    "baremoArgentino": "Dominante: 2 a 6% Não dominante: 1 a 5%",
    "baremoPortugues": "até 5%",
    "baremoEspanhol": "Cotovelo doloroso: 1-3%"
  },
  {
    "descricao": "Epilepsia",
    "baremoEuropeu": "a) Epilepsia parciais sem perturbações da consciência: 5-15% b) Epilepsia controladas: 10-15% c) Epilepsia de difícil controle: 15-35% d) Epilepsia refratária: 35-70%",
    "baremoArgentino": "a) Controlável com medicação: soma 4% como indicado para o dano cerebral orgânico até um máximo de 100% b) Controlável parcialmente: soma 10% como indicado para o dano cerebral orgânico até um máximo de 100% c) Não controlável com medicação: soma 30% como indicado para o dano cerebral orgânico até um máximo de 100%",
    "baremoPortugues": "a) Epilepsia parciais sem perturbações da consciência: 5-15% b) Epilepsia controladas: 10-15% c) Epilepsia de difícil controle: 16-35% d) Epilepsia refratária: 36-70%"
  },
  {
    "descricao": "Escoliose (segundo arco de curvatura e graus) (coluna)",
    "baremoArgentino": "a) Não operada, sem manifestações clínicas (contratura muscular), radiográficas (artroses localizada) nem neurológicas (eletroneuromiografia normal):2 a 4% b) Não operada, com manifestações clínicas (contratura muscular), radiográficas (artroses localizada) mas sem manifestações neurológicas (eletroneuromiografia normal): 4 a 8% c) Operada com artrodese consolidada, sem ou com pouca contratura muscular paravertebral e neurológico normal: 4 a 8% d) Operada com artrodese consolidada, com contratura muscular paravertebral importante e neurológico normal: 8 a 15% e) Operada com artrodese não consolidada e alterações neurológicas moderadas a graves: 15 a 30% f) Com alterações respiratórias (insuficiência respiratória): Além da incapacidade de derivada da alteração anatômica associar ao montante a deficiência correspondente ao grau de insuficiência respiratória como o resultado do teste funcional respiratório",
    "baremoPortugues": "1-12%",
    "louisMelennec": "a) Transtorno leve. Sintomas pouco importantes ou intermitentes, sinais ausentes ou quase normais e exames complementares com poucos significados do ponto de vista funcional: 1 a 5% b) Transtorno moderado: sintomas mais evidentes, exame clinico com pontos doloroso, podendo haver manifestação à distância. Força muscular e exame neurológico normal: Evidencia de uso de tratamento(medicação, fisioterapias): 5 a 15% c) Transtorno médio: Sintomas importantes mas não graves. Em todos os casos identifica-se sinais clínicos locais e à distância. A capacidade de esforços está diminuída . Necessidade de tratamentos contínuos: 15 a 30% d) Transtorno importante: A alteração funcional é permanente e incapacitante com exame clinico evidente. A capacidade de esforço está muito reduzida mas a autonomia ainda é completa. Necessidade de tratamentos contínuos: 30 a 60% e) Transtornos graves: Já existem complicações secundárias (respiratórias, cardíacas, neurológicas, etc) já com comprometimento da autonomia: Mais de 60%"
  },
  {
    "descricao": "Esplenectomia (baço)",
    "baremoEuropeu": "Esplenectomia total assintomática: 5% Esplenectomia total com exigências terapêuticas: 6 a 15%",
    "dpvat": "Perda do Baço: 10%",
    "baremoPortugues": "Esplenectomia total assintomática:até 5% Esplenectomia total com exigências terapêuticas: 6 a 15%",
    "baremoEspanhol": "Esplenectomia total sem repercussões hemato-imunológicas: 5% Esplenectomia total com repercussões hemato-imunológicas:: 10 a 15%"
  },
  {
    "descricao": "Esquizofrenia",
    "baremoArgentino": "a) Episodio único em remissão total: 3 a 10% b) Contínua ou episódica com sintomas residuais leves (não há nenhuma alteração da relação de emprego, mas se isso afeta a vida familiar, não há nenhum distúrbios de memória ou concentração): 10 a 20% c) Contínua ou episódica com sintomas residuais moderados (não há alteração das relações laborais e a vida familiar, mas você pode executar alguns trabalhos): 20 a 40% d) Contínua ou episódica com graves sintomas residuais (há alteração marcada da vida familiar e a incapacidade de trabalhar): 40 a 70% e) Contínua ou episódica com sintomas residuais muito graves (requer assistência ou supervisão em curso, não pode trabalhar ou fazer uma vida independente): 70 a 100%"
  },
  {
    "descricao": "Esterilidade feminina Histerectomia Útero Ovário",
    "baremoEuropeu": "Esterilidade feminina: 25%",
    "baremoArgentino": "a) Histerectomia em idade fértil, parcial, com possibilidade de preservação de uma gravidez: 30 a 40% b) Histerectomia em idade fértil, total: 50 a 60% c) Histerectomia Idade fértil, com ooforectomia: 55 a 60% d) Histerectomia na pós-menopausa, parcial: 6 a 10% e) Histerectomia na pós-menopausa, total: 10 a 15% f) Histerectomia na pós-menopausa, com ooforectomia: 15 a 25% g) Histerectomia total com distúrbio vaginal que impede a relação sexual: 60% h) Ooforectomia parcial em idade fértil, sem interrupção do ciclo menstrual: unilateral (1 a 8%) bilateral (2 a 8%) i) Ooforectomia parcial uni ou bilateral interrupção do ciclo menstrual, sem esterilidade (15 a 25%) j) Ooforectomia parcial em pós-menopausa: 0 a 2% k) Ooforectomia total em idade fértil: unilateral(15 a 25%) e bilateral (50 a 60%) l) Ooforectomia total na pós-menopausa unilateral (7 a 8%) bilateral (10 a 20%)",
    "baremoPortugues": "Esterilidade feminina: até 30% Cicatriz do corpo do útero que implique risco de rotura no decurso duma gravidez: 11 a 25% Histerectomia com conservação dos ovários: 11 a 25% Histerectomia com anexectomia bilateral: 26 a 35% Ooforectomia parcial ou unilateral, com conservação da ovulação: 5 a 10% Perda da ovogênese ou Ooforectomia bilateral pós idade fértil: 1 a 4% Perda da ovogênese ou Ooforectomia bilateral em idade fértil: 11 a 25%",
    "baremoEspanhol": "Perda de útero antes da menopausa: 40% Perda de útero depois da menopausa: 10% Perda de de um ovário: 20 a 25% Perda de dois ovários: 40%"
  },
  {
    "descricao": "Estresse pós traumático",
    "baremoEuropeu": "a) Estresse pós traumático com leve repercussão na autonomia pessoal: 3 a 8% b) Estresse pós traumático com moderada repercussão na autonomia pessoal: 8 a 12% c) Estresse pós traumático com grave repercussão na autonomia pessoal: 12 a 20%",
    "baremoArgentino": "a) Aguda: Avaliados após 3 meses do evento b) Crônica muito leve (só aparecem manifestações relacionadas com o conflito gerador de reação, não há nenhuma alteração da relação de emprego ou vida familiar, ligeira alteração da personalidade de base, não ha nenhuma distúrbios de memória ou concentração, não requer tratamento):2 a 7% c) Crônica leve (aparecem manifestações ligadas a situações cotidianas, mas com algum grau de relação com o conflito gerador de reação, não há nenhuma alteração da relação de emprego, mas se isso afeta a vida familiar, apresenta acentuação das características mais características da personalidade de base, não há nenhuma memória ou concentração transtornos, pode ser tratada por meio de terapias breves): 7 a 15% d) Crônica moderada (aparecem situações relacionadas ao cotidiano de manifestações completamente alheias ao conflito de reação gerador há alteração das relações de trabalho e vida familiar apresenta acentuação das características mais características da personalidade de base, lá não é nenhuma distúrbios de memória ou concentração, requer terapia prolongada, mas o tratamento não-farmacológico):15 a 30% e) Crônica grave (aparecem manifestações relacionadas com situações cotidianas completamente alheias ao conflito de reação gerador lá é a alteração das relações laborais e a vida familiar, alterou a personalidade de base, lá são distúrbios de memória e concentração, requer terapia prolongada e tratamento farmacológico): 30 a 50%",
    "baremoPortugues": "Com ligeira repercussão na autonomia pessoal, social e profissional: 4 a 10% Com moderada repercussão na autonomia pessoal, social e profissional: 11 a 15% Com grave repercussão na autonomia pessoal, social e profissional: 16 a 25%",
    "baremoEspanhol": "Estresse pós-traumático: 1 a 3%"
  },
  {
    "descricao": "Fratura de fêmur (coxa) (osteomielite) (pseudoartrose)",
    "baremoEuropeu": "Consolidação viciosa de fêmur até 10º: 1-3% Consolidação viciosa de fêmur maior que 10º: 3-8% Pseudoartrose de fêmur: 10-20%",
    "baremoPortugues": "Em posição viciosa : 10-25% Pseudoartrose de fêmur: 30-35% Osteomielite de fêmur: 14%",
    "baremoEspanhol": "Com consolidação viciosa de 1 a 10 graus: 1-5% Com consolidação viciosa maior que 10 graus: 5-10% Pseudoartrose de fêmur com infecção: 40% Pseudoartrose de fêmur sem infecção 30% Osteomielite crônica: 20% Material de osteossíntese: 1-10%"
  },
  {
    "descricao": "Fratura de tíbia (perna) (osteomielite) (pseudoartrose) (tibia)",
    "baremoEuropeu": "Consolidação viciosa de tíbia com desordem de rotação maior que 10º: 3-8% Consolidação viciosa de tíbia com desordem de rotação menor que 10º: 1-3% Pseudoartrose de tíbia: 10-15%",
    "baremoPortugues": "Fratura da tíbia ou da tíbia e perónio, com desvio consolidada em posição viciosa sem angulação com encurtamento inferior a 2 cm: 1-5% Fratura da tíbia ou da tíbia e perónio consolidada em posição viciosa com angulação e com encurtamento inferior a 2 cm : 1-5% Fratura da tíbia ou da tíbia e perónio consolidada em posição viciosa com angulação e encurtamento superior a 2 cm adiciona -se, de acordo com o princípio da capacidade restante as porcentagens da dismetria. d) Fratura consolidada com bom alinhamento, mas encurtamento superior a 2 cm (ver dismetria apenas) e)Fratura da tíbia e perónio, consolidada com bom alinhamento, mas com diminuição da resistência por perda de tecido ósseo: 1-3%",
    "baremoEspanhol": "consolidação viciosa de 1 a 10 graus: 1-5% consolidação viciosa maior que 10 graus: 5-10% Osteomielite: 20% Pseudoartrose sem infecção: 25% Pseudoartrose com infecção: 30% Material de osteossíntese: 1-6%"
  },
  {
    "descricao": "Fratura de ulna e ou rádio (antebraço) (pseudoartrose) (osteomielite)",
    "baremoArgentino": "Pseudoartrose diafisária: 9-12% Pseudoartrose olecraniana: 12-15% Pseudoartrose apófise estilóide: 0-1% Pseudoartrose de rádio e ulna: 20-30%",
    "baremoPortugues": "Consolidação viciosa:1-3% Pseudoartrose de ulna: 6-18% Pseudoartrose de rádio: 8-10% Pseudoartrose de ulna e radio:10-15% Osteomielite de ossos de antebraço: 6-10%",
    "baremoEspanhol": "Consolidação viciosa: 1-3% Pseudoartrose de ulna sem infecção: 8-10% Pseudoartrose de ulna com infecção: 10-15% Pseudoartrose de rádio sem infecção: 6-8% Pseudoartrose de rádio com infecção: 8-12% Pseudoartrose de ulna e rádio sem infecção: 18-20% Pseudoartrose de ulna e rádio a com infecção: 20-25% Material de osteossíntese: 1-4%"
  },
  {
    "descricao": "Fratura de úmero (braço) (osteomielite) (pseudoartrose) (umero)",
    "baremoEuropeu": "Consolidação viciosa: 1-5% Pseudoartrose: 8-12%",
    "baremoArgentino": "Pseudoartrose: 15-30%",
    "baremoPortugues": "Consolidação viciosa: 1-3% Pseudoartrose: 10-15% Osteomielite: 10-15%",
    "baremoEspanhol": "Consolidação viciosa: 1-5% Pseudoartrose sem infecção: 15% Pseudoartrose com infecção: 25% Osteomielite: 15% Material de osteossíntese: 1-5%"
  },
  {
    "descricao": "Fratura de vértebra (Coluna)",
    "baremoPortugues": "Fratura de uma vértebra com achatamento de até 50%: 1 a 10% Fratura de uma vértebra com achatamento de mais de 50%: 10 a 15%",
    "baremoEspanhol": "Fratura de uma vértebra com achatamento de até 50%: 1 a 10% Fratura de uma vértebra com achatamento de mais de 50%: 10 a 15%"
  },
  {
    "descricao": "Genun Varum (Varo) ou Valgum (Valgo) de joelho",
    "baremoEuropeu": "Até 10º: 0-3% De 10 a 20º: 4-10% Acima de 20º: 10-20%",
    "baremoArgentino": "Até 15º: 5-10% Maior que 15º: 10-20%",
    "baremoPortugues": "té 10º: 0-3% De 10 a 20º: 4-10% Acima de 20º: 10-20%"
  },
  {
    "descricao": "Hemianopsia",
    "baremoEuropeu": "Hemianopsia heterônima binasal: Hemianopsia heterônima bitemporal: Hemianopsia homônima",
    "baremoArgentino": "Se valora com base na diminuição da visão",
    "baremoPortugues": "Hemianopsia heterônima binasal: 15% Hemianopsia heterônima bitemporal: 5% Hemianopsia homônima: 12%",
    "baremoEspanhol": "Hemianopsia heterônima binasal: 40 a 50% Hemianopsia heterônima bitemporal: 30 a 40% Hemianopsia homônima 30 a 45%"
  },
  {
    "descricao": "Hemiplegia (coluna)",
    "baremoEuropeu": "Hemiplegia com afasia: 90% Hemiplegia sem afasia: 70%",
    "susep": "100%",
    "dpvat": "100%",
    "baremoArgentino": "Motora: 70 a 100% Sensitiva: 50 a 70% Alterna: 70 a 100% Com afasia: 90 a 100%",
    "baremoPortugues": "Com marcha possível com auxiliares, membro superior inutilizável, sem afasia: 51 a 60% Com marcha possível com auxiliares, membro superior inutilizável, com afasia: 71 a 80% Com marcha impossível e com alteração dos esfíncteres:71 a 91%"
  },
  {
    "descricao": "Hérnia inguinal",
    "baremoEuropeu": "a) Corrigidas cirurgicamente com sequelas que levam a repercussões funcionais 5-10%",
    "baremoArgentino": "a) Inguinal unilateral, não operada, sem conteúdo visceral: 0 a 5% b) Inguinal unilateral, não operada, com conteúdo visceral redutível: 5 a 9% c) Inguinal unilateral, não operada, com conteúdo visceral irredutível: 9 a 12% d) Inguinal unilateral, operada e recidivada, sem conteúdo visceral: 10 a 15% e) Inguinal unilateral, operada e recidivada, com conteúdo visceral redutível: 15 a 19% f) Inguinal unilateral, operada e recidivada, com conteúdo visceral irredutível: 19 a 22% g) Inguinal, operada com explicações (exceto recidiva): se avaia segundo o grau de complicação h) Inguinal , operada sem sequela: se soma 2% ao grau de dano derivado da cicatriz da pele i) Bilateral , operada ou não : se soma as incapacidades parciais de cada uma segundo o método da capacidade restante",
    "baremoPortugues": "a) Corrigidas cirurgicamente com sequelas que levam a repercussões funcionais 5-10%"
  },
  {
    "descricao": "Hipertiroidismo",
    "baremoEuropeu": "a) com deterioração constantes biológicas , tremor, exoftalmia sem efeitos sobre a visão: 5 a 8% b) com deterioração constantes biológicas , tremor, exoftalmia com repercussões sobre outros órgãos e / ou funções: 8 a 30%",
    "baremoArgentino": "Compensado com tratamento, operável: 15 a 30 % Compensado com tratamento, não operável: 25 a 40% Com má resposta ao tratamento: 60 a 70%",
    "baremoPortugues": "a) Hipertiroidismo, com alteração dos parâmetros biológicos, tremores e alterações visuais: 5 a 10% b) Hipertiroidismo, com alteração dos parâmetros biológicos, tremores e alterações visuais com repercussões sobre outros órgãos e ou funções 11 a 30%"
  },
  {
    "descricao": "Hipoparatireoidismo",
    "baremoEuropeu": "5-15%",
    "baremoArgentino": "a) Compensado com tratamento: 15 a 35% b) Com má resposta ao tratamento: 60 a 70%",
    "baremoPortugues": "5-30%"
  },
  {
    "descricao": "Hipotireoidismo",
    "baremoEuropeu": "1-5%",
    "baremoArgentino": "a) Parcial compensada com tratamento: 20-30% b) Total compensada com tratamento: 30-40% c) Com pouco ou nenhuma resposta ao tratamento:60-70%",
    "baremoPortugues": "1-5%"
  },
  {
    "descricao": "Incontinência urinária",
    "baremoEuropeu": "a) Incontinência urinária com possibilidade de controle terapêutico: 1 a 10% b) Incontinência urinária sem possibilidade de controle terapêutico: 10 a 30%",
    "baremoArgentino": "a) Esporádica ocorrendo somente com grandes esforços: 5 a 10% b) Permanente ocorrendo somente com grandes esforços: 10 a 15% c) Permanente ocorrendo com esforços leves a moderados: 15 a 30% d) Esporádica na posição em pé ou ao deambular: 30 a 45% e) Permanente com lesões cutâneas: 45 a 60%",
    "baremoPortugues": "a) Incontinência urinária com possibilidade de controle terapêutico: 1 a 10% b) Incontinência urinária sem possibilidade de controle terapêutico: 10 a 30%",
    "baremoEspanhol": "a) Incontinência urinária de esforço: 2 a 15% b) Incontinência urinária permanente: 30 a 40%"
  },
  {
    "descricao": "Instabilidade articular de joelho, lesão ligamentar",
    "baremoEuropeu": "Anterior: 2-10% Lateral: 1-10% Posterior: 3-12% Mista: 10-17%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "baremoArgentino": "Instabilidade sem artrose: 5-10% Instabilidade com artrose: 10-20%",
    "baremoPortugues": "Leve: 1-5% Medio: 6-15% Grave:16-20%",
    "baremoEspanhol": "Ligamentos laterias com sintomas: 1-10% Ligamentos cruzados: 1-15%"
  },
  {
    "descricao": "Instabilidade articular de tornozelo(laxidez)",
    "baremoEuropeu": "2-6%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "com gaveta positiva e com edema: 5% com gaveta positiva com edema e alterações artrósicas: 11-20%",
    "baremoPortugues": "2-5%",
    "baremoEspanhol": "1-7%"
  },
  {
    "descricao": "Instabilidade escapulo-umeral (ombro)",
    "baremoEuropeu": "1-3%"
  },
  {
    "descricao": "Insuficiência arterial (vascular) (artéria) (veia)",
    "baremoEuropeu": "a) Insuficiência arterial de membro superior 5 a 10% b) Insuficiência arterial de membros inferiores com queixas durante a claudicação 5 a 15% c) Insuficiência arterial de membros inferiores com queixas em repouso 15 a 25%",
    "baremoPortugues": "a) Insuficiência arterial de membro superior: 1 a 10% b) Insuficiência arterial de membros inferiores com queixas durante a claudicação: 5 a 10% c) Insuficiência arterial de membros inferiores com queixas em repouso: 11 a 20%",
    "baremoEspanhol": "a) Insuficiência arterial de membros inferiores com queixas durante a claudicação: 5 a 15% b) Insuficiência arterial de membros inferiores com transtornos tróficos: 15 a 25%"
  },
  {
    "descricao": "Insuficiência Renal (rim)",
    "baremoEuropeu": "a) Insuficiência Renal com clearance de creatinina entre 60 e 80 ml/mn: 5 a 10% b) Insuficiência Renal com clearance de creatinina entre 30 e 60 ml/mn: 10 a 15% c) Insuficiência Renal com clearance de creatinina entre 10 e 30 ml/mn: 15 a 20% d) Insuficiência Renal com clearance de creatinina inferior a 10 ml/mn: 20 a 40%",
    "baremoArgentino": "a) Leve (clearance de creatinina maior de 50 ml e dosagem de creatinina menor de 2,5 mg % e hematócrito maior de 30 %) : 20a 30% b) Moderada (clearance de creatinina entre 25 e 49 ml e dosagem de creatinina entre 3,9 e 2,5 mg % e/ou hematócrito entre 25 e 30 %) : 30 a 55 % c) Grave (clearance de creatinina menor de 25 ml e e dosagem de creatinina maior de 4 mg % e hematócrito menor de 25 %): 55 a 70%",
    "baremoPortugues": "a) Insuficiência Renal com clearence de creatinina entre 60 e 80 ml/mn: 5 a 15% b) Insuficiência Renal com clearence de creatinina entre 30 e 60 ml/mn: 15 a 25% c) Insuficiência Renal com clearence de creatinina entre 10 e 30 ml/mn: 25 a 35% d) Insuficiência Renal com clearence de creatinina inferior a 10 ml/mn: 36 a 65%",
    "louisMelennec": "a) Transtorno leve. Sintomas criam poucas dificuldades ou nenhuma menosvalia. Exame clinico é normal e as alterações de exame não são significativas (proteinúria, anomalias do sedimento urinário, cicatrizes). Estado geral é normal a capacidade de esforços e normal e a autonomia é total. Não há necessidade de medicamentos continuados: 0 a 5% Insuficiência renal assintomática b) Transtorno moderado: transtornos funcionais são mais notáveis , podendo causar algum grau de discapacidade sem chegar a ser grave. O exame clinico habitualmente é normal. Pode existir algum grau de astenia e da vida sócia e privada. Capacidade de esforços e normal e a autonomia é total.: Insuficiência Renal que cumpra os critérios acima. Geralmente clearance maior que 60 ml/mn: 5 a 15% c) Transtorno médio: Sintomas são importantes mas não graves. Existe algum grau de discapacidade patente sem chegar a ser grave. Existe uma clara, ainda que não grave, limitação da vida social e privada. Capacidade de esforços e normal e a autonomia é total. Insuficiência Renal com clearance de creatinina entre 30 e 60 ml/mn: 15 a 30% d) Transtorno importante: Sintomas são notáveis , criam limitações funcionais graves Existe uma limitação importante da vida social e privada. A autonomia ainda é total: Insuficiência Renal com clearance de creatinina entre 10 e 30 ml/mn: 15 a 30% 30 a 60% e) Transtornos graves: Limitação funcional é grave, traduzindo-se em invalidez, o estado geral já está alterado a autonomia e a capacidade de esforço estão reduzidas necessitando de ajuda de terceiros Insuficiência Renal com clearance de creatinina menor que 10 e 30 ml/mn: Mais de 60%",
    "baremoEspanhol": ") Insuficiência Renal com clearance de creatinina entre 90 e 120 ml/mn: 5 a 10% b) Insuficiência Renal com clearance de creatinina entre 60 e 90 ml/mn: 10 a 20% c) Insuficiência Renal com clearance de creatinina entre 30 e 60 ml/mn: 20 a 40% d) Insuficiência Renal com clearance de creatinina inferior a 30 ml/mn: 40 a 70%"
  },
  {
    "descricao": "Insuficiência supra-renal",
    "baremoEuropeu": "10 a 25%",
    "baremoArgentino": "Aguda tratada: se valora segundo as sequelas (se houverem) Crônica compensada com tratamento: 30 a 40% Crônica não compensada: 60 a 70¨%",
    "baremoPortugues": "10 a 25%"
  },
  { "descricao": "Joelho doloroso", "baremoPortugues": "1-3%" },
  {
    "descricao": "Lesão e paralisia do plexo braquial",
    "baremoEuropeu": "1) Completa em membro dominante: 65% 2) Completa em membro não dominante: 60%",
    "susep": "70%",
    "dpvat": "70%",
    "baremoArgentino": "1) Completa em membro dominante: 60% 2) Completa em membro não dominante: 55% 3) Radicular superior em membro dominante: 30 a 40% 4) Radicular superior em membro não dominante: 25 a 30% 5) Radicular inferior em membro dominante: 40 a 50% 6) Radicular inferior em membro não dominante: 35 a 45%",
    "baremoPortugues": "1) Paralisia ou paresia de todo o membro superior : 10 a 60% 2) Paralisia do plexo braquial de tipo superior (tipo Duchene -Erb) : 20 a 45% 3) Paralisia do plexo braquial de tipo inferior (tipo Dégérine -Klumpke): 40 a 50%",
    "baremoEspanhol": "1) Paralisia do plexo braquial (C5 e C6): 45 a 55% 1) Paralisia do plexo braquial (C7, C8 e T1): 30 a 55%"
  },
  {
    "descricao": "Ligeiras perturbações da expressão oral",
    "baremoEuropeu": "5%"
  },
  {
    "descricao": "Limitação na flexão e abdução do ombro a 130o dominante",
    "baremoEuropeu": "dominante: 3% não dominante: 2%"
  },
  {
    "descricao": "Limitação na flexão e abdução do ombro a 60o",
    "baremoEuropeu": "Dominante com perda: 22% Dominante sem perda : 18% Não dominante com perda :20% Não dominante sem perda: 16%"
  },
  {
    "descricao": "Limitação na flexão e abdução do ombro a 90o",
    "baremoEuropeu": "Dominante com perda: 22% Dominante sem perda : 18% Não dominante com perda :20% Não dominante sem perda: 16%"
  },
  {
    "descricao": "Limitação na mobilização de joelho",
    "baremoEuropeu": "Em extensão inferior a 10 graus: 0% Em extensão de 10 graus: 3% Em extensão de 20 graus: 5% Em extensão de 30 graus: 20% Em flexão limitada a 110 graus: 2% Em flexão limitada a 90 graus: 5% Em flexão limitada a 70 graus: 10% Em flexão limitada a 50 graus: 15% Em flexão limitada a 30 graus: 20%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%"
  },
  {
    "descricao": "Limitação na mobilização de quadril",
    "baremoEuropeu": "em flexão limitada a 90 graus: 4% em flexão limitada a 70 graus: 7% em flexão limitada a 30 graus: 13% em flexão total: 17% em extensão total: 2% rotação interna: 1% rotação externa: 3% adução: 6% abdução: 1%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "idem ao europeu",
    "baremoEspanhol": "em flexão movendo mais de 90 graus: 1-5% em flexão movendo entre 45 e 90 graus: 5-10% em flexão movendo menos de 45 graus: 10-15% em extensão: 1-5% rotação interna: 1-3% em rotação externa move mais de 30 graus: 1-3% em rotação externa move menos de 30 graus: 3-6% adução: 6% abdução move mais de 30 graus: 1-3% adução move menos de 30 graus: 3-6%"
  },
  {
    "descricao": "Linfedema",
    "baremoEuropeu": "de membro superior: até 10%",
    "baremoArgentino": "a) Alterações tróficas crônicas sem fibrose: 1 a 5% b) Fibrose crônica, sem alterações tróficas: 8 a 15% c) Fibrose crônica e alterações tróficas leves a moderada, sem úlceras ou infecções: 15 a 22% d) Crônica com importantes alterações tróficas ou úlceras: 22 a 35% e) Complicado com infecção: é avaliada de acordo com a sequência uma vez curada a infecção",
    "baremoPortugues": "DE MEMBRO SUPERIOR: até 10% DE MEMBRO INFERIOR a)Sensação de pernas pesadas com edema vespertino verificável: 1 a 4% b)Perturbação na marcha prolongada com edema permanente mensurável, necessitando de uso continua do de meia elástica de contenção: 5 a 10% c)Perturbação na marcha prolongada com edema permanente mensurável, necessitando de uso continua do de meia elástica de contenção; lesões tróficas e úlceras recidivantes: 11 a 20%",
    "baremoEspanhol": "10 a 15%"
  },
  {
    "descricao": "Língua (boca)",
    "baremoEuropeu": "Amputação da língua: 3 a 20%",
    "baremoArgentino": "Parcial sem alteração da fonação ou da deglutição: 10 a 15% Parcial com alteração da fonação ou da deglutição: se avalia de acordo com o grau de disfagia ou disartria Total: 30 a 50%",
    "baremoPortugues": "a) Mutilação parcial da língua no seu terço anterior: 5 a 15% b) Mutilação parcial da língua, abrangendo os dois terços anteriores: 16 a 25% c) Perda total da língua: 26% a 40%",
    "baremoEspanhol": "Cicatrizes retráteis da língua: 1 a 5% Amputação parcial da língua (menos de 50%): 5 a 20% Amputação parcial da língua (maia de 50%): 20 a 45% Amputação total da língua:45%"
  },
  {
    "descricao": "Lombalgia e lombociatalgia (lombar) (coluna)",
    "baremoEuropeu": "a) sem lesões ósseas ou disco-ligamentares implicando em uso medicações, com compromisso funcional: 1 a 3% b) com lesões ósseas ou disco-ligamentares documentadas , com comprometimento funcional, implicando em uso medicação ocasional: 3 a 10% c) com lesões ósseas ou disco-ligamentares documentadas ,com comprometimento funcional, implicando em uso medicação constante: 10 a 15% d) Em situações clinicas e radiológicas excepcionalmente severas: até 25%",
    "susep": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "a) Sintomas subjetivos de dor não confirmados por alterações estruturais patológicas nas radiografias , sem contratura dolorosa involuntária paravertebral: 0% b) Contratura muscular dolorosa persistente, perda da lordose nas radiografias com redução da amplitude do movimento , sem discopatia localizada 6 a 10% c) Contratura muscular dolorosa persistente, perda da lordose nas radiografias com redução da amplitude do movimento , com eletroneuromiografia alterada sem discopatia localizada 6 a 10% d) Contratura muscular e rigidez com alterações degenerativos discais: 8 a 15% e) Contratura muscular e rigidez com alterações degenerativos discais e eletroneuromiografia alterada unilateral: 10 a 25% f) Contratura muscular e rigidez com alterações degenerativos discais e eletroneuromiografia alterada unilateral: 25 a 40%",
    "louisMelennec": "a) Transtorno leve. Sintomas pouco importantes ou intermitentes, sinais ausentes ou quase normais e exames complementares com poucos significados do ponto de vista funcional: 1 a 5% b) Transtorno moderado: sintomas mais evidentes, exame clinico com pontos doloroso, podendo haver manifestação à distância. Força muscular e exame neurológico normal: Evidencia de uso de tratamento(medicação, fisioterapias): 5 a 15% c) Transtorno médio: Sintomas importantes mas não graves. Em todos os casos identifica-se sinais clínicos locais e à distância. A capacidade de esforços está diminuída . Necessidade de tratamentos contínuos: 15 a 30% d) Transtorno importante: A alteração funcional é permanente e incapacitante com exame clinico evidente. A capacidade de esforço está muito reduzida mas a autonomia ainda é completa. Necessidade de tratamentos contínuos: 30 a 60% e) Transtornos graves: Já existem complicações secundárias (respiratórias, cardíacas, neurológicas, etc) já com comprometimento da autonomia: Mais de 60%",
    "baremoEspanhol": "Limitação da mobilidade da coluna tóraco-lombar: 2 a 25%"
  },
  {
    "descricao": "Lordose (segundo arco de curvatura e graus) (lombar) (coluna)",
    "baremoArgentino": "a) Não operada, sem manifestações clínicas (contratura muscular), radiográficas (artroses localizada) nem neurológicas (eletroneuromiografia normal):2 a 4% b) Não operada, com manifestações clínicas (contratura muscular), radiográficas (artroses localizada) mas sem manifestações neurológicas (eletroneuromiografia normal): 4 a 8% c) Não Operada com manifestações neurológicas (eletroneuromiografia alterado): se avalia de acordo com o grau de lombociatalgia d) Operada com artrodese consolidada, sem ou com pouca contratura muscular paravertebral e neurológico normal: 8 a 15% d) Operada com artrodese consolidada, com contratura muscular paravertebral importante e neurológico normal: 15 a 25% e) Operada com artrodese não consolidada e alterações neurológicas moderadas a graves: 25 a 40%",
    "baremoPortugues": "a) Pequena a moderada: 1 a 5% b) Pronunciada: 6 a 10% c) Alterações da estática vertebral (cifose, escoliose, lordose) (segundo o arco de curvatura e graus): 1 a 12%",
    "louisMelennec": "a) Transtorno leve. Sintomas pouco importantes ou intermitentes, sinais ausentes ou quase normais e exames complementares com poucos significados do ponto de vista funcional: 1 a 5% b) Transtorno moderado: sintomas mais evidentes, exame clinico com pontos doloroso, podendo haver manifestação à distância. Força muscular e exame neurológico normal: Evidencia de uso de tratamento(medicação, fisioterapias): 5 a 15% c) Transtorno médio: Sintomas importantes mas não graves. Em todos os casos identifica-se sinais clínicos locais e à distância. A capacidade de esforços está diminuída . Necessidade de tratamentos contínuos: 15 a 30% d) Transtorno importante: A alteração funcional é permanente e incapacitante com exame clinico evidente. A capacidade de esforço está muito reduzida mas a autonomia ainda é completa. Necessidade de tratamentos contínuos: 30 a 60% e) Transtornos graves: Já existem complicações secundárias (respiratórias, cardíacas, neurológicas, etc) já com comprometimento da autonomia: Mais de 60%"
  },
  {
    "descricao": "Luxação acrômio-clavicular inoperável (ombro) (clavícula)",
    "baremoEuropeu": "1-3%",
    "baremoPortugues": "1-3%",
    "baremoEspanhol": "1-5%"
  },
  {
    "descricao": "Luxação recidivante de ombro",
    "baremoEuropeu": "Frequente: 5-12% Não frequente: -5%",
    "baremoArgentino": "12-15%",
    "baremoPortugues": "Quando inoperável: 3-13%",
    "baremoEspanhol": "5-15%"
  },
  {
    "descricao": "Mastectomia",
    "baremoEuropeu": "Unilateral: 10% Bilateral:25%",
    "baremoArgentino": "Unilateral parcial com preservação do mamilo: 0-5%",
    "baremoPortugues": "Unilateral: 10-15% Bilateral:16-25%",
    "baremoEspanhol": "Unilateral: 5-15% Bilateral: 15-25%"
  },
  {
    "descricao": "Nervo Ciático",
    "baremoEuropeu": "1) Nevralgia: 1-20% 2) Parasilia: 35 a 45%",
    "baremoPortugues": "1) Paralisia do Ciático popliteo externo: 20% 2) Paresia do Ciático popliteo externo: 5 a 12% 3) Paralisia do Ciático popliteo interno: 22% 4) Paresia do Ciático popliteo interno: 5 a 12%",
    "baremoEspanhol": "1) Paralisia do Ciático comum: 40% 2) Paresia do Ciático comum: 12 a 18% 3) Paralisia do Ciático popliteo externo: 5 a 12% 4) Paresia do Ciático popliteo externo: 3% 5) Paralisia do Ciático popliteo interno: 2-4% 6) Paresia do Ciático popliteo interno: 1%"
  },
  {
    "descricao": "Nervo Facial",
    "baremoEuropeu": "1) Nevralgia: 1-8% 2) Paralisia unilateral: 20% 3) Paralisia bilateral: 45%",
    "baremoPortugues": "1) Paralisia:11 a 20% 2) Paresia: 2 a 10%",
    "baremoEspanhol": "1) Paralisia:20% 2) Paresia: 5 a 15%"
  },
  {
    "descricao": "Nervo Femural",
    "baremoEuropeu": "1) Nevralgia: 1-3% 2) Paralisia: 35%",
    "baremoArgentino": "1) Leve: 0 a 3% 2) Moderada: 4 a 7% 3) Severa: 8 a 10%",
    "baremoPortugues": "1) Paralisia:35% 2) Paresia: 5 a 15%",
    "baremoEspanhol": "1) Paralisia:25% 2) Paresia: 6 a 12%"
  },
  {
    "descricao": "Nervo fibular",
    "baremoEuropeu": "1) Paralisia: 22%",
    "baremoPortugues": "1) Paralisia: 8% 2) Paresia: 2 a 4%",
    "baremoEspanhol": "1) Paralisia do fibular comum: 18% 2) Paralisia do fibular superficial(músculo-cutâneo): 3% 3) Paralisia do fibular profundo (tibial anterior): 2% 4) Paresia do fibular comum: 7 a 12% 5) Paresia do fibular superficial(músculo-cutâneo): 1% 6) Paresia do fibular profundo (tibial anterior): 2 a 4%"
  },
  {
    "descricao": "Nervo glossofaringeo",
    "baremoEuropeu": "1) Paralisia: 8%",
    "baremoArgentino": "1) Com hipoestesia ou anestesia do terço posterior da língua, sem disfagia: 8% 2) Com disfagia: valora-se a disfagia",
    "baremoPortugues": "1) Paralisia unilateral: 8 a 10% 2) Paralisia bilateral: 11 a 20% 3) com alteração do gosto: 21 a 30%",
    "baremoEspanhol": "1) Paralisia : 1 a 10% 2) Parestesia: 1 a 5% 3) Dor: 10 a 15%"
  },
  {
    "descricao": "Nervo hipoglosso",
    "baremoEuropeu": "1) Paralisia unilateral: 10%",
    "baremoArgentino": "Se valora segundo a paraisia da lingua",
    "baremoPortugues": "1) Paralisia unilateral: 1 a 5% 2) Bilateral: incapacidade é avaliada em função da disartria e das perturbações da mastigação e deglutição",
    "baremoEspanhol": "1) Paralisia unilateral: 7 a 10% 2) Paralisia Bilateral: 20% 3) Paresia: 1 a 7%"
  },
  {
    "descricao": "Nervo mediano (carpo)",
    "baremoEuropeu": "1) Paralisia ao nível do braço dominante: 35% 2) Paralisia ao nível do punho dominante: 25% 3) Paralisia ao nível do braço não dominante: 30% 4) Paralisia ao nível do punho não dominante: 20%",
    "baremoPortugues": "1) Paralisia ao nível do braço: 30 a 35% 2) Paralisia ao nível do punho: 20 a 25% 3) Paresia: 10 a 15%",
    "louisMelennec": "Dominante:49% Não dominante: 34%",
    "baremoEspanhol": "1) Paralisia ao nível do braço: 30 a 35% 2) Paralisia ao nível do punho: 10 a 15% 3) Paresia: 10 a 15% 4) Parestesias acras:1 a 5%"
  },
  {
    "descricao": "Nervo oculomotor",
    "baremoEuropeu": "Paralisia: até 15%",
    "baremoPortugues": "Paralisia: até 15%",
    "baremoEspanhol": "Paralisia: 25%"
  },
  {
    "descricao": "Nervo radial",
    "baremoEuropeu": "1) Paralisia acima do ramo tricipital membro dominante: 40% 2) Paralisia acima do ramo tricipital membro não dominante dominante: 35% 3) Paralisia abaixo do ramo tricipital membro dominante: 30% 4) Paralisia abaixo do ramo tricipital membro não dominante dominante: 25%",
    "baremoPortugues": "1) Paralisia a nível do braço: 30 a 35% 2) Paralisia a nível do antebraço/punho: 20 a 25% 3) Parestesia/Paresia: 10 a 15%",
    "baremoEspanhol": "1) Paralisia a nível do braço: 25 a 30% 2) Paralisia a nível do antebraço/punho: 20 a 25%"
  },
  {
    "descricao": "Nervo tibial",
    "baremoEuropeu": "1) Paralisia 22%",
    "susep": "0.00",
    "baremoPortugues": "1) Paralisia: 8% 2) Paresia: 2 a 4%",
    "baremoEspanhol": "1) Paralisia: 22% 2) Paresia: 5 a 8%"
  },
  {
    "descricao": "Nervo trigêmeo",
    "baremoEuropeu": "1) Nevralgia do trigêmeo contínua: 20-25% 2) Nevralgia do trigêmeo intermitente: 5-15% 3) Paralisia unilateral: 15% 4) Paralisia bilateral: 30%",
    "baremoPortugues": "afetação segundo o grau: 2 a 10%",
    "baremoEspanhol": "1) Nevralgia do trigêmeo contínua: 15-30% 2) Nevralgia do trigêmeo intermitente: 2-12% 3) Paralisia suborbitária(oftálmica): 5 a 10% 4) Paralisia inferior(maxilar): 5 a 10% 30% 5) Paralisia lingual (dento mandibular): 5 a 10%"
  },
  {
    "descricao": "Nervo ulnar",
    "baremoEuropeu": "1) Paralisia do membro dominante: 20% 2) Paralisia do membro não dominante: 20%",
    "baremoPortugues": "1) Paralisia a nível do braço:30 a 35% 2) Paralisia a nível do punho:20 a 25% 3) Parestesia:10 a 15%",
    "baremoEspanhol": "1) Paralisia a nível do braço:25 a 30% 2) Paralisia a nível do punho:10 a 15% 3) Parestesia:10 a 12%"
  },
  {
    "descricao": "Neurose de renda",
    "baremoArgentino": "Neurose de Renda: é um estado de espírito para alguns indivíduos em especiais sinistrados ou em busca de compensações , que possuem uma personalidade litigantes que exageram inconscientemente a impotência funcional, possuem anormalmente incapacidade prolongada para o trabalho, acentuam as consequências objetivas, outras subjetivas e empreendem um aumento da atividade paranoide em busca de compensação máxima. Dada a existência de um transtorno de personalidade prévia antes de danos, não dão direito à avaliação da deficiência como uma sequela do acidente de trabalho."
  },
  {
    "descricao": "Neuroses (Reações vivenciais anormais neuróticas)",
    "baremoArgentino": "a) Grau I: eles estão relacionados com situações cotidianas, a magnitude é leve e não interfere nas atividades da vida diária, nem a adaptação do ambiente. Eles não requerem tratamento em uma base permanente. 0% b) Grau II: os traços de personalidade de base são enfatizados, não apresentaram alterações no pensamento, concentração ou memória. Às vezes eles precisam de algum tipo de droga ou tratamento psicoterapêutico: 10% c) Grau III: exigem tratamento mais intensivo. Existem remissão dos sintomas mais agudos em cerca de 3 meses. Distúrbios de memória e concentração são verificados durante o exame psiquiátrico. As formas de apresentação são desde a depressão, a crises conversivas, crises de pânico, fobias e obsessões. Eles são reversíveis com o tratamento psicoterapêutico e psicofarmacológico adequado. Fazem controle por cerca de um ano: 20% d) Grau IV: Exigem uma assistência permanente de terceiros. A neurose Fóbicas, conversões histéricas, são as manifestações clínicas que ais invalidam neste grau. Depressões neuróticas também podem ser muito incapacitantes. 30% e) Neurose de Renda: é um estado de espírito para alguns indivíduos em especiais sinistrados ou em busca de compensações , que possuem uma personalidade litigantes que exageram inconscientemente a impotência funcional, possuem anormalmente incapacidade prolongada para o trabalho, acentuam as consequências objetivas, outras subjetivas e empreendem um aumento da atividade paranoide em busca de compensação máxima. Dada a existência de um transtorno de personalidade prévia antes de danos, não dão direito à avaliação da deficiência como uma sequela do acidente de trabalho."
  },
  {
    "descricao": "Nevralgia intercostal (nervo)",
    "baremoEuropeu": "1 a 3%",
    "baremoArgentino": "3 a 7%",
    "baremoPortugues": "1 a 3%"
  },
  {
    "descricao": "Obesidade",
    "baremoArgentino": "IMC 25-30 (sobrepeso): 0-3% IMC 30-39 : 4-8% IMC 40-49: 9-15% Maior 50: 16-30%"
  },
  {
    "descricao": "Ombro doloroso",
    "baremoPortugues": "3%",
    "baremoEspanhol": "1-5%"
  },
  {
    "descricao": "Osteoartrite crônica de joelho",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "baremoPortugues": "18-20%"
  },
  {
    "descricao": "Osteoartrite de quadril",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "20-25%",
    "baremoEspanhol": "20-35%"
  },
  {
    "descricao": "Osteoartrite séptica crônica do cotovelo",
    "baremoPortugues": "18-20%"
  },
  {
    "descricao": "Osteoartrite séptica crônica ombro",
    "susep": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "18-20%"
  },
  {
    "descricao": "Osteomielite crônica de fêmur",
    "baremoPortugues": "14.00%"
  },
  {
    "descricao": "Osteomielite crônica de tíbia e fíbula (tibia)",
    "baremoPortugues": "12.00%"
  },
  {
    "descricao": "Osteomielite de ossos do antebraço",
    "baremoPortugues": "6-10%",
    "louisMelennec": "6-10%"
  },
  {
    "descricao": "Osteoporose",
    "baremoArgentino": "primária sem complicações ou fraturas: 5-15% Com fraturas: se valora de acordo com as complicações"
  },
  {
    "descricao": "Panhipoptuitarismo",
    "baremoEuropeu": "20-45%",
    "baremoArgentino": "1) Compensado com tratamento: 20 a 45% 2) Com má resposta ao tratamento: 60 a 70%",
    "baremoPortugues": "20-45%",
    "baremoEspanhol": "10 a 20%"
  },
  {
    "descricao": "Paraparesia e paraplegia",
    "baremoEuropeu": "a) Paraparesia força muscular 1 ou 2: 51 -70% b) Paraparesia força muscular 3: 41-50% c) Paraparesia força muscular 4: 15-40% d) Paraplegia total, dependendo do nível: 70-75%",
    "baremoPortugues": "a) Paraparesia crural com marcha paraparética, com ou sem espasticidade sem alteração dos esfíncteres 20 a 50% b) Paraparesia crural com marcha paraparética, com ou sem espasticidade, com alteração dos esfíncteres: 51 a 70% c) Paraplegia (força muscular de grau 0 ou 1) sem alteração dos esfíncteres 60 a 70% d) Paraplegia (força muscular de grau 0 ou 1) com alteração dos esfíncteres: 71 a 80%",
    "baremoEspanhol": "a) Paraparesia de membros superiores ou inferiores grau leve: 30 a 40% b) Paraparesia de membros superiores ou inferiores grau moderado: 50 a 55% c) Paraparesia de membros superiores ou inferiores grau grave: 60 a 65% d) Paraplegia T1 a T5: 85% e) Paraplegia T6 a T10: 80% f) Paraplegia T11 a L1: 85% : 75%"
  },
  {
    "descricao": "Pelve (cintura pélvica) (pelve) (sacro) (púbis) (cóccix) (bacia) (púbis)",
    "baremoEuropeu": "a) Dor em região do cóccix: 1 a 3% b) Dores pós-fratura isquio-púbicas: 1 a 2% c) Dores e instabilidade na sínfise púbica: 2 a 5% d) Diastase sacro-ilíaca com dores: 2 a 5% e) Instabilidade da sínfise púbica e sacroilíacas associadas sem compromisso da marcha: 5 a 8% f) Instabilidade da sínfise púbica e sacroilíacas associadas com compromisso da marcha: 8 a 18%",
    "baremoArgentino": "a) Luxação do púbis : 5 a 15% b) Luxação sacro-ilíaca: 5 a 15% c) Luxação do púbis com luxação sacro-ilíaca: 20 a 30% (no caso de lesão de uretra adicionar-ver urologia) d) Exostose óssea sintomática (dor, tendinite, etc) sem alteração da função articular: 5 a 10% e) Exostose óssea sintomática (dor, tendinite, etc) com alteração da função articular: soma 10% a limitação da função articular f) Fratura de cóccix com deslocamento mínimo: 2 a 5% / g) com deslocamento importante (homem) 6 a 10% / com deslocamento importante , na mulher que impede ou dificulta parto: 20 a 30% / mulher sem possibilidade gravidez: 7 a 12% h) Fratura de sacro ou ilíaco (ver planilha específica)",
    "baremoPortugues": "a) Disjunção da articulação sacro -ilíaca: 10 a 20% b)Fraturas ou luxações dolorosas que impeçam a permanência na posição de sentado, na posição de cócoras ou que se traduzam na impossibilidade de utilizar o selim de velocípedes ou equiparáveis c) Fraturas sem rotura do anel pélvico: 5 a 10% d) Fraturas com rotura do anel pélvico: 11 a 25% e) Diástase da sínfise púbica sem sintomatologia (0%) e com sintomatologia (5 a 10%) f) Dores e/ou instabilidade da sínfise púbica: 2 a 5% f) Dores e/ou instabilidade da sínfise púbica e da articulação sacroilíaca SEM alteração da estática da bacia nem compromisso da marcha: 5 a 8% f) Dores e/ou instabilidade da sínfise púbica e da articulação sacroilíaca COM alteração estática da bacia e compromisso da marcha: 9 a 15% g) Dores relacionadas com diastase ou fractura sacro -ilíaca:2 a 5%"
  },
  {
    "descricao": "Perda da visão total (cegueira)",
    "baremoEuropeu": "1) Dos dois olho: 85% 2) De um olho: 25%",
    "susep": "1) Dos dois olho: 100% 2) De um olho: 30%",
    "dpvat": "1) Dos dois olho: 100% 2) De um olho: 50%",
    "baremoArgentino": "1) Dos dois olho: 100% 2) De um olho: 42%",
    "baremoPortugues": "1) Dos dois olho: 85% 2) De um olho: 25%",
    "baremoEspanhol": "1) Dos dois olho: 85% 2) De um olho: 25%"
  },
  {
    "descricao": "Perda do pênis e testículo (orquiectomia)",
    "baremoEuropeu": "Pênis: 40% Testículo unilateral: 6% Testículo bilateral: 15%",
    "baremoArgentino": "a) Lesão dos corpos cavernosos com angulação do pénis que não impede a relação sexual e manutenção da ereção: 5 a 10% b) Lesão dos corpos cavernosos com perda de ereção: Avaliados como indicado para disfunção erétil c) Amputação parcial do pênis que não impede a relação sexual e com a manutenção da ereção: 10 a 20% d) Amputação parcial do pênis com perda de ereção: Avaliados como indicado para disfunção erétil e) Amputação total do pênis: soma 10% disfunção erétil f) Emasculação total castração (perda de ambos os testículos e pênis): soma 10% um pênis de disfunção erétil g) Lesão de pênis com estenose uretral: somar 10% ao valor correspondente ao tipo de lesão, até um máximo de 70%",
    "baremoPortugues": "Pênis: 40% Testículo unilateral: 10-20% Testículo bilateral: 21-35%",
    "baremoEspanhol": "a) Desestruturação do Pênis sem estreitamento de uretra: 30-40% b) Desestruturação do Pênis sem estreitamento de uretra: 40-50% c) Testículo unilateral: 20-30% d) Testículo bilateral: 40%"
  },
  {
    "descricao": "Perda isolada da rotação externa do ombro",
    "baremoEuropeu": "dominante:3% não dominante: 2%"
  },
  {
    "descricao": "Perda isolada da rotação interna do ombro dominante",
    "baremoEuropeu": "dominante: 6% não dominante: 5%"
  },
  {
    "descricao": "Perturbação pós-traumática da mastigação, fonação, algias",
    "baremoEuropeu": "2-10%",
    "baremoPortugues": "2-10%",
    "baremoEspanhol": "a) Sem contato dentário: 15 a 30% b) Com contato dental Unilateral: 5 a 10% c) Com contato dental bilateral: 1 a 5"
  },
  {
    "descricao": "pseudoartrose da tíbia (perna) (tibia)",
    "baremoEuropeu": "10-15%",
    "baremoArgentino": "diáfise hipertrófica: 30-40% diáfise hipotrófica: 40-50% pilão tibial hipertrófica: 20-30% pilão tibial hipotrófica: 31-40% maléolo tibial: soma 8% à rigidez do tornozelo",
    "baremoPortugues": "14-17%",
    "baremoEspanhol": "com infecção: 30% sem infecção: 25%"
  },
  {
    "descricao": "Pseudoartrose de clavícula com dor esporádica (ombro)",
    "baremoEuropeu": "1-3%",
    "baremoPortugues": "1-3%",
    "baremoEspanhol": "5-10%"
  },
  {
    "descricao": "Pseudoartrose de escafóide (punho)",
    "baremoArgentino": "Pseudoartrose de escafóide: 15-18%"
  },
  {
    "descricao": "Pseudoartrose de úmero (ombro, braço umero)",
    "baremoEuropeu": "8-12%",
    "baremoPortugues": "10-15%",
    "baremoEspanhol": "Sem infecção ativa: 15% Com infecção ativa: 15%"
  },
  {
    "descricao": "Pseudoartrose do antebraço (rádio, ulna)",
    "baremoEuropeu": "8-12%",
    "susep": "Pseudoartrose diafisária de ulna: 9-12% Pseudoartrose olecraniana de ulna: 12-15% Pseudoartrose apófise estilóide de ulna: 0-1% Pseudoartrose de rádio e ulna: 20-30% Pseudoartrose diafisária de rádio: 6-9% Pseudoartrose apófise estilóide de rádio: 0-1%",
    "baremoPortugues": "ulna e rádio:10-15% ulna: 6-8% rádio: 8-10%",
    "baremoEspanhol": "ulna e rádio com infecção:20-25% ulna e rádio sem infecção:18-20% ulna sem infecção: 8-10% ulna com infecção: 10-15% rádio sem infecção: 6-8% rádio com infecção: 8-12%"
  },
  {
    "descricao": "Pulmão pneumectomia lobectomia",
    "baremoEuropeu": "a) Total: 15% b) Lobar: 5%",
    "baremoArgentino": "a) Total, com prova funcional respiratória normal: 31 a 40% a) Parcial, com prova funcional respiratória normal: 20 a 30% c) Parcial ou total, com prova funcional respiratória alterada: se valore de acordo com o grau de insuficência",
    "baremoPortugues": "a) Total: 15% b) Lobar: 5%",
    "baremoEspanhol": "a) Total: 12% b) Lobar: 5%"
  },
  { "descricao": "Punho doloroso", "baremoPortugues": "1-3%" },
  {
    "descricao": "Quadril doloroso",
    "baremoPortugues": "1-3%",
    "baremoEspanhol": "1-10%"
  },
  {
    "descricao": "Retração isquêmica de Volkmann (antebraço) (cotovelo)",
    "baremoArgentino": "20-40%",
    "baremoPortugues": "25-30%",
    "baremoEspanhol": "30-35%"
  },
  {
    "descricao": "Rigidez de punho",
    "baremoEuropeu": "Dominante com déficit na flexão: 0-4% Dominante com déficit na extensão: 0-6% Não dominante com déficit na flexão: 0-3% Não dominante com déficit na extensão: 0-5%",
    "susep": "Por analogia Grau leve: 5% Grau Médio: 10% Grau acentuado: 15%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoPortugues": "Déficit em flexão: 1-3% Déficit em extensão: 1-5% Perda no desvio radial ou ulnar: 1%",
    "baremoEspanhol": "Pronação: 1-5% Supinação: 1-5% Flexão: 1-7% Extensão: 1-8% Desvio radial: 1-3% Desvio ulnar: 1-3%"
  },
  {
    "descricao": "Rim",
    "baremoEuropeu": "Perda de um rim: 10-40%",
    "dpvat": "100% caso haja compromisso funcional",
    "baremoArgentino": "1) Parcial com rim contra-lateral são, sem insuficiência renal:5 a 15% 2) Parcial sem rim contra-lateral, sem insuficiência renal: 20 a 40% 3) Total com rim contra-lateral são, sem insuficiência renal: 15 a 30% 4) Com insuficiência renal",
    "baremoPortugues": "Perda de um rim: 15 %",
    "baremoEspanhol": "1) Nefrectomia unilateral: 20 a 25%. Caso tenha Insuficiência renal valor como esta última)"
  },
  {
    "descricao": "Rotura de bíceps (ombro, braço)",
    "baremoArgentino": "Proximal: 5-8% Distal: 6-9%"
  },
  { "descricao": "Rotura de deltóide (ombro)", "baremoArgentino": "10-15%" },
  {
    "descricao": "Rotura de Tríceps (ombro, braço)",
    "baremoArgentino": "9-12%"
  },
  {
    "descricao": "Secção de tendões (tendão, punho,flexores, extensores)",
    "baremoArgentino": "Secção de tendões flexores do antebraço ou punho: 5-10% Secção de tendões extensores do antebraço ou punho: 5-10%"
  },
  {
    "descricao": "Sequelas de lesões meniscais (joelho)",
    "baremoEuropeu": "1-5%",
    "susep": "Por analogia: 5%",
    "dpvat": "Por analogia: 5%",
    "baremoArgentino": "Não operado: 8-12% Meniscectomia total interna: 8-12% Meniscectomia total externa: 10-15% Meniscectomia parcial interna(vídeo): 3-6% Meniscectomia parcial externa(vídeo): 5-8%",
    "baremoPortugues": "1-5%",
    "louisMelennec": "5%"
  },
  {
    "descricao": "Sequelas de trauma craniano(síndrome pós-comocional), TCE, crânio",
    "baremoEuropeu": "2%"
  },
  {
    "descricao": "Sindrome da cauda equina",
    "baremoEuropeu": "15-30%",
    "baremoPortugues": "15 a 30% segundo o compromisso sensitivo, sexual e dos esfíncteres, tendo em conta as suas repercussões nas AVD; nos casos em que houver paraparésia, valorizar de acordo com grau",
    "baremoEspanhol": "a) Completo (transtornos motores, sensitivos e esfincterianos): 50 a 55% b) Incompleto Alto (L1, L2, L3): 35 a 40% c) Incompleto Médio (L4 a S2): 25 a 35% d) Incompleto Baixo (abaixo de S2): 15 a 20%"
  },
  {
    "descricao": "Síndrome frontal (cérebro) (cerebro)",
    "baremoEuropeu": "a) Maior: 60 a 85%% b) Severa: 40 a 60% c) Moderada: 20 a 40% d) Leve: 10 a 20% e) Menor: 5 a 10%",
    "baremoArgentino": "Dano Orgânico Cerebral a) alterações leves, mas pode trabalhar normalmente: 0 a 10% b) Pode trabalhar com algumas limitações para trabalhos de precisão, se comunica normalmente: 11 a 20% c) Pode trabalhar com algumas limitações para trabalhos de precisão, tem dificuldade para se comunicar, mas é possível entender: 21 a 25% d) Pode realizar o trabalho que exigem pouca precisão, se comunica normalmente: 31 a 40% e) Pode realizar trabalhos que exigem pouca precisão, tem dificuldade para se comunicar, mas é possível entender: 41 a 45% f) Só pode executar alguns trabalhos simples, mas pode cuidar de si mesmo, comunica-se normalmente: 46 a 60% g) Só pode executar alguns trabalhos simples mas pode se cuidar sozinho, tem dificuldades para se comunicar:61 a 65% h) Não pode trabalhar e assistência esporádica cuidados pessoais são necessários ou não podem ser compreendido: 66 a 80% i) requer supervisão contínua: 81 a 100%",
    "baremoPortugues": "a) Perturbação grave (com apragmatismo e alterações graves da inserção social e familiar.):61 a 85% b)Perturbação importante (com alteração das condutas instintivas, perda da iniciativa, perturbações do humor, inserção familiar e social precária.):36 a 60 c) Perturbação moderada (com bradipsiquismo relativo, dificuldade de memorização, perturbações do humor e repercussões na inserção social e familiar.): 21 a 35% d) Perturbação ligeira (com distratabilidade, lentificação, dificuldades de memorização e de elaboração de estratégias complexas; escassas ou nulas perturbações da inserção social e familiar.): 10 a 20%",
    "baremoEspanhol": "Deterioração das funções mentais superiores a) Muito grave(limitação grave de todas as funções diárias com necessidade de terceira pessoa, não é capaz de cuidar de si mesmo): 75 a 90% b) Grave: (impede as atividades úteis em quase funções sociais e interpessoais e requer supervisão contínua): 50 a 75% c) Moderado (limitação moderada de algumas atividades sociais, mas ão de todas.Requer supervisão contínua) 20 a 50% d) Leve: limitção leve das atividades interpessoais e sociais: 10 a 20%"
  },
  {
    "descricao": "Sindrome residual pós-algodistrofia de mão",
    "baremoPortugues": "3 a 5%"
  },
  {
    "descricao": "Síndromes femuro-patelares (joelho) (fêmur) (patela) (rótula)",
    "baremoEuropeu": "com dor frusta sem derrame articular: 0-3% com dor , crepitações e derrame articular: 3-5% com dor , crepitações e derrame articular e atrofia de quadríceps: 5-8%",
    "baremoPortugues": "1-8%"
  },
  {
    "descricao": "Surdez",
    "baremoEuropeu": "a) Bilateral: 60% b) Unilateral: 14%",
    "susep": "a) Bilateral: 40% b) Unilateral: 20%",
    "dpvat": "a) Bilateral: 50%",
    "baremoPortugues": "a) Bilateral: 60% b) Unilateral: 20%",
    "baremoEspanhol": "a) Bilateral: 70% b) Unilateral: 12%"
  },
  {
    "descricao": "Tendinopatia crônica de punho",
    "baremoArgentino": "Dominante: 2-4% Não dominante: 1-3%"
  },
  {
    "descricao": "Tendinopatias crônicas do supraespinhoso do ombro (Manguito Rotador)",
    "susep": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "dpvat": "Por analogia Grau leve: 6,25% Grau Médio: 12,5% Grau acentuado: 18,75%",
    "baremoArgentino": "Dominante: 4-8% Não dominante: 3-7%"
  },
  {
    "descricao": "Tetraplegia",
    "baremoEuropeu": "a) C2 a C6: 95% b) abaixo C6: 85%",
    "susep": "100%",
    "dpvat": "100%",
    "baremoArgentino": "a) acima de C4 (nenhuma mobilidade , sujeito a respirador: 100% b) C5-C6: (mobilidade de cintura escapular) 95% c) C7-C8: (pode mobilizar membros superiores): 90%",
    "baremoPortugues": "Tetraplegia:95%",
    "baremoEspanhol": "a) acima de C4 (nenhuma mobilidade , sujeito a respirador: 100% b) C5-C6: (mobilidade de cintura escapular) 95% c) C7-C8: (pode mobilizar membros superiores): 90%"
  },
  {
    "descricao": "Transplantes Cardíaco",
    "baremoEuropeu": "25-30%",
    "baremoPortugues": "10-20%"
  },
  {
    "descricao": "Transtorno de memória",
    "baremoEuropeu": "a) Transtorno de memória tipo 1 -severa (não se lembra de fatos antigos e orientação tempo-espacial) 40 a 60% b) Transtorno de memória tipo 2- moderada (limitação na aprendizagem e ligeira dificuldade na lembrança de fatos antigos): 20 a 40% c) Transtorno de memória tipo 3 -ligeira(problemas s na aprendizagem e dificuldade na fixação dos fatos aprendidos): 10 a 20% d) Transtorno de memória tipo 4 -minima (distúrbios na memória de fixação com perturbação sistemática da aprendizagem): 5 a 10% e) Transtorno de memória tipo 5 -(distúrbios na memória ligeiros sem outros problemas cognitivos associados",
    "baremoPortugues": "Grau I (perturbações graves, com reduzida ou nula capacidade para as AVD, requerendo vigilância e cuidados permanentes): 41 a 60% Grau II (perturbações moderadas, com manifesta diminuição do nível da eficiência pessoal, social e laboral). 11 a 40% Grau III (perturbações ligeiras, com discreta diminuição do nível da eficiência pessoal, social e laboral): 1 a 10%"
  },
  {
    "descricao": "Transtorno depressivo (depressão)",
    "baremoEuropeu": "Severo( 8 dos 10 sintomas da CID 10): 20 a 35% Moderado( 6 dos 10 sintomas da CID 10): 10 a 20% Leve (4 sintomas da CID 10): 3-10%",
    "baremoArgentino": "a) Leve: aparecem manifestações ligadas a situações cotidianas com relação ao efeito. Não há dificuldade em relação ao trabalho. Não há déficits de memória ou concentração. Pode ser tratada com terapias breves: 15-25% b) moderada: aparecem manifestações relacionadas com situações cotidianas completamente alheias ao conflito gerador. Já há alteração das relações de trabalho e vida familiar Não há déficits de memória ou concentração. Pode ser tratada com terapias prolongada: 25 a 50% c) Grave (aparecem manifestações relacionadas com situações cotidianas completamente alheias ao conflito de reação do gerador. Já há alteração das relações de trabalho e vida familiar Há distúrbios de memória e concentração, requer terapia prolongada e tratamento farmacológico: 50 a 70% d) Muito grave (requer assistência ou supervisão em curso, não pode trabalhar ou fazer uma vida independente): 70 a 100%"
  },
  {
    "descricao": "Traqueostomia (traquéia)",
    "baremoArgentino": "Traqueostomia definitiva: 50%",
    "baremoPortugues": "LARINGE a) Estenose parcial simples : 5 A 10% b) Estenose parcial com disfonia marcada .21-30% c) Estenose parcial com disfonia e dispneia 30 a 40% d) Estenose total (traqueostomia) 50 a 80% e) Laringectomia ou cânula permanente de traqueostomia 85%",
    "baremoEspanhol": "Traqueostomizado com necessidade permanente de cânula: 35 a 45% Estenose de traquéia: graduar conforme grau de insuficiência respiratória"
  },
  {
    "descricao": "Urostomia, cistostomia estomia",
    "baremoEuropeu": "Estomia urinária: 15%",
    "baremoPortugues": "a) Nefro ou ureterostomia cutânea definitiva unilateral: 31 a 60% b) Nefro ou ureterostomia cutânea definitiva bilateral ou sobre rim único: 46 a 60% c) Derivação uretero -intestinal: 46 a 60% d) Nefro ou ureterostomia com reimplantação vesical eficaz: 20 a 30% e) Ureteroplastia com segmento ileal: 30 a 45%"
  },
  {
    "descricao": "Varizes (veias) (venosa)",
    "baremoEuropeu": "a) Insuficiência venosa com sensação de pernas pesadas: 1 a 4% b) Insuficiência venosa com edema sem lesões tróficas e úlceras: 4 a 10% c) Insuficiência venosa com edema , lesões tróficas e úlceras 10 a 15%",
    "baremoPortugues": "a)Sensação de pernas pesadas com edema vespertino verificável: 1 a 4% b)Perturbação na marcha prolongada com edema permanente mensurável, necessitando de uso continua do de meia elástica de contenção: 5 a 10% c)Perturbação na marcha prolongada com edema permanente mensurável, necessitando de uso continua do de meia elástica de contenção; lesões tróficas e úlceras recidivantes: 11 a 20%",
    "baremoEspanhol": "a) Leve: varizes e hiperpigmentação: 1 a 8% b) Moderado: (edema, eczema, dor e celulite: 9 a 15% c) Grave: Ulceras e transtornos tróficos graves): 20 a 30%"
  },
  {
    "descricao": "Vertigem e acufenos",
    "baremoEuropeu": "a) Bilateral: 10 a 25% b) Unilateral: 4 a 10% c) Acufenos: 3% d) Vertigens paroxísticas benignas: 1 a 4%",
    "baremoArgentino": "a) Sintomatologia esporádica: 0 a 5% b)Sintomatologia permanente: se valora segundo o dano orgânico observado",
    "baremoPortugues": "a) Ligeira 1 a 5%5 b) Moderada com alteração electrofisiológica : 6 a 15% c) Severa com alteração electrofisiológica e com quedas ao chão: 16 a 25% c) Acufenos: 1 a 5%"
  }
]
