import React, { useState } from "react";
/* import { collection, getDocs } from "firebase/firestore";
import { TipoUsuarioContexto } from "../../../contexts/tipoUsuarioContexto";
import { db } from "../../../services/firebaseConfig"; */

export const DocSecVara = () => {
  const [doc, setDoc] = useState([
    {
      name: "Oficiar o INSS para apresentar relatório detalhado do prontuário do autor incluindo datas específicas dos períodos de afastamento e altas com respectivos CID encaminhando as cópias do CNIS, INFBEN, CRER, HISMED e LAUDOS MÉDICOS PERICIAIS;",
    },
    {
      name: "O perito desde já impugna os documentos apresentados pelas partes a partir de tal momento. Com fulcro no artigo 434 do CPC e o artigo 845 da CLT, que prevê que aos litigantes cabimento de junção aos autos processuais provas de suas assertivas na exordial e contestação. Ocorre que desde já se encontram preclusos quaisquer documentos juntados aos autos após os tais momentos processuais. Todavia, o artigo 435 caput e parágrafo único do CPC, abrindo exceção à regra geral, admite que as partes, a qualquer tempo, juntem aos autos documentos novos, quando destinados a fazer prova de fatos ocorridos depois dos articulados ou para contrapô-los aos que foram produzidos aos autos.",
    },
  ]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (name === "allSelected") {
      const tempDoc = doc.map((e) => {
        return { ...e, isChecked: checked };
      });
      setDoc(tempDoc);
    } else {
      const tempDoc = doc.map((e) => {
        return e.name === name ? { ...e, isChecked: checked } : e;
      });
      setDoc(tempDoc);
    }
  };

  const [docSecVara, setDocSecVara] = useState([]);

  const adicionarDocSecVara = (e) => {
    e.preventDefault();

    const dadoSelecionado = [];
    doc.map(({ name, isChecked }) => {
      if (isChecked === true) {
        dadoSelecionado.push(name);
      }
      return null;
    });
    if (dadoSelecionado) {
      setDocSecVara(dadoSelecionado);

      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _07_docSecVara: dadoSelecionado,
        })
      );
    }
  };

  const excluirDocSecVara = (e) => {
    e.preventDefault();
    docSecVara.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        docSecVara.splice(i, 1);
        setDocSecVara([...docSecVara]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _07_docSecVara: docSecVara,
          })
        );
      }
      return null;
    });
  };

  const personalizacaoDoUsuario = /* async */ () => {
    /* const getRefs = (docs) => {
            return docs.map((doc) =>
              doc ? doc.ref : console.log(`Um erro ocorreu`)
            );
          };
          const getIds = (refs) => {
            return refs.map((ref) =>
              ref ? ref.id : console.log(`Um erro ocorreu`)
            );
          }; */

    const docPersonalizado = JSON.parse(
      localStorage.getItem("tudo")
    )._04_docHonorarios; /* getIds(getRefs((await getDocs(collection(db, "users"))).docs)) */
    if (docPersonalizado && docPersonalizado.length > 0) {
      let z = [];
      docPersonalizado.map((e) => {
        z.push({
          name: e.toString(),
        });
        return z;
      });
      setDoc(
        [
          {
            name: "Oficiar o INSS para apresentar relatório detalhado do prontuário do autor incluindo datas específicas dos períodos de afastamento e altas com respectivos CID encaminhando as cópias do CNIS, INFBEN, CRER, HISMED e LAUDOS MÉDICOS PERICIAIS;",
          },
          {
            name: "O perito desde já impugna os documentos apresentados pelas partes a partir de tal momento. Com fulcro no artigo 434 do CPC e o artigo 845 da CLT, que prevê que aos litigantes cabimento de junção aos autos processuais provas de suas assertivas na exordial e contestação. Ocorre que desde já se encontram preclusos quaisquer documentos juntados aos autos após os tais momentos processuais. Todavia, o artigo 435 caput e parágrafo único do CPC, abrindo exceção à regra geral, admite que as partes, a qualquer tempo, juntem aos autos documentos novos, quando destinados a fazer prova de fatos ocorridos depois dos articulados ou para contrapô-los aos que foram produzidos aos autos.",
          },
        ].concat(z)
      );
    }
  };

  /* useMemo(() => {
    setDoc([
      {
        name: "Oficiar o INSS para apresentar relatório detalhado do prontuário do autor incluindo datas específicas dos períodos de afastamento e altas com respectivos CID encaminhando as cópias do CNIS, INFBEN, CRER, HISMED e LAUDOS MÉDICOS PERICIAIS;",
      },
      {
        name: "O perito desde já impugna os documentos apresentados pelas partes a partir de tal momento. Com fulcro no artigo 434 do CPC e o artigo 845 da CLT, que prevê que aos litigantes cabimento de junção aos autos processuais provas de suas assertivas na exordial e contestação. Ocorre que desde já se encontram preclusos quaisquer documentos juntados aos autos após os tais momentos processuais. Todavia, o artigo 435 caput e parágrafo único do CPC, abrindo exceção à regra geral, admite que as partes, a qualquer tempo, juntem aos autos documentos novos, quando destinados a fazer prova de fatos ocorridos depois dos articulados ou para contrapô-los aos que foram produzidos aos autos.",
      },
    ]);
  }, []); */

  //console.log('**doc',doc)

  const excluirTudoDocSecVara = (e) => {
    e.preventDefault();

    setDocSecVara([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _07_docSecVara: [],
      })
    );
  };

  const handleCarregarFormDocSecVara = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));
    if (tudo._07_docSecVara && tudo._07_docSecVara.length > 0) {
      const a =
        JSON.parse(localStorage.getItem("tudo"))._07_docSecVara !== undefined ||
        JSON.parse(localStorage.getItem("tudo"))._07_docSecVara !== null
          ? JSON.parse(localStorage.getItem("tudo"))._07_docSecVara
          : [];

      setDocSecVara(a);
    }
  };

  return (
    <>
      <fieldset>
      <legend>Documentos para a Secretaria da Vara</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>
                  {" "}
                  <span>Ação</span>
                  <span>Informação</span>
                  <button onClick={personalizacaoDoUsuario}>botao teste</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="allSelected"
                    onChange={handleChange}
                  />
                </th>
                <th>Selecionar todos</th>
              </tr>
              {doc.map((e, id) => {
                return (
                  <tr key={id}>
                    <th>
                    <label>
                        <div>
                        <input
                          type="checkbox"
                          name={e.name}
                          checked={e.isChecked || false}
                          onChange={handleChange}
                        /></div>
                        <span>
                        {e.name}
                        </span>
                        </label>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarDocSecVara}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormDocSecVara}>
                      Carregar formulário
                    </button>
                  </th>
                  {/* <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th> */}
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Documentos relativos ao reclamante Registrados</legend>
            {docSecVara.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docSecVara.length > 0 &&
                      docSecVara.map((e, i) => {
                        return (
                          <tr key={i}>
                            <th>
                              <button onClick={excluirDocSecVara}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                            <th>{e}</th>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoDocSecVara}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoDocSecVara"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            )}
          </fieldset>
        </fieldset>
     </div> </fieldset>
    </>
  );
};
