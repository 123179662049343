import { auth, db, firebaseApp } from "../services/firebaseConfig";
import {
  addDoc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";

export const StripePagamentoContexto = createContext();

export const StripePagamentoProvider = ({ children }) => {

  const getCheckoutUrl = async (firebaseApp, priceId) => {
    const user = auth.currentUser;
  
    if (!user.uid) throw new Error("Usuário não autenticado!!");
  
    const CheckoutSessionRef = collection(
      db,
      "customers",
      user.uid,
      "checkout_sessions"
    );
  
    const docRef = await addDoc(CheckoutSessionRef, {
      price: priceId,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
  
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        console.log("*getCheckoutUrl", snap.data());
        if (error) {
          unsubscribe();
          reject(new Error(`Um erro ocorreu: ${error.message}`));
        }
  
        if (url) {
          //console.log("URL do checkout:", url);
          unsubscribe();
          resolve(url);
        }
      });
    });
  };
  
  const getPortalUrl = async () => {
    const user = auth.currentUser;
  
    let dataWithUrl = "";
    try {
      const functions = getFunctions(firebaseApp, "southamerica-east1");
      const functionRef = httpsCallable(
        functions,
        "ext-firestore-stripe-payments-createPortalLink"
      );
      const { data } = await functionRef({
        customerId: user.uid,
        returnUrl: window.location.origin,
      });
  
      dataWithUrl = data;
      console.log("*getPortalUrl", data);
      //console.log("Direcionando para o portal do Stripe", dataWithUrl.url);
    } catch (error) {
      console.error(error);
    }
  
    return new Promise((resolve, reject) => {
      if (dataWithUrl.url) {
        resolve(dataWithUrl.url);
      } else {
        reject(new Error("Não retornou Url"));
      }
    });
  };

  const navegar = useNavigate();

  const UpGradeToPremium = async () => {
    const priceId = "price_1PdGyn2LucSx3txB3TVBe2jB";
    const checkoutUrl = await getCheckoutUrl(firebaseApp, priceId);
    // console.log(checkoutUrl)
    const x = checkoutUrl.substring(34);
    const y = checkoutUrl.substring(7);
    sessionStorage.setItem(
      "@CheckoutAssinatura",
      JSON.stringify({
        checkoutUrl: checkoutUrl,
        inicioUrl: y,
        fimUrl: x,
      })
    );
    console.log("Voce está sendo direcionado para o carrinho de compras...");
    navegar(`/Home/CheckoutAssinatura/checkout.stripe.com/c/pay/`);
  };

  const ManageSubscription = async () => {
    const portalUrl = await getPortalUrl(firebaseApp);
    const x = portalUrl.substring(37);
    const y = portalUrl.substring(7);
    sessionStorage.setItem(
      "@PortalAssinatura",
      JSON.stringify({
        portalUrl: portalUrl,
        inicioUrl: y,
        fimUrl: x,
      })
    );
    //console.log("Voce está sendo direcionado para o portal de assinatura...")
    navegar(`/Home/PortalAssinatura/billing.stripe.com/p/session`);
  };

  return (
    <StripePagamentoContexto.Provider
      value={{
        getCheckoutUrl,
        getPortalUrl,
        UpGradeToPremium,
        ManageSubscription
      }}
    >
      {children}
    </StripePagamentoContexto.Provider>
  );
};
