import React from 'react'

import { DadosInicial } from './dadosInicial/DadosInicial'
import { DadosContestacao } from './dadosContestacao/DadosContestacao'
import { DadosAtestado } from './dadosAtestado/DadosAtestado'
import { DadosInss } from './dadosInss/DadosInss'
import { DadosInssPericia } from './dadosInssPericia/DadosInssPericia'
import { DadosCat } from './dadosCat/DadosCat'
import { DadosDocSst } from './dadosDocSst/DadosDocSst'
import { DadosDocOutros } from './dadosDocOutros/DadosDocOutros'
import { DadosTrabEmpresa } from './dadosTrabEmpresa/DadosTrabEmpresa'
import { DadosHistoricoLaboral } from './dadosHistoricoLaboral/DadosHistoricoLaboral'
import { Outlet } from 'react-router-dom'

export const DadosProcessuais = () => {

    return (

        <>
                    <Outlet>
                    <DadosInicial />
                    <DadosContestacao />
                    <DadosAtestado />
                    <DadosInss />
                    <DadosInssPericia />
                    <DadosCat />
                    <DadosDocSst />
                    <DadosDocOutros />
                    <DadosTrabEmpresa />
                    <DadosHistoricoLaboral />
                    </Outlet>
        </>
    )
}



