import React, { useState } from "react";

export const QuesitosReclamada = () => {
    const [formValues, setFormValues] = useState({});
  
    const handleInputChange = (e) => {
      const { name, value, type } = e.target;
  
      const data = formValues[name] || {};
      const isCheckbox = type === "checkbox";
      const newValue = isCheckbox ? data : value;
  
      setFormValues({ ...formValues, [name]: newValue });
      // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    };
  
    const [quesitosReclamada, setQuesitosReclamada] = useState([]);
  
    const adicionarQuesitosReclamada = (e) => {
      e.preventDefault();
  
      const arrQuesitosReclamada = [];
  
      arrQuesitosReclamada.push({
        idQuesitosReclamada0:
          formValues.idQuesitosReclamada0 !== undefined
            ? formValues.idQuesitosReclamada0
            : "",
        conteudoQuesitosReclamada0:
          formValues.conteudoQuesitosReclamada0 !== undefined
            ? formValues.conteudoQuesitosReclamada0
            : "",
      });
      setQuesitosReclamada([...arrQuesitosReclamada]);
  
      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _76_quesitosReclamada: [
            {
              idQuesitosReclamada0:
                formValues.idQuesitosReclamada0 !== undefined
                  ? formValues.idQuesitosReclamada0
                  : "",
              conteudoQuesitosReclamada0:
                formValues.conteudoQuesitosReclamada0 !== undefined
                  ? formValues.conteudoQuesitosReclamada0
                  : "",
            },
          ],
        })
      );
    };
    const excluirQuesitosReclamada = (e) => {
      e.preventDefault();
      quesitosReclamada.filter((el, i) => {
        if (
          e.target.attributes.value.value !== undefined &&
          e.target.attributes.value.value === String(i)
        ) {
          quesitosReclamada.splice(i, 1);
          setQuesitosReclamada([...quesitosReclamada]);
  
          localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _76_quesitosReclamada: [...quesitosReclamada],
            })
          );
        }
        return null;
      });
    };
  
  
    const excluirTudoQuesitosReclamada = (e) => {
      e.preventDefault();
  
      setQuesitosReclamada([]);
  
      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _76_quesitosReclamada: [],
        })
      );
    };
  
    const handleCarregarFormQuesitosReclamada = () => {
      const tudo = JSON.parse(localStorage.getItem("tudo"));
  
      const x = () => {
      if (tudo._76_quesitosReclamada && tudo._76_quesitosReclamada.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._76_quesitosReclamada !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._76_quesitosReclamada !== null
            ? JSON.parse(localStorage.getItem("tudo"))._76_quesitosReclamada
            : [];
  
        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            idQuesitosReclamada0:
              e !== undefined && e.idQuesitosReclamada0 !== undefined
                ? e.idQuesitosReclamada0
                : "",
            conteudoQuesitosReclamada0:
              e !== undefined && e.conteudoQuesitosReclamada0 !== undefined
                ? e.conteudoQuesitosReclamada0
                : "",
          })
        );
      }}
  
      const y = () => {
        if (tudo._76_quesitosReclamada && tudo._76_quesitosReclamada.length > 0) {
          const a =
            JSON.parse(localStorage.getItem("tudo"))._76_quesitosReclamada !==
              undefined ||
            JSON.parse(localStorage.getItem("tudo"))._76_quesitosReclamada !== null
              ? JSON.parse(localStorage.getItem("tudo"))._76_quesitosReclamada
              : [];
    
          //console.log('***',a)
          a.map((e) =>
            setQuesitosReclamada([{
              idQuesitosReclamada0:
                e !== undefined && e.idQuesitosReclamada0 !== undefined
                  ? e.idQuesitosReclamada0
                  : "",
              conteudoQuesitosReclamada0:
                e !== undefined && e.conteudoQuesitosReclamada0 !== undefined
                  ? e.conteudoQuesitosReclamada0
                  : "",
            }])
          );
        }}
  
        x()
        y()
  
  
    };
  
    const handleReset = () => {
      setFormValues({});
    };
  
    const handleLimparCampo = (e) => {
      e.preventDefault();
      if (e.target.attributes.value) {
        setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
      }
    };
  
    return (
      <>
        <fieldset>
          <legend>Quesitos da Reclamada</legend>
          <div >
          <fieldset >
            <table >
            <thead>
                  <tr>
                    <th>Limpar Campo</th>
                    <th>Informação</th>
                  </tr>
                </thead>
              <tbody>
                <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i className="fas fa-trash perigo" value={"idQuesitosReclamada0"}></i>
                    </button>
                  </th>
                  <th>
                    <input
                      name="idQuesitosReclamada0"
                      placeholder="Id - página"
                      onChange={handleInputChange}
                      value={
                        formValues.idQuesitosReclamada0 !== undefined
                          ? formValues.idQuesitosReclamada0
                          : ""
                      }
                    />
                  </th>
                  </tr>
                  <tr>
                  <th>
                    <button onClick={handleLimparCampo}>
                      <i className="fas fa-trash perigo" value={"conteudoQuesitosReclamada0"}></i>
                    </button>
                  </th>
                  <th>
                    <textarea
                      name="conteudoQuesitosReclamada0"
                      cols="30"
                      rows="10"
                      placeholder="Digite o conteúdo aqui."
                      onChange={handleInputChange}
                      value={
                        formValues.conteudoQuesitosReclamada0 !== undefined
                          ? formValues.conteudoQuesitosReclamada0
                          : ""
                      }
                    />
                  </th>
                  </tr>
                
                  
                
              </tbody>
            </table>
            <fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarQuesitosReclamada}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormQuesitosReclamada}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>
  
            <fieldset>
              <legend>Dados registrados dos quesitos da Reclamada</legend>
              {quesitosReclamada.length === 0 ? (
                <p>Sem dados registrados</p>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Tópico</th>
                        <th>Informação</th>
                      </tr>
                    </thead>
                    {quesitosReclamada.length > 0 &&
                      quesitosReclamada.map(
                        ({ idQuesitosReclamada0, conteudoQuesitosReclamada0 }, i) => {
                          return (
                            <tbody key={i}>
                              
                              <tr >
                              <th>Registro número</th>
                              <th> {i + 1}</th>
                            </tr>
                            <tr >
                              <th>Id</th>
                              <th>{idQuesitosReclamada0}</th>
                            </tr>
                            <tr >
                              <th>Conteúdo</th>
                              <th>{conteudoQuesitosReclamada0}</th>
                            </tr>
                            <tr>
                                  <th>Ação</th>
                                  <th>
                                    <button onClick={excluirQuesitosReclamada}>
                                      <i
                                        className="fas fa-solid fa-trash perigo"
                                        value={i}
                                      ></i>
                                    </button>
                                  </th>
                                </tr>
  
                            </tbody>
                          );
                        }
                      )}
                  </table>
                  <fieldset>
                      <legend>Ação</legend>
                      <table>
                        <thead>
                          <tr>
                            <th>Excluir todos os documentos?</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <button onClick={excluirTudoQuesitosReclamada}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={"excluirTudoQuesitosReclamada"}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </>
                )}
              </fieldset>
            </fieldset>
          </div>{" "}
        </fieldset>
      </>
    );
  };
  