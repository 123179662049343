import React, {useState} from 'react'
import { UtilitariosBuscaCbo, UtilitariosBuscaCnaeAcentuado, UtilitariosBuscaNtepListaC, UtilitariosBuscaTabelaDpvat } from "../../utilitarios/UtilitariosBusca"
import styles from '../repercussaoAtividadesDesportivas/RepercussaoAtividadesDesportivas.module.css'

export const RepercussaoAtividadesDesportivas = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
       // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ repercussaoAtividadesDesportivas, setRepercussaoAtividadesDesportivas] = useState([])
   
    const adicionarRepercussaoAtividadesDesportivas = (e) => {

        e.preventDefault()

        const arrRepercussaoAtividadesDesportivas = []
    
        arrRepercussaoAtividadesDesportivas.push({
          //  perguntaRepercussaoAtividadesDesportivas0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaRepercussaoAtividadesDesportivas0: formValues.respostaRepercussaoAtividadesDesportivas0 !== undefined ? formValues.respostaRepercussaoAtividadesDesportivas0 : '',
            comentarioRepercussaoAtividadesDesportivas0: formValues.comentarioRepercussaoAtividadesDesportivas0 !== undefined ? formValues.comentarioRepercussaoAtividadesDesportivas0 : '',     
        })
        setRepercussaoAtividadesDesportivas([...arrRepercussaoAtividadesDesportivas])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _75_repercussaoAtividadesDesportivas: [{
           //     perguntaRepercussaoAtividadesDesportivas0: "Compreende o período temporário durante o qual a parte autora ficou impedida de realizar com razoável autonomia suas atividades comuns do dia a dia e suas atividades profissionais. Em geral compreende o período do início do afastamento previdenciário até a data da consolidação/cura das lesões.",
            respostaRepercussaoAtividadesDesportivas0: formValues.respostaRepercussaoAtividadesDesportivas0 !== undefined ? formValues.respostaRepercussaoAtividadesDesportivas0 : '',
         comentarioRepercussaoAtividadesDesportivas0: formValues.comentarioRepercussaoAtividadesDesportivas0 !== undefined ? formValues.comentarioRepercussaoAtividadesDesportivas0 : '',
            }]
      }))
    }

    const excluirRepercussaoAtividadesDesportivas = (e) => {
        e.preventDefault()  

        setRepercussaoAtividadesDesportivas([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _75_repercussaoAtividadesDesportivas: []
              }))
    }

    const handleCarregarFormRepercussaoAtividadesDesportivas = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._75_repercussaoAtividadesDesportivas && tudo._75_repercussaoAtividadesDesportivas.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._75_repercussaoAtividadesDesportivas !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._75_repercussaoAtividadesDesportivas !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._75_repercussaoAtividadesDesportivas : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
   // perguntaRepercussaoAtividadesDesportivas0: e !== undefined && e.perguntaRepercussaoAtividadesDesportivas0 !== undefined ? e.perguntaRepercussaoAtividadesDesportivas0 : '',
            respostaRepercussaoAtividadesDesportivas0: e !== undefined &&  e.respostaRepercussaoAtividadesDesportivas0 !== undefined ? e.respostaRepercussaoAtividadesDesportivas0 : '',
            comentarioRepercussaoAtividadesDesportivas0: e !== undefined &&  e.comentarioRepercussaoAtividadesDesportivas0 !== undefined ? e.comentarioRepercussaoAtividadesDesportivas0 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparRepercussaoAtividadesDesportivas = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaRepercussaoAtividadesDesportivas'+e.target.attributes.value.value]: '', ['comentarioRepercussaoAtividadesDesportivas'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxRepercussaoAtividadesDesportivas} />
            <label htmlFor={styles.checkboxRepercussaoAtividadesDesportivas}>
                <h2 className={styles.titulo}>Repercussão Atividades Desportivas</h2>
            </label>
            <fieldset id={styles.conteudoRepercussaoAtividadesDesportivas}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        {/* <th>Pergunta</th> */}
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="0"> 
                        <th><button onClick={handleLimparRepercussaoAtividadesDesportivas}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>
                        <select 
                                name="respostaRepercussaoAtividadesDesportivas0"
                                onChange={handleInputChange}
                                value={formValues.respostaRepercussaoAtividadesDesportivas0 !== undefined ? formValues.respostaRepercussaoAtividadesDesportivas0 : ''}
                            >
                                <option value="" defaultValue>Selecione:</option>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select> 
                            </th>
                        <th>
                        <textarea 
                            name="comentarioRepercussaoAtividadesDesportivas0"  
                            cols="30" 
                            rows="10" 
                            placeholder="Digite seu comentário aqui."
                            onChange={handleInputChange}
                            value={formValues.comentarioRepercussaoAtividadesDesportivas0 !== undefined ? formValues.comentarioRepercussaoAtividadesDesportivas0 : ''}
                        />
                        </th>
                    </tr>                    
                </tbody>
            </table>
            <div>
            <button onClick={adicionarRepercussaoAtividadesDesportivas}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormRepercussaoAtividadesDesportivas}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados relativos a repercussão nas atividades desportivas</legend>
            {
            repercussaoAtividadesDesportivas.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                           {/*  <th>Pergunta</th> */}
                            <th>Resposta</th>
                            <th>Comentário</th> 
                        </tr>
                    </thead>
                        {repercussaoAtividadesDesportivas.length > 0 && repercussaoAtividadesDesportivas.map(({ 
                           // perguntaRepercussaoAtividadesDesportivas0,
                            respostaRepercussaoAtividadesDesportivas0,
                            comentarioRepercussaoAtividadesDesportivas0,
                        }, i)=>{

                        return(                  
                            <tbody key={i}>                          
                            <tr >
                               {/*  <th>{perguntaRepercussaoAtividadesDesportivas0}</th> */}
                                <th>{respostaRepercussaoAtividadesDesportivas0}</th>
                                 <th>{comentarioRepercussaoAtividadesDesportivas0}</th> 
                            </tr>
                            </tbody>                        
                    )})}                
                    </table>
                    <div>
                    <button onClick={excluirRepercussaoAtividadesDesportivas}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
                <>         
                <UtilitariosBuscaTabelaDpvat/>
                <UtilitariosBuscaNtepListaC/>
                <UtilitariosBuscaCnaeAcentuado/>
                <UtilitariosBuscaCbo/>           
            </>
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )   
}
