import React, { useState, useEffect } from "react";

export const DadosHistoricoLaboral = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log(
      "***data",
      data,
      "***formValues",
      formValues,
      "***newValue",
      newValue
    );
  };

  const [trab, setTrab] = useState([]);

  const adicionarTrab = () => {
    const arrTrab = [];
    arrTrab.push({
      //id: trab !== undefined ? trab.length + 1 : '',
      historicoLaboral:
        formValues.historicoLaboral !== undefined
          ? formValues.historicoLaboral
          : "",
      dataAdmissao:
        formValues.dataAdmissao !== undefined &&
        formValues.dataAdmissao.length === 10
          ? formValues.dataAdmissao
          : "",
      dataAdmissaoAmericano:
        formValues.dataAdmissaoAmericano !== undefined &&
        formValues.dataAdmissaoAmericano.length === 10
          ? formValues.dataAdmissaoAmericano
          : "",
      dataDemissao:
        formValues.dataDemissao !== undefined &&
        formValues.dataDemissao.length === 10
          ? formValues.dataDemissao
          : "",
      dataDemissaoAmericano:
        formValues.dataDemissaoAmericano !== undefined &&
        formValues.dataDemissaoAmericano.length === 10
          ? formValues.dataDemissaoAmericano
          : "",
      qtdeDiasTrabalho:
        formValues.qtdeDiasTrabalho !== undefined
          ? formValues.qtdeDiasTrabalho
          : "",
      funcaoTrabalho:
        formValues.funcaoTrabalho !== undefined
          ? formValues.funcaoTrabalho
          : "",
      nomeEmpresaTrabalho:
        formValues.nomeEmpresaTrabalho !== undefined
          ? formValues.nomeEmpresaTrabalho
          : "",
    });

    setTrab([...trab, ...arrTrab]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _18_trab: [
          ...trab,
          {
            //id: trab !== undefined ? trab.length + 1 : '',
            historicoLaboral:
              formValues.historicoLaboral !== undefined
                ? formValues.historicoLaboral
                : "",
            dataAdmissao:
              formValues.dataAdmissao !== undefined &&
              formValues.dataAdmissao.length === 10
                ? formValues.dataAdmissao
                : "",
            dataAdmissaoAmericano:
              formValues.dataAdmissaoAmericano !== undefined &&
              formValues.dataAdmissaoAmericano.length === 10
                ? formValues.dataAdmissaoAmericano
                : "",
            dataDemissao:
              formValues.dataDemissao !== undefined &&
              formValues.dataDemissao.length === 10
                ? formValues.dataDemissao
                : "",
            dataDemissaoAmericano:
              formValues.dataDemissaoAmericano !== undefined &&
              formValues.dataDemissaoAmericano.length === 10
                ? formValues.dataDemissaoAmericano
                : "",
            qtdeDiasTrabalho:
              formValues.qtdeDiasTrabalho !== undefined
                ? formValues.qtdeDiasTrabalho
                : "",
            funcaoTrabalho:
              formValues.funcaoTrabalho !== undefined
                ? formValues.funcaoTrabalho
                : "",
            nomeEmpresaTrabalho:
              formValues.nomeEmpresaTrabalho !== undefined
                ? formValues.nomeEmpresaTrabalho
                : "",
          },
        ],
      })
    );
  };

  const excluirTrab = (e) => {
    e.preventDefault();
    trab.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        trab.splice(i, 1);
        setTrab([...trab]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _18_trab: trab,
          })
        );
      }
      return null;
    });
  };

  const excluirTudoTrab = (e) => {
    e.preventDefault();

    setTrab([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _18_trab: [],
      })
    );
  };

  const handleCarregarFormTrab = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
      if (tudo._18_trab && tudo._18_trab.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._18_trab !== undefined ||
          JSON.parse(localStorage.getItem("tudo"))._18_trab !== null
            ? JSON.parse(localStorage.getItem("tudo"))._18_trab
            : [];

        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            historicoLaboral:
              e !== undefined &&
              e.historicoLaboral !== undefined &&
              e.historicoLaboral !== ""
                ? e.historicoLaboral
                : "",

            dataAdmissao:
              e !== undefined &&
              e.dataAdmissao !== undefined &&
              e.dataAdmissao !== ""
                ? e.dataAdmissao
                : "",

            dataAdmissaoAmericano:
              e !== undefined &&
              e.dataAdmissaoAmericano !== undefined &&
              e.dataAdmissaoAmericano !== ""
                ? e.dataAdmissaoAmericano
                : "",

            dataDemissao:
              e !== undefined &&
              e.dataDemissao !== undefined &&
              e.dataDemissao !== ""
                ? e.dataDemissao
                : "",

            dataDemissaoAmericano:
              e !== undefined &&
              e.dataDemissaoAmericano !== undefined &&
              e.dataDemissaoAmericano !== ""
                ? e.dataDemissaoAmericano
                : "",

            qtdeDiasTrabalho:
              e !== undefined &&
              e.qtdeDiasTrabalho !== undefined &&
              e.qtdeDiasTrabalho !== ""
                ? e.qtdeDiasTrabalho
                : "",

            funcaoTrabalho:
              e !== undefined &&
              e.funcaoTrabalho !== undefined &&
              e.funcaoTrabalho !== ""
                ? e.funcaoTrabalho
                : "",

            nomeEmpresaTrabalho:
              e !== undefined &&
              e.nomeEmpresaTrabalho !== undefined &&
              e.nomeEmpresaTrabalho !== ""
                ? e.nomeEmpresaTrabalho
                : "",
          })
        );
      }
    };

    const y = () => {
      if (tudo._18_trab && tudo._18_trab.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._18_trab !== undefined ||
          JSON.parse(localStorage.getItem("tudo"))._18_trab !== null
            ? JSON.parse(localStorage.getItem("tudo"))._18_trab
            : [];

        //console.log('***',a)
        const arrTrab = [];
        a.map((e) => {
          return arrTrab.push({
            historicoLaboral:
              e !== undefined &&
              e.historicoLaboral !== undefined &&
              e.historicoLaboral !== ""
                ? e.historicoLaboral
                : "",

            dataAdmissao:
              e !== undefined &&
              e.dataAdmissao !== undefined &&
              e.dataAdmissao !== ""
                ? e.dataAdmissao
                : "",

            dataAdmissaoAmericano:
              e !== undefined &&
              e.dataAdmissaoAmericano !== undefined &&
              e.dataAdmissaoAmericano !== ""
                ? e.dataAdmissaoAmericano
                : "",

            dataDemissao:
              e !== undefined &&
              e.dataDemissao !== undefined &&
              e.dataDemissao !== ""
                ? e.dataDemissao
                : "",

            dataDemissaoAmericano:
              e !== undefined &&
              e.dataDemissaoAmericano !== undefined &&
              e.dataDemissaoAmericano !== ""
                ? e.dataDemissaoAmericano
                : "",

            qtdeDiasTrabalho:
              e !== undefined &&
              e.qtdeDiasTrabalho !== undefined &&
              e.qtdeDiasTrabalho !== ""
                ? e.qtdeDiasTrabalho
                : "",

            funcaoTrabalho:
              e !== undefined &&
              e.funcaoTrabalho !== undefined &&
              e.funcaoTrabalho !== ""
                ? e.funcaoTrabalho
                : "",

            nomeEmpresaTrabalho:
              e !== undefined &&
              e.nomeEmpresaTrabalho !== undefined &&
              e.nomeEmpresaTrabalho !== ""
                ? e.nomeEmpresaTrabalho
                : "",
          });
        });
        setTrab(arrTrab);
      }
    };

    x();
    y();
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  useEffect(() => {
    if (
      formValues.dataAdmissaoAmericano !== undefined &&
      formValues.dataAdmissaoAmericano.length === 10
    ) {
      formValues.dataAdmissao =
        `${formValues.dataAdmissaoAmericano.split("-")[2]}-${
          formValues.dataAdmissaoAmericano.split("-")[1]
        }-${formValues.dataAdmissaoAmericano.split("-")[0]}` || "";
    }
    if (
      formValues.dataDemissaoAmericano !== undefined &&
      formValues.dataDemissaoAmericano.length === 10
    ) {
      formValues.dataDemissao =
        `${formValues.dataDemissaoAmericano.split("-")[2]}-${
          formValues.dataDemissaoAmericano.split("-")[1]
        }-${formValues.dataDemissaoAmericano.split("-")[0]}` || "";
    }
    if (
      formValues.dataAdmissaoAmericano !== undefined &&
      formValues.dataAdmissaoAmericano.length === 10 &&
      formValues.dataDemissaoAmericano !== undefined &&
      formValues.dataDemissaoAmericano.length === 10
    ) {
      formValues.qtdeDiasTrabalho = Math.ceil(
        (new Date(formValues.dataDemissaoAmericano).getTime() -
          new Date(formValues.dataAdmissaoAmericano).getTime()) /
          (1000 * 60 * 60 * 24) +
          1
      );
    }
  }, [formValues]);

  return (
    <>
      <fieldset>
        <legend>Histórico Laboral</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>Limpar Campo</th>
                <th>Informação</th>
              </tr>
            </thead>

            <tbody>
              <tr key="historicoLaboral">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"historicoLaboral"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div className="radios">
                    <span>Historico Laboral</span>
                    <label>
                      <input
                        type="radio"
                        value="Anterior"
                        name="historicoLaboral"
                        onChange={handleInputChange}
                        checked={formValues.historicoLaboral === "Anterior"}
                      />
                      Anterior
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="Concomitante"
                        name="historicoLaboral"
                        onChange={handleInputChange}
                        checked={formValues.historicoLaboral === "Concomitante"}
                      />
                      Concomitante
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="Posterior"
                        name="historicoLaboral"
                        onChange={handleInputChange}
                        checked={formValues.historicoLaboral === "Posterior"}
                      />
                      Posterior
                    </label>
                  </div>
                </th>
              </tr>

              <tr key="dataAdmissaoAmericano">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"dataAdmissaoAmericano"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                  <span>Data da admissão</span>
                  <input
                    type="date"
                    name="dataAdmissaoAmericano"
                    onChange={handleInputChange}
                    value={
                      formValues.dataAdmissaoAmericano !== undefined
                        ? formValues.dataAdmissaoAmericano
                        : ""
                    }
                  />
                  </div>
                  
                </th>
              </tr>

              <tr key="dataDemissaoAmericano">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"dataDemissaoAmericano"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                  <span>Data da demissão</span>
                  <input
                    type="date"
                    name="dataDemissaoAmericano"
                    onChange={handleInputChange}
                    value={
                      formValues.dataDemissaoAmericano !== undefined
                        ? formValues.dataDemissaoAmericano
                        : ""
                    }
                  />
                  </div> 
                </th>
              </tr>

              <tr key="qtdeDiasTrabalho">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"qtdeDiasTrabalho"}
                    ></i>
                  </button>
                </th>
                <th>
                  <div>
                  <span>
                    Tempo de empresa
                  </span>
                  <input
                  disabled
                    type="text"
                    name="qtdeDiasTrabalho"
                    onChange={handleInputChange}
                    value={
                      formValues.qtdeDiasTrabalho !== undefined
                        ? formValues.qtdeDiasTrabalho
                        : ""
                    }
                  />
                  </div>
                  
                </th>
              </tr>

              <tr key="funcaoTrabalho">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"funcaoTrabalho"}
                    ></i>
                  </button>
                </th>
                <th>
                  <input
                    type="text"
                    name="funcaoTrabalho"
                    placeholder="Insira o nome da  funcao"
                    onChange={handleInputChange}
                    value={
                      formValues.funcaoTrabalho !== undefined
                        ? formValues.funcaoTrabalho
                        : ""
                    }
                  />
                </th>
              </tr>

              <tr key="nomeEmpresaTrabalho">
                <th>
                  <button onClick={handleLimparCampo}>
                    <i
                      className="fas fa-trash perigo"
                      value={"nomeEmpresaTrabalho"}
                    ></i>
                  </button>
                </th>
                <th>
                  <input
                    type="text"
                    name="nomeEmpresaTrabalho"
                    placeholder="Insira o nome da  empresa"
                    onChange={handleInputChange}
                    value={
                      formValues.nomeEmpresaTrabalho !== undefined
                        ? formValues.nomeEmpresaTrabalho
                        : ""
                    }
                  />
                </th>
              </tr>
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarTrab}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormTrab}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Dados registrados do Historico laboral</legend>
            {trab.length === 0 ? 
              <p>Sem dados registrados</p>
             : 
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {trab.length > 0 &&
                    trab.map(
                      (
                        {
                          historicoLaboral,
                          dataAdmissao,
                          dataDemissao,
                          qtdeDiasTrabalho,
                          funcaoTrabalho,
                          nomeEmpresaTrabalho,
                        },
                        i
                      ) => {
                        return (
                          
                            <tbody key={i}>
                              <tr >
                                <th>Registro número</th>
                                <th> {i + 1}</th>
                              </tr>
                              <tr>
                                <th>Histórico laboral</th>
                                <th>{historicoLaboral}</th>
                              </tr>
                              <tr>
                                <th>Admissão</th>
                                <th>{dataAdmissao}</th>
                              </tr>
                              <tr>
                                <th>Demissão</th>
                                <th>{dataDemissao}</th>
                              </tr>
                              <tr>
                                <th>Dias corridos</th>
                                <th>{qtdeDiasTrabalho}</th>
                              </tr>
                              <tr>
                                <th>Função</th>
                                <th>{funcaoTrabalho}</th>
                              </tr>
                              <tr>
                                <th>Empresa</th>
                                <th>{nomeEmpresaTrabalho}</th>
                              </tr>

                              <tr>
                                <th>Ação</th>
                                <th >
                                  <button onClick={excluirTrab}>
                                    <i
                                      className="fas fa-solid fa-trash perigo"
                                      value={i}
                                    ></i>
                                  </button>
                                </th>
                              </tr>
                            </tbody>
                          
                        );
                      }
                    )}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoTrab}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoTrab"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
