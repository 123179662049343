import React, {useState, useEffect,} from 'react'
import { UtilitariosBuscaCid } from '../../utilitarios/UtilitariosBusca'

export const DadosCat = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ cat, setCat] = useState([])
    
    const adicionarCat = () => {

        const tudo = JSON.parse(localStorage.getItem('tudo')) || []
            let cidBusca = []
            if(tudo._99_cidBusca){
         cidBusca = tudo._99_cidBusca /* !== null ? tudo._99_cidBusca : [] */
            }
            
        const arrCat = []

        arrCat.push({
            //id: cat !== undefined ? cat.length + 1 : '',
            idCat: formValues.idCat !== undefined ? formValues.idCat : '',
            tipo: formValues.tipo !== undefined ? formValues.tipo : '',
            emissor: formValues.emissor !== undefined ? formValues.emissor : '', 
            dataEmissao: formValues.dataEmissao !== undefined && formValues.dataEmissao.length === 10 ? formValues.dataEmissao : '',
            dataEmissaoCatAmericano: formValues.dataEmissaoCatAmericano !== undefined && formValues.dataEmissaoCatAmericano.length === 10 ? formValues.dataEmissaoCatAmericano : '',
            dataAcidente: formValues.dataAcidente !== undefined && formValues.dataAcidente.length === 10 ? formValues.dataAcidente : '',
            dataAcidenteCatAmericano: formValues.dataAcidenteCatAmericano !== undefined && formValues.dataAcidenteCatAmericano.length === 10 ? formValues.dataAcidenteCatAmericano : '',
            cid: cidBusca !== undefined || cidBusca !== null ? cidBusca : []
        })

        setCat([...cat, ...arrCat])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _14_cat: [...cat,{
                //id: cat !== undefined ? cat.length + 1 : '',
                idCat: formValues.idCat !== undefined ? formValues.idCat : '',
                tipo: formValues.tipo !== undefined ? formValues.tipo : '',
                emissor: formValues.emissor !== undefined ? formValues.emissor : '', 
                dataEmissao: formValues.dataEmissao !== undefined && formValues.dataEmissao.length === 10 ? formValues.dataEmissao : '',
                dataEmissaoCatAmericano: formValues.dataEmissaoCatAmericano !== undefined && formValues.dataEmissaoCatAmericano.length === 10 ? formValues.dataEmissaoCatAmericano : '',
                dataAcidente: formValues.dataAcidente !== undefined && formValues.dataAcidente.length === 10 ? formValues.dataAcidente : '',
                dataAcidenteCatAmericano: formValues.dataAcidenteCatAmericano !== undefined && formValues.dataAcidenteCatAmericano.length === 10 ? formValues.dataAcidenteCatAmericano : '',
                cid: cidBusca !== undefined || cidBusca !== null ? cidBusca : []
            }]
      }))
    }

    const excluirCat = (e) => {

        e.preventDefault()      
        cat.filter((
            el, i
        )=>{    
            if (e.target.attributes.value.value !== undefined && e.target.attributes.value.value === String(i)) {
                        
                cat.splice(i, 1)
                setCat([...cat]) 
                
                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _14_cat: cat
              }))
            }    
            return null
        })
    }

    const excluirTudoCat = (e) => {
        e.preventDefault();
       // console.log(e.target.attributes.value.value)

          setCat([])
  
           localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _14_cat: []
            })
          ); 
      }
  
      const handleCarregarFormCat = () => {
          
          const tudo = JSON.parse(localStorage.getItem('tudo'))
  
          const x = () => {
            if (tudo._14_cat && tudo._14_cat.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._14_cat !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._14_cat !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._14_cat : []
      
              //console.log('***',a)
          a.map((e)=> 
  
            setFormValues({
              idCat: e !== undefined && e.idCat !== undefined && e.idCat !== ""
            ? e.idCat
            : "",

            tipo: e !== undefined && e.tipo !== undefined && e.tipo !== ""
            ? e.tipo
            : "",
            
            emissor: e !== undefined && e.emissor !== undefined && e.emissor !== ''
            ? e.emissor
            : "",
            
            dataEmissao: e !== undefined && e.dataEmissao !== undefined && e.dataEmissao !== ""
            ? e.dataEmissao
            : "",

            dataEmissaoCatAmericano: e !== undefined && e.dataEmissaoCatAmericano !== undefined && e.dataEmissaoCatAmericano !== ""
            ? e.dataEmissaoCatAmericano
            : "",
  
            dataAcidente: e !== undefined && e.dataAcidente !== undefined &&
            e.dataAcidente !== ''
              ? e.dataAcidente
              : "",

              dataAcidenteCatAmericano: e !== undefined && e.dataAcidenteCatAmericano !== undefined &&
            e.dataAcidenteCatAmericano !== ''
              ? e.dataAcidenteCatAmericano
              : "",

              cid: e !== undefined && e.cid !== undefined && e.cid !== ""
            ? e.cid
            : "",
  
        })
      )  
  }
          }
  
          const y = () => {
            if (tudo._14_cat && tudo._14_cat.length > 0) {
              const a = JSON.parse(localStorage.getItem('tudo'))._14_cat !== undefined
              ||JSON.parse(localStorage.getItem('tudo'))._14_cat !== null ? 
              JSON.parse(localStorage.getItem('tudo'))._14_cat : []
      
              //console.log('***',a)
              const arrCat = []
          a.map((e)=> {
  
          return  arrCat.push({
            idCat: e !== undefined && e.idCat !== undefined && e.idCat !== ""
            ? e.idCat
            : "",

            tipo: e !== undefined && e.tipo !== undefined && e.tipo !== ""
            ? e.tipo
            : "",
            
            emissor: e !== undefined && e.emissor !== undefined && e.emissor !== ''
            ? e.emissor
            : "",
            
            dataEmissao: e !== undefined && e.dataEmissao !== undefined && e.dataEmissao !== ""
            ? e.dataEmissao
            : "",

            dataEmissaoCatAmericano: e !== undefined && e.dataEmissaoCatAmericano !== undefined && e.dataEmissaoCatAmericano !== ""
            ? e.dataEmissaoCatAmericano
            : "",
  
            dataAcidente: e !== undefined && e.dataAcidente !== undefined &&
            e.dataAcidente !== ''
              ? e.dataAcidente
              : "",

              dataAcidenteCatAmericano: e !== undefined && e.dataAcidenteCatAmericano !== undefined &&
            e.dataAcidenteCatAmericano !== ''
              ? e.dataAcidenteCatAmericano
              : "",

              cid: e !== undefined && e.cid !== undefined && e.cid !== ""
            ? e.cid
            : "",
        }) 
    
    }
      )  

      setCat(arrCat)
  }
          }
  
          x()
          y()
          
  
  }
  
      const handleReset = () => {
          setFormValues({})
      }
  
      const handleLimparCampo = (e) => {
          e.preventDefault()
          if (e.target.attributes.value) {
              setFormValues({...formValues,  
                [e.target.attributes.value.value]: '', 
              })
          }
      }
    
    useEffect(() => {
        if (formValues.dataEmissaoCatAmericano !== undefined &&
            formValues.dataEmissaoCatAmericano.length === 10) {
            formValues.dataEmissao =`${formValues.dataEmissaoCatAmericano.split('-')[2]}-${formValues.dataEmissaoCatAmericano.split('-')[1]}-${formValues.dataEmissaoCatAmericano.split('-')[0]}` || ''
        }
        if (formValues.dataAcidenteCatAmericano !== undefined &&
            formValues.dataAcidenteCatAmericano.length === 10) {
            formValues.dataAcidente =`${formValues.dataAcidenteCatAmericano.split('-')[2]}-${formValues.dataAcidenteCatAmericano.split('-')[1]}-${formValues.dataAcidenteCatAmericano.split('-')[0]}` || ''
        }
        }, [formValues]
    )

    return (
        <>
           <fieldset>
        <legend>Comunicado de Acidente de Trabalho</legend>
        <div >
        <fieldset >
          <table >
                        <thead>
                <tr>
                  <th>Limpar Campo</th>
                  <th>Informação</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"idCat"}
                      ></i>
                    </button></th>
                    <th><input
                            type="text"
                            name="idCat"
                            maxLength="22"
                            className="capturaIdCat"
                            placeholder="Id - página"
                            onChange={handleInputChange}
                            value={formValues.idCat !== undefined ? formValues.idCat : ''}
                        /></th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"tipo"}
                      ></i>
                    </button></th>
                    <th>
                    <div className="radios">
                            <span>Tipo</span>
                            <label>
                                <input
                                    type="radio"
                                    value="Inicial"
                                    name="tipo"
                                    onChange={handleInputChange}
                                    checked={formValues.tipo === 'Inicial'}
                                />
                                Inicial
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Reabertura"
                                    name="tipo"
                                    onChange={handleInputChange}
                                    checked={formValues.tipo === 'Reabertura'}
                                />
                                Reabertura
                            </label>
                        </div>

                    </th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"emissor"}
                      ></i>
                    </button></th>
                    <th>
                        <select
                            name="emissor"
                            className="selectCat"
                            onChange={handleInputChange}
                            value={formValues.emissor !== undefined ? formValues.emissor : ''}
                        >
                            <option value="">Emissor do Cat</option>
                            <option value="Sindicato">Sindicato</option>
                            <option value="Empregador">Empregador</option>
                            <option value="Cerest">Cerest</option>
                            <option value="Médico Assistente">Médico Assistente</option>
                            <option value="O próprio acidentado">O próprio acidentado</option>
                        </select>
                        
                    </th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"dataEmissaoCatAmericano"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span >Data da emissão</span>
                        <input
                            type="date"
                            name="dataEmissaoCatAmericano"
                            onChange={handleInputChange}
                            value={formValues.dataEmissaoCatAmericano !== undefined ? formValues.dataEmissaoCatAmericano : ''}
                        />
                      </div>
                    </th>
                </tr>
                <tr>
                    <th><button onClick={handleLimparCampo}>
                      <i
                        className="fas fa-trash perigo"
                        value={"dataAcidenteCatAmericano"}
                      ></i>
                    </button></th>
                    <th>
                      <div>
                      <span>Data do acidente</span>
                        <input
                            type="date"
                            name="dataAcidenteCatAmericano"
                            onChange={handleInputChange}
                            value={formValues.dataAcidenteCatAmericano !== undefined ? formValues.dataAcidenteCatAmericano : ''}
                        />
                      </div> 
                    </th>
                </tr>
</tbody>
</table>
<UtilitariosBuscaCid />
                    
                    
                  
<fieldset>
              <legend>Ações</legend>
              <table>
                <thead>
                  <tr>
                    <th>Adicionar</th>
                    <th>Carregar</th>
                    <th>Limpar campos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th >
                      <button onClick={adicionarCat}>
                        <i className="fas fa-plus"></i>
                      </button>
                    </th>
                    <th>
                      <button onClick={handleCarregarFormCat}>
                        Carregar formulário
                      </button>
                    </th>
                    <th >
                      <button onClick={handleReset}>
                        <i
                          className="fas fa-trash perigo"
                          value={"Limpar Campos"}
                        ></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </fieldset>
                        
                        
                
                  
                    <fieldset>
                        <legend>Dados registrados de Cats</legend>
                        {cat.length === 0 ? <p>Sem Dados registrados</p> :
                            <>
                            <table>
                              <thead>
                                <tr>
                                  <th>Tópico</th>
                                  <th>Informação</th>
                                </tr>
                              </thead>
                                    {cat.length > 0 && cat.map(({
                                        
                                        idCat,
                                        tipo,
                                        emissor,
                                        dataEmissao,
                                        dataAcidente,
                                        cid
                                    }, i) => {
                                        return (
                                            <tbody key={i}>
                                            <tr >
                                              <th>Registro número</th>
                                              <th> {i + 1}</th>
                                            </tr>
                                            <tr>
                                              <th>Id</th>
                                              <th>{idCat}</th>
                                            </tr>
                                            <tr>
                                              <th>Tipo</th>
                                              <th>{tipo}</th>
                                            </tr>
                                            <tr>
                                              <th>Emissor</th>
                                              <th>{emissor}</th>
                                            </tr>
                                            <tr>
                                              <th>Data emissão</th>
                                              <th>{dataEmissao}</th>
                                            </tr>
                                            <tr>
                                              <th>Data acidente</th>
                                              <th>{dataAcidente}</th>
                                            </tr>
                                            <tr>
                                              <th>Cid</th>
                                              <th>
                                            {cid === undefined && cid.lenght === 0 ? <p>Não registrado</p> : cid.map(({ codigo, descricao }, i) => {
                                                    return <p key={i}>{codigo} - {descricao}</p>
                                                })
                                                }
                                            </th>
                                            </tr>
                                            <tr>
                                                <th>Ação</th>
                                                <th >
                                                  <button onClick={excluirCat}>
                                                    <i
                                                      className="fas fa-solid fa-trash perigo"
                                                      value={i}
                                                    ></i>
                                                  </button>
                                                </th>
                                              </tr>
                                            </tbody>
                                          
                                        );
                                      }
                                    )}
                                </table>
                                <fieldset>
                                  <legend>Ação</legend>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Excluir todos os documentos?</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr >
                                        <th>
                                          <button onClick={excluirTudoCat}>
                                            <i
                                              className="fas fa-solid fa-trash perigo"
                                              value={"excluirTudoCat"}
                                            ></i>
                                          </button>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                              </>
                            }
                          </fieldset>
                        </fieldset>
                    </div>  </fieldset>
                    </>
                  );
}
