import React from 'react'
import styles from '../valoracaoDanoCorporal/ValoracaoDanoCorporal.module.css'

import { ValoracaoDesnecessaria } from './valoracaoDesnecessaria/ValoracaoDesnecessaria'
import { DeficitFuncionalTemporario } from './deficitFuncionalTemporario/DeficitFuncionalTemporario'
import { IncapacidadeLaboralTemporaria } from './incapacidadeLaboralTemporaria/IncapacidadeLaboralTemporaria'
import { QuantumDoloris } from './quantumDoloris/QuantumDoloris'
import { DeficitFuncionalDefinitivo } from './deficitFuncionalDefinitivo/DeficitFuncionalDefinitivo'
import { ClassificacaoIncapacidadePenteado } from './classificacaoIncapacidadePenteado/ClassificacaoIncapacidadePenteado'
import { BaremoArgentinoQualitativo } from './baremoArgentinoQualitativo/BaremoArgentinoQualitativo'
import { ClassificacaoWelitonBarbosaSantos } from './classificacaoWelitonBarbosaSantos/ClassificacaoWelitonBarbosaSantos'
import { ClassificacaoDecreto3048 } from './classificacaoDecreto3048/ClassificacaoDecreto3048'
import { NecessidadeAjudaTerceiros } from './necessidadeAjudaTerceiros/NecessidadeAjudaTerceiros'
import { ClassificacaoDanoEstetico } from './classificacaoDanoEstetico/ClassificacaoDanoEstetico'
import { RepercussaoAtividadesDesportivas } from './repercussaoAtividadesDesportivas/RepercussaoAtividadesDesportivas'
import { Outlet } from 'react-router-dom'

export const ValoracaoDanoCorporal = () => {

    return ( 
        <>
            {/* <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxValoracaoDanoCorporal} />
                <label htmlFor={styles.checkboxValoracaoDanoCorporal}>
                    <h2 className={styles.titulo}>Valoração do dano corporal</h2>
                </label>
                <fieldset id={styles.conteudoValoracaoDanoCorporal}>
                    <table className={styles.table}></table> */}
                    <Outlet>
                    <ValoracaoDesnecessaria/>
      <DeficitFuncionalTemporario/>
      <IncapacidadeLaboralTemporaria/>
      <QuantumDoloris/>
      <DeficitFuncionalDefinitivo/>
      <ClassificacaoIncapacidadePenteado/>
      <BaremoArgentinoQualitativo/>
      <ClassificacaoWelitonBarbosaSantos/>
      <ClassificacaoDecreto3048/>
      <NecessidadeAjudaTerceiros/>
      <ClassificacaoDanoEstetico/>
      <RepercussaoAtividadesDesportivas/> 
                    </Outlet>
                        
              {/*   </fieldset>
            </fieldset> */}
        </>
    )
}