import React from 'react'
import styles from './BancoDadosPerito.module.css'

import { DadosPerito } from './dadosPerito/DadosPerito'
import { DadosPeritoEspecialidade } from './dadosPeritoEspecialidade/DadosPeritoEspecialidade'
import { BuscarApiEnd } from './buscarApiEnd/BuscarApiEnd'
import { DadosPeritoEndRes } from './dadosPeritoEndRes/DadosPeritoEndRes'
import { DadosPeritoEndCom } from './dadosPeritoEndCom/DadosPeritoEndCom'   

export const BancoDadosPerito = () => {

  return (
    <>
      <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxBancoDadosPerito} />
        <label htmlFor={styles.checkboxBancoDadosPerito}>
          <h2 className={styles.titulo}>Banco de Dados do Perito</h2>
        </label>
        <fieldset id={styles.conteudoBancoDadosPerito}>
          <table className={styles.table}></table>
          <DadosPerito />
          <DadosPeritoEspecialidade />
          <BuscarApiEnd/>
          <DadosPeritoEndRes />
          <DadosPeritoEndCom />
        </fieldset>
      </fieldset>
    </>
  )
}