import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoutes } from ".";
/* import DadosPerito from '../components/DadosPerito'
import { MostraEmpresas } from '../components/MostraEmpresas' */
import { Home } from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { PaginaNaoEncontrada } from "../pages/404";
import { PortalAssinatura } from "../pages/PortalAssinatura";
import { CheckoutAssinatura } from "../pages/CheckoutAssinatura";
import { Site } from "../pages/Site/Site";
import { Admin } from "../components/admin/Admin";
import { Admin1 } from "../components/admin/admin1/Admin1";
import {
  Admin2,
  GravarDadosPerito,
  GravarDadosUsuario,
} from "../components/admin/admin2/Admin2";
import { Admin3 } from "../components/admin/admin3/Admin3";
import { Admin4 } from "../components/admin/admin4/Admin4";
import { DadosIniciais } from "../components/dadosIniciais/DadosIniciais";
import { DadosIniciaisProcesso } from "../components/dadosIniciais/dadosIniciaisProcesso/DadosIniciaisProcesso";
import { DadosPolo1 } from "../components/dadosIniciais/polo1/DadosPolo1";
import { DadosPolo2 } from "../components/dadosIniciais/polo2/DadosPolo2";
import { BuscarApiCnpj } from "../components/dadosIniciais/buscarApiCnpj/BuscarApiCnpj";
import { DocHonorarios } from "../components/dadosIniciais/docHonorarios/DocHonorarios";
import { DocReclamada } from "../components/dadosIniciais/docReclamada/DocReclamada";
import { DocReclamante } from "../components/dadosIniciais/docReclamante/DocReclamante";
import { DocSecVara } from "../components/dadosIniciais/docSecVara/DocSecVara";
import { AgendamentoPericia } from "../components/dadosIniciais/agendamento/AgendamentoPericia";
import { DadosTrabEmpresa } from "../components/dadosProcessuais/dadosTrabEmpresa/DadosTrabEmpresa";
import { DadosHistoricoLaboral } from "../components/dadosProcessuais/dadosHistoricoLaboral/DadosHistoricoLaboral";
import { DadosProcessuais } from "../components/dadosProcessuais/DadosProcessuais";
import { DadosInicial } from "../components/dadosProcessuais/dadosInicial/DadosInicial";
import { DadosContestacao } from "../components/dadosProcessuais/dadosContestacao/DadosContestacao";
import { DadosAtestado } from "../components/dadosProcessuais/dadosAtestado/DadosAtestado";
import { DadosCat } from "../components/dadosProcessuais/dadosCat/DadosCat";
import { DadosInss } from "../components/dadosProcessuais/dadosInss/DadosInss";
import { DadosInssPericia } from "../components/dadosProcessuais/dadosInssPericia/DadosInssPericia";
import { DadosDocSst } from "../components/dadosProcessuais/dadosDocSst/DadosDocSst";
import { DadosDocOutros } from "../components/dadosProcessuais/dadosDocOutros/DadosDocOutros";
import { RaciocinioTecnicoPericial } from "../components/raciocinioTecnicoPericial/RaciocinioTecnicoPericial";
import { Ntep } from "../components/raciocinioTecnicoPericial/ntep/Ntep";
import { NexoProfissional } from "../components/raciocinioTecnicoPericial/nexoProfissional/NexoProfissional";
import { CriteriosSimonin } from "../components/raciocinioTecnicoPericial/criteriosSimonin/CriteriosSimonin";
import { CriteriosPenteado } from "../components/raciocinioTecnicoPericial/criteriosPenteado/CriteriosPenteado";
import { CriteriosConcausa } from "../components/raciocinioTecnicoPericial/criteriosConcausa/CriteriosConcausa";
import { CriteriosBradfordHill } from "../components/raciocinioTecnicoPericial/criteriosBradfordHill/CriteriosBradfordHill";
import { CriteriosCfm } from "../components/raciocinioTecnicoPericial/criteriosCfm/CriteriosCfm";
import { CriteriosFranchini } from "../components/raciocinioTecnicoPericial/criteriosFranchini/CriteriosFranchini";
import { ValoracaoDanoCorporal } from "../components/valoracaoDanoCorporal/ValoracaoDanoCorporal";
import { ValoracaoDesnecessaria } from "../components/valoracaoDanoCorporal/valoracaoDesnecessaria/ValoracaoDesnecessaria";
import { DeficitFuncionalTemporario } from "../components/valoracaoDanoCorporal/deficitFuncionalTemporario/DeficitFuncionalTemporario";
import { DeficitFuncionalDefinitivo } from "../components/valoracaoDanoCorporal/deficitFuncionalDefinitivo/DeficitFuncionalDefinitivo";
import { IncapacidadeLaboralTemporaria } from "../components/valoracaoDanoCorporal/incapacidadeLaboralTemporaria/IncapacidadeLaboralTemporaria";
import { QuantumDoloris } from "../components/valoracaoDanoCorporal/quantumDoloris/QuantumDoloris";
import { BaremoArgentinoQualitativo } from "../components/valoracaoDanoCorporal/baremoArgentinoQualitativo/BaremoArgentinoQualitativo";
import { ClassificacaoDanoEstetico } from "../components/valoracaoDanoCorporal/classificacaoDanoEstetico/ClassificacaoDanoEstetico";
import { ClassificacaoDecreto3048 } from "../components/valoracaoDanoCorporal/classificacaoDecreto3048/ClassificacaoDecreto3048";
import { ClassificacaoIncapacidadePenteado } from "../components/valoracaoDanoCorporal/classificacaoIncapacidadePenteado/ClassificacaoIncapacidadePenteado";
import { ClassificacaoWelitonBarbosaSantos } from "../components/valoracaoDanoCorporal/classificacaoWelitonBarbosaSantos/ClassificacaoWelitonBarbosaSantos";
import { NecessidadeAjudaTerceiros } from "../components/valoracaoDanoCorporal/necessidadeAjudaTerceiros/NecessidadeAjudaTerceiros";
import { RepercussaoAtividadesDesportivas } from "../components/valoracaoDanoCorporal/repercussaoAtividadesDesportivas/RepercussaoAtividadesDesportivas";
import { Quesitos } from "../components/quesitos/Quesitos";
import { QuesitosReclamada } from "../components/quesitos/quesitosReclamada/QuesitosReclamada";
import { QuesitosReclamante } from "../components/quesitos/quesitosReclamante/QuesitosReclamante";
import { QuesitosJuizo } from "../components/quesitos/quesitosJuizo/QuesitosJuizo";
import { QuesitosEsclarecimento } from '../components/quesitos/quesitosEsclarecimento/QuesitosEsclarecimento'
import { ConclusaoLaudo } from "../components/conclusao/ConclusaoLaudo";
import { BancoDadosPerito } from "../components/bancoDadosPerito/BancoDadosPerito";
import { BuscarApiEnd } from "../components/bancoDadosPerito/buscarApiEnd/BuscarApiEnd";
import { DadosPerito } from "../components/bancoDadosPerito/dadosPerito/DadosPerito";
import { DadosPeritoEndCom } from "../components/bancoDadosPerito/dadosPeritoEndCom/DadosPeritoEndCom";
import { DadosPeritoEndRes } from "../components/bancoDadosPerito/dadosPeritoEndRes/DadosPeritoEndRes";
import { DadosPeritoEspecialidade } from "../components/bancoDadosPerito/dadosPeritoEspecialidade/DadosPeritoEspecialidade";
import { NovoGeradorPdf } from "../components/geradorPdf/NovoGeradorPdf";
export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Site />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Home" element={<PrivateRoutes />}>
          <Route path="/Home" element={<Home />}>
            <Route path="/Home/Admin" element={<Admin />}>
              <Route path="/Home/Admin/Admin1" element={<Admin1 />} />
              <Route path="/Home/Admin/Admin2" element={<Admin2 />} />
              <Route path="/Home/Admin/Admin3" element={<Admin3 />} />
              <Route path="/Home/Admin/Admin4" element={<Admin4 />} />
              <Route
                path="/Home/Admin/GravarDadosPerito"
                element={<GravarDadosPerito />}
              />
              <Route
                path="/Home/Admin/GravarDadosUsuario"
                element={<GravarDadosUsuario />}
              />
            </Route>

            <Route path="/Home/DadosIniciais" element={<DadosIniciais />}>
              <Route path="/Home/DadosIniciais/DadosIniciaisProcesso" element={<DadosIniciaisProcesso />}/>
              <Route path="/Home/DadosIniciais/DadosPolo1" element={<DadosPolo1 />}/>
              <Route path="/Home/DadosIniciais/BuscarApiCnpj" element={<BuscarApiCnpj />}/>
              <Route path="/Home/DadosIniciais/DadosPolo2" element={<DadosPolo2 />}/>
              <Route path="/Home/DadosIniciais/DocHonorarios" element={<DocHonorarios />}/>
              <Route path="/Home/DadosIniciais/DocReclamada" element={<DocReclamada />}/>
              <Route path="/Home/DadosIniciais/DocReclamante" element={<DocReclamante/>}/>
              <Route path="/Home/DadosIniciais/DocSecVara" element={<DocSecVara/>}/>
              <Route path="/Home/DadosIniciais/AgendamentoPericia" element={<AgendamentoPericia/>}/>
            </Route>


            <Route path="/Home/DadosProcessuais" element={<DadosProcessuais />}>
              <Route path="/Home/DadosProcessuais/DadosInicial" element={<DadosInicial />}/>
              <Route path="/Home/DadosProcessuais/DadosContestacao" element={<DadosContestacao />}/>
              <Route path="/Home/DadosProcessuais/DadosAtestado" element={<DadosAtestado />}/>
              <Route path="/Home/DadosProcessuais/DadosCat" element={<DadosCat />}/>
              <Route path="/Home/DadosProcessuais/DadosInss" element={<DadosInss />}/>
              <Route path="/Home/DadosProcessuais/DadosInssPericia" element={<DadosInssPericia />}/>
              <Route path="/Home/DadosProcessuais/DadosDocSst" element={<DadosDocSst/>}/>
              <Route path="/Home/DadosProcessuais/DadosDocOutros" element={<DadosDocOutros/>}/>
              <Route path="/Home/DadosProcessuais/DadosTrabEmpresa" element={<DadosTrabEmpresa/>}/>
              <Route path="/Home/DadosProcessuais/DadosHistoricoLaboral" element={<DadosHistoricoLaboral/>}/>
            </Route>

            <Route path="/Home/RaciocinioTecnicoPericial" element={<RaciocinioTecnicoPericial />}>
              <Route path="/Home/RaciocinioTecnicoPericial/Ntep" element={<Ntep />}/>
              <Route path="/Home/RaciocinioTecnicoPericial/NexoProfissional" element={<NexoProfissional />}/>
              <Route path="/Home/RaciocinioTecnicoPericial/CriteriosSimonin" element={<CriteriosSimonin />}/>
              <Route path="/Home/RaciocinioTecnicoPericial/CriteriosPenteado" element={<CriteriosPenteado />}/>
              <Route path="/Home/RaciocinioTecnicoPericial/CriteriosConcausa" element={<CriteriosConcausa />}/>
              <Route path="/Home/RaciocinioTecnicoPericial/CriteriosBradfordHill" element={<CriteriosBradfordHill />}/>
              <Route path="/Home/RaciocinioTecnicoPericial/CriteriosCfm" element={<CriteriosCfm/>}/>
              <Route path="/Home/RaciocinioTecnicoPericial/CriteriosFranchini" element={<CriteriosFranchini/>}/>
            </Route>


            <Route path="/Home/ValoracaoDanoCorporal" element={<ValoracaoDanoCorporal />}>
              <Route path="/Home/ValoracaoDanoCorporal/ValoracaoDesnecessaria" element={<ValoracaoDesnecessaria />}/>
              <Route path="/Home/ValoracaoDanoCorporal/DeficitFuncionalTemporario" element={<DeficitFuncionalTemporario />}/>
              <Route path="/Home/ValoracaoDanoCorporal/DeficitFuncionalDefinitivo" element={<DeficitFuncionalDefinitivo />}/>
              <Route path="/Home/ValoracaoDanoCorporal/IncapacidadeLaboralTemporaria" element={<IncapacidadeLaboralTemporaria />}/>
              <Route path="/Home/ValoracaoDanoCorporal/QuantumDoloris" element={<QuantumDoloris />}/>
              <Route path="/Home/ValoracaoDanoCorporal/BaremoArgentinoQualitativo" element={<BaremoArgentinoQualitativo />}/>
              <Route path="/Home/ValoracaoDanoCorporal/ClassificacaoDanoEstetico" element={<ClassificacaoDanoEstetico/>}/>
              <Route path="/Home/ValoracaoDanoCorporal/ClassificacaoDecreto3048" element={<ClassificacaoDecreto3048/>}/>
              <Route path="/Home/ValoracaoDanoCorporal/ClassificacaoIncapacidadePenteado" element={<ClassificacaoIncapacidadePenteado/>}/>
              <Route path="/Home/ValoracaoDanoCorporal/ClassificacaoWelitonBarbosaSantos" element={<ClassificacaoWelitonBarbosaSantos/>}/>
              <Route path="/Home/ValoracaoDanoCorporal/NecessidadeAjudaTerceiros" element={<NecessidadeAjudaTerceiros/>}/>
              <Route path="/Home/ValoracaoDanoCorporal/RepercussaoAtividadesDesportivas" element={<RepercussaoAtividadesDesportivas/>}/>
            </Route>

            <Route path="/Home/Quesitos" element={<Quesitos />}>
              <Route path="/Home/Quesitos/QuesitosReclamada" element={<QuesitosReclamada />}/>
              <Route path="/Home/Quesitos/QuesitosReclamante" element={<QuesitosReclamante />}/>
              <Route path="/Home/Quesitos/QuesitosJuizo" element={<QuesitosJuizo />}/>
              <Route path="/Home/Quesitos/QuesitosEsclarecimento" element={<QuesitosEsclarecimento />}/>
            </Route>

            <Route path="/Home/ConclusaoLaudo" element={<ConclusaoLaudo />}/>

            <Route path="/Home/NovoGeradorPdf" element={<NovoGeradorPdf />}/>
              
            <Route path="/Home/BancoDadosPerito" element={<BancoDadosPerito />}>
              <Route path="/Home/BancoDadosPerito/BuscarApiEnd" element={<BuscarApiEnd />}/>
              <Route path="/Home/BancoDadosPerito/DadosPerito" element={<DadosPerito />}/>
              <Route path="/Home/BancoDadosPerito/DadosPeritoEndCom" element={<DadosPeritoEndCom />}/>
              <Route path="/Home/BancoDadosPerito/DadosPeritoEndRes" element={<DadosPeritoEndRes />}/>
              <Route path="/Home/BancoDadosPerito/DadosPeritoEspecialidade" element={<DadosPeritoEspecialidade />}/>
            </Route>

            <Route
              path="/Home/CheckoutAssinatura/checkout.stripe.com/c/pay/*"
              element={<CheckoutAssinatura />}
            />
            <Route
              path="/Home/PortalAssinatura/billing.stripe.com/p/session/*"
              element={<PortalAssinatura />}
            />
          </Route>
        </Route>

        <Route path="/404" element={<PaginaNaoEncontrada />} />
        <Route path="*" element={<PaginaNaoEncontrada />} />
      </Routes>
    </BrowserRouter>
  );
};
