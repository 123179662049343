import React from 'react'
import styles from '../raciocinioTecnicoPericial/RaciocinioTecnicoPericial.module.css'

import { Ntep } from './ntep/Ntep'
import { NexoProfissional } from './nexoProfissional/NexoProfissional'
import { CriteriosSimonin } from './criteriosSimonin/CriteriosSimonin'
import { CriteriosPenteado } from './criteriosPenteado/CriteriosPenteado'
import { CriteriosFranchini } from './criteriosFranchini/CriteriosFranchini'
import { CriteriosBradfordHill } from './criteriosBradfordHill/CriteriosBradfordHill'
import { CriteriosCfm } from './criteriosCfm/CriteriosCfm'
import { CriteriosConcausa } from './criteriosConcausa/CriteriosConcausa'
import { Outlet } from 'react-router-dom'

export const RaciocinioTecnicoPericial = () => {

    return (

        <>
            {/* <fieldset>
                <legend></legend>
                <input type="checkbox" id={styles.checkboxRaciocinioTecnicoPericial} />
                <label htmlFor={styles.checkboxRaciocinioTecnicoPericial}>
                    <h2 className={styles.titulo}>Raciocínio Técnico Pericial</h2>
                </label>
                <fieldset id={styles.conteudoRaciocinioTecnicoPericial}>
                    <table className={styles.table}></table> */}
                    <Outlet>
                    <Ntep />
                    <NexoProfissional />
                    <CriteriosPenteado />
                    <CriteriosConcausa />
                    <CriteriosSimonin />
                    <CriteriosFranchini />
                    <CriteriosBradfordHill />
                    <CriteriosCfm />
                    </Outlet>
                    
                {/* </fieldset>
            </fieldset> */}
        </>
    )
}










/*  export const RaciocinioTecnicoPericial = () => {

    const [formValues, setFormValues] = useState({})

    const handleInputChange = (e) => {
        const { name, value, type } = e.target
    
        const data = formValues[name] || {}
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  })
        console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [docPerguntaSimonin, setDocPerguntaSimonin] = useState([])
    const [docPerguntaPenteado, setDocPerguntaPenteado] = useState([])
    const [docPerguntaFranchini, setDocPerguntaFranchini] = useState([])
    const [docPerguntaBradfordHill, setDocPerguntaBradfordHill] = useState([])
    const [docPerguntaCfm, setDocPerguntaCfm] = useState([])
    const [docPerguntaConcausa, setDocPerguntaConcausa] = useState([])

    
    useEffect(() => {
        setDocPerguntaSimonin(perguntaSimonin)
        setDocPerguntaPenteado(perguntaPenteado)
        setDocPerguntaFranchini(perguntaFranchini)
        setDocPerguntaBradfordHill(perguntaBradfordHill)
        setDocPerguntaCfm(perguntaCfm)
        setDocPerguntaConcausa(perguntaConcausa)
    }, [
        perguntaSimonin,
        perguntaPenteado,
        perguntaFranchini,
        perguntaBradfordHill,
        perguntaCfm,
        perguntaConcausa
    ])

    const handleSubmit = (e) => {
        e.preventDefault()
        
        console.log('***Final:', formValues) 
        
        sessionStorage.setItem('@raciocinioTecnicoPericial', JSON.stringify(formValues))
        localStorage.setItem('@raciocinioTecnicoPericial', JSON.stringify(formValues)) 
        
        const dadoSelecionadoPerguntaSimonin = []

        docPerguntaSimonin.map(({id, name}) => {
            /* if (e === true) { */

                /* const obj = {
                    id: id,
                    pergunta: name,
                    resposta: formValues.respostaSimonin,
                    comentario: formValues.comentarioSimonin
                } */

                /* console.log(e) */
 
                /* dadoSelecionadoPerguntaSimonin.push(obj) */
                /* console.log('***dadoSelecionado', dadoSelecionadoPerguntaSimonin)  */ 
                    
           /*  } */
            /* return null
        })

        if (dadoSelecionadoPerguntaSimonin) {

            sessionStorage.setItem('@raciocinioTecnicoPericial@simonin', JSON.stringify(dadoSelecionadoPerguntaSimonin))
            localStorage.setItem('@raciocinioTecnicoPericial@simonin', JSON.stringify(dadoSelecionadoPerguntaSimonin)) 
        }

        const dadoSelecionadoPerguntaPenteado = []

        docPerguntaPenteado.map(({id, name, isChecked}) => {
            if (isChecked === true) {
 
                dadoSelecionadoPerguntaPenteado.push({id: id, name: name}) */
                /* console.log('***dadoSelecionado', dadoSelecionadoPerguntaPenteado)  */ 
                    
          /*   }
            return null
        })

        if (dadoSelecionadoPerguntaPenteado) {

            sessionStorage.setItem('@raciocinioTecnicoPericial@penteado', JSON.stringify(dadoSelecionadoPerguntaPenteado))
            localStorage.setItem('@raciocinioTecnicoPericial@penteado', JSON.stringify(dadoSelecionadoPerguntaPenteado)) 
        }

        const dadoSelecionadoPerguntaFranchini = []

        docPerguntaFranchini.map(({id, name, isChecked}) => {
            if (isChecked === true) {
 
                dadoSelecionadoPerguntaFranchini.push({id: id, name: name}) */
                /* console.log('***dadoSelecionado', dadoSelecionadoPerguntaFranchini)  */ 
                    
            /* }
            return null
        })

        if (dadoSelecionadoPerguntaFranchini) {

            sessionStorage.setItem('@raciocinioTecnicoPericial@franchini', JSON.stringify(dadoSelecionadoPerguntaFranchini))
            localStorage.setItem('@raciocinioTecnicoPericial@franchini', JSON.stringify(dadoSelecionadoPerguntaFranchini)) 
        }

        const dadoSelecionadoPerguntaBradfordHill = []

        docPerguntaBradfordHill.map(({id, name, isChecked}) => {
            if (isChecked === true) {
 
                dadoSelecionadoPerguntaBradfordHill.push({id: id, name: name}) */
                /* console.log('***dadoSelecionado', dadoSelecionadoPerguntaBradfordHill)  */ 
                    
           /*  }
            return null
        })

        if (dadoSelecionadoPerguntaBradfordHill) {

            sessionStorage.setItem('@raciocinioTecnicoPericial@bradfordHill', JSON.stringify(dadoSelecionadoPerguntaBradfordHill))
            localStorage.setItem('@raciocinioTecnicoPericial@bradfordHill', JSON.stringify(dadoSelecionadoPerguntaBradfordHill)) 
        }

        const dadoSelecionadoPerguntaCfm = []

        docPerguntaCfm.map(({id, name, isChecked}) => {
            if (isChecked === true) {
 
                dadoSelecionadoPerguntaCfm.push({id: id, name: name}) */
                /* console.log('***dadoSelecionado', dadoSelecionadoPerguntaCfm)  */ 
                    
           /*  }
            return null
        })

        if (dadoSelecionadoPerguntaCfm) {

            sessionStorage.setItem('@raciocinioTecnicoPericial@cfm', JSON.stringify(dadoSelecionadoPerguntaCfm))
            localStorage.setItem('@raciocinioTecnicoPericial@cfm', JSON.stringify(dadoSelecionadoPerguntaCfm)) 
        }

        const dadoSelecionadoPerguntaConcausa = []

        docPerguntaConcausa.map(({id, name, isChecked}) => {
            if (isChecked === true) {
 
                dadoSelecionadoPerguntaConcausa.push({id: id, name: name}) */
                /* console.log('***dadoSelecionado', dadoSelecionadoPerguntaConcausa)  */ 
                    
         /*   }
            return null
        })

        if (dadoSelecionadoPerguntaConcausa) {

            sessionStorage.setItem('@raciocinioTecnicoPericial@concausa', JSON.stringify(dadoSelecionadoPerguntaConcausa))
            localStorage.setItem('@raciocinioTecnicoPericial@concausa', JSON.stringify(dadoSelecionadoPerguntaConcausa)) 
        }


    }

    const limparDados = () => {
        
        const alerta = alert('Tem certeza que quer apagar os dados?')

        if (!alerta === false || undefined) {
            setFormValues({})
            localStorage.removeItem('@raciocinioTecnicoPericial')
            sessionStorage.removeItem('@raciocinioTecnicoPericial')
        }   
    }


  return (
    <>
    <fieldset>
        <legend>Dados Raciocinio Técnico Pericial</legend>
        <div>
            <form onSubmit={handleSubmit}>
            <fieldset>
                <legend>Critérios de Simonin</legend>
                <table className="criteriosSimonin">
                    <thead>
                        <tr>
                            <th>Interpretação</th>
                            <th>Conclusão</th>
                            <th>Explicação</th>
                        </tr>
                    </thead>
                    <tbody>        
                    {docPerguntaSimonin.map(({id, name}) => {
                        return (
                        <tr key={id}>
                            <td>                           
                                {formValues.perguntaSimonin = name || []}
                            </td>
                            <td>
                                <select 
                                    name="respostaSimonin" 
                                    onChange={handleInputChange}
                                    value={formValues.respostaSimonin || ''}
                                >
                                <option value="" defaultValue>Selecione a resposta:</option>
                                <option value="Não atendido">Não atendido</option>
                                <option value="Atendido">Atendido</option>
                                </select>
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="comentarioSimonin" 
                                    placeholder="Comentários"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioSimonin || ''}
                                />
                            </td>
                        </tr>               
                    )  
                })
            }

                    </tbody>
                </table>
                <select 
                    name="conclusaoSimonin" 
                    onChange={handleInputChange}
                    value={formValues.conclusaoSimonin || ''}
                >
                    <option value="" defaultValue>Selecione a conclusão:</option>
                    <option value="Não é plausível o nexo causal">Não é plausível o nexo causal</option>
                    <option value="É duvidoso o nexo causal">É duvidoso o nexo causal</option>
                    <option value="É plausível o nexo causal">É plausível o nexo causal</option>             
                </select>
                <textarea 
                    name="conclusaoSimoninComentario" 
                    placeholder="Comentários sobre a conclusao" 
                        cols="30" 
                        rows="10"
                        onChange={handleInputChange}
                        value={formValues.conclusaoSimoninComentario || ''}
                />
            </fieldset>

             <fieldset>
                <legend>Critérios de Penteado</legend>
                <table className="criteriosPenteado">
                    <thead>
                        <tr>
                            <th>Interpretação</th>
                            <th>Conclusão</th>
                            <th>Explicação</th>
                        </tr>
                    </thead>
                    <tbody>        
                    {docPerguntaPenteado.map(({id, name}) => {
                        return (
                    
                        <tr key={id}>
                            <td>                           
                                {formValues.perguntaPenteado = name || []}
                            </td>
                            <td>
                                <select 
                                    name="respostaPenteado" 
                                    onChange={handleInputChange}
                                    value={formValues.respostaPenteado || ''}
                                >
                                    <option value="" defaultValue>Selecione a resposta:</option>
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                    <option value="Não se aplica">Não se aplica</option>
                                    <option value="Prejudicado">Prejudicado</option>
                                </select>
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="comentarioPenteado" 
                                    placeholder="Comentários"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioPenteado || ''}
                                />
                            </td>
                        </tr>                   
                    )  
                })
            }

                    </tbody>
                </table>
                <select 
                    name="conclusaoPenteado" 
                    onChange={handleInputChange}
                    value={formValues.conclusaoPenteado || ''}
                >
                    <option value="" defaultValue>Selecione a conclusão:</option>
                    <option value="Não é plausível o nexo causal">Não é plausível o nexo causal</option>
                    <option value="É plausível o nexo causal">É plausível o nexo causal</option>             
                </select>
                <textarea 
                    name="conclusaoPenteadoComentario" 
                    placeholder="Comentários sobre a conclusao" 
                        cols="30" 
                        rows="10"
                        onChange={handleInputChange}
                        value={formValues.conclusaoPenteadoComentario || ''}
                />
            </fieldset>

            <fieldset>
                <legend>Critérios de Franchini</legend>
                <table className="criteriosFranchini">
                    <thead>
                        <tr>
                            <th>Interpretação</th>
                            <th>Conclusão</th>
                            <th>Explicação</th>
                        </tr>
                    </thead>
                    <tbody>        
                    {docPerguntaFranchini.map(({id, name}) => {
                        return (
                    
                        <tr key={id}>
                            <td>                           
                                {formValues.perguntaFranchini = name || []}
                            </td>
                            <td>
                                <select 
                                    name="respostaFranchini" 
                                    onChange={handleInputChange}
                                    value={formValues.respostaFranchini || ''}
                                >
                                    <option value="" defaultValue>Selecione a resposta:</option>
                                    <option value="Não atendido">Não atendido</option>
                                    <option value="Atendido">Atendido</option>
                                </select>
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="comentarioFranchini" 
                                    placeholder="Comentários"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioFranchini || ''}
                                />
                            </td>
                        </tr>                    
                    )  
                })
            }

                    </tbody>
                </table>
                <select 
                    name="conclusaoFranchini" 
                    onChange={handleInputChange}
                    value={formValues.conclusaoFranchini || ''}
                >
                    <option value="" defaultValue>Selecione a conclusão:</option>
                    <option value="Não é plausível o nexo causal">Não é plausível o nexo causal</option>
                    <option value="É plausível o nexo causal">É plausível o nexo causal</option>             
                </select>
                <textarea 
                    name="conclusaoFranchiniComentario" 
                    placeholder="Comentários sobre a conclusao" 
                        cols="30" 
                        rows="10"
                        onChange={handleInputChange}
                        value={formValues.conclusaoFranchiniComentario || ''}
                />
            </fieldset>

            <fieldset>
                <legend>Critérios de BradfordHill</legend>
                <table className="criteriosBradfordHill">
                    <thead>
                        <tr>
                            <th>Interpretação</th>
                            <th>Conclusão</th>
                            <th>Explicação</th>
                        </tr>
                    </thead>
                    <tbody>        
                    {docPerguntaBradfordHill.map(({id, name}) => {
                        return (
                    
                        <tr key={id}>
                            <td>                           
                                {formValues.perguntaBradfordHill = name || []}
                            </td>
                            <td>
                                <select 
                                    name="respostaBradfordHill" 
                                    onChange={handleInputChange}
                                    value={formValues.respostaBradfordHill || ''}
                                >
                                    <option value="" defaultValue>Selecione a resposta:</option>
                                    <option value="Não atendido">Não atendido</option>
                                    <option value="Atendido">Atendido</option>
                                </select>
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="comentarioBradfordHill" 
                                    placeholder="Comentários"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioBradfordHill || ''}
                                />
                            </td>
                        </tr>                   
                    )  
                })
            }

                    </tbody>
                </table>
                <select 
                    name="conclusaoBradfordHill" 
                    onChange={handleInputChange}
                    value={formValues.conclusaoBradfordHill || ''}
                >
                    <option value="" defaultValue>Selecione a conclusão:</option>
                    <option value="É duvidoso o nexo causal">É duvidoso o nexo causal</option>
                    <option value="Não é plausível o nexo causal">Não é plausível o nexo causal</option>
                    <option value="É plausível o nexo causal">É plausível o nexo causal</option>             
                </select>
                <textarea 
                    name="conclusaoBradfordHillComentario" 
                    placeholder="Comentários sobre a conclusao" 
                        cols="30" 
                        rows="10"
                        onChange={handleInputChange}
                        value={formValues.conclusaoBradfordHillComentario || ''}
                />
            </fieldset>

            <fieldset>
                <legend>Critérios de Cfm</legend>
                <table className="criteriosCfm">
                    <thead>
                        <tr>
                            <th>Interpretação</th>
                            <th>Conclusão</th>
                            <th>Explicação</th>
                        </tr>
                    </thead>
                    <tbody>        
                    {docPerguntaCfm.map(({id, name}) => {
                        return (
                    
                        <tr key={id}>
                            <td>                            
                                {formValues.perguntaCfm = name || []}
                            </td>
                            <td>
                                <select 
                                    name="respostaCfm" 
                                    onChange={handleInputChange}
                                    value={formValues.respostaCfm || ''}
                                >
                                    <option value="" defaultValue>Selecione a resposta:</option>
                                    <option value="Atendido">Atendido</option>
                                    <option value="Não atendido">Não atendido</option>
                                    <option value="Não se aplica">Não se aplica</option>
                                </select>
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="comentarioCfm" 
                                    placeholder="Comentários"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioCfm || ''}
                                />
                            </td>
                        </tr>                   
                    )  
                })
            }

                    </tbody>
                </table>
                <select 
                    name="conclusaoCfm" 
                    onChange={handleInputChange}
                    value={formValues.conclusaoCfm || ''}
                >
                    <option value="" defaultValue>Selecione a conclusão:</option>
                    <option value="Não é plausível o nexo causal">Não é plausível o nexo causal</option>
                    <option value="É plausível o nexo causal">É plausível o nexo causal</option>             
                </select>
                <textarea 
                    name="conclusaoCfmComentario" 
                    placeholder="Comentários sobre a conclusao" 
                        cols="30" 
                        rows="10"
                        onChange={handleInputChange}
                        value={formValues.conclusaoCfmComentario || ''}
                />
            </fieldset>

            <fieldset>
                <legend>Critérios de Concausa</legend>
                <table className="criteriosConcausa">
                    <thead>
                        <tr>
                            <th>Interpretação</th>
                            <th>Conclusão</th>
                            <th>Explicação</th>
                        </tr>
                    </thead>
                    <tbody>        
                    {docPerguntaConcausa.map(({id, name}) => {
                        return (
                    
                        <tr key={id}>
                            <td >               
                                {formValues.perguntaConcausa = name || []}
                            </td>
                            <td>
                                <select 
                                    name="respostaConcausa" 
                                    onChange={handleInputChange}
                                    value={formValues.respostaConcausa || ''}
                                >
                                    <option value="" defaultValue>Selecione a resposta:</option>
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                    <option value="Não se aplica">Não se aplica</option>
                                    <option value="Prejudicado">Prejudicado</option>
                                </select>
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="comentarioConcausa" 
                                    placeholder="Comentários"
                                    onChange={handleInputChange}
                                    value={formValues.comentarioConcausa || ''}
                                />
                            </td>
                        </tr>              
                    )  
                })
            }

                    </tbody>
                </table>
                <select 
                    name="conclusaoConcausa" 
                    onChange={handleInputChange}
                    value={formValues.conclusaoConcausa || ''}
                >
                    <option value="" defaultValue>Selecione a conclusão:</option>
                    <option value="Não é plausível o nexo causal">Não é plausível o nexo causal</option>
                    <option value="É plausível o nexo causal">É plausível o nexo causal</option>             
                </select>
                <textarea 
                    name="conclusaoConcausaComentario" 
                    placeholder="Comentários sobre a conclusao" 
                        cols="30" 
                        rows="10"
                        onChange={handleInputChange}
                        value={formValues.conclusaoConcausaComentario || ''}
                />
            </fieldset>

            

                
                            
                <hr />
                <button type='submit' onClick={handleSubmit}>Confirmar Dados Raciocinio Técnico Pericial</button>
                <hr />
                <br />
                                
                <p>Nome: </p>
                <p>CPF: </p>
                <hr />
                <br />
                <button onClick={limparDados}>Limpar dados</button>
            </form>
        </div>
    </fieldset>
    </>
  )
}  */