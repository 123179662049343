import React, { useState } from "react";
import styles from "../admin3/Admin3.module.css";

const Input = ({
  typeI,
  placeholder,
  onChange,
  value,
  name,
  size,
  maxLength,
  minLength,
}) => {
  return (
    <input
      type={typeI}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      size={size}
      maxLength={maxLength}
      minLength={minLength}
    />
  );
};

const Button = ({ typeB, children, className, onClick } /* , cor */) => {
  return (
    <button
      type={typeB}
      className={className} /* style={{backgroundColor:`${cor}`}} */
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Form = ({ OnSubmit, OnChange, children }) => {
  return <form onSubmit={OnSubmit}>{children}</form>;
};

export const Admin3 = () => {
  const [formValues, setFormValues] = useState({});
  const OnChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    console.log(
      "%c ***data",
      data,
      "***formValues",
      formValues,
      "***newValue",
      newValue
    );
  };

  const [array, setArray] = useState([]);
  
  const OnSubmit = (e) => {
    e.preventDefault();

    const l = [];
    l.push(formValues)
    setArray(l);
     /* return l */
  };

  /* useMemo(() => array, [array]) */

  console.log(formValues, array)

  return (
    <>
      <Form  /*  onSubmit={OnSubmit} */  > 
        <Input
          type={"text"}
          placeholder={"Insira o número do processo"}
          onChange={OnChange}
          name={"numProcesso"}
          size={"25"}
          minLength={"25"}
          maxLength={"25"}
          value={
            formValues.numProcesso !== undefined
              ? formValues.numProcesso
                  .trim()
                  .replace(/\D/g, "")
                  .replace(/(\d{7})(\d)/, "$1-$2")
                  .replace(/(-\d{2})(\d{1})/, "$1.$2")
                  .replace(/(\.\d{4})(\d{1})/, "$1.$2")
                  .replace(/(\.\d{1})(\d{2})(\d{4})/, "$1.$2.$3")
              : ""
          }
        />
        <Button
            /* type="submit"  */   onClick={OnSubmit} 
          className={styles.button}
        >
          Enviar Dados
        </Button>
       </Form> 

       {array.lenght > 0 ? (
        <ul>
          {array.map(({numProcesso}, i) => (
            <li key={i}>{numProcesso}</li>
          ))}
        </ul>
      ):'xxxxxx'} 
      {array.lenght === 0 && <p>Sem dados</p>}
       
      
    </>
  );
};
