export const Cbo = [
{"id_Cbo":"1", "codigo":"10105", "titulo":"Oficial general da aeronautica"},
 {"id_Cbo":"2", "codigo":"10110", "titulo":"Oficial general do exercito"},
 {"id_Cbo":"3", "codigo":"10115", "titulo":"Oficial general da marinha"},
 {"id_Cbo":"4", "codigo":"10205", "titulo":"Oficial da aeronautica"},
 {"id_Cbo":"5", "codigo":"10210", "titulo":"Oficial do exercito"},
 {"id_Cbo":"6", "codigo":"10215", "titulo":"Oficial da marinha"},
 {"id_Cbo":"7", "codigo":"10305", "titulo":"Praca da aeronautica"},
 {"id_Cbo":"8", "codigo":"10310", "titulo":"Praca do exercito"},
 {"id_Cbo":"9", "codigo":"10315", "titulo":"Praca da marinha"},
 {"id_Cbo":"10", "codigo":"20105", "titulo":"Coronel da policia militar"},
 {"id_Cbo":"11", "codigo":"20110", "titulo":"Tenente-coronel da policia militar"},
 {"id_Cbo":"12", "codigo":"20115", "titulo":"Major da policia militar"},
 {"id_Cbo":"13", "codigo":"20205", "titulo":"Capitao da policia militar"},
 {"id_Cbo":"14", "codigo":"20305", "titulo":"Primeiro tenente de policia militar"},
 {"id_Cbo":"15", "codigo":"20310", "titulo":"Segundo tenente de policia militar"},
 {"id_Cbo":"16", "codigo":"21105", "titulo":"Subtenente da policia militar"},
 {"id_Cbo":"17", "codigo":"21110", "titulo":"Sargento da policia militar"},
 {"id_Cbo":"18", "codigo":"21205", "titulo":"Cabo da policia militar"},
 {"id_Cbo":"19", "codigo":"21210", "titulo":"Soldado da policia militar"},
 {"id_Cbo":"20", "codigo":"30105", "titulo":"Coronel bombeiro militar"},
 {"id_Cbo":"21", "codigo":"30110", "titulo":"Major bombeiro militar"},
 {"id_Cbo":"22", "codigo":"30115", "titulo":"Tenente-coronel bombeiro militar"},
 {"id_Cbo":"23", "codigo":"30205", "titulo":"Capitao bombeiro militar"},
 {"id_Cbo":"24", "codigo":"30305", "titulo":"Tenente do corpo de bombeiros militar"},
 {"id_Cbo":"25", "codigo":"31105", "titulo":"Subtenente bombeiro militar"},
 {"id_Cbo":"26", "codigo":"31110", "titulo":"Sargento bombeiro militar"},
 {"id_Cbo":"27", "codigo":"31205", "titulo":"Cabo bombeiro militar"},
 {"id_Cbo":"28", "codigo":"31210", "titulo":"Soldado bombeiro militar"},
 {"id_Cbo":"29", "codigo":"111105", "titulo":"Senador"},
 {"id_Cbo":"30", "codigo":"111110", "titulo":"Deputado federal"},
 {"id_Cbo":"31", "codigo":"111115", "titulo":"Deputado estadual e distrital"},
 {"id_Cbo":"32", "codigo":"111120", "titulo":"Vereador"},
 {"id_Cbo":"33", "codigo":"111205", "titulo":"Presidente da republica"},
 {"id_Cbo":"34", "codigo":"111210", "titulo":"Vice-presidente da republica"},
 {"id_Cbo":"35", "codigo":"111215", "titulo":"Ministro de estado"},
 {"id_Cbo":"36", "codigo":"111220", "titulo":"Secretario - executivo"},
 {"id_Cbo":"37", "codigo":"111225", "titulo":"Membro superior do poder executivo"},
 {"id_Cbo":"38", "codigo":"111230", "titulo":"Governador de estado"},
 {"id_Cbo":"39", "codigo":"111235", "titulo":"Governador do distrito federal"},
 {"id_Cbo":"40", "codigo":"111240", "titulo":"Vice-governador de estado"},
 {"id_Cbo":"41", "codigo":"111245", "titulo":"Vice-governador do distrito federal"},
 {"id_Cbo":"42", "codigo":"111250", "titulo":"Prefeito"},
 {"id_Cbo":"43", "codigo":"111255", "titulo":"Vice-prefeito"},
 {"id_Cbo":"44", "codigo":"111305", "titulo":"Ministro do supremo tribunal federal"},
 {"id_Cbo":"45", "codigo":"111310", "titulo":"Ministro do superior tribunal de justica"},
 {"id_Cbo":"46", "codigo":"111315", "titulo":"Ministro do  superior tribunal militar"},
 {"id_Cbo":"47", "codigo":"111320", "titulo":"Ministro do  superior tribunal do trabalho"},
 {"id_Cbo":"48", "codigo":"111325", "titulo":"Juiz de direito"},
 {"id_Cbo":"49", "codigo":"111330", "titulo":"Juiz federal"},
 {"id_Cbo":"50", "codigo":"111335", "titulo":"Juiz auditor federal - justica militar"},
 {"id_Cbo":"51", "codigo":"111340", "titulo":"Juiz auditor estadual - justica militar"},
 {"id_Cbo":"52", "codigo":"111345", "titulo":"Juiz do trabalho"},
 {"id_Cbo":"53", "codigo":"111405", "titulo":"Dirigente do servico publico federal"},
 {"id_Cbo":"54", "codigo":"111410", "titulo":"Dirigente do servico publico estadual e distrital"},
 {"id_Cbo":"55", "codigo":"111415", "titulo":"Dirigente do servico publico municipal"},
 {"id_Cbo":"56", "codigo":"111505", "titulo":"Especialista de politicas publicas e gestao governamental - eppgg"},
 {"id_Cbo":"57", "codigo":"111510", "titulo":"Analista de planejamento e orcamento - apo"},
 {"id_Cbo":"58", "codigo":"113005", "titulo":"Cacique"},
 {"id_Cbo":"59", "codigo":"113010", "titulo":"Lider de comunidade caicara"},
 {"id_Cbo":"60", "codigo":"113015", "titulo":"Membro de lideranca quilombola"},
 {"id_Cbo":"61", "codigo":"114105", "titulo":"Dirigente de partido politico"},
 {"id_Cbo":"62", "codigo":"114205", "titulo":"Dirigentes de entidades de trabalhadores"},
 {"id_Cbo":"63", "codigo":"114210", "titulo":"Dirigentes de entidades patronais"},
 {"id_Cbo":"64", "codigo":"114305", "titulo":"Dirigente e administrador de organizacao religiosa"},
 {"id_Cbo":"65", "codigo":"114405", "titulo":"Dirigente e administrador de organizacao da sociedade civil sem fins lucrativos"},
 {"id_Cbo":"66", "codigo":"121005", "titulo":"Diretor de planejamento estrategico"},
 {"id_Cbo":"67", "codigo":"121010", "titulo":"Diretor geral de empresa e organizacoes (exceto de interesse publico)"},
 {"id_Cbo":"68", "codigo":"122105", "titulo":"Diretor de producao e operacoes em empresa agropecuaria"},
 {"id_Cbo":"69", "codigo":"122110", "titulo":"Diretor de producao e operacoes em empresa aquicola"},
 {"id_Cbo":"70", "codigo":"122115", "titulo":"Diretor de producao e operacoes em empresa florestal"},
 {"id_Cbo":"71", "codigo":"122120", "titulo":"Diretor de producao e operacoes em empresa pesqueira"},
 {"id_Cbo":"72", "codigo":"122205", "titulo":"Diretor de producao e operacoes da industria de transformacao, extracao mineral e utilidades"},
 {"id_Cbo":"73", "codigo":"122305", "titulo":"Diretor de operacoes de obras publica e civil"},
 {"id_Cbo":"74", "codigo":"122405", "titulo":"Diretor de operacoes comerciais (comercio atacadista e varejista)"},
 {"id_Cbo":"75", "codigo":"122505", "titulo":"Diretor de  producao e operacoes de alimentacao"},
 {"id_Cbo":"76", "codigo":"122510", "titulo":"Diretor de  producao e operacoes de hotel"},
 {"id_Cbo":"77", "codigo":"122515", "titulo":"Diretor de  producao e operacoes de turismo"},
 {"id_Cbo":"78", "codigo":"122520", "titulo":"Turismologo"},
 {"id_Cbo":"79", "codigo":"122605", "titulo":"Diretor de operacoes de correios"},
 {"id_Cbo":"80", "codigo":"122610", "titulo":"Diretor de operacoes de servicos de armazenamento"},
 {"id_Cbo":"81", "codigo":"122615", "titulo":"Diretor de operacoes de servicos de telecomunicacoes"},
 {"id_Cbo":"82", "codigo":"122620", "titulo":"Diretor de operacoes de servicos de transporte"},
 {"id_Cbo":"83", "codigo":"122705", "titulo":"Diretor comercial em operacoes de intermediacao financeira"},
 {"id_Cbo":"84", "codigo":"122710", "titulo":"Diretor de produtos bancarios"},
 {"id_Cbo":"85", "codigo":"122715", "titulo":"Diretor de credito rural"},
 {"id_Cbo":"86", "codigo":"122720", "titulo":"Diretor de cambio e comercio exterior"},
 {"id_Cbo":"87", "codigo":"122725", "titulo":"Diretor de compliance"},
 {"id_Cbo":"88", "codigo":"122730", "titulo":"Diretor de credito (exceto credito imobiliario)"},
 {"id_Cbo":"89", "codigo":"122735", "titulo":"Diretor de credito imobiliario"},
 {"id_Cbo":"90", "codigo":"122740", "titulo":"Diretor de leasing"},
 {"id_Cbo":"91", "codigo":"122745", "titulo":"Diretor de mercado de capitais"},
 {"id_Cbo":"92", "codigo":"122750", "titulo":"Diretor de recuperacao de creditos em operacoes de intermediacao financeira"},
 {"id_Cbo":"93", "codigo":"122755", "titulo":"Diretor de riscos de mercado"},
 {"id_Cbo":"94", "codigo":"123105", "titulo":"Diretor administrativo"},
 {"id_Cbo":"95", "codigo":"123110", "titulo":"Diretor administrativo e financeiro"},
 {"id_Cbo":"96", "codigo":"123115", "titulo":"Diretor financeiro"},
 {"id_Cbo":"97", "codigo":"123205", "titulo":"Diretor de recursos humanos"},
 {"id_Cbo":"98", "codigo":"123210", "titulo":"Diretor de relacoes de trabalho"},
 {"id_Cbo":"99", "codigo":"123305", "titulo":"Diretor comercial"},
 {"id_Cbo":"100", "codigo":"123310", "titulo":"Diretor de marketing"},
 {"id_Cbo":"101", "codigo":"123405", "titulo":"Diretor de suprimentos"},
 {"id_Cbo":"102", "codigo":"123410", "titulo":"Diretor de suprimentos no servico publico"},
 {"id_Cbo":"103", "codigo":"123605", "titulo":"Diretor de tecnologia da informacao"},
 {"id_Cbo":"104", "codigo":"123705", "titulo":"Diretor de pesquisa e desenvolvimento (p&d)"},
 {"id_Cbo":"105", "codigo":"123805", "titulo":"Diretor de manutencao"},
 {"id_Cbo":"106", "codigo":"131105", "titulo":"Diretor de servicos culturais"},
 {"id_Cbo":"107", "codigo":"131110", "titulo":"Diretor de servicos sociais"},
 {"id_Cbo":"108", "codigo":"131115", "titulo":"Gerente de servicos culturais"},
 {"id_Cbo":"109", "codigo":"131120", "titulo":"Gerente de servicos sociais"},
 {"id_Cbo":"110", "codigo":"131205", "titulo":"Diretor de servicos de saude"},
 {"id_Cbo":"111", "codigo":"131210", "titulo":"Gerente de servicos de saude"},
 {"id_Cbo":"112", "codigo":"131215", "titulo":"Tecnologo em gestao hospitalar"},
 {"id_Cbo":"113", "codigo":"131220", "titulo":"Gerontologo"},
 {"id_Cbo":"114", "codigo":"131225", "titulo":"Sanitarista"},
 {"id_Cbo":"115", "codigo":"131305", "titulo":"Diretor de instituicao educacional da area privada"},
 {"id_Cbo":"116", "codigo":"131310", "titulo":"Diretor de instituicao educacional publica"},
 {"id_Cbo":"117", "codigo":"131315", "titulo":"Gerente de instituicao educacional da area privada"},
 {"id_Cbo":"118", "codigo":"131320", "titulo":"Gerente de servicos educacionais da area publica"},
 {"id_Cbo":"119", "codigo":"141105", "titulo":"Gerente de producao e operacoes  aquicolas"},
 {"id_Cbo":"120", "codigo":"141110", "titulo":"Gerente de producao e operacoes  florestais"},
 {"id_Cbo":"121", "codigo":"141115", "titulo":"Gerente de producao e operacoes agropecuarias"},
 {"id_Cbo":"122", "codigo":"141120", "titulo":"Gerente de producao e operacoes pesqueiras"},
 {"id_Cbo":"123", "codigo":"141205", "titulo":"Gerente de producao e operacoes"},
 {"id_Cbo":"124", "codigo":"141305", "titulo":"Gerente de producao e operacoes da construcao civil e obras publicas"},
 {"id_Cbo":"125", "codigo":"141405", "titulo":"Comerciante atacadista"},
 {"id_Cbo":"126", "codigo":"141410", "titulo":"Comerciante varejista"},
 {"id_Cbo":"127", "codigo":"141415", "titulo":"Gerente de loja e supermercado"},
 {"id_Cbo":"128", "codigo":"141420", "titulo":"Gerente de operacoes de servicos de assistencia tecnica"},
 {"id_Cbo":"129", "codigo":"141505", "titulo":"Gerente de hotel"},
 {"id_Cbo":"130", "codigo":"141510", "titulo":"Gerente de restaurante"},
 {"id_Cbo":"131", "codigo":"141515", "titulo":"Gerente de bar"},
 {"id_Cbo":"132", "codigo":"141520", "titulo":"Gerente de pensao"},
 {"id_Cbo":"133", "codigo":"141525", "titulo":"Gerente de turismo"},
 {"id_Cbo":"134", "codigo":"141605", "titulo":"Gerente de operacoes de transportes"},
 {"id_Cbo":"135", "codigo":"141610", "titulo":"Gerente de operacoes de correios e telecomunicacoes"},
 {"id_Cbo":"136", "codigo":"141615", "titulo":"Gerente de logistica (armazenagem e distribuicao)"},
 {"id_Cbo":"137", "codigo":"141705", "titulo":"Gerente de produtos bancarios"},
 {"id_Cbo":"138", "codigo":"141710", "titulo":"Gerente de agencia"},
 {"id_Cbo":"139", "codigo":"141715", "titulo":"Gerente de cambio e comercio exterior"},
 {"id_Cbo":"140", "codigo":"141720", "titulo":"Gerente de credito e cobranca"},
 {"id_Cbo":"141", "codigo":"141725", "titulo":"Gerente de credito imobiliario"},
 {"id_Cbo":"142", "codigo":"141730", "titulo":"Gerente de credito rural"},
 {"id_Cbo":"143", "codigo":"141735", "titulo":"Gerente de recuperacao de credito"},
 {"id_Cbo":"144", "codigo":"141805", "titulo":"Gerente de administracao em aeroportos"},
 {"id_Cbo":"145", "codigo":"141810", "titulo":"Gerente de empresa aerea e empresa de servicos auxiliares ao transporte aereo (esata) em aeroportos"},
 {"id_Cbo":"146", "codigo":"141815", "titulo":"Gerente de operacoes em aeroportos"},
 {"id_Cbo":"147", "codigo":"141820", "titulo":"Gerente de operacoes de cargas"},
 {"id_Cbo":"148", "codigo":"141825", "titulo":"Gerente de seguranca da aviacao civil"},
 {"id_Cbo":"149", "codigo":"141830", "titulo":"Gerente de seguranca operacional (aviacao civil)"},
 {"id_Cbo":"150", "codigo":"142105", "titulo":"Gerente administrativo"},
 {"id_Cbo":"151", "codigo":"142110", "titulo":"Gerente de riscos"},
 {"id_Cbo":"152", "codigo":"142115", "titulo":"Gerente financeiro"},
 {"id_Cbo":"153", "codigo":"142120", "titulo":"Tecnologo em gestao administrativo- financeira"},
 {"id_Cbo":"154", "codigo":"142205", "titulo":"Gerente de recursos humanos"},
 {"id_Cbo":"155", "codigo":"142210", "titulo":"Gerente de departamento pessoal"},
 {"id_Cbo":"156", "codigo":"142305", "titulo":"Gerente comercial"},
 {"id_Cbo":"157", "codigo":"142310", "titulo":"Gerente de comunicacao"},
 {"id_Cbo":"158", "codigo":"142315", "titulo":"Gerente de marketing"},
 {"id_Cbo":"159", "codigo":"142320", "titulo":"Gerente de vendas"},
 {"id_Cbo":"160", "codigo":"142325", "titulo":"Relacoes publicas"},
 {"id_Cbo":"161", "codigo":"142330", "titulo":"Analista de negocios"},
 {"id_Cbo":"162", "codigo":"142335", "titulo":"Analista de pesquisa de mercado"},
 {"id_Cbo":"163", "codigo":"142340", "titulo":"Ouvidor"},
 {"id_Cbo":"164", "codigo":"142345", "titulo":"Profissional de relacoes institucionais e governamentais"},
 {"id_Cbo":"165", "codigo":"142350", "titulo":"Profissonal de relacoes internacionais"},
 {"id_Cbo":"166", "codigo":"142405", "titulo":"Gerente de compras"},
 {"id_Cbo":"167", "codigo":"142410", "titulo":"Gerente de suprimentos"},
 {"id_Cbo":"168", "codigo":"142415", "titulo":"Gerente de almoxarifado"},
 {"id_Cbo":"169", "codigo":"142505", "titulo":"Gerente de infraestrutura de tecnologia da informacao"},
 {"id_Cbo":"170", "codigo":"142510", "titulo":"Gerente de desenvolvimento de sistemas"},
 {"id_Cbo":"171", "codigo":"142515", "titulo":"Gerente de operacao de tecnologia da informacao"},
 {"id_Cbo":"172", "codigo":"142520", "titulo":"Gerente de projetos de tecnologia da informacao"},
 {"id_Cbo":"173", "codigo":"142525", "titulo":"Gerente de seguranca da informacao"},
 {"id_Cbo":"174", "codigo":"142530", "titulo":"Gerente de suporte tecnico de tecnologia da informacao"},
 {"id_Cbo":"175", "codigo":"142535", "titulo":"Tecnologo em gestao da tecnologia da informacao"},
 {"id_Cbo":"176", "codigo":"142605", "titulo":"Gerente de pesquisa e desenvolvimento (p&d)"},
 {"id_Cbo":"177", "codigo":"142610", "titulo":"Especialista em desenvolvimento de cigarros"},
 {"id_Cbo":"178", "codigo":"142705", "titulo":"Gerente de projetos e servicos de manutencao"},
 {"id_Cbo":"179", "codigo":"142710", "titulo":"Tecnologo em sistemas biomedicos"},
 {"id_Cbo":"180", "codigo":"201105", "titulo":"Bioengenheiro"},
 {"id_Cbo":"181", "codigo":"201110", "titulo":"Biotecnologista"},
 {"id_Cbo":"182", "codigo":"201115", "titulo":"Geneticista"},
 {"id_Cbo":"183", "codigo":"201205", "titulo":"Pesquisador em metrologia"},
 {"id_Cbo":"184", "codigo":"201210", "titulo":"Especialista em calibracoes metrologicas"},
 {"id_Cbo":"185", "codigo":"201215", "titulo":"Especialista em ensaios metrologicos"},
 {"id_Cbo":"186", "codigo":"201220", "titulo":"Especialista em instrumentacao metrologica"},
 {"id_Cbo":"187", "codigo":"201225", "titulo":"Especialista em materiais de referencia metrologica"},
 {"id_Cbo":"188", "codigo":"202105", "titulo":"Engenheiro mecatronico"},
 {"id_Cbo":"189", "codigo":"202110", "titulo":"Engenheiro de controle e automacao"},
 {"id_Cbo":"190", "codigo":"202115", "titulo":"Tecnologo em mecatronica"},
 {"id_Cbo":"191", "codigo":"202120", "titulo":"Tecnologo em automacao industrial"},
 {"id_Cbo":"192", "codigo":"203005", "titulo":"Pesquisador em biologia ambiental"},
 {"id_Cbo":"193", "codigo":"203010", "titulo":"Pesquisador em biologia animal"},
 {"id_Cbo":"194", "codigo":"203015", "titulo":"Pesquisador em biologia de microorganismos e parasitas"},
 {"id_Cbo":"195", "codigo":"203020", "titulo":"Pesquisador em biologia humana"},
 {"id_Cbo":"196", "codigo":"203025", "titulo":"Pesquisador em biologia vegetal"},
 {"id_Cbo":"197", "codigo":"203105", "titulo":"Pesquisador em ciencias da computacao e informatica"},
 {"id_Cbo":"198", "codigo":"203110", "titulo":"Pesquisador em ciencias da terra e meio ambiente"},
 {"id_Cbo":"199", "codigo":"203115", "titulo":"Pesquisador em fisica"},
 {"id_Cbo":"200", "codigo":"203120", "titulo":"Pesquisador em matematica"},
 {"id_Cbo":"201", "codigo":"203125", "titulo":"Pesquisador em quimica"},
 {"id_Cbo":"202", "codigo":"203205", "titulo":"Pesquisador de engenharia civil"},
 {"id_Cbo":"203", "codigo":"203210", "titulo":"Pesquisador de engenharia e tecnologia (outras areas da engenharia)"},
 {"id_Cbo":"204", "codigo":"203215", "titulo":"Pesquisador de engenharia eletrica e eletronica"},
 {"id_Cbo":"205", "codigo":"203220", "titulo":"Pesquisador de engenharia mecanica"},
 {"id_Cbo":"206", "codigo":"203225", "titulo":"Pesquisador de engenharia metalurgica, de minas e de materiais"},
 {"id_Cbo":"207", "codigo":"203230", "titulo":"Pesquisador de engenharia quimica"},
 {"id_Cbo":"208", "codigo":"203305", "titulo":"Pesquisador de clinica medica"},
 {"id_Cbo":"209", "codigo":"203310", "titulo":"Pesquisador de medicina basica"},
 {"id_Cbo":"210", "codigo":"203315", "titulo":"Pesquisador em medicina veterinaria"},
 {"id_Cbo":"211", "codigo":"203320", "titulo":"Pesquisador em saude coletiva"},
 {"id_Cbo":"212", "codigo":"203405", "titulo":"Pesquisador em ciencias agronomicas"},
 {"id_Cbo":"213", "codigo":"203410", "titulo":"Pesquisador em ciencias da pesca e aquicultura"},
 {"id_Cbo":"214", "codigo":"203415", "titulo":"Pesquisador em ciencias da zootecnia"},
 {"id_Cbo":"215", "codigo":"203420", "titulo":"Pesquisador em ciencias florestais"},
 {"id_Cbo":"216", "codigo":"203505", "titulo":"Pesquisador em ciencias sociais e humanas"},
 {"id_Cbo":"217", "codigo":"203510", "titulo":"Pesquisador em economia"},
 {"id_Cbo":"218", "codigo":"203515", "titulo":"Pesquisador em ciencias da educacao"},
 {"id_Cbo":"219", "codigo":"203520", "titulo":"Pesquisador em historia"},
 {"id_Cbo":"220", "codigo":"203525", "titulo":"Pesquisador em psicologia"},
 {"id_Cbo":"221", "codigo":"204105", "titulo":"Perito criminal"},
 {"id_Cbo":"222", "codigo":"211105", "titulo":"Atuario"},
 {"id_Cbo":"223", "codigo":"211110", "titulo":"Especialista em pesquisa operacional"},
 {"id_Cbo":"224", "codigo":"211115", "titulo":"Matematico"},
 {"id_Cbo":"225", "codigo":"211120", "titulo":"Matematico aplicado"},
 {"id_Cbo":"226", "codigo":"211205", "titulo":"Estatistico"},
 {"id_Cbo":"227", "codigo":"211210", "titulo":"Estatistico (estatistica aplicada)"},
 {"id_Cbo":"228", "codigo":"211215", "titulo":"Estatistico teorico"},
 {"id_Cbo":"229", "codigo":"212205", "titulo":"Engenheiro de aplicativos em computacao"},
 {"id_Cbo":"230", "codigo":"212210", "titulo":"Engenheiro de equipamentos em computacao"},
 {"id_Cbo":"231", "codigo":"212215", "titulo":"Engenheiros de sistemas operacionais em computacao"},
 {"id_Cbo":"232", "codigo":"212305", "titulo":"Administrador de banco de dados"},
 {"id_Cbo":"233", "codigo":"212310", "titulo":"Administrador de redes"},
 {"id_Cbo":"234", "codigo":"212315", "titulo":"Administrador de sistemas operacionais"},
 {"id_Cbo":"235", "codigo":"212320", "titulo":"Administrador em seguranca da informacao"},
 {"id_Cbo":"236", "codigo":"212405", "titulo":"Analista de desenvolvimento de sistemas"},
 {"id_Cbo":"237", "codigo":"212410", "titulo":"Analista de redes e de comunicacao de dados"},
 {"id_Cbo":"238", "codigo":"212415", "titulo":"Analista de sistemas de automacao"},
 {"id_Cbo":"239", "codigo":"212420", "titulo":"Analista de suporte computacional"},
 {"id_Cbo":"240", "codigo":"212425", "titulo":"Arquiteto de solucoes de tecnologia da informacao"},
 {"id_Cbo":"241", "codigo":"212430", "titulo":"Analista de testes de tecnologia da informacao"},
 {"id_Cbo":"242", "codigo":"213105", "titulo":"Fisico"},
 {"id_Cbo":"243", "codigo":"213110", "titulo":"Fisico (acustica)"},
 {"id_Cbo":"244", "codigo":"213115", "titulo":"Fisico (atomica e molecular)"},
 {"id_Cbo":"245", "codigo":"213120", "titulo":"Fisico (cosmologia)"},
 {"id_Cbo":"246", "codigo":"213125", "titulo":"Fisico (estatistica e matematica)"},
 {"id_Cbo":"247", "codigo":"213130", "titulo":"Fisico (fluidos)"},
 {"id_Cbo":"248", "codigo":"213135", "titulo":"Fisico (instrumentacao)"},
 {"id_Cbo":"249", "codigo":"213140", "titulo":"Fisico (materia condensada)"},
 {"id_Cbo":"250", "codigo":"213145", "titulo":"Fisico (materiais)"},
 {"id_Cbo":"251", "codigo":"213150", "titulo":"Fisico (medicina)"},
 {"id_Cbo":"252", "codigo":"213155", "titulo":"Fisico (nuclear e reatores)"},
 {"id_Cbo":"253", "codigo":"213160", "titulo":"Fisico (optica)"},
 {"id_Cbo":"254", "codigo":"213165", "titulo":"Fisico (particulas e campos)"},
 {"id_Cbo":"255", "codigo":"213170", "titulo":"Fisico (plasma)"},
 {"id_Cbo":"256", "codigo":"213175", "titulo":"Fisico (termica)"},
 {"id_Cbo":"257", "codigo":"213205", "titulo":"Quimico"},
 {"id_Cbo":"258", "codigo":"213210", "titulo":"Quimico industrial"},
 {"id_Cbo":"259", "codigo":"213215", "titulo":"Tecnologo em processos quimicos"},
 {"id_Cbo":"260", "codigo":"213305", "titulo":"Astronomo"},
 {"id_Cbo":"261", "codigo":"213310", "titulo":"Geofisico espacial"},
 {"id_Cbo":"262", "codigo":"213315", "titulo":"Meteorologista"},
 {"id_Cbo":"263", "codigo":"213405", "titulo":"Geologo"},
 {"id_Cbo":"264", "codigo":"213410", "titulo":"Geologo de engenharia"},
 {"id_Cbo":"265", "codigo":"213415", "titulo":"Geofisico"},
 {"id_Cbo":"266", "codigo":"213420", "titulo":"Geoquimico"},
 {"id_Cbo":"267", "codigo":"213425", "titulo":"Hidrogeologo"},
 {"id_Cbo":"268", "codigo":"213430", "titulo":"Paleontologo"},
 {"id_Cbo":"269", "codigo":"213435", "titulo":"Petrografo"},
 {"id_Cbo":"270", "codigo":"213440", "titulo":"Oceanografo"},
 {"id_Cbo":"271", "codigo":"214005", "titulo":"Engenheiro ambiental"},
 {"id_Cbo":"272", "codigo":"214010", "titulo":"Tecnologo em meio ambiente"},
 {"id_Cbo":"273", "codigo":"214105", "titulo":"Arquiteto de edificacoes"},
 {"id_Cbo":"274", "codigo":"214110", "titulo":"Arquiteto de interiores"},
 {"id_Cbo":"275", "codigo":"214115", "titulo":"Arquiteto de patrimonio"},
 {"id_Cbo":"276", "codigo":"214120", "titulo":"Arquiteto paisagista"},
 {"id_Cbo":"277", "codigo":"214125", "titulo":"Arquiteto urbanista"},
 {"id_Cbo":"278", "codigo":"214130", "titulo":"Urbanista"},
 {"id_Cbo":"279", "codigo":"214205", "titulo":"Engenheiro civil"},
 {"id_Cbo":"280", "codigo":"214210", "titulo":"Engenheiro civil (aeroportos)"},
 {"id_Cbo":"281", "codigo":"214215", "titulo":"Engenheiro civil (edificacoes)"},
 {"id_Cbo":"282", "codigo":"214220", "titulo":"Engenheiro civil (estruturas metalicas)"},
 {"id_Cbo":"283", "codigo":"214225", "titulo":"Engenheiro civil (ferrovias e metrovias)"},
 {"id_Cbo":"284", "codigo":"214230", "titulo":"Engenheiro civil (geotecnia)"},
 {"id_Cbo":"285", "codigo":"214235", "titulo":"Engenheiro civil (hidrologia)"},
 {"id_Cbo":"286", "codigo":"214240", "titulo":"Engenheiro civil (hidraulica)"},
 {"id_Cbo":"287", "codigo":"214245", "titulo":"Engenheiro civil (pontes e viadutos)"},
 {"id_Cbo":"288", "codigo":"214250", "titulo":"Engenheiro civil (portos e vias navegaveis)"},
 {"id_Cbo":"289", "codigo":"214255", "titulo":"Engenheiro civil (rodovias)"},
 {"id_Cbo":"290", "codigo":"214260", "titulo":"Engenheiro civil (saneamento)"},
 {"id_Cbo":"291", "codigo":"214265", "titulo":"Engenheiro civil (tuneis)"},
 {"id_Cbo":"292", "codigo":"214270", "titulo":"Engenheiro civil (transportes e transito)"},
 {"id_Cbo":"293", "codigo":"214280", "titulo":"Tecnologo em construcao civil"},
 {"id_Cbo":"294", "codigo":"214305", "titulo":"Engenheiro eletricista"},
 {"id_Cbo":"295", "codigo":"214310", "titulo":"Engenheiro eletronico"},
 {"id_Cbo":"296", "codigo":"214315", "titulo":"Engenheiro eletricista de manutencao"},
 {"id_Cbo":"297", "codigo":"214320", "titulo":"Engenheiro eletricista de projetos"},
 {"id_Cbo":"298", "codigo":"214325", "titulo":"Engenheiro eletronico de manutencao"},
 {"id_Cbo":"299", "codigo":"214330", "titulo":"Engenheiro eletronico de projetos"},
 {"id_Cbo":"300", "codigo":"214335", "titulo":"Engenheiro de manutencao de telecomunicacoes"},
 {"id_Cbo":"301", "codigo":"214340", "titulo":"Engenheiro de telecomunicacoes"},
 {"id_Cbo":"302", "codigo":"214345", "titulo":"Engenheiro projetista de telecomunicacoes"},
 {"id_Cbo":"303", "codigo":"214350", "titulo":"Engenheiro de redes de comunicacao"},
 {"id_Cbo":"304", "codigo":"214360", "titulo":"Tecnologo em eletricidade"},
 {"id_Cbo":"305", "codigo":"214365", "titulo":"Tecnologo em eletronica"},
 {"id_Cbo":"306", "codigo":"214370", "titulo":"Tecnologo em telecomunicacoes"},
 {"id_Cbo":"307", "codigo":"214405", "titulo":"Engenheiro mecanico"},
 {"id_Cbo":"308", "codigo":"214410", "titulo":"Engenheiro mecanico automotivo"},
 {"id_Cbo":"309", "codigo":"214415", "titulo":"Engenheiro mecanico (energia nuclear)"},
 {"id_Cbo":"310", "codigo":"214420", "titulo":"Engenheiro mecanico industrial"},
 {"id_Cbo":"311", "codigo":"214425", "titulo":"Engenheiro aeronautico"},
 {"id_Cbo":"312", "codigo":"214430", "titulo":"Engenheiro naval"},
 {"id_Cbo":"313", "codigo":"214435", "titulo":"Tecnologo em fabricacao mecanica"},
 {"id_Cbo":"314", "codigo":"214505", "titulo":"Engenheiro quimico"},
 {"id_Cbo":"315", "codigo":"214510", "titulo":"Engenheiro quimico (industria quimica)"},
 {"id_Cbo":"316", "codigo":"214515", "titulo":"Engenheiro quimico (mineracao, metalurgia, siderurgia, cimenteira e ceramica)"},
 {"id_Cbo":"317", "codigo":"214520", "titulo":"Engenheiro quimico (papel e celulose)"},
 {"id_Cbo":"318", "codigo":"214525", "titulo":"Engenheiro quimico (petroleo e borracha)"},
 {"id_Cbo":"319", "codigo":"214530", "titulo":"Engenheiro quimico (utilidades e meio ambiente)"},
 {"id_Cbo":"320", "codigo":"214535", "titulo":"Tecnologo em producao sulcroalcooleira"},
 {"id_Cbo":"321", "codigo":"214605", "titulo":"Engenheiro de materiais"},
 {"id_Cbo":"322", "codigo":"214610", "titulo":"Engenheiro metalurgista"},
 {"id_Cbo":"323", "codigo":"214615", "titulo":"Tecnologo em metalurgia"},
 {"id_Cbo":"324", "codigo":"214705", "titulo":"Engenheiro de minas"},
 {"id_Cbo":"325", "codigo":"214710", "titulo":"Engenheiro de minas (beneficiamento)"},
 {"id_Cbo":"326", "codigo":"214715", "titulo":"Engenheiro de minas (lavra a ceu aberto)"},
 {"id_Cbo":"327", "codigo":"214720", "titulo":"Engenheiro de minas (lavra subterranea)"},
 {"id_Cbo":"328", "codigo":"214725", "titulo":"Engenheiro de minas (pesquisa mineral)"},
 {"id_Cbo":"329", "codigo":"214730", "titulo":"Engenheiro de minas (planejamento)"},
 {"id_Cbo":"330", "codigo":"214735", "titulo":"Engenheiro de minas (processo)"},
 {"id_Cbo":"331", "codigo":"214740", "titulo":"Engenheiro de minas (projeto)"},
 {"id_Cbo":"332", "codigo":"214745", "titulo":"Tecnologo em petroleo e gas"},
 {"id_Cbo":"333", "codigo":"214750", "titulo":"Tecnologo em rochas ornamentais"},
 {"id_Cbo":"334", "codigo":"214805", "titulo":"Engenheiro agrimensor"},
 {"id_Cbo":"335", "codigo":"214810", "titulo":"Engenheiro cartografo"},
 {"id_Cbo":"336", "codigo":"214905", "titulo":"Engenheiro de producao"},
 {"id_Cbo":"337", "codigo":"214910", "titulo":"Engenheiro de controle de qualidade"},
 {"id_Cbo":"338", "codigo":"214915", "titulo":"Engenheiro de seguranca do trabalho"},
 {"id_Cbo":"339", "codigo":"214920", "titulo":"Engenheiro de riscos"},
 {"id_Cbo":"340", "codigo":"214925", "titulo":"Engenheiro de tempos e movimentos"},
 {"id_Cbo":"341", "codigo":"214930", "titulo":"Tecnologo em producao industrial"},
 {"id_Cbo":"342", "codigo":"214935", "titulo":"Tecnologo em seguranca do trabalho"},
 {"id_Cbo":"343", "codigo":"214940", "titulo":"Higienista ocupacional"},
 {"id_Cbo":"344", "codigo":"214945", "titulo":"Engenheiro de logistica"},
 {"id_Cbo":"345", "codigo":"215105", "titulo":"Agente de manobra e docagem"},
 {"id_Cbo":"346", "codigo":"215110", "titulo":"Capitao de manobra da marinha mercante"},
 {"id_Cbo":"347", "codigo":"215115", "titulo":"Comandante da marinha mercante"},
 {"id_Cbo":"348", "codigo":"215120", "titulo":"Coordenador de operacoes de combate a poluicao no meio aquaviario"},
 {"id_Cbo":"349", "codigo":"215125", "titulo":"Imediato da marinha mercante"},
 {"id_Cbo":"350", "codigo":"215130", "titulo":"Inspetor de terminal"},
 {"id_Cbo":"351", "codigo":"215135", "titulo":"Inspetor naval"},
 {"id_Cbo":"352", "codigo":"215140", "titulo":"Oficial de quarto de navegacao da marinha mercante"},
 {"id_Cbo":"353", "codigo":"215145", "titulo":"Pratico de portos da marinha mercante"},
 {"id_Cbo":"354", "codigo":"215150", "titulo":"Vistoriador naval"},
 {"id_Cbo":"355", "codigo":"215205", "titulo":"Oficial superior de maquinas da marinha mercante"},
 {"id_Cbo":"356", "codigo":"215210", "titulo":"Primeiro oficial de maquinas da marinha mercante"},
 {"id_Cbo":"357", "codigo":"215215", "titulo":"Segundo oficial de maquinas da marinha mercante"},
 {"id_Cbo":"358", "codigo":"215220", "titulo":"Superintendente tecnico no transporte aquaviario"},
 {"id_Cbo":"359", "codigo":"215305", "titulo":"Piloto de aeronaves"},
 {"id_Cbo":"360", "codigo":"215310", "titulo":"Piloto de ensaios em voo"},
 {"id_Cbo":"361", "codigo":"215315", "titulo":"Instrutor de voo"},
 {"id_Cbo":"362", "codigo":"221105", "titulo":"Biologo"},
 {"id_Cbo":"363", "codigo":"221205", "titulo":"Biomedico"},
 {"id_Cbo":"364", "codigo":"222105", "titulo":"Engenheiro agricola"},
 {"id_Cbo":"365", "codigo":"222110", "titulo":"Engenheiro agronomo"},
 {"id_Cbo":"366", "codigo":"222115", "titulo":"Engenheiro de pesca"},
 {"id_Cbo":"367", "codigo":"222120", "titulo":"Engenheiro florestal"},
 {"id_Cbo":"368", "codigo":"222205", "titulo":"Engenheiro de alimentos"},
 {"id_Cbo":"369", "codigo":"222215", "titulo":"Tecnologo em alimentos"},
 {"id_Cbo":"370", "codigo":"223204", "titulo":"Cirurgiao dentista - auditor"},
 {"id_Cbo":"371", "codigo":"223208", "titulo":"Cirurgiao dentista - clinico geral"},
 {"id_Cbo":"372", "codigo":"223212", "titulo":"Cirurgiao dentista - endodontista"},
 {"id_Cbo":"373", "codigo":"223216", "titulo":"Cirurgiao dentista - epidemiologista"},
 {"id_Cbo":"374", "codigo":"223220", "titulo":"Cirurgiao dentista - estomatologista"},
 {"id_Cbo":"375", "codigo":"223224", "titulo":"Cirurgiao dentista - implantodontista"},
 {"id_Cbo":"376", "codigo":"223228", "titulo":"Cirurgiao dentista - odontogeriatra"},
 {"id_Cbo":"377", "codigo":"223232", "titulo":"Cirurgiao dentista - odontologista legal"},
 {"id_Cbo":"378", "codigo":"223236", "titulo":"Cirurgiao dentista - odontopediatra"},
 {"id_Cbo":"379", "codigo":"223240", "titulo":"Cirurgiao dentista - ortopedista e ortodontista"},
 {"id_Cbo":"380", "codigo":"223244", "titulo":"Cirurgiao dentista - patologista bucal"},
 {"id_Cbo":"381", "codigo":"223248", "titulo":"Cirurgiao dentista - periodontista"},
 {"id_Cbo":"382", "codigo":"223252", "titulo":"Cirurgiao dentista - protesiologo bucomaxilofacial"},
 {"id_Cbo":"383", "codigo":"223256", "titulo":"Cirurgiao dentista - protesista"},
 {"id_Cbo":"384", "codigo":"223260", "titulo":"Cirurgiao dentista - radiologista"},
 {"id_Cbo":"385", "codigo":"223264", "titulo":"Cirurgiao dentista - reabilitador oral"},
 {"id_Cbo":"386", "codigo":"223268", "titulo":"Cirurgiao dentista - traumatologista bucomaxilofacial"},
 {"id_Cbo":"387", "codigo":"223272", "titulo":"Cirurgiao dentista de saude coletiva"},
 {"id_Cbo":"388", "codigo":"223276", "titulo":"Cirurgiao dentista - odontologia do trabalho"},
 {"id_Cbo":"389", "codigo":"223280", "titulo":"Cirurgiao dentista - dentistica"},
 {"id_Cbo":"390", "codigo":"223284", "titulo":"Cirurgiao dentista - disfuncao temporomandibular e dor orofacial"},
 {"id_Cbo":"391", "codigo":"223288", "titulo":"Cirurgiao dentista - odontologia para pacientes com necessidades especiais"},
 {"id_Cbo":"392", "codigo":"223293", "titulo":"Cirurgiao-dentista da estrategia de saude da familia"},
 {"id_Cbo":"393", "codigo":"223305", "titulo":"Medico veterinario"},
 {"id_Cbo":"394", "codigo":"223310", "titulo":"Zootecnista"},
 {"id_Cbo":"395", "codigo":"223405", "titulo":"Farmaceutico"},
 {"id_Cbo":"396", "codigo":"223415", "titulo":"Farmaceutico analista clinico"},
 {"id_Cbo":"397", "codigo":"223420", "titulo":"Farmaceutico de alimentos"},
 {"id_Cbo":"398", "codigo":"223425", "titulo":"Farmaceutico praticas integrativas e complementares"},
 {"id_Cbo":"399", "codigo":"223430", "titulo":"Farmaceutico em saude publica"},
 {"id_Cbo":"400", "codigo":"223435", "titulo":"Farmaceutico industrial"},
 {"id_Cbo":"401", "codigo":"223440", "titulo":"Farmaceutico toxicologista"},
 {"id_Cbo":"402", "codigo":"223445", "titulo":"Farmaceutico hospitalar e clinico"},
 {"id_Cbo":"403", "codigo":"223505", "titulo":"Enfermeiro"},
 {"id_Cbo":"404", "codigo":"223510", "titulo":"Enfermeiro auditor"},
 {"id_Cbo":"405", "codigo":"223515", "titulo":"Enfermeiro de bordo"},
 {"id_Cbo":"406", "codigo":"223520", "titulo":"Enfermeiro de centro cirurgico"},
 {"id_Cbo":"407", "codigo":"223525", "titulo":"Enfermeiro de terapia intensiva"},
 {"id_Cbo":"408", "codigo":"223530", "titulo":"Enfermeiro do trabalho"},
 {"id_Cbo":"409", "codigo":"223535", "titulo":"Enfermeiro nefrologista"},
 {"id_Cbo":"410", "codigo":"223540", "titulo":"Enfermeiro neonatologista"},
 {"id_Cbo":"411", "codigo":"223545", "titulo":"Enfermeiro obstetrico"},
 {"id_Cbo":"412", "codigo":"223550", "titulo":"Enfermeiro psiquiatrico"},
 {"id_Cbo":"413", "codigo":"223555", "titulo":"Enfermeiro puericultor e pediatrico"},
 {"id_Cbo":"414", "codigo":"223560", "titulo":"Enfermeiro sanitarista"},
 {"id_Cbo":"415", "codigo":"223565", "titulo":"Enfermeiro da estrategia de saude da familia"},
 {"id_Cbo":"416", "codigo":"223570", "titulo":"Perfusionista"},
 {"id_Cbo":"417", "codigo":"223605", "titulo":"Fisioterapeuta geral"},
 {"id_Cbo":"418", "codigo":"223625", "titulo":"Fisioterapeuta respiratoria"},
 {"id_Cbo":"419", "codigo":"223630", "titulo":"Fisioterapeuta neurofuncional"},
 {"id_Cbo":"420", "codigo":"223635", "titulo":"Fisioterapeuta traumato-ortopedica funcional"},
 {"id_Cbo":"421", "codigo":"223640", "titulo":"Fisioterapeuta osteopata"},
 {"id_Cbo":"422", "codigo":"223645", "titulo":"Fisioterapeuta quiropraxista"},
 {"id_Cbo":"423", "codigo":"223650", "titulo":"Fisioterapeuta acupunturista"},
 {"id_Cbo":"424", "codigo":"223655", "titulo":"Fisioterapeuta esportivo"},
 {"id_Cbo":"425", "codigo":"223660", "titulo":"Fisioterapeuta  do trabalho"},
 {"id_Cbo":"426", "codigo":"223705", "titulo":"Dietista"},
 {"id_Cbo":"427", "codigo":"223710", "titulo":"Nutricionista"},
 {"id_Cbo":"428", "codigo":"223810", "titulo":"Fonoaudiologo geral"},
 {"id_Cbo":"429", "codigo":"223815", "titulo":"Fonoaudiologo educacional"},
 {"id_Cbo":"430", "codigo":"223820", "titulo":"Fonoaudiologo em audiologia"},
 {"id_Cbo":"431", "codigo":"223825", "titulo":"Fonoaudiologo em disfagia"},
 {"id_Cbo":"432", "codigo":"223830", "titulo":"Fonoaudiologo em linguagem"},
 {"id_Cbo":"433", "codigo":"223835", "titulo":"Fonoaudiologo em motricidade orofacial"},
 {"id_Cbo":"434", "codigo":"223840", "titulo":"Fonoaudiologo em saude coletiva"},
 {"id_Cbo":"435", "codigo":"223845", "titulo":"Fonoaudiologo em voz"},
 {"id_Cbo":"436", "codigo":"223905", "titulo":"Terapeuta ocupacional"},
 {"id_Cbo":"437", "codigo":"223910", "titulo":"Ortoptista"},
 {"id_Cbo":"438", "codigo":"223915", "titulo":"Psicomotricista"},
 {"id_Cbo":"439", "codigo":"224105", "titulo":"Avaliador fisico"},
 {"id_Cbo":"440", "codigo":"224110", "titulo":"Ludomotricista"},
 {"id_Cbo":"441", "codigo":"224115", "titulo":"Preparador de atleta"},
 {"id_Cbo":"442", "codigo":"224120", "titulo":"Preparador fisico"},
 {"id_Cbo":"443", "codigo":"224125", "titulo":"Tecnico de desporto individual e coletivo (exceto futebol)"},
 {"id_Cbo":"444", "codigo":"224130", "titulo":"Tecnico de laboratorio e fiscalizacao desportiva"},
 {"id_Cbo":"445", "codigo":"224135", "titulo":"Treinador profissional de futebol"},
 {"id_Cbo":"446", "codigo":"224140", "titulo":"Profissional de educacao fisica na saude"},
 {"id_Cbo":"447", "codigo":"225103", "titulo":"Medico infectologista"},
 {"id_Cbo":"448", "codigo":"225105", "titulo":"Medico acupunturista"},
 {"id_Cbo":"449", "codigo":"225106", "titulo":"Medico legista"},
 {"id_Cbo":"450", "codigo":"225109", "titulo":"Medico nefrologista"},
 {"id_Cbo":"451", "codigo":"225110", "titulo":"Medico alergista e imunologista"},
 {"id_Cbo":"452", "codigo":"225112", "titulo":"Medico neurologista"},
 {"id_Cbo":"453", "codigo":"225115", "titulo":"Medico angiologista"},
 {"id_Cbo":"454", "codigo":"225118", "titulo":"Medico nutrologista"},
 {"id_Cbo":"455", "codigo":"225120", "titulo":"Medico cardiologista"},
 {"id_Cbo":"456", "codigo":"225121", "titulo":"Medico oncologista clinico"},
 {"id_Cbo":"457", "codigo":"225122", "titulo":"Medico cancerologista pediatrico"},
 {"id_Cbo":"458", "codigo":"225124", "titulo":"Medico pediatra"},
 {"id_Cbo":"459", "codigo":"225125", "titulo":"Medico clinico"},
 {"id_Cbo":"460", "codigo":"225127", "titulo":"Medico pneumologista"},
 {"id_Cbo":"461", "codigo":"225130", "titulo":"Medico de familia e comunidade"},
 {"id_Cbo":"462", "codigo":"225133", "titulo":"Medico psiquiatra"},
 {"id_Cbo":"463", "codigo":"225135", "titulo":"Medico dermatologista"},
 {"id_Cbo":"464", "codigo":"225136", "titulo":"Medico reumatologista"},
 {"id_Cbo":"465", "codigo":"225139", "titulo":"Medico sanitarista"},
 {"id_Cbo":"466", "codigo":"225140", "titulo":"Medico do trabalho"},
 {"id_Cbo":"467", "codigo":"225142", "titulo":"Medico da estrategia de saude da familia"},
 {"id_Cbo":"468", "codigo":"225145", "titulo":"Medico em medicina de trafego"},
 {"id_Cbo":"469", "codigo":"225148", "titulo":"Medico anatomopatologista"},
 {"id_Cbo":"470", "codigo":"225150", "titulo":"Medico em medicina intensiva"},
 {"id_Cbo":"471", "codigo":"225151", "titulo":"Medico anestesiologista"},
 {"id_Cbo":"472", "codigo":"225154", "titulo":"Medico antroposofico"},
 {"id_Cbo":"473", "codigo":"225155", "titulo":"Medico endocrinologista e metabologista"},
 {"id_Cbo":"474", "codigo":"225160", "titulo":"Medico fisiatra"},
 {"id_Cbo":"475", "codigo":"225165", "titulo":"Medico gastroenterologista"},
 {"id_Cbo":"476", "codigo":"225170", "titulo":"Medico generalista"},
 {"id_Cbo":"477", "codigo":"225175", "titulo":"Medico geneticista"},
 {"id_Cbo":"478", "codigo":"225180", "titulo":"Medico geriatra"},
 {"id_Cbo":"479", "codigo":"225185", "titulo":"Medico hematologista"},
 {"id_Cbo":"480", "codigo":"225195", "titulo":"Medico homeopata"},
 {"id_Cbo":"481", "codigo":"225203", "titulo":"Medico em cirurgia vascular"},
 {"id_Cbo":"482", "codigo":"225210", "titulo":"Medico cirurgiao cardiovascular"},
 {"id_Cbo":"483", "codigo":"225215", "titulo":"Medico cirurgiao de cabeca e pescoco"},
 {"id_Cbo":"484", "codigo":"225220", "titulo":"Medico cirurgiao do aparelho digestivo"},
 {"id_Cbo":"485", "codigo":"225225", "titulo":"Medico cirurgiao geral"},
 {"id_Cbo":"486", "codigo":"225230", "titulo":"Medico cirurgiao pediatrico"},
 {"id_Cbo":"487", "codigo":"225235", "titulo":"Medico cirurgiao plastico"},
 {"id_Cbo":"488", "codigo":"225240", "titulo":"Medico cirurgiao toracico"},
 {"id_Cbo":"489", "codigo":"225250", "titulo":"Medico ginecologista e obstetra"},
 {"id_Cbo":"490", "codigo":"225255", "titulo":"Medico mastologista"},
 {"id_Cbo":"491", "codigo":"225260", "titulo":"Medico neurocirurgiao"},
 {"id_Cbo":"492", "codigo":"225265", "titulo":"Medico oftalmologista"},
 {"id_Cbo":"493", "codigo":"225270", "titulo":"Medico ortopedista e traumatologista"},
 {"id_Cbo":"494", "codigo":"225275", "titulo":"Medico otorrinolaringologista"},
 {"id_Cbo":"495", "codigo":"225280", "titulo":"Medico coloproctologista"},
 {"id_Cbo":"496", "codigo":"225285", "titulo":"Medico urologista"},
 {"id_Cbo":"497", "codigo":"225290", "titulo":"Medico cancerologista cirurgico"},
 {"id_Cbo":"498", "codigo":"225295", "titulo":"Medico cirurgiao da mao"},
 {"id_Cbo":"499", "codigo":"225305", "titulo":"Medico citopatologista"},
 {"id_Cbo":"500", "codigo":"225310", "titulo":"Medico em endoscopia"},
 {"id_Cbo":"501", "codigo":"225315", "titulo":"Medico em medicina nuclear"},
 {"id_Cbo":"502", "codigo":"225320", "titulo":"Medico em radiologia e diagnostico por imagem"},
 {"id_Cbo":"503", "codigo":"225325", "titulo":"Medico patologista"},
 {"id_Cbo":"504", "codigo":"225330", "titulo":"Medico radioterapeuta"},
 {"id_Cbo":"505", "codigo":"225335", "titulo":"Medico patologista clinico / medicina laboratorial"},
 {"id_Cbo":"506", "codigo":"225340", "titulo":"Medico hemoterapeuta"},
 {"id_Cbo":"507", "codigo":"225345", "titulo":"Medico hiperbarista"},
 {"id_Cbo":"508", "codigo":"225350", "titulo":"Medico neurofisiologista clinico"},
 {"id_Cbo":"509", "codigo":"225355", "titulo":"Medico radiologista intervencionista"},
 {"id_Cbo":"510", "codigo":"226105", "titulo":"Quiropraxista"},
 {"id_Cbo":"511", "codigo":"226110", "titulo":"Osteopata"},
 {"id_Cbo":"512", "codigo":"226305", "titulo":"Musicoterapeuta"},
 {"id_Cbo":"513", "codigo":"226310", "titulo":"Arteterapeuta"},
 {"id_Cbo":"514", "codigo":"226315", "titulo":"Equoterapeuta"},
 {"id_Cbo":"515", "codigo":"226320", "titulo":"Naturologo"},
 {"id_Cbo":"516", "codigo":"231105", "titulo":"Professor de nivel superior na educacao infantil (quatro a seis anos)"},
 {"id_Cbo":"517", "codigo":"231110", "titulo":"Professor de nivel superior na educacao infantil (zero a tres anos)"},
 {"id_Cbo":"518", "codigo":"231205", "titulo":"Professor da  educacao de jovens e adultos do ensino fundamental (primeira a quarta serie)"},
 {"id_Cbo":"519", "codigo":"231210", "titulo":"Professor de nivel superior do ensino fundamental (primeira a quarta serie)"},
 {"id_Cbo":"520", "codigo":"231305", "titulo":"Professor de ciencias exatas e naturais do ensino fundamental"},
 {"id_Cbo":"521", "codigo":"231310", "titulo":"Professor de educacao artistica do ensino fundamental"},
 {"id_Cbo":"522", "codigo":"231315", "titulo":"Professor de educacao fisica do ensino fundamental"},
 {"id_Cbo":"523", "codigo":"231320", "titulo":"Professor de geografia do ensino fundamental"},
 {"id_Cbo":"524", "codigo":"231325", "titulo":"Professor de historia do ensino fundamental"},
 {"id_Cbo":"525", "codigo":"231330", "titulo":"Professor de lingua estrangeira moderna do ensino fundamental"},
 {"id_Cbo":"526", "codigo":"231335", "titulo":"Professor de lingua portuguesa do ensino fundamental"},
 {"id_Cbo":"527", "codigo":"231340", "titulo":"Professor de matematica do ensino fundamental"},
 {"id_Cbo":"528", "codigo":"232105", "titulo":"Professor de artes no ensino medio"},
 {"id_Cbo":"529", "codigo":"232110", "titulo":"Professor de biologia no ensino medio"},
 {"id_Cbo":"530", "codigo":"232115", "titulo":"Professor de disciplinas pedagogicas no ensino medio"},
 {"id_Cbo":"531", "codigo":"232120", "titulo":"Professor de educacao fisica no ensino medio"},
 {"id_Cbo":"532", "codigo":"232125", "titulo":"Professor de filosofia no ensino medio"},
 {"id_Cbo":"533", "codigo":"232130", "titulo":"Professor de fisica no ensino medio"},
 {"id_Cbo":"534", "codigo":"232135", "titulo":"Professor de geografia no ensino medio"},
 {"id_Cbo":"535", "codigo":"232140", "titulo":"Professor de historia no ensino medio"},
 {"id_Cbo":"536", "codigo":"232145", "titulo":"Professor de lingua e literatura brasileira no ensino medio"},
 {"id_Cbo":"537", "codigo":"232150", "titulo":"Professor de lingua estrangeira moderna no ensino medio"},
 {"id_Cbo":"538", "codigo":"232155", "titulo":"Professor de matematica no ensino medio"},
 {"id_Cbo":"539", "codigo":"232160", "titulo":"Professor de psicologia no ensino medio"},
 {"id_Cbo":"540", "codigo":"232165", "titulo":"Professor de quimica no ensino medio"},
 {"id_Cbo":"541", "codigo":"232170", "titulo":"Professor de sociologia no ensino medio"},
 {"id_Cbo":"542", "codigo":"233105", "titulo":"Professor da area de meio ambiente"},
 {"id_Cbo":"543", "codigo":"233110", "titulo":"Professor de desenho tecnico"},
 {"id_Cbo":"544", "codigo":"233115", "titulo":"Professor de tecnicas agricolas"},
 {"id_Cbo":"545", "codigo":"233120", "titulo":"Professor de tecnicas comerciais e secretariais"},
 {"id_Cbo":"546", "codigo":"233125", "titulo":"Professor de tecnicas de enfermagem"},
 {"id_Cbo":"547", "codigo":"233130", "titulo":"Professor de tecnicas industriais"},
 {"id_Cbo":"548", "codigo":"233135", "titulo":"Professor de tecnologia e calculo tecnico"},
 {"id_Cbo":"549", "codigo":"233205", "titulo":"Instrutor de aprendizagem e treinamento agropecuario"},
 {"id_Cbo":"550", "codigo":"233210", "titulo":"Instrutor de aprendizagem e treinamento industrial"},
 {"id_Cbo":"551", "codigo":"233215", "titulo":"Instrutor de aprendizagem e treinamento comercial"},
 {"id_Cbo":"552", "codigo":"233220", "titulo":"Professor instrutor de ensino e aprendizagem agroflorestal"},
 {"id_Cbo":"553", "codigo":"233225", "titulo":"Professor instrutor de ensino e aprendizagem em servicos"},
 {"id_Cbo":"554", "codigo":"234105", "titulo":"Professor de matematica aplicada (no ensino superior)"},
 {"id_Cbo":"555", "codigo":"234110", "titulo":"Professor de matematica pura (no ensino superior)"},
 {"id_Cbo":"556", "codigo":"234115", "titulo":"Professor de estatistica (no ensino superior)"},
 {"id_Cbo":"557", "codigo":"234120", "titulo":"Professor de computacao (no ensino superior)"},
 {"id_Cbo":"558", "codigo":"234125", "titulo":"Professor de pesquisa operacional (no ensino superior)"},
 {"id_Cbo":"559", "codigo":"234205", "titulo":"Professor de fisica (ensino superior)"},
 {"id_Cbo":"560", "codigo":"234210", "titulo":"Professor de quimica (ensino superior)"},
 {"id_Cbo":"561", "codigo":"234215", "titulo":"Professor de astronomia (ensino superior)"},
 {"id_Cbo":"562", "codigo":"234305", "titulo":"Professor de arquitetura"},
 {"id_Cbo":"563", "codigo":"234310", "titulo":"Professor de engenharia"},
 {"id_Cbo":"564", "codigo":"234315", "titulo":"Professor de geofisica"},
 {"id_Cbo":"565", "codigo":"234320", "titulo":"Professor de geologia"},
 {"id_Cbo":"566", "codigo":"234405", "titulo":"Professor de ciencias biologicas do ensino superior"},
 {"id_Cbo":"567", "codigo":"234410", "titulo":"Professor de educacao fisica no ensino superior"},
 {"id_Cbo":"568", "codigo":"234415", "titulo":"Professor de enfermagem do ensino superior"},
 {"id_Cbo":"569", "codigo":"234420", "titulo":"Professor de farmacia e bioquimica"},
 {"id_Cbo":"570", "codigo":"234425", "titulo":"Professor de fisioterapia"},
 {"id_Cbo":"571", "codigo":"234430", "titulo":"Professor de fonoaudiologia"},
 {"id_Cbo":"572", "codigo":"234435", "titulo":"Professor de medicina"},
 {"id_Cbo":"573", "codigo":"234440", "titulo":"Professor de medicina veterinaria"},
 {"id_Cbo":"574", "codigo":"234445", "titulo":"Professor de nutricao"},
 {"id_Cbo":"575", "codigo":"234450", "titulo":"Professor de odontologia"},
 {"id_Cbo":"576", "codigo":"234455", "titulo":"Professor de terapia ocupacional"},
 {"id_Cbo":"577", "codigo":"234460", "titulo":"Professor de zootecnia do ensino superior"},
 {"id_Cbo":"578", "codigo":"234505", "titulo":"Professor de ensino superior na area de didatica"},
 {"id_Cbo":"579", "codigo":"234510", "titulo":"Professor de ensino superior na area de orientacao educacional"},
 {"id_Cbo":"580", "codigo":"234515", "titulo":"Professor de ensino superior na area de pesquisa educacional"},
 {"id_Cbo":"581", "codigo":"234520", "titulo":"Professor de ensino superior na area de pratica de ensino"},
 {"id_Cbo":"582", "codigo":"234604", "titulo":"Professor de lingua alema"},
 {"id_Cbo":"583", "codigo":"234608", "titulo":"Professor de lingua italiana"},
 {"id_Cbo":"584", "codigo":"234612", "titulo":"Professor de lingua francesa"},
 {"id_Cbo":"585", "codigo":"234616", "titulo":"Professor de lingua inglesa"},
 {"id_Cbo":"586", "codigo":"234620", "titulo":"Professor de lingua espanhola"},
 {"id_Cbo":"587", "codigo":"234624", "titulo":"Professor de lingua portuguesa"},
 {"id_Cbo":"588", "codigo":"234628", "titulo":"Professor de literatura brasileira"},
 {"id_Cbo":"589", "codigo":"234632", "titulo":"Professor de literatura portuguesa"},
 {"id_Cbo":"590", "codigo":"234636", "titulo":"Professor de literatura alema"},
 {"id_Cbo":"591", "codigo":"234640", "titulo":"Professor de literatura comparada"},
 {"id_Cbo":"592", "codigo":"234644", "titulo":"Professor de literatura espanhola"},
 {"id_Cbo":"593", "codigo":"234648", "titulo":"Professor de literatura francesa"},
 {"id_Cbo":"594", "codigo":"234652", "titulo":"Professor de literatura inglesa"},
 {"id_Cbo":"595", "codigo":"234656", "titulo":"Professor de literatura italiana"},
 {"id_Cbo":"596", "codigo":"234660", "titulo":"Professor de literatura de linguas estrangeiras modernas"},
 {"id_Cbo":"597", "codigo":"234664", "titulo":"Professor de outras linguas e literaturas"},
 {"id_Cbo":"598", "codigo":"234668", "titulo":"Professor de linguas estrangeiras modernas"},
 {"id_Cbo":"599", "codigo":"234672", "titulo":"Professor de linguistica e linguistica aplicada"},
 {"id_Cbo":"600", "codigo":"234676", "titulo":"Professor de filologia e critica textual"},
 {"id_Cbo":"601", "codigo":"234680", "titulo":"Professor de semiotica"},
 {"id_Cbo":"602", "codigo":"234684", "titulo":"Professor de teoria da literatura"},
 {"id_Cbo":"603", "codigo":"234705", "titulo":"Professor de antropologia do ensino superior"},
 {"id_Cbo":"604", "codigo":"234710", "titulo":"Professor de arquivologia do ensino superior"},
 {"id_Cbo":"605", "codigo":"234715", "titulo":"Professor de biblioteconomia do ensino superior"},
 {"id_Cbo":"606", "codigo":"234720", "titulo":"Professor de ciencia politica do ensino superior"},
 {"id_Cbo":"607", "codigo":"234725", "titulo":"Professor de comunicacao social do ensino superior"},
 {"id_Cbo":"608", "codigo":"234730", "titulo":"Professor de direito do ensino superior"},
 {"id_Cbo":"609", "codigo":"234735", "titulo":"Professor de filosofia do ensino superior"},
 {"id_Cbo":"610", "codigo":"234740", "titulo":"Professor de geografia do ensino superior"},
 {"id_Cbo":"611", "codigo":"234745", "titulo":"Professor de historia do ensino superior"},
 {"id_Cbo":"612", "codigo":"234750", "titulo":"Professor de jornalismo"},
 {"id_Cbo":"613", "codigo":"234755", "titulo":"Professor de museologia do ensino superior"},
 {"id_Cbo":"614", "codigo":"234760", "titulo":"Professor de psicologia do ensino superior"},
 {"id_Cbo":"615", "codigo":"234765", "titulo":"Professor de servico social do ensino superior"},
 {"id_Cbo":"616", "codigo":"234770", "titulo":"Professor de sociologia do ensino superior"},
 {"id_Cbo":"617", "codigo":"234805", "titulo":"Professor de economia"},
 {"id_Cbo":"618", "codigo":"234810", "titulo":"Professor de administracao"},
 {"id_Cbo":"619", "codigo":"234815", "titulo":"Professor de contabilidade"},
 {"id_Cbo":"620", "codigo":"234905", "titulo":"Professor de artes do espetaculo no ensino superior"},
 {"id_Cbo":"621", "codigo":"234910", "titulo":"Professor de artes visuais no ensino superior (artes plasticas e multimidia)"},
 {"id_Cbo":"622", "codigo":"234915", "titulo":"Professor de musica no ensino superior"},
 {"id_Cbo":"623", "codigo":"239205", "titulo":"Professor de alunos com deficiencia auditiva e surdos"},
 {"id_Cbo":"624", "codigo":"239210", "titulo":"Professor de alunos com deficiencia fisica"},
 {"id_Cbo":"625", "codigo":"239215", "titulo":"Professor de alunos com deficiencia mental"},
 {"id_Cbo":"626", "codigo":"239220", "titulo":"Professor de alunos com deficiencia multipla"},
 {"id_Cbo":"627", "codigo":"239225", "titulo":"Professor de alunos com deficiencia visual"},
 {"id_Cbo":"628", "codigo":"239405", "titulo":"Coordenador pedagogico"},
 {"id_Cbo":"629", "codigo":"239410", "titulo":"Orientador educacional"},
 {"id_Cbo":"630", "codigo":"239415", "titulo":"Pedagogo"},
 {"id_Cbo":"631", "codigo":"239420", "titulo":"Professor de tecnicas e recursos audiovisuais"},
 {"id_Cbo":"632", "codigo":"239425", "titulo":"Psicopedagogo"},
 {"id_Cbo":"633", "codigo":"239430", "titulo":"Supervisor de ensino"},
 {"id_Cbo":"634", "codigo":"239435", "titulo":"Designer educacional"},
 {"id_Cbo":"635", "codigo":"239440", "titulo":"Neuropsicopedagogo clinico"},
 {"id_Cbo":"636", "codigo":"239445", "titulo":"Neuropsicopedagogo institucional"},
 {"id_Cbo":"637", "codigo":"241005", "titulo":"Advogado"},
 {"id_Cbo":"638", "codigo":"241010", "titulo":"Advogado de empresa"},
 {"id_Cbo":"639", "codigo":"241015", "titulo":"Advogado (direito civil)"},
 {"id_Cbo":"640", "codigo":"241020", "titulo":"Advogado (direito publico)"},
 {"id_Cbo":"641", "codigo":"241025", "titulo":"Advogado (direito penal)"},
 {"id_Cbo":"642", "codigo":"241030", "titulo":"Advogado (areas especiais)"},
 {"id_Cbo":"643", "codigo":"241035", "titulo":"Advogado (direito do trabalho)"},
 {"id_Cbo":"644", "codigo":"241040", "titulo":"Consultor juridico"},
 {"id_Cbo":"645", "codigo":"241205", "titulo":"Advogado da uniao"},
 {"id_Cbo":"646", "codigo":"241210", "titulo":"Procurador autarquico"},
 {"id_Cbo":"647", "codigo":"241215", "titulo":"Procurador da fazenda nacional"},
 {"id_Cbo":"648", "codigo":"241220", "titulo":"Procurador do estado"},
 {"id_Cbo":"649", "codigo":"241225", "titulo":"Procurador do municipio"},
 {"id_Cbo":"650", "codigo":"241230", "titulo":"Procurador federal"},
 {"id_Cbo":"651", "codigo":"241235", "titulo":"Procurador fundacional"},
 {"id_Cbo":"652", "codigo":"241305", "titulo":"Oficial de registro de contratos maritimos"},
 {"id_Cbo":"653", "codigo":"241310", "titulo":"Oficial do registro civil de pessoas juridicas"},
 {"id_Cbo":"654", "codigo":"241315", "titulo":"Oficial do registro civil de pessoas naturais"},
 {"id_Cbo":"655", "codigo":"241320", "titulo":"Oficial do registro de distribuicoes"},
 {"id_Cbo":"656", "codigo":"241325", "titulo":"Oficial do registro de imoveis"},
 {"id_Cbo":"657", "codigo":"241330", "titulo":"Oficial do registro de titulos e documentos"},
 {"id_Cbo":"658", "codigo":"241335", "titulo":"Tabeliao de notas"},
 {"id_Cbo":"659", "codigo":"241340", "titulo":"Tabeliao de protestos"},
 {"id_Cbo":"660", "codigo":"242205", "titulo":"Procurador da republica"},
 {"id_Cbo":"661", "codigo":"242210", "titulo":"Procurador de justica"},
 {"id_Cbo":"662", "codigo":"242215", "titulo":"Procurador de justica militar"},
 {"id_Cbo":"663", "codigo":"242220", "titulo":"Procurador do trabalho"},
 {"id_Cbo":"664", "codigo":"242225", "titulo":"Procurador regional da republica"},
 {"id_Cbo":"665", "codigo":"242230", "titulo":"Procurador regional do trabalho"},
 {"id_Cbo":"666", "codigo":"242235", "titulo":"Promotor de justica"},
 {"id_Cbo":"667", "codigo":"242240", "titulo":"Subprocurador de justica militar"},
 {"id_Cbo":"668", "codigo":"242245", "titulo":"Subprocurador-geral da republica"},
 {"id_Cbo":"669", "codigo":"242250", "titulo":"Subprocurador-geral do trabalho"},
 {"id_Cbo":"670", "codigo":"242305", "titulo":"Delegado de policia"},
 {"id_Cbo":"671", "codigo":"242405", "titulo":"Defensor publico"},
 {"id_Cbo":"672", "codigo":"242410", "titulo":"Procurador da assistencia judiciaria"},
 {"id_Cbo":"673", "codigo":"242905", "titulo":"Oficial de inteligencia"},
 {"id_Cbo":"674", "codigo":"242910", "titulo":"Oficial tecnico de inteligencia"},
 {"id_Cbo":"675", "codigo":"251105", "titulo":"Antropologo"},
 {"id_Cbo":"676", "codigo":"251110", "titulo":"Arqueologo"},
 {"id_Cbo":"677", "codigo":"251115", "titulo":"Cientista politico"},
 {"id_Cbo":"678", "codigo":"251120", "titulo":"Sociologo"},
 {"id_Cbo":"679", "codigo":"251205", "titulo":"Economista"},
 {"id_Cbo":"680", "codigo":"251210", "titulo":"Economista agroindustrial"},
 {"id_Cbo":"681", "codigo":"251215", "titulo":"Economista financeiro"},
 {"id_Cbo":"682", "codigo":"251220", "titulo":"Economista industrial"},
 {"id_Cbo":"683", "codigo":"251225", "titulo":"Economista do setor publico"},
 {"id_Cbo":"684", "codigo":"251230", "titulo":"Economista ambiental"},
 {"id_Cbo":"685", "codigo":"251235", "titulo":"Economista regional e urbano"},
 {"id_Cbo":"686", "codigo":"251305", "titulo":"Geografo"},
 {"id_Cbo":"687", "codigo":"251405", "titulo":"Filosofo"},
 {"id_Cbo":"688", "codigo":"251505", "titulo":"Psicologo educacional"},
 {"id_Cbo":"689", "codigo":"251510", "titulo":"Psicologo clinico"},
 {"id_Cbo":"690", "codigo":"251515", "titulo":"Psicologo do esporte"},
 {"id_Cbo":"691", "codigo":"251520", "titulo":"Psicologo hospitalar"},
 {"id_Cbo":"692", "codigo":"251525", "titulo":"Psicologo juridico"},
 {"id_Cbo":"693", "codigo":"251530", "titulo":"Psicologo social"},
 {"id_Cbo":"694", "codigo":"251535", "titulo":"Psicologo do transito"},
 {"id_Cbo":"695", "codigo":"251540", "titulo":"Psicologo do trabalho"},
 {"id_Cbo":"696", "codigo":"251545", "titulo":"Neuropsicologo"},
 {"id_Cbo":"697", "codigo":"251550", "titulo":"Psicanalista"},
 {"id_Cbo":"698", "codigo":"251555", "titulo":"Psicologo acupunturista"},
 {"id_Cbo":"699", "codigo":"251605", "titulo":"Assistente social"},
 {"id_Cbo":"700", "codigo":"251610", "titulo":"Economista domestico"},
 {"id_Cbo":"701", "codigo":"252105", "titulo":"Administrador"},
 {"id_Cbo":"702", "codigo":"252205", "titulo":"Auditor (contadores e afins)"},
 {"id_Cbo":"703", "codigo":"252210", "titulo":"Contador"},
 {"id_Cbo":"704", "codigo":"252215", "titulo":"Perito contabil"},
 {"id_Cbo":"705", "codigo":"252305", "titulo":"Secretaria(o) executiva(o)"},
 {"id_Cbo":"706", "codigo":"252310", "titulo":"Secretario  bilingue"},
 {"id_Cbo":"707", "codigo":"252315", "titulo":"Secretaria trilingue"},
 {"id_Cbo":"708", "codigo":"252320", "titulo":"Tecnologo em secretariado escolar"},
 {"id_Cbo":"709", "codigo":"252405", "titulo":"Analista de recursos humanos"},
 {"id_Cbo":"710", "codigo":"252505", "titulo":"Administrador de fundos e carteiras de investimento"},
 {"id_Cbo":"711", "codigo":"252510", "titulo":"Analista de cambio"},
 {"id_Cbo":"712", "codigo":"252515", "titulo":"Analista de cobranca (instituicoes financeiras)"},
 {"id_Cbo":"713", "codigo":"252525", "titulo":"Analista de credito (instituicoes financeiras)"},
 {"id_Cbo":"714", "codigo":"252530", "titulo":"Analista de credito rural"},
 {"id_Cbo":"715", "codigo":"252535", "titulo":"Analista de leasing"},
 {"id_Cbo":"716", "codigo":"252540", "titulo":"Analista de produtos bancarios"},
 {"id_Cbo":"717", "codigo":"252545", "titulo":"Analista financeiro (instituicoes financeiras)"},
 {"id_Cbo":"718", "codigo":"252550", "titulo":"Profissional de relacoes com investidores"},
 {"id_Cbo":"719", "codigo":"252605", "titulo":"Gestor em seguranca"},
 {"id_Cbo":"720", "codigo":"252705", "titulo":"Analista de pcp (programacao e controle da producao)"},
 {"id_Cbo":"721", "codigo":"252710", "titulo":"Analista de planejamento de materias"},
 {"id_Cbo":"722", "codigo":"252715", "titulo":"Analista de logistica"},
 {"id_Cbo":"723", "codigo":"252720", "titulo":"Analista de projetos logisticos"},
 {"id_Cbo":"724", "codigo":"252725", "titulo":"Analista de gestao de estoque"},
 {"id_Cbo":"725", "codigo":"253110", "titulo":"Redator de publicidade"},
 {"id_Cbo":"726", "codigo":"253115", "titulo":"Publicitario"},
 {"id_Cbo":"727", "codigo":"253120", "titulo":"Diretor de midia (publicidade)"},
 {"id_Cbo":"728", "codigo":"253125", "titulo":"Diretor de arte (publicidade)"},
 {"id_Cbo":"729", "codigo":"253130", "titulo":"Diretor de criacao"},
 {"id_Cbo":"730", "codigo":"253135", "titulo":"Diretor de contas (publicidade)"},
 {"id_Cbo":"731", "codigo":"253140", "titulo":"Agenciador de propaganda"},
 {"id_Cbo":"732", "codigo":"253205", "titulo":"Gerente de captacao (fundos e investimentos institucionais)"},
 {"id_Cbo":"733", "codigo":"253210", "titulo":"Gerente de clientes especiais (private)"},
 {"id_Cbo":"734", "codigo":"253215", "titulo":"Gerente de contas - pessoa fisica e juridica"},
 {"id_Cbo":"735", "codigo":"253220", "titulo":"Gerente de grandes contas (corporate)"},
 {"id_Cbo":"736", "codigo":"253225", "titulo":"Operador de negocios"},
 {"id_Cbo":"737", "codigo":"253305", "titulo":"Corretor de valores, ativos financeiros, mercadorias e derivativos"},
 {"id_Cbo":"738", "codigo":"254105", "titulo":"Auditor-fiscal da receita federal"},
 {"id_Cbo":"739", "codigo":"254110", "titulo":"Tecnico da receita federal"},
 {"id_Cbo":"740", "codigo":"254205", "titulo":"Auditor-fiscal da previdencia social"},
 {"id_Cbo":"741", "codigo":"254305", "titulo":"Auditor-fiscal do trabalho"},
 {"id_Cbo":"742", "codigo":"254310", "titulo":"Agente de higiene e seguranca"},
 {"id_Cbo":"743", "codigo":"254405", "titulo":"Fiscal de tributos estadual"},
 {"id_Cbo":"744", "codigo":"254410", "titulo":"Fiscal de tributos municipal"},
 {"id_Cbo":"745", "codigo":"254415", "titulo":"Tecnico de tributos estadual"},
 {"id_Cbo":"746", "codigo":"254420", "titulo":"Tecnico de tributos municipal"},
 {"id_Cbo":"747", "codigo":"254505", "titulo":"Fiscal de atividades urbanas"},
 {"id_Cbo":"748", "codigo":"261105", "titulo":"Arquivista pesquisador (jornalismo)"},
 {"id_Cbo":"749", "codigo":"261110", "titulo":"Assessor de imprensa"},
 {"id_Cbo":"750", "codigo":"261115", "titulo":"Diretor de redacao"},
 {"id_Cbo":"751", "codigo":"261120", "titulo":"Editor"},
 {"id_Cbo":"752", "codigo":"261125", "titulo":"Jornalista"},
 {"id_Cbo":"753", "codigo":"261130", "titulo":"Produtor de texto"},
 {"id_Cbo":"754", "codigo":"261135", "titulo":"Reporter (exclusive radio e televisao)"},
 {"id_Cbo":"755", "codigo":"261140", "titulo":"Revisor de texto"},
 {"id_Cbo":"756", "codigo":"261205", "titulo":"Bibliotecario"},
 {"id_Cbo":"757", "codigo":"261210", "titulo":"Documentalista"},
 {"id_Cbo":"758", "codigo":"261215", "titulo":"Analista de informacoes (pesquisador de informacoes de rede)"},
 {"id_Cbo":"759", "codigo":"261305", "titulo":"Arquivista"},
 {"id_Cbo":"760", "codigo":"261310", "titulo":"Museologo"},
 {"id_Cbo":"761", "codigo":"261405", "titulo":"Filologo"},
 {"id_Cbo":"762", "codigo":"261410", "titulo":"Interprete"},
 {"id_Cbo":"763", "codigo":"261415", "titulo":"Linguista"},
 {"id_Cbo":"764", "codigo":"261420", "titulo":"Tradutor"},
 {"id_Cbo":"765", "codigo":"261425", "titulo":"Interprete de lingua de sinais"},
 {"id_Cbo":"766", "codigo":"261430", "titulo":"Audiodescritor"},
 {"id_Cbo":"767", "codigo":"261505", "titulo":"Autor-roteirista"},
 {"id_Cbo":"768", "codigo":"261510", "titulo":"Critico"},
 {"id_Cbo":"769", "codigo":"261515", "titulo":"Escritor de ficcao"},
 {"id_Cbo":"770", "codigo":"261520", "titulo":"Escritor de nao ficcao"},
 {"id_Cbo":"771", "codigo":"261525", "titulo":"Poeta"},
 {"id_Cbo":"772", "codigo":"261530", "titulo":"Redator de textos tecnicos"},
 {"id_Cbo":"773", "codigo":"261605", "titulo":"Editor de jornal"},
 {"id_Cbo":"774", "codigo":"261610", "titulo":"Editor de livro"},
 {"id_Cbo":"775", "codigo":"261615", "titulo":"Editor de midia eletronica"},
 {"id_Cbo":"776", "codigo":"261620", "titulo":"Editor de revista"},
 {"id_Cbo":"777", "codigo":"261625", "titulo":"Editor de revista cientifica"},
 {"id_Cbo":"778", "codigo":"261705", "titulo":"Ancora de midias audiovisuais"},
 {"id_Cbo":"779", "codigo":"261710", "titulo":"Comentarista de midias audiovisuais"},
 {"id_Cbo":"780", "codigo":"261715", "titulo":"Locutor de midias audiovisuais"},
 {"id_Cbo":"781", "codigo":"261730", "titulo":"Reporter de midias audiovisuais"},
 {"id_Cbo":"782", "codigo":"261805", "titulo":"Fotografo"},
 {"id_Cbo":"783", "codigo":"261810", "titulo":"Fotografo publicitario"},
 {"id_Cbo":"784", "codigo":"261815", "titulo":"Fotografo retratista"},
 {"id_Cbo":"785", "codigo":"261820", "titulo":"Reporter fotografico"},
 {"id_Cbo":"786", "codigo":"261905", "titulo":"Continuista"},
 {"id_Cbo":"787", "codigo":"261910", "titulo":"Assistente de direcao (tv)"},
 {"id_Cbo":"788", "codigo":"262105", "titulo":"Produtor cultural"},
 {"id_Cbo":"789", "codigo":"262110", "titulo":"Produtor cinematografico"},
 {"id_Cbo":"790", "codigo":"262115", "titulo":"Produtor de radio"},
 {"id_Cbo":"791", "codigo":"262120", "titulo":"Produtor de teatro"},
 {"id_Cbo":"792", "codigo":"262125", "titulo":"Produtor de televisao"},
 {"id_Cbo":"793", "codigo":"262130", "titulo":"Tecnologo em producao fonografica"},
 {"id_Cbo":"794", "codigo":"262135", "titulo":"Tecnologo em producao audiovisual"},
 {"id_Cbo":"795", "codigo":"262205", "titulo":"Diretor de cinema"},
 {"id_Cbo":"796", "codigo":"262210", "titulo":"Diretor de programas de radio"},
 {"id_Cbo":"797", "codigo":"262215", "titulo":"Diretor de programas de televisao"},
 {"id_Cbo":"798", "codigo":"262220", "titulo":"Diretor teatral"},
 {"id_Cbo":"799", "codigo":"262225", "titulo":"Diretor de programacao"},
 {"id_Cbo":"800", "codigo":"262230", "titulo":"Diretor de producao"},
 {"id_Cbo":"801", "codigo":"262235", "titulo":"Diretor artistico"},
 {"id_Cbo":"802", "codigo":"262305", "titulo":"Cenografo carnavalesco e festas populares"},
 {"id_Cbo":"803", "codigo":"262310", "titulo":"Cenografo de cinema"},
 {"id_Cbo":"804", "codigo":"262315", "titulo":"Cenografo de eventos"},
 {"id_Cbo":"805", "codigo":"262320", "titulo":"Cenografo de teatro"},
 {"id_Cbo":"806", "codigo":"262325", "titulo":"Cenografo de tv"},
 {"id_Cbo":"807", "codigo":"262330", "titulo":"Diretor de arte"},
 {"id_Cbo":"808", "codigo":"262405", "titulo":"Artista (artes visuais)"},
 {"id_Cbo":"809", "codigo":"262410", "titulo":"Desenhista industrial grafico (designer grafico)"},
 {"id_Cbo":"810", "codigo":"262415", "titulo":"Conservador-restaurador de bens  culturais"},
 {"id_Cbo":"811", "codigo":"262420", "titulo":"Desenhista industrial de produto (designer de produto)"},
 {"id_Cbo":"812", "codigo":"262425", "titulo":"Desenhista industrial de produto de moda (designer de moda)"},
 {"id_Cbo":"813", "codigo":"262505", "titulo":"Ator"},
 {"id_Cbo":"814", "codigo":"262605", "titulo":"Compositor"},
 {"id_Cbo":"815", "codigo":"262610", "titulo":"Musico arranjador"},
 {"id_Cbo":"816", "codigo":"262615", "titulo":"Musico regente"},
 {"id_Cbo":"817", "codigo":"262620", "titulo":"Musicologo"},
 {"id_Cbo":"818", "codigo":"262705", "titulo":"Musico interprete cantor"},
 {"id_Cbo":"819", "codigo":"262710", "titulo":"Musico interprete instrumentista"},
 {"id_Cbo":"820", "codigo":"262805", "titulo":"Assistente de coreografia"},
 {"id_Cbo":"821", "codigo":"262810", "titulo":"Bailarino (exceto dancas populares)"},
 {"id_Cbo":"822", "codigo":"262815", "titulo":"Coreografo"},
 {"id_Cbo":"823", "codigo":"262820", "titulo":"Dramaturgo de danca"},
 {"id_Cbo":"824", "codigo":"262825", "titulo":"Ensaiador de danca"},
 {"id_Cbo":"825", "codigo":"262830", "titulo":"Professor de danca"},
 {"id_Cbo":"826", "codigo":"262905", "titulo":"Decorador de interiores de nivel superior"},
 {"id_Cbo":"827", "codigo":"263105", "titulo":"Ministro de culto religioso"},
 {"id_Cbo":"828", "codigo":"263110", "titulo":"Missionario"},
 {"id_Cbo":"829", "codigo":"263115", "titulo":"Teologo"},
 {"id_Cbo":"830", "codigo":"271105", "titulo":"Chefe de cozinha"},
 {"id_Cbo":"831", "codigo":"271110", "titulo":"Tecnologo em gastronomia"},
 {"id_Cbo":"832", "codigo":"300105", "titulo":"Tecnico em mecatronica - automacao da manufatura"},
 {"id_Cbo":"833", "codigo":"300110", "titulo":"Tecnico em mecatronica - robotica"},
 {"id_Cbo":"834", "codigo":"300305", "titulo":"Tecnico em eletromecanica"},
 {"id_Cbo":"835", "codigo":"301105", "titulo":"Tecnico de laboratorio industrial"},
 {"id_Cbo":"836", "codigo":"301110", "titulo":"Tecnico de laboratorio de analises fisico-quimicas (materiais de construcao)"},
 {"id_Cbo":"837", "codigo":"301115", "titulo":"Tecnico quimico de petroleo"},
 {"id_Cbo":"838", "codigo":"301205", "titulo":"Tecnico de apoio a bioengenharia"},
 {"id_Cbo":"839", "codigo":"311105", "titulo":"Tecnico quimico"},
 {"id_Cbo":"840", "codigo":"311110", "titulo":"Tecnico de celulose e papel"},
 {"id_Cbo":"841", "codigo":"311115", "titulo":"Tecnico em curtimento"},
 {"id_Cbo":"842", "codigo":"311205", "titulo":"Tecnico em petroquimica"},
 {"id_Cbo":"843", "codigo":"311305", "titulo":"Tecnico em materiais, produtos ceramicos e vidros"},
 {"id_Cbo":"844", "codigo":"311405", "titulo":"Tecnico em borracha"},
 {"id_Cbo":"845", "codigo":"311410", "titulo":"Tecnico em plastico"},
 {"id_Cbo":"846", "codigo":"311505", "titulo":"Tecnico de controle de meio ambiente"},
 {"id_Cbo":"847", "codigo":"311510", "titulo":"Tecnico de meteorologia"},
 {"id_Cbo":"848", "codigo":"311515", "titulo":"Tecnico de utilidade (producao e distribuicao de vapor, gases, oleos, combustiveis, energia)"},
 {"id_Cbo":"849", "codigo":"311520", "titulo":"Tecnico em tratamento de efluentes"},
 {"id_Cbo":"850", "codigo":"311605", "titulo":"Tecnico textil"},
 {"id_Cbo":"851", "codigo":"311610", "titulo":"Tecnico textil (tratamentos quimicos)"},
 {"id_Cbo":"852", "codigo":"311615", "titulo":"Tecnico textil de fiacao"},
 {"id_Cbo":"853", "codigo":"311620", "titulo":"Tecnico textil de malharia"},
 {"id_Cbo":"854", "codigo":"311625", "titulo":"Tecnico textil de tecelagem"},
 {"id_Cbo":"855", "codigo":"311705", "titulo":"Colorista de papel"},
 {"id_Cbo":"856", "codigo":"311710", "titulo":"Colorista textil"},
 {"id_Cbo":"857", "codigo":"311715", "titulo":"Preparador de tintas"},
 {"id_Cbo":"858", "codigo":"311720", "titulo":"Preparador de tintas (fabrica de tecidos)"},
 {"id_Cbo":"859", "codigo":"311725", "titulo":"Tingidor de couros e peles"},
 {"id_Cbo":"860", "codigo":"312105", "titulo":"Tecnico de obras civis"},
 {"id_Cbo":"861", "codigo":"312205", "titulo":"Tecnico de estradas"},
 {"id_Cbo":"862", "codigo":"312210", "titulo":"Tecnico de saneamento"},
 {"id_Cbo":"863", "codigo":"312305", "titulo":"Tecnico em agrimensura"},
 {"id_Cbo":"864", "codigo":"312310", "titulo":"Tecnico em geodesia e cartografia"},
 {"id_Cbo":"865", "codigo":"312315", "titulo":"Tecnico em hidrografia"},
 {"id_Cbo":"866", "codigo":"312320", "titulo":"Topografo"},
 {"id_Cbo":"867", "codigo":"313105", "titulo":"Eletrotecnico"},
 {"id_Cbo":"868", "codigo":"313110", "titulo":"Eletrotecnico (producao de energia)"},
 {"id_Cbo":"869", "codigo":"313115", "titulo":"Eletrotecnico na fabricacao, montagem e instalacao de maquinas e equipamentos"},
 {"id_Cbo":"870", "codigo":"313120", "titulo":"Tecnico de manutencao eletrica"},
 {"id_Cbo":"871", "codigo":"313125", "titulo":"Tecnico de manutencao eletrica de maquina"},
 {"id_Cbo":"872", "codigo":"313130", "titulo":"Tecnico eletricista"},
 {"id_Cbo":"873", "codigo":"313205", "titulo":"Tecnico de manutencao eletronica"},
 {"id_Cbo":"874", "codigo":"313210", "titulo":"Tecnico de manutencao eletronica (circuitos de maquinas com comando numerico)"},
 {"id_Cbo":"875", "codigo":"313215", "titulo":"Tecnico eletronico"},
 {"id_Cbo":"876", "codigo":"313220", "titulo":"Tecnico em manutencao de equipamentos de informatica"},
 {"id_Cbo":"877", "codigo":"313305", "titulo":"Tecnico de comunicacao de dados"},
 {"id_Cbo":"878", "codigo":"313310", "titulo":"Tecnico de rede (telecomunicacoes)"},
 {"id_Cbo":"879", "codigo":"313315", "titulo":"Tecnico de telecomunicacoes (telefonia)"},
 {"id_Cbo":"880", "codigo":"313320", "titulo":"Tecnico de transmissao (telecomunicacoes)"},
 {"id_Cbo":"881", "codigo":"313405", "titulo":"Tecnico em calibracao"},
 {"id_Cbo":"882", "codigo":"313410", "titulo":"Tecnico em instrumentacao"},
 {"id_Cbo":"883", "codigo":"313415", "titulo":"Encarregado de manutencao de instrumentos de controle, medicao e similares"},
 {"id_Cbo":"884", "codigo":"313505", "titulo":"Tecnico em fotonica"},
 {"id_Cbo":"885", "codigo":"314105", "titulo":"Tecnico em mecanica de precisao"},
 {"id_Cbo":"886", "codigo":"314110", "titulo":"Tecnico mecanico"},
 {"id_Cbo":"887", "codigo":"314115", "titulo":"Tecnico mecanico (calefacao, ventilacao e refrigeracao)"},
 {"id_Cbo":"888", "codigo":"314120", "titulo":"Tecnico mecanico (maquinas)"},
 {"id_Cbo":"889", "codigo":"314125", "titulo":"Tecnico mecanico (motores)"},
 {"id_Cbo":"890", "codigo":"314205", "titulo":"Tecnico mecanico na fabricacao de ferramentas"},
 {"id_Cbo":"891", "codigo":"314210", "titulo":"Tecnico mecanico na manutencao de ferramentas"},
 {"id_Cbo":"892", "codigo":"314305", "titulo":"Tecnico em automobilistica"},
 {"id_Cbo":"893", "codigo":"314310", "titulo":"Tecnico mecanico (aeronaves)"},
 {"id_Cbo":"894", "codigo":"314315", "titulo":"Tecnico mecanico (embarcacoes)"},
 {"id_Cbo":"895", "codigo":"314405", "titulo":"Tecnico de manutencao de sistemas e instrumentos"},
 {"id_Cbo":"896", "codigo":"314410", "titulo":"Tecnico em manutencao de maquinas"},
 {"id_Cbo":"897", "codigo":"314605", "titulo":"Inspetor de soldagem"},
 {"id_Cbo":"898", "codigo":"314610", "titulo":"Tecnico em caldeiraria"},
 {"id_Cbo":"899", "codigo":"314615", "titulo":"Tecnico em estruturas metalicas"},
 {"id_Cbo":"900", "codigo":"314620", "titulo":"Tecnico em soldagem"},
 {"id_Cbo":"901", "codigo":"314625", "titulo":"Tecnologo em soldagem"},
 {"id_Cbo":"902", "codigo":"314705", "titulo":"Tecnico de acabamento em siderurgia"},
 {"id_Cbo":"903", "codigo":"314710", "titulo":"Tecnico de aciaria em siderurgia"},
 {"id_Cbo":"904", "codigo":"314715", "titulo":"Tecnico de fundicao em siderurgia"},
 {"id_Cbo":"905", "codigo":"314720", "titulo":"Tecnico de laminacao em siderurgia"},
 {"id_Cbo":"906", "codigo":"314725", "titulo":"Tecnico de reducao na siderurgia (primeira fusao)"},
 {"id_Cbo":"907", "codigo":"314730", "titulo":"Tecnico de refratario em siderurgia"},
 {"id_Cbo":"908", "codigo":"316105", "titulo":"Tecnico em geofisica"},
 {"id_Cbo":"909", "codigo":"316110", "titulo":"Tecnico em geologia"},
 {"id_Cbo":"910", "codigo":"316115", "titulo":"Tecnico em geoquimica"},
 {"id_Cbo":"911", "codigo":"316120", "titulo":"Tecnico em geotecnia"},
 {"id_Cbo":"912", "codigo":"316305", "titulo":"Tecnico de mineracao"},
 {"id_Cbo":"913", "codigo":"316310", "titulo":"Tecnico de mineracao (oleo e petroleo)"},
 {"id_Cbo":"914", "codigo":"316315", "titulo":"Tecnico em processamento mineral (exceto petroleo)"},
 {"id_Cbo":"915", "codigo":"316320", "titulo":"Tecnico em pesquisa mineral"},
 {"id_Cbo":"916", "codigo":"316325", "titulo":"Tecnico de producao em refino de petroleo"},
 {"id_Cbo":"917", "codigo":"316330", "titulo":"Tecnico em planejamento de lavra de minas"},
 {"id_Cbo":"918", "codigo":"316335", "titulo":"Desincrustador (pocos de petroleo)"},
 {"id_Cbo":"919", "codigo":"316340", "titulo":"Cimentador (pocos de petroleo)"},
 {"id_Cbo":"920", "codigo":"317105", "titulo":"Desenvolvedor web (tecnico)"},
 {"id_Cbo":"921", "codigo":"317110", "titulo":"Desenvolvedor de sistemas de tecnologia da informacao (tecnico)"},
 {"id_Cbo":"922", "codigo":"317115", "titulo":"Programador de maquinas - ferramenta com comando numerico"},
 {"id_Cbo":"923", "codigo":"317120", "titulo":"Desenvolvedor de multimidia"},
 {"id_Cbo":"924", "codigo":"317205", "titulo":"Operador de computador"},
 {"id_Cbo":"925", "codigo":"317210", "titulo":"Tecnico de suporte ao usuario de tecnologia da informacao"},
 {"id_Cbo":"926", "codigo":"318005", "titulo":"Desenhista tecnico"},
 {"id_Cbo":"927", "codigo":"318010", "titulo":"Desenhista copista"},
 {"id_Cbo":"928", "codigo":"318015", "titulo":"Desenhista detalhista"},
 {"id_Cbo":"929", "codigo":"318105", "titulo":"Desenhista tecnico (arquitetura)"},
 {"id_Cbo":"930", "codigo":"318110", "titulo":"Desenhista tecnico (cartografia)"},
 {"id_Cbo":"931", "codigo":"318115", "titulo":"Desenhista tecnico (construcao civil)"},
 {"id_Cbo":"932", "codigo":"318120", "titulo":"Desenhista tecnico (instalacoes hidrossanitarias)"},
 {"id_Cbo":"933", "codigo":"318205", "titulo":"Desenhista tecnico mecanico"},
 {"id_Cbo":"934", "codigo":"318210", "titulo":"Desenhista tecnico aeronautico"},
 {"id_Cbo":"935", "codigo":"318215", "titulo":"Desenhista tecnico naval"},
 {"id_Cbo":"936", "codigo":"318305", "titulo":"Desenhista tecnico (eletricidade e eletronica)"},
 {"id_Cbo":"937", "codigo":"318310", "titulo":"Desenhista tecnico (calefacao, ventilacao e refrigeracao)"},
 {"id_Cbo":"938", "codigo":"318405", "titulo":"Desenhista tecnico (artes graficas)"},
 {"id_Cbo":"939", "codigo":"318410", "titulo":"Desenhista tecnico (ilustracoes artisticas)"},
 {"id_Cbo":"940", "codigo":"318415", "titulo":"Desenhista tecnico (ilustracoes tecnicas)"},
 {"id_Cbo":"941", "codigo":"318420", "titulo":"Desenhista tecnico (industria textil)"},
 {"id_Cbo":"942", "codigo":"318425", "titulo":"Desenhista tecnico (mobiliario)"},
 {"id_Cbo":"943", "codigo":"318430", "titulo":"Desenhista tecnico de embalagens, maquetes e leiautes"},
 {"id_Cbo":"944", "codigo":"318505", "titulo":"Desenhista projetista de arquitetura"},
 {"id_Cbo":"945", "codigo":"318510", "titulo":"Desenhista projetista de construcao civil"},
 {"id_Cbo":"946", "codigo":"318605", "titulo":"Desenhista projetista de maquinas"},
 {"id_Cbo":"947", "codigo":"318610", "titulo":"Desenhista projetista mecanico"},
 {"id_Cbo":"948", "codigo":"318705", "titulo":"Desenhista projetista de eletricidade"},
 {"id_Cbo":"949", "codigo":"318710", "titulo":"Desenhista projetista eletronico"},
 {"id_Cbo":"950", "codigo":"318805", "titulo":"Projetista de moveis"},
 {"id_Cbo":"951", "codigo":"318810", "titulo":"Modelista de roupas"},
 {"id_Cbo":"952", "codigo":"318815", "titulo":"Modelista de calcados"},
 {"id_Cbo":"953", "codigo":"319105", "titulo":"Tecnico em calcados e artefatos de couro"},
 {"id_Cbo":"954", "codigo":"319110", "titulo":"Tecnico em confeccoes do vestuario"},
 {"id_Cbo":"955", "codigo":"319205", "titulo":"Tecnico do mobiliario"},
 {"id_Cbo":"956", "codigo":"320105", "titulo":"Tecnico em bioterismo"},
 {"id_Cbo":"957", "codigo":"320110", "titulo":"Tecnico em histologia"},
 {"id_Cbo":"958", "codigo":"321105", "titulo":"Tecnico agricola"},
 {"id_Cbo":"959", "codigo":"321110", "titulo":"Tecnico agropecuario"},
 {"id_Cbo":"960", "codigo":"321205", "titulo":"Tecnico em madeira"},
 {"id_Cbo":"961", "codigo":"321210", "titulo":"Tecnico florestal"},
 {"id_Cbo":"962", "codigo":"321305", "titulo":"Tecnico em piscicultura"},
 {"id_Cbo":"963", "codigo":"321310", "titulo":"Tecnico em carcinicultura"},
 {"id_Cbo":"964", "codigo":"321315", "titulo":"Tecnico em mitilicultura"},
 {"id_Cbo":"965", "codigo":"321320", "titulo":"Tecnico em ranicultura"},
 {"id_Cbo":"966", "codigo":"322105", "titulo":"Tecnico em acupuntura"},
 {"id_Cbo":"967", "codigo":"322110", "titulo":"Podologo"},
 {"id_Cbo":"968", "codigo":"322115", "titulo":"Tecnico em quiropraxia"},
 {"id_Cbo":"969", "codigo":"322120", "titulo":"Massoterapeuta"},
 {"id_Cbo":"970", "codigo":"322125", "titulo":"Terapeuta holistico"},
 {"id_Cbo":"971", "codigo":"322130", "titulo":"Esteticista"},
 {"id_Cbo":"972", "codigo":"322135", "titulo":"Doula"},
 {"id_Cbo":"973", "codigo":"322205", "titulo":"Tecnico de enfermagem"},
 {"id_Cbo":"974", "codigo":"322210", "titulo":"Tecnico de enfermagem de terapia intensiva"},
 {"id_Cbo":"975", "codigo":"322215", "titulo":"Tecnico de enfermagem do trabalho"},
 {"id_Cbo":"976", "codigo":"322220", "titulo":"Tecnico de enfermagem psiquiatrica"},
 {"id_Cbo":"977", "codigo":"322225", "titulo":"Instrumentador cirurgico"},
 {"id_Cbo":"978", "codigo":"322230", "titulo":"Auxiliar de enfermagem"},
 {"id_Cbo":"979", "codigo":"322235", "titulo":"Auxiliar de enfermagem do trabalho"},
 {"id_Cbo":"980", "codigo":"322240", "titulo":"Auxiliar de saude (navegacao maritima)"},
 {"id_Cbo":"981", "codigo":"322245", "titulo":"Tecnico de enfermagem da estrategia de saude da familia"},
 {"id_Cbo":"982", "codigo":"322250", "titulo":"Auxiliar de enfermagem da estrategia de saude da familia"},
 {"id_Cbo":"983", "codigo":"322305", "titulo":"Tecnico em optica e optometria"},
 {"id_Cbo":"984", "codigo":"322405", "titulo":"Tecnico em saude bucal"},
 {"id_Cbo":"985", "codigo":"322410", "titulo":"Protetico dentario"},
 {"id_Cbo":"986", "codigo":"322415", "titulo":"Auxiliar em saude bucal"},
 {"id_Cbo":"987", "codigo":"322420", "titulo":"Auxiliar de protese dentaria"},
 {"id_Cbo":"988", "codigo":"322425", "titulo":"Tecnico em saude bucal da estrategia de saude da familia"},
 {"id_Cbo":"989", "codigo":"322430", "titulo":"Auxiliar em saude bucal da estrategia de saude da familia"},
 {"id_Cbo":"990", "codigo":"322505", "titulo":"Tecnico de ortopedia"},
 {"id_Cbo":"991", "codigo":"322605", "titulo":"Tecnico de imobilizacao ortopedica"},
 {"id_Cbo":"992", "codigo":"323105", "titulo":"Tecnico em pecuaria"},
 {"id_Cbo":"993", "codigo":"324105", "titulo":"Tecnico em metodos eletrograficos em encefalografia"},
 {"id_Cbo":"994", "codigo":"324110", "titulo":"Tecnico em metodos graficos em cardiologia"},
 {"id_Cbo":"995", "codigo":"324115", "titulo":"Tecnico em radiologia e imagenologia"},
 {"id_Cbo":"996", "codigo":"324120", "titulo":"Tecnologo em radiologia"},
 {"id_Cbo":"997", "codigo":"324125", "titulo":"Tecnologo oftalmico"},
 {"id_Cbo":"998", "codigo":"324130", "titulo":"Tecnico em espirometria"},
 {"id_Cbo":"999", "codigo":"324135", "titulo":"Tecnico em polissonografia"},
 {"id_Cbo":"1000", "codigo":"324205", "titulo":"Tecnico em patologia clinica"},
 {"id_Cbo":"1001", "codigo":"324215", "titulo":"Citotecnico"},
 {"id_Cbo":"1002", "codigo":"324220", "titulo":"Tecnico em hemoterapia"},
 {"id_Cbo":"1003", "codigo":"325005", "titulo":"Enologo"},
 {"id_Cbo":"1004", "codigo":"325010", "titulo":"Aromista"},
 {"id_Cbo":"1005", "codigo":"325015", "titulo":"Perfumista"},
 {"id_Cbo":"1006", "codigo":"325105", "titulo":"Auxiliar tecnico em laboratorio de farmacia"},
 {"id_Cbo":"1007", "codigo":"325110", "titulo":"Tecnico em laboratorio de farmacia"},
 {"id_Cbo":"1008", "codigo":"325115", "titulo":"Tecnico em farmacia"},
 {"id_Cbo":"1009", "codigo":"325205", "titulo":"Tecnico de alimentos"},
 {"id_Cbo":"1010", "codigo":"325210", "titulo":"Tecnico em nutricao e dietetica"},
 {"id_Cbo":"1011", "codigo":"325305", "titulo":"Tecnico em biotecnologia"},
 {"id_Cbo":"1012", "codigo":"325310", "titulo":"Tecnico em imunobiologicos"},
 {"id_Cbo":"1013", "codigo":"328105", "titulo":"Embalsamador"},
 {"id_Cbo":"1014", "codigo":"328110", "titulo":"Taxidermista"},
 {"id_Cbo":"1015", "codigo":"331105", "titulo":"Professor de nivel medio na educacao infantil"},
 {"id_Cbo":"1016", "codigo":"331110", "titulo":"Auxiliar de desenvolvimento infantil"},
 {"id_Cbo":"1017", "codigo":"331205", "titulo":"Professor de nivel medio no ensino fundamental"},
 {"id_Cbo":"1018", "codigo":"331305", "titulo":"Professor de nivel medio no ensino profissionalizante"},
 {"id_Cbo":"1019", "codigo":"332105", "titulo":"Professor leigo no ensino fundamental"},
 {"id_Cbo":"1020", "codigo":"332205", "titulo":"Professor pratico no ensino profissionalizante"},
 {"id_Cbo":"1021", "codigo":"333105", "titulo":"Instrutor de auto-escola"},
 {"id_Cbo":"1022", "codigo":"333110", "titulo":"Instrutor de cursos livres"},
 {"id_Cbo":"1023", "codigo":"333115", "titulo":"Professores de cursos livres"},
 {"id_Cbo":"1024", "codigo":"334105", "titulo":"Inspetor de alunos de escola privada"},
 {"id_Cbo":"1025", "codigo":"334110", "titulo":"Inspetor de alunos de escola publica"},
 {"id_Cbo":"1026", "codigo":"334115", "titulo":"Monitor de transporte escolar"},
 {"id_Cbo":"1027", "codigo":"341105", "titulo":"Piloto comercial (exceto linhas aereas)"},
 {"id_Cbo":"1028", "codigo":"341110", "titulo":"Piloto comercial de helicoptero (exceto linhas aereas)"},
 {"id_Cbo":"1029", "codigo":"341115", "titulo":"Mecanico de voo"},
 {"id_Cbo":"1030", "codigo":"341120", "titulo":"Piloto agricola"},
 {"id_Cbo":"1031", "codigo":"341205", "titulo":"Contramestre de cabotagem"},
 {"id_Cbo":"1032", "codigo":"341210", "titulo":"Mestre de cabotagem"},
 {"id_Cbo":"1033", "codigo":"341215", "titulo":"Mestre fluvial"},
 {"id_Cbo":"1034", "codigo":"341220", "titulo":"Patrao de pesca de alto-mar"},
 {"id_Cbo":"1035", "codigo":"341225", "titulo":"Patrao de pesca na navegacao interior"},
 {"id_Cbo":"1036", "codigo":"341230", "titulo":"Piloto fluvial"},
 {"id_Cbo":"1037", "codigo":"341235", "titulo":"Tenico em sinalizacao nautica"},
 {"id_Cbo":"1038", "codigo":"341240", "titulo":"Tecnicos em manobras em equipamentos de conves"},
 {"id_Cbo":"1039", "codigo":"341245", "titulo":"Tecnico em sinais navais"},
 {"id_Cbo":"1040", "codigo":"341250", "titulo":"Auxiliar tecnico de sinalizacao nautica"},
 {"id_Cbo":"1041", "codigo":"341305", "titulo":"Condutor maquinista motorista fluvial"},
 {"id_Cbo":"1042", "codigo":"341310", "titulo":"Condutor de maquinas"},
 {"id_Cbo":"1043", "codigo":"341315", "titulo":"Eletricista de bordo"},
 {"id_Cbo":"1044", "codigo":"341320", "titulo":"Condutor de maquinas (bombeador)"},
 {"id_Cbo":"1045", "codigo":"341325", "titulo":"Condutor de maquinas (mecanico)"},
 {"id_Cbo":"1046", "codigo":"342105", "titulo":"Analista de transporte em comercio exterior"},
 {"id_Cbo":"1047", "codigo":"342110", "titulo":"Operador de transporte multimodal"},
 {"id_Cbo":"1048", "codigo":"342115", "titulo":"Controlador de servicos de maquinas e veiculos"},
 {"id_Cbo":"1049", "codigo":"342120", "titulo":"Afretador"},
 {"id_Cbo":"1050", "codigo":"342125", "titulo":"Tecnologo em logistica de transporte"},
 {"id_Cbo":"1051", "codigo":"342205", "titulo":"Ajudante de despachante aduaneiro"},
 {"id_Cbo":"1052", "codigo":"342210", "titulo":"Despachante aduaneiro"},
 {"id_Cbo":"1053", "codigo":"342215", "titulo":"Analista de desembaraco aduaneiro"},
 {"id_Cbo":"1054", "codigo":"342305", "titulo":"Chefe de servico de transporte rodoviario (passageiros e cargas)"},
 {"id_Cbo":"1055", "codigo":"342310", "titulo":"Inspetor de servicos de transportes rodoviarios (passageiros e cargas)"},
 {"id_Cbo":"1056", "codigo":"342315", "titulo":"Supervisor de carga e descarga"},
 {"id_Cbo":"1057", "codigo":"342405", "titulo":"Agente de estacao (ferrovia e metro)"},
 {"id_Cbo":"1058", "codigo":"342410", "titulo":"Operador de centro de controle (ferrovia e metro)"},
 {"id_Cbo":"1059", "codigo":"342505", "titulo":"Controlador de trafego aereo"},
 {"id_Cbo":"1060", "codigo":"342510", "titulo":"Despachante operacional de voo"},
 {"id_Cbo":"1061", "codigo":"342515", "titulo":"Fiscal de aviacao civil (fac)"},
 {"id_Cbo":"1062", "codigo":"342530", "titulo":"Inspetor de aviacao civil"},
 {"id_Cbo":"1063", "codigo":"342535", "titulo":"Operador de atendimento aeroviario"},
 {"id_Cbo":"1064", "codigo":"342540", "titulo":"Supervisor da administracao de aeroportos"},
 {"id_Cbo":"1065", "codigo":"342545", "titulo":"Supervisor de empresa aerea em aeroportos"},
 {"id_Cbo":"1066", "codigo":"342550", "titulo":"Agente de protecao de aviacao civil"},
 {"id_Cbo":"1067", "codigo":"342555", "titulo":"Fiscal de pista de aeroporto"},
 {"id_Cbo":"1068", "codigo":"342560", "titulo":"Lider de rampa ( transporte aereo)"},
 {"id_Cbo":"1069", "codigo":"342605", "titulo":"Chefe de estacao portuaria"},
 {"id_Cbo":"1070", "codigo":"342610", "titulo":"Supervisor de operacoes portuarias"},
 {"id_Cbo":"1071", "codigo":"351105", "titulo":"Tecnico de contabilidade"},
 {"id_Cbo":"1072", "codigo":"351110", "titulo":"Chefe de contabilidade (tecnico)"},
 {"id_Cbo":"1073", "codigo":"351115", "titulo":"Consultor contabil (tecnico)"},
 {"id_Cbo":"1074", "codigo":"351305", "titulo":"Tecnico em administracao"},
 {"id_Cbo":"1075", "codigo":"351310", "titulo":"Tecnico em administracao de comercio exterior"},
 {"id_Cbo":"1076", "codigo":"351315", "titulo":"Agente de recrutamento e selecao"},
 {"id_Cbo":"1077", "codigo":"351405", "titulo":"Escrevente"},
 {"id_Cbo":"1078", "codigo":"351410", "titulo":"Escrivao judicial"},
 {"id_Cbo":"1079", "codigo":"351415", "titulo":"Escrivao extra - judicial"},
 {"id_Cbo":"1080", "codigo":"351420", "titulo":"Escrivao de policia"},
 {"id_Cbo":"1081", "codigo":"351425", "titulo":"Oficial de justica"},
 {"id_Cbo":"1082", "codigo":"351430", "titulo":"Auxiliar de servicos juridicos"},
 {"id_Cbo":"1083", "codigo":"351505", "titulo":"Tecnico em secretariado"},
 {"id_Cbo":"1084", "codigo":"351510", "titulo":"Taquigrafo"},
 {"id_Cbo":"1085", "codigo":"351515", "titulo":"Estenotipista"},
 {"id_Cbo":"1086", "codigo":"351605", "titulo":"Tecnico em seguranca do trabalho"},
 {"id_Cbo":"1087", "codigo":"351610", "titulo":"Tecnico em higiene ocupacional"},
 {"id_Cbo":"1088", "codigo":"351705", "titulo":"Analista de seguros (tecnico)"},
 {"id_Cbo":"1089", "codigo":"351710", "titulo":"Analista de sinistros"},
 {"id_Cbo":"1090", "codigo":"351715", "titulo":"Assistente comercial de seguros"},
 {"id_Cbo":"1091", "codigo":"351720", "titulo":"Assistente tecnico de seguros"},
 {"id_Cbo":"1092", "codigo":"351725", "titulo":"Inspetor de risco"},
 {"id_Cbo":"1093", "codigo":"351730", "titulo":"Inspetor de sinistros"},
 {"id_Cbo":"1094", "codigo":"351735", "titulo":"Tecnico de resseguros"},
 {"id_Cbo":"1095", "codigo":"351740", "titulo":"Tecnico de seguros"},
 {"id_Cbo":"1096", "codigo":"351805", "titulo":"Detetive profissional"},
 {"id_Cbo":"1097", "codigo":"351810", "titulo":"Investigador de policia"},
 {"id_Cbo":"1098", "codigo":"351815", "titulo":"Papiloscopista policial"},
 {"id_Cbo":"1099", "codigo":"351905", "titulo":"Agente de inteligencia"},
 {"id_Cbo":"1100", "codigo":"351910", "titulo":"Agente tecnico de inteligencia"},
 {"id_Cbo":"1101", "codigo":"352205", "titulo":"Agente de defesa ambiental"},
 {"id_Cbo":"1102", "codigo":"352210", "titulo":"Agente de saude publica"},
 {"id_Cbo":"1103", "codigo":"352305", "titulo":"Metrologista"},
 {"id_Cbo":"1104", "codigo":"352310", "titulo":"Agente fiscal de qualidade"},
 {"id_Cbo":"1105", "codigo":"352315", "titulo":"Agente fiscal metrologico"},
 {"id_Cbo":"1106", "codigo":"352320", "titulo":"Agente fiscal textil"},
 {"id_Cbo":"1107", "codigo":"352405", "titulo":"Agente de direitos autorais"},
 {"id_Cbo":"1108", "codigo":"352410", "titulo":"Avaliador de produtos do meio de comunicacao"},
 {"id_Cbo":"1109", "codigo":"352420", "titulo":"Tecnico em direitos autorais"},
 {"id_Cbo":"1110", "codigo":"353205", "titulo":"Tecnico de operacoes e servicos bancarios - cambio"},
 {"id_Cbo":"1111", "codigo":"353210", "titulo":"Tecnico de operacoes e servicos bancarios - credito imobiliario"},
 {"id_Cbo":"1112", "codigo":"353215", "titulo":"Tecnico de operacoes e servicos bancarios - credito rural"},
 {"id_Cbo":"1113", "codigo":"353220", "titulo":"Tecnico de operacoes e servicos bancarios - leasing"},
 {"id_Cbo":"1114", "codigo":"353225", "titulo":"Tecnico de operacoes e servicos bancarios - renda fixa e variavel"},
 {"id_Cbo":"1115", "codigo":"353230", "titulo":"Tesoureiro de banco"},
 {"id_Cbo":"1116", "codigo":"353235", "titulo":"Chefe de servicos bancarios"},
 {"id_Cbo":"1117", "codigo":"354120", "titulo":"Agente de vendas de servicos"},
 {"id_Cbo":"1118", "codigo":"354125", "titulo":"Assistente de vendas"},
 {"id_Cbo":"1119", "codigo":"354130", "titulo":"Promotor de vendas especializado"},
 {"id_Cbo":"1120", "codigo":"354135", "titulo":"Tecnico de vendas"},
 {"id_Cbo":"1121", "codigo":"354140", "titulo":"Tecnico em atendimento e vendas"},
 {"id_Cbo":"1122", "codigo":"354145", "titulo":"Vendedor pracista"},
 {"id_Cbo":"1123", "codigo":"354150", "titulo":"Propagandista de produtos famaceuticos"},
 {"id_Cbo":"1124", "codigo":"354205", "titulo":"Comprador"},
 {"id_Cbo":"1125", "codigo":"354210", "titulo":"Supervisor de compras"},
 {"id_Cbo":"1126", "codigo":"354305", "titulo":"Analista de exportacao e importacao"},
 {"id_Cbo":"1127", "codigo":"354405", "titulo":"Leiloeiro"},
 {"id_Cbo":"1128", "codigo":"354410", "titulo":"Avaliador de imoveis"},
 {"id_Cbo":"1129", "codigo":"354415", "titulo":"Avaliador de bens moveis"},
 {"id_Cbo":"1130", "codigo":"354505", "titulo":"Corretor de seguros"},
 {"id_Cbo":"1131", "codigo":"354605", "titulo":"Corretor de imoveis"},
 {"id_Cbo":"1132", "codigo":"354705", "titulo":"Representante comercial autonomo"},
 {"id_Cbo":"1133", "codigo":"354805", "titulo":"Tecnico em turismo"},
 {"id_Cbo":"1134", "codigo":"354810", "titulo":"Operador de turismo"},
 {"id_Cbo":"1135", "codigo":"354815", "titulo":"Agente de viagem"},
 {"id_Cbo":"1136", "codigo":"354820", "titulo":"Organizador de evento"},
 {"id_Cbo":"1137", "codigo":"354825", "titulo":"Cerimonialista"},
 {"id_Cbo":"1138", "codigo":"371105", "titulo":"Auxiliar de biblioteca"},
 {"id_Cbo":"1139", "codigo":"371110", "titulo":"Tecnico em biblioteconomia"},
 {"id_Cbo":"1140", "codigo":"371205", "titulo":"Colecionador de selos e moedas"},
 {"id_Cbo":"1141", "codigo":"371210", "titulo":"Tecnico em museologia"},
 {"id_Cbo":"1142", "codigo":"371305", "titulo":"Tecnico em programacao visual"},
 {"id_Cbo":"1143", "codigo":"371310", "titulo":"Tecnico grafico"},
 {"id_Cbo":"1144", "codigo":"371405", "titulo":"Recreador de acantonamento"},
 {"id_Cbo":"1145", "codigo":"371410", "titulo":"Recreador"},
 {"id_Cbo":"1146", "codigo":"372105", "titulo":"Diretor de fotografia"},
 {"id_Cbo":"1147", "codigo":"372110", "titulo":"Iluminador (televisao)"},
 {"id_Cbo":"1148", "codigo":"372115", "titulo":"Operador de camera de televisao"},
 {"id_Cbo":"1149", "codigo":"372205", "titulo":"Operador de rede de teleprocessamento"},
 {"id_Cbo":"1150", "codigo":"372210", "titulo":"Radiotelegrafista"},
 {"id_Cbo":"1151", "codigo":"373105", "titulo":"Operador de midia audiovisual"},
 {"id_Cbo":"1152", "codigo":"373130", "titulo":"Tecnico de sistemas audiovisuais"},
 {"id_Cbo":"1153", "codigo":"373135", "titulo":"Operador de controle mestre"},
 {"id_Cbo":"1154", "codigo":"373140", "titulo":"Coordenador de programacao"},
 {"id_Cbo":"1155", "codigo":"373145", "titulo":"Assistente de operacoes audiovisuais"},
 {"id_Cbo":"1156", "codigo":"373220", "titulo":"Supervisor tecnico operacional de sistemas de televisao e produtoras de video"},
 {"id_Cbo":"1157", "codigo":"373225", "titulo":"Supervisor de operacoes (midias audiovisuais)"},
 {"id_Cbo":"1158", "codigo":"373230", "titulo":"Supervisor tecnico (midias audiovisuais)"},
 {"id_Cbo":"1159", "codigo":"374105", "titulo":"Tecnico em gravacao de audio"},
 {"id_Cbo":"1160", "codigo":"374110", "titulo":"Tecnico em instalacao de equipamentos de audio"},
 {"id_Cbo":"1161", "codigo":"374115", "titulo":"Tecnico em masterizacao de audio"},
 {"id_Cbo":"1162", "codigo":"374120", "titulo":"Projetista de som"},
 {"id_Cbo":"1163", "codigo":"374125", "titulo":"Tecnico em sonorizacao"},
 {"id_Cbo":"1164", "codigo":"374130", "titulo":"Tecnico em mixagem de audio"},
 {"id_Cbo":"1165", "codigo":"374135", "titulo":"Projetista de sistemas de audio"},
 {"id_Cbo":"1166", "codigo":"374140", "titulo":"Microfonista"},
 {"id_Cbo":"1167", "codigo":"374145", "titulo":"Dj (disc jockey)"},
 {"id_Cbo":"1168", "codigo":"374150", "titulo":"Sonoplasta"},
 {"id_Cbo":"1169", "codigo":"374155", "titulo":"Analista musical"},
 {"id_Cbo":"1170", "codigo":"374205", "titulo":"Cenotecnico (cinema, video, televisao, teatro e espetaculos)"},
 {"id_Cbo":"1171", "codigo":"374210", "titulo":"Maquinista de cinema e video"},
 {"id_Cbo":"1172", "codigo":"374215", "titulo":"Maquinista de teatro e espetaculos"},
 {"id_Cbo":"1173", "codigo":"374305", "titulo":"Operador de projetor cinematografico"},
 {"id_Cbo":"1174", "codigo":"374310", "titulo":"Operador-mantenedor de projetor cinematografico"},
 {"id_Cbo":"1175", "codigo":"374405", "titulo":"Editor de midia audiovisual"},
 {"id_Cbo":"1176", "codigo":"374410", "titulo":"Finalizador de filmes"},
 {"id_Cbo":"1177", "codigo":"374415", "titulo":"Finalizador de video"},
 {"id_Cbo":"1178", "codigo":"374420", "titulo":"Montador de filmes"},
 {"id_Cbo":"1179", "codigo":"374425", "titulo":"Diretor de imagens (tv)"},
 {"id_Cbo":"1180", "codigo":"375105", "titulo":"Designer de interiores"},
 {"id_Cbo":"1181", "codigo":"375110", "titulo":"Designer de vitrines"},
 {"id_Cbo":"1182", "codigo":"375115", "titulo":"Visual merchandiser"},
 {"id_Cbo":"1183", "codigo":"375120", "titulo":"Decorador de eventos"},
 {"id_Cbo":"1184", "codigo":"375125", "titulo":"Produtor de moda"},
 {"id_Cbo":"1185", "codigo":"376105", "titulo":"Dancarino tradicional"},
 {"id_Cbo":"1186", "codigo":"376110", "titulo":"Dancarino popular"},
 {"id_Cbo":"1187", "codigo":"376205", "titulo":"Acrobata"},
 {"id_Cbo":"1188", "codigo":"376210", "titulo":"Artista aereo"},
 {"id_Cbo":"1189", "codigo":"376215", "titulo":"Artista de circo (outros)"},
 {"id_Cbo":"1190", "codigo":"376220", "titulo":"Contorcionista"},
 {"id_Cbo":"1191", "codigo":"376225", "titulo":"Domador de animais (circense)"},
 {"id_Cbo":"1192", "codigo":"376230", "titulo":"Equilibrista"},
 {"id_Cbo":"1193", "codigo":"376235", "titulo":"Magico"},
 {"id_Cbo":"1194", "codigo":"376240", "titulo":"Malabarista"},
 {"id_Cbo":"1195", "codigo":"376245", "titulo":"Palhaco"},
 {"id_Cbo":"1196", "codigo":"376250", "titulo":"Titeriteiro"},
 {"id_Cbo":"1197", "codigo":"376255", "titulo":"Trapezista"},
 {"id_Cbo":"1198", "codigo":"376305", "titulo":"Apresentador de eventos"},
 {"id_Cbo":"1199", "codigo":"376310", "titulo":"Apresentador de festas populares"},
 {"id_Cbo":"1200", "codigo":"376315", "titulo":"Apresentador de programas de radio"},
 {"id_Cbo":"1201", "codigo":"376320", "titulo":"Apresentador de programas de televisao"},
 {"id_Cbo":"1202", "codigo":"376325", "titulo":"Apresentador de circo"},
 {"id_Cbo":"1203", "codigo":"376330", "titulo":"Mestre de cerimonias"},
 {"id_Cbo":"1204", "codigo":"376405", "titulo":"Modelo artistico"},
 {"id_Cbo":"1205", "codigo":"376410", "titulo":"Modelo de modas"},
 {"id_Cbo":"1206", "codigo":"376415", "titulo":"Modelo publicitario"},
 {"id_Cbo":"1207", "codigo":"377105", "titulo":"Atleta profissional (outras modalidades)"},
 {"id_Cbo":"1208", "codigo":"377110", "titulo":"Atleta profissional de futebol"},
 {"id_Cbo":"1209", "codigo":"377115", "titulo":"Atleta profissional de golfe"},
 {"id_Cbo":"1210", "codigo":"377120", "titulo":"Atleta profissional de luta"},
 {"id_Cbo":"1211", "codigo":"377125", "titulo":"Atleta profissional de tenis"},
 {"id_Cbo":"1212", "codigo":"377130", "titulo":"Joquei"},
 {"id_Cbo":"1213", "codigo":"377135", "titulo":"Piloto de competicao automobilistica"},
 {"id_Cbo":"1214", "codigo":"377140", "titulo":"Profissional de atletismo"},
 {"id_Cbo":"1215", "codigo":"377145", "titulo":"Pugilista"},
 {"id_Cbo":"1216", "codigo":"377205", "titulo":"Arbitro desportivo"},
 {"id_Cbo":"1217", "codigo":"377210", "titulo":"Arbitro de atletismo"},
 {"id_Cbo":"1218", "codigo":"377215", "titulo":"Arbitro de basquete"},
 {"id_Cbo":"1219", "codigo":"377220", "titulo":"Arbitro de futebol"},
 {"id_Cbo":"1220", "codigo":"377225", "titulo":"Arbitro de futebol de salao"},
 {"id_Cbo":"1221", "codigo":"377230", "titulo":"Arbitro de judo"},
 {"id_Cbo":"1222", "codigo":"377235", "titulo":"Arbitro de karate"},
 {"id_Cbo":"1223", "codigo":"377240", "titulo":"Arbitro de polo aquatico"},
 {"id_Cbo":"1224", "codigo":"377245", "titulo":"Arbitro de volei"},
 {"id_Cbo":"1225", "codigo":"391105", "titulo":"Cronoanalista"},
 {"id_Cbo":"1226", "codigo":"391110", "titulo":"Cronometrista"},
 {"id_Cbo":"1227", "codigo":"391115", "titulo":"Controlador de entrada e saida"},
 {"id_Cbo":"1228", "codigo":"391120", "titulo":"Planejista"},
 {"id_Cbo":"1229", "codigo":"391125", "titulo":"Tecnico de planejamento de producao"},
 {"id_Cbo":"1230", "codigo":"391130", "titulo":"Tecnico de planejamento e programacao da manutencao"},
 {"id_Cbo":"1231", "codigo":"391135", "titulo":"Tecnico de materia-prima e material"},
 {"id_Cbo":"1232", "codigo":"391140", "titulo":"Analista de manutencao (equipamentos aereos)"},
 {"id_Cbo":"1233", "codigo":"391145", "titulo":"Analista de planejamento de manutencao"},
 {"id_Cbo":"1234", "codigo":"391205", "titulo":"Inspetor de qualidade"},
 {"id_Cbo":"1235", "codigo":"391210", "titulo":"Tecnico de garantia da qualidade"},
 {"id_Cbo":"1236", "codigo":"391215", "titulo":"Operador de inspecao de qualidade"},
 {"id_Cbo":"1237", "codigo":"391220", "titulo":"Tecnico de painel de controle"},
 {"id_Cbo":"1238", "codigo":"391225", "titulo":"Escolhedor de papel"},
 {"id_Cbo":"1239", "codigo":"391230", "titulo":"Tecnico operacional de servicos de correios"},
 {"id_Cbo":"1240", "codigo":"395105", "titulo":"Tecnico de apoio em pesquisa e desenvolvimento (exceto agropecuario e florestal)"},
 {"id_Cbo":"1241", "codigo":"395110", "titulo":"Tecnico de apoio em pesquisa e desenvolvimento agropecuario florestal"},
 {"id_Cbo":"1242", "codigo":"410105", "titulo":"Supervisor administrativo"},
 {"id_Cbo":"1243", "codigo":"410205", "titulo":"Supervisor de almoxarifado"},
 {"id_Cbo":"1244", "codigo":"410210", "titulo":"Supervisor de cambio"},
 {"id_Cbo":"1245", "codigo":"410215", "titulo":"Supervisor de contas a pagar"},
 {"id_Cbo":"1246", "codigo":"410220", "titulo":"Supervisor de controle patrimonial"},
 {"id_Cbo":"1247", "codigo":"410225", "titulo":"Supervisor de credito e cobranca"},
 {"id_Cbo":"1248", "codigo":"410230", "titulo":"Supervisor de orcamento"},
 {"id_Cbo":"1249", "codigo":"410235", "titulo":"Supervisor de tesouraria"},
 {"id_Cbo":"1250", "codigo":"410240", "titulo":"Supervisor de logistica"},
 {"id_Cbo":"1251", "codigo":"411005", "titulo":"Auxiliar de escritorio"},
 {"id_Cbo":"1252", "codigo":"411010", "titulo":"Assistente administrativo"},
 {"id_Cbo":"1253", "codigo":"411015", "titulo":"Atendente de judiciario"},
 {"id_Cbo":"1254", "codigo":"411020", "titulo":"Auxiliar de judiciario"},
 {"id_Cbo":"1255", "codigo":"411025", "titulo":"Auxiliar de cartorio"},
 {"id_Cbo":"1256", "codigo":"411030", "titulo":"Auxiliar de pessoal"},
 {"id_Cbo":"1257", "codigo":"411035", "titulo":"Auxiliar de estatistica"},
 {"id_Cbo":"1258", "codigo":"411040", "titulo":"Auxiliar de seguros"},
 {"id_Cbo":"1259", "codigo":"411045", "titulo":"Auxiliar de servicos de importacao e exportacao"},
 {"id_Cbo":"1260", "codigo":"411050", "titulo":"Agente de microcredito"},
 {"id_Cbo":"1261", "codigo":"412105", "titulo":"Datilografo"},
 {"id_Cbo":"1262", "codigo":"412110", "titulo":"Digitador"},
 {"id_Cbo":"1263", "codigo":"412115", "titulo":"Operador de mensagens de telecomunicacoes (correios)"},
 {"id_Cbo":"1264", "codigo":"412120", "titulo":"Supervisor de digitacao e operacao"},
 {"id_Cbo":"1265", "codigo":"412205", "titulo":"Continuo"},
 {"id_Cbo":"1266", "codigo":"413105", "titulo":"Analista de folha de pagamento"},
 {"id_Cbo":"1267", "codigo":"413110", "titulo":"Auxiliar de contabilidade"},
 {"id_Cbo":"1268", "codigo":"413115", "titulo":"Auxiliar de faturamento"},
 {"id_Cbo":"1269", "codigo":"413205", "titulo":"Atendente de agencia"},
 {"id_Cbo":"1270", "codigo":"413210", "titulo":"Caixa de banco"},
 {"id_Cbo":"1271", "codigo":"413215", "titulo":"Compensador de banco"},
 {"id_Cbo":"1272", "codigo":"413220", "titulo":"Conferente de servicos bancarios"},
 {"id_Cbo":"1273", "codigo":"413225", "titulo":"Escriturario de banco"},
 {"id_Cbo":"1274", "codigo":"413230", "titulo":"Operador de cobranca bancaria"},
 {"id_Cbo":"1275", "codigo":"414105", "titulo":"Almoxarife"},
 {"id_Cbo":"1276", "codigo":"414110", "titulo":"Armazenista"},
 {"id_Cbo":"1277", "codigo":"414115", "titulo":"Balanceiro"},
 {"id_Cbo":"1278", "codigo":"414120", "titulo":"Conferente mercadoria (exceto carga e descarga)"},
 {"id_Cbo":"1279", "codigo":"414125", "titulo":"Estoquista"},
 {"id_Cbo":"1280", "codigo":"414135", "titulo":"Expedidor de mercadorias"},
 {"id_Cbo":"1281", "codigo":"414140", "titulo":"Auxiliar de logistica"},
 {"id_Cbo":"1282", "codigo":"414205", "titulo":"Apontador de mao-de-obra"},
 {"id_Cbo":"1283", "codigo":"414210", "titulo":"Apontador de producao"},
 {"id_Cbo":"1284", "codigo":"414215", "titulo":"Conferente de carga e descarga"},
 {"id_Cbo":"1285", "codigo":"415105", "titulo":"Arquivista de documentos"},
 {"id_Cbo":"1286", "codigo":"415115", "titulo":"Codificador de dados"},
 {"id_Cbo":"1287", "codigo":"415120", "titulo":"Fitotecario"},
 {"id_Cbo":"1288", "codigo":"415125", "titulo":"Kardexista"},
 {"id_Cbo":"1289", "codigo":"415130", "titulo":"Operador de maquina copiadora (exceto operador de grafica rapida)"},
 {"id_Cbo":"1290", "codigo":"415205", "titulo":"Carteiro"},
 {"id_Cbo":"1291", "codigo":"415210", "titulo":"Operador de triagem e transbordo"},
 {"id_Cbo":"1292", "codigo":"415215", "titulo":"Entregador de publicacoes"},
 {"id_Cbo":"1293", "codigo":"415305", "titulo":"Registrador de cancer"},
 {"id_Cbo":"1294", "codigo":"420105", "titulo":"Supervisor de caixas e bilheteiros (exceto caixa de banco)"},
 {"id_Cbo":"1295", "codigo":"420110", "titulo":"Supervisor de cobranca"},
 {"id_Cbo":"1296", "codigo":"420115", "titulo":"Supervisor de coletadores de apostas e de jogos"},
 {"id_Cbo":"1297", "codigo":"420120", "titulo":"Supervisor de entrevistadores e recenseadores"},
 {"id_Cbo":"1298", "codigo":"420125", "titulo":"Supervisor de recepcionistas"},
 {"id_Cbo":"1299", "codigo":"420130", "titulo":"Supervisor de telefonistas"},
 {"id_Cbo":"1300", "codigo":"420135", "titulo":"Supervisor de telemarketing e atendimento"},
 {"id_Cbo":"1301", "codigo":"421105", "titulo":"Atendente comercial (agencia postal)"},
 {"id_Cbo":"1302", "codigo":"421110", "titulo":"Bilheteiro de transportes coletivos"},
 {"id_Cbo":"1303", "codigo":"421115", "titulo":"Bilheteiro no servico de diversoes"},
 {"id_Cbo":"1304", "codigo":"421120", "titulo":"Emissor de passagens"},
 {"id_Cbo":"1305", "codigo":"421125", "titulo":"Operador de caixa"},
 {"id_Cbo":"1306", "codigo":"421205", "titulo":"Recebedor de apostas (loteria)"},
 {"id_Cbo":"1307", "codigo":"421210", "titulo":"Recebedor de apostas (turfe)"},
 {"id_Cbo":"1308", "codigo":"421305", "titulo":"Cobrador externo"},
 {"id_Cbo":"1309", "codigo":"421310", "titulo":"Cobrador interno"},
 {"id_Cbo":"1310", "codigo":"421315", "titulo":"Localizador (cobrador)"},
 {"id_Cbo":"1311", "codigo":"422105", "titulo":"Recepcionista, em geral"},
 {"id_Cbo":"1312", "codigo":"422110", "titulo":"Recepcionista de consultorio medico ou dentario"},
 {"id_Cbo":"1313", "codigo":"422115", "titulo":"Recepcionista de seguro saude"},
 {"id_Cbo":"1314", "codigo":"422120", "titulo":"Recepcionista de hotel"},
 {"id_Cbo":"1315", "codigo":"422125", "titulo":"Recepcionista de banco"},
 {"id_Cbo":"1316", "codigo":"422130", "titulo":"Concierge"},
 {"id_Cbo":"1317", "codigo":"422205", "titulo":"Telefonista"},
 {"id_Cbo":"1318", "codigo":"422210", "titulo":"Teleoperador"},
 {"id_Cbo":"1319", "codigo":"422215", "titulo":"Monitor de teleatendimento"},
 {"id_Cbo":"1320", "codigo":"422220", "titulo":"Operador de radio-chamada"},
 {"id_Cbo":"1321", "codigo":"422305", "titulo":"Operador de telemarketing ativo"},
 {"id_Cbo":"1322", "codigo":"422310", "titulo":"Operador de telemarketing ativo e receptivo"},
 {"id_Cbo":"1323", "codigo":"422315", "titulo":"Operador de telemarketing receptivo"},
 {"id_Cbo":"1324", "codigo":"422320", "titulo":"Operador de telemarketing tecnico"},
 {"id_Cbo":"1325", "codigo":"423105", "titulo":"Despachante documentalista"},
 {"id_Cbo":"1326", "codigo":"423110", "titulo":"Despachante de transito"},
 {"id_Cbo":"1327", "codigo":"424105", "titulo":"Entrevistador censitario e de pesquisas amostrais"},
 {"id_Cbo":"1328", "codigo":"424110", "titulo":"Entrevistador de pesquisa de opiniao e midia"},
 {"id_Cbo":"1329", "codigo":"424115", "titulo":"Entrevistador de pesquisas de mercado"},
 {"id_Cbo":"1330", "codigo":"424120", "titulo":"Entrevistador de precos"},
 {"id_Cbo":"1331", "codigo":"424125", "titulo":"Escriturario  em  estatistica"},
 {"id_Cbo":"1332", "codigo":"424130", "titulo":"Entrevistador social"},
 {"id_Cbo":"1333", "codigo":"424205", "titulo":"Coordenador de provas (concurso, avaliacao, exame)"},
 {"id_Cbo":"1334", "codigo":"424210", "titulo":"Aplicador de provas (concurso, avaliacao,exame)"},
 {"id_Cbo":"1335", "codigo":"510105", "titulo":"Supervisor de transportes"},
 {"id_Cbo":"1336", "codigo":"510110", "titulo":"Administrador de edificios"},
 {"id_Cbo":"1337", "codigo":"510115", "titulo":"Supervisor de andar"},
 {"id_Cbo":"1338", "codigo":"510120", "titulo":"Chefe de portaria de hotel"},
 {"id_Cbo":"1339", "codigo":"510130", "titulo":"Chefe de bar"},
 {"id_Cbo":"1340", "codigo":"510135", "titulo":"Maitre"},
 {"id_Cbo":"1341", "codigo":"510205", "titulo":"Supervisor de lavanderia"},
 {"id_Cbo":"1342", "codigo":"510305", "titulo":"Supervisor de bombeiros"},
 {"id_Cbo":"1343", "codigo":"510310", "titulo":"Supervisor de vigilantes"},
 {"id_Cbo":"1344", "codigo":"511105", "titulo":"Comissario de voo"},
 {"id_Cbo":"1345", "codigo":"511110", "titulo":"Comissario de trem"},
 {"id_Cbo":"1346", "codigo":"511115", "titulo":"Taifeiro (exceto militares)"},
 {"id_Cbo":"1347", "codigo":"511205", "titulo":"Fiscal de transportes coletivos (exceto trem)"},
 {"id_Cbo":"1348", "codigo":"511210", "titulo":"Despachante de transportes coletivos (exceto trem)"},
 {"id_Cbo":"1349", "codigo":"511215", "titulo":"Cobrador de transportes coletivos (exceto trem)"},
 {"id_Cbo":"1350", "codigo":"511220", "titulo":"Bilheteiro (estacoes de metro, ferroviarias e assemelhadas)"},
 {"id_Cbo":"1351", "codigo":"511405", "titulo":"Guia de turismo"},
 {"id_Cbo":"1352", "codigo":"511505", "titulo":"Condutor de turismo de aventura"},
 {"id_Cbo":"1353", "codigo":"511510", "titulo":"Condutor de turismo de pesca"},
 {"id_Cbo":"1354", "codigo":"512105", "titulo":"Empregado  domestico  nos servicos gerais"},
 {"id_Cbo":"1355", "codigo":"512110", "titulo":"Empregado domestico  arrumador"},
 {"id_Cbo":"1356", "codigo":"512115", "titulo":"Empregado domestico  faxineiro"},
 {"id_Cbo":"1357", "codigo":"512120", "titulo":"Empregado domestico diarista"},
 {"id_Cbo":"1358", "codigo":"513105", "titulo":"Mordomo de residencia"},
 {"id_Cbo":"1359", "codigo":"513110", "titulo":"Mordomo de hotelaria"},
 {"id_Cbo":"1360", "codigo":"513115", "titulo":"Governanta de hotelaria"},
 {"id_Cbo":"1361", "codigo":"513205", "titulo":"Cozinheiro geral"},
 {"id_Cbo":"1362", "codigo":"513210", "titulo":"Cozinheiro do servico domestico"},
 {"id_Cbo":"1363", "codigo":"513215", "titulo":"Cozinheiro industrial"},
 {"id_Cbo":"1364", "codigo":"513220", "titulo":"Cozinheiro de hospital"},
 {"id_Cbo":"1365", "codigo":"513225", "titulo":"Cozinheiro de embarcacoes"},
 {"id_Cbo":"1366", "codigo":"513305", "titulo":"Camareira de teatro"},
 {"id_Cbo":"1367", "codigo":"513310", "titulo":"Camareira de televisao"},
 {"id_Cbo":"1368", "codigo":"513315", "titulo":"Camareiro  de hotel"},
 {"id_Cbo":"1369", "codigo":"513320", "titulo":"Camareiro de embarcacoes"},
 {"id_Cbo":"1370", "codigo":"513325", "titulo":"Guarda-roupeira de cinema"},
 {"id_Cbo":"1371", "codigo":"513405", "titulo":"Garcom"},
 {"id_Cbo":"1372", "codigo":"513410", "titulo":"Garcom (servicos de vinhos)"},
 {"id_Cbo":"1373", "codigo":"513415", "titulo":"Cumim"},
 {"id_Cbo":"1374", "codigo":"513420", "titulo":"Barman"},
 {"id_Cbo":"1375", "codigo":"513425", "titulo":"Copeiro"},
 {"id_Cbo":"1376", "codigo":"513430", "titulo":"Copeiro de hospital"},
 {"id_Cbo":"1377", "codigo":"513435", "titulo":"Atendente de lanchonete"},
 {"id_Cbo":"1378", "codigo":"513440", "titulo":"Barista"},
 {"id_Cbo":"1379", "codigo":"513505", "titulo":"Auxiliar nos servicos de alimentacao"},
 {"id_Cbo":"1380", "codigo":"513605", "titulo":"Churrasqueiro"},
 {"id_Cbo":"1381", "codigo":"513610", "titulo":"Pizzaiolo"},
 {"id_Cbo":"1382", "codigo":"513615", "titulo":"Sushiman"},
 {"id_Cbo":"1383", "codigo":"514105", "titulo":"Ascensorista"},
 {"id_Cbo":"1384", "codigo":"514110", "titulo":"Garagista"},
 {"id_Cbo":"1385", "codigo":"514115", "titulo":"Sacristao"},
 {"id_Cbo":"1386", "codigo":"514120", "titulo":"Zelador de edificio"},
 {"id_Cbo":"1387", "codigo":"514205", "titulo":"Coletor de lixo domiciliar"},
 {"id_Cbo":"1388", "codigo":"514215", "titulo":"Varredor de rua"},
 {"id_Cbo":"1389", "codigo":"514225", "titulo":"Trabalhador de servicos de limpeza e conservacao de areas publicas"},
 {"id_Cbo":"1390", "codigo":"514230", "titulo":"Coletor de residuos solidos de servicos de saude"},
 {"id_Cbo":"1391", "codigo":"514305", "titulo":"Limpador de vidros"},
 {"id_Cbo":"1392", "codigo":"514310", "titulo":"Auxiliar de manutencao predial"},
 {"id_Cbo":"1393", "codigo":"514315", "titulo":"Limpador de fachadas"},
 {"id_Cbo":"1394", "codigo":"514320", "titulo":"Faxineiro"},
 {"id_Cbo":"1395", "codigo":"514325", "titulo":"Trabalhador da manutencao de edificacoes"},
 {"id_Cbo":"1396", "codigo":"514330", "titulo":"Limpador de piscinas"},
 {"id_Cbo":"1397", "codigo":"515105", "titulo":"Agente comunitario de saude"},
 {"id_Cbo":"1398", "codigo":"515110", "titulo":"Atendente de enfermagem"},
 {"id_Cbo":"1399", "codigo":"515115", "titulo":"Parteira leiga"},
 {"id_Cbo":"1400", "codigo":"515120", "titulo":"Visitador sanitario"},
 {"id_Cbo":"1401", "codigo":"515125", "titulo":"Agente indigena de saude"},
 {"id_Cbo":"1402", "codigo":"515130", "titulo":"Agente indigena de saneamento"},
 {"id_Cbo":"1403", "codigo":"515135", "titulo":"Socorrista (exceto medicos e enfermeiros)"},
 {"id_Cbo":"1404", "codigo":"515140", "titulo":"Agente de combate as endemias"},
 {"id_Cbo":"1405", "codigo":"515205", "titulo":"Auxiliar de banco de sangue"},
 {"id_Cbo":"1406", "codigo":"515210", "titulo":"Auxiliar de farmacia de manipulacao"},
 {"id_Cbo":"1407", "codigo":"515215", "titulo":"Auxiliar de laboratorio de analises clinicas"},
 {"id_Cbo":"1408", "codigo":"515220", "titulo":"Auxiliar de laboratorio de imunobiologicos"},
 {"id_Cbo":"1409", "codigo":"515225", "titulo":"Auxiliar de producao farmaceutica"},
 {"id_Cbo":"1410", "codigo":"515305", "titulo":"Educador social"},
 {"id_Cbo":"1411", "codigo":"515310", "titulo":"Agente de acao social"},
 {"id_Cbo":"1412", "codigo":"515315", "titulo":"Monitor de dependente quimico"},
 {"id_Cbo":"1413", "codigo":"515320", "titulo":"Conselheiro tutelar"},
 {"id_Cbo":"1414", "codigo":"515325", "titulo":"Socioeducador"},
 {"id_Cbo":"1415", "codigo":"515330", "titulo":"Monitor de ressocializacao prisional"},
 {"id_Cbo":"1416", "codigo":"516105", "titulo":"Barbeiro"},
 {"id_Cbo":"1417", "codigo":"516110", "titulo":"Cabeleireiro"},
 {"id_Cbo":"1418", "codigo":"516120", "titulo":"Manicure"},
 {"id_Cbo":"1419", "codigo":"516125", "titulo":"Maquiador"},
 {"id_Cbo":"1420", "codigo":"516130", "titulo":"Maquiador de caracterizacao"},
 {"id_Cbo":"1421", "codigo":"516140", "titulo":"Pedicure"},
 {"id_Cbo":"1422", "codigo":"516205", "titulo":"Baba"},
 {"id_Cbo":"1423", "codigo":"516210", "titulo":"Cuidador de idosos"},
 {"id_Cbo":"1424", "codigo":"516215", "titulo":"Mae social"},
 {"id_Cbo":"1425", "codigo":"516220", "titulo":"Cuidador em saude"},
 {"id_Cbo":"1426", "codigo":"516305", "titulo":"Lavadeiro, em geral"},
 {"id_Cbo":"1427", "codigo":"516310", "titulo":"Lavador de roupas  a maquina"},
 {"id_Cbo":"1428", "codigo":"516315", "titulo":"Lavador de artefatos de tapecaria"},
 {"id_Cbo":"1429", "codigo":"516320", "titulo":"Limpador a seco, a maquina"},
 {"id_Cbo":"1430", "codigo":"516325", "titulo":"Passador de roupas em geral"},
 {"id_Cbo":"1431", "codigo":"516330", "titulo":"Tingidor de roupas"},
 {"id_Cbo":"1432", "codigo":"516335", "titulo":"Conferente-expedidor de roupas (lavanderias)"},
 {"id_Cbo":"1433", "codigo":"516340", "titulo":"Atendente de lavanderia"},
 {"id_Cbo":"1434", "codigo":"516345", "titulo":"Auxiliar de lavanderia"},
 {"id_Cbo":"1435", "codigo":"516405", "titulo":"Lavador de roupas"},
 {"id_Cbo":"1436", "codigo":"516410", "titulo":"Limpador de roupas a seco, a mao"},
 {"id_Cbo":"1437", "codigo":"516415", "titulo":"Passador de roupas, a mao"},
 {"id_Cbo":"1438", "codigo":"516505", "titulo":"Agente funerario"},
 {"id_Cbo":"1439", "codigo":"516605", "titulo":"Operador de forno (servicos funerarios)"},
 {"id_Cbo":"1440", "codigo":"516610", "titulo":"Sepultador"},
 {"id_Cbo":"1441", "codigo":"516705", "titulo":"Astrologo"},
 {"id_Cbo":"1442", "codigo":"516710", "titulo":"Numerologo"},
 {"id_Cbo":"1443", "codigo":"516805", "titulo":"Esoterico"},
 {"id_Cbo":"1444", "codigo":"516810", "titulo":"Paranormal"},
 {"id_Cbo":"1445", "codigo":"517105", "titulo":"Bombeiro de aerodromo"},
 {"id_Cbo":"1446", "codigo":"517110", "titulo":"Bombeiro civil"},
 {"id_Cbo":"1447", "codigo":"517115", "titulo":"Salva-vidas"},
 {"id_Cbo":"1448", "codigo":"517120", "titulo":"Brigadista florestal"},
 {"id_Cbo":"1449", "codigo":"517125", "titulo":"Chefe de brigada"},
 {"id_Cbo":"1450", "codigo":"517205", "titulo":"Agente de policia federal"},
 {"id_Cbo":"1451", "codigo":"517210", "titulo":"Policial rodoviario federal"},
 {"id_Cbo":"1452", "codigo":"517215", "titulo":"Guarda-civil municipal"},
 {"id_Cbo":"1453", "codigo":"517220", "titulo":"Agente de transito"},
 {"id_Cbo":"1454", "codigo":"517225", "titulo":"Policial legislativo"},
 {"id_Cbo":"1455", "codigo":"517305", "titulo":"Agente de protecao de aeroporto"},
 {"id_Cbo":"1456", "codigo":"517310", "titulo":"Agente de seguranca"},
 {"id_Cbo":"1457", "codigo":"517315", "titulo":"Agente de seguranca penitenciaria"},
 {"id_Cbo":"1458", "codigo":"517320", "titulo":"Vigia florestal"},
 {"id_Cbo":"1459", "codigo":"517325", "titulo":"Vigia portuario"},
 {"id_Cbo":"1460", "codigo":"517330", "titulo":"Vigilante"},
 {"id_Cbo":"1461", "codigo":"517335", "titulo":"Guarda portuario"},
 {"id_Cbo":"1462", "codigo":"517405", "titulo":"Porteiro (hotel)"},
 {"id_Cbo":"1463", "codigo":"517410", "titulo":"Porteiro de edificios"},
 {"id_Cbo":"1464", "codigo":"517415", "titulo":"Porteiro de locais de diversao"},
 {"id_Cbo":"1465", "codigo":"517420", "titulo":"Vigia"},
 {"id_Cbo":"1466", "codigo":"517425", "titulo":"Fiscal de loja"},
 {"id_Cbo":"1467", "codigo":"519105", "titulo":"Ciclista mensageiro"},
 {"id_Cbo":"1468", "codigo":"519110", "titulo":"Motofretista"},
 {"id_Cbo":"1469", "codigo":"519115", "titulo":"Mototaxista"},
 {"id_Cbo":"1470", "codigo":"519205", "titulo":"Catador de material reciclavel"},
 {"id_Cbo":"1471", "codigo":"519210", "titulo":"Selecionador de material reciclavel"},
 {"id_Cbo":"1472", "codigo":"519215", "titulo":"Operador de prensa de material reciclavel"},
 {"id_Cbo":"1473", "codigo":"519305", "titulo":"Auxiliar de veterinario"},
 {"id_Cbo":"1474", "codigo":"519310", "titulo":"Esteticista de animais domesticos"},
 {"id_Cbo":"1475", "codigo":"519315", "titulo":"Banhista de animais domesticos"},
 {"id_Cbo":"1476", "codigo":"519320", "titulo":"Tosador de animais domesticos"},
 {"id_Cbo":"1477", "codigo":"519805", "titulo":"Profissional do sexo"},
 {"id_Cbo":"1478", "codigo":"519905", "titulo":"Cartazeiro"},
 {"id_Cbo":"1479", "codigo":"519910", "titulo":"Controlador de pragas"},
 {"id_Cbo":"1480", "codigo":"519915", "titulo":"Engraxate"},
 {"id_Cbo":"1481", "codigo":"519920", "titulo":"Gandula"},
 {"id_Cbo":"1482", "codigo":"519925", "titulo":"Guardador de veiculos"},
 {"id_Cbo":"1483", "codigo":"519930", "titulo":"Lavador de garrafas, vidros e outros utensilios"},
 {"id_Cbo":"1484", "codigo":"519935", "titulo":"Lavador de veiculos"},
 {"id_Cbo":"1485", "codigo":"519940", "titulo":"Leiturista"},
 {"id_Cbo":"1486", "codigo":"519945", "titulo":"Recepcionista de casas de espetaculos"},
 {"id_Cbo":"1487", "codigo":"520105", "titulo":"Supervisor de vendas de servicos"},
 {"id_Cbo":"1488", "codigo":"520110", "titulo":"Supervisor de vendas comercial"},
 {"id_Cbo":"1489", "codigo":"521105", "titulo":"Vendedor em comercio atacadista"},
 {"id_Cbo":"1490", "codigo":"521110", "titulo":"Vendedor de comercio varejista"},
 {"id_Cbo":"1491", "codigo":"521115", "titulo":"Promotor de vendas"},
 {"id_Cbo":"1492", "codigo":"521120", "titulo":"Demonstrador de mercadorias"},
 {"id_Cbo":"1493", "codigo":"521125", "titulo":"Repositor de mercadorias"},
 {"id_Cbo":"1494", "codigo":"521130", "titulo":"Atendente de farmacia - balconista"},
 {"id_Cbo":"1495", "codigo":"521135", "titulo":"Frentista"},
 {"id_Cbo":"1496", "codigo":"521140", "titulo":"Atendente de lojas e mercados"},
 {"id_Cbo":"1497", "codigo":"523105", "titulo":"Instalador de cortinas e persianas, portas sanfonadas e boxe"},
 {"id_Cbo":"1498", "codigo":"523110", "titulo":"Instalador de som e acessorios de veiculos"},
 {"id_Cbo":"1499", "codigo":"523115", "titulo":"Chaveiro"},
 {"id_Cbo":"1500", "codigo":"524105", "titulo":"Vendedor em domicilio"},
 {"id_Cbo":"1501", "codigo":"524205", "titulo":"Feirante"},
 {"id_Cbo":"1502", "codigo":"524210", "titulo":"Jornaleiro (em banca de jornal)"},
 {"id_Cbo":"1503", "codigo":"524215", "titulo":"Vendedor  permissionario"},
 {"id_Cbo":"1504", "codigo":"524305", "titulo":"Vendedor ambulante"},
 {"id_Cbo":"1505", "codigo":"524310", "titulo":"Pipoqueiro ambulante"},
 {"id_Cbo":"1506", "codigo":"524315", "titulo":"Baiana de acaraje"},
 {"id_Cbo":"1507", "codigo":"611005", "titulo":"Produtor agropecuario, em geral"},
 {"id_Cbo":"1508", "codigo":"612005", "titulo":"Produtor agricola polivalente"},
 {"id_Cbo":"1509", "codigo":"612105", "titulo":"Produtor de arroz"},
 {"id_Cbo":"1510", "codigo":"612110", "titulo":"Produtor de cana-de-acucar"},
 {"id_Cbo":"1511", "codigo":"612115", "titulo":"Produtor de cereais de inverno"},
 {"id_Cbo":"1512", "codigo":"612120", "titulo":"Produtor de gramineas forrageiras"},
 {"id_Cbo":"1513", "codigo":"612125", "titulo":"Produtor de milho e sorgo"},
 {"id_Cbo":"1514", "codigo":"612205", "titulo":"Produtor de algodao"},
 {"id_Cbo":"1515", "codigo":"612210", "titulo":"Produtor de curaua"},
 {"id_Cbo":"1516", "codigo":"612215", "titulo":"Produtor de juta"},
 {"id_Cbo":"1517", "codigo":"612220", "titulo":"Produtor de rami"},
 {"id_Cbo":"1518", "codigo":"612225", "titulo":"Produtor de sisal"},
 {"id_Cbo":"1519", "codigo":"612305", "titulo":"Produtor na olericultura de legumes"},
 {"id_Cbo":"1520", "codigo":"612310", "titulo":"Produtor na olericultura de raizes, bulbos e tuberculos"},
 {"id_Cbo":"1521", "codigo":"612315", "titulo":"Produtor na olericultura de talos, folhas e flores"},
 {"id_Cbo":"1522", "codigo":"612320", "titulo":"Produtor na olericultura de frutos e sementes"},
 {"id_Cbo":"1523", "codigo":"612405", "titulo":"Produtor de flores de corte"},
 {"id_Cbo":"1524", "codigo":"612410", "titulo":"Produtor de flores em vaso"},
 {"id_Cbo":"1525", "codigo":"612415", "titulo":"Produtor de forracoes"},
 {"id_Cbo":"1526", "codigo":"612420", "titulo":"Produtor de plantas ornamentais"},
 {"id_Cbo":"1527", "codigo":"612505", "titulo":"Produtor de arvores frutiferas"},
 {"id_Cbo":"1528", "codigo":"612510", "titulo":"Produtor de especies frutiferas rasteiras"},
 {"id_Cbo":"1529", "codigo":"612515", "titulo":"Produtor de especies frutiferas trepadeiras"},
 {"id_Cbo":"1530", "codigo":"612605", "titulo":"Cafeicultor"},
 {"id_Cbo":"1531", "codigo":"612610", "titulo":"Produtor de cacau"},
 {"id_Cbo":"1532", "codigo":"612615", "titulo":"Produtor de erva-mate"},
 {"id_Cbo":"1533", "codigo":"612620", "titulo":"Produtor de fumo"},
 {"id_Cbo":"1534", "codigo":"612625", "titulo":"Produtor de guarana"},
 {"id_Cbo":"1535", "codigo":"612705", "titulo":"Produtor da cultura de amendoim"},
 {"id_Cbo":"1536", "codigo":"612710", "titulo":"Produtor da cultura de canola"},
 {"id_Cbo":"1537", "codigo":"612715", "titulo":"Produtor da cultura de coco-da-baia"},
 {"id_Cbo":"1538", "codigo":"612720", "titulo":"Produtor da cultura de dende"},
 {"id_Cbo":"1539", "codigo":"612725", "titulo":"Produtor da cultura de girassol"},
 {"id_Cbo":"1540", "codigo":"612730", "titulo":"Produtor da cultura de linho"},
 {"id_Cbo":"1541", "codigo":"612735", "titulo":"Produtor da cultura de mamona"},
 {"id_Cbo":"1542", "codigo":"612740", "titulo":"Produtor da cultura de soja"},
 {"id_Cbo":"1543", "codigo":"612805", "titulo":"Produtor de especiarias"},
 {"id_Cbo":"1544", "codigo":"612810", "titulo":"Produtor de plantas aromaticas e medicinais"},
 {"id_Cbo":"1545", "codigo":"613005", "titulo":"Criador em pecuaria polivalente"},
 {"id_Cbo":"1546", "codigo":"613010", "titulo":"Criador de animais domesticos"},
 {"id_Cbo":"1547", "codigo":"613105", "titulo":"Criador de asininos e muares"},
 {"id_Cbo":"1548", "codigo":"613110", "titulo":"Criador de bovinos (corte)"},
 {"id_Cbo":"1549", "codigo":"613115", "titulo":"Criador de bovinos (leite)"},
 {"id_Cbo":"1550", "codigo":"613120", "titulo":"Criador de bubalinos (corte)"},
 {"id_Cbo":"1551", "codigo":"613125", "titulo":"Criador de bubalinos (leite)"},
 {"id_Cbo":"1552", "codigo":"613130", "titulo":"Criador de equinos"},
 {"id_Cbo":"1553", "codigo":"613205", "titulo":"Criador de caprinos"},
 {"id_Cbo":"1554", "codigo":"613210", "titulo":"Criador de ovinos"},
 {"id_Cbo":"1555", "codigo":"613215", "titulo":"Criador de suinos"},
 {"id_Cbo":"1556", "codigo":"613305", "titulo":"Avicultor"},
 {"id_Cbo":"1557", "codigo":"613310", "titulo":"Cunicultor"},
 {"id_Cbo":"1558", "codigo":"613405", "titulo":"Apicultor"},
 {"id_Cbo":"1559", "codigo":"613410", "titulo":"Criador de animais produtores de veneno"},
 {"id_Cbo":"1560", "codigo":"613415", "titulo":"Minhocultor"},
 {"id_Cbo":"1561", "codigo":"613420", "titulo":"Sericultor"},
 {"id_Cbo":"1562", "codigo":"620105", "titulo":"Supervisor de exploracao agricola"},
 {"id_Cbo":"1563", "codigo":"620110", "titulo":"Supervisor de exploracao agropecuaria"},
 {"id_Cbo":"1564", "codigo":"620115", "titulo":"Supervisor de exploracao pecuaria"},
 {"id_Cbo":"1565", "codigo":"621005", "titulo":"Trabalhador agropecuario em geral"},
 {"id_Cbo":"1566", "codigo":"622005", "titulo":"Caseiro (agricultura)"},
 {"id_Cbo":"1567", "codigo":"622010", "titulo":"Jardineiro"},
 {"id_Cbo":"1568", "codigo":"622015", "titulo":"Trabalhador na producao de mudas e sementes"},
 {"id_Cbo":"1569", "codigo":"622020", "titulo":"Trabalhador volante da agricultura"},
 {"id_Cbo":"1570", "codigo":"622105", "titulo":"Trabalhador da cultura de arroz"},
 {"id_Cbo":"1571", "codigo":"622110", "titulo":"Trabalhador da cultura de cana-de-acucar"},
 {"id_Cbo":"1572", "codigo":"622115", "titulo":"Trabalhador da cultura de milho e sorgo"},
 {"id_Cbo":"1573", "codigo":"622120", "titulo":"Trabalhador da cultura de trigo, aveia, cevada e triticale"},
 {"id_Cbo":"1574", "codigo":"622205", "titulo":"Trabalhador da cultura de algodao"},
 {"id_Cbo":"1575", "codigo":"622210", "titulo":"Trabalhador da cultura de sisal"},
 {"id_Cbo":"1576", "codigo":"622215", "titulo":"Trabalhador da cultura do rami"},
 {"id_Cbo":"1577", "codigo":"622305", "titulo":"Trabalhador na olericultura (frutos e sementes)"},
 {"id_Cbo":"1578", "codigo":"622310", "titulo":"Trabalhador na olericultura (legumes)"},
 {"id_Cbo":"1579", "codigo":"622315", "titulo":"Trabalhador na olericultura (raizes, bulbos e tuberculos)"},
 {"id_Cbo":"1580", "codigo":"622320", "titulo":"Trabalhador na olericultura (talos, folhas e flores)"},
 {"id_Cbo":"1581", "codigo":"622405", "titulo":"Trabalhador no cultivo de flores e folhagens de corte"},
 {"id_Cbo":"1582", "codigo":"622410", "titulo":"Trabalhador no cultivo de flores em vaso"},
 {"id_Cbo":"1583", "codigo":"622415", "titulo":"Trabalhador no cultivo de forracoes"},
 {"id_Cbo":"1584", "codigo":"622420", "titulo":"Trabalhador no cultivo de mudas"},
 {"id_Cbo":"1585", "codigo":"622425", "titulo":"Trabalhador no cultivo de plantas ornamentais"},
 {"id_Cbo":"1586", "codigo":"622505", "titulo":"Trabalhador no cultivo de arvores frutiferas"},
 {"id_Cbo":"1587", "codigo":"622510", "titulo":"Trabalhador no cultivo de especies frutiferas rasteiras"},
 {"id_Cbo":"1588", "codigo":"622515", "titulo":"Trabalhador no cultivo de trepadeiras frutiferas"},
 {"id_Cbo":"1589", "codigo":"622605", "titulo":"Trabalhador da cultura de cacau"},
 {"id_Cbo":"1590", "codigo":"622610", "titulo":"Trabalhador da cultura de cafe"},
 {"id_Cbo":"1591", "codigo":"622615", "titulo":"Trabalhador da cultura de erva-mate"},
 {"id_Cbo":"1592", "codigo":"622620", "titulo":"Trabalhador da cultura de fumo"},
 {"id_Cbo":"1593", "codigo":"622625", "titulo":"Trabalhador da cultura de guarana"},
 {"id_Cbo":"1594", "codigo":"622705", "titulo":"Trabalhador na cultura de amendoim"},
 {"id_Cbo":"1595", "codigo":"622710", "titulo":"Trabalhador na cultura de canola"},
 {"id_Cbo":"1596", "codigo":"622715", "titulo":"Trabalhador na cultura de coco-da-baia"},
 {"id_Cbo":"1597", "codigo":"622720", "titulo":"Trabalhador na cultura de dende"},
 {"id_Cbo":"1598", "codigo":"622725", "titulo":"Trabalhador na cultura de mamona"},
 {"id_Cbo":"1599", "codigo":"622730", "titulo":"Trabalhador na cultura de soja"},
 {"id_Cbo":"1600", "codigo":"622735", "titulo":"Trabalhador na cultura do girassol"},
 {"id_Cbo":"1601", "codigo":"622740", "titulo":"Trabalhador na cultura do linho"},
 {"id_Cbo":"1602", "codigo":"622805", "titulo":"Trabalhador da cultura de especiarias"},
 {"id_Cbo":"1603", "codigo":"622810", "titulo":"Trabalhador da cultura de plantas aromaticas e medicinais"},
 {"id_Cbo":"1604", "codigo":"623005", "titulo":"Adestrador de animais"},
 {"id_Cbo":"1605", "codigo":"623010", "titulo":"Inseminador"},
 {"id_Cbo":"1606", "codigo":"623015", "titulo":"Trabalhador de pecuaria polivalente"},
 {"id_Cbo":"1607", "codigo":"623020", "titulo":"Tratador de animais"},
 {"id_Cbo":"1608", "codigo":"623025", "titulo":"Casqueador de animais"},
 {"id_Cbo":"1609", "codigo":"623030", "titulo":"Ferrador de animais"},
 {"id_Cbo":"1610", "codigo":"623105", "titulo":"Trabalhador da pecuaria (asininos e muares)"},
 {"id_Cbo":"1611", "codigo":"623110", "titulo":"Trabalhador da pecuaria (bovinos corte)"},
 {"id_Cbo":"1612", "codigo":"623115", "titulo":"Trabalhador da pecuaria (bovinos leite)"},
 {"id_Cbo":"1613", "codigo":"623120", "titulo":"Trabalhador da pecuaria (bubalinos)"},
 {"id_Cbo":"1614", "codigo":"623125", "titulo":"Trabalhador da pecuaria (equinos)"},
 {"id_Cbo":"1615", "codigo":"623205", "titulo":"Trabalhador da caprinocultura"},
 {"id_Cbo":"1616", "codigo":"623210", "titulo":"Trabalhador da ovinocultura"},
 {"id_Cbo":"1617", "codigo":"623215", "titulo":"Trabalhador da suinocultura"},
 {"id_Cbo":"1618", "codigo":"623305", "titulo":"Trabalhador da avicultura de corte"},
 {"id_Cbo":"1619", "codigo":"623310", "titulo":"Trabalhador da avicultura de postura"},
 {"id_Cbo":"1620", "codigo":"623315", "titulo":"Operador de incubadora"},
 {"id_Cbo":"1621", "codigo":"623320", "titulo":"Trabalhador da cunicultura"},
 {"id_Cbo":"1622", "codigo":"623325", "titulo":"Sexador"},
 {"id_Cbo":"1623", "codigo":"623405", "titulo":"Trabalhador em criatorios de animais produtores de veneno"},
 {"id_Cbo":"1624", "codigo":"623410", "titulo":"Trabalhador na apicultura"},
 {"id_Cbo":"1625", "codigo":"623415", "titulo":"Trabalhador na minhocultura"},
 {"id_Cbo":"1626", "codigo":"623420", "titulo":"Trabalhador na sericicultura"},
 {"id_Cbo":"1627", "codigo":"630105", "titulo":"Supervisor da aquicultura"},
 {"id_Cbo":"1628", "codigo":"630110", "titulo":"Supervisor da area florestal"},
 {"id_Cbo":"1629", "codigo":"631005", "titulo":"Catador de caranguejos e siris"},
 {"id_Cbo":"1630", "codigo":"631010", "titulo":"Catador de mariscos"},
 {"id_Cbo":"1631", "codigo":"631015", "titulo":"Pescador artesanal de lagostas"},
 {"id_Cbo":"1632", "codigo":"631020", "titulo":"Pescador artesanal de peixes e camaroes"},
 {"id_Cbo":"1633", "codigo":"631105", "titulo":"Pescador artesanal de agua doce"},
 {"id_Cbo":"1634", "codigo":"631205", "titulo":"Pescador industrial"},
 {"id_Cbo":"1635", "codigo":"631210", "titulo":"Pescador profissional"},
 {"id_Cbo":"1636", "codigo":"631305", "titulo":"Criador de camaroes"},
 {"id_Cbo":"1637", "codigo":"631310", "titulo":"Criador de jacares"},
 {"id_Cbo":"1638", "codigo":"631315", "titulo":"Criador de mexilhoes"},
 {"id_Cbo":"1639", "codigo":"631320", "titulo":"Criador de ostras"},
 {"id_Cbo":"1640", "codigo":"631325", "titulo":"Criador de peixes"},
 {"id_Cbo":"1641", "codigo":"631330", "titulo":"Criador de quelonios"},
 {"id_Cbo":"1642", "codigo":"631335", "titulo":"Criador de ras"},
 {"id_Cbo":"1643", "codigo":"631405", "titulo":"Gelador industrial"},
 {"id_Cbo":"1644", "codigo":"631410", "titulo":"Gelador profissional"},
 {"id_Cbo":"1645", "codigo":"631415", "titulo":"Proeiro"},
 {"id_Cbo":"1646", "codigo":"631420", "titulo":"Redeiro (pesca)"},
 {"id_Cbo":"1647", "codigo":"632005", "titulo":"Guia florestal"},
 {"id_Cbo":"1648", "codigo":"632010", "titulo":"Raizeiro"},
 {"id_Cbo":"1649", "codigo":"632015", "titulo":"Viveirista florestal"},
 {"id_Cbo":"1650", "codigo":"632105", "titulo":"Classificador de toras"},
 {"id_Cbo":"1651", "codigo":"632110", "titulo":"Cubador de madeira"},
 {"id_Cbo":"1652", "codigo":"632115", "titulo":"identificador florestal"},
 {"id_Cbo":"1653", "codigo":"632120", "titulo":"Operador de motosserra"},
 {"id_Cbo":"1654", "codigo":"632125", "titulo":"Trabalhador de extracao florestal, em geral"},
 {"id_Cbo":"1655", "codigo":"632205", "titulo":"Seringueiro"},
 {"id_Cbo":"1656", "codigo":"632210", "titulo":"Trabalhador da exploracao de especies produtoras de gomas nao elasticas"},
 {"id_Cbo":"1657", "codigo":"632215", "titulo":"Trabalhador da exploracao de resinas"},
 {"id_Cbo":"1658", "codigo":"632305", "titulo":"Trabalhador da exploracao de andiroba"},
 {"id_Cbo":"1659", "codigo":"632310", "titulo":"Trabalhador da exploracao de babacu"},
 {"id_Cbo":"1660", "codigo":"632315", "titulo":"Trabalhador da exploracao de bacaba"},
 {"id_Cbo":"1661", "codigo":"632320", "titulo":"Trabalhador da exploracao de buriti"},
 {"id_Cbo":"1662", "codigo":"632325", "titulo":"Trabalhador da exploracao de carnauba"},
 {"id_Cbo":"1663", "codigo":"632330", "titulo":"Trabalhador da exploracao de coco-da-praia"},
 {"id_Cbo":"1664", "codigo":"632335", "titulo":"Trabalhador da exploracao de copaiba"},
 {"id_Cbo":"1665", "codigo":"632340", "titulo":"Trabalhador da exploracao de malva (paina)"},
 {"id_Cbo":"1666", "codigo":"632345", "titulo":"Trabalhador da exploracao de murumuru"},
 {"id_Cbo":"1667", "codigo":"632350", "titulo":"Trabalhador da exploracao de oiticica"},
 {"id_Cbo":"1668", "codigo":"632355", "titulo":"Trabalhador da exploracao de ouricuri"},
 {"id_Cbo":"1669", "codigo":"632360", "titulo":"Trabalhador da exploracao de pequi"},
 {"id_Cbo":"1670", "codigo":"632365", "titulo":"Trabalhador da exploracao de piacava"},
 {"id_Cbo":"1671", "codigo":"632370", "titulo":"Trabalhador da exploracao de tucum"},
 {"id_Cbo":"1672", "codigo":"632405", "titulo":"Trabalhador da exploracao de acai"},
 {"id_Cbo":"1673", "codigo":"632410", "titulo":"Trabalhador da exploracao de castanha"},
 {"id_Cbo":"1674", "codigo":"632415", "titulo":"Trabalhador da exploracao de pinhao"},
 {"id_Cbo":"1675", "codigo":"632420", "titulo":"Trabalhador da exploracao de pupunha"},
 {"id_Cbo":"1676", "codigo":"632505", "titulo":"Trabalhador da exploracao de arvores e arbustos produtores de substancias aromat., Medic. E toxicas"},
 {"id_Cbo":"1677", "codigo":"632510", "titulo":"Trabalhador da exploracao de cipos produtores de substancias aromaticas, medicinais e toxicas"},
 {"id_Cbo":"1678", "codigo":"632515", "titulo":"Trabalhador da exploracao de madeiras tanantes"},
 {"id_Cbo":"1679", "codigo":"632520", "titulo":"Trabalhador da exploracao de raizes produtoras de substancias aromaticas, medicinais e toxicas"},
 {"id_Cbo":"1680", "codigo":"632525", "titulo":"Trabalhador da extracao de substancias aromaticas, medicinais e toxicas, em geral"},
 {"id_Cbo":"1681", "codigo":"632605", "titulo":"Carvoeiro"},
 {"id_Cbo":"1682", "codigo":"632610", "titulo":"Carbonizador"},
 {"id_Cbo":"1683", "codigo":"632615", "titulo":"Ajudante de carvoaria"},
 {"id_Cbo":"1684", "codigo":"641005", "titulo":"Operador de colheitadeira"},
 {"id_Cbo":"1685", "codigo":"641010", "titulo":"Operador de maquinas de beneficiamento de produtos agricolas"},
 {"id_Cbo":"1686", "codigo":"641015", "titulo":"Tratorista agricola"},
 {"id_Cbo":"1687", "codigo":"642005", "titulo":"Operador de colhedor florestal"},
 {"id_Cbo":"1688", "codigo":"642010", "titulo":"Operador de maquinas florestais estaticas"},
 {"id_Cbo":"1689", "codigo":"642015", "titulo":"Operador de trator florestal"},
 {"id_Cbo":"1690", "codigo":"643005", "titulo":"Trabalhador na operacao de sistema de irrigacao localizada (microaspersao e gotejamento)"},
 {"id_Cbo":"1691", "codigo":"643010", "titulo":"Trabalhador na operacao de sistema de irrigacao por aspersao (pivo central)"},
 {"id_Cbo":"1692", "codigo":"643015", "titulo":"Trabalhador na operacao de sistemas convencionais de irrigacao por aspersao"},
 {"id_Cbo":"1693", "codigo":"643020", "titulo":"Trabalhador na operacao de sistemas de irrigacao e aspersao (alto propelido)"},
 {"id_Cbo":"1694", "codigo":"643025", "titulo":"Trabalhador na operacao de sistemas de irrigacao por superficie e drenagem"},
 {"id_Cbo":"1695", "codigo":"710105", "titulo":"Supervisor de apoio operacional na mineracao"},
 {"id_Cbo":"1696", "codigo":"710110", "titulo":"Supervisor de extracao de sal"},
 {"id_Cbo":"1697", "codigo":"710115", "titulo":"Supervisor de perfuracao e desmonte"},
 {"id_Cbo":"1698", "codigo":"710120", "titulo":"Supervisor de producao na mineracao"},
 {"id_Cbo":"1699", "codigo":"710125", "titulo":"Supervisor de transporte na mineracao"},
 {"id_Cbo":"1700", "codigo":"710205", "titulo":"Mestre (construcao civil)"},
 {"id_Cbo":"1701", "codigo":"710210", "titulo":"Mestre de linhas (ferrovias)"},
 {"id_Cbo":"1702", "codigo":"710215", "titulo":"Inspetor de terraplenagem"},
 {"id_Cbo":"1703", "codigo":"710220", "titulo":"Supervisor de usina de concreto"},
 {"id_Cbo":"1704", "codigo":"710225", "titulo":"Fiscal de patio de usina de concreto"},
 {"id_Cbo":"1705", "codigo":"711105", "titulo":"Amostrador de minerios"},
 {"id_Cbo":"1706", "codigo":"711110", "titulo":"Canteiro"},
 {"id_Cbo":"1707", "codigo":"711115", "titulo":"Destrocador de pedra"},
 {"id_Cbo":"1708", "codigo":"711120", "titulo":"Detonador"},
 {"id_Cbo":"1709", "codigo":"711125", "titulo":"Escorador de minas"},
 {"id_Cbo":"1710", "codigo":"711130", "titulo":"Mineiro"},
 {"id_Cbo":"1711", "codigo":"711205", "titulo":"Operador de caminhao (minas e pedreiras)"},
 {"id_Cbo":"1712", "codigo":"711210", "titulo":"Operador de carregadeira"},
 {"id_Cbo":"1713", "codigo":"711215", "titulo":"Operador de maquina cortadora (minas e pedreiras)"},
 {"id_Cbo":"1714", "codigo":"711220", "titulo":"Operador de maquina de extracao continua (minas de carvao)"},
 {"id_Cbo":"1715", "codigo":"711225", "titulo":"Operador de maquina perfuradora (minas e pedreiras)"},
 {"id_Cbo":"1716", "codigo":"711230", "titulo":"Operador de maquina perfuratriz"},
 {"id_Cbo":"1717", "codigo":"711235", "titulo":"Operador de motoniveladora (extracao de minerais solidos)"},
 {"id_Cbo":"1718", "codigo":"711240", "titulo":"Operador de schutthecar"},
 {"id_Cbo":"1719", "codigo":"711245", "titulo":"Operador de trator (minas e pedreiras)"},
 {"id_Cbo":"1720", "codigo":"711305", "titulo":"Operador de sonda de percussao"},
 {"id_Cbo":"1721", "codigo":"711310", "titulo":"Operador de sonda rotativa"},
 {"id_Cbo":"1722", "codigo":"711315", "titulo":"Sondador (pocos de petroleo e gas)"},
 {"id_Cbo":"1723", "codigo":"711320", "titulo":"Sondador de pocos (exceto de petroleo e gas)"},
 {"id_Cbo":"1724", "codigo":"711325", "titulo":"Plataformista (petroleo)"},
 {"id_Cbo":"1725", "codigo":"711330", "titulo":"Torrista (petroleo)"},
 {"id_Cbo":"1726", "codigo":"711405", "titulo":"Garimpeiro"},
 {"id_Cbo":"1727", "codigo":"711410", "titulo":"Operador de salina (sal marinho)"},
 {"id_Cbo":"1728", "codigo":"712105", "titulo":"Moleiro de minerios"},
 {"id_Cbo":"1729", "codigo":"712110", "titulo":"Operador de aparelho de flotacao"},
 {"id_Cbo":"1730", "codigo":"712115", "titulo":"Operador de aparelho de precipitacao (minas de ouro ou prata)"},
 {"id_Cbo":"1731", "codigo":"712120", "titulo":"Operador de britador de mandibulas"},
 {"id_Cbo":"1732", "codigo":"712125", "titulo":"Operador de espessador"},
 {"id_Cbo":"1733", "codigo":"712130", "titulo":"Operador de jig (minas)"},
 {"id_Cbo":"1734", "codigo":"712135", "titulo":"Operador de peneiras hidraulicas"},
 {"id_Cbo":"1735", "codigo":"712205", "titulo":"Cortador de pedras"},
 {"id_Cbo":"1736", "codigo":"712210", "titulo":"Gravador de inscricoes em pedra"},
 {"id_Cbo":"1737", "codigo":"712215", "titulo":"Gravador de relevos em pedra"},
 {"id_Cbo":"1738", "codigo":"712220", "titulo":"Polidor de pedras"},
 {"id_Cbo":"1739", "codigo":"712225", "titulo":"Torneiro (lavra de pedra)"},
 {"id_Cbo":"1740", "codigo":"712230", "titulo":"Tracador de pedras"},
 {"id_Cbo":"1741", "codigo":"715105", "titulo":"Operador de bate-estacas"},
 {"id_Cbo":"1742", "codigo":"715110", "titulo":"Operador de compactadora de solos"},
 {"id_Cbo":"1743", "codigo":"715115", "titulo":"Operador de escavadeira"},
 {"id_Cbo":"1744", "codigo":"715120", "titulo":"Operador de maquina de abrir valas"},
 {"id_Cbo":"1745", "codigo":"715125", "titulo":"Operador de maquinas de construcao civil e mineracao"},
 {"id_Cbo":"1746", "codigo":"715130", "titulo":"Operador de motoniveladora"},
 {"id_Cbo":"1747", "codigo":"715135", "titulo":"Operador de pa carregadeira"},
 {"id_Cbo":"1748", "codigo":"715140", "titulo":"Operador de pavimentadora (asfalto, concreto e materiais similares)"},
 {"id_Cbo":"1749", "codigo":"715145", "titulo":"Operador de trator de lamina"},
 {"id_Cbo":"1750", "codigo":"715205", "titulo":"Calceteiro"},
 {"id_Cbo":"1751", "codigo":"715210", "titulo":"Pedreiro"},
 {"id_Cbo":"1752", "codigo":"715215", "titulo":"Pedreiro (chamines industriais)"},
 {"id_Cbo":"1753", "codigo":"715220", "titulo":"Pedreiro (material refratario)"},
 {"id_Cbo":"1754", "codigo":"715225", "titulo":"Pedreiro (mineracao)"},
 {"id_Cbo":"1755", "codigo":"715230", "titulo":"Pedreiro de edificacoes"},
 {"id_Cbo":"1756", "codigo":"715305", "titulo":"Armador de estrutura de concreto"},
 {"id_Cbo":"1757", "codigo":"715310", "titulo":"Moldador de corpos de prova em usinas de concreto"},
 {"id_Cbo":"1758", "codigo":"715315", "titulo":"Armador de estrutura de concreto armado"},
 {"id_Cbo":"1759", "codigo":"715405", "titulo":"Operador de betoneira"},
 {"id_Cbo":"1760", "codigo":"715410", "titulo":"Operador de bomba de concreto"},
 {"id_Cbo":"1761", "codigo":"715415", "titulo":"Operador de central de concreto"},
 {"id_Cbo":"1762", "codigo":"715505", "titulo":"Carpinteiro"},
 {"id_Cbo":"1763", "codigo":"715510", "titulo":"Carpinteiro (esquadrias)"},
 {"id_Cbo":"1764", "codigo":"715515", "titulo":"Carpinteiro (cenarios)"},
 {"id_Cbo":"1765", "codigo":"715520", "titulo":"Carpinteiro (mineracao)"},
 {"id_Cbo":"1766", "codigo":"715525", "titulo":"Carpinteiro de obras"},
 {"id_Cbo":"1767", "codigo":"715530", "titulo":"Carpinteiro (telhados)"},
 {"id_Cbo":"1768", "codigo":"715535", "titulo":"Carpinteiro de formas para concreto"},
 {"id_Cbo":"1769", "codigo":"715540", "titulo":"Carpinteiro de obras civis de arte (pontes, tuneis, barragens)"},
 {"id_Cbo":"1770", "codigo":"715545", "titulo":"Montador de andaimes (edificacoes)"},
 {"id_Cbo":"1771", "codigo":"715605", "titulo":"Eletricista de instalacoes (cenarios)"},
 {"id_Cbo":"1772", "codigo":"715610", "titulo":"Eletricista de instalacoes (edificios)"},
 {"id_Cbo":"1773", "codigo":"715615", "titulo":"Eletricista de instalacoes"},
 {"id_Cbo":"1774", "codigo":"715705", "titulo":"Aplicador de asfalto impermeabilizante (coberturas)"},
 {"id_Cbo":"1775", "codigo":"715710", "titulo":"Instalador de isolantes acusticos"},
 {"id_Cbo":"1776", "codigo":"715715", "titulo":"Instalador de isolantes termicos (refrigeracao e climatizacao)"},
 {"id_Cbo":"1777", "codigo":"715720", "titulo":"Instalador de isolantes termicos de caldeira e tubulacoes"},
 {"id_Cbo":"1778", "codigo":"715725", "titulo":"Instalador de material isolante, a mao (edificacoes)"},
 {"id_Cbo":"1779", "codigo":"715730", "titulo":"Instalador de material isolante, a maquina (edificacoes)"},
 {"id_Cbo":"1780", "codigo":"716105", "titulo":"Acabador de superficies de concreto"},
 {"id_Cbo":"1781", "codigo":"716110", "titulo":"Revestidor de superficies de concreto"},
 {"id_Cbo":"1782", "codigo":"716205", "titulo":"Telhador (telhas de argila e materiais similares)"},
 {"id_Cbo":"1783", "codigo":"716210", "titulo":"Telhador (telhas de cimento-amianto)"},
 {"id_Cbo":"1784", "codigo":"716215", "titulo":"Telhador (telhas metalicas)"},
 {"id_Cbo":"1785", "codigo":"716220", "titulo":"Telhador (telhas plasticas)"},
 {"id_Cbo":"1786", "codigo":"716305", "titulo":"Vidraceiro"},
 {"id_Cbo":"1787", "codigo":"716310", "titulo":"Vidraceiro (edificacoes)"},
 {"id_Cbo":"1788", "codigo":"716315", "titulo":"Vidraceiro (vitrais)"},
 {"id_Cbo":"1789", "codigo":"716405", "titulo":"Gesseiro"},
 {"id_Cbo":"1790", "codigo":"716505", "titulo":"Assoalhador"},
 {"id_Cbo":"1791", "codigo":"716510", "titulo":"Assentador de revestimentos ceramicos"},
 {"id_Cbo":"1792", "codigo":"716515", "titulo":"Pastilheiro"},
 {"id_Cbo":"1793", "codigo":"716520", "titulo":"Lustrador de piso"},
 {"id_Cbo":"1794", "codigo":"716525", "titulo":"Marmorista (construcao)"},
 {"id_Cbo":"1795", "codigo":"716530", "titulo":"Mosaista"},
 {"id_Cbo":"1796", "codigo":"716535", "titulo":"Taqueiro"},
 {"id_Cbo":"1797", "codigo":"716540", "titulo":"Rejuntador de revestimentos"},
 {"id_Cbo":"1798", "codigo":"716605", "titulo":"Calafetador"},
 {"id_Cbo":"1799", "codigo":"716610", "titulo":"Pintor de obras"},
 {"id_Cbo":"1800", "codigo":"716615", "titulo":"Revestidor de interiores (papel, material plastico e emborrachados)"},
 {"id_Cbo":"1801", "codigo":"717005", "titulo":"Demolidor de edificacoes"},
 {"id_Cbo":"1802", "codigo":"717010", "titulo":"Operador de martelete"},
 {"id_Cbo":"1803", "codigo":"717015", "titulo":"Poceiro (edificacoes)"},
 {"id_Cbo":"1804", "codigo":"717020", "titulo":"Servente de obras"},
 {"id_Cbo":"1805", "codigo":"717025", "titulo":"Vibradorista"},
 {"id_Cbo":"1806", "codigo":"720105", "titulo":"Mestre (afiador de ferramentas)"},
 {"id_Cbo":"1807", "codigo":"720110", "titulo":"Mestre de caldeiraria"},
 {"id_Cbo":"1808", "codigo":"720115", "titulo":"Mestre de ferramentaria"},
 {"id_Cbo":"1809", "codigo":"720120", "titulo":"Mestre de forjaria"},
 {"id_Cbo":"1810", "codigo":"720125", "titulo":"Mestre de fundicao"},
 {"id_Cbo":"1811", "codigo":"720130", "titulo":"Mestre de galvanoplastia"},
 {"id_Cbo":"1812", "codigo":"720135", "titulo":"Mestre de pintura (tratamento de superficies)"},
 {"id_Cbo":"1813", "codigo":"720140", "titulo":"Mestre de soldagem"},
 {"id_Cbo":"1814", "codigo":"720145", "titulo":"Mestre de trefilacao de metais"},
 {"id_Cbo":"1815", "codigo":"720150", "titulo":"Mestre de usinagem"},
 {"id_Cbo":"1816", "codigo":"720155", "titulo":"Mestre serralheiro"},
 {"id_Cbo":"1817", "codigo":"720160", "titulo":"Supervisor de controle de tratamento termico"},
 {"id_Cbo":"1818", "codigo":"720205", "titulo":"Mestre (construcao naval)"},
 {"id_Cbo":"1819", "codigo":"720210", "titulo":"Mestre (industria de automotores e material de transportes)"},
 {"id_Cbo":"1820", "codigo":"720215", "titulo":"Mestre (industria de maquinas e outros equipamentos mecanicos)"},
 {"id_Cbo":"1821", "codigo":"720220", "titulo":"Mestre de construcao de fornos"},
 {"id_Cbo":"1822", "codigo":"721105", "titulo":"Ferramenteiro"},
 {"id_Cbo":"1823", "codigo":"721110", "titulo":"Ferramenteiro de mandris, calibradores e outros dispositivos"},
 {"id_Cbo":"1824", "codigo":"721115", "titulo":"Modelador de metais (fundicao)"},
 {"id_Cbo":"1825", "codigo":"721205", "titulo":"Operador de maquina de eletroerosao"},
 {"id_Cbo":"1826", "codigo":"721210", "titulo":"Operador de maquinas operatrizes"},
 {"id_Cbo":"1827", "codigo":"721215", "titulo":"Operador de maquinas-ferramenta convencionais"},
 {"id_Cbo":"1828", "codigo":"721220", "titulo":"Operador de usinagem convencional por abrasao"},
 {"id_Cbo":"1829", "codigo":"721225", "titulo":"Preparador de maquinas-ferramenta"},
 {"id_Cbo":"1830", "codigo":"721305", "titulo":"Afiador de cardas"},
 {"id_Cbo":"1831", "codigo":"721310", "titulo":"Afiador de cutelaria"},
 {"id_Cbo":"1832", "codigo":"721315", "titulo":"Afiador de ferramentas"},
 {"id_Cbo":"1833", "codigo":"721320", "titulo":"Afiador de serras"},
 {"id_Cbo":"1834", "codigo":"721325", "titulo":"Polidor de metais"},
 {"id_Cbo":"1835", "codigo":"721405", "titulo":"Operador de centro de usinagem com comando numerico"},
 {"id_Cbo":"1836", "codigo":"721410", "titulo":"Operador de fresadora com comando numerico"},
 {"id_Cbo":"1837", "codigo":"721415", "titulo":"Operador de mandriladora com comando numerico"},
 {"id_Cbo":"1838", "codigo":"721420", "titulo":"Operador de maquina eletroerosao, a fio, com comando numerico"},
 {"id_Cbo":"1839", "codigo":"721425", "titulo":"Operador de retificadora com comando numerico"},
 {"id_Cbo":"1840", "codigo":"721430", "titulo":"Operador de torno com comando numerico"},
 {"id_Cbo":"1841", "codigo":"722105", "titulo":"Forjador"},
 {"id_Cbo":"1842", "codigo":"722110", "titulo":"Forjador a martelo"},
 {"id_Cbo":"1843", "codigo":"722115", "titulo":"Forjador prensista"},
 {"id_Cbo":"1844", "codigo":"722205", "titulo":"Fundidor de metais"},
 {"id_Cbo":"1845", "codigo":"722210", "titulo":"Lingotador"},
 {"id_Cbo":"1846", "codigo":"722215", "titulo":"Operador de acabamento de pecas fundidas"},
 {"id_Cbo":"1847", "codigo":"722220", "titulo":"Operador de maquina centrifugadora de fundicao"},
 {"id_Cbo":"1848", "codigo":"722225", "titulo":"Operador de maquina de fundir sob pressao"},
 {"id_Cbo":"1849", "codigo":"722230", "titulo":"Operador de vazamento (lingotamento)"},
 {"id_Cbo":"1850", "codigo":"722235", "titulo":"Preparador de panelas (lingotamento)"},
 {"id_Cbo":"1851", "codigo":"722305", "titulo":"Macheiro, a mao"},
 {"id_Cbo":"1852", "codigo":"722310", "titulo":"Macheiro, a  maquina"},
 {"id_Cbo":"1853", "codigo":"722315", "titulo":"Moldador, a  mao"},
 {"id_Cbo":"1854", "codigo":"722320", "titulo":"Moldador, a  maquina"},
 {"id_Cbo":"1855", "codigo":"722325", "titulo":"Operador de equipamentos de preparacao de areia"},
 {"id_Cbo":"1856", "codigo":"722330", "titulo":"Operador de maquina de moldar automatizada"},
 {"id_Cbo":"1857", "codigo":"722405", "titulo":"Cableador"},
 {"id_Cbo":"1858", "codigo":"722410", "titulo":"Estirador de tubos de metal sem costura"},
 {"id_Cbo":"1859", "codigo":"722415", "titulo":"Trefilador de metais, a maquina"},
 {"id_Cbo":"1860", "codigo":"723105", "titulo":"Cementador de metais"},
 {"id_Cbo":"1861", "codigo":"723110", "titulo":"Normalizador de metais e de compositos"},
 {"id_Cbo":"1862", "codigo":"723115", "titulo":"Operador de equipamento para resfriamento"},
 {"id_Cbo":"1863", "codigo":"723120", "titulo":"Operador de forno de tratamento termico de metais"},
 {"id_Cbo":"1864", "codigo":"723125", "titulo":"Temperador de metais e de compositos"},
 {"id_Cbo":"1865", "codigo":"723205", "titulo":"Decapador"},
 {"id_Cbo":"1866", "codigo":"723210", "titulo":"Fosfatizador"},
 {"id_Cbo":"1867", "codigo":"723215", "titulo":"Galvanizador"},
 {"id_Cbo":"1868", "codigo":"723220", "titulo":"Metalizador a pistola"},
 {"id_Cbo":"1869", "codigo":"723225", "titulo":"Metalizador (banho quente)"},
 {"id_Cbo":"1870", "codigo":"723230", "titulo":"Operador de maquina recobridora de arame"},
 {"id_Cbo":"1871", "codigo":"723235", "titulo":"Operador de zincagem (processo eletrolitico)"},
 {"id_Cbo":"1872", "codigo":"723240", "titulo":"Oxidador"},
 {"id_Cbo":"1873", "codigo":"723305", "titulo":"Operador de equipamento de secagem de pintura"},
 {"id_Cbo":"1874", "codigo":"723310", "titulo":"Pintor a pincel e rolo (exceto obras e estruturas metalicas)"},
 {"id_Cbo":"1875", "codigo":"723315", "titulo":"Pintor de estruturas metalicas"},
 {"id_Cbo":"1876", "codigo":"723320", "titulo":"Pintor de veiculos (fabricacao)"},
 {"id_Cbo":"1877", "codigo":"723325", "titulo":"Pintor por imersao"},
 {"id_Cbo":"1878", "codigo":"723330", "titulo":"Pintor, a  pistola (exceto obras e estruturas metalicas)"},
 {"id_Cbo":"1879", "codigo":"724105", "titulo":"Assentador de canalizacao (edificacoes)"},
 {"id_Cbo":"1880", "codigo":"724110", "titulo":"Encanador"},
 {"id_Cbo":"1881", "codigo":"724115", "titulo":"Instalador de tubulacoes"},
 {"id_Cbo":"1882", "codigo":"724120", "titulo":"Instalador de tubulacoes (aeronaves)"},
 {"id_Cbo":"1883", "codigo":"724125", "titulo":"Instalador de tubulacoes (embarcacoes)"},
 {"id_Cbo":"1884", "codigo":"724130", "titulo":"Instalador de tubulacoes de gas combustivel (producao e distribuicao)"},
 {"id_Cbo":"1885", "codigo":"724135", "titulo":"Instalador de tubulacoes de vapor (producao e distribuicao)"},
 {"id_Cbo":"1886", "codigo":"724205", "titulo":"Montador de estruturas metalicas"},
 {"id_Cbo":"1887", "codigo":"724210", "titulo":"Montador de estruturas metalicas de embarcacoes"},
 {"id_Cbo":"1888", "codigo":"724215", "titulo":"Rebitador a  martelo pneumatico"},
 {"id_Cbo":"1889", "codigo":"724220", "titulo":"Preparador de estruturas metalicas"},
 {"id_Cbo":"1890", "codigo":"724225", "titulo":"Riscador de estruturas metalicas"},
 {"id_Cbo":"1891", "codigo":"724230", "titulo":"Rebitador, a  mao"},
 {"id_Cbo":"1892", "codigo":"724305", "titulo":"Brasador"},
 {"id_Cbo":"1893", "codigo":"724310", "titulo":"Oxicortador a mao e a  maquina"},
 {"id_Cbo":"1894", "codigo":"724315", "titulo":"Soldador"},
 {"id_Cbo":"1895", "codigo":"724320", "titulo":"Soldador a  oxigas"},
 {"id_Cbo":"1896", "codigo":"724325", "titulo":"Soldador eletrico"},
 {"id_Cbo":"1897", "codigo":"724405", "titulo":"Caldeireiro (chapas de cobre)"},
 {"id_Cbo":"1898", "codigo":"724410", "titulo":"Caldeireiro (chapas de ferro e aco)"},
 {"id_Cbo":"1899", "codigo":"724415", "titulo":"Chapeador"},
 {"id_Cbo":"1900", "codigo":"724420", "titulo":"Chapeador de carrocerias metalicas (fabricacao)"},
 {"id_Cbo":"1901", "codigo":"724425", "titulo":"Chapeador naval"},
 {"id_Cbo":"1902", "codigo":"724430", "titulo":"Chapeador de aeronaves"},
 {"id_Cbo":"1903", "codigo":"724435", "titulo":"Funileiro industrial"},
 {"id_Cbo":"1904", "codigo":"724440", "titulo":"Serralheiro"},
 {"id_Cbo":"1905", "codigo":"724505", "titulo":"Operador de maquina de cilindrar chapas"},
 {"id_Cbo":"1906", "codigo":"724510", "titulo":"Operador de maquina de dobrar chapas"},
 {"id_Cbo":"1907", "codigo":"724515", "titulo":"Prensista (operador de prensa)"},
 {"id_Cbo":"1908", "codigo":"724605", "titulo":"Operador de lacos de cabos de aco"},
 {"id_Cbo":"1909", "codigo":"724610", "titulo":"Trancador de cabos de aco"},
 {"id_Cbo":"1910", "codigo":"725005", "titulo":"Ajustador ferramenteiro"},
 {"id_Cbo":"1911", "codigo":"725010", "titulo":"Ajustador mecanico"},
 {"id_Cbo":"1912", "codigo":"725015", "titulo":"Ajustador mecanico (usinagem em bancada e em maquinas-ferramentas)"},
 {"id_Cbo":"1913", "codigo":"725020", "titulo":"Ajustador mecanico em bancada"},
 {"id_Cbo":"1914", "codigo":"725025", "titulo":"Ajustador naval (reparo e construcao)"},
 {"id_Cbo":"1915", "codigo":"725105", "titulo":"Montador de maquinas, motores e acessorios (montagem em serie)"},
 {"id_Cbo":"1916", "codigo":"725205", "titulo":"Montador de maquinas"},
 {"id_Cbo":"1917", "codigo":"725210", "titulo":"Montador de maquinas graficas"},
 {"id_Cbo":"1918", "codigo":"725215", "titulo":"Montador de maquinas operatrizes para madeira"},
 {"id_Cbo":"1919", "codigo":"725220", "titulo":"Montador de maquinas texteis"},
 {"id_Cbo":"1920", "codigo":"725225", "titulo":"Montador de maquinas-ferramentas (usinagem de metais)"},
 {"id_Cbo":"1921", "codigo":"725305", "titulo":"Montador de equipamento de levantamento"},
 {"id_Cbo":"1922", "codigo":"725310", "titulo":"Montador de maquinas agricolas"},
 {"id_Cbo":"1923", "codigo":"725315", "titulo":"Montador de maquinas de minas e pedreiras"},
 {"id_Cbo":"1924", "codigo":"725320", "titulo":"Montador de maquinas de terraplenagem"},
 {"id_Cbo":"1925", "codigo":"725405", "titulo":"Mecanico montador de motores de aeronaves"},
 {"id_Cbo":"1926", "codigo":"725410", "titulo":"Mecanico montador de motores de embarcacoes"},
 {"id_Cbo":"1927", "codigo":"725415", "titulo":"Mecanico montador de motores de explosao e diesel"},
 {"id_Cbo":"1928", "codigo":"725420", "titulo":"Mecanico montador de turboalimentadores"},
 {"id_Cbo":"1929", "codigo":"725505", "titulo":"Montador de veiculos (linha de montagem)"},
 {"id_Cbo":"1930", "codigo":"725510", "titulo":"Operador de time de montagem"},
 {"id_Cbo":"1931", "codigo":"725605", "titulo":"Montador de estruturas de aeronaves"},
 {"id_Cbo":"1932", "codigo":"725610", "titulo":"Montador de sistemas de combustivel de aeronaves"},
 {"id_Cbo":"1933", "codigo":"725705", "titulo":"Mecanico de refrigeracao"},
 {"id_Cbo":"1934", "codigo":"730105", "titulo":"Supervisor de montagem e instalacao eletroeletronica"},
 {"id_Cbo":"1935", "codigo":"731105", "titulo":"Montador de equipamentos eletronicos (aparelhos medicos)"},
 {"id_Cbo":"1936", "codigo":"731110", "titulo":"Montador de equipamentos eletronicos (computadores e equipamentos auxiliares)"},
 {"id_Cbo":"1937", "codigo":"731115", "titulo":"Montador de equipamentos eletricos (instrumentos de medicao)"},
 {"id_Cbo":"1938", "codigo":"731120", "titulo":"Montador de equipamentos eletricos (aparelhos eletrodomesticos)"},
 {"id_Cbo":"1939", "codigo":"731125", "titulo":"Montador de equipamentos eletricos (centrais eletricas)"},
 {"id_Cbo":"1940", "codigo":"731130", "titulo":"Montador de equipamentos eletricos (motores e dinamos)"},
 {"id_Cbo":"1941", "codigo":"731135", "titulo":"Montador de equipamentos eletricos"},
 {"id_Cbo":"1942", "codigo":"731140", "titulo":"Montador de equipamentos eletronicos (instalacoes de sinalizacao)"},
 {"id_Cbo":"1943", "codigo":"731145", "titulo":"Montador de equipamentos eletronicos (maquinas industriais)"},
 {"id_Cbo":"1944", "codigo":"731150", "titulo":"Montador de equipamentos eletronicos"},
 {"id_Cbo":"1945", "codigo":"731155", "titulo":"Montador de equipamentos eletricos (elevadores e equipamentos similares)"},
 {"id_Cbo":"1946", "codigo":"731160", "titulo":"Montador de equipamentos eletricos (transformadores)"},
 {"id_Cbo":"1947", "codigo":"731165", "titulo":"Bobinador eletricista, a mao"},
 {"id_Cbo":"1948", "codigo":"731170", "titulo":"Bobinador eletricista, a maquina"},
 {"id_Cbo":"1949", "codigo":"731175", "titulo":"Operador de linha de montagem (aparelhos eletricos)"},
 {"id_Cbo":"1950", "codigo":"731180", "titulo":"Operador de linha de montagem (aparelhos eletronicos)"},
 {"id_Cbo":"1951", "codigo":"731205", "titulo":"Montador de equipamentos eletronicos (estacao de radio, tv e equipamentos de radar)"},
 {"id_Cbo":"1952", "codigo":"731305", "titulo":"Instalador-reparador de equipamentos de comutacao em telefonia"},
 {"id_Cbo":"1953", "codigo":"731310", "titulo":"Instalador-reparador de equipamentos de energia em telefonia"},
 {"id_Cbo":"1954", "codigo":"731315", "titulo":"Instalador-reparador de equipamentos de transmissao em telefonia"},
 {"id_Cbo":"1955", "codigo":"731320", "titulo":"Instalador-reparador de linhas e aparelhos de telecomunicacoes"},
 {"id_Cbo":"1956", "codigo":"731325", "titulo":"Instalador-reparador de redes e cabos telefonicos"},
 {"id_Cbo":"1957", "codigo":"731330", "titulo":"Reparador de aparelhos de telecomunicacoes em laboratorio"},
 {"id_Cbo":"1958", "codigo":"732105", "titulo":"Eletricista de manutencao de linhas eletricas, telefonicas e de comunicacao de dados"},
 {"id_Cbo":"1959", "codigo":"732110", "titulo":"Emendador de cabos eletricos e telefonicos (aereos e subterraneos)"},
 {"id_Cbo":"1960", "codigo":"732115", "titulo":"Examinador de cabos, linhas eletricas e telefonicas"},
 {"id_Cbo":"1961", "codigo":"732120", "titulo":"Instalador de linhas eletricas de alta e baixa - tensao (rede aerea e subterranea)"},
 {"id_Cbo":"1962", "codigo":"732125", "titulo":"Instalador eletricista (tracao de veiculos)"},
 {"id_Cbo":"1963", "codigo":"732130", "titulo":"Instalador-reparador de redes telefonicas e de comunicacao de dados"},
 {"id_Cbo":"1964", "codigo":"732135", "titulo":"Ligador de linhas telefonicas"},
 {"id_Cbo":"1965", "codigo":"732140", "titulo":"Instalador de sistemas fotovoltaicos"},
 {"id_Cbo":"1966", "codigo":"740105", "titulo":"Supervisor da mecanica de precisao"},
 {"id_Cbo":"1967", "codigo":"740110", "titulo":"Supervisor de fabricacao de instrumentos musicais"},
 {"id_Cbo":"1968", "codigo":"741105", "titulo":"Ajustador de instrumentos de precisao"},
 {"id_Cbo":"1969", "codigo":"741110", "titulo":"Montador de instrumentos de optica"},
 {"id_Cbo":"1970", "codigo":"741115", "titulo":"Montador de instrumentos de precisao"},
 {"id_Cbo":"1971", "codigo":"741120", "titulo":"Relojoeiro (fabricacao)"},
 {"id_Cbo":"1972", "codigo":"741125", "titulo":"Relojoeiro (reparacao)"},
 {"id_Cbo":"1973", "codigo":"742105", "titulo":"Afinador de instrumentos musicais"},
 {"id_Cbo":"1974", "codigo":"742110", "titulo":"Confeccionador de acordeao"},
 {"id_Cbo":"1975", "codigo":"742115", "titulo":"Confeccionador de instrumentos de corda"},
 {"id_Cbo":"1976", "codigo":"742120", "titulo":"Confeccionador de instrumentos de percussao (pele, couro ou plastico)"},
 {"id_Cbo":"1977", "codigo":"742125", "titulo":"Confeccionador de instrumentos de sopro (madeira)"},
 {"id_Cbo":"1978", "codigo":"742130", "titulo":"Confeccionador de instrumentos de sopro (metal)"},
 {"id_Cbo":"1979", "codigo":"742135", "titulo":"Confeccionador de orgao"},
 {"id_Cbo":"1980", "codigo":"742140", "titulo":"Confeccionador de piano"},
 {"id_Cbo":"1981", "codigo":"750105", "titulo":"Supervisor de joalheria"},
 {"id_Cbo":"1982", "codigo":"750205", "titulo":"Supervisor da industria de minerais nao metalicos (exceto os derivados de petroleo e carvao)"},
 {"id_Cbo":"1983", "codigo":"751005", "titulo":"Engastador (joias)"},
 {"id_Cbo":"1984", "codigo":"751010", "titulo":"Joalheiro"},
 {"id_Cbo":"1985", "codigo":"751015", "titulo":"Joalheiro (reparacoes)"},
 {"id_Cbo":"1986", "codigo":"751020", "titulo":"Lapidador (joias)"},
 {"id_Cbo":"1987", "codigo":"751105", "titulo":"Bate-folha a  maquina"},
 {"id_Cbo":"1988", "codigo":"751110", "titulo":"Fundidor (joalheria e ourivesaria)"},
 {"id_Cbo":"1989", "codigo":"751115", "titulo":"Gravador (joalheria e ourivesaria)"},
 {"id_Cbo":"1990", "codigo":"751120", "titulo":"Laminador de metais preciosos a  mao"},
 {"id_Cbo":"1991", "codigo":"751125", "titulo":"Ourives"},
 {"id_Cbo":"1992", "codigo":"751130", "titulo":"Trefilador (joalheria e ourivesaria)"},
 {"id_Cbo":"1993", "codigo":"752105", "titulo":"Artesao modelador (vidros)"},
 {"id_Cbo":"1994", "codigo":"752110", "titulo":"Moldador (vidros)"},
 {"id_Cbo":"1995", "codigo":"752115", "titulo":"Soprador de vidro"},
 {"id_Cbo":"1996", "codigo":"752120", "titulo":"Transformador de tubos de vidro"},
 {"id_Cbo":"1997", "codigo":"752205", "titulo":"Aplicador serigrafico em vidros"},
 {"id_Cbo":"1998", "codigo":"752210", "titulo":"Cortador de vidro"},
 {"id_Cbo":"1999", "codigo":"752215", "titulo":"Gravador de vidro a  agua-forte"},
 {"id_Cbo":"2000", "codigo":"752220", "titulo":"Gravador de vidro a  esmeril"},
 {"id_Cbo":"2001", "codigo":"752225", "titulo":"Gravador de vidro a  jato de areia"},
 {"id_Cbo":"2002", "codigo":"752230", "titulo":"Lapidador de vidros e cristais"},
 {"id_Cbo":"2003", "codigo":"752235", "titulo":"Surfassagista"},
 {"id_Cbo":"2004", "codigo":"752305", "titulo":"Ceramista"},
 {"id_Cbo":"2005", "codigo":"752310", "titulo":"Ceramista (torno de pedal e motor)"},
 {"id_Cbo":"2006", "codigo":"752315", "titulo":"Ceramista (torno semi-automatico)"},
 {"id_Cbo":"2007", "codigo":"752320", "titulo":"Ceramista modelador"},
 {"id_Cbo":"2008", "codigo":"752325", "titulo":"Ceramista moldador"},
 {"id_Cbo":"2009", "codigo":"752330", "titulo":"Ceramista prensador"},
 {"id_Cbo":"2010", "codigo":"752405", "titulo":"Decorador de ceramica"},
 {"id_Cbo":"2011", "codigo":"752410", "titulo":"Decorador de vidro"},
 {"id_Cbo":"2012", "codigo":"752415", "titulo":"Decorador de vidro a pincel"},
 {"id_Cbo":"2013", "codigo":"752420", "titulo":"Operador de esmaltadeira"},
 {"id_Cbo":"2014", "codigo":"752425", "titulo":"Operador de espelhamento"},
 {"id_Cbo":"2015", "codigo":"752430", "titulo":"Pintor de ceramica, a  pincel"},
 {"id_Cbo":"2016", "codigo":"760105", "titulo":"Contramestre de acabamento (industria textil)"},
 {"id_Cbo":"2017", "codigo":"760110", "titulo":"Contramestre de fiacao (industria textil)"},
 {"id_Cbo":"2018", "codigo":"760115", "titulo":"Contramestre de malharia (industria textil)"},
 {"id_Cbo":"2019", "codigo":"760120", "titulo":"Contramestre de tecelagem (industria textil)"},
 {"id_Cbo":"2020", "codigo":"760125", "titulo":"Mestre (industria textil e de confeccoes)"},
 {"id_Cbo":"2021", "codigo":"760205", "titulo":"Supervisor de curtimento"},
 {"id_Cbo":"2022", "codigo":"760305", "titulo":"Encarregado de corte na confeccao do vestuario"},
 {"id_Cbo":"2023", "codigo":"760310", "titulo":"Encarregado de costura na confeccao do vestuario"},
 {"id_Cbo":"2024", "codigo":"760405", "titulo":"Supervisor  (industria de calcados e artefatos de couro)"},
 {"id_Cbo":"2025", "codigo":"760505", "titulo":"Supervisor da confeccao de artefatos de tecidos, couros e afins"},
 {"id_Cbo":"2026", "codigo":"760605", "titulo":"Supervisor das artes graficas  (industria editorial e grafica)"},
 {"id_Cbo":"2027", "codigo":"761005", "titulo":"Operador polivalente da industria textil"},
 {"id_Cbo":"2028", "codigo":"761105", "titulo":"Classificador de fibras texteis"},
 {"id_Cbo":"2029", "codigo":"761110", "titulo":"Lavador de la"},
 {"id_Cbo":"2030", "codigo":"761205", "titulo":"Operador de abertura (fiacao)"},
 {"id_Cbo":"2031", "codigo":"761210", "titulo":"Operador de binadeira"},
 {"id_Cbo":"2032", "codigo":"761215", "titulo":"Operador de bobinadeira"},
 {"id_Cbo":"2033", "codigo":"761220", "titulo":"Operador de cardas"},
 {"id_Cbo":"2034", "codigo":"761225", "titulo":"Operador de conicaleira"},
 {"id_Cbo":"2035", "codigo":"761230", "titulo":"Operador de filatorio"},
 {"id_Cbo":"2036", "codigo":"761235", "titulo":"Operador de laminadeira e reunideira"},
 {"id_Cbo":"2037", "codigo":"761240", "titulo":"Operador de macaroqueira"},
 {"id_Cbo":"2038", "codigo":"761245", "titulo":"Operador de open-end"},
 {"id_Cbo":"2039", "codigo":"761250", "titulo":"Operador de passador (fiacao)"},
 {"id_Cbo":"2040", "codigo":"761255", "titulo":"Operador de penteadeira"},
 {"id_Cbo":"2041", "codigo":"761260", "titulo":"Operador de retorcedeira"},
 {"id_Cbo":"2042", "codigo":"761303", "titulo":"Tecelao (redes)"},
 {"id_Cbo":"2043", "codigo":"761306", "titulo":"Tecelao (rendas e bordados)"},
 {"id_Cbo":"2044", "codigo":"761309", "titulo":"Tecelao (tear automatico)"},
 {"id_Cbo":"2045", "codigo":"761312", "titulo":"Tecelao (tear jacquard)"},
 {"id_Cbo":"2046", "codigo":"761315", "titulo":"Tecelao (tear mecanico de maquineta)"},
 {"id_Cbo":"2047", "codigo":"761318", "titulo":"Tecelao (tear mecanico de xadrez)"},
 {"id_Cbo":"2048", "codigo":"761321", "titulo":"Tecelao (tear mecanico liso)"},
 {"id_Cbo":"2049", "codigo":"761324", "titulo":"Tecelao (tear mecanico, exceto jacquard)"},
 {"id_Cbo":"2050", "codigo":"761327", "titulo":"Tecelao de malhas, a  maquina"},
 {"id_Cbo":"2051", "codigo":"761330", "titulo":"Tecelao de malhas (maquina circular)"},
 {"id_Cbo":"2052", "codigo":"761333", "titulo":"Tecelao de malhas (maquina retilinea)"},
 {"id_Cbo":"2053", "codigo":"761336", "titulo":"Tecelao de meias, a  maquina"},
 {"id_Cbo":"2054", "codigo":"761339", "titulo":"Tecelao de meias (maquina circular)"},
 {"id_Cbo":"2055", "codigo":"761342", "titulo":"Tecelao de meias (maquina retilinea)"},
 {"id_Cbo":"2056", "codigo":"761345", "titulo":"Tecelao de tapetes, a  maquina"},
 {"id_Cbo":"2057", "codigo":"761348", "titulo":"Operador de engomadeira de urdume"},
 {"id_Cbo":"2058", "codigo":"761351", "titulo":"Operador de espuladeira"},
 {"id_Cbo":"2059", "codigo":"761354", "titulo":"Operador de maquina de cordoalha"},
 {"id_Cbo":"2060", "codigo":"761357", "titulo":"Operador de urdideira"},
 {"id_Cbo":"2061", "codigo":"761360", "titulo":"Passamaneiro a  maquina"},
 {"id_Cbo":"2062", "codigo":"761363", "titulo":"Remetedor de fios"},
 {"id_Cbo":"2063", "codigo":"761366", "titulo":"Picotador de cartoes jacquard"},
 {"id_Cbo":"2064", "codigo":"761405", "titulo":"Alvejador (tecidos)"},
 {"id_Cbo":"2065", "codigo":"761410", "titulo":"Estampador de tecido"},
 {"id_Cbo":"2066", "codigo":"761415", "titulo":"Operador de calandras (tecidos)"},
 {"id_Cbo":"2067", "codigo":"761420", "titulo":"Operador de chamuscadeira de tecidos"},
 {"id_Cbo":"2068", "codigo":"761425", "titulo":"Operador de impermeabilizador de tecidos"},
 {"id_Cbo":"2069", "codigo":"761430", "titulo":"Operador de maquina de lavar fios e tecidos"},
 {"id_Cbo":"2070", "codigo":"761435", "titulo":"Operador de rameuse"},
 {"id_Cbo":"2071", "codigo":"761805", "titulo":"Inspetor de estamparia (producao textil)"},
 {"id_Cbo":"2072", "codigo":"761810", "titulo":"Revisor de fios (producao textil)"},
 {"id_Cbo":"2073", "codigo":"761815", "titulo":"Revisor de tecidos acabados"},
 {"id_Cbo":"2074", "codigo":"761820", "titulo":"Revisor de tecidos crus"},
 {"id_Cbo":"2075", "codigo":"762005", "titulo":"Trabalhador polivalente do curtimento de couros e peles"},
 {"id_Cbo":"2076", "codigo":"762105", "titulo":"Classificador de peles"},
 {"id_Cbo":"2077", "codigo":"762110", "titulo":"Descarnador de couros e peles, a maquina"},
 {"id_Cbo":"2078", "codigo":"762115", "titulo":"Estirador de couros e peles (preparacao)"},
 {"id_Cbo":"2079", "codigo":"762120", "titulo":"Fuloneiro"},
 {"id_Cbo":"2080", "codigo":"762125", "titulo":"Rachador de couros e peles"},
 {"id_Cbo":"2081", "codigo":"762205", "titulo":"Curtidor (couros e peles)"},
 {"id_Cbo":"2082", "codigo":"762210", "titulo":"Classificador de couros"},
 {"id_Cbo":"2083", "codigo":"762215", "titulo":"Enxugador de couros"},
 {"id_Cbo":"2084", "codigo":"762220", "titulo":"Rebaixador de couros"},
 {"id_Cbo":"2085", "codigo":"762305", "titulo":"Estirador de couros e peles (acabamento)"},
 {"id_Cbo":"2086", "codigo":"762310", "titulo":"Fuloneiro no acabamento de couros e peles"},
 {"id_Cbo":"2087", "codigo":"762315", "titulo":"Lixador de couros e peles"},
 {"id_Cbo":"2088", "codigo":"762320", "titulo":"Matizador de couros e peles"},
 {"id_Cbo":"2089", "codigo":"762325", "titulo":"Operador de maquinas do acabamento de couros e peles"},
 {"id_Cbo":"2090", "codigo":"762330", "titulo":"Prensador de couros e peles"},
 {"id_Cbo":"2091", "codigo":"762335", "titulo":"Palecionador de couros e peles"},
 {"id_Cbo":"2092", "codigo":"762340", "titulo":"Preparador de couros curtidos"},
 {"id_Cbo":"2093", "codigo":"762345", "titulo":"Vaqueador de couros e peles"},
 {"id_Cbo":"2094", "codigo":"763005", "titulo":"Alfaiate"},
 {"id_Cbo":"2095", "codigo":"763010", "titulo":"Costureira de pecas sob encomenda"},
 {"id_Cbo":"2096", "codigo":"763015", "titulo":"Costureira de reparacao de roupas"},
 {"id_Cbo":"2097", "codigo":"763020", "titulo":"Costureiro de roupa de couro e pele"},
 {"id_Cbo":"2098", "codigo":"763105", "titulo":"Auxiliar de corte (preparacao da confeccao de roupas)"},
 {"id_Cbo":"2099", "codigo":"763110", "titulo":"Cortador de roupas"},
 {"id_Cbo":"2100", "codigo":"763115", "titulo":"Enfestador de roupas"},
 {"id_Cbo":"2101", "codigo":"763120", "titulo":"Riscador de roupas"},
 {"id_Cbo":"2102", "codigo":"763125", "titulo":"Ajudante de confeccao"},
 {"id_Cbo":"2103", "codigo":"763205", "titulo":"Costureiro de roupas de couro e pele, a  maquina na  confeccao em serie"},
 {"id_Cbo":"2104", "codigo":"763210", "titulo":"Costureiro na confeccao em serie"},
 {"id_Cbo":"2105", "codigo":"763215", "titulo":"Costureiro, a  maquina  na confeccao em serie"},
 {"id_Cbo":"2106", "codigo":"763305", "titulo":"Arrematadeira"},
 {"id_Cbo":"2107", "codigo":"763310", "titulo":"Bordador, a maquina"},
 {"id_Cbo":"2108", "codigo":"763315", "titulo":"Marcador de pecas confeccionadas para bordar"},
 {"id_Cbo":"2109", "codigo":"763320", "titulo":"Operador de maquina de costura de acabamento"},
 {"id_Cbo":"2110", "codigo":"763325", "titulo":"Passadeira de pecas confeccionadas"},
 {"id_Cbo":"2111", "codigo":"764005", "titulo":"Trabalhador polivalente da confeccao de calcados"},
 {"id_Cbo":"2112", "codigo":"764105", "titulo":"Cortador de calcados, a  maquina (exceto solas e palmilhas)"},
 {"id_Cbo":"2113", "codigo":"764110", "titulo":"Cortador de solas e palmilhas, a  maquina"},
 {"id_Cbo":"2114", "codigo":"764115", "titulo":"Preparador de calcados"},
 {"id_Cbo":"2115", "codigo":"764120", "titulo":"Preparador de solas e palmilhas"},
 {"id_Cbo":"2116", "codigo":"764205", "titulo":"Costurador de calcados, a  maquina"},
 {"id_Cbo":"2117", "codigo":"764210", "titulo":"Montador de calcados"},
 {"id_Cbo":"2118", "codigo":"764305", "titulo":"Acabador de calcados"},
 {"id_Cbo":"2119", "codigo":"765005", "titulo":"Confeccionador de artefatos de couro (exceto sapatos)"},
 {"id_Cbo":"2120", "codigo":"765010", "titulo":"Chapeleiro de senhoras"},
 {"id_Cbo":"2121", "codigo":"765015", "titulo":"Boneleiro"},
 {"id_Cbo":"2122", "codigo":"765105", "titulo":"Cortador de artefatos de couro (exceto roupas e calcados)"},
 {"id_Cbo":"2123", "codigo":"765110", "titulo":"Cortador de tapecaria"},
 {"id_Cbo":"2124", "codigo":"765205", "titulo":"Colchoeiro (confeccao de colchoes)"},
 {"id_Cbo":"2125", "codigo":"765215", "titulo":"Confeccionador de brinquedos de pano"},
 {"id_Cbo":"2126", "codigo":"765225", "titulo":"Confeccionador de velas nauticas, barracas e toldos"},
 {"id_Cbo":"2127", "codigo":"765230", "titulo":"Estofador de avioes"},
 {"id_Cbo":"2128", "codigo":"765235", "titulo":"Estofador de moveis"},
 {"id_Cbo":"2129", "codigo":"765240", "titulo":"Tapeceiro de autos"},
 {"id_Cbo":"2130", "codigo":"765310", "titulo":"Costurador de artefatos de couro, a  maquina (exceto roupas e calcados)"},
 {"id_Cbo":"2131", "codigo":"765315", "titulo":"Montador de artefatos de couro (exceto roupas e calcados)"},
 {"id_Cbo":"2132", "codigo":"765405", "titulo":"Trabalhador do acabamento de artefatos de tecidos e couros"},
 {"id_Cbo":"2133", "codigo":"766105", "titulo":"Copiador de chapa"},
 {"id_Cbo":"2134", "codigo":"766115", "titulo":"Gravador de matriz para flexografia (clicherista)"},
 {"id_Cbo":"2135", "codigo":"766120", "titulo":"Editor de texto e imagem"},
 {"id_Cbo":"2136", "codigo":"766125", "titulo":"Montador de fotolito (analogico e digital)"},
 {"id_Cbo":"2137", "codigo":"766130", "titulo":"Gravador de matriz para rotogravura (eletromecanico e quimico)"},
 {"id_Cbo":"2138", "codigo":"766135", "titulo":"Gravador de matriz calcografica"},
 {"id_Cbo":"2139", "codigo":"766140", "titulo":"Gravador de matriz serigrafica"},
 {"id_Cbo":"2140", "codigo":"766145", "titulo":"Operador de sistemas de prova (analogico e digital)"},
 {"id_Cbo":"2141", "codigo":"766150", "titulo":"Operador de processo de tratamento de imagem"},
 {"id_Cbo":"2142", "codigo":"766155", "titulo":"Programador visual grafico"},
 {"id_Cbo":"2143", "codigo":"766205", "titulo":"Impressor (serigrafia)"},
 {"id_Cbo":"2144", "codigo":"766210", "titulo":"Impressor calcografico"},
 {"id_Cbo":"2145", "codigo":"766215", "titulo":"Impressor de ofsete (plano e rotativo)"},
 {"id_Cbo":"2146", "codigo":"766220", "titulo":"Impressor de rotativa"},
 {"id_Cbo":"2147", "codigo":"766225", "titulo":"Impressor de rotogravura"},
 {"id_Cbo":"2148", "codigo":"766230", "titulo":"Impressor digital"},
 {"id_Cbo":"2149", "codigo":"766235", "titulo":"Impressor flexografico"},
 {"id_Cbo":"2150", "codigo":"766240", "titulo":"Impressor letterset"},
 {"id_Cbo":"2151", "codigo":"766245", "titulo":"Impressor tampografico"},
 {"id_Cbo":"2152", "codigo":"766250", "titulo":"Impressor tipografico"},
 {"id_Cbo":"2153", "codigo":"766305", "titulo":"Acabador de embalagens (flexiveis e cartotecnicas)"},
 {"id_Cbo":"2154", "codigo":"766310", "titulo":"Impressor de corte e vinco"},
 {"id_Cbo":"2155", "codigo":"766315", "titulo":"Operador de acabamento (industria grafica)"},
 {"id_Cbo":"2156", "codigo":"766320", "titulo":"Operador de guilhotina (corte de papel)"},
 {"id_Cbo":"2157", "codigo":"766325", "titulo":"Preparador de matrizes de corte e vinco"},
 {"id_Cbo":"2158", "codigo":"766405", "titulo":"Laboratorista fotografico"},
 {"id_Cbo":"2159", "codigo":"766410", "titulo":"Revelador de filmes fotograficos, em preto e branco"},
 {"id_Cbo":"2160", "codigo":"766415", "titulo":"Revelador de filmes fotograficos, em cores"},
 {"id_Cbo":"2161", "codigo":"766420", "titulo":"Auxiliar de radiologia (revelacao fotografica)"},
 {"id_Cbo":"2162", "codigo":"768105", "titulo":"Tecelao (tear manual)"},
 {"id_Cbo":"2163", "codigo":"768110", "titulo":"Tecelao de tapetes, a  mao"},
 {"id_Cbo":"2164", "codigo":"768115", "titulo":"Tricoteiro, a mao"},
 {"id_Cbo":"2165", "codigo":"768120", "titulo":"Redeiro"},
 {"id_Cbo":"2166", "codigo":"768125", "titulo":"Chapeleiro (chapeus de palha)"},
 {"id_Cbo":"2167", "codigo":"768130", "titulo":"Crocheteiro, a  mao"},
 {"id_Cbo":"2168", "codigo":"768205", "titulo":"Bordador, a  mao"},
 {"id_Cbo":"2169", "codigo":"768210", "titulo":"Cerzidor"},
 {"id_Cbo":"2170", "codigo":"768305", "titulo":"Artifice do couro"},
 {"id_Cbo":"2171", "codigo":"768310", "titulo":"Cortador de calcados, a  mao (exceto solas)"},
 {"id_Cbo":"2172", "codigo":"768315", "titulo":"Costurador de artefatos de couro, a  mao (exceto roupas e calcados)"},
 {"id_Cbo":"2173", "codigo":"768320", "titulo":"Sapateiro (calcados sob medida)"},
 {"id_Cbo":"2174", "codigo":"768325", "titulo":"Seleiro"},
 {"id_Cbo":"2175", "codigo":"768605", "titulo":"Tipografo"},
 {"id_Cbo":"2176", "codigo":"768610", "titulo":"Linotipista"},
 {"id_Cbo":"2177", "codigo":"768615", "titulo":"Monotipista"},
 {"id_Cbo":"2178", "codigo":"768620", "titulo":"Paginador"},
 {"id_Cbo":"2179", "codigo":"768625", "titulo":"Pintor de letreiros"},
 {"id_Cbo":"2180", "codigo":"768630", "titulo":"Confeccionador de carimbos de borracha"},
 {"id_Cbo":"2181", "codigo":"768705", "titulo":"Gravador, a mao (encadernacao)"},
 {"id_Cbo":"2182", "codigo":"768710", "titulo":"Restaurador de livros"},
 {"id_Cbo":"2183", "codigo":"770105", "titulo":"Mestre (industria de madeira e mobiliario)"},
 {"id_Cbo":"2184", "codigo":"770110", "titulo":"Mestre carpinteiro"},
 {"id_Cbo":"2185", "codigo":"771105", "titulo":"Marceneiro"},
 {"id_Cbo":"2186", "codigo":"771110", "titulo":"Modelador de madeira"},
 {"id_Cbo":"2187", "codigo":"771115", "titulo":"Maquetista na marcenaria"},
 {"id_Cbo":"2188", "codigo":"771120", "titulo":"Tanoeiro"},
 {"id_Cbo":"2189", "codigo":"772105", "titulo":"Classificador de madeira"},
 {"id_Cbo":"2190", "codigo":"772110", "titulo":"Impregnador de madeira"},
 {"id_Cbo":"2191", "codigo":"772115", "titulo":"Secador de madeira"},
 {"id_Cbo":"2192", "codigo":"773105", "titulo":"Cortador de laminados de madeira"},
 {"id_Cbo":"2193", "codigo":"773110", "titulo":"Operador de serras no desdobramento de madeira"},
 {"id_Cbo":"2194", "codigo":"773115", "titulo":"Serrador de bordas no desdobramento de madeira"},
 {"id_Cbo":"2195", "codigo":"773120", "titulo":"Serrador de madeira"},
 {"id_Cbo":"2196", "codigo":"773125", "titulo":"Serrador de madeira (serra circular multipla)"},
 {"id_Cbo":"2197", "codigo":"773130", "titulo":"Serrador de madeira (serra de fita multipla)"},
 {"id_Cbo":"2198", "codigo":"773205", "titulo":"Operador de maquina intercaladora e placas (compensados)"},
 {"id_Cbo":"2199", "codigo":"773210", "titulo":"Prensista de aglomerados"},
 {"id_Cbo":"2200", "codigo":"773215", "titulo":"Prensista de compensados"},
 {"id_Cbo":"2201", "codigo":"773220", "titulo":"Preparador de aglomerantes"},
 {"id_Cbo":"2202", "codigo":"773305", "titulo":"Operador de desempenadeira na usinagem convencional de madeira"},
 {"id_Cbo":"2203", "codigo":"773310", "titulo":"Operador de entalhadeira (usinagem de madeira)"},
 {"id_Cbo":"2204", "codigo":"773315", "titulo":"Operador de fresadora (usinagem de madeira)"},
 {"id_Cbo":"2205", "codigo":"773320", "titulo":"Operador de lixadeira (usinagem de madeira)"},
 {"id_Cbo":"2206", "codigo":"773325", "titulo":"Operador de maquina de usinagem madeira, em geral"},
 {"id_Cbo":"2207", "codigo":"773330", "titulo":"Operador de molduradora (usinagem de madeira)"},
 {"id_Cbo":"2208", "codigo":"773335", "titulo":"Operador de plaina desengrossadeira"},
 {"id_Cbo":"2209", "codigo":"773340", "titulo":"Operador de serras (usinagem de madeira)"},
 {"id_Cbo":"2210", "codigo":"773345", "titulo":"Operador de torno automatico (usinagem de madeira)"},
 {"id_Cbo":"2211", "codigo":"773350", "titulo":"Operador de tupia (usinagem de madeira)"},
 {"id_Cbo":"2212", "codigo":"773355", "titulo":"Torneiro na usinagem convencional de madeira"},
 {"id_Cbo":"2213", "codigo":"773405", "titulo":"Operador de maquina bordatriz"},
 {"id_Cbo":"2214", "codigo":"773410", "titulo":"Operador de maquina de cortina d´agua (producao de moveis)"},
 {"id_Cbo":"2215", "codigo":"773415", "titulo":"Operador de maquina de usinagem de madeira (producao em serie)"},
 {"id_Cbo":"2216", "codigo":"773420", "titulo":"Operador de prensa de alta frequencia na usinagem de madeira"},
 {"id_Cbo":"2217", "codigo":"773505", "titulo":"Operador de centro de usinagem de madeira (cnc)"},
 {"id_Cbo":"2218", "codigo":"773510", "titulo":"Operador de maquinas de usinar madeira (cnc)"},
 {"id_Cbo":"2219", "codigo":"774105", "titulo":"Montador de moveis e artefatos de madeira"},
 {"id_Cbo":"2220", "codigo":"775105", "titulo":"Entalhador  de madeira"},
 {"id_Cbo":"2221", "codigo":"775110", "titulo":"Folheador de moveis de madeira"},
 {"id_Cbo":"2222", "codigo":"775115", "titulo":"Lustrador de pecas de madeira"},
 {"id_Cbo":"2223", "codigo":"775120", "titulo":"Marcheteiro"},
 {"id_Cbo":"2224", "codigo":"776405", "titulo":"Cesteiro"},
 {"id_Cbo":"2225", "codigo":"776410", "titulo":"Confeccionador de escovas, pinceis e produtos similares (a mao)"},
 {"id_Cbo":"2226", "codigo":"776415", "titulo":"Confeccionador de escovas, pinceis e produtos similares (a maquina)"},
 {"id_Cbo":"2227", "codigo":"776420", "titulo":"Confeccionador de moveis de vime, junco e bambu"},
 {"id_Cbo":"2228", "codigo":"776425", "titulo":"Esteireiro"},
 {"id_Cbo":"2229", "codigo":"776430", "titulo":"Vassoureiro"},
 {"id_Cbo":"2230", "codigo":"777105", "titulo":"Carpinteiro naval (construcao de pequenas embarcacoes)"},
 {"id_Cbo":"2231", "codigo":"777110", "titulo":"Carpinteiro naval (embarcacoes)"},
 {"id_Cbo":"2232", "codigo":"777115", "titulo":"Carpinteiro naval (estaleiros)"},
 {"id_Cbo":"2233", "codigo":"777205", "titulo":"Carpinteiro de carretas"},
 {"id_Cbo":"2234", "codigo":"777210", "titulo":"Carpinteiro de carrocerias"},
 {"id_Cbo":"2235", "codigo":"780105", "titulo":"Supervisor de embalagem e etiquetagem"},
 {"id_Cbo":"2236", "codigo":"781105", "titulo":"Condutor de processos robotizados de pintura"},
 {"id_Cbo":"2237", "codigo":"781110", "titulo":"Condutor de processos robotizados de soldagem"},
 {"id_Cbo":"2238", "codigo":"781305", "titulo":"Operador de veiculos subaquaticos controlados remotamente"},
 {"id_Cbo":"2239", "codigo":"781705", "titulo":"Mergulhador profissional (raso e profundo)"},
 {"id_Cbo":"2240", "codigo":"782105", "titulo":"Operador de draga"},
 {"id_Cbo":"2241", "codigo":"782110", "titulo":"Operador de guindaste (fixo)"},
 {"id_Cbo":"2242", "codigo":"782115", "titulo":"Operador de guindaste movel"},
 {"id_Cbo":"2243", "codigo":"782120", "titulo":"Operador de maquina rodoferroviaria"},
 {"id_Cbo":"2244", "codigo":"782125", "titulo":"Operador de monta-cargas (construcao civil)"},
 {"id_Cbo":"2245", "codigo":"782130", "titulo":"Operador de ponte rolante"},
 {"id_Cbo":"2246", "codigo":"782135", "titulo":"Operador de portico rolante"},
 {"id_Cbo":"2247", "codigo":"782140", "titulo":"Operador de talha eletrica"},
 {"id_Cbo":"2248", "codigo":"782145", "titulo":"Sinaleiro (ponte-rolante)"},
 {"id_Cbo":"2249", "codigo":"782205", "titulo":"Guincheiro (construcao civil)"},
 {"id_Cbo":"2250", "codigo":"782210", "titulo":"Operador de docagem"},
 {"id_Cbo":"2251", "codigo":"782220", "titulo":"Operador de empilhadeira"},
 {"id_Cbo":"2252", "codigo":"782305", "titulo":"Motorista de carro de passeio"},
 {"id_Cbo":"2253", "codigo":"782310", "titulo":"Motorista de furgao ou veiculo similar"},
 {"id_Cbo":"2254", "codigo":"782315", "titulo":"Motorista de taxi"},
 {"id_Cbo":"2255", "codigo":"782320", "titulo":"Condutor de ambulancia"},
 {"id_Cbo":"2256", "codigo":"782405", "titulo":"Motorista de onibus rodoviario"},
 {"id_Cbo":"2257", "codigo":"782410", "titulo":"Motorista de onibus urbano"},
 {"id_Cbo":"2258", "codigo":"782415", "titulo":"Motorista de trolebus"},
 {"id_Cbo":"2259", "codigo":"782505", "titulo":"Caminhoneiro autonomo (rotas regionais e internacionais)"},
 {"id_Cbo":"2260", "codigo":"782510", "titulo":"Motorista de caminhao (rotas regionais e internacionais)"},
 {"id_Cbo":"2261", "codigo":"782515", "titulo":"Motorista operacional de guincho"},
 {"id_Cbo":"2262", "codigo":"782605", "titulo":"Operador de trem de metro"},
 {"id_Cbo":"2263", "codigo":"782610", "titulo":"Maquinista de trem"},
 {"id_Cbo":"2264", "codigo":"782615", "titulo":"Maquinista de trem metropolitano"},
 {"id_Cbo":"2265", "codigo":"782620", "titulo":"Motorneiro"},
 {"id_Cbo":"2266", "codigo":"782625", "titulo":"Auxiliar de maquinista de trem"},
 {"id_Cbo":"2267", "codigo":"782630", "titulo":"Operador de teleferico (passageiros)"},
 {"id_Cbo":"2268", "codigo":"782705", "titulo":"Marinheiro de conves (maritimo e fluviario)"},
 {"id_Cbo":"2269", "codigo":"782710", "titulo":"Marinheiro de maquinas"},
 {"id_Cbo":"2270", "codigo":"782715", "titulo":"Moco de conves (maritimo e fluviario)"},
 {"id_Cbo":"2271", "codigo":"782720", "titulo":"Moco de maquinas (maritimo e fluviario)"},
 {"id_Cbo":"2272", "codigo":"782725", "titulo":"Marinheiro de esporte e recreio"},
 {"id_Cbo":"2273", "codigo":"782730", "titulo":"Marinheiro auxiliar de conves (maritimo e aquaviario)"},
 {"id_Cbo":"2274", "codigo":"782735", "titulo":"Marinheiro auxiliar de maquinas (maritimo e aquaviario)"},
 {"id_Cbo":"2275", "codigo":"782805", "titulo":"Condutor de veiculos de tracao animal (ruas e estradas)"},
 {"id_Cbo":"2276", "codigo":"782810", "titulo":"Tropeiro"},
 {"id_Cbo":"2277", "codigo":"782815", "titulo":"Boiadeiro"},
 {"id_Cbo":"2278", "codigo":"782820", "titulo":"Condutor de veiculos a pedais"},
 {"id_Cbo":"2279", "codigo":"783105", "titulo":"Agente de patio"},
 {"id_Cbo":"2280", "codigo":"783110", "titulo":"Manobrador"},
 {"id_Cbo":"2281", "codigo":"783205", "titulo":"Carregador (aeronaves)"},
 {"id_Cbo":"2282", "codigo":"783210", "titulo":"Carregador (armazem)"},
 {"id_Cbo":"2283", "codigo":"783215", "titulo":"Carregador (veiculos de transportes terrestres)"},
 {"id_Cbo":"2284", "codigo":"783220", "titulo":"Estivador"},
 {"id_Cbo":"2285", "codigo":"783225", "titulo":"Ajudante de motorista"},
 {"id_Cbo":"2286", "codigo":"783230", "titulo":"Bloqueiro (trabalhador portuario)"},
 {"id_Cbo":"2287", "codigo":"783235", "titulo":"Trabalhador portuario de capatazia"},
 {"id_Cbo":"2288", "codigo":"783240", "titulo":"Amarrador e desamarrado de embarcacoes"},
 {"id_Cbo":"2289", "codigo":"784105", "titulo":"Embalador, a mao"},
 {"id_Cbo":"2290", "codigo":"784110", "titulo":"Embalador, a maquina"},
 {"id_Cbo":"2291", "codigo":"784115", "titulo":"Operador de maquina de etiquetar"},
 {"id_Cbo":"2292", "codigo":"784120", "titulo":"Operador de maquina de envasar liquidos"},
 {"id_Cbo":"2293", "codigo":"784125", "titulo":"Operador de prensa de enfardamento"},
 {"id_Cbo":"2294", "codigo":"784205", "titulo":"Alimentador de linha de producao"},
 {"id_Cbo":"2295", "codigo":"791105", "titulo":"Artesao bordador"},
 {"id_Cbo":"2296", "codigo":"791110", "titulo":"Artesao ceramista"},
 {"id_Cbo":"2297", "codigo":"791115", "titulo":"Artesao com material reciclavel"},
 {"id_Cbo":"2298", "codigo":"791120", "titulo":"Artesao confeccionador de biojoias e ecojoias"},
 {"id_Cbo":"2299", "codigo":"791125", "titulo":"Artesao do couro"},
 {"id_Cbo":"2300", "codigo":"791130", "titulo":"Artesao escultor"},
 {"id_Cbo":"2301", "codigo":"791135", "titulo":"Artesao moveleiro (exceto reciclado)"},
 {"id_Cbo":"2302", "codigo":"791140", "titulo":"Artesao tecelao"},
 {"id_Cbo":"2303", "codigo":"791145", "titulo":"Artesao trancador"},
 {"id_Cbo":"2304", "codigo":"791150", "titulo":"Artesao crocheteiro"},
 {"id_Cbo":"2305", "codigo":"791155", "titulo":"Artesao tricoteiro"},
 {"id_Cbo":"2306", "codigo":"791160", "titulo":"Artesao rendeiro"},
 {"id_Cbo":"2307", "codigo":"810105", "titulo":"Mestre (industria petroquimica e carboquimica)"},
 {"id_Cbo":"2308", "codigo":"810110", "titulo":"Mestre de producao quimica"},
 {"id_Cbo":"2309", "codigo":"810205", "titulo":"Mestre (industria de borracha e plastico)"},
 {"id_Cbo":"2310", "codigo":"810305", "titulo":"Mestre de producao farmaceutica"},
 {"id_Cbo":"2311", "codigo":"811005", "titulo":"Operador de processos quimicos e petroquimicos"},
 {"id_Cbo":"2312", "codigo":"811010", "titulo":"Operador de sala de controle de instalacoes quimicas, petroquimicas e afins"},
 {"id_Cbo":"2313", "codigo":"811105", "titulo":"Moleiro (tratamentos quimicos e afins)"},
 {"id_Cbo":"2314", "codigo":"811110", "titulo":"Operador de maquina misturadeira (tratamentos quimicos e afins)"},
 {"id_Cbo":"2315", "codigo":"811115", "titulo":"Operador de britadeira (tratamentos quimicos e afins)"},
 {"id_Cbo":"2316", "codigo":"811120", "titulo":"Operador de concentracao"},
 {"id_Cbo":"2317", "codigo":"811125", "titulo":"Trabalhador da fabricacao de resinas e vernizes"},
 {"id_Cbo":"2318", "codigo":"811130", "titulo":"Trabalhador de fabricacao de tintas"},
 {"id_Cbo":"2319", "codigo":"811205", "titulo":"Operador de calcinacao (tratamento quimico e afins)"},
 {"id_Cbo":"2320", "codigo":"811215", "titulo":"Operador de tratamento quimico de materiais radioativos"},
 {"id_Cbo":"2321", "codigo":"811305", "titulo":"Operador de centrifugadora (tratamentos quimicos e afins)"},
 {"id_Cbo":"2322", "codigo":"811310", "titulo":"Operador de exploracao de petroleo"},
 {"id_Cbo":"2323", "codigo":"811315", "titulo":"Operador de filtro de secagem (mineracao)"},
 {"id_Cbo":"2324", "codigo":"811320", "titulo":"Operador de filtro de tambor rotativo (tratamentos quimicos e afins)"},
 {"id_Cbo":"2325", "codigo":"811325", "titulo":"Operador de filtro-esteira (mineracao)"},
 {"id_Cbo":"2326", "codigo":"811330", "titulo":"Operador de filtro-prensa (tratamentos quimicos e afins)"},
 {"id_Cbo":"2327", "codigo":"811335", "titulo":"Operador de filtros de parafina (tratamentos quimicos e afins)"},
 {"id_Cbo":"2328", "codigo":"811405", "titulo":"Destilador de madeira"},
 {"id_Cbo":"2329", "codigo":"811410", "titulo":"Destilador de produtos quimicos (exceto petroleo)"},
 {"id_Cbo":"2330", "codigo":"811415", "titulo":"Operador de alambique de funcionamento continuo (produtos quimicos, exceto petroleo)"},
 {"id_Cbo":"2331", "codigo":"811420", "titulo":"Operador de aparelho de reacao e conversao (produtos quimicos, exceto petroleo)"},
 {"id_Cbo":"2332", "codigo":"811425", "titulo":"Operador de equipamento de destilacao de alcool"},
 {"id_Cbo":"2333", "codigo":"811430", "titulo":"Operador de evaporador na destilacao"},
 {"id_Cbo":"2334", "codigo":"811505", "titulo":"Operador de painel de controle (refinacao de petroleo)"},
 {"id_Cbo":"2335", "codigo":"811510", "titulo":"Operador de transferencia e estocagem - na refinacao do petroleo"},
 {"id_Cbo":"2336", "codigo":"811605", "titulo":"Operador de britador de coque"},
 {"id_Cbo":"2337", "codigo":"811610", "titulo":"Operador de carro de apagamento e coque"},
 {"id_Cbo":"2338", "codigo":"811615", "titulo":"Operador de destilacao e subprodutos de coque"},
 {"id_Cbo":"2339", "codigo":"811620", "titulo":"Operador de enfornamento e desenfornamento de coque"},
 {"id_Cbo":"2340", "codigo":"811625", "titulo":"Operador de exaustor (coqueria)"},
 {"id_Cbo":"2341", "codigo":"811630", "titulo":"Operador de painel de controle"},
 {"id_Cbo":"2342", "codigo":"811635", "titulo":"Operador de preservacao e controle termico"},
 {"id_Cbo":"2343", "codigo":"811640", "titulo":"Operador de reator de coque de petroleo"},
 {"id_Cbo":"2344", "codigo":"811645", "titulo":"Operador de refrigeracao (coqueria)"},
 {"id_Cbo":"2345", "codigo":"811650", "titulo":"Operador de sistema de reversao (coqueria)"},
 {"id_Cbo":"2346", "codigo":"811705", "titulo":"Bamburista"},
 {"id_Cbo":"2347", "codigo":"811710", "titulo":"Calandrista de borracha"},
 {"id_Cbo":"2348", "codigo":"811715", "titulo":"Confeccionador de pneumaticos"},
 {"id_Cbo":"2349", "codigo":"811725", "titulo":"Confeccionador de velas por imersao"},
 {"id_Cbo":"2350", "codigo":"811735", "titulo":"Confeccionador de velas por moldagem"},
 {"id_Cbo":"2351", "codigo":"811745", "titulo":"Laminador de plastico"},
 {"id_Cbo":"2352", "codigo":"811750", "titulo":"Moldador de borracha por compressao"},
 {"id_Cbo":"2353", "codigo":"811760", "titulo":"Moldador de plastico por compressao"},
 {"id_Cbo":"2354", "codigo":"811770", "titulo":"Moldador de plastico por injecao"},
 {"id_Cbo":"2355", "codigo":"811775", "titulo":"Trefilador de borracha"},
 {"id_Cbo":"2356", "codigo":"811805", "titulo":"Operador de maquina de produtos farmaceuticos"},
 {"id_Cbo":"2357", "codigo":"811810", "titulo":"Drageador (medicamentos)"},
 {"id_Cbo":"2358", "codigo":"811815", "titulo":"Operador de maquina de fabricacao de cosmeticos"},
 {"id_Cbo":"2359", "codigo":"811820", "titulo":"Operador de maquina de fabricacao de produtos de higiene e limpeza (sabao, sabonete, detergente, absorvente, fraldas cotonetes e outros)"},
 {"id_Cbo":"2360", "codigo":"812105", "titulo":"Pirotecnico"},
 {"id_Cbo":"2361", "codigo":"812110", "titulo":"Trabalhador da fabricacao de municao e explosivos"},
 {"id_Cbo":"2362", "codigo":"813105", "titulo":"Cilindrista (petroquimica e afins)"},
 {"id_Cbo":"2363", "codigo":"813110", "titulo":"Operador de calandra (quimica, petroquimica e afins)"},
 {"id_Cbo":"2364", "codigo":"813115", "titulo":"Operador de extrusora (quimica, petroquimica e afins)"},
 {"id_Cbo":"2365", "codigo":"813120", "titulo":"Operador de processo (quimica, petroquimica e afins)"},
 {"id_Cbo":"2366", "codigo":"813125", "titulo":"Operador de producao (quimica, petroquimica e afins)"},
 {"id_Cbo":"2367", "codigo":"813130", "titulo":"Tecnico de operacao (quimica, petroquimica e afins)"},
 {"id_Cbo":"2368", "codigo":"818105", "titulo":"Assistente de laboratorio industrial"},
 {"id_Cbo":"2369", "codigo":"818110", "titulo":"Auxiliar de laboratorio de analises fisico-quimicas"},
 {"id_Cbo":"2370", "codigo":"820105", "titulo":"Mestre de siderurgia"},
 {"id_Cbo":"2371", "codigo":"820110", "titulo":"Mestre de aciaria"},
 {"id_Cbo":"2372", "codigo":"820115", "titulo":"Mestre de alto-forno"},
 {"id_Cbo":"2373", "codigo":"820120", "titulo":"Mestre de forno eletrico"},
 {"id_Cbo":"2374", "codigo":"820125", "titulo":"Mestre de laminacao"},
 {"id_Cbo":"2375", "codigo":"820205", "titulo":"Supervisor de fabricacao de produtos ceramicos, porcelanatos e afins"},
 {"id_Cbo":"2376", "codigo":"820210", "titulo":"Supervisor de fabricacao de produtos de vidro"},
 {"id_Cbo":"2377", "codigo":"821105", "titulo":"Operador de centro de controle"},
 {"id_Cbo":"2378", "codigo":"821110", "titulo":"Operador de maquina de sinterizar"},
 {"id_Cbo":"2379", "codigo":"821205", "titulo":"Forneiro e operador (alto-forno)"},
 {"id_Cbo":"2380", "codigo":"821210", "titulo":"Forneiro e operador (conversor a oxigenio)"},
 {"id_Cbo":"2381", "codigo":"821215", "titulo":"Forneiro e operador (forno eletrico)"},
 {"id_Cbo":"2382", "codigo":"821220", "titulo":"Forneiro e operador (refino de metais nao-ferrosos)"},
 {"id_Cbo":"2383", "codigo":"821225", "titulo":"Forneiro e operador de forno de reducao direta"},
 {"id_Cbo":"2384", "codigo":"821230", "titulo":"Operador de aciaria (basculamento de convertedor)"},
 {"id_Cbo":"2385", "codigo":"821235", "titulo":"Operador de aciaria (dessulfuracao de gusa)"},
 {"id_Cbo":"2386", "codigo":"821240", "titulo":"Operador de aciaria (recebimento de gusa)"},
 {"id_Cbo":"2387", "codigo":"821245", "titulo":"Operador de area de corrida"},
 {"id_Cbo":"2388", "codigo":"821250", "titulo":"Operador de desgaseificacao"},
 {"id_Cbo":"2389", "codigo":"821255", "titulo":"Soprador de convertedor"},
 {"id_Cbo":"2390", "codigo":"821305", "titulo":"Operador de laminador"},
 {"id_Cbo":"2391", "codigo":"821310", "titulo":"Operador de laminador de barras a frio"},
 {"id_Cbo":"2392", "codigo":"821315", "titulo":"Operador de laminador de barras a quente"},
 {"id_Cbo":"2393", "codigo":"821320", "titulo":"Operador de laminador de metais nao-ferrosos"},
 {"id_Cbo":"2394", "codigo":"821325", "titulo":"Operador de laminador de tubos"},
 {"id_Cbo":"2395", "codigo":"821330", "titulo":"Operador de montagem de cilindros e mancais"},
 {"id_Cbo":"2396", "codigo":"821335", "titulo":"Recuperador de guias e cilindros"},
 {"id_Cbo":"2397", "codigo":"821405", "titulo":"Encarregado de acabamento de chapas e metais  (tempera)"},
 {"id_Cbo":"2398", "codigo":"821410", "titulo":"Escarfador"},
 {"id_Cbo":"2399", "codigo":"821415", "titulo":"Marcador de produtos (siderurgico e metalurgico)"},
 {"id_Cbo":"2400", "codigo":"821420", "titulo":"Operador de bobinadeira de tiras a quente, no acabamento de chapas e metais"},
 {"id_Cbo":"2401", "codigo":"821425", "titulo":"Operador de cabine de laminacao (fio-maquina)"},
 {"id_Cbo":"2402", "codigo":"821430", "titulo":"Operador de escoria e sucata"},
 {"id_Cbo":"2403", "codigo":"821435", "titulo":"Operador de jato abrasivo"},
 {"id_Cbo":"2404", "codigo":"821440", "titulo":"Operador de tesoura mecanica e maquina de corte, no acabamento de chapas e metais"},
 {"id_Cbo":"2405", "codigo":"821445", "titulo":"Preparador de sucata e aparas"},
 {"id_Cbo":"2406", "codigo":"821450", "titulo":"Rebarbador de metal"},
 {"id_Cbo":"2407", "codigo":"822105", "titulo":"Forneiro de cubilo"},
 {"id_Cbo":"2408", "codigo":"822110", "titulo":"Forneiro de forno-poco"},
 {"id_Cbo":"2409", "codigo":"822115", "titulo":"Forneiro de fundicao (forno de reducao)"},
 {"id_Cbo":"2410", "codigo":"822120", "titulo":"Forneiro de reaquecimento e tratamento termico na metalurgia"},
 {"id_Cbo":"2411", "codigo":"822125", "titulo":"Forneiro de reverbero"},
 {"id_Cbo":"2412", "codigo":"823105", "titulo":"Preparador de massa (fabricacao de abrasivos)"},
 {"id_Cbo":"2413", "codigo":"823110", "titulo":"Preparador de massa (fabricacao de vidro)"},
 {"id_Cbo":"2414", "codigo":"823115", "titulo":"Preparador de massa de argila"},
 {"id_Cbo":"2415", "codigo":"823120", "titulo":"Preparador de barbotina"},
 {"id_Cbo":"2416", "codigo":"823125", "titulo":"Preparador de esmaltes (ceramica)"},
 {"id_Cbo":"2417", "codigo":"823130", "titulo":"Preparador de aditivos"},
 {"id_Cbo":"2418", "codigo":"823135", "titulo":"Operador de atomizador"},
 {"id_Cbo":"2419", "codigo":"823210", "titulo":"Extrusor de fios ou fibras de vidro"},
 {"id_Cbo":"2420", "codigo":"823215", "titulo":"Forneiro na fundicao de vidro"},
 {"id_Cbo":"2421", "codigo":"823220", "titulo":"Forneiro no recozimento de vidro"},
 {"id_Cbo":"2422", "codigo":"823230", "titulo":"Moldador de abrasivos na fabricacao de ceramica, vidro e porcelana"},
 {"id_Cbo":"2423", "codigo":"823235", "titulo":"Operador de banho metalico de vidro por flutuacao"},
 {"id_Cbo":"2424", "codigo":"823240", "titulo":"Operador de maquina de soprar vidro"},
 {"id_Cbo":"2425", "codigo":"823245", "titulo":"Operador de maquina extrusora de varetas e tubos de vidro"},
 {"id_Cbo":"2426", "codigo":"823250", "titulo":"Operador de prensa de moldar vidro"},
 {"id_Cbo":"2427", "codigo":"823255", "titulo":"Temperador de vidro"},
 {"id_Cbo":"2428", "codigo":"823265", "titulo":"Trabalhador na fabricacao de produtos abrasivos"},
 {"id_Cbo":"2429", "codigo":"823305", "titulo":"Classificador e empilhador de tijolos refratarios"},
 {"id_Cbo":"2430", "codigo":"823315", "titulo":"Forneiro (materiais de construcao)"},
 {"id_Cbo":"2431", "codigo":"823320", "titulo":"Trabalhador da elaboracao de pre-fabricados (cimento amianto)"},
 {"id_Cbo":"2432", "codigo":"823325", "titulo":"Trabalhador da elaboracao de pre-fabricados (concreto armado)"},
 {"id_Cbo":"2433", "codigo":"823330", "titulo":"Trabalhador da fabricacao de pedras artificiais"},
 {"id_Cbo":"2434", "codigo":"828105", "titulo":"Oleiro (fabricacao de telhas)"},
 {"id_Cbo":"2435", "codigo":"828110", "titulo":"Oleiro (fabricacao de tijolos)"},
 {"id_Cbo":"2436", "codigo":"830105", "titulo":"Mestre (industria de celulose, papel e papelao)"},
 {"id_Cbo":"2437", "codigo":"831105", "titulo":"Cilindreiro na preparacao de pasta para fabricacao de papel"},
 {"id_Cbo":"2438", "codigo":"831110", "titulo":"Operador de branqueador de pasta para fabricacao de papel"},
 {"id_Cbo":"2439", "codigo":"831115", "titulo":"Operador de digestor de pasta para fabricacao de papel"},
 {"id_Cbo":"2440", "codigo":"831120", "titulo":"Operador de lavagem e depuracao de pasta para fabricacao de papel"},
 {"id_Cbo":"2441", "codigo":"831125", "titulo":"Operador de maquina de secar celulose"},
 {"id_Cbo":"2442", "codigo":"832105", "titulo":"Calandrista de papel"},
 {"id_Cbo":"2443", "codigo":"832110", "titulo":"Operador de cortadeira de papel"},
 {"id_Cbo":"2444", "codigo":"832115", "titulo":"Operador de maquina de fabricar papel  (fase umida)"},
 {"id_Cbo":"2445", "codigo":"832120", "titulo":"Operador de maquina de fabricar papel (fase seca)"},
 {"id_Cbo":"2446", "codigo":"832125", "titulo":"Operador de maquina de fabricar papel e papelao"},
 {"id_Cbo":"2447", "codigo":"832135", "titulo":"Operador de rebobinadeira na fabricacao de papel e papelao"},
 {"id_Cbo":"2448", "codigo":"833105", "titulo":"Cartonageiro, a maquina"},
 {"id_Cbo":"2449", "codigo":"833110", "titulo":"Confeccionador de bolsas, sacos e sacolas e papel, a maquina"},
 {"id_Cbo":"2450", "codigo":"833115", "titulo":"Confeccionador de sacos de celofane, a maquina"},
 {"id_Cbo":"2451", "codigo":"833120", "titulo":"Operador de maquina de cortar e dobrar papelao"},
 {"id_Cbo":"2452", "codigo":"833125", "titulo":"Operador de prensa de embutir papelao"},
 {"id_Cbo":"2453", "codigo":"833205", "titulo":"Cartonageiro, a mao (caixas de papelao)"},
 {"id_Cbo":"2454", "codigo":"840105", "titulo":"Supervisor de producao da industria alimenticia"},
 {"id_Cbo":"2455", "codigo":"840110", "titulo":"Supervisor da industria de bebidas"},
 {"id_Cbo":"2456", "codigo":"840115", "titulo":"Supervisor da industria de fumo"},
 {"id_Cbo":"2457", "codigo":"840120", "titulo":"Chefe de confeitaria"},
 {"id_Cbo":"2458", "codigo":"841105", "titulo":"Moleiro de cereais (exceto arroz)"},
 {"id_Cbo":"2459", "codigo":"841110", "titulo":"Moleiro de especiarias"},
 {"id_Cbo":"2460", "codigo":"841115", "titulo":"Operador de processo de moagem"},
 {"id_Cbo":"2461", "codigo":"841205", "titulo":"Moedor de sal"},
 {"id_Cbo":"2462", "codigo":"841210", "titulo":"Refinador de sal"},
 {"id_Cbo":"2463", "codigo":"841305", "titulo":"Operador de cristalizacao na refinacao de acucar"},
 {"id_Cbo":"2464", "codigo":"841310", "titulo":"Operador de equipamentos de refinacao de acucar (processo continuo)"},
 {"id_Cbo":"2465", "codigo":"841315", "titulo":"Operador de moenda na fabricacao de acucar"},
 {"id_Cbo":"2466", "codigo":"841320", "titulo":"Operador de tratamento de calda na refinacao de acucar"},
 {"id_Cbo":"2467", "codigo":"841408", "titulo":"Cozinhador (conservacao de alimentos)"},
 {"id_Cbo":"2468", "codigo":"841416", "titulo":"Cozinhador de carnes"},
 {"id_Cbo":"2469", "codigo":"841420", "titulo":"Cozinhador de frutas e legumes"},
 {"id_Cbo":"2470", "codigo":"841428", "titulo":"Cozinhador de pescado"},
 {"id_Cbo":"2471", "codigo":"841432", "titulo":"Desidratador de alimentos"},
 {"id_Cbo":"2472", "codigo":"841440", "titulo":"Esterilizador de alimentos"},
 {"id_Cbo":"2473", "codigo":"841444", "titulo":"Hidrogenador de oleos e gorduras"},
 {"id_Cbo":"2474", "codigo":"841448", "titulo":"Lagareiro"},
 {"id_Cbo":"2475", "codigo":"841456", "titulo":"Operador de camaras frias"},
 {"id_Cbo":"2476", "codigo":"841460", "titulo":"Operador de preparacao de graos vegetais (oleos e gorduras)"},
 {"id_Cbo":"2477", "codigo":"841464", "titulo":"Prensador de frutas (exceto oleaginosas)"},
 {"id_Cbo":"2478", "codigo":"841468", "titulo":"Preparador de racoes"},
 {"id_Cbo":"2479", "codigo":"841472", "titulo":"Refinador de oleo e gordura"},
 {"id_Cbo":"2480", "codigo":"841476", "titulo":"Trabalhador de fabricacao de margarina"},
 {"id_Cbo":"2481", "codigo":"841484", "titulo":"Trabalhador de preparacao de pescados (limpeza)"},
 {"id_Cbo":"2482", "codigo":"841505", "titulo":"Trabalhador de tratamento do leite e fabricacao de laticinios e afins"},
 {"id_Cbo":"2483", "codigo":"841605", "titulo":"Misturador de cafe"},
 {"id_Cbo":"2484", "codigo":"841610", "titulo":"Torrador de cafe"},
 {"id_Cbo":"2485", "codigo":"841615", "titulo":"Moedor de cafe"},
 {"id_Cbo":"2486", "codigo":"841620", "titulo":"Operador de extracao de cafe soluvel"},
 {"id_Cbo":"2487", "codigo":"841625", "titulo":"Torrador de cacau"},
 {"id_Cbo":"2488", "codigo":"841630", "titulo":"Misturador de cha ou mate"},
 {"id_Cbo":"2489", "codigo":"841705", "titulo":"Alambiqueiro"},
 {"id_Cbo":"2490", "codigo":"841710", "titulo":"Filtrador de cerveja"},
 {"id_Cbo":"2491", "codigo":"841715", "titulo":"Fermentador"},
 {"id_Cbo":"2492", "codigo":"841720", "titulo":"Trabalhador de fabricacao de vinhos"},
 {"id_Cbo":"2493", "codigo":"841725", "titulo":"Malteiro (germinacao)"},
 {"id_Cbo":"2494", "codigo":"841730", "titulo":"Cozinhador de malte"},
 {"id_Cbo":"2495", "codigo":"841735", "titulo":"Dessecador de malte"},
 {"id_Cbo":"2496", "codigo":"841740", "titulo":"Vinagreiro"},
 {"id_Cbo":"2497", "codigo":"841745", "titulo":"Xaropeiro"},
 {"id_Cbo":"2498", "codigo":"841805", "titulo":"Operador de forno (fabricacao de paes, biscoitos e similares)"},
 {"id_Cbo":"2499", "codigo":"841810", "titulo":"Operador de maquinas de fabricacao de doces, salgados e massas alimenticias"},
 {"id_Cbo":"2500", "codigo":"841815", "titulo":"Operador de maquinas de fabricacao de chocolates e achocolatados"},
 {"id_Cbo":"2501", "codigo":"842105", "titulo":"Preparador de melado e essencia de fumo"},
 {"id_Cbo":"2502", "codigo":"842110", "titulo":"Processador de fumo"},
 {"id_Cbo":"2503", "codigo":"842115", "titulo":"Classificador de fumo"},
 {"id_Cbo":"2504", "codigo":"842120", "titulo":"Auxiliar de processamento de fumo"},
 {"id_Cbo":"2505", "codigo":"842125", "titulo":"Operador de maquina (fabricacao de cigarros)"},
 {"id_Cbo":"2506", "codigo":"842135", "titulo":"Operador de maquina de preparacao de materia prima para producao de cigarros"},
 {"id_Cbo":"2507", "codigo":"842205", "titulo":"Preparador de fumo na fabricacao de charutos"},
 {"id_Cbo":"2508", "codigo":"842210", "titulo":"Operador de maquina de fabricar charutos e cigarrilhas"},
 {"id_Cbo":"2509", "codigo":"842215", "titulo":"Classificador de charutos"},
 {"id_Cbo":"2510", "codigo":"842220", "titulo":"Cortador de charutos"},
 {"id_Cbo":"2511", "codigo":"842225", "titulo":"Celofanista na fabricacao de charutos"},
 {"id_Cbo":"2512", "codigo":"842230", "titulo":"Charuteiro a mao"},
 {"id_Cbo":"2513", "codigo":"842235", "titulo":"Degustador de charutos"},
 {"id_Cbo":"2514", "codigo":"848105", "titulo":"Defumador de carnes e pescados"},
 {"id_Cbo":"2515", "codigo":"848110", "titulo":"Salgador de alimentos"},
 {"id_Cbo":"2516", "codigo":"848115", "titulo":"Salsicheiro (fabricacao de linguica, salsicha e produtos similares)"},
 {"id_Cbo":"2517", "codigo":"848205", "titulo":"Pasteurizador"},
 {"id_Cbo":"2518", "codigo":"848210", "titulo":"Queijeiro na fabricacao de laticinio"},
 {"id_Cbo":"2519", "codigo":"848215", "titulo":"Manteigueiro na fabricacao de laticinio"},
 {"id_Cbo":"2520", "codigo":"848305", "titulo":"Padeiro"},
 {"id_Cbo":"2521", "codigo":"848310", "titulo":"Confeiteiro"},
 {"id_Cbo":"2522", "codigo":"848315", "titulo":"Masseiro (massas alimenticias)"},
 {"id_Cbo":"2523", "codigo":"848325", "titulo":"Trabalhador de fabricacao de sorvete"},
 {"id_Cbo":"2524", "codigo":"848405", "titulo":"Degustador de cafe"},
 {"id_Cbo":"2525", "codigo":"848410", "titulo":"Degustador de cha"},
 {"id_Cbo":"2526", "codigo":"848415", "titulo":"Degustador de derivados de cacau"},
 {"id_Cbo":"2527", "codigo":"848420", "titulo":"Degustador de vinhos ou licores"},
 {"id_Cbo":"2528", "codigo":"848425", "titulo":"Classificador de graos"},
 {"id_Cbo":"2529", "codigo":"848505", "titulo":"Abatedor"},
 {"id_Cbo":"2530", "codigo":"848510", "titulo":"Acougueiro"},
 {"id_Cbo":"2531", "codigo":"848515", "titulo":"Desossador"},
 {"id_Cbo":"2532", "codigo":"848520", "titulo":"Magarefe"},
 {"id_Cbo":"2533", "codigo":"848525", "titulo":"Retalhador de carne"},
 {"id_Cbo":"2534", "codigo":"848605", "titulo":"Trabalhador do beneficiamento de fumo"},
 {"id_Cbo":"2535", "codigo":"860105", "titulo":"Supervisor de manutencao eletromecanica (utilidades)"},
 {"id_Cbo":"2536", "codigo":"860110", "titulo":"Supervisor de operacao de fluidos (distribuicao, captacao, tratamento de agua, gases, vapor)"},
 {"id_Cbo":"2537", "codigo":"860115", "titulo":"Supervisor de operacao eletrica (geracao, transmissao e distribuicao de energia eletrica)"},
 {"id_Cbo":"2538", "codigo":"861105", "titulo":"Operador de central hidreletrica"},
 {"id_Cbo":"2539", "codigo":"861110", "titulo":"Operador de quadro de distribuicao de energia eletrica"},
 {"id_Cbo":"2540", "codigo":"861115", "titulo":"Operador de central termoeletrica"},
 {"id_Cbo":"2541", "codigo":"861120", "titulo":"Operador de reator nuclear"},
 {"id_Cbo":"2542", "codigo":"861205", "titulo":"Operador de subestacao"},
 {"id_Cbo":"2543", "codigo":"862105", "titulo":"Foguista (locomotivas a vapor)"},
 {"id_Cbo":"2544", "codigo":"862110", "titulo":"Maquinista de embarcacoes"},
 {"id_Cbo":"2545", "codigo":"862115", "titulo":"Operador de bateria de gas de hulha"},
 {"id_Cbo":"2546", "codigo":"862120", "titulo":"Operador de caldeira"},
 {"id_Cbo":"2547", "codigo":"862130", "titulo":"Operador de compressor de ar"},
 {"id_Cbo":"2548", "codigo":"862140", "titulo":"Operador de estacao de bombeamento"},
 {"id_Cbo":"2549", "codigo":"862150", "titulo":"Operador de maquinas fixas, em geral"},
 {"id_Cbo":"2550", "codigo":"862155", "titulo":"Operador de utilidade (producao e distribuicao de vapor, gas, oleo, combustivel, energia, oxigenio)"},
 {"id_Cbo":"2551", "codigo":"862160", "titulo":"Operador de abastecimento de combustivel de aeronave"},
 {"id_Cbo":"2552", "codigo":"862205", "titulo":"Operador de estacao de captacao, tratamento e distribuicao de agua"},
 {"id_Cbo":"2553", "codigo":"862305", "titulo":"Operador de estacao de tratamento de agua e efluentes"},
 {"id_Cbo":"2554", "codigo":"862310", "titulo":"Operador de forno de incineracao no tratamento de agua, efluentes e residuos industriais"},
 {"id_Cbo":"2555", "codigo":"862405", "titulo":"Operador de instalacao de extracao, processamento, envasamento e distribuicao de gases"},
 {"id_Cbo":"2556", "codigo":"862505", "titulo":"Operador de instalacao de refrigeracao"},
 {"id_Cbo":"2557", "codigo":"862510", "titulo":"Operador de refrigeracao com amonia"},
 {"id_Cbo":"2558", "codigo":"862515", "titulo":"Operador de instalacao de ar-condicionado"},
 {"id_Cbo":"2559", "codigo":"910105", "titulo":"Encarregado de manutencao mecanica de sistemas operacionais"},
 {"id_Cbo":"2560", "codigo":"910110", "titulo":"Supervisor de manutencao de aparelhos termicos, de climatizacao e de refrigeracao"},
 {"id_Cbo":"2561", "codigo":"910115", "titulo":"Supervisor de manutencao de bombas, motores, compressores e equipamentos de transmissao"},
 {"id_Cbo":"2562", "codigo":"910120", "titulo":"Supervisor de manutencao de maquinas graficas"},
 {"id_Cbo":"2563", "codigo":"910125", "titulo":"Supervisor de manutencao de maquinas industriais texteis"},
 {"id_Cbo":"2564", "codigo":"910130", "titulo":"Supervisor de manutencao de maquinas operatrizes e de usinagem"},
 {"id_Cbo":"2565", "codigo":"910205", "titulo":"Supervisor da manutencao e reparacao de veiculos leves"},
 {"id_Cbo":"2566", "codigo":"910210", "titulo":"Supervisor da manutencao e reparacao de veiculos pesados"},
 {"id_Cbo":"2567", "codigo":"910905", "titulo":"Supervisor de reparos linhas ferreas"},
 {"id_Cbo":"2568", "codigo":"910910", "titulo":"Supervisor de manutencao de vias ferreas"},
 {"id_Cbo":"2569", "codigo":"911105", "titulo":"Mecanico de manutencao de bomba injetora (exceto de veiculos automotores)"},
 {"id_Cbo":"2570", "codigo":"911110", "titulo":"Mecanico de manutencao de bombas"},
 {"id_Cbo":"2571", "codigo":"911115", "titulo":"Mecanico de manutencao de compressores de ar"},
 {"id_Cbo":"2572", "codigo":"911120", "titulo":"Mecanico de manutencao de motores diesel (exceto de veiculos automotores)"},
 {"id_Cbo":"2573", "codigo":"911125", "titulo":"Mecanico de manutencao de redutores"},
 {"id_Cbo":"2574", "codigo":"911130", "titulo":"Mecanico de manutencao de turbinas (exceto de aeronaves)"},
 {"id_Cbo":"2575", "codigo":"911135", "titulo":"Mecanico de manutencao de turbocompressores"},
 {"id_Cbo":"2576", "codigo":"911205", "titulo":"Mecanico de manutencao e instalacao de aparelhos de climatizacao e  refrigeracao"},
 {"id_Cbo":"2577", "codigo":"911305", "titulo":"Mecanico de manutencao de maquinas, em geral"},
 {"id_Cbo":"2578", "codigo":"911310", "titulo":"Mecanico de manutencao de maquinas graficas"},
 {"id_Cbo":"2579", "codigo":"911315", "titulo":"Mecanico de manutencao de maquinas operatrizes (lavra de madeira)"},
 {"id_Cbo":"2580", "codigo":"911320", "titulo":"Mecanico de manutencao de maquinas texteis"},
 {"id_Cbo":"2581", "codigo":"911325", "titulo":"Mecanico de manutencao de maquinas-ferramentas (usinagem de metais)"},
 {"id_Cbo":"2582", "codigo":"913105", "titulo":"Mecanico de manutencao de aparelhos de levantamento"},
 {"id_Cbo":"2583", "codigo":"913110", "titulo":"Mecanico de manutencao de equipamento de mineracao"},
 {"id_Cbo":"2584", "codigo":"913115", "titulo":"Mecanico de manutencao de maquinas agricolas"},
 {"id_Cbo":"2585", "codigo":"913120", "titulo":"Mecanico de manutencao de maquinas de construcao e terraplenagem"},
 {"id_Cbo":"2586", "codigo":"914105", "titulo":"Mecanico de manutencao de aeronaves, em geral"},
 {"id_Cbo":"2587", "codigo":"914110", "titulo":"Mecanico de manutencao de sistema hidraulico de aeronaves (servicos de pista e hangar)"},
 {"id_Cbo":"2588", "codigo":"914205", "titulo":"Mecanico de manutencao de motores e equipamentos navais"},
 {"id_Cbo":"2589", "codigo":"914305", "titulo":"Mecanico de manutencao de veiculos ferroviarios"},
 {"id_Cbo":"2590", "codigo":"914405", "titulo":"Mecanico de manutencao de automoveis, motocicletas e veiculos similares"},
 {"id_Cbo":"2591", "codigo":"914410", "titulo":"Mecanico de manutencao de empilhadeiras e outros veiculos de cargas leves"},
 {"id_Cbo":"2592", "codigo":"914415", "titulo":"Mecanico de manutencao de motocicletas"},
 {"id_Cbo":"2593", "codigo":"914420", "titulo":"Mecanico de manutencao de tratores"},
 {"id_Cbo":"2594", "codigo":"914425", "titulo":"Mecanico de veiculos automotores a diesel (exceto tratores)"},
 {"id_Cbo":"2595", "codigo":"915105", "titulo":"Tecnico em manutencao de instrumentos de medicao e precisao"},
 {"id_Cbo":"2596", "codigo":"915110", "titulo":"Tecnico em manutencao de hidrometros"},
 {"id_Cbo":"2597", "codigo":"915115", "titulo":"Tecnico em manutencao de balancas"},
 {"id_Cbo":"2598", "codigo":"915205", "titulo":"Restaurador de instrumentos musicais (exceto cordas arcadas)"},
 {"id_Cbo":"2599", "codigo":"915210", "titulo":"Reparador de instrumentos musicais"},
 {"id_Cbo":"2600", "codigo":"915215", "titulo":"Luthier (restauracao de cordas arcadas)"},
 {"id_Cbo":"2601", "codigo":"915305", "titulo":"Tecnico em manutencao de equipamentos e instrumentos medico-hospitalares"},
 {"id_Cbo":"2602", "codigo":"915405", "titulo":"Reparador de equipamentos fotograficos"},
 {"id_Cbo":"2603", "codigo":"919105", "titulo":"Lubrificador industrial"},
 {"id_Cbo":"2604", "codigo":"919110", "titulo":"Lubrificador de veiculos automotores (exceto embarcacoes)"},
 {"id_Cbo":"2605", "codigo":"919115", "titulo":"Lubrificador de embarcacoes"},
 {"id_Cbo":"2606", "codigo":"919205", "titulo":"Mecanico de manutencao de maquinas cortadoras de grama, rocadeiras, motosserras e similares"},
 {"id_Cbo":"2607", "codigo":"919305", "titulo":"Mecanico de manutencao de aparelhos esportivos e de ginastica"},
 {"id_Cbo":"2608", "codigo":"919310", "titulo":"Mecanico de manutencao de bicicletas e veiculos similares"},
 {"id_Cbo":"2609", "codigo":"919315", "titulo":"Montador de bicicletas"},
 {"id_Cbo":"2610", "codigo":"950105", "titulo":"Supervisor de manutencao eletrica de alta tensao industrial"},
 {"id_Cbo":"2611", "codigo":"950110", "titulo":"Supervisor de manutencao eletromecanica industrial, comercial e predial"},
 {"id_Cbo":"2612", "codigo":"950205", "titulo":"Encarregado de manutencao eletrica de veiculos"},
 {"id_Cbo":"2613", "codigo":"950305", "titulo":"Supervisor de manutencao eletromecanica"},
 {"id_Cbo":"2614", "codigo":"951105", "titulo":"Eletricista de manutencao eletroeletronica"},
 {"id_Cbo":"2615", "codigo":"951305", "titulo":"Instalador de sistemas eletroeletronicos de seguranca"},
 {"id_Cbo":"2616", "codigo":"951310", "titulo":"Mantenedor de sistemas eletroeletronicos de seguranca"},
 {"id_Cbo":"2617", "codigo":"951315", "titulo":"Monitor de sistemas eletronicos de seguranca interno"},
 {"id_Cbo":"2618", "codigo":"951320", "titulo":"Monitor de sistemas eletronicos de seguranca externo"},
 {"id_Cbo":"2619", "codigo":"953105", "titulo":"Eletricista de instalacoes (aeronaves)"},
 {"id_Cbo":"2620", "codigo":"953110", "titulo":"Eletricista de instalacoes (embarcacoes)"},
 {"id_Cbo":"2621", "codigo":"953115", "titulo":"Eletricista de instalacoes (veiculos automotores e maquinas operatrizes, exceto aeronaves e embarcacoes)"},
 {"id_Cbo":"2622", "codigo":"954105", "titulo":"Eletromecanico de manutencao de elevadores"},
 {"id_Cbo":"2623", "codigo":"954110", "titulo":"Eletromecanico de manutencao de escadas rolantes"},
 {"id_Cbo":"2624", "codigo":"954115", "titulo":"Eletromecanico de manutencao de portas automaticas"},
 {"id_Cbo":"2625", "codigo":"954120", "titulo":"Mecanico de manutencao de instalacoes mecanicas de edificios"},
 {"id_Cbo":"2626", "codigo":"954125", "titulo":"Operador eletromecanico"},
 {"id_Cbo":"2627", "codigo":"954205", "titulo":"Reparador de aparelhos eletrodomesticos (exceto imagem e som)"},
 {"id_Cbo":"2628", "codigo":"954210", "titulo":"Reparador de radio, tv e som"},
 {"id_Cbo":"2629", "codigo":"954305", "titulo":"Reparador de equipamentos de escritorio"},
 {"id_Cbo":"2630", "codigo":"991105", "titulo":"Conservador de via permanente (trilhos)"},
 {"id_Cbo":"2631", "codigo":"991110", "titulo":"Inspetor de via permanente (trilhos)"},
 {"id_Cbo":"2632", "codigo":"991115", "titulo":"Operador de maquinas especiais em conservacao de via permanente (trilhos)"},
 {"id_Cbo":"2633", "codigo":"991120", "titulo":"Soldador aluminotermico em conservacao de trilhos"},
 {"id_Cbo":"2634", "codigo":"991205", "titulo":"Mantenedor de equipamentos de parques de diversoes e similares"},
 {"id_Cbo":"2635", "codigo":"991305", "titulo":"Funileiro de veiculos (reparacao)"},
 {"id_Cbo":"2636", "codigo":"991310", "titulo":"Montador de veiculos (reparacao)"},
 {"id_Cbo":"2637", "codigo":"991315", "titulo":"Pintor de veiculos (reparacao)"},
 {"id_Cbo":"2638", "codigo":"992105", "titulo":"Alinhador de pneus"},
 {"id_Cbo":"2639", "codigo":"992110", "titulo":"Balanceador"},
 {"id_Cbo":"2640", "codigo":"992115", "titulo":"Borracheiro"},
 {"id_Cbo":"2641", "codigo":"992120", "titulo":"Lavador de pecas"},
 {"id_Cbo":"2642", "codigo":"992205", "titulo":"Encarregado geral de operacoes de conservacao de vias permanentes (exceto trilhos)"},
 {"id_Cbo":"2643", "codigo":"992210", "titulo":"Encarregado de equipe de conservacao de vias permanentes (exceto trilhos)"},
 {"id_Cbo":"2644", "codigo":"992215", "titulo":"Operador de ceifadeira na conservacao de vias permanentes"},
 {"id_Cbo":"2645", "codigo":"992220", "titulo":"Pedreiro de conservacao de vias permanentes (exceto trilhos)"},
 {"id_Cbo":"2646", "codigo":"992225", "titulo":"Auxiliar geral de conservacao de vias permanentes (exceto trilhos)"}]