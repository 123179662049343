import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const PortalAssinatura = () => {
  const [url, setUrl] = useState({});

  useEffect(() => {
    const carregaSessionStorage = () => {
      const sessionPortal = JSON.parse(
        sessionStorage.getItem("@PortalAssinatura")
      );

      sessionPortal
        ? setUrl(sessionPortal)
        : console.log("sessionPortal nao encontrado", sessionPortal);
    };
    carregaSessionStorage();
  }, []);

  return (
    url ? (
      <div style={{display:'flex', justifyContent: 'center',
        alignItems: 'center'}}>
        <Link to={url ? url.portalUrl : ""}
          target="_blank"
          referrerPolicy='same-origin'
          style={{ padding:'10px', margin:'10px',  textDecoration:'none'}}
        >
          Acesso ao Portal de Assinatura
        </Link>
        <Link to="/Home" referrerPolicy='same-origin' style={{ padding:'10px', margin:'10px', textDecoration:'none'}}>Voltar para a Home</Link>
      </div>
    ) : (
      <div style={{display:'flex', justifyContent: 'center',
        alignItems: 'center'}}>   
        <Link to="/Home" referrerPolicy='same-origin' style={{ padding:'10px', margin:'10px', textDecoration:'none'}}>Voltar para a Home</Link>
      </div>
    )
  );
};
