import React, { useState } from "react";

export const DocReclamante = () => {
  const [doc, setDoc] = useState([
    { name: "Carteira de trabalho" },
    {
      name: "Cópia completa dos prontuários clínicos onde realizou tratamentos médicos.",
    },
    {
      name: "Cópia dos exames que comprovem suas doenças sendo advertida que caso não tiver exames no autos no momento da perícia este perito irá concluir o laudo com os dados que dispõe.",
    },
    { name: "Carteira de motorista (se possuir)" },
  ]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelected") {
      const tempDoc = doc.map((e) => {
        return { ...e, isChecked: checked };
      });
      setDoc(tempDoc);
    } else {
      const tempDoc = doc.map((e) => {
        return e.name === name ? { ...e, isChecked: checked } : e;
      });
      setDoc(tempDoc);
    }
  };

  const [docReclamante, setDocReclamante] = useState([]);

  const adicionarDocReclamante = (e) => {
    e.preventDefault();
    const dadoSelecionado = [];

    doc.map(({ name, isChecked }) => {
      if (isChecked === true) {
        dadoSelecionado.push(name);
      }
      return null;
    });
    if (dadoSelecionado) {
      setDocReclamante(dadoSelecionado);

      localStorage.setItem(
        "tudo",
        JSON.stringify({
          ...(JSON.parse(localStorage.getItem("tudo")) || []),
          _06_docReclamante: dadoSelecionado,
        })
      );
    }
  };

  const excluirDocReclamante = (e) => {
    e.preventDefault();
    docReclamante.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        docReclamante.splice(i, 1);
        setDocReclamante([...docReclamante]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _06_docReclamante: docReclamante,
          })
        );
      }
      return null;
    });
  };

  const personalizacaoDoUsuario = /* async */ () => {
    /* const getRefs = (docs) => {
              return docs.map((doc) =>
                doc ? doc.ref : console.log(`Um erro ocorreu`)
              );
            };
            const getIds = (refs) => {
              return refs.map((ref) =>
                ref ? ref.id : console.log(`Um erro ocorreu`)
              );
            }; */

    const docPersonalizado = JSON.parse(
      localStorage.getItem("tudo")
    )._04_docHonorarios; /* getIds(getRefs((await getDocs(collection(db, "users"))).docs)) */
    if (docPersonalizado && docPersonalizado.length > 0) {
      let z = [];
      docPersonalizado.map((e) => {
        z.push({
          name: e.toString(),
        });
        return z;
      });
      setDoc(
        [
          { name: "Carteira de trabalho" },
          {
            name: "Cópia completa dos prontuários clínicos onde realizou tratamentos médicos.",
          },
          {
            name: "Cópia dos exames que comprovem suas doenças sendo advertida que caso não tiver exames no autos no momento da perícia este perito irá concluir o laudo com os dados que dispõe.",
          },
          { name: "Carteira de motorista (se possuir)" },
        ].concat(z)
      );
    }
  };

  /* useMemo(() => {
    setDoc([
      { name: "Carteira de trabalho" },
      {
        name: "Cópia completa dos prontuários clínicos onde realizou tratamentos médicos.",
      },
      {
        name: "Cópia dos exames que comprovem suas doenças sendo advertida que caso não tiver exames no autos no momento da perícia este perito irá concluir o laudo com os dados que dispõe.",
      },
      { name: "Carteira de motorista (se possuir)" },
    ]);
  }, []); */

  const excluirTudoDocReclamante = (e) => {
    e.preventDefault();

    setDocReclamante([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _06_docReclamante: [],
      })
    );
  };

  const handleCarregarFormDocReclamante = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));
    if (tudo._06_docReclamante && tudo._06_docReclamante.length > 0) {
      const a =
        JSON.parse(localStorage.getItem("tudo"))._06_docReclamante !==
          undefined ||
        JSON.parse(localStorage.getItem("tudo"))._06_docReclamante !== null
          ? JSON.parse(localStorage.getItem("tudo"))._06_docReclamante
          : [];

      setDocReclamante(a);
    }
  };

  return (
    <>
      <fieldset>
      <legend>Documentos para o Reclamante</legend>
        <div >
        <fieldset >
          <table >
            <thead>
              <tr>
                <th>
                  {" "}
                  <span>Ação</span>
                  <span>Informação</span>
                  <button onClick={personalizacaoDoUsuario}>botao teste</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="allSelected"
                    onChange={handleChange}
                  />
                </th>
                <th>Selecionar todos</th>
              </tr>
              {doc.map((e, id) => {
                return (
                  <tr key={id}>
                    <th>
                    <label>
                        <div>
                        <input
                          type="checkbox"
                          name={e.name}
                          checked={e.isChecked || false}
                          onChange={handleChange}
                        /></div>
                        <span>
                        {e.name}
                        </span>
                        </label>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarDocReclamante}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormDocReclamante}>
                      Carregar formulário
                    </button>
                  </th>
                  {/* <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th> */}
                </tr>
              </tbody>
            </table>
          </fieldset>

          <fieldset>
            <legend>Documentos relativos ao reclamante Registrados</legend>
            {docReclamante.length === 0 ? (
              <p>Sem dados registrados</p>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Ação</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docReclamante.length > 0 &&
                      docReclamante.map((e, i) => {
                        return (
                          <tr key={i}>
                            <th>
                              <button onClick={excluirDocReclamante}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                            <th>{e}</th>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoDocReclamante}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoDocReclamante"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            )}
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
