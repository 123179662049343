import React, {useState} from 'react'
import styles from '../criteriosBradfordHill/CriteriosBradfordHill.module.css'

export const CriteriosBradfordHill = () => {
    const [formValues, setFormValues] = useState({})
    
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        const data = formValues[name] || {};
        const isCheckbox = type === 'checkbox'
        const newValue = isCheckbox  ? data : value;
        
        setFormValues({...formValues,  [name]: newValue  });
        //console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
    }

    const [ criteriosBradfordHill, setCriteriosBradfordHill] = useState([])
   
    const adicionarCriteriosBradfordHill = (e) => {

        e.preventDefault()

        const arrCriteriosBradfordHill = []
    
        arrCriteriosBradfordHill.push({
            perguntaCriteriosBradfordHill0: "Força de associação: Se a associação entre a suposta causa e o efeito (lesão) é alta ou baixa, isto é, se há uma grande ou pequena associação.",
            respostaCriteriosBradfordHill0: formValues.respostaCriteriosBradfordHill0 !== undefined ? formValues.respostaCriteriosBradfordHill0 : '',
            comentarioCriteriosBradfordHill0: formValues.comentarioCriteriosBradfordHill0 !== undefined ? formValues.comentarioCriteriosBradfordHill0 : '',
            perguntaCriteriosBradfordHill1: "Consistência: Se a associação entre as duas variáveis - o evento infortunístico e o dano tem sido confirmada por mais de um estudo, em populações e circunstâncias diferentes e por autores diversos.",
            respostaCriteriosBradfordHill1: formValues.respostaCriteriosBradfordHill1 !== undefined ? formValues.respostaCriteriosBradfordHill1 : '',
            comentarioCriteriosBradfordHill1: formValues.comentarioCriteriosBradfordHill1 !== undefined ? formValues.comentarioCriteriosBradfordHill1 : '',
            perguntaCriteriosBradfordHill2: "Especificidade do efeito: É um critério útil quando um determinado efeito se relaciona diretamente com uma causa específica.",
            respostaCriteriosBradfordHill2: formValues.respostaCriteriosBradfordHill2 !== undefined ? formValues.respostaCriteriosBradfordHill2 : '',
            comentarioCriteriosBradfordHill2: formValues.comentarioCriteriosBradfordHill2 !== undefined ? formValues.comentarioCriteriosBradfordHill2 : '',
            perguntaCriteriosBradfordHill3: "Sequência temporal: Um efeito (lesão) apenas apareceu depois de ter agido de uma certa causa lesional conhecida.",
            respostaCriteriosBradfordHill3: formValues.respostaCriteriosBradfordHill3 !== undefined ? formValues.respostaCriteriosBradfordHill3 : '',
            comentarioCriteriosBradfordHill3: formValues.comentarioCriteriosBradfordHill3 !== undefined ? formValues.comentarioCriteriosBradfordHill3 : '',
            perguntaCriteriosBradfordHill4: "Gradiente biológico: Avalia a curva dose-resposta ou o tempo de exposição-resposta. Alguns autores, anteriormente, consideravam o gradiente biológico como um critério de intensidade.",
            respostaCriteriosBradfordHill4: formValues.respostaCriteriosBradfordHill4 !== undefined ? formValues.respostaCriteriosBradfordHill4 : '',
            comentarioCriteriosBradfordHill4: formValues.comentarioCriteriosBradfordHill4 !== undefined ? formValues.comentarioCriteriosBradfordHill4 : '',
            perguntaCriteriosBradfordHill5: "Plausibilidade biológica: Relação lógica necessária para que um determinado efeito seja atribuível a uma causa certa.",
            respostaCriteriosBradfordHill5: formValues.respostaCriteriosBradfordHill5 !== undefined ? formValues.respostaCriteriosBradfordHill5 : '',
            comentarioCriteriosBradfordHill5: formValues.comentarioCriteriosBradfordHill5 !== undefined ? formValues.comentarioCriteriosBradfordHill5 : '',
            perguntaCriteriosBradfordHill6: "Coerência: Interpretação das causas e dos efeitos não pode colidir nem ser contraditório com o comportamento ou desenvolvimento próprio da lesão.",
            respostaCriteriosBradfordHill6: formValues.respostaCriteriosBradfordHill6 !== undefined ? formValues.respostaCriteriosBradfordHill6 : '',
            comentarioCriteriosBradfordHill6: formValues.comentarioCriteriosBradfordHill6 !== undefined ? formValues.comentarioCriteriosBradfordHill6 : '',
            perguntaCriteriosBradfordHill7: "Topografia: O agente agressivo provoca lesões em um determinado local, ao passo que no local simétrico em que ele não agiu, não se observam as mesmas lesões.",
            respostaCriteriosBradfordHill7: formValues.respostaCriteriosBradfordHill7 !== undefined ? formValues.respostaCriteriosBradfordHill7 : '',
            comentarioCriteriosBradfordHill7: formValues.comentarioCriteriosBradfordHill7 !== undefined ? formValues.comentarioCriteriosBradfordHill7 : '', 
            perguntaCriteriosBradfordHill8: "conclusaoCriteriosBradfordHill",
            respostaCriteriosBradfordHill8: formValues.respostaCriteriosBradfordHill8 !== undefined ? formValues.respostaCriteriosBradfordHill8 : '',
            comentarioCriteriosBradfordHill8: formValues.comentarioCriteriosBradfordHill8 !== undefined ? formValues.comentarioCriteriosBradfordHill8 : '',    
        })
        setCriteriosBradfordHill([...arrCriteriosBradfordHill])

        localStorage.setItem('tudo', JSON.stringify({
            ...JSON.parse(localStorage.getItem('tudo')) || [],
            _58_criteriosBradfordHill: [{
                perguntaCriteriosBradfordHill0: "Força de associação: Se a associação entre a suposta causa e o efeito (lesão) é alta ou baixa, isto é, se há uma grande ou pequena associação.",
            respostaCriteriosBradfordHill0: formValues.respostaCriteriosBradfordHill0 !== undefined ? formValues.respostaCriteriosBradfordHill0 : '',
            comentarioCriteriosBradfordHill0: formValues.comentarioCriteriosBradfordHill0 !== undefined ? formValues.comentarioCriteriosBradfordHill0 : '',
            perguntaCriteriosBradfordHill1: "Consistência: Se a associação entre as duas variáveis - o evento infortunístico e o dano tem sido confirmada por mais de um estudo, em populações e circunstâncias diferentes e por autores diversos.",
            respostaCriteriosBradfordHill1: formValues.respostaCriteriosBradfordHill1 !== undefined ? formValues.respostaCriteriosBradfordHill1 : '',
            comentarioCriteriosBradfordHill1: formValues.comentarioCriteriosBradfordHill1 !== undefined ? formValues.comentarioCriteriosBradfordHill1 : '',
            perguntaCriteriosBradfordHill2: "Especificidade do efeito: É um critério útil quando um determinado efeito se relaciona diretamente com uma causa específica.",
            respostaCriteriosBradfordHill2: formValues.respostaCriteriosBradfordHill2 !== undefined ? formValues.respostaCriteriosBradfordHill2 : '',
            comentarioCriteriosBradfordHill2: formValues.comentarioCriteriosBradfordHill2 !== undefined ? formValues.comentarioCriteriosBradfordHill2 : '',
            perguntaCriteriosBradfordHill3: "Sequência temporal: Um efeito (lesão) apenas apareceu depois de ter agido de uma certa causa lesional conhecida.",
            respostaCriteriosBradfordHill3: formValues.respostaCriteriosBradfordHill3 !== undefined ? formValues.respostaCriteriosBradfordHill3 : '',
            comentarioCriteriosBradfordHill3: formValues.comentarioCriteriosBradfordHill3 !== undefined ? formValues.comentarioCriteriosBradfordHill3 : '',
            perguntaCriteriosBradfordHill4: "Gradiente biológico: Avalia a curva dose-resposta ou o tempo de exposição-resposta. Alguns autores, anteriormente, consideravam o gradiente biológico como um critério de intensidade.",
            respostaCriteriosBradfordHill4: formValues.respostaCriteriosBradfordHill4 !== undefined ? formValues.respostaCriteriosBradfordHill4 : '',
            comentarioCriteriosBradfordHill4: formValues.comentarioCriteriosBradfordHill4 !== undefined ? formValues.comentarioCriteriosBradfordHill4 : '',
            perguntaCriteriosBradfordHill5: "Plausibilidade biológica: Relação lógica necessária para que um determinado efeito seja atribuível a uma causa certa.",
            respostaCriteriosBradfordHill5: formValues.respostaCriteriosBradfordHill5 !== undefined ? formValues.respostaCriteriosBradfordHill5 : '',
            comentarioCriteriosBradfordHill5: formValues.comentarioCriteriosBradfordHill5 !== undefined ? formValues.comentarioCriteriosBradfordHill5 : '',
            perguntaCriteriosBradfordHill6: "Coerência: Interpretação das causas e dos efeitos não pode colidir nem ser contraditório com o comportamento ou desenvolvimento próprio da lesão.",
            respostaCriteriosBradfordHill6: formValues.respostaCriteriosBradfordHill6 !== undefined ? formValues.respostaCriteriosBradfordHill6 : '',
            comentarioCriteriosBradfordHill6: formValues.comentarioCriteriosBradfordHill6 !== undefined ? formValues.comentarioCriteriosBradfordHill6 : '',
            perguntaCriteriosBradfordHill7: "Topografia: O agente agressivo provoca lesões em um determinado local, ao passo que no local simétrico em que ele não agiu, não se observam as mesmas lesões.",
            respostaCriteriosBradfordHill7: formValues.respostaCriteriosBradfordHill7 !== undefined ? formValues.respostaCriteriosBradfordHill7 : '',
            comentarioCriteriosBradfordHill7: formValues.comentarioCriteriosBradfordHill7 !== undefined ? formValues.comentarioCriteriosBradfordHill7 : '',
            perguntaCriteriosBradfordHill8: "conclusaoCriteriosBradfordHill",
            respostaCriteriosBradfordHill8: formValues.respostaCriteriosBradfordHill8 !== undefined ? formValues.respostaCriteriosBradfordHill8 : '',
            comentarioCriteriosBradfordHill8: formValues.comentarioCriteriosBradfordHill8 !== undefined ? formValues.comentarioCriteriosBradfordHill8 : '',
            }]
      }))
    }

    const excluirCriteriosBradfordHill = (e) => {
        e.preventDefault()  

        setCriteriosBradfordHill([])

                localStorage.setItem('tudo', JSON.stringify({
                    ...JSON.parse(localStorage.getItem('tudo')) || [],
                    _58_criteriosBradfordHill: []
              }))
    }

    const handleCarregarFormCriteriosBradfordHill = () => {
        
        const tudo = JSON.parse(localStorage.getItem('tudo'))
        if (tudo._58_criteriosBradfordHill && tudo._58_criteriosBradfordHill.length > 0) {
            const a = JSON.parse(localStorage.getItem('tudo'))._58_criteriosBradfordHill !== undefined
            ||JSON.parse(localStorage.getItem('tudo'))._58_criteriosBradfordHill !== null ? 
            JSON.parse(localStorage.getItem('tudo'))._58_criteriosBradfordHill : []
    
            //console.log('***',a)
        a.map((e)=> 
            setFormValues({
    perguntaCriteriosBradfordHill0: e !== undefined && e.perguntaCriteriosBradfordHill0 !== undefined ? e.perguntaCriteriosBradfordHill0 : '',
            respostaCriteriosBradfordHill0: e !== undefined &&  e.respostaCriteriosBradfordHill0 !== undefined ? e.respostaCriteriosBradfordHill0 : '',
            comentarioCriteriosBradfordHill0: e !== undefined &&  e.comentarioCriteriosBradfordHill0 !== undefined ? e.comentarioCriteriosBradfordHill0 : '',
            perguntaCriteriosBradfordHill1: e !== undefined && e.perguntaCriteriosBradfordHill1 !== undefined ? e.perguntaCriteriosBradfordHill1 : '',
            respostaCriteriosBradfordHill1: e !== undefined &&  e.respostaCriteriosBradfordHill1 !== undefined ? e.respostaCriteriosBradfordHill1 : '',
            comentarioCriteriosBradfordHill1: e !== undefined &&  e.comentarioCriteriosBradfordHill1 !== undefined ? e.comentarioCriteriosBradfordHill1 : '',
            perguntaCriteriosBradfordHill2: e !== undefined && e.perguntaCriteriosBradfordHill2 !== undefined ? e.perguntaCriteriosBradfordHill2 : '',
            respostaCriteriosBradfordHill2: e !== undefined &&  e.respostaCriteriosBradfordHill2 !== undefined ? e.respostaCriteriosBradfordHill2 : '',
            comentarioCriteriosBradfordHill2: e !== undefined &&  e.comentarioCriteriosBradfordHill2 !== undefined ? e.comentarioCriteriosBradfordHill2 : '',
            perguntaCriteriosBradfordHill3: e !== undefined && e.perguntaCriteriosBradfordHill3 !== undefined ? e.perguntaCriteriosBradfordHill3 : '',
            respostaCriteriosBradfordHill3: e !== undefined &&  e.respostaCriteriosBradfordHill3 !== undefined ? e.respostaCriteriosBradfordHill3 : '',
            comentarioCriteriosBradfordHill3: e !== undefined &&  e.comentarioCriteriosBradfordHill3 !== undefined ? e.comentarioCriteriosBradfordHill3 : '',
            perguntaCriteriosBradfordHill4: e !== undefined && e.perguntaCriteriosBradfordHill4 !== undefined ? e.perguntaCriteriosBradfordHill4 : '',
            respostaCriteriosBradfordHill4: e !== undefined &&  e.respostaCriteriosBradfordHill4 !== undefined ? e.respostaCriteriosBradfordHill4 : '',
            comentarioCriteriosBradfordHill4: e !== undefined &&  e.comentarioCriteriosBradfordHill4 !== undefined ? e.comentarioCriteriosBradfordHill4 : '',
            perguntaCriteriosBradfordHill5: e !== undefined && e.perguntaCriteriosBradfordHill5 !== undefined ? e.perguntaCriteriosBradfordHill5 : '',
            respostaCriteriosBradfordHill5: e !== undefined &&  e.respostaCriteriosBradfordHill5 !== undefined ? e.respostaCriteriosBradfordHill5 : '',
            comentarioCriteriosBradfordHill5: e !== undefined &&  e.comentarioCriteriosBradfordHill5 !== undefined ? e.comentarioCriteriosBradfordHill5 : '',
            perguntaCriteriosBradfordHill6: e !== undefined && e.perguntaCriteriosBradfordHill6 !== undefined ? e.perguntaCriteriosBradfordHill6 : '',
            respostaCriteriosBradfordHill6: e !== undefined &&  e.respostaCriteriosBradfordHill6 !== undefined ? e.respostaCriteriosBradfordHill6 : '',
            comentarioCriteriosBradfordHill6: e !== undefined &&  e.comentarioCriteriosBradfordHill6 !== undefined ? e.comentarioCriteriosBradfordHill6 : '',
            perguntaCriteriosBradfordHill7: e !== undefined && e.perguntaCriteriosBradfordHill7 !== undefined ? e.perguntaCriteriosBradfordHill7 : '',
            respostaCriteriosBradfordHill7: e !== undefined &&  e.respostaCriteriosBradfordHill7 !== undefined ? e.respostaCriteriosBradfordHill7 : '',
            comentarioCriteriosBradfordHill7: e !== undefined &&  e.comentarioCriteriosBradfordHill7 !== undefined ? e.comentarioCriteriosBradfordHill7 : '', 
            perguntaCriteriosBradfordHill8: e !== undefined && e.perguntaCriteriosBradfordHill8 !== undefined ? e.perguntaCriteriosBradfordHill8 : '',
            respostaCriteriosBradfordHill8: e !== undefined &&  e.respostaCriteriosBradfordHill8 !== undefined ? e.respostaCriteriosBradfordHill8 : '',
            comentarioCriteriosBradfordHill8: e !== undefined &&  e.comentarioCriteriosBradfordHill8 !== undefined ? e.comentarioCriteriosBradfordHill8 : '',
        }) 
    )  
}

}

    const handleReset = () => {
        setFormValues({})
    }

    const handleLimparCriteriosBradfordHill = (e) => {
        e.preventDefault()
        if (e.target.attributes.value) {
            setFormValues({...formValues,  ['respostaCriteriosBradfordHill'+e.target.attributes.value.value]: '', ['comentarioCriteriosBradfordHill'+e.target.attributes.value.value]: ''})
        }
    }


    return (
    <>
    <fieldset>
        <legend></legend>
        <input type="checkbox" id={styles.checkboxCriteriosBradfordHill} />
            <label htmlFor={styles.checkboxCriteriosBradfordHill}>
                <h2 className={styles.titulo}>Criterios de BradfordHill</h2>
            </label>
            <fieldset id={styles.conteudoCriteriosBradfordHill}>  
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Ação</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Comentário</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="Força de associação: Se a associação entre a suposta causa e o efeito (lesão) é alta ou baixa, isto é, se há uma grande ou pequena associação."> 
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'0'}></i></button></th>
                        <th>Força de associação: Se a associação entre a suposta causa e o efeito (lesão) é alta ou baixa, isto é, se há uma grande ou pequena associação.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill0 !== undefined ? formValues.respostaCriteriosBradfordHill0 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill0 !== undefined ? formValues.respostaCriteriosBradfordHill0 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill0 !== undefined ? formValues.respostaCriteriosBradfordHill0 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill0"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill0 !== undefined ? formValues.respostaCriteriosBradfordHill0 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill0"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill0 !== undefined ? formValues.comentarioCriteriosBradfordHill0 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Consistência: Se a associação entre as duas variáveis - o evento infortunístico e o dano tem sido confirmada por mais de um estudo, em populações e circunstâncias diferentes e por autores diversos.">
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'1'}></i></button></th>
                        <th>Consistência: Se a associação entre as duas variáveis - o evento infortunístico e o dano tem sido confirmada por mais de um estudo, em populações e circunstâncias diferentes e por autores diversos.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill1 !== undefined ? formValues.respostaCriteriosBradfordHill1 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill1 !== undefined ? formValues.respostaCriteriosBradfordHill1 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill1 !== undefined ? formValues.respostaCriteriosBradfordHill1 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill1"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill1 !== undefined ? formValues.respostaCriteriosBradfordHill1 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill1"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill1 !== undefined ? formValues.comentarioCriteriosBradfordHill1 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Especificidade do efeito: É um critério útil quando um determinado efeito se relaciona diretamente com uma causa específica.">
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'2'}></i></button></th>
                        <th>Especificidade do efeito: É um critério útil quando um determinado efeito se relaciona diretamente com uma causa específica.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill2 !== undefined ? formValues.respostaCriteriosBradfordHill2 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill2 !== undefined ? formValues.respostaCriteriosBradfordHill2 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill2 !== undefined ? formValues.respostaCriteriosBradfordHill2 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill2"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill2 !== undefined ? formValues.respostaCriteriosBradfordHill2 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill2"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill2 !== undefined ? formValues.comentarioCriteriosBradfordHill2 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Sequência temporal: Um efeito (lesão) apenas apareceu depois de ter agido de uma certa causa lesional conhecida.">
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'3'}></i></button></th>
                        <th>Sequência temporal: Um efeito (lesão) apenas apareceu depois de ter agido de uma certa causa lesional conhecida.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill3 !== undefined ? formValues.respostaCriteriosBradfordHill3 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill3 !== undefined ? formValues.respostaCriteriosBradfordHill3 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill3 !== undefined ? formValues.respostaCriteriosBradfordHill3 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill3"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill3 !== undefined ? formValues.respostaCriteriosBradfordHill3 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill3"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill3 !== undefined ? formValues.comentarioCriteriosBradfordHill3 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Gradiente biológico: Avalia a curva dose-resposta ou o tempo de exposição-resposta. Alguns autores, anteriormente, consideravam o gradiente biológico como um critério de intensidade.">
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'4'}></i></button></th>
                        <th>Gradiente biológico: Avalia a curva dose-resposta ou o tempo de exposição-resposta. Alguns autores, anteriormente, consideravam o gradiente biológico como um critério de intensidade.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill4 !== undefined ? formValues.respostaCriteriosBradfordHill4 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill4 !== undefined ? formValues.respostaCriteriosBradfordHill4 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill4 !== undefined ? formValues.respostaCriteriosBradfordHill4 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill4"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill4 !== undefined ? formValues.respostaCriteriosBradfordHill4 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill4"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill4 !== undefined ? formValues.comentarioCriteriosBradfordHill4 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Plausibilidade biológica: Relação lógica necessária para que um determinado efeito seja atribuível a uma causa certa.">
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'5'}></i></button></th>
                        <th>Plausibilidade biológica: Relação lógica necessária para que um determinado efeito seja atribuível a uma causa certa.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill5 !== undefined ? formValues.respostaCriteriosBradfordHill5 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill5 !== undefined ? formValues.respostaCriteriosBradfordHill5 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill5 !== undefined ? formValues.respostaCriteriosBradfordHill5 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill5"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill5 !== undefined ? formValues.respostaCriteriosBradfordHill5 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill5"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill5 !== undefined ? formValues.comentarioCriteriosBradfordHill5 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Coerência: Interpretação das causas e dos efeitos não pode colidir nem ser contraditório com o comportamento ou desenvolvimento próprio da lesão."> 
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'6'}></i></button></th>
                        <th>Coerência: Interpretação das causas e dos efeitos não pode colidir nem ser contraditório com o comportamento ou desenvolvimento próprio da lesão.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill6 !== undefined ? formValues.respostaCriteriosBradfordHill6 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill6 !== undefined ? formValues.respostaCriteriosBradfordHill6 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill6 !== undefined ? formValues.respostaCriteriosBradfordHill6 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill6"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill6 !== undefined ? formValues.respostaCriteriosBradfordHill6 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill6"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill6 !== undefined ? formValues.comentarioCriteriosBradfordHill6 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="Topografia: O agente agressivo provoca lesões em um determinado local, ao passo que no local simétrico em que ele não agiu, não se observam as mesmas lesões."> 
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'7'}></i></button></th>
                        <th>Topografia: O agente agressivo provoca lesões em um determinado local, ao passo que no local simétrico em que ele não agiu, não se observam as mesmas lesões.</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill7 !== undefined ? formValues.respostaCriteriosBradfordHill7 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill7 !== undefined ? formValues.respostaCriteriosBradfordHill7 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill7 !== undefined ? formValues.respostaCriteriosBradfordHill7 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill7"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill7 !== undefined ? formValues.respostaCriteriosBradfordHill7 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill7"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill7 !== undefined ? formValues.comentarioCriteriosBradfordHill7 : ''}
                            />
                        </th>
                    </tr>
                    <tr key="conclusaoCriteriosBradfordHill"> 
                        <th><button onClick={handleLimparCriteriosBradfordHill}><i className="fas fa-trash perigo" value={'8'}></i></button></th>
                        <th>conclusaoCriteriosBradfordHill</th>
                        <th>
                            <div 
                                className={styles.resposta}
                            >
                                <label>
                                <input
                                    type="radio"
                                    value="Atendido"
                                    name="respostaCriteriosBradfordHill8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill8 !== undefined ? formValues.respostaCriteriosBradfordHill8 === 'Atendido' : ''}
                                />
                                    Atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não atendido"
                                    name="respostaCriteriosBradfordHill8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill8 !== undefined ? formValues.respostaCriteriosBradfordHill8 === 'Não atendido' : ''}
                                />
                                    Não atendido
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Não se aplica"
                                    name="respostaCriteriosBradfordHill8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill8 !== undefined ? formValues.respostaCriteriosBradfordHill8 === 'Não se aplica' : ''}
                                />
                                    Não se aplica
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    value="Prejudicado"
                                    name="respostaCriteriosBradfordHill8"
                                    onChange={handleInputChange}
                                    checked={formValues.respostaCriteriosBradfordHill8 !== undefined ? formValues.respostaCriteriosBradfordHill8 === 'Prejudicado' : ''}
                                />
                                    Prejudicado
                                </label>
                            </div>
                        </th>
                        <th>
                            <input 
                                className={styles.comentario}
                                type="text" 
                                name="comentarioCriteriosBradfordHill8"
                                placeholder="Comentários do criterio de BradfordHill"
                                onChange={handleInputChange}
                                value={formValues.comentarioCriteriosBradfordHill8 !== undefined ? formValues.comentarioCriteriosBradfordHill8 : ''}
                            />
                        </th>
                    </tr>
                                        
                </tbody>
            </table>
            <div>
            <button onClick={adicionarCriteriosBradfordHill}><i className="fas fa-plus"></i></button>
            <button onClick={handleCarregarFormCriteriosBradfordHill}>Carregar formulário</button>
            <button onClick={handleReset}><i className="fas fa-trash perigo" value={'Limpar Campos'}></i></button>
            </div>
        <fieldset>
            <legend>Dados registrados dos Critérios de BradfordHill</legend>
            {
            criteriosBradfordHill.length === 0 ? <p>Sem dados registrados</p> : 
            <>
                <table>
                    <thead>
                        <tr>
                            {/* <th>Ação</th> */}
                            <th>Pergunta</th>
                            <th>Resposta</th>
                            <th>Comentário</th>
                        </tr>
                    </thead>
                        {criteriosBradfordHill.length > 0 && criteriosBradfordHill.map(({ 
                            perguntaCriteriosBradfordHill0,
                            respostaCriteriosBradfordHill0,
                            comentarioCriteriosBradfordHill0,
                            perguntaCriteriosBradfordHill1,
                            respostaCriteriosBradfordHill1,
                            comentarioCriteriosBradfordHill1,
                            perguntaCriteriosBradfordHill2,
                            respostaCriteriosBradfordHill2,
                            comentarioCriteriosBradfordHill2,
                            perguntaCriteriosBradfordHill3,
                            respostaCriteriosBradfordHill3,
                            comentarioCriteriosBradfordHill3,
                            perguntaCriteriosBradfordHill4,
                            respostaCriteriosBradfordHill4,
                            comentarioCriteriosBradfordHill4,
                            perguntaCriteriosBradfordHill5,
                            respostaCriteriosBradfordHill5,
                            comentarioCriteriosBradfordHill5,
                            perguntaCriteriosBradfordHill6,
                            respostaCriteriosBradfordHill6,
                            comentarioCriteriosBradfordHill6,
                            perguntaCriteriosBradfordHill7,
                            respostaCriteriosBradfordHill7,
                            comentarioCriteriosBradfordHill7,
                            perguntaCriteriosBradfordHill8,
                            respostaCriteriosBradfordHill8,
                            comentarioCriteriosBradfordHill8,
                         
                        }, i)=>{

                        return(
                        
                            <tbody key={i}>
                                
                            <tr >
                                <th>{perguntaCriteriosBradfordHill0}</th>
                                <th>{respostaCriteriosBradfordHill0}</th>
                                <th>{comentarioCriteriosBradfordHill0}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill1}</th>
                                <th>{respostaCriteriosBradfordHill1}</th>
                                <th>{comentarioCriteriosBradfordHill1}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill2}</th>
                                <th>{respostaCriteriosBradfordHill2}</th>
                                <th>{comentarioCriteriosBradfordHill2}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill3}</th>
                                <th>{respostaCriteriosBradfordHill3}</th>
                                <th>{comentarioCriteriosBradfordHill3}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill4}</th>
                                <th>{respostaCriteriosBradfordHill4}</th>
                                <th>{comentarioCriteriosBradfordHill4}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill5}</th>
                                <th>{respostaCriteriosBradfordHill5}</th>
                                <th>{comentarioCriteriosBradfordHill5}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill6}</th>
                                <th>{respostaCriteriosBradfordHill6}</th>
                                <th>{comentarioCriteriosBradfordHill6}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill7}</th>
                                <th>{respostaCriteriosBradfordHill7}</th>
                                <th>{comentarioCriteriosBradfordHill7}</th>
                            </tr>
                            <tr >
                                
                                <th>{perguntaCriteriosBradfordHill8}</th>
                                <th>{respostaCriteriosBradfordHill8}</th>
                                <th>{comentarioCriteriosBradfordHill8}</th>
                            </tr>
                            
                            </tbody>
                            
                    )})}
                    
                    </table>
                    <div>
                    <button onClick={excluirCriteriosBradfordHill}><i className="fas fa-solid fa-trash perigo" value={'Excluir'}></i></button>
                    </div>
                </>
                }
            </fieldset>
            </fieldset>
        </fieldset>
        </>
        )   
}