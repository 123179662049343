import React, {useState, useEffect} from "react"

export const DadosIniciaisProcesso = () => {
  const [formValues, setFormValues] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    const data = formValues[name] || {};
    const isCheckbox = type === "checkbox";
    const newValue = isCheckbox ? data : value;

    setFormValues({ ...formValues, [name]: newValue });
    // console.log('***data', data, '***formValues', formValues, '***newValue', newValue)
  };

  const [dadosIniciais, setDadosIniciais] = useState([]);

  const adicionarDadosIniciais = (e) => {
    e.preventDefault();

    const arrDadosIniciais = [];

    arrDadosIniciais.push({
      numProcesso:
        formValues.numProcesso !== undefined && formValues.numProcesso.length === 25
          ? formValues.numProcesso
          : "Número do processo inválido!",
      tipoProcesso:
        formValues.tipoProcesso !== undefined && formValues.tipoProcesso !== ""
          ? formValues.tipoProcesso
          : "Tipo do processo inválido!",
    });
    setDadosIniciais([...arrDadosIniciais]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _01_dadosIniciais: [
          {
            numProcesso:
              formValues.numProcesso !== undefined && formValues.numProcesso.length === 25
                ? formValues.numProcesso
                : "Número do processo inválido!",
            tipoProcesso:
              formValues.tipoProcesso !== undefined && formValues.tipoProcesso !== ""
                ? formValues.tipoProcesso
                : "Tipo do processo inválido!",
          },
        ],
      })
    );
  };

  const excluirDadosIniciais = (e) => {
    e.preventDefault();
    dadosIniciais.filter((el, i) => {
      if (
        e.target.attributes.value.value !== undefined &&
        e.target.attributes.value.value === String(i)
      ) {
        dadosIniciais.splice(i, 1);
        setDadosIniciais([...dadosIniciais]);

        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _01_dadosIniciais: [...dadosIniciais],
          })
        );
      }
      return null;
    });
  };

  const excluirTudoDadosIniciais = (e) => {
    e.preventDefault();

    setDadosIniciais([]);

    localStorage.setItem(
      "tudo",
      JSON.stringify({
        ...(JSON.parse(localStorage.getItem("tudo")) || []),
        _01_dadosIniciais: [],
      })
    );
  };

  const handleCarregarFormDadosIniciais = () => {
    const tudo = JSON.parse(localStorage.getItem("tudo"));

    const x = () => {
      if (tudo._01_dadosIniciais && tudo._01_dadosIniciais.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._01_dadosIniciais !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._01_dadosIniciais !== null
            ? JSON.parse(localStorage.getItem("tudo"))._01_dadosIniciais
            : [];
  
        //console.log('***',a)
        a.map((e) =>
          setFormValues({
            numProcesso:
              e !== undefined && e.numProcesso !== undefined
                ? e.numProcesso
                : "",
            tipoProcesso:
              e !== undefined && e.tipoProcesso !== undefined
                ? e.tipoProcesso
                : "",
          })
        );
      }
    }

    const y = () => {
      if (tudo._01_dadosIniciais && tudo._01_dadosIniciais.length > 0) {
        const a =
          JSON.parse(localStorage.getItem("tudo"))._01_dadosIniciais !==
            undefined ||
          JSON.parse(localStorage.getItem("tudo"))._01_dadosIniciais !== null
            ? JSON.parse(localStorage.getItem("tudo"))._01_dadosIniciais
            : [];
  
        //console.log('***',a)
        a.map((e) =>
          setDadosIniciais([{
            numProcesso:
              e !== undefined && e.numProcesso !== undefined
                ? e.numProcesso
                : "",
            tipoProcesso:
              e !== undefined && e.tipoProcesso !== undefined
                ? e.tipoProcesso
                : "",
          }])
        );
      }
    }

    x()
    y()
    
  };

  const handleReset = () => {
    setFormValues({});
  };

  const handleLimparCampo = (e) => {
    e.preventDefault();
    if (e.target.attributes.value) {
      setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
    }
  };

  useEffect(() => {
    if (formValues.numProcesso !== undefined) {
      //console.log(formValues.numProcesso.length)
      formValues.numProcesso = formValues.numProcesso
        .replace(/\D/g, "")
        .replace(/(\d{7})(\d)/, "$1-$2")
        .replace(/(-\d{2})(\d{1})/, "$1.$2")
        .replace(/(\.\d{4})(\d{1})/, "$1.$2")
        .replace(/(\.\d{1})(\d{2})(\d{4})/, "$1.$2.$3");

      //console.log(formValues.numProcesso.length)
    }
  }, [formValues, dadosIniciais]);

  return (
    <>
      <fieldset>
        <legend>Dados Iniciais do Processo</legend>
        <div >
        <fieldset >

          <table >
          <thead>
              <tr>
                <th>Limpar Campo</th>
                <th>Informação</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"numProcesso"}></i>
                  </button>
                </th>
                <th>
                <input
                    name="numProcesso"
                    size="25"
              minLength="25"
              maxLength="25"
              placeholder="Número do processo"
              onChange={handleInputChange}
              value={
                formValues.numProcesso !== undefined
                  ? formValues.numProcesso
                      .trim()
                      .replace(/\D/g, "")
                      .replace(/(\d{7})(\d)/, "$1-$2")
                      .replace(/(-\d{2})(\d{1})/, "$1.$2")
                      .replace(/(\.\d{4})(\d{1})/, "$1.$2")
                      .replace(/(\.\d{1})(\d{2})(\d{4})/, "$1.$2.$3")
                  : ""
              }
                  />
                </th>
              </tr>
              <tr>
                <th>
                  <button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"tipoProcesso"}></i>
                  </button>
                </th>
                <th>
                <select
              name="tipoProcesso"
              className="selectTipoProcesso"
              onChange={handleInputChange}
              value={
                formValues.tipoProcesso !== undefined
                  ? formValues.tipoProcesso
                  : ""
              }
            >
              <option value="">
                Selecione o tipo do processo
              </option>
              <option value="Trabalhista">Trabalhista</option>
              <option value="Securitária">Securitária</option>
              <option value="Dpvat">DPVAT</option>
            </select>
                </th>
              </tr>
              
            </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarDadosIniciais}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormDadosIniciais}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>


          <fieldset >
            <legend>Dados registrados dos dados iniciais</legend>
            {dadosIniciais.length === 0 ? 
              <p>Sem dados registrados</p>
             : 
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Tópico</th>
                      <th>Informação</th>
                    </tr>
                  </thead>
                  {dadosIniciais.length > 0 &&
                    dadosIniciais.map(
                      ({ numProcesso, tipoProcesso }, i) => {
                        return (
                          <tbody key={i}>
                            
                            <tr >
                            <th>Registro número</th>
                            <th> {i + 1}</th>
                          </tr>
                          <tr>
                            <th>Número do processo</th>
                            <th>{numProcesso}</th>
                          </tr>

                          <tr>
                            <th>Tipo</th>
                            <th>{tipoProcesso}</th>
                          </tr>
                          <tr>
                            <th>Ação</th>
                            <th >
                              <button onClick={excluirDadosIniciais}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>

                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoDadosIniciais}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoDadosIniciais"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
          
        </fieldset>
        </div>
        </fieldset>
    </>
  );
};
