import React, { useContext } from "react";

import { DadosUsuario } from "../../components/dadosUsuario/DadosUsuario";

import { TipoUsuarioContexto } from "../../contexts/tipoUsuarioContexto";


import styles from './Home.module.css'
import { Outlet } from "react-router-dom";
import { Dropdown } from "../../layout/Dropdown/Dropdown";
import { linksAdmin, linksConclusaoLaudo, linksDadosIniciais, linksDadosPerito, linksDadosProcessuais, linksNovoGeradorPdf, linksQuesitos, linksRaciocinioTecnicoPericial, linksValoracaoDanoCorporal } from "../../routes/Links";

export const Home = () => {

  const {/* usuario, */ isAdmin, isPremium, isSecretaria} = useContext(TipoUsuarioContexto)

  const AdminHome = () => {
    return (
      <div className={styles.div}>
        <header className={styles.header}><DadosUsuario/></header>
        <main className={styles.main}>          
          <Outlet/>         
        </main>
        <aside className={styles.aside1}>
      
          <Dropdown nameButton={'Administrador'} links={linksAdmin}/>
          <Dropdown nameButton={'Dados Iniciais'} links={linksDadosIniciais}/>
          <Dropdown nameButton={'Dados Processuais'} links={linksDadosProcessuais}/>
          <Dropdown nameButton={'Dados Saude'} /* links={linksDadosSaude} *//>
           <Dropdown nameButton={'Raciocinio Tecnico Pericial'} links={linksRaciocinioTecnicoPericial}/> 
           <Dropdown nameButton={'Valoracao Dano Corporal'} links={linksValoracaoDanoCorporal}/> 
           <Dropdown nameButton={'Quesitos'} links={linksQuesitos}/>
           <Dropdown nameButton={'ConclusaoLaudo'} links={linksConclusaoLaudo}/>
           <Dropdown nameButton={'NovoGeradorPdf'} links={linksNovoGeradorPdf}/>
           <Dropdown nameButton={'DadosPerito'} links={linksDadosPerito}/>
        </aside>
        <aside className={styles.aside2}>
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </aside>
        <footer className={styles.footer}>
          <p>{/* Desenvolvido por Da Vinci Tecnologia e Saúde */}</p>
        </footer>
      </div>
    );
  };

  const PremiumHome = () => {
    return (
      <div className={styles.div}>
        <header className={styles.header}><DadosUsuario/></header>
        <main className={styles.main}>          
          <Outlet/>         
        </main>
        <aside className={styles.aside1}>
      
          {/* <Dropdown nameButton={'Administrador'} links={linksAdmin}/> */}
          <Dropdown nameButton={'Dados Iniciais'} links={linksDadosIniciais}/>
          <Dropdown nameButton={'Dados Processuais'} links={linksDadosProcessuais}/>
          <Dropdown nameButton={'Dados Saude'} /* links={linksDadosSaude} *//>
           <Dropdown nameButton={'Raciocinio Tecnico Pericial'} links={linksRaciocinioTecnicoPericial}/> 
           <Dropdown nameButton={'Valoracao Dano Corporal'} links={linksValoracaoDanoCorporal}/> 
           <Dropdown nameButton={'Quesitos'} links={linksQuesitos}/>
           <Dropdown nameButton={'ConclusaoLaudo'} links={linksConclusaoLaudo}/>
           <Dropdown nameButton={'NovoGeradorPdf'} links={linksNovoGeradorPdf}/>
           <Dropdown nameButton={'DadosPerito'} links={linksDadosPerito}/>
        </aside>
        <aside className={styles.aside2}>
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </aside>
        <footer className={styles.footer}>
          <p>{/* Desenvolvido por Da Vinci Tecnologia e Saúde */}</p>
        </footer>
      </div>
    );
  };

  const StandardHome = () => {
    return (
      <div className={styles.div}>
      <header className={styles.header}><DadosUsuario/></header>
      <main className={styles.main}>          
        <Outlet/>         
      </main>
      <aside className={styles.aside1}>
    
        {/* <Dropdown nameButton={'Administrador'} links={linksAdmin}/> */}
        <Dropdown nameButton={'Dados Iniciais'} links={linksDadosIniciais}/>
        <Dropdown nameButton={'Dados Processuais'} links={linksDadosProcessuais}/>
        <Dropdown nameButton={'Dados Saude'} /* links={linksDadosSaude} *//>
         <Dropdown nameButton={'Raciocinio Tecnico Pericial'} links={linksRaciocinioTecnicoPericial}/> 
         <Dropdown nameButton={'Valoracao Dano Corporal'} links={linksValoracaoDanoCorporal}/> 
         <Dropdown nameButton={'Quesitos'} links={linksQuesitos}/>
         <Dropdown nameButton={'ConclusaoLaudo'} links={linksConclusaoLaudo}/>
         <Dropdown nameButton={'NovoGeradorPdf'} links={linksNovoGeradorPdf}/>
         <Dropdown nameButton={'DadosPerito'} links={linksDadosPerito}/>
      </aside>
      <aside className={styles.aside2}>
      <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </aside>
      <footer className={styles.footer}>
        <p>{/* Desenvolvido por Da Vinci Tecnologia e Saúde */}</p>
      </footer>
    </div>
    );
  };

  const SecretariaHome = () => {
    return (

<div className={styles.div}>
        <header className={styles.header}><DadosUsuario/></header>
        <main className={styles.main}>          
          <Outlet/>         
        </main>
        <aside className={styles.aside1}>
      
          {/* <Dropdown nameButton={'Administrador'} links={linksAdmin}/> */}
          <Dropdown nameButton={'Dados Iniciais'} links={linksDadosIniciais}/>
          <Dropdown nameButton={'Dados Processuais'} links={linksDadosProcessuais}/>
          <Dropdown nameButton={'Dados Saude'} /* links={linksDadosSaude} *//>
           <Dropdown nameButton={'Raciocinio Tecnico Pericial'} links={linksRaciocinioTecnicoPericial}/> 
           <Dropdown nameButton={'Valoracao Dano Corporal'} links={linksValoracaoDanoCorporal}/> 
           <Dropdown nameButton={'Quesitos'} links={linksQuesitos}/>
           <Dropdown nameButton={'ConclusaoLaudo'} links={linksConclusaoLaudo}/>
           <Dropdown nameButton={'NovoGeradorPdf'} links={linksNovoGeradorPdf}/>
           <Dropdown nameButton={'DadosPerito'} links={linksDadosPerito}/>
        </aside>
        <aside className={styles.aside2}>
        <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </aside>
        <footer className={styles.footer}>
          <p>{/* Desenvolvido por Da Vinci Tecnologia e Saúde */}</p>
        </footer>
      </div>




      
    );
  };

  const statusHome =
    isAdmin ? (
      <AdminHome />
    ) : isPremium ? (
      <PremiumHome />
    ) : (isSecretaria ? (<SecretariaHome/>) :(
      <StandardHome />
    ));

  return <div>{statusHome}</div>;
};

//LIXO

/* <div>
        <CardGlass>
          <DadosUsuario></DadosUsuario>
           <DadosIniciais></DadosIniciais>
        <DadosProcessuais></DadosProcessuais>
        <DadosSaude></DadosSaude>
         <DadosReferencialTecnico></DadosReferencialTecnico>
        <RaciocinioTecnicoPericial></RaciocinioTecnicoPericial>
        <ValoracaoDanoCorporal></ValoracaoDanoCorporal> 
        <Quesitos></Quesitos>
         <ConclusaoLaudo />
        <Esclarecimentos /> 
         <NovoGeradorPdf />
        <BancoDados></BancoDados> 
         <MostraPericias /> 
        </CardGlass>
      </div> */
