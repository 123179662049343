import whats from "./assets/whatzapp6.jpeg";
import styles from "./Site.module.css";
import { Link } from "react-router-dom";

export const Site = () => {
  return (
    <>
      <noscript>
        <iframe
          title={"googletagmanager"}
          src={"https://www.googletagmanager.com/ns.html?id=GTM-W5W6ZG6N"}
          height={"0"}
          width={"0"}
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <div className={styles.div}>
        <header className={styles.header}>
          <div>
            <Link to={"/Login"}>Acesso ao sistema</Link>
            <Link
              to={
                "https://wa.me/5581994213928?text=Olá, gostaria de um orçamento de assistência técnica pericial para minha empresa."
              }
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              <img
                style={{
                  size: "10%",
                  height: "7vh",
                  opacity: "0.6",
                  cursor: "pointer",
                }}
                src={whats}
                alt="Fale conosco pelo whatzapp"
                title="Fale conosco pelo whatzapp"
              />
            </Link>
          </div>
        </header>
        <main className={styles.main}>
          <div>
            <span>
              Perícia médica e assistência técnica pericial com
              <h1> excelência.</h1>
            </span>
            <p>
              “Como médico perito judicial e assistente técnico, minha expertise
              é essencial para a justiça prevalecer. Analiso minuciosamente
              processos, fornecendo pareceres técnicos embasados. Conte comigo
              para garantir imparcialidade e rigor na busca pela verdade.”
            </p>
            <p>Entre em contato</p>
            <p>Email: contato@pericias.app</p>
            <p>
              Endereço: Av. República do Líbano, 251,Empresarial Rio Mar Trade
              Center 3, Sl 2801, Pina, Recife - PE
            </p>
          </div>
        </main>
        <aside className={styles.aside1}></aside>
        <aside className={styles.aside2}></aside>
        <footer className={styles.footer}>
          <p>Desenvolvido por Da Vinci Tecnologia e Saúde</p>
        </footer>
      </div>
    </>
  );
};
