import React, {useEffect, useState} from 'react'

export const BuscarApiCnpj = () => {
    const [dadosApiCnpj, setDadosApiCnpj] = useState({});
  
    const [formValues, setFormValues] = useState({});
  
    const handleInputChange = (e) => {
      const { name, value, type } = e.target;
  
      const data = formValues[name] || {};
      const isCheckbox = type === "checkbox";
      const newValue = isCheckbox ? data : value;
  
      setFormValues({ ...formValues, [name]: newValue });
      /* console.log(
        "***data",
        data,
        "***formValues",
        formValues,
        "***newValue",
        newValue
      ); */
    };
  
    const adicionarDadosApiCnpj = async () => {
      
      if (formValues.cnpj !== undefined && formValues.cnpj.length === 18) {
        const resApi = await fetch(
          `https://brasilapi.com.br/api/cnpj/v1/${formValues.cnpj.replace(
            /\D/g,
            ""
          )}`
        );
        const dados = await resApi.json();
  
        if (resApi.status !== 200) {
          //alert(`Desculpe, estamos sem acesso aos dados ou o CEP é inválido!! status:${resApi.status}, ok:${resApi.ok}`)
          
          setDadosApiCnpj({
            statusApi:
              resApi.status !== undefined
                ? resApi.status
                : "Sem informações de status da API",
            respostaApi:
              resApi.ok !== undefined ? resApi.ok : "Sem informações da API",
            /* cep:'' */
          });
  
          localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _99_dadosApiCnpj: {},
            })
          );
        } else {
          setDadosApiCnpj({
            tipoPolo2:
              formValues.tipoPolo2 !== undefined && formValues.tipoPolo2 !== ""
                ? formValues.tipoPolo2
                : "",
            cnpj:
              formValues.cnpj !== undefined
                ? formValues.cnpj
                    .trim()
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d)/, "$1.$2")
                    .replace(/(\d{3})(\d)/, "$1.$2")
                    .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                    .replace(/(\d{4})(\d)/, "$1-$2")
                    .replace(/(-\d{2})\d+$/, "$1")
                : "",
            razaoSocial:
              dados.razao_social !== undefined ? dados.razao_social : "",
            cnaeFiscal:
              dados.cnae_fiscal !== undefined
                ? dados.cnae_fiscal.toString()
                : "",
            cnaeFiscalDescricao:
              dados.cnae_fiscal_descricao !== undefined
                ? dados.cnae_fiscal_descricao
                : "",
            statusApi:
              resApi.status !== undefined
                ? resApi.status.toString()
                : "Sem informações de status da API",
            respostaApi:
              resApi.ok !== undefined ? resApi.ok.toString() : "Sem informações da API",
          });
  
          localStorage.setItem(
            "tudo",
            JSON.stringify({
              ...(JSON.parse(localStorage.getItem("tudo")) || []),
              _99_dadosApiCnpj: {
                tipoPolo2:
                  formValues.tipoPolo2 !== undefined && formValues.tipoPolo2 !== ""
                    ? formValues.tipoPolo2
                    : "",
                cnpj:
                  formValues.cnpj !== undefined
                    ? formValues.cnpj
                        .trim()
                        .replace(/\D/g, "")
                        .replace(/(\d{2})(\d)/, "$1.$2")
                        .replace(/(\d{3})(\d)/, "$1.$2")
                        .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                        .replace(/(\d{4})(\d)/, "$1-$2")
                        .replace(/(-\d{2})\d+$/, "$1")
                    : "",
                razaoSocial:
                  dados.razao_social !== undefined ? dados.razao_social : "",
                cnaeFiscal:
                  dados.cnae_fiscal !== undefined
                    ? dados.cnae_fiscal.toString()
                    : "",
                cnaeFiscalDescricao:
                  dados.cnae_fiscal_descricao !== undefined
                    ? dados.cnae_fiscal_descricao
                    : "",
                    statusApi:
                      resApi.status !== undefined
                        ? resApi.status.toString()
                        : "Sem informações de status da API",
                    respostaApi:
                      resApi.ok !== undefined ? resApi.ok.toString() : "Sem informações da API",
              },
            })
          );
  
          //console.log("***dadosApiCnpj***", dadosApiCnpj);
        }
      }
    };
  
    const excluirDadosApiCnpj = (e) => {
      e.preventDefault();
  
      if (
        e.target.offsetParent.nextElementSibling.innerText === dadosApiCnpj.tipoPolo
      ) {
        setDadosApiCnpj({});
        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _99_dadosApiCnpj: {},
          })
        );
      }
      if (
        e.target.offsetParent.nextElementSibling.nextElementSibling.innerText ===
        dadosApiCnpj.cnpj
      ) {
        setDadosApiCnpj({});
        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _99_dadosApiCnpj: {},
          })
        );
      }
  
      return null;
    };
  
    const excluirTudoDadosApiCnpj = (e) => {
      e.preventDefault();
     
        setDadosApiCnpj([])
  
        localStorage.setItem(
          "tudo",
          JSON.stringify({
            ...(JSON.parse(localStorage.getItem("tudo")) || []),
            _99_dadosApiCnpj: []
          })
        );
    }
  
    const handleCarregarFormDadosApiCnpj = () => {

      const tudo = JSON.parse(localStorage.getItem("tudo"));

      const x = () => {
        if (tudo._99_dadosApiCnpj) {
          const a = JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj !== undefined
          ||JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj !== null ? 
          [JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj] : []
    
          //console.log('***',a)
          a.map((e) =>
            setFormValues({
              tipoPolo2:
                      e.tipoPolo2 !== undefined && e.tipoPolo2 !== ""
                        ? e.tipoPolo2
                        : "",
                    cnpj:
                      e.cnpj !== undefined && e.cnpj !== ""
                        ? e.cnpj
                            .trim()
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                            .replace(/(\d{4})(\d)/, "$1-$2")
                            .replace(/(-\d{2})\d+$/, "$1")
                        : "",
                    razaoSocial:
                      e.razaoSocial !== undefined && e.razaoSocial !== "" ? e.razaoSocial : "",
                    cnaeFiscal:
                      e.cnaeFiscal !== undefined && e.cnaeFiscal !== ""
                        ? e.cnaeFiscal.toString()
                        : "",
                    cnaeFiscalDescricao:
                      e.cnaeFiscalDescricao !== undefined && e.cnaeFiscalDescricao !== ""
                        ? e.cnaeFiscalDescricao
                        : "",
                        statusApi:
                          e.statusApi !== undefined
                            ? e.statusApi
                            : "",
                        respostaApi:
                          e.respostaApi !== undefined ? e.respostaApi : "",
          
          })
          );
        }
      }
  
      const y = () => {
        if (tudo._99_dadosApiCnpj) {
          const a = JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj !== undefined
          ||JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj !== null ? 
          [JSON.parse(localStorage.getItem('tudo'))._99_dadosApiCnpj] : []
    
          //console.log('***',a)
          a.map((e) =>
            setDadosApiCnpj({
              tipoPolo2:
                      e.tipoPolo2 !== undefined && e.tipoPolo2 !== ""
                        ? e.tipoPolo2
                        : "",
                    cnpj:
                      e.cnpj !== undefined && e.cnpj !== ""
                        ? e.cnpj
                            .trim()
                            .replace(/\D/g, "")
                            .replace(/(\d{2})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d)/, "$1.$2")
                            .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                            .replace(/(\d{4})(\d)/, "$1-$2")
                            .replace(/(-\d{2})\d+$/, "$1")
                        : "",
                    razaoSocial:
                      e.razaoSocial !== undefined && e.razaoSocial !== "" ? e.razaoSocial : "",
                    cnaeFiscal:
                      e.cnaeFiscal !== undefined && e.cnaeFiscal !== ""
                        ? e.cnaeFiscal.toString()
                        : "",
                    cnaeFiscalDescricao:
                      e.cnaeFiscalDescricao !== undefined && e.cnaeFiscalDescricao !== ""
                        ? e.cnaeFiscalDescricao
                        : "",
                        statusApi:
                          e.statusApi !== undefined
                            ? e.statusApi
                            : "",
                        respostaApi:
                          e.respostaApi !== undefined ? e.respostaApi : "",
          
          })
          );
        }
      }
  
      x()
      y()

  }
  
    const handleReset = () => {
        setFormValues({})
    }
  
    const handleLimparCampo = (e) => {
      e.preventDefault();
      if (e.target.attributes.value) {
        setFormValues({ ...formValues, [e.target.attributes.value.value]: "" });
      }
    };
  
    useEffect(() => {
      if (formValues.cnpj !== undefined) {
  
        //console.log(formValues.cnpj.length);
        if (formValues.cnpj.lenght === 18) {
          formValues.cnpj = formValues.cnpj.replace(/\D/g, "");
          /* .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1/$2')
                .replace(/(\d{4})(\d)/, '$1-$2')
                .replace(/(-\d{2})\d+$/, '$1')  */
    
          //console.log(formValues.cnpj.length, formValues.cnpj);
        }
  
        
      }
    }, [dadosApiCnpj, formValues]);
  
    //console.log( dadosApiCnpj)
    return (
      <>
        <fieldset>
        <legend>Api CNPJ</legend>
        <div >
        <fieldset >
          <table >
            
            <thead>
              <tr>
                <th>Limpar Campo</th>
                <th>Informação</th>
              </tr>
            </thead>

            <tbody>
                      <tr> 
                          <th><button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"cnpj"}></i>
                  </button></th>
                          <th>
                          <input
                type="text"
                name="cnpj"
                size="18"
                minLength="18"
                maxLength="18"
                placeholder="Digite o CNPJ "
                onChange={handleInputChange}
                value={
                  formValues.cnpj !== undefined
                    ? formValues.cnpj
                        .trim()
                        .replace(/\D/g, "")
                        .replace(/(\d{2})(\d)/, "$1.$2")
                        .replace(/(\d{3})(\d)/, "$1.$2")
                        .replace(/(\d{3})(\d{1,2})/, "$1/$2")
                        .replace(/(\d{4})(\d)/, "$1-$2")
                        .replace(/(-\d{2})\d+$/, "$1")
                    : ""
                }
              />
                          </th>
                         
                      </tr>

                      <tr> 
                          <th><button onClick={handleLimparCampo}>
                    <i className="fas fa-trash perigo" value={"tipoPolo2"}></i>
                  </button></th>
                          <th>
                          <div className="radios">
                <span>Tipo do Polo</span>
                <label>
                  <input
                    type="radio"
                    value="Polo ativo"
                    name="tipoPolo2"
                    onChange={handleInputChange}
                    checked={formValues.tipoPolo2 !== undefined ? formValues.tipoPolo2 === "Polo ativo" : ''}
                  />
                  Polo ativo
                </label>
                <label>
                  <input
                    type="radio"
                    value="Polo Passivo"
                    name="tipoPolo2"
                    onChange={handleInputChange}
                    checked={formValues.tipoPolo2 !== undefined ? formValues.tipoPolo2 === "Polo Passivo" : ''}
                  />
                  Polo passivo
                </label>
              </div> 
                          </th>
                         
                      </tr>
   
                      </tbody>
          </table>

          <fieldset>
            <legend>Ações</legend>
            <table>
              <thead>
                <tr>
                  <th>Adicionar</th>
                  <th>Carregar</th>
                  <th>Limpar campos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th >
                    <button onClick={adicionarDadosApiCnpj}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </th>
                  <th>
                    <button onClick={handleCarregarFormDadosApiCnpj}>
                      Carregar formulário
                    </button>
                  </th>
                  <th >
                    <button onClick={handleReset}>
                      <i
                        className="fas fa-trash perigo"
                        value={"Limpar Campos"}
                      ></i>
                    </button>
                  </th>
                </tr>
              </tbody>
            </table>
          </fieldset>
      
      
              <fieldset>
              
                <legend>Dados da Api Cnpj encontrados</legend>
                {dadosApiCnpj.statusApi !== 200 && !dadosApiCnpj.cnpj ? 
                
                  <p>
                    Erro {dadosApiCnpj.statusApi} ou CNPJ inválido, preencha
                    manualmente
                  </p>
                 : 
                 <>
                 <table>
                   <thead>
                     <tr>
                       <th>Tópico</th>
                       <th>Informação</th>
                     </tr>
                   </thead>
                      {[dadosApiCnpj].length > 0 &&
                        [dadosApiCnpj].map(
                          (
                            {
                              tipoPolo2,
                              cnpj,
                              razaoSocial,
                              cnaeFiscal,
                              cnaeFiscalDescricao,
                              statusApi,
                            },
                            i
                          ) => {
                            return (
                              <tbody key={i}>
                            
                            <tr >
                            <th>Registro número</th>
                            <th> {i + 1}</th>
                          </tr>
                              <tr>
                                <th>Tipo polo
                                </th>
                                <th>{tipoPolo2}</th>
                                </tr>

                                <tr>
                                <th>Cnpj
                                </th>
                                <th>{cnpj}</th>
                                </tr>

                                <tr>
                                <th>Razão social
                                </th>
                                <th>{razaoSocial}</th>
                                </tr>

                                <tr>
                                <th>CNAE fiscal
                                </th>
                                <th>{cnaeFiscal}</th>
                                </tr>

                                <tr>
                                <th>CNAE descrição</th>
                                <th>{cnaeFiscalDescricao}</th>
                                </tr>

                                <tr>
                                <th>Status API</th>
                                <th>{statusApi} {/* {respostaApi} */}</th>
                                </tr>
                                <tr>
                            <th>Ação</th>
                            <th >
                              <button onClick={excluirDadosApiCnpj}>
                                <i
                                  className="fas fa-solid fa-trash perigo"
                                  value={i}
                                ></i>
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                <fieldset>
                  <legend>Ação</legend>
                  <table>
                    <thead>
                      <tr>
                        <th>Excluir todos os documentos?</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr >
                        <th>
                          <button onClick={excluirTudoDadosApiCnpj}>
                            <i
                              className="fas fa-solid fa-trash perigo"
                              value={"excluirTudoDadosApiCnpj"}
                            ></i>
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </>
            }
          </fieldset>
        </fieldset>
    </div>  </fieldset>
    </>
  );
};
