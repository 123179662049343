import React from "react";
import { Link } from "react-router-dom";

export const PaginaNaoEncontrada = () => {
  return (
    <>
      <div
        style={{
          background: "url(fundoAutenticacao.jpg)no-repeat center center/cover",
          width: "100%",
          height: "100vh",
          overflow: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/">
          <button>Página desconhecida, volte para a tela inicial</button>
        </Link>
      </div>
    </>
  );
};
