import style from "./Dropdown.module.css";

const Button = ({ nome }) => <button>{nome}</button>;
const DivConteudo = ({ links }) => (
  <ul className={style.conteudo}>{links ? links.map((e,i) => <li key={i}>{e}</li>) : ''}</ul>
);

export const Dropdown = ({ nameButton, links }) => {
  return (
    <div className={style.dropdown}>
      <Button nome={nameButton} />
      <DivConteudo links={links} />
    </div>
  );
};
